<template>
	<div class="total-sales-statistic vld-parent">
		<loading :active.sync="showLoader" :is-full-page="false"></loading>
		<InsightBaseWidget
			:title="'Total Sales'"
			:contentText="statistic"
			:footerIcon="'sk-icon-lightbulb-light'"
			:footerTitle="'Learn more about utilizing this information'"
			:footerLinkText="'View'"
			:actions="actions"
			@actionSelected="actionSelected"
			@footerClicked="footerClicked"
		/>
	</div>
</template>

<style scoped>
.total-sales-statistic {
	display: flex;
}
</style>

<script>
import { PermissionInfo } from "@/utils/permissions"
import search_service from "@/services/search"
import xlsx_helper from "@/utils/xlsx-helper"
import moment from "moment"
import currencyFilterMixin from "@/mixins/currency-filter-mixin"
import InsightBaseWidget from "@/components/insights/InsightBaseWidget.vue"
import Loading from "vue-loading-overlay"

export default {
	name: "TotalSalesStatistic",
	mixins: [currencyFilterMixin],
	components: {
		InsightBaseWidget,
		Loading
	},
	props: {
		footerArticleId: String,
		exportCogs: Boolean
	},
	data: function () {
		return {
			exporting: false
		}
	},
	computed: {
		statistic() {
			const data = this.$store.state.insightsModule.totalSales.data
			if (data) {
				const total = data.reduce((total, dailyData) => {
					total += dailyData.sum
					return total
				}, 0)
				return this.$options.filters.currency(total)
			}
			return "--"
		},
		showLoader() {
			return this.loadingData || this.exporting
		},
		loadingData() {
			return this.$store.state.insightsModule.totalSales.loading
		},
		actions() {
			if (
				this.$store.state.permissions.includes(
					PermissionInfo.INSIGHTS_SALES_DOWNLOAD
				)
			) {
				return [
					{
						id: 0,
						text: `<i class="sk-icon-download"></i> Download`
					}
				]
			}
			return []
		}
	},
	methods: {
		async actionSelected() {
			try {
				this.exporting = true
				const module = this.$store.state.insightsModule
				const downloadFilters = {
					order_date_local_from: moment
						.utc(module.filters.startDate, "YYYY-MM-DD")
						.format(),
					order_date_local_to: moment
						.utc(module.filters.endDate, "YYYY-MM-DD")
						.format()
				}

				if (module.filters.businessId) {
					downloadFilters.business = {
						ids: [module.filters.businessId]
					}
				}

				if (module.filters.restaurantId) {
					downloadFilters.restaurant = {
						ids: [module.filters.restaurantId]
					}
				}

				const ordersData = module.totalOrders.data
				let limit = 1000
				if (ordersData) {
					limit = ordersData.reduce((total, dailyData) => {
						total += dailyData.total
						return total
					}, 0)
					//Padding in case we have more records than orders
					limit += 50
				}

				const response =
					await search_service.searchOrdersFinancialRecords({
						filters: downloadFilters,
						pagination: {
							offset: 0,
							limit: limit
						}
					})

				const exportData = response.data.orders_financial_records.map(
					(record) => {
						if (this.exportCogs) {
							return {
								"Order ID": record.order_id,
								"Order Date": record.order_date,
								Cogs: record.cogs,
								"Cogs Tax": record.cogs_tax,
								Commission: record.restaurant_commission,
								"Exception Amount": record.exceptions,
								"Tip to Restaurant": record.restaurant_tip
							}
						} else {
							return {
								"Order ID": record.order_id,
								"Order Date": record.order_date,
								Cogs: record.cogs,
								"Cogs Tax": record.cogs_tax,
								Commission: record.restaurant_commission,
								"Exception Amount": record.exceptions,
								"Tip to Restaurant": record.restaurant_tip,
								Tax: record.tax,
								"Service Fee": record.service_fee,
								Total: record.price
							}
						}
					}
				)

				let options = null

				if (this.exportCogs) {
					options = {
						header: [
							"Order ID",
							"Order Date",
							"Cogs",
							"Cogs Tax",
							"Commission",
							"Exception Amount",
							"Tip to Restaurant"
						]
					}
				} else {
					options = {
						header: [
							"Order ID",
							"Order Date",
							"Cogs",
							"Cogs Tax",
							"Commission",
							"Exception Amount",
							"Tip to Restaurant"
						]
					}
				}

				xlsx_helper.export(exportData, "Total Sales", options)
				this.exporting = false
			} catch (e) {
				this.exporting = false
				throw e
			}
		},
		footerClicked() {
			$crisp.push([
				"do",
				"helpdesk:article:open",
				["en", this.footerArticleId]
			])
		}
	}
}
</script>