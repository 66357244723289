<template>
  <SkBaseModal
  :isOpen="isOpen"
  :showCloseButton="false"
  @close="close">
  <div class="sk-modal-action-header">
    <h1 class="sk-modal-title">New Category</h1>
    <button 
      @click="saveCategory"
      class="button button-primary">
      Save
    </button>
  </div>
    <input 
      class="sk-input"
      v-model="categoryName" 
      placeholder="Category Name" 
      type="text">
    <!-- <textarea 
      class="sk-input"
      v-model="categoryDescription" 
      placeholder="Category Description" 
      type="text"></textarea> -->
  </SkBaseModal>
</template>

<style scoped>
.sk-input + .sk-input {
  margin-top: 20px;
}
</style>

<script>
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import skModalMixin from "@/mixins/modals/sk-modal-mixin"

export default {
  name: "NewCategoryModal",
  mixins: [skModalMixin],
  components: {
    SkBaseModal
  },
  data: function() {
    return {
      categoryName: "",
      categoryDescription: ""
    }
  },
  methods: {
    opened: function() {
      this.categoryName = ""
    },
    saveCategory() {
      this.$store.dispatch("restaurantsModule/addCategory", {
        name: this.categoryName
      })
      this.close()
    }
  }
};
</script>