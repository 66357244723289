<template>
	<div class="sk-page">
		<div class="page-left">
			<div class="sk-widget-alt reset-widget">
				<img src="/images/2k-logo-login.png" alt="2ndKitchen Logo" />
				<SkPasswordInput
					class="sk-row"
					ref="passwordInput"
					v-model="password"
					:name="'New Password'"
					:tabindex="1"
					@onEnter="resetPassword"
				/>
				<SkPasswordInput
					class="sk-row"
					ref="confirmedPasswordInput"
					v-model="confirmedPassword"
					:name="'Confirm New Password'"
					:tabindex="2"
					@onEnter="resetPassword"
				/>
				<div class="sk-row">
					<button
						@click="resetPassword"
						class="button button-primary reset-password"
					>
						Reset Password
					</button>
				</div>
				<SignupWidgetFooter
					:links="footerLinks"
					@linkClicked="linkClicked"
				/>
			</div>
		</div>
		<LoginCarousel />
	</div>
</template>

<style scoped>
.sk-page {
	flex-direction: row;
}

.page-left {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

.reset-widget {
	width: 500px;
	max-width: 500px;
	height: auto;
	padding: 0;
}

.reset-widget img {
	height: 40px;
	margin: 60px auto;
	display: block;
}

.reset-password {
	min-width: 140px;
	flex: 0 0 auto !important;
	margin: 10px auto 0 auto;
}
</style>

<script>
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import tracker from "@/utils/tracker"
import auth from "@/utils/auth"
import loaderMixin from "@/mixins/loader-mixin"
import LoginCarousel from "@/components/login/LoginCarousel.vue"
import SkPasswordInput from "@/components/SkPasswordInput.vue"
import SignupWidgetFooter from "@/components/login/SignupWidgetFooter.vue"

export default {
	name: "ResetPassword",
	mixins: [loaderMixin],
	components: {
		LoginCarousel,
		SkPasswordInput,
		SignupWidgetFooter
	},
	data() {
		return {
			password: "",
			confirmedPassword: "",
			loading: false,
			footerLinks: [
				{
					id: 0,
					text: `Not a 2ndKitchen Partner? <span class="signup-link">Get Started!</span>`
				},
				{
					id: 1,
					text: `Already a Partner? <span class="signup-link">Sign In!</span>`
				}
			]
		}
	},
	methods: {
		validate() {
			const passwordValid = this.$refs.passwordInput.validate()
			const confirmedPasswordValid =
				this.$refs.confirmedPasswordInput.validate()
			return passwordValid && confirmedPasswordValid
		},
		resetPassword() {
			tracker.trackEvent("Reset Password")
			const isValid = this.validate()
			if (!isValid) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Reset Password",
					message: `Please fill out all fields`,
					type: NotificationType.WARNING
				})
				return
			}

			this.loading = true
			const token = this.$route.params.token

			if (this.password != this.confirmedPassword) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Reset Password",
					message: `Passwords do not match`,
					type: NotificationType.WARNING
				})
				this.loading = false
			} else {
				auth.resetPassword(this.password, token)
					.then((success) => {
						if (!success) {
							eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
								title: "Reset Password",
								message: `Something went wrong, please try again`,
								type: NotificationType.ERROR
							})
						} else {
							eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
								title: "Reset Password",
								message: `Your password has been reset successfully`,
								type: NotificationType.SUCCESS
							})
							this.$router.replace(
								this.$route.query.redirect || "/"
							)
						}
					})
					.finally(() => {
						this.loading = false
					})
			}
		},
		linkClicked(link) {
			switch (link.id) {
				case 0: {
					tracker.trackEvent("Signup Clicked")
					window.open("https://2ndkitchen.com/")
					break
				}
				case 1: {
					tracker.trackEvent("Activate Clicked")
					this.$emit("backToLogin")
					break
				}
			}
		}
	},
	watch: {
		loading(newVal, oldVal) {
			if (newVal && !oldVal) {
				this.toggleLoader(true)
			} else if (!newVal && oldVal) {
				this.toggleLoader(false)
			}
		}
	}
}
</script>