<template>
	<div class="sk-table-row">
		<div
			class="sk-table-column-checkbox"
			@click="batchSelectClicked($event)"
		>
			<SkCheckbox class="sk-checkbox-green" :checked="batchSelected" />
		</div>
		<div class="sk-table-column-fit">
			<span :class="exceptionStatusClass"></span>
			{{ exceptionStatusText }}
		</div>
		<div class="sk-table-column-fit">
			{{ exception.order_id }}
		</div>
		<div class="sk-table-column-fit">
			{{ created_date }}
		</div>
		<div class="sk-table-column-fit">
			<div class="sk-pills-container">
				<router-link
					:key="exception.id"
					:to="restaurantLink"
					tag="a"
					class="pill pill-grey"
					:title="name"
				>
					<i class="sk-icon-link-regular"></i>
					{{ name }}
				</router-link>
			</div>
		</div>
		<div>${{ exception.amount }}</div>
		<div>
			{{ exception_text }}
		</div>
		<div>N/A</div>
		<div class="sk-table-column-fit">
			<div class="sk-table-actions">
				<button
					class="button button-icon"
					title="Approve"
					@click="approveException"
				>
					<i class="sk-icon-check-regular"></i>
				</button>
				<SkDropdownSelect
					:options="allowedActions"
					:iconClasses="'sk-icon-ellipsis-v-regular'"
					:showSelectedOption="false"
					:position="'left'"
					@selected="actionSelected"
				/>
			</div>
		</div>
	</div>
</template>

<style scoped>
.pill i {
	margin-right: 10px;
}
.sk-table-body .sk-table-row > .menu-schedules {
	white-space: normal;
}
.menu-schedules {
	padding: 20px 0;
}
.menu-schedules > div {
	display: inline-block;
	margin-right: 20px;
}

.pill {
	margin: 0 !important;
}

.order-pill {
	max-width: 1000px !important;
}
</style>

<script>
import moment from "moment"
import batchSelectableMixin from "@/mixins/batch-selectable-mixin"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"
import SkCheckbox from "@/components/SkCheckbox.vue"
import { ExceptionReasonType } from "@/enums/exceptionReasonType.js"

export default {
	name: "ExceptionLineItem",
	mixins: [batchSelectableMixin],
	components: {
		SkDropdownSelect,
		SkCheckbox
	},
	props: {
		exception: Object
	},
	computed: {
		restaurantLink() {
			return this.restaurant != undefined
				? `/restaurant/${this.restaurant.id}`
				: "/restaurants'"
		},
		exceptionStatusClass() {
			return "status-dot yellow"
		},
		exceptionStatusText() {
			return "Pending"
		},
		restaurant() {
			let retVal = this.$store.state.restaurants[
				this.exception.restaurant_id
			]
			return retVal
		},
		created_date() {
			if (this.restaurant != undefined) {
				return moment
					.utc(this.exception.created_date)
					.add(this.restaurant.timezone_offset, "m")
					.format("MMMM Do YYYY, h:mm a")
			} else
				return moment
					.utc(this.exception.created_date)
					.format("MMMM Do YYYY, h:mm a")
		},
		name() {
			if (this.restaurant) {
				return this.restaurant.name
			} else {
				return ""
			}
		},
		exception_text() {
			let exceptions_types = this.$store.state.ordersModule.exception_types
			let that = this
			const relExceptionArr = exceptions_types.filter(function (exc) {
				return exc.id == that.exception.reason_id
			})
			if (relExceptionArr.length == 1) {
				if (this.exception.reason_id == ExceptionReasonType.OTHER){
					return relExceptionArr[0].text + " - " + this.exception.reason_text
				}
				return relExceptionArr[0].text
			}
			else return "N/A"
		},
		allowedActions() {
			let actions = [
				{
					text: `<i class="sk-icon-times-regular"></i> Reject`,
					id: 0
				},
				{
					text: `<i class="sk-icon-pencil-solid"></i> Edit`,
					id: 1
				},
				{
					text: `<i class="sk-icon-eye-solid"></i> View Order`,
					id: 2
				}
			]
			return actions
		}
	},
	methods: {
		approveException() {
			this.$emit("setExceptionStatus", {
				exception: this.exception,
				approve: true
			})
		},
		openOrderDetails() {
			this.$emit("openOrderDetailsModal", this.exception.order_id)
		},
		actionSelected(action) {
			switch (action.id) {
				case 0: {
					this.$emit("setExceptionStatus", {
						exception: this.exception,
						approve: false
					})
					break
				}
				case 1: {
					this.$emit("updateExceptionClicked", {
						exception: this.exception
					})
					break
				}
				case 2: {
					this.openOrderDetails()
					break
				}
			}
		}
	}
}
</script>