import Vue from 'vue'
import Router from 'vue-router'
import guards, { PermissionGuardMode } from "@/utils/guards"
import { store } from "@/store/store"

import Terms from "./views/Terms.vue"
import Privacy from "./views/Privacy.vue"
import Restaurants from './views/Restaurants.vue'
import Businesses from './views/Businesses.vue'
import Business from './views/Business.vue'
import Restaurant from './views/Restaurant.vue'
import RestaurantMenu from "./views/RestaurantMenu.vue"
import BusinessMenu from "./views/BusinessMenu.vue"
import Login from './views/Login.vue'
import Promotions from './views/Promotions.vue'
import ActiveOrders from "./views/ActiveOrders.vue"
import Finance from './views/Finance.vue'
import Dashboard from './views/Dashboard.vue'
import Supplies from './views/Supplies.vue'
import SalesTool from './views/SalesTool.vue'
import Activate from './views/Activate.vue'
import ResetPassword from './views/ResetPassword.vue'
import Events from "./views/Events.vue"
import Event from "./views/Event.vue"
import PayoutDetails from "./views/PayoutDetails.vue"
import InvoiceDetails from "./views/InvoiceDetails.vue"
import Users from "@/views/Users.vue"
import BusinessGuest from "@/views/BusinessGuest.vue"
import VoucherRequest from "@/views/VoucherRequest.vue"
import Prepackages from "@/views/Prepackages.vue"
import Insights from "@/views/Insights.vue"
import Hubs from "@/views/Hubs.vue"
import Hub from "@/views/Hub.vue"

Vue.use(Router)

import auth from "@/utils/auth"
import { SidebarMenuId } from "@/enums/sidebarMenuId"
import { PermissionPage } from "@/utils/permissions"
import { EntityType } from "@/enums/entityType"

export const RouteName = {
  "TERMS": "Terms",
  "PRIVACY": "Privacy",
  "ACTIVATE": "Activate",
  "RESET_PASSWORD": "ResetPassword",
  "LOGIN": "Login",
  "ORDERS": "Orders",
  "DASHBOARD": "Dashboard",
  "RESTAURANTS": "Restaurants",
  "BUSINESSES": "Businesses",
  "PROMOTIONS": "Promotions",
  "VOUCHER_REQUEST": "VoucherRequest",
  "FINANCE": "Finance",
  "PAYOUT_DETAILS": "PayoutDetails",
  "INVOICE_DETAILS": "InvoiceDetails",
  "SALES": "Sales",
  "BUSINESS": "Business",
  "BUSINESS_MENU": "BusinessMenu",
  "RESTAURANT": "Restaurant",
  "SUPPLIES": "Supplies",
  "USERS": "Users",
  "BUSINESS_GUEST": "BusinessGuest",
  "LOGOUT": "Logout",
  "EVENTS": "Events",
  "EVENT": "Event",
  "PREPACKAGES": "Prepackages",
  "INSIGHTS": "Insights",
  "HUBS": "Hubs"
}

export const RouteTitle = {
  "TERMS": "Terms of Service",
  "PRIVACY": "Privacy Policy",
  "ORDERS": "Orders",
  "DASHBOARD": "Dashboard",
  "RESTAURANTS": "Restaurants",
  "BUSINESSES": "Businesses",
  "PROMOTIONS": "Promotions",
  "VOUCHER_REQUEST": "Voucher Requests",
  "FINANCE": "Finance",
  "PAYOUT_DETAILS": "Payout Details",
  "INVOICE_DETAILS": "Invoice Details",
  "SALES": "Sales",
  "SUPPLIES": "Supplies",
  "USERS": "Users",
  "EVENTS": "Events",
  "PREPACKAGES": "Catering",
  "INSIGHTS": "Insights",
  "HUBS": "Hubs"
}

const router = new Router({
    mode: 'history',
    base : __dirname,
    routes: [
    {
      path: '/terms',
      name: RouteName.TERMS,
      component: Terms,
      meta: {
        title: RouteTitle.TERMS
      }
    },
    {
      path: '/privacy',
      name: RouteName.PRIVACY,
      component: Privacy,
      meta: {
        title: RouteTitle.PRIVACY
      }
    },
    {
      path: '/activate/:token',
      name: RouteName.ACTIVATE,
      component: Activate
    },
    {
      path: '/reset/:token',
      name: RouteName.RESET_PASSWORD,
      component: ResetPassword
    },
    {
      path: '/login',
      name: RouteName.LOGIN,
      component: Login,
      beforeEnter: guards.requireLoggedOut
    },
     {
      path: '/orders',
      name: RouteName.ORDERS,
      component: ActiveOrders,
      beforeEnter: guards.multiGuard([guards.requireAuth]),
      meta: {
        title: RouteTitle.ORDERS,
        sidebarMenuId: SidebarMenuId.ACTIVE_ORDERS
      }
    },
    {
      path: '/',
      alias: '/dashboard',
      name: RouteName.DASHBOARD,
      component: Dashboard, 
      beforeEnter: guards.requireAuth,
      meta: {
        title: RouteTitle.DASHBOARD,
        sidebarMenuId: SidebarMenuId.DASHBOARD
      }
    },
    {
      path: '/restaurants',
      name: RouteName.RESTAURANTS,
      component: Restaurants, 
      beforeEnter: guards.multiGuard([guards.requireAuth, guards.requirePermission]),
      meta: {
        title: RouteTitle.RESTAURANTS,
        permission: PermissionPage.MANAGE_RESTAURANTS,
        sidebarMenuId: SidebarMenuId.RESTAURANT
      }
    },
    {
      path: '/businesses',
      name: RouteName.BUSINESSES,
      component: Businesses, 
      beforeEnter: guards.multiGuard([guards.requireAuth, guards.requirePermission]),
      meta: {
        title: RouteTitle.BUSINESSES,
        permission: PermissionPage.MANAGE_BUSINESSES,
        sidebarMenuId: SidebarMenuId.BUSINESS
      }
    },
    {
      path: '/promotions',
      name: RouteName.PROMOTIONS,
      component: Promotions, 
      beforeEnter: guards.multiGuard([guards.requireAuth, guards.requirePermission]),
      meta: {
        title: RouteTitle.PROMOTIONS,
        permission: PermissionPage.MANAGE_PROMOTIONS,
        sidebarMenuId: SidebarMenuId.PROMOTIONS
      }
    },
    {
      path: "/voucher_request/:id",
      name: RouteName.VOUCHER_REQUEST,
      component: VoucherRequest,
      beforeEnter: guards.multiGuard([guards.requireAuth, guards.requirePermission, guards.requireValidVoucherRequest]),
      meta: {
        title: RouteTitle.VOUCHER_REQUEST,
        permission: PermissionPage.MANAGE_PROMOTIONS,
        sidebarMenuId: SidebarMenuId.PROMOTIONS
      }
    },
    {
      path: '/finance',
      name: RouteName.FINANCE,
      component: Finance, 
      beforeEnter: guards.multiGuard([guards.requireAuth, guards.requirePermission]),
      meta: {
        title: RouteTitle.FINANCE,
        permission: PermissionPage.FINANCE,
        sidebarMenuId: SidebarMenuId.FINANCE
      }
    },
    {
      path: '/finance/restaurant/:entity_id',
      name: RouteName.PAYOUT_DETAILS,
      component: PayoutDetails, 
      beforeEnter: guards.multiGuard([guards.requireAuth, guards.requirePermission, guards.requireEntity, guards.requireCurrentFinanceDetails]),
      meta: {
        permission: PermissionPage.FINANCE,
        sidebarMenuId: SidebarMenuId.FINANCE,
        entityType: EntityType.RESTAURANT,
        entityParamsKey: "entity_id",
        entityKey: "id"
      }
    },
    {
      path: '/finance/business/:entity_id',
      name: RouteName.INVOICE_DETAILS,
      component: InvoiceDetails,
      beforeEnter: guards.multiGuard([guards.requireAuth, guards.requirePermission, guards.requireEntity, guards.requireCurrentFinanceDetails]),
      meta: {
        permission: PermissionPage.FINANCE,
        sidebarMenuId: SidebarMenuId.FINANCE,
        entityType: EntityType.BUSINESS,
        entityParamsKey: "entity_id",
        entityKey: "id"
      }
    },
    {
      path: '/sales',
      name: RouteName.SALES,
      component: SalesTool, 
      beforeEnter: guards.multiGuard([guards.requireAuth, guards.requirePermission]),
      meta: {
        title: RouteTitle.SALES,
        permission: PermissionPage.SALES,
        sidebarMenuId: SidebarMenuId.SALES
      }
    },
    {
      path: '/business/:business_slug?',
      name: RouteName.BUSINESS,
      component: Business, 
      beforeEnter: guards.multiGuard([guards.requireAuth, guards.requirePermission, guards.requireEntity]),
      meta: {
        permission: PermissionPage.MANAGE_BUSINESSES,
        sidebarMenuId: SidebarMenuId.BUSINESS,
        entityType: EntityType.BUSINESS,
        entityParamsKey: "business_slug",
        entityKey: "slug"
      }
    },
    {
      path: '/business/:business_slug/menu/:menu_id',
      name: RouteName.BUSINESS_MENU,
      component: BusinessMenu,
      beforeEnter: guards.multiGuard([guards.requireAuth, guards.requirePermission, guards.requireEntity, guards.requireCurrentBusiness]),
      meta: {
        permission: PermissionPage.MANAGE_BUSINESSES,
        sidebarMenuId: SidebarMenuId.BUSINESS,
        entityType: EntityType.BUSINESS,
        entityParamsKey: "business_slug",
        entityKey: "slug"
      }
    },
    {
      path: '/restaurant/:rest_slug?',
      name: RouteName.RESTAURANT,
      component: Restaurant, 
      beforeEnter: guards.multiGuard([guards.requireAuth, guards.requireEntity, guards.requirePermission]),
      meta: {
        permission: PermissionPage.MANAGE_RESTAURANTS,
        sidebarMenuId: SidebarMenuId.RESTAURANT,
        entityType: EntityType.RESTAURANT,
        entityParamsKey: "rest_slug",
        entityKey: "id"
      }
    },
    {
      path: '/restaurant/:rest_slug/menu/:menu_id',
      name: RouteName.RESTAURANT,
      component: RestaurantMenu, 
      beforeEnter: guards.multiGuard([guards.requireAuth, guards.requirePermission, guards.requireCurrentRestaurant]),
      meta: {
        permission: PermissionPage.MANAGE_RESTAURANTS,
        sidebarMenuId: SidebarMenuId.RESTAURANT,
        entityType: EntityType.RESTAURANT,
        entityParamsKey: "rest_slug",
        entityKey: "id"
      }
    },
    {
      path: '/supplies',
      name: RouteName.SUPPLIES,
      component: Supplies, 
      beforeEnter: guards.multiGuard([guards.requireAuth, guards.requirePermission]),
      meta: {
        title: RouteTitle.SUPPLIES,
        permission: PermissionPage.SUPPLIES,
        sidebarMenuId: SidebarMenuId.SUPPLIES
      }
    },
    {
      path: '/users',
      name: RouteName.USERS,
      component: Users, 
      beforeEnter: guards.multiGuard([guards.requireAuth, guards.requirePermission]),
      meta: {
        title: RouteTitle.USERS,
        permission: PermissionPage.USERS,
        sidebarMenuId: SidebarMenuId.USERS
      }
    },
    {
      path: '/users/guest/:guest_id',
      name: RouteName.BUSINESS_GUEST,
      component: BusinessGuest, 
      beforeEnter: guards.multiGuard([guards.requireAuth, guards.requirePermission]),
      meta: {
        title: RouteTitle.USERS,
        permission: PermissionPage.USERS,
        sidebarMenuId: SidebarMenuId.USERS
      }
    },
    {
      path: '/logout',
      name: RouteName.LOGOUT,
      beforeEnter (to, from, next) {
        auth.logout()
        next('/login')
      }
    },
    {
      path: "/events",
      name: RouteName.EVENTS,
      component: Events,
      beforeEnter: guards.requireAuth,
      meta: {
        title: RouteTitle.EVENTS,
        sidebarMenuId: SidebarMenuId.EVENTS
      }
    },
    {
      path: "/event/:id",
      name: RouteName.EVENT,
      component: Event,
      beforeEnter: guards.multiGuard([guards.requireAuth, guards.requireValidEvent]),
      meta: {
        sidebarMenuId: SidebarMenuId.EVENTS
      }
    },
    {
      path: "/catering",
      name: RouteName.PREPACKAGES,
      component: Prepackages, 
      beforeEnter: guards.multiGuard([guards.requireAuth, guards.requirePermission]),
      meta: {
        title: RouteTitle.PREPACKAGES,
        permission: PermissionPage.USERS,
        sidebarMenuId: SidebarMenuId.PREPACKAGES
      }
    },
    {
      path: "/insights",
      name: RouteName.INSIGHTS,
      component: Insights, 
      beforeEnter: guards.multiGuard([guards.requireAuth, guards.requirePermissions]),
      meta: {
        title: RouteTitle.INSIGHTS,
        permissions: [PermissionPage.BUSINESS_INSIGHTS, PermissionPage.RESTAURANT_INSIGHTS],
        permissionMode: PermissionGuardMode.ONE,
        sidebarMenuId: SidebarMenuId.INSIGHTS
      }
    },
    {
      path: "/hubs",
      name: RouteName.HUBS,
      component: Hubs, 
      beforeEnter: guards.multiGuard([guards.requireAuth, guards.requirePermission]),
      meta: {
        title: RouteTitle.HUBS,
        permission: PermissionPage.HUBS,
        sidebarMenuId: SidebarMenuId.HUBS
      }
    },
    {
      path: "/hub/:hub_id",
      name: RouteName.HUBS,
      component: Hub, 
      beforeEnter: guards.multiGuard([guards.requireAuth, guards.requirePermission]),
      meta: {
        title: RouteTitle.HUBS,
        permission: PermissionPage.HUBS,
        sidebarMenuId: SidebarMenuId.HUBS
      }
    }
  ]
})

router.afterEach((to) => {
  Vue.nextTick(() => {
    let title = to.meta.title ? `2ndKitchen | ${to.meta.title}` : "2ndKitchen"
    if (!to.meta.title) {
      switch (to.name) {
        case RouteName.BUSINESS:
        case RouteName.BUSINESS_MENU: {
          const business = store.state.businesses[to.params.business_slug]
          if (business && business.name) {
            title = `2ndKitchen | ${business.name}`
          }
          break
        }
        case RouteName.RESTAURANT: {
          const restaurant = store.state.restaurants[to.params.rest_slug]
          if (restaurant && restaurant.name) {
            title = `2ndKitchen | ${restaurant.name}`
          }
          break
        }
        case RouteName.EVENT: {
          const event = store.state.eventsModule.event
          if (event && event.name) {
            title = `2ndKitchen | ${event.name}`

          }
          break
        }
        case RouteName.INVOICE_DETAILS: {
          const business = store.state.businesses[to.params.entity_id]
          if (business && business.name) {
            title = `2ndKitchen | ${business.name} Finance`
          }
          break
        }
        case RouteName.PAYOUT_DETAILS: {
          const restaurant = store.state.restaurants[to.params.entity_id]
          if (restaurant && restaurant.name) {
            title = `2ndKitchen | ${restaurant.name} Finance`
          }
          break
        }
      }
    }
    document.title = title
  })
})

router.beforeEach(guards.requireRestoredStorage)

export default router