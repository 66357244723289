import { BusinessType } from "@arikgaisler/utils/enums/businessType"
import { DeliveryMode } from "@arikgaisler/utils/enums/deliveryMode"

let deliveryModeFormatterMixin = {
	filters: {
		deliveryModeName: function (deliveryMode, businessType) {
			switch (deliveryMode) {
				case DeliveryMode.DIRECT_TO_CUSTOMER: {
					return "Direct to Customer"
				}
				case DeliveryMode.DIRECT_TO_LOCATION: {
					switch (businessType) {
						case BusinessType.HOTEL:
							return "Room Service"
						case BusinessType.COWORKING:
							return "To Suite"
						case BusinessType.RESIDENTIAL:
							return "To Apartment"
						case BusinessType.AIRPORT:
							return "Gate Delivery"
						default:
							return "Table Number"
					}
				}
				case DeliveryMode.PICKUP_AREA: {
					if (businessType !== BusinessType.AIRPORT) {
						if (businessType == BusinessType.HOTEL) {
							return "Lobby Pickup Area"
						}
					}
					return "Pickup Area"
				}
				case DeliveryMode.YAY_FOOD_POLE: {
					return "Yay Food Pole"
				}
				case DeliveryMode.LAST_YARD: {
					return "Last Yard"
				}
				case DeliveryMode.LOCKER: {
					return "Foodhall Pickup"
				}
			}
			return ""
		}
	}
}

export default deliveryModeFormatterMixin
