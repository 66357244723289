import Vue from 'vue'
import { store } from '@/store/store'
import { parseResponse } from "@arikgaisler/utils/api-utils"

let host = process.env.VUE_APP_API_HOST

const RESTAURANT_API = `https://restaurant${host}.2ndkitchen.com`
const SEARCH_API = `https://indexing${host}.2ndkitchen.com`
const SCRAPER_API = process.env.VUE_APP_SCRAPER_API

export default {
    async getItem(item_id) {
        return Vue.axios.get(`${RESTAURANT_API}/products/${item_id}`);
    },
    async getRestaurant(rest_id) {
        return Vue.axios.get(`${RESTAURANT_API}/restaurants/${rest_id}?include=opening_hours,menus,opening_hours_exceptions`);
    },
    async getRestaurantProducts(rest_id) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.get(`${RESTAURANT_API}/restaurants/${rest_id}/products?include=menu_ids`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getRestaurants() {
        const tokenStr = 'Bearer ' + store.state.user_token
        return Vue.axios.get(`${RESTAURANT_API}/restaurants?include=integrations`, { 'headers': { 'Authorization': tokenStr } });
    },
    async getRestaurantsByIds(rest_ids, includes) {
        const tokenStr = 'Bearer ' + store.state.user_token
        let url = `${RESTAURANT_API}/restaurants?ids=${rest_ids}`
        if (includes) {
            url = `${url}&include=${includes}`
        }
        return parseResponse(Vue.axios.get(url, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getRestaurantMenus(rest_id) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.get(`${RESTAURANT_API}/restaurants/${rest_id}/menus?include=payment_methods`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getMenusByIds(menu_ids, includes) {
        const tokenStr = "Bearer " + store.state.user_token
        let includesSuffix = ""
        if (includes) {
            includesSuffix = `&include=${includes}`
        }
        return parseResponse(Vue.axios.get(`${RESTAURANT_API}/menus?ids=${menu_ids}${includesSuffix}`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getMenuProducts(menu_id){
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.get(`${RESTAURANT_API}/menus/${menu_id}/products`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async addMenuProduct(menu_id, payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.post(`${RESTAURANT_API}/menus/${menu_id}/products`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async addMenu(restaurant_id,payload){
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.post(`${RESTAURANT_API}/restaurants/${restaurant_id}/menus`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async updateMenu(menu_id,payload){
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.put(`${RESTAURANT_API}/menus/${menu_id}`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async deleteMenu(menu_id){
        const tokenStr = 'Bearer ' + store.state.user_token
        return Vue.axios.delete(`${RESTAURANT_API}/menus/${menu_id}`, { 'headers': { 'Authorization': tokenStr } });
    },
    async getExternalMenus(rest_id) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.get(`${RESTAURANT_API}/restaurants/${rest_id}/menus/external`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async importExternalMenuItems(menu_id,payload){
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.post(`${RESTAURANT_API}/menus/${menu_id}/products/import`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async updateRestaurant(rest_id, payload) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.put(`${RESTAURANT_API}/restaurants/${rest_id}`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async addRestaurant(payload) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.post(`${RESTAURANT_API}/restaurants`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async deleteRestaurant(restaurant_id) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.delete(`${RESTAURANT_API}/restaurants/${restaurant_id}`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async addProduct(restaurant_id, payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.post(`${RESTAURANT_API}/restaurants/${restaurant_id}/products`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async batchAddProducts(restaurant_id, payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.post(`${RESTAURANT_API}/restaurants/${restaurant_id}/products/batch`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async batchAddProductsToMenu(menu_id, payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.post(`${RESTAURANT_API}/menus/${menu_id}/products/batch`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async updateProduct(product_id, payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.put(`${RESTAURANT_API}/products/${product_id}`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async updateProductMetaValueStock(id, payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.put(`${RESTAURANT_API}/product_meta_values/${id}/stock_status`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async updateProductMetaStock(id, payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.put(`${RESTAURANT_API}/product_metas/${id}/stock_status`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async updateProductStock(id, payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.put(`${RESTAURANT_API}/products/${id}/stock_status_override`, payload,  { 'headers': { 'Authorization': tokenStr } }))
    },
    async batchUpdateProducts(payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.put(`${RESTAURANT_API}/products/batch`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async batchDeleteProducts(productIds) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.delete(`${RESTAURANT_API}/products/batch?product_ids=${productIds}`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async updateMenuStock(id, payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.put(`${RESTAURANT_API}/menus/${id}/stock_status`, payload,  { 'headers': { 'Authorization': tokenStr } }))
    },
    async updateRestaurantStock(id, payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.put(`${RESTAURANT_API}/restaurants/${id}` + '/stock_status_override', payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async deleteMenuProduct(menu_id,product_id) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return Vue.axios.delete(`${RESTAURANT_API}/menus/${menu_id}/products/${product_id}`, { 'headers': { 'Authorization': tokenStr } });
    },
    async archiveProduct(product_id) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.post(`${RESTAURANT_API}/products/${product_id}/archive`, {}, { 'headers': { 'Authorization': tokenStr } }))
    },
    async unarchiveProduct(product_id) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.post(`${RESTAURANT_API}/products/${product_id}/unarchive`, {}, { 'headers': { 'Authorization': tokenStr } }))
    },
    async batchSetProductsStatus(payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.put(`${RESTAURANT_API}/products/status/batch`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async deleteProduct(product_id) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.delete(`${RESTAURANT_API}/products/${product_id}`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getRestaurantSupplies() {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.get(`${RESTAURANT_API}/supplies`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getRestaurantSupplyRequests() {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.get(`${SEARCH_API}/search/restaurant_supplies_requests`,{ 'headers': { 'Authorization': tokenStr } }))
    },
    async setSupplyRequestStatus(payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.put(`${RESTAURANT_API}/handle_restaurant_supply_request_action`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async requestRestaurantSupplies(supply_id,payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return Vue.axios.post(`${RESTAURANT_API}/supplies/${supply_id}/requests`, payload, { 'headers': { 'Authorization': tokenStr } });
    },
    async getRestaurantProductsByIds(productsStr) {
        return parseResponse(Vue.axios.get(`${RESTAURANT_API}/products?ids=${productsStr}`))
    },
    async autocomplete(query) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.get(`${SEARCH_API}/restaurants/autocomplete?name=${query}&fuzziness=1`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async addCategory(payload) {
        const tokenStr = "Bearer " + store.state.user_token
        const data = { 
            category: {
                name: payload.name 
            } 
        }
        return parseResponse(Vue.axios.post(`${RESTAURANT_API}/product_categories`, data, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getCategories(ids) {
        const tokenStr = "Bearer " + store.state.user_token
        let suffix = ids ? `?ids=${ids}` : ""
        return parseResponse(Vue.axios.get(`${RESTAURANT_API}/product_categories${suffix}`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getLabels(ids) {
        const tokenStr = "Bearer " + store.state.user_token
        let suffix = ids ? `?ids=${ids}` : ""
        return parseResponse(Vue.axios.get(`${RESTAURANT_API}/product_labels${suffix}`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async addRestaurantHoursException(rest_id, payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.post(`${RESTAURANT_API}/restaurants/${rest_id}/opening_hours_exceptions`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async updateRestaurantHoursException(id, payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.put(`${RESTAURANT_API}/restaurants_opening_hours_exceptions/${id}`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async deleteRestaurantHoursException(id) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return Vue.axios.delete(`${RESTAURANT_API}/restaurants_opening_hours_exceptions/${id}`, { 'headers': { 'Authorization': tokenStr } });
    },
    async getRestaurantIntegrations() {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.get(`${RESTAURANT_API}/restaurant_integration_types`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getRestaurantActiveIntegrations(rest_id) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.get(`${RESTAURANT_API}/restaurants/${rest_id}/integrations`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getRestaurantSquareLocations(integration_id) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.get(`${RESTAURANT_API}/restaurant_integrations/${integration_id}/external_locations`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async addRestaurantSquareIntegration(payload) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.post(`${RESTAURANT_API}/restaurant_integrations/square`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async addRestaurantChowlyIntegration(payload) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.post(`${RESTAURANT_API}/restaurant_integrations/chowly`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async addRestaurantUntappdIntegration(payload) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.post(`${RESTAURANT_API}/restaurant_integrations/untappd`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async addRestaurantOtterIntegration(payload) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.post(`${RESTAURANT_API}/restaurant_integrations/otter`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async addRestaurantOrdermarkIntegration(payload) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.post(`${RESTAURANT_API}/restaurant_integrations/ordermark`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async addRestaurantReefIntegration(payload) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.post(`${RESTAURANT_API}/restaurant_integrations/reef`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async addRestaurantGrubhubIntegration(payload) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.post(`${RESTAURANT_API}/restaurant_integrations/grubhub`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getGrubhubRestaurantCatalog(location_id) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.get(`${SCRAPER_API}/retrieve-catalog/${location_id}`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async updateRestaurantIntegration(integration_id, payload) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.put(`${RESTAURANT_API}/restaurant_integrations/${integration_id}`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async deleteRestaurantIntegration(integration_id) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.delete(`${RESTAURANT_API}/restaurant_integrations/${integration_id}`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async requestRestaurantIntegration(payload) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.post(`${RESTAURANT_API}/restaurant_integration_types/request`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async importIntegrationCatalog(integrationId) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.post(`${RESTAURANT_API}/restaurant_integrations/${integrationId}/sync_catalog`, {}, { 'headers': { 'Authorization': tokenStr } }))
    },
    async importIntegrationProducts(integrationId, payload) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.post(`${RESTAURANT_API}/restaurant_integrations/${integrationId}/sync_products`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    
    async requestPopup(rest_id) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.post(`${RESTAURANT_API}/restaurants/${rest_id}/request_popup`, {}, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getRestaurantBrands(restaurant_id) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.get(`${RESTAURANT_API}/restaurants/${restaurant_id}/brands`, { 'headers': { 'Authorization': tokenStr } }))
    },
}