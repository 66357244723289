<template>
	<SkBaseModal
		:isOpen="isOpen"
		:showCloseButton="false"
		@close="close"
		:hasLoader="true"
	>
		<loading :active.sync="loading" :is-full-page="false" />
		<div class="sk-modal-alt-title">
			<h1>{{ title }}</h1>
		</div>
		<CollapsibleSectionSlim
			v-for="(dropoffLocation, i) in dropoff_locations"
			:key="i"
			:index="i"
			:title="'Location'"
			:showBar="!isEdit"
		>
			<div slot="expanded">
				<div class="section-row">
					<SkDropdownSelect
						v-model="dropoffLocation.delivery_mode"
						class="sk-input"
						:options="locationTypes"
					/>
				</div>
				<div class="section-row">
					<SkInput
						ref="locationName"
						:value="dropoffLocation.text"
						:name="'Location Name'"
						v-model="dropoffLocation.text"
					/>
				</div>
				<div class="section-row">
					<SkInput
						class="optional-field"
						:value="dropoffLocation.description"
						v-model="dropoffLocation.description"
						:name="'Description'"
						:multiline="true"
					/>
				</div>
				<div class="section-row">
					<SkInput
						class="optional-field"
						:value="dropoffLocation.courier_dropoff_instructions"
						v-model="dropoffLocation.courier_dropoff_instructions"
						:name="'Courier Instructions'"
						:multiline="true"
					/>
				</div>
				<div class="section-row">
					<SkInput
						class="optional-field"
						:value="dropoffLocation.customer_pickup_instructions"
						v-model="dropoffLocation.customer_pickup_instructions"
						:name="'Customer Instructions'"
						:multiline="true"
					/>
				</div>
			</div>

			<div slot="collapsed"></div>
		</CollapsibleSectionSlim>
		<div class="sk-row" v-if="!isEdit">
			<button
				@click="addAdditionalDropoffLocation()"
				class="button button-text sk-widget-section-text-button-light"
			>
				<i class="sk-icon-plus-regular"></i>
				Add Another Location
			</button>
		</div>
		<div class="sk-modal-actions">
			<button @click="cancel" class="button button-text">Cancel</button>
			<button
				@click="addLocations"
				class="button button-primary"
				v-if="!isEdit"
			>
				Add Locations
			</button>
			<button @click="addLocations" class="button button-primary" v-else>
				Edit Location
			</button>
		</div>
	</SkBaseModal>
</template>

<style scoped>
.section-row {
	margin: 20px 0 20px 0;
}
</style>

<script>
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { DeliveryMode } from "@arikgaisler/utils/enums/deliveryMode"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"
import TypeaheadInput from "@/components/TypeaheadInput.vue"
import deliveryModeFormatterMixin from "@/mixins/business/delivery-mode-formatter-mixin"
import SkInput from "@/components/SkInput.vue"
import Loading from "vue-loading-overlay"
import { BusinessType } from "@arikgaisler/utils/enums/businessType"
import { NotificationType } from "@/enums/notificationType"
import CollapsibleSectionSlim from "@/components/CollapsibleSectionSlim.vue"

export default {
	name: "DropoffLocationModal",
	mixins: [skModalMixin, deliveryModeFormatterMixin],
	components: {
		SkBaseModal,
		SkDropdownSelect,
		TypeaheadInput,
		SkInput,
		Loading,
		CollapsibleSectionSlim
	},
	data: function () {
		return {
			loading: false,
			dropoff_locations: [
				{
					id: null,
					delivery_mode: {
						id: null,
						text: "Location Type"
					},
					text: "",
					description: "",
					courier_dropoff_instructions: "",
					customer_pickup_instructions: ""
				}
			]
		}
	},
	computed: {
		business() {
			return this.$store.state.businessesModule.currentBusiness
		},
		deliveryMods() {
			return this.$store.state.businessesModule.currentBusiness
				.delivery_modes
		},
		locationId() {
			return this.$store.state.businessesModule.currentBusiness
				.locations[0].id
		},
		isEdit() {
			if (this.dropoff_locations[0].id != null) {
				return true
			} else {
				return false
			}
		},
		title() {
			if (this.isEdit) {
				return "Edit Dropoff Location"
			} else {
				return "New Dropoff Locations"
			}
		},
		locationTypes() {
			const businessDeliveryModes = this.business.delivery_modes
				? this.business.delivery_modes
				: []
			const activeDeliveryModes = businessDeliveryModes.map(
				(deliveryMode) => deliveryMode.delivery_mode
			)

			let deliveryModes = [
				{
					id: null,
					text: "Location Type"
				}
			]

			deliveryModes.push({
				id: DeliveryMode.DIRECT_TO_LOCATION,
				text: this.$options.filters.deliveryModeName(
					DeliveryMode.DIRECT_TO_LOCATION,
					this.business.type
				)
			})

			if (this.business.type == BusinessType.BAR) {
				deliveryModes.push({
					id: DeliveryMode.YAY_FOOD_POLE,
					text: this.$options.filters.deliveryModeName(
						DeliveryMode.YAY_FOOD_POLE,
						this.business.type
					)
				})
			}

			deliveryModes = deliveryModes.concat([
				{
					id: DeliveryMode.PICKUP_AREA,
					text: this.$options.filters.deliveryModeName(
						DeliveryMode.PICKUP_AREA,
						this.business.type
					)
				},
				{
					id: DeliveryMode.DIRECT_TO_CUSTOMER,
					text: this.$options.filters.deliveryModeName(
						DeliveryMode.DIRECT_TO_CUSTOMER,
						this.business.type
					)
				},
				{
					id: DeliveryMode.LAST_YARD,
					text: this.$options.filters.deliveryModeName(
						DeliveryMode.LAST_YARD,
						this.business.type
					)
				}
			])

			deliveryModes.forEach((deliveryMode) => {
				deliveryMode.active = activeDeliveryModes.includes(
					deliveryMode.id
				)
				deliveryMode.isDefault =
					this.defaultDeliveryMode == deliveryMode.id
			})
			return deliveryModes
		}
	},
	methods: {
		addAdditionalDropoffLocation() {
			this.dropoff_locations.push({
				id: null,
				delivery_mode: {
					id: null,
					text: "Location Type"
				},
				text: "",
				description: "",
				courier_dropoff_instructions: "",
				customer_pickup_instructions: ""
			})
		},
		validate() {
			let formValid = true
			this.dropoff_locations.forEach(location => {
				if(location.delivery_mode.id == null || location.text == null){
					formValid = false
					return;
				}
			});
			return formValid
		},
		cancel() {
			this.close()
		},
		async addLocations() {
			const isValid = this.validate()
			if (!isValid) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Delivery selector and location name must be entered",
					message: `Please fill out all required fields`,
					type: NotificationType.WARNING
				})
				return
			}
			this.loading = true

			if (this.isEdit) {
				const retVal = await this.$store.dispatch(
					`businessesModule/updateDropoffLocation`,
					{
						dropoff_location_data: {
							id: this.dropoff_locations[0].id,
							delivery_mode:
								this.dropoff_locations[0].delivery_mode.id,
							text: this.dropoff_locations[0].text,
							description: this.dropoff_locations[0].description,
							courier_dropoff_instructions:
								this.dropoff_locations[0]
									.courier_dropoff_instructions,
							customer_pickup_instructions:
								this.dropoff_locations[0]
									.customer_pickup_instructions
						}
					}
				)
				if (retVal) {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: `Succesfully updated ${this.dropoff_locations[0].text}`,
						type: NotificationType.SUCCESS
					})
				} else {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Error updating location, please try again",
						type: NotificationType.WARNING
					})
				}
			} else {
				let dropoffLocations = JSON.parse(JSON.stringify(this.dropoff_locations))
				dropoffLocations.forEach((location) => {
					location.delivery_mode = location.delivery_mode.id
				})
				const added = await this.$store.dispatch(
					"businessesModule/addDropoffLocation",
					{
						business_location_id: this.locationId,
						dropoff_locations: dropoffLocations
					}
				)
				if (added) {
					this.dropoff_locations.forEach((addedLocation) => {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: `Succesfully added ${addedLocation.text}`,
							type: NotificationType.SUCCESS
						})
					})
				} else {
					this.dropoff_locations.forEach((addLocationError) => {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: `Error adding ${addLocationError.text}. Please try again.`,
							type: NotificationType.ERROR
						})
					})
				}
			}
			this.loading = false
			this.close()
		},
		handleOptions(options) {
			if (options && options.dropoffLocation) {
				this.loading = false
				this.dropoff_locations = [
					{
						id: options.dropoffLocation.id,
						delivery_mode: {
							id: options.dropoffLocation.delivery_mode,
							text: this.$options.filters.deliveryModeName(
								options.dropoffLocation.delivery_mode,
								this.business.type
							)
						},
						text: options.dropoffLocation.text,
						description: options.dropoffLocation.description,
						courier_dropoff_instructions:
							options.dropoffLocation
								.courier_dropoff_instructions,
						customer_pickup_instructions:
							options.dropoffLocation.customer_pickup_instructions
					}
				]
			} else {
				this.initDropoffLocation()
			}
		},
		initDropoffLocation() {
			this.loading = false
			this.dropoff_locations = [
				{
					delivery_mode: {
						id: null,
						text: "Location Type"
					},
					text: "",
					description: "",
					courier_dropoff_instructions: "",
					customer_pickup_instructions: ""
				}
			]
		}
	}
}
</script>