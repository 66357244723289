<template>
	<div class="order-status-cards">
		<div
			class="sk-widget"
			:class="{
				complete: !isCancelledOrder,
				danger: isCancelledOrder
			}"
		>
			<div class="status-indicator"></div>
			<div class="status-title">
				<i class="sk-icon-check-regular"></i>
				Order Placed
			</div>
			<div class="status-detail">
				<div>Order Placed</div>
				<div>{{ createdDate | formatTime(formattedTimezone) }}</div>
			</div>
			<div class="status-detail">
				<div>Customer</div>
				<div class="text-truncate" :title="name">{{ name }} {{historyDetailsGateText}}</div>
			</div>
			<div class="status-detail">
				<div>Phone</div>
				<div>{{ phone | formatInternationalPhone }}</div>
			</div>
			<div class="status-detail">
				<div>Payment</div>
				<div>{{ paymentMethod }}</div>
			</div>
			<div class="status-detail">
				<div>Placement</div>
				<div>{{ orderPlatform }}</div>
			</div>
			<div class="status-detail">
				<div>Delivery Type</div>
				<div>{{ pickupMethod }}</div>
			</div>
			<div class="status-detail">
				<div>Delivery Time</div>
				<div>{{ scheduledDate }}</div>
			</div>
		</div>
		<div
			v-if="isUpcomingOrder"
			class="sk-widget"
			:class="{
				complete: received,
				danger: (orderTimeinMins >= 5 && !received) || isCancelledOrder
			}"
		>
			<div class="status-indicator"></div>
			<div class="status-title">
				<i class="sk-icon-check-regular"></i>
				{{ receivedStatusTitle }}
			</div>
			<div class="status-detail">
				<div>Order Received</div>
				<div v-if="received">
					{{ receivedDate | formatTime(formattedTimezone) }}
				</div>
				<div v-else>--</div>
			</div>
			<div class="status-detail">
				<div>Restaurant</div>
				<router-link
					class="pill pill-grey"
					target="_blank"
					:to="`/restaurant/${restaurant.id}`"
					:title="restaurant.name"
				>
					<span class="text-truncate">{{ restaurant.name }}</span>
				</router-link>
			</div>
			<div v-if="restPhone" class="status-detail">
				<div>Phone</div>
				<div>
					<div v-for="(phone, index) in restPhone" :key="index">
						{{ phone | formatInternationalPhone }}
					</div>
				</div>
			</div>
			<div class="status-detail">
				<div>Address</div>
				<div>{{ restaurant.address }}</div>
			</div>
		</div>
		<div
			class="sk-widget"
			:class="{
				complete: accepted,
				danger: (orderTimeinMins >= 5 && !accepted) || isCancelledOrder
			}"
		>
			<div class="status-indicator"></div>
			<div class="status-title">
				<i class="sk-icon-check-regular"></i>
				{{ acceptStatusTitle }}
			</div>
			<div class="status-detail">
				<div>Order Accepted</div>
				<div v-if="accepted">
					{{ acceptedDate | formatTime(formattedTimezone) }}
				</div>
				<div v-else>--</div>
			</div>
			<div class="status-detail">
				<div>Restaurant</div>
				<router-link
					class="pill pill-grey"
					target="_blank"
					:to="`/restaurant/${restaurant.id}`"
					:title="restaurant.name"
				>
					<span class="text-truncate">{{ restaurant.name }}</span>
				</router-link>
			</div>
			<div v-if="restPhone" class="status-detail">
				<div>Phone</div>
				<div>
					<div v-for="(phone, index) in restPhone" :key="index">
						{{ phone | formatInternationalPhone }}
					</div>
				</div>
			</div>
			<div class="status-detail">
				<div>Address</div>
				<div>{{ restaurant.address }}</div>
			</div>
		</div>
		<div
			class="sk-widget"
			:class="{
				complete: acknowledged,
				danger:
					(orderTimeinMins >= 15 && !acknowledged && accepted) ||
					isCancelledOrder
			}"
		>
			<div class="status-indicator"></div>
			<div class="status-title">
				<i class="sk-icon-check-regular"></i>
				{{ acknowledgeStatusTitle }}
			</div>
			<div class="status-detail">
				<div>Order Acknowledged</div>
				<div>
					{{ acknowledgedDate | formatTime(formattedTimezone) }}
				</div>
			</div>
			<div class="status-detail">
				<div>Courier</div>
				<div>
					{{ courier }}
				</div>
			</div>
		</div>
		<div
			class="sk-widget"
			:class="{
				complete: pickedUp,
				danger:
					(orderTimeinMins >= 30 && !pickedUp && acknowledged) ||
					isCancelledOrder
			}"
		>
			<div class="status-indicator"></div>
			<div class="status-title">
				<i class="sk-icon-check-regular"></i>
				{{ pickUpStatusTitle }}
			</div>
			<div class="status-detail">
				<div>Order Picked Up</div>
				<div v-if="pickedUp">
					{{ pickedUpDate | formatTime(formattedTimezone) }}
				</div>
				<div v-else>--</div>
			</div>
			<div class="status-detail">
				<div>Courier</div>
				<div>
					{{ courier }}
				</div>
			</div>
			<div v-if="isLastYard" class="status-detail">
				<div>Last Yard</div>
				<div>{{ lastYardDeliveryStatusText }}</div>
			</div>
		</div>
		<div
			class="sk-widget"
			:class="{
				complete: delivered,
				danger:
					(orderTimeinMins >= 45 && !delivered && pickedUp) ||
					isCancelledOrder
			}"
		>
			<div class="status-indicator"></div>
			<div class="status-title">
				<i class="sk-icon-check-regular"></i>
				{{ deliveryStatusTitle }}
			</div>
			<div class="status-detail">
				<div>Order Delivered</div>
				<div v-if="delivered">
					{{ deliveredDate | formatTime(formattedTimezone) }}
				</div>
				<div v-else>--</div>
			</div>
			<div class="status-detail">
				<div>Business</div>
				<router-link
					class="pill pill-grey"
					target="_blank"
					:to="`/business/${business.slug}`"
					:title="business.name"
				>
					<span class="text-truncate">{{ business.name }}</span>
				</router-link>
			</div>
			<div class="status-detail">
				<div>Type</div>
				<div>{{ businessType }}</div>
			</div>
			<div class="status-detail">
				<div>Pickup Method</div>
				<div>{{ pickupMethod }}</div>
			</div>
			<div v-if="businessPhone" class="status-detail">
				<div>Phone</div>
				<div>
					<div v-for="(phone, index) in businessPhone" :key="index">
						{{ phone | formatInternationalPhone }}
					</div>
				</div>
			</div>
			<div class="status-detail">
				<div>Address</div>
				<div>{{ business.address }}</div>
			</div>
			<div v-if="deliveryStatusLocker" class="status-detail">
				<div>Locker Status</div>
				<div>{{ lockerDeliveryStatus }}</div>
			</div>
		</div>
	</div>
</template>

<script>
import orderStatusMixin from "@/mixins/order-status-mixin"

export default {
	name: "OrderReportStatus",
	mixins: [orderStatusMixin],
	computed: {
		business() {
			return this.$store.getters.businessesMap[this.order.business.id]
		},
		restaurant() {
			return this.$store.state.restaurants[this.item.restaurant.id]
		},
		phone() {
			return this.order.user.phone
		},
		username() {
			return this.order.user.name
		},
		lastYardDelivery() {
			return this.item && this.item.last_yard_delivery
		}
	}
}
</script>
