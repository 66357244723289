export const GETTING_ACTIVITY_FEED = "GETTING_ACTIVITY_FEED"
export const GOT_ACTIVITY_FEED = "GOT_ACTIVITY_FEED"
export const ADDED_ACTIVITY = "ADDED_ACTIVITY"
export const RESET_ACTIVITY_FEED = "RESET_ACTIVITY_FEED"

export default {
  GETTING_ACTIVITY_FEED,
  GOT_ACTIVITY_FEED,
  ADDED_ACTIVITY,
  RESET_ACTIVITY_FEED
}