<template>
	<div class="order-status-cards">
		<div
			class="sk-widget"
			:class="{
				complete: !isCancelledOrder,
				danger: isCancelledOrder
			}"
		>
			<div class="status-indicator"></div>
			<div class="status-title">
				<i class="sk-icon-check-regular"></i>
				Order Placed
			</div>
			<div class="status-detail">
				<div>Order Placed</div>
				<div>{{ createdDate | formatTime(formattedTimezone) }}</div>
			</div>
			<div class="status-detail">
				<div>Customer</div>
				<div class="text-truncate" :title="name">{{ name }} {{deliveryText}}</div>
			</div>
			<div class="status-detail">
				<div>Phone</div>
				<div>{{ phone | formatInternationalPhone }}</div>
			</div>
			<div class="status-detail">
				<div>Payment</div>
				<div>{{ paymentMethod }}</div>
			</div>
			<div class="status-detail">
				<div>Placement</div>
				<div>{{ orderPlatform }}</div>
			</div>
			<div class="status-detail">
				<div>Delivery Type</div>
				<div>{{ pickupMethod }}</div>
			</div>
			<div class="status-detail">
				<div>Delivery Time</div>
				<div>{{ scheduledDate }}</div>
			</div>
			<div class="status-action">
				<button
					class="button button-light-grey"
					@click="openTextLink(order.user_phone)"
				>
					Text Customer
					<i class="sk-icon-comment-lines-solid"></i>
				</button>
			</div>
		</div>
		<div
			v-if="isUpcomingOrder"
			class="sk-widget"
			:class="{
				complete: received,
				danger: (orderTimeinMins >= 5 && !received) || isCancelledOrder
			}"
		>
			<div class="status-indicator"></div>
			<div class="status-title">
				<i class="sk-icon-check-regular"></i>
				{{ receivedStatusTitle }}
			</div>
			<div class="status-detail">
				<div>Order Received</div>
				<div v-if="received">
					{{ receivedDate | formatTime(formattedTimezone) }}
				</div>
				<button
					v-else
					class="button button-light-grey"
					@click="markAsReceived"
				>
					<i class="sk-icon-check-regular"></i>
				</button>
			</div>
			<div class="status-detail">
				<div>Restaurant</div>
				<router-link
					class="pill pill-grey"
					target="_blank"
					:to="`/restaurant/${restaurant.id}`"
					:title="restaurant.name"
				>
					<span class="text-truncate">{{ restaurant.name }}</span>
				</router-link>
			</div>
			<div class="status-detail">
				<div>Phone</div>
				<div>
					<div
						v-for="(phone, index) in restPhone"
						:key="index"
						class="pill pill-grey"
						@click="openPhoneLink(phone)"
					>
						{{ phone | formatInternationalPhone }}
					</div>
				</div>
			</div>
			<div class="status-detail">
				<div>POS</div>
				<div>{{ posSystem }}</div>
			</div>
			<div class="status-detail">
				<div>Address</div>
				<div>{{ restaurant.address }}</div>
			</div>
		</div>
		<div
			class="sk-widget"
			:class="{
				complete: accepted,
				danger: (orderTimeinMins >= 5 && !accepted) || isCancelledOrder
			}"
		>
			<div class="status-indicator"></div>
			<div class="status-title">
				<i class="sk-icon-check-regular"></i>
				{{ acceptStatusTitle }}
			</div>
			<div class="status-detail">
				<div>Order Accepted</div>
				<div v-if="accepted">
					{{ acceptedDate | formatTime(formattedTimezone) }}
				</div>
				<div v-else>
					<button
						class="button button-light-grey"
						@click="refreshPOSOrder"
					>
						<i class="sk-icon-bell-on-solid"></i>
					</button>
					<button
						class="button button-light-grey"
						@click="markAsAccepted"
					>
						<i class="sk-icon-check-regular"></i>
					</button>
				</div>
			</div>
			<div class="status-detail">
				<div>Restaurant</div>
				<router-link
					class="pill pill-grey"
					target="_blank"
					:to="`/restaurant/${restaurant.id}`"
					:title="restaurant.name"
				>
					<span class="text-truncate">{{ restaurant.name }}</span>
				</router-link>
			</div>
			<div class="status-detail">
				<div>Phone</div>
				<div>
					<div
						v-for="(phone, index) in restPhone"
						:key="index"
						class="pill pill-grey"
						@click="openPhoneLink(phone)"
					>
						{{ phone | formatInternationalPhone }}
					</div>
				</div>
			</div>
			<div class="status-detail">
				<div>POS</div>
				<div>{{ posSystem }}</div>
			</div>
			<div class="status-detail">
				<div>Address</div>
				<div>{{ restaurant.address }}</div>
			</div>
		</div>
		<div
			class="sk-widget"
			:class="{
				complete: acknowledged,
				danger:
					(orderTimeinMins >= 15 && !acknowledged && accepted) ||
					isCancelledOrder
			}"
		>
			<div class="status-indicator"></div>
			<div class="status-title">
				<i class="sk-icon-check-regular"></i>
				{{ acknowledgeStatusTitle }}
			</div>
			<div class="status-detail">
				<div>Order Acknowledged</div>
				<div>
					{{ acknowledgedDate | formatTime(formattedTimezone) }}
				</div>
			</div>
			<div class="status-detail">
				<div>Courier</div>
				<div
					v-if="canOpenCourierLink"
					class="pill pill-grey"
					@click="openCourierLink"
				>
					{{ courier }}
				</div>
				<div v-else>
					{{ courier }}
				</div>
			</div>
			<div class="status-action" v-if="showContactCourier">
				<button
					class="button button-light-grey"
					@click="contactCourier"
				>
					Contact Courier
					<i class="sk-icon-phone-solid"></i>
				</button>
			</div>
		</div>
		<div
			class="sk-widget"
			:class="{
				complete: pickedUp,
				danger:
					(orderTimeinMins >= 30 && !pickedUp && acknowledged) ||
					isCancelledOrder
			}"
		>
			<div class="status-indicator"></div>
			<div class="status-title">
				<i class="sk-icon-check-regular"></i>
				{{ pickUpStatusTitle }}
			</div>
			<div class="status-detail">
				<div>Order Picked Up</div>
				<div v-if="pickedUp">
					{{ pickedUpDate | formatTime(formattedTimezone) }}
				</div>
				<button
					v-else
					class="button button-light-grey"
					@click="markAsPickedUp"
				>
					<i class="sk-icon-check-regular"></i>
				</button>
			</div>
			<div class="status-detail">
				<div>Courier</div>
				<div
					v-if="canOpenCourierLink"
					class="pill pill-grey"
					@click="openCourierLink"
				>
					{{ courier }}
				</div>
				<div v-else>
					{{ courier }}
				</div>
			</div>
			<div v-if="isLastYard" class="status-detail">
				<div>Last Yard</div>
				<div>{{ lastYardDeliveryStatusText }}</div>
			</div>
			<div class="status-action" v-if="showTrackCourier">
				<button class="button button-light-grey" @click="trackOrder">
					Track Courier
					<i class="sk-icon-location-solid"></i>
				</button>
			</div>
		</div>
		<div
			class="sk-widget"
			:class="{
				complete: delivered,
				danger:
					(orderTimeinMins >= 45 && !delivered && pickedUp) ||
					isCancelledOrder
			}"
		>
			<div class="status-indicator"></div>
			<div class="status-title">
				<i class="sk-icon-check-regular"></i>
				{{ deliveryStatusTitle }}
			</div>
			<div class="status-detail">
				<div>Order Delivered</div>
				<div v-if="delivered">
					{{ deliveredDate | formatTime(formattedTimezone) }}
				</div>
				<button
					v-else
					class="button button-light-grey"
					@click="markAsDelivered"
				>
					<i class="sk-icon-check-regular"></i>
				</button>
			</div>
			<div class="status-detail">
				<div>Business</div>
				<router-link
					class="pill pill-grey"
					target="_blank"
					:to="`/business/${business.slug}`"
					:title="business.name"
				>
					<span class="text-truncate">{{ business.name }}</span>
				</router-link>
			</div>
			<div class="status-detail">
				<div>Type</div>
				<div>{{ businessType }}</div>
			</div>
			<div class="status-detail">
				<div>Pickup Method</div>
				<div>{{ pickupMethod }}</div>
			</div>
			<div class="status-detail">
				<div>Phone</div>
				<div>
					<div
						v-for="(phone, index) in businessPhone"
						:key="index"
						class="pill pill-grey"
						@click="openPhoneLink(phone)"
					>
						{{ phone | formatInternationalPhone }}
					</div>
				</div>
			</div>
			<div class="status-detail">
				<div>Address</div>
				<div>{{ business.address }}</div>
			</div>
			<div v-if="deliveryStatusLocker" class="status-detail">
				<div>Locker Status</div>
				<div>{{ lockerDeliveryStatus }}</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.status-detail .pill + .pill {
	margin-left: 0;
	margin-top: 5px;
}
</style>

<script>
import { CourierId } from "@arikgaisler/utils/enums/courierId"
import { NotificationType } from "@/enums/notificationType"
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { OrderStatus } from "@arikgaisler/utils/enums/orderStatus"
import utils from "@/utils/utils"
import delivery_service from "@/services/delivery"
import loaderMixin from "@/mixins/loader-mixin"
import orderStatusMixin from "@/mixins/order-status-mixin"

export default {
	name: "CurrentOrderStatus",
	mixins: [loaderMixin, orderStatusMixin],
	computed: {
		canOpenCourierLink() {
			return this.item.courier_id == CourierId.DOORDASH
		},
		delivery() {
			const deliveries =
				this.$store.state.ordersModule.currentOrderDeliveries
			return deliveries
				? deliveries.find(
						(delivery) => delivery.id == this.item.delivery_job_id
				  )
				: null
		},
		lastYardDelivery() {
			return this.delivery && this.delivery.last_yard_delivery
		}
	},
	methods: {
		markAsReceived() {
			this.toggleLoader(true)
			this.$store
				.dispatch("ordersModule/updateOrderStatus", {
					orderId: this.item.id,
					status: OrderStatus.RECEIVED,
					time: 60
				})
				.then((success) => {
					if (success) {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Order status updated!"
						})
					} else {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Failed to update order status",
							type: NotificationType.ERROR
						})
					}
				})
				.catch(() => {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Failed to update order status",
						type: NotificationType.ERROR
					})
				})
				.finally(() => {
					this.toggleLoader(false)
				})
		},
		markAsAccepted() {
			this.toggleLoader(true)
			this.$store
				.dispatch("ordersModule/updateOrderStatus", {
					orderId: this.item.id,
					status: OrderStatus.PROCESSING
				})
				.then((success) => {
					if (success) {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Order marked as accepted"
						})
					} else {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Failed to update order status",
							type: NotificationType.ERROR
						})
					}
				})
				.catch(() => {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Failed to update order status",
						type: NotificationType.ERROR
					})
				})
				.finally(() => {
					this.toggleLoader(false)
				})
		},
		markAsPickedUp() {
			if (this.item.courier_id == CourierId.WALKING) {
				this.markAsDelivered()
			} else {
				this.toggleLoader(true)
				this.$store
					.dispatch("ordersModule/updateDeliveryStatus", {
						orderId: this.item.id,
						status: 3
					})
					.then((success) => {
						if (success) {
							eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
								title: "Delivery status updated"
							})
						} else {
							eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
								title: "Failed to update order status",
								type: NotificationType.ERROR
							})
						}
					})
					.catch(() => {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Failed to update order status",
							type: NotificationType.ERROR
						})
					})
					.finally(() => {
						this.toggleLoader(false)
					})
			}
		},
		markAsDelivered() {
			if (this.item.courier_id == CourierId.WALKING) {
				this.toggleLoader(true)
				this.$store
					.dispatch("ordersModule/updateOrderStatus", {
						orderId: this.item.id,
						status: OrderStatus.COMPLETED
					})
					.then((success) => {
						if (success) {
							eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
								title: "Order marked as delivered"
							})
						} else {
							eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
								title: "Failed to update order status",
								type: NotificationType.ERROR
							})
						}
					})
					.catch(() => {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Failed to update order status",
							type: NotificationType.ERROR
						})
					})
					.finally(() => {
						this.toggleLoader(false)
					})
			} else {
				this.toggleLoader(true)
				this.$store
					.dispatch("ordersModule/updateDeliveryStatus", {
						orderId: this.item.id,
						status: 4
					})
					.then((success) => {
						if (success) {
							eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
								title: "Order marked as delivered"
							})
						} else {
							eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
								title: "Failed to update order status",
								type: NotificationType.ERROR
							})
						}
					})
					.catch(() => {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Failed to update order status",
							type: NotificationType.ERROR
						})
					})
					.finally(() => {
						this.toggleLoader(false)
					})
			}
		},
		refreshPOSOrder() {
			this.toggleLoader(true)
			this.$store
				.dispatch("ordersModule/refreshPOSOrder", this.item.id)
				.finally(() => {
					this.toggleLoader(false)
				})
		},
		openCourierLink() {
			const courier_id = this.item.delivery_job_id
			const delivery_url = `https://www.doordash.com/drive/portal/order/${courier_id}`
			window.open(delivery_url, "_blank")
		},
		openTextLink(phone) {
			if (phone == undefined) {
				phone = this.order.user_phone
			}
			var title = "Text Customer",
				w = 300,
				h = 500
			var _options = "scrollbars=no,resizable=no"
			const link =
				"https://www.avochato.com/accounts/2ndkitchen/tickets/?archived=on&order=&search=phone:" +
				phone +
				"&status=&unanswered=off&unread=off&user_id="
			var dualScreenLeft =
				window.screenLeft != undefined ? window.screenLeft : screen.left
			var dualScreenTop =
				window.screenTop != undefined ? window.screenTop : screen.top
			var width = window.innerWidth
				? window.innerWidth
				: document.documentElement.clientWidth
				? document.documentElement.clientWidth
				: screen.width
			var height = window.innerHeight
				? window.innerHeight
				: document.documentElement.clientHeight
				? document.documentElement.clientHeight
				: screen.height

			var _left = width / 2 - w / 2 + dualScreenLeft
			var _top = height / 2 - h / 2 + dualScreenTop
			window.open(
				link,
				title,
				_options +
					", width=" +
					w +
					", height=" +
					h +
					", top=" +
					_top +
					", left=" +
					_left
			)
			return false
		},
		openPhoneLink(phone) {
			if (phone.includes("+")) {
				return utils.openPhoneLink(phone)
			} else {
				return utils.openPhoneLink(phone, "+1")
			}
		},
		trackOrder() {
			if (
				this.item.delivery_job_id != "" &&
				this.item.delivery_job_id != null &&
				this.item.delivery_job_id != undefined
			) {
				delivery_service
					.getDelivery(this.item.delivery_job_id)
					.then((response) => {
						if (response.status == 200) {
							var url = ""
							let courier_id = response.data.delivery.courier_id
							let ext_courier_id =
								response.data.delivery.external_id
							if (courier_id == 6) {
								url =
									"https://www.doordash.com/drive/portal/order/" +
									ext_courier_id
							} else if (courier_id == 5) {
								url =
									"https://postmates.com/track/" +
									ext_courier_id
							} else if (courier_id == 1) {
								url =
									"https://twinjet.co/atc/details/" +
									ext_courier_id
							}
							if (url != "") {
								window.open(url, "_blank")
							}
						}
						return false
					})
					.catch(() => {
						return false
					})
			}
		},
		contactCourier: function () {
			if (
				this.item.delivery_job_id != "" &&
				this.item.delivery_job_id != null &&
				this.item.delivery_job_id != undefined
			) {
				delivery_service
					.getDelivery(this.item.delivery_job_id)
					.then((response) => {
						const phone = this.$options.filters.internationalPhone(
							response.data.delivery.courier_phone_number,
							"+1"
						)
						if (phone != undefined && phone.length > 0) {
							this.openTextLink(phone)
						}
					})
					.catch(() => {
						return false
					})
			}
		}
	}
}
</script>
