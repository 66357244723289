import actions from "./actions"
import mutations from "./mutations"

const state = {
    filters: {
        businessId: null,
        restaurantId: null,
        startDate: null,
        endDate: null
    },
    totalSales: {
        loading: false,
        data: null
    },
    totalOrders: {
        loading: false,
        data: null
    },
    averageTicketPriceByDow: {
        loading: false,
        data: null
    },
    topOrdersByDow: {
        loading: false,
        data: null
    },
    topOrdersByHour: {
        loading: false,
        data: null
    },
    averageRating: {
        loading: false,
        data: null
    },
    averageAccuracy: {
        loading: false,
        data: null
    },
    topExceptions: {
        loading: false,
        data: null
    },
    missingItemsOrders: {
        loading: false,
        data: null
    },
    incorrectItemsOrders: {
        loading: false,
        data: null
    },
    restaurantClosedOrders: {
        loading: false,
        data: null
    },
    averageAcceptTime: {
        loading: false,
        data: null
    },
    averagePreparationTime: {
        loading: false,
        data: null
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
