<template>
	<div>
		<div
			class="sk-row-inner sk-widget"
			v-for="(warning, index) in formattedWarnings"
			:key="index"
		>
			<i class="warning-icon" :class="warning.icon"></i>
			<div class="warning-title">
				{{ warning.title }}:
				<span>{{ warning.text }}</span>
			</div>
			<button
				v-if="warning.action"
				class="button button-light-grey warning-action"
				@click="actionClicked(warning)"
			>
				{{ warning.action.text }}
				<i :class="warning.action.icon"></i>
			</button>
		</div>
	</div>
</template>

<style scoped>
.sk-row-inner {
	margin-left: 0;
	margin-right: 0;
	width: 100%;
	align-items: center;
}

.warning-title,
.warning-title span {
	font-size: 12px;
}
.warning-title {
	color: var(--sk-grey2);
}

.warning-title span {
	color: var(--sk-grey3);
}

.warning-icon {
	font-size: 16px;
	align-self: center;
	margin-right: 20px;
	color: var(--sk-red);
}

.warning-action {
	margin-left: auto;
	font-size: 10px;
	min-height: 30px;
}

.warning-action i {
	margin-left: 10px;
	margin-right: 0 !important;
}
</style>

<script>
import { OrderWarningType } from "@/enums/orderWarningType"
import utils from "@/utils/utils"

export default {
	name: "OrderWarnings",
	props: {
		warnings: {
			type: Array
		}
	},
	computed: {
		formattedWarnings() {
			let warnings = this.warnings ? this.warnings : []
			return warnings.map(warning => {
				return {
					title: this.titleForWarning(warning.type),
					text: warning.text,
					icon: this.iconForWarning(warning.type),
					action: this.actionForWarning(warning.type),
					type: warning.type,
					params: warning.extra_params ? warning.extra_params : {}
				}
			})
		}
	},
	methods: {
		titleForWarning(type) {
			switch (type) {
				case OrderWarningType.POS_DEVICE_UNHEALTHY:
					return "Tablet Unhealthy"
				case OrderWarningType.DELIVERY_NOT_PICKUP_UP:
					return "Delivery Not Picked up"
				case OrderWarningType.DELIVERY_WAS_CANCELLED:
					return "Delivery Cancelled"
				case OrderWarningType.RESTAURANT_NEAR_CLOSED:
					return "Restaurant Closed"
				case OrderWarningType.LARGE_BASKET_SIZE:
					return "Large Basket Size"
				case OrderWarningType.POS_FAILED:
					return "Failed to send order to POS"
			}
		},
		iconForWarning(type) {
			switch (type) {
				case OrderWarningType.POS_DEVICE_UNHEALTHY:
					return "sk-icon-battery-quarter-regular"
				case OrderWarningType.DELIVERY_NOT_PICKUP_UP:
					return "sk-icon-clock-regular-1"
				case OrderWarningType.DELIVERY_WAS_CANCELLED:
					return "sk-icon-times-regular"
				case OrderWarningType.RESTAURANT_NEAR_CLOSED:
					return "sk-icon-stopwatch-regular"
				case OrderWarningType.LARGE_BASKET_SIZE:
					return "sk-icon-dollar-sign-regular"
				case OrderWarningType.POS_FAILED:
					return "sk-icon-share-solid"
			}
		},
		actionForWarning(type) {
			switch (type) {
				case OrderWarningType.POS_DEVICE_UNHEALTHY:
				case OrderWarningType.RESTAURANT_NEAR_CLOSED:
				case OrderWarningType.POS_FAILED: {
					return {
						text: "Call Restaurant",
						icon: "sk-icon-phone-solid"
					}
				}
			}
			return null
		},
		actionClicked(warning) {
			switch (warning.type) {
				case OrderWarningType.POS_DEVICE_UNHEALTHY:
				case OrderWarningType.RESTAURANT_NEAR_CLOSED:
				case OrderWarningType.POS_FAILED: {
					const restaurant = this.$store.state.restaurants[warning.params.restaurant_id]
					const phone = restaurant.phone_number.split(";")[0]
					utils.openPhoneLink(phone)
				}
			}
		}
	}
}
</script>