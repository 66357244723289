export default {
	exceptionTypeMap: (state) => {
		return state.exception_types ? state.exception_types.reduce((reasons, reason) => {
			reasons[reason.id] = reason.text
			return reasons
		}, {}) : {}
	},
	refundTypeMap: (state, getters, rootState) => {
		return rootState.refund_reasons ? rootState.refund_reasons.reduce((reasons, reason) => {
			reasons[reason.id] = reason.text
			return reasons
		}, {}) : {}
	},
}