
<template>
	<div class="sk-widget-alt login-widget">
		<img src="/images/2k-logo-login.png" alt="2ndKitchen Logo" />
		<div class="sk-row">
			<SkInput
				ref="emailInput"
				v-model="email"
				:name="'Email'"
				:placeholder="'Email'"
				:required="true"
				:tabindex="1"
			/>
		</div>
		<SkPasswordInput
			class="sk-row"
			ref="passwordInput"
			v-model="password"
			@onEnter="loginClicked('enter')"
			:tabindex="2"
		/>
		<div class="sk-row">
			<button
				@click="forgotPasswordClicked"
				class="button button-link forgot-password-button"
			>
				Forgot Password
			</button>
		</div>
		<div class="sk-row">
			<button
				@click="loginClicked('button')"
				class="button button-primary login-button"
			>
				Sign In
			</button>
		</div>
		<SignupWidgetFooter :links="footerLinks" @linkClicked="linkClicked" />
	</div>
</template>

<style scoped>
.login-widget {
	width: 500px;
	max-width: 500px;
	height: auto;
	padding: 0;
}

.login-widget img {
	height: 40px;
	margin: 60px auto;
	display: block;
}

.forgot-password-button {
	min-height: 15px;
	font-size: 12px;
	padding: 0;
	flex: 0 0 auto !important;
	margin-left: auto;
	color: var(--sk-grey2);
}

.login-button {
	min-width: 140px;
	flex: 0 0 auto !important;
	margin: 10px auto 0 auto;
}
</style>

<script>
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import tracker from "@/utils/tracker"
import auth from "@/utils/auth"
import SkInput from "@/components/SkInput.vue"
import SkPasswordInput from "@/components/SkPasswordInput.vue"
import SignupWidgetFooter from "@/components/login/SignupWidgetFooter.vue"

export default {
	name: "LoginWidget",
	components: {
		SkInput,
		SkPasswordInput,
		SignupWidgetFooter
	},
	data() {
		return {
			email: "",
			password: "",
			footerLinks: [
				{
					id: 0,
					text: `Not a 2ndKitchen Partner? <span class="signup-link">Get Started!</span>`
				},
				{
					id: 1,
					text: `First time? <span class="signup-link">Activate your account!</span>`
				}
			]
		}
	},
	methods: {
		validate() {
			const emailValid = this.$refs.emailInput.validate()
			const passwordValid = this.$refs.passwordInput.validate()
			return emailValid && passwordValid
		},
		loginClicked(context) {
			tracker.trackEvent("Login Clicked", {
				context: context
			})
			const isValid = this.validate()
			if (!isValid) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Chef Login",
					message: `Email and password is required`,
					type: NotificationType.WARNING
				})
				return false
			}
			this.error = false
			auth.login(this.email, this.password).then((response) => {
				if (!response.success) {
					let errorMessage = "Something went wrong, please try again"

					if (response.code) {
						switch (response.code) {
							case "admin_user_wrong_credentials_error":
								errorMessage =
									"Password is incorrect, please try again"
						}
					}

					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Chef Login",
						message: errorMessage,
						type: NotificationType.ERROR
					})
				}
			})
		},
		forgotPasswordClicked() {
			tracker.trackEvent("Forgot Password Clicked")
			this.$emit("forgotPassword")
		},
		linkClicked(link) {
			switch (link.id) {
				case 0: {
					tracker.trackEvent("Signup Clicked")
					window.open("https://2ndkitchen.com/")
					break
				}
				case 1: {
					tracker.trackEvent("Activate Clicked")
					this.$emit("activateAccount")
					break
				}
			}
		}
	}
}
</script>