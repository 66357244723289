<template>
	<SkBaseModal
		class="sk-modal-alt vld-parent"
		:isOpen="isOpen"
		@close="close"
	>
		<div class="sk-modal-alt-title">
			<h1>Send Voucher to {{ businessGuest.name }}</h1>
		</div>

		<div class="sk-row">
			<div class="sk-row">
				<SkInput
					v-model="voucherRequest.amount"
					ref="amountInput"
					:name="'Voucher Value'"
					:placeholder="'Voucher Value'"
					:required="true"
					:type="'number'"
					:group="'$'"
				/>
			</div>
		</div>
		<div class="sk-row">
			<SkInput
				v-model="voucherRequest.quantity"
				ref="quantityInput"
				:type="'number'"
				:name="'Number of Vouchers'"
				:required="true"
			/>
		</div>
		<div class="sk-row">
			<VueCtkDateTimePicker
				v-model="voucherRequestEndDate"
				:label="'Valid Until'"
				:no-clear-button="true"
				:no-label="true"
				:no-button-now="true"
				:only-date="true"
				:button-color="'#90DA36'"
				:format="'MM-DD-YYYY'"
				:formatted="'ll'"
				:color="'#2E3C49'"
				:output-format="'ddd MMM DD, YYYY'"
				:no-value-to-custom-elem="true"
			>
				<SkInput
					:value="voucherRequestEndDate"
					:disabled="true"
					:name="'Valid Until (Optional)'"
				/>
			</VueCtkDateTimePicker>
		</div>

		<div class="sk-modal-actions">
			<button @click="close" class="button button-text">Close</button>
			<button
				type="button"
				class="button button-success"
				@click="sendBusinessGuestVoucher"
			>
				<i class="sk-icon-check-regular"></i>
				Send Voucher
			</button>
		</div>
	</SkBaseModal>
</template>

<script>
import Vue from "vue"
import utils from "@/utils/utils"
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import SkInput from "@/components/SkInput.vue"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import ActionTypes from "@/store/modules/users/action-types"
import VueCtkDateTimePicker from "vue-ctk-date-time-picker"
import moment from "moment"
import "moment-timezone"


export default {
	name: "BusinessGuestVoucherModal",
	mixins: [skModalMixin],
	components: {
		SkBaseModal,
		SkInput,
		VueCtkDateTimePicker
	},
	data() {
		return {
			businessGuest: {},
			voucherRequest: {},
			amount: null,
			quantity: null,
			validUntil: null
		}
	},
	computed: {
		business() {
			return this.$store.getters.businessesMap[
				this.businessGuest.business_id
			]
		},
		voucherRequestEndDate: {
			get: function () {
				if (this.voucherRequest.endDate) {
					let voucherRequestEndDate = moment(
						this.voucherRequest.endDate,
						"ddd MMM DD, YYYY"
					)
					return voucherRequestEndDate.format("ddd MMM DD, YYYY")
				} else {
					return null
				}
			},
			set: function (date) {
				this.voucherRequest.endDate = date
			}
		},
	},
	methods: {
		validateBusinessGuestVoucherData() {
			const validAmount = this.$refs.amountInput.validate()
			const validQuantity = this.$refs.quantityInput.validate()
			if (!validAmount) {
				throw "Please enter a valid amount"
			}
			if (!validQuantity) {
				throw "Please enter a valid quantity"
			}
			return true
		},
		async sendBusinessGuestVoucher() {
			try {
				this.validateBusinessGuestVoucherData()
				if (this.businessGuest.id) {
					if (this.voucherRequest.endDate) {
						let tempEndDateMoment = moment.tz(
							this.voucherRequest.endDate,
							"ddd MMM DD, YYYY",
							this.businessTimezone
						)
						this.voucherRequest.endDate = tempEndDateMoment
							.utc()
							.format("YYYY-MM-DD HH:mm:ss")
					} else {
						this.voucherRequest.endDate = null
					}
					const payload = {
						businessGuestId: this.businessGuest.id,
						amount: this.voucherRequest.amount,
						quantity: this.voucherRequest.quantity,
						endDate: this.voucherRequest.endDate
					}
					const resp = await this.$store.dispatch(
						`usersModule/${ActionTypes.SEND_BUSINESS_GUEST_VOUCHER}`,
						payload
					)
					if (resp) {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: `Successfully sent voucher to ${this.businessGuest.name}`
						})
						this.close()
					} else {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Something went wrong, please try again",
							type: NotificationType.WARNING
						})
					}
				}
			} catch (e) {
				this.bugsnag.notify("Unable to save guest", (event) => {
					event.addMetadata("error", {
						error: e
					})
				})
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title:
						e && !e.message
							? e
							: "Something went wrong, please try again",
					type: NotificationType.WARNING
				})
			}
		},
		close() {
			this.isOpen = false
			this.closed()
		},
		handleOptions: function (payload) {
			this.businessGuest = utils.clonedeep(payload.businessGuest)
			Vue.set(this.voucherRequest, "amount", null)
			Vue.set(this.voucherRequest, "quantity", null)
			Vue.set(this.voucherRequest, "endDate", null)
		},
	},
}
</script>