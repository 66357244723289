<template>
	<div class="dashboard-empty-section">
		<h3>{{ title }}</h3>
		<div class="empty-message">
			{{ message }}
		</div>
		<router-link :to="link">{{ linkText }}</router-link>
	</div>
</template>


<style scoped>
.dashboard-empty-section {
	text-align: center;
	margin-top: 167px;
}

.dashboard-empty-section h3 {
	margin-bottom: 20px;
	font-size: 14px;
	color: var(--sk-dark-navy);
}

.dashboard-empty-section .empty-message {
	font-size: 12px;
	margin-bottom: 20px;
	color: var(--sk-grey2);
}

.dashboard-empty-section a {
	color: var(--sk-orange);
	font-size: 10px;
	text-decoration: none;
}
</style>

<script>
export default {
	name: "DashboardEmptySection",
	props: {
		title: String,
		message: String,
		link: String,
		linkText: String
	}
}
</script>