import MutationTypes from "./mutation-types"

export default {
	[MutationTypes.GETTING_ACTIVITY_FEED](state) {
		state.fetching = true
	},
	[MutationTypes.GOT_ACTIVITY_FEED](state, newFeed) {
		if (newFeed && newFeed.activities) {
			if (state.offset == 0) {
				state.activities = newFeed.activities ? newFeed.activities : []
				state.offset = state.limit
			} else if (state.activities && state.activities.length > 0) {
				state.activities = state.activities.concat(newFeed.activities)
				state.offset += state.limit
			}
			state.total = newFeed.activity_count ? newFeed.activity_count : 0
			state.totalRead = newFeed.read_activity_count ? newFeed.read_activity_count : 0
			state.totalNew = state.total - state.totalRead
			state.hasMore = newFeed.activities.length >= 20
		} else {
			state.activities = []
			state.offset = 0
			state.total = 0
			state.totalRead = 0
			state.totalNew = 0
			state.hasMore = true
		}
		state.fetching = false
	},
	[MutationTypes.ADDED_ACTIVITY](state, activity) {
		let activityFeedIds = state.activities.map(activity => activity.id)
		if (!activityFeedIds.includes(activity.id)) {
			state.activities.unshift(activity)
			state.total += 1
			state.totalNew += 1
			state.offset += 1
		}
	},
	[MutationTypes.RESET_ACTIVITY_FEED](state) {
		state.activities = []
		state.offset = 0
		state.total = 0
		state.totalRead = 0
		state.totalNew = 0
		state.hasMore = true
		state.fetching = false
		state.limit = 20
	}
}