import MutationTypes from "./mutation-types"

export default {
	[MutationTypes.GOT_PREPACKAGED_ORDERS](state, orders) {
		state.prepackagedOrders = orders
	},
	[MutationTypes.GOT_WEEKLY_PREPACKAGED_ORDERS](state, orders) {
		state.prepackagedOrders = orders
	},
	[MutationTypes.GOT_PREPACKAGED_PRODUCTS](state, payload) {
		const restaurantProducts = payload.restaurantProducts.reduce((products, product) => {
			products[product.id] = product
			return products
		}, {})
		state.prepackagedMenu = payload.menu
		state.prepackagedProducts = payload.businessProducts
			.filter(product => restaurantProducts[product.id] !== undefined)
			.map(product => {
				return Object.assign(
					restaurantProducts[product.id],
					product,
					{
						name: product.name ? product.name : restaurantProducts[product.id].name,
						price: product.price ? product.price : restaurantProducts[product.id].price,
						category_id: restaurantProducts[product.id].category_id
					})
			})
		state.prepackagedRestaurantHourExceptions = payload.restaurantHourExceptions
	},
	[MutationTypes.CLEAR_PREPACKAGED_PRODUCTS](state) {
		state.prepackagedProducts = []
		state.prepackagedMenu = null
		state.prepackagedRestaurantHourExceptions = []
	},
	[MutationTypes.ADDED_PREPACKAGED_ORDERS](state, orders) {
		state.prepackagedOrders = orders.concat(state.prepackagedOrders)
	},
	[MutationTypes.UPDATED_PREPACKAGED_ORDER](state, updatedOrder) {
		const index = state.prepackagedOrders.findIndex(order => order.order_id == updatedOrder.order_id)
		if (index > -1) {
			state.prepackagedOrders.splice(index, 1, updatedOrder)
		} else {
			state.prepackagedOrders.unshift(updatedOrder)	
		}
	},
	[MutationTypes.CANCELLED_PREPACKAGED_ORDER](state, cancelledOrder) {
		const index = state.prepackagedOrders.findIndex(order => order.order_id == cancelledOrder.order_id)
		if (index) {
			state.prepackagedOrders.splice(index, 1)
		}
	}
}