import moment from "moment"
import { BusinessType } from "@arikgaisler/utils/enums/businessType"
import { CourierId } from "@arikgaisler/utils/enums/courierId"

let activeOrderMixin = {
	computed: {
		suborder() {
			return this.order.sub_orders.length > 0
				? this.order.sub_orders[0]
				: null
		},
		business() {
			if (this.order && this.order.business_id) {
				return this.$store.getters.businessesMap[this.order.business_id]
			}
			if (this.order && this.order.business) {
				return this.$store.getters.businessesMap[this.order.business.id]
			}
			return null
		},
		businessName() {
			return this.business ? this.business.name : ""
		},
		showBusinessName() {
			return this.$store.getters.isRestaurantAdmin
		},
		restaurant() {
			if (this.order && this.item && this.item.rest_id) {
				return this.$store.state.restaurants[this.item.rest_id]
			}
			if (this.order && this.item && this.item.restaurant) {
				return this.$store.state.restaurants[this.item.restaurant.id]
			}
			return null
		},
		timezone() {
			if (this.business && this.business.timezone) {
				return this.business.timezone
			}
			if (this.restaurant && this.restaurant.timezone) {
				return this.restaurant.timezone
			}
			return null
		},
		tablePrefix() {
			if (this.business && this.business.type != undefined) {
				switch (this.business.type) {
					case BusinessType.HOTEL: {
						return "Room"
					}
					case BusinessType.COWORKING: {
						return "Suite"
					}
					case BusinessType.AIRPORT: {
						return "Gate"
					}
				}
			}
			return "Table"
		},
		tableNumber() {
			if (this.order && this.hasTable) {
				return `${this.tablePrefix} ${this.order.table}`
			}
			return ""
		},
		accepted() {
			if (!this.order) {
				return false
			}
			const allSubordersAccepted = this.order.sub_orders.every(
				(suborder) => {
					return suborder.accept_time && suborder.accept_time != ""
				}
			)
			return this.order.sub_orders.length > 0 && allSubordersAccepted
		},
		pickedUp() {
			if (!this.order) {
				return false
			}
			const allSubordersPickedUp = this.order.sub_orders.every(
				(suborder) => {
					if (suborder.courier_id == CourierId.WALKING) {
						return (
							suborder.completed_time &&
							suborder.completed_time != ""
						)
					} else {
						return (
							suborder.pickup_time && suborder.pickup_time != ""
						)
					}
				}
			)
			return this.order.sub_orders.length > 0 && allSubordersPickedUp
		},
		deliveredSuborder() {
			if (!this.order) {
				return null
			}
			const deliveredSuborder = this.order.sub_orders.find((suborder) => {
				if (suborder.courier_id == CourierId.WALKING) {
					return (
						suborder.completed_time && suborder.completed_time != ""
					)
				} else {
					return (
						suborder.delivered_time && suborder.delivered_time != ""
					)
				}
			})
			return this.order.sub_orders.length > 0 ? deliveredSuborder : null
		},
		delivered() {
			return this.deliveredSuborder
		},
		deliveredTime() {
			if (!this.deliveredSuborder) {
				return ""
			}
			if (
				this.deliveredSuborder &&
				this.deliveredSuborder.courier_id == CourierId.WALKING
			) {
				return this.deliveredSuborder.completed_time
			} else {
				return this.deliveredSuborder.delivered_time
			}
		},
		pickedUpTime() {
			if (!this.pickedUp) {
				return ""
			}
			if (this.suborder.courier_id == CourierId.WALKING) {
				return this.suborder.completed_time
			} else {
				return this.suborder.pickup_time
			}
		},
		formattedTimezone() {
			if (this.timezone) {
				return moment().tz(this.timezone).format("z")
			}
			return ""
		}
	}
}

export default activeOrderMixin
