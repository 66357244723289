<template>
	<SkBaseModal
		:isOpen="isOpen"
		:hasLoader="true"
		@close="cancel"
		class="sk-modal-alt"
	>
		<loading :active.sync="loading" :is-full-page="false" />
		<div class="sk-modal-alt-title">
			<h1>Add New Menu</h1>
		</div>
		<div class="sk-row">
			<TypeaheadInput
				ref="restaurantAutocomplete"
				:placeholder="'Restaurant'"
				:fetch="autocompleteRestaurants"
				:serializer="typeaheadSerializer"
				@selected="restaurantSelected"
			/>
		</div>
		<div class="sk-row">
			<SkDropdownMultiSelect
				v-model="menuIds"
				:options="menuOptions"
				:class="{ disabled: !restaurant }"
			/>
		</div>
		<div class="sk-modal-actions">
			<button @click="cancel" class="button button-text">Cancel</button>
			<button
				type="button"
				class="button button-primary"
				@click="addMenus"
			>
				<i class="sk-icon-check-regular"></i>
				Add New Menus
			</button>
		</div>
	</SkBaseModal>
</template>

<script>
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import ActionTypes from "@/store/modules/hubs/action-types"
import restaurant_service from "@/services/restaurants"
import skPromiseModalMixin from "@/mixins/modals/sk-promise-modal-mixin"
import pluralizeFilterMixin from "@/mixins/pluralize-filter-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import SkDropdownMultiSelect from "@/components/SkDropdownMultiSelect.vue"
import TypeaheadInput from "@/components/TypeaheadInput.vue"
import Loading from "vue-loading-overlay"

export default {
	name: "AddHubRestaurantMenuModal",
	mixins: [skPromiseModalMixin, pluralizeFilterMixin],
	components: {
		SkBaseModal,
		SkDropdownMultiSelect,
		TypeaheadInput,
		Loading
	},
	data: function () {
		return {
			loading: false,
			restaurant: null,
			restaurantMenus: [],
			menuIds: []
		}
	},
	computed: {
		menuOptions() {
			const hubMenuIds = this.$store.state.hubsModule.currentHub
				? this.$store.state.hubsModule.currentHub.restaurant_menu_ids
				: []
			const options = [
				{
					text: "Select Menu",
					id: null
				}
			]

			this.restaurantMenus.forEach((menu) => {
				if (!hubMenuIds.includes(menu.id)) {
					options.push({
						text: menu.name,
						id: menu.id
					})
				}
			})

			return options
		}
	},
	methods: {
		handleOptions() {
			this.loading = false
			this.restaurant = null
			this.restaurantMenus = []
			this.menuIds = []
			this.$refs.restaurantAutocomplete.clearSearch()
		},
		typeaheadSerializer(result) {
			return {
				text: result.name
			}
		},
		autocompleteRestaurants(query) {
			return this.$store.dispatch(
				"restaurantsModule/autocompleteRestaurants",
				query
			)
		},
		async restaurantSelected(restaurant) {
			try {
				if (
					!restaurant ||
					!this.restaurant ||
					restaurant.id != this.restaurant.id
				) {
					this.menuIds = []
				}
				this.restaurant = restaurant
				this.loading = true
				const response = await restaurant_service.getRestaurantMenus(
					restaurant.id
				)
				if (response.status && response.data.menus) {
					this.restaurantMenus = response.data.menus
				}
				this.loading = false
			} catch (e) {
				this.loading = false
				throw e
			}
		},
		validateMenus() {
			if (!this.restaurant) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Add New Hub Menu",
					message: "Please select a restaurant to continue",
					type: NotificationType.WARNING
				})
				return false
			} else if (this.menuIds.length == 0) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Add New Hub Menu",
					message: "Please select at least one restaurant menu",
					type: NotificationType.WARNING
				})
				return false
			}
			return true
		},
		async addMenus() {
			try {
				this.loading = true
				const isValid = this.validateMenus()
				if (isValid) {
					const menus = this.restaurantMenus.filter((menu) =>
						this.menuIds.includes(menu.id)
					)
					const success = await this.$store.dispatch(
						`hubsModule/${ActionTypes.ADD_HUB_RESTAURANT_MENU}`,
						this.menuIds
					)
					if (success) {
						const menuCountText = this.$options.filters.pluralize(
							this.menuIds.length,
							"Menu"
						)
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Add New Hub Menu",
							message: `${menuCountText} were successfully added!`,
							type: NotificationType.SUCCESS
						})

						this.confirm({
							restaurant: this.restaurant,
							menus: menus
						})
					} else {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Add New Hub Menu",
							message: "Something went wrong, please try again",
							type: NotificationType.ERROR
						})
					}
				}
				this.loading = false
			} catch (e) {
				this.loading = false
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Add New Hub",
					message: "Something went wrong, please try again",
					type: NotificationType.ERROR
				})
				throw e
			}
		}
	}
}
</script>