
<template>
	<div class="hub-restaurant-menus">
		<CollapsibleSection
			v-for="(menuCategory, i) in menuCategories"
			:key="i"
			:index="i"
			class="hub-collapsible-section"
			:title="menuCategory.name"
			:subtitle="menuCategory.timeRange"
			:badgeText="menuCategory.menuCount"
		>
			<div slot="expanded">
				<div class="sk-table sk-table-default">
					<div class="sk-table-header">
						<div class="sk-table-row">
							<div>Restaurant</div>
							<div>Menu</div>
							<div>Hours</div>
							<div>Businesses</div>
							<div>Status</div>
							<div>Actions</div>
						</div>
					</div>
					<div class="sk-table-body">
						<HubRestaurantMenuLineItem
							v-for="restaurantMenu in menuCategory.menus"
							:key="restaurantMenu.id"
							:menu="restaurantMenu"
						/>
					</div>
				</div>
			</div>
			<div slot="collapsed">
				<div class="sk-pills-container">
					<router-link
						v-for="restaurantMenu in menuCategory.menus"
						:key="restaurantMenu.id"
						:to="`/restaurant/${restaurantMenu.restaurant_id}/menu/${restaurantMenu.id}`"
						tag="a"
						class="pill pill-grey"
						:title="restaurantMenu.name"
					>
						<i class="sk-icon-utensils-solid"></i>
						{{ restaurantMenu.name }}
					</router-link>
					<div
						class="pill pill-grey"
						@click="addNewMenu"
					>
						<i class="sk-icon-plus-regular"></i>
						New {{ menuCategory.name }} Menu
					</div>
				</div>
			</div>
		</CollapsibleSection>
	</div>
</template>

<style scoped>
.hub-restaurant-menus {
	height: 100%;
}

.hub-collapsible-section {
	margin-top: 37px;
}

.sk-table {
	width: 100%;
	margin: 0;
}

.sk-pills-container > .pill.pill-grey {
	max-width: 100%;
}
</style>
  
<script>
import "moment-timezone"
import loaderMixin from "@/mixins/loader-mixin"
import menuCategorizationMixin from "@/mixins/menu-categorization-mixin"
import CollapsibleSection from "@/components/CollapsibleSection.vue"
import ActionTypes from "@/store/modules/hubs/action-types"
import HubRestaurantMenuLineItem from "@/components/hubs/HubRestaurantMenuLineItem.vue"

export default {
	name: "HubRestaurantMenus",
	mixins: [loaderMixin, menuCategorizationMixin],
	components: {
		CollapsibleSection,
		HubRestaurantMenuLineItem
	},
	computed: {
		menus() {
			return this.$store.state.hubsModule.currentHubRestaurantMenus
		}
	},
	mounted: async function () {
		this.toggleLoader(true)
		this.$store
			.dispatch(`hubsModule/${ActionTypes.GET_HUB_RESTAURANT_MENUS}`)
			.finally(() => {
				this.toggleLoader(false)
			})
	},
	methods: {
		addNewMenu() {
			this.$emit("openAddNewHubMenuModal")
		}
	}
}
</script>