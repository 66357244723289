<template>
	<div class="business-menus">
		<slot v-if="pagedExceptions.length > 0">
			<div class="sk-table sk-table-default">
				<div class="sk-table-header">
					<div class="sk-table-row">
						<div
							class="sk-table-column-checkbox"
							@click="batchSelectAllEntitiesClicked($event)"
						>
							<SkCheckbox
								class="sk-checkbox-green"
								:checked="allBatchableEntitiesSelected"
							/>
						</div>
						<div>Status</div>
						<div>Order #</div>
						<div>Date/Time</div>
						<div>Restaurant</div>
						<div>Exception (COGS)</div>
						<div>Reason</div>
						<div>Issued By</div>
						<div>Actions</div>
					</div>
				</div>
				<transition-group
					name="list"
					tag="div"
					class="sk-table-body"
					appear
				>
					<ExceptionLineItem
						v-for="exception in pagedExceptions"
						:key="exception.id"
						:exception="exception"
						:batchSelectId="exception.id"
						:batchSelectedIds="batchSelectedIds"
						@batchSelected="batchSelectEntity"
						@setExceptionStatus="setExceptionStatus"
						@updateExceptionClicked="openUpdateExceptionModal"
						@openOrderDetailsModal="openOrderDetailsModal"
					/>
				</transition-group>
			</div>
			<PaginationFooter
				:contentText="'Exceptions'"
				:pageSize="20"
				:total="exceptions.length"
				@pageChanged="filterPage"
			/>
		</slot>
		<EmptyPlaceholderWidget
			v-else
			:title="`No Exceptions`"
			:description="`No exceptions. Woohoo!`"
		/>
		<ConfirmationModal ref="exceptionConfirmationModal" />
		<ExceptionModal ref="updateExceptionModal" />
		<OrderDetailsModal ref="orderDetailsModal" />
	</div>
</template>

<style scoped>
.business-menus {
	height: 100%;
	display: flex;
	flex-direction: column;
}
</style>

<script>
import ExceptionLineItem from "@/components/finance/ExceptionLineItem.vue"
import PaginationFooter from "@/components/PaginationFooter.vue"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue"
import { ConfirmationModalType } from "@/enums/confirmationModalType"
import EmptyPlaceholderWidget from "@/components/EmptyPlaceholderWidget.vue"
import ExceptionModal from "@/components/modals/ExceptionModal.vue"
import SkCheckbox from "@/components/SkCheckbox.vue"
import batchSelectMixin from "@/mixins/batch-select-mixin"
import moment from "moment"
import OrderDetailsModal from "@/components/modals/OrderDetailsModal.vue"

export default {
	name: "Exceptions",
	mixins: [batchSelectMixin],
	components: {
		ExceptionLineItem,
		PaginationFooter,
		ConfirmationModal,
		EmptyPlaceholderWidget,
		ExceptionModal,
		SkCheckbox,
		OrderDetailsModal
	},
	props: {
		restaurantId: Number,
		dateFrom: {
			type: String,
			default: ""
		},
		dateTo: {
			type: String,
			default: ""
		}
	},
	data: function() {
		return {
			pageRange: {
				page: 1,
				start: 0,
				end: 20
			},
			menuDeleted: false
		}
	},
	computed: {
		exceptions() {
			var retVal = this.$store.state.order_exceptions
			if (this.dateFrom != null && this.dateFrom != "") {
				if (this.dateTo != null && this.dateTo != "") {
					retVal = retVal.filter(
						exception =>
							moment
								.utc(exception.created_date)
								.isSameOrAfter(this.dateFrom, "days") &&
							moment(exception.created_date).isSameOrBefore(
								this.dateTo,
								"days"
							)
					)
				} else {
					retVal = retVal.filter(exception =>
						moment(exception.created_date).isSame(
							this.dateFrom,
							"days"
						)
					)
				}
			}
			if (this.restaurantId != -1) {
				let that = this
				retVal = retVal.filter(function(exception) {
					return exception.restaurant_id == that.restaurantId
				})
			}
			return retVal
		},
		pagedExceptions() {
			return this.exceptions.slice(
				this.pageRange.start,
				this.pageRange.end
			)
		},
		batchableEntityIds() {
			return this.pagedExceptions.map((exception) => exception.id)
		},
	},
	methods: {
		filterPage(range) {
			this.pageRange = range
		},
		async setExceptionStatus(payload) {
			let exception = payload.exception
			let approve = payload.approve
			if (approve) {
				const confirmed = await this.$refs.exceptionConfirmationModal.open(
					{
						title: `Are you sure you want to approve an exception of $${exception.amount} for ${exception.order_id}?`,
						confirmation: "Approve",
						confirmationIcon: "sk-icon-check-regular",
						cancel: "Cancel",
						type: ConfirmationModalType.GREEN
					}
				)
				if (confirmed) {
					this.$store.dispatch("setExceptionStatus", [
						exception.id,
						1
					])
				}
			} else {
				const confirmed = await this.$refs.exceptionConfirmationModal.open(
					{
						title: `Are you sure you want to reject an exception of $${exception.amount} for ${exception.order_id}?`,
						confirmation: "Reject",
						confirmationIcon: "sk-icon-trash-alt-solid",
						cancel: "Cancel",
						type: ConfirmationModalType.RED
					}
				)
				if (confirmed) {
					this.$store.dispatch("setExceptionStatus", [
						exception.id,
						2
					])
				}
			}
		},
		async openUpdateExceptionModal(payload) {
			let order = await this.$store.dispatch(
				"ordersModule/getOrderStatus",
				payload.exception.order_id
			)
			this.$refs.updateExceptionModal.open({
				exception: payload.exception,
				order: order
			})
		},
		cleanUpBatchIds() {
			this.batchSelectedIds = []
			this.$emit("update:selectedIds", [])
		},
		openOrderDetailsModal(orderId) {
			this.$refs.orderDetailsModal.open({
				orderId: orderId
			})
		}
	},
	watch : {
		batchSelectedIds:{ 
			immediate: true,
			handler(ids) {
				this.$emit("update:selectedIds", ids)
			}
		}
	}
}
</script>