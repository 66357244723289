// This is used in the root level component for displaying orders
let orderComponentMixin = {
	data: function () {
		return {
			expandedSuborder: null,
			activeTabId: 0
		}
	},
	computed: {
		isExpanded() {
			return (
				this.$store.state.ordersModule.currentOrder != null &&
				this.order.id == this.$store.state.ordersModule.currentOrder.id
			)
		},
		warnings() {
			return this.order &&
				this.order.warnings &&
				this.order.warnings.length > 0
				? this.order.warnings
				: []
		},
		suborderWarnings() {
			const suborders = this.order.sub_orders.reduce(
				(suborders, suborder) => {
					suborders[suborder.id] = {
						suborder: suborder,
						warnings: []
					}
					return suborders
				},
				{}
			)
			this.warnings
				.filter(
					(warning) =>
						warning.extra_params &&
						warning.extra_params.sub_order_id
				)
				.forEach((warning) => {
					suborders[warning.extra_params.sub_order_id].warnings.push(
						warning
					)
				})
			return Object.values(suborders)
		}
	},
	methods: {
		toggleOrderDetails(refresh) {
			if (this.isExpanded) {
				this.activeTabId = 0
				this.$store.dispatch("ordersModule/setCurrentOrder", null)
			} else {
				this.$store.dispatch(
					"ordersModule/setDropoffLocation",
					this.order.business.slug
				)
				this.$store.dispatch("ordersModule/setCurrentOrder", {
					order: this.order,
					refresh: refresh
				})
			}
		},
		expand(suborder) {
			if (this.order.sub_orders.length > 1) {
				this.expandedSuborder = suborder
			}
		}
	}
}

export default orderComponentMixin
