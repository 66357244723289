let eventMixin = {
	computed: {
		business() {
			if (this.event.business_id && this.$store.getters.businessesMap && this.$store.getters.businessesMap[this.event.business_id]) {
				return this.$store.getters.businessesMap[this.event.business_id]
			}
			return null
		},
		restaurant() {
			if (this.event.restaurant_id && this.$store.state.restaurants && this.$store.state.restaurants[this.event.restaurant_id]) {
				return this.$store.state.restaurants[this.event.restaurant_id]
			}
			return null
		},
		businessName() {
			return this.business && this.business.name ? this.business.name : null
		},
		restaurantName() {
			if (this.event.restaurant_id == null) {
				return null
			}

			return this.restaurant && this.restaurant.name ? this.restaurant.name : "N/A"
		},
		menuName() {
			if (this.event.menu_id == null) {
				return null
			}
			return this.menu && this.menu.name ? this.menu.name : "N/A"
		}
	}
}

export default eventMixin
