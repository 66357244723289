export const PusherEvent = {
  "ORDER_STATUS": "order_status",
  "ORDER_WARNING": "order_warning",
  "ORDER_TRANSACTION_STATUS_CHANGED": "order_transaction_status_changed",
  "ORDER_REFUND_CREATED": "order_refund_created",
  "ORDER_REFUND_UPDATED": "order_refund_updated",
  "ORDER_EXCEPTION_CREATED" :"order_exception_created",
  "ORDER_EXCEPTION_UPDATED" :"order_exception_updated",
  "ACTIVITY_ADDED": "activity_added",
  "RESTAURANT_CREATED": "restaurant_created",
  "RESTAURANT_UPDATED": "restaurant_updated",
  "RESTAURANT_MENU_UPDATED": "restaurant_menu_updated",
  "BUSINESS_CREATED": "business_created",
  "BUSINESS_UPDATED": "business_updated",
  "BUSINESS_MENU_UPDATED": "business_menu_updated",
  "ORDER_NOTE": "order_note",
  "DELIVERY_STATUS": "delivery_status"
}