import Vue from 'vue'
import { store } from "@/store/store"
import { parseResponse } from "@arikgaisler/utils/api-utils"

let host = process.env.VUE_APP_API_HOST
const HUB_API = `https://hub${host}.2ndkitchen.com`

export default {
    async addHub(payload) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.post(`${HUB_API}/hub_locations`, payload, { "headers": { "Authorization": tokenStr } }))
    },
    async getHub(id) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.get(`${HUB_API}/hub_locations/${id}`, { "headers": { "Authorization": tokenStr } }))
    },
    async updateHub(id, payload) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.put(`${HUB_API}/hub_locations/${id}`, payload, { "headers": { "Authorization": tokenStr } }))
    },
    async addRestaurantMenus(id, payload) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.post(`${HUB_API}/hub_locations/${id}/restaurant_menus`, payload, { "headers": { "Authorization": tokenStr } }))
    },
    async addBusiness(id, payload) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.post(`${HUB_API}/hub_locations/${id}/businesses`, payload, { "headers": { "Authorization": tokenStr } }))
    },
    async updateHubBusiness(id, business_id, payload) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.put(`${HUB_API}/hub_locations/${id}/businesses/${business_id}`, payload, { "headers": { "Authorization": tokenStr } }))
    }
}