<template>
  <label class="sk-switch" :style="{width: width, maxWidth: width}">
    <input 
      type="checkbox"
      v-model="model"
      :disabled="disabled"/>
    <span class="slider round"></span>
  </label>
</template>

<script>
export default {
  name: "SkToggle",
  props: {
    value: {
      type: [Boolean, Number]
    },
    disabled: {
      type: [Boolean, Number]
    },
    width: {
      type: String,
      default: "40px"
    }
  },
  computed: {
    model:{
      get() {
        return this.value
      },
      set(val) {
        this.$emit("input", val)
      }
    }
  },
}
</script>