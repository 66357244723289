import moment from "moment"

export default {
    dowNames: {
        0: "Mon",
        1: "Tue",
        2: "Wed",
        3: "Thu",
        4: "Fri",
        5: "Sat",
        6: "Sun"
    },
    normalizeDayOfWeek(date) {
        let dow = date.day()
        return dow == 0 ? 6 : dow - 1
    },
    numericalTime(time) {
        return Number(time.replace(/:/g, ""))
    },
    timeDiff(from, to) {
        const duration = moment.duration(from.diff(to))
        const days = duration.asDays()
        const fixedDays = Number(days.toFixed(0))
        if (fixedDays >= 1) {
            return `${fixedDays} ${fixedDays > 1 ? "days" : "day"}`
        } else if (fixedDays < 0) {
            return `${Math.abs(fixedDays)} ${
                fixedDays < -1 ? "days" : "day"
                } ago`
        }
        const hours = duration.asHours()
        const fixedHours = Number(hours.toFixed(0))
        if (fixedHours >= 1) {
            return `${fixedHours} ${fixedHours > 1 ? "hours" : "hour"}`
        } else if (fixedHours < 0) {
            return `${Math.abs(fixedHours)} ${
                fixedHours < -1 ? "hours" : "hour"
                } ago`
        }
        const minutes = duration.asMinutes()
        const fixedMinutes = Number(minutes.toFixed(0))
        if (fixedMinutes > 0) {
            return `${fixedMinutes} ${
                fixedMinutes > 1 ? "minutes" : "minute"
                }`
        } else {
            return `${Math.abs(fixedMinutes)} ${
                fixedMinutes < -1 ? "minutes" : "minute"
                } ago`
        }
    },
    generateSlots(start, end, interval) {
        const startMoment = moment(start, "HH:mm")
        const endMoment = moment(end, "HH:mm")
        const minutesDiff = Math.abs(startMoment.diff(endMoment, "m"))
        const maxSlots = minutesDiff / interval
        const slots = [startMoment.format("HH:mm")]
        for (let i = 0; i < maxSlots; i ++) {
            startMoment.add(interval, "m")
            slots.push(startMoment.format("HH:mm"))
        }
        return slots
    },
    hoursToSchedule(openingHours, offset) {
        if (openingHours) {
            let schedule = {}
            let scheduleSummary = []
            const openDays = []
            openingHours
                .slice()
                .sort((a, b) => a.day_of_week - b.day_of_week)
                .forEach((hour) => {
                    openDays.push(hour.day_of_week)
                    if (
                        schedule[`${hour.open_time}-${hour.close_time}`] ==
                        undefined
                    ) {
                        schedule[`${hour.open_time}-${hour.close_time}`] = [
                            hour.day_of_week
                        ]
                    } else if (
                        !schedule[
                            `${hour.open_time}-${hour.close_time}`
                        ].includes(hour.day_of_week)
                    ) {
                        schedule[
                            `${hour.open_time}-${hour.close_time}`
                        ].push(hour.day_of_week)
                    }
                })

            for (let hours in schedule) {
                let days = schedule[hours].slice().sort()
                let isRange = days.length > 2

                for (var i = 1; i < days.length; i++) {
                    let day = days[i]
                    let prevDay = days[i - 1]
                    if (day != prevDay + 1) {
                        isRange = false
                        break
                    }
                }

                if (isRange) {
                    days.splice(1, days.length - 2)
                    days = days.map((day) => this.dowNames[day])
                } else {
                    days = days.map((day) => this.dowNames[day])
                }

                const time = hours.split("-").map((time) => {
                    const offsetTime = moment(time, "h:mm").add(
                        offset,
                        "m"
                    )
                    if (offsetTime.minutes() > 0) {
                        return offsetTime.format("h:mm A")
                    } else {
                        return offsetTime.format("h A")
                    }
                })
                scheduleSummary.push({
                    dows: schedule[hours],
                    days: days,
                    times: time,
                    daysText: isRange ? days.join(" - ") : days.join(", "),
                    timesText: time.join(" - ")
                })
            }

            const closedDays = Object.keys(this.dowNames)
                .filter((dow) => !openDays.includes(Number(dow)))
                .map((dow) => Number(dow))

            if (closedDays.length > 0) {
                let daysText = closedDays
                    .map((dow) => this.dowNames[dow])
                    .join(", ")
                scheduleSummary.push({
                    dows: closedDays,
                    days: daysText,
                    daysText: daysText,
                    timesText: "Closed"
                })
            }

            return scheduleSummary
        } else {
            const closedDays = Object.keys(this.dowNames).map((dow) =>
                Number(dow)
            )
            return [{
                dows: closedDays,
                days: closedDays,
                daysText: closedDays
                    .map((dow) => this.dowNames[dow])
                    .join(", "),
                timesText: "Closed"
            }]
        }
    },
    isWithinBounds(bounds, targetRange) {
        const boundOpenTime = moment.utc(bounds.openTime, "HH:mm:ss")
        const boundCloseTime = moment.utc(bounds.closeTime, "HH:mm:ss")
        const openTime = moment.utc(targetRange.openTime, "HH:mm:ss")
        const closeTime = moment.utc(targetRange.closeTime, "HH:mm:ss")
    
        if (boundCloseTime.isBefore(boundOpenTime, "m")) {
            boundCloseTime.add(1, "d")
        }
    
        if (closeTime.isBefore(openTime, "m")) {
            closeTime.add(1, "d")
        }
    
        const openTimeWithinBounds = openTime.isBetween(boundOpenTime, boundCloseTime, "m", "[]")
        const closeTimeWithinBounds = closeTime.isBetween(boundOpenTime, boundCloseTime, "m", "[]")
        if (openTimeWithinBounds && closeTimeWithinBounds) {
            return {
                openTimeInBounds: true,
                closeTimeInBounds: true
            }
        } else if (!closeTimeWithinBounds && !openTimeWithinBounds) {
          const boundsWithinTargetRange = boundOpenTime.isBetween(openTime, closeTime, "m", "[]") && boundCloseTime.isBetween(openTime, closeTime, "m", "[]")
          if (boundsWithinTargetRange) {
            return {
              openTimeInBounds: false,
              closeTimeInBounds: false,
              boundsInTargetRange: true
            }
          } else {
            return {
                openTimeInBounds: false,
                closeTimeInBounds: false
            }
          }
        } else if (!closeTimeWithinBounds) {
            return {
                openTimeInBounds: true,
                closeTimeInBounds: false
            }
        } else if (!openTimeWithinBounds) {
            return {
                openTimeInBounds: false,
                closeTimeInBounds: true
            }
        }
        return {
            openTimeInBounds: false,
            closeTimeInBounds: false
        }
    },
    intersectWithinRanges(bounds, targetRange) {
        const withinBounds = this.isWithinBounds(bounds, targetRange)
        if (withinBounds.openTimeInBounds && withinBounds.closeTimeInBounds) {
            return targetRange
        } else if (!withinBounds.closeTimeInBounds && !withinBounds.openTimeInBounds) {
            if (withinBounds.boundsInTargetRange) {
              return bounds
            } else {
              return null
            }
        } else if (!withinBounds.closeTimeInBounds) {
            return Object.assign({}, targetRange, {
                closeTime: bounds.closeTime
            })
        } else if (!withinBounds.openTimeInBounds) {
            return Object.assign({}, targetRange, {
                openTime: bounds.openTime
            })
        }
        return null
    },
    createScheduleFromRange(openTime, closeTime, dow, timezoneOffset) {
        let localOpenTime = moment.utc(openTime, "HH:mm:ss").add(timezoneOffset, "m").format("HH:mm:ss")
        let localCloseTime = moment.utc(closeTime, "HH:mm:ss").add(timezoneOffset, "m").format("HH:mm:ss")
        let numOpenTime =  Number(localOpenTime.replace(/:/g, ""))
        let numCloseTime = Number(localCloseTime.replace(/:/g, ""))
        let schedule = {}
        let nextDay = dow == 6 ? 0 : dow + 1
        schedule[dow] = []
        schedule[nextDay] = []
        let scheduleDay = schedule[dow]
        if (
            localCloseTime == "00:00:00" &&
            closeTime == "00:00:00"
        ) {
            scheduleDay.push({
                openTime: "00:00:00",
                closeTime: "24:00:00",
                dow: dow,
                rangesFrom: dow
            })
        } else if (numOpenTime > numCloseTime) {
            let scheduleNextDay = schedule[nextDay]
            if (localOpenTime != "24:00:00") {
                scheduleDay.push({
                    openTime: localOpenTime,
                    closeTime: "24:00:00",
                    dow: dow,
                    rangesFrom: dow
                })
            }
            if (localCloseTime != "00:00:00") {
                scheduleNextDay.push({
                    openTime: "00:00:00",
                    closeTime: localCloseTime,
                    dow: nextDay,
                    rangesFrom: dow
                })
            }
        } else {
            scheduleDay.push({
                openTime: localOpenTime,
                closeTime: localCloseTime,
                dow: dow,
                rangesFrom: dow
            })
        }
        return schedule
    }
}