<template>
	<div class="sk-table-row">
		<div
			v-if="canBeApproved"
			class="sk-table-column-checkbox"
			@click="batchSelectClicked($event)"
		>
			<SkCheckbox class="sk-checkbox-green" :checked="batchSelected" />
		</div>
		<div v-else></div>
		<div>
			{{ businessName }}
		</div>
		<div>
			{{ invoicePeriod }}
		</div>
		<div>
			{{ invoice.credits | currency }}
		</div>
		<div>{{ invoice.amount | currency }}</div>
		<div>{{ invoiceTotal | currency }}</div>
		<div>
			{{ fundingSource }}
		</div>
		<div>
			<div :class="w9StatusBadgeClass">
				<span :class="w9StatusClass"></span>
				{{ w9StatusLabel }}
			</div>
		</div>
		<div>
			<div :class="statusBadgeClasses">
				<span :class="statusClasses"></span>
				{{ statusLabel }}
				<span v-if="showPaidDate" class="paid-date">
					{{ paidDate }}
				</span>
			</div>
		</div>
		<div class="sk-table-column-fit">
			<div class="sk-table-actions" v-if="canBeApproved">
				<button
					class="button button-icon"
					@click="approveInvoice"
					title="Approve Invoice"
				>
					<i class="sk-icon-check-regular"></i>
				</button>
				<SkDropdownSelect
					:options="allowedActions"
					:iconClasses="'sk-icon-ellipsis-v-regular'"
					:showSelectedOption="false"
					:position="'left'"
					@selected="actionSelected"
				/>
			</div>
			<div class="sk-table-actions" v-else-if="canBeCompleted">
				<button
					class="button button-icon"
					@click="completeInvoice"
					title="Complete Invoice"
				>
					<i class="sk-icon-archive-solid"></i>
				</button>
				<SkDropdownSelect
					:options="allowedActions"
					:iconClasses="'sk-icon-ellipsis-v-regular'"
					:showSelectedOption="false"
					:position="'left'"
					@selected="actionSelected"
				/>
			</div>
			<div class="sk-table-actions sk-table-buttons" v-else>
				<button
					class="button button-icon"
					@click="viewInvoice"
					title="View Invoice"
				>
					<i class="sk-icon-eye-solid"></i>
				</button>
				<button
					class="button button-icon"
					@click="downloadInvoiceBreakdown"
					title="Download Breakdown"
				>
					<i class="sk-icon-download"></i>
				</button>
			</div>
		</div>
	</div>
</template>

<style scoped>
.sk-table-row .pill-alt {
	max-width: 100%;
}

.paid-date {
	font-weight: 400;
}

.sk-table-buttons .button:last-child {
	padding-right: 0;
	padding-left: 5px;
}
</style>

<script>
import moment from "moment"
import currencyFilter from "@/mixins/currency-filter-mixin"
import batchSelectableMixin from "@/mixins/batch-selectable-mixin"
import { FundingSource } from "@/enums/fundingSource"
import { InvoiceStatus } from "@/enums/invoiceStatus"
import SkCheckbox from "@/components/SkCheckbox.vue"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"

export default {
	name: "InvoiceLineItem",
	mixins: [currencyFilter, batchSelectableMixin],
	components: {
		SkCheckbox,
		SkDropdownSelect
	},
	props: {
		invoice: Object
	},
	computed: {
		business() {
			const businessId = this.invoice.business.id
			return this.$store.getters.businessesMap[businessId]
		},
		businessName() {
			if (this.business) {
				return this.business.name
			}
			return "N/A"
		},
		invoiceTotal() {
			return (
				parseFloat(this.invoice.amount) -
				parseFloat(this.invoice.credits)
			)
		},
		invoicePeriod() {
			const from = moment.utc(this.invoice.from_date).format("MMM D")
			const to = moment.utc(this.invoice.to_date).format("MMM D")
			return `${from} - ${to}`
		},
		paidDate() {
			if (this.invoice && this.invoice.paid_at) {
				return moment.utc(this.invoice.paid_at).format("MMM D")
			}
			return "N/A"
		},
		fundingSource() {
			if (
				this.invoice.funding_source != undefined &&
				this.invoice.funding_source.type != undefined &&
				this.invoice.funding_source.type.id == FundingSource.ACH
			) {
				return "ACH Transfer"
			}
			return "N/A"
		},
		showPaidDate() {
			return this.invoice.status.value == InvoiceStatus.COMPLETED &&
				this.invoice.paid_at
				? true
				: false
		},
		statusBadgeClasses() {
			switch (this.invoice.status.value) {
				case InvoiceStatus.PENDING:
					return "pill-alt pill-alt-yellow"
				case InvoiceStatus.APPROVED:
				case InvoiceStatus.PROCESSING:
					return "pill-alt pill-alt-green"
				case InvoiceStatus.COMPLETED:
					return "pill-alt pill-alt-grey"
				case InvoiceStatus.FAILED:
					return "pill-alt pill-alt-red"
			}
		},
		statusClasses() {
			switch (this.invoice.status.value) {
				case InvoiceStatus.PENDING:
					return "status-dot yellow"
				case InvoiceStatus.APPROVED:
					return "status-dot green"
				case InvoiceStatus.PROCESSING:
					return "status-dot green-pulsating"
				case InvoiceStatus.COMPLETED:
					return "status-dot grey"
				case InvoiceStatus.FAILED:
					return "status-dot red"
			}
		},
		statusLabel() {
			switch (this.invoice.status.value) {
				case InvoiceStatus.PENDING:
					return "Pending"
				case InvoiceStatus.APPROVED:
					return "Approved"
				case InvoiceStatus.PROCESSING:
					return "Processing"
				case InvoiceStatus.COMPLETED:
					return "Paid"
				case InvoiceStatus.FAILED:
					return "Failed"
			}
		},
		w9Uploaded() {
			return this.business ? this.business.w9_uploaded == 1 : false
		},
		w9StatusBadgeClass() {
			return this.w9Uploaded
				? "pill-alt pill-alt-green"
				: "pill-alt pill-alt-red"
		},
		w9StatusClass() {
			return this.w9Uploaded ? "status-dot green" : "status-dot red"
		},
		w9StatusLabel() {
			return this.w9Uploaded ? "Uploaded" : "Not Uploaded"
		},
		canBeApproved() {
			return this.invoice.status.value == InvoiceStatus.PENDING
		},
		canBeCompleted() {
			return (
				this.invoice.status.value == InvoiceStatus.PENDING ||
				this.invoice.status.value == InvoiceStatus.APPROVED
			)
		},
		allowedActions() {
			let actions = [
				{
					text: `<i class="sk-icon-eye-solid"></i> View Invoice`,
					id: 0
				},
				{
					text: `<i class="sk-icon-download"></i> Download Breakdown`,
					id: 1
				}
			]

			if (this.canBeApproved) {
				actions = actions.concat([
					{
						text: `<i class="sk-icon-pencil-solid"></i> Add External Record`,
						id: 2
					},
					{
						text: `<i class="sk-icon-file-check-solid"></i> Complete Invoice`,
						id: 3
					}
				])
			}

			return actions
		}
	},
	methods: {
		actionSelected(action) {
			switch (action.id) {
				case 0: {
					this.viewInvoice()
					break
				}
				case 1: {
					this.downloadInvoiceBreakdown()
					break
				}
				case 2: {
					this.addExternalRecord()
					break
				}
				case 3: {
					this.completeInvoice()
					break
				}
			}
		},
		viewInvoice() {
			this.$emit("viewInvoice", this.invoice)
		},
		approveInvoice() {
			this.$emit("approveInvoice", this.invoice)
		},
		addExternalRecord() {
			this.$emit("addExternalRecord", this.invoice)
		},
		completeInvoice() {
			this.$emit("completeInvoice", this.invoice)
		},
		downloadInvoiceBreakdown() {
			this.$emit("downloadInvoiceBreakdown", this.invoice)
		}
	}
}
</script>