import { store } from "@/store/store"
import { CANNY_SSO_API } from "@/services/utils"
import user_service from "@/services/users.js"
import Vue from "vue"
import { parseResponse } from "@arikgaisler/utils"
import { EntityType } from "@/enums/entityType"
import businessTypeFormatterMixin from "@/mixins/business/business-type-formatter-mixin"

const vue = new Vue()

export default {
	login(email, pass) {
		store.dispatch("login")
		return store.dispatch("authUser", {
			email: email,
			pass: pass
		})
	},
	activate(token, pass, username) {
		return store.dispatch("activateUser", {
			token: token,
			pass: pass,
			username: username
		})
	},
	verifyActivationToken(token) {
		return store.dispatch("verifyActivationToken", token)
	},
	logout() {
		store.dispatch("userLogout")
	},
	loggedIn() {
		return store.state.user_id > 0
	},
	async sendForgotPasswordEmail(username) {
		try {
			if (username && username != "") {
				try {
					const sendForgotPasswordResponse = await user_service.sendForgotPassword({ username: username })
					if (sendForgotPasswordResponse.status == 200) {
						return {
							success: true,
							activated: true
						}
					} else {
						if (sendForgotPasswordResponse.status == 400 && sendForgotPasswordResponse.data.code == "user_not_active_message") {
							const resendActivationSuccess = await this.resendActivationLink(username)
							return {
							success: resendActivationSuccess,
							activated: false
							}
						} else {
							throw {
								response: sendForgotPasswordResponse,
								message: "Unhandled api error code"
							}
						}
					}
				} catch (e) {
					if (e.status == 400 && e.code == "user_not_active_error") {
						const resendActivationSuccess = await this.resendActivationLink(username)
						return {
							success: resendActivationSuccess,
							activated: false
						}
					}
				}
			}
			return {
				success: false, 
				activated: false
			}
		} catch (e) {
			vue.bugsnag.notify(
				"Failed to send forgot password email",
				event => {
					event.addMetadata("error", {
						error: e
					})
				}
			)
			return {
				success: false, 
				activated: false
			}
		}
	},
	async resetPassword(pass, token) {
		return store.dispatch("resetPassword", {
			pass: pass,
			token: token
		})
	},
	resendActivationLink(email) {
		return store.dispatch("resendActivationLink", email)
	},
	sendSMSActivationCode(userId) {
		return store.dispatch("sendSMSActivationCode", userId)
	},
	verifySMSActivationCode(userId, code) {
		return store.dispatch("verifySMSActivationCode", {
			userId: userId,
			code: code
		})
	},
	async generateCannySSO() {
        const tokenStr = 'Bearer ' + store.state.user_token
		let companies = undefined
		if (!store.getters.isSuperAdmin) {
			companies = store.state.user_entities.map((entity) => {
				const companyData = {
					id: entity.id,
					name: "N/A",
					customFields: {}
				}
				switch (entity.type) {
					case Number(EntityType.BUSINESS): {
						const business = store.getters.businessesMap[entity.id]
						companyData.customFields.entityType = "Business"
						if (business) {
							companyData.name = business ? business.name : "N/A"
							companyData.customFields.businessType = businessTypeFormatterMixin.filters.businessTypeName(
								business.type
							)
						} else {
							companyData.name = "N/A"
							companyData.customFields.businessType = "N/A"
						}
						break
					}
					case Number(EntityType.RESTAURANT): {
						const restaurant = store.state.restaurants[entity.id]
						companyData.customFields.entityType = "Restaurant"
						companyData.name = restaurant ? restaurant.name : "N/A"
						break
					}
				}
				return companyData
			})
		}
		const payload = {
			data: {
				id: store.state.user_id,
				name: store.state.user_name,
				email: store.state.user.email,
				companies: companies
			}
		}
        return parseResponse(Vue.axios.post(CANNY_SSO_API, payload, { 'headers': { 'Authorization': tokenStr } }))
    }
}