<template>
	<SkBaseModal
		class="restaurant-setup-modal"
		:isOpen="isOpen"
		:showCloseButton="false"
		:hasLoader="true"
		@close="cancel"
	>
		<loading :active.sync="loading" :is-full-page="false" />
		<div class="sk-modal-alt-title">
			<h1>New Restaurant Setup</h1>
		</div>
		<div class="restaurant-setup-navbar">
			<div
				v-for="step in steps"
				class="restaurant-setup-step"
				:key="step.id"
				:class="{
					active: step.isActive,
					invalid: step.isInvalid,
					complete: step.isComplete
				}"
				@click="setStep(step.id, $event)"
			>
				{{ step.text }}
				<SkRadio :option="step.id" v-model="currentStep" />
			</div>
		</div>
		<transition-group name="fade-height" class="restaurant-setup-steps">
			<div v-show="currentStep == 0" class="sk-modal-section" key="0">
				<div class="sk-row">
					<EstablishmentTypeaheadInput
						class="optional-field"
						v-model="establishment"
						:placeholder="'Google Restaurant Name'"
					/>
				</div>
				<div class="sk-row">
					<SkInput
						v-model="restaurant.name"
						ref="restaurantNameInput"
						:required="true"
						:name="'Restaurant Name'"
					/>
				</div>
				<div class="sk-row">
					<AddressTypeaheadInput
						v-model="address"
						:invalid="isAddressInvalid"
					/>
				</div>
				<div class="sk-row">
					<SkTelephoneInput
						v-model="phone"
						class="optional-field"
						:name="'Phone Number'"
						:type="'tel'"
					/>
				</div>
				<div class="sk-row">
					<SkInput
						v-model="restaurant.email"
						class="optional-field"
						ref="restaurantEmailInput"
						:validation="emailRegex"
						:name="'Email'"
					/>
				</div>
				<div class="sk-row">
					<SkInput
						v-model="restaurantSlug"
						ref="restaurantSlugInput"
						:name="'Slug'"
						:required="true"
					/>
				</div>
				<div class="sk-row restaurant-logo-row">
					<transition name="expand-horizontally">
						<img
							v-if="restaurantLogoPreview"
							class="restaurant-logo-preview"
							:src="restaurantLogoPreview"
						/>
					</transition>
					<FileUpload
						ref="restaurantLogoUploader"
						:entity="'restaurant'"
						:entity_name="restaurant.slug"
						@set="setRestaurantLogo"
						@loading="setLoader"
						@previewImageChanged="setRestaurantLogoPreview"
					>
						<button
							v-if="!restaurantLogoPreview"
							@click="logoUploadClicked"
							class="
								button button-text
								sk-widget-section-text-button-light
							"
						>
							<i class="sk-icon-plus-regular"></i>
							Add Restaurant Logo
							<span class="optional-label">Optional</span>
						</button>
						<button
							v-else
							@click="logoRemoveClicked"
							class="
								button button-text
								sk-widget-section-text-button-light
							"
						>
							<i class="sk-icon-times-regular"></i>
							Remove Restaurant Logo
							<span class="optional-label">Optional</span>
						</button>
					</FileUpload>
				</div>
			</div>
			<div v-if="currentStep == 1" key="1" class="sk-modal-section">
				<div class="sk-row restaurant-timezone-row">
					<div>{{ restaurantTimezone }} Timezone</div>
					<div>
						Timezone is based on the business address. Edit the
						business address in ‘General’ to update the timezone.
					</div>
				</div>
				<div
					class="sk-row"
					v-for="schedule in restaurantSchedule"
					:key="schedule.day"
				>
					<div class="restaurant-schedule-day">
						{{ schedule.day }}
					</div>
					<SkTimePicker v-model="schedule.open" :name="'Open'" />
					<SkTimePicker v-model="schedule.close" :name="'Close'" />
				</div>
			</div>
			<div v-show="currentStep == 2" key="2" class="sk-modal-section">
				<div class="sk-row">
					<SkDropdownSelect
						v-model="restaurantDeliveryMethod"
						:classes="'sk-input'"
						:options="deliveryMethods"
					/>
				</div>
				<div class="sk-row">
					<SkInput
						v-model="selfDeliveryCommission"
						ref="selfDeliveryCommissionInput"
						:name="'Self Delivery Commission'"
						:placeholder="'Commission'"
						:type="'number'"
						:group="'%'"
						:validation="requiredNumberRegex"
					/>
				</div>
				<div class="sk-row">
					<SkInput
						v-model="courierCommission"
						ref="courierCommissionInput"
						:name="'3rd Party Commission'"
						:placeholder="'Commission'"
						:type="'number'"
						:group="'%'"
						:validation="requiredNumberRegex"
					/>
				</div>
				<div class="sk-row">
					<SkInput
						v-model="eventCommission"
						ref="eventCommissionInput"
						:name="'Popup Commission'"
						:placeholder="'Commission'"
						:type="'number'"
						:group="'%'"
						:validation="requiredNumberRegex"
					/>
				</div>
				<div class="sk-row">
					<SkInput
						v-model="toGoCommission"
						ref="toGoCommissionInput"
						:name="'2Go Commission'"
						:placeholder="'Commission'"
						:type="'number'"
						:group="'%'"
						:validation="requiredNumberRegex"
					/>
				</div>
			</div>
			<div v-show="currentStep == 3" key="3" class="sk-modal-section">
				<section
					class="add-user-section"
					v-for="user in restaurantUsers"
					:key="user.uuid"
				>
					<div class="sk-row">
						<SkInput
							v-model="user.name"
							:ref="`userNameInput${user.uuid}`"
							:name="'Name'"
							:required="true"
						/>
					</div>
					<div class="sk-row">
						<SkInput
							v-model="user.email"
							:ref="`userEmailInput${user.uuid}`"
							:name="'Email'"
							:validation="emailRegex"
							:required="true"
						/>
					</div>
					<div class="sk-row">
						<SkTelephoneInput
							v-model="user.phone"
							class="optional-field"
							:ref="`userPhoneInput${user.uuid}`"
							:name="'Phone Number'"
							:type="'tel'"
							:required="true"
						/>
					</div>
					<div class="sk-row">
						<div class="sk-widget-alt sk-switch-label-wrapper">
							<SkCheckbox v-model="user.sendActivation" />
							<div>Send Activation Email</div>
						</div>
					</div>
					<button
						@click="removeUser(user)"
						class="
							button button-text
							sk-widget-section-text-button-light
						"
					>
						<i class="sk-icon-times-regular"></i>
						Clear User
					</button>
				</section>
				<div class="sk-row">
					<button
						@click="addNewUser"
						class="
							button button-text
							sk-widget-section-text-button-light
						"
					>
						<i class="sk-icon-plus-regular"></i>
						Add Another User
					</button>
				</div>
			</div>
			<div v-if="currentStep == 4" key="4" class="sk-modal-section">
				<SetupReviewStep
					:stepName="'General'"
					:stepId="0"
					:invalid="generalStepInvalid"
					:complete="generalStepComplete"
					:progress="generalStepProgress"
					:maxProgress="5"
					:reviewingStepId="reviewingStepId"
					@reviewStep="reviewStep"
					@goToStep="setStep"
				>
					<div
						class="restaurant-step-detail"
						:class="{
							invalid: restaurant.name.length == 0
						}"
					>
						Restaurant Name
						<b v-sk-unassigned="restaurant.name"></b>
					</div>
					<div class="restaurant-step-detail">
						Phone Number
						<b v-sk-unassigned="formattedPhone"></b>
					</div>
					<div
						class="restaurant-step-detail"
						:class="{ invalid: !isEmailValid }"
					>
						Email
						<b v-sk-unassigned="restaurant.email"></b>
					</div>
					<div
						class="restaurant-step-detail"
						:class="{
							invalid: restaurant.slug.length == 0
						}"
					>
						Slug
						<b v-sk-unassigned="restaurant.slug"></b>
					</div>
					<div
						class="restaurant-step-detail"
						:class="{ invalid: !isAddressSet }"
					>
						Restaurant Street Address
						<b v-sk-unassigned="formattedAddress"></b>
					</div>
				</SetupReviewStep>
				<SetupReviewStep
					:stepName="'Hours'"
					:stepId="1"
					:invalid="hoursStepInvalid"
					:complete="hoursStepComplete"
					:progress="hoursStepProgress"
					:maxProgress="7"
					:reviewingStepId="reviewingStepId"
					@reviewStep="reviewStep"
					@goToStep="setStep"
				>
					<div
						class="restaurant-step-detail"
						v-for="schedule in restaurantSchedule"
						:class="{
							invalid:
								(schedule.open && !schedule.close) ||
								(schedule.close && !schedule.open)
						}"
						:key="schedule.day"
					>
						{{ schedule.day }}
						<b v-if="schedule.open || schedule.close">
							{{ schedule.open }} - {{ schedule.close }}
						</b>
						<span v-else class="sk-muted-text">Closed</span>
					</div>
				</SetupReviewStep>
				<SetupReviewStep
					:stepName="'Admin'"
					:stepId="2"
					:invalid="adminStepInvalid"
					:complete="adminStepComplete"
					:progress="adminStepProgress"
					:maxProgress="5"
					:reviewingStepId="reviewingStepId"
					@reviewStep="reviewStep"
					@goToStep="setStep"
				>
					<div class="restaurant-step-detail">
						Delivery Method
						<b v-sk-unassigned="restaurantDeliveryMethod.text"></b>
					</div>
					<div
						class="restaurant-step-detail"
						:class="{ invalid: selfDeliveryCommission === '' }"
					>
						Self Delivery Commission
						<b v-sk-unassigned="selfDeliveryCommission"></b>
					</div>
					<div
						class="restaurant-step-detail"
						:class="{ invalid: courierCommission === '' }"
					>
						3rd Party Commission Method
						<b v-sk-unassigned="courierCommission"></b>
					</div>
					<div
						class="restaurant-step-detail"
						:class="{ invalid: eventCommission === '' }"
					>
						Popup Commission
						<b v-sk-unassigned="eventCommission"></b>
					</div>
					<div
						class="restaurant-step-detail"
						:class="{ invalid: toGoCommission === '' }"
					>
						2Go Commission
						<b v-sk-unassigned="toGoCommission"></b>
					</div>
				</SetupReviewStep>
				<SetupReviewStep
					:stepName="'Users'"
					:stepId="3"
					:invalid="usersStepInvalid"
					:complete="usersStepComplete"
					:progress="usersStepProgress"
					:maxProgress="restaurantUsersAdded.length"
					:reviewingStepId="reviewingStepId"
					@reviewStep="reviewStep"
					@goToStep="setStep"
				>
					<div
						v-for="user in restaurantUsersToReview"
						class="user-step-details"
						:key="user.uuid"
					>
						<div
							class="restaurant-step-detail"
							:class="{
								invalid: user.nameInvalid
							}"
						>
							Name
							<b v-sk-unassigned="user.name"></b>
						</div>
						<div
							class="restaurant-step-detail"
							:class="{
								invalid: user.emailInvalid
							}"
						>
							Email
							<b v-sk-unassigned="user.email"></b>
						</div>
						<div
							class="restaurant-step-detail"
							:class="{
								invalid: user.phoneInvalid
							}"
						>
							Phone Number
							<b v-sk-unassigned="user.phone"></b>
						</div>
					</div>
				</SetupReviewStep>
			</div>
		</transition-group>
		<div class="sk-modal-actions">
			<button @click="cancel" class="button button-text">Cancel</button>
			<button
				v-if="currentStep != lastStep"
				class="button button-primary"
				@click="nextStep"
			>
				Continue
				<i class="sk-icon-caret-right-solid"></i>
			</button>
			<button
				v-else
				class="button button-primary"
				:class="{
					'button-primary': canCreateRestaurant,
					'button-grey': !canCreateRestaurant
				}"
				@click="createRestaurant"
			>
				Create Restaurant
			</button>
		</div>
		<ConfirmationModal ref="confirmationModal" />
	</SkBaseModal>
</template>

<style scoped>
.sk-modal-alt-title {
	margin-bottom: 0;
	position: relative;
}

.sk-modal-section .sk-widget-alt {
	padding: 15px;
}

.restaurant-setup-navbar {
	display: flex;
	justify-content: space-between;
	height: 54px;
	box-shadow: 0px 0px 3px rgba(191, 191, 191, 0.5);
	margin: 0 -20px;
	padding: 0 0 0 20px;
}

.restaurant-setup-steps {
	display: grid;
	grid-template-columns: 1fr;
}

.restaurant-setup-steps > div {
	grid-row-start: 1;
	grid-column-start: 1;
}

.restaurant-setup-steps > div {
	padding: 5px;
	margin: -5px;
}

.restaurant-setup-step {
	display: flex;
	align-items: center;
	color: var(--sk-grey2);
	font-size: 14px;
	cursor: pointer;
}

.restaurant-setup-step.active {
	color: var(--sk-navy);
	font-weight: 600;
}

.restaurant-setup-step.invalid:not(.active) >>> .checkmark {
	border: 1px solid var(--sk-red);
	background: var(--sk-red);
}

.restaurant-setup-step.complete:not(.active) >>> .checkmark {
	border: 1px solid var(--sk-grey2);
	background: var(--sk-grey2);
}

.restaurant-setup-step >>> .checkmark-fill {
	display: none;
}

.restaurant-setup-step >>> .sk-radio {
	width: 10px;
	height: 10px;
	margin-left: 10px;
}

.sk-widget-section-title {
	display: flex;
	align-items: center;
}

.sk-modal-actions .button i {
	margin-left: 30px;
	margin-right: 0px;
}

.restaurant-logo-row {
	height: 75px;
}

.restaurant-logo-row .sk-widget-section-text-button-light {
	margin-top: 0 !important;
}

.restaurant-logo-preview {
	width: 75px;
	height: 75px;
	background: var(--sk-navy);
	border-radius: 5px;
	flex: 0 0 auto !important;
}

.restaurant-timezone-row {
	flex-direction: column;
	background: var(--sk-grey);
	border-radius: 5px;
	padding: 12px 15px;
	color: var(--sk-grey3);
}

.restaurant-timezone-row div:first-child {
	font-size: 14px;
	font-weight: 600;
}

.restaurant-timezone-row div:last-child {
	font-size: 12px;
	font-style: italic;
}

.restaurant-schedule-day {
	width: 100px;
	flex: 0 0 auto !important;
	background: var(--sk-grey);
	border-radius: 5px;
	text-align: center;
	line-height: 50px;
	font-size: 14px;
	color: var(--sk-grey3);
}

.add-user-section {
	border-bottom: 1px solid var(--sk-grey);
	margin-bottom: 30px;
	padding-bottom: 10px;
}

.user-step-details + .user-step-details {
	padding-top: 20px;
	margin-top: 20px;
	border-top: 1px solid var(--sk-grey);
}

.restaurant-step-detail {
	color: var(--sk-grey2);
	font-size: 12px;
}

.restaurant-step-detail.invalid,
.restaurant-step-detail.invalid .sk-muted-text,
.restaurant-step-detail.invalid b {
	color: var(--sk-red) !important;
}

.restaurant-step-detail:not(:last-child) {
	margin-bottom: 20px;
}

.restaurant-step-detail .sk-muted-text {
	font-style: italic;
	font-weight: 400;
}

.restaurant-step-detail .sk-muted-text,
.restaurant-step-detail b {
	margin-left: 11px;
}

.restaurant-step-detail b {
	color: var(--sk-grey3);
	font-weight: 600;
}

.sk-widget-section-text-button-light {
	font-size: 12px;
}

.sk-widget-section-text-button-light .optional-label {
	font-style: italic;
	margin-left: 10px;
}
</style>

<script>
import { v1 as uuidv1 } from "uuid"
import { CourierId } from "@arikgaisler/utils/enums/courierId"
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import { MURType } from "@/enums/murType"
import { UserRole } from "@/enums/userRole"
import apiUtils from "@/services/utils"
import utils from "@/utils/utils"
import moment from "moment"
import "moment-timezone"
import gapi from "@/utils/gapi"
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import currencyFilterMixin from "@/mixins/currency-filter-mixin"
import restaurantCommissionMixin from "@/mixins/restaurant-commission-mixin"
import phoneFilterMixin from "@/mixins/phone-filter-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import SkInput from "@/components/SkInput.vue"
import SkTelephoneInput from "@/components/SkTelephoneInput.vue"
import SkPopover from "@/components/SkPopover.vue"
import SkTimePicker from "@/components/SkTimePicker.vue"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"
import SkCheckbox from "@/components/SkCheckbox.vue"
import SkRadio from "@/components/SkRadio.vue"
import Loading from "vue-loading-overlay"
import TypeaheadInput from "@/components/TypeaheadInput.vue"
import VueCtkDateTimePicker from "vue-ctk-date-time-picker"
import FileUpload from "@/components/FileUpload.vue"
import SetupReviewStep from "@/components/modals/SetupReviewStep.vue"
import AddressTypeaheadInput from "@/components/AddressTypeaheadInput.vue"
import EstablishmentTypeaheadInput from "@/components/EstablishmentTypeaheadInput.vue"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue"

export default {
	name: "RestaurantSetupModal",
	mixins: [
		skModalMixin,
		currencyFilterMixin,
		restaurantCommissionMixin,
		phoneFilterMixin
	],
	components: {
		SkBaseModal,
		SkInput,
		SkTelephoneInput,
		SkPopover,
		SkTimePicker,
		SkDropdownSelect,
		SkCheckbox,
		SkRadio,
		Loading,
		TypeaheadInput,
		VueCtkDateTimePicker,
		SetupReviewStep,
		FileUpload,
		AddressTypeaheadInput,
		EstablishmentTypeaheadInput,
		ConfirmationModal
	},
	data: function () {
		return {
			loading: false,
			emailRegex: utils.emailRegex,
			requiredNumberRegex: /^[0-9][0-9]*$/,
			currentStep: 0,
			lastStep: 4,
			seenSteps: [],
			restaurant: {},
			restaurantLogoPreview: null,
			restaurantSchedule: [],
			restaurantTimezoneId: null,
			restaurantTimezone: "N/A",
			restaurantUsers: [],
			timezoneSyncRequired: false,
			deliveryMethods: [
				{
					id: CourierId.WALKING,
					text: "Self Delivery"
				},
				{
					id: CourierId.DOORDASH,
					text: "DoorDash"
				},
				{
					id: CourierId.CUT_CATS,
					text: "Cut Cats"
				},
				{
					id: CourierId.POSTMATES,
					text: "Postmates"
				},
				{
					id: CourierId.TOOKAN,
					text: "Bar Delivery"
				}
			],
			reviewingStepId: null
		}
	},
	computed: {
		address: {
			get: function () {
				return {
					placeId: this.restaurant.external_location_id,
					address: this.restaurant.address,
					city: this.restaurant.city,
					state: this.restaurant.state,
					zip: this.restaurant.zip
				}
			},
			set: function (address) {
				this.restaurant.external_location_id =
					address && address.placeId ? address.placeId : ""
				this.restaurant.address =
					address && address.address ? address.address : ""
				this.restaurant.city =
					address && address.city ? address.city : ""
				this.restaurant.state =
					address && address.state ? address.state : ""
				this.restaurant.zip = address && address.zip ? address.zip : ""
				this.timezoneSyncRequired = true
			}
		},
		establishment: {
			get: function () {
				return {
					placeId: this.restaurant.external_establishment_id,
					name: this.restaurant.external_establishment_name
				}
			},
			set: function (establishment) {
				this.restaurant.external_establishment_id =
					establishment && establishment.placeId
						? establishment.placeId
						: ""
				this.restaurant.external_establishment_name =
					establishment && establishment.name
						? establishment.name
						: ""
				if (!this.restaurant.name || this.restaurant.name.length == 0) {
					this.restaurant.name =
						establishment && establishment.shortName
							? establishment.shortName
							: ""
				}
				if (!this.isAddressSet) {
					this.loading = true
					gapi.getPlaceDetails(establishment.placeId).then(
						(result) => {
							this.loading = false
							const addressComponents = result
								? result.address_components
								: null
							const data =
								gapi.parseAddressComponents(addressComponents)
							data.placeId = establishment.placeId
							this.address = data
						}
					)
				}
			}
		},
		phone: {
			get: function () {
				return this.restaurant.phone_number
			},
			set: function (phone) {
				this.restaurant.phone_number = phone
			}
		},
		restaurantSlug: {
			get: function () {
				return this.restaurant.slug
			},
			set: function (slug) {
				if (this.restaurantLogoPreview) {
					this.$refs.restaurantLogoUploader.reset()
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Restaurant Setup",
						message:
							"Restaurant logo must be reuploaded if the slug has changed since uploading",
						type: NotificationType.WARNING
					})
				}
				this.restaurant.slug = slug
			}
		},
		formattedPhone() {
			if (this.phone) {
				return this.$options.filters.formatInternationalPhone(
					this.phone
				)
			}
			return null
		},
		formattedAddress() {
			if (this.isAddressSet) {
				return `${this.restaurant.address} ${this.restaurant.city}, ${this.restaurant.state}, ${this.restaurant.zip}`
			}
			return null
		},
		isEmailValid() {
			if (this.restaurant.email && this.restaurant.email.length > 0) {
				return utils.emailRegex.test(this.restaurant.email)
			}
			return true
		},
		isAddressSet() {
			let hasAddress =
				this.restaurant.address != undefined &&
				this.restaurant.address.length > 0
			let hasCity =
				this.restaurant.city != undefined &&
				this.restaurant.city.length > 0
			let hasState =
				this.restaurant.state != undefined &&
				this.restaurant.state.length > 0
			let hasZip =
				this.restaurant.zip != undefined &&
				this.restaurant.zip.length > 0
			return hasAddress && hasCity && hasState && hasZip
		},
		isAddressInvalid() {
			if (this.seenSteps.includes(0)) {
				return !this.isAddressSet
			}
			return false
		},
		restaurantDeliveryMethod: {
			get: function () {
				return this.deliveryMethods.find(
					(method) => method.id == this.restaurant.delivery_id
				)
			},
			set: function (newVal) {
				if (newVal) {
					this.restaurant.delivery_id = newVal.id
				}
			}
		},
		restaurantUsersAdded() {
			return this.restaurantUsers.filter((user) => {
				return user.name != "" || user.phone != "" || user.email != ""
			})
		},
		emptyRestaurantUsers() {
			return this.restaurantUsers.filter((user) => {
				return user.name == "" && user.phone == "" && user.email == ""
			})
		},
		restaurantUsersToReview() {
			return this.restaurantUsersAdded.map((user) => {
				let emailInvalid = true
				if (user.email.length > 0) {
					emailInvalid = utils.emailRegex.test(user.email) == false
				}
				let formattedPhone = user.phone
				if (user.phone.length > 0) {
					formattedPhone =
						this.$options.filters.formatInternationalPhone(
							user.phone
						)
				}
				return {
					name: user.name,
					email: user.email,
					phone: formattedPhone,
					emailInvalid: emailInvalid,
					nameInvalid: user.name.length == 0,
					phoneInvalid: user.name.length == 0
				}
			})
		},
		generalStepComplete() {
			return (
				this.isAddressSet &&
				this.isEmailValid &&
				this.restaurant.slug.length > 0 &&
				this.restaurant.name.length > 0
			)
		},
		generalStepInvalid() {
			return (
				!this.isAddressSet ||
				!this.isEmailValid ||
				this.restaurant.slug.length == 0 ||
				this.restaurant.name.length == 0
			)
		},
		generalStepProgress() {
			let progress = 0
			if (this.restaurant.name && this.restaurant.name.length > 0) {
				progress += 1
			}
			if (this.restaurant.slug && this.restaurant.slug.length > 0) {
				progress += 1
			}
			if (this.isAddressSet) {
				progress += 1
			}
			if (this.restaurant.email && this.restaurant.email.length > 0) {
				progress += 1
			}
			if (this.phone) {
				progress += 1
			}
			return progress
		},
		hoursStepComplete() {
			return this.hoursStepProgress == 7
		},
		hoursStepInvalid() {
			return this.restaurantSchedule.some(
				(day) => (day.open && !day.close) || (day.close && !day.open)
			)
		},
		hoursStepProgress() {
			const availableDays = this.restaurantSchedule.filter(
				(day) => day.open && day.close
			)
			return availableDays.length
		},
		adminStepComplete() {
			return this.adminStepProgress == 5
		},
		adminStepInvalid() {
			const emptySelfDeliveryCommission =
				this.selfDeliveryCommission === ""
			const emptyCourierCommission = this.courierCommission === ""
			const emptyEventCommission = this.eventCommission === ""
			const emptyToGoCommission = this.toGoCommission === ""
			return (
				emptySelfDeliveryCommission ||
				emptyCourierCommission ||
				emptyEventCommission ||
				emptyToGoCommission
			)
		},
		adminStepProgress() {
			let progress = 1
			if (this.selfDeliveryCommission !== "") {
				progress += 1
			}
			if (this.courierCommission !== "") {
				progress += 1
			}
			if (this.eventCommission !== "") {
				progress += 1
			}
			if (this.toGoCommission !== "") {
				progress += 1
			}
			return progress
		},
		usersStepComplete() {
			return (
				this.restaurantUsersAdded.length > 0 &&
				this.usersStepProgress == this.restaurantUsersAdded.length
			)
		},
		usersStepInvalid() {
			return this.restaurantUsersToReview.some(
				(user) =>
					user.emailInvalid || user.nameInvalid || user.phoneInvalid
			)
		},
		usersStepProgress() {
			return this.restaurantUsersToReview.filter(
				(user) =>
					!user.emailInvalid &&
					!user.nameInvalid &&
					!user.phoneInvalid
			).length
		},
		canCreateRestaurant() {
			return this.steps.every((step) => !step.isInvalid)
		},
		steps() {
			return [
				{
					id: 0,
					isActive: this.currentStep == 0,
					isInvalid: this.generalStepInvalid,
					isComplete: this.generalStepComplete,
					text: "General"
				},
				{
					id: 1,
					isActive: this.currentStep == 1,
					isInvalid: this.hoursStepInvalid,
					isComplete: this.hoursStepComplete,
					text: "Hours"
				},
				{
					id: 2,
					isActive: this.currentStep == 2,
					isInvalid: this.adminStepInvalid,
					isComplete: this.adminStepComplete,
					text: "Admin"
				},
				{
					id: 3,
					isActive: this.currentStep == 3,
					isInvalid: this.usersStepInvalid,
					isComplete: this.usersStepComplete,
					text: "User"
				},
				{
					id: 4,
					isActive: this.currentStep == 4,
					isComplete:
						this.generalStepComplete &&
						this.hoursStepComplete &&
						this.adminStepComplete &&
						this.usersStepComplete,
					text: "Review"
				}
			]
		}
	},
	methods: {
		handleOptions() {
			this.$refs.restaurantLogoUploader.reset()
			this.resetValidation("restaurantNameInput")
			this.resetValidation("restaurantEmailInput")
			this.resetValidation("restaurantSlugInput")
			this.resetValidation("selfDeliveryCommissionInput")
			this.resetValidation("courierCommissionInput")
			this.resetValidation("eventCommissionInput")
			this.resetValidation("toGoCommissionInput")
			const defaultComissions = [
				{
					commission: this.selfDeliveryCommission,
					type: MURType.SELF_DELIVERY
				},
				{
					commission: this.courierCommission,
					type: MURType.COURIER_DELIVERY
				},
				{
					commission: this.eventCommission,
					type: MURType.EVENT
				},
				{
					commission: this.toGoCommission,
					type: MURType.TO_GO
				}
			]
			this.restaurant = {
				logo: "",
				slug: "",
				name: "",
				email: "",
				phone_number: "",
				external_location_id: null,
				external_establishment_id: null,
				external_establishment_name: "",
				address: "",
				state: "",
				city: "",
				zip: "",
				delivery_id: CourierId.WALKING,
				commissions: defaultComissions
			}
			const days = [
				{
					name: "Sunday",
					dow: 6
				},
				{
					name: "Monday",
					dow: 0
				},
				{
					name: "Tuesday",
					dow: 1
				},
				{
					name: "Wednesday",
					dow: 2
				},
				{
					name: "Thursday",
					dow: 3
				},
				{
					name: "Friday",
					dow: 4
				},
				{
					name: "Saturday",
					dow: 5
				}
			]
			this.restaurantSchedule = days.map((day) => {
				return {
					dow: day.dow,
					day: day.name,
					open: null,
					close: null
				}
			})
			this.reviewingStepId = null
			this.restaurantTimezoneId = null
			this.restaurantTimezone = "N/A"
			this.restaurantUsers = []
			this.addNewUser()
		},
		setLoader(loading) {
			this.loading = loading
		},
		logoUploadClicked(event) {
			if (!this.restaurant.slug) {
				event.stopPropagation()
				event.preventDefault()
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Restaurant Setup",
					message: "A slug is required before uploading a logo",
					type: NotificationType.WARNING
				})
			}
		},
		logoRemoveClicked(event) {
			event.stopPropagation()
			event.preventDefault()
			this.$refs.restaurantLogoUploader.reset()
		},
		setRestaurantLogo(img) {
			this.restaurant.logo = img
		},
		setRestaurantLogoPreview(src) {
			this.restaurantLogoPreview = src
		},
		async updateTimezone() {
			this.timezoneSyncRequired = false
			if (!this.isAddressSet) {
				this.timezone = "N/A"
			} else {
				if (this.$gmapApiPromiseLazy) {
					await this.$gmapApiPromiseLazy()
				}
				const fullAddress = `${this.restaurant.address}, ${this.restaurant.city}, ${this.restaurant.state}, ${this.restaurant.zip}`
				let geocoder = new google.maps.Geocoder()
				geocoder.geocode(
					{ address: fullAddress },
					async (results, status) => {
						if (status == "OK" && results && results[0]) {
							const location = results[0].geometry.location
							const lat = location.lat()
							const lng = location.lng()
							const time = Math.round(new Date().getTime() / 1000)
							const timezoneResponse = await apiUtils.getTimezone(
								lat,
								lng,
								time,
								process.env.VUE_APP_GAPI_NON_REFERRER_KEY
							)
							if (timezoneResponse.status == 200) {
								this.restaurantTimezoneId =
									timezoneResponse.timeZoneId
								this.restaurantTimezone = moment()
									.tz(timezoneResponse.timeZoneId)
									.format("z")
							}
						}
					}
				)
			}
		},
		nextStep() {
			if (this.currentStep < this.lastStep) {
				this.setStep(this.currentStep + 1)
			}
		},
		reviewStep(id) {
			this.reviewingStepId = id
		},
		async setStep(id, event) {
			if (!this.seenSteps.includes(this.currentStep)) {
				this.seenSteps.push(this.currentStep)
			}
			if (event) {
				event.stopPropagation()
			}
			if (this.currentStep == 0) {
				if (this.timezoneSyncRequired) {
					this.updateTimezone()
				}
				this.validateInput("restaurantNameInput")
				if (this.restaurant.email) {
					this.validateInput("restaurantEmailInput")
				} else {
					this.resetValidation("restaurantEmailInput")
				}
				this.validateInput("restaurantSlugInput")
			}
			if (this.currentStep == 2) {
				this.validateInput("selfDeliveryCommissionInput")
				this.validateInput("courierCommissionInput")
				this.validateInput("eventCommissionInput")
				this.validateInput("toGoCommissionInput")
			}
			if (this.currentStep == 3) {
				this.restaurantUsersAdded.forEach((user) => {
					this.validateInput(`userNameInput${user.uuid}`)
					this.validateInput(`userEmailInput${user.uuid}`)
					if (user.phone.length > 0) {
						this.validateInput(`userPhoneInput${user.uuid}`)
					} else {
						this.resetValidation(`userPhoneInput${user.uuid}`)
					}
				})
				this.emptyRestaurantUsers.forEach((user) => {
					this.resetValidation(`userNameInput${user.uuid}`)
					this.resetValidation(`userEmailInput${user.uuid}`)
					this.resetValidation(`userPhoneInput${user.uuid}`)
				})
			}
			this.currentStep = id
		},
		validateInput(inputRef) {
			if (this.$refs[inputRef]) {
				if (this.$refs[inputRef].length > 0) {
					this.$refs[inputRef][0].validate()
				} else {
					this.$refs[inputRef].validate()
				}
			}
		},
		resetValidation(inputRef) {
			if (this.$refs[inputRef]) {
				if (this.$refs[inputRef].length > 0) {
					this.$refs[inputRef][0].resetValidation()
				} else {
					this.$refs[inputRef].resetValidation()
				}
			}
		},
		newUser() {
			return {
				name: "",
				email: "",
				phone: "",
				sendActivation: true,
				uuid: uuidv1()
			}
		},
		addNewUser() {
			this.restaurantUsers.push(this.newUser())
		},
		removeUser(user) {
			if (this.restaurantUsers.length > 1) {
				this.restaurantUsers = this.restaurantUsers.filter(
					(restaurantUser) => user.uuid != restaurantUser.uuid
				)
			} else {
				const user = this.newUser()
				this.restaurantUsers.splice(0, 1, user)
				this.resetValidation(`userNameInput${user.uuid}`)
				this.resetValidation(`userEmailInput${user.uuid}`)
				this.resetValidation(`userPhoneInput${user.uuid}`)
			}
		},
		async createRestaurantUsers(restaurantId) {
			if (this.restaurantUsersAdded.length > 0) {
				const users = this.restaurantUsersAdded.map((user) => {
					return {
						id: 0,
						entities: [{ id: restaurantId, type: 2 }],
						display_name: user.name,
						email: user.email,
						phone: user.phone,
						role_id: UserRole.RESAURANT_ADMIN
					}
				})
				const addAdminsPromises = users.map((user) => {
					return this.$store.dispatch("addAdminUser", user)
				})
				const admins = await Promise.all(addAdminsPromises)
				const adminsToEmail = []
				let atLeaseOneFailure = false
				admins.forEach((admin, i) => {
					const user = this.restaurantUsersAdded[i]
					if (admin && admin.id && user.sendActivation) {
						adminsToEmail.push(admin)
					}
					if (admin == false) {
						atLeaseOneFailure = true
					}
				})
				if (atLeaseOneFailure) {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Restaurant Setup",
						message: "Failed to create one or more users",
						type: NotificationType.WARNING
					})
					this.bugsnag.notify(
						"Failed to create one or more users",
						(event) => {
							event.addMetadata("error", {
								usersData: admins
							})
						}
					)
				}
				await this.sendActivationEmails(adminsToEmail)
			}
			return true
		},
		async sendActivationEmails(users) {
			if (users.length > 0) {
				const activationPromises = users.map((user) => {
					return this.$store.dispatch(
						"resendAdminUserActivationEmail",
						user
					)
				})
				const responses = await Promise.all(activationPromises)
				const atLeastOneFailure = responses.some(
					(response) => response == false
				)
				if (atLeastOneFailure) {
					let error =
						"Failed to send an activation email to one or more users"
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Restaurant Setup",
						message: error,
						type: NotificationType.WARNING
					})
					this.bugsnag.notify(error, (event) => {
						event.addMetadata("error", {
							users: users
						})
					})
				}
			}
			return true
		},
		async createRestaurant() {
			try {
				if (!this.canCreateRestaurant) {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Restaurant Setup",
						message: "Required fields are missing or invalid",
						type: NotificationType.ERROR
					})
					const firstInvalidStep = this.steps.find(
						(step) => step.isInvalid
					)
					this.reviewingStepId = firstInvalidStep.id
					return false
				}

				const availableSchedules = this.restaurantSchedule.filter(
					(schedule) => schedule.open && schedule.close
				)

				if (
					availableSchedules.length > 0 &&
					!this.restaurantTimezoneId
				) {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Restaurant Setup",
						message:
							"The restaurant address does not have a valid timezone",
						type: NotificationType.ERROR
					})
					return
				}

				this.loading = true

				if (availableSchedules.length > 0) {
					const isDST = moment().isDST()
					this.restaurant.opening_hours = availableSchedules.map(
						(schedule) => {
							const openTime = moment.tz(
								schedule.open,
								"hh:mm A",
								this.restaurantTimezoneId
							)
							const closeTime = moment.tz(
								schedule.close,
								"hh:mm A",
								this.restaurantTimezoneId
							)

							if (!isDST) {
								openTime.subtract(60, "m")
								closeTime.subtract(60, "m")
							}

							return {
								day_of_week: schedule.dow,
								open_time: openTime.utc().format("HH:mm:ss"),
								close_time: closeTime.utc().format("HH:mm:ss")
							}
						}
					)
				} else {
					this.restaurant.opening_hours = []
				}

				const restaurant = await this.$store.dispatch(
					"addRestaurant",
					this.restaurant
				)
				if (restaurant) {
					await this.createRestaurantUsers(restaurant.id)
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Restaurant Setup",
						message: "The restaurant was created successfully!"
					})
				} else {
					throw "Restaurant Setup failed to create restaurant"
				}
				this.close()
				this.loading = false
			} catch (e) {
				this.loading = false
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Restaurant Setup",
					message: "Something went wrong, please try again.",
					type: NotificationType.ERROR
				})
				this.bugsnag.notify(e, (event) => {
					event.addMetadata("error", {
						restaurantData: this.restaurant
					})
				})
			}
		},
		cancel() {
			this.$refs.confirmationModal
				.open({
					title: "You have unsaved changes",
					body: "Are you sure you want to close this modal without saving your changes?",
					confirmation: "Continue",
					cancel: "Never Mind"
				})
				.then((confirmed) => {
					if (confirmed) {
						this.close()
					}
				})
		},
		closed() {
			this.loading = false
			this.currentStep = 0
			this.seenSteps = []
		}
	}
}
</script>