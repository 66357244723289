<template>
	<div class="vld-parent">
		<loading :active.sync="loading" :is-full-page="false"></loading>
		<TabbedInsightWidget :tabs="tabs" @tabClicked="tabClicked">
			<div class="header-title" slot="title">
				<b>Top Orders</b>
				Time of Day
			</div>
			<div class="total-tabbed-chart-content" slot="content">
				<SkChart
					:type="'Line'"
					:data="chartData"
					:options="chartOptions"
					:tooltip="true"
					:eventHandlers="eventHandlers"
				/>
			</div>
		</TabbedInsightWidget>
	</div>
</template>

<style scoped>
.header-title {
	font-size: 14px;
	color: var(--sk-dark-navy);
}

* >>> .sk-tabs {
	display: flex;
	justify-content: space-between;
	padding: 0 15px;
}

* >>> .sk-tabs .button-text {
	font-size: 12px;
	padding: 0 5px;
}
</style>

<script>
import chartist from "chartist"
import TabbedInsightWidget from "@/components/insights/TabbedInsightWidget.vue"
import Loading from "vue-loading-overlay"
import SkChart from "@/components/SkChart.vue"

export default {
	name: "TopOrdersHourlyChart",
	components: {
		TabbedInsightWidget,
		Loading,
		SkChart
	},
	data: function () {
		return {
			activeTabId: 1,
			chartOptions: {
				height: "185px",
				fullWidth: true,
				axisX: {
					offset: 35,
					labelOffset: {
						x: -10,
						y: 10
					}
				},
				axisY: {
					offset: 0,
					showLabel: false,
					showGrid: false
				},
				chartPadding: {
					top: 20,
					right: 5,
					bottom: 0,
					left: 5
				},
				showArea: true,
				showLine: false,
				lineSmooth: false
			},
			eventHandlers: [
				{
					event: "draw",
					fn(event) {
						if (event.type === "area") {
							event.element.animate({
								d: {
									begin: 2000 * event.index,
									dur: 2000,
									from: event.path
										.clone()
										.scale(1, 0)
										.translate(0, event.chartRect.height())
										.stringify(),
									to: event.path.clone().stringify(),
									easing: chartist.Svg.Easing.easeOutQuint
								}
							})
						} else if (event.type === "point") {
							let delay = 1000 + event.index * 20
							event.element.animate({
								opacity: {
									begin: delay,
									dur: 1000,
									from: 0,
									to: 1,
									easing: chartist.Svg.Easing.easeOutQuint
								}
							})
						}
					}
				}
			]
		}
	},
	computed: {
		loading() {
			return this.$store.state.insightsModule.topOrdersByHour.loading
		},
		tabs() {
			let tabs = [
				{
					id: 1,
					text: "Mon"
				},
				{
					id: 2,
					text: "Tue"
				},
				{
					id: 3,
					text: "Wed"
				},
				{
					id: 4,
					text: "Thu"
				},
				{
					id: 5,
					text: "Fri"
				},
				{
					id: 6,
					text: "Sat"
				},
				{
					id: 7,
					text: "Sun"
				}
			]
			return tabs.map((tab) => {
				tab.active = tab.id == this.activeTabId
				tab.disabled = this.disabledDows.includes(tab.id)
				return tab
			})
		},
		disabledDows() {
			const dows = [1, 2, 3, 4, 5, 6, 7]
			const data = this.$store.state.insightsModule.topOrdersByHour.data
			if (data) {
				data.totals.forEach((total) => {
					let index = dows.indexOf(parseFloat(total.dayOfWeek))
					if (index > -1) {
						dows.splice(index, 1)
					}
				})
			}
			return dows
		},
		chartData() {
			const data = this.$store.state.insightsModule.topOrdersByHour.data
			let series = [0, 0, 0, 0, 0, 0, 0]
			let labels = ["6A", "9A", "12P", "3P", "6P", "9P", "12A"]
			let hoursToSeriesIndex = {
				5: 0,
				6: 0,
				7: 0,
				8: 1,
				9: 1,
				10: 1,
				11: 2,
				12: 2,
				13: 2,
				14: 3,
				15: 3,
				16: 3,
				17: 4,
				18: 4,
				19: 4,
				20: 5,
				21: 5,
				22: 5,
				23: 6,
				0: 6,
				1: 6
			}

			if (data && data.totals) {
				const dowTotal = data.totals.find(
					(total) => total.dayOfWeek == this.activeTabId
				)
				if (dowTotal) {
					dowTotal.sub_periods.forEach((total) => {
						let index = hoursToSeriesIndex[total.hourOfDay]
						if (index != undefined) {
							series[index] += total.avg
						}
					})
				}
			}

			return {
				series: [series],
				labels: labels
			}
		}
	},
	methods: {
		tabClicked(tab) {
			this.activeTabId = tab.id
		}
	},
	watch: {
		disabledDows() {
			if (this.tabs.length > 0 && this.disabledDows >= 7) {
				return
			}

			const activeTabInvalid = this.disabledDows.includes(this.activeTabId)
			if (activeTabInvalid) {
				const newActiveTab = this.tabs.find(tab => !tab.disabled)
				this.activeTabId = newActiveTab ? newActiveTab.id : 1
			}
		}
	}
}
</script>