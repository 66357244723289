export const UserRole = {
  "DEFAULT": 0, 
  "SUPER_ADMIN": 1,
  "ADMIN": 2,
  "SUPPORT": 3,
  "BUSINESS_ADMIN": 4,
  "RESAURANT_ADMIN": 5,
  "RESTAURANT_APP": 6,
  "POS_SYSTEM": 7
}

export const UserSubRole = {
  "ADMIN": 0,
  "STAFF": 1
}