<template>
	<div class="order-reports">
		<div v-for="date in ordersByDate" :key="date.id">
			<div class="sk-row order-date-seperator">
				<div></div>
				{{ date.time }}
				<div></div>
			</div>
			<transition-group
				v-if="!loading && ordersFound"
				name="list"
				tag="div"
				appear
			>
				<OrderReport
					v-for="order in date.orders"
					:key="order.id"
					:order="order"
					@openReceipt="openReceiptModal"
					@openException="openExceptionModal"
					@openRefund="openRefundModal"
					@openDelivery="openDeliveryModal"
					@disputeCharge="openActiveOrderDetails(order, true)"
					@openNotes="openNotesModal"
					@repushOrder="repushOrder"
				/>
			</transition-group>
		</div>
		<EmptyPlaceholderWidget
			v-if="!loading && !ordersFound"
			:title="'No Orders Found'"
		/>
		<PaginationFooter
			v-show="!loading && ordersFound"
			ref="paginationFooter"
			:contentText="'Orders'"
			:pageSize="20"
			:total="totalOrderReportsCount"
			@pageChanged="filterPage"
		/>
		<ExceptionModal ref="exceptionModal" :type="1" />
		<RefundModal ref="refundModal" />
		<SendReceiptModal ref="sendReceiptModal" :type="1" />
		<OrderDeliveryModal ref="orderDeliveryModal" />
		<ActiveOrderDetailsModal ref="activeOrderDetailsModal" />
		<AddOrderNoteModal ref="orderNoteModal" />
	</div>
</template>

<style scoped>
.order-date-seperator {
	align-items: center;
	color: var(--sk-grey2);
}

.order-date-seperator > div {
	height: 1px;
	background: var(--sk-grey2);
	opacity: 0.5;
}

.order-date-seperator > div:last-child {
	margin-left: 20px;
}

.order-reports {
	height: 100%;
	display: flex;
	flex-direction: column;
}
</style>

<script>
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import moment from "moment"
import loaderMixin from "@/mixins/loader-mixin"
import EmptyPlaceholderWidget from "@/components/EmptyPlaceholderWidget.vue"
import OrderReport from "@/components/orderreport/OrderReport.vue"
import PaginationFooter from "@/components/PaginationFooter.vue"
import ExceptionModal from "@/components/modals/ExceptionModal.vue"
import RefundModal from "@/components/modals/RefundModal.vue"
import SendReceiptModal from "@/components/modals/SendReceiptModal.vue"
import OrderDeliveryModal from "@/components/modals/OrderDeliveryModal.vue"
import ActiveOrderDetailsModal from "@/components/modals/ActiveOrderDetailsModal.vue"
import AddOrderNoteModal from "@/components/modals/AddOrderNoteModal.vue"

export default {
	name: "OrderReports",
	mixins: [loaderMixin],
	components: {
		EmptyPlaceholderWidget,
		OrderReport,
		PaginationFooter,
		ExceptionModal,
		RefundModal,
		SendReceiptModal,
		OrderDeliveryModal,
		ActiveOrderDetailsModal,
		AddOrderNoteModal
	},
	props: {
		filterVals: Object
	},
	data: function () {
		return {
			pageRange: {
				page: 1,
				start: 0,
				end: 20
			}
		}
	},
	computed: {
		ordersByDate() {
			const ordersByDate = {}
			this.$store.state.ordersModule.orderReports.forEach((order) => {
				const createdMoment = moment.utc(order.created_time_local)
				const createdTime = createdMoment.format("MMM DD")
				if (!ordersByDate[createdTime]) {
					ordersByDate[createdTime] = {
						orders: [],
						time: createdMoment.format("MMM DD"),
						id: moment(
							createdMoment.format("YYYY-MM-DD"),
							"YYYY-MM-DD"
						).unix()
					}
				}
				ordersByDate[createdTime].orders.push(order)
			})
			return Object.values(ordersByDate).sort((a, b) => b.id - a.id)
		},
		ordersFound() {
			return this.$store.state.ordersModule.orderReports.length > 0
		},
		totalOrderReportsCount() {
			return this.$store.state.ordersModule.totalOrderReportsCount
		},
		loading() {
			return this.$store.state.ordersModule.loading
		}
	},
	methods: {
		filterPage(range) {
			this.pageRange = range
			this.getOrders()
		},
		getOrders() {
			this.$store.dispatch(
				"ordersModule/searchOrders",
				Object.assign(
					{
						pageStart: this.pageRange.start,
						pageSize: 20,
						noPlaceholdDates: true
					},
					this.filterVals
				)
			)
		},
		refreshOrders() {
			if (this.pageRange.page != 1) {
				this.$refs.paginationFooter.pageChanged(1)
			} else {
				this.getOrders()
			}
		},
		openReceiptModal(order) {
			this.$store.dispatch("ordersModule/setCurrentOrder", {
				order: order
			})
			this.$refs.sendReceiptModal.open()
		},
		openExceptionModal(order) {
			this.$store.dispatch("ordersModule/setCurrentOrder", {
				order: order
			})
			this.$refs.exceptionModal.open()
		},
		openRefundModal(order) {
			this.$store.dispatch("ordersModule/setCurrentOrder", {
				order: order
			})
			this.$refs.refundModal.open()
		},
		async openDeliveryModal(order) {
			this.$store.dispatch("ordersModule/setCurrentOrder", {
				order: order
			})
			this.$refs.orderDeliveryModal.open()
		},
		openActiveOrderDetails(order, disputeCharge) {
			this.$refs.activeOrderDetailsModal.open({
				order: order,
				disputeCharge: disputeCharge
			})
		},
		async openNotesModal(order) {
			this.$store.dispatch("ordersModule/setCurrentOrder", {
				order: order
			})
			this.$refs.orderNoteModal.open()
		},
		async exportOrders() {
			this.toggleLoader(true)
			const payload = Object.assign({}, this.filterVals, {
				totalOrders: this.totalOrderReportsCount
			})
			this.$store
				.dispatch("ordersModule/exportOrders", payload)
				.then((success) => {
					this.toggleLoader(false)
					if (success) {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Order Reports",
							message: "Orders successfully exported!"
						})
					} else {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Order Reports",
							message: "Failed to export orders",
							type: NotificationType.ERROR
						})
					}
				})
				.catch(() => {
					this.toggleLoader(false)
				})
		},
		repushOrder(order) {
			this.$emit("repushOrder", order)
		}
	},
	beforeDestroy: async function () {
		this.$store.dispatch("ordersModule/clearOrderReports")
	},
	watch: {
		loading(newVal) {
			this.toggleLoader(newVal)
		},
		filterVals: {
			immediate: true,
			handler() {
				this.refreshOrders()
			}
		}
	}
}
</script>