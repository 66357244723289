<template>
	<div class="restaurant-menus">
		<slot v-if="pagedMenus.length > 0">
			<div class="sk-table sk-table-default">
				<SkTableHeader :columns="tableColumns" v-model="sortBy" />
				<transition-group
					name="list"
					tag="div"
					class="sk-table-body"
					appear
				>
					<RestaurantMenuLineItem
						v-for="menu in pagedMenus"
						:key="menu.id"
						:menu="menu"
						:pairings="menuPairings[menu.id]"
						@delete="deleteMenu"
					/>
				</transition-group>
			</div>
			<PaginationFooter
				:contentText="'Menus'"
				:pageSize="20"
				:total="menus.length"
				@pageChanged="filterPage"
			/>
		</slot>
		<EmptyPlaceholderWidget
			v-else-if="canAddBusinessMenu"
			:title="`No Menus`"
			:description="`You don’t have any menus yet. Add a menu to start serving food to your partners today!`"
			:buttonText="'New Menu'"
			@actionClicked="handlePlaceholderClicked"
		/>
		<EmptyPlaceholderWidget
			v-else
			:title="`No Menus`"
			:description="`You don’t have any menus yet. Add a menu to start serving food to your partners today!`"
		/>
		<ConfirmationModal ref="confirmationModal" />
	</div>
</template>

<style scoped>
.restaurant-menus {
	height: 100%;
	display: flex;
	flex-direction: column;
}
</style>

<script>
import { CourierId } from "@arikgaisler/utils/enums/courierId"
import { ConfirmationModalType } from "@/enums/confirmationModalType"
import { PermissionMethod } from "@/utils/permissions"
import menuTypeFormatterMixin from '@/mixins/menu-type-formatter-mixin'
import RestaurantMenuLineItem from "@/components/restaurant/RestaurantMenuLineItem.vue"
import PaginationFooter from "@/components/PaginationFooter.vue"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue"
import EmptyPlaceholderWidget from "@/components/EmptyPlaceholderWidget.vue"
import SkTableHeader from "@/components/SkTableHeader.vue"

export default {
	name: "RestaurantMenus",
	mixins: [menuTypeFormatterMixin],
	components: {
		RestaurantMenuLineItem,
		PaginationFooter,
		ConfirmationModal,
		EmptyPlaceholderWidget,
		SkTableHeader
	},
	data: function () {
		return {
			tableColumns: [
				{
					name: "Name",
					id: 0
				},
				{
					name: "Type",
					id: 1
				},
				{
					name: "Hours",
					id: 2,
					sortable: false
				},
				{
					name: "Delivery",
					id: 3
				},
				{
					name: "Paired With",
					id: 4,
					sortable: false
				},
				{
					name: "Actions",
					id: 5,
					sortable: false
				}
			],
			sortBy: {
				id: -1,
				asc: 0
			},
			pageRange: {
				page: 1,
				start: 0,
				end: 20
			}
		}
	},
	computed: {
		restaurant() {
			return this.$store.state.restaurantsModule.currentRestaurant
		},
		canAddBusinessMenu() {
			return this.$store.state.permissions.includes(PermissionMethod.NEW_RESTAURANT_MENU)
		},
		menus() {
			let menus = this.restaurant.menus.map((menu) => {
				return {
					name: menu.name,
					id: menu.id,
					restaurant_id: menu.restaurant_id,
					type: menu.type,
					opening_hours: menu.opening_hours,
					courier_id: menu.courier_id,
					menuTypeText: this.$options.filters.menuTypeText(menu.type),
					courierText: this.textForCourierId(menu.courier_id)
				}
			})

			if (this.sortBy.id > -1) {
				menus = menus.sort((a, b) => {
					let key = ""
					switch (this.sortBy.id) {
						case 0:
							key = "name"
							break
						case 1:
							key = "menuTypeText"
							break
						case 3:
							key = "courierText"
							break
					}
					let textA = a[key].toUpperCase
						? a[key].toUpperCase()
						: a[key]
					let textB = b[key].toUpperCase
						? b[key].toUpperCase()
						: b[key]
					if (this.sortBy.asc) {
						return textA < textB ? -1 : textA > textB ? 1 : 0
					} else {
						return textA > textB ? -1 : textA < textB ? 1 : 0
					}
				})
			}

			return menus
		},
		canLinkToBusiness() {
			return this.$store.state.permissions.includes(PermissionMethod.LINK_TO_BUSINESS)
		},
		pagedMenus() {
			return this.menus.slice(this.pageRange.start, this.pageRange.end)
		},
		menuPairings() {
			let pairings = {}

			this.menus.forEach((menu) => {
				pairings[menu.id] = []
			})

			Object.values(
				this.$store.state.restaurantsModule.restaurantBusinesses
			).forEach((business) => {
				if (business.menus) {
					business.menus.forEach((menu) => {
						var link = ""
						if (this.canLinkToBusiness) {
							link = `/business/${business.slug}/menu/${menu.id}`
						}
						if (pairings[menu.id]) {
							pairings[menu.id].push({
								id: business.id,
								name: business.name,
								slug: business.slug,
								link: link
							})
						}
					})
				}
			})

			return pairings
		}
	},
	methods: {
		filterPage(range) {
			this.pageRange = range
		},
		async deleteMenu(menu) {
			const confirmed = await this.$refs.confirmationModal.open({
				title: `Are you sure you want to delete ${menu.name}?`,
				confirmation: "Delete Menu",
				confirmationIcon: "sk-icon-trash-alt-solid",
				cancel: "Keep Menu",
				type: ConfirmationModalType.RED
			})
			if (confirmed) {
				this.$store.dispatch("restaurantsModule/deleteMenu", menu.id)
			}
		},
		handlePlaceholderClicked() {
			this.$emit("openNewMenuModal")
		},
		textForCourierId(courierId) {
			switch (courierId) {
				case CourierId.WALKING:
					return "Self-Delivery"
				case CourierId.CUT_CATS:
					return "Cut Cats"
				case CourierId.TOOKAN:
					return "Bar Self Delivery"
				case CourierId.POSTMATES:
					return "PostMates"
				case CourierId.DOORDASH:
					return "DoorDash"
				case CourierId.RELAY:
					return "Relay"
			}
			return "N/A"
		}
	}
}
</script>