var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sk-row sk-widget-alt active-order",on:{"click":_vm.openOrderDetails}},[_c('div',{staticClass:"pill-alt pill-alt-grey"},[_vm._v(_vm._s(_vm.orderTime))]),_c('div',[_c('div',{staticClass:"order-title"},[_c('div',{staticClass:"order-name"},[_vm._v(_vm._s(_vm.order.user_name))]),(_vm.isInRoomBilling && _vm.paymentStatus != null)?_c('div',{class:_vm.inRoomBillingStatusBadgeClasses},[_c('i',{class:_vm.inRoomBillingStatusIcon}),_c('b',[_vm._v("Charge to Room:")]),_c('i',[_vm._v(_vm._s(_vm.inRoomBillingStatusText))])]):_vm._e()]),_c('div',{staticClass:"order-info"},[_c('span',[_vm._v("#"+_vm._s(_vm.order.id))]),(_vm.hasTable)?_c('span',[_vm._v(_vm._s(_vm.tableNumber))]):_vm._e(),(_vm.showBusinessName)?_c('span',[_vm._v(_vm._s(_vm.businessName))]):_vm._e()])]),_c('div',{staticClass:"order-status"},[_c('div',{staticClass:"order-status-title"},[_c('b',[_vm._v(_vm._s(_vm.orderStatusTitle))]),_vm._v(" "+_vm._s(_vm.orderStatusTime)+" ")]),_c('div',{staticClass:"order-status-progress"},[_c('div',{class:{
					complete: !_vm.isCancelledOrder,
					danger: _vm.isCancelledOrder
				}}),_c('div',{class:{
					complete: _vm.accepted && !_vm.isCancelledOrder
				}}),_c('div',{class:{
					complete: _vm.pickedUp && !_vm.isCancelledOrder
				}}),_c('div',{class:{
					complete: _vm.delivered && !_vm.isCancelledOrder
				}})])]),(_vm.orderActions)?_c('SkDropdownSelect',{attrs:{"options":_vm.orderActions,"iconClasses":'sk-icon-ellipsis-v-regular',"showSelectedOption":false,"position":'left'},on:{"selected":_vm.orderActionSelected}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }