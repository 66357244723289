<template>
	<SkBaseModal class="sk-modal-alt" :isOpen="isOpen" @close="cancel">
		<button class="button button-icon button-close" @click="cancel()">
			<i class="sk-icon-times-regular"></i>
		</button>
		<div class="sk-modal-alt-title">
			<img src="/images/chowly-logo.png" alt="Chowly Logo" />
			<h1>
				Chowly
			</h1>
		</div>
		<div class="sk-row">
			<SkInput
				v-model="apiKey"
				ref="apiKeyInput"
				:name="'API Key'"
				:required="true"
			/>
		</div>
		<div class="sk-row">
			<SkInput
				v-model="locationID"
				ref="locationIDInput"
				:name="'Location ID'"
				:required="true"
			/>
		</div>
		<div class="sk-modal-actions">
			<button class="button button-text" @click="cancel">
				Cancel
			</button>
			<button class="button button-primary" @click="saveIntegration">
				{{ confirmBtnText }}
			</button>
		</div>
	</SkBaseModal>
</template>

<script>
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import skPromiseModalMixin from "@/mixins/modals/sk-promise-modal-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import SkInput from "@/components/SkInput.vue"

export default {
	name: "ChowlyIntegrationModal",
	mixins: [skPromiseModalMixin],
	components: {
		SkBaseModal,
		SkInput
	},
	data: function () {
		return {
			apiKey: "",
			locationID: "",
			editing: false
		}
	},
	computed: {
		confirmBtnText() {
			return this.editing ? "Save Integration" : "Add Integration"
		}
	},
	methods: {
		handleOptions(options) {
            if (options.integration) {
                this.editing = true
				this.apiKey = options.integration.apiKey ? options.integration.apiKey : ""
				this.locationID = options.integration.locationId ? options.integration.locationId : ""
            } else {
                this.editing = false
            }
		},
		saveIntegration() {
			const isAPIkeyValid = this.$refs.apiKeyInput.validate()
			// const isLocationIDValid = this.$refs.locationIDInput.validate()
			if (!isAPIkeyValid) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Chowly Integration",
                    message: "An API key is required",
                    type: NotificationType.WARNING
				})
				return
			}
			this.confirm({
				apiKey: this.apiKey,
				locationID: this.locationID
			})
		}
	}
}
</script>