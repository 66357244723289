import ActionTypes from "./action-types"
import MutationTypes from "./mutation-types"
import api from "@/api"
import Vue from "vue"

const vue = new Vue()

export default {
	async [ActionTypes.GET_ACTIVITY_FEED]({ commit, state, dispatch }, payload) {
		try {
			if ((state.hasMore || (payload && payload.offset == 0)) && !state.fetching) {
				commit(MutationTypes.GETTING_ACTIVITY_FEED)
				const response = await api.getFeed({
					limit: state.limit,
					offset: state.offset
				})
				commit(MutationTypes.GOT_ACTIVITY_FEED, response.data)
				if (payload && payload.autoRead) {
					dispatch(ActionTypes.READ_ACTIVITES, response.data.activities)
				}
			}
			return true
		} catch (e) {
			commit(MutationTypes.GOT_ACTIVITY_FEED, [])
			vue.bugsnag.notify(
                "Failed to get activity feed",
                event => {
                    event.addMetadata("error", {
                        error: e
                    })
                }
            )
			return false
		}
	},
	[ActionTypes.ADD_ACTIVITY]({ commit, dispatch }, payload) {
		commit(MutationTypes.ADDED_ACTIVITY, payload)
		dispatch(ActionTypes.READ_ACTIVITES, [payload.id])
	},
	[ActionTypes.READ_ACTIVITES](store, activities) {
		if (activities && activities.length > 0) {
			let newActivityIds = activities
				.filter(activity => activity.id != null && activity.read_date == null)
				.map(activity => activity.id)

			if (newActivityIds.length > 0) {
				api.readFeedActivities({
					ids: newActivityIds
				})
			}
		}
	},
	[ActionTypes.RESET_ACTIVITY_FEED]({ commit }) {
		commit(MutationTypes.RESET_ACTIVITY_FEED)
	}
}
