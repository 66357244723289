<template>
	<div class="sk-row">
		<div class="sk-widget-alt">
			<div class="attached-exception-header">
				<div @click="toggleExceptionExpand(exception)">
					<div class="attached-exception-title">
						Exception Attached
					</div>
					<div class="attached-exception-amount">
						<b>{{ exception.amount | currency }}</b>
						<span>for</span>
						<b>{{ orderId }}</b>
					</div>
				</div>
				<div>
					<div
						v-if="approved"
						class="pill-alt pill-alt-green approved-status-badge"
					>
						<i class="sk-icon-check-regular"></i>
					</div>
					<SkDropdownSelect
						:options="exceptionActions"
						:iconClasses="'sk-icon-ellipsis-v-regular'"
						:showSelectedOption="false"
						:position="'left'"
						@selected="exceptionActionSelected"
					/>
				</div>
			</div>
			<transition-expand>
				<div v-if="expanded">
					<div class="sk-divider"></div>
					<div class="exception-detail-title">Date & Time</div>
					<div class="exception-detail-value">
						{{ orderDate }} at {{ orderTime }}
					</div>
					<div class="exception-detail-title">Reason & Price</div>
					<div class="exception-detail-value">
						{{ exceptionReason }} for
						{{ exception.amount | currency }}
					</div>
					<div class="exception-detail-title">
						Order Number & Restaurant
					</div>
					<div class="exception-detail-value">
						{{ orderId }} at {{ restaurantName }}
					</div>
					<div class="exception-detail-title">Issued By</div>
					<div class="exception-detail-value">
						{{ issuerName }}
					</div>
				</div>
			</transition-expand>
		</div>
	</div>
</template>

<style scoped>
.sk-widget-alt {
	overflow: visible;
}

.attached-exception-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
}

.attached-exception-header > div:first-child {
	flex: 1;
}

.attached-exception-header > div:last-child {
	display: flex;
	align-items: center;
}

.attached-exception-title,
.attached-exception-amount {
	font-size: 12px;
}

.attached-exception-title {
	color: var(--sk-dark-navy);
	font-weight: 600;
}

.attached-exception-amount {
	color: var(--sk-grey3);
}

.attached-exception-amount span {
	margin-right: 2px;
	margin-left: 2px;
}

.approved-status-badge {
	flex-shrink: 0;
	font-size: 10px;
	height: 20px;
	width: 30px;
	padding: 0;
	justify-content: center;
}

.sk-select {
	color: var(--sk-grey2);
}

.exception-detail-title {
	font-size: 10px;
	color: var(--sk-grey2);
}

.exception-detail-value {
	font-size: 12px;
	color: var(--sk-dark-navy);
	font-weight: 600;
}

.exception-detail-value + .exception-detail-title {
	margin-top: 20px;
}

.sk-divider {
	margin-left: -20px;
	width: calc(100% + 40px);
}
</style>

<script>
import moment from "moment"
import currencyFilterMixin from "@/mixins/currency-filter-mixin"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"
import TransitionExpand from "@/components/transitions/TransitionExpand.vue"

export default {
	name: "RefundAttachedException",
	mixins: [currencyFilterMixin],
	components: {
		SkDropdownSelect,
		TransitionExpand
	},
	props: {
		order: Object,
		exception: Object,
		expanded: Boolean
	},
	computed: {
		orderId() {
			return this.exception ? this.exception.order_id : ""
		},
		orderDate() {
			return this.order
				? moment.utc(this.order.created_time).format("MMM DD, YYYY")
				: ""
		},
		orderTime() {
			return this.order
				? moment.utc(this.order.created_time).format("h:mm A")
				: ""
		},
		restaurantName() {
			if (this.exception && this.exception.restaurant_id) {
				const restaurant = this.$store.state.restaurants[
					this.exception.restaurant_id
				]
				return restaurant && restaurant.name ? restaurant.name : "N/A"
			}
			return "N/A"
		},
		issuerName() {
			if (this.exception && this.exception.admin_user_id) {
				const admin = this.$store.getters.adminUsersMap[
					this.exception.admin_user_id
				]
				return admin ? admin.display_name : "N/A"
			}
			return "N/A"
		},
		exceptionReason() {
			const exceptionType = this.$store.state.ordersModule.exception_types.find(
				(exceptionType) => exceptionType.id == this.exception.reason_id
			)
			return exceptionType && exceptionType.text ? exceptionType.text : ""
		},
		approved() {
			return this.exception && this.exception.status == 1
		},
		exceptionActions() {
			const actions = [
				{
					id: 0,
					text: "Details"
				}
			]

			if (this.exception && this.exception.status == 0) {
				actions.push(
					{
						id: 1,
						text: "Reject"
					},
					{
						id: 2,
						text: "Edit"
					}
				)
			}
			return actions
		}
	},
	methods: {
		exceptionActionSelected(action) {
			switch (action.id) {
				case 0: {
					this.toggleExceptionExpand()
					break
				}
				case 1: {
					this.$emit("reject", this.exception)
					break
				}
				case 2: {
					this.$emit("edit", this.exception)
					break
				}
			}
		},
		toggleExceptionExpand() {
			this.$emit("expand")
		}
	}
}
</script>