<template>
	<div class="sk-table-row">
		<div>{{ location.name }}</div>
		<div @click="togglePopover('isBusinessesPopoverOpen')">
			{{ businessCount }}
			<i
				v-if="businessCount > 0"
				class="sk-icon-chevron-down-regular"
			></i>
			<SkPopover
				v-if="businessCount > 0"
				v-model="isBusinessesPopoverOpen"
			>
				<div v-for="business in businesses" :key="business.id">
					{{ business.name }}
				</div>
			</SkPopover>
		</div>
		<div @click="togglePopover('isRestaurantsPopoverOpen')">
			{{ restaurantCount }}
			<i
				v-if="restaurantCount > 0"
				class="sk-icon-chevron-down-regular"
			></i>
			<SkPopover
				v-if="restaurantCount > 0"
				v-model="isRestaurantsPopoverOpen"
			>
				<div v-for="restaurant in restaurants" :key="restaurant.id">
					{{ restaurant.name }}
				</div>
			</SkPopover>
		</div>
		<div @click="togglePopover('isMenusPopoverOpen')">
			{{ menuAvailability.progress }}
			<i class="sk-icon-chevron-down-regular"></i>
			<SkPopover v-model="isMenusPopoverOpen">
				<div
					class="menu-type-availability"
					v-for="(menuType, i) in menuAvailability.types"
					:key="i"
				>
					<span
						class="status-dot"
						:class="{
							'dark-green': menuType.available,
							grey: !menuType.available
						}"
					></span>
					{{ menuType.name }}
				</div>
			</SkPopover>
		</div>
		<div>
			<div :title="statusLabel" :class="statusBadgeClass">
				<span :class="statusClasses"></span>
				{{ statusLabel }}
			</div>
		</div>
		<div class="sk-table-column-fit">
			<div class="sk-table-actions">
				<router-link
					:key="location.id"
					:to="`/hub/${location.id}`"
					tag="button"
					class="button button-icon"
					title="Edit"
				>
					<i class="sk-icon-pencil-solid"></i>
				</router-link>
			</div>
		</div>
	</div>
</template>

<style scoped>
.sk-table-row .sk-icon-chevron-down-regular {
	font-size: 10px;
	margin-left: 10px;
}

.menu-type-availability:not(:last-child) {
	margin-bottom: 15px;
}
</style>

<script>
import { MenuType } from "@arikgaisler/utils/enums/menuType"
import moment from "moment"
import "moment-timezone"
import SkPopover from "@/components/SkPopover.vue"

export default {
	name: "HubLocationLineItem",
	components: {
		SkPopover
	},
	props: {
		location: Object
	},
	data: function () {
		return {
			isBusinessesPopoverOpen: false,
			isRestaurantsPopoverOpen: false,
			isMenusPopoverOpen: false
		}
	},
	computed: {
		statusBadgeClass() {
			return this.hasActiveBusiness
				? "pill-alt pill-alt-green"
				: "pill-alt pill-alt-red"
		},
		statusLabel() {
			return this.hasActiveBusiness ? "Active" : "Inactive"
		},
		statusClasses() {
			return this.hasActiveBusiness
				? "status-dot green"
				: "status-dot red"
		},
		businesses() {
			return this.location && this.location.businesses
				? this.location.businesses
				: []
		},
		businessCount() {
			return this.businesses.length
		},
		hasActiveBusiness() {
			const buisinessesMap = this.$store.getters.businessesMap
			return this.businesses.some((business) => {
				const fullBusiness = buisinessesMap[business.id]
				return fullBusiness && fullBusiness.is_active
			})
		},
		restaurants() {
			let restaurantIds = []
			let restaurants = []
			if (this.location && this.location.menus) {
				this.location.menus.forEach((menu) => {
					if (!restaurantIds.includes(menu.restaurant_id)) {
						let restaurant =
							this.$store.state.restaurants[menu.restaurant_id]
						restaurantIds.push(menu.restaurant_id)
						restaurants.push({
							id: menu.restaurant_id,
							name: restaurant ? restaurant.name : "N/A"
						})
					}
				})
			}
			return restaurants
		},
		restaurantCount() {
			return this.restaurants.length
		},
		menus() {
			return this.location && this.location.menus
				? this.location.menus
				: []
		},
		offset() {
			let offset = 0
			this.menus.find((menu) => {
				let restaurant =
					this.$store.state.restaurants[menu.restaurant_id]
				if (restaurant && restaurant.timezone_offset) {
					offset = restaurant && restaurant.timezone_offset
					return true
				}
				return false
			})
			return offset
		},
		menuAvailability() {
			let menuTimeRanges = this.$store.state.menuTimeRanges
			let progress = 0
			let maxProgress = 5
			let hasBreakfast = false
			let hasLunch = false
			let hasDinner = false
			let hasLateNight = false
			let hasCatering = false
			this.menus.forEach((menu) => {
				if (progress >= maxProgress) {
					return
				}

				let isBreakfast = false
				let isLunch = false
				let isDinner = false
				let isLateNight = false
				let isCatering = menu.type == MenuType.PRE_PACKAGED

				if (!hasCatering && isCatering) {
					hasCatering = true
					progress += 1
				}

				menu.hours.forEach((hour) => {
					if (progress >= maxProgress) {
						return
					}
					const openTime = parseFloat(
						moment
							.utc(hour.open_time, "HH:mm")
							.add(this.offset, "m")
							.format("HH:mm")
							.replace(":", "")
					)
					const closeTime = parseFloat(
						moment
							.utc(hour.close_time, "HH:mm")
							.add(this.offset, "m")
							.format("HH:mm")
							.replace(":", "")
					)

					if (openTime >= closeTime) {
						isBreakfast = true
						isLunch = true
						isDinner = true
						isLateNight = true
					} else {
						if (!hasBreakfast && !isBreakfast) {
							isBreakfast = menuTimeRanges.breakfast.some(
								(hour) => hour >= openTime || hour <= closeTime
							)
						}

						if (!hasLunch && !isLunch) {
							isLunch = menuTimeRanges.lunch.some(
								(hour) => hour >= openTime || hour <= closeTime
							)
						}

						if (!hasDinner && !isDinner) {
							isDinner = menuTimeRanges.dinner.some(
								(hour) => hour >= openTime || hour <= closeTime
							)
						}

						if (!hasLateNight && !isLateNight) {
							isLateNight = menuTimeRanges.latenight.some(
								(hour) => hour >= openTime || hour <= closeTime
							)
						}
					}
					if (!hasBreakfast && isBreakfast) {
						hasBreakfast = true
						progress += 1
					}
					if (!hasLunch && isLunch) {
						hasLunch = true
						progress += 1
					}
					if (!hasDinner && isDinner) {
						hasDinner = true
						progress += 1
					}
					if (!hasLateNight && isLateNight) {
						hasLateNight = true
						progress += 1
					}
				})
			})
			return {
				progress: `${progress}|${maxProgress}`,
				types: [
					{
						name: "Breakfast",
						available: hasBreakfast
					},
					{
						name: "Lunch",
						available: hasLunch
					},
					{
						name: "Dinner",
						available: hasDinner
					},
					{
						name: "Late Night",
						available: hasLateNight
					},
					{
						name: "Catering",
						available: hasCatering
					}
				]
			}
		}
	},
	methods: {
		togglePopover(name) {
			this.isBusinessesPopoverOpen =
				name == "isBusinessesPopoverOpen"
					? !this.isBusinessesPopoverOpen
					: false
			this.isRestaurantsPopoverOpen =
				name == "isRestaurantsPopoverOpen"
					? !this.isRestaurantsPopoverOpen
					: false
			this.isMenusPopoverOpen =
				name == "isMenusPopoverOpen" ? !this.isMenusPopoverOpen : false
		}
	}
}
</script>