<template>
	<div>
		<SkCalendar @dateChanged="onDateChanged">
			<PrepackagesCalendarDay
				v-for="day in monthDays"
				slot="calendar-days"
				:key="day.text"
				:day="day"
				@actionSelected="actionSelected"
				@orderPrepackages="orderPrepackages"
			/>
		</SkCalendar>
	</div>
</template>

<script>
import tracker from "@/utils/tracker"
import loaderMixin from "@/mixins/loader-mixin"
import ActionTypes from "@/store/modules/prepackages/action-types"
import moment from "moment"
import SkCalendar from "@/components/SkCalendar.vue"
import PrepackagesCalendarDay from "@/components/prepackages/PrepackagesCalendarDay.vue"

export default {
	name: "PrepackagesCalendar",
	mixins: [loaderMixin],
	components: {
		SkCalendar,
		PrepackagesCalendarDay
	},
	props: {
		filters: Object
	},
	data: function () {
		return {
			currentMonth: 0,
			currentYear: 0
		}
	},
	computed: {
		prepackagedOrders() {
			return this.$store.state.prepackagesModule.prepackagedOrders
		},
		prepackagesByDate() {
			const businesses = this.$store.getters.businessesMap
			return this.prepackagedOrders.reduce((orders, order) => {
				const business = businesses[order.business_id]
				if (
					business &&
					order.sub_orders &&
					order.sub_orders.length > 0 &&
					!order.cancelled
				) {
					let suborder = order.sub_orders[0]
					let offset = business.timezone_offset
					let deliverySlot = moment
						.utc(suborder.delivery_slot)
						.add(offset, "m")
					let localSlot = deliverySlot.format("YYYY-MM-DD")

					if (!orders[localSlot]) {
						orders[localSlot] = []
					}

					orders[localSlot].push(order)
				}
				return orders
			}, {})
		},
		monthDays() {
			const daysInMonth = moment(
				`${this.currentYear}-${this.currentMonth}`,
				"YYYY-MM"
			).daysInMonth()
			const days = []
			for (let i = 1; i <= daysInMonth; i++) {
				let date = moment(
					`${this.currentYear}-${this.currentMonth}-${i}`,
					"YYYY-MM-DD"
				).format("YYYY-MM-DD")
				let prepackages = this.prepackagesByDate[date]
					? this.prepackagesByDate[date]
					: []
				days.push({
					text: `${i}`,
					prepackages: prepackages,
					date: date
				})
			}
			return days
		}
	},
	methods: {
		onDateChanged(date) {
			this.currentMonth = date.month
			this.currentYear = date.year
			this.getPrepackagedOrders()
		},
		getPrepackagedOrders() {
			this.toggleLoader(true)
			const currentDate = moment(
				`${this.currentYear}-${this.currentMonth}`,
				"YYYY-MM"
			)
			const currentMonth = currentDate.format("MM")
			const endingMonth = currentDate
				.endOf("month")
				.add(1, "day")
				.format("MM")
			const endingYear =
				currentMonth == "12" ? this.currentYear + 1 : this.currentYear
			const filters = Object.assign({}, this.filters, {
				startDate: `${this.currentYear}-${currentMonth}-01`,
				endDate: `${endingYear}-${endingMonth}-01`,
				statuses: !this.filters.statuses
					? [0, 1, 2, 3, 5]
					: this.filters.statuses
			})
			this.$store
				.dispatch(
					`prepackagesModule/${ActionTypes.GET_PREPACKAGED_ORDERS}`,
					filters
				)
				.finally(() => {
					this.toggleLoader(false)
				})
		},
		orderPrepackages(date) {
			this.$emit("openPrepackagesModal", {
				date: date
			})
		},
		actionSelected(action) {
			switch (action.id) {
				case 0: {
					tracker.trackEvent("View Prepackage Clicked", {
						order: action.prepackage.order_id
					})
					this.$emit("openPrepackagesModal", {
						order: action.prepackage,
						viewing: true
					})
					break
				}
				case 1: {
					tracker.trackEvent("Cancel Prepackage Clicked", {
						order: action.prepackage.order_id
					})
					this.$emit("cancelPrepackage", action.prepackage)
					break
				}
				case 2: {
					tracker.trackEvent("Edit Prepackage Clicked", {
						order: action.prepackage.order_id
					})
					this.$emit("openPrepackagesModal", {
						order: action.prepackage,
						updating: true
					})
					break
				}
				case 3: {
					tracker.trackEvent("Download Prepackegd Manifest", {
						order: action.prepackage.order_id
					})
					const menu_id = action.prepackage.sub_orders[0].products[0].menu_id
					const payload = {
						order_ids: [action.prepackage.order_id],
						business_id: action.prepackage.business_id,
						menu_id: menu_id
					}
					this.$emit("downloadManifest", payload)
					break
				}

			}
		}
	},
	watch: {
		filters: {
			deep: true,
			handler() {
				this.getPrepackagedOrders()
			}
		}
	}
}
</script>