import MutationTypes from "@/store/modules/users/mutation-types"
import Vue from 'vue'

const vue = new Vue()

export default {
    [MutationTypes.GOT_CURRENT_BUSINESS_GUEST](state, businessGuest) {
        state.currentBusinessGuest = businessGuest
    },
    [MutationTypes.GOT_CURRENT_BUSINESS_GUEST_ORDERS](state, orders) {
        if (state.currentBusinessGuest) {
            vue.$set(state.currentBusinessGuest, "enrichedOrders", orders)
        }
    },
    [MutationTypes.GOT_BUSINESS_GUESTS](state, data) {
        state.businessGuests = data.business_guests
        state.totalBusinessGuestsCount = data.total_business_guests_count
    },
    [MutationTypes.GOT_BUSINESS_GUEST](state, data) {
        let guestIndex = state.businessGuests.findIndex(guest => guest.id == data.business_guest.id)
        if (guestIndex >= 0) {
            state.businessGuests.splice(guestIndex, 1, data.business_guest)
        }
        else {
            state.businessGuests.push(data.business_guest)
            state.totalBusinessGuestsCount = state.totalBusinessGuestsCount + 1
        }
        if (state.currentBusinessGuest && state.currentBusinessGuest.id == data.business_guest.id) {
            const amenities = state.currentBusinessGuest.amenities
            state.currentBusinessGuest = data.business_guest
            state.currentBusinessGuest.amenities = amenities
        }
    },
    [MutationTypes.BUSINESS_GUEST_DELETED](state, id) {
        let guestIndex = state.businessGuests.findIndex(guest => guest.id == id)
        if (guestIndex >= 0) {
            state.businessGuests.splice(guestIndex, 1)
        }
        if (state.currentBusinessGuest && state.currentBusinessGuest.id == id) {
            state.currentBusinessGuest = null
        }
    },

    [MutationTypes.LOADING](state) {
        state.loading = true
    },

    [MutationTypes.LOADED](state) {
        state.loading = false
    },

}