import { MenuType } from "@arikgaisler/utils/enums/menuType"

export default {
	businessesWithPrepackagedMenus: (state, getters, rootState, rootGetters) => {
		return rootGetters.ownedBusinesses.filter((business) => {
			//The pre_order_allowed property here indicates that this is available only in the webapp and is only user facing
			return (
				business.menus &&
				business.menus.some(
					(menu) => menu.type == MenuType.PRE_PACKAGED && menu.pre_order_allowed == 0
				)
			)
		})
	},
	canCreatePrepackages: (state, getters, rootState, rootGetters) => {
		if (rootGetters.isSuperAdmin) {
			return true
		}
		return getters.businessesWithPrepackagedMenus.length > 0
	},
	normalizedPrepackagedMenuSchedule: (state) => {
		const menu = state.prepackagedMenu
		if (menu.opening_hours) {
			let schedule = {
				0: [],
				1: [],
				2: [],
				3: [],
				4: [],
				5: [],
				6: []
			}
			menu.opening_hours.forEach((hour) => {
				let numOpenTime =  Number(hour.open_time.replace(/:/g, ""))
				let numCloseTime = Number(hour.close_time.replace(/:/g, ""))
				let scheduleDay = schedule[hour.day_of_week]
				if (
					hour.open_time == "00:00:00" &&
					hour.close_time == "00:00:00"
				) {
					scheduleDay.push({
						openTime: "00:00:00",
						closeTime: "24:00:00",
						dow: hour.day_of_week,
						rangesFrom: hour.day_of_week
					})
				} else if (numOpenTime > numCloseTime) {
					let nextDay =
						hour.day_of_week == 6 ? 0 : hour.day_of_week + 1
					let scheduleNextDay = schedule[nextDay]
					if (hour.open_time != "24:00:00") {
						scheduleDay.push({
							openTime: hour.open_time,
							closeTime: "24:00:00",
							dow: hour.day_of_week,
							rangesFrom: hour.day_of_week
						})
					}
					if (hour.close_time != "00:00:00") {
						scheduleNextDay.push({
							openTime: "00:00:00",
							closeTime: hour.close_time,
							dow: nextDay,
							rangesFrom: hour.day_of_week
						})
					}
				} else {
					scheduleDay.push({
						openTime: hour.open_time,
						closeTime: hour.close_time,
						dow: hour.day_of_week,
						rangesFrom: hour.day_of_week
					})
				}
			})
			return schedule
		}
		return null
	}
}