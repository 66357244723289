<template>
	<div class="business-menus">
		<CollapsibleSection
			v-for="(menuCategory, i) in menuCategories"
			:key="i"
			:index="i"
			class="hub-collapsible-section"
			:title="menuCategory.name"
			:subtitle="showSubtitle ? menuCategory.timeRange : null"
			:badgeText="menuCategory.menuCount"
			:defaultExpanded="menuCategory.menuCount > 0"
		>
			<div slot="expanded">
				<div class="sk-table sk-table-default">
					<div class="sk-table-header">
						<div class="sk-table-row">
							<div>Status</div>
							<div>Restaurant</div>
							<div>Name</div>
							<div>Products</div>
							<div>Type</div>
							<div>Hours</div>
							<div>Delivery Mode</div>
							<div>Courier</div>
							<div>Actions</div>
						</div>
					</div>
					<transition-group
						name="list"
						tag="div"
						class="sk-table-body"
						appear
					>
						<BusinessMenuLineItem
							v-for="menu in menuCategory.menus"
							:key="menu.id"
							:menu="menu"
							:restaurant="menuPairings[menu.id]"
							@delete="deleteMenu"
						/>
					</transition-group>
				</div>
			</div>
			<div slot="collapsed">
				<div class="sk-pills-container">
					<router-link
						v-for="businessMenu in menuCategory.menus"
						:key="businessMenu.id"
						:to="`/business/${business.slug}/menu/${businessMenu.id}`"
						tag="a"
						class="pill pill-grey"
						:title="businessMenu.name"
					>
						<i class="sk-icon-link-regular"></i>
						{{ businessMenu.name }}
					</router-link>
					<div
						class="pill pill-grey"
						@click="handlePlaceholderClicked"
					>
						<i class="sk-icon-plus-regular"></i>
						New {{ menuCategory.name }} Menu
					</div>
				</div>
			</div>
		</CollapsibleSection>
		<button
			v-if="menuDeleted || menuAdded"
			class="button button-primary button-absolute"
			@click="saveBusinessInfo"
		>
			Save Menus
		</button>
		<ConfirmationModal ref="confirmationModal" />
	</div>
</template>

<style scoped>
.business-menus {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.sk-pills-container > .pill.pill-grey {
	max-width: 100%;
}
</style>

<script>
import { PermissionInfo } from "@/utils/permissions"
import { ConfirmationModalType } from "@/enums/confirmationModalType"
import menuCategorizationMixin from "@/mixins/menu-categorization-mixin"
import BusinessMenuLineItem from "@/components/business/BusinessMenuLineItem.vue"
import CollapsibleSection from "@/components/CollapsibleSection.vue"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue"

export default {
	name: "BusinessMenus",
	mixins: [menuCategorizationMixin],
	components: {
		BusinessMenuLineItem,
		CollapsibleSection,
		ConfirmationModal
	},
	props: {
		menuAdded: Boolean
	},
	data: function () {
		return {
			menuDeleted: false
		}
	},
	computed: {
		showSubtitle() {
			return this.$store.state.permissions.includes(
				PermissionInfo.BUSINESS_MENU_CATEGORY_HOURS
			)
		},
		business() {
			return this.$store.state.businessesModule.currentBusiness
		},
		menus() {
			return this.business.menus.map((menu) => {
				return Object.assign({}, menu, {
					timezoneOffset: this.business.timezone_offset
				})
			})
		},
		menuPairings() {
			let pairings = {}
			this.menus.forEach((menu) => {
				if (this.$store.state.restaurants[menu.restaurant_id]) {
					pairings[menu.id] = {
						id: this.$store.state.restaurants[menu.restaurant_id]
							.id,
						name: this.$store.state.restaurants[menu.restaurant_id]
							.name,
						slug: this.$store.state.restaurants[menu.restaurant_id]
							.slug
					}
				}
			})

			return pairings
		}
	},
	methods: {
		saveBusinessInfo() {
			this.$emit("save")
			this.menuDeleted = false
		},
		async deleteMenu(menu) {
			const restName = this.menuPairings[menu.id].name
			const confirmed = await this.$refs.confirmationModal.open({
				title: `Are you sure you want to remove ${menu.name} (${restName})?`,
				confirmation: "Remove Menu",
				confirmationIcon: "sk-icon-trash-alt-solid",
				cancel: "Keep Menu",
				type: ConfirmationModalType.RED
			})
			if (confirmed) {
				this.$store.dispatch(
					"businessesModule/removeBusinessMenu",
					menu.id
				)
				this.menuDeleted = true
			}
		},
		handlePlaceholderClicked() {
			this.$emit("openNewMenuModal")
		}
	}
}
</script>