<template>
	<div class="sk-calendar-day">
		<div class="sk-calendar-header" :class="{ disabled: disabled }">
			{{ dayText }}
		</div>
		<slot
			name="calendar-short-list-item"
			:selectItem="selectItem"
			:selectedItem="selectedItem"
			:viewMore="viewMore"
		></slot>
		<button
			class="button button-text view-more-button"
			v-if="hiddenItemsCount > 0"
			@click="viewMore"
		>
			{{ hiddenItemsCount }} More
		</button>
		<transition-expand>
			<div class="sk-calendar-full" v-if="viewingMore">
				<div class="sk-calendar-full-header">
					{{ dayText }}
					<button class="button button-text" @click="viewLess">
						<i class="sk-icon-times-regular"></i>
					</button>
				</div>
				<div class="sk-calendar-full-list">
					<slot
						name="calendar-full-list-item"
						:selectItem="selectItem"
						:selectedItem="selectedItem"
					></slot>
				</div>
			</div>
		</transition-expand>
		<transition-expand>
			<slot
				v-if="selectedItem"
				name="calendar-detail"
				:close="closeDetail"
				:position="detailPosition"
				:selectItem="selectItem"
				:selectedItem="selectedItem"
			></slot>
		</transition-expand>
	</div>
</template>

<style scoped>
.sk-calendar-day {
	height: 105px;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	color: var(--sk-grey3);
	font-size: 20px;
	text-align: center;
	min-width: 0;
	position: relative;
}

.sk-calendar-header {
	pointer-events: none;
}

.sk-calendar-header.disabled {
	color: var(--sk-grey2);
}

.view-more-button {
	font-weight: 600;
	font-size: 10px;
	padding: 0;
	min-height: auto;
	margin-top: 3px;
	text-align: left;
}

.sk-calendar-full {
	box-shadow: 0px 1px 4px rgba(136, 136, 136, 0.25);
	border-radius: 5px;
	width: 200px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1000;
	background: var(--sk-greybg);
}

.sk-calendar-full-list {
	padding: 10px;
}

.sk-calendar-full .button {
	position: absolute;
	top: 0;
	right: 0;
	padding: 0;
	min-height: 100%;
}

.sk-calendar-full-header {
	position: relative;
	background: var(--sk-white);
	border: 1px solid var(--sk-grey);
	border-radius: 5px;
	line-height: 40px;
}
</style>

<script>
import TransitionExpand from "@/components/transitions/TransitionExpand.vue"

export default {
	name: "SkCalendarDay",
	components: {
		TransitionExpand
	},
	data: function () {
		return {
			viewingMore: false,
			selectedItem: null
		}
	},
	props: {
		dayText: String,
		totalItems: Number,
		disabled: Boolean
	},
	computed: {
		observeOutsideClicks() {
			return this.viewingMore || this.selectedItem
		},
		hiddenItemsCount() {
			return this.totalItems <= 2 ? 0 : this.totalItems - 2
		},
		detailPosition() {
			if (this.$el) {
				let bounding = this.$el.getBoundingClientRect()
				let sidebarWidth = 70
				let detailSpaceRequired = 391 + sidebarWidth
				let minimumMargin = 10
				return bounding.x - detailSpaceRequired > minimumMargin
					? "right"
					: "left"
			}
			return "left"
		}
	},
	methods: {
		viewMore() {
			this.viewingMore = true
		},
		viewLess() {
			this.viewingMore = false
		},
		selectItem(selectedItem) {
			this.selectedItem = selectedItem
		},
		closeDetail() {
			this.selectedItem = null
		},
		clickedOutside(event) {
			if (
				this.$el &&
				!(this.$el == event.target || this.$el.contains(event.target))
			) {
				this.viewLess()
				this.closeDetail()
			}
		}
	},
	watch: {
		observeOutsideClicks(shouldObserve) {
			if (shouldObserve) {
				document.body.addEventListener("click", this.clickedOutside)
			} else {
				document.body.removeEventListener(
					"click",
					this.clickOutsideEvent
				)
			}
		}
	},
	beforeDestroy() {
		document.body.removeEventListener("click", this.clickOutsideEvent)
	}
}
</script>