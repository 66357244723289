import XLSX from "xlsx"

function getFileExtension(filename) {
  return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
}

export default {
  isValidXlSXFile(file) {
    let ext = getFileExtension(file.name)
    let isCSV = file.type == "text/csv" || ext == "csv"
    let isXLSX = file.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || ext == "xlsx"
    return isCSV || isXLSX
  },
  readFile(file) {
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader()
        reader.onload = (data) => {
          const workbook = XLSX.read(data.target.result, { type: "binary" })
          const json = this.workbookToJSON(workbook)
          resolve(json)
        }
        reader.readAsBinaryString(file)
      } catch (e) {
        reject(e)
      }
    })
  },
  sanitizeFilename(filename) {
    let name =  filename.length > 20 ? filename.substring(0, 20) : filename
    /* eslint-disable-next-line no-useless-escape */
    name = name.replace(/([:\\\/?*\[\]])/g, "")
    return name
  },
  workbookToJSON(workbook) {
    let result = []
    workbook.SheetNames.forEach((sheetName) => {
      const sheet = workbook.Sheets[sheetName]
      const roa = XLSX.utils.sheet_to_json(sheet)
      if (roa.length) result = result.concat(roa)
    })
    return result
  },
  export(json, filename, options) {
    const name = this.sanitizeFilename(filename)
    const workbook = XLSX.utils.book_new()
    const sheet = XLSX.utils.json_to_sheet(json, options)
    XLSX.utils.book_append_sheet(workbook, sheet, name)
    XLSX.writeFile(workbook, name + ".xlsx")
  },
  exportMultiSheet(sheetsJson, filename) {
    const name = this.sanitizeFilename(filename)
    const workbook = XLSX.utils.book_new()
    sheetsJson.forEach(sheetJson => {
      const sheet = XLSX.utils.json_to_sheet(sheetJson.json, sheetJson.options)
      XLSX.utils.book_append_sheet(workbook, sheet, sheetJson.name)
    })
    XLSX.writeFile(workbook, name + ".xlsx")
  },
  s2ab(s) {
    var buf = new ArrayBuffer(s.length) //convert s to arrayBuffer
    var view = new Uint8Array(buf) //create uint8array as viewer
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff //convert to octet
    return buf
  }
}