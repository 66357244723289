export const LOADING = "LOADING"
export const LOADED = "LOADED"
export const GOT_RESTAURANTS_FINANCE_DETAILS = "GOT_RESTAURANTS_FINANCE_DETAILS"
export const GOT_BUSINESSES_FINANCE_DETAILS = "GOT_BUSINESSES_FINANCE_DETAILS"
export const GOT_CURRENT_FINANCE_DETAILS = "GOT_CURRENT_FINANCE_DETAILS"
export const CLEAR_CURRENT_FINANCE_DETAILS = "CLEAR_CURRENT_FINANCE_DETAILS"
export const SET_CURRENT_FUNDING_ACCOUNT = "SET_CURRENT_FUNDING_ACCOUNT"
export const UPDATED_FUNDING_SOURCE_AUTO_PAY = "UPDATED_FUNDING_SOURCE_AUTO_PAY"
export const GOT_RESTAURANT_PAYOUTS = "GOT_RESTAURANT_PAYOUTS"
export const GOT_CURRENT_RESTAURANT_PAYOUT_HISTORY = "GOT_CURRENT_RESTAURANT_PAYOUT_HISTORY"
export const GOT_CURRENT_BUSINESS_INVOICE_HISTORY = "GOT_CURRENT_BUSINESS_INVOICE_HISTORY"
export const PAID_BUSINESS_INVOICE = "PAID_BUSINESS_INVOICE"
export const REMOVE_RESTAURANT_PAYOUT = "REMOVE_RESTAURANT_PAYOUT"
export const ADJUSTED_RESTAURANT_PAYOUT = "ADJUSTED_RESTAURANT_PAYOUT"
export const BATCH_REMOVED_PAYOUTS = "BATCH_REMOVED_PAYOUTS"
export const GOT_BUSINESS_INVOICES = "GOT_BUSINESS_INVOICES"
export const ADDED_BUSINESS_INVOICE_RECORD = "ADDED_BUSINESS_INVOICE_RECORD"
export const REMOVE_BUSINESS_INVOICE = "REMOVE_BUSINESS_INVOICE"
export const BATCH_APPROVED_BUSINESS_INVOICES = "BATCH_APPROVED_BUSINESS_INVOICES"

export default {
	LOADING,
	LOADED,
	GOT_RESTAURANTS_FINANCE_DETAILS,
	GOT_BUSINESSES_FINANCE_DETAILS,
	GOT_CURRENT_FINANCE_DETAILS,
	CLEAR_CURRENT_FINANCE_DETAILS,
	SET_CURRENT_FUNDING_ACCOUNT,
	UPDATED_FUNDING_SOURCE_AUTO_PAY,
	GOT_RESTAURANT_PAYOUTS,
	GOT_CURRENT_RESTAURANT_PAYOUT_HISTORY,
	GOT_CURRENT_BUSINESS_INVOICE_HISTORY,
	PAID_BUSINESS_INVOICE,
	REMOVE_RESTAURANT_PAYOUT,
	ADJUSTED_RESTAURANT_PAYOUT,
	BATCH_REMOVED_PAYOUTS,
	GOT_BUSINESS_INVOICES,
	ADDED_BUSINESS_INVOICE_RECORD,
	REMOVE_BUSINESS_INVOICE,
	BATCH_APPROVED_BUSINESS_INVOICES
}