export const GOT_HUBS = "GOT_HUBS"
export const ADDED_HUB = "ADDED_HUB"
export const GOT_HUB = "GOT_HUB"
export const GOT_HUB_RESTAURANT_MENUS = "GOT_HUB_RESTAURANT_MENUS"
export const GOT_HUB_BUSINESSES = "GOT_HUB_BUSINESSES"
export const ADDED_HUB_BUSINESS = "ADDED_HUB_BUSINESS"
export const UPDATED_HUB_BUSINESS_MENUS = "UPDATED_HUB_BUSINESS_MENUS"

export default {
    GOT_HUBS,
    ADDED_HUB,
    GOT_HUB,
    GOT_HUB_RESTAURANT_MENUS,
    GOT_HUB_BUSINESSES,
    ADDED_HUB_BUSINESS,
    UPDATED_HUB_BUSINESS_MENUS
}