<template>
	<BusinessInsights v-if="showBusinessInsights" />
	<RestaurantInsights v-else />
</template>

<script>
import { PermissionPage } from "@/utils/permissions"
import BusinessInsights from "@/components/insights/BusinessInsights.vue"
import RestaurantInsights from "@/components/insights/RestaurantInsights.vue"

export default {
	name: "Insights",
	components: {
		BusinessInsights,
		RestaurantInsights
	},
	computed: {
		showBusinessInsights() {
			return this.businessInsightsAllowed && (!this.$route.query.view || this.$route.query.view != "restaurant")
		},
		businessInsightsAllowed() {
			return this.$store.state.permissions.includes(
				PermissionPage.BUSINESS_INSIGHTS
			)
		}
	}
}
</script>