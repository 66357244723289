<template>
	<div class="invoices-details-history">
		<slot v-if="invoices.length > 0">
			<div class="sk-table sk-table-default">
				<div class="sk-table-header">
					<div class="sk-table-row">
						<div>Invoice Period</div>
						<div>Method</div>
						<div>Credits</div>
						<div>Usage</div>
						<div>Total Amount</div>
						<div>Status</div>
						<div>Actions</div>
					</div>
				</div>
				<transition-group
					name="list"
					tag="div"
					class="sk-table-body"
					appear
				>
					<InvoiceDetailsHistoryLineItem
						v-for="invoice in invoices"
						:invoice="invoice"
						:key="invoice.id"
						@viewInvoice="viewInvoice"
						@downloadInvoice="downloadInvoice"
						@downloadInvoiceBreakdown="downloadInvoiceBreakdown"
					/>
				</transition-group>
			</div>
			<PaginationFooter
				ref="paginationFooter"
				:contentText="'Invoices'"
				:pageSize="20"
				:total="totalInvoices"
				@pageChanged="filterPage"
			/>
		</slot>
		<EmptyPlaceholderWidget
			v-else
			:title="`No Invoices`"
			:description="'You don’t have any invoices yet.'"
		/>
	</div>
</template>

<style scoped>
.invoices-details-history {
	display: flex;
	flex-direction: column;
	height: 100%;
}
</style>

<script>
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import utils from "@/utils/utils"
import ActionTypes from "@/store/modules/finance/action-types"
import InvoiceDetailsHistoryLineItem from "@/components/finance/InvoiceDetailsHistoryLineItem.vue"
import PaginationFooter from "@/components/PaginationFooter.vue"
import EmptyPlaceholderWidget from "@/components/EmptyPlaceholderWidget.vue"

export default {
	name: "InvoiceDetailsHistory",
	components: {
		InvoiceDetailsHistoryLineItem,
		PaginationFooter,
		EmptyPlaceholderWidget
	},
	computed: {
		invoices() {
			return this.$store.state.financeModule.currentBusinessInvoiceHistory
		},
		totalInvoices() {
			return this.$store.state.financeModule
				.currentBusinessInvoiceHistoryTotalCount
		}
	},
	methods: {
		filterPage(range) {
			this.$emit("pageChanged", range)
		},
		resetPagination() {
			if (this.$refs.paginationFooter) {
				this.$refs.paginationFooter.pageChanged(1)
			}
		},
		viewInvoice(invoice) {
			this.$emit("viewInvoice", invoice)
		},
		downloadInvoice(invoice) {
			const filename = `invoice_${invoice.id}.pdf`
			const businessId = invoice.business.id
			const business = this.$store.getters.businessesMap[businessId]
			if (business) {
				utils.downloadFile(
					`${this.$invoiceCDNURL}businesses/${business.slug}/${filename}`
				)
			}
		},
		downloadInvoiceBreakdown(invoice) {
			this.$store
				.dispatch(
					`financeModule/${ActionTypes.DOWNLOAD_INVOICE_USAGE_BREAKDOWN}`,
					invoice
				)
				.then((success) => {
					if (!success) {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Invoice",
							message:
								"Failed to download invoice breakdown, please contact support or try again",
							type: NotificationType.ERROR
						})
					}
				})
		}
	}
}
</script>