import { ConfirmationModalType } from "@/enums/confirmationModalType"
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"

let adminUserTableMixin = {
    props: {
		filter: Object
	},
	data: function () {
		return {
			pageRange: {
				page: 1,
				start: 0,
				end: 20
			}
		}
	},
    computed: {
        pagedAdminUsers() {
			return this.adminUsers.slice(
				this.pageRange.start,
				this.pageRange.end
			)
		}
    },
    methods: {
		filterPage(range) {
			this.pageRange = range
		},
		async resendActivationLink(user) {
			const resp = await this.$store.dispatch(
				"resendAdminUserActivationEmail",
				user
			)
			if (resp) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Successfully sent activation Email"
				})
			} else {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Something went wrong, please try again",
					type: NotificationType.WARNING
				})
			}
		},
		async resendForgotPasswordEmail(user) {
			const resp = await this.$store.dispatch(
				"resendAdminUserForgotPasswordEmail",
				user
			)
			if (resp) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Successfully sent reset password email"
				})
			} else {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Something went wrong, please try again",
					type: NotificationType.WARNING
				})
			}
		},
		async deleteAdminUser(user) {
			const confirmed = await this.$refs.adminUserConfirmationModal.open({
				title: `Are you sure you want to remove ${user.display_name} from your users?`,
				confirmation: "Remove",
				confirmationIcon: "sk-icon-trash-alt-solid",
				cancel: "Cancel",
				type: ConfirmationModalType.RED
			})
			if (confirmed) {
				const resp = this.$store.dispatch("removeAdminUser", user.id)
				if (resp) {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: `Successfully removed ${user.display_name} from user list`
					})
				} else {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Something went wrong, please try again",
						type: NotificationType.WARNING
					})
				}
			}
		}
    }
}

export default adminUserTableMixin