export const GOT_RESTAURANT = "GOT_RESTAURANT"
export const GOT_TODAYS_RESTAURANT_MENUS = "GOT_TODAYS_RESTAURANT_MENUS"
export const GOT_AVERAGE_RATING = "GOT_AVERAGE_RATING"
export const GOT_TODAYS_BUSINESSES = "GOT_TODAYS_BUSINESSES"
export const GOT_TODAYS_EVENTS = "GOT_TODAYS_EVENTS"
export const GOT_RECENT_ORDERS = "GOT_RECENT_ORDERS"
export const LOADING = "LOADING"
export const LOADED = "LOADED"

export default {
  GOT_RESTAURANT,
  GOT_TODAYS_RESTAURANT_MENUS,
  GOT_AVERAGE_RATING,
  GOT_TODAYS_BUSINESSES,
  GOT_TODAYS_EVENTS,
  GOT_RECENT_ORDERS,
  LOADING,
  LOADED
}