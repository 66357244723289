let skModalMixin = {
  data: function()  {
    return {
      isOpen: false
    }
  },
  methods: {
    opened() {},
    closed() {},
    // eslint-disable-next-line no-unused-vars
    handleOptions(options) {},
    open(options) {
      this.handleOptions(options ? options : {})
      this.isOpen = true
      this.opened()
    },
    close() {
      this.isOpen = false
      this.closed()
    }
  }
}

export default skModalMixin