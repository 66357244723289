<template>
	<div class="full-height-table-wrapper">
		<div class="sk-table sk-table-default">
			<div class="sk-table-header">
				<div class="sk-table-row">
					<div>Code</div>
					<div>Type</div>
					<div>Value</div>
					<div>Applies To</div>
					<div>Uses</div>
					<div>Status</div>
					<div>Actions</div>
				</div>
			</div>
			<transition-group
				name="list"
				tag="div"
				class="sk-table-body"
				appear
			>
				<CouponLineItem
					v-for="coupon in coupons"
					:coupon="coupon"
					@deleteCoupon="deleteCoupon"
					@updateCoupon="openCouponModal"
					:key="coupon.id"
				/>
			</transition-group>
		</div>
		<PaginationFooter
			:contentText="'Coupons'"
			:pageSize="20"
			:total="totalCouponsCount"
			ref="paginationFooter"
			@pageChanged="filterPage"
		/>
		<ConfirmationModal ref="confirmationModal"></ConfirmationModal>
		<CouponModal ref="couponModal" />
	</div>
</template>

<script>
import ActionTypes from "@/store/modules/coupons/action-types"
import PageHeader from "@/components/PageHeader.vue"
import PaginationFooter from "@/components/PaginationFooter.vue"
import CouponLineItem from "@/components/coupon/CouponLineItem.vue"
import loaderMixin from "@/mixins/loader-mixin"
import couponStatusMixin from "@/mixins/coupons/coupon-status-mixin"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue"
import CouponModal from "@/components/modals/CouponModal.vue"

export default {
	name: "Coupons",
	mixins: [loaderMixin, couponStatusMixin],
	components: {
		PageHeader,
		CouponLineItem,
		PaginationFooter,
		ConfirmationModal,
		CouponModal
	},
	props: {
		filters: Object
	},
	data: function () {
		return {
			pageRange: {
				page: 1,
				start: 0,
				end: 20
			},
			actions: [
				{
					id: 0,
					text: `<i class="sk-icon-plus-regular"></i> New Promotion`,
					classes: "button button-primary"
				}
			],
			secActions: [
				{
					id: 0,
					text: `<i class="sk-icon-plus-regular"></i> Batch Upload`
				},
				{
					id: 1,
					text: `<i class="sk-icon-link-regular"></i> Download Template`
				}
			],
			filterVals: {
				businessId: 0,
				code: "",
				couponGroup: 0
			}
		}
	},
	computed: {
		coupons() {
			return this.$store.state.couponsModule.coupons
		},
		pagedCoupons() {
			return this.coupons.slice(this.pageRange.start, this.pageRange.end)
		},
		totalCouponsCount() {
			return this.$store.state.couponsModule.totalCouponsCount
		},
		tabs() {
			let allowedTabs = []
			allowedTabs.push({
				id: 0,
				text: "Coupons"
			})
			allowedTabs.push({
				id: 1,
				text: "Vouchers"
			})
			return allowedTabs
				.map((tab) => {
					tab.active = tab.id == this.activeTabId
					return tab
				})
				.sort((a, b) => a.id - b.id)
		}
	},
	mounted: async function () {
		this.getCoupons()
	},
	methods: {
		filterPage: function (range) {
			this.pageRange = range
			this.getCoupons()
		},
		refreshCoupons() {
			if (this.pageRange.page != 1) {
				this.$refs.paginationFooter.pageChanged(1)
			} else {
				this.getCoupons()
			}
		},
		getCoupons() {
			this.$store.dispatch(
				`couponsModule/${ActionTypes.SEARCH_COUPONS}`,
				Object.assign(
					{
						pageStart: this.pageRange.start,
						pageSize: 20
					},
					this.filterVals
				)
			)
		},
		async openCouponModal(coupon) {
			this.$refs.couponModal.open({
				coupon: coupon
			})
		}
	},
	watch: {
		filters: {
			deep: true,
			handler(val) {
				if (
					val.businessId != this.filterVals.businessId ||
					val.code != this.filterVals.code ||
					val.couponGroup != this.filterVals.couponGroup
				) {
					this.filterVals.code = val.code
					this.filterVals.businessId = val.businessId
					this.filterVals.couponGroup = val.couponGroup
					this.refreshCoupons()
				}
			}
		}
	}
}
</script>