<template>
	<div class="events-list">
		<div>
			<div class="sk-table sk-table-default">
				<div class="sk-table-header">
					<div class="sk-table-row">
						<div>Status</div>
						<div>Type</div>
						<div>Business</div>
						<div>Restaurant</div>
						<div>Menu</div>
						<div>Pre Orders</div>
						<div>Date</div>
						<div>Time</div>
						<div>Actions</div>
					</div>
				</div>
				<transition-group
					name="list"
					tag="div"
					class="sk-table-body"
					appear
				>
					<EventLineItem
						v-for="event in pagedEvents"
						:event="event"
						:key="event.id"
						@updateEventStatus="updateEventStatus"
						@updateEventFulfillmentStatus="
							updateEventFulfillmentStatus
						"
						@edit="editEvent"
						@deleteEvent="deleteEvent"
						@duplicateEvent="duplicateEvent"
						@endEvent="endEvent"
						@stopEventPreOrders = "stopEventPreOrders"
					/>
				</transition-group>
			</div>
		</div>
		<PaginationFooter
			:contentText="'Events'"
			:pageSize="20"
			:total="events.length"
			ref="paginationFooter"
			@pageChanged="filterPage"
		/>
	</div>
</template>

<style scoped>
.events-list {
	height: 100%;
	display: flex;
	flex-direction: column;
}
</style>

<script>
import ActionTypes from "@/store/modules/events/action-types"
import PaginationFooter from "@/components/PaginationFooter.vue"
import EventLineItem from "@/components/events/EventLineItem.vue"

export default {
	name: "EventsList",
	components: {
		EventLineItem,
		PaginationFooter
	},
	data: function () {
		return {
			pageRange: {
				page: 1,
				start: 0,
				end: 20
			}
		}
	},
	computed: {
		events() {
			return this.$store.state.eventsModule.events
		},
		pagedEvents() {
			return this.$store.state.eventsModule.events.slice(
				this.pageRange.start,
				this.pageRange.end
			)
		}
	},
	methods: {
		updateEventStatus(event, businessName, status) {
			this.$emit("updateEventStatus", event, businessName, status)
		},
		updateEventFulfillmentStatus(event, businessName, status) {
			this.$emit(
				"updateEventFulfillmentStatus",
				event,
				businessName,
				status
			)
		},
		editEvent(event) {
			this.$emit("edit", event)
		},
		deleteEvent(event, businessName) {
			this.$emit("deleteEvent", event, businessName)
		},
		duplicateEvent(event) {
			this.$emit("duplicateEvent", event)
		},
		endEvent(event, businessName) {
			this.$emit("endEvent", event, businessName)
		},
		stopEventPreOrders(event) {
			this.$emit("stopEventPreOrders", event)
		},
		filterPage(range) {
			this.pageRange = range
		},
		resetPagination() {
			this.$refs.paginationFooter.pageChanged(1)
		}
	},
	mounted: function () {
		this.$emit("resetFilters")
	},
	watch: {
		pagedEvents(newVal) {
			if (newVal && newVal.length > 0) {
				let menuIds = newVal
					.filter((event) => event.menu_id != null)
					.map((event) => event.menu_id)
					.filter((item, i, ar) => ar.indexOf(item) === i)
				if (menuIds.length > 0) {
					this.$store.dispatch(
						`eventsModule/${ActionTypes.GET_EVENT_MENUS}`,
						menuIds
					)
				}
			}
		}
	}
}
</script>