<template>
	<div class="business-invoices">
		<slot v-if="invoices.length > 0">
			<div class="sk-table sk-table-default">
				<div class="sk-table-header">
					<div class="sk-table-row">
						<div
							class="sk-table-column-checkbox"
							@click="batchSelectAllEntitiesClicked($event)"
						>
							<SkCheckbox
								class="sk-checkbox-green"
								:checked="allBatchableEntitiesSelected"
							/>
						</div>
						<div>Business</div>
						<div>Invoice Period</div>
						<div>Credits</div>
						<div>Usage</div>
						<div>Total Amount</div>
						<div>Method</div>
						<div>W9</div>
						<div>Status</div>
						<div>Actions</div>
					</div>
				</div>
				<transition-group
					name="list"
					tag="div"
					class="sk-table-body"
					appear
				>
					<InvoiceLineItem
						v-for="invoice in pagedInvoices"
						:key="invoice.id"
						:invoice="invoice"
						:batchSelectId="invoice.id"
						:batchSelectedIds="batchSelectedIds"
						@batchSelected="batchSelectEntity"
						@approveInvoice="approveInvoice"
						@completeInvoice="completeInvoice"
						@addExternalRecord="addExternalRecord"
						@downloadInvoiceBreakdown="downloadInvoiceBreakdown"
						@viewInvoice="viewInvoice"
					/>
				</transition-group>
			</div>
			<PaginationFooter
				:contentText="'Invoices'"
				:pageSize="20"
				:total="totalInvoices"
				@pageChanged="filterPage"
			/>
		</slot>
		<EmptyPlaceholderWidget v-else :title="`No Invoices`" />
		<ConfirmationModal ref="confirmationModal" />
		<InvoiceDetailModal ref="invoiceDetailModal" :allowPayment="false" />
		<AddInvoiceRecordModal ref="addInvoiceRecordModal" />
	</div>
</template>

<style scoped>
.business-invoices {
	height: 100%;
	display: flex;
	flex-direction: column;
}
</style>

<script>
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import { InvoiceStatus } from "@/enums/invoiceStatus"
import { ConfirmationModalType } from "@/enums/confirmationModalType"
import ActionTypes from "@/store/modules/finance/action-types"
import batchSelectMixin from "@/mixins/batch-select-mixin"
import PaginationFooter from "@/components/PaginationFooter.vue"
import EmptyPlaceholderWidget from "@/components/EmptyPlaceholderWidget.vue"
import SkCheckbox from "@/components/SkCheckbox.vue"
import InvoiceLineItem from "@/components/finance/InvoiceLineItem.vue"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue"
import InvoiceDetailModal from "@/components/modals/InvoiceDetailsModal.vue"
import AddInvoiceRecordModal from "@/components/modals/AddInvoiceRecordModal.vue"

export default {
	name: "Invoices",
	mixins: [batchSelectMixin],
	components: {
		PaginationFooter,
		EmptyPlaceholderWidget,
		SkCheckbox,
		InvoiceLineItem,
		ConfirmationModal,
		InvoiceDetailModal,
		AddInvoiceRecordModal
	},
	props: {
		selectedIds: {
			type: Array
		},
		dateFrom: {
			type: String,
			default: ""
		},
		dateTo: {
			type: String,
			default: ""
		},
		businessId: {
			type: Number,
			default: -1
		},
		status: {
			type: Number
		}
	},
	data: function () {
		return {
			pageRange: {
				page: 1,
				start: 0,
				end: 20
			}
		}
	},
	computed: {
		batchableEntityIds() {
			return this.invoices
				.filter(
					(invoice) => invoice.status.value == InvoiceStatus.PENDING
				)
				.map((invoice) => invoice.id)
		},
		invoices() {
			return this.$store.state.financeModule.businessInvoices
		},
		pagedInvoices() {
			return this.invoices.slice(this.pageRange.start, this.pageRange.end)
		},
		totalInvoices() {
			return this.invoices.length
		},
		filtersChanged() {
			return {
				dateFrom: this.dateFrom,
				dateTo: this.dateTo,
				businessId: this.businessId,
				status: this.status
			}
		}
	},
	mounted: function () {
		this.getInvoices()
	},
	methods: {
		filterPage(range) {
			this.pageRange = range
		},
		cleanUpBatchIds() {
			this.batchSelectedIds = []
			this.$emit("update:selectedIds", [])
		},
		getInvoices() {
			const filters = {}

			if (this.businessId && this.businessId != -1) {
				filters.business = {
					id: this.businessId
				}
			}

			if (this.status > -1) {
				filters.statuses = [this.status]
			}

			if (this.dateFrom) {
				filters.from_date = `${this.dateFrom}T00:00:00Z`
			}

			if (this.dateTo) {
				filters.to_date = `${this.dateTo}T00:00:00Z`
			}

			this.$store.dispatch(
				`financeModule/${ActionTypes.GET_BUSINESS_INVOICES}`,
				{
					filters: filters
				}
			)
		},
		notifyActionFailure(message) {
			eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
				title: "Invoices",
				message: message,
				type: NotificationType.ERROR
			})
		},
		async approveInvoice(invoice) {
			try {
				const confirmed = await this.$refs.confirmationModal.open(
					{
						title: `Are you sure you want to complete this invoice?`,
						confirmation: "Complete",
						confirmationIcon: "sk-icon-check-regular",
						cancel: "Cancel",
						type: ConfirmationModalType.GREEN
					}
				)

				if (confirmed) {
					const success = await this.$store.dispatch(
						`financeModule/${ActionTypes.APPROVE_BUSINESS_INVOICE}`,
						invoice
					)
					if (success) {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Invoices",
							message: "Approved Invoice",
							type: NotificationType.SUCCESS
						})
						this.batchSelectedIds = this.batchSelectedIds.filter(
							(id) => id != invoice.id
						)
					} else {
						this.notifyActionFailure(
							"Failed to approve invoice, please try again"
						)
					}
				}
			} catch (e) {
				this.notifyActionFailure(
					"Failed to approve invoice, please try again"
				)
				throw e
			}
		},
		async completeInvoice(invoice) {
			try {
				const confirmed = await this.$refs.confirmationModal.open(
					{
						title: `Are you sure you want to complete this invoice?`,
						confirmation: "Complete",
						confirmationIcon: "sk-icon-check-regular",
						cancel: "Cancel",
						type: ConfirmationModalType.GREEN
					}
				)

				if (confirmed) {
					const success = await this.$store.dispatch(
						`financeModule/${ActionTypes.COMPLETE_BUSINESS_INVOICE}`,
						invoice
					)
					if (success) {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Invoices",
							message: "Completed Invoice",
							type: NotificationType.SUCCESS
						})
					} else {
						this.notifyActionFailure(
							"Failed to complete invoice, please try again"
						)
					}
				}
			} catch (e) {
				this.notifyActionFailure(
					"Failed to complete invoice, please try again"
				)
				throw e
			}
		},
		addExternalRecord(invoice) {
			this.$refs.addInvoiceRecordModal.open({
				invoice: invoice
			})
		},
		viewInvoice(invoice) {
			this.$refs.invoiceDetailModal.open({
				invoice: invoice
			})
		},
		downloadInvoiceBreakdown(invoice) {
			this.$store
				.dispatch(
					`financeModule/${ActionTypes.DOWNLOAD_INVOICE_USAGE_BREAKDOWN}`,
					invoice
				)
				.then((success) => {
					if (!success) {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Invoice",
							message:
								"Failed to download invoice breakdown, please contact support or try again",
							type: NotificationType.ERROR
						})
					}
				})
		}
	},
	watch: {
		batchSelectedIds: {
			immediate: true,
			handler(ids) {
				this.$emit("update:selectedIds", ids)
			}
		},
		filtersChanged() {
			this.getInvoices()
		}
	}
}
</script>