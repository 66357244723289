
<template>
	<div class="sk-page-content">
		<PageHeader
			:title="headerTitle"
			:tabs="tabs"
			:actions="actions"
			:breadcrumbs="breadcrumbs"
			@actionClicked="headerActionClicked"
			@tabClicked="headerTabClicked"
		/>
		<transition name="slide" mode="out-in">
			<HubDetailsTab
				key="0"
				v-if="activeTabId == 0"
				class="centered-tab-content"
			/>
			<HubRestaurantMenus
				key="1"
				v-if="activeTabId == 1"
				@openAddNewHubMenuModal="openAddNewHubMenuModal"
			/>
			<HubBusinesses
				key="2"
				v-if="activeTabId == 2"
				@openAddHubBusinessModal="openAddHubBusinessModal"
			/>
		</transition>
		<AddHubRestaurantMenuModal ref="addHubRestaurantMenuModal" />
		<AddHubBusinessModal ref="addHubBusinessModal" />
		<AddHubRestaurantMenuToBusinesses
			ref="addHubRestaurantMenuToBusinesses"
		/>
	</div>
</template>
  
<script>
import loaderMixin from "@/mixins/loader-mixin"
import ActionTypes from "@/store/modules/hubs/action-types"
import PageHeader from "@/components/PageHeader.vue"
import HubDetailsTab from "@/components/hubs/HubDetailsTab.vue"
import HubRestaurantMenus from "@/components/hubs/HubRestaurantMenus.vue"
import HubBusinesses from "@/components/hubs/HubBusinesses.vue"
import AddHubRestaurantMenuModal from "@/components/modals/hubs/AddHubRestaurantMenuModal.vue"
import AddHubBusinessModal from "@/components/modals/hubs/AddHubBusinessModal.vue"
import AddHubRestaurantMenuToBusinesses from "@/components/modals/hubs/AddHubRestaurantMenuToBusinesses.vue"

export default {
	name: "Hub",
	mixins: [loaderMixin],
	components: {
		PageHeader,
		HubDetailsTab,
		HubRestaurantMenus,
		HubBusinesses,
		AddHubRestaurantMenuModal,
		AddHubBusinessModal,
		AddHubRestaurantMenuToBusinesses
	},
	data: function () {
		return {
			activeTabId: 0
		}
	},
	computed: {
		currentHub() {
			return this.$store.state.hubsModule.currentHub
		},
		headerTitle() {
			if (this.currentHub && this.currentHub.name) {
				return this.currentHub.name
			}
			return "..."
		},
		actions() {
			switch (this.activeTabId) {
				case 1: {
					return [
						{
							id: 1,
							text: `<i class="sk-icon-plus-regular"></i> Add New Menu`,
							classes: "button button-primary"
						}
					]
				}
				case 2: {
					return [
						{
							id: 2,
							text: `<i class="sk-icon-plus-regular"></i> Add New Business`,
							classes: "button button-primary"
						}
					]
				}
			}
		},
		tabs() {
			const tabs = [
				{
					id: 0,
					text: "Details"
				},
				{
					id: 1,
					text: "Retaurants & Menus"
				},
				{
					id: 2,
					text: "Businesses"
				}
			]

			return tabs.map((tab) => {
				return {
					id: tab.id,
					text: tab.text,
					active: this.activeTabId == tab.id
				}
			})
		},
		breadcrumbs() {
			return [
				{
					name: "Hubs",
					link: "/hubs"
				},
				{
					name: this.headerTitle
				}
			]
		}
	},
	methods: {
		headerActionClicked() {
			switch (this.activeTabId) {
				case 1: {
					this.openAddNewHubMenuModal()
					break
				}
				case 2: {
					this.openAddHubBusinessModal()
					break
				}
			}
		},
		headerTabClicked(tabClicked) {
			this.activeTabId = tabClicked.id
		},
		openAddNewHubMenuModal() {
			this.$refs.addHubRestaurantMenuModal.open().then((data) => {
				if (data && data.menus && this.currentHub && this.currentHub.business_ids.length > 0) {
					const missingMenus = []
					const businessesMap = this.$store.getters.businessesMap
					this.currentHub.business_ids.forEach((id) => {
						let business = businessesMap[id]
						if (business && business.menus) {
							const businessMenus = business.menus.reduce((menus, menu) => {
								menus[menu.id] = menu
								return menus
							}, {})
							const menus = data.menus.filter(menu => !businessMenus[menu.id])
							if (menus.length > 0) {
								missingMenus.push({
									business: business,
									restaurant: data.restaurant,
									menus: menus
								})
							}
						}
					})
					if (missingMenus.length > 0) {
						this.$refs.addHubRestaurantMenuToBusinesses.open({
							missingMenus: missingMenus
						})
					}
				}
			})
		},
		openAddHubBusinessModal() {
			this.$refs.addHubBusinessModal.open()
		}
	},
	mounted: function () {
		this.toggleLoader(true)
		this.$store
			.dispatch(
				`hubsModule/${ActionTypes.GET_HUB}`,
				this.$route.params.hub_id
			)
			.finally(() => {
				this.toggleLoader(false)
			})
	}
}
</script>