<template>
	<div class="sk-widget sk-widget-line-items">
		<div class="sk-widget-line-item-header">
			<div>{{ deviceName }}</div>
			<span class="pill pill-green" v-if="isHealthy">
				<i class="sk-icon-heart-rate-regular"></i>
				Online
			</span>
			<span class="pill pill-red" v-else>
				<i class="sk-icon-heart-rate-regular"></i>
				{{ offlineText }}
			</span>
		</div>
		<div class="sk-widget-line-item">
			<div>Name</div>
			<div>{{ device.external_name }}</div>
		</div>
		<div class="sk-widget-line-item">
			<div>Last Seen</div>
			<div>{{ device.last_seen | relativeTime }} ago</div>
		</div>
		<div v-if="device.battery_status != null" class="sk-widget-line-item">
			<div>Battery Status</div>
			<div>{{ batteryStatus }}</div>
		</div>
		<div v-if="device.os_version != null" class="sk-widget-line-item">
			<div>OS</div>
			<div>{{ device.os_version }}</div>
		</div>
		<div v-if="device.app_version != null" class="sk-widget-line-item">
			<div>App Version</div>
			<div>{{ device.app_version }}</div>
		</div>
		<button
			class="button button-light-grey"
			v-if="canRefreshPOS"
			@click="refreshPos"
		>
			<i class="sk-icon-sync-regular"></i>
			Refresh Device
		</button>
	</div>
</template>

<style scoped>
.sk-widget-line-items {
	min-width: 380px;
	max-width: 430px;
}

.pill {
	height: 28px;
}

.button-light-grey {
	height: 28px;
	min-height: 28px;
	display: block;
	margin: 12px auto;
}

@media (max-width: 400px) {
	.sk-widget {
		min-width: 300px;
	}
}
</style>

<script>
import moment from "moment"
import { RestaurantPOSDevice } from "@/enums/restaurantPOSDevice"

export default {
	name: "RestaurantPOS",
	props: {
		device: Object
	},
	computed: {
		backgroundClass() {
			return "bg-kiosk"
		},
		deviceName() {
			return "2K Tablet"
		},
		isHealthy() {
			return this.device.is_healthy && this.device.is_active
		},
		offlineText() {
			return this.device.is_active ? "Offline" : "Inactive"
		},
		batteryStatus() {
			if (this.device.battery_status) {
				return `${this.device.battery_status}%`
			}
			return ""
		},
		canRefreshPOS() {
			return this.device.pos_system_id == RestaurantPOSDevice.TABLET
		}
	},
	filters: {
		relativeTime(value) {
			return value ? moment.utc().from(moment.utc(value), true) : null
		}
	},
	methods: {
		refreshPos() {
			this.$store.dispatch("restaurantsModule/refreshRestaurantTablet", this.device.id)
		}
	}
}
</script>