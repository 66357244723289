<template>
	<div class="payout-details-history">
		<div class="sk-table sk-table-default">
			<div class="sk-table-header">
				<div class="sk-table-row">
					<div>Payout Period</div>
					<div>Location</div>
					<div>Method</div>
					<div># Orders</div>
					<div>Sales</div>
					<div>Taxes</div>
					<div>Fees</div>
					<div>Tips</div>
					<div>Exceptions</div>
					<div>Payout</div>
					<div>Status</div>
					<div>Actions</div>
				</div>
			</div>
			<transition-group
				name="list"
				tag="div"
				class="sk-table-body"
				appear
			>
				<PayoutDetailsHistoryLineItem
					v-for="payout in pagedPayouts"
					:payout="payout"
					:key="payout.id"
				/>
			</transition-group>
		</div>
		<PaginationFooter
			:contentText="'Payouts'"
			:pageSize="20"
			:total="payouts.length"
			ref="paginationFooter"
			@pageChanged="filterPage"
		/>
	</div>
</template>

<style scoped>
.payout-details-history {
	display: flex;
	flex-direction: column;
	height: 100%;
}
</style>

<script>
import PayoutDetailsHistoryLineItem from "@/components/finance/PayoutDetailsHistoryLineItem.vue"
import PaginationFooter from "@/components/PaginationFooter.vue"
import moment from "moment"
import { PayoutStatus } from "@/enums/payoutStatus"

export default {
	name: "PayoutDetailsHistory",
	components: {
		PayoutDetailsHistoryLineItem,
		PaginationFooter
	},
	props: {
		filters: Object
	},
	data: function () {
		return {
			pageRange: {
				page: 1,
				start: 0,
				end: 20
			}
		}
	},
	computed: {
		pagedPayouts() {
			return this.payouts.slice(this.pageRange.start, this.pageRange.end)
		},
		payouts() {
			var retVal = this.$store.state.financeModule.currentRestaurantPayoutHistory
			if (
				this.filters.payoutStartDate != null &&
				this.filters.payoutEndDate != null
			) {
				if (
					this.filters.payoutStartDate != null &&
					this.filters.payoutEndDate != null
				) {
					retVal = retVal.filter(
						(payout) =>
							moment
								.utc(payout.from_date)
								.isSameOrAfter(
									this.filters.payoutStartDate,
									"days"
								) &&
							moment(payout.to_date).isSameOrBefore(
								this.filters.payoutEndDate,
								"days"
							)
					)
				} else {
					retVal = retVal.filter((payout) =>
						moment(payout.from_date).isSame(
							this.filters.payoutStartDate,
							"days"
						)
					)
				}
			}
			if (this.filters.status != -1) {
				if (this.filters.status == PayoutStatus.PROCESSING) {
					retVal = retVal.filter(
						(payout) =>
							payout.status.value == PayoutStatus.APPROVED ||
							payout.status.value == PayoutStatus.PROCESSING
					)
				} else {
					retVal = retVal.filter(
						(payout) => payout.status.value == this.filters.status
					)
				}
			}
			return retVal
		}
	},
	methods: {
		filterPage(range) {
			this.pageRange = range
			this.$emit("pageChanged", range)
		}
	}
}
</script>