<template>
	<div class="mobile-button-bottom">
		<div class="sk-row sk-widget-alt sk-widget-section">
			<div class="sk-widget-section-title">Name</div>
			<SkInput
				:name="'Hub Name'"
				:placeholder="'Apartment, suite, etc.'"
				v-model="hub.name"
			/>
		</div>
		<div class="sk-row sk-widget-alt sk-widget-section">
			<div class="sk-widget-section-title">Location</div>
			<TypeaheadInput
				:placeholder="'Hub City & State'"
				:fetch="autocompleteLocation"
				:serializer="typeaheadSerializer"
				:query="locationQuery"
				@selected="locationSelected"
			/>
		</div>
		<button class="button button-primary button-absolute" @click="saveHub">
			Save Details
		</button>
	</div>
</template>

<style scoped>
</style>

<script>
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import ActionTypes from "@/store/modules/hubs/action-types"
import gapi from "@/utils/gapi"
import loaderMixin from "@/mixins/loader-mixin"
import TypeaheadInput from "@/components/TypeaheadInput.vue"
import SkInput from "@/components/SkInput.vue"
import utils from "@/utils/utils"

export default {
	name: "HubDetailsTab",
	mixins: [loaderMixin],
	components: {
		TypeaheadInput,
		SkInput
	},
	data: function () {
		return {
			hub: {},
			locationQuery: ""
		}
	},
	computed: {
		currentHub() {
			return this.$store.state.hubsModule.currentHub
		}
	},
	methods: {
		typeaheadSerializer(result) {
			return {
				text: result.name
			}
		},
		autocompleteLocation(query) {
			return gapi.autocompleteLocation(query, ["(regions)"])
		},
		locationSelected(location) {
			this.hub.external_location_id = location.id
			this.hub.city = location.city
			this.hub.state = location.state
		},
		validateHub() {
			if (
				!this.hub.external_location_id ||
				!this.hub.city ||
				!this.hub.state
			) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Save Hub",
					message: "Please select a hub location",
					type: NotificationType.WARNING
				})
				return false
			} else if (!this.hub.name || this.hub.name.length == 0) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Save Hub",
					message: "Please enter a valid hub name",
					type: NotificationType.WARNING
				})
				return false
			}
			return true
		},
		async saveHub() {
			try {
				this.toggleLoader(true)
				const valid = this.validateHub()
				if (valid) {
					await this.$store.dispatch(
						`hubsModule/${ActionTypes.UPDATE_HUB}`,
						this.hub
					)
				}
				this.toggleLoader(false)
			} catch (e) {
				this.toggleLoader(false)
				throw e
			}
		}
	},
	watch: {
		currentHub: {
			immediate: true,
			handler: function (val) {
				if (val) {
					this.hub = utils.clonedeep(val)

					if (val.city && val.state && val.external_location_id) {
						this.locationQuery = `${val.city}, ${val.state}`
					} else {
						this.locationQuery = ""
					}
				}
			}
		}
	}
}
</script>