var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sk-table-row"},[_c('div',{staticClass:"sk-table-column-fit"},[_c('div',{staticClass:"sk-pills-container"},[_c('div',{class:_vm.statusBadgeClasses,attrs:{"title":_vm.statusLabel}},[_c('span',{class:_vm.statusClasses}),_vm._v(" "+_vm._s(_vm.statusLabel)+" ")])])]),_c('div',{attrs:{"title":_vm.$options.filters.eventTypeName(_vm.event.type)}},[_vm._v(" "+_vm._s(_vm._f("eventTypeName")(_vm.event.type))+" ")]),_c('div',{directives:[{name:"sk-unassigned",rawName:"v-sk-unassigned",value:(_vm.businessName),expression:"businessName"}],staticClass:"short-name text-truncate",attrs:{"title":_vm.businessName}}),_c('div',{directives:[{name:"sk-unassigned",rawName:"v-sk-unassigned",value:(_vm.restaurantName),expression:"restaurantName"}],staticClass:"short-name text-truncate",attrs:{"title":_vm.restaurantName}}),_c('div',{directives:[{name:"sk-unassigned",rawName:"v-sk-unassigned",value:(_vm.menuName),expression:"menuName"}],staticClass:"short-name text-truncate",attrs:{"title":_vm.menuName}}),_c('div',[_vm._v(" "+_vm._s(_vm.preordersEnabled)+" ")]),_c('div',{directives:[{name:"sk-unassigned",rawName:"v-sk-unassigned",value:(_vm.eventDate),expression:"eventDate"}],attrs:{"title":_vm.eventDate}}),_c('div',{directives:[{name:"sk-unassigned",rawName:"v-sk-unassigned",value:(_vm.eventTimeRange),expression:"eventTimeRange"}],attrs:{"title":_vm.eventTimeRange}}),_c('div',{staticClass:"sk-table-column-fit"},[_c('div',{staticClass:"sk-table-actions"},[(_vm.isConfirmable)?_c('button',{staticClass:"button button-icon",attrs:{"title":"Confirm"},on:{"click":function($event){return _vm.actionOptionClicked({
						id: 4
					})}}},[_c('i',{staticClass:"sk-icon-check-regular"})]):(_vm.isEditable)?_c('button',{staticClass:"button button-icon",attrs:{"title":"Edit"},on:{"click":function($event){return _vm.actionOptionClicked({
						id: 1
					})}}},[_c('i',{staticClass:"sk-icon-pencil-solid"})]):(_vm.isDeletable)?_c('button',{staticClass:"button button-icon",attrs:{"title":"Delete"},on:{"click":function($event){return _vm.actionOptionClicked({
						id: 3
					})}}},[_c('i',{staticClass:"sk-icon-trash-alt-solid"})]):_c('button',{staticClass:"button button-icon",attrs:{"title":"View"},on:{"click":function($event){return _vm.actionOptionClicked({
						id: 2
					})}}},[_c('i',{staticClass:"sk-icon-eye-solid"})]),(_vm.allowedActions)?_c('SkDropdownSelect',{staticClass:"secondary-actions",attrs:{"options":_vm.allowedActions,"iconClasses":'sk-icon-ellipsis-v-regular',"showSelectedOption":false,"position":'left'},on:{"selected":_vm.actionOptionClicked}}):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }