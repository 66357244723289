import Vue from "vue"

import { store } from "@/store/store"
import { parseResponse } from "@arikgaisler/utils/api-utils"

let host = process.env.VUE_APP_API_HOST
const POS_API = `https://pos${host}.2ndkitchen.com`


export default {
    async refreshRestaurantTablet(pos_id) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return Vue.axios.post(`${POS_API}/pos_devices/${pos_id}/refresh`, null, { 'headers': { 'Authorization': tokenStr } })
    },
    async refreshPOSOrder(sub_order_id) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.post(`${POS_API}/restaurants/pos_systems/orders/${sub_order_id}/refresh`, null, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getRestaurantsPOSDevices(rest_ids) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return Vue.axios.get(`${POS_API}/pos_devices?restaurants_ids=${rest_ids}`, { "headers": { "Authorization": tokenStr } })
    },
    async getRestaurantPOSDevices(rest_id) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return Vue.axios.get(`${POS_API}/restaurants/${rest_id}/pos_devices`, { "headers": { "Authorization": tokenStr } })
    },
}