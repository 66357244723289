<template>
	<SkBaseModal
		class="request-event-modal"
		:isOpen="isOpen"
		:showCloseButton="false"
		:hasLoader="true"
		@close="close"
	>
		<loading :active.sync="loading" :is-full-page="false" />
		<div class="sk-modal-alt-title">
			<h1>Request An Event</h1>
		</div>
		<transition-expand>
			<div v-if="currentStep != 0">
				<SkModalStepNavbar
					:steps="steps"
					:currentStep="currentStep"
					@setStep="setStep"
				/>
			</div>
		</transition-expand>
		<transition-group name="fade-height" class="request-event-steps">
			<div
				v-if="currentStep == 0"
				class="sk-grid radio-selection-wrapper"
				key="0"
			>
				<div class="sk-widget-alt radio-selection-widget">
					<div>
						<SkRadio
							:option="flowTypes.SPONSORED"
							v-model="flowType"
						/>
					</div>
					<div class="radio-selection-description">
						<div>Sponsored</div>
						<div>
							Want to treat your residents? Set a budget to fully,
							or partially, pre pay meals for a select number of
							guests
						</div>
					</div>
				</div>
				<div class="sk-widget-alt radio-selection-widget">
					<div>
						<SkRadio
							:option="flowTypes.STANDARD"
							v-model="flowType"
						/>
					</div>
					<div class="radio-selection-description">
						<div>Standard</div>
						<div>
							Interested in boosting resident happiness? Invite
							2ndKitchen partners to your business to serve unique
							food directly to your residents, at no cost to you.
						</div>
					</div>
				</div>
			</div>
			<div v-show="currentStep == 1" key="1" class="sk-modal-section">
				<div class="sk-row">
					<VueCtkDateTimePicker
						v-model="eventDate"
						:label="'Event Date'"
						:no-clear-button="true"
						:no-label="true"
						:no-button-now="true"
						:only-date="true"
						:button-color="'#90DA36'"
						:format="'MM-DD-YYYY'"
						:formatted="'ll'"
						:color="'#2E3C49'"
						:output-format="'ddd MMM DD, YYYY'"
						:min-date="minStartDate"
						:no-value-to-custom-elem="true"
					>
						<SkInput
							ref="eventDateInput"
							:value="eventDate"
							:disabled="true"
							:name="'Event Date'"
							:required="true"
						/>
					</VueCtkDateTimePicker>
				</div>
				<transition-expand>
					<div class="sk-row" v-if="showDateWarning">
						<div class="event-date-warning">
							<i class="sk-icon-exclamation-triangle-solid"></i>
							<span>
								The selected date is within 5 days of today.
								There is a chance your event may not be able to
								be set up before then.
							</span>
						</div>
					</div>
				</transition-expand>
				<div class="sk-row">
					<SkTimePicker
						v-model="eventTime"
						ref="eventTimeInput"
						:name="'Event Time'"
						:required="true"
						:inputFormat="'h:mm A'"
					/>
				</div>
				<div class="sk-row">
					<div class="sk-switch-label-wrapper">
						<SkCheckbox v-model="flexibleOnWhen" />
						<span>Flexible on Date & Time</span>
					</div>
				</div>
			</div>
			<div
				v-show="currentStep == 2"
				key="2"
				class="sk-grid radio-selection-wrapper"
			>
				<div class="sk-widget-alt has-budget-widget">
					<div class="radio-selection-widget">
						<div>
							<SkRadio :option="true" v-model="hasBudget" />
						</div>
						<div class="radio-selection-description">
							<div>I Know My Budget</div>
							<div>Know exactly what you want? Let us know</div>
						</div>
					</div>
					<transition-expand>
						<div v-if="hasBudget" class="budget-details-widget">
							<SkInput
								v-model="estimatedBudget"
								ref="estimatedBudgetInput"
								:name="'Estimated Budget'"
								:required="true"
							/>
							<SkInput
								class="optional-field"
								v-model="estimatedGuests"
								:name="'Estimated Guests Covered'"
							/>
						</div>
					</transition-expand>
				</div>
				<div class="sk-widget-alt radio-selection-widget">
					<div>
						<SkRadio :option="false" v-model="hasBudget" />
					</div>
					<div class="radio-selection-description">
						<div>I Do Not Know My Budget</div>
						<div>
							We’ll send you budget options based on your needs to
							help you decide.
						</div>
					</div>
				</div>
			</div>
			<div v-show="currentStep == 3" key="3" class="sk-modal-section">
				<div class="sk-row">
					<SkInput
						class="optional-field"
						v-model="eventDetails"
						:name="'Give any details about the event you’d like to have! Theme? Food Type? Let us know!'"
						:multiline="true"
					/>
				</div>
				<div class="sk-row" v-if="ownsMultipleEntities">
					<SkDropdownSelect
						class="sk-input"
						v-model="eventBusiness"
						ref="eventBusinessInput"
						:options="businesses"
						:required="true"
					/>
				</div>
			</div>
			<div v-show="currentStep == 4" key="4" class="sk-modal-section">
				<SetupReviewStep
					:stepName="'Type'"
					:stepId="0"
					:complete="true"
					:invalid="false"
					:progress="1"
					:maxProgress="1"
					:reviewingStepId="reviewingStepId"
					@reviewStep="reviewStep"
					@goToStep="setStep"
				>
					<div class="setup-review-step-detail">
						Type
						<b>{{ requestTypeText }}</b>
					</div>
				</SetupReviewStep>
				<SetupReviewStep
					:stepName="'When'"
					:stepId="1"
					:complete="whenStepComplete"
					:invalid="whenStepInvalid"
					:progress="whenStepProgress"
					:maxProgress="2"
					:reviewingStepId="reviewingStepId"
					@reviewStep="reviewStep"
					@goToStep="setStep"
				>
					<div
						class="setup-review-step-detail"
						:class="{
							invalid: !eventDate
						}"
					>
						Event Date
						<b v-sk-unassigned="eventDate" />
						<i v-if="flexibleOnWhen">Flexible</i>
					</div>
					<div
						class="setup-review-step-detail"
						:class="{
							invalid: !eventTime
						}"
					>
						Event Time
						<b v-sk-unassigned="eventTime" />
					</div>
				</SetupReviewStep>
				<SetupReviewStep
					v-if="showBudgetStep"
					:stepName="'Budget'"
					:stepId="2"
					:complete="budgetStepComplete"
					:invalid="budgetStepInvalid"
					:progress="budgetStepProgress"
					:maxProgress="2"
					:reviewingStepId="reviewingStepId"
					@reviewStep="reviewStep"
					@goToStep="setStep"
				>
					<div class="setup-review-step-detail">
						Type
						<b>{{ hasBudgetText }}</b>
					</div>
					<div
						v-if="hasBudget"
						class="setup-review-step-detail"
						:class="{
							invalid: estimatedBudget == null
						}"
					>
						Estimated Budget
						<b v-sk-unassigned="formattedEstimatedBudget" />
					</div>
					<div v-if="hasBudget" class="setup-review-step-detail">
						Estimated Guest Coverage
						<b v-sk-unassigned="estimatedGuests" />
					</div>
				</SetupReviewStep>
				<SetupReviewStep
					:stepName="'Details'"
					:stepId="3"
					:complete="detailsStepComplete"
					:invalid="detailsStepInvalid"
					:progress="detailsStepProgress"
					:maxProgress="2"
					:reviewingStepId="reviewingStepId"
					@reviewStep="reviewStep"
					@goToStep="setStep"
				>
					<div class="setup-review-step-detail">
						Details
						<b v-sk-unassigned="eventDetails" />
					</div>
					<div
						v-if="ownsMultipleEntities"
						:class="{
							invalid: eventBusiness.id == null
						}"
						class="setup-review-step-detail"
					>
						Business
						<b v-sk-unassigned="eventBusinessText" />
					</div>
				</SetupReviewStep>
			</div>
		</transition-group>
		<div class="sk-modal-actions">
			<button @click="close" class="button button-text">
				Cancel
			</button>
			<button
				v-if="currentStep != lastStep"
				class="button button-primary"
				@click="nextStep"
			>
				Continue
				<i class="sk-icon-caret-right-solid"></i>
			</button>
			<button v-else class="button button-primary" @click="requestEvent">
				Request Event
			</button>
		</div>
	</SkBaseModal>
</template>

<style scoped>
.request-event-modal >>> .sk-modal-content {
	background: var(--sk-greybg2);
}

.sk-modal-alt-title {
	margin-bottom: 0;
	position: relative;
}

.request-event-steps {
	display: grid;
	grid-template-columns: 1fr;
}

.request-event-steps > div {
	grid-row-start: 1;
	grid-column-start: 1;
}

.request-event-steps > div:not(.radio-selection-wrapper) {
	padding: 5px;
	margin: -5px;
}

.radio-selection-wrapper {
	margin: 20px -5px;
}

.radio-selection-widget {
	display: flex;
	align-items: center;
}

.radio-selection-widget >>> .sk-radio {
	width: 20px;
	height: 20px;
	margin-right: 15px;
}

.radio-selection-description > div:first-child {
	font-size: 14px;
	font-weight: 600;
	color: var(--sk-navy);
	margin-bottom: 5px;
}

.radio-selection-description > div:last-child {
	font-size: 12px;
	color: var(--sk-grey2);
}

.sk-switch-label-wrapper span {
	font-size: 14px;
	font-weight: 600;
	color: var(--sk-dark-navy);
}

.event-date-warning {
	display: flex;
	padding: 0 10px;
}

.event-date-warning i,
.event-date-warning span {
	color: var(--sk-yellow);
	font-size: 12px;
}

.event-date-warning span {
	margin-left: 10px;
	font-style: italic;
}

.has-budget-widget {
	padding-bottom: 0;
}

.has-budget-widget .sk-widget-alt {
	padding-bottom: 15px;
}

.has-budget-widget .radio-selection-widget {
	padding-bottom: 15px;
}

.budget-details-widget {
	border-top: 1px solid var(--sk-grey);
	padding-right: 20px;
	padding-left: 20px;
	margin-left: -20px;
	margin-right: -20px;
}

.budget-details-widget div:first-child {
	margin-top: 15px;
}

.budget-details-widget div {
	margin-bottom: 15px;
}

.request-event-modal >>> .sk-input-multiline .sk-input {
	height: 125px;
}

.sk-modal-actions .button i {
	margin-left: 30px;
	margin-right: 0px;
}
</style>

<script>
import moment from "moment"
import "moment-timezone"
import currencyFilterMixin from "@/mixins/currency-filter-mixin"
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import SkModalStepNavbar from "@/components/modals/SkModalStepNavbar.vue"
import SkInput from "@/components/SkInput.vue"
import SkTimePicker from "@/components/SkTimePicker.vue"
import SkRadio from "@/components/SkRadio.vue"
import SkCheckbox from "@/components/SkCheckbox.vue"
import SkToggle from "@/components/SkToggle.vue"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"
import Loading from "vue-loading-overlay"
import VueCtkDateTimePicker from "vue-ctk-date-time-picker"
import TransitionExpand from "@/components/transitions/TransitionExpand.vue"
import SetupReviewStep from "@/components/modals/SetupReviewStep.vue"
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import ActionTypes from "@/store/modules/events/action-types"

const RequestFlowType = {
	SPONSORED: 0,
	STANDARD: 1
}

const RequestEventStep = {
	TYPE: 0,
	WHEN: 1,
	BUDGET: 2,
	DETAILS: 3,
	REVIEW: 4
}

export default {
	name: "RequestEventModal",
	mixins: [skModalMixin, currencyFilterMixin],
	components: {
		SkBaseModal,
		SkModalStepNavbar,
		SkInput,
		SkTimePicker,
		SkRadio,
		SkCheckbox,
		SkToggle,
		SkDropdownSelect,
		Loading,
		VueCtkDateTimePicker,
		TransitionExpand,
		SetupReviewStep
	},
	data: function () {
		return {
			loading: false,
			currentStep: 0,
			lastStep: 4,
			stepsSeen: [RequestEventStep.TYPE],
			reviewingStepId: null,
			minStartDate: moment().format("YYYY-MM-DD"),
			flowType: RequestFlowType.SPONSORED,
			flowTypes: RequestFlowType,
			flexibleOnWhen: false,
			eventDate: null,
			eventTime: null,
			hasBudget: true,
			estimatedBudget: null,
			estimatedGuests: null,
			eventDetails: "",
			eventBusiness: {
				id: null,
				text: "Business"
			}
		}
	},
	computed: {
		ownsMultipleEntities() {
			return this.$store.getters.ownsMultipleEntities
		},
		businesses() {
			const businessOptions = [
				{
					id: null,
					text: "Business"
				}
			]
			const businesses = Object.values(this.$store.state.businesses).map(
				(business) => {
					return {
						id: business.id,
						text: business.name
					}
				}
			)
			return businessOptions.concat(businesses)
		},
		steps() {
			let steps = [
				{
					id: RequestEventStep.TYPE,
					isActive: this.currentStep == RequestEventStep.TYPE,
					isInvalid: false,
					isComplete: true,
					text: "Type"
				},
				{
					id: RequestEventStep.WHEN,
					isActive: this.currentStep == RequestEventStep.WHEN,
					isComplete: this.whenStepComplete,
					isInvalid: this.whenStepInvalid,
					text: "When"
				},
				{
					id: RequestEventStep.BUDGET,
					isActive: this.currentStep == RequestEventStep.BUDGET,
					isComplete: this.budgetStepComplete,
					isInvalid: this.budgetStepInvalid,
					text: "Budget"
				},
				{
					id: RequestEventStep.DETAILS,
					isActive: this.currentStep == RequestEventStep.DETAILS,
					isComplete: this.detailsStepComplete,
					isInvalid: this.detailsStepInvalid,
					text: "Details"
				},
				{
					id: RequestEventStep.REVIEW,
					isActive: this.currentStep == RequestEventStep.REVIEW,
					isComplete: false,
					isInvalid: false,
					text: "Review"
				}
			]

			if (!this.showBudgetStep) {
				steps = steps.filter(
					(step) => step.id != RequestEventStep.BUDGET
				)
			}

			return steps
		},
		showBudgetStep() {
			return this.flowType != RequestFlowType.STANDARD
		},
		whenStepComplete() {
			return this.eventDate != null && this.eventTime != null
		},
		whenStepInvalid() {
			return (
				this.stepsSeen.includes(RequestEventStep.WHEN) &&
				(this.eventDate == null || this.eventTime == null)
			)
		},
		whenStepProgress() {
			let progress = 0
			if (this.eventDate != null) {
				progress += 1
			}
			if (this.eventTime != null) {
				progress += 1
			}
			return progress
		},
		budgetStepComplete() {
			if (this.hasBudget) {
				return this.estimatedBudget != null
			}
			return true
		},
		budgetStepInvalid() {
			if (this.hasBudget) {
				return (
					this.stepsSeen.includes(RequestEventStep.BUDGET) &&
					this.estimatedBudget == null
				)
			}
			return false
		},
		budgetStepProgress() {
			let progress = 0
			if (this.hasBudget) {
				if (this.estimatedBudget != null) {
					progress += 1
				}
				if (this.estimatedGuests != null) {
					progress += 1
				}
			}
			return progress
		},
		detailsStepComplete() {
			return this.eventBusiness.id != null
		},
		detailsStepInvalid() {
			return (
				this.stepsSeen.includes(RequestEventStep.DETAILS) &&
				this.eventBusiness.id == null
			)
		},
		detailsStepProgress() {
			let progress = 0
			if (this.eventBusiness.id != null) {
				progress += 1
			}
			if (this.eventDetails.length > 0) {
				progress += 1
			}
			return progress
		},
		canRequestEvent() {
			return this.steps.every((step) => !step.isInvalid)
		},
		requestType: {
			get: function () {
				return this.flowType
			},
			set: async function (type) {
				switch (type) {
					case RequestFlowType.SPONSORED:
						this.hasBudget = true

						break
					case RequestFlowType.STANDARD:
						this.hasBudget = false
						this.estimatedBudget = null
						this.estimatedGuests = null
						break
				}
			}
		},
		showDateWarning() {
			if (this.eventDate) {
				const warningDateThreshold = moment
					.utc()
					.startOf("day")
					.add(5, "d")
				const eventDate = moment
					.utc(`${this.eventDate}`, "ddd MMM DD")
					.startOf("day")
				return eventDate.isBefore(warningDateThreshold)
			}
			return false
		},
		requestTypeText() {
			switch (this.flowType) {
				case RequestFlowType.SPONSORED:
					return "Sponsored"
				case RequestFlowType.STANDARD:
					return "Standard"
			}
			return ""
		},
		hasBudgetText() {
			return this.hasBudget
				? "I Know My Budget"
				: "I Do Not Know My Budget"
		},
		formattedEstimatedBudget() {
			return this.estimatedBudget
				? this.$options.filters.currency(this.estimatedBudget)
				: null
		},
		eventBusinessText() {
			return this.eventBusiness.id != null
				? this.eventBusiness.text
				: null
		}
	},
	methods: {
		setLoader(loading) {
			this.loading = loading
		},
		handleOptions() {
			if (!this.ownsMultipleEntities) {
				const businesses = Object.values(this.$store.state.businesses)
				if (businesses.length > 0) {
					this.eventBusiness = {
						id: businesses[0].id,
						text: businesses[0].name
					}
				}
			}
		},
		resetValidation() {
			this.$refs["eventDateInput"].resetValidation()
			this.$refs["eventTimeInput"].resetValidation()
			if (this.$refs["estimatedBudgetInput"]) {
				this.$refs["estimatedBudgetInput"].resetValidation()
			}
			if (this.$refs["eventBusinessInput"]) {
				this.$refs["eventBusinessInput"].resetValidation()
			}
		},
		closed() {
			this.loading = false
			this.currentStep = 0
			this.stepsSeen = [RequestEventStep.TYPE]
			this.flowType = RequestFlowType.SPONSORED
			this.eventDate = null
			this.eventTime = null
			this.flexibleOnWhen = false
			this.hasBudget = true
			this.estimatedBudget = null
			this.estimatedGuests = null
			this.eventDetails = ""
			this.eventBusiness = {
				id: null,
				text: "Business"
			}
			this.resetValidation()
			this.reviewingStepId = null
		},
		nextStep() {
			if (this.currentStep < this.lastStep) {
				let nextStep = this.currentStep + 1
				if (!this.showBudgetStep && nextStep == 2) {
					nextStep += 1
				}
				this.setStep(nextStep)
			}
		},
		setStep(id) {
			if (!this.stepsSeen.includes(this.currentStep)) {
				this.stepsSeen.push(this.currentStep)
			}
			if (this.currentStep == RequestEventStep.WHEN) {
				this.$refs["eventDateInput"].validate()
				this.$refs["eventTimeInput"].validate()
			} else if (
				this.currentStep == RequestEventStep.BUDGET &&
				this.hasBudget
			) {
				this.$refs["estimatedBudgetInput"].validate()
			} else if (
				this.currentStep == RequestEventStep.DETAILS &&
				this.ownsMultipleEntities
			) {
				this.$refs["eventBusinessInput"].validate()
			}
			this.currentStep = id
		},
		reviewStep(id) {
			this.reviewingStepId = id
		},
		async requestEvent() {
			try {
				if (!this.canRequestEvent) {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Request an Event",
						message: "Required fields are missing or invalid",
						type: NotificationType.ERROR
					})
					const firstInvalidStep = this.steps.find(
						(step) => step.isInvalid
					)
					this.reviewingStepId = firstInvalidStep.id
					return false
				}
				this.loading = true
				let requestNotes = ""
				const flexibleDates = this.flexibleOnWhen ? "Y" : "N"
				if (this.hasBudget) {
					const guestCount = this.estimatedGuests
						? this.estimatedGuests
						: "N/A"
					requestNotes = `Budget: ${this.estimatedBudget}, Est. Guest Count: ${guestCount}, Flexible Dates: ${flexibleDates}`
				} else {
					requestNotes = `Flexible Dates : ${flexibleDates}`
				}
				const business = this.$store.getters.businessesMap[
					this.eventBusiness.id
				]
				const timezone = business.timezone
				const eventMoment = moment
					.tz(
						`${this.eventDate} ${this.eventTime}`,
						"ddd MMM DD, YYYY hh:mm A",
						timezone
					)
					.utc()
				const success = this.$store.dispatch(
					`eventsModule/${ActionTypes.EDIT_BUSINESS_EVENT}`,
					{
						data: {
							business_id: this.eventBusiness.id,
							start_date: eventMoment.format("YYYY-MM-DD"),
							start_time: eventMoment.format("HH:mm:ss"),
							description: this.eventDetails,
							request_notes: requestNotes,
							is_sponsored:
								this.flowType == RequestFlowType.SPONSORED
									? 1
									: 0
						}
					}
				)
				if (success) {
					this.close()
				}
				this.loading = false
			} catch (e) {
				this.loading = false
				throw e
			}
		}
	},
	watch: {
		eventDate() {
			this.$nextTick(() => {
				if (this.stepsSeen.includes(RequestEventStep.WHEN)) {
					this.$refs["eventDateInput"].validate()
				}
			})
		}
	}
}
</script>