export default {
    offset: state => {
      if (state.currentBusiness) {
        return state.currentBusiness.timezone_offset
      }
      return 0
    },
    timezone: state => {
      if (state.currentBusiness) {
        return state.currentBusiness.timezone
      }
      return null
    },
    loading: state => {
        return state.loading
      }
  }