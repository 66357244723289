<template>
	<label class="sk-checkbox" @click="updateModel">
		<input type="checkbox" v-if="checked == null" v-model="model" disabled />
		<input type="checkbox" :checked="checked" disabled v-else />
		<span>
			<i class="sk-icon-check-regular"></i>
		</span>
	</label>
</template>

<script>
export default {
	name: "SkCheckbox",
	props: {
		checked: {
			type: Boolean,
			default: null
		},
		disabled: {
			type: Boolean,
			default: false
		},
		value: null
	},
	computed: {
		model: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit("input", val)
			}
		}
	},
	methods: {
		updateModel() {
			if (this.disabled) {
				return
			}
			const isNumber = !isNaN(parseFloat(this.model)) && !isNaN(this.model - 0)
			if (isNumber) {
				this.model = this.model ? 0 : 1
			} else {
				this.model = !this.model
			}
		}
	}
}
</script>