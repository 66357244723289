<template>
	<div class="sk-typeahead-input" :class="classes">
		<button
			v-if="showClear"
			class="button button-icon"
			@click="clearSearch"
		>
			<i class="sk-icon-times-regular"></i>
		</button>
		<SkInput
			v-if="showLabel"
			ref="typeaheadInput"
			v-model="search"
			:class="{ 'clearable-input': showClear, invalid: invalid }"
			:name="placeholder"
			:placeholder="placeholder"
			:disabled="disabled"
			@input="onChange"
			@focus="onFocus"
			@blur="onBlur"
			@arrowDown="onArrowDown"
			@arrowUp="onArrowUp"
			@onEnter="onEnter"
		/>
		<input
			v-else
			class="sk-input"
			type="text"
			:class="{ 'clearable-input': showClear, invalid: invalid }"
			:placeholder="placeholder"
			:disabled="disabled"
			v-model="search"
			@input="onChange"
			@focus="onFocus"
			@keydown.down="onArrowDown"
			@keydown.up="onArrowUp"
			@keydown.enter="onEnter"
		/>
		<ul class="autocomplete-results dark-scrollbar" v-show="isOpen">
			<li
				class="autocomplete-result"
				v-for="(result, i) in results"
				:key="i"
				@click="setResult(result)"
				:class="{ 'is-active': i === arrowCounter }"
			>
				<div class="typeahead-title" v-html="result.text"></div>
				<div
					class="typeahead-subtitle"
					v-if="result.description"
					v-html="result.description"
				></div>
			</li>
			<li
				class="autocomplete-result"
				v-if="results.length == 0"
				@click="closeResults"
			>
				<b>No Results Found</b>
			</li>
			<li
				v-if="showSuggestion"
				class="autocomplete-suggestion"
				@click="suggestionClicked"
			>
				<button class="button button-primary button-rounded">
					<i class="sk-icon-plus-regular"></i>
				</button>
				<div>
					<div class="autocomplete-suggestion-title">
						{{ suggestionTitle }}
					</div>
					<div>{{ search }}</div>
				</div>
			</li>
		</ul>
	</div>
</template>

<style scoped>
.sk-typeahead-input {
	position: relative;
}

.invalid >>> .sk-input,
.invalid.sk-input {
	border-color: var(--sk-red);
}

.invalid >>> .sk-input .sk-placeholder,
.invalid.sk-input::placeholder {
	color: var(--sk-red);
}

.autocomplete {
	position: relative;
	width: 130px;
}

.autocomplete-results {
	padding: 0;
	margin: 0;
	max-height: 275px;
	overflow-y: auto;
	position: absolute;
	width: 100%;
	top: calc(100% + 10px);
	background: var(--sk-white);
	border-radius: 5px;
	box-shadow: 0px 1px 4px rgba(136, 136, 136, 0.25);
	z-index: 1000;
}

.autocomplete-result {
	list-style: none;
	text-align: left;
	cursor: pointer;
	padding: 10px 18px;
}

.autocomplete-result:hover,
.is-active {
	background: var(--sk-orange);
	color: var(--sk-white);
}

.autocomplete-result:hover .typeahead-title,
.is-active .typeahead-title,
.autocomplete-result:hover .typeahead-subtitle,
.is-active .typeahead-subtitle {
	color: var(--sk-white);
}

.typeahead-title {
	color: var(--sk-grey3);
	font-size: 14px;
}

.typeahead-subtitle {
	color: var(--sk-grey2);
	font-size: 12px;
}

.button-icon {
	position: absolute;
	right: 0;
	top: 0;
	color: var(--sk-grey2);
}

.clearable-input {
	padding-right: 30px;
}

.autocomplete-suggestion {
	display: flex;
	align-items: center;
	height: 60px;
	border-top: 1px solid var(--sk-grey);
	background: var(--sk-greybg2);
	padding: 0 18px;
	font-size: 12px;
	color: var(--sk-grey3);
	cursor: pointer;
}

.autocomplete-suggestion-title {
	font-size: 10px;
	color: var(--sk-grey2);
}

.autocomplete-suggestion .button {
	width: 20px;
	height: 20px;
	min-height: 20px;
	padding: 0;
	margin-right: 15px;
}

.autocomplete-suggestion .button i {
	font-size: 10px;
	margin: 0;
}
</style>

<script>
import utils from "@/utils/utils"
import SkInput from "@/components/SkInput.vue"

export default {
	name: "TypeaheadInput",
	components: {
		SkInput
	},
	props: {
		placeholder: {
			type: String,
			default: ""
		},
		items: {
			type: Array,
			default: () => []
		},
		suggestionTitle: String,
		fetch: {
			type: Function,
			default: null
		},
		serializer: {
			type: Function,
			default: (i) => {
				return { text: i }
			}
		},
		classes: {
			type: String,
			default: ""
		},
		disabled: {
			type: Boolean,
			default: false
		},
		limit: {
			type: Number,
			default: 5
		},
		query: {
			type: String,
			default: ""
		},
		showClear: {
			type: Boolean,
			default: false
		},
		invalid: {
			type: Boolean,
			default: false
		},
		showLabel: {
			type: Boolean,
			default: true
		}
	},
	data: function () {
		return {
			search: "",
			results: [],
			isOpen: false,
			arrowCounter: -1
		}
	},
	computed: {
		showSuggestion() {
			if (!this.suggestionTitle || this.search.length == 0) {
				return false
			}

			if (!this.results) {
				return true
			}

			return this.results.every((result) => {
				return result.text.toLowerCase() != this.search
			})
		}
	},
	methods: {
		onChange() {
			this.showResults()
		},
		showResults() {
			this.isOpen = true
			this.filterResults()
		},
		async filterResults() {
			try {
				let search = utils.sanitize(this.search)
				if (this.fetch) {
					const results = await this.fetch(search)
					this.results = results.map((result) => {
						return Object.assign(
							{ item: result },
							this.serializer(result)
						)
					})
				} else {
					const re = utils.stringToRegex(search)

					this.results = this.items
						.map((item) => {
							return Object.assign(
								{ item: item },
								this.serializer(item)
							)
						})
						.filter(
							(item) =>
								item &&
								item.text &&
								item.text.match(re) !== null
						)
						.sort((a, b) => {
							const aIndex = a.text.indexOf(a.text.match(re)[0])
							const bIndex = b.text.indexOf(b.text.match(re)[0])
							if (aIndex < bIndex) {
								return -1
							}
							if (aIndex > bIndex) {
								return 1
							}
							return 0
						})
						.splice(0, this.limit)
				}
				return this.results
			} catch (e) {
				this.results = []
				return this.results
			}
		},
		setResult(result) {
			this.search = result.text
			this.isOpen = false
			this.$emit("selected", result.item)
		},
		suggestionClicked() {
			this.isOpen = false
			this.$emit("suggestionClicked", this.search)
		},
		closeResults() {
			this.isOpen = false
		},
		onArrowDown() {
			if (this.arrowCounter < this.results.length) {
				this.arrowCounter = this.arrowCounter + 1
			}
		},
		onArrowUp() {
			if (this.arrowCounter > 0) {
				this.arrowCounter = this.arrowCounter - 1
			}
		},
		onEnter() {
			if (this.arrowCounter > -1) {
				this.setResult(this.results[this.arrowCounter])
			} else {
				this.setResult({ text: "" })
			}
			this.arrowCounter = -1
		},
		onFocus() {
			this.$emit("focus")
		},
		onBlur() {
			this.$emit("blur")
		},
		handleClickOutside(evt) {
			if (!this.$el.contains(evt.target)) {
				this.isOpen = false
				this.arrowCounter = -1
			}
		},
		clearSearch() {
			this.search = ""
			this.isOpen = false
			this.arrowCounter = -1
			this.$emit("clear")
		}
	},
	mounted: function () {
		document.addEventListener("click", this.handleClickOutside)
	},
	destroyed: function () {
		document.removeEventListener("click", this.handleClickOutside)
	},
	watch: {
		query: {
			immediate: true,
			handler(newValue) {
				if (this.search != newValue) {
					this.search = newValue
				}
			}
		}
	}
}
</script>