import actions from "./actions"
import mutations from "./mutations"
import getters from "./getters"

const state = {
	loading: false,
	currentOrder: null,
	currentOrderDeliveries: null,
	currentOrders: [],
	orderReports: [],
	totalOrderReportsCount: 0,
	exception_types: [],
	currentBusiness: {},
	orderDropoffLocations: [],
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
