<template>
	<div
		class="sk-input-group"
		:class="{ invalid: !valid && validationEnabled }"
	>
		<div
			class="flag-container"
			data-cy="phone-country-button"
			@click="toggleFlagPopover"
		>
			<div :class="selectedFlagClass" data-cy="phone-country-flag"></div>
		</div>
		<input @input="onInput" @blur="setBlurred()" type="text" ref="input" />
		<SkPopover v-model="flagPopoverOpen">
			<input
				v-model="countrySearch"
				class="country-search-input"
				type="text"
				placeholder="Search Countries"
			/>
			<div class="sk-divider-wrapper">
				<div class="divider"></div>
			</div>
			<div
				@click="setCountry(country)"
				class="country-item"
				v-for="country in preferredCountries"
				:key="country.iso2"
				:class="{
					selected:
						selectedCountry && selectedCountry.iso2 == country.iso2
				}"
			>
				<div :class="`${flagClassPrefix}${country.iso2}`"></div>
				<div class="country-name">{{ country.name }}</div>
				<div>+{{ country.dialCode }}</div>
			</div>
			<div class="sk-divider-wrapper">
				<div class="divider"></div>
			</div>
			<div
				@click="setCountry(country)"
				class="country-item"
				v-for="country in filteredCountries"
				:key="country.iso2"
				:class="{
					selected:
						selectedCountry && selectedCountry.iso2 == country.iso2
				}"
			>
				<div :class="`${flagClassPrefix}${country.iso2}`"></div>
				<div class="country-name">{{ country.name }}</div>
				<div>+{{ country.dialCode }}</div>
			</div>
		</SkPopover>
	</div>
</template>

<style>
.sk-telephone-input {
	width: calc(100% - 68px);
	height: 48px;
}

.sk-telephone-input input {
	width: 100%;
	height: 100%;
	color: var(--sk-grey3);
	outline: none;
	border: none;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	opacity: 0.7;
}

.iti__flag-container {
	display: none;
}
</style>

<style scoped>
.sk-input-wrapper {
	width: 100%;
	height: 50px;
	border: 1px solid var(--sk-grey4);
	border-radius: 5px;
	display: flex;
	position: relative;
}

.sk-input-wrapper.invalid {
	border: 1px solid var(--sk-red);
}

.sk-popover-wrapper {
	position: absolute;
	left: 0;
	bottom: 0;
}

.sk-popover-wrapper >>> .sk-popover {
	top: calc(100% + 5px) !important;
	box-shadow: none;
	border: 1px solid var(--sk-grey);
}

.flag-container {
	margin-right: 18px;
	width: 50px;
	height: 48px;
	background: var(--sk-grey);
	display: flex;
	align-items: center;
	justify-content: center;
	border-bottom-left-radius: 5px;
	border-top-left-radius: 5px;
}

.country-item {
	display: flex;
	align-items: center;
	font-weight: 600;
	font-size: 12px;
	margin-bottom: 10px;
	cursor: pointer;
}

.country-item > div {
	color: var(--sk-grey2);
}

.country-item.selected > div {
	color: var(--sk-dark-navy);
	font-weight: 400;
}

.country-item .iti__flag {
	margin-right: 15px;
}

.country-name {
	margin-right: 15px;
}

.sk-divider-wrapper {
	margin: 15px 0;
}

.country-search-input {
	border: 0;
	outline: 0;
	color: var(--sk-grey3);
	font-size: 12px;
}
</style>

<script>
import intlTelInput from "intl-tel-input"
import SkPopover from "@/components/SkPopover.vue"

export default {
	name: "SkTelephoneInput",
	components: {
		SkPopover
	},
	props: {
		value: null,
		initialCountry: {
			type: String,
			default: "us"
		}
	},
	data: function () {
		return {
			intlInput: null,
			lastNumber: "",
			validationEnabled: false,
			valid: null,
			selectedCountry: null,
			flagPopoverOpen: false,
			countries: [],
			preferredCountries: [],
			flagClassPrefix: "iti__flag iti__",
			preferredCountryIsoCodes: ["us", "gb"],
			countrySearch: ""
		}
	},
	computed: {
		model: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit("input", val)
			}
		},
		selectedFlagClass() {
			if (this.selectedCountry) {
				return `${this.flagClassPrefix}${this.selectedCountry.iso2}`
			}
			return `${this.flagClassPrefix}${this.initialCountry}`
		},
		filteredCountries() {
			return this.countries.filter((country) => {
				if (this.countrySearch.length > 0) {
					return country.name
						.toLowerCase()
						.includes(this.countrySearch.toLowerCase())
				}
				return true
			})
		}
	},
	methods: {
		onInput() {
			if (this.intlInput) {
				const number = this.intlInput.getNumber()
				this.setNumber(number)
			}
		},
		setNumber(number) {
			if (this.intlInput) {
				if (this.lastNumber != number) {
					this.intlInput.setNumber(number)
				}
			}
			this.lastNumber = number
			if (this.model != number) {
				this.model = number
			}
		},
		validate() {
			this.validationEnabled = true
			if (this.intlInput) {
				this.valid = this.intlInput.isValidNumber()
			} else {
				this.valid = false
			}

			if (!this.valid) {
				const error = this.intlInput.getValidationError()
				if (
					window.intlTelInputUtils &&
					error ==
						window.intlTelInputUtils.validationError.IS_POSSIBLE
				) {
					this.valid = true
				}
			}
			return this.valid
		},
		resetValidation() {
			this.validationEnabled = false
			this.valid = null
		},
		focusInput() {
			this.$refs.input.focus()
		},
		setBlurred() {
			this.$emit("blur")
			if (this.validationEnabled) {
				this.validate()
			}
		},
		toggleFlagPopover() {
			this.flagPopoverOpen = !this.flagPopoverOpen
		},
		setCountry(country) {
			this.setNumber("")
			this.intlInput.setCountry(country.iso2)
			this.selectedCountry = country
			this.flagPopoverOpen = false
		}
	},
	mounted: function () {
		this.$refs.input.addEventListener("countrychange", () => {
			this.selectedCountry = this.intlInput.selectedCountryData
		})

		this.intlInput = intlTelInput(this.$refs.input, {
			initialCountry: this.initialCountry,
			customContainer: "sk-telephone-input",
			allowDropdown: false,
			customPlaceholder: function () {
				return "Phone Number"
			},
			utilsScript: `${this.$staticAssetUrl}intl-tel-input/utils.min.js`
		})

		if (this.model != this.lastNumber) {
			this.setNumber(this.model)
		}

		const countryData = this.intlInput.countries.reduce(
			(countryData, country) => {
				if (this.preferredCountryIsoCodes.includes(country.iso2)) {
					countryData.preferred.push(country)
				} else {
					countryData.countries.push(country)
				}
				if (
					!this.selectedCountry &&
					!this.model &&
					this.initialCountry == country.iso2
				) {
					this.selectedCountry = country
				}
				return countryData
			},
			{
				countries: [],
				preferred: []
			}
		)
		this.countries = countryData.countries
		this.preferredCountries = countryData.preferred.sort(
			(countryA, countryB) =>
				this.preferredCountryIsoCodes.indexOf(countryA.iso2) -
				this.preferredCountryIsoCodes.indexOf(countryB.iso2)
		)
		if (!this.selectedCountry && this.model) {
			this.selectedCountry = this.intlInput.selectedCountryData
		}
	},
	watch: {
		model(model) {
			if (!model) {
				return
			}

			if (model != this.lastNumber) {
				this.setNumber(model)
			}
		},
		lastNumber() {
			if (this.intlInput) {
				this.selectedCountry = this.intlInput.getSelectedCountryData()
			}
		}
	}
}
</script>