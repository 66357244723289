<template>
	<div class="sk-table-row">
		<div class="sk-table-column-fit">
			<span :class="stockClasses"></span>
			{{ stockText }}
		</div>
		<div>{{ business.name }}</div>
		<div class="sk-table-column-fit" v-html="businessAddress"></div>
		<div class="sk-table-pills">
			<router-link
				v-for="pairing in pairings"
				:key="pairing.id"
				:to="`/restaurant/${pairing.id}`"
				:title="pairing.name"
				tag="div"
				class="pill pill-grey"
			>
				<i class="sk-icon-link-regular"></i>
				{{ pairing.name }}
			</router-link>
		</div>
		<div class="sk-table-column-fit">
			<span
				class="pill"
				v-if="hasKiosk"
				:class="{
					'pill-green': kioskHealthy,
					'pill-red': !kioskHealthy
				}"
				v-sk-tooltip="kioskBattery"
			>
				<i class="sk-icon-heart-rate-regular"></i>
				Kiosk
			</span>
			<span class="pill pill-green">
				<i class="sk-icon-heart-rate-regular"></i>
				Web
			</span>
		</div>
		<div class="sk-table-column-fit">
			<div class="sk-table-actions">
				<router-link
					tag="button"
					:to="`/business/${business.slug}`"
					class="button button-icon"
					title="Edit"
				>
					<i class="sk-icon-pencil-solid"></i>
				</router-link>
				<button
					class="button button-icon"
					@click="deleteBusiness"
					title="Delete"
					v-if="canDelete"
				>
					<i class="sk-icon-trash-alt-solid"></i>
				</button>
			</div>
		</div>
	</div>
</template>

<style>
.kiosk-battery {
	display: flex;
	align-items: center;
	font-size: 12px;
}

.kiosk-battery i {
	margin-right: 10px;
	transform: rotate(-90deg);
	display: inline-block;
	font-size: 10px;
}
</style>

<style scoped>
.sk-table-row >>> .sk-icon-battery-quarter-regular {
	margin-right: 10px;
}
</style>

<script>
import { PermissionMethod } from "@/utils/permissions"

export default {
	name: "BusinessLineItem",
	props: {
		business: Object
	},
	computed: {
		businessAddress() {
			let hasAddress =
				this.business.address &&
				this.business.city &&
				this.business.state &&
				this.business.zip
			return hasAddress
				? `<div class="column-title">${this.business.address}</div><div class="column-subtitle">${this.business.city}, ${this.business.state}</div>`
				: "N/A"
		},
		stockClasses() {
			return this.business.stock_status == 1
				? "status-dot green"
				: "status-dot red"
		},
		stockText() {
			return this.business.stock_status == 1 ? "In Stock" : "Out of Stock"
		},
		canDelete() {
			return this.$store.state.permissions.includes(PermissionMethod.DELETE_BUSINESS)
		},
		hasKiosk() {
			return this.business.kiosks.length > 0
		},
		kioskHealthy() {
			return this.hasKiosk && this.business.kiosks[0].is_healthy
		},
		kioskBattery() {
			const level = this.hasKiosk
				? `${this.business.kiosks[0].battery_status}%`
				: "N/A"
			return `<span class="kiosk-battery"><i class="sk-icon-battery-quarter-regular"></i> Battery: ${level}</span>`
		},
		pairings() {
			const pairings = []
			const restaurantIds = []
			if (this.business.menus) {
				this.business.menus.map(menu => {
					const restaurant = this.$store.state.restaurants[
						menu.restaurant_id
					]
					if (
						!restaurantIds.includes(menu.restaurant_id) &&
						restaurant
					) {
						restaurantIds.push(menu.restaurant_id)
						pairings.push({
							id: menu.restaurant_id,
							name: restaurant.name
						})
					}
				})
			}

			return pairings
		}
	},
	methods: {
		deleteBusiness() {
			this.$emit("delete", this.business)
		}
	}
}
</script>