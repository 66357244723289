export const SET_FILTERS = "SET_FILTERS"
export const GET_TOTAL_SALES_DATA = "GET_TOTAL_SALES_DATA"
export const GET_TOTAL_ORDERS_DATA = "GET_TOTAL_ORDERS_DATA"
export const GET_AVERAGE_TICKET_PRICE_BY_DOW = "GET_AVERAGE_TICKET_PRICE_BY_DOW"
export const GET_TOP_ORDERS_BY_DOW = "GET_TOP_ORDERS_BY_DOW"
export const GET_TOP_ORDERS_BY_HOUR = "GET_TOP_ORDERS_BY_HOUR"
export const GET_AVERAGE_RATING = "GET_AVERAGE_RATING"
export const GET_AVERAGE_RESTAURANT_ACCURACY = "GET_AVERAGE_RESTAURANT_ACCURACY"
export const GET_TOP_EXCEPTIONS = "GET_TOP_EXCEPTIONS"
export const GET_MISSING_ITEMS_ORDERS = "GET_MISSING_ITEMS_ORDERS"
export const GET_INCORRECT_ITEMS_ORDERS = "GET_INCORRECT_ITEMS_ORDERS"
export const GET_RESTAURANT_CLOSED_ORDERS = "GET_RESTAURANT_CLOSED_ORDERS"
export const GET_RESTAURANT_AVERAGE_ACCEPT_TIME = "GET_RESTAURANT_AVERAGE_ACCEPT_TIME"
export const GET_RESTAURANT_AVERAGE_PREPARATION_TIME = "GET_RESTAURANT_AVERAGE_PREPARATION_TIME"

export default {
    SET_FILTERS,
    GET_TOTAL_SALES_DATA,
    GET_TOTAL_ORDERS_DATA,
    GET_AVERAGE_TICKET_PRICE_BY_DOW,
    GET_TOP_ORDERS_BY_DOW,
    GET_TOP_ORDERS_BY_HOUR,
    GET_AVERAGE_RATING,
    GET_AVERAGE_RESTAURANT_ACCURACY,
    GET_TOP_EXCEPTIONS,
    GET_MISSING_ITEMS_ORDERS,
    GET_INCORRECT_ITEMS_ORDERS,
    GET_RESTAURANT_CLOSED_ORDERS,
    GET_RESTAURANT_AVERAGE_ACCEPT_TIME,
    GET_RESTAURANT_AVERAGE_PREPARATION_TIME
}
