<template>
	<SkBaseModal :isOpen="isOpen" @close="close" class="sk-modal-alt">
		<loading :active.sync="loading" :is-full-page="false"></loading>
		<div class="sk-modal-alt-title">
			<h1>Add Order Note for {{ orderId }}</h1>
		</div>
		<div class="sk-row">
			<SkInput
				ref="notesInput"
				:name="'Notes'"
				:multiline="true"
				v-model="notes"
			/>
		</div>
		<div class="sk-modal-actions">
			<button @click="close" class="button button-text">Close</button>
			<button
				type="button"
				class="button"
				:class="{
					'button-primary': canAddNote,
					'button-grey': !canAddNote
				}"
				@click="saveNote"
			>
				<i class="sk-icon-check-regular"></i>
				Add Notes
			</button>
		</div>
	</SkBaseModal>
</template>

<script>
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import SkInput from "@/components/SkInput.vue"
import Loading from "vue-loading-overlay"

export default {
	name: "AddOrderNotesModal",
	mixins: [skModalMixin],
	components: {
		SkBaseModal,
		SkInput,
		Loading
	},
	data: function () {
		return {
			notes: "",
			loading: false
		}
	},
	computed: {
		orderId() {
			return this.currentOrder ? this.currentOrder.id : ""
		},
		currentOrder() {
			return this.$store.state.ordersModule.currentOrder
		},
		canAddNote() {
			return this.notes && this.notes != ""
		}
	},
	methods: {
		closed() {
			this.notes = ""
			this.eventId = 0
		},
		async saveNote() {
			if (this.canAddNote) {
				this.loading = true
				const success = await this.$store.dispatch(
					`ordersModule/addOrderNote`,
					{
						note: this.notes,
						orderId: this.orderId
					}
				)
				if (success) {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Added order note succesfully"
					})
					this.close()
				} else {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Failed to add order note",
						type: NotificationType.ERROR
					})
				}
				this.loading = false
			} else {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Note cannot be empty",
					type: NotificationType.ERROR
				})
			}
		}
	}
}
</script>