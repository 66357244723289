export const GOT_CURRENT_BUSINESS_GUEST = "GOT_CURRENT_BUSINESS_GUEST"
export const GOT_CURRENT_BUSINESS_GUEST_ORDERS = "GOT_CURRENT_BUSINESS_GUEST_ORDERS"
export const GOT_BUSINESS_GUESTS = "GOT_BUSINESS_GUESTS"
export const GOT_BUSINESS_GUEST = "GOT_BUSINESS_GUEST"
export const BUSINESS_GUEST_DELETED = "BUSINESS_GUEST_DELETED"
export const LOADING = "LOADING"
export const LOADED = "LOADED"


export default {
    GOT_CURRENT_BUSINESS_GUEST,
    GOT_CURRENT_BUSINESS_GUEST_ORDERS,
    GOT_BUSINESS_GUEST,
    GOT_BUSINESS_GUESTS,
    BUSINESS_GUEST_DELETED,
    LOADING,
    LOADED,
}