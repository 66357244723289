import Vue from "vue"
import utils_service from "@/services/utils"

const vue = new Vue()

export default {
  async batchUploadImages(params, files) {
    try {
      const fileParams = []
      const filePaths = []
      files.forEach((file) => {
        const name = file.name.replace(/ /g, "-")
        fileParams.push(Object.assign({
          filePath: name,
          file: file
        }, params))
        filePaths.push(name)
      })
      const signature = await utils_service.getAWSSignature({
        filePaths: filePaths.join(","),
        slug: params.slug,
        type: "product"
      })
      const responses = await Promise.all(fileParams.map((params) => {
        const signatureParams = signature.data[params.filePath]
        return this.awsImageUpload(signatureParams.postEndpoint, signatureParams.signature, params.file)
      }))
      return responses
    } catch (e) {
      vue.bugsnag.notify(
        "Failed to batch upload images",
        event => {
          event.addMetadata("error", {
              error: e
          })
        }
      )
      return []
    }
  },
  awsImageUpload(url, params, imageBuffer) {
    let uploadData = new FormData()
    for (var param in params) {
      uploadData.append(param, params[param])
    }
    uploadData.append("file", imageBuffer)
    return Vue.axios.post(url, uploadData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
  }
}