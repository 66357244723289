<template>
	<div class="sk-table-row">
		<div :title="order.id" class="sk-table-column-fit">
			{{ order.id }}
		</div>
		<div>
			<div>{{ placedDay }}</div>
			<div class="order-time">{{ placedTime }}</div>
		</div>
		<div>
			<div>{{ deliveredDay }}</div>
			<div class="order-time">{{ deliveredTime }}</div>
		</div>
		<div>
			{{ itemCount }}
		</div>
		<div>
			{{ order.price | currency }}
		</div>
		<div>
			{{ voucherCode }}
		</div>
		<div>
			{{ npsScore }}
		</div>
		<div class="sk-table-column-fit">
			<div class="sk-table-actions">
				<button
					class="button button-icon"
					@click="viewOrder"
					title="View"
				>
					<i class="sk-icon-eye-solid"></i>
				</button>
			</div>
		</div>
	</div>
</template>

<style scoped>
.order-time {
	font-size: 12px;
	color: var(--sk-grey2);
}
</style>

<script>
import moment from "moment"
import currencyFilterMixin from "@/mixins/currency-filter-mixin"

export default {
	name: "BusinessGuestOrderLineItem",
	mixins: [currencyFilterMixin],
	props: {
		order: Object
	},
	computed: {
		businessOffset() {
			const businessId = this.order.business.id
			const business = this.$store.getters.businessesMap[businessId]
			return business ? business.timezone_offset : 0
		},
		voucherCode() {
			if (this.order.coupon_uses && this.order.coupon_uses.length > 0) {
				return this.order.coupon_uses[0].code
			}
			return ""
		},
		itemCount() {
			return this.order.sub_orders.reduce(
				(count, suborder) => count + suborder.products.length,
				0
			)
		},
		npsScore() {
			return this.order.score > -1 ? this.order.score : "--"
		},
		suborderDeliveredTime() {
			const deliveredSuborder = this.order.sub_orders.find((suborder) => {
				return suborder.delivered_time && suborder.delivered_time != ""
			})
			if (deliveredSuborder) {
				return deliveredSuborder.delivered_time
			}
			return null
		},
		deliveredDay() {
			if (this.suborderDeliveredTime) {
				const deliveredMoment = moment
					.utc(this.suborderDeliveredTime)
					.add(this.businessOffset, "m")
				return deliveredMoment.format("MMMM Do")
			}
			return "--"
		},
		deliveredTime() {
			if (this.suborderDeliveredTime) {
				const deliveredMoment = moment
					.utc(this.suborderDeliveredTime)
					.add(this.businessOffset, "m")
				return deliveredMoment.format("h:mm a")
			}
			return "--"
		},
		placedDay() {
			const createdTime = moment
				.utc(this.order.created_time)
				.add(this.businessOffset, "m")
			return createdTime.format("MMMM Do")
		},
		placedTime() {
			const createdTime = moment
				.utc(this.order.created_time)
				.add(this.businessOffset, "m")
			return createdTime.format("h:mm a")
		}
	},
	methods: {
		viewOrder() {
			this.$emit("viewOrder", this.order)
		}
	}
}
</script>