import moment from "moment"
import "moment-timezone"
import { PaymentType } from "@arikgaisler/utils/enums/paymentType"
import { BusinessType } from "@arikgaisler/utils/enums/businessType"
import { CourierId } from "@arikgaisler/utils/enums/courierId"
import { IntegrationType } from "@/enums/integrationType"
import { DeliveryMode } from "@arikgaisler/utils/enums/deliveryMode"
import { LastYardDeliveryStatus } from "@/enums/LastYardDeliveryStatus"
import orderMixin from "@/mixins/order-mixin"
import phoneFilterMixin from "@/mixins/phone-filter-mixin"
import businessTypeFormatterMixin from "@/mixins/business/business-type-formatter-mixin"
import deliveryModeFormatterMixin from "@/mixins/business/delivery-mode-formatter-mixin"
import delivery_service from "@/services/delivery"

let orderStatusMixin = {
	mixins: [
		orderMixin,
		phoneFilterMixin,
		businessTypeFormatterMixin,
		deliveryModeFormatterMixin
	],
	filters: {
		formatTime: function (date, timezone) {
			if (date && date != "--") {
				return `${date} ${timezone}`
			}
			return date
		}
	},
	props: {
		item: Object,
		order: Object,
		lockerDeliveryStatus: String
	},
	computed: {
		receivedStatusTitle() {
			return this.received ? "Order Received" : "Awaiting Order Received"
		},
		acceptStatusTitle() {
			return this.accepted ? "Order Accepted" : "Awaiting Order Accept"
		},
		acknowledgeStatusTitle() {
			return this.acknowledged
				? "Courier Acknowledged"
				: "Awaiting Courier Acknowledge"
		},
		pickUpStatusTitle() {
			return this.pickedUp
				? "Courier Picked Up"
				: "Awaiting Courier Pickup"
		},
		deliveryStatusTitle() {
			return this.delivered
				? "Delivery Confirmed"
				: "Awaiting Delivery Confirmation"
		},
		deliveryStatusLocker() {
			if (this.order.delivery_mode == 5) {
				if (this.order.sub_orders[0].locker_delivery) {
					switch (this.order.sub_orders[0].locker_delivery.status) {
						case 0: {
							this.lockerDeliveryStatus = "Order Created"
							break
						}
						case 1: {
							this.lockerDeliveryStatus = "Delivered"
							break
						}
						case 2: {
							this.lockerDeliveryStatus = "Picked Up"
							break
						}
					}
				} else if (this.order.sub_orders[0].delivery_job_id == "") {
					this.lockerDeliveryStatus = "Order Created"
				} else if (this.order.sub_orders[0].delivery_job_id) {
					delivery_service
						.getDelivery(this.order.sub_orders[0].delivery_job_id)
						.then((response) => {
							if (response.status == 200) {
								switch (
									response.data.delivery.locker_delivery
										.status
								) {
									case 0: {
										this.lockerDeliveryStatus =
											"Order Created"
										break
									}
									case 1: {
										this.lockerDeliveryStatus = "Delivered"
										break
									}
									case 2: {
										this.lockerDeliveryStatus = "Picked Up"
										break
									}
								}
							}
						})
						.catch(() => {
							this.lockerDeliveryStatus = "STATUS_ERROR"
						})
				} else {
					this.lockerDeliveryStatus = "Complete"
				}
				return true
			}
		},
		deliveryText() {
			if (!this.hasTable) {
				switch (this.order.delivery_mode) {
					case DeliveryMode.DIRECT_TO_LOCATION: {
						if (
							this.$store.state.ordersModule.currentBusiness
								.locations
						) {
							let locationId = this.order.dropoff_location_id
							let dropoffLocations =
								this.$store.state.ordersModule.currentBusiness
									.locations[0].dropoff_locations

							let gateObj = dropoffLocations.filter(function (
								el
							) {
								if (el.id == locationId) {
									return el.text
								}
							})
							if (gateObj) {
								return `(Gate #${gateObj[0].text})`
							} else {
								return ""
							}
						}
					}
				}
			}
		},
		historyDetailsGateText() {
			if (!this.hasTable) {
				switch (this.order.delivery_mode) {
					case DeliveryMode.DIRECT_TO_LOCATION: {
						if (
							this.$store.state.ordersModule.orderDropoffLocations
								.length > 0
						) {
							let locationId = this.order.dropoff_location_id
							let dropoffLocations =
								this.$store.state.ordersModule
									.orderDropoffLocations

							let gateObj = dropoffLocations.filter(function (
								el
							) {
								if (el.id == locationId) {
									return el.text
								}
							})
							if (gateObj) {
								return `(Gate #${gateObj[0].text})`
							} else {
								return ""
							}
						}
					}
				}
			}
		},
		accepted() {
			return this.isStateCompleted(this.acceptedDate)
		},
		received() {
			return this.isStateCompleted(this.receivedDate)
		},
		acknowledged() {
			return this.isStateCompleted(this.acknowledgedDate)
		},
		pickedUp() {
			return this.isStateCompleted(this.pickedUpDate)
		},
		delivered() {
			return this.isStateCompleted(this.deliveredDate)
		},
		username() {
			return this.order.user_name
		},
		name() {
			if (this.hasTable) {
				let locationPrefix = "Table"
				if (this.business && this.business.type != undefined) {
					switch (this.business.type) {
						case BusinessType.HOTEL: {
							locationPrefix = "Room"
							break
						}
						case BusinessType.COWORKING: {
							locationPrefix = "Suite"
							break
						}
						case BusinessType.AIRPORT: {
							locationPrefix = "Gate"
							break
						}
					}
				}
				return `${this.username} (${locationPrefix} #${this.order.table})`
			}
			return this.username
		},
		orderTimeinMins() {
			if (!this.orderTimeStarted) {
				return 0
			}

			let endTime = moment.utc()
			let subOrder = this.item
			if (
				subOrder.delivered_time != null &&
				subOrder.delivered_time != undefined &&
				subOrder.delivered_time == ""
			) {
				endTime = moment.utc(subOrder.delivered_time)
			}
			return endTime.diff(moment.utc(this.order.created_time), "minutes")
		},
		showContactCourier() {
			return (
				this.item.courier_id != CourierId.WALKING &&
				this.item.courier_id != CourierId.TOOKAN &&
				this.isStateCompleted(this.acknowledgedDate)
			)
		},
		showTrackCourier() {
			return (
				this.item.courier_id != CourierId.WALKING &&
				this.item.courier_id != CourierId.TOOKAN
			)
		},
		posSystem() {
			let retVal = "2ndKitchen"
			if (
				this.restaurant &&
				this.restaurant.integrations &&
				this.restaurant.integrations.length > 0
			) {
				this.restaurant.integrations.forEach((integration) => {
					switch (integration.type) {
						case IntegrationType.CHOWLY:
							retVal = "Chowly"
							break
						case IntegrationType.CLOVER:
							retVal = "Clover"
							break
						case IntegrationType.ORDERMARK:
							retVal = "Ordermark"
							break
						case IntegrationType.OTTER:
							retVal = "Otter"
							break
						case IntegrationType.REEF:
							retVal = "Reef/Orderlord"
							break
						case IntegrationType.SQUARE:
							retVal = "Square"
							break
						case IntegrationType.UNTAPPD:
							retVal = "UnTappd"
							break
						case IntegrationType.STARMICRONICS:
							retVal = "Starmicronics Printer"
							break
					}
				})
			}
			return retVal
		},
		restaurantNotes() {
			if (
				this.restaurant &&
				this.restaurant.notes != undefined &&
				this.restaurant.notes != null &&
				this.restaurant.notes != ""
			) {
				return this.restaurant.notes
			}
			return null
		},
		businessNotes() {
			if (
				this.business &&
				this.business.notes != undefined &&
				this.business.notes != null &&
				this.business.notes != ""
			) {
				return this.business.notes
			}
			return null
		},
		businessType() {
			let businessTypeName = this.$options.filters.businessTypeName(
				this.business.type
			)
			return businessTypeName == "" ? "N/A" : businessTypeName
		},
		business() {
			return this.$store.getters.businessesMap[this.order.business_id]
		},
		restaurant() {
			return this.$store.state.restaurants[this.item.rest_id]
		},
		formattedTimezone() {
			return moment().tz(this.business.timezone).format("z")
		},
		scheduledDate() {
			if (this.isPreOrder) {
				return `${this.orderScheduledDate.format("ddd h:mm A")} ${
					this.formattedTimezone
				}`
			} else {
				return "ASAP"
			}
		},
		createdDate() {
			const timezone = this.business.timezone
			let createdMoment = moment.utc(this.order.created_time).tz(timezone)
			return createdMoment.format("h:mm A")
		},
		acceptedDate() {
			if (
				this.item.accept_time != null &&
				this.item.accept_time != undefined &&
				this.item.accept_time != ""
			) {
				const timezone = this.business.timezone
				let acceptedMoment = moment
					.utc(this.item.accept_time)
					.tz(timezone)
				return acceptedMoment.format("h:mm A")
			} else {
				return "--"
			}
		},
		receivedDate() {
			if (
				this.item.received_time != null &&
				this.item.received_time != undefined &&
				this.item.received_time != ""
			) {
				const timezone = this.business.timezone
				let receivedMoment = moment
					.utc(this.item.received_time)
					.tz(timezone)
				return receivedMoment.format("h:mm A")
			} else {
				return "--"
			}
		},
		acknowledgedDate() {
			if (this.item.courier_id == CourierId.WALKING) {
				return this.acceptedDate
			} else {
				if (
					this.item.acknowledged_time != null &&
					this.item.acknowledged_time != undefined &&
					this.item.acknowledged_time != ""
				) {
					const timezone = this.business.timezone
					let ackMoment = moment
						.utc(this.item.acknowledged_time)
						.tz(timezone)
					return ackMoment.format("h:mm A")
				} else {
					return "--"
				}
			}
		},
		pickedUpDate() {
			if (this.item.courier_id == CourierId.WALKING) {
				if (
					this.item.completed_time != null &&
					this.item.completed_time != undefined &&
					this.item.completed_time != ""
				) {
					const timezone = this.business.timezone
					let completedMoment = moment
						.utc(this.item.completed_time)
						.tz(timezone)
					return completedMoment.format("h:mm A")
				} else {
					return "--"
				}
			} else {
				if (
					this.item.pickup_time != null &&
					this.item.pickup_time != undefined &&
					this.item.pickup_time != ""
				) {
					const timezone = this.business.timezone
					let pickupMoment = moment
						.utc(this.item.pickup_time)
						.tz(timezone)
					return pickupMoment.format("h:mm A")
				} else {
					return "--"
				}
			}
		},
		deliveredDate() {
			if (this.item.courier_id == CourierId.WALKING) {
				if (
					this.item.completed_time != null &&
					this.item.completed_time != undefined &&
					this.item.completed_time != ""
				) {
					const timezone = this.business.timezone
					let completedMoment = moment
						.utc(this.item.completed_time)
						.tz(timezone)
					return completedMoment.format("h:mm A")
				} else {
					return "--"
				}
			} else {
				if (
					this.item.delivered_time != null &&
					this.item.delivered_time != undefined &&
					this.item.delivered_time != ""
				) {
					const timezone = this.business.timezone
					let deliveredMoment = moment
						.utc(this.item.delivered_time)
						.tz(timezone)
					return deliveredMoment.format("h:mm A")
				} else {
					return "--"
				}
			}
		},
		phone() {
			return this.order.user_phone
		},
		restPhone() {
			if (this.restaurant && this.restaurant.phone_number) {
				return this.restaurant.phone_number.split(";")
			}
			return null
		},
		businessPhone() {
			if (this.business && this.business.phone_number) {
				return this.business.phone_number.split(";")
			}
			return null
		},
		courier() {
			switch (this.item.courier_id) {
				case CourierId.WALKING:
					return "Walking"
				case CourierId.CUT_CATS:
					return "Cut Cats"
				case CourierId.TOOKAN:
					return "Delivery (Tookan)"
				case CourierId.POSTMATES:
					return "Postmates"
				case CourierId.DOORDASH:
					return "Doordash"
				case CourierId.RELAY:
					return "Relay"
			}
		},
		orderPlatform() {
			if (
				this.order.order_method != null &&
				this.order.order_method != undefined &&
				this.order.order_method == 0
			) {
				return "App"
			} else {
				return "Kiosk"
			}
		},
		paymentMethod() {
			let type = this.order.payment_method
			if (this.order.payment_method && this.order.payment_method.type) {
				type = this.order.payment_method.type
			}
			switch (type) {
				case PaymentType.CREDIT_CARD:
					return "Credit Card"
				case PaymentType.APPLE_PAY:
					return "Apple Pay"
				case PaymentType.GOOGLE_PAY:
					return "Google Pay"
				case PaymentType.CASH:
					return this.order.price > 0 ? "Cash" : "Voucher"
				case PaymentType.IN_ROOM_BILLING:
					return "Charge to Room"
			}
			return "Credit Card"
		},
		pickupMethod() {
			switch (this.order.delivery_mode) {
				case DeliveryMode.DIRECT_TO_CUSTOMER:
				case DeliveryMode.LAST_YARD:
				case DeliveryMode.LOCKER:
				case DeliveryMode.DIRECT_TO_LOCATION: {
					return this.$options.filters.deliveryModeName(
						this.order.delivery_mode,
						this.business.type
					)
				}
				case DeliveryMode.YAY_FOOD_POLE: {
					return this.isPopupOrder ? "Delivery" : "Yay Food Pole"
				}
				case DeliveryMode.PICKUP_AREA: {
					if (this.isPopupOrder) {
						return "Pickup"
					} else {
						if (this.business.type == BusinessType.BAR) {
							return "Pickup Area"
						} else {
							return "Lobby"
						}
					}
				}
			}
		},
		isLastYard() {
			return this.order.delivery_mode == DeliveryMode.LAST_YARD
		},
		lastYardDelivery() {
			return null
		},
		lastYardDeliveryStatusText() {
			if (this.lastYardDelivery) {
				const timezone = this.business.timezone
				switch (this.lastYardDelivery.status) {
					case LastYardDeliveryStatus.CREATED: {
						return "Pending"
					}
					case LastYardDeliveryStatus.PICKED_UP: {
						let time = moment
							.utc(this.lastYardDelivery.picked_up_at)
							.tz(timezone)
							.format("h:mm A")
						return `Picked Up at ${time}`
					}
					case LastYardDeliveryStatus.DELIVERED: {
						let time = moment
							.utc(this.lastYardDelivery.delivered_at)
							.tz(timezone)
							.format("h:mm A")
						return `Delivered at ${time}`
					}
				}
			}
			return "--"
		}
	},
	methods: {
		isStateCompleted(date) {
			return this.order.cancelled != 1 && date != "--" ? true : false
		}
	}
}

export default orderStatusMixin
