<template>
	<SkBaseModal :isOpen="isOpen" @close="close" class="sk-modal-alt">
		<div class="sk-modal-alt-title">
			<h1>Adjust {{ payoutPeriod }} payout for {{ restaurantName }}:</h1>
		</div>
		<div class="sk-row">
			<SkInput
				v-model="amount"
				ref="amountInput"
				:required="true"
				:name="'Amount to adjust'"
				:placeholder="'Amount to adjust'"
				:type="'number'"
				:group="'$'"
			/>
		</div>
		<SkInput
			ref="reasonInput"
			:name="'Reason'"
			:multiline="true"
			:required="true"
			v-model="reason"
		/>
		<div class="sk-modal-actions">
			<button @click="close" class="button button-text">
				Close
			</button>
			<button
				type="button"
				class="button button-success"
				@click="adjustPayout"
			>
				<i class="sk-icon-check-regular"></i>
				Adjust Amount
			</button>
		</div>
	</SkBaseModal>
</template>

<script>
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import SkInput from "@/components/SkInput.vue"
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import ActionTypes from "@/store/modules/finance/action-types"
import moment from "moment"

export default {
	name: "AdjustPayoutAmountModal",
	mixins: [skModalMixin],
	components: {
		SkBaseModal,
		SkInput
	},
	data() {
		return {
			amount: 0,
			payoutId: 0,
			reason: ""
		}
	},
	computed: {
		currentPayout() {
			return this.$store.state.financeModule.restaurantPayouts.find(
				payout => payout.id == this.payoutId
			)
		},
		restaurantName() {
			if (this.currentPayout) {
				return this.currentPayout.restaurant.name
			}
		},
		payoutPeriod() {
			if (this.currentPayout) {
				return (
					moment.utc(this.currentPayout.from_date).format("MMM D") +
					"-" +
					moment.utc(this.currentPayout.to_date).format("MMM D")
				)
			}
		}
	},
	methods: {
		handleOptions(options) {
			if (options) {
				this.amount = 0
				this.payoutId = options.id
				this.reason = ""
			}
		},
		validateAdjustPayoutData() {
			const validAmount =
				this.$refs.amountInput.validate() && this.amount != 0
			const validReason = this.$refs.reasonInput.validate()
			return validAmount && validReason
		},
		async adjustPayout() {
			if (this.validateAdjustPayoutData()) {
				const success = await this.$store.dispatch(
					`financeModule/${ActionTypes.ADJUST_RESTAURANT_PAYOUT_AMOUNT}`,
					{
						id: this.payoutId,
						amount: this.amount,
						reason: this.reason
					}
				)
				if (success) {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: `Successfully adjusted payout amount`
					})
				} else {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: `Error adjusting payout amount. Please try again`,
						type: NotificationType.ERROR
					})
				}
				this.close()
			}
		},
		closed() {
			this.amount = 0
			this.payoutId = 0
			this.reason = ""
		}
	}
}
</script>