<template>
	<SkBaseModal class="sk-modal-alt" :isOpen="isOpen" @close="cancel">
		<button class="button button-icon button-close" @click="cancel()">
			<i class="sk-icon-times-regular"></i>
		</button>
		<div class="sk-modal-alt-title">
			<img src="/images/square-logo.png" alt="Square Logo" />
			<h1>
				Square
			</h1>
		</div>
		<div class="sk-modal-description">
			To complete the integration with Square, please choose your
			location.
		</div>
		<div class="sk-row">
			<SkDropdownSelect
				class="sk-input"
				v-model="selectedLocation"
				:options="locationOptions"
			/>
		</div>
		<div class="sk-modal-actions">
			<button class="button button-text" @click="cancel()">
				Cancel
			</button>
			<button class="button button-primary" @click="saveIntegration">
				<i class="sk-icon-check-regular"></i>
				{{ confirmBtnText }}
			</button>
		</div>
	</SkBaseModal>
</template>

<script>
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import skPromiseModalMixin from "@/mixins/modals/sk-promise-modal-mixin"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"

export default {
	name: "SquareIntegrationModal",
	mixins: [skPromiseModalMixin],
	components: {
		SkBaseModal,
		SkDropdownSelect
	},
	data: function () {
		return {
			editing: false,
			selectedLocation: null
		}
	},
	computed: {
		locationOptions() {
			let options = [
				{
					id: null,
					text: "Location"
				}
			]
			this.$store.state.restaurantsModule.squareLocations.forEach(
				(location) => {
					options.push({
						id: location.id,
						text: location.name
					})
				}
			)
			return options
		},
		confirmBtnText() {
			return this.editing ? "Save Integration" : "Complete Integration"
		}
	},
	methods: {
		handleOptions(options) {
			this.editing = options.integration ? true : false
			let defaultLocation = {
				id: null
			}
			if (
				this.$store.state.restaurantsModule.squareLocations.length > 0
			) {
				if (options.integration && options.integration.locationId) {
					defaultLocation = this.$store.state.restaurantsModule.squareLocations.find(
						(location) =>
							location.id == options.integration.locationId
					)
				} else {
					defaultLocation = this.$store.state.restaurantsModule
						.squareLocations[0]
				}
			}
			this.selectedLocation = {
				id: defaultLocation.id,
				text: defaultLocation.name
			}
		},
		async saveIntegration() {
			if (!this.selectedLocation || !this.selectedLocation.id) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Square Integration",
					message:
						"Select a location to complete the Square integration.",
					type: NotificationType.WARNING
				})
				return
			}
			this.confirm({
				locationId: this.selectedLocation.id
			})
		}
	}
}
</script>