<template>
	<div class="sk-table-row">
		<div>
			{{ payout_period }}
		</div>
		<div
			class="payout-location text-truncate"
			:title="payout.restaurant.name"
		>
			{{ payout.restaurant.name }}
		</div>
		<div>
			{{ fundingSource }}
		</div>
		<div>
			{{ payout.orders_count }}
		</div>
		<div>
			{{ payout.cogs.toFixed(2) | currency }}
		</div>
		<div>
			{{ payout.cogs_tax.toFixed(2) | currency }}
		</div>
		<div>
			{{ payout.commission.toFixed(2) | currency }}
		</div>
		<div>
			{{ payout.tips.toFixed(2) | currency }}
		</div>
		<div>
			{{ payout.exceptions.toFixed(2) | currency }}
		</div>
		<div class="total-payout">
			{{ payout.amount.toFixed(2) | currency }}
		</div>
		<div>
			<span :class="statusClasses"></span>
			{{ statusLabel }}
		</div>
		<div class="sk-table-column-fit">
			<div class="sk-table-actions">
				<button
					class="button button-icon"
					@click="downloadBreakdown"
					title="Download Payout"
				>
					<i class="sk-icon-download"></i>
				</button>
			</div>
		</div>
	</div>
</template>

<style scoped>
.total-payout {
	font-weight: 600;
}
.payout-location {
	max-width: 150px;
}
</style>

<script>
import currencyFilter from "@/mixins/currency-filter-mixin"
import { FundingSource } from "@/enums/fundingSource"
import { PayoutStatus } from "@/enums/payoutStatus"
import moment from "moment"
import exportPayoutBreakdownMixin from "@/mixins/finance/export-payout-breakdown-mixin"

export default {
	name: "PayoutDetailsHistoryLineItem",
	mixins: [currencyFilter, exportPayoutBreakdownMixin],
	props: {
		payout: Object
	},
	computed: {
		payout_period() {
			return (
				moment.utc(this.payout.from_date).format("MMM D") +
				"-" +
				moment.utc(this.payout.to_date).format("MMM D")
			)
		},
		fundingSource() {
			var retVal = "N/A"
			if (
				this.payout.funding_source != undefined &&
				this.payout.funding_source.type != undefined &&
				this.payout.funding_source.type.id == FundingSource.ACH
			)
				retVal = "ACH"
			return retVal
		},
		fileName() {
			return (
				this.payout.restaurant.name + "_" + this.payout_period + ".xlsx"
			)
		},
		statusClasses() {
			var retVal = ""
			switch (this.payout.status.value) {
				case PayoutStatus.APPROVED:
					retVal = "status-dot green"
					break
				case PayoutStatus.PROCESSING:
					retVal = "status-dot yellow"
					break
				case PayoutStatus.COMPLETED:
					retVal = "status-dot green"
					break
				case PayoutStatus.FAILED:
					retVal = "status-dot red"
					break
			}
			return retVal
		},
		statusLabel() {
			var retVal = ""
			switch (this.payout.status.value) {
				case PayoutStatus.APPROVED:
					retVal = "Processing"
					break
				case PayoutStatus.PROCESSING:
					retVal = "Processing"
					break
				case PayoutStatus.COMPLETED:
					retVal = "Completed"
					break
				case PayoutStatus.FAILED:
					retVal = "Failed"
					break
			}
			return retVal
		}
	}
}
</script>