<template>
	<div class="sk-widget-alt">
		<div class="restaurant-summary">
			<div class="restaurant-title">
				<h1>{{ restaurantName }}</h1>
				<img
					v-if="restaurantLogo"
					:src="restaurantLogo"
					alt="Restaurant Logo"
				/>
				<SkDropdownSelect
					v-if="showRestaurantSelector"
					:showSelectedOption="false"
					:options="restaurantOptions"
					@selected="filterRestaurant"
				/>
			</div>
			<div class="header-line-item">
				<i class="sk-icon-clock-regular-1"></i>
				<b>Serving Hours</b>
				{{ servingHours }}
			</div>
			<div class="header-line-item">
				<i class="sk-icon-star-solid"></i>
				{{ restaurantRating }}
			</div>
		</div>
	</div>
</template>

<style scoped>
.sk-widget-alt {
	overflow: visible;
	z-index: 1001;
    position: relative;
}

.restaurant-summary >>> .sk-select {
	width: auto;
}

.restaurant-summary {
	flex: 1;
}

.restaurant-title {
	display: flex;
	align-items: center;
	margin-bottom: 23px;
}

.restaurant-title h1 {
	font-size: 20px;
	color: var(--sk-dark-navy);
	margin-bottom: 0;
}

.restaurant-title img {
	width: 30px;
	height: 30px;
	border-radius: 2px;
	margin-left: 10px;
}

.header-line-item + .header-line-item {
	margin-top: 15px;
}

.header-line-item {
	display: flex;
	align-items: center;
	font-size: 10px;
	color: var(--sk-grey3);
}

.header-line-item a {
	color: var(--sk-grey3);
	text-decoration: none;
}

.header-line-item i {
	margin-right: 10px;
	font-size: 10px;
	color: var(--sk-grey2);
}

.header-line-item b {
	margin-right: 5px;
}
</style>

<script>
import moment from "moment"
import momentHelper from "@/utils/moment-helper"
import ActionTypes from "@/store/modules/restaurantDashboard/action-types"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"
import { MenuType } from "@arikgaisler/utils/enums/menuType"

export default {
	name: "RestaurantDashboardHeader",
	components: {
		SkDropdownSelect
	},
	computed: {
		showRestaurantSelector() {
			return this.$store.getters.ownsMultipleEntities
		},
		restaurantOptions() {
			return this.$store.getters.ownedRestaurants
				.filter(
					(restaurant) => restaurant.name && restaurant.name != ""
				)
				.map((restaurant) => {
					return {
						id: restaurant.id,
						text: restaurant.name
					}
				})
				.sort((a, b) => {
					if (a.text < b.text) {
						return -1
					}
					if (a.text > b.text) {
						return 1
					}
					return 0
				})
		},
		restaurant() {
			return this.$store.state.restaurantDashboardModule.restaurant
		},
		restaurantName() {
			return this.restaurant ? this.restaurant.name : ""
		},
		restaurantTimezone() {
			return this.restaurant && this.restaurant.timezone_offset
				? this.restaurant.timezone_offset
				: 0
		},
		restaurantLogo() {
			if (
				this.restaurant &&
				this.restaurant.logo &&
				this.restaurant.logo.length > 0
			) {
				let logoSuffix =
					this.restaurant.logo && this.restaurant.logo.length > 0
						? this.restaurant.logo
						: `${this.restaurant.slug}-logo.jpg`
				return `${this.$restaurantCDNURL}${this.restaurant.slug}/${logoSuffix}`
			}
			return null
		},
		restaurantRating() {
			if (
				this.$store.state.restaurantDashboardModule.restaurantAvgRating
			) {
				return this.$store.state.restaurantDashboardModule
					.restaurantAvgRating
			}
			return "--"
		},
		servingHours() {
			const menus =
				this.$store.state.restaurantDashboardModule
					.todaysRestaurantMenus
			const servingRanges = []
			const dailyHours = menus
				.map((menu) => {
					let hours = []
					if (menu.data.type == MenuType.POPUP) {
						const event = Object.values(
							this.$store.state.restaurantDashboardModule
								.todaysEvents
						).find((event) => event.menu_id == menu.data.id)
						if (event) {
							hours.push({
								open_time: moment
									.utc(event.start_time, "HH:mm:ss")
									.add(this.restaurantTimezone, "m")
									.format("HH:mm:ss"),
								close_time: moment
									.utc(event.end_time, "HH:mm:ss")
									.add(this.restaurantTimezone, "m")
									.format("HH:mm:ss"),
								dow: momentHelper.normalizeDayOfWeek(
									moment
										.utc(event.start_time, "HH:mm:ss")
										.add(this.restaurantTimezone, "m")
								)
							})
						}
					} else if (menu.schedules && menu.schedules.length > 0) {
						hours = menu.schedules.map((schedule) => {
							return {
								open_time: schedule.openTime,
								close_time: schedule.closeTime,
								dow: schedule.dow
							}
						})
					}

					return hours
				})
				.flat()
				.sort((dailyHourA, dailyHourB) => {
					const openTimeANum = momentHelper.numericalTime(
						dailyHourA.open_time
					)
					const openTimeBNum = momentHelper.numericalTime(
						dailyHourB.open_time
					)
					return openTimeANum - openTimeBNum
				})

			if (dailyHours.length == 0) {
				return "Closed"
			}

			dailyHours.forEach((dailyHour) => {
				if (
					!dailyHour ||
					!dailyHour.open_time ||
					!dailyHour.close_time
				) {
					return
				}

				if (servingRanges.length == 0) {
					servingRanges.push({
						openTime: dailyHour.open_time,
						closeTime: dailyHour.close_time,
						dow: dailyHour.dow
					})
				} else {
					const dailyHourBounds = {
						openTime: dailyHour.open_time,
						closeTime: dailyHour.close_time,
						dow: dailyHour.dow
					}
					const fitsWithinBounds = servingRanges.find(
						(range, index) => {
							const withinRangeBounds =
								momentHelper.isWithinBounds(
									range,
									dailyHourBounds
								)
							const withinDailyHourBounds =
								momentHelper.isWithinBounds(
									dailyHourBounds,
									range
								)
							if (
								withinRangeBounds.openTimeInBounds &&
								withinRangeBounds.closeTimeInBounds
							) {
								return true
							} else if (
								withinDailyHourBounds.openTimeInBounds &&
								withinDailyHourBounds.closeTimeInBounds
							) {
								servingRanges[index] = dailyHourBounds
								return true
							} else if (
								withinRangeBounds.openTimeInBounds &&
								!withinRangeBounds.closeTimeInBounds
							) {
								servingRanges[index].closeTime =
									dailyHourBounds.closeTime
								return true
							}
							return false
						}
					)

					if (!fitsWithinBounds) {
						servingRanges.push(dailyHourBounds)
					}
				}
			})

			return servingRanges
				.sort(
					(dailyHourA, dailyHourB) => dailyHourA.dow - dailyHourB.dow
				)
				.map((range) => {
					let openTime = moment
						.utc(range.openTime, "HH:mm:ss")
						.format("h:mm A")
					let closeTime = moment
						.utc(range.closeTime, "HH:mm:ss")
						.format("h:mm A")

					if (openTime == "12:00 AM" && closeTime == "12:00 AM") {
						return "All Day"
					}

					if (range.openTime == "12:00 AM") {
						openTime = "Midnight"
					}

					if (closeTime == "12:00 AM") {
						closeTime = "Midnight"
					}
					return `${openTime} - ${closeTime}`
				})
				.join(", ")
		}
	},
	methods: {
		filterRestaurant(restaurant) {
			return this.$store.dispatch(
				`restaurantDashboardModule/${ActionTypes.GET_DASHBOARD_DATA}`,
				{
					restaurantId: restaurant.id
				}
			)
		}
	}
}
</script>