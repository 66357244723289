<template>
	<div class="sk-tabs">
		<button
			v-for="tab in tabs"
			class="button button-text"
			:disabled="tab.disabled"
			:key="tab.id"
			:class="{ active: tab.active }"
			@click="tabClicked(tab)"
		>
			<div :ref="`${tab.id}_content`">
				{{ tab.text }}
			</div>
		</button>
		<span class="sk-tab-underline" :style="underlineStyle"></span>
	</div>
</template>

<style scoped>
.sk-tabs {
	box-shadow: 0px 0px 3px rgba(191, 191, 191, 0.5);
	margin-left: -20px !important;
	margin-top: -20px !important;
	position: relative;
	z-index: 0;
	width: calc(100% + 40px) !important;
	background: var(--sk-white);
}

.sk-tabs .button-text {
	font-size: 14px;
	margin-right: 0;
	color: var(--sk-grey2);
}

.sk-tabs .button-text:disabled {
	color: var(--sk-grey);
}

.sk-tabs .button-text.active {
	font-weight: 600;
	color: var(--sk-navy);
}

.button > div {
	display: inline;
}

.sk-tab-underline {
	position: absolute;
	bottom: 0;
	left: 0;
	height: 3px;
	background: var(--sk-dark-navy);
	transition: left 0.25s ease-in-out, width 0.25s ease-in-out;
}
</style>

<script>
export default {
	name: "SkTabs",
	data: function () {
		return {
			underlineLeftOffset: 0,
			underlineWidth: 0,
			didMount: false
		}
	},
	props: {
		tabs: Array,
		isVisible: Boolean
	},
	computed: {
		underlineStyle() {
			return {
				left: `${this.underlineLeftOffset}px`,
				width: `${this.underlineWidth}px`
			}
		},
		activeTab() {
			let activeTab = null
			if (this.tabs && this.tabs.length > 0) {
				activeTab = this.tabs.find((tab) => tab.active)
			}
			return activeTab
		}
	},
	methods: {
		underlineActiveTab() {
			if (!this.activeTab) {
				return
			}
			const tabContent = this.$refs[`${this.activeTab.id}_content`]
			if (tabContent && tabContent[0]) {
				this.underlineLeftOffset = tabContent[0].offsetLeft
				this.underlineWidth = tabContent[0].offsetWidth
			}
		},
		tabClicked(tab) {
			this.$emit("tabClicked", tab)
		}
	},
	mounted: function () {
		this.$nextTick(() => {
			this.underlineActiveTab()
		})
		this.didMount = true
		window.addEventListener("resize", this.underlineActiveTab)
	},
	beforeDestroy: function () {
		window.removeEventListener("resize", this.underlineActiveTab)
	},
	watch: {
		tabs() {
			if (this.didMount) {
				this.underlineActiveTab()
			}
		},
		isVisible: {
			immediate: true,
			handler(visible) {
				if (visible) {
					this.$nextTick(() => {
						this.underlineActiveTab()
					})
				}
			}
		}
	}
}
</script>