<template>
	<div class="sk-page-content dashboard-page-content">
		<div class="dashboard-content-left">
			<RestaurantDashboardHeader class="sk-row" />
			<div class="sk-row-inner">
				<RestaurantDashboardBusinesses />
				<RestaurantDashboardOrders />
			</div>
		</div>
		<div class="dashboard-content-right">
			<DashboardCta
				class="sk-row"
				:title="'Insights'"
				:message="'Dive deeper into your sales, orders and performance'"
				:icon="'sk-icon-analytics-solid'"
				:actions="insightsActions"
				@actionSelected="insightsActionSelected"
			/>
			<DashboardCta
				class="sk-row"
				:title="'Supplies'"
				:message="'Boost customer awareness & interaction with 2K supplies'"
				:icon="'sk-icon-boxes-solid'"
				:actions="suppliesActions"
				@actionSelected="suppliesActionSelected"
			/>
			<DashboardCta
				v-if="showFinance"
				class="sk-row"
				:title="'Finance'"
				:message="'Check invoices and keep your financial details up to date'"
				:icon="'sk-icon-dollar-sign-regular'"
				:actions="financesActions"
				@actionSelected="financesActionSelected"
			/>
			<DashboardCta
				class="sk-row"
				:title="'Events'"
				:message="'Events are a great way to boost business & engage customers'"
				:icon="'sk-icon-calendar-alt-regular'"
				:actions="eventsActions"
				@actionSelected="eventsActionSelected"
			/>
			<DashboardCta
				class="sk-row"
				:title="'Integrations'"
				:message="'Events are a great way to boost business & engage customers'"
				:icon="'sk-icon-calendar-alt-regular'"
				:actions="integrationsActions"
				@actionSelected="integrationsActionSelected"
			/>
			<DashboardCta
				class="sk-row"
				:title="'Special Closures'"
				:message="'Closing for a day or a special event? Be sure to update your hours in advance'"
				:icon="'sk-icon-alarm-clock-regular'"
				:actions="openingHoursExceptionActions"
				@actionSelected="openingHoursExceptionActionSelected"
			/>
			<DashboardCta
				class="sk-row"
				:title="'Feedback'"
				:message="'Provide your feedback to help build a better experience'"
				:icon="'sk-icon-comment-edit-solid'"
				:actions="feedbackActions"
				@actionSelected="feedbackActionSelected"
			/>
		</div>
		<JoinPopupNetworkModal ref="joinPopupNetworkModal" />
		<RequestSuppliesModal ref="requestSuppliesModal" />
		<CannyFeedbackModal ref="cannyFeedbackModal" />
		<RestaurantHoursExceptionModal ref="restaurantHoursExceptionModal" />
	</div>
</template>

<script>
import { PusherEvent } from "@/enums/pusherEvent"
import { PermissionPage } from "@/utils/permissions"
import tracker from "@/utils/tracker"
import ActionTypes from "@/store/modules/restaurantDashboard/action-types"
import loaderMixin from "@/mixins/loader-mixin"
import pusherMixin from "@/mixins/pusher-mixin"
import RestaurantDashboardHeader from "@/components/dashboard/restaurant/RestaurantDashboardHeader.vue"
import RestaurantDashboardBusinesses from "@/components/dashboard/restaurant/RestaurantDashboardBusinesses.vue"
import RestaurantDashboardOrders from "@/components/dashboard/restaurant/RestaurantDashboardOrders.vue"
import DashboardCta from "@/components/dashboard/DashboardCta.vue"
import JoinPopupNetworkModal from "@/components/modals/JoinPopupNetworkModal.vue"
import RequestSuppliesModal from "@/components/modals/supplies/RequestSuppliesModal.vue"
import CannyFeedbackModal from "@/components/modals/CannyFeedbackModal.vue"
import RestaurantHoursExceptionModal from "@/components/modals/RestaurantHoursExceptionModal.vue"

export default {
	name: "RestaurantDashboard",
	mixins: [loaderMixin, pusherMixin],
	components: {
		RestaurantDashboardHeader,
		RestaurantDashboardBusinesses,
		RestaurantDashboardOrders,
		DashboardCta,
		JoinPopupNetworkModal,
		RequestSuppliesModal,
		CannyFeedbackModal,
		RestaurantHoursExceptionModal
	},
	data: function () {
		return {
			suppliesActions: [
				{
					id: 0,
					text: "Order Supplies"
				},
				{
					id: 1,
					text: "View All"
				}
			],
			insightsActions: [
				{
					id: 0,
					text: "View All"
				}
			],
			financesActions: [
				{
					id: 0,
					text: "View Finances"
				}
			],
			feedbackActions: [
				{
					id: 0,
					text: "Provide Feedback"
				}
			],
			integrationsActions: [
				{
					id: 0,
					text: "View Integrations"
				}
			],
			openingHoursExceptionActions: [
				{
					id: 0,
					text: "Create Special Closure"
				},
				{
					id: 1,
					text: "View All"
				}
			]
		}
	},
	computed: {
		restaurant() {
			return this.$store.state.restaurantDashboardModule.restaurant
		},
		supportsPopups() {
			return this.restaurant && this.restaurant.supports_popup == 1
		},
		showFinance() {
			return this.$store.state.permissions.includes(
				PermissionPage.FINANCE
			)
		},
		eventsActions() {
			return [
				{
					id: 0,
					text: this.supportsPopups
						? "View All"
						: "Join Events Offering"
				}
			]
		},
		loading() {
			return this.$store.state.restaurantDashboardModule.loading
		}
	},
	methods: {
		handlePusherEvent(event) {
			switch (event.name) {
				case PusherEvent.ORDER_STATUS: {
					this.$store.dispatch(
						`restaurantDashboardModule/${ActionTypes.GET_RECENT_ORDERS}`,
						this.restaurant.id
					)
					break
				}
			}
		},
		suppliesActionSelected(action) {
			switch (action.id) {
				case 0: {
					tracker.trackEvent("Restaurant Dashboard CTA Clicked", {
						type: "Supplies Request"
					})
					this.$refs.requestSuppliesModal.open()
					break
				}
				case 1: {
					tracker.trackEvent("Restaurant Dashboard CTA Clicked", {
						type: "Supplies View"
					})
					this.$router.push({ path: "/supplies" })
					break
				}
			}
		},
		trainingActionSelected(action) {
			switch (action.id) {
				case 0: {
					tracker.trackEvent("Restaurant Dashboard CTA Clicked", {
						type: "Training Complete"
					})
					this.$refs.staffTrainingIntroModal.open()
					break
				}
			}
		},
		financesActionSelected(action) {
			switch (action.id) {
				case 0: {
					tracker.trackEvent("Restaurant Dashboard CTA Clicked", {
						type: "Finances View"
					})
					this.$router.push({
						path: `/finance/restaurant/${this.restaurant.id}`
					})
					break
				}
			}
		},
		eventsActionSelected(action) {
			switch (action.id) {
				case 0: {
					if (this.supportsPopups) {
						this.$router.push({ path: "/events" })
					} else {
						this.$refs.joinPopupNetworkModal.open({
							restaurantId: this.restaurant.id
						})
					}
					break
				}
			}
		},
		insightsActionSelected(action) {
			switch (action.id) {
				case 0: {
					tracker.trackEvent("Restaurant Dashboard CTA Clicked", {
						type: "Insights View"
					})
					this.$router.push({ path: "/insights" })
					break
				}
			}
		},
		integrationsActionSelected(action) {
			switch (action.id) {
				case 0: {
					tracker.trackEvent("Restaurant Dashboard CTA Clicked", {
						type: "Integration View"
					})
					this.$router.push({
						path: `/restaurant/${this.restaurant.id}?tabId=7`
					})
					break
				}
			}
		},
		openingHoursExceptionActionSelected(action) {
			switch (action.id) {
				case 0: {
					tracker.trackEvent("Restaurant Dashboard CTA Clicked", {
						type: "Special Closures Create"
					})
					this.$refs.restaurantHoursExceptionModal.open({
						restaurant: this.restaurant
					})
					break
				}
				case 1: {
					tracker.trackEvent("Restaurant Dashboard CTA Clicked", {
						type: "Special Closures View"
					})
					this.$router.push({
						path: `/restaurant/${this.restaurant.id}?tabId=4`
					})
					break
				}
			}
		},
		feedbackActionSelected(action) {
			switch (action.id) {
				case 0: {
					tracker.trackEvent("Restaurant Dashboard CTA Clicked", {
						type: "Feedback Request"
					})
					this.$refs.cannyFeedbackModal.open()
					break
				}
			}
		}
	},
	mounted: function () {
		this.$store.dispatch(
			`restaurantDashboardModule/${ActionTypes.GET_DASHBOARD_DATA}`
		)
		this.subscribe(this.handlePusherEvent)
	},
	watch: {
		loading: function (loading) {
			this.toggleLoader(loading)
		}
	}
}
</script>