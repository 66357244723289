import Vue from "vue"

import { store } from "@/store/store"
import { parseResponse } from "@arikgaisler/utils/api-utils"

let host = process.env.VUE_APP_API_HOST

const FINANCE_API = `https://finance${host}.2ndkitchen.com`

export default {
	async getRestaurantPendingPayoutAmount(restaurant_id) {
		const tokenStr = "Bearer " + store.state.user_token
		return parseResponse(
			Vue.axios.get(
				`${FINANCE_API}/restaurants/${restaurant_id}/pending_payout_amount`,
				{ headers: { Authorization: tokenStr } }
			)
		)
	},
	async updateRestaurantPayout(payout_id, payload) {
		const tokenStr = "Bearer " + store.state.user_token
		return Vue.axios.put(
			`${FINANCE_API}/restaurants_payouts/${payout_id}`,
			payload,
			{ headers: { Authorization: tokenStr } }
		)
	},
	async getPayoutOrders(payout_id) {
		const tokenStr = "Bearer " + store.state.user_token
		return Vue.axios.get(
			`${FINANCE_API}/restaurants/payouts/${payout_id}/orders`,
			{ headers: { Authorization: tokenStr } }
		)
	},
	async batchGetPendingPayoutAmounts(payload) {
		const tokenStr = "Bearer " + store.state.user_token
		return parseResponse(
			Vue.axios.post(
				`${FINANCE_API}/restaurants/pending_payout_amount/batch/get`,
				payload,
				{ headers: { Authorization: tokenStr } }
			)
		)
	},
	async batchGetPendingInvoiceAmount(payload) {
		const tokenStr = "Bearer " + store.state.user_token
		return parseResponse(
			Vue.axios.post(
				`${FINANCE_API}/businesses/pending_collection_amount/batch/get`,
				payload,
				{ headers: { Authorization: tokenStr } }
			)
		)
	},
	async getBusinessBillingInvoiceRecords(invoice_id) {
		const tokenStr = "Bearer " + store.state.user_token
		return parseResponse(
			Vue.axios.get(
				`${FINANCE_API}/businesses_billing_collections/${invoice_id}/records`,
				{ headers: { Authorization: tokenStr } }
			)
		)
	},
	async approveRestaurantPayout(payout_id) {
		const tokenStr = "Bearer " + store.state.user_token
		return Vue.axios.put(
			`${FINANCE_API}/restaurants/payouts/${payout_id}/approve`,
			null,
			{ headers: { Authorization: tokenStr } }
		)
	},
	async adjustRestaurantPayoutAmount(payout_id, payload) {
		const tokenStr = "Bearer " + store.state.user_token
		return parseResponse(
			Vue.axios.post(
				`${FINANCE_API}/restaurants_payouts/${payout_id}/adjust_amount`,
				payload,
				{ headers: { Authorization: tokenStr } }
			)
		)
	},
	async clearRestaurantPayout(payout_id, payload) {
		const tokenStr = "Bearer " + store.state.user_token
		return parseResponse(
			Vue.axios.post(
				`${FINANCE_API}/restaurants_payouts/${payout_id}/complete`,
				payload,
				{ headers: { Authorization: tokenStr } }
			)
		)
	},
	async batchApproveRestaurantPayouts(payload) {
		const tokenStr = "Bearer " + store.state.user_token
		return parseResponse(
			Vue.axios.post(
				`${FINANCE_API}/restaurants_payouts/batch-approve`,
				payload,
				{ headers: { Authorization: tokenStr } }
			)
		)
	},
	async batchCompleteRestaurantPayouts(payload) {
		const tokenStr = "Bearer " + store.state.user_token
		return parseResponse(
			Vue.axios.post(
				`${FINANCE_API}/restaurants_payouts/batch-complete`,
				payload,
				{ headers: { Authorization: tokenStr } }
			)
		)
	},
	async addBusinessInvoiceRecord(invoice_id, payload) {
		const tokenStr = "Bearer " + store.state.user_token
		return Vue.axios.post(
			`${FINANCE_API}/businesses_billing_collections/${invoice_id}/records`,
			payload,
			{ headers: { Authorization: tokenStr } }
		)
	},
	async approveBusinessInvoice(invoice_id) {
		const tokenStr = "Bearer " + store.state.user_token
		return Vue.axios.post(
			`${FINANCE_API}/businesses_billing_collections/${invoice_id}/approve`,
			null,
			{ headers: { Authorization: tokenStr } }
		)
	},
	async completeBusinessInvoice(invoice_id) {
		const tokenStr = "Bearer " + store.state.user_token
		return Vue.axios.post(
			`${FINANCE_API}/businesses_billing_collections/${invoice_id}/complete`,
			null,
			{ headers: { Authorization: tokenStr } }
		)
	},
	async batchApproveBusinessInvoices(payload) {
		const tokenStr = "Bearer " + store.state.user_token
		return parseResponse(
			Vue.axios.post(
				`${FINANCE_API}/businesses_billing_collections/batch/approve`,
				payload,
				{ headers: { Authorization: tokenStr } }
			)
		)
	}
}