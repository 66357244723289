<template>
	<div class="sk-widget-alt activate-widget">
		<img src="/images/2k-logo-login.png" alt="2ndKitchen Logo" />
		<div class="sk-column">
			<h1>Welcome to 2ndKitchen!</h1>
			<div class="activate-subtitle">
				Let’s get your account set up with some basic information.
			</div>
		</div>
		<div class="sk-row">
			<SkInput
				ref="nameInput"
				v-model="name"
				:name="'Name'"
				:placeholder="'Name'"
				:required="true"
				:tabindex="1"
			/>
		</div>
		<div class="sk-row">
			<SkInput
				ref="emailInput"
				v-model="email"
				:name="'Email'"
				:placeholder="'Email'"
				:required="true"
				:tabindex="2"
			/>
		</div>
		<div class="sk-row">
			<SkInput
				ref="phoneInput"
				v-model="phone"
				class="optional-field"
				:name="'Phone Number'"
				:placeholder="'Phone Number'"
				:tabindex="3"
				:type="'tel'"
			/>
		</div>
		<div class="sk-row">
			<h1 class="setup-password">Set Up Password</h1>
		</div>
		<SkPasswordInput
			class="sk-row"
			ref="passwordInput"
			v-model="password"
			:name="'Password'"
			:tabindex="4"
		/>
		<SkPasswordInput
			class="sk-row"
			ref="confirmedPasswordInput"
			v-model="confirmedPassword"
			:name="'Confirm Password'"
			:tabindex="5"
			@onEnter="activateUser"
		/>
		<div class="sk-row">
			<button
				@click="activateUser"
				class="button button-primary activate-button"
			>
				Complete Setup
			</button>
		</div>
		<SignupWidgetFooter :links="footerLinks" @linkClicked="linkClicked" />
	</div>
</template>

<style scoped>
h1 {
	color: var(--sk-dark-navy);
	font-size: 16px;
	margin-bottom: 12px !important;
}

h1.setup-password {
	margin-bottom: 0 !important;
	margin-top: 25px;
}

.activate-subtitle {
	font-size: 12px;
	color: var(--sk-grey2);
	margin-bottom: 10px;
}

.activate-widget {
	width: 500px;
	max-width: 500px;
	height: auto;
	padding: 0;
}

.activate-widget img {
	height: 40px;
	margin: 60px auto;
	display: block;
}

.activate-button {
	min-width: 140px;
	flex: 0 0 auto !important;
	margin: 10px auto 0 auto;
}
</style>

<script>
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import tracker from "@/utils/tracker"
import auth from "@/utils/auth"
import SkPasswordInput from "@/components/SkPasswordInput.vue"
import SignupWidgetFooter from "@/components/login/SignupWidgetFooter.vue"
import SkInput from "@/components/SkInput.vue"

export default {
	name: "ActivateWidget",
	components: {
		SkPasswordInput,
		SignupWidgetFooter,
		SkInput
	},
	props: {
		userDetails: Object
	},
	data() {
		return {
			name: "",
			email: "",
			phone: "",
			password: "",
			confirmedPassword: "",
			footerLinks: [
				{
					id: 0,
					text: `Not a 2ndKitchen Partner? <span class="signup-link">Get Started!</span>`
				},
				{
					id: 1,
					text: `Already a Partner? <span class="signup-link">Sign In!</span>`
				}
			]
		}
	},
	methods: {
		validate() {
			const nameValid = this.$refs.nameInput.validate()
			const emailValid = this.$refs.emailInput.validate()
			const phoneValid = this.$refs.phoneInput.validate()
			const passwordValid = this.$refs.passwordInput.validate()
			const confirmedPasswordValid = this.$refs.confirmedPasswordInput.validate()
			return (
				nameValid &&
				emailValid &&
				phoneValid &&
				passwordValid &&
				confirmedPasswordValid
			)
		},
		activateUser() {
			tracker.trackEvent("Activate User Clicked", {
				email: this.email,
				name: this.name,
				phone: this.phone
			})
			const isValid = this.validate()
			if (!isValid) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Chef Activation",
					message: "Field forms are invalid",
					type: NotificationType.WARNING
				})
				return
			}

			if (this.password != this.confirmedPassword) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Chef Activation",
					message: "Passwords must match",
					type: NotificationType.WARNING
				})
				return
			}
            
            this.$emit("toggleLoader", true)
			auth.activate(this.$route.params.token, this.password, this.email)
				.then((loggedIn) => {
					if (!loggedIn) {
						this.$emit("refreshActivation")
					}
				})
				.finally(() => {
					this.$emit("toggleLoader", false)
				})
		},
		linkClicked(link) {
			switch (link.id) {
				case 0: {
					tracker.trackEvent("Signup Clicked")
					window.open("https://2ndkitchen.com/")
					break
				}
				case 1: {
					tracker.trackEvent("Activate Clicked")
					this.$router.push({ path: "/login" })
					break
				}
			}
		}
	},
	watch: {
		userDetails: {
			deep: true,
			immediate: true,
			handler: function (newVal) {
				this.name =
					newVal && newVal.display_name ? newVal.display_name : ""
				this.email = newVal && newVal.username ? newVal.username : ""
				this.phone = newVal && newVal.phone ? newVal.phone : ""
			}
		}
	}
}
</script>