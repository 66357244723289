<template>
	<div
		v-show="showLauncher"
		class="sk-crisp-launcher"
		ref="draggableContainer"
		@click="openChat"
		@mousedown="dragMouseDown"
		:class="{ disabled: chatOpened, unread: hasUnreadMessages }"
	>
		<i class="sk-icon-comment-lines-solid"></i>
	</div>
</template>

<style scoped>
.sk-crisp-launcher {
	cursor: pointer;
	width: 40px;
	height: 40px;
	position: fixed;
	bottom: 20px;
	right: 20px;
	background: var(--sk-orange);
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--sk-white);
	box-shadow: 0px 4px 4px rgba(255, 112, 0, 0.25);
	opacity: 1;
	transition: opacity linear 0.5s, background linear 0.5s;
	z-index: 10000;
}

.unread {
	background: var(--sk-green);
	box-shadow: 0px 4px 4px rgba(144, 218, 54, 0.25);
}

.sk-crisp-launcher i {
	font-size: 15px;
}

.disabled {
	pointer-events: none;
	cursor: default;
	opacity: 0.5;
}
</style>

<script>
export default {
	name: "CrispLauncher",
	data: function () {
		return {
			showLauncher: false,
			chatOpened: false,
			hasUnreadMessages: false,
			preventClick: false,
			positions: {
				clientX: undefined,
				clientY: undefined,
				movementX: 0,
				movementY: 0
			}
		}
	},
	computed: {
		username() {
			return this.$store.state.user_name
		}
	},
	methods: {
		openChat() {
			if (this.preventClick) {
				this.preventClick = false
			} else {
				$crisp.push(["do", "chat:open"])
			}
		},
		configureCrisp() {
			if (this.$store.state.user_id) {
				const user = this.$store.state.user
				$crisp.push(["set", "user:email", [user.email]])
				$crisp.push(["set", "user:nickname", [user.display_name]])
				this.showLauncher = true
			} else {
				$crisp.push(["do", "session:reset"])
				$crisp.push(["do", "chat:hide"])
				this.showLauncher = false
				this.hasUnreadMessages = false
			}
		},
		dragMouseDown: function (event) {
			event.preventDefault()
			this.positions.clientX = event.clientX
			this.positions.clientY = event.clientY
			document.onmousemove = this.elementDrag
			document.onmouseup = this.closeDragElement
		},
		elementDrag: function (event) {
			event.preventDefault()
			this.positions.movementX = this.positions.clientX - event.clientX
			this.positions.movementY = this.positions.clientY - event.clientY
			if (this.positions.movementY > 0 || this.positions.movementY > 0) {
				this.preventClick = true
			}
			this.positions.clientX = event.clientX
			this.positions.clientY = event.clientY
			this.$refs.draggableContainer.style.top =
				this.$refs.draggableContainer.offsetTop -
				this.positions.movementY +
				"px"
			this.$refs.draggableContainer.style.left =
				this.$refs.draggableContainer.offsetLeft -
				this.positions.movementX +
				"px"
		},
		closeDragElement() {
			document.onmouseup = null
			document.onmousemove = null
		}
	},
	mounted: function () {
		$crisp.push(["config", "color:theme", ["orange"]])
		$crisp.push(["do", "chat:hide"])
		$crisp.push([
			"on",
			"chat:closed",
			() => {
				$crisp.push(["do", "chat:hide"])
				this.chatOpened = false
			}
		])
		$crisp.push([
			"on",
			"chat:opened",
			() => {
				setTimeout(() => {
					$crisp.push(["do", "chat:show"])
				}, 500)
				this.chatOpened = true
				this.hasUnreadMessages = false
			}
		])

		$crisp.push([
			"on",
			"message:received",
			() => {
				if (!this.chatOpened) {
					this.hasUnreadMessages = true
				}
			}
		])

		$crisp.push([
			"on",
			"session:loaded",
			() => {
				setTimeout(() => {
					if (!this.chatOpened) {
						this.hasUnreadMessages =
							$crisp.get("chat:unread:count") > 0
					}
				}, 5000)
			}
		])
	},
	watch: {
		username: {
			immediate: true,
			handler() {
				this.configureCrisp()
			}
		}
	}
}
</script>