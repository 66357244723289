<template>
	<div
		v-if="showSidebar"
		class="sidebar-wrapper"
		:class="{
			'sidebar-content-expanded': isExpanded,
			'sidebar-wrapper-hovering': hovering
		}"
	>
		<div class="sidebar-header">
			<a href="/" class="sidebar-logo-mobile">
				<img alt="Logo" src="/images/logo-sidebar-1.png" />
			</a>
			<span
				@click="toggleSidebar"
				class="sk-icon-ellipsis-v-regular"
			></span>
		</div>
		<div ref="sidebarContent" class="sidebar-content">
			<a href="/" class="sidebar-logo">
				<img alt="Logo" src="/images/logo-sidebar-1.png" />
				<img alt="Logo" src="/images/logo-sidebar-2.png" />
			</a>
			<SideBarActivityFeed
				ref="activityFeed"
				:isExpanded="expandedActivityFeed"
				@toggled="overflowMenuToggled"
				@resetHover="resetHover"
			/>
			<ul class="sidebar-menu dark-scrollbar">
				<li
					class="sidebar-item"
					@click="closeSidebar"
					:class="{
						'sidebar-item-active':
							menuID == sidebarMenuIds.DASHBOARD
					}"
					aria-haspopup="true"
				>
					<router-link to="/dashboard">
						<i class="sk sk-icon-home-lg-alt-solid"></i>
						<span class="sidebar-item-text">Dashboard</span>
					</router-link>
				</li>

				<li
					v-if="insightsAllowed"
					class="sidebar-item"
					@click="closeSidebar"
					:class="{
						'sidebar-item-active':
							menuID == sidebarMenuIds.INSIGHTS
					}"
					aria-haspopup="true"
				>
					<router-link to="/insights">
						<i class="sk sk-icon-analytics-solid"></i>
						<span class="sidebar-item-text">Insights</span>
					</router-link>
				</li>


				<li
					@click="closeSidebar"
					class="sidebar-item"
					aria-haspopup="true"
					:class="{
						'sidebar-item-active':
							menuID == sidebarMenuIds.ACTIVE_ORDERS
					}"
				>
					<router-link to="/orders">
						<i class="sk sk-icon-receipt-solid"></i>
						<span class="sidebar-item-text">Orders</span>
					</router-link>
				</li>

				<li
					v-if="businessManagementAllowed"
					@click="closeSidebar"
					class="sidebar-item"
					aria-haspopup="true"
					:class="{
						'sidebar-item-active': menuID == sidebarMenuIds.BUSINESS
					}"
				>
					<router-link :to="businessLink">
						<i class="sk sk-icon-building-solid"></i>
						<span class="sidebar-item-text">
							{{ businessText }}
						</span>
					</router-link>
				</li>
				<li
					v-if="restaurantManagementAllowed"
					@click="closeSidebar"
					class="sidebar-item"
					aria-haspopup="true"
					:class="{
						'sidebar-item-active':
							menuID == sidebarMenuIds.RESTAURANT
					}"
				>
					<router-link :to="restaurantLink">
						<i class="sk sk-icon-utensils-solid"></i>
						<span class="sidebar-item-text">
							{{ restaurantText }}
						</span>
					</router-link>
				</li>

				<li
					@click="closeSidebar"
					class="sidebar-item"
					aria-haspopup="true"
					:class="{
						'sidebar-item-active': menuID == sidebarMenuIds.EVENTS
					}"
				>
					<router-link to="/events">
						<i class="sk sk-icon-calendar-alt-regular"></i>
						<span class="sidebar-item-text">Events</span>
					</router-link>
				</li>

				<li
					v-if="managePromotionsAllowed"
					@click="closeSidebar"
					class="sidebar-item"
					aria-haspopup="true"
					:class="{
						'sidebar-item-active':
							menuID == sidebarMenuIds.PROMOTIONS
					}"
				>
					<router-link to="/promotions">
						<i class="sk sk-icon-tag-solid"></i>
						<span class="sidebar-item-text">Promotions</span>
					</router-link>
				</li>

				<li
					v-if="prepackagesAllowed"
					@click="closeSidebar"
					class="sidebar-item"
					aria-haspopup="true"
					:class="{
						'sidebar-item-active':
							menuID == sidebarMenuIds.PREPACKAGES
					}"
				>
					<router-link to="/catering">
						<i class="sk sk-icon-sandwich-solid"></i>
						<span class="sidebar-item-text">Catering</span>
					</router-link>
				</li>

				<li
					v-if="salesToolPageAllowed"
					@click="closeSidebar"
					class="sidebar-item"
					aria-haspopup="true"
					:class="{
						'sidebar-item-active': menuID == sidebarMenuIds.SALES
					}"
				>
					<router-link to="/sales">
						<i class="sk sk-icon-map-marker-alt-solid1"></i>
						<span class="sidebar-item-text">Check Location</span>
					</router-link>
				</li>

				<li
					v-if="hubsManagementAllowed"
					@click="closeSidebar"
					class="sidebar-item"
					aria-haspopup="true"
					:class="{
						'sidebar-item-active': menuID == sidebarMenuIds.HUBS
					}"
				>
					<router-link to="/hubs">
						<i class="sk sk-icon-globe-americas-solid"></i>
						<span class="sidebar-item-text">Hubs</span>
					</router-link>
				</li>

				<li
					v-if="financeManagementAllowed"
					@click="closeSidebar"
					class="sidebar-item"
					aria-haspopup="true"
					:class="{
						'sidebar-item-active': menuID == sidebarMenuIds.FINANCE
					}"
				>
					<router-link to="/finance">
						<i class="sk sk-icon-dollar-sign-regular"></i>
						<span class="sidebar-item-text">Finance</span>
					</router-link>
				</li>

				<li
					class="sidebar-item"
					@click="closeSidebar"
					v-if="userPageAllowed"
					aria-haspopup="true"
					:class="{
						'sidebar-item-active': menuID == sidebarMenuIds.USERS
					}"
				>
					<router-link to="/users">
						<i class="sk sk-icon-users-crown-solid"></i>
						<span class="sidebar-item-text">Users</span>
					</router-link>
				</li>
				<li
					class="sidebar-item"
					@click="closeSidebar"
					v-if="suppliesPageAllowed"
					aria-haspopup="true"
					:class="{
						'sidebar-item-active': menuID == sidebarMenuIds.SUPPLIES
					}"
				>
					<router-link to="/supplies">
						<i class="sk sk-icon-boxes-solid"></i>
						<span class="sidebar-item-text">Request Supplies</span>
					</router-link>
				</li>
			</ul>

			<div class="sidebar-user-section">
				<div class="sidebar-user-picture">
					{{ userNameInitial }}
				</div>
				<div class="sidebar-user-details">
					<div class="sidebar-username text-truncate">
						{{ username }}
					</div>
					<div class="sidebar-user-subtitle text-truncate">
						{{ userSubtitle }}
					</div>
				</div>
				<SkDropdownSelect
					ref="userMenu"
					:options="userMenuOptions"
					:iconClasses="'sk-icon-chevron-down-regular'"
					:showSelectedOption="false"
					:position="'top'"
					@selected="userMenuActionSelected"
					@toggled="overflowMenuToggled"
				/>
			</div>
		</div>
		<transition name="fade" v-if="isExpanded">
			<div class="sidebar-mobile-backdrop" @click="toggleSidebar"></div>
		</transition>
	</div>
</template>

<style scoped>
.sidebar-wrapper {
	flex: 0 0 71px;
	width: 71px;
	height: 100vh;
	background: var(--sk-white);
	z-index: 1000;
}

.sidebar-wrapper-hovering .sidebar-content {
	width: 215px;
}

.sidebar-wrapper-hovering {
	z-index: 10000;
}

.sidebar-wrapper-hovering .sidebar-logo img:last-child,
.sidebar-wrapper-hovering .sidebar-item-text,
.sidebar-wrapper-hovering .sidebar-user-details,
.sidebar-wrapper-hovering .sidebar-user-section >>> .sk-select {
	opacity: 1;
}

.sidebar-wrapper-hovering .sidebar-user-details {
	overflow: visible;
}

.sidebar-wrapper .sidebar-logo img:last-child,
.sidebar-wrapper .sidebar-item-text,
.sidebar-wrapper .sidebar-user-details,
.sidebar-wrapper .sidebar-user-section >>> .sk-select {
	transition: opacity 0.25s linear;
}

.sidebar-header {
	background: var(--sk-white);
	width: 100vw;
	position: fixed;
	top: 0;
	left: 0;
	padding: 20px 25px;
	justify-content: space-between;
	align-items: center;
	display: none;
}

.sidebar-content {
	width: 70px;
	height: 100%;
	display: flex;
	flex-direction: column;
	box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
	border-radius: 0px 10px 10px 0px;
	position: fixed;
	top: 0;
	left: 0;
	transition: width 0.25s linear;
	background: var(--sk-white);
}

.sidebar-logo {
	display: flex;
	padding-left: 17px;
	margin-top: 21px;
	margin-bottom: 20px;
	align-items: center;
	overflow: hidden;
}

.sidebar-logo img:first-child {
	margin-right: 13px;
	height: 35px;
}

.sidebar-logo img:last-child {
	opacity: 0;
	height: 25px;
}

.sidebar-logo-mobile img {
	height: 40px;
}

.sk-icon-ellipsis-v-regular {
	font-size: 24px;
}

.sidebar-menu {
	list-style: none;
	padding: 0;
	background: var(--sk-white);
	flex: 1;
	margin-bottom: 65px;
	overflow: hidden;
}

.sidebar-item a,
.sidebar-item button {
	display: flex;
	color: var(--sk-grey2);
	font-size: 14px;
	padding: 10px 0 10px 25px;
	align-items: center;
	text-decoration: none !important;
	border-left: 4px solid var(--sk-white);
}

.sidebar-item-active a,
.sidebar-item a:hover,
.sidebar-item-active button,
.sidebar-item button:hover {
	color: var(--sk-orange);
	font-weight: 600;
	border-left: 4px solid var(--sk-orange);
}

.sidebar-item .sk {
	min-width: 20px;
}

.sidebar-item-text {
	margin-left: 22px;
	opacity: 0;
	white-space: nowrap;
	margin-right: 15px;
}

.sidebar-user-section {
	height: 65px;
	background: var(--sk-greybg2);
	border-top: 1px solid var(--sk-grey);
	padding-left: 20px;
	display: flex;
	align-items: center;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
}

.sidebar-user-picture {
	flex-shrink: 0;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background: var(--sk-dark-navy);
	color: var(--sk-white);
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 600;
}

.sidebar-user-details {
	opacity: 0;
	margin-left: 15px;
	max-width: 110px;
	overflow: hidden;
}

.sidebar-username {
	font-size: 12px;
	color: var(--sk-grey3);
	font-weight: 600;
	white-space: nowrap;
}

.sidebar-user-subtitle {
	font-size: 10px;
	color: var(--sk-grey2);
	white-space: nowrap;
}

.sidebar-user-section >>> .sk-select {
	width: 36px;
	margin-left: auto;
	opacity: 0;
}

.sidebar-user-section >>> .sk-select .selected i {
	font-size: 10px;
}

@media (max-width: 1024px) {
	.sidebar-wrapper {
		position: fixed;
		left: 0;
		top: 0;
		z-index: 1000;
		height: 80px;
	}

	.sidebar-logo {
		display: none;
	}

	.sidebar-content {
		width: 215px;
		transition: transform 0.25s linear, opacity 0.25s linear;
		transform: translateX(-215px);
		background: var(--sk-white);
		z-index: 1000;
	}

	.sidebar-header {
		display: flex;
		box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
	}

	.sidebar-wrapper {
		background: transparent;
	}

	.sidebar-mobile-backdrop {
		background: rgba(0, 0, 0, 0.25);
		position: absolute;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		z-index: 999;
	}

	.sidebar-wrapper-hovering .sidebar-logo img:last-child {
		opacity: 0;
	}

	.sidebar-wrapper-hovering .sidebar-item-text,
	.sidebar-content-expanded .sidebar-item-text {
		opacity: 1;
	}

	.sidebar-content-expanded .sidebar-content {
		opacity: 1;
		transform: translateX(0);
		overflow: visible;
	}

	.sidebar-content-expanded .sidebar-mobile-backdrop {
		opacity: 1;
		display: block;
	}
}
</style>

<script>
import { SidebarMenuId } from "@/enums/sidebarMenuId"
import { PermissionPage } from "@/utils/permissions"
import { UserRole } from "@/enums/userRole"
import SideBarActivityFeed from "@/components/sidebar/SideBarActivityFeed.vue"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"

export default {
	name: "SideBar",
	components: {
		SideBarActivityFeed,
		SkDropdownSelect
	},
	props: {
		hovering: Boolean
	},
	data: function () {
		return {
			isExpanded: false,
			sidebarMenuIds: SidebarMenuId
		}
	},
	computed: {
		userMenuOptions() {
			return [
				{
					text: `<i class="sk-icon-question-solid"></i> Help`,
					id: 0
				},
				{
					text: `<i class="sk-icon-sign-out-alt-solid"></i> Logout`,
					id: 1
				}
			]
		},
		menuID() {
			return this.$route.meta.sidebarMenuId
		},
		username() {
			return this.$store.state.user_name
		},
		userNameInitial() {
			return this.$store.state.user_name
				? this.$store.state.user_name.charAt(0)
				: ""
		},
		userSubtitle() {
			if (this.$store.getters.isSuperAdmin) {
				return "Admin"
			} else if (this.$store.state.user_role == UserRole.BUSINESS_ADMIN) {
				return this.$store.getters.business
					? this.$store.getters.business.name
					: "N/A"
			} else if (
				this.$store.state.user_role == UserRole.RESAURANT_ADMIN
			) {
				return this.$store.getters.restaurant
					? this.$store.getters.restaurant.name
					: "N/A"
			}
		},
		showSidebar() {
			return (
				this.$store.state.user_id > 0 &&
				this.$route.name != "Login" &&
				this.$route.name != "Activate"
			)
		},
		insightsAllowed() {
			const businessInsights = this.$store.state.permissions.includes(
				PermissionPage.BUSINESS_INSIGHTS
			)
			const restaurantInsights = this.$store.state.permissions.includes(
				PermissionPage.RESTAURANT_INSIGHTS
			)
			return businessInsights || restaurantInsights
		},
		businessManagementAllowed() {
			return this.$store.state.permissions.includes(
				PermissionPage.MANAGE_BUSINESSES
			)
		},
		restaurantManagementAllowed() {
			return this.$store.state.permissions.includes(
				PermissionPage.MANAGE_RESTAURANTS
			)
		},
		hubsManagementAllowed() {
			return this.$store.state.permissions.includes(
				PermissionPage.HUBS
			)
		},
		financeManagementAllowed() {
			return this.$store.state.permissions.includes(
				PermissionPage.FINANCE
			)
		},
		salesToolPageAllowed() {
			return this.$store.state.permissions.includes(
				PermissionPage.SALES
			)
		},
		userPageAllowed() {
			return this.$store.state.permissions.includes(PermissionPage.USERS)
		},
		suppliesPageAllowed() {
			return this.$store.state.permissions.includes(
				PermissionPage.SUPPLIES
			)
		},
		managePromotionsAllowed() {
			return this.$store.state.permissions.includes(
				PermissionPage.MANAGE_PROMOTIONS
			)
		},
		prepackagesAllowed() {
			return this.$store.state.permissions.includes(
				PermissionPage.PREPACKAGES
			)
		},
		businessLink() {
			if (this.$store.getters.ownsMultipleEntities) {
				return "/businesses"
			} else if (this.$store.getters.business) {
				return `/business/${this.$store.getters.business.slug}`
			}
		},
		businessText() {
			return this.$store.getters.ownsMultipleEntities
				? "Businesses"
				: "My Business"
		},
		restaurantText() {
			return this.$store.getters.ownsMultipleEntities
				? "Restaurants"
				: "My Restaurant"
		},
		restaurantLink() {
			if (this.$store.getters.ownsMultipleEntities) {
				return "/restaurants"
			} else {
				let restaurants = Object.values(this.$store.state.restaurants)
				if (restaurants.length > 0) {
					return `/restaurant/${restaurants[0].id}`
				}
				return ""
			}
		},
		expandedActivityFeed() {
			return this.hovering || this.isExpanded
		}
	},
	methods: {
		openCrisp() {
			$crisp.push(["do", "chat:open"])
		},
		toggleSidebar() {
			this.isExpanded = !this.isExpanded
		},
		closeSidebar() {
			if (document.documentElement.clientWidth <= this.$mobileWidth) {
				this.toggleSidebar()
			}
		},
		userMenuActionSelected(action) {
			switch (action.id) {
				case 0:
					this.openCrisp()
					break
				case 1: {
					const routerResponse = this.$router.replace("/logout")
					if (routerResponse && routerResponse.catch) {
						routerResponse.catch(() => {})
					}
					break
				}
			}
			this.overflowMenuToggled(false)
		},
		overflowMenuToggled(toggled) {
			this.$emit("forceExpand", toggled)
		},
		resetHover() {
			this.$emit("hidden")
		}
	},
	watch: {
		showSidebar: function (newValue) {
			if (!newValue) {
				this.$emit("hidden")
				this.$emit("forceExpand", false)
				if (this.$refs.activityFeed) {
					this.$refs.activityFeed.close()
				}
				if (this.$refs.userMenu) {
					this.$refs.userMenu.onBlur()
				}
			}
		},
		$route() {
			this.$emit("hidden")
			this.$emit("forceExpand", false)
			if (this.$refs.activityFeed) {
				this.$refs.activityFeed.close()
			}
			if (this.$refs.userMenu) {
				this.$refs.userMenu.onBlur()
			}
		}
	}
}
</script>