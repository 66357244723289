<template>
	<div class="business-menus">
		<slot v-if="pagedStaffUsers.length > 0">
			<div class="sk-table sk-table-default">
				<div class="sk-table-header">
					<div class="sk-table-row">
						<div>Eligibility</div>
						<div>Name</div>
						<div>Email</div>
						<div>Phone</div>
						<div>Business</div>
						<div>Role</div>
						<div>Actions</div>
					</div>
				</div>
				<transition-group
					name="list"
					tag="div"
					class="sk-table-body"
					appear
				>
					<StaffUserLineItem
						v-for="staffUser in pagedStaffUsers"
						:key="staffUser.id"
						:user="staffUser"
						@updateStaffUserClicked="editStaffUserClicked"
						@removeEmployeeClicked="removeBusinessEmployee"
					/>
				</transition-group>
			</div>
			<PaginationFooter
				:contentText="'Staff'"
				:pageSize="20"
				:total="staffUsers.length"
				@pageChanged="filterPage"
			/>
		</slot>
		<EmptyPlaceholderWidget
			v-else
			:title="`No staff members found`"
		/>
		<ConfirmationModal ref="staffConfirmationModal" />
	</div>
</template>

<style scoped>
.business-menus {
	height: 100%;
	display: flex;
	flex-direction: column;
}
</style>

<script>

import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import StaffUserLineItem from "@/components/users/StaffUserLineItem.vue"
import PaginationFooter from "@/components/PaginationFooter.vue"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue"
import { ConfirmationModalType } from "@/enums/confirmationModalType"
import EmptyPlaceholderWidget from "@/components/EmptyPlaceholderWidget.vue"

export default {
	name: "StaffUsers",
	components: {
		StaffUserLineItem,
		PaginationFooter,
		ConfirmationModal,
		EmptyPlaceholderWidget
	},
	props: {
		filter: Object
	},
	data: function() {
		return {
			pageRange: {
				page: 1,
				start: 0,
				end: 20
			}
		}
	},
	computed: {
		staffUsers() {
			let retVal = this.$store.state.businessesModule.business_employees.slice().sort(
				(a, b) => b.id - a.id
			)
			var that = this
			if (this.filter.businessId > 0) {
				retVal = retVal.filter(
					employee => employee.business_id == that.filter.businessId
				)
			}
			if (this.filter.name != "") {
				retVal = retVal.filter(
					employee => employee.name.toLowerCase() == that.filter.name
				)
			}
			if (this.filter.email != "") {
				retVal = retVal.filter(
					employee =>
						employee.email.toLowerCase() == that.filter.email
				)
			}
			if (this.filter.phone != "") {
				retVal = retVal.filter(
					employee => employee.phone == that.filter.phone
				)
			}
			if (this.filter.status != null) {
				retVal = retVal.filter(
					employee => employee.is_active == that.filter.status
				)
			}
			return retVal
		},
		pagedStaffUsers() {
			return this.staffUsers.slice(
				this.pageRange.start,
				this.pageRange.end
			)
		}
	},
	methods: {
		filterPage(range) {
			this.pageRange = range
		},
		editStaffUserClicked(payload) {
			this.$emit("editStaffUserClicked", payload)
		},
		async removeBusinessEmployee(employee) {
			const confirmed = await this.$refs.staffConfirmationModal.open({
				title: `Are you sure you want to remove ${employee.name} from your staff list?`,
				confirmation: "Remove",
				confirmationIcon: "sk-icon-trash-alt-solid",
				cancel: "Cancel",
				type: ConfirmationModalType.RED
			})
			if (confirmed) {
				const resp = this.$store.dispatch(
					"businessesModule/removeBusinessEmployee",
					employee.id
				)
				if (resp) {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: `Successfully removed ${employee.name} from staff list`
					})
				} else {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Something went wrong, please try again",
						type: NotificationType.WARNING
					})
				}
			}
		}
	}
}
</script>