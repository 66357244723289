<template>
	<div class="exception-statistic-widget vld-parent">
		<loading :active.sync="loading" :is-full-page="false"></loading>
		<TabbedInsightWidget
			:title="title"
			:tabs="tabs"
			:footerTitle="footerTitle"
			:footerIcon="'sk-icon-lightbulb-light'"
			@tabClicked="tabClicked"
		>
			<div class="exception-statistic-content" slot="content">
				<transition name="fade-height" mode="out-in">
					<div v-if="activeTabId == 0" :key="0">
						<div class="exception-statistic">
							{{ statistic }}
						</div>
					</div>
					<div v-else-if="activeTabId == 1" :key="1">
						<div
							class="exception-order"
							v-for="order in orders"
							:key="order.id"
						>
							<div class="exception-order-details">
								<div>#{{ order.id }}</div>
								<div>
									{{ order.orderDate }}
								</div>
							</div>
							<div>{{ order.exceptionAmount | currency }}</div>
						</div>
					</div>
				</transition>
			</div>
		</TabbedInsightWidget>
	</div>
</template>

<style scoped>
.exception-statistic-widget {
	min-height: 262px;
}

.exception-statistic {
	font-size: 30px;
	font-weight: 600;
	color: var(--sk-dark-navy);
}

.exception-statistic-content {
	min-height: 105px;
    padding: 30px 0;
}

.exception-order {
	display: flex;
	justify-content: space-between;
	color: var(--sk-dark-navy);
	font-weight: 600;
	font-size: 12px;
}

.exception-order:not(:first-child) {
    margin-top: 30px;
}

.exception-order-details > div:last-child {
	color: var(--sk-grey3);
	font-weight: 400;
}

.tab-height-enter-active,
.tab-height-leave-active {
	transition: all 2s;
	max-height: 1000px;
	overflow: hidden;
}

.tab-height-enter-active {
	/* position: absolute; */
}

.tab-height-enter,
.tab-height-leave-to {
	opacity: 0;
	max-height: 0px;
}
</style>

<script>
import moment from "moment"
import pluralizeFilterMixin from "@/mixins/pluralize-filter-mixin"
import currencyFilterMixin from "@/mixins/currency-filter-mixin"
import TabbedInsightWidget from "@/components/insights/TabbedInsightWidget.vue"
import Loading from "vue-loading-overlay"

export default {
	name: "RestaurantExceptionStatistic",
	mixins: [pluralizeFilterMixin, currencyFilterMixin],
	components: {
		TabbedInsightWidget,
		Loading
	},
	props: {
		loading: Boolean,
		title: String,
		footerTitle: String,
		data: Object
	},
	data: function () {
		return {
			activeTabId: 0
		}
	},
	computed: {
		tabs() {
			let tabs = [
				{
					id: 0,
					text: "Total"
				},
				{
					id: 1,
					text: "Recent Affected Orders",
					disabled: this.orders.length == 0
				}
			]
			return tabs.map((tab) => {
				tab.active = tab.id == this.activeTabId
				return tab
			})
		},
		statistic() {
			let count = 0
			if (this.data && this.data.order_count) {
				count = this.data.order_count
			}
			const context = this.$options.filters.pluralize(count, "Order")
			return `${count} ${context}`
		},
		orders() {
			if (this.data && this.data.orders) {
				return this.data.orders.map((order) => {
					let exceptionAmount = order.exceptions.reduce(
						(amount, exception) => {
							amount += exception.amount
							return amount
						},
						0
					)
					return {
						id: order.id,
						orderDate: moment
							.utc(order.created_time_local)
							.format("MMM Do, YYYY"),
						exceptionAmount: exceptionAmount
					}
				})
			}
			return []
		}
	},
	methods: {
		tabClicked(tab) {
			this.activeTabId = tab.id
		}
	},
	watch: {
		orders: {
			handler() {
				if (this.orders.length == 0) {
					this.activeTabId = 0
				}
			}
		}
	}
}
</script>