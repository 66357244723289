<template>
	<SkBaseModal class="sk-modal-alt" :isOpen="isOpen" @close="cancel">
		<button class="button button-icon button-close" @click="cancel()">
			<i class="sk-icon-times-regular"></i>
		</button>
		<div class="sk-modal-alt-title">
			<img src="/images/grubhub-logo.png" alt="Grubhub Logo" />
			<h1>Grubhub</h1>
		</div>
		<div class="sk-row">
			<SkInput
				ref="locationIdInput"
				:value="locationId"
				:name="'Grubhub Restaurant Id'"
				:required="true"
				@input="parseLocationId"
			/>
		</div>
		<div class="sk-modal-actions">
			<button class="button button-text" @click="cancel">Cancel</button>
			<button class="button button-primary" @click="saveIntegration">
				{{ confirmBtnText }}
			</button>
		</div>
	</SkBaseModal>
</template>

<script>
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import skPromiseModalMixin from "@/mixins/modals/sk-promise-modal-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import SkInput from "@/components/SkInput.vue"

export default {
	name: "GrubhubIntegrationModal",
	mixins: [skPromiseModalMixin],
	components: {
		SkBaseModal,
		SkInput
	},
	data: function () {
		return {
			locationId: "",
			editing: false
		}
	},
	computed: {
		confirmBtnText() {
			return this.editing ? "Save Integration" : "Add Integration"
		}
	},
	methods: {
		parseLocationId(value) {
			if (value.includes("grubhub.com")) {
				try {
					const url = new URL(value)
					const locationId = url.pathname.split("/")[3]
					this.locationId = locationId ? locationId : value
				} catch (e) {
					this.locationId = value
				}
			} else {
				this.locationId = value
			}
		},
		handleOptions(options) {
			if (options.integration) {
				this.editing = true
				this.locationId = options.integration.locationId
					? options.integration.locationId
					: ""
			} else {
				this.editing = false
			}
		},
		saveIntegration() {
			const isLocationIdValid = this.$refs.locationIdInput.validate()
			if (!isLocationIdValid) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Grubhub Integration",
					message: "A Grubhub restaurant id is required",
					type: NotificationType.WARNING
				})
				return
			}
			this.confirm({
				locationId: this.locationId
			})
		}
	}
}
</script>