<template>
	<div class="sk-table-row">
		<div :title="coupon.code" class="sk-table-column-fit">
			<div class="pill pill-grey">
				<i class="sk-icon-tag-solid"></i>
				{{ coupon.code }}
			</div>
		</div>
		<div :title="couponType">{{ couponType }}</div>
		<div :title="amount">{{ amount }}</div>
		<div v-if="businessName" :title="businessName">{{ businessName }}</div>
		<div class="sk-muted-text" v-else>Universal</div>
		<div v-if="coupon.usage_limit > 0" :title="usageCount">
			{{ usageCount }}
		</div>
		<div v-else :title="usageCount">
			{{ coupon.num_of_uses }}/
			<i>&#8734;</i>
		</div>
		<div :title="valid" class="sk-table-column-fit">
			<span :class="statusClasses"></span>
			{{ valid }}
		</div>
		<div class="sk-table-column-fit">
			<div class="sk-table-actions">
				<button
					class="button button-icon"
					@click="actionSelected({ id: 1 })"
					title="Edit"
				>
					<i class="sk-icon-pencil-solid"></i>
				</button>
				<SkDropdownSelect
					:options="allowedActions"
					:iconClasses="'sk-icon-ellipsis-v-regular'"
					:showSelectedOption="false"
					:position="'left'"
					@selected="actionSelected"
				/>
			</div>
		</div>
	</div>
</template>

<style>
.pill i {
	margin-right: 10px;
}
</style>

<script>
import { CouponType } from "@arikgaisler/utils/enums/couponType"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"

export default {
	name: "CouponLineItem",
	components: {
		SkDropdownSelect
	},
	props: {
		coupon: Object
	},
	computed: {
		businessName() {
			if (this.coupon.business) {
				return this.coupon.business.name
			}
			return null
		},
		usageCount() {
			let limit = "<i>&#8734;<i>"
			if (this.coupon.usage_limit > 0) {
				limit = this.coupon.usage_limit
			}
			return this.coupon.num_of_uses + "/" + limit
		},
		amount() {
			let amount = this.coupon.amount
			if (this.coupon.type == 0) {
				amount = "$" + amount
			} else {
				amount = amount + "%"
			}
			return amount
		},
		couponType() {
			if (
				this.coupon.coupon_type == CouponType.CREDIT ||
				(this.coupon.ref_order_id != "" &&
					this.coupon.ref_order_id != undefined)
			) {
				return "Credit"
			} else if (this.coupon.coupon_type == CouponType.DEFAULT) {
				return "Promotion"
			} else if (this.coupon.coupon_type == CouponType.VOUCHER) {
				return "Voucher"
			}
		},
		allowedActions() {
			let actions = []
			actions.push({
				text: `<i class="sk-icon-pencil-solid"></i> Edit`,
				id: 1
			})
			if (this.coupon.coupon_type == CouponType.VOUCHER) {
				actions.push({
					text: `<i class="sk-icon-times-regular"></i> Print`,
					id: 2
				})
			}
			actions.push({
				text: `<i class="sk-icon-times-regular"></i> Delete`,
				id: 0
			})
			return actions
		},
		valid() {
			if (
				this.coupon.usage_limit == 0 ||
				this.coupon.num_of_uses < this.coupon.usage_limit
			) {
				return "Valid"
			}
			return "Used"
		},
		statusClasses() {
			if (
				this.coupon.usage_limit == 0 ||
				this.coupon.num_of_uses < this.coupon.usage_limit
			) {
				return "status-dot green"
			}
			return "status-dot red"
		}
	},
	methods: {
		actionSelected(action) {
			switch (action.id) {
				case 0:
					this.$emit("deleteCoupon", this.coupon)
					break
				case 1:
					this.$emit("updateCoupon", this.coupon)
					break
			}
		}
	}
}
</script>