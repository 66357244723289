<template>
	<SkBaseModal
		class="business-setup-modal"
		:isOpen="isOpen"
		:showCloseButton="false"
		:hasLoader="true"
		@close="cancel"
	>
		<loading :active.sync="loading" :is-full-page="false" />
		<div class="sk-modal-alt-title">
			<h1>New Menu Setup</h1>
		</div>
		<div class="business-setup-navbar">
			<div
				v-for="step in steps"
				class="business-setup-step"
				:key="step.id"
				:class="{
					active: step.isActive,
					invalid: step.isInvalid,
					complete: step.isComplete
				}"
				@click="setStep(step.id, $event)"
			>
				{{ step.text }}
				<SkRadio :option="step.id" v-model="currentStep" />
			</div>
		</div>
		<transition-group name="fade-height" class="business-setup-steps">
			<div v-show="currentStep == 0" class="sk-modal-section" key="0">
				<div class="sk-row">
					<SkInput
						v-model="menu.name"
						ref="menuNameInput"
						:required="true"
						:name="'Menu Name'"
					/>
				</div>
				<div class="sk-row">
					<SkDropdownSelect
						class="business-type-dd sk-input"
						v-model="menuType"
						:options="menuTypes"
					/>
				</div>
				<div class="sk-row">
					<SkDropdownSelect
						class="business-type-dd sk-input"
						v-model="deliveryType"
						:options="deliveryTypes"
					/>
				</div>
			</div>
			<div v-if="currentStep == 1" key="1" class="sk-modal-section">
				<div class="sk-row">
					<div class="search-input">
						<i class="sk-icon-search-regular"></i>
						<input
							class="sk-input"
							placeholder="Search by Product Name"
							v-model="search"
						/>
					</div>
				</div>
				<div class="sk-row">
					<div class="sk-switch-label-wrapper" @click="selectAll">
						<SkCheckbox :checked="allSelected" />
						<span>Select All</span>
					</div>
				</div>
				<transition-group
					tag="div"
					class="product-rows dark-scrollbar"
					name="list"
					appear
				>
					<div
						class="sk-row sk-widget-alt product-row"
						v-for="product in products"
						:key="product.id"
						@click="productSelected(product)"
					>
						<SkCheckbox
							:checked="productIds.includes(product.id)"
						/>
						<div class="product-name">{{ product.name }}</div>
						<div>{{ product.price | currency }}</div>
					</div>
				</transition-group>
			</div>
			<div v-show="currentStep == 2" key="2" class="sk-modal-section">
				<div class="sk-row restaurant-timezone-row">
					<div>{{ timezone | formatTimezone }} Timezone</div>
					<div>
						Timezone is based on the restaurant address. Edit the
						restaurant address to update the timezone.
					</div>
				</div>
				<div
					class="sk-row"
					v-for="schedule in menuSchedule"
					:key="schedule.day"
				>
					<div class="restaurant-schedule-day">
						{{ schedule.day }}
					</div>
					<SkTimePicker v-model="schedule.open" :name="'Open'" />
					<SkTimePicker v-model="schedule.close" :name="'Close'" />
				</div>
			</div>
			<div v-show="currentStep == 3" key="3" class="sk-modal-section">
				<section
					class="add-user-section"
					v-for="(business, index) in businesses"
					:key="business.uuid"
				>
					<div class="sk-row">
						<TypeaheadInput
							:placeholder="'Business'"
							:fetch="autocompleteBusinesses"
							:serializer="typeaheadSerializer"
							:query="business.name"
							@selected="businessSelected(index, ...arguments)"
						/>
					</div>
					<button
						@click="removeBusiness(business)"
						class="
							button button-text
							sk-widget-section-text-button-light
						"
					>
						<i class="sk-icon-times-regular"></i>
						Remove Partner
					</button>
				</section>
				<div class="sk-row">
					<button
						@click="addNewBusiness"
						class="
							button button-text
							sk-widget-section-text-button-light
						"
					>
						<i class="sk-icon-plus-regular"></i>
						Add Partner
					</button>
				</div>
			</div>
			<div v-if="currentStep == 4" key="4" class="sk-modal-section">
				<SetupReviewStep
					:stepName="'General'"
					:stepId="0"
					:invalid="generalStepInvalid"
					:complete="generalStepComplete"
					:progress="generalStepProgress"
					:maxProgress="3"
					:reviewingStepId="reviewingStepId"
					@reviewStep="reviewStep"
					@goToStep="setStep"
				>
					<div
						class="business-step-detail"
						:class="{
							invalid: menu.name.length == 0
						}"
					>
						Menu Name
						<b v-sk-unassigned="menu.name"></b>
					</div>
					<div
						class="business-step-detail"
						:class="{
							invalid: menuType == null
						}"
					>
						Menu Type
						<b v-sk-unassigned="menuType.text"></b>
					</div>
					<div
						class="business-step-detail"
						:class="{
							invalid: deliveryType == null
						}"
					>
						Delivery Type
						<b v-sk-unassigned="deliveryType.text"></b>
					</div>
				</SetupReviewStep>
				<SetupReviewStep
					:stepName="'Hours'"
					:stepId="1"
					:invalid="hoursStepInvalid"
					:complete="hoursStepComplete"
					:progress="hoursStepProgress"
					:maxProgress="7"
					:reviewingStepId="reviewingStepId"
					@reviewStep="reviewStep"
					@goToStep="setStep"
				>
					<div
						class="restaurant-step-detail"
						v-for="schedule in menuSchedule"
						:class="{
							invalid:
								(schedule.open && !schedule.close) ||
								(schedule.close && !schedule.open)
						}"
						:key="schedule.day"
					>
						{{ schedule.day }}
						<b v-if="schedule.open || schedule.close">
							{{ schedule.open }} - {{ schedule.close }}
						</b>
						<span v-else class="sk-muted-text">Closed</span>
					</div>
				</SetupReviewStep>
				<SetupReviewStep
					:stepName="'Products'"
					:stepId="2"
					:complete="productsStepComplete"
					:progress="productsMaxProgress"
					:maxProgress="productsMaxProgress"
					:reviewingStepId="reviewingStepId"
					@reviewStep="reviewStep"
					@goToStep="setStep"
				>
					<div class="business-step-detail">
						Products
						<b v-sk-unassigned="productsLengthStr"></b>
					</div>
				</SetupReviewStep>
				<SetupReviewStep
					:stepName="'Partners'"
					:stepId="3"
					:complete="partnersStepComplete"
					:progress="partnersMaxProgress"
					:maxProgress="partnersMaxProgress"
					:reviewingStepId="reviewingStepId"
					@reviewStep="reviewStep"
					@goToStep="setStep"
				>
					<div class="business-step-detail">
						Partners
						<b v-sk-unassigned="partnersLengthStr"></b>
					</div>
				</SetupReviewStep>
			</div>
		</transition-group>
		<div class="sk-modal-actions">
			<button @click="cancel" class="button button-text">Cancel</button>
			<button
				v-if="currentStep != lastStep"
				class="button button-primary"
				@click="nextStep"
			>
				Continue
				<i class="sk-icon-caret-right-solid"></i>
			</button>
			<button
				v-else
				class="button button-primary"
				:class="{
					'button-primary': canCreateMenu,
					'button-grey': !canCreateMenu
				}"
				@click="createMenu"
			>
				Create Menu
			</button>
		</div>
		<ConfirmationModal ref="confirmationModal" />
	</SkBaseModal>
</template>

<style scoped>
.search-input {
	position: relative;
}

.search-input i {
	position: absolute;
	left: 15px;
	top: 17.5px;
	color: var(--sk-grey2);
}

.search-input .sk-input {
	padding-left: 60px;
}

.product-rows {
	position: relative;
	margin: 0;
	height: 330px;
	overflow-y: auto;
	overflow-x: hidden;
	margin: 0 -5px 20px -5px;
	padding: 5px 5px 0 5px;
}

.product-row {
	display: flex;
	align-items: center;
	margin-left: 0;
	width: 100%;
}

.product-row:first-child {
	margin-top: 0;
}

.product-row >>> .sk-checkbox {
	margin-right: 30px;
}

.product-row .product-name {
	flex: 1;
}

.product-row div {
	font-size: 14px;
	font-weight: 600;
}

.sk-switch-label-wrapper {
	padding-left: 20px;
	font-weight: 600;
	font-size: 12px;
	color: var(--sk-dark-navy);
}

.sk-switch-label-wrapper .sk-checkbox {
	margin-right: 20px;
}

.filter-group-header {
	font-size: 14px;
	font-weight: 600;
	color: var(--sk-navy);
	display: flex;
	align-items: center;
	width: 100%;
	cursor: pointer;
	margin-top: 18px;
}

.filter-group-header .sk-icon-chevron-down-regular {
	margin-left: 15px;
	font-size: 10px;
	color: var(--sk-grey2);
}

.filter-group-divider {
	height: 1px;
	background: rgba(112, 120, 124, 0.35);
	width: 100%;
	margin-left: 15px;
}

.advanced-filter-row {
	margin-top: 20px;
}

.sk-modal-alt-title {
	margin-bottom: 0;
	position: relative;
}

.sk-modal-section .sk-widget-alt {
	padding: 15px;
}

.modal-commission-input {
	min-width: 350px;
}

.business-setup-navbar {
	display: flex;
	justify-content: space-between;
	height: 54px;
	box-shadow: 0px 0px 3px rgba(191, 191, 191, 0.5);
	margin: 0 -20px;
	padding: 0 0 0 20px;
}

.business-setup-steps {
	display: grid;
	grid-template-columns: 1fr;
}

.business-setup-steps > div {
	grid-row-start: 1;
	grid-column-start: 1;
}

.business-setup-steps > div {
	padding: 5px;
	margin: -5px;
}

.business-setup-step {
	display: flex;
	align-items: center;
	color: var(--sk-grey2);
	font-size: 14px;
	cursor: pointer;
}

.business-setup-step.active {
	color: var(--sk-navy);
	font-weight: 600;
}

.business-setup-step.invalid:not(.active) >>> .checkmark {
	border: 1px solid var(--sk-red);
	background: var(--sk-red);
}

.business-setup-step.complete:not(.active) >>> .checkmark {
	border: 1px solid var(--sk-grey2);
	background: var(--sk-grey2);
}

.business-setup-step >>> .checkmark-fill {
	display: none;
}

.business-setup-step >>> .sk-radio {
	width: 10px;
	height: 10px;
	margin-left: 10px;
}

.sk-widget-section-title {
	display: flex;
	align-items: center;
}

.sk-modal-actions .button i {
	margin-left: 30px;
	margin-right: 0px;
}

.business-logo-row {
	height: 20px;
}

.business-logo-row.expanded {
	margin-top: 50px;
}

.business-logo-row .sk-widget-section-text-button-light {
	margin-top: 0 !important;
}

.business-logo-preview {
	width: 75px;
	height: 75px;
	background: var(--sk-navy);
	border-radius: 5px;
	flex: 0 0 auto !important;
}

.restaurant-timezone-row {
	flex-direction: column;
	background: var(--sk-grey);
	border-radius: 5px;
	padding: 12px 15px;
	color: var(--sk-grey3);
}

.restaurant-timezone-row div:first-child {
	font-size: 14px;
	font-weight: 600;
}

.restaurant-timezone-row div:last-child {
	font-size: 12px;
	font-style: italic;
}

.restaurant-schedule-day {
	width: 100px;
	flex: 0 0 auto !important;
	background: var(--sk-grey);
	border-radius: 5px;
	text-align: center;
	line-height: 50px;
	font-size: 14px;
	color: var(--sk-grey3);
}

.add-user-section {
	border-bottom: 1px solid var(--sk-grey);
	margin-bottom: 30px;
	padding-bottom: 10px;
}

.user-step-details + .user-step-details {
	padding-top: 20px;
	margin-top: 20px;
	border-top: 1px solid var(--sk-grey);
}

.business-step-detail {
	color: var(--sk-grey2);
	font-size: 12px;
}

.business-step-detail.invalid,
.business-step-detail.invalid .sk-muted-text,
.business-step-detail.invalid b {
	color: var(--sk-red) !important;
}

.business-step-detail:not(:last-child) {
	margin-bottom: 20px;
}

.business-step-detail .sk-muted-text {
	font-style: italic;
	font-weight: 400;
}

.business-step-detail .sk-muted-text,
.business-step-detail b {
	margin-left: 11px;
}

.business-step-detail b {
	color: var(--sk-grey3);
	font-weight: 600;
}

.sk-widget-section-text-button-light {
	font-size: 12px;
}

.sk-widget-section-text-button-light .optional-label {
	font-style: italic;
	margin-left: 10px;
}
</style>

<script>
import { v1 as uuidv1 } from "uuid"
import { CourierId } from "@arikgaisler/utils/enums/courierId"
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import { MenuType } from "@arikgaisler/utils/enums/menuType"
import { ProductStatus } from "@/enums/productStatus"
import utils from "@/utils/utils"
import moment from "moment"
import "moment-timezone"
import business_service from "@/services/businesses"
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import currencyFilterMixin from "@/mixins/currency-filter-mixin"
import menuScheduleMixin from "@/mixins/menu-schedule-mixin"
import pluralizeFilterMixin from "@/mixins/pluralize-filter-mixin"
import menuTypeFormatterMixin from "@/mixins/menu-type-formatter-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import SkInput from "@/components/SkInput.vue"
import SkTimePicker from "@/components/SkTimePicker.vue"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"
import SkCheckbox from "@/components/SkCheckbox.vue"
import SkRadio from "@/components/SkRadio.vue"
import Loading from "vue-loading-overlay"
import TypeaheadInput from "@/components/TypeaheadInput.vue"
import SetupReviewStep from "@/components/modals/SetupReviewStep.vue"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue"
import TransitionExpand from "@/components/transitions/TransitionExpand.vue"

export default {
	name: "RestaurantMenuSetupModal",
	mixins: [
		skModalMixin,
		currencyFilterMixin,
		menuScheduleMixin,
		pluralizeFilterMixin,
		menuTypeFormatterMixin
	],
	components: {
		SkBaseModal,
		SkInput,
		SkTimePicker,
		SkDropdownSelect,
		SkCheckbox,
		SkRadio,
		Loading,
		TypeaheadInput,
		SetupReviewStep,
		ConfirmationModal,
		TransitionExpand
	},
	data: function () {
		return {
			loading: false,
			search: "",
			requiredNumberRegex: /^[0-9][0-9]*$/,
			currentStep: 0,
			lastStep: 4,
			seenSteps: [],
			productIds: [],
			menu: {},
			menuSchedule: [],
			businesses: [],
			deliveryTypes: [
				{ id: CourierId.WALKING, text: "Self Delivery" },
				{ id: CourierId.CUT_CATS, text: "Cut Cats" },
				{ id: CourierId.POSTMATES, text: "Postmates" },
				{ id: CourierId.DOORDASH, text: "Doordash" },
				{ id: CourierId.REEF, text: "Reef" },
				{ id: CourierId.RELAY, text: "Relay" },
				{ id: CourierId.TOOKAN, text: "Business Delivery" }
			],
			reviewingStepId: null,
			activateBusiness: false
		}
	},
	computed: {
		partnersMaxProgress() {
			return this.businesses.length - 1
		},
		allProducts() {
			return this.$store.state.restaurantsModule.restaurantProducts.filter(
				(product) => product.status != ProductStatus.ARCHIVED
			)
		},
		allSelected() {
			return (
				!this.loading &&
				this.productIds.length == this.allProducts.length
			)
		},
		productsLengthStr() {
			const productCountText = this.$options.filters.pluralize(
				this.productIds.length,
				"Product"
			)
			return `${this.productIds.length} ${productCountText} selected`
		},
		partnersLengthStr() {
			const partnerCountText = this.$options.filters.pluralize(
				this.businesses.length - 1,
				"Partner"
			)
			return `${this.businesses.length - 1} ${partnerCountText} selected`
		},
		restaurant() {
			return this.$store.state.restaurantsModule.currentRestaurant
		},
		products() {
			let regex = null
			if (this.search.length > 0) {
				const term = utils.sanitize(this.search)
				regex = utils.stringToRegex(term)
			}
			return this.allProducts
				.filter((product) => {
					if (!regex) {
						return true
					}

					if (product.name && product.name.match(regex) !== null) {
						return true
					}

					if (this.productIds.includes(product.id)) {
						return true
					}
					return false
				})
				.sort((a, b) => {
					if (
						a.status != ProductStatus.ARCHIVED &&
						b.status != ProductStatus.ARCHIVED
					) {
						if (this.productIds.includes(a.id)) {
							return -1
						}
						if (this.productIds.includes(b.id)) {
							return 1
						}

						if (!regex) {
							return 0
						}

						const aIndex = a.name.indexOf(a.name.match(regex)[0])
						const bIndex = b.name.indexOf(b.name.match(regex)[0])
						if (aIndex < bIndex) {
							return -1
						}
						if (aIndex > bIndex) {
							return 1
						}
						return 0
					}
				})
		},
		timezone() {
			return this.$store.getters["restaurantsModule/timezone"]
		},
		hoursStepComplete() {
			return this.hoursStepProgress == 7
		},
		hoursStepInvalid() {
			return this.menuSchedule.some(
				(day) => (day.open && !day.close) || (day.close && !day.open)
			)
		},
		hoursStepProgress() {
			const availableDays = this.menuSchedule.filter(
				(day) => day.open && day.close
			)
			return availableDays.length
		},
		menuTypes() {
			return [
				{
					id: MenuType.DEFAULT,
					text: this.$options.filters.menuTypeText(MenuType.DEFAULT)
				},
				{
					id: MenuType.POPUP,
					text: this.$options.filters.menuTypeText(MenuType.POPUP)
				},
				{
					id: MenuType.PRE_PACKAGED,
					text: this.$options.filters.menuTypeText(
						MenuType.PRE_PACKAGED
					)
				},
				{
					id: MenuType.ESSENTIALS,
					text: this.$options.filters.menuTypeText(
						MenuType.ESSENTIALS
					)
				}
			]
		},
		menuType: {
			get: function () {
				if (!this.menu || this.menu.type == undefined) {
					return null
				}
				return this.menuTypes.find(
					(typeOption) => typeOption.id == this.menu.type
				)
			},
			set: function (newVal) {
				if (this.menu && newVal != undefined) {
					this.menu.type = newVal.id
				}
			}
		},
		deliveryType: {
			get: function () {
				if (this.menu.courier_id === undefined) {
					return null
				}
				var typeStr = ""
				if (this.menu.courier_id == CourierId.WALKING) {
					typeStr = "Self Delivery"
				}
				if (this.menu.courier_id == CourierId.CUT_CATS) {
					typeStr = "Cut Cats"
				}
				if (this.menu.courier_id == CourierId.TOOKAN) {
					typeStr = "Business Delivery"
				}
				if (this.menu.courier_id == CourierId.POSTMATES) {
					typeStr = "Postmates"
				}
				if (this.menu.courier_id == CourierId.DOORDASH) {
					typeStr = "Doordash"
				}
				if (this.menu.courier_id == CourierId.REEF) {
					typeStr = "Reef"
				}
				if (this.menu.courier_id == CourierId.RELAY) {
					typeStr = "Relay"
				}
				return {
					id: this.menu.courier_id,
					text: typeStr
				}
			},
			set: function (newVal) {
				if (this.menu && newVal != undefined) {
					this.menu.courier_id = newVal.id
				}
			}
		},

		generalStepComplete() {
			return this.generalStepProgress == 3
		},
		generalStepInvalid() {
			return (
				this.menuType === undefined ||
				this.deliveryType === undefined ||
				!this.menu.name ||
				this.menu.name.length == 0
			)
		},
		generalStepProgress() {
			let progress = 0
			if (this.menu.name && this.menu.name.length > 0) {
				progress += 1
			}
			if (this.menuType) {
				progress += 1
			}
			if (this.deliveryType) {
				progress += 1
			}
			return progress
		},
		productsStepComplete() {
			return this.productIds.length > 0
		},
		productsStepInvalid() {
			return false
		},
		productsStepProgress() {
			return this.productIds.length
		},
		productsMaxProgress() {
			return this.productIds.length
		},
		partnersStepComplete() {
			return this.businesses.length > 0
		},
		partnersStepInvalid() {
			return false
		},
		canCreateMenu() {
			return this.steps.every((step) => !step.isInvalid)
		},
		steps() {
			return [
				{
					id: 0,
					isActive: this.currentStep == 0,
					isInvalid: this.generalStepInvalid,
					isComplete: this.generalStepComplete,
					text: "General"
				},
				{
					id: 1,
					isActive: this.currentStep == 1,
					isInvalid: this.productsStepInvalid,
					isComplete: this.productsStepComplete,
					text: "Products"
				},
				{
					id: 2,
					isActive: this.currentStep == 2,
					isInvalid: this.hoursStepInvalid,
					isComplete: this.hoursStepComplete,
					text: "Hours"
				},
				{
					id: 3,
					isActive: this.currentStep == 3,
					isInvalid: this.partnersStepInvalid,
					isComplete: this.partnersStepComplete,
					text: "Partners"
				},
				{
					id: 4,
					isActive: this.currentStep == 4,
					isComplete:
						this.generalStepComplete &&
						this.hoursStepComplete &&
						this.menuStepComplete &&
						this.productsStepComplete,
					text: "Review"
				}
			]
		}
	},
	methods: {
		handleOptions() {
			this.resetValidation("menuNameInput")

			this.menu = {
				type: MenuType.DEFAULT,
				courier_id: CourierId.WALKING,
				name: ""
			}
			this.reviewingStepId = null
			const days = [
				{
					name: "Sunday",
					dow: 6
				},
				{
					name: "Monday",
					dow: 0
				},
				{
					name: "Tuesday",
					dow: 1
				},
				{
					name: "Wednesday",
					dow: 2
				},
				{
					name: "Thursday",
					dow: 3
				},
				{
					name: "Friday",
					dow: 4
				},
				{
					name: "Saturday",
					dow: 5
				}
			]
			this.menuSchedule = days.map((day) => {
				return {
					dow: day.dow,
					day: day.name,
					open: null,
					close: null
				}
			})
			this.productIds = []
			this.addNewBusiness()
		},
		setLoader(loading) {
			this.loading = loading
		},
		getSelectedRestaurantMenuNames(restaurant) {
			const menuNames = restaurant.menus
				.filter((menu) => restaurant.menuIDs.includes(menu.id))
				.map((selectedMenu) => {
					return selectedMenu.name
				})
			if (menuNames && menuNames.length > 0) {
				return menuNames.join()
			} else {
				return "N/A"
			}
		},
		productSelected(product) {
			if (!this.productIds.includes(product.id)) {
				this.productIds.push(product.id)
			} else {
				this.productIds = this.productIds.filter(
					(id) => product.id != id
				)
			}
		},
		autocompleteBusinesses(query) {
			return this.$store.dispatch(
				"businessesModule/autocompleteBusinesses",
				query
			)
		},
		async businessSelected(index, event) {
			const response = await business_service.getBusiness(event.id)
			this.businesses[index].name = response.data.name
			this.businesses[index].id = response.data.id
			this.businesses[index].delivery_modes = response.data.delivery_modes
			this.businesses[index].uuid = uuidv1()
		},
		typeaheadSerializer(result) {
			let hasAddress =
				result.address && result.city && result.state && result.zip
			return {
				text: result.name,
				description: hasAddress
					? `${result.address} <br> ${result.city}, ${result.state} ${result.zip}`
					: null
			}
		},
		nextStep() {
			if (this.currentStep < this.lastStep) {
				this.setStep(this.currentStep + 1)
			}
		},
		reviewStep(id) {
			this.reviewingStepId = id
		},
		async setStep(id, event) {
			if (!this.seenSteps.includes(this.currentStep)) {
				this.seenSteps.push(this.currentStep)
			}
			if (event) {
				event.stopPropagation()
			}
			if (this.currentStep == 0) {
				this.validateInput("menuNameInput")
			}
			this.currentStep = id
		},
		validateInput(inputRef) {
			if (this.$refs[inputRef]) {
				if (this.$refs[inputRef].length > 0) {
					this.$refs[inputRef][0].validate()
				} else {
					this.$refs[inputRef].validate()
				}
			}
		},
		resetValidation(inputRef) {
			if (this.$refs[inputRef]) {
				if (this.$refs[inputRef].length > 0) {
					this.$refs[inputRef][0].resetValidation()
				} else {
					this.$refs[inputRef].resetValidation()
				}
			}
		},
		newBusiness() {
			return {
				name: "",
				uuid: uuidv1(),
				id: 0
			}
		},
		addNewBusiness() {
			this.businesses.push(this.newBusiness())
		},
		removeBusiness(business) {
			if (this.businesses.length > 1) {
				this.businesses = this.businesses.filter(
					(existingBusiness) => business.uuid != existingBusiness.uuid
				)
			} else {
				const business = this.newBusiness()
				this.businesses.splice(0, 1, business)
			}
		},

		async addMenuProducts(menuId) {
			if (this.productIds.length > 0) {
				const success = this.$store.dispatch(
					"restaurantsModule/batchAddProductsToMenu",
					{
						menuId: menuId,
						productIds: this.productIds
					}
				)
				if (success) {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: `Succesfully added ${this.productIds.length} to menu`,
						type: NotificationType.SUCCESS
					})
				} else {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: `Error adding products. Please try again.`,
						type: NotificationType.ERROR
					})
				}
			}
		},
		async addMenuToBusinesses(menuId) {
			const validBusinesses = this.businesses.filter(
				(business) => business.id > 0
			)
			if (validBusinesses.length != 0) {
				validBusinesses.forEach(async (business) => {
					const menuObj = {
						restaurant_id: this.restaurant.id,
						courier_id: this.menu.courier_id,
						delivery_fee: 0,
						delivery_modes: business.delivery_modes,
						id: menuId,
						is_backup: 0,
						is_late_night: 0,
						opening_hours: this.menu.opening_hours,
						min_order_amount: 12,
						name: this.menu.name,
						restaurant_commission: this.restaurant.commission,
						stock_status: 0,
						type: this.menu.type,
						products_categories: [],
						pre_order_allowed:
							this.menu.type == MenuType.PRE_PACKAGED ? 0 : 1
					}
					const payload = {
						menu: menuObj,
						rest: this.restaurant,
						businessID: business.id
					}
					const success = await this.$store.dispatch(
						"businessesModule/addBusinessMenu",
						payload
					)
					if (success) {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: `Succesfully added menu to ${business.name}`,
							type: NotificationType.SUCCESS
						})

						await this.$store.dispatch(
							"getBusinessesByIds",
							{
								ids: [business.id],
								silently: true
							},
							{ root: true }
						)
					} else {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: `Error adding menu to ${business.name}. Please try again.`,
							type: NotificationType.ERROR
						})
					}
				})
			}
		},
		selectAll() {
			if (!this.allSelected) {
				this.productIds = this.allProducts.map((product) => product.id)
			} else {
				this.productIds = []
			}
		},
		async createMenu() {
			try {
				if (!this.canCreateMenu) {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Menu Setup",
						message: "Required fields are missing or invalid",
						type: NotificationType.ERROR
					})
					const firstInvalidStep = this.steps.find(
						(step) => step.isInvalid
					)
					this.reviewingStepId = firstInvalidStep.id
					return false
				}
				const availableSchedules = this.menuSchedule.filter(
					(schedule) => schedule.open && schedule.close
				)
				if (availableSchedules.length > 0 && !this.timezone) {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Menu Setup",
						message:
							"The restaurant address does not have a valid timezone",
						type: NotificationType.ERROR
					})
					return
				}

				this.loading = true

				if (availableSchedules.length > 0) {
					const isDST = moment().isDST()
					this.menu.opening_hours = availableSchedules.map(
						(schedule) => {
							const openTime = moment.tz(
								schedule.open,
								"hh:mm A",
								this.timezone
							)
							const closeTime = moment.tz(
								schedule.close,
								"hh:mm A",
								this.timezone
							)

							if (!isDST) {
								openTime.subtract(60, "m")
								closeTime.subtract(60, "m")
							}

							return {
								day_of_week: schedule.dow,
								open_time: openTime.utc().format("HH:mm:ss"),
								close_time: closeTime.utc().format("HH:mm:ss")
							}
						}
					)
				} else if (
					this.restaurant.opening_hours &&
					this.restaurant.opening_hours.length > 0
				) {
					this.menu.opening_hours = this.restaurant.opening_hours
				} else {
					this.menu.opening_hours = []
				}
				const restaurantId = this.restaurant.id
				const menu = await this.$store.dispatch(
					"restaurantsModule/addMenu",
					{
						restaurantId: restaurantId,
						menu: this.menu
					}
				)
				if (menu) {
					await this.addMenuProducts(menu.data.menu.id)
					await this.addMenuToBusinesses(menu.data.menu.id)

					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Menu Setup",
						message: "The menu was created successfully!"
					})
					await this.$store.dispatch(
						"restaurantsModule/getRestaurant",
						this.restaurant.id
					)
				} else {
					throw "Menu Setup failed to create menu"
				}
				this.close()
				this.loading = false
			} catch (e) {
				this.loading = false
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Menu Setup",
					message: "Something went wrong, please try again.",
					type: NotificationType.ERROR
				})
				this.bugsnag.notify(e, (event) => {
					event.addMetadata("error", {
						restaurantData: this.menu
					})
				})
			}
		},
		cancel() {
			this.$refs.confirmationModal
				.open({
					title: "You have unsaved changes",
					body: "Are you sure you want to close this modal without saving your changes?",
					confirmation: "Continue",
					cancel: "Never Mind"
				})
				.then((confirmed) => {
					if (confirmed) {
						this.close()
					}
				})
		},
		closed() {
			this.loading = false
			this.currentStep = 0
			this.seenSteps = []
		},
		opened() {
			this.productIds = []
			this.search = ""
			this.loading = true
			this.$store
				.dispatch("restaurantsModule/getRestaurantProducts")
				.finally(() => {
					this.loading = false
				})
		}
	}
}
</script>