<template>
	<div class="sk-table-row">
		<div :title="voucher.code" class="sk-table-column-fit">
			<div class="pill pill-grey">
				<i class="sk-icon-tag-solid"></i>
				{{ voucher.code }}
			</div>
		</div>
		<div :title="amount">{{ amount }}</div>
		<div :title="businessName">{{ businessName }}</div>
		<div title="Validity Dates">{{ validityDates }}</div>
		<div :title="payment">{{ payment }}</div>
		<div :title="distribution">{{ distribution }}</div>
			<div v-if="voucher.usage_limit > 0" :title="usageCount">
			{{ usageCount }}
		</div>
		<div v-else :title="usageCount">
			{{ voucher.num_of_uses }}/
			<i>&#8734;</i>
		</div>
		<div :title="valid" class="sk-table-column-fit">
			<span :class="statusClasses"></span>
			{{ statusLabel }}
		</div>
		<div class="sk-table-column-fit">
			<div class="sk-table-actions">
				<button
					class="button button-icon"
					@click="actionSelected({ id: 1 })"
					title="Edit"
				>
					<i class="sk-icon-pencil-solid"></i>
				</button>
				<SkDropdownSelect
					v-if="valid"
					:options="allowedActions"
					:iconClasses="'sk-icon-ellipsis-v-regular'"
					:showSelectedOption="false"
					:position="'left'"
					@selected="actionSelected"
				/>
			</div>
		</div>
	</div>
</template>

<style>
.pill i {
	margin-right: 10px;
}
</style>

<script>
import moment from "moment"
import "moment-timezone"
import { VoucherPaymentType } from "@/enums/voucherPaymentType"
import { VoucherDistributionType } from "@/enums/voucherDistributionType"
import { VoucherRequestStatus } from "@/enums/voucherRequestStatus"
import { PermissionMethod } from "@/utils/permissions"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"
import voucherRequestStatusMixin from "@/mixins/vouchers/voucher-request-status-mixin"

export default {
	name: "VoucherLineItem",
	mixins: [voucherRequestStatusMixin],
	components: {
		SkDropdownSelect
	},
	props: {
		voucher: Object
	},
	computed: {
		businessName() {
			if (this.voucher.business && this.voucher.business.name) {
				return this.voucher.business.name
			} else if (this.business && this.business != null) {
				return this.business.name
			}
			return ""
		},
		business() {
			let business_id = this.voucher.business
				? this.voucher.business.id
				: this.voucher.business_id
			if (business_id > 0) {
				return this.$store.getters.businessesMap[business_id]
			}
			return null
		},
		businessTimezone() {
			let timezone = ""
			if (this.business && this.business.id) {
				const fullBusiness =
					this.$store.getters.businessesMap[this.business.id]
				timezone =
					fullBusiness && fullBusiness.timezone
						? fullBusiness.timezone
						: ""
			}
			return timezone
		},
		amount() {
			var retVal = this.voucher.amount
			retVal = "$" + retVal
			return retVal
		},
		usageCount() {
			let limit
			if (this.voucher.usage_limit > 0) {
				limit = this.voucher.usage_limit
			}
			return this.voucher.num_of_uses + "/" + limit
		},
		validityDates() {
			let startDate = null
			let endDate = null
			if (this.voucher.valid_from) {
				startDate = this.voucher.valid_from
					? moment
							.tz(this.voucher.valid_from, this.businessTimezone)
							.format("MMM DD, YYYY")
					: null
			}

			if (this.voucher.valid_to) {
				endDate = this.voucher.valid_to
					? moment
							.tz(this.voucher.valid_to, this.businessTimezone)
							.format("MMM DD, YYYY")
					: null
			}

			if (startDate && endDate) {
				return `${startDate} - ${endDate}`
			} else if (endDate) {
				const createdDate = moment
					.tz(this.voucher.create_date, this.businessTimezone)
					.format("MMM DD, YYYY")
				return `${createdDate} - ${endDate}`
			}

			return "N/A"
		},
		voucherRequest() {
			return this.voucher && this.voucher.voucherRequest
				? this.voucher.voucherRequest
				: null
		},
		distribution() {
			if (this.voucherRequest) {
				switch (this.voucherRequest.distribution_type) {
					case VoucherDistributionType.PRINT_NOW:
						return "Printed"
					case VoucherDistributionType.SEND_TO_GUEST:
						return "Sent to Guest"
					case VoucherDistributionType.SEND_TO_ME:
						return "Send to Me"
					case VoucherDistributionType.DIGITAL_ONLY:
						return "N/A"
				}
			}
			return "N/A"
		},
		payment() {
			if (this.voucherRequest) {
				switch (this.voucherRequest.payment_type) {
					case VoucherPaymentType.PRE_PAID:
						return "Pre Paid"
					case VoucherPaymentType.PAY_AS_YOU_GO:
						return "Pay as you Go"
				}
			}
			return "N/A"
		},
		allowedActions() {
			let actions = []
			if (this.valid) {
				actions.push({
					text: `<i class="sk-icon-pencil-solid"></i> Edit`,
					id: 1
				})

				actions.push({
					text: `<i class="sk-icon-arrow-to-bottom-regular"></i> Print`,
					id: 2
				})

				actions.push({
					text: `<i class="sk-icon-envelope-solid"></i> Send to Guest`,
					id: 3
				})
			}
			return actions
		},
		valid() {
			if (
				!this.voucherRequest ||
				this.voucherRequest.status != VoucherRequestStatus.REQUESTED
			) {
				if (
					this.voucher.usage_limit == 0 ||
					this.voucher.num_of_uses < this.voucher.usage_limit
				) {
					return true
				}
			}
			return false
		},
		allowEdit() {
			return this.$store.state.permissions.includes(
				PermissionMethod.EDIT_VOUCHER
			)
		}
	},
	methods: {
		actionSelected(action) {
			switch (action.id) {
				case 1:
					this.$emit("updateVoucher", this.voucher)
					break
				case 2: {
					const businessId = this.voucher.business
						? this.voucher.business.id
						: this.voucher.business_id
					this.$emit("printVoucher", this.voucher.id, businessId)
					break
				}
				case 3: {
					this.$emit("sendVoucherEmail", this.voucher)
					break
				}
			}
		}
	}
}
</script>