
<template>
	<div class="sk-page">
		<transition-group :name="transitionName" tag="div" class="login-left">
			<LoginWidget
				v-if="displayingLoginWidget"
				class="login-widget"
				@forgotPassword="displayForgetPasswordWidget"
				@activateAccount="displayActivationWidget"
				:key="0"
			/>
			<OTPActivationWidget
				v-else-if="displayingActivationWidget"
				@backToLogin="displayLoginWidget"
				:key="1"
			/>
			<ForgotPasswordWidget
				class="forgot-password-widget"
				v-else
				@backToLogin="displayLoginWidget"
				:key="2"
			/>
			<div class="terms-link" :key="3">
				<router-link :to="`/privacy`" target="_blank">
					Privacy
				</router-link>
				&
				<router-link :to="`/terms`" target="_blank">Terms</router-link>
			</div>
		</transition-group>
		<LoginCarousel />
	</div>
</template>

<style scoped>
.login-widgets > * {
	backface-visibility: hidden;
	z-index: 1;
}

.sk-page {
	flex-direction: row;
}

.login-left {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}

.terms-link,
.terms-link:active,
.terms-link:hover {
	position: absolute;
	left: 0;
	bottom: 20px;
	width: 100%;
	text-align: center;
}

.terms-link,
.terms-link a {
	color: var(--sk-grey2);
	font-size: 12px;
}
</style>

<script>
import loaderMixin from "@/mixins/loader-mixin"
import LoginWidget from "@/components/login/LoginWidget.vue"
import ForgotPasswordWidget from "@/components/login/ForgotPasswordWidget.vue"
import OTPActivationWidget from "@/components/login/OTPActivationWidget.vue"
import LoginCarousel from "@/components/login/LoginCarousel.vue"

export default {
	name: "Login",
	mixins: [loaderMixin],
	components: {
		LoginWidget,
		ForgotPasswordWidget,
		LoginCarousel,
		OTPActivationWidget
	},
	data() {
		return {
			displayingLoginWidget: true,
			displayingActivationWidget: false
		}
	},
	mounted: function () {
		this.$store.dispatch("clearBusinesses")
		this.$store.dispatch("clearRestaurants")
	},
	computed: {
		loading() {
			return this.$store.state.login
		},
		transitionName() {
			return this.displayingLoginWidget ? "widgetexit" : "widgetenter"
		}
	},
	methods: {
		displayForgetPasswordWidget() {
			this.displayingLoginWidget = false
			this.displayingActivationWidget = false
		},
		displayLoginWidget() {
			this.displayingLoginWidget = true
			this.displayingActivationWidget = false
		},
		displayActivationWidget() {
			this.displayingLoginWidget = false
			this.displayingActivationWidget = true
		}
	},
	watch: {
		loading: function (newVal, oldVal) {
			if (newVal && !oldVal) {
				this.toggleLoader(true)
			} else if (!newVal && oldVal) {
				this.toggleLoader(false)
				this.$router.replace(this.$route.query.redirect || "/")
			}
		}
	}
}
</script>