import actions from "./actions"
import mutations from "./mutations"

const state = {
	loading: false,
	restaurant: null,
	restaurantAvgRating: null,
	todaysRestaurantMenus: [],
	openingHourExceptions: null,
	todaysBusinesses: {},
	todaysEvents: {},
	recentOrders: []
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}