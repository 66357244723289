<template>
	<div class="centered-tab-content">
		<div class="sk-row sk-widget-alt sk-widget-section">
			<div class="sk-widget-section-header">
				<div class="sk-widget-section-title">Bank Account</div>
				<div :class="linkedStatusBadgeClasses">
					<span :class="linkedStatusClasses"></span>
					{{ linkedStatusLabel }}
				</div>
			</div>
			<div v-if="!isLinked" class="sk-widget-section-subtitle">
				Link your bank account with 2ndKitchen.
				<br />
				We use Plaid for a 100% secure and 256-bit encrypted connection.
			</div>
			<slot v-else>
				<div class="sk-widget-section-subtitle">
					We use Plaid for a 100% secure and 256-bit encrypted
					connection.
				</div>
				<div class="sk-widget-section-detail">
					<div>Bank</div>
					<div>{{ account.bank_institution_name }}</div>
				</div>
				<div class="sk-widget-section-detail">
					<div>Account Name</div>
					<div>{{ account.bank_account_name }}</div>
				</div>
				<div
					v-if="account.bank_account_number != ''"
					class="sk-widget-section-detail"
				>
					<div>Account No.</div>
					<div>{{ account.bank_account_number }}</div>
				</div>
				<div class="sk-widget-section-detail">
					<div>Type</div>
					<div>{{ accountType }}</div>
				</div>
			</slot>
			<div class="sk-widget-section-footer">
				<slot v-if="isLinked">
					<div class="sk-switch-label-wrapper" @click="toggleAutoPay">
						<SkCheckbox :disabled="true" :value="autoPay" />
						<span>Auto Pay</span>
					</div>
					<button
						class="button button-text button-text-navy button-auto"
						@click="unlinkAccountClicked"
					>
						<i class="sk-icon-unlink-regular"></i>
						Unlink Account
					</button>
				</slot>
				<button
					v-else
					class="button button-primary button-small"
					@click="linkWithBankCredentials"
				>
					<i class="sk-icon-plus-regular"></i>
					Link Bank Account
				</button>
			</div>
		</div>
		<div class="sk-row sk-widget-alt sk-widget-section">
			<div class="sk-widget-section-header">
				<div class="sk-widget-section-title">W-9</div>
				<div :class="w9StatusBadgeClasses">
					<span :class="w9StatusClasses"></span>
					{{ w9StatusLabel }}
				</div>
			</div>
			<div class="sk-widget-section-subtitle">
				W-9s are required when payouts exceed $700
			</div>
			<div class="sk-widget-section-footer">
				<FileUpload
					v-if="!isW9Uploaded"
					:entity="'business_finance'"
					:entity_name="business.slug"
					:signingBaseUrl="secureSignatureUrl"
					@set="w9FileChanged"
					@loading="toggleLoader"
				>
					<button class="button button-primary button-small">
						<i class="sk-icon-plus-regular"></i>
						Upload W-9
					</button>
				</FileUpload>
				<slot v-else>
					<!-- <button
						class="button button-text button-text-navy button-auto"
						@click="downloadW9"
					>
						<i class="sk-icon-folder-download-regular"></i>
						Download W-9
					</button> -->
					<FileUpload
						v-if="isW9Uploaded"
						class="dropzone-button-text-container"
						:entity="'business_finance'"
						:entity_name="business.slug"
						:signingBaseUrl="secureSignatureUrl"
						@set="w9FileChanged"
						@loading="toggleLoader"
					>
						<button
							class="button button-text button-text-navy button-auto"
						>
							<i class="sk-icon-folder-upload-regular"></i>
							Reupload W-9
						</button>
					</FileUpload>
				</slot>
			</div>
		</div>
		<div class="sk-row sk-widget-alt sk-widget-section">
			<div class="sk-widget-section-header">
				<div class="sk-widget-section-title">Next Invoice</div>
			</div>
			<div class="sk-widget-section-detail">
				<div>Invoice Date (Monthly):</div>
				<div>{{ nextInvoiceDate }}</div>
			</div>
			<div class="sk-widget-section-detail">
				<div>Invoice Amount:</div>
				<div>{{ nextInvoiceAmount | currency }}</div>
			</div>
			<div class="sk-widget-section-footer">
				<button
					class="button button-text button-text-navy button-auto"
					@click="invoiceHistoryClicked"
				>
					<i class="sk-icon-file-invoice-dollar-solid"></i>
					View Invoice History
				</button>
			</div>
		</div>
		<ConfirmationModal ref="removeSourceConfirmationModal" />
	</div>
</template>

<style scoped>
.sk-widget-section-footer
	> .button:not(.button-text)
	+ .button:not(.button-text) {
	margin-left: 15px;
}

.sk-widget-section-footer > .button-text + .button-text,
.sk-widget-section-footer > .button-text + .dropzone-message {
	margin-left: 40px;
}

.sk-widget-section-footer
	>>> .dropzone-button-text-container.dropzone-message
	> div {
	height: 23px;
}

.sk-widget-section-footer > .sk-switch-label-wrapper {
	margin-right: 40px;
	font-size: 12px;
	font-weight: 600;
	color: var(--sk-dark-navy);
	cursor: pointer;
}
</style>

<script>
import { SECURE_SIGNATURE_API } from "@/services/utils"
import { ConfirmationModalType } from "@/enums/confirmationModalType"
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import ActionTypes from "@/store/modules/finance/action-types"
import moment from "moment"
import loaderMixin from "@/mixins/loader-mixin"
import currencyFilterMixin from "@/mixins/currency-filter-mixin"
import plaidMixin from "@/mixins/finance/plaid-mixin"
import financeDetailsDisplayMixin from "@/mixins/finance/finance-details-display-mixin"
import FileUpload from "@/components/FileUpload.vue"
import SkCheckbox from "@/components/SkCheckbox.vue"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue"

export default {
	name: "InvoiceDetailsBasicInfo",
	mixins: [
		currencyFilterMixin,
		loaderMixin,
		plaidMixin,
		financeDetailsDisplayMixin
	],
	components: {
		FileUpload,
		SkCheckbox,
		ConfirmationModal
	},
	mounted: function () {
		this.initPlaid()
	},
	data: function() {
		return {
			secureSignatureUrl: SECURE_SIGNATURE_API
		}
	},
	computed: {
		autoPay() {
			return this.account.auto_pay
		},
		business() {
			return this.$store.getters.businessesMap[this.$route.params.entity_id]
		},
		isW9Uploaded() {
			return this.business != null
				? this.business.w9_uploaded == 1
				: false
		},
		nextInvoiceDate() {
			let nextInvoice = ""
			const now = moment()
			const dayOfMonth = now.date()
			if (dayOfMonth == 1) {
				nextInvoice = "Today"
			} else {
				const nextMonth = now.add(1, "month").startOf("month")
				nextInvoice = nextMonth.format("MMM Do, YYYY")
			}
			return nextInvoice
		},
		nextInvoiceAmount() {
			return this.financeDetails.amount != 0
				? this.financeDetails.amount
				: "N/A"
		}
	},
	methods: {
		toggleAutoPay() {
			this.$store.dispatch(
				`financeModule/${ActionTypes.TOGGLE_FUNDING_SOURCE_AUTO_PAY}`,
				{
					fundingSource: this.account
				}
			)
		},
		onPlaidSuccess(public_token, metadata) {
			this.$store.dispatch(
				`financeModule/${ActionTypes.ADD_FUNDING_SOURCE}`,
				{
					entityId: this.business.id,
					entityType: 1,
					validationType: 2,
					token: public_token,
					metadata: metadata,
					autoPay: 1
				}
			)
		},
		downloadW9() {
			// utils.downloadFile(
			// 	`${this.$financeS3URL}businesses/${this.business.slug}/w9.pdf`
			// )
		},
		invoiceHistoryClicked() {
			this.$emit("showInvoiceHistory")
		},
		linkWithBankCredentials() {
			this.plaid_handler.open()
		},
		async unlinkAccountClicked() {
			const confirmed = await this.$refs.removeSourceConfirmationModal.open(
				{
					title: `Are you sure you want to unlink your funding account?`,
					confirmation: "Unlink Account",
					confirmationIcon: "sk-icon-trash-alt-solid",
					cancel: "Keep Account",
					type: ConfirmationModalType.RED
				}
			)
			if (confirmed) {
				this.$store.dispatch(
					`financeModule/${ActionTypes.REMOVE_FUNDING_SOURCE}`,
					this.account.id
				)
			}
		},
		async w9FileChanged() {
			try {
				this.toggleLoader(true)
				const w9Uploaded = await this.$store.dispatch(
					"businessesModule/setW9Uploaded",
					this.business.id
				)
				if (w9Uploaded) {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "W9 Upload",
						message: "Successfully uploaded the W9 file"
					})
				}
				this.toggleLoader(false)
			} catch (e) {
				this.toggleLoader(false)
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "W9 Upload",
						message: "Failed to upload the W9 file, please try again",
						type: NotificationType.ERROR
					})
				throw e
			}
		}
	}
}
</script>