import Vue from "vue"
import auth from "@/utils/auth"
import tracker from "@/utils/tracker"
import { store } from "@/store/store.js"
import ActionTypes from "@/store/modules/events/action-types"
import PricingActionTypes from "@/store/modules/coupons/action-types"
import actionTypes from "@/store/modules/finance/action-types"
import { RouteName } from "@/router"
import { EntityType } from '@/enums/entityType'

const vue = new Vue()

export const PermissionGuardMode = {
  "ALL": 0,
  "ONE": 1
}

function hideLoader(loader) {
  if (loader) {
    loader.hide()
  }
  return null
}

export default {
  multiGuard(guards) {
    return async (to, from, next) => {
        let changed = false

        const mNext = function (value) {
            if(changed) return

            if(typeof value != "undefined") {
                changed = true
                next(value)
            }
        }

        for (let i = 0; i < guards.length; i++) {
            if(changed) break;
            await guards[i](to, from, mNext)
        }

        if(!changed) next()
    }
  },
  requirePermission(to, from, next) {
    if (!to.meta.permission) {
      return next({
        path: '/'
      })
    }
    const permissionGranted = store.state.permissions.includes(to.meta.permission)
    if (permissionGranted) {
      next()
    } else {
      next({
        path: '/'
      })
    }
  },
  requirePermissions(to, from, next) {
    const permissions = to.meta.permissions
    const mode = to.meta.permissionMode
    let permissionGranted = false
    if (!mode || mode == PermissionGuardMode.ALL) {
      permissionGranted = permissions.every(permission => store.state.permissions.includes(permission))
    } else if (mode == PermissionGuardMode.ONE) {
      permissionGranted = permissions.some(permission => store.state.permissions.includes(permission))
    }
    if (permissionGranted) {
      next()
    } else {
      next({
        path: '/'
      })
    }
  },
  requireAuth(to, from, next) {
    tracker.track(to)
    const loggedIn = auth.loggedIn()
    if (loggedIn) {
      next()
    } else {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    }
  },
  requireLoggedOut(to, from, next) {
    const loggedIn = auth.loggedIn()
    if (loggedIn) {
      next({
        path: '/'
      })
    } else {
      next()
    }
  },
  async requireCurrentRestaurant(to, from, next) {
    // eslint-disable-next-line no-unused-vars
    let loader = null
    try {
      const currentRestaurant = store.state.restaurantsModule.currentRestaurant
      const fromRestaurant = from.name == RouteName.RESTAURANT
      if (!fromRestaurant || !currentRestaurant ||  currentRestaurant == undefined != to.params.rest_slug) {
        loader = Vue.$loading.show()
        const result = await store.dispatch("restaurantsModule/getRestaurant", to.params.rest_slug)
        if (result) {
          loader = hideLoader(loader)
          next()
        } else {
          throw "Unable to fetch current restaurant"
        }
      }
    } catch (e) {
      loader = hideLoader(loader)
      next({
        path: "/restaurants"
      })
    }
  },
  async requireCurrentBusiness(to, from, next) {
    // eslint-disable-next-line no-unused-vars
    let loader = null
    try {
      const currentBusiness = store.state.businessesModule.currentBusiness
      const fromBusiness = from.name == RouteName.BUSINESS
      if (!fromBusiness || !currentBusiness || currentBusiness.slug != to.params.business_slug) {
        loader = Vue.$loading.show()
        const result = await store.dispatch("businessesModule/getBusiness", to.params.business_slug)
        if (result) {
          loader = hideLoader(loader)
          next()
        } else {
          throw "Unable to fetch current business"
        }
      } else {
        next()
      }
    } catch (e) {
      loader = hideLoader(loader)
      next({
        path: "/businesses"
      })
    }
  },
  async requireCurrentFinanceDetails(to, from, next) {
    try {
      if (to.params.entity_id == undefined) {
        throw "Invalid entity Id"
      }
      let fetchFinanceDetails = false
      const currentFinanceDetails = store.state.financeModule.currentFinanceDetails
      if (currentFinanceDetails == undefined ||  currentFinanceDetails.funding_source == undefined) {
        fetchFinanceDetails = true
      } else if (currentFinanceDetails.funding_source.entity_id != to.params.entity_id) {
        fetchFinanceDetails = true
      }
      if (fetchFinanceDetails) {
        const action = to.path.includes("business") ? actionTypes.GET_CURRENT_BUSINESS_FINANCE_DETAILS : actionTypes.GET_CURRENT_RESTAURANT_FINANCE_DETAILS
        const result = await store.dispatch(`financeModule/${action}`, to.params.entity_id)
        if (result) {
          next()
        } else {
          throw "Unable to fetch current finance details"
        }
      } else {
        next()
      }
      return true
    } catch (e) {
      next({
        path: "/finance"
      })
      return false
    }
  },
  async requireValidEvent(to, from, next) {
    try {
      const event = await store.dispatch(`eventsModule/${ActionTypes.GET_EVENT}`, to.params.id)
      if (!to.params.id) {
        next()
        return
      }
      if (event) {
        next()
        return 
      }
      next({
        path: "/events"
      })
    } catch (e) {
      next({
        path: "/events"
      })
    }
  },
  async requireValidVoucherRequest(to, from, next) {
    try {
      const voucher_request = await store.dispatch(`couponsModule/${PricingActionTypes.GET_VOUCHER_REQUEST}`, to.params.id)
      if (store.state.couponsModule.voucherRequests.length == 0){
        await store.dispatch(`couponsModule/${PricingActionTypes.GET_VOUCHER_REQUESTS}`)
      }
      if (!to.params.id) {
        next()
        return
      }
      if (voucher_request) {
        next()
        return 
      }
      next({
        path: "/promotions"
      })
    } catch (e) {
      next({
        path: "/promotions"
      })
    }
  },
  async requireRestoredStorage(to, from, next) {
    await store.restored
    next()
  },
  async requireEntity(to, from, next) {
    try {
      if (store.getters.isSuperAdmin) {
        next()
        return
      }
      switch (to.meta.entityType) {
        case EntityType.BUSINESS: {
          if (store.getters.isRestaurantAdmin) {
            throw "Entity not allowed"
          }
          const business = store.getters.ownedBusinesses.find(business => business[to.meta.entityKey] == to.params[to.meta.entityParamsKey])
          if (business) {
            next()
          } else {
            throw "Entity not allowed"
          }
          break
        }
        case EntityType.RESTAURANT: {
          if (store.getters.isBusinessAdmin) {
            throw "Entity not allowed"
          }
          const restaurant = store.getters.ownedRestaurants.find(restaurant => restaurant[to.meta.entityKey] == to.params[to.meta.entityParamsKey])
          if (restaurant) {
            next()
          } else {
            throw "Entity not allowed"
          }
          break
        }
        default: {
          throw "Unknown entity"
        }
      }
    } catch (e) {
      next(from)
      vue.bugsnag.notify(
        "Required entity not found",
        event => {
            event.addMetadata("error", {
                error: e,
                meta: to.meta,
                params: to.params
            })
        }
    )
    }
  }
}