<template>
	<SkBaseModal
		class="sk-modal-alt vld-parent"
		:isOpen="isOpen"
		@close="close"
	>
		<loading :active.sync="loading" :is-full-page="false" />
		<div class="sk-modal-alt-title">
			<h1>Special Closure Hours</h1>
		</div>
		<div class="sk-row">
			<SkInput
				v-model="title"
				ref="titleInput"
				:required="true"
				:name="'Title / Closure Reason'"
			/>
		</div>
		<div class="sk-row">
			<VueCtkDateTimePicker
				:label="'Start Date'"
				:no-clear-button="true"
				:no-label="true"
				:no-button-now="true"
				:only-date="true"
				:button-color="'#90DA36'"
				:format="'MM-DD-YYYY'"
				:formatted="'ll'"
				:color="'#2E3C49'"
				:output-format="'YYYY-MM-DD'"
				:min-date="minStartDate"
				v-model="startDate"
			/>
			<VueCtkDateTimePicker
				:label="'End Date'"
				:no-clear-button="true"
				:no-label="true"
				:no-button-now="true"
				:only-date="true"
				:button-color="'#90DA36'"
				:format="'MM-DD-YYYY'"
				:formatted="'ll'"
				:color="'#2E3C49'"
				:output-format="'YYYY-MM-DD'"
				:min-date="startDate"
				v-model="endDate"
			/>
		</div>
		<h2>Hours Closed</h2>
		<div class="sk-row">
			<div class="sk-radio-group">
				<SkRadio :option="1" v-model="closedAllDay" />
				<span>Closed All Day</span>
			</div>
		</div>
		<div class="sk-row">
			<div class="sk-radio-group">
				<SkRadio :option="0" v-model="closedAllDay" />
				<span>Open for Specific Hours</span>
			</div>
		</div>
		<div class="sk-row" v-if="!closedAllDay">
			<VueCtkDateTimePicker
				:label="'Start Time'"
				:no-clear-button="true"
				:no-label="true"
				:no-button-now="true"
				:only-time="true"
				:minute-interval="15"
				:button-color="'#90DA36'"
				:format="'hh:mm A'"
				:formatted="'LT'"
				:color="'#2E3C49'"
				:output-format="'hh:mm A'"
				:min-date="minStartDate"
				v-model="startTime"
			/>
			<VueCtkDateTimePicker
				:label="'End Time'"
				:no-clear-button="true"
				:no-label="true"
				:no-button-now="true"
				:only-time="true"
				:minute-interval="15"
				:button-color="'#90DA36'"
				:format="'hh:mm A'"
				:formatted="'LT'"
				:color="'#2E3C49'"
				:output-format="'hh:mm A'"
				:min-date="minStartDate"
				v-model="endTime"
			/>
		</div>
		<div class="sk-modal-actions">
			<button class="button button-primary" @click="saveException">
				Save Closure Hours
			</button>
		</div>
	</SkBaseModal>
</template>

<style scoped>
h2 {
	color: var(--sk-navy);
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 25px;
	margin-top: 25px;
}

* >>> .sk-radio {
	width: 15px;
	height: 15px;
	margin-right: 10px;
}

.sk-modal-actions {
	justify-content: center;
}
</style>

<script>
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import moment from "moment"
import Loading from "vue-loading-overlay"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import SkModalMixin from "@/mixins/modals/sk-modal-mixin"
import SkInput from "@/components/SkInput.vue"
import SkRadio from "@/components/SkRadio.vue"
import VueCtkDateTimePicker from "vue-ctk-date-time-picker"

export default {
	name: "RestaurantHoursExceptionModal",
	mixins: [SkModalMixin],
	components: {
		Loading,
		SkBaseModal,
		SkInput,
		SkRadio,
		VueCtkDateTimePicker
	},
	data: function () {
		return {
			loading: false,
			title: "",
			id: null,
			startDate: null,
			endDate: null,
			startTime: null,
			endTime: null,
			closedAllDay: 1,
			minStartDate: moment().format("YYYY-MM-DD"),
			restaurant: null
		}
	},
	computed: {
		offset() {
			return this.restaurant ? this.restaurant.timezone_offset : 0
		}
	},
	methods: {
		handleOptions(options) {
			this.restaurant = options.restaurant
				? options.restaurant
				: this.$store.state.restaurantsModule.currentRestaurant
			if (options.id) {
				this.title = options.title
				this.id = options.id
				if (options.open_time) {
					const openMoment = this.momentWithOffset(
						options.start_date,
						options.open_time,
						this.offset
					)
					const closeMoment = this.momentWithOffset(
						options.end_date,
						options.close_time,
						this.offset
					)
					this.startDate = openMoment.format("YYYY-MM-DD")
					this.endDate = closeMoment.format("YYYY-MM-DD")
					this.startTime = openMoment.format("HH:mm:ss")
					this.endTime = closeMoment.format("HH:mm:ss")
					this.closedAllDay = 0
				} else {
					this.startDate = options.start_date
					this.endDate = options.end_date
					this.startTime = null
					this.endTime = null
					this.closedAllDay = 1
				}
			}
		},
		closed() {
			this.loading = false
			this.title = ""
			this.id = null
			this.startDate = null
			this.endDate = null
			this.startTime = null
			this.endTime = null
			this.closedAllDay = 1
			this.restaurant = null
		},
		momentWithOffset: function (date, time, offset) {
			if (!date) return ""
			return moment
				.utc(`${date} ${time}`, "YYYY-MM-DD HH:mm:ss")
				.add(offset, "m")
		},
		utcMomentFromLocal: function (date, time, offset) {
			if (!date) return ""
			return moment
				.utc(`${date} ${time}`, "YYYY-MM-DD h:mm A")
				.subtract(offset, "m")
		},
		validateData() {
			if (!this.$refs.titleInput.validate()) {
				throw "Please enter a valid title"
			}
			if (!this.startDate) {
				throw "Please enter a valid start date"
			}
			if (!this.closedAllDay) {
				if (!this.startTime) {
					throw "Please enter a valid open time"
				}
				if (!this.endTime) {
					throw "Please enter a valid close time"
				}
			}
			return true
		},
		async saveException() {
			try {
				this.validateData()
				this.loading = true

				const data = {
					title: this.title,
					is_open: 0
				}

				const endDate = this.endDate ? this.endDate : this.startDate
				if (!this.closedAllDay) {
					const openMoment = this.utcMomentFromLocal(
						this.startDate,
						this.startTime,
						this.offset
					)
					const closeMoment = this.utcMomentFromLocal(
						endDate,
						this.endTime,
						this.offset
					)

					data.start_date = openMoment.format("YYYY-MM-DD")
					data.end_date = closeMoment.format("YYYY-MM-DD")
					data.open_time = openMoment.format("HH:mm:ss")
					data.close_time = closeMoment.format("HH:mm:ss")
					data.is_open = 1
				} else {
					data.start_date = this.startDate
					data.end_date = endDate
					data.open_time = null
					data.close_time = null
				}

				let success = false
				if (this.id) {
					success = await this.$store.dispatch(
						"restaurantsModule/updateRestaurantHoursException",
						{
							exception: data,
							id: this.id
						}
					)
				} else {
					success = await this.$store.dispatch(
						"restaurantsModule/addRestaurantHoursException",
						{
							restaurantId: this.restaurant.id,
							exception: data
						}
					)
				}
				if (!success) {
					throw "Something went wrong, please try again"
				}
				this.loading = false
				this.close()
			} catch (e) {
				this.loading = false
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: e.message ? e.message : e,
					type: NotificationType.WARNING
				})
			}
		}
	}
}
</script>