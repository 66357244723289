<template>
	<SkBaseModal
		class="sk-modal-alt"
		:isOpen="isOpen"
		:hasLoader="true"
		@close="close"
	>
		<loading :active.sync="loading" :is-full-page="false"></loading>
		<button class="button button-icon button-close" @click="close">
			<i class="sk-icon-times-regular"></i>
		</button>
		<div class="sk-modal-alt-title">
			<h1>Invoice Review & Payment</h1>
		</div>
		<SetupReviewStep
			:stepName="'Payment Breakdown'"
			:stepId="0"
			:showCompletion="false"
			:showProgress="false"
			:reviewingStepId="reviewingStepId"
			:openByDefault="true"
			:editable="false"
		>
			<div
				class="setup-review-step-detail"
				v-for="module in modules"
				:key="module.id"
			>
				<div>{{ module.name }}</div>
				<div>
					<b>{{ module.amount }}</b>
				</div>
			</div>
			<div class="setup-review-step-detail invoice-total">
				<div>Invoice Total</div>
				<div>
					<b>{{ invoiceTotal }}</b>
				</div>
			</div>
		</SetupReviewStep>
		<SetupReviewStep
			:stepName="'Details'"
			:stepId="0"
			:showCompletion="false"
			:showProgress="false"
			:reviewingStepId="reviewingStepId"
			:openByDefault="true"
			:editable="false"
		>
			<div class="setup-review-step-detail">
				<div>Invoice Period</div>
				<div>
					<b>{{ invoicePeriod }}</b>
				</div>
			</div>
			<div class="setup-review-step-detail">
				<div>Bank</div>
				<div>
					<b v-sk-unassigned="fundingSourceDetails.bankName" />
				</div>
			</div>
			<div class="setup-review-step-detail">
				<div>Account</div>
				<div>
					<b v-sk-unassigned="fundingSourceDetails.accountNumber" />
				</div>
			</div>
			<div class="setup-review-step-detail">
				<div>Type</div>
				<div>
					<b v-sk-unassigned="fundingSourceDetails.accountType" />
				</div>
			</div>
			<div class="setup-review-step-detail">
				<div>Method</div>
				<div>
					<b v-sk-unassigned="fundingSourceDetails.method" />
				</div>
			</div>
		</SetupReviewStep>
		<div v-if="showAutoPay" class="sk-row sk-widget-alt sk-widget-section">
			<div class="auto-pay-title">
				Setup Auto Pay for future invoice payments?
			</div>
			<div class="sk-switch-label-wrapper">
				<SkCheckbox v-model="enableAutoPay" />
				<span>Auto Pay</span>
			</div>
		</div>
		<div v-if="isPayable" class="sk-modal-actions">
			<button @click="close" class="button button-text">Cancel</button>
			<button
				type="button"
				class="button button-primary"
				@click="payInvoice"
			>
				Pay Invoice
			</button>
		</div>
	</SkBaseModal>
</template>

<style scoped>
.invoice-total {
	border-top: 1px solid var(--sk-grey);
	padding-top: 10px;
}

.setup-review-step-detail:not(:last-child) {
	margin-bottom: 15px;
}

.auto-pay-title {
	font-size: 12px;
	color: var(--sk-grey2);
}

.sk-switch-label-wrapper {
	margin-top: 12px !important;
}

.sk-switch-label-wrapper span {
	font-size: 12px;
	font-weight: 600;
	color: var(--sk-dark-navy);
}

.button-primary {
	width: 190px;
}
</style>

<script>
import moment from "moment"
import search_service from "@/services/search"
import { InvoiceModuleId } from "@/enums/invoiceModuleId"
import { FundingSource } from "@/enums/fundingSource"
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import { InvoiceStatus } from "@/enums/invoiceStatus"
import ActionTypes from "@/store/modules/finance/action-types"
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import Loading from "vue-loading-overlay"
import SetupReviewStep from "@/components/modals/SetupReviewStep.vue"
import SkCheckbox from "@/components/SkCheckbox.vue"

export default {
	name: "InvoiceDetailsModal",
	mixins: [skModalMixin],
	components: {
		SkBaseModal,
		Loading,
		SetupReviewStep,
		SkCheckbox
	},
	props: {
		allowPayment: {
			type: Boolean,
			default: true
		}
	},
	data: function () {
		return {
			loading: false,
			enableAutoPay: false,
			invoice: null,
			fundingSource: null,
			reviewingStepId: null
		}
	},
	computed: {
		isPayable() {
			return (
				this.allowPayment &&
				this.invoice &&
				this.fundingSource &&
				this.invoice.status.value == InvoiceStatus.APPROVED
			)
		},
		showAutoPay() {
			return (
				this.isPayable &&
				this.fundingSource &&
				this.fundingSource.auto_pay != 1
			)
		},
		modules() {
			if (this.invoice) {
				let allowedModuleIds = [
					InvoiceModuleId.PAY_AS_YOU_GO_VOUCHER_USE,
					InvoiceModuleId.PRE_PAID_VOUCHER_REQUEST,
					InvoiceModuleId.ADMIN_ORDER
				]
				return this.invoice.modules
					.filter((module) => allowedModuleIds.includes(module.id))
					.map((module) => {
						return {
							name: this.nameFromModuleId(module.id),
							amount: module.amount
						}
					})
			}
			return []
		},
		invoiceTotal() {
			if (this.invoice) {
				return (
					parseFloat(this.invoice.amount) -
					parseFloat(this.invoice.credits)
				)
			}
			return 0
		},
		invoicePeriod() {
			if (this.invoice) {
				const from = moment
					.utc(this.invoice.from_date)
					.format("MMMM D, YYYY")
				const to = moment
					.utc(this.invoice.to_date)
					.format("MMMM D, YYYY")
				return `${from} - ${to}`
			}
			return ""
		},
		fundingSourceDetails() {
			if (this.fundingSource) {
				let fundingSourceMethod = "N/A"
				if (this.fundingSource.type == FundingSource.ACH) {
					fundingSourceMethod = "ACH"
				}
				return {
					bankName: this.fundingSource.bank_institution_name,
					accountNumber: `-${this.fundingSource.bank_account_number}`,
					accountType: this.fundingSource.bank_account_type,
					method: fundingSourceMethod
				}
			}
			return {}
		}
	},
	methods: {
		nameFromModuleId(id) {
			switch (id) {
				case InvoiceModuleId.PAY_AS_YOU_GO_VOUCHER_USE:
					return "Voucher Usage"
				case InvoiceModuleId.PRE_PAID_VOUCHER_REQUEST:
					return "Prepaid Vouchers"
				case InvoiceModuleId.ADMIN_ORDER:
					return "Breakfast Boxes"
			}
			return ""
		},
		async handleOptions(options) {
			try {
				if (options.invoice) {
					this.invoice = options.invoice
				} else if (options.invoiceId) {
					this.loading = true
					const invoiceResponse = await search_service.getBusinessBillingInvoices(
						{
							filters: {
								ids: [options.invoiceId]
							}
						}
					)
					if (
						invoiceResponse.status == 200 &&
						invoiceResponse.data.billing_collections.length > 0
					) {
						this.invoice =
							invoiceResponse.data.billing_collections[0]
					}
					this.loading = false
				}
				this.setFundingSource()
			} catch (e) {
				this.loading = false
				this.close()
				throw e
			}
		},
		async setFundingSource() {
			try {
				if (this.invoice.funding_source) {
					const currentFinanceDetails = this.$store.state
						.financeModule.currentFinanceDetails
					if (
						currentFinanceDetails.funding_source &&
						this.invoice.funding_source.id ==
							currentFinanceDetails.funding_source.id
					) {
						this.fundingSource =
							currentFinanceDetails.funding_source
					} else {
						this.loading = true
						this.fundingSource = await this.$store.dispatch(
							`financeModule/${ActionTypes.GET_FUNDING_SOURCE}`,
							this.invoice.funding_source.id
						)
						this.loading = false
					}
				}
				return true
			} catch (e) {
				this.loading = false
				throw e
			}
		},
		payInvoice() {
			this.loading = true
			const payload = {
				invoice: this.invoice
			}

			if (this.enableAutoPay) {
				payload.autoPay = true
			}

			this.$store
				.dispatch(
					`financeModule/${ActionTypes.PAY_BUSINESS_INVOICE}`,
					payload
				)
				.then((success) => {
					if (success) {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Invoice",
							message: "Invoice Paid"
						})
						this.close()
					} else {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Invoice",
							message: "Failed to pay invoice, please try again.",
							type: NotificationType.ERROR
						})
					}
				})
				.finally(() => {
					this.loading = false
				})
		},
		closed() {
			this.reviewingStepId = null
			this.fundingSource = null
			this.invoice = null
		}
	}
}
</script>