import { IntegrationType } from '@/enums/integrationType';
import square from "./square"

export default {
    authenticate(integration) {
        switch (integration.type) {
            case IntegrationType.SQUARE:
                return square.authorize(["ORDERS_READ", "ITEMS_READ", "MERCHANT_PROFILE_READ", "ORDERS_WRITE", "PAYMENTS_WRITE"])
        }
        return null
    }
}