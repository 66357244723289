import Vue from "vue"
import { store } from "@/store/store"
import { parseResponse } from "@arikgaisler/utils/api-utils"

let host = process.env.VUE_APP_API_HOST

const BASE_API = `https://orders${host}.2ndkitchen.com`

export default {
    async getCurrentOrders() {
        return Vue.axios.get(`${BASE_API}/orders/current`)
    },
    async getActiveOrders(payload) {
        //V2 of getCurrentOrders
        const tokenStr = 'Bearer ' + store.state.user_token
        return Vue.axios.get(`${BASE_API}/orders/active`, { "headers": { "Authorization": tokenStr },  "params": payload})
    },
    async getEventOrders(event_id) {
        return parseResponse(Vue.axios.get(`${BASE_API}/events/${event_id}/orders`))
    },
    async getOrderStatus(order_id) {
        return Vue.axios.get(`${BASE_API}/order/${order_id}`)
    },
    async updateOrderStatus(order_id, status, payload) {
        return parseResponse(Vue.axios.put(`${BASE_API}/orders/${order_id}/status/${status}`, payload))
    },
    async updateDeliveryStatus(order_id, payload) {
        return Vue.axios.put(`${BASE_API}/orders/${order_id}/delivery/3/status`, payload)
    },
    async addException(order_id, payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.post(`${BASE_API}/orders/${order_id}/order_exceptions`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async updateException(exception_id, payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.put(`${BASE_API}/order_exceptions/${exception_id}`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getOrdersExceptions(order_ids) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.get(`${BASE_API}/orders/order_exceptions?order_ids=${order_ids}`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getExceptionTypes() {
        return Vue.axios.get(`${BASE_API}/order_exception_reasons`)
    },
    async claimOrder(order_id, payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return Vue.axios.post(`${BASE_API}/orders/${order_id}/claim`, payload, { 'headers': { 'Authorization': tokenStr } })
    },
    async updateSupervisor(order_id, payload) {
        return Vue.axios.post(`${BASE_API}/orders/${order_id}/supervisor`, payload)
    },
    async updateSuppress(order_id, payload) {
        return Vue.axios.put(`${BASE_API}/orders/${order_id}/suppress`, payload)
    },
    async sendOrderSurvey(order_id) {
        return parseResponse(Vue.axios.post(`${BASE_API}/orders/${order_id}/survey`))
    },
    async setExceptionStatus(exception_id, payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return Vue.axios.put(`${BASE_API}/exceptions/${exception_id}/status`, payload, { 'headers': { 'Authorization': tokenStr } })
    },
    async batchSetExceptionStatus(payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.put(`${BASE_API}/exceptions/status/batch`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async addOrderNote(order_id, payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return Vue.axios.post(`${BASE_API}/orders/${order_id}/notes`, payload, { 'headers': { 'Authorization': tokenStr } })
    },
    async getOrdersNotes(order_ids) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.get(`${BASE_API}/notes?order_ids=${order_ids}`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async addOrderWarning(order_id, payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.post(`${BASE_API}/orders/${order_id}/warnings`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getOrderWarnings(order_id) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.get(`${BASE_API}/orders/${order_id}/warnings`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getOrdersWarnings(order_ids) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.get(`${BASE_API}/warnings?order_ids=${order_ids}`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async requestPrepackages(payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return Vue.axios.post(`${BASE_API}/admin_orders/request`, payload, { 'headers': { 'Authorization': tokenStr } })
    },
    async getPrepackagedManifest(payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return Vue.axios.post(`${BASE_API}/admin_orders/generate_manifest`, payload, { 'headers': { 'Authorization': tokenStr } })
    },
    async searchAdminOrders(payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.post(`${BASE_API}/admin_orders/search`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async addAdminOrders(payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.post(`${BASE_API}/admin_orders`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async updateAdminOrder(order_id, payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.put(`${BASE_API}/admin_orders/${order_id}`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async cancelAdminOrder(order_id) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return Vue.axios.put(`${BASE_API}/admin_orders/${order_id}/cancel`, {}, { 'headers': { 'Authorization': tokenStr } })
    },
    async duplicateOrder(order_id) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return Vue.axios.post(`${BASE_API}/orders/${order_id}/duplicate`, {}, { 'headers': { 'Authorization': tokenStr } })
    }
}