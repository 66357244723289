import { EntityType } from "@/enums/entityType"
import { UserRole } from "@/enums/userRole"
import { BusinessType } from "@arikgaisler/utils/enums/businessType"

export default {
	tipsEnabled: (state) => {
		let enabled = false
		for (let business in state.businesses) {
			if (state.businesses[business].tip_commission > 0) {
				enabled = true
				break
			}
		}
		return enabled
	},
	isSuperAdmin: (state) => {
		return state.user_role == UserRole.SUPER_ADMIN
	},
	isBusinessAdmin: (state) => {
		return state.user_role == UserRole.BUSINESS_ADMIN
	},
	isRestaurantAdmin: (state) => {
		return state.user_role == UserRole.RESAURANT_ADMIN
	},
	isLoggedIn: (state) => {
		return state.user.id != undefined
	},
	ownsMultipleEntities: (state, getters) => {
		if (getters.isSuperAdmin) {
			return true
		} else {
			return state.user_entities.length > 1
		}
	},
	hasHotel: (state, getters) => {
		if (getters.isSuperAdmin) {
			return true
		} else {
			return getters.ownedBusinesses.some(
				(business) => business.type == BusinessType.HOTEL
			)
		}
	},
	hasDrinkingPlace: (state, getters) => {
		if (getters.isSuperAdmin) {
			return true
		} else {
			return getters.ownedBusinesses.some(
				(business) => business.type == BusinessType.BAR
			)
		}
	},
	hasCoworking: (state, getters) => {
		if (getters.isSuperAdmin) {
			return true
		} else {
			return getters.ownedBusinesses.some(
				(business) => business.type == BusinessType.COWORKING
			)
		}
	},
	hasAirport: (state, getters) => {
		if (getters.isSuperAdmin) {
			return true
		} else {
			return getters.ownedBusinesses.some(
				(business) => business.type == BusinessType.AIRPORT
			)
		}
	},
	ownedBusinesses: (state, getters) => {
		if (getters.isSuperAdmin) {
			return Object.values(state.businesses)
		}
		return state.user_entities
			.filter(
				(entity) => entity.type == 1 && getters.businessesMap[entity.id]
			)
			.map((entity) => {
				return getters.businessesMap[entity.id]
			})
	},
	business: (state, getters) => {
		if (state.user_entities.length > 0) {
			const businessEntity = state.user_entities.find(
				(entity) => entity.type == EntityType.BUSINESS
			)
			if (businessEntity) {
				return getters.businessesMap[businessEntity.id]
			}
		} else if (state.businesses) {
			let businesses = Object.values(state.businesses)
			return businesses.length > 0 ? businesses[0] : null
		}
		return null
	},
	ownedRestaurants: (state, getters) => {
		if (getters.isSuperAdmin) {
			return Object.values(state.restaurants)
		}
		return state.user_entities
			.filter(
				(entity) => entity.type == 2 && state.restaurants[entity.id]
			)
			.map((entity) => {
				return state.restaurants[entity.id]
			})
	},
	restaurant: (state) => {
		if (state.user_entities.length > 0) {
			const restaurantEntity = state.user_entities.find(
				(entity) => entity.type == EntityType.RESTAURANT
			)
			if (restaurantEntity) {
				return state.restaurants[restaurantEntity.id]
			}
		} else if (state.restaurants) {
			let restaurants = Object.values(state.restaurants)
			return restaurants.length > 0 ? restaurants[0] : null
		}
		return null
	},
	businessRestaurantPairings: (state) => {
		const pairings = {}
		Object.values(state.businesses).forEach((business) => {
			if (business.menus && business.menus.length > 0) {
				business.menus.map((menu) => {
					if (pairings[menu.restaurant_id] == undefined) {
						pairings[menu.restaurant_id] = {
							ids: [],
							businesses: []
						}
					}
					if (
						!pairings[menu.restaurant_id].ids.includes(business.id)
					) {
						pairings[menu.restaurant_id].ids.push(business.id)
						pairings[menu.restaurant_id].businesses.push({
							name: business.name,
							id: business.id,
							slug: business.slug
						})
					}
				})
			}
		})
		return pairings
	},
	restaurantBusinessPairings: (state) => {
		const pairings = {}
		Object.values(state.businesses).forEach((business) => {
			if (business.menus && business.menus.length > 0) {
				business.menus.map((menu) => {
					if (pairings[business.id] == undefined) {
						pairings[business.id] = {
							ids: [],
							restaurants: []
						}
					}
					if (
						!pairings[business.id].ids.includes(menu.restaurant_id)
					) {
						const restaurant = state.restaurants[menu.restaurant_id]
						pairings[business.id].ids.push(menu.restaurant_id)
						pairings[business.id].restaurants.push({
							name: restaurant ? restaurant.name : "N/A",
							id: menu.restaurant_id,
							slug: restaurant ? restaurant.slug : "N/A"
						})
					}
				})
			}
		})
		return pairings
	},
	adminUsersMap: (state) => {
		if (
			state.admin_users &&
			state.admin_users != null &&
			state.admin_users.length > 0
		) {
			return state.admin_users.reduce((adminUsers, adminUser) => {
				adminUsers[adminUser.id] = adminUser
				return adminUsers
			}, {})
		}
		return {}
	},
	businessesMap: (state) => {
		const map = {}
		for (let slug in state.businesses) {
			let business = state.businesses[slug]
			map[business.id] = business
		}
		return map
	},
	supplyRequests: (state) => {
		return state.businessesModule.supplyRequests.concat(
			state.restaurantsModule.supplyRequests
		)
	}
}
