<template>
	<div
		class="section-column"
		
		:style="{ zIndex: 1000 - index, position: 'relative' }"
	>
		<div class="collapsible-slim-title" v-if="showBar" @click="toggleExpand">
			{{ title }} {{ index + 1 }}
			<div class="seperator"></div>
			<i
				class="flippable sk-icon-chevron-down-regular"
				:class="{ flipped: expanded }"
			></i>
		</div>
		<transition-expand>
			<div class="expandable-content" v-if="expanded" key="0">
				<slot name="expanded"></slot>
			</div>
			<div class="expandable-content" v-else key="1">
				<slot name="collapsed"></slot>
			</div>
		</transition-expand>
	</div>
</template>

<style scoped>
.section-column {
	margin: 12px 0 12px 0;
}
.expandable-content {
	flex: auto !important;
	margin-bottom: 0 !important;
}
.collapsible-slim-title {
	color: var(--sk-dark-navy);
	font-size: 14px;
	font-weight: 700;
	display: flex;
	align-items: center;
}
.seperator {
	content: "";
	display: block;
	flex-grow: 1;
	height: 2px;
	background: var(--sk-grey);
	margin: 0 20px 0 20px;
}
</style>

<script>
import TransitionExpand from "@/components/transitions/TransitionExpand.vue"

export default {
	name: "CollapsibleSectionSlim",
	components: {
		TransitionExpand
	},
	props: {
		title: String,
		defaultExpanded: {
			type: Boolean,
			default: true
		},
		index: Number,
		showBar: Boolean
	},
	data: function () {
		return {
			expanded: true
		}
	},
	methods: {
		toggleExpand() {
			this.expanded = !this.expanded
		}

	},
	mounted: function () {
		this.expanded = this.defaultExpanded
	}
}
</script>