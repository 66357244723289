import moment from "moment"
import tracker from "@/utils/tracker"

let prepackagedOrderMixin = {
	computed: {
		suborder() {
			return this.order ? this.order.sub_orders[0] : null
		},
		business() {
			return this.$store.getters.businessesMap[this.order.business_id]
		},
		businessOffset() {
			return this.business ? this.business.timezone_offset : 0
		},
		deliverySlot() {
			return this.suborder ? this.suborder.delivery_slot : ""
		},
		orderDay() {
			let deliverySlot = moment
				.utc(this.deliverySlot)
				.add(this.businessOffset, "m")
			return deliverySlot.format("dddd")
		},
		orderDate() {
			let deliverySlot = moment
				.utc(this.deliverySlot)
				.add(this.businessOffset, "m")
			return deliverySlot.format("M/D")
		},
		businessName() {
			return this.business ? this.business.name : "N/A"
		},
		prepackageCutoffPassed() {
			if (this.suborder && this.deliverySlot && this.deliverySlot != "") {
				const deliveryMoment = moment
					.utc(this.deliverySlot)
				return this.suborder.status != 0 || deliveryMoment.isSameOrBefore(moment.utc(), "d")
			} else {
				return true
			}
		},
		actions() {
			const actions = []

			if (!this.prepackageCutoffPassed) {
				actions.push({
					id: 0,
					text: `<i class="sk-icon-eye-solid"></i> View`
				})
				actions.push({
					id: 3,
					text: `<i class="sk-icon-receipt-solid"></i> Preview Manifest`
				})
				actions.push({
					id: 1,
					text: `<i class="sk-icon-times-regular"></i> Cancel`
				})
			}
			else {
				actions.push({
					id: 3,
					text: `<i class="sk-icon-receipt-solid"></i> Preview Manifest`
				})
			}
			return actions
		},
		orderQuantity() {
			if (this.suborder) {
				return this.suborder.products.reduce(
					(total, product) => {
						return product.quantity + total
					},
					0
				)
			}
			return 0
		},
		orderPrice() {
			if (this.suborder) {
				return this.suborder.products.reduce(
					(total, product) => {
						return product.total_price + total
					},
					0
				)
			}
			return 0
		},
		orderIssuer() {
			if (this.order) {
				const adminUser = this.$store.getters.adminUsersMap[
					this.order.issuer_id
				]
				if (adminUser) {
					return adminUser.display_name
				}
			}
			return "N/A"
		},
		isCancelled() {
			return (
				(this.suborder && this.suborder.status == 4) ||
				this.order.cancelled == 1
			)
		},
		isUpcoming() {
			if (this.suborder && this.deliverySlot && this.deliverySlot != "") {
				const deliveryMoment = moment
					.utc(this.deliverySlot)
					.add(this.businessOffset, "m")
				const deliveryPassed = deliveryMoment.isBefore(moment())
				if (deliveryPassed) {
					return false
				}
			}
			return (this.suborder && [0, 1, 3, 5].includes(this.suborder.status))
		},
		statusBadgeClasses() {
			if (this.isCancelled) {
				return "pill-alt pill-alt-red"
			} else if (this.isUpcoming) {
				return "pill-alt pill-alt-navy"
			} else {
				return "pill-alt pill-alt-grey"
			}
		},
		statusLabel() {
			if (this.isCancelled) {
				return "Cancelled"
			} else if (this.isUpcoming) {
				return "Upcoming"
			} else {
				return "Completed"
			}
		},
		statusClasses() {
			if (this.isCancelled) {
				return "status-dot red"
			} else if (this.isUpcoming) {
				return "status-dot navy"
			} else {
				return "status-dot grey"
			}
		}
	},
	methods: {
		actionSelected(action) {
			switch (action.id) {
				case 0: {
					tracker.trackEvent("View Prepackage Clicked", {
						order: this.order.order_id
					})
					this.$emit("openPrepackagesModal", {
						order: this.order,
						viewing: true
					})
					break
				}
				case 1: {
					tracker.trackEvent("Cancel Prepackage Clicked", {
						order: this.order.order_id
					})
					this.$emit("cancelPrepackage", this.order)
					break
				}
				case 3: {
					tracker.trackEvent("Download Prepackaged Manifest", {
						order: this.order.order_id
					})
					const menu_id = this.order.sub_orders[0].products[0].menu_id
					const payload = {
						order_ids: [this.order.order_id],
						business_id: this.order.business_id,
						menu_id: menu_id
					}
					this.$emit("downloadManifest", payload)
					break
				}
			}
		},
		editPrepackage() {
			tracker.trackEvent("Edit Prepackage Clicked", {
				order: this.order.order_id
			})
			this.$emit("openPrepackagesModal", {
				order: this.order,
				updating: true
			})
		},
	}
}

export default prepackagedOrderMixin
