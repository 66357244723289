<template>
	<div
		@click="toggleExpand"
		class="sk-modal-section batch-edit-product-property-widget"
		:class="{ expanded: expanded }"
	>
		<div class="sk-widget-alt sk-column">
			<div class="sk-row collapsed-view">
				<div class="property-title">{{ title }}</div>
				<div class="property-value">
					<span>{{ propertyValue }}</span>
					<i
						class="sk-icon-chevron-down-regular flippable"
						:class="{ flipped: expanded }"
					></i>
				</div>
			</div>
			<transition-expand>
				<div
					v-if="expanded"
					@click="collapsibleSectionClicked"
					class="collapsible-section"
				>
					<div class="sk-divider"></div>
					<slot></slot>
				</div>
			</transition-expand>
		</div>
	</div>
</template>

<style scoped>
.batch-edit-product-property-widget.expanded {
	z-index: 1000;
	transition: z-index 0s ease-in-out 0s;
}

.batch-edit-product-property-widget {
	height: 50px;
	position: relative;
	margin-bottom: 20px;
	transition: z-index 0s ease-in-out 0.25s;
	z-index: 1;
}

.sk-widget-alt {
	cursor: pointer;
	position: absolute;
	top: 0;
	left: 0;
}

.collapsed-view {
	margin-left: -20px;
	width: calc(100% + 40px);
	justify-content: space-between;
}

.sk-row,
.sk-column {
	margin: 0;
	width: 100%;
}

.property-title {
	font-weight: 600;
	font-size: 14px;
	color: var(--sk-grey3);
}

.property-title,
.property-value {
	flex: 0 0 auto !important;
}

.property-value {
	display: flex;
	align-items: center;
}

.property-value > span {
	color: var(--sk-grey3);
	font-style: italic;
	font-size: 14px;
	margin-right: 20px;
}

.property-value > span + i {
	color: var(--sk-grey2);
	font-size: 10px;
}

.collapsible-section > .sk-divider {
	margin: 13px -20px;
	width: calc(100% + 40px);
}
</style>

<script>
import TransitionExpand from "@/components/transitions/TransitionExpand.vue"

export default {
	name: "BatchEditProductPropertyWidget",
	components: {
		TransitionExpand
	},
	props: {
		title: String,
		propertyValue: String
	},
	data: function () {
		return {
			expanded: false
		}
	},
	methods: {
		toggleExpand() {
			this.expanded = !this.expanded
			this.$emit("expanded", this.expanded)
		},
		collapsibleSectionClicked(e) {
			e.preventDefault()
			e.stopPropagation()
		}
	}
}
</script>