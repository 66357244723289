<template>
	<SkBaseModal
		:isOpen="isOpen"
		class="confirmation-modal sk-modal-alt"
		@close="cancel"
	>
		<div class="sk-modal-alt-title">
			<h1>
				{{ titleText }}
				<span class="sk-modal-alt-subtitle" v-if="subtitleText">
					{{ subtitleText }}
				</span>
			</h1>
		</div>
		<div class="sk-modal-description">{{ bodyText }}</div>
		<div class="sk-modal-actions">
			<button class="button button-text" @click="cancel(false)">
				{{ cancelText }}
			</button>
			<button class="button" :class="buttonClass" @click="confirm(true)">
				<i v-if="confirmationIcon" :class="confirmationIcon"></i>
				{{ confirmationText }}
			</button>
		</div>
	</SkBaseModal>
</template>

<style scoped>
.confirmation-modal {
	z-index: 2000;
}

.confirmation-modal-content {
	border-top: 5px solid;
}

.confirmation-modal-content.green {
	border-color: var(--sk-green);
}

.confirmation-modal-content.red {
	border-color: var(--sk-red);
}
</style>

<script>
import { ConfirmationModalType } from "@/enums/confirmationModalType"
import skPromiseModalMixin from "@/mixins/modals/sk-promise-modal-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"

export default {
	name: "ConfirmationModal",
	mixins: [skPromiseModalMixin],
	components: {
		SkBaseModal
	},
	data: function() {
		return {
			titleText: "",
			subtitleText: null,
			bodyText: null,
			confirmationText: "Ok",
			confirmationIcon: null,
			cancelText: "Cancel",
			type: ConfirmationModalType.GREEN
		}
	},
	computed: {
		buttonClass() {
			switch (this.type) {
				case ConfirmationModalType.GREEN:
					return "button-success"
				case ConfirmationModalType.RED:
					return "button-danger"
				case ConfirmationModalType.WARNING:
					return "button-primary"
			}
		},
		confirmationBorderClass() {
			switch (this.type) {
				case ConfirmationModalType.GREEN:
					return "green"
				case ConfirmationModalType.RED:
					return "red"
			}
		}
	},
	methods: {
		handleOptions(options) {
			if (options) {
				this.titleText = options.title ? options.title : ""
				this.subtitleText = options.subtitle ? options.subtitle : null
				this.bodyText = options.body ? options.body : null
				this.confirmationText = options.confirmation
					? options.confirmation
					: this.confirmationText
				this.confirmationIcon = options.confirmationIcon
					? options.confirmationIcon
					: null
				this.cancelText = options.cancel
					? options.cancel
					: this.cancelText
				this.type = options.type != undefined ? options.type : this.type
			}
		}
	}
}
</script>