<template>
	<div class="sk-row">
		<div class="sk-widget">
			<div class="sk-row order-summary">
				<div>
					<div class="pill pill-grey">
						<i class="sk-icon-shopping-bag-solid"></i>
						{{ order.itemCount }} Items
					</div>
					<div class="order-identifier">#{{ order.data.id }}</div>
				</div>
			</div>
			<ul class="order-tabs sk-page-tabs">
				<li
					:class="{ 'active-tab': tab.active }"
					v-for="tab in tabs"
					:key="tab.id"
					@click="tabClicked(tab)"
				>
					{{ tab.text }}
				</li>
			</ul>
			<transition name="fade" mode="out-in">
				<div v-if="activeTab.id == 0" key="0">
					<div class="sk-table sk-table-default sk-table-tabbed">
						<div class="sk-table-header">
							<div class="sk-table-row">
								<div>Quantity</div>
								<div>Item</div>
								<div v-if="showCogs">COGS</div>
								<div>Price</div>
							</div>
						</div>
						<div
							class="sk-table-row sk-widget"
							v-for="product in order.data.sub_orders[0].products"
							:key="product.id"
						>
							<div>
								{{ product.quantity }}
							</div>
							<div>
								{{ product.name }}
								<div
									class="order-product-meta"
									v-for="meta in product.meta"
									:key="`${product.id}-${meta.meta_id}-${meta.meta_val_id}`"
								>
									{{ meta.label }}
									<b>{{ meta.value }}</b>
								</div>
								<div
									class="order-product-meta"
									v-if="product.comments.length > 0"
								>
									Comments:
									<b>{{ product.comments }}</b>
									<br />
								</div>
							</div>
							<div v-if="showCogs">
								{{ order.prices[product.id].cogs | currency }}
							</div>
							<div>
								{{
									order.prices[product.id].subtotal | currency
								}}
							</div>
						</div>
					</div>
				</div>
				<div v-if="activeTab.id == 1" key="1">
					<div class="sk-table sk-table-default sk-table-tabbed">
						<div class="sk-table-header">
							<div class="sk-table-row">
								<div>Name</div>
								<div>Apartment</div>
								<div>Phone</div>
								<div>Order Time</div>
							</div>
						</div>
						<div class="sk-table-row sk-widget">
							<div>
								{{ order.data.user_name }}
							</div>
							<div>
								{{ order.data.table }}
							</div>
							<div v-if="showCogs">
								{{
									order.data.user_phone
										| formatInternationalPhone
								}}
							</div>
							<div>
								{{
									order.createdTime
										| timeWithOffset(businessTimezone)
								}}
							</div>
						</div>
					</div>
				</div>
			</transition>
		</div>
	</div>
</template>

<style scoped>
.sk-widget {
	overflow: auto;
}
</style>

<script>
import { UserRole } from "@/enums/userRole"
import tracker from "@/utils/tracker"
import { PermissionTab } from "@/utils/permissions"
import currencyFilterMixin from "@/mixins/currency-filter-mixin"
import phoneFilterMixin from "@/mixins/phone-filter-mixin"
import eventDisplayMixin from "@/mixins/events/event-display-mixin"

export default {
	name: "EventOrderLineItem",
	mixins: [currencyFilterMixin, phoneFilterMixin, eventDisplayMixin],
	props: {
		order: Object,
		business: Object,
		event: Object
	},
	data: function () {
		return {
			tabs: [
				{
					id: 0,
					text: "Items",
					active: true
				},
				{
					id: 1,
					text: "Customer",
					active: false
				}
			]
		}
	},
	computed: {
		activeTab() {
			return this.tabs.find((tab) => tab.active)
		},
		showCogs() {
			return (
				this.$store.state.permissions.includes(
					PermissionTab.EVENT_COGS
				) || this.userRole == UserRole.RESAURANT_ADMIN
			)
		}
	},
	methods: {
		tabClicked(tabClicked) {
			tracker.trackEvent("Event Orders Tab Clicked", {
				tab_name: tabClicked.text
			})
			this.tabs.forEach((tab) => (tab.active = tab.id == tabClicked.id))
		}
	}
}
</script>