export const IntegrationType = {
    "UNTAPPD": 1,
    "SQUARE": 2,
    "CLOVER": 3,
    "CHOWLY": 4,
    "OTTER": 5,
    "ORDERMARK": 6,
    "REEF": 7,
    "STARMICRONICS": 8,
    "GRUBHUB": 9
}