<template>
	<div>
		<div class="dashboard-orders-title">
			<h2>Recent Orders</h2>
			<router-link to="/orders">View All Orders</router-link>
		</div>
		<DashboardOrder
			v-for="order in recentOrders"
			:key="order.id"
			:order="order"
			:entityType="entityTypes.RESTAURANT"
			@click.native="openOrderDetailsModal(order.id)"
		/>
		<DashboardEmptySection
			v-if="recentOrders.length == 0"
			:title="'No Recent Orders'"
			:message="'No active or upcoming orders to show. Check your order history to see information on past orders.'"
			:link="'/orders'"
			:linkText="'View All Orders'"
		/>
		<OrderDetailsModal ref="orderDetailsModal" />
	</div>
</template>

<style scoped>
h2 {
	font-size: 16px;
	color: var(--sk-dark-navy);
	margin-bottom: 0;
	padding: 4px 0;
}

.dashboard-orders-title {
	padding-left: 30px;
	display: flex;
	align-items: center;
	margin-bottom: 26px;
}

.dashboard-orders-title a {
	margin-left: auto;
	color: var(--sk-grey3);
	font-size: 10px;
	text-decoration: none;
}

.dashboard-order + .dashboard-order {
	margin-top: 10px;
}
</style>

<script>
import DashboardOrder from "@/components/dashboard/DashboardOrder.vue"
import DashboardEmptySection from "@/components/dashboard/DashboardEmptySection.vue"
import OrderDetailsModal from "@/components/modals/OrderDetailsModal.vue"
import { EntityType } from "@/enums/entityType"

export default {
	name: "RestaurantDashboardOrders",
	components: {
		DashboardOrder,
		DashboardEmptySection,
		OrderDetailsModal
	},
	data: function () {
		return {
			entityTypes: EntityType
		}
	},
	computed: {
		recentOrders() {
			return this.$store.state.restaurantDashboardModule.recentOrders
		}
	},
	methods: {
		openOrderDetailsModal(orderId) {
			this.$refs.orderDetailsModal.open({
				orderId: orderId
			})
		}
	}
}
</script>