import Vue from "vue"
import { store } from "@/store/store"
import { parseResponse } from "@arikgaisler/utils/api-utils"

let host = process.env.VUE_APP_API_HOST

const BUSINESS_API = `https://business${host}.2ndkitchen.com`
const SEARCH_API = `https://indexing${host}.2ndkitchen.com`

export default {
    async getBusiness(business_slug, includes) {
        const includesSuffix = includes ? includes : "locations,restaurants,products,kiosks,menus,opening_hours,markup_rules,delivery_modes"
        return Vue.axios.get(`${BUSINESS_API}/businesses/${business_slug}?include=${includesSuffix}`)
    },
    async getRestaurantBusinesses(rest_id) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.get(`${BUSINESS_API}/restaurants/${rest_id}/businesses?include=menus`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async updateBusiness(business_id, payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return Vue.axios.put(`${BUSINESS_API}/businesses/${business_id}`, payload, { 'headers': { 'Authorization': tokenStr }})
    },
    async addBusiness(payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return Vue.axios.post(`${BUSINESS_API}/businesses`, payload, { 'headers': { 'Authorization': tokenStr }})
    },
    async addBusinessMenu(business_id, menu_id, payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.post(`${BUSINESS_API}/businesses/${business_id}/menus/${menu_id}`, payload, { 'headers': { 'Authorization': tokenStr }}))
	},
	async addDropoffLocation(payload) {
		const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.post(`${BUSINESS_API}/business_locations/dropoff_locations/batch`, payload, { 'headers': { 'Authorization': tokenStr }}))
	},
	async updateDropoffLocation(dropoff_location_id, payload) {
		const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.put(`${BUSINESS_API}/dropoff_locations/${dropoff_location_id}`, payload, { 'headers': { 'Authorization': tokenStr }}))
    },
    async updateBusinessMenu(business_id, menu_id, payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.put(`${BUSINESS_API}/businesses/${business_id}/menus/${menu_id}`, payload, { 'headers': { 'Authorization': tokenStr }}))
    },
    async addKiosk(business_id,payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return Vue.axios.post(`${BUSINESS_API}/businesses/${business_id}/kiosks`, payload, { 'headers': { 'Authorization': tokenStr } });
    },
    async updateKiosk(kiosk_id, payload){
        const tokenStr = 'Bearer ' + store.state.user_token
        return Vue.axios.put(`${BUSINESS_API}/kiosks/${kiosk_id}`, payload, { 'headers': { 'Authorization': tokenStr } });
    },
    async enableRewards(business_id) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return Vue.axios.post(`${BUSINESS_API}/businesses/${business_id}/rewards/activate`, null, { 'headers': { 'Authorization': tokenStr } });
    },
    async deleteBusiness(business_id) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return Vue.axios.delete(`${BUSINESS_API}/businesses/${business_id}`, { 'headers': { 'Authorization': tokenStr } });
    },
    async deleteDropoffLocation(dropoff_location_id) {
        const tokenStr = "Bearer " + store.state.user_token
        return Vue.axios.delete(`${BUSINESS_API}/dropoff_locations/${dropoff_location_id}`, { 'headers': { 'Authorization': tokenStr } })
    },
    async rebootKiosk(kiosk_id) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return Vue.axios.put(`${BUSINESS_API}/kiosks/${kiosk_id}/reboot`, null, { 'headers': { 'Authorization': tokenStr } });
    },
    async launchBusiness(business_id) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return Vue.axios.post(`${BUSINESS_API}/businesses/${business_id}/launch`, null, { 'headers': { 'Authorization': tokenStr } });
    },
    async getBusinessSupplies() {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.get(`${BUSINESS_API}/business_supplies`, { 'headers': { 'Authorization': tokenStr } }))
    },    
    async getBusinessSupplyRequests(payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.post(`${BUSINESS_API}/business_supplies_requests/search`, payload,{ 'headers': { 'Authorization': tokenStr } }))
    },
    async setSupplyRequestStatus(request_id, status) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.put(`${BUSINESS_API}/business_supplies_requests/${request_id}/${status}`, null, { 'headers': { 'Authorization': tokenStr } }))
    },
    async setActivatedBusiness(business_id, payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return Vue.axios.put(`${BUSINESS_API}/businesses/${business_id}/activation_status`, payload, { 'headers': { 'Authorization': tokenStr } });
    },
    async createBusinessSuppliesRequest(payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return Vue.axios.post(`${BUSINESS_API}/business_supplies_requests`,payload, { 'headers': { 'Authorization': tokenStr } });
    },
    async scheduleBusinessEvent(business_id, payload) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.post(`${BUSINESS_API}/businesses/${business_id}/events`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async searchBusinessEvents(payload) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.post(`${BUSINESS_API}/events/search`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getBusinessEvent(event_id) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.get(`${BUSINESS_API}/events/${event_id}`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getBusinessEvents(ids) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.get(`${BUSINESS_API}/events?ids=${ids}`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async updateBusinessEvent(payload) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.put(`${BUSINESS_API}/events/${payload.event.id}`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async updateBusinessEventNotes(event_id, payload) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.put(`${BUSINESS_API}/events/${event_id}`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getEventManifestURL(event_id) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.post(`${BUSINESS_API}/events/${event_id}/generate_pre_order_manifest`, null, { 'headers': { 'Authorization': tokenStr } }))
    },
    async cancelBusinessEvent(event_id) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.post(`${BUSINESS_API}/events/${event_id}/cancel`, {}, { 'headers': { 'Authorization': tokenStr } }))
    },
    async reviewBusinessEvent(event_id) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.post(`${BUSINESS_API}/events/${event_id}/review`, {}, { 'headers': { 'Authorization': tokenStr } }))
    },
    async confirmBusinessEvent(event_id) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.post(`${BUSINESS_API}/events/${event_id}/confirm`, {}, { 'headers': { 'Authorization': tokenStr } }))
    },
    async deleteBusinessEvent(event_id) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.delete(`${BUSINESS_API}/events/${event_id}`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async endBusinessEvent(event_id) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.post(`${BUSINESS_API}/events/${event_id}/end`, {}, { 'headers': { 'Authorization': tokenStr } }))
    },
    async stopBusinessEventPreOrders(event_id) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.post(`${BUSINESS_API}/events/${event_id}/stop_pre_order`, {}, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getBusinessEventReminders(event_id) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.get(`${BUSINESS_API}/events/${event_id}/reminders`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async updateBusinessEventReminder(reminder_id, payload) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.put(`${BUSINESS_API}/event_reminders/${reminder_id}`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async addEventReminder(event_id, payload) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.post(`${BUSINESS_API}/events/${event_id}/reminders`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async deleteEventReminder(reminder_id) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.delete(`${BUSINESS_API}/event_reminders/${reminder_id}`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async fulfillBusinessEvent(event_id, payload) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.put(`${BUSINESS_API}/events/${event_id}/pre_order_fulfillment_status`, payload,  { 'headers': { 'Authorization': tokenStr } }))
    },
    async autocomplete(query) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.get(`${SEARCH_API}/businesses/autocomplete?name=${query}&fuzziness=1`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getDefaultMUR() {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.get(`${BUSINESS_API}/markup_rule_types`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getBusinessMUR(business_id) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.get(`${BUSINESS_API}/businesses/${business_id}/markup_rules`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async addBusinessMUR(business_id, payload) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.post(`${BUSINESS_API}/businesses/${business_id}/markup_rules`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async updateBusinessMUR(mur_id, payload) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.put(`${BUSINESS_API}/business_markup_rules/${mur_id}`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getBusinessEmployees() {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.get(`${BUSINESS_API}/businesses_employees`, { 'headers': { 'Authorization': tokenStr } }));
    },
    async addBusinessEmployee(business_id,payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.post(`${BUSINESS_API}/businesses/${business_id}/employees`, payload, { 'headers': { 'Authorization': tokenStr } }));
    },
    async updateBusinessEmployee(employee_id,payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.put(`${BUSINESS_API}/businesses_employees/${employee_id}`, payload, { 'headers': { 'Authorization': tokenStr } }));
    },
    async removeBusinessEmployee(employee_id) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.delete(`${BUSINESS_API}/businesses_employees/${employee_id}`, { 'headers': { 'Authorization': tokenStr } }));
    },
    async updateBusinessEmployeeStatus(employee_id, payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.put(`${BUSINESS_API}/businesses/employees/${employee_id}/status`, payload, { 'headers': { 'Authorization': tokenStr } }));
    },
}