<template>
	<div class="event-calendar-detail" :style="containerRightStyle">
		<div class="event-banner" :style="backgroundImageStyle">
			<div class="actions">
				<button class="button button-icon button-close" @click="close">
					<i class="sk-icon-times-regular"></i>
				</button>
				<button
					class="button button-icon"
					v-if="isConfirmable"
					@click="actionSelected({ id: 4 })"
					title="Confirm"
				>
					<i class="sk-icon-check-regular"></i>
				</button>
				<button
					class="button button-icon"
					@click="actionSelected({ id: 1 })"
					v-else-if="isEditable"
					title="Edit"
				>
					<i class="sk-icon-pencil-solid"></i>
				</button>
				<button
					class="button button-icon"
					@click="actionSelected({ id: 3 })"
					v-else-if="isDeletable"
					title="Delete"
				>
					<i class="sk-icon-trash-alt-solid"></i>
				</button>
				<button
					class="button button-icon"
					@click="actionSelected({ id: 2 })"
					v-else
					title="View"
				>
					<i class="sk-icon-eye-solid"></i>
				</button>
				<SkDropdownSelect
					v-if="allowedActions"
					class="button button-icon"
					:options="allowedActions"
					:iconClasses="'sk-icon-ellipsis-v-regular'"
					:showSelectedOption="false"
					:position="'left'"
					@selected="actionSelected"
				/>
			</div>
			<div class="event-name text-truncate">{{ event.name }}</div>
		</div>
		<div class="event-calendar-date">
			<span>{{ eventDate }}</span>
			<span>{{ eventTime }}</span>
		</div>
		<div class="sk-row">
			<div class="event-calendar-detail-line-item">
				<i class="sk-icon-align-left-regular"></i>
				<span>{{ eventType | eventTypeName }}</span>
			</div>
		</div>
		<div class="sk-row">
			<div class="event-calendar-detail-line-item">
				<i class="sk-icon-map-marker-alt-solid1"></i>
				<span v-sk-unassigned="businessName"></span>
			</div>
		</div>
		<div class="sk-row">
			<div class="event-calendar-detail-line-item">
				<i class="sk-icon-utensils-solid"></i>
				<span v-sk-unassigned="restaurantName"></span>
			</div>
		</div>
		<div class="sk-row" v-if="acceptsPreorders">
			<div class="event-calendar-detail-line-item">
				<i class="sk-icon-stopwatch-regular"></i>
				<span>Accepting Pre Orders</span>
			</div>
		</div>
		<div class="sk-row">
			<div class="event-status" :class="statusBadgeClasses">
				<span :class="statusClasses"></span>
				{{ statusLabel }}
			</div>
		</div>
	</div>
</template>

<style scoped>
.event-calendar-detail {
	position: absolute;
	transform: translateY(-50%);
	width: 362px;
	background: var(--sk-greybg);
	box-shadow: 0px 5px 15px rgba(136, 136, 136, 0.25);
	border-radius: 5px;
	z-index: 1000;
	overflow: hidden;
}

.event-banner {
	background-size: cover;
	background-position: center;
	height: 150px;
	width: 100%;
	position: relative;
}

.event-banner .actions {
	position: absolute;
	top: 0;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 25px;
	margin-top: 5px;
	padding: 0 10px;
}

.event-banner .actions .button {
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--sk-white);
	background: rgba(46, 60, 73, 0.7);
	min-height: 25px;
	padding: 0;
	width: 25px;
	font-size: 10px;
}

.event-banner .actions .button + .button {
	margin-left: 10px;
}

.event-banner .actions .button-close {
	margin-right: auto;
}

.event-banner .actions >>> .sk-icon-ellipsis-v-regular {
	margin: auto;
}

.event-name {
	position: absolute;
	bottom: 0;
	left: 0;
	font-size: 14px;
	font-weight: 600;
	color: var(--sk-white);
	background: var(--sk-dark-navy);
	width: 100%;
	text-align: left;
	padding: 15px;
	border-top-right-radius: 5px;
	border-top-left-radius: 5px;
}

.event-calendar-date {
	background: var(--sk-white);
	padding: 15px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	border: 1px solid var(--sk-grey);
	text-align: left;
}

.event-calendar-date span {
	font-size: 12px;
	color: var(--sk-grey3);
}

.event-calendar-date span:first-child {
	margin-right: 25px;
}

.event-calendar-detail-line-item {
	text-align: left;
}

.event-calendar-detail-line-item span {
	font-size: 12px;
	font-weight: 600;
	color: var(--sk-grey3);
}

.event-calendar-detail-line-item i {
	margin-right: 20px;
	color: var(--sk-grey2);
	font-size: 10px;
}

.event-status {
	flex: 0 0 auto !important;
}
</style>

<script>
import { BusinessEventType } from "@arikgaisler/utils/enums/businessEventType"
import eventDisplayMixin from "@/mixins/events/event-display-mixin"
import eventMixin from "@/mixins/events/event-mixin"
import eventStatusMixin from "@/mixins/events/event-status-mixin"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"

export default {
	name: "EventCalendarDetail",
	mixins: [eventDisplayMixin, eventMixin, eventStatusMixin],
	components: {
		SkDropdownSelect
	},
	props: {
		selectedItem: Object,
		position: String
	},
	computed: {
		event() {
			return this.selectedItem
				? this.$store.state.eventsModule.events.find(
						(event) => event.id == this.selectedItem.id
				  )
				: null
		},
		eventType() {
			return this.event ? this.event.type : 0
		},
		backgroundImageStyle() {
			if (!this.event || !this.business) {
				return ""
			}
			const image = this.event.image
				? `${this.$eventsCDNURL}${this.business.slug}/${this.event.image}`
				: "/images/event-banner-placeholder.png"

			return {
				"background-image": `url(${image})`
			}
		},
		acceptsPreorders() {
			return this.event.pre_order_end_time != null
		},
		eventDate() {
			switch (this.event.type) {
				case BusinessEventType.POPUP:
					return this.$options.filters.formatEventDate(
						this.event.start_date,
						this.event.start_time,
						this.businessTimezone,
						"MMM D"
					)
				case BusinessEventType.VIRTUAL:
					return this.$options.filters.formatEventDate(
						this.event.end_date,
						this.event.end_time,
						this.businessTimezone,
						"MMM D"
					)
			}
		},
		eventTime() {
			switch (this.event.type) {
				case BusinessEventType.POPUP: {
					return this.$options.filters.timeWithOffset(
						this.event.start_date,
						this.event.start_time,
						this.businessTimezone
					)
				}
				case BusinessEventType.VIRTUAL: {
					return this.$options.filters.timeWithOffset(
						this.event.end_date,
						this.event.end_time,
						this.businessTimezone
					)
				}
			}
			return null
		},
		containerRightStyle() {
			if (this.position == "right") {
				return {
					right: "calc(100% + 30px)"
				}
			} else {
				return {
					left: "calc(100% + 30px)"
				}
			}
		}
	},
	methods: {
		actionSelected(action) {
			this.$emit("actionSelected", {
				id: action.id,
				event: this.event,
				businessName: this.businessName
			})
		},
		close() {
			this.$emit("close")
		}
	}
}
</script>