<template>
	<div class="sk-table-row">
		<div>
			{{ invoicePeriod }}
		</div>
		<div>
			{{ fundingSource }}
		</div>
		<div>
			{{ invoice.credits | currency }}
		</div>
		<div>{{ invoice.amount | currency }}</div>
		<div>{{ invoiceTotal | currency }}</div>
		<div>
			<div :class="statusBadgeClasses">
				<span :class="statusClasses"></span>
				{{ statusLabel }}
				<span v-if="showPaidDate" class="paid-date">
					{{ paidDate }}
				</span>
			</div>
		</div>
		<div class="sk-table-column-fit">
			<div class="sk-table-actions">
				<button
					class="button button-icon"
					@click="viewInvoice"
					title="View Invoice"
				>
					<i class="sk-icon-eye-solid"></i>
				</button>
				<SkDropdownSelect
					:options="allowedActions"
					:iconClasses="'sk-icon-ellipsis-v-regular'"
					:showSelectedOption="false"
					:position="'left'"
					@selected="actionSelected"
				/>
			</div>
		</div>
	</div>
</template>

<style scoped>
.sk-table-row .pill-alt {
	max-width: 100%;
}

.paid-date {
	font-weight: 400;
}
</style>

<script>
import moment from "moment"
import currencyFilter from "@/mixins/currency-filter-mixin"
import { FundingSource } from "@/enums/fundingSource"
import { InvoiceStatus } from "@/enums/invoiceStatus"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"

export default {
	name: "InvoiceDetailsHistoryLineItem",
	mixins: [currencyFilter],
	components: {
		SkDropdownSelect
	},
	props: {
		invoice: Object
	},
	computed: {
		invoiceTotal() {
			return (
				parseFloat(this.invoice.amount) -
				parseFloat(this.invoice.credits)
			)
		},
		invoicePeriod() {
			const from = moment.utc(this.invoice.from_date).format("MMM D")
			const to = moment.utc(this.invoice.to_date).format("MMM D")
			return `${from} - ${to}`
		},
		paidDate() {
			if (this.invoice && this.invoice.paid_at) {
				return moment.utc(this.invoice.paid_at).format("MMM D")
			}
			return "N/A"
		},
		fundingSource() {
			if (
				this.invoice.funding_source != undefined &&
				this.invoice.funding_source.type != undefined &&
				this.invoice.funding_source.type.id == FundingSource.ACH
			) {
				return "ACH"
			}
			return "N/A"
		},
		showPaidDate() {
			return this.invoice.status.value == InvoiceStatus.COMPLETED &&
				this.invoice.paid_at
				? true
				: false
		},
		statusBadgeClasses() {
			switch (this.invoice.status.value) {
				case InvoiceStatus.APPROVED:
					return "pill-alt pill-alt-yellow"
				case InvoiceStatus.PROCESSING:
					return "pill-alt pill-alt-green"
				case InvoiceStatus.COMPLETED:
					return "pill-alt pill-alt-green"
			}
		},
		statusClasses() {
			switch (this.invoice.status.value) {
				case InvoiceStatus.APPROVED:
					return "status-dot yellow"
				case InvoiceStatus.PROCESSING:
					return "status-dot green-pulsating"
				case InvoiceStatus.COMPLETED:
					return "status-dot green"
			}
		},
		statusLabel() {
			switch (this.invoice.status.value) {
				case InvoiceStatus.APPROVED:
					return "Approved"
				case InvoiceStatus.PROCESSING:
					return "Processing"
				case InvoiceStatus.COMPLETED:
					return "Paid"
			}
		},
		allowedActions() {
			const actions = []
			if (this.invoice && this.invoice.invoice_generated) {
				actions.push({
					text: `<i class="sk-icon-download"></i> Download Invoice`,
					id: 0
				})
			}
			actions.push({
				text: `<i class="sk-icon-download"></i> Download Breakdown`,
				id: 1
			})
			return actions
		}
	},
	methods: {
		actionSelected(action) {
			switch (action.id) {
				case 0: {
					this.downloadInvoice()
					break
				}
				case 1: {
					this.downloadInvoiceBreakdown()
					break
				}
			}
		},
		viewInvoice() {
			this.$emit("viewInvoice", this.invoice)
		},
		downloadInvoice() {
			this.$emit("downloadInvoice", this.invoice)
		},
		downloadInvoiceBreakdown() {
			this.$emit("downloadInvoiceBreakdown", this.invoice)
		}
	}
}
</script>