<template>
	<SkBaseModal class="sk-modal-alt" :isOpen="isOpen" @close="cancel">
		<div class="sk-modal-alt-title">
			<h1>
				Import Products To Menus
			</h1>
		</div>
		<div class="menu-rows">
			<div
				class="sk-row sk-widget menu-row"
				v-for="menu in menus"
				:key="menu.id"
				@click="menuSelected(menu)"
			>
				<SkCheckbox :checked="menuIds.includes(menu.id)" />
				<div class="menu-name">{{ menu.name }}</div>
			</div>
		</div>
		<div class="sk-modal-actions">
			<button class="button button-text" @click="cancel(null)">
				Not Now
			</button>
			<button class="button button-primary" @click="confirm(menuIds)">
				<i class="sk-icon-check-regular"></i>
				Import Products
			</button>
		</div>
	</SkBaseModal>
</template>

<style scoped>
.menu-rows {
	position: relative;
	margin: 0;
	height: 330px;
	overflow-y: auto;
	overflow-x: hidden;
	margin: 0 -5px 20px -5px;
	padding: 5px 5px 0 5px;
}

.menu-row {
	display: flex;
	align-items: center;
	margin-left: 0;
	width: 100%;
}

.menu-row:first-child {
	margin-top: 0;
}

.menu-row >>> .sk-checkbox {
	margin-right: 30px;
}

.menu-row .menu-name {
	flex: 1;
}

.menu-row div {
	font-size: 14px;
	font-weight: 600;
}
</style>

<script>
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import skPromiseModalMixin from "@/mixins/modals/sk-promise-modal-mixin"
import SkCheckbox from "@/components/SkCheckbox.vue"

export default {
	name: "BatchAddMenusProductsModal",
	mixins: [skPromiseModalMixin],
	components: {
		SkBaseModal,
		SkCheckbox
	},
	data() {
		return {
			menuIds: []
		}
	},
	computed: {
		restaurant() {
			return this.$store.state.restaurantsModule.currentRestaurant
		},
		menus() {
			const menus =
				this.restaurant && this.restaurant.menus
					? this.restaurant.menus
					: []
			return menus.map(menu => {
				return {
					name: menu.name,
					id: menu.id
				}
			})
		}
	},
	methods: {
		opened() {
			this.menuIds = []
		},
		menuSelected(menu) {
			if (!this.menuIds.includes(menu.id)) {
				this.menuIds.push(menu.id)
			} else {
				this.menuIds = this.menuIds.filter(id => menu.id != id)
			}
		}
	}
}
</script>