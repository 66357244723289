<template>
	<div class="label-icons" v-if="hasLabels">
		<span class="label-icon" v-if="hasPreOrderOnlyLabel" v-sk-tooltip="'Pre Order Only'">
			<i class="label-icon sk-icon-stopwatch-regular"></i>
		</span>
		<span class="label-icon" v-if="hasFeaturedLabel" v-sk-tooltip="'Featured'">
			<i class="label-icon sk-icon-star-solid"></i>
		</span>
		<span class="label-icon" v-if="hasPopularLabel" v-sk-tooltip="'Popular'">
			<i class="label-icon sk-icon-fire-solid"></i>
		</span>
	</div>
</template>

<style scoped>
.label-icons {
	display: flex;
}

.label-icon {
	width: 30px;
	height: 20px;
	background: var(--sk-navy);
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.label-icon + .label-icon {
	margin-left: 10px;
}

.label-icon i {
	font-size: 10px;
	color: var(--sk-white);
	width: 10px;
	height: 10px;
}
</style>

<script>
import { UniqueProductLabelId } from "@arikgaisler/utils/enums/uniqueProductLabelId"

export default {
	name: "RestaurantProductLabelIcons",
	props: {
		labels: Array
	},
	computed: {
		hasLabels() {
			return this.labels && this.labels.length > 0
		},
		hasFeaturedLabel() {
			return (
				this.hasLabels &&
				this.labels.find(label => label.id == UniqueProductLabelId.FEATURED) != undefined
			)
		},
		hasPopularLabel() {
			return (
				this.hasLabels &&
				this.labels.find(label => label.id == UniqueProductLabelId.POPULAR) != undefined
			)
		},
		hasPreOrderOnlyLabel() {
			return (
				this.hasLabels &&
				this.labels.find(label => label.id == UniqueProductLabelId.PREORDER_ONLY) != undefined
			)
		}
	}
}
</script>