<template>
	<div class="sk-table-row">
		<div class="sk-table-column-fit">
			{{ entity.name }}
		</div>
		<div>
			<div class="column-title">
				{{ addressLine1 }}
			</div>
			<div class="column-subtitle">
				{{ addressLine2 }}
			</div>
		</div>
		<div>
			<div class="column-title">{{ nextPayout }}</div>
			<div class="column-subtitle">{{ location.amount | currency }}</div>
		</div>
		<div>
			<div :class="bankStatusBadgeClass">
				<span :class="bankStatusClass"></span>
				{{ bankStatusLabel }}
			</div>
		</div>
		<div>
			<div :class="w9StatusBadgeClass">
				<span :class="w9StatusClass"></span>
				{{ w9StatusLabel }}
			</div>
		</div>
		<div class="sk-table-column-fit">
			<div class="sk-table-actions">
				<router-link
					tag="button"
					:to="`/finance/restaurant/${entity.id}`"
					class="button button-icon"
					title="Edit"
				>
					<i class="sk-icon-pencil-solid"></i>
				</router-link>
			</div>
		</div>
	</div>
</template>

<style scoped>
.sk-table-row .pill-alt {
	max-width: 100%;
}
</style>

<script>
import moment from "moment"
import { FundingSource } from "@/enums/fundingSource.js"
import currencyFilterMixin from "@/mixins/currency-filter-mixin"

export default {
	name: "PayoutLocationLineItem",
	mixins: [currencyFilterMixin],
	props: {
		location: Object
	},
	computed: {
		bankStatusBadgeClass() {
			return this.location.funding_source.processor_id ==
				FundingSource.ACH
				? "pill-alt pill-alt-green"
				: "pill-alt pill-alt-red"
		},
		bankStatusClass() {
			return this.location.funding_source.processor_id ==
				FundingSource.ACH
				? "status-dot green"
				: "status-dot red"
		},
		bankStatusLabel() {
			return this.location.funding_source.processor_id ==
				FundingSource.ACH
				? "Linked"
				: "Not Linked"
		},
		w9StatusBadgeClass() {
			return this.entity.w9_uploaded == 1
				? "pill-alt pill-alt-green"
				: "pill-alt pill-alt-red"
		},
		w9StatusClass() {
			return this.entity.w9_uploaded == 1
				? "status-dot green"
				: "status-dot red"
		},
		w9StatusLabel() {
			return this.entity.w9_uploaded == 1
				? "Uploaded"
				: "Not Uploaded"
		},
		entity() {
			return this.$store.state.restaurants[
				this.location.funding_source.entity_id
			]
		},
		addressLine1() {
			return this.entity && this.entity.address
				? this.entity.address
				: "N/A"
		},
		addressLine2() {
			if (this.entity && this.entity.city && this.entity.state) {
				return `${this.entity.city}, ${this.entity.state}`
			}
			return "N/A"
		},
		nextPayout() {
			let nextPayout = ""
			const now = moment()
			const dayOfMonth = now.date()
			if (dayOfMonth == 15) {
				nextPayout = "Today"
			} else if (dayOfMonth > 15) {
				nextPayout =
					now.format("MMM") +
					" 16 - " +
					now.format("MMM") +
					" " +
					now.daysInMonth()
			} else if (dayOfMonth < 15) {
				nextPayout =
					now.format("MMM") + " 1 " + "- " + now.format("MMM") + " 15"
			}
			return nextPayout
		}
	}
}
</script>