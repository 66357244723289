<template>
	<SkBaseModal
		class="sk-modal-alt"
		:isOpen="isOpen"
		:hasLoader="true"
		@close="cancel"
	>
		<loading :active.sync="loading" :is-full-page="false" />
		<button class="button button-icon button-close" @click="cancel(false)">
			<i class="sk-icon-times-regular"></i>
		</button>
		<div class="sk-modal-alt-title">
			<h1>Confirm Menu Changes?</h1>
		</div>
		<div class="sk-modal-description">
			Where would you like to apply these changes to?
		</div>
		<div>
			<div
				class="sk-switch-label-wrapper sk-modal-select-all"
				@click="selectAll"
			>
				<SkCheckbox :checked="allSelected" />
				<span>Select All</span>
			</div>
			<div
				v-for="menu in businessMenus"
				:key="`${menu.businessId}-${menu.menu.id}`"
				class="sk-switch-label-wrapper"
			>
				<SkCheckbox v-model="menu.sync" />
				<span>{{ menu.businessName }} - {{ menu.menuName }}</span>
			</div>
		</div>
		<div class="sk-modal-actions">
			<button class="button button-text" @click="cancel(false)">
				Don't Apply
			</button>
			<button class="button button-primary" @click="applyChanges">
				<i class="sk-icon-check-regular"></i>
				Apply Changes
			</button>
		</div>
	</SkBaseModal>
</template>

<script>
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import skPromiseModalMixin from "@/mixins/modals/sk-promise-modal-mixin"
import SkCheckbox from "@/components/SkCheckbox.vue"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import Loading from "vue-loading-overlay"

export default {
	name: "SyncBusinessesProductsModal",
	mixins: [skPromiseModalMixin],
	components: {
		SkCheckbox,
		SkBaseModal,
		Loading
	},
	data: function () {
		return {
			businessMenus: [],
			product: {},
			loading: false
		}
	},
	computed: {
		allSelected() {
			return (
				!this.loading && this.businessMenus.every((menu) => menu.sync)
			)
		}
	},
	methods: {
		selectAll() {
			if (this.allSelected) {
				this.businessMenus.forEach((menu) => {
					this.$set(menu, "sync", false)
				})
			} else {
				this.businessMenus.forEach((menu) => {
					this.$set(menu, "sync", true)
				})
			}
		},
		handleOptions(options) {
			this.loading = false
			this.businessMenus = options.businesses
			this.product = options.product
		},
		applyChanges() {
			let confirmedBusinessMenus = this.businessMenus
				.filter((menu) => menu.sync)
				.reduce((businesses, business) => {
					if (!businesses[business.businessId]) {
						businesses[business.businessId] = []
					}
					businesses[business.businessId].push(business.menu.id)
					return businesses
				}, {})
			this.confirm(confirmedBusinessMenus)
			this.syncBusinessesProducts(confirmedBusinessMenus, this.product)
			this.businessMenus = []
		},
		syncAll(businessMenus, product) {
			let confirmedBusinessMenus = businessMenus.reduce(
				(businesses, business) => {
					if (!businesses[business.businessId]) {
						businesses[business.businessId] = []
					}
					businesses[business.businessId].push(business.menu.id)
					return businesses
				},
				{}
			)
			this.syncBusinessesProducts(confirmedBusinessMenus, product)
		},
		syncBusinessesProducts(businesses, editedProduct) {
			let promises = []
			this.loading = true
			Object.keys(businesses).forEach((confirmedBusiness) => {
				let business =
					this.$store.state.restaurantsModule.restaurantBusinesses.find(
						(business) => business.id == confirmedBusiness
					)
				let menus = businesses[confirmedBusiness]
				business.menus.forEach((menu) => {
					if (menus.includes(menu.id)) {
						let product = menu.products.find(
							(product) => editedProduct.id == product.id
						)
						if (product) {
							product.name = editedProduct.name
						}
						let promise = this.$store.dispatch(
							"businessesModule/updateBusinessMenu",
							{
								menu: menu,
								business: business
							}
						)
						promise
							.then((success) => {
								if (!success) {
									throw "Error"
								}
								eventBus.emit(
									EventBusEvents.SHOW_NOTIFICATION,
									{
										title: "Menu Changes",
										message: `${menu.name} has been updated!`
									}
								)
							})
							.catch(() => {
								eventBus.emit(
									EventBusEvents.SHOW_NOTIFICATION,
									{
										title: "Menu Changes",
										message: `Failed to update ${menu.name}`,
										type: NotificationType.ERROR
									}
								)
							})
						promises.push(promise)
					}
				})
			})
			if (promises.length > 0) {
				Promise.all(promises).finally(() => {
					this.loading = false
				})
			} else {
				this.loading = false
			}
		}
	}
}
</script>