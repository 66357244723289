import { MenuType } from "@arikgaisler/utils/enums/menuType"
import { CourierId } from "@arikgaisler/utils/enums/courierId"
import { PaymentType } from "@arikgaisler/utils/enums/paymentType"
import { OrderTransactionStatus } from "@/enums/orderTransactionStatus"
import { eventBus } from "@/utils/eventBus"
import moment from "moment"
import orderHelper from "@/utils/order-helper"
import actionTypes from "@/store/modules/orders/action-types"
import { NotificationType } from "@/enums/notificationType"
import { EventBusEvents } from "@/enums/eventBusEvents"
import { PermissionMethod } from "@/utils/permissions"

let orderMixin = {
	computed: {
		business() {
			return this.order
				? this.$store.getters.businessesMap[this.businessId]
				: null
		},
		needsSupervisor() {
			return (this.order.needs_supervisor == 1)
		},
		actions() {
			return this.order.actions
		},
		isCancelledOrder() {
			return this.order.cancelled == 1
		},
		isPopupOrder() {
			return this.order.menu_type == MenuType.POPUP
		},
		isPreOrder() {
			return orderHelper.isPreorder(this.order, this.item)
		},
		isUpcomingOrder() {
			return orderHelper.isUpcomingOrder(this.order)
		},
		orderScheduledDate() {
			return orderHelper.preorderDate(
				this.order,
				this.item,
				this.business.timezone
			)
		},
		orderTimeStarted() {
			if (this.isUpcomingOrder) {
				let now = moment().utc()
				let offset = this.business ? this.business.timezone_offset : 0
				if (now.isDST()) {
					offset = offset - 60
				}
				now.add(offset, "m")
				let preorderDateStart = moment(this.orderScheduledDate).subtract(
					45,
					"m"
				)
				if (now.isBefore(preorderDateStart)) {
					return false
				}
			}
			return true
		},
		orderStartTime() {
			const suborder = this.order.sub_orders[0]
			return this.isPreOrder && suborder.accept_time ? suborder.accept_time : this.order.created_time
		},
		orderEndTime() {
			const isWalking = this.order.sub_orders.some(suborder => suborder.courier_id == CourierId.WALKING)
			const timeKey = isWalking ? "completed_time" : "delivered_time"
			let longestDuration = 0
			let longestTime = moment.utc()
			this.order.sub_orders.forEach((suborder) => {
				if (suborder[timeKey] && suborder[timeKey] != "") {
					let deliveredTime = moment.utc(suborder[timeKey])
					let suborderDuration = moment
						.duration(deliveredTime.diff(this.orderStartTime))
						.asSeconds()
					if (suborderDuration > longestDuration) {
						longestTime = deliveredTime
						longestDuration = suborderDuration
					}
				}
			})
			return moment.utc(longestTime)
		},
		hasTable() {
            return (
				this.order &&
                this.order.table != undefined &&
                this.order.table != null &&
                this.order.table != 0 &&
                this.order.table != ""
            )
        },
		isInRoomBilling() {
			if (!this.order) {
				return false
			}

			if (this.order.payment_method && this.order.payment_method.type != undefined) {
				return this.order.payment_method.type == PaymentType.IN_ROOM_BILLING
			} else if (this.order.payment_method != undefined) {
				return this.order.payment_method == PaymentType.IN_ROOM_BILLING
			}
		},
		inRoomBillingTransaction() {
			if (this.orderTransaction) {
				return this.orderTransaction
			} else if (
				this.order &&
				this.isInRoomBilling &&
				this.order.transaction
			) {
				return this.order.transaction
			}

			return null
		},
		inRoomBillingStatusText() {
			if (this.isInRoomBilling && this.paymentStatus != null) {
				switch (this.paymentStatus) {
					case OrderTransactionStatus.PENDING:
						return "Pending"
					case OrderTransactionStatus.POSTED:
						return "Posted"
					case OrderTransactionStatus.DISPUTED:
						return "Disputed"
				}
			}
			return ""
		},
		inRoomBillingStatusIcon() {
			if (this.isInRoomBilling && this.paymentStatus != null) {
				switch (this.paymentStatus) {
					case OrderTransactionStatus.PENDING:
					case OrderTransactionStatus.POSTED:
						return "sk-icon-bell-solid"
					case OrderTransactionStatus.DISPUTED:
						return "sk-icon-bell-exclamation-solid"
				}
			}
			return ""
		},
		inRoomBillingStatusBadgeClasses() {
			if (this.isInRoomBilling && this.paymentStatus != null) {
				switch (this.paymentStatus) {
					case OrderTransactionStatus.PENDING:
						return "pill-alt dark-pill-alt-navy"
					case OrderTransactionStatus.POSTED:
						return "pill-alt dark-pill-alt-green"
					case OrderTransactionStatus.DISPUTED:
						return "pill-alt dark-pill-alt-red"
				}
			}
			return ""
		},
		paymentStatus() {
			if (this.inRoomBillingTransaction) {
				return this.inRoomBillingTransaction.status
			} else if (this.order && this.order.payment_method && this.order.payment_method.payment_status != undefined) {
				return this.order.payment_method.payment_status.status
			}
			return null
		},
		canDisputeCharge() {
			return (
				this.isInRoomBilling &&
				this.paymentStatus != null &&
				this.paymentStatus == OrderTransactionStatus.PENDING &&
				this.$store.state.permissions.includes(PermissionMethod.MARK_IN_ROOM_BILLING_DISPUTED)
			)
		},
		canMarkPosted() {
			return (
				this.isInRoomBilling &&
				this.paymentStatus != null &&
				this.paymentStatus != OrderTransactionStatus.POSTED &&
				this.$store.state.permissions.includes(PermissionMethod.MARK_IN_ROOM_BILLING_POSTED)
			)
		}
	},
	methods: {
		updateOrderTransactionStatus(status, successMsg, errorMsg, reasonText) {
			return new Promise((resolve, reject) => {
				let updatedStatus = false
				this.$store
					.dispatch(
						`ordersModule/${actionTypes.UPDATE_ORDER_TRANSACTION_STATUS}`,
						{
							orderId: this.order.id,
							status: status,
							reasonText: reasonText
						}
					)
					.then((success) => {
						if (success) {
							eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
								title: successMsg
							})
							updatedStatus = true
						} else {
							throw "Failed to post order"
						}
					})
					.catch(() => {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: errorMsg,
							type: NotificationType.ERROR
						})
					})
					.finally(() => {
						if (updatedStatus) {
							resolve(true)
						} else {
							reject()
						}
					})
			})
		},
	}
}

export default orderMixin