import { VoucherRequestStatus } from "@/enums/voucherRequestStatus"

let voucherRequestStatusMixin = {
	computed: {
		statusLabel() {
			if (this.voucherRequest) {
				if (this.voucherRequest.status == VoucherRequestStatus.REQUESTED) {
					return "Requested"
				} else if (
					this.voucherRequest.vouchers_used >=
					this.voucherRequest.quantity
				) {
					return "Used"
				} else {
					return "Approved"
				}
			}
			else {
				return "Approved"
			}
		},
		statusClasses() {
			if (this.voucherRequest) {
				if (this.voucherRequest.status == VoucherRequestStatus.REQUESTED) {
					return "status-dot yellow"
				} else if (
					this.voucherRequest.vouchers_used >=
					this.voucherRequest.quantity
				) {
					return "status-dot navy"
				} else {
					return "status-dot green"
				}
			}
			else {
				return "status-dot green"
			}
		},
		statusBadgeClasses() {
			if (this.voucherRequest && this.voucherRequest.status != null) {
				if (this.voucherRequest.status == VoucherRequestStatus.REQUESTED) {
					return "pill-alt pill-alt-yellow"
				} else if (
					this.voucherRequest.vouchers_used >=
					this.voucherRequest.quantity
				) {
					return "pill-alt pill-alt-navy"
				} else {
					return "pill-alt pill-alt-green"
				}
			}
			return "pill-alt pill-alt-green"
		}
	}
}

export default voucherRequestStatusMixin