import restaurant_service from "@/services/restaurants"
import business_service from "@/services/businesses"
import pos_service from "@/services/pos"
import xlsx_helper from "@/utils/xlsx-helper"
import aws_utils from "@/utils/aws"
import utils from "@/utils/utils"
import Vue from "vue"
import { UserRole } from "@/enums/userRole"
import { EventBusEvents, eventBus } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import { IntegrationType } from "@/enums/integrationType"
import { SupplyRequestStatus } from "@/enums/supplyRequestStatus"
import { ProductStatus } from "@/enums/productStatus"

const vue = new Vue()

export default {
    async getRestaurant({ commit }, id) {
        try {
            commit("loading")
            const promises = [
                restaurant_service.getRestaurant(id),
                business_service.getRestaurantBusinesses(id)
            ].map(promise => promise.catch(e => {
                console.warn(e)
                vue.bugsnag.notify(
                    "Restaurant API failed",
                    event => {
                        event.addMetadata("error", {
                            error: e
                        })
                    }
                )
            }))

            const responses = await Promise.all(promises)
            commit("gotRestaurant", responses[0].data)
            commit("gotRestaurantBusinesses", responses[1])
            commit("loaded")
            return true
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to get restaurant",
                event => {
                    event.addMetadata("error", {
                        error: e
                    })
                }
            )
            commit("loaded")
            return false
        }
    },
    async getRestaurantProducts({ commit, state }) {
        try {
            const response = await restaurant_service.getRestaurantProducts(state.currentRestaurant.id)
            if (response.status == 200) {
                commit("gotRestaurantProducts", response.data.products)
                return true
            }
            return false
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to get restaurant products",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        restaurantId: state.currentRestaurant.id
                    })
                }
            )
            return false
        }
    },
    async getRestaurantMenu({ commit, dispatch }, menuId) {
        try {
            commit("loading")
            const responses = await Promise.all([
                restaurant_service.getMenuProducts(menuId),
                dispatch("getCategories"),
                dispatch("getLabels")
            ])
            commit("setCurrentMenuID", menuId)
            commit("addRestaurantMenuProducts", [menuId, responses[0].data.products])
            commit("loaded")
            return true
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to get menu",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        menuId: menuId
                    })
                }
            )
            commit("loaded")
            return false
        }
    },
    async getRestaurantPOSDevices({ commit }, id) {
        try {
            commit("loading")
            const response = await pos_service.getRestaurantPOSDevices(id)
            if (response.data && response.data.data && response.data.data.devices) {
                commit("gotRestaurantPOSDevices", {
                    devices: response.data.data.devices,
                    id: id
                })
                commit("loaded")
                return true
            } else {
                throw "Devices not returned"
            }
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to get POS devices",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        restaurantId: id
                    })
                }
            )
            commit("loaded")
            throw e
        }
    },
    async updateProductMetaValueStock({ commit }, payload) {
        const status = payload.status ? 1 : 0
        const response = await restaurant_service.updateProductMetaValueStock(payload.valueId, { stock_status: status })
        if (response.status == 200) {
            commit("updatedProductMetaValueStock", {
                productId: payload.productId,
                metaId: payload.metaId,
                valueId: payload.valueId,
                status: status
            })
            return true
        } else {
            return false
        }
    },
    async updateProductMetaStock({ commit }, payload) {
        const status = payload.status ? 1 : 0
        const response = await restaurant_service.updateProductMetaStock(payload.metaId, { stock_status: status })
        if (response.status == 200) {
            commit("updatedProductMetaStock", {
                productId: payload.productId,
                metaId: payload.metaId,
                status: status
            })
            return true
        } else {
            return false
        }
    },
    async updateProductStock({ commit }, payload) {
        const status = payload.status ? 1 : 0
        const data = { stock_status: status }
        const resp = await restaurant_service.updateProductStock(payload.productId, data)
        commit("updateProductStock", {
            productId: payload.productId,
            status: resp.status == 200 ? status : !status
        })
        return true
    },
    async updateMenuStock({ commit, state }, status) {
        try {
            const stockStatus = status ? 1 : 0
            const payload = { "stock_status": stockStatus }
            const resp = await restaurant_service.updateMenuStock(state.currentMenuID, payload)
            if (resp.status == 200) {
                commit("updatedMenuStock", stockStatus)
            } else {
                throw "API error"
            }
            return true
        } catch (e) {
            commit("updatedMenuStock", status ? 0 : 1)
            eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
                title: "Unable to set menu stock status, please try again.",
                type: NotificationType.ERROR
            })
            vue.bugsnag.notify(
                "Failed to set menu stock status",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        stockStatus: status
                    })
                }
            )
            console.warn(e)
            return false
        }
    },
    async updateRestaurantStock({ commit, state }, payload) {
        try {
            commit("loading")
            const stockStatus = payload.status ? 1 : 0
            const resp = await restaurant_service.updateRestaurantStock(state.currentRestaurant.id, {
                stock_status: stockStatus,
                duration: payload.minutes
            })
            if (resp.status == 200) {
                commit("updatedRestaurantStock", stockStatus)
            } else {
                throw "API error"
            }
            commit("loaded")
            return true
        } catch (e) {
            commit("loaded")
            commit("updatedRestaurantStock", status ? 0 : 1)
            eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
                title: "Unable to set restaurant stock status, please try again.",
                type: NotificationType.ERROR
            })
            vue.bugsnag.notify(
                "Failed to set restaurant stock status",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        stockStatus: status
                    })
                }
            )
            console.warn(e)
            return false
        }
    },
    async refreshRestaurantTablet(store, pos_id) {
        const resp = await pos_service.refreshRestaurantTablet(pos_id)
        if (resp.data.code == "ok") {
            eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
                title: "Restaurant tablet refreshed"
            })
        }
    },
    async updateRestaurantMenu({ commit }, menu) {
        try {
            commit("loading")
            var retVal = false
            const menuResp = await restaurant_service.updateMenu(menu.id, { "menu": menu })
            if (menuResp.status == 200) {
                commit("updatedMenu", menuResp.data.menu)
                retVal = true
                eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
                    title: `Successfully updated ${menu.name}`
                })
            }
            else {
                retVal = false
            }
        }
        catch (e) {
            vue.bugsnag.notify(
                "Failed to update restaurant menu",
                event => {
                    event.addMetadata("error", {
                        error: e
                    })
                }
            )
        }
        commit("loaded")
        return retVal
    },
    async updateRestaurant({ commit }, rest) {
        try {
            commit("loading")
            const payload = { "restaurant": rest }
            const restResp = await restaurant_service.updateRestaurant(rest.id, payload)
            if (restResp.status == 200 || restResp.status == 200) {
                commit("loaded")
                eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
                    title: `Successfully updated ${rest.slug}`
                })
                return true
            }
            commit("loaded")
            return false
        } catch (e) {
            commit("loaded")
            eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
                title: e.message ? e.message : "Failed to update restaurant",
                type: NotificationType.ERROR
            })
            return false
        }
    },
    async addMenu({ commit }, payload) {
        try {
            const data = { "menu": payload.menu }
            const menuResp = await restaurant_service.addMenu(payload.restaurantId, data)
            if (menuResp.status == 200) {
                commit("addedMenu", menuResp.data.menu)
                eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
                    title: `Successfully added ${payload.name} menu`
                })
            } else {
                throw `Failed to add menu: http error status ${menuResp.status}`
            }
            return menuResp
        } catch (e) {
            eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
                title: "Unable to add menu, please try again.",
                type: NotificationType.ERROR
            })
            throw e
        }
    },
    async addProduct({ commit, state }, product) {
        try {
            const payload = { product: product }
            const productResp = await restaurant_service.addProduct(state.currentRestaurant.id, payload)
            if (productResp.status == 200) {
                commit("addedProduct", productResp.data.product)
                return state.restaurantProducts.find(item => productResp.data.product.id == item.id)
            }
            return null
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to add restaurant product",
                event => {
                    event.addMetadata("error", {
                        error: e
                    })
                }
            )
            return null
        }
    },
    async addMenuProduct({ commit }, payload) {
        try {
            const data = { product_id: payload.product.id }
            const response = await restaurant_service.addMenuProduct(payload.menuId, data)
            if (response.status == 200) {
                commit("addedMenuProduct", {
                    menuId: payload.menuId,
                    product: payload.product,
                    stock_status: response.data.menu_product.stock_status
                })
                return response
            }
            return null
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to add restaurant menu product",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        menuId: payload.menuId,
                        productId: payload.product ? payload.product.id : 0
                    })
                }
            )
            return null
        }
    },
    async updateProduct({ commit }, product) {
        try {
            const id = product.id
            const payload = { product: product }
            const response = await restaurant_service.updateProduct(id, payload)
            if (response.status == 200) {
                response.product.menu_ids = product.menu_ids
                commit("updatedProduct", response.product)
                eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
                    title: `${product.name} succesfully saved!`
                })
            } else {
                throw `API error: ${response.status}`
            }
        } catch (e) {
            eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
                title: `Unable to save ${product.name}`,
                type: NotificationType.ERROR
            })
            vue.bugsnag.notify(
                "Failed to add product",
                event => {
                    event.addMetadata("error", {
                        error: e
                    })
                }
            )
            throw e
        }
    },
    async batchUpdateProducts({ commit }, payload) {
        try {
            const response = await restaurant_service.batchUpdateProducts({
                ids: payload.productIds,
                data: payload.updates
            })
            if (response.status == 200) {
                commit("updatedRestaurantProducts", {
                    products: response.data.products
                })
                return true
            }
            return false
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to update restaurant products",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        stockStatus: status
                    })
                }
            )
            return false
        }
    },
    updateRestaurantHours({ commit }, payload) {
        commit("updatedRestaurantHours", payload)
    },
    async updateMenuHours({ commit }, payload) {
        commit("updatedMenuHours", payload)
    },
    setCurrentRestaurantLogo({ commit }, img) {
        commit("setCurrentRestaurantLogo", img)
    },
    async importExternalMenu(store, payload) {
        try {
            const promises = payload.externalMenuIds.map(id => {
                restaurant_service.importExternalMenuItems(payload.menuId, {
                    external_menu_id: id
                })
            })
            await Promise.all(promises)
            return true
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to import external menu",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        menuIds: payload.externalMenuIds
                    })
                }
            )
            return false
        }
    },
    async importIntegrationCatalog(store, id) {
        try {
            const response = await restaurant_service.importIntegrationCatalog(id)
            if (response.status == 200) {
                return true
            } else {
                throw `API Error: ${response.status}`
            }
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to import catalog",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        integration_id: id
                    })
                }
            )
            return false
        }
    },
    async importIntegrationProducts(store, params) {
        try {
            const response = await restaurant_service.importIntegrationProducts(params.id, {
                external_product_ids: params.productIds
            })
            if (response.status == 200) {
                return true
            } else {
                throw `API Error: ${response.status}`
            }
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to import catalog",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        payload: params
                    })
                }
            )
            return false
        }
    },
    async batchUploadProducts({ commit, state }, payload) {
        try {
            commit("loading")
            const productsFile = payload.files.find((file) => xlsx_helper.isValidXlSXFile(file))
            if (!productsFile) {
                eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
                    title: "No CSV or XLSX (excel) file selected, please select a folder containing a CSV/XLSX file.",
                    type: NotificationType.ERROR
                })
                commit("loaded")
                return false
            }
            const productRows = await xlsx_helper.readFile(productsFile)
            const productJSON = productRows
                .filter((row) => row.Name)
                .map((row) => {
                    const imageName = row["Image Postfix"] ? row["Image Postfix"].replace(/ /g, "-") : ""
                    const product = {
                        name: row.Name,
                        description: row.Description ? row.Description : "",
                        cogs: row.Cogs && Number(row.Cogs) >= 0 ? Number(row.Cogs) : 0,
                        price: row.Price && Number(row.Price) >= 0 ? Number(row.Price) : 0,
                        img: imageName,
                        contains_alcohol: row["Contains Alcohol"] ? row["Contains Alcohol"] : 0,
                        comments_enabled: row["Comments Enabled"] ? row["Comments Enabled"] : 0,
                        item_img: imageName
                    }
                    if (row.Category) {
                        const category = state.categories.find(category => category.name == row.Category)
                        if (category) {
                            product.category_id = category.id
                        }
                    }

                    if (row.Labels) {
                        const labels = row.Labels.split(",")
                        const labelIds = state.labels
                            .filter(label => labels.includes(label.name))
                            .map(label => {
                                return {
                                    id: label.id
                                }
                            })
                        if (labelIds && labelIds.length > 0) {
                            product.labels = labelIds
                        }
                    }

                    let metadata = row["Metadata (Name, Required,Multiple, Value, Value Price……),(….)"]
                    if (metadata) {
                        /* eslint-disable no-useless-escape */
                        metadata = metadata.trim()
                        metadata = metadata.substring(1, metadata.length - 1)
                            .replace(/(\(")|("[\(\)])|([)(]),|,([)(])+/g, "^")
                            .split("^")
                            .filter((meta) => meta.length > 0 && meta != ",")
                            .map((meta) => {
                                const metas = meta.replace(/,\s(?=(?:[^"]*"[^"]*")*[^"]*$)/g, ",").split(/,(?=(?:[^"]*"[^"]*")*[^"]*$)/g)
                                let values = []
                                for (var i = 3; i < metas.length; i += 2) {
                                    values.push({
                                        id: 0,
                                        name: metas[i].replace(/"/g, ""),
                                        price: metas[i + 1]
                                    })
                                }
                                return {
                                    key: metas[0].replace(/"/g, ""),
                                    required: metas[1] === "Y" ? 1 : 0,
                                    type: metas[2] > 0 ? 1 : 0,
                                    max_allowed_values: metas[2],
                                    values: values
                                }
                            })
                        /* eslint-enable no-useless-escape */
                    }
                    product.metas = metadata && metadata.length > 0 ? metadata : []
                    return product
                })
            const addProductsResponse = await restaurant_service.batchAddProducts(state.currentRestaurant.id, {
                products: productJSON
            })
            if (addProductsResponse.status == 200) {
                let imageMap = {}
                const acceptedImageTypes = ["image/jpeg", "image/jpg", "image/png"]
                payload.files.map((file) => {
                    if (acceptedImageTypes.includes(file.type)) {
                        const name = file.name.replace(/ /g, "-")
                        imageMap[name] = file
                    }
                })
                const addedProductIds = []
                const addedProducts = addProductsResponse.data.products.map((product) => {
                    addedProductIds.push(product.id)
                    product.file = product.img ? imageMap[product.img] : null
                    return product
                })

                if (payload.menuIds && payload.menuIds.length > 0) {
                    const promises = payload.menuIds.map((id) => {
                        return restaurant_service.batchAddProductsToMenu(id, {
                            product_ids: addedProductIds
                        }).catch(e => {
                            console.warn(e)
                            vue.bugsnag.notify(
                                "Failed to batch add products to menu",
                                event => {
                                    event.addMetadata("error", {
                                        error: e,
                                        menuId: payload.menuId
                                    })
                                }
                            )
                        })
                    })

                    const responses = await Promise.all(promises)
                    const products = {}
                    responses.forEach(response => {
                        if (response.data && response.data.menus_products) {
                            response.data.menus_products.forEach(product => {
                                products[product.product_id] = product
                            })
                        }
                    })
                    addedProducts.forEach(product => {
                        if (products[product.id] && products[product.id].stock_status != undefined) {
                            product.stock_status = products[product.id].stock_status
                            product.menu_ids = payload.menuIds
                        }
                    })
                    payload.menuIds.forEach(menuId => {
                        commit("addedNewMenuProducts", {
                            menuId: menuId,
                            products: addedProducts
                        })
                    })
                }

                let imagesUploaded = 0
                let imagesToUpload = addedProducts.filter((product) => product.file != undefined || product.file != null)
                    .map((product) => product.file)
                if (imagesToUpload.length > 0) {
                    const responses = await aws_utils.batchUploadImages({
                        type: "product",
                        slug: state.currentRestaurant.slug
                    }, imagesToUpload)
                    if (responses) {
                        imagesUploaded = responses.filter((response) => response != null).length
                    }
                }
                eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
                    title: `Succesfully uploaded ${addedProducts.length}/${productJSON.length} products and ${imagesUploaded} images!`
                })
                commit("addedProducts", addedProducts)
                commit("loaded")
                return true
            }
            throw addProductsResponse.data
        } catch (e) {
            commit("loaded")
            vue.bugsnag.notify(
                "Failed to batch upload",
                event => {
                    event.addMetadata("error", {
                        error: e
                    })
                }
            )
            eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
                title: "Something went wrong, try again.",
                type: NotificationType.ERROR
            })
            return e
        }
    },
    async batchAddProductsToMenu({ commit }, payload) {
        try {
            const response = await restaurant_service.batchAddProductsToMenu(payload.menuId, {
                product_ids: payload.productIds
            })
            if (response.status == 200) {
                commit("addedMenuProducts", {
                    menuId: payload.menuId,
                    products: response.data.menus_products
                })
                return true
            }
            return false
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to batch associate products",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        menuId: payload.menuId,
                        products: payload.productIds
                    })
                }
            )
            eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
                title: "Something went wrong, try again.",
                type: NotificationType.ERROR
            })
            return e
        }
    },
    async exportProducts({ commit, state }, options) {
        try {
            commit("loading")
            const products = options.products ? options.products : state.restaurantProducts
            if (products) {
                const filePaths = []
                let exportData = products.map((product) => {
                    if (product.img && options.exportPhotos) {
                        filePaths.push(`${Vue.prototype.$restaurantCDNURL}${state.currentRestaurant.slug}/${product.img}`)
                    }
                    const metas = product.metas.map((meta) => {
                        let values = meta.values.map((value) => `"${value.name}"` + "," + value.price).join(",")
                        const isRequired = meta.required ? "Y" : "N"
                        return "(" + `"${meta.key}"` + "," + isRequired + "," + meta.max_allowed_values + "," + values + ")"
                    })
                    const data = {
                        "Name": product.name,
                        "Description": product.description,
                        "Cogs": product.cogs,
                        "Price": product.price,
                        "Image Postfix": product.img,
                        "Contains Alcohol": product.contains_alcohol,
                        "Comments Enabled": product.comments_enabled != undefined ? product.comments_enabled : 1,
                        "Metadata (Name, Required,Multiple, Value, Value Price……),(….)": metas.join(","),
                        "Category": "",
                        "Labels": ""
                    }

                    if (product.category_id) {
                        const category = state.categories.find(category => category.id == product.category_id)
                        if (category) {
                            data.Category = category.name
                        }
                    }

                    if (product.labels && product.labels.length > 0) {
                        const labels = product.labels.map(label => label.id)
                        const labelNames = state.labels
                            .filter(label => labels.includes(label.id))
                            .map(label => label.name)
                        if (labelNames && labelNames.length > 0) {
                            data.Labels = labelNames.join(",")
                        }
                    }

                    return data
                })

                if (exportData.length == 0) {
                    exportData.push({
                        "Name": "",
                        "Description": "",
                        "Cogs": "",
                        "Price": "",
                        "Image Postfix": "",
                        "Contains Alcohol": "",
                        "Comments Enabled": "",
                        "Metadata (Name, Required,Multiple, Value, Value Price……),(….)": "",
                        "Category": "",
                        "Labels": ""
                    })
                }
                xlsx_helper.export(exportData, state.currentRestaurant.name)
                if (options.exportPhotos) {
                    await utils.downloadFiles(filePaths)
                }
                eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
                    message: `Succesfully exported products from ${state.currentRestaurant.name}!`
                })
            } else {
                eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
                    title: "Menu was not found.",
                    type: NotificationType.ERROR
                })
            }
            commit("loaded")
            return true
        } catch (e) {
            commit("loaded")
            vue.bugsnag.notify(
                "Failed to export products",
                event => {
                    event.addMetadata("error", {
                        error: e
                    })
                }
            )
            eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
                title: e.message ? e.message : "An error Occurred",
                type: NotificationType.ERROR
            })
            return e
        }
    },
    async archiveProduct({ commit }, product) {
        try {
            const response = await restaurant_service.archiveProduct(product.id)
            if (response.status == 200) {
                commit("archivedProduct", product.id)
                eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
                    message: `Succesfully archived ${product.name}!`
                })
                return true
            }
            throw `API error: ${response.status}`
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to archive product",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        productId: product.id
                    })
                }
            )
            eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
                title: e.message ? e.message : "An error Occurred",
                type: NotificationType.ERROR
            })
            return false
        }
    },
    async batchArchiveProducts({ commit }, productIDs) {
        try {
            const payload = { "product_ids": productIDs, "status": ProductStatus.ARCHIVED }
            const response = await restaurant_service.batchSetProductsStatus(payload)
            if (response.status == 200) {
                response.data.products.forEach(product => {
                    commit("archivedProduct", product.id)
                });
                return true
            }
            return false
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to batch archive product",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        productIds: productIDs
                    })
                }
            )
            return false
        }
    },
    async unarchiveProduct({ commit }, product) {
        try {
            const response = await restaurant_service.unarchiveProduct(product.id)
            if (response.status == 200) {
                commit("unarchivedProduct", product.id)
                eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
                    message: `Succesfully reactivated ${product.name}!`
                })
                return true
            }
            throw `API error: ${response.status}`
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to unarchive product",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        productId: product.id
                    })
                }
            )
            eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
                title: e.message ? e.message : "An error Occurred",
                type: NotificationType.ERROR
            })
            return false
        }
    },
    async batchUnArchiveProducts({ commit }, productIDs) {
        try {
            const payload = { "product_ids": productIDs, "status": ProductStatus.ACTIVE }
            const response = await restaurant_service.batchSetProductsStatus(payload)
            if (response.status == 200) {
                response.data.products.forEach(product => {
                    commit("unarchivedProduct", product.id)
                });
                return true
            }
            return false
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to batch unarchive product",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        productId: productIDs
                    })
                }
            )
            return false
        }
    },
    async deleteProduct({ commit }, productId) {
        const response = await restaurant_service.deleteProduct(productId)
        if (response.status == 200) {
            commit("deletedProduct", productId)
        }
    },
    async batchDeleteProducts({ commit }, productIds) {
        try {
            const response = await restaurant_service.batchDeleteProducts(productIds)
            if (response.status == 200) {
                commit("batchDeletedProducts", productIds)
                return true
            }
            return false
        }
        catch (e) {
            vue.bugsnag.notify(
                "Failed to batch delete product",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        productId: productIds
                    })
                }
            )
            return false
        }
    },
    async removeMenuProduct({ commit }, payload) {
        const menuProductResp = await restaurant_service.deleteMenuProduct(payload.menuId, payload.productId)
        if (menuProductResp.status == 200) {
            commit("removedMenuProduct", {
                menuId: payload.menuId,
                productId: payload.productId
            })
        }
    },
    async deleteMenu({ commit }, menu_id) {
        const menuResp = await restaurant_service.deleteMenu(menu_id)
        if (menuResp.data.code == "ok") {
            commit("deletedMenu", menu_id)
        }
    },
    async getCategories({ commit }, payload) {
        try {
            const response = await restaurant_service.getCategories(payload)
            if (response.status == 200 && response.data.categories) {
                commit("gotCategories", response.data.categories)
                return true
            }
            return false
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to get categories",
                event => {
                    event.addMetadata("error", {
                        error: e
                    })
                }
            )
            return false
        }
    },
    async getLabels({ commit }, payload) {
        try {
            const response = await restaurant_service.getLabels(payload)
            if (response.status == 200 && response.data.labels) {
                commit("gotLabels", response.data.labels)
                return true
            }
            return false
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to get labels",
                event => {
                    event.addMetadata("error", {
                        error: e
                    })
                }
            )
            return false
        }
    },
    async addCategory({ commit }, payload) {
        const response = await restaurant_service.addCategory(payload)
        if (response.status == 200 && response.data.category) {
            commit("addedCategory", response.data.category)
        }
    },
    async getSupplies({ commit }) {
        try {
            const suppliesResp = await restaurant_service.getRestaurantSupplies()
            if (suppliesResp.status == 200) {
                commit("gotSupplies", suppliesResp.data.supplies)
            }
            return suppliesResp.status == 200
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to get restaurant supplies",
                event => {
                    event.addMetadata("error", {
                        error: e
                    })
                }
            )
            return false
        }
    },
    async getSupplyRequests({ commit }) {
        try {
            const resp = await restaurant_service.getRestaurantSupplyRequests()
            if (resp.status == 200) {
                commit("gotSupplyRequests", resp.data.requests)
            }
            return resp.status == 200
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to get restaurant supply requests",
                event => {
                    event.addMetadata("error", {
                        error: e
                    })
                }
            )
            return false
        }
    },
    async getExternalMenus(store, id) {
        const restResp = await restaurant_service.getExternalMenus(id)
        if (restResp.status == 200 && restResp.code == "ok") {
            return restResp.data.menus
        } else {
            return null
        }
    },
    async createSupplyRequests(store, payload) {
        try {
            const promises = payload.supplies.map((supplyItem) => {
                return business_service.createBusinessSuppliesRequest({
                    restaurant_id: payload.restaurantId,
                    supply_id: supplyItem.id,
                    quantity: supplyItem.quantity
                })
            })
            const responses = await Promise.all(promises)
            const success = responses.every(response => response.status == 200)
            if (success) {
                return true
            }
            return false
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to create restaurant supply requests",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        data: payload
                    })
                }
            )
            return false
        }
    },
    async setSupplyRequestStatus({ commit }, payload) {
        try {
            const id = payload.supplyRequest.id
            let statusStr = "approve"
            if (payload.status == SupplyRequestStatus.REJECTED) {
                statusStr = "reject"
            } else if (payload.status == SupplyRequestStatus.CANCELLED) {
                statusStr = "cancel"
            }
            const resp = await restaurant_service.setSupplyRequestStatus({
                request_id: payload.id,
                action: statusStr
            })
            if (resp.status == 200) {
                if (payload.status == SupplyRequestStatus.REJECTED) {
                    commit("removeSupplyRequest", id)
                } else {
                    commit("updateSupplyRequest", {
                        id: id,
                        status: status
                    })
                }
            }
            return resp.status == 200
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to set supply request status",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        payload: payload
                    })
                }
            )
            return false
        }
    },
    async setW9Uploaded({ commit }, restaurant_id) {
        const payload = {
            restaurant: {
                w9_uploaded: 1
            }
        }
        const restResp = await restaurant_service.updateRestaurant(restaurant_id, payload)
        if (restResp.status == 200) {
            commit("restaurantW9Updated", restaurant_id, { root: true })
            return true
        }
    },
    setCurrentMenuID({ commit }, menuID) {
        commit("setCurrentMenuID", menuID)
    },
    clearRestaurant({ commit }) {
        commit("clearRestaurant")
    },
    clearProduct({ commit }) {
        commit("clearProduct")
    },
    async autocompleteRestaurants({ rootState }, query) {
        try {
            const response = await restaurant_service.autocomplete(query)
            let results = []
            if (response.data.restaurants) {
                if (rootState.user_role == UserRole.SUPER_ADMIN) {
                    results = response.data.restaurants
                } else {
                    const entityIds = rootState.user_entities.map(entity => entity.id)
                    results = response.data.restaurants.filter(result => result.id && entityIds.includes(result.id))
                }
            }
            return results
        } catch (e) {
            console.warn(e)
            return []
        }
    },
    async getRestaurantBrands(state, restaurant_id) {
        try {
            const response = await restaurant_service.getRestaurantBrands(restaurant_id)
            let results = []
            if (response.data.restaurant_brands) {
                results = response.data.restaurant_brands
            }
            return results
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to get restaurant brands",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        id: restaurant_id
                    })
                }
            )
            return []
        }
    },
    async addRestaurantHoursException({ commit }, payload) {
        try {
            const response = await restaurant_service.addRestaurantHoursException(payload.restaurantId, {
                exception: payload.exception
            })

            if (response.status == 200) {
                commit("addedRestaurantHoursException", response.data.exception)
                eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
                    title: `Succesfully added a special closure`
                })
                return true
            }
            return false
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to add restaurant exception",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        payload: payload
                    })
                }
            )
            if (e && e.code == "date_range_conflict_error") {
                throw e
            }
            return false
        }
    },
    async updateRestaurantHoursException({ commit }, payload) {
        try {
            const response = await restaurant_service.updateRestaurantHoursException(payload.id, {
                exception: payload.exception
            })

            if (response.status == 200) {
                commit("updatedRestaurantHoursException", response.data.exception)
                eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
                    title: `Succesfully updated ${payload.exception.title}`
                })
                return true
            }
            return false
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to add restaurant exception",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        payload: payload
                    })
                }
            )
            return false
        }
    },
    async removeRestaurantHoursException({ commit }, exception) {
        try {
            const response = await restaurant_service.deleteRestaurantHoursException(exception.id)

            if (response.status == 200) {
                commit("removedRestaurantHoursException", exception.id)
                eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
                    title: `Succesfully removed ${exception.title}`
                })
                return true
            }
            return false
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to delete restaurant exception",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        id: exception.id
                    })
                }
            )
            return false
        }
    },
    async getRestaurantIntegrations({ commit, state, dispatch, rootState }) {
        try {
            const responses = await Promise.all([
                restaurant_service.getRestaurantActiveIntegrations(state.currentRestaurant.id),
                restaurant_service.getRestaurantIntegrations()
            ])

            if (responses[0].status != 200) {
                throw `API Error: ${responses[0].status}`
            } else if (responses[1].status != 200) {
                throw `API Error: ${responses[1].status}`
            }

            let activeIntegrations = responses[0].data.integrations ? responses[0].data.integrations : []
            let availableIntegrations = responses[1].data.integration_types ? responses[1].data.integration_types : []

            commit("gotRestaurantIntegrations", {
                permissions: rootState.permissions,
                active: activeIntegrations,
                available: availableIntegrations
            })
            dispatch("getRestaurantSquareLocations")
            return true
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to get restaurant integrations",
                event => {
                    event.addMetadata("error", {
                        error: e
                    })
                }
            )
            return false
        }
    },
    async addRestaurantIntegration({ state, commit }, payload) {
        try {
            let addIntegrationResponse = null
            if (payload.integration && payload.integration.type) {
                switch (payload.integration.type) {
                    case IntegrationType.SQUARE: {
                        addIntegrationResponse = await restaurant_service.addRestaurantSquareIntegration({
                            authorization_code: payload.authToken,
                            restaurant_id: state.currentRestaurant.id
                        })
                        break
                    }
                    case IntegrationType.CHOWLY: {
                        addIntegrationResponse = await restaurant_service.addRestaurantChowlyIntegration({
                            access_token: payload.params.apiKey,
                            location_id: payload.params.locationID,
                            restaurant_id: state.currentRestaurant.id
                        })
                        break
                    }
                    case IntegrationType.UNTAPPD: {
                        addIntegrationResponse = await restaurant_service.addRestaurantUntappdIntegration({
                            access_token: payload.params.apiKey,
                            username: payload.params.username,
                            sync_catalog: payload.params.autoSync ? 1 : 0,
                            restaurant_id: state.currentRestaurant.id
                        })
                        break
                    }
                    case IntegrationType.OTTER: {
                        addIntegrationResponse = await restaurant_service.addRestaurantOtterIntegration({
                            restaurant_id: state.currentRestaurant.id
                        })
                        break
                    }
                    case IntegrationType.ORDERMARK: {
                        addIntegrationResponse = await restaurant_service.addRestaurantOrdermarkIntegration({
                            location_id: payload.params.locationId,
                            restaurant_id: state.currentRestaurant.id
                        })
                        break
                    }
                    case IntegrationType.REEF: {
                        const store_ids = payload.params.storeIDs.map((store) => {
                            return parseInt(store.id)
                        })
                        addIntegrationResponse = await restaurant_service.addRestaurantReefIntegration({
                            store_ids: store_ids,
                            restaurant_id: state.currentRestaurant.id
                        })
                        break
                    }
                    case IntegrationType.GRUBHUB: {
                        addIntegrationResponse = await restaurant_service.addRestaurantGrubhubIntegration({
                            location_id: payload.params.locationId,
                            restaurant_id: state.currentRestaurant.id
                        })
                        break
                    }
                }
                if (!addIntegrationResponse || addIntegrationResponse.status != 200) {
                    throw `API Error: ${addIntegrationResponse.status}`
                }
                commit("updatedRestaurantIntegration", addIntegrationResponse.data.integration)
                return true
            }
            return false
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to add restaurant integration",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        integrationType: payload.integration,
                        params: payload.params
                    })
                }
            )
            return false
        }
    },
    async updateRestaurantIntegration({ state, commit }, payload) {
        const integration = state.integrations.find(integration => integration.type == payload.integrationType)
        try {
            if (integration) {
                let params = null
                switch (integration.type) {
                    case IntegrationType.SQUARE:
                    case IntegrationType.GRUBHUB: {
                        params = {
                            external_location_id: payload.params.locationId
                        }
                        break
                    }
                    case IntegrationType.CHOWLY: {
                        params = {
                            access_token: payload.params.apiKey
                        }
                        break
                    }
                    case IntegrationType.UNTAPPD: {
                        params = {
                            access_token: payload.params.apiKey,
                            username: payload.params.username,
                            sync_catalog: payload.params.autoSync ? 1 : 0,
                        }
                        break
                    }
                    case IntegrationType.REEF: {
                        let storeIDs = payload.params.storeIDs.map((obj) => {return obj.id})
                        params = {
                            store_ids: storeIDs
                        }
                        break
                    }
                }
                if (params != null) {
                    const response = await restaurant_service.updateRestaurantIntegration(integration.id, {
                        integration: params
                    })
                    if (!response || response.status != 200) {
                        throw `API Error: ${response.status}`
                    }
                    commit("updatedRestaurantIntegration", response.data.integration)
                }
                return true
            }
            return false
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to update restaurant integration",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        payload: payload
                    })
                }
            )
            return false
        }
    },
    async requestRestaurantIntegrationRemoval(store, integration) {
        try {
            const response = await restaurant_service.deleteRestaurantIntegration(integration.id)
            if (response.status == 200) {
                return true
            } else {
                throw `API Error: ${response.status}`
            }
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to request restaurant integration removal",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        integrationType: integration.id
                    })
                }
            )
            return false
        }
    },
    async requestRestaurantIntegration({ state }, payload) {
        try {
            const response = await restaurant_service.requestRestaurantIntegration({
                restaurant_id: state.currentRestaurant.id,
                integration_name: payload.name,
                integration_reason: payload.reason
            })
            if (response.status == 200) {
                return true
            } else {
                throw `API Error: ${response.status}`
            }
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to request restaurant integration",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        payload: payload
                    })
                }
            )
            return false
        }
    },
    async getRestaurantSquareLocations({ state, commit }) {
        const squareIntegration = state.integrations.find(integration => integration.type == IntegrationType.SQUARE)
        try {
            if (squareIntegration && squareIntegration.id) {
                const response = await restaurant_service.getRestaurantSquareLocations(squareIntegration.id)
                if (response.status == 200) {
                    commit("gotRestaurantSquareLocations", response.data.locations)
                    return true
                } else {
                    throw `API Error: ${response.status}`
                }
            }
            return true
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to get restaurant square locations",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        integrationId: squareIntegration ? squareIntegration.id : null
                    })
                }
            )
            return false
        }
    },
    hideIntegrationsExplainer({ commit }) {
        commit("hideIntegrationsExplainer")
    }
}