import MutationTypes from "./mutation-types"

export default {
	[MutationTypes.LOADING](state) {
		state.loading = true
	},
	[MutationTypes.LOADED](state) {
		state.loading = false
	},
	[MutationTypes.GOT_RESTAURANT](state, restaurant) {
		state.restaurant = restaurant
	},
	[MutationTypes.GOT_TODAYS_RESTAURANT_MENUS](state, payload) {
		state.todaysRestaurantMenus = payload.menus
		state.openingHoursExceptions = payload.openingHoursExceptions
	},
	[MutationTypes.GOT_AVERAGE_RATING](state, avgRating) {
		state.restaurantAvgRating = avgRating ? avgRating.toFixed(2).replace(/[.,]00$/, "") : avgRating
	},
	[MutationTypes.GOT_TODAYS_BUSINESSES](state, businesses) {
		state.todaysBusinesses = businesses
	},
	[MutationTypes.GOT_TODAYS_EVENTS](state, events) {
		state.todaysEvents = events.reduce((eventHash, event) => {
			eventHash[event.id] = event
			return eventHash
		}, {})
	},
	[MutationTypes.GOT_RECENT_ORDERS](state, orders) {
		state.recentOrders = orders ? orders : []
	}
}