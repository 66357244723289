<template>
	<div class="sk-table-row">
		<div :title="statusLabel" class="sk-table-column-fit">
			<span :class="statusClasses"></span>
			{{ statusLabel }}
		</div>
		<div :title="exception.title">
			{{ exception.title }}
		</div>
		<div>{{ exceptionDate }}</div>
		<div>{{ exceptionTime }}</div>
		<div class="sk-table-column-fit">
			<div class="sk-table-actions">
				<button
					v-if="canEdit"
					class="button button-icon"
					title="Edit"
					@click="editExceptionClicked"
				>
					<i class="sk-icon-pencil-solid"></i>
				</button>
				<button
					class="button button-icon"
					title="Delete"
					@click="deleteExceptionClicked"
				>
					<i class="sk-icon-trash-alt-solid"></i>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import tracker from "@/utils/tracker"
import moment from "moment"

export default {
	name: "RestaurantHoursExceptiionLineItem",
	props: {
		exception: Object
	},
	computed: {
		offset() {
			return this.$store.getters["restaurantsModule/offset"]
		},
		canEdit() {
			return this.status == 0
		},
		status() {
			if (this.exception) {
				const now = moment().utc()
				const openTime = this.exception.open_time
					? this.exception.open_time
					: "00:00:00"
				const closeTime = this.exception.close_time
					? this.exception.close_time
					: "24:00:00"

				const start = moment.utc(
					`${this.exception.start_date} ${openTime}`,
					"YYYY-MM-DD HH:mm:ss"
				)
				const end = moment.utc(
					`${this.exception.end_date} ${closeTime}`,
					"YYYY-MM-DD HH:mm:ss"
				)
				const isBeforeStart = now.isBefore(start)
				const isAfterEnd = now.isAfter(end)

				if (isBeforeStart) {
					return 0
				} else if (isAfterEnd) {
					return 2
				} else {
					return 1
				}
			}
			return null
		},
		statusLabel() {
			if (this.status != null) {
				switch (this.status) {
					case 0:
						return "Upcoming"
					case 1:
						return "Active"
					case 2:
						return "Completed"
				}
			}
			return ""
		},
		statusClasses() {
			if (this.status != null) {
				switch (this.status) {
					case 0:
						return "status-dot yellow"
					case 1:
						return "status-dot green-pulsating"
					case 2:
						return "status-dot navy"
				}
			}
			return ""
		},
		openMoment() {
			if (this.exception.open_time) {
				return this.momentWithOffset(
					this.exception.start_date,
					this.exception.open_time,
					this.offset
				)
			} else if (this.exception.start_date) {
				return moment.utc(this.exception.start_date, "YYYY-MM-DD")
			}
			return null
		},
		closeMoment() {
			if (this.exception.close_time) {
				return this.momentWithOffset(
					this.exception.end_date,
					this.exception.close_time,
					this.offset
				)
			} else if (this.exception.end_date) {
				return moment.utc(this.exception.end_date, "YYYY-MM-DD")
			}
			return null
		},
		exceptionDate() {
			let date = "--"

			if (this.exception) {
				if (this.openMoment) {
					date = this.openMoment.format("MMM DD, YYYY")
				}

				if (this.closeMoment && this.openMoment) {
					if (!this.openMoment.isSame(this.closeMoment)) {
						date = `${date} - ${this.closeMoment.format(
							"MMM DD, YYYY"
						)}`
					}
				}
			}

			return date
		},
		exceptionTime() {
			if (this.exception) {
				if (this.exception.open_time && this.exception.close_time) {
					const open = this.openMoment.format("h:mm A")
					const close = this.closeMoment.format("h:mm A")
					return `${open} - ${close}`
				}
			}

			return "Closed"
		}
	},
	methods: {
		deleteExceptionClicked() {
			tracker.trackEvent("Delete Restaurant Hours Exception")
			this.$emit("delete", this.exception)
		},
		editExceptionClicked() {
			tracker.trackEvent("Edit Restaurant Hours Exception Clicked")
			this.$emit("edit", this.exception)
		},
		momentWithOffset: function (date, time, offset) {
			if (!date) return ""
			return moment
				.utc(`${date} ${time}`, "YYYY-MM-DD HH:mm:ss")
				.add(offset, "m")
		}
	}
}
</script>