<template>
	<SkBaseModal :isOpen="isOpen" @close="close" class="sk-modal-alt">
		<div class="sk-modal-alt-title">
			<h1>
				Add Notes
			</h1>
		</div>
		<div class="sk-row">
			<SkInput
				ref="notesInput"
				:name="'Notes'"
				:multiline="true"
				v-model="notes"
			/>
		</div>
		<div class="sk-modal-actions">
			<button @click="close" class="button button-text">
				Close
			</button>
			<button
				type="button"
				class="button button-primary"
				@click="saveNotes"
			>
				<i class="sk-icon-check-regular"></i>
				Add Notes
			</button>
		</div>
	</SkBaseModal>
</template>

<script>
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import ActionTypes from "@/store/modules/events/action-types"
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import SkInput from "@/components/SkInput.vue"

export default {
	name: "AddEventNotesModal",
	mixins: [skModalMixin],
	components: {
		SkBaseModal,
		SkInput
	},
	data: function () {
		return {
			eventId: 0,
			notes: ""
		}
	},
	methods: {
		handleOptions(options) {
			this.notes = options.notes ? options.notes : ""
			this.eventId = options.eventId
		},
		closed() {
			this.notes = ""
			this.eventId = 0
		},
		async saveNotes() {
			const success = await this.$store.dispatch(
				`eventsModule/${ActionTypes.UPDATE_EVENT_NOTES}`,
				{
					notes: this.notes,
					id: this.eventId
				}
			)
			if (success) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Updated event notes succesfully"
				})
				this.close()
			} else {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Failed to update event notes",
					type: NotificationType.ERROR
				})
			}
		}
	}
}
</script>