<template>
	<div class="sk-table-row">
		<div class="sk-table-column-fit">
			<div :title="statusLabel" :class="statusBadgeClasses">
				<span :class="statusClasses"></span>
				{{ statusLabel }}
			</div>
		</div>
		<div class="sk-table-pills sk-table-column-fit">
			<router-link
				:key="restaurant.id"
				:to="`/restaurant/${restaurant.id}/menu/${menu.id}`"
				tag="a"
				class="pill pill-grey"
				:title="restaurant.name"
			>
				<i class="sk-icon-link-regular"></i>
				{{ restaurant.name }}
			</router-link>
		</div>
		<div>
			{{ menuName }}
		</div>
		<div>
			{{ menuProductCount }}
		</div>
		<div>{{ menu.type | menuTypeText }} {{ menuAvailability }}</div>
		<div
			class="menu-schedules"
			@click="isMenuScheduleOpen = !isMenuScheduleOpen"
		>
			<div class="menu-today" v-if="todaysSchedule">
				<div>
					<div class="sk-muted-text">
						{{ todaysSchedule.daysText }}
					</div>
					<div>{{ todaysSchedule.timesText }}</div>
				</div>
				<i
					class="expand-popover-icon sk-icon-chevron-down-regular"
					:class="{ flipped: isMenuScheduleOpen }"
				></i>
			</div>
			<SkPopover v-model="isMenuScheduleOpen">
				<div
					v-for="(schedule, i) in menuSchedule"
					:key="i"
					class="menu-schedule"
				>
					<div class="sk-muted-text">
						{{ schedule.daysText }}
					</div>
					<div>
						{{ schedule.timesText }}
					</div>
				</div>
			</SkPopover>
		</div>
		<div>
			<div :class="{'sk-clickable': hasMultipleDeliveryModes}" @click="toggleMultipleDeliveryModes">
				{{deliveryModeText}}
				<i
					v-if="hasMultipleDeliveryModes"
					class="expand-popover-icon sk-icon-chevron-down-regular"
					:class="{ flipped: isDeliveryModesOpen }"
				></i>
			</div>
			<SkPopover v-if="hasMultipleDeliveryModes" v-model="isDeliveryModesOpen">
				<div
					v-for="deliveryMode in deliveryModes"
					:key="deliveryMode.id"
					class="delivery-mode-popover-item"
				>
					<b v-if="deliveryMode.isDefault">{{deliveryMode.text}} (Default)</b>
					<span v-else>{{deliveryMode.text}}</span>
				</div>
			</SkPopover>
		</div>
		<div>
			{{ menuCourier }}
		</div>
		<div class="sk-table-column-fit">
			<div class="sk-table-actions">
				<router-link
					v-if="editAllowed"
					tag="a"
					class="button button-icon"
					title="Edit"
					:to="editPath"
				>
					<i class="sk-icon-pencil-solid"></i>
				</router-link>
				<router-link
					v-else
					tag="a"
					class="button button-icon"
					title="View"
					:to="editPath"
				>
					<i class="sk-icon-eye-solid"></i>
				</router-link>
				<SkDropdownSelect
					:options="allowedActions"
					:iconClasses="'sk-icon-ellipsis-v-regular'"
					:showSelectedOption="false"
					:position="'left'"
					@selected="actionSelected"
				/>
				<!-- <button
					v-if="deleteAllowed"
					class="button button-icon"
					title="Delete"
					@click="deleteMenu"
				>
					<i class="sk-icon-trash-alt-solid"></i>
				</button> -->
			</div>
		</div>
	</div>
</template>

<style scoped>
.pill i {
	margin-right: 10px;
}
.sk-table-body .sk-table-row > .menu-schedules {
	white-space: normal;
}

.menu-schedules {
	padding: 20px 0;
}

.menu-today {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.menu-schedule > div {
	color: var(--sk-grey3);
}

.menu-schedule div:first-child {
	margin-bottom: 30px;
}

.menu-schedule:not(:first-child) div:first-child {
	margin-top: 30px;
}

.expand-popover-icon {
	margin-left: 20px;
	color: var(--sk-grey2);
	font-size: 10px;
}

.delivery-mode-popover-item + .delivery-mode-popover-item{
	margin-top: 5px;
}
</style>

<script>
import moment from "moment"
import momentHelper from "@/utils/moment-helper"
import { CourierId } from "@arikgaisler/utils/enums/courierId"
import { PermissionMethod } from "@/utils/permissions"
import deliveryModeFormatterMixin from "@/mixins/business/delivery-mode-formatter-mixin"
import menuTypeFormatterMixin from "@/mixins/menu-type-formatter-mixin"
import SkPopover from "@/components/SkPopover.vue"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"

export default {
	name: "BusinessMenuLineItem",
	mixins: [deliveryModeFormatterMixin, menuTypeFormatterMixin],
	components: {
		SkPopover,
		SkDropdownSelect
	},
	props: {
		menu: Object,
		restaurant: {
			type: Object,
			default: () => {}
		}
	},
	data: function () {
		return {
			isMenuScheduleOpen: false,
			isDeliveryModesOpen: false
		}
	},
	computed: {
		business() {
			return this.$store.state.businessesModule.currentBusiness
		},
		hasMultipleDeliveryModes() {
			return this.deliveryModes && this.deliveryModes.length > 1
		},
		deliveryModes() {
			if (this.menu.delivery_modes && this.menu.delivery_modes.length > 0) {
				return this.menu.delivery_modes.map((deliveryMode) => {
					return {
						id: deliveryMode.delivery_mode,
						text: this.$options.filters.deliveryModeName(deliveryMode.delivery_mode, this.business.type),
						isDefault: deliveryMode.is_default
					}
				})
			} else if (this.menu.delivery_mode != null) {
				return [{
					id: this.menu.delivery_mode,
					text: this.$options.filters.deliveryModeName(this.menu.delivery_mode, this.business.type),
					isDefault: true
				}]
			}
			return null
		},
		deliveryModeText() {
			if (!this.deliveryModes || this.deliveryModes.length == 0) {
				return "N/A"
			}

			if (this.hasMultipleDeliveryModes) {
				return "Multiple"
			} else {
				return this.deliveryModes[0].text
			}
		},
		editPath() {
			return `/business/${this.$route.params.business_slug}/menu/${this.menu.id}`
		},
		deleteAllowed() {
			return this.$store.state.permissions.includes(
				PermissionMethod.DELETE_BUSINESS_MENU
			)
		},
		editAllowed() {
			return this.$store.state.permissions.includes(
				PermissionMethod.EDIT_BUSINESS_PRODUCTS
			)
		},
		menuAvailability() {
			if (this.menu.is_backup) {
				return "(Backup)"
			}
			if (this.menu.is_late_night) {
				return "(Late Night)"
			}
			return "(Primary)"
		},
		menuCourier() {
			switch (this.menu.courier_id) {
				case CourierId.WALKING:
					return "Self-Delivery"
				case CourierId.CUT_CATS:
					return "Cut Cats"
				case CourierId.TOOKAN:
					return "Bar Self Delivery"
				case CourierId.POSTMATES:
					return "PostMates"
				case CourierId.DOORDASH:
					return "DoorDash"
				case CourierId.RELAY:
					return "Relay"
			}
			return "N/A"
		},
		menuName() {
			return this.menu.name
		},
		menuProductCount() {
			return this.menu.products == undefined
				? 0
				: this.menu.products.length
		},
		offset() {
			if (this.$store.state.businessesModule.currentBusiness) {
				return this.$store.state.businessesModule.currentBusiness
					.timezone_offset
			}
			return 0
		},
		menuSchedule() {
			return momentHelper.hoursToSchedule(
				this.menu.opening_hours,
				this.offset
			)
		},
		todaysSchedule() {
			const date = moment()
			let dow = date.day()
			const normalizedDow = dow == 0 ? 6 : dow - 1
			const todaysSchedule = this.menuSchedule.find((schedule) =>
				schedule.dows.includes(Number(normalizedDow))
			)
			if (todaysSchedule) {
				return {
					daysText: momentHelper.dowNames[normalizedDow],
					timesText: todaysSchedule.timesText
				}
			}
		},
		statusLabel() {
			return this.menu.stock_status == 1 ? "Active" : "Inactive"
		},
		statusClasses() {
			return this.menu.stock_status == 1
				? "status-dot green-pulsating"
				: "status-dot grey"
		},
		statusBadgeClasses() {
			return this.menu.stock_status == 1
				? "pill-alt pill-alt-green"
				: "pill-alt pill-alt-grey"
		},
		allowedActions() {
			let actions = [
				{
					text: `<i class="sk-icon-eye-solid"></i> Preview Menu`,
					id: 0
				}
			]

			if (this.deleteAllowed) {
				actions.push({
					text: `<i class="sk-icon-trash-alt-solid"></i> Delete`,
					id: 1
				})
			}

			return actions
		}
	},
	methods: {
		deleteMenu() {
			this.$emit("delete", this.menu)
		},
		actionSelected(action) {
			switch (action.id) {
				case 0: {
					let url = `${this.$webappUrl}/${this.$route.params.business_slug}/menu/preview/${this.menu.id}`
					window.open(url, "_blank")
					break
				}
				case 1: {
					this.deleteMenu()
					break
				}
			}
		},
		toggleMultipleDeliveryModes() {
			this.isDeliveryModesOpen = !this.isDeliveryModesOpen
		}
	}
}
</script>