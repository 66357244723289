<template>
	<div>
		<div class="sk-row sk-widget event-card" :style="backgroundImage">
			<div class="event-name">
				{{ eventTitle }}
			</div>
			<div>
				<div>
					<i class="sk-icon-hat-chef-solid"></i>
					<span v-sk-unassigned="restaurantName"></span>
				</div>
				<div>
					<i class="sk-icon-calendar-alt-regular"></i>
					<span v-sk-unassigned="eventDate"></span>
				</div>
			</div>
		</div>
		<div class="sk-row">
			<div class="sk-widget sk-event-review-widget">
				<div class="sk-widget-line-item-header">
					<span>Type</span>
					<button
						v-if="isEditable"
						class="button-link"
						@click="openEditModal(0)"
					>
						Edit
					</button>
				</div>
				<div class="sk-widget-line-item">
					<div>{{ event.type | eventTypeName }}</div>
				</div>
			</div>
		</div>
		<div class="sk-row">
			<div class="sk-widget sk-event-review-widget">
				<div class="sk-widget-line-item-header">
					<span>Details</span>
					<button
						v-if="isEditable"
						class="button-link"
						@click="openEditModal(1)"
					>
						Edit
					</button>
				</div>
				<div class="sk-widget-line-item">
					<div>Name</div>
					<div>{{ eventTitle }}</div>
				</div>
				<div class="sk-widget-line-item">
					<div>Description</div>
					<div v-sk-unassigned="event.description"></div>
				</div>
				<div class="sk-widget-line-item">
					<div>Business Name</div>
					<div v-sk-unassigned="business.name"></div>
				</div>
				<div class="sk-widget-line-item">
					<div>Event Coordinator Email</div>
					<div v-sk-unassigned="event.contact_email"></div>
				</div>
				<div class="sk-widget-line-item">
					<div>Event Notes</div>
					<div v-sk-unassigned="event.manifest_notes"></div>
				</div>
				<div class="sk-widget-line-item">
					<div>Event Request Notes</div>
					<div v-sk-unassigned="event.request_notes"></div>
				</div>
				<div v-if="event.type == eventTypes.POPUP" class="sk-widget-line-item">
					<div>Event App Login Code</div>
					<div v-sk-unassigned="event.token"></div>
				</div>
			</div>
		</div>
		<div class="sk-row">
			<div class="sk-widget sk-event-review-widget">
				<div class="sk-widget-line-item-header">
					<span>When</span>
					<button
						v-if="isEditable"
						class="button-link"
						@click="openEditModal(2)"
					>
						Edit
					</button>
				</div>
				<slot v-if="event.type == eventTypes.VIRTUAL">
					<div class="sk-widget-line-item">
						<div>Pre Orders Cutoff Date</div>
						<div v-sk-unassigned="eventCutoffDate"></div>
					</div>
					<div class="sk-widget-line-item">
						<div>Pre Orders Cutoff Time</div>
						<div v-sk-unassigned="eventCutoffTime"></div>
					</div>
					<div class="sk-widget-line-item">
						<div>Event Delivery Date</div>
						<div v-sk-unassigned="eventEndDate"></div>
					</div>
					<div class="sk-widget-line-item">
						<div>Event Delivery Time</div>
						<div v-sk-unassigned="eventEndTime"></div>
					</div>
				</slot>
				<slot v-if="event.type == eventTypes.POPUP">
					<div class="sk-widget-line-item">
						<div>Event Date</div>
						<div v-sk-unassigned="eventStartDate"></div>
					</div>
					<div class="sk-widget-line-item">
						<div>Start Time</div>
						<div v-sk-unassigned="eventStartTime"></div>
					</div>
					<div class="sk-widget-line-item">
						<div>End Time</div>
						<div v-sk-unassigned="eventEndTime"></div>
					</div>
					<div v-if="this.event.pre_order_end_time">
						<div class="sk-widget-line-item">
							<div>Pre Orders Cutoff Date</div>
							<div v-sk-unassigned="eventCutoffDate"></div>
						</div>
						<div class="sk-widget-line-item">
							<div>Pre Orders Cutoff Time</div>
							<div v-sk-unassigned="eventCutoffTime"></div>
						</div>
					</div>
				</slot>
			</div>
		</div>
		<div class="sk-row">
			<div class="sk-widget sk-event-review-widget">
				<div class="sk-widget-line-item-header">
					<span>Restaurant</span>
					<button
						v-if="isEditable"
						class="button-link"
						@click="openEditModal(3)"
					>
						Edit
					</button>
				</div>
				<div class="sk-widget-line-item">
					<div>Restaurant Name</div>
					<div v-sk-unassigned="restaurantName"></div>
				</div>
				<div class="sk-widget-line-item">
					<div>Menu Name</div>
					<div v-sk-unassigned="menuName"></div>
				</div>
				<div class="sk-widget-line-item">
					<div>Delivery Mode</div>
					<div>
						{{ event.delivery_mode | eventDeliveryModeName }}
					</div>
				</div>
				<div class="sk-widget-line-item" v-if="event.type == eventTypes.VIRTUAL">
					<div>Delivery Method</div>
					<div>
						{{ event.courier_id | deliveryMethodName }}
					</div>
				</div>
				<div class="sk-widget-line-item">
					<div class="menu-items-toggle" @click="toggleMenuItems">
						Menu Items
						<i
							class="sk-icon-chevron-down-regular"
							:class="{ flipped: menuItemsOpen }"
						></i>
					</div>
				</div>
				<transition-expand>
					<div v-if="menuItemsOpen">
						<div
							class="sk-widget-line-item"
							v-for="product in eventProducts"
							:key="product.id"
						>
							<div>{{ product.name }}</div>
							<div>{{ product.price | currency }}</div>
						</div>
					</div>
				</transition-expand>
			</div>
		</div>
	</div>
</template>

<style scoped>
.event-card {
	cursor: pointer;
	background-size: cover;
	background-position: center;
	margin-top: 15px !important;
	margin-bottom: 15px !important;
	height: 175px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.event-card .sk-muted-text {
	color: var(--sk-white) !important;
	font-style: italic;
}

.event-name,
.event-card span,
.event-card i {
	font-weight: 600;
	color: var(--sk-white);
}

.event-name {
	margin-bottom: 15px;
	font-size: 16px;
}

.event-card span {
	font-size: 12px;
}

.event-card i {
	margin-right: 10px;
	font-size: 10px;
}

.menu-items-toggle {
	color: var(--sk-grey2) !important;
	cursor: pointer;
}

.menu-items-toggle > i {
	font-size: 10px;
	margin-left: 10px;
}
</style>

<script>
import { MenuType } from "@arikgaisler/utils/enums/menuType"
import { EventStatus } from "@arikgaisler/utils/enums/eventStatus"
import { BusinessEventType } from "@arikgaisler/utils/enums/businessEventType"
import ActionTypes from "@/store/modules/events/action-types"
import eventStatusMixin from "@/mixins/events/event-status-mixin"
import eventMixin from "@/mixins/events/event-mixin"
import eventDisplayMixin from "@/mixins/events/event-display-mixin"
import currencyFilterMixin from "@/mixins/currency-filter-mixin"
import loaderMixin from "@/mixins/loader-mixin"
import TransitionExpand from "@/components/transitions/TransitionExpand.vue"

export default {
	name: "EventDetails",
	mixins: [
		loaderMixin,
		currencyFilterMixin,
		eventDisplayMixin,
		eventMixin,
		eventStatusMixin
	],
	components: {
		TransitionExpand
	},
	data: function() {
		return {
			eventTypes: BusinessEventType,
			eventStatus: EventStatus,
			menu: {},
			menuItemsOpen: false
		}
	},
	props: {
		event: Object
	},
	computed: {
		backgroundImage() {
			const image = this.event.image
				? `${this.$eventsCDNURL}${this.business.slug}/${this.event.image}`
				: "/images/event-banner-placeholder.png"

			let gradient =
				"linear-gradient(270deg, rgba(255, 133, 37, 0.38) 0%, rgba(255, 112, 0, 0.76) 100%)"
			return {
				"background-image": `${gradient}, url(${image})`
			}
		},
		eventTitle() {
			return this.event.name ? this.event.name : "Popup"
		},
		eventProducts() {
			return this.products
				? this.products.filter(product => product.addToEventMenu)
				: []
		},
		eventStartDate() {
			return this.$options.filters.formatEventDate(
				this.event.start_date,
				this.event.start_time,
				this.businessTimezone
			)
		},
		eventEndDate() {
			return this.$options.filters.formatEventDate(
				this.event.end_date,
				this.event.end_time,
				this.businessTimezone
			)
		},
		eventStartTime() {
			return this.$options.filters.timeWithOffset(
				this.event.start_date,
				this.event.start_time,
				this.businessTimezone
			)
		},
		eventEndTime() {
			return this.$options.filters.timeWithOffset(
				this.event.end_date,
				this.event.end_time,
				this.businessTimezone
			)
		},
		eventCutoffDate() {
			if (this.event.pre_order_end_date == null) {
				return this.$options.filters.formatEventDate(
					this.event.end_date,
					this.event.end_time,
					this.businessTimezone
				)
			} else {
				return this.$options.filters.formatEventDate(
					this.event.pre_order_end_date,
					this.event.pre_order_end_time,
					this.businessTimezone
				)
			}
		},
		eventCutoffTime() {
			return this.$options.filters.timeWithOffset(
				this.event.pre_order_end_date,
				this.event.pre_order_end_time,
				this.businessTimezone
			)
		},
		eventDate() {
			switch (this.event.type) {
				case BusinessEventType.VIRTUAL: {
					if (!this.event.start_date && !this.event.end_date) {
						return null
					} else {
						const date = this.event.end_date
							? this.event.end_date
							: this.event.start_date
						const time = this.event.end_date
							? this.event.end_time
							: this.event.start_time
						const formattedDate = this.$options.filters.formatEventDate(
							date,
							time,
							this.businessTimezone,
							"MMMM Do"
						)
						return `${formattedDate} ${this.eventEndTime}`
					}
				}
				case BusinessEventType.POPUP: {
					if (
						!this.event.start_date &&
						!this.eventStartTime &&
						!this.eventEndTime
					) {
						return null
					} else {
						const formattedStartDate = this.$options.filters.formatEventDate(
							this.event.start_date,
							this.event.start_time,
							this.businessTimezone,
							"MMMM Do"
						)
						const start = `${formattedStartDate} ${this.eventStartTime}`
						return `${start} - ${this.eventEndTime}`
					}
				}
			}
		},
		menus() {
			let menus = this.$store.state.eventsModule.restaurantMenus[
				this.event.restaurant_id
			]
			if (menus) {
				menus = menus.filter(menu => menu.type == MenuType.POPUP)
			}
			return menus
		},
		products() {
			if (this.menus) {
				let menu = this.menus.find(
					menu => menu.id == this.event.menu_id
				)
				if (menu && menu.products) {
					return menu.products
				}
			}
			return []
		}
	},
	methods: {
		setMenu() {
			if (
				this.menus &&
				this.menus.length > 0 &&
				this.event.menu_id &&
				!this.menu.id
			) {
				let menu = this.menus.find(
					menu => menu.id == this.event.menu_id
				)
				this.menu = menu ? Object.assign({}, menu) : {}
			}
		},
		getMenuProducts() {
			if (this.event.menu_id) {
				this.toggleLoader(true)
				this.$store
					.dispatch(
						`eventsModule/${ActionTypes.GET_EVENT_RESTAURANT_MENU_PRODUCTS}`,
						{
							menuId: this.event.menu_id,
							restaurantId: this.event.restaurant_id,
							products: this.event.products
						}
					)
					.finally(() => {
						this.toggleLoader(false)
					})
			}
		},
		toggleMenuItems() {
			this.menuItemsOpen = !this.menuItemsOpen
			if (this.menuItemsOpen) {
				this.getMenuProducts()
			}
		},
		openEditModal(step) {
			this.$emit("editEvent", {
				eventId: this.event.id,
				step: step
			})
		}
	},
	mounted: function() {
		this.setMenu()
	}
}
</script>