var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sk-row"},[_c('div',{staticClass:"sk-widget-alt"},[_c('div',{staticClass:"sk-row order-summary",on:{"click":function($event){return _vm.toggleOrderDetails()}}},[_c('div',[_c('div',{class:_vm.statusPillClass},[_vm._v(" "+_vm._s(_vm.statusText)+" ")]),_c('div',{staticClass:"order-identifier"},[_vm._v(_vm._s(_vm.order.id))]),_c('div',{staticClass:"pill pill-navy"},[_vm._v(" "+_vm._s(_vm._f("menuTypeText")(_vm.order.menu_type))+" ")]),(_vm.isPreOrder)?_c('div',{staticClass:"pill pill-navy"},[_vm._v("Preorder")]):_vm._e(),(_vm.isInRoomBilling && _vm.paymentStatus != null)?_c('div',{class:_vm.inRoomBillingStatusBadgeClasses},[_c('i',{class:_vm.inRoomBillingStatusIcon}),_c('b',[_vm._v("Charge to Room:")]),_c('i',[_vm._v(_vm._s(_vm.inRoomBillingStatusText))])]):_vm._e()]),(_vm.actions.length > 0)?_c('SkDropdownSelect',{attrs:{"options":_vm.actions,"iconClasses":'sk-icon-ellipsis-v-regular',"showSelectedOption":false,"position":'left'},on:{"selected":_vm.actionSelected}}):_vm._e()],1),_c('transition-expand',[(_vm.isExpanded)?_c('div',[_c('ul',{staticClass:"order-tabs sk-page-tabs"},_vm._l((_vm.orderDetailTabs),function(tab){return _c('li',{key:tab.id,class:{ 'active-tab': tab.active },on:{"click":function($event){return _vm.orderDetailTabClicked(tab)}}},[_vm._v(" "+_vm._s(tab.text)+" "),(tab.badgeCount)?_c('span',{staticClass:"tab-badge"},[_vm._v(" "+_vm._s(tab.badgeCount)+" ")]):_vm._e()])}),0),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.activeDetailsTab.id == 0)?_c('div',_vm._l((_vm.order.sub_orders),function(suborder,index){return _c('div',{key:index},[_c('SuborderHeader',{attrs:{"order":_vm.order,"suborder":suborder,"business":_vm.business,"restaurantId":suborder.restaurant.id,"expandedSuborder":_vm.expandedSuborder,"index":index},on:{"toggleExpand":function($event){return _vm.expand(suborder)}}}),_c('transition-expand',[(
										(_vm.expandedSuborder &&
											suborder.id ==
												_vm.expandedSuborder.id) ||
										_vm.order.sub_orders.length == 1
									)?_c('OrderReportStatus',{attrs:{"item":suborder,"order":_vm.order}}):_vm._e()],1)],1)}),0):_vm._e(),(_vm.activeDetailsTab.id == 1)?_c('div',_vm._l((_vm.order.sub_orders),function(suborder,index){return _c('div',{key:index},[_c('SuborderHeader',{attrs:{"order":_vm.order,"suborder":suborder,"business":_vm.business,"restaurantId":suborder.restaurant.id,"expandedSuborder":_vm.expandedSuborder,"index":index},on:{"toggleExpand":function($event){return _vm.expand(suborder)}}}),_c('transition-expand',[(
										(_vm.expandedSuborder &&
											suborder.id ==
												_vm.expandedSuborder.id) ||
										_vm.order.sub_orders.length == 1
									)?_c('OrderProducts',{attrs:{"item":suborder,"order":_vm.order,"business":_vm.business,"type":1}}):_vm._e()],1)],1)}),0):_vm._e(),(_vm.activeDetailsTab.id == 2)?_c('div',[_c('OrderActions',{attrs:{"order":_vm.order,"orderActions":_vm.actions},on:{"actionSelected":_vm.actionSelected}})],1):_vm._e(),(_vm.activeDetailsTab.id == 3)?_c('div',_vm._l((_vm.suborderWarnings),function(suborder,index){return _c('div',{key:index},[_c('SuborderHeader',{attrs:{"order":_vm.order,"suborder":suborder.suborder,"business":_vm.business,"restaurantId":suborder.suborder.restaurant.id,"expandedSuborder":_vm.expandedSuborder,"index":index},on:{"toggleExpand":function($event){return _vm.expand(suborder)}}}),_c('transition-expand',[_c('OrderWarnings',{attrs:{"warnings":suborder.warnings}})],1)],1)}),0):_vm._e(),(_vm.activeDetailsTab.id == 4)?_c('div',[_c('OrderNotes',{attrs:{"notes":_vm.notes}})],1):_vm._e()])],1):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }