<template>
	<div class="sk-widget-alt supply-item">
		<img class="supply-item-img" :src="imgSrc" alt="Product Image" />
		<div class="supply-item-details">
			<div class="supply-item-name">{{ item.name }}</div>
			<div class="supply-item-description">{{ item.description }}</div>
		</div>
		<button class="button button-primary button-rounded" @click="requestSupply">
			<i class="sk-icon-plus-regular"></i>
		</button>
	</div>
</template>

<style scoped>
.supply-item {
    height: 150px;
    display: flex;
    align-items: center;
}

.supply-item-img {
    height: 100px;
}

.supply-item-details {
    flex: 1;
    height: 100%;
}

.supply-item-name {
    font-weight: 600;
    color: var(--sk-dark-navy);
    font-size: 14px;
}

.supply-item-description {
    font-size: 12px;
    color: var(--sk-grey2);
}

.button-rounded i {
    margin-right: 0 !important;
}
</style>

<script>
export default {
	name: "SupplyItem",
	props: {
		item: Object
	},
	computed: {
		imgSrc() {
			return this.$chefCDNURL + "supplies/" + this.item.img
		}
	},
	methods: {
        requestSupply() {
            this.$emit("request", this.item)
        }
    }
}
</script>