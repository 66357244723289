var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order-status-cards"},[_c('div',{staticClass:"sk-widget",class:{
			complete: !_vm.isCancelledOrder,
			danger: _vm.isCancelledOrder
		}},[_c('div',{staticClass:"status-indicator"}),_vm._m(0),_c('div',{staticClass:"status-detail"},[_c('div',[_vm._v("Order Placed")]),_c('div',[_vm._v(_vm._s(_vm._f("formatTime")(_vm.createdDate,_vm.formattedTimezone)))])]),_c('div',{staticClass:"status-detail"},[_c('div',[_vm._v("Customer")]),_c('div',{staticClass:"text-truncate",attrs:{"title":_vm.name}},[_vm._v(_vm._s(_vm.name)+" "+_vm._s(_vm.historyDetailsGateText))])]),_c('div',{staticClass:"status-detail"},[_c('div',[_vm._v("Phone")]),_c('div',[_vm._v(_vm._s(_vm._f("formatInternationalPhone")(_vm.phone)))])]),_c('div',{staticClass:"status-detail"},[_c('div',[_vm._v("Payment")]),_c('div',[_vm._v(_vm._s(_vm.paymentMethod))])]),_c('div',{staticClass:"status-detail"},[_c('div',[_vm._v("Placement")]),_c('div',[_vm._v(_vm._s(_vm.orderPlatform))])]),_c('div',{staticClass:"status-detail"},[_c('div',[_vm._v("Delivery Type")]),_c('div',[_vm._v(_vm._s(_vm.pickupMethod))])]),_c('div',{staticClass:"status-detail"},[_c('div',[_vm._v("Delivery Time")]),_c('div',[_vm._v(_vm._s(_vm.scheduledDate))])])]),(_vm.isUpcomingOrder)?_c('div',{staticClass:"sk-widget",class:{
			complete: _vm.received,
			danger: (_vm.orderTimeinMins >= 5 && !_vm.received) || _vm.isCancelledOrder
		}},[_c('div',{staticClass:"status-indicator"}),_c('div',{staticClass:"status-title"},[_c('i',{staticClass:"sk-icon-check-regular"}),_vm._v(" "+_vm._s(_vm.receivedStatusTitle)+" ")]),_c('div',{staticClass:"status-detail"},[_c('div',[_vm._v("Order Received")]),(_vm.received)?_c('div',[_vm._v(" "+_vm._s(_vm._f("formatTime")(_vm.receivedDate,_vm.formattedTimezone))+" ")]):_c('div',[_vm._v("--")])]),_c('div',{staticClass:"status-detail"},[_c('div',[_vm._v("Restaurant")]),_c('router-link',{staticClass:"pill pill-grey",attrs:{"target":"_blank","to":("/restaurant/" + (_vm.restaurant.id)),"title":_vm.restaurant.name}},[_c('span',{staticClass:"text-truncate"},[_vm._v(_vm._s(_vm.restaurant.name))])])],1),(_vm.restPhone)?_c('div',{staticClass:"status-detail"},[_c('div',[_vm._v("Phone")]),_c('div',_vm._l((_vm.restPhone),function(phone,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(_vm._f("formatInternationalPhone")(phone))+" ")])}),0)]):_vm._e(),_c('div',{staticClass:"status-detail"},[_c('div',[_vm._v("Address")]),_c('div',[_vm._v(_vm._s(_vm.restaurant.address))])])]):_vm._e(),_c('div',{staticClass:"sk-widget",class:{
			complete: _vm.accepted,
			danger: (_vm.orderTimeinMins >= 5 && !_vm.accepted) || _vm.isCancelledOrder
		}},[_c('div',{staticClass:"status-indicator"}),_c('div',{staticClass:"status-title"},[_c('i',{staticClass:"sk-icon-check-regular"}),_vm._v(" "+_vm._s(_vm.acceptStatusTitle)+" ")]),_c('div',{staticClass:"status-detail"},[_c('div',[_vm._v("Order Accepted")]),(_vm.accepted)?_c('div',[_vm._v(" "+_vm._s(_vm._f("formatTime")(_vm.acceptedDate,_vm.formattedTimezone))+" ")]):_c('div',[_vm._v("--")])]),_c('div',{staticClass:"status-detail"},[_c('div',[_vm._v("Restaurant")]),_c('router-link',{staticClass:"pill pill-grey",attrs:{"target":"_blank","to":("/restaurant/" + (_vm.restaurant.id)),"title":_vm.restaurant.name}},[_c('span',{staticClass:"text-truncate"},[_vm._v(_vm._s(_vm.restaurant.name))])])],1),(_vm.restPhone)?_c('div',{staticClass:"status-detail"},[_c('div',[_vm._v("Phone")]),_c('div',_vm._l((_vm.restPhone),function(phone,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(_vm._f("formatInternationalPhone")(phone))+" ")])}),0)]):_vm._e(),_c('div',{staticClass:"status-detail"},[_c('div',[_vm._v("Address")]),_c('div',[_vm._v(_vm._s(_vm.restaurant.address))])])]),_c('div',{staticClass:"sk-widget",class:{
			complete: _vm.acknowledged,
			danger:
				(_vm.orderTimeinMins >= 15 && !_vm.acknowledged && _vm.accepted) ||
				_vm.isCancelledOrder
		}},[_c('div',{staticClass:"status-indicator"}),_c('div',{staticClass:"status-title"},[_c('i',{staticClass:"sk-icon-check-regular"}),_vm._v(" "+_vm._s(_vm.acknowledgeStatusTitle)+" ")]),_c('div',{staticClass:"status-detail"},[_c('div',[_vm._v("Order Acknowledged")]),_c('div',[_vm._v(" "+_vm._s(_vm._f("formatTime")(_vm.acknowledgedDate,_vm.formattedTimezone))+" ")])]),_c('div',{staticClass:"status-detail"},[_c('div',[_vm._v("Courier")]),_c('div',[_vm._v(" "+_vm._s(_vm.courier)+" ")])])]),_c('div',{staticClass:"sk-widget",class:{
			complete: _vm.pickedUp,
			danger:
				(_vm.orderTimeinMins >= 30 && !_vm.pickedUp && _vm.acknowledged) ||
				_vm.isCancelledOrder
		}},[_c('div',{staticClass:"status-indicator"}),_c('div',{staticClass:"status-title"},[_c('i',{staticClass:"sk-icon-check-regular"}),_vm._v(" "+_vm._s(_vm.pickUpStatusTitle)+" ")]),_c('div',{staticClass:"status-detail"},[_c('div',[_vm._v("Order Picked Up")]),(_vm.pickedUp)?_c('div',[_vm._v(" "+_vm._s(_vm._f("formatTime")(_vm.pickedUpDate,_vm.formattedTimezone))+" ")]):_c('div',[_vm._v("--")])]),_c('div',{staticClass:"status-detail"},[_c('div',[_vm._v("Courier")]),_c('div',[_vm._v(" "+_vm._s(_vm.courier)+" ")])]),(_vm.isLastYard)?_c('div',{staticClass:"status-detail"},[_c('div',[_vm._v("Last Yard")]),_c('div',[_vm._v(_vm._s(_vm.lastYardDeliveryStatusText))])]):_vm._e()]),_c('div',{staticClass:"sk-widget",class:{
			complete: _vm.delivered,
			danger:
				(_vm.orderTimeinMins >= 45 && !_vm.delivered && _vm.pickedUp) ||
				_vm.isCancelledOrder
		}},[_c('div',{staticClass:"status-indicator"}),_c('div',{staticClass:"status-title"},[_c('i',{staticClass:"sk-icon-check-regular"}),_vm._v(" "+_vm._s(_vm.deliveryStatusTitle)+" ")]),_c('div',{staticClass:"status-detail"},[_c('div',[_vm._v("Order Delivered")]),(_vm.delivered)?_c('div',[_vm._v(" "+_vm._s(_vm._f("formatTime")(_vm.deliveredDate,_vm.formattedTimezone))+" ")]):_c('div',[_vm._v("--")])]),_c('div',{staticClass:"status-detail"},[_c('div',[_vm._v("Business")]),_c('router-link',{staticClass:"pill pill-grey",attrs:{"target":"_blank","to":("/business/" + (_vm.business.slug)),"title":_vm.business.name}},[_c('span',{staticClass:"text-truncate"},[_vm._v(_vm._s(_vm.business.name))])])],1),_c('div',{staticClass:"status-detail"},[_c('div',[_vm._v("Type")]),_c('div',[_vm._v(_vm._s(_vm.businessType))])]),_c('div',{staticClass:"status-detail"},[_c('div',[_vm._v("Pickup Method")]),_c('div',[_vm._v(_vm._s(_vm.pickupMethod))])]),(_vm.businessPhone)?_c('div',{staticClass:"status-detail"},[_c('div',[_vm._v("Phone")]),_c('div',_vm._l((_vm.businessPhone),function(phone,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(_vm._f("formatInternationalPhone")(phone))+" ")])}),0)]):_vm._e(),_c('div',{staticClass:"status-detail"},[_c('div',[_vm._v("Address")]),_c('div',[_vm._v(_vm._s(_vm.business.address))])]),(_vm.deliveryStatusLocker)?_c('div',{staticClass:"status-detail"},[_c('div',[_vm._v("Locker Status")]),_c('div',[_vm._v(_vm._s(_vm.lockerDeliveryStatus))])]):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"status-title"},[_c('i',{staticClass:"sk-icon-check-regular"}),_vm._v(" Order Placed ")])}]

export { render, staticRenderFns }