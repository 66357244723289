<template>
	<div class="admin-active-orders">
		<transition-group
			v-if="!loading && pagedOrders.length > 0"
			name="list"
			tag="div"
			appear
		>
			<CurrentOrder
				v-for="order in pagedOrders"
				:key="order.id"
				:order="order"
				@openSurvey="openSurveyModal"
				@openReceipt="openReceiptModal"
				@openCoupon="openCouponModal"
				@openException="openExceptionModal"
				@openRefund="openRefundModal"
				@openDelivery="openDeliveryModal"
				@openCancel="openCancelModal"
				@openNotes="openNotesModal"
				@repushOrder="repushOrder"
			/>
		</transition-group>
		<EmptyPlaceholderWidget
			v-else-if="!loading"
			:title="'No Active Orders'"
			:buttonText="'Refresh'"
			:buttonIcon="'sk-icon-sync-regular'"
			@actionClicked="refreshPage"
		/>
		<PaginationFooter
			ref="paginationFooter"
			:contentText="'Active Orders'"
			:pageSize="20"
			:total="filteredOrders.length"
			@pageChanged="filterPage"
		/>
		<OrderDeliveryModal ref="orderDeliveryModal" />
		<OrderCouponModal ref="couponModal" />
		<ExceptionModal ref="exceptionModal" />
		<RefundModal ref="refundModal" />
		<SendReceiptModal ref="sendReceiptModal" />
		<AddOrderNoteModal ref="orderNoteModal" />
		<ConfirmationModal ref="confirmationModal" />
	</div>
</template>

<style scoped>
.admin-active-orders {
	height: 100%;
	display: flex;
	flex-direction: column;
}
</style>

<script>
import moment from "moment"
import orderHelper from "@/utils/order-helper"
import { OrderStatusFilter } from "@/enums/orderStatusFilter"
import { CourierId } from "@arikgaisler/utils/enums/courierId"
import { ConfirmationModalType } from "@/enums/confirmationModalType"
import { MenuType } from "@arikgaisler/utils/enums/menuType"
import ActionTypes from "@/store/modules/orders/action-types"
import loaderMixin from "@/mixins/loader-mixin"
import CurrentOrder from "@/components/currentorders/CurrentOrder.vue"
import EmptyPlaceholderWidget from "@/components/EmptyPlaceholderWidget.vue"
import PaginationFooter from "@/components/PaginationFooter.vue"
import OrderDeliveryModal from "@/components/modals/OrderDeliveryModal.vue"
import OrderCouponModal from "@/components/modals/OrderCouponModal.vue"
import ExceptionModal from "@/components/modals/ExceptionModal.vue"
import RefundModal from "@/components/modals/RefundModal.vue"
import SendReceiptModal from "@/components/modals/SendReceiptModal.vue"
import AddOrderNoteModal from "@/components/modals/AddOrderNoteModal.vue"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue"

export default {
	name: "AdminActiveOrders",
	mixins: [loaderMixin],
	components: {
		CurrentOrder,
		EmptyPlaceholderWidget,
		PaginationFooter,
		OrderDeliveryModal,
		OrderCouponModal,
		ExceptionModal,
		RefundModal,
		SendReceiptModal,
		AddOrderNoteModal,
		ConfirmationModal
	},
	props: {
		filterVals: Object,
		filterUpcomingOrders: {
			type: Boolean,
			default: false
		},
		pusher: Object
	},
	data() {
		return {
			pageRange: {
				page: 1,
				start: 0,
				end: 20
			},
			timer: null,
			disconnected: false
		}
	},
	mounted: async function () {
		this.$store.dispatch("businessesModule/clearBusiness")
		this.$store.dispatch("ordersModule/getCurrentOrdersData", true)
		if (this.timer == null) {
			let lastTimerRun = null
			this.timer = setInterval(() => {
				let now = new Date().getTime()
				this.$store.dispatch(
					`ordersModule/${ActionTypes.REMOVE_OLD_ORDERS}`,
					false
				)
				if (lastTimerRun && now - lastTimerRun > 900000) {
					this.$store.dispatch("ordersModule/updateOrderActions", {
						orders: this.currentOrders,
						orderIdsStr: this.currentOrders.map((order) => order.id)
					})
				}
				lastTimerRun = now
			}, 60000)
		}
		this.pusher.pusherClient.connection.bind("state_change", this.handlePusherStateChange)
	},
	computed: {
		loading() {
			return this.$store.state.ordersModule.loading
		},
		userId() {
			return this.$store.state.user_id
		},
		currentOrders() {
			return this.$store.state.ordersModule.currentOrders
				? this.$store.state.ordersModule.currentOrders
				: []
		},
		filteredOrders() {
			return this.currentOrders.filter((order) => {
				const isUpcomingOrder = orderHelper.isUpcomingOrder(order)
				if (this.filterUpcomingOrders && !isUpcomingOrder) {
					return false
				} else if (!this.filterUpcomingOrders && isUpcomingOrder) {
					return false
				}

				if (
					this.filterVals.orderId != null &&
					order.id != this.filterVals.orderId
				) {
					return false
				}

				if (this.filterVals.orderLabelId != null) {
					switch (this.filterVals.orderLabelId) {
						case 0: {
							const business = order
								? this.$store.getters.businessesMap[
										order.business_id
								  ]
								: null
							if (business && business.launch_date) {
								if (
									moment().diff(
										business.launch_date,
										"weeks"
									) > 2
								) {
									return false
								}
							}
							break
						}
						case 1: {
							if (
								!orderHelper.isPreorder(
									order,
									order.sub_orders[0]
								)
							) {
								return false
							}
							break
						}
						case 2: {
							if (
								order.menu_type != MenuType.ECOMMERCE &&
								order.menu_type != MenuType.DONATION
							) {
								return false
							}
							break
						}
						case 3: {
							if (order.menu_type != MenuType.POPUP) {
								return false
							}
							break
						}
					}
				}

				if (this.filterVals.courierId != null) {
					const hasCourierId = order.sub_orders.some(
						(suborder) =>
							suborder.courier_id == this.filterVals.courierId
					)
					if (!hasCourierId) {
						return false
					}
				}

				if (this.filterVals.warningType != null) {
					if (order.warnings && order.warnings.length != 0) {
						const hasWarningType = order.warnings.some(
							(warning) =>
								warning.type == this.filterVals.warningType
						)
						if (!hasWarningType) {
							return false
						}
					} else {
						return false
					}
				}

				if (this.filterVals.orderType != null) {
					if (order.menu_type != this.filterVals.orderType) {
						return false
					}
				}

				if (this.filterVals.claimStatus != -1) {
					if (
						this.filterVals.claimStatus == 1 &&
						order.admin_user_id != this.userId
					) {
						return false
					} else if (
						this.filterVals.claimStatus == 2 &&
						order.needs_supervisor != 1
					) {
						return false
					}
				}

				if (this.filterVals.orderStatus != null) {
					const cancelled = order.cancelled == 1
					const accepted = order.sub_orders.some(
						(suborder) =>
							suborder.accept_time != null &&
							suborder.accept_time != undefined &&
							suborder.accept_time != ""
					)
					const acknowledged = order.sub_orders.some((suborder) => {
						if (suborder.courier_id == CourierId.WALKING) {
							return (
								suborder.accept_time != null &&
								suborder.accept_time != undefined &&
								suborder.accept_time != ""
							)
						} else {
							return (
								suborder.acknowledged_time != null &&
								suborder.accept_time != undefined &&
								suborder.accept_time != ""
							)
						}
					})
					const pickedUp = order.sub_orders.some((suborder) => {
						if (suborder.courier_id == CourierId.WALKING) {
							return (
								suborder.completed_time != null &&
								suborder.completed_time != undefined &&
								suborder.completed_time != ""
							)
						} else {
							return (
								suborder.pickup_time != null &&
								suborder.pickup_time != undefined &&
								suborder.pickup_time != ""
							)
						}
					})

					const delivered = order.sub_orders.some((suborder) => {
						if (suborder.courier_id == CourierId.WALKING) {
							return (
								suborder.completed_time != null &&
								suborder.completed_time != undefined &&
								suborder.completed_time != ""
							)
						} else {
							return (
								suborder.delivered_time != null &&
								suborder.delivered_time != undefined &&
								suborder.delivered_time != ""
							)
						}
					})

					let excludeStatus = false
					let passesOneStatus = false

					if (
						this.filterVals.orderStatus.includes(
							OrderStatusFilter.AWAITING_ACCEPT
						) &&
						!passesOneStatus
					) {
						excludeStatus = accepted
						passesOneStatus = excludeStatus ? false : true
					}
					if (
						this.filterVals.orderStatus.includes(
							OrderStatusFilter.AWAITING_ACKNOWLEDGE
						) &&
						!passesOneStatus
					) {
						excludeStatus = acknowledged || !accepted
						passesOneStatus = excludeStatus ? false : true
					}
					if (
						this.filterVals.orderStatus.includes(
							OrderStatusFilter.AWAITING_PICKUP
						) &&
						!passesOneStatus
					) {
						excludeStatus = pickedUp || !accepted || !acknowledged
						passesOneStatus = excludeStatus ? false : true
					}
					if (
						this.filterVals.orderStatus.includes(
							OrderStatusFilter.AWAITING_DELIVERY_CONFIRMATION
						) &&
						!passesOneStatus
					) {
						excludeStatus =
							delivered || !accepted || !acknowledged || !pickedUp
						passesOneStatus = excludeStatus ? false : true
					}
					if (
						this.filterVals.orderStatus.includes(
							OrderStatusFilter.DELIVERED
						) &&
						!passesOneStatus
					) {
						excludeStatus = !delivered
						passesOneStatus = excludeStatus ? false : true
					}

					if (
						this.filterVals.orderStatus.includes(
							OrderStatusFilter.CANCELLED
						) &&
						!cancelled
					) {
						excludeStatus = true
					}
					if (
						!this.filterVals.orderStatus.includes(
							OrderStatusFilter.CANCELLED
						) &&
						cancelled
					) {
						excludeStatus = true
					}

					if (excludeStatus) {
						return false
					}
				}

				if (
					this.filterVals.businessId > 0 &&
					order.business_id != this.filterVals.businessId
				) {
					return false
				}

				if (this.filterVals.username) {
					const orderUsername = order.user_name
						.toLowerCase()
						.normalize("NFD")
						.replace(/[\u0300-\u036f]/g, "")
					const usernameFilter = this.filterVals.username
						.toLowerCase()
						.normalize("NFD")
						.replace(/[\u0300-\u036f]/g, "")
					if (!orderUsername.includes(usernameFilter)) {
						return false
					}
				}

				if (
					this.filterVals.phone &&
					!order.user_phone
						.replace(/[()\s-]/g, "")
						.includes(this.filterVals.phone.replace(/[()\s-]/g, ""))
				) {
					return false
				}

				return true
			})
		},
		pagedOrders() {
			return this.filteredOrders.slice(
				this.pageRange.start,
				this.pageRange.end
			)
		}
	},
	methods: {
		refreshPage() {
			location.reload()
		},
		filterPage(range) {
			this.pageRange = range
		},
		openReceiptModal(order) {
			this.$store.dispatch("ordersModule/setCurrentOrder", {
				order: order,
				refresh: true
			})
			this.$refs.sendReceiptModal.open()
		},
		openCouponModal(order) {
			this.$store.dispatch("ordersModule/setCurrentOrder", {
				order: order,
				refresh: true
			})
			this.$refs.couponModal.open()
		},
		openExceptionModal(order) {
			this.$store.dispatch("ordersModule/setCurrentOrder", {
				order: order,
				refresh: true
			})
			this.$refs.exceptionModal.open()
		},
		openRefundModal(order) {
			this.$store.dispatch("ordersModule/setCurrentOrder", {
				order: order,
				refresh: true
			})
			this.$refs.refundModal.open()
		},
		async openSurveyModal(order) {
			this.$store.dispatch("ordersModule/setCurrentOrder", {
				order: order,
				refresh: true
			})
			const confirmed = await this.$refs.confirmationModal.open({
				title: "Send Manual Survey:",
				subtitle: `#${order.id}`,
				body: "Are you sure you want to send a manual survey?",
				confirmation: "Send Survey",
				confirmationIcon: "sk-icon-check-regular",
				cancel: "Close"
			})
			if (confirmed) {
				this.$store.dispatch(
					"ordersModule/sendSurvey",
					this.$store.state.ordersModule.currentOrder.id
				)
				this.$store.dispatch(
					"ordersModule/orderStatusUpdate",
					this.$store.state.ordersModule.currentOrder.id
				)
			}
		},
		async openDeliveryModal(order) {
			this.$store.dispatch("ordersModule/setCurrentOrder", {
				order: order,
				refresh: true
			})
			this.$refs.orderDeliveryModal.open()
		},
		async openNotesModal(order) {
			this.$store.dispatch("ordersModule/setCurrentOrder", {
				order: order,
				refresh: true
			})
			this.$refs.orderNoteModal.open()
		},
		async openCancelModal(order) {
			this.$store.dispatch("ordersModule/setCurrentOrder", {
				order: order,
				refresh: true
			})
			const confirmed = await this.$refs.confirmationModal.open({
				title: "Are you sure you want to cancel this order?",
				body: "This cannot be undone",
				confirmation: "Cancel Order",
				confirmationIcon: "sk-icon-times-regular",
				cancel: "Close",
				type: ConfirmationModalType.RED
			})
			if (confirmed) {
				let currentOrder = this.$store.state.ordersModule.currentOrder
				this.$store.dispatch("ordersModule/cancelOrder", {
					orderId: currentOrder.id,
					suborderId: currentOrder.sub_orders[0].id
				})
			}
		},
		repushOrder(order) {
			this.$emit("repushOrder", order)
		},
		handlePusherStateChange(states) {
			if (
				states.previous == "connected" &&
				states.current == "connecting"
			) {
				this.disconnected = true
			} else if (
				states.previous == "connecting" &&
				states.current == "connected"
			) {
				if (this.disconnected) {
					this.$store.dispatch(
						"ordersModule/getCurrentOrdersData",
						true
					)
				}
				this.disconnected = false
			}
		}
	},
	watch: {
		loading: {
			immediate: true,
			handler(newVal) {
				this.toggleLoader(newVal)
			}
		},
		filterVals: {
			immediate: true,
			handler() {
				if (this.$refs.paginationFooter) {
					this.$refs.paginationFooter.pageChanged(1)
				}
			}
		}
	},
	beforeDestroy: function () {
		if (this.timer != null) {
			clearInterval(this.timer)
			this.timer = null
		}
	},
	destroyed: function () {
		this.pusher.pusherClient.connection.unbind(
			"state_change",
			this.handlePusherStateChange
		)
	}
}
</script>