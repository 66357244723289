<template>
	<div class="sk-widget-alt packaged-product-quantity-input">
		<div class="product-icon">
			<i :class="productIconClass"></i>
		</div>
		<div class="product-details">
			<div class="product-info">
				<div class="product-name">
					{{ product.name }}
				</div>
				<div
					@click="showDescription = !showDescription"
					class="product-description-dropdown"
				>
					Description
					<i
						class="sk-icon-chevron-down-regular flippable"
						:class="{ flip: showDescription }"
					></i>
				</div>
			</div>
			<transition-expand>
				<div v-if="showDescription" class="packaged-product-description">
					{{ product.description }}
				</div>
			</transition-expand>
		</div>
		<SkInput
			v-model="model"
			ref="quantityInput"
			class="quantity-input"
			:name="'# Amount'"
			:type="'number'"
			:required="true"
			:validation="/^[1-9]\d*$/"
		/>
	</div>
</template>

<style scoped>
.packaged-product-quantity-input {
	display: flex;
}

.product-icon {
	height: 50px;
	display: flex;
	align-items: center;
}

.product-icon i {
	font-size: 25px;
	color: var(--sk-dark-navy);
	margin-right: 25px;
	height: 25px;
}

.product-details {
	flex: 1;
	min-width: 0;
}

.product-info {
	height: 50px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.product-name {
	font-size: 14px;
	font-weight: 600;
	color: var(--sk-dark-navy);
	margin-bottom: 8px;
}

.packaged-product-description {
	padding-right: 10px;
}

.product-description-dropdown,
.packaged-product-description {
	font-size: 12px;
}

.product-description-dropdown {
	color: var(--sk-grey2);
	cursor: pointer;
}

.product-description-dropdown i {
	margin-left: 5px;
	font-size: 10px;
}

.packaged-product-description {
	color: var(--sk-grey3);
}

.quantity-input {
	width: 100px;
}
</style>

<script>
import { UniqueProductLabelId } from "@arikgaisler/utils/enums/uniqueProductLabelId"
import TransitionExpand from "@/components/transitions/TransitionExpand.vue"
import SkInput from "@/components/SkInput.vue"

export default {
	name: "PrepackagedProductQuantityInput",
	components: {
		TransitionExpand,
		SkInput
	},
	data: function () {
		return {
			showDescription: false
		}
	},
	props: {
		product: Object,
		value: Number
	},
	computed: {
		productIconClass() {
			let iconClass = "sk-icon-sandwich-solid"
			if (this.product.labels) {
				const labelIds = this.product.labels.map((label) => label.id)
				if (labelIds.includes(UniqueProductLabelId.GLUTEN_FREE)) {
					return "sk-icon-wheat-solid"
				} else if (labelIds.includes(UniqueProductLabelId.VEGAN)) {
					return "sk-icon-leaf-heart-solid"
				} else if (labelIds.includes(UniqueProductLabelId.VEGETARIAN)) {
					return "sk-icon-carrot-solid"
				}
			}
			return iconClass
		},
		model: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit("input", val)
			}
		}
	},
	methods: {
		validate() {
			return this.$refs.quantityInput.validate()
		},
		resetValidation() {
			return this.$refs.quantityInput.resetValidation()
		}
	}
}
</script>