export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA"
export const FILTER_BY_RESTAURANT = "FILTER_BY_RESTAURANT"
export const GET_AVERAGE_RATING = "GET_AVERAGE_RATING"
export const GET_TODAYS_BUSINESSES = "GET_TODAYS_BUSINESSES"
export const GET_TODAYS_EVENTS = "GET_TODAYS_EVENTS"
export const GET_RECENT_ORDERS = "GET_RECENT_ORDERS"

export default {
  GET_DASHBOARD_DATA,
  FILTER_BY_RESTAURANT,
  GET_AVERAGE_RATING,
  GET_TODAYS_BUSINESSES,
  GET_TODAYS_EVENTS,
  GET_RECENT_ORDERS
}