<template>
  <div class="dropzone-message">
    <div>
      <vue-dropzone 
        :id="dropzoneId" 
        :options="dropOptions" 
        :awss3="awss3" 
        :useCustomSlot="true"
        :includeStyling="false"
        @vdropzone-success="success"
        @vdropzone-processing="loading(true)"
        @vdropzone-queue-complete="loading(false)">
        <slot></slot>
      </vue-dropzone>
    </div>
  </div>
</template>

<style scoped>
.dz-message {
  display: block;
}
* >>> .dz-preview {
  display: none;
}
.dropzone-message {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
</style>

<script>
import imageUploadMixin from "@/mixins/image-upload-mixin"
export default {
  name: "FileUpload",
  mixins: [imageUploadMixin]
}
</script>