<template>
	<div class="sk-table-row">
		<div class="sk-table-column-fit">
			<b>{{ orderDay }}</b>
			{{ orderDate }}
		</div>
		<div class="sk-table-column-fit">{{ orderQuantity }}</div>
		<div>{{ orderPrice | currency }}</div>
		<div>{{ orderIssuer }}</div>
		<div v-if="canFilterForBusiness">{{ businessName }}</div>
		<div>
			<div :title="statusLabel" :class="statusBadgeClasses">
				<span :class="statusClasses"></span>
				{{ statusLabel }}
			</div>
		</div>
		<div class="sk-table-column-fit">
			<div class="sk-table-actions">
				<button
					v-if="!prepackageCutoffPassed"
					@click="editPrepackage"
					class="button button-icon"
					title="Edit"
				>
					<i class="sk-icon-pencil-solid"></i>
				</button>
				<button
					v-else
					@click="actionSelected({ id: 0 })"
					class="button button-icon"
					title="View"
				>
					<i class="sk-icon-eye-solid"></i>
				</button>
				<SkDropdownSelect
					v-if="actions.length > 0"
					:options="actions"
					:iconClasses="'sk-icon-ellipsis-v-regular'"
					:showSelectedOption="false"
					:position="'left'"
					@selected="actionSelected"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import currencyFilterMixin from "@/mixins/currency-filter-mixin"
import prepackagedOrderMixin from "@/mixins/prepackaged-order-mixin"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"

export default {
	name: "PrepackagesListOrderLineItem",
	components: {
		SkDropdownSelect
	},
	mixins: [currencyFilterMixin, prepackagedOrderMixin],
	props: {
		order: Object,
		canFilterForBusiness: {
			type: Boolean,
			default: false
		}
	}
}
</script>