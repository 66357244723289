import { BusinessEventType } from "@arikgaisler/utils/enums/businessEventType"
import { DeliveryMode } from "@arikgaisler/utils/enums/deliveryMode"
import { CourierId } from "@arikgaisler/utils/enums/courierId"
import moment from "moment"
import "moment-timezone"

let eventDisplayMixin = {
    filters: {
        eventDeliveryModeName: function (type) {
            switch (type) {
                case DeliveryMode.YAY_FOOD_POLE:
                    return "Direct to Customer"
                case DeliveryMode.PICKUP_AREA:
                    return "Pickup"
            }
            return ""
        },
        deliveryMethodName: function (type) {
            switch (type) {
                case CourierId.WALKING:
                    return "Self Delivery"
                case CourierId.CUT_CATS:
                    return "Cut Cats"
                case CourierId.DOORDASH:
                    return "DoorDash"
                case CourierId.POSTMATES:
                    return "Postmates"
            }
            return ""
        },
        eventTypeName: function (type) {
            switch (type) {
                case BusinessEventType.POPUP:
                    return "On Premises"
                case BusinessEventType.VIRTUAL:
                    return "Virtual"
            }
        },
        formatEventDate: function (date, time, timezone, format) {
            if (date) {
                let eventDate = null
                const dateFormat = format ? format : "ddd MMM DD, YYYY"
                if (time) {
                    eventDate = moment.utc(`${date} ${time}`, "YYYY-MM-DD HH:mm:ss").tz(timezone)
                } else {
                    eventDate = moment.utc(date, "YYYY-MM-DD")
                }
                return eventDate.format(
                    dateFormat
                )
            }
            return null
        },
        timeWithOffset(date, time, timezone) {
            if (timezone == null) {
                return null
            }
            if (date && time) {
                return moment
                    .utc(`${date} ${time}`, "YYYY-MM-DD HH:mm:ss")
                    .tz(timezone)
                    .format("h:mm A")
            } else if (time) {
                let currentDate = moment().format("YYYY-MM-DD")
                return moment
                    .utc(`${currentDate} ${time}`, "YYYY-MM-DD HH:mm:ss")
                    .tz(timezone)
                    .format("h:mm A")
            }
            return null
        }
    },
    computed: {
        businessTimezone() {
            if (
                this.business &&
                this.business.timezone != null &&
                this.business.timezone != undefined
            ) {
                return this.business.timezone
            }
            return null
        }
    }
}

export default eventDisplayMixin