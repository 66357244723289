
<template>
	<div class="sk-widget-alt otp-activation-widget">
		<button
			class="button button-link-grey back-button"
			@click="backToLoginClicked"
		>
			<i class="sk-icon-long-arrow-left-regular"></i>
			Back to Log In
		</button>
		<img src="/images/2k-logo-login.png" alt="2ndKitchen Logo" />
		<transition name="fade" mode="out-in">
			<div v-if="currentStep == steps.FIND_ACCOUNT" key="0">
				<div class="sk-column" v-if="failedToFindAccount">
					<h1>No Account Found</h1>
					<div class="otp-activation-subtitle">
						We do not have record of this user email.
						<br />
						Try another email?
					</div>
				</div>
				<div class="sk-column" v-else>
					<h1>Not Activated?</h1>
					<div class="otp-activation-subtitle">
						Looks like we need to resend your activation
						information.
						<br />
						First, let’s find your account.
					</div>
				</div>
				<div class="sk-row">
					<SkInput
						ref="emailInput"
						v-model="email"
						:name="'Email'"
						:placeholder="'Email'"
						:required="true"
						:tabindex="1"
					/>
				</div>
				<div class="sk-row">
					<button
						@click="findAccount()"
						class="button button-primary otp-activation-button"
					>
						Find Account
					</button>
				</div>
			</div>
			<div v-else-if="currentStep == steps.SELECT_ACTIVATION" key="1">
				<div class="sk-column">
					<h1>Account Found!</h1>
					<div class="otp-activation-subtitle">
						How would you prefer your activation code to be sent?
					</div>
				</div>
				<div class="sk-widget-alt sk-column otp-account-details">
					<b>{{ userAccount.email }}</b>
					<div v-if="hasPhoneNumber">
						{{ userAccount.phone_number }}
					</div>
				</div>
				<div class="sk-row">
					<button
						v-if="hasPhoneNumber"
						@click="sendTextActivation"
						class="button button-primary otp-activation-button"
					>
						Text
					</button>
					<button
						@click="sendEmailActivation"
						class="button button-primary otp-activation-button"
					>
						Email
					</button>
				</div>
			</div>
			<div v-else-if="currentStep == steps.VERIFY_CODE" key="2">
				<div class="sk-column">
					<h1>Verification Code Sent!</h1>
					<div class="otp-activation-subtitle">
						A 5 digit code has been sent to
						{{ userAccount.phone_number }}
					</div>
					<div class="code-container">
						<input
							class="sk-input"
							:value="activationCode | charAt(0)"
							@input="setCodeDigit($event, 0)"
							type="number"
							ref="digitInput0"
						/>
						<input
							class="sk-input"
							:value="activationCode | charAt(1)"
							@input="setCodeDigit($event, 1)"
							type="number"
							ref="digitInput1"
						/>
						<input
							class="sk-input"
							:value="activationCode | charAt(2)"
							@input="setCodeDigit($event, 2)"
							type="number"
							ref="digitInput2"
						/>
						<input
							class="sk-input"
							:value="activationCode | charAt(3)"
							@input="setCodeDigit($event, 3)"
							type="number"
							ref="digitInput3"
						/>
						<input
							class="sk-input"
							:value="activationCode | charAt(4)"
							@input="setCodeDigit($event, 4)"
							type="number"
							ref="digitInput4"
						/>
					</div>
				</div>
				<div class="sk-column action-buttons">
					<button
						@click="verifySMSActivationCode"
						class="button otp-activation-button button-primary"
					>
						Verify
					</button>
					<button
						@click="sendTextActivation"
						class="button button-text button-text-navy"
					>
						Resend Code
					</button>
				</div>
			</div>
		</transition>
		<SignupWidgetFooter :links="footerLinks" @linkClicked="linkClicked" />
	</div>
</template>

<style scoped>
.otp-activation-widget {
	width: 500px;
	max-width: 500px;
	height: auto;
	padding: 0;
}

.otp-activation-widget img {
	height: 40px;
	margin: 60px auto;
	display: block;
}

.otp-activation-button {
	min-width: 176px;
	flex: 0 0 auto !important;
	margin: 10px auto 0 auto;
}

h1 {
	color: var(--sk-dark-navy);
	font-size: 16px;
	margin-bottom: 12px !important;
}

.otp-activation-subtitle {
	font-size: 12px;
	color: var(--sk-grey3);
	margin-bottom: 10px;
}

.otp-account-details {
	background: var(--sk-greybg);
	font-size: 12px;
	padding: 14px 15px;
}

.otp-account-details b + div,
.otp-account-details div + div {
	margin-top: 5px;
}

.otp-account-details b {
	font-weight: 600;
	font-size: 14px;
	color: var(--sk-dark-navy);
}

.code-container {
	display: flex;
	justify-content: space-between;
	width: 370px;
	margin: 25px auto;
}

.code-container input {
	width: 50px;
	height: 50px;
}

.code-container input::-webkit-inner-spin-button,
.code-container input::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	margin: 0;
}

.sk-column.action-buttons {
	margin-bottom: -30px;
}

.action-buttons .button-primary {
	margin-bottom: 5px !important;
}
</style>

<script>
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import tracker from "@/utils/tracker"
import auth from "@/utils/auth"
import user_service from "@/services/users"
import loaderMixin from "@/mixins/loader-mixin"
import SkInput from "@/components/SkInput.vue"
import SignupWidgetFooter from "@/components/login/SignupWidgetFooter.vue"

const OTPActivationStep = {
	FIND_ACCOUNT: 0,
	SELECT_ACTIVATION: 1,
	VERIFY_CODE: 2
}

export default {
	name: "OTPActivationWidget",
	mixins: [loaderMixin],
	components: {
		SkInput,
		SignupWidgetFooter
	},
	filters: {
		charAt: function (value, pos) {
			if (!value) {
				return value
			}
			return value[pos]
		}
	},
	data() {
		return {
			steps: OTPActivationStep,
			currentStep: OTPActivationStep.FIND_ACCOUNT,
			email: "",
			userAccount: null,
			failedToFindAccount: false,
			activationCode: { 0: "", 1: "", 2: "", 3: "", 4: "" },
			footerLinks: [
				{
					id: 0,
					text: `Not a 2ndKitchen Partner? <span class="signup-link">Get Started!</span>`
				},
				{
					id: 1,
					text: `Already a Partner? <span class="signup-link">Sign In!</span>`
				}
			]
		}
	},
	computed: {
		hasPhoneNumber() {
			return this.userAccount && this.userAccount.phone_number != ""
		}
	},
	methods: {
		backToLoginClicked() {
			tracker.trackEvent("Back To Login Clicked")
			this.$emit("backToLogin")
		},
		findAccount() {
			tracker.trackEvent("Account Search Clicked")
			if (!this.$refs.emailInput.validate()) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Chef Verification",
					message: "An email is required",
					type: NotificationType.WARNING
				})
				return false
			}
			this.toggleLoader(true)
			user_service
				.getUserByEmail(this.email)
				.then((response) => {
					this.toggleLoader(false)
					if (response.status == 200) {
						const user = response.data
						if (user) {
							this.failedToFindAccount = false
							this.userAccount = user
							this.currentStep =
								OTPActivationStep.SELECT_ACTIVATION
						} else {
							this.failedToFindAccount = true
						}
					} else {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Chef Activation",
							message: "An error occurred, please try again",
							type: NotificationType.ERROR
						})
					}
				})
				.catch((e) => {
					this.toggleLoader(false)
					let message = "An error occurred, please try again"
					if (e && e.code && e.code == "user_not_found_error") {
						this.failedToFindAccount = true
					} else {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Chef Activation",
							message: message,
							type: NotificationType.ERROR
						})
						throw e
					}
				})
		},
		sendEmailActivation() {
			tracker.trackEvent("Email Activation Clicked")
			this.toggleLoader(true)
			auth.resendActivationLink(this.userAccount.email).then(
				(success) => {
					this.toggleLoader(false)
					if (success) {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Chef Activation",
							message:
								"Check your email and follow the link to finish activating"
						})
					} else {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Chef Activation",
							message: "An error occurred, please try again",
							type: NotificationType.ERROR
						})
					}
				}
			)
		},
		sendTextActivation() {
			tracker.trackEvent("Text Activation Clicked")
			this.toggleLoader(true)
			auth.sendSMSActivationCode(this.userAccount.user_id).then(
				(success) => {
					this.toggleLoader(false)
					if (success) {
						this.currentStep = OTPActivationStep.VERIFY_CODE
					} else {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Chef Activation",
							message: "An error occurred, please try again",
							type: NotificationType.ERROR
						})
					}
				}
			)
		},
		verifySMSActivationCode() {
			tracker.trackEvent("Verify SMS Code Clicked")
			const code = Object.entries(this.activationCode)
				.sort((a, b) => Number(a[0]) - Number(b[0]))
				.map((entry) => entry[1])
				.join("")

			if (code.length < 5) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Chef Verification",
					message: "Please enter a verification code",
					type: NotificationType.ERROR
				})
				return
			}
			this.toggleLoader(true)

			return auth
				.verifySMSActivationCode(this.userAccount.user_id, code)
				.then((data) => {
					this.toggleLoader(false)
					if (data) {
						const activationToken = data.activation_token
						this.$router.push({
							path: `/activate/${activationToken}`
						})
					} else {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Chef Verification",
							message: "An error occurred, please try again",
							type: NotificationType.ERROR
						})
						this.activationCode = {
							0: "",
							1: "",
							2: "",
							3: "",
							4: ""
						}
						if (this.$refs["digitInput0"]) {
							this.$refs["digitInput0"].focus()
						}
					}
				})
		},
		setCodeDigit(event, position) {
			event.preventDefault()
			event.stopPropagation()
			const regex = /[0-9]/
			let input =
				event.target.value.length > 0
					? event.target.value[event.target.value.length - 1]
					: ""
			event.target.value = input
			if (
				regex.test(input) &&
				this.activationCode[position] != undefined
			) {
				this.activationCode[position] = input
				const refKey = `digitInput${position + 1}`
				const nextInput = this.$refs[refKey]
				if (nextInput) {
					nextInput.focus()
				} else {
					this.verifySMSActivationCode()
				}
			}
		},
		linkClicked(link) {
			switch (link.id) {
				case 0: {
					tracker.trackEvent("Signup Clicked")
					window.open("https://2ndkitchen.com/")
					break
				}
				case 1: {
					tracker.trackEvent("Activate Clicked")
					this.$emit("backToLogin")
					break
				}
			}
		}
	}
}
</script>