<template>
	<div class="sk-widget-alt supply-item-quantity-input">
		<img class="supply-item-img" :src="supplyItemImg" alt="Supply Item Image" />
		<div class="supply-item-details">
			<div class="supply-item-name">
				{{ item.name }}
			</div>
			<div class="supply-item-price">$1.00</div>
		</div>
		<SkInput
			v-model="model"
			ref="quantityInput"
			class="quantity-input"
			:name="'# Amount'"
			:type="'number'"
			:required="true"
			:validation="/^[1-9]\d*$/"
		/>
	</div>
</template>

<style scoped>
.supply-item-quantity-input {
	display: flex;
	align-items: center;
}

.supply-item-details {
	flex: 1;
}

.supply-item-img {
	height: 65px;
	margin-right: 10px;
}

.supply-item-name {
	font-size: 14px;
	font-weight: 600;
	color: var(--sk-dark-navy);
	margin-bottom: 5px;
}

.supply-item-price {
	font-size: 10px;
	color: var(--sk-grey3);
}

.quantity-input {
	width: 100px;
}
</style>

<script>
import SkInput from "@/components/SkInput.vue"

export default {
	name: "SupplyItemQuantityInput",
	components: {
		SkInput
	},
	props: {
		item: Object,
		value: Number
	},
	computed: {
		supplyItemImg() {
			return this.$chefCDNURL + "supplies/" + this.item.img
		},
		model: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit("input", val)
			}
		}
	},
	methods: {
		validate() {
			return this.$refs.quantityInput.validate()
		},
		resetValidation() {
			return this.$refs.quantityInput.resetValidation()
		}
	}
}
</script>