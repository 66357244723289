<template>
	<div class="sk-page-content">
		<PageHeader
			:title="pageTitle"
			:tabs="tabs"
			:filters="filters"
			@filterSet="filterSet"
			@allFiltersReset="clearFilters"
			@tabClicked="headerTabClicked"
		/>
		<transition name="slide" mode="out-in">
			<InvoiceDetailsBasicInfo
				key="0"
				v-if="activeTabId == 0"
				@showInvoiceHistory="invoiceHistoryClicked"
			/>
			<InvoiceDetailsHistory
				key="1"
				v-if="activeTabId == 1"
				ref="invoiceDetailsHistory"
				:filters="invoiceFilters"
				@pageChanged="pageChanged"
				@viewInvoice="viewInvoice"
			/>
		</transition>
		<InvoiceDetailModal ref="invoiceDetailModal" />
	</div>
</template>

<style scoped>
</style>

<script>
import ActionTypes from "@/store/modules/finance/action-types"
import { InvoiceStatus } from "@/enums/invoiceStatus"
import loaderMixin from "@/mixins/loader-mixin"
import PageHeader from "@/components/PageHeader.vue"
import InvoiceDetailsBasicInfo from "@/components/finance/InvoiceDetailsBasicInfo.vue"
import InvoiceDetailsHistory from "@/components/finance/InvoiceDetailsHistory.vue"
import InvoiceDetailModal from "@/components/modals/InvoiceDetailsModal.vue"

export default {
	name: "InvoiceDetails",
	mixins: [loaderMixin],
	components: {
		PageHeader,
		InvoiceDetailsBasicInfo,
		InvoiceDetailsHistory,
		InvoiceDetailModal
	},
	data: function () {
		return {
			activeTabId: 0,
			didMount: false,
			invoiceFilters: {
				status: -1,
				invoiceStartDate: null,
				invoiceEndDate: null
			},
			invoicePageRange: {
				page: 1,
				start: 0,
				end: 20
			}
		}
	},
	mounted: function () {
		this.toggleLoader(true)
		if (this.$route.query.collection_id) {
			this.activeTabId = 1
			this.getInvoiceHistory()
			this.$refs.invoiceDetailModal.open({
				invoiceId: this.$route.query.collection_id
			})
		}
		this.didMount = true
	},
	computed: {
		business() {
			const businessId = this.$route.params.entity_id
			return this.$store.getters.businessesMap[businessId]
		},
		pageTitle() {
			return this.business && this.business.name
				? `${this.business.name} Invoices`
				: "Invoices"
		},
		loading() {
			return this.$store.state.financeModule.loading || !this.didMount
		},
		tabs() {
			let allowedTabs = [
				{
					id: 0,
					text: "Account Info"
				},
				{
					id: 1,
					text: "History"
				}
			]
			return allowedTabs
				.map((tab) => {
					tab.active = tab.id == this.activeTabId
					return tab
				})
				.sort((a, b) => a.id - b.id)
		},
		filters() {
			let filters = []
			if (this.activeTabId == 1) {
				filters = [
					{
						id: 0,
						placeholder: "Status",
						type: "dropdown",
						items: [
							{
								text: `Status`,
								id: null
							},
							{
								text: `<span class="status-dot dark-grey"></span> All`,
								id: -1
							},
							{
								text: `<span class="status-dot yellow"></span> Pending`,
								id: InvoiceStatus.PENDING
							},
							{
								text: `<span class="status-dot yellow"></span> Approved`,
								id: InvoiceStatus.APPROVED
							},
							{
								text: `<span class="status-dot green-pulsating"></span> Processing`,
								id: InvoiceStatus.PROCESSING
							},
							{
								text: `<span class="status-dot green"></span> Completed`,
								id: InvoiceStatus.COMPLETED
							},
							{
								text: `<span class="status-dot red"></span> Failed`,
								id: InvoiceStatus.FAILED
							}
						]
					},
					{
						id: 1,
						placeholder: "Payout Dates",
						type: "daterange"
					}
				]
			}
			return filters
		}
	},
	methods: {
		headerTabClicked(tabClicked) {
			this.activeTabId = tabClicked.id
			if (this.activeTabId == 1) {
				this.getInvoiceHistory()
			}
		},
		clearFilters(silent) {
			this.invoiceFilters.status = -1
			this.invoiceFilters.invoiceStartDate = null
			this.invoiceFilters.invoiceEndDate = null
			this.$refs.invoiceDetailsHistory.resetPagination()
			if (!silent) {
				this.getInvoiceHistory()
			}
		},
		filterSet(filter) {
			switch (filter.id) {
				case 0:
					this.invoiceFilters.status = filter.value.id
					break
				case 1:
					this.invoiceFilters.invoiceStartDate = filter.value.from
					this.invoiceFilters.invoiceEndDate = filter.value.to
					break
			}
			this.invoicePageRange = {
				page: 1,
				start: 0,
				end: 20
			}
			this.$refs.invoiceDetailsHistory.resetPagination()
			this.getInvoiceHistory()
		},
		invoiceHistoryClicked() {
			this.activeTabId = 1
		},
		getInvoiceHistory() {
			const payload = {
				businessId: this.$route.params.entity_id,
				pageStart: this.invoicePageRange.start,
				pageSize: 20
			}

			if (this.invoiceFilters.status != -1) {
				payload.statuses = [this.invoiceFilters.status]
			} else {
				payload.statuses = [InvoiceStatus.APPROVED, InvoiceStatus.PROCESSING, InvoiceStatus.COMPLETED]
			}

			if (this.invoiceFilters.invoiceStartDate) {
				payload.startDate = this.invoiceFilters.invoiceStartDate
				payload.endDate = this.invoiceFilters.invoiceEndDate
			}

			this.$store.dispatch(
				`financeModule/${ActionTypes.GET_CURRENT_BUSINESS_INVOICE_HISTORY}`,
				payload
			)
		},
		pageChanged(range) {
			if (range.page != this.invoicePageRange.page) {
				this.invoicePageRange = range
				this.getInvoiceHistory()
			}
		},
		viewInvoice(invoice) {
			this.$refs.invoiceDetailModal.open({
				invoice: invoice
			})
		}
	},
	watch: {
		activeTabId(tabId) {
			if (tabId != 1) {
				this.clearFilters(true)
			}
		},
		loading: function (newVal) {
			this.toggleLoader(newVal)
		}
	}
}
</script>