import actions from "./actions"
import mutations from "./mutations"
import getters from "./getters"

const state = {
	prepackagedOrders: [],
	prepackagedProducts: [],
	prepackagedMenu: null,
	prepackagedRestaurantHourExceptions: []
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}