<template>
	<div class="login-carousel">
		<transition-group name="slideleft">
			<div
				class="slide"
				v-for="slide in visibleSlides"
				:key="slide.id"
				:style="{
					backgroundImage: `linear-gradient(359.8deg, rgba(46, 60, 73, 0.55) 0.09%, rgba(47, 72, 88, 0.275) 99.74%), url(${slide.imgSrc})`
				}"
			>
				<div class="slide-content">
					<h1>{{ slide.quote }}</h1>
					<div class="slide-author">{{ slide.author }}</div>
					<div class="slide-tag">{{ slide.authorTag }}</div>
				</div>
			</div>
		</transition-group>
		<div class="slide-indicators">
			<div
				class="slide-indicator"
				v-for="slide in slides"
				@click="goToSlide(slide)"
				:class="{ active: activeSlideId == slide.id }"
				:key="slide.id"
			></div>
		</div>
	</div>
</template>

<style scoped>
.login-carousel {
	width: 475px;
	background: var(--sk-dark-navy);
	position: relative;
	height: 100%;
	overflow: hidden;
}

.slide {
	width: 475px;
	height: 100%;
	position: absolute;
	top: 0;
	display: flex;
	align-items: center;
}

.slide-content {
	display: flex;
	flex-direction: column;
	margin-left: 48px;
	width: 350px;
}

.slide h1,
.slide-author,
.slide-tag {
	color: var(--sk-white);
}

.slide-author,
.slide-tag {
	text-transform: uppercase;
	transform: translateY(0);
}

.slide h1 {
	font-size: 24px;
	margin-bottom: 60px;
	line-height: 30px;
	transform: translateY(0);
	transition: transform 0.7s ease-in-out 0.15s, opacity 0.35s ease-in-out 0.1s;
}

.slide-author {
	font-size: 18px;
	transition: transform 0.7s ease-in-out 0.2s, opacity 0.35s ease-in-out 0.3s;
}

.slide-tag {
	font-size: 14px;
	transition: transform 0.7s ease-in-out 0.25s, opacity 0.35s ease-in-out 0.6s;
}

.slide-indicators {
	position: absolute;
	bottom: 20px;
	display: flex;
	width: 100%;
	justify-content: center;
}

.slide-indicator {
	width: 10px;
	height: 10px;
	background: var(--sk-white);
	border-radius: 50%;
	cursor: pointer;
}

.slide-indicator.active {
	opacity: 0.6;
}

.slide-indicator:not(:last-child) {
	margin-right: 10px;
}

/* transition */

.slideleft-enter-active,
.slideleft-leave-active {
	transition: left 0.35s ease-out;
}

.slideleft-enter {
	left: 475px;
}

.slideleft-enter-to {
	left: 0;
}

.slideleft-leave {
	left: 0;
}
.slideleft-leave-to {
	left: -475px;
}

.slideleft-enter .slide-content h1,
.slideleft-enter .slide-author,
.slideleft-enter .slide-tag {
	opacity: 0;
	transform: translateY(50px);
}

.slideleft-enter-to .slide-content h1,
.slideleft-enter-to .slide-author,
.slideleft-enter-to .slide-tag {
	opacity: 1;
	transform: translateY(0);
}

/*****/
</style>

<script>
export default {
	name: "LoginCarousel",
	data: function () {
		return {
			skipInterval: false,
			activeSlideId: 0,
			slides: [
				{
					id: 0,
					quote:
						"“We were eager to provide something hyper-local and authentic to our area, especially with guests ordering takeout in a post-COVID world. We’re happy to always see a 2ndKitchen delivery in the lobby.”",
					author: "Ryan Fender",
					authorTag: "GM at Hotel 50 Bowery",
					imgSrc: "/images/login-hotel.jpg"
				},
				{
					id: 1,
					quote:
						"“For us, it’s so simple: we don’t have to deal with food preparation and we get to feature food items from amazing chefs in our neighborhood.”",
					author: "Marcia Sullivan",
					authorTag: "Owner. Doc Ryans",
					imgSrc: "/images/login-brewing.jpg"
				},
				{
					id: 2,
					quote:
						"“2ndKitchen is a great solution that makes it simple for Replay’s customers to order food from Zizi’s without leaving the bar.”",
					author: "Zizi K.",
					authorTag: "Owner at Zizi's",
					imgSrc: "/images/login-restaurant.jpg"
				},
				{
					id: 3,
					quote:
						"“We have seen our renewals go up now that we have an additional amenity to offer our tenants. It has been a fabulous, easy, and fun addition to have at our buildings”",
					author: "Jen Danisch",
					authorTag: "Property Manager at Sheffield Lofts",
					imgSrc: "/images/login-residential.jpg"
				}
			]
		}
	},
	computed: {
		visibleSlides() {
			return this.slides.filter((slide) => slide.id == this.activeSlideId)
		}
	},
	methods: {
		goToSlide(slide) {
			this.skipInterval = true
			this.activeSlideId = slide.id
		}
	},
	mounted: function () {
		setInterval(() => {
			if (this.skipInterval) {
				this.skipInterval = false
				return false
			}
			this.activeSlideId =
				this.activeSlideId >= this.slides.length - 1
					? 0
					: (this.activeSlideId += 1)
		}, 10000)
	}
}
</script>