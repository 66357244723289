<template>
	<SkBaseModal
		class="order-prepackages-modal"
		:isOpen="isOpen"
		:showCloseButton="false"
		:hasLoader="true"
		@close="close"
	>
		<loading :active.sync="loading" :is-full-page="false" />
		<div class="sk-modal-alt-title">
			<SkDropdownSelect
				:options="allowedActions"
				:iconClasses="'sk-icon-ellipsis-v-regular'"
				:showSelectedOption="false"
				:position="'left'"
				@selected="actionSelected"
			/>
			<h1>
				{{ modalTitle }}
				<span>{{ orderId }}</span>
			</h1>
		</div>
		<SkModalTabs
			:tabs="tabs"
			:isVisible="isOpen"
			@tabClicked="modalTabClicked"
		/>
		<transition-group name="fade-height">
			<div
				v-show="activeTabId == activeOrderDetailsTab.ORDER"
				:key="activeOrderDetailsTab.ORDER"
				class="sk-modal-section"
			>
				<h3>
					{{ products.length }}
					{{ products.length | pluralize("Item") }}
				</h3>
				<div>
					<div
						class="sk-row sk-widget-alt"
						v-for="(product, index) in products"
						:key="index"
					>
						<div
							class="product-quantity pill-alt pill-alt-baby-blue"
						>
							{{ product.quantity }}
						</div>
						<div class="product-details">
							<h2>{{ product.name }}</h2>
							<div
								class="product-meta"
								v-for="meta in product.meta"
								:key="meta.id"
							>
								{{ meta.label }}:
								<b>{{ meta.value }}</b>
							</div>
						</div>
						<h2 class="product-price" v-if="showFullPrice">
							{{
								productPrices[`${product.id}_${index}`].price
									| currency
							}}
						</h2>
						<h2 class="product-price" v-else>
							{{
								productPrices[`${product.id}_${index}`].cogs
									| currency
							}}
						</h2>
					</div>
				</div>
				<h3>Payment</h3>
				<div class="sk-widget-alt payment-details">
					<div>
						<div>Method</div>
						<b class="payment-method">{{ paymentMethod }}</b>
					</div>
					<div v-if="couponUse">
						<div>Voucher</div>
						<div>-{{ couponUse.amount | currency }}</div>
					</div>
					<div>
						<div>Taxes & Fees</div>
						<div v-if="showFullPrice">{{ tax | currency }}</div>
						<div v-else>{{ cogsTax | currency }}</div>
					</div>
					<div v-if="showTip">
						<div>Tip</div>
						<div>{{ tip | currency }}</div>
					</div>
					<div class="sk-divider"></div>
					<div>
						<b>Total</b>
						<b v-if="showFullPrice">
							{{ orderPrice | currency }}
						</b>
						<b v-else-if="showTip">
							{{ totalCogsWithTip | currency }}
						</b>
						<b v-else>{{ totalCogsWithTax | currency }}</b>
					</div>
				</div>
			</div>
			<div
				v-show="activeTabId == activeOrderDetailsTab.DETAILS"
				:key="activeOrderDetailsTab.DETAILS"
				class="sk-modal-section"
			>
				<h3>Customer</h3>
				<div class="sk-widget-alt customer-info">
					<h2>{{ orderUsername }}</h2>
					<div>{{ orderUserPhone }}</div>
					<div v-if="hasTable">{{ tableNumber }}</div>
					<router-link
						v-if="hotelGuestId"
						:to="`/users/guest/${hotelGuestId}`"
						tag="a"
						class="hotel-guest-link"
					>
						View Guest
					</router-link>
				</div>
				<h3>Delivery</h3>
				<div class="sk-widget-alt courier-info">
					<h2>{{ deliveryBy }}</h2>
					<div>
						{{ deliveryInstructions }}
					</div>
				</div>
				<h3>Issues</h3>
				<transition-expand>
					<div v-if="showIssueButtons" class="issue-buttons">
						<button
							@click="toggleIssueForm(0)"
							class="button button-navy"
						>
							<i class="sk-icon-question-solid"></i>
							Problems with the Order?
						</button>
						<button
							v-if="canDisputeCharge"
							@click="toggleIssueForm(1)"
							class="button button-navy"
						>
							<i class="sk-icon-question-solid"></i>
							Dispute Charge?
						</button>
					</div>
				</transition-expand>
				<transition-expand>
					<div class="sk-widget-alt" v-if="issue">
						<div class="issue-date">
							<b>Submitted</b>
							{{ issueCreatedTime }}
						</div>
						<div>
							{{ issue.text }}
						</div>
					</div>
				</transition-expand>
				<transition-expand>
					<div v-if="issueFormExpanded">
						<SkInput
							ref="issueInput"
							:name="'Issue'"
							:placeholder="'Let support know what is wrong with the order...'"
							:multiline="true"
							:required="true"
							v-model="issueComment"
						/>
						<div class="sk-modal-actions">
							<button
								@click="toggleIssueForm"
								class="button button-text"
							>
								Cancel
							</button>
							<button
								v-if="isDisputeIssue"
								class="button button-navy"
								@click="submitIssue"
							>
								Dispute Charge
							</button>
							<button
								v-else
								class="button button-navy"
								@click="submitIssue"
							>
								Submit Issue to Support
							</button>
						</div>
					</div>
				</transition-expand>
			</div>
			<div
				v-show="activeTabId == activeOrderDetailsTab.TRACKING"
				:key="activeOrderDetailsTab.TRACKING"
				class="sk-modal-section"
			>
				<div class="sk-widget-alt tracking-step complete">
					<div class="tracking-step-status-bar"></div>
					<div class="tracking-step-name">
						<i class="sk-icon-check-regular"></i>
						Placed
					</div>
					<div class="tracking-step-time">
						{{ placedTime }}
					</div>
				</div>
				<div
					class="sk-widget-alt tracking-step"
					:class="{ complete: accepted }"
				>
					<div class="tracking-step-status-bar"></div>
					<div class="tracking-step-name">
						<i v-if="accepted" class="sk-icon-check-regular"></i>
						<i v-else class="sk-icon-pause-solid"></i>
						Accepted
					</div>
					<div class="tracking-step-time" v-if="accepted">
						{{ localAcceptedTime }}
					</div>
				</div>
				<div
					class="sk-widget-alt tracking-step"
					:class="{ complete: pickedUp }"
				>
					<div class="tracking-step-status-bar"></div>
					<div class="tracking-step-name">
						<i v-if="pickedUp" class="sk-icon-check-regular"></i>
						<i v-else class="sk-icon-pause-solid"></i>
						Picked Up
					</div>
					<div class="tracking-step-time" v-if="pickedUp">
						{{ localPickedUpTime }}
					</div>
				</div>
				<div
					class="sk-widget-alt tracking-step"
					:class="{ complete: delivered }"
				>
					<div class="tracking-step-status-bar"></div>
					<div class="tracking-step-name">
						<i v-if="delivered" class="sk-icon-check-regular"></i>
						<i v-else class="sk-icon-pause-solid"></i>
						Awaiting Delivery
					</div>
					<div class="tracking-step-time" v-if="delivered">
						{{ localDeliveredTime }}
					</div>
				</div>
				<button
					v-if="canNotifyGuest"
					@click="notifyGuest"
					class="button button-success notify-guest-button"
				>
					<i class="sk-icon-bell-solid"></i>
					{{ notifyGuestText }}
				</button>
			</div>
		</transition-group>
	</SkBaseModal>
</template>

<style scoped>
.sk-modal-alt-title span {
	font-size: 12px;
	font-weight: 400;
	color: var(--sk-grey3);
	margin-left: 15px;
}

.sk-modal-section {
	font-size: 12px;
	color: var(--sk-grey3);
}

.sk-modal-section > .sk-widget-alt {
	margin-bottom: 20px;
}

.sk-modal-alt-title >>> .sk-select {
	width: auto;
}

.sk-modal-alt-title >>> .selected .dropdown-icon {
	margin-left: 0;
	margin-right: 15px;
	color: var(--sk-grey2);
}

h2,
h3 {
	color: var(--sk-dark-navy);
	font-weight: 600;
}

h2 {
	font-size: 14px;
	margin-bottom: 0;
}

h3 {
	font-size: 12px;
	margin-bottom: 22px;
}

.product-quantity {
	flex-grow: 0 !important;
	padding: 3px 10px;
	font-size: 14px;
	margin-right: 15px;
}

.product-details {
	flex-grow: 1;
}

.product-details,
.product-price {
	padding-top: 2px;
}

h2 + .product-meta {
	margin-top: 12px;
}

.product-meta,
.product-meta b {
	color: var(--sk-grey3);
	font-size: 12px;
}

.product-meta + .product-meta {
	margin-top: 1px;
}

.payment-details > div {
	display: flex;
	justify-content: space-between;
	font-size: 12px;
	color: var(--sk-grey3);
}

.payment-details .sk-divider {
	margin: 15px 0 10px 0;
}

.payment-method {
	color: var(--sk-dark-navy);
}

.hotel-guest-link,
.hotel-guest-link:hover,
.hotel-guest-link:active {
	font-weight: 600;
	font-size: 12px;
	color: var(--sk-dark-navy);
	text-decoration: none;
	margin-top: 5px;
	display: inline-block;
}

.customer-info div,
.courier-info div {
	margin-top: 5px;
}

.sk-modal-actions {
	margin-top: 20px;
}

.tracking-step {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 60px;
	font-size: 12px;
	padding-top: 20px;
}

.tracking-step-name {
	font-size: 12px;
	font-weight: 600;
}

.tracking-step-name i {
	font-size: 10px;
	margin-right: 15px;
}

.tracking-step-time {
	font-size: 10px;
}

.tracking-step.complete .tracking-step-status-bar {
	background: linear-gradient(89.39deg, #7fc729 1.17%, #95f223 99.59%);
}

.tracking-step-status-bar {
	background: linear-gradient(
		89.39deg,
		var(--sk-grey3) 1.17%,
		var(--sk-grey2) 99.59%
	);
	height: 5px;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

.notify-guest-button {
	margin: 0 auto;
	display: block;
}

.issue-date {
	color: var(--sk-dark-navy);
	font-size: 14px;
	margin-bottom: 5px;
}

.issue-date b {
	margin-right: 5px;
}

.issue-buttons button + button {
	margin-left: 15px;
}
</style>

<script>
import { CourierId } from "@arikgaisler/utils/enums/courierId"
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import { PermissionInfo, PermissionMethod } from "@/utils/permissions"
import { DeliveryMode } from "@arikgaisler/utils/enums/deliveryMode"
import { BusinessType } from "@arikgaisler/utils/enums/businessType"
import { MenuType } from "@arikgaisler/utils/enums/menuType"
import { PaymentType } from "@arikgaisler/utils/enums/paymentType"
import moment from "moment"
import "moment-timezone"
import tracker from "@/utils/tracker"
import pricing_service from "@/services/pricing"
import order_service from "@/services/orders"
import payment_service from "@/services/payments"
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import currencyFilterMixin from "@/mixins/currency-filter-mixin"
import pluralizeFilterMixin from "@/mixins/pluralize-filter-mixin"
import phoneFilterMixin from "@/mixins/phone-filter-mixin"
import orderProductPricing from "@/mixins/order-product-pricing"
import activeOrderMixin from "@/mixins/active-order-mixin"
import orderMixin from "@/mixins/order-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import SkModalTabs from "@/components/modals/SkModalTabs.vue"
import SkInput from "@/components/SkInput.vue"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"
import Loading from "vue-loading-overlay"
import TransitionExpand from "@/components/transitions/TransitionExpand.vue"

const ActiveOrderDetailsTab = {
	ORDER: 0,
	DETAILS: 1,
	TRACKING: 2
}

export default {
	name: "ActiveOrderDetailsModal",
	mixins: [
		skModalMixin,
		currencyFilterMixin,
		pluralizeFilterMixin,
		phoneFilterMixin,
		orderProductPricing,
		orderMixin,
		activeOrderMixin
	],
	components: {
		SkBaseModal,
		SkModalTabs,
		SkInput,
		SkDropdownSelect,
		Loading,
		TransitionExpand
	},
	data: function () {
		return {
			loading: false,
			activeTabId: 0,
			order: null,
			couponUse: null,
			issueFormExpanded: false,
			issueComment: "",
			issue: null,
			activeOrderDetailsTab: ActiveOrderDetailsTab,
			isDisputeIssue: false,
			orderTransaction: null
		}
	},
	computed: {
		modalTitle() {
			if (this.order) {
				return this.order.user_name
			}
			return ""
		},
		orderId() {
			if (this.order) {
				return `#${this.order.id}`
			}
			return ""
		},
		orderPrice() {
			return this.order ? this.order.price : 0
		},
		orderUsername() {
			if (this.order && this.order.user_name) {
				return this.order.user_name
			} else if (this.order && this.order.user) {
				return this.order.user.name
			}
			return ""
		},
		orderUserPhone() {
			let phone = ""
			if (this.order && this.order.user_phone) {
				phone = this.order.user_phone
			} else if (this.order && this.order.user) {
				phone = this.order.user.phone
			}
			return phone && phone != ""
				? this.$options.filters.formatInternationalPhone(phone)
				: ""
		},
		tabs() {
			return [
				{
					id: ActiveOrderDetailsTab.ORDER,
					active: this.activeTabId == ActiveOrderDetailsTab.ORDER,
					text: "Order"
				},
				{
					id: ActiveOrderDetailsTab.DETAILS,
					active: this.activeTabId == ActiveOrderDetailsTab.DETAILS,
					text: "Details"
				},
				{
					id: ActiveOrderDetailsTab.TRACKING,
					active: this.activeTabId == ActiveOrderDetailsTab.TRACKING,
					text: "Tracking"
				}
			]
		},
		item() {
			return this.order ? this.order.sub_orders[0] : null
		},
		products() {
			return this.item ? this.item.products : []
		},
		showFullPrice() {
			return this.$store.state.permissions.includes(
				PermissionInfo.ORDER_FULL_PRICE
			)
		},
		showTip() {
			return (
				this.showFullPrice ||
				(this.item &&
					(this.item.courier_id == CourierId.WALKING ||
						this.item.courier_id == CourierId.TOOKAN))
			)
		},
		hotelGuestId() {
			return null
		},
		canNotifyGuest() {
			return this.$store.state.permissions.includes(
				PermissionMethod.NOTIFY_GUEST
			)
		},
		showIssueButtons() {
			return !this.issueFormExpanded && !this.issue
		},
		placedTime() {
			if (this.order) {
				const time = moment
					.utc(this.order.created_time)
					.tz(this.timezone)
					.format("h:mm A")
				return `${time} ${this.formattedTimezone}`
			}
			return ""
		},
		localAcceptedTime() {
			if (this.accepted) {
				const time = moment
					.utc(this.item.accept_time)
					.tz(this.timezone)
					.format("h:mm A")
				return `${time} ${this.formattedTimezone}`
			}
			return ""
		},
		localPickedUpTime() {
			if (this.pickedUp) {
				const time = moment
					.utc(this.item.pickedUpTime)
					.tz(this.timezone)
					.format("h:mm A")
				return `${time} ${this.formattedTimezone}`
			}
			return ""
		},
		localDeliveredTime() {
			if (this.delivered) {
				const time = moment
					.utc(this.item.deliveredTime)
					.tz(this.timezone)
					.format("h:mm A")
				return `${time} ${this.formattedTimezone}`
			}
			return ""
		},
		notifyGuestText() {
			return this.delivered
				? "Re-Notify Guest of Order Delivery"
				: "Notify Guest of Order Delivery"
		},
		deliveryBy() {
			if (!this.item) {
				return ""
			}
			switch (this.item.courier_id) {
				case CourierId.TOOKAN:
				case CourierId.WALKING:
					return this.restaurant ? this.restaurant.name : "N/A"
				case CourierId.CUT_CATS:
					return "Cut Cats"
				case CourierId.POSTMATES:
					return "Postmates"
				case CourierId.DOORDASH:
					return "Door Dash"
			}
			return ""
		},
		deliveryInstructions() {
			if (!this.order || this.order.delivery_mode == undefined) {
				return ""
			}

			switch (this.order.delivery_mode) {
				case DeliveryMode.DIRECT_TO_LOCATION: {
					if (this.tableNumber) {
						return `Courier will drop off the order at ${this.tableNumber}`
					} else {
						return `Courier will drop off the order at ${this.tablePrefix}.`
					}
				}
				case DeliveryMode.YAY_FOOD_POLE: {
					return "Courier will drop off the order at table."
				}
				case DeliveryMode.PICKUP_AREA: {
					let station = ""
					if (this.order.menu_type == MenuType.POPUP) {
						station =
							"Courier will drop off the order at popup pickup area."
					} else {
						const businessType =
							this.business && this.business.type != undefined
								? this.business.type
								: BusinessType.BAR
						station =
							businessType == BusinessType.HOTEL
								? "Lobby"
								: "Pickup Station"
					}
					return `Courier will drop off the order at the ${station}.`
				}
				case DeliveryMode.DIRECT_TO_CUSTOMER: {
					return "Courier will drop off the order directly to the customer."
				}
				case DeliveryMode.LOCKER: {
					return "Courier will drop off the order at the foodhall lockers."
				}
			}
		},
		issueCreatedTime() {
			if (this.issue) {
				const formattedDate = moment
					.tz(this.issue.created_time, this.timezone)
					.format("MMMM Do h:mm A")
				return `${formattedDate} ${this.formattedTimezone}`
			}
			return ""
		},
		paymentMethod() {
			if (this.order) {
				const method =
					this.order.payment_method &&
					this.order.payment_method.status != undefined
						? this.order.payment_method.status
						: this.order.payment_method
				switch (method) {
					case PaymentType.CREDIT_CARD:
						return "Credit Card"
					case PaymentType.APPLE_PAY:
						return "Apple Pay"
					case PaymentType.GOOGLE_PAY:
						return "Google Pay"
					case PaymentType.CASH:
						return this.order.price > 0 ? "Cash" : "Voucher"
					case PaymentType.IN_ROOM_BILLING:
						return "Charge to Room"
				}
				return "Credit Card"
			}
		},
		allowedActions() {
			let actions = [
				{
					id: 0,
					text: "Report Issue"
				}
			]

			if (this.canNotifyGuest) {
				const notifyGuestText = this.delivered
					? "Re-Notify Guest"
					: "Notify Guest"

				actions.push({
					id: 1,
					text: notifyGuestText
				})
			}

			if (this.isInRoomBilling) {
				if (this.canDisputeCharge) {
					actions.push({
						id: 2,
						text: "Dispute Charge"
					})
				}
				if (this.canMarkPosted) {
					actions.push({
						id: 3,
						text: "Mark Posted",
						highlight: true
					})
				}
			}
			return actions
		}
	},
	methods: {
		setLoader(loading) {
			this.loading = loading
		},
		modalTabClicked(tab) {
			this.activeTabId = tab.id
		},
		handleOptions(options) {
			if (options && options.order) {
				this.order = options.order
				if (options.reportIssue) {
					this.activeTabId = ActiveOrderDetailsTab.DETAILS
					this.toggleIssueForm()
				} else if (options.disputeCharge) {
					this.activeTabId = ActiveOrderDetailsTab.DETAILS
					this.toggleIssueForm(1)
				}

				const promises = [this.getCoupons(), this.getIssues()]

				if (this.isInRoomBilling) {
					if (options.order.transaction) {
						this.orderTransaction = options.order.transaction
					} else {
						promises.push(this.getOrdersTransactions())
					}
				}
				this.loading = true
				Promise.allSettled(promises).finally(() => {
					this.loading = false
				})
			}
		},
		toggleIssueForm(type) {
			if (type != undefined) {
				this.isDisputeIssue = type == 1
			} else {
				this.isDisputeIssue = false
			}
			this.issueFormExpanded = !this.issueFormExpanded
		},
		submitIssue() {
			if (this.$refs.issueInput) {
				const isValid = this.$refs.issueInput.validate()
				if (!isValid) {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Issue description must not be empty",
						type: NotificationType.WARNING
					})
					return false
				}
			} else {
				return false
			}

			if (this.isDisputeIssue) {
				this.loading = true
				tracker.trackEvent("Dispute In Room Billing Order Submitted")
				this.updateOrderTransactionStatus(
					2,
					"Order was marked as disputed",
					"Failed to mark the order as disputed, please try again",
					this.issueComment
				)
					.then(() => {
						this.issueComment = ""
						this.issueFormExpanded = false
						this.isDisputeIssue = false
						this.orderTransaction.status = 2
					})
					.finally(() => {
						this.loading = false
					})
			} else {
				this.loading = true
				this.$store
					.dispatch("ordersModule/addOrderIssue", {
						orderId: this.order.id,
						suborderId: this.item.id,
						issueComment: this.issueComment
					})
					.then((issue) => {
						if (issue) {
							this.issueComment = ""
							this.issueFormExpanded = false
							this.issue = issue
							eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
								title: "Support has been notified of the issue"
							})
						} else {
							throw "Failed to submit issue"
						}
					})
					.catch(() => {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Failed to submit issue, please try again",
							type: NotificationType.ERROR
						})
					})
					.finally(() => {
						this.loading = false
					})
			}
		},
		notifyGuest() {
			this.loading = true
			this.$store
				.dispatch("ordersModule/notifyGuestOrderDelivered", {
					id: this.item.id,
					orderId: this.order.id
				})
				.then((success) => {
					if (success) {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Guest notified of order delivery"
						})
					} else {
						throw "Failed to notify guest"
					}
				})
				.catch(() => {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Failed to notify guest, please try again",
						type: NotificationType.ERROR
					})
				})
				.finally(() => {
					this.loading = false
				})
		},
		async getCoupons() {
			try {
				if (this.order.coupon_used == 1) {
					const response = await pricing_service.getOrdersCouponUses(
						this.order.id
					)
					this.couponUse =
						response.data.coupon_uses &&
						response.data.coupon_uses[0]
							? response.data.coupon_uses[0]
							: null
				}
				return true
			} catch (e) {
				throw e
			}
		},
		async getIssues() {
			try {
				const response = await order_service.getOrderWarnings(
					this.order.id
				)
				if (response.data && response.data.order_warnings) {
					this.issue = response.data.order_warnings.find(
						(orderWarning) => orderWarning.type == 5
					)
				}
				return true
			} catch (e) {
				throw e
			}
		},
		async getOrdersTransactions() {
			try {
				const response = await payment_service.getOrdersTransactions(
					this.order.id
				)
				if (
					response.status == 200 &&
					response.data.order_transactions &&
					response.data.order_transactions.length > 0
				) {
					this.orderTransaction = response.data.order_transactions[0]
				} else {
					this.orderTransaction = null
				}
			} catch (e) {
				throw e
			}
		},
		closed() {
			this.loading = false
			this.activeTabId = 0
			this.order = null
			this.couponUse = null
			this.issueFormExpanded = false
			this.issueComment = ""
			this.issue = null
			this.isDisputeIssue = false
			this.orderTransaction = null
		},
		actionSelected(action) {
			switch (action.id) {
				case 0: {
					this.activeTabId = ActiveOrderDetailsTab.DETAILS
					this.issueFormExpanded = true
					this.isDisputeIssue = false
					break
				}
				case 1: {
					this.notifyGuest()
					break
				}
				case 2: {
					tracker.trackEvent("Dispute In Room Billing Order")
					this.activeTabId = ActiveOrderDetailsTab.DETAILS
					this.issueFormExpanded = true
					this.isDisputeIssue = true
					break
				}
				case 3: {
					tracker.trackEvent("Post In Room Billing Order")
					this.loading = true
					this.updateOrderTransactionStatus(
						1,
						"Successfully marked the order as posted",
						"Failed to mark the order as posted, please try again"
					).finally(() => {
						this.loading = false
					})
					break
				}
			}
		}
	}
}
</script>