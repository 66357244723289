
<template>
	<div class="sk-page-content">
		<PageHeader
			ref="pageHeader"
			:title="'Catering'"
			:filters="filters"
			:tabs="tabs"
			:actions="actions"
			@filterSet="filterSet"
			@allFiltersReset="filtersReset"
			@actionClicked="headerActionClicked"
			@tabClicked="headerTabClicked"
		/>
		<slot v-if="canCreatePrepackages">
			<transition name="slide" mode="out-in">
				<PrepackagesCalendar
					key="0"
					v-if="activeTabId == 0"
					:filters="filterVals"
					@openPrepackagesModal="openPrepackagesModal"
					@cancelPrepackage="cancelPrepackage"
					@downloadManifest="downloadManifest"
					ref="prepackagesCalendar"
				/>
				<PrepackagesList
					key="1"
					v-if="activeTabId == 1"
					:filters="filterVals"
					:canFilterForBusiness="canFilterForBusiness"
					@openPrepackagesModal="openPrepackagesModal"
					@downloadManifest="downloadManifest"
					ref="prepackagesList"
				/>
			</transition>
		</slot>
		<EmptyPlaceholderWidget
			v-else
			:title="`Catering`"
			:description="`Order Catering for your guests!`"
			:buttonText="'Order Catering'"
			:imgSrc="'/images/2k-mealboxes.png'"
			@actionClicked="requestPrepackages"
		/>
		<OrderPrepackagesModal ref="orderPrepackagesModal" />
		<ViewPrepackageOrderModal ref="viewPrepackageOrderModal" />
		<PrepackagesIntroModal ref="prepackagesIntroModal" />
		<ConfirmationModal ref="confirmationModal" />
	</div>
</template>
  
<script>
import moment from "moment"
import { ConfirmationModalType } from "@/enums/confirmationModalType"
import { NotificationType } from "@/enums/notificationType"
import { EventBusEvents } from "@/enums/eventBusEvents"
import { eventBus } from "@/utils/eventBus"
import loaderMixin from "@/mixins/loader-mixin"
import ActionTypes from "@/store/modules/prepackages/action-types"
import tracker from "@/utils/tracker"
import PageHeader from "@/components/PageHeader.vue"
import PrepackagesCalendar from "@/components/prepackages/PrepackagesCalendar.vue"
import PrepackagesList from "@/components/prepackages/PrepackagesList.vue"
import EmptyPlaceholderWidget from "@/components/EmptyPlaceholderWidget.vue"
import OrderPrepackagesModal from "@/components/modals/prepackages/OrderPrepackagesModal.vue"
import ViewPrepackageOrderModal from "@/components/modals/prepackages/ViewPrepackageOrderModal.vue"
import PrepackagesIntroModal from "@/components/modals/prepackages/PrepackagesIntroModal.vue"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue"

export default {
	name: "Prepackages",
	mixins: [loaderMixin],
	components: {
		PageHeader,
		PrepackagesCalendar,
		PrepackagesList,
		EmptyPlaceholderWidget,
		OrderPrepackagesModal,
		ViewPrepackageOrderModal,
		PrepackagesIntroModal,
		ConfirmationModal
	},
	data: function () {
		return {
			activeTabId: 0,
			filterVals: {
				businessId: 0,
				startDate: null,
				endDate: null,
				issuerId: null,
				statuses: null
			}
		}
	},
	computed: {
		canCreatePrepackages() {
			return this.$store.getters["prepackagesModule/canCreatePrepackages"]
		},
		actions() {
			if (this.activeTabId == 0 || this.activeTabId == 1) {
				if (this.canCreatePrepackages) {
					return [
						{
							id: 0,
							text: `<i class="sk-icon-plus-regular"></i> New Catering order`,
							classes: "button button-primary"
						}
					]
				}
			}
			return []
		},
		tabs() {
			const tabs = [
				{
					id: 0,
					text: "Calendar"
				},
				{
					id: 1,
					text: "List"
				}
			]

			return tabs.map((tab) => {
				tab.active = tab.id == this.activeTabId
				return tab
			})
		},
		canFilterForBusiness() {
			const businessesWithPrepackagedMenus =
				this.$store.getters[
					"prepackagesModule/businessesWithPrepackagedMenus"
				]
			return businessesWithPrepackagedMenus.length > 1
		},
		filters() {
			const filters = [
				{
					id: 2,
					placeholder: "Issuer",
					type: "search",
					width: "280px",
					items: this.$store.state.admin_users,
					serializer: (i) => {
						return { text: i.display_name }
					}
				},
				{
					id: 3,
					placeholder: "Status",
					type: "dropdown",
					items: [
						{
							text: "Status",
							id: null
						},
						{
							text: `Completed`,
							id: 2
						},
						{
							text: `Cancelled`,
							id: 4
						}
					]
				}
			]
			if (this.canFilterForBusiness) {
				filters.push({
					id: 0,
					placeholder: "Business Name",
					type: "search",
					width: "280px",
					fetch: (query) => {
						return this.$store.dispatch(
							"businessesModule/autocompleteBusinesses",
							query
						)
					},
					serializer: (i) => {
						return { text: i.name }
					}
				})
			}

			if (this.activeTabId != 0) {
				filters.push({
					id: 1,
					placeholder: "Delivery Date",
					type: "daterange"
				})
			}

			return filters.sort((a, b) => a.id - b.id)
		}
	},
	methods: {
		filterSet(filter) {
			switch (filter.id) {
				case 0: {
					this.filterVals.businessId = filter.value.id
					break
				}
				case 1: {
					this.filterVals.startDate = filter.value.from
					this.filterVals.endDate = filter.value.to
					break
				}
				case 2: {
					this.filterVals.issuerId = filter.value.id
					break
				}
				case 3: {
					this.filterVals.statuses = [filter.value.id]
					break
				}
			}
		},
		filtersReset() {
			if (this.activeTabId == 1) {
				this.$nextTick(() => {
					const dateFilter = this.filters.find(
						(filter) => filter.id == 1
					)
					if (dateFilter) {
						const start = moment.utc().startOf("M")
						const end = moment.utc().endOf("M")
						this.filterVals = {
							businessId: 0,
							startDate: start.format("YYYY-MM-DD"),
							endDate: end.format("YYYY-MM-DD"),
							issuerId: null,
							statuses: null
						}
						this.$refs.pageHeader.filterSet(
							dateFilter,
							{
								start: start.format("MMM DD, YYYY"),
								end: end.format("MMM DD, YYYY"),
								formatted: {
									start: start.format("MMM DD, YYYY"),
									end: end.format("MMM DD, YYYY")
								}
							},
							true
						)
					}
				})
			} else {
				this.filterVals = {
					businessId: 0,
					startDate: null,
					endDate: null,
					issuerId: null,
					statuses: null
				}
			}
		},
		headerTabClicked(tabClicked) {
			this.activeTabId = tabClicked.id
			this.$refs.pageHeader.clearAll()
		},
		headerActionClicked() {
			tracker.trackEvent("Add Prepackage Clicked", {
				context: "Header"
			})
			this.openPrepackagesModal()
		},
		openPrepackagesModal(options) {
			if (options && options.viewing) {
				this.$refs.viewPrepackageOrderModal.open({
					order: options.order
				})
			} else {
				this.$refs.orderPrepackagesModal.open(options)
			}
		},
		async downloadManifest(payload) {
			try {
				this.toggleLoader(true)
				const url = await this.$store.dispatch(
					`prepackagesModule/${ActionTypes.GET_PREPACKAGED_MANIFEST}`,
					payload
				)
				if (url && url != null && url != "") {
					window.open(url, "_blank")
				}
				this.toggleLoader(false)
			} catch (e) {
				this.toggleLoader(false)
				throw e
			}
		},
		async cancelPrepackage(order) {
			try {
				const business =
					this.$store.getters.businessesMap[order.business_id]
				let title = "Cancel Catering Order?"
				if (business) {
					const deliverySlot = moment
						.utc(order.sub_orders[0].delivery_slot)
						.add(business.timezone_offset, "m")
					const deliveryDowName = deliverySlot.format("dddd")
					title = `Cancel Catering order for ${deliveryDowName}?`
				}
				const confirmed = await this.$refs.confirmationModal.open({
					title: title,
					body: "Are you sure you want to cancel this Catering order? This cannot be undone",
					confirmation: "Cancel Catering order",
					confirmationIcon: "sk-icon-times-regular",
					cancel: "Close",
					type: ConfirmationModalType.RED
				})
				if (confirmed) {
					this.toggleLoader(true)
					const success = await this.$store.dispatch(
						`prepackagesModule/${ActionTypes.CANCEL_PREPACKAGED_ORDER}`,
						order
					)
					const type = success
						? NotificationType.SUCCESS
						: NotificationType.ERROR
					const message = success
						? "Successfully cancelled Catering order"
						: "Failed to cancel Catering order, please contact support"
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Catering",
						message: message,
						type: type
					})

					if (success) {
						switch (this.activeTabId) {
							case 0: {
								if (this.$refs.prepackagesCalendar) {
									this.$refs.prepackagesCalendar.getPrepackagedOrders()
								}
								break
							}
							case 1: {
								if (this.$refs.prepackagesList) {
									this.$refs.prepackagesList.getPrepackagedOrders()
								}
								break
							}
						}
					}
					this.toggleLoader(false)
				}
			} catch (e) {
				this.toggleLoader(false)
				throw e
			}
		},
		requestPrepackages() {
			this.$refs.prepackagesIntroModal.open()
		}
	}
}
</script>