let loaderMixin = {
  data: function() {
    return {
      loader: null,
    }
  },
  methods: {
    toggleLoader(show) {
      if (show) {
        if (!this.loader) {
          this.loader = this.$loading.show()
        }
      } else if (this.loader) {
        this.loader.hide()
        this.loader = null
      }
    }
  },
  beforeDestroy: function() {
    this.toggleLoader(false)
  }
}

export default loaderMixin
