import utils from "@/utils/utils"
import search_service from "@/services/search"
import hub_service from "@/services/hub"
import restaurant_service from "@/services/restaurants"
import business_service from "@/services/businesses"
import ActionTypes from "@/store/modules/hubs/action-types"
import MutationTypes from "@/store/modules/hubs/mutation-types"
import Vue from "vue"

const vue = new Vue()

export default {
    async [ActionTypes.GET_HUBS]({ commit }, payload) {
        try {
            let filters = {}
            if (payload.businessId) {
                filters.business = {
                    ids: [payload.businessId]
                }
            }
            const response = await search_service.searchHubs({
                filters: filters
            })
            if (response.status == 200 && response.data && response.data.hub_locations) {
                commit(MutationTypes.GOT_HUBS, response.data.hub_locations)
            } else {
                throw `API Error: ${response.status}`
            }
            return true
        } catch (e) {
            commit(MutationTypes.GOT_HUBS, [])
            vue.bugsnag.notify(
                "Failed to get hubs",
                event => {
                    event.addMetadata("error", {
                        error: e
                    })
                }
            )
			return false
		}
    },
    async [ActionTypes.ADD_HUB]({ commit }, payload) {
        try {
            const response = await hub_service.addHub({
                name: payload.name,
                city: payload.location.city,
                state: payload.location.state,
                external_location_id: payload.location.id
            })
            if (response.status == 200 && response.data.hub_location) {
                commit(MutationTypes.ADDED_HUB, response.data.hub_location)
                return true
            } else {
                throw `API Error: ${response.status}`
            }
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to add hub",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        payload: payload
                    })
                }
            )
			return false
		}
    },
    async [ActionTypes.GET_HUB]({ commit }, id) {
        try {
            commit(MutationTypes.GOT_HUB, null)
            const response = await hub_service.getHub(id)
            if (response.status == 200 && response.data.hub_location) {
                commit(MutationTypes.GOT_HUB, response.data.hub_location)
            } else {
                throw `API Error: ${response.status}`
            }
            return true
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to get hub",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        id: id
                    })
                }
            )
			return false
        }
    },
    async [ActionTypes.UPDATE_HUB]({ commit, state }, payload) {
        try {
            if (state.currentHub && payload.id == state.currentHub.id && utils.isObjectEqual(payload, state.currentHub)) {
                return true
            }
            const response = await hub_service.updateHub(payload.id, payload)
            if (response.status == 200 && response.data.hub_location) {
                commit(MutationTypes.GOT_HUB, response.data.hub_location)
            } else {
                throw `API Error: ${response.status}`
            }
            return true
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to update hub",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        payload: payload
                    })
                }
            )
			return false
        }
    },
    async [ActionTypes.GET_HUB_RESTAURANT_MENUS]({ commit, state, dispatch, rootState, rootGetters }) {
        try {
            commit(MutationTypes.GOT_HUB_RESTAURANT_MENUS, [])
            if (!state.currentHub) {
                throw "Missing current hub!"
            }
            if (!state.currentHub.restaurant_menu_ids ||state.currentHub.restaurant_menu_ids.length == 0) {
                return true
            }

            const promises = [
                restaurant_service.getMenusByIds(state.currentHub.restaurant_menu_ids.join(), "opening_hours")
            ]

            const missingBusinessIds = state.currentHub.business_ids.filter((id) => !rootGetters.businessesMap[id])
            if (missingBusinessIds.length > 0) {
                promises.push(dispatch("getBusinessesByIds", {
                    ids: missingBusinessIds,
                    silently: true
                }, { root: true }))
            }

            const responses = await Promise.all(promises)
            
            let menus = []
            if (responses.length > 0) {
                let menuResponse = responses[0]
                menus = menuResponse.data.menus
                const missingRestaurantIds = menus.filter((menu) => !rootState.restaurants[menu.restaurant_id])
                                                    .map((menu) => menu.restaurant_id)
                if (missingRestaurantIds.length > 0) {
                    await dispatch("getRestaurantsByIds", {
                        ids: missingRestaurantIds,
                        silently: true
                    }, { root: true })
                }
                const businesses = state.currentHub.business_ids.map((id) => rootGetters.businessesMap[id])
                menus.forEach(menu => {
                    menu.businesses = businesses.filter(business => business.menus && business.menus.some(businessMenu => businessMenu.id == menu.id))
                    menu.restaurant = rootState.restaurants[menu.restaurant_id]
                    menu.timezoneOffset = menu.restaurant ? menu.restaurant.timezone_offset : 0
                })
            }
            commit(MutationTypes.GOT_HUB_RESTAURANT_MENUS, menus)
            return true
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to get hub restaurant menus",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        hub: state.currentHub
                    })
                }
            )
            commit(MutationTypes.GOT_HUB_RESTAURANT_MENUS, [])
			return false
        }
    },
    async [ActionTypes.ADD_HUB_RESTAURANT_MENU]({ commit, state, dispatch }, menuIds) {
        try {
            if (!state.currentHub) {
                throw "Missing current hub!"
            }
            const response = await hub_service.addRestaurantMenus(state.currentHub.id, {
                restaurant_menu_ids: menuIds
            })
            if (response.status == 200 && response.data.hub_location) {
                commit(MutationTypes.GOT_HUB, response.data.hub_location)
                await dispatch(ActionTypes.GET_HUB_RESTAURANT_MENUS)
                return true
            } else {
                throw `API Error: ${response.status}`
            }
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to add hub restaurant menus",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        hub: state.currentHub,
                        menuIds: menuIds
                    })
                }
            )
			return false
        }
    },
    async [ActionTypes.GET_HUB_BUSINESSES]({ state, commit }) {
        try {
            commit(MutationTypes.GOT_HUB_BUSINESSES, [])
            if (!state.currentHub) {
                throw "Missing current hub!"
            }

            const promises = state.currentHub.business_ids.map((businessId) => {
                let promise = business_service.getBusiness(businessId)
                promise.catch((e) => {
                    vue.bugsnag.notify(
                        "Failed to fetch hub business",
                        event => {
                            event.addMetadata("error", {
                                error: e,
                                hub: state.currentHub,
                                businessId: businessId
                            })
                        }
                    )
                })
                return promise
            })
            const responses = await Promise.all(promises)
            let businesses = responses.map((response) => response.data)
            commit(MutationTypes.GOT_HUB_BUSINESSES, businesses)
            return true
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to get hub businesses",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        hub: state.currentHub
                    })
                }
            )
            commit(MutationTypes.GOT_HUB_BUSINESSES, [])
			return false
        }
    },
    async [ActionTypes.ADD_HUB_BUSINESS]({ commit, state, dispatch }, payload) {
        try {
            if (!state.currentHub) {
                throw "Missing current hub!"
            }
            const response = await hub_service.addBusiness(state.currentHub.id, {
                business_id: payload.businessId,
                menu_ids: payload.menuIds
            })
            if (response.status == 200 && response.data.hub_location) {
                commit(MutationTypes.GOT_HUB, response.data.hub_location)
                await dispatch(ActionTypes.GET_HUB_BUSINESSES)
                commit(MutationTypes.ADDED_HUB_BUSINESS, {
                    businessId: payload.businessId,
                    menuIds: payload.menuIds
                })
                return true
            } else {
                throw `API Error: ${response.status}`
            }
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to add hub business",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        hub: state.currentHub,
                        payload: payload
                    })
                }
            )
			return false
        }
    },
    async [ActionTypes.UPDATE_HUB_BUSINESS_MENUS]({ state, commit }, payload) {
        try {
            if (!state.currentHub) {
                throw "Missing current hub!"
            }
            const response = await hub_service.updateHubBusiness(state.currentHub.id, payload.id, {
                menu_ids: payload.menuIds
            })
            if (response.status == 200) {
                if (payload.menus) {
                    commit(MutationTypes.UPDATED_HUB_BUSINESS_MENUS, {
                        menus: payload.menus,
                        menuIds: payload.menuIds,
                        businessId: payload.id
                    })
                }
                return true
            } else {
                throw `API Error: ${response.status}`
            }
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to update hub business menus",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        hub: state.currentHub,
                        payload: payload
                    })
                }
            )
			return false
        }
    }
}