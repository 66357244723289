<template>
	<div @click="checkDisabled">
		<vue-dropzone
			:class="{'dropzone-disabled': disabled}"
			:id="dropzoneId"
			:options="dropOptions"
			:awss3="awss3"
			:useCustomSlot="true"
			:includeStyling="false"
			@vdropzone-success="success"
			@vdropzone-processing="loading(true)"
			@vdropzone-queue-complete="loading(false)"
		>
			<div class="dropzone-message sk-widget" :style="imageBannerStyle">
				<div>
					<div class="dropzone-title" :style="imageBannerTitleColor">
						{{ titleText }}
					</div>
					<div
						class="dropzone-recommendation"
						:style="imageBannerSubtitleColor"
					>
						recommended size {{ recommendedSize }}
					</div>
				</div>
			</div>
			<button class="button button-light-grey">
				<i class="sk-icon-camera-solid"></i>
				Change Photo
			</button>
		</vue-dropzone>
	</div>
</template>

<style scoped>
* >>> .dz-message {
	position: relative;
}

* >>> .dz-preview {
	display: none;
}

.dropzone-message {
	height: 109px;
	border-radius: 5px;
	background-size: cover !important;
	padding: 0;
}

.dropzone-message > div:first-child {
	display: flex;
	flex-direction: column;
	align-items: top;
	justify-content: flex-start;
	padding-top: 20px;
	padding-left: 20px;
}

.dropzone-title,
.dropzone-recommendation {
	text-align: left;
}

.dropzone-title {
	font-size: 14px;
	font-weight: 600;
}

.dropzone-recommendation {
	font-size: 12px;
	margin-bottom: 10px;
}

.button-light-grey {
	max-height: 25px;
	min-height: 25px;
	height: 25px;
	position: absolute;
	bottom: 15px;
	right: 20px;
	padding: 0 15px;
}

.button-light-grey,
.button-light-grey i {
	font-size: 12px;
}

.dropzone-disabled {
  pointer-events: none;
}
</style>

<script>
import imageUploadMixin from "@/mixins/image-upload-mixin"

export default {
	name: "BannerUpload",
	mixins: [imageUploadMixin],
	computed: {
		imageBannerStyle() {
			if (this.previewImage) {
				const gradient =
					"linear-gradient(0deg, rgba(47, 72, 88, 0.5) 0%, rgba(47, 72, 88, 0.5) 100%) 0% 0% / cover,"
				return {
					background: `${gradient}url(${this.previewImage}) center no-repeat`
				}
			} else {
				return ""
			}
		},
		imageBannerTitleColor() {
			if (this.previewImage) {
				return {
					color: "var(--sk-white)"
				}
			} else {
				return {
					color: "var(--sk-navy)"
				}
			}
		},
		imageBannerSubtitleColor() {
			if (this.previewImage) {
				return {
					color: "var(--sk-white)"
				}
			} else {
				return {
					color: "var(--sk-grey2)"
				}
			}
		}
	}
}
</script>