<template>
	<div class="sk-radio" @click="updateModel">
		<input
			type="radio"
			v-model="model"
			:checked="model == option"
			:value="option"
			:disabled="disabled"
		/>
		<span class="checkmark">
			<span class="checkmark-fill"></span>
		</span>
	</div>
</template>

<script>
export default {
	name: "SkRadio",
	props: {
		checked: Boolean,
		disabled: Boolean,
		option: null,
		value: null
	},
	computed: {
		model: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit("input", val)
			}
		}
	},
	methods: {
		updateModel() {
			this.model = this.option
		}
	}
}
</script>