<template>
	<div class="sk-widget-alt sk-widget-hover dashboard-menu" @click="goToMenu">
		<div>
			<div class="menu-title">
				<div class="menu-name">{{ menu.name }}</div>
				<div v-if="temporarilyClosed" class="pill-alt pill-alt-red">
					Temporarily Closed
				</div>
				<div v-else-if="nowServing" class="pill-alt pill-alt-green">
					Now Serving
				</div>
				<div v-else class="pill-alt pill-alt-baby-blue">Upcoming</div>
			</div>
			<div class="menu-schedule">
				<i class="sk-icon-clock-regular-1"></i>
				<b>Serving</b>
				{{ menuHours }}
			</div>
		</div>
		<div class="restaurant-info">
			<span>Served By</span>
			<span class="restaurant-name">{{ restaurantName }}</span>
			<img
				v-if="restaurantLogo"
				:src="restaurantLogo"
				alt="Restuarant Logo"
			/>
		</div>
	</div>
</template>

<style scoped>
.sk-widget-alt {
	padding-bottom: 12px;
	cursor: pointer;
}

.menu-title {
	display: flex;
	margin-bottom: 18px;
}

.menu-name {
	font-weight: 600;
	font-size: 14px;
	color: var(--sk-dark-navy);
	flex: 1;
}

.menu-schedule {
	font-size: 10px;
	color: var(--sk-grey3);
	display: flex;
	align-items: center;
}

.menu-schedule i {
	color: var(--sk-grey2);
}

.menu-schedule i,
.menu-schedule b {
	margin-right: 5px;
}

.restaurant-info {
	display: flex;
	align-items: center;
	color: var(--sk-grey3);
	font-size: 10px;
	border-top: 1px solid var(--sk-grey-border);
	margin: 20px -20px 0 -20px;
	padding: 12px 20px 0 20px;
}

.restaurant-name {
	color: var(--sk-dark-navy);
	font-weight: 600;
	margin-left: 10px;
}

.restaurant-info img {
	width: 25px;
	height: 25px;
	border-radius: 2px;
	margin-left: auto;
}
</style>

<script>
import moment from "moment"
import momentHelper from "@/utils/moment-helper"
import { MenuType } from "@arikgaisler/utils/enums/menuType"

export default {
	name: "BusinessDashboardMenu",
	props: {
		menu: Object,
		schedules: Array
	},
	computed: {
		nowServing() {
			return this.menu.stock_status == 1
		},
		temporarilyClosed() {
			if (
				this.schedules &&
				this.schedules.length > 0 &&
				!this.menu.stock_status
			) {
				const now = moment.utc().add(this.businessTimezone, "m")
				const shouldBeInStock = this.schedules.some((schedule) => {
					const openTime = moment.utc(schedule.openTime, "HH:mm:ss")
					const closeTime = moment.utc(schedule.closeTime, "HH:mm:ss")

					if (closeTime.isBefore(openTime, "m")) {
						closeTime.add(1, "d")
					}
					return now.isBetween(openTime, closeTime, "m")
				})
				if (shouldBeInStock) {
					return true
				}
			}
			return false
		},
		restaurant() {
			const restaurantId = this.menu.restaurant_id
			return this.$store.state.restaurants[restaurantId]
		},
		restaurantName() {
			return this.restaurant ? this.restaurant.name : ""
		},
		restaurantLogo() {
			if (
				this.restaurant &&
				this.restaurant.logo != "" &&
				this.restaurant.logo != undefined
			) {
				return (
					this.$restaurantCDNURL +
					this.restaurant.slug +
					"/" +
					this.restaurant.logo
				)
			}
			return null
		},
		businessTimezone() {
			const business = this.$store.state.businessDashboardModule.business
			if (business) {
				return business.timezone_offset
			}
			return 0
		},
		menuHours() {
			if (this.menu.type == MenuType.POPUP) {
				const event =
					this.$store.state.businessDashboardModule.todaysEvents[
						this.menu.event_id
					]
				if (event) {
					const openTimeMoment = moment
						.utc(`${event.start_time}`, "HH:mm:ss")
						.add(this.businessTimezone, "m")
					const closeTimeMoment = moment
						.utc(`${event.end_time}`, "HH:mm:ss")
						.add(this.businessTimezone, "m")
					return `${openTimeMoment.format(
						"h:mm A"
					)} - ${closeTimeMoment.format("h:mm A")}`
				}
			} else {
				return this.schedules
					.slice()
					.sort((dailyHourA, dailyHourB) => {
						if (dailyHourA == -1 || dailyHourB == -1) {
							return -1
						}

						if (dailyHourA.dow != dailyHourB.dow) {
							return dailyHourA.dow - dailyHourB.dow
						}

						const openTimeANum = momentHelper.numericalTime(
							dailyHourA.openTime
						)
						const openTimeBNum = momentHelper.numericalTime(
							dailyHourB.closeTime
						)

						return openTimeANum - openTimeBNum
					})
					.reduce((range, schedule) => {
						let openTime = moment
							.utc(schedule.openTime, "HH:mm:ss")
							.format("h:mm A")
						let closeTime = moment
							.utc(schedule.closeTime, "HH:mm:ss")
							.format("h:mm A")
						if (openTime == "12:00 AM") {
							openTime = "Midnight"
						}
						if (closeTime == "12:00 AM") {
							closeTime = "Midnight"
						}
						if (range.length > 0) {
							return `${range}, ${openTime} - ${closeTime}`
						} else {
							return `${openTime} - ${closeTime}`
						}
					}, "")
			}

			return "--"
		}
	},
	methods: {
		goToMenu() {
			const business = this.$store.state.businessDashboardModule.business
			this.$router.push({
				path: `/business/${business.id}/menu/${this.menu.id}`
			})
		}
	}
}
</script>