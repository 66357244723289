<template>
	<SkBaseModal
		class="sk-modal-alt"
		:isOpen="isOpen"
		:hasLoader="true"
		@close="close"
	>
		<loading :active.sync="loading" :is-full-page="false" />
		<button class="button button-icon button-close" @click="close">
			<i class="sk-icon-times-regular"></i>
		</button>
		<div class="sk-modal-alt-title">
			<i class="new-integration-icon sk-icon-plus-regular"></i>
			<h1>
				New Integration
			</h1>
		</div>
		<div class="sk-row">
			<SkInput v-model="integrationName" :name="'Integration Name'" />
		</div>
		<div class="sk-row">
			<SkInput
				v-model="integrationReason"
				:name="'Reason (Optional)'"
				:multiline="true"
			/>
		</div>
		<div class="sk-modal-actions">
			<button class="button button-text" @click="close">
				Cancel
			</button>
			<button class="button button-primary" @click="requestIntegration">
				<i class="sk-icon-check-regular"></i>
				Request Integration
			</button>
		</div>
	</SkBaseModal>
</template>

<style scoped>
.new-integration-icon {
	font-size: 35px;
	margin-right: 20px;
	color: var(--sk-dark-navy);
}
</style>

<script>
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import SkInput from "@/components/SkInput.vue"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import Loading from "vue-loading-overlay"

export default {
	name: "RequestIntegrationModal",
	mixins: [skModalMixin],
	components: {
		Loading,
		SkBaseModal,
		SkInput
	},
	data: function () {
		return {
			integrationName: "",
			integrationReason: "",
			loading: false
		}
	},
	methods: {
		opened() {
			this.loading = false
			this.integrationName = ""
			this.integrationReason = ""
		},
		closed() {
			this.loading = false
			this.integrationName = ""
			this.integrationReason = ""
		},
		async requestIntegration() {
			if (this.integrationName.length == 0) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Integration Request",
					message: "Please enter a name for your integration",
					type: NotificationType.WARNING
				})
				return
			}
			this.loading = true

			const success = await this.$store.dispatch(
				"restaurantsModule/requestRestaurantIntegration",
				{ name: this.integrationName, reason: this.integrationReason }
			)

			this.loading = false

			if (success) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Request Submitted",
					message: "Your request for a new integration has been sent!"
				})
				this.close()
			} else {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Request Error",
					message: "Something went wrong, please try again.",
					type: NotificationType.ERROR
				})
			}
		}
	}
}
</script>