var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SkCalendarDay',{attrs:{"dayText":_vm.day.text,"totalItems":_vm.eventsList.length},nativeOn:{"click":function($event){return _vm.createEvent.apply(null, arguments)}},scopedSlots:_vm._u([{key:"calendar-short-list-item",fn:function(calendarDay){return _vm._l((_vm.eventsShortList),function(event){return _c('div',{key:event.id,staticClass:"event",class:{
				selected:
					calendarDay.selectedItem &&
					event.id == calendarDay.selectedItem.id
			},on:{"click":function($event){return calendarDay.selectItem(event)}}},[_c('span',{class:event.statusClass}),_c('span',{staticClass:"text-truncate"},[_vm._v(_vm._s(event.businessName))])])})}},{key:"calendar-full-list-item",fn:function(calendarDay){return _vm._l((_vm.eventsList),function(event){return _c('div',{key:event.id,staticClass:"event",class:{
				selected:
					calendarDay.selectedItem &&
					event.id == calendarDay.selectedItem.id
			},on:{"click":function($event){return calendarDay.selectItem(event)}}},[_c('span',{class:event.statusClass}),_c('span',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(event.businessName)+" ")])])})}},{key:"calendar-detail",fn:function(calendarDay){return [_c('EventCalendarDetail',{attrs:{"selectedItem":calendarDay.selectedItem,"position":calendarDay.position},on:{"actionSelected":_vm.actionSelected,"close":function($event){return calendarDay.close()}}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }