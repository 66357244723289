<template>
	<div class="fixed-button-bottom menu-schedules-container">
		<div class="sk-row">
			<MenuScheduleSummary
				:scheduleSummary="scheduleSummary"
				:timezone="timezone"
			/>
			<div>
				<MenuSchedule
					v-for="schedule in aggregatedSchedule"
					:key="schedule.id"
					:schedule="schedule"
					:offset="offset"
					:readonly="!canEditSchedule"
					@daySelected="selectDay"
					@dayUnselected="unselectDay"
					@updateTime="updateTime"
					@removeHours="removeHours"
				/>
				<div
					class="sk-widget menu-schedule-add-hours"
					v-if="canEditSchedule"
				>
					<div>
						<div>Add Open Hours</div>
						<div>
							Days with no hours set will be marked as 'Closed'
						</div>
					</div>
					<button class="button button-primary" @click="addHours">
						<i class="sk-icon-plus-regular"></i>
						Hours
					</button>
				</div>
			</div>
		</div>
		<button class="button button-primary button-absolute" @click="saveMenu">
			Save Details
		</button>
		<SyncBusinessMenusHoursModal ref="syncBusinessMenusHoursModal" />
	</div>
</template>

<script>
import { UserRole } from "@/enums/userRole"
import { PermissionMethod } from "@/utils/permissions"
import loaderMixin from "@/mixins/loader-mixin"
import menuScheduleMixin from "@/mixins/menu-schedule-mixin"
import MenuSchedule from "@/components/MenuSchedule.vue"
import MenuScheduleSummary from "@/components/MenuScheduleSummary.vue"
import SyncBusinessMenusHoursModal from "@/components/modals/SyncBusinessMenusHoursModal.vue"

export default {
	name: "RestaurantMenuSchedules",
	mixins: [loaderMixin, menuScheduleMixin],
	components: {
		MenuSchedule,
		MenuScheduleSummary,
		SyncBusinessMenusHoursModal
	},
	props: {
		menu: Object
	},
	computed: {
		restaurant() {
			return this.$store.state.restaurantsModule.currentRestaurant
		},
		offset() {
			return this.$store.getters["restaurantsModule/offset"]
		},
		timezone() {
			return this.$store.getters["restaurantsModule/timezone"]
		},
		canEditSchedule() {
			return this.$store.state.permissions.includes(PermissionMethod.EDIT_RESTAURANT_SCHEDULE)
		}
	},
	methods: {
		async saveMenu() {
			const openingHours = this.normalizedOpeningHours()
			const businessesToSync = this.getBusinessesToSync()
			if (
				this.$store.state.user_role == UserRole.SUPER_ADMIN &&
				businessesToSync.length > 0
			) {
				await this.$refs.syncBusinessMenusHoursModal.open({
					businesses: businessesToSync,
					hours: openingHours
				})
			}

			this.$emit("setHours", openingHours)
			this.$emit("save")
		},
		getBusinessesToSync() {
			const businessesToSync = []
			this.$store.state.restaurantsModule.restaurantBusinesses.forEach(
				business => {
					business.menus
						.filter(menu => menu.id == this.menu.id)
						.forEach(menu => {
							businessesToSync.push({
								menu: menu,
								menuName: menu.name,
								businessId: business.id,
								businessName: business.name
							})
						})
				}
			)
			return businessesToSync
		}
	}
}
</script>
