export const ExceptionReasonType = {
    "RESTAURANT_CLOSED": 0,
    "DUPLICATE_ORDER": 1,
    "MISSING_ITEM": 2,
    "LATE_DELIVERY": 3,
    "REVERSE_EXCEPTION": 4,
    "TEST_ORDER": 5,
    "CANCELLED": 6,
    "WRONG_ITEM": 7,
    "CASH_PAYMENT": 8,
    "NOT_ACCEPTED": 9,
    "COURIER_REQUESTED": 10,
    "UNDELIVERED": 11,
    "OTHER": 12
}