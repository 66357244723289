import { MenuType } from "@arikgaisler/utils/enums/menuType"
import { BusinessEventType } from "@arikgaisler/utils/enums/businessEventType"
import ActionTypes from "./action-types"
import MutationTypes from "./mutation-types"
import search_service from "@/services/search"
import restaurant_service from "@/services/restaurants"
import business_service from "@/services/businesses"
import orders_service from "@/services/orders"
import moment from "moment"
import Vue from "vue"
import momentHelper from "@/utils/moment-helper"

const vue = new Vue()

const dailySchedulesFromHours = function(openingHours, openingHoursExceptions, dow, date, timezoneOffset) {
	const openingHoursIndex = openingHours.findIndex(openingHours => openingHours.day_of_week == dow)
	if (openingHoursIndex <= -1) {
		return []
	}

	const now = moment().utc().add(timezoneOffset, "m")

	let schedule = {
		0: [],
		1: [],
		2: [],
		3: [],
		4: [],
		5: [],
		6: []
	}

	openingHours.forEach((hour) => {
		const schedules = momentHelper.createScheduleFromRange(hour.open_time, hour.close_time, hour.day_of_week, timezoneOffset)
		for (let scheduleDow in schedules) {
			schedule[scheduleDow] = schedule[scheduleDow].concat(schedules[scheduleDow])
		}
	})

	let dailySchedules = Object.values(schedule).flat().reduce((schedules, schedule) => {
		if (schedule.rangesFrom == dow || schedule.dow == dow) {
			schedules.push(schedule)
		}
		return schedules
	}, [])

	if (openingHoursExceptions.length > 0) {
		openingHoursExceptions.forEach((hourException) => {
			if (hourException.allDay) {
				dailySchedules = []
			} else {
				dailySchedules = dailySchedules.reduce((schedules, schedulePiece) => {
					if (hourException.schedules[schedulePiece.dow] && hourException.schedules[schedulePiece.dow].length > 0) {
						let adjustedOpeningHours = null
						hourException.schedules[schedulePiece.dow].find((exceptionSchedulePiece) => {
							adjustedOpeningHours = momentHelper.intersectWithinRanges(exceptionSchedulePiece, schedulePiece)
							if (adjustedOpeningHours) {
								return true
							}
							return false
						})
						if (adjustedOpeningHours) {
							schedules.push(Object.assign({}, schedulePiece, {
								openTime: adjustedOpeningHours.openTime,
								closeTime: adjustedOpeningHours.closeTime,
								dow: schedulePiece.dow
							}))
						}
					} else {
						schedules.push(schedulePiece)
					}
					return schedules
				}, [])
			}
		})
	}
	
	dailySchedules.forEach((schedule) => {
		const openMoment = moment.utc(`${date} ${schedule.openTime}`, "YYYY-MM-DD HH:mm:ss")
		const closeMoment = moment.utc(`${date} ${schedule.closeTime}`, "YYYY-MM-DD HH:mm:ss")
		if (openMoment.isAfter(now) || now.isBetween(openMoment, closeMoment, "m", "[)")) {
			schedule.isUpcoming = true
		}
	})

	return dailySchedules
}

export default {
	async [ActionTypes.GET_DASHBOARD_DATA]({ commit, dispatch, rootGetters }, payload) {
		try {
			commit(MutationTypes.LOADING)
			const restaurantId = payload && payload.restaurantId ? payload.restaurantId : rootGetters.restaurant.id
			await Promise.all([
				dispatch(ActionTypes.FILTER_BY_RESTAURANT, restaurantId),
				dispatch(ActionTypes.GET_RECENT_ORDERS, restaurantId)
			])
			await Promise.all([
				dispatch(ActionTypes.GET_AVERAGE_RATING),
				dispatch(ActionTypes.GET_TODAYS_BUSINESSES)
			])
			await dispatch(ActionTypes.GET_TODAYS_EVENTS)
			commit(MutationTypes.LOADED)
			return true
		} catch (e) {
			commit(MutationTypes.LOADED)
			vue.bugsnag.notify(
                "Failed to get restaurant dashboard data",
                event => {
                    event.addMetadata("error", {
                        error: e,
						params: payload
                    })
                }
            )
			return false
		}
	},
	async [ActionTypes.FILTER_BY_RESTAURANT]({ commit }, restaurantId) {
		try {
			const response = await restaurant_service.getRestaurant(restaurantId)
			if (response.status == 200) {
				const availableMenus = []
				const restaurant = response.data
				commit(MutationTypes.GOT_RESTAURANT, restaurant)
				const timezoneOffset = restaurant && restaurant.timezone_offset ? restaurant.timezone_offset : 0
				const now = moment().utc().add(timezoneOffset, "m")
				const todaysDate = now.format("YYYY-MM-DD")
				const normalizedDow = momentHelper.normalizeDayOfWeek(now)

				let menus = restaurant && restaurant.menus ? restaurant.menus : []
				menus = menus.filter(menu => {
					const inStockPopupEvent = menu.stock_status == 1 && menu.type == MenuType.POPUP
					const allowedMenuTypes = [MenuType.DEFAULT, MenuType.ESSENTIALS]
					const validMenu = menu.opening_hours && menu.opening_hours.length > 0 && allowedMenuTypes.includes(menu.type)
					if (inStockPopupEvent || validMenu) {
						return true
					}
				})

				let openingHoursExceptions = []

				if (restaurant.opening_hours_exceptions && restaurant.opening_hours_exceptions.length > 0) {
					const now = moment.utc().startOf("d")
					openingHoursExceptions = restaurant.opening_hours_exceptions.reduce((hourExceptions, hourException) => {
						const startTime = hourException.open_time ? hourException.open_time : "00:00"
						const closeTime = hourException.close_time ? hourException.close_time : "23:59"
						const startDate = moment.utc(`${hourException.start_date} ${startTime}`, "YYYY-MM-DD HH:mm:ss")
						const endDate = moment.utc(`${hourException.end_date} ${closeTime}`, "YYYY-MM-DD HH:mm:ss")
						const localStartDate = startDate.clone()
						const localEndDate = endDate.clone()
						if (now.isBetween(startDate.startOf("d"), endDate.startOf("d"), "days", "[]")) {
							if (hourException.open_time) {
								localStartDate.add(timezoneOffset, "m")
							}
							if (hourException.close_time) {
								localEndDate.add(timezoneOffset, "m")
							}
							const dow = momentHelper.normalizeDayOfWeek(moment.utc().add(timezoneOffset, "m"))
							hourExceptions.push({
								dow: dow,
								openTime: startTime,
								localOpenTime: localStartDate.format("HH:mm:ss"),
								closeTime: closeTime,
								localCloseTime: localEndDate.format("HH:mm:ss"),
								allDay: localStartDate.format("HH:mm") == "00:00" && localEndDate.format("HH:mm") == "23:59",
								schedules: momentHelper.createScheduleFromRange(startTime, closeTime, dow, timezoneOffset)
							})
						}
						return hourExceptions
					}, [])

				}

				menus.forEach(menu => {
					if (menu.type == MenuType.POPUP) {
						availableMenus.push({
							schedules: null,
							data: menu
						})
					} else {
						const dailySchedules = dailySchedulesFromHours(menu.opening_hours, openingHoursExceptions, normalizedDow, todaysDate, timezoneOffset)
	
						if (dailySchedules.length > 0) {
							availableMenus.push({
								schedules: dailySchedules,
								data: menu
							})			
						}
					}
				})

				commit(MutationTypes.GOT_TODAYS_RESTAURANT_MENUS, {
					menus: availableMenus,
					openingHoursExceptions: openingHoursExceptions
				})
			} else {
				throw `API Error: ${response.status}`
			}
			return true
		} catch (e) {
			commit(MutationTypes.GOT_TODAYS_RESTAURANT_MENUS, {
				menus: [],
				openingHoursExceptions: null
			})
            vue.bugsnag.notify(
                "Failed to get dashboard restaurant",
                event => {
                    event.addMetadata("error", {
                        error: e,
						restaurantId: restaurantId
                    })
                }
            )
            return false
        }
	},
	async [ActionTypes.GET_AVERAGE_RATING]({ state, commit }) {
        try {
			const end = moment.utc().hours(0).minutes(0).seconds(0)
			const start = moment
				.utc()
				.hours(0)
				.minutes(0)
				.seconds(0)
				.add(-1, "M")
            let averageOrderScoreFilters = {
                created_time_local_from: start.format(),
                created_time_local_to: end.format()
            }

			averageOrderScoreFilters.restaurant = {
				ids: [state.restaurant.id]
			}

            const response = await search_service.searchAverageOrderScore({
                filters: averageOrderScoreFilters
            })
            if (response.status == 200 && response.data) {
                commit(MutationTypes.GOT_AVERAGE_RATING, response.data.avg_score)
            } else {
				throw `API Error: ${response.status}`
            }
			return true
        } catch (e) {
			commit(MutationTypes.GOT_AVERAGE_RATING, null)
            vue.bugsnag.notify(
                "Failed to get dashboard average rating data",
                event => {
                    event.addMetadata("error", {
                        error: e,
						restaurantId: state.restaurant ? state.restaurant.id : 0
                    })
                }
            )
            return false
        }
    },
	async [ActionTypes.GET_TODAYS_BUSINESSES]({ state, commit }) {
		try {
			if (state.todaysRestaurantMenus.length > 0) {
				const response = await business_service.getRestaurantBusinesses(state.restaurant.id)
				if (response.status == 200) {
					const timezoneOffset = state.restaurant && state.restaurant.timezone_offset ? state.restaurant.timezone_offset : 0
					const now = moment().utc().add(timezoneOffset, "m")
					const todaysDate = now.format("YYYY-MM-DD")
					const normalizedDow = momentHelper.normalizeDayOfWeek(now)
					const restaurantMenuIds = state.todaysRestaurantMenus.map((menu) => menu.data.id)
					const businesses = {}
					const allowedMenuTypes = [MenuType.DEFAULT, MenuType.ESSENTIALS]
					response.data.businesses.forEach(business => {
						let businessMenus = business.menus.filter(menu => restaurantMenuIds.includes(menu.id))
						if (businessMenus.length > 0) {
							businesses[business.id] = {
								data: business,
								menus: [],
								inStock: false
							}

							businessMenus.forEach((menu) => {
								if (menu.type == MenuType.POPUP && menu.stock_status == 1) {
									businesses[business.id].menus.push({
										schedules: null,
										data: menu
									})
								} else if (allowedMenuTypes.includes(menu.type)) {
									const dailySchedules = dailySchedulesFromHours(menu.opening_hours, state.openingHoursExceptions, normalizedDow, todaysDate, timezoneOffset)
									if (dailySchedules.length > 0) {
										if (menu.stock_status == 1) {
											businesses[business.id].inStock = true
										}
										dailySchedules.sort((scheduleA, scheduleB) => {
											const aOpenTime = momentHelper.numericalTime(scheduleA.openTime)
											const bOpenTime = momentHelper.numericalTime(scheduleB.openTime)
											return aOpenTime - bOpenTime
										})
										businesses[business.id].menus.push({
											schedules: dailySchedules,
											data: menu
										})
									}
								}
							})
						}
					})
					commit(MutationTypes.GOT_TODAYS_BUSINESSES, businesses)
				} else {
					throw `API Error: ${response.status}`
				}
			} else {
				commit(MutationTypes.GOT_TODAYS_BUSINESSES, [])
			}
		} catch (e) {
			commit(MutationTypes.GOT_TODAYS_BUSINESSES, [])
            vue.bugsnag.notify(
                "Failed to get todays businesses",
                event => {
                    event.addMetadata("error", {
                        error: e,
						restaurantId: state.restaurant ? state.restaurant.id : 0
                    })
                }
            )
            return false
        }
	},
	async [ActionTypes.GET_TODAYS_EVENTS]({ state, commit }) {
		try {
			const eventMenuIds = []
			Object.values(state.todaysBusinesses).forEach(business => {
				business.menus.forEach((menu => {
					if (menu.data.event_id && !eventMenuIds.includes(menu.data.event_id)) {
						eventMenuIds.push(menu.data.event_id)
					}
				}))
			})

			if (eventMenuIds.length > 0) {
				const response = await business_service.getBusinessEvents(eventMenuIds.join())
				if (response.status == 200 && response.data.events) {
					const popupEvents = response.data.events.filter(event => event.type == BusinessEventType.POPUP)
					commit(MutationTypes.GOT_TODAYS_EVENTS, popupEvents)
				} else {
					throw `API Error: ${response.status}`
				}
			} else {
				commit(MutationTypes.GOT_TODAYS_EVENTS, [])	
			}
			return true
		} catch (e) {
			commit(MutationTypes.GOT_TODAYS_EVENTS, [])
			vue.bugsnag.notify(
				"Failed to get todays business events",
				event => {
					event.addMetadata("error", {
						error: e,
						restaurantId: state.restaurant ? state.restaurant.id : 0
					})
				}
			)
			return false
		}
	},
	async [ActionTypes.GET_RECENT_ORDERS]({ state, commit }, restaurantId) {
		try {
            const response = await orders_service.getActiveOrders({
				restaurant_ids: restaurantId
			})
			if (response.status == 200 && response.data.orders) {
				const orders = response.data.orders.slice(0, 3)
				commit(MutationTypes.GOT_RECENT_ORDERS, orders)
			} else {
				throw `API Error: ${response.status}`
			}
			return true
		} catch (e) {
			commit(MutationTypes.GOT_RECENT_ORDERS, [])
			vue.bugsnag.notify(
				"Failed to get recent orders",
				event => {
					event.addMetadata("error", {
						error: e,
						restaurantId: state.restaurant ? state.restaurant.id : 0
					})
				}
			)
			return false
		}
	}
}