<template>
	<SkBaseModal
		class="request-supply-modal"
		:isOpen="isOpen"
		:showCloseButton="false"
		:hasLoader="true"
		@close="close"
	>
		<loading :active.sync="loading" :is-full-page="false" />
		<div class="sk-modal-alt-title">
			<h1>Order Supplies</h1>
		</div>
		<SkModalStepNavbar
			:steps="steps"
			:currentStep="currentStep"
			@setStep="setStep"
		/>
		<transition-group name="fade-height">
			<div
				v-show="currentStep == requestSupplySteps.ENTITY"
				class="sk-modal-section"
				key="0"
			>
				<div class="sk-row">
					<div class="sk-widget-alt sk-radio-group">
						<SkRadio
							:option="entityTypes.BUSINESS"
							v-model="entityType"
						/>
						<span>Business</span>
					</div>
					<div class="sk-widget-alt sk-radio-group">
						<SkRadio
							:option="entityTypes.RESTAURANT"
							v-model="entityType"
						/>
						<span>Restaurant</span>
					</div>
				</div>
				<div class="sk-row">
					<TypeaheadInput
						ref="entitySearchInput"
						:invalid="false"
						:placeholder="entitySearchPlaceholder"
						:fetch="autocompleteEntity"
						:serializer="typeaheadSerializer"
						@selected="entitySelected"
					/>
				</div>
			</div>
			<div
				v-show="currentStep == requestSupplySteps.SUPPLY"
				key="1"
				class="sk-modal-section"
			>
				<SupplyItemQuantityInput
					v-for="supplyItem in supplies"
					:key="supplyItem.id"
					:item="supplyItem"
					v-model="quantities[supplyItem.id]"
				/>
				<button
					v-if="filterBySupplyItem"
					class="
						button button-text
						sk-widget-section-text-button-navy
					"
					@click="showMoreSupplies"
				>
					<i class="sk-icon-plus-regular"></i>
					Add more supplies to this order?
				</button>
			</div>
			<div
				v-if="currentStep == requestSupplySteps.REVIEW"
				key="2"
				class="sk-modal-section"
			>
				<SetupReviewStep
					v-if="showEntityStep"
					:stepName="'Send To'"
					:stepId="requestSupplySteps.ENTITY"
					:invalid="entityStepInvalid"
					:complete="entityStepComplete"
					:maxProgress="1"
					:showProgress="false"
					:reviewingStepId="reviewingStepId"
					:openByDefault="true"
					@reviewStep="reviewStep"
					@goToStep="setStep"
				>
					<div
						class="setup-review-step-detail"
						:class="{
							invalid: entityStepInvalid
						}"
					>
						{{ sendToLabel }}
						<b v-sk-unassigned="entityName"></b>
					</div>
				</SetupReviewStep>
				<SetupReviewStep
					:stepName="'Type & Quantity'"
					:stepId="requestSupplySteps.SUPPLY"
					:invalid="supplyStepInvalid"
					:complete="supplyStepComplete"
					:maxProgress="1"
					:showProgress="false"
					:reviewingStepId="reviewingStepId"
					:openByDefault="true"
					@reviewStep="reviewStep"
					@goToStep="setStep"
				>
					<div
						v-for="supplyItem in reviewSupplies"
						:key="supplyItem.id"
						class="setup-review-step-detail supply-item-review"
					>
						<div>
							<b>{{ supplyItem.name }}</b>
							<b>{{ supplyItem.totalPrice | currency }}</b>
						</div>
						<div>
							Quantity:
							<b>{{ supplyItem.quantity }}</b>
						</div>
					</div>
					<div class="supply-item-review-divider"></div>
					<div class="setup-review-step-detail supply-item-review">
						<div>
							<b>Total</b>
							<b>{{ suppliesTotalPrice | currency }}</b>
						</div>
						<div>
							Quantity:
							<b>{{ suppliesTotal }}</b>
						</div>
					</div>
				</SetupReviewStep>
				<div class="sk-row">
					<div class="step-hint">
						* Payment will not automatically be taken. All charges
						will take place on the businesses billing cycle.
					</div>
				</div>
			</div>
		</transition-group>
		<div class="sk-modal-actions">
			<button @click="close" class="button button-text">Close</button>
			<button
				v-if="currentStep != lastStep"
				class="button button-primary"
				@click="nextStep"
			>
				Continue
				<i class="sk-icon-caret-right-solid"></i>
			</button>
			<button v-else class="button button-primary" @click="orderSupplies">
				Order Supplies
			</button>
		</div>
	</SkBaseModal>
</template>

<style scoped>
.sk-modal-alt-title {
	margin-bottom: 0;
	position: relative;
}

.request-supply-modal >>> .sk-modal-content {
	background: var(--sk-greybg2);
}

.sk-radio-group >>> .sk-radio {
	width: 20px;
	height: 20px;
	margin-right: 15px;
}

.sk-radio-group span {
	font-weight: 600;
	font-size: 14px;
	color: var(--sk-dark-navy);
}

.sk-modal-section >>> .supply-item-quantity-input {
	margin: 15px 0;
}

.sk-modal-actions .button i {
	margin-left: 30px;
	margin-right: 0px;
}

.supply-item-review div:first-child {
	display: flex;
	justify-content: space-between;
}

.supply-item-review div:first-child b {
	margin-left: 0;
}

.supply-item-review div:last-child b {
	margin-left: 5px;
}

.supply-item-review-divider {
	padding-bottom: 15px;
	margin-top: -5px;
	border-top: 1px solid var(--sk-grey);
}

.step-hint {
	font-size: 12px;
	font-style: italic;
	color: var(--sk-grey2);
}
</style>

<script>
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import { EntityType } from "@/enums/entityType"
import utils from "@/utils/utils"
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import currencyFilterMixin from "@/mixins/currency-filter-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import SkInput from "@/components/SkInput.vue"
import SkRadio from "@/components/SkRadio.vue"
import Loading from "vue-loading-overlay"
import TypeaheadInput from "@/components/TypeaheadInput.vue"
import SetupReviewStep from "@/components/modals/SetupReviewStep.vue"
import SkModalStepNavbar from "@/components/modals/SkModalStepNavbar.vue"
import SupplyItemQuantityInput from "@/components/modals/supplies/SupplyItemQuantityInput.vue"

const RequestSupplyStep = {
	ENTITY: 0,
	SUPPLY: 1,
	REVIEW: 2
}

export default {
	name: "RequestSuppliesModal",
	mixins: [skModalMixin, currencyFilterMixin],
	components: {
		SkBaseModal,
		SkInput,
		SkRadio,
		Loading,
		TypeaheadInput,
		SetupReviewStep,
		SkModalStepNavbar,
		SupplyItemQuantityInput
	},
	data: function () {
		return {
			loading: false,
			reviewingStepId: null,
			currentStep: 0,
			lastStep: 2,
			stepsSeen: [],
			requestSupplySteps: RequestSupplyStep,
			entityTypes: EntityType,
			entityType: EntityType.BUSINESS,
			entity: null,
			quantities: {},
			filterBySupplyItem: null
		}
	},
	computed: {
		canRequestSupplies() {
			return this.steps.every((step) => !step.isInvalid)
		},
		entityName() {
			return this.entity ? this.entity.name : null
		},
		entityStepComplete() {
			return this.entity != null
		},
		entityStepInvalid() {
			if (this.stepsSeen.includes(RequestSupplyStep.ENTITY)) {
				return !this.entity
			}
			return false
		},
		supplyStepComplete() {
			return !utils.isObjEmpty(this.quantities)
		},
		supplyStepInvalid() {
			if (this.stepsSeen.includes(RequestSupplyStep.SUPPLY)) {
				return utils.isObjEmpty(this.quantities)
			}
			return false
		},
		showEntityStep() {
			return this.$store.getters.ownsMultipleEntities
		},
		steps() {
			let allowedSteps = []

			if (this.showEntityStep) {
				allowedSteps.push({
					id: RequestSupplyStep.ENTITY,
					isActive: this.currentStep == 0,
					isComplete: this.entityStepComplete,
					isInvalid: this.entityStepInvalid,
					text: "Send To"
				})
			}

			allowedSteps = allowedSteps.concat([
				{
					id: RequestSupplyStep.SUPPLY,
					isActive: this.currentStep == 1,
					isComplete: this.supplyStepComplete,
					isInvalid: this.supplyStepInvalid,
					text: "Type & Quantity"
				},
				{
					id: RequestSupplyStep.REVIEW,
					isActive: this.currentStep == 2,
					isComplete: false,
					text: "Pricing & Review"
				}
			])

			return allowedSteps
		},
		entitySearchPlaceholder() {
			return this.entityType == EntityType.BUSINESS
				? "Business"
				: "Restaurant"
		},
		supplies() {
			let supplies = []
			switch (this.entityType) {
				case EntityType.BUSINESS: {
					supplies = this.$store.state.businessesModule.supplies
					break
				}
				case EntityType.RESTAURANT: {
					supplies = this.$store.state.restaurantsModule.supplies
					break
				}
			}

			if (this.filterBySupplyItem) {
				const supply = supplies.find(
					(supply) => supply.id == this.filterBySupplyItem.id
				)
				if (supply) {
					return [supply]
				}
			}

			return supplies
		},
		sendToLabel() {
			return this.entityType == EntityType.BUSINESS
				? "Business"
				: "Restaurant"
		},
		reviewSupplies() {
			const supplyItems = []
			const suppliesMap = this.supplies.reduce((supplies, supplyItem) => {
				supplies[supplyItem.id] = supplyItem
				return supplies
			}, {})

			for (let supplyItemId in this.quantities) {
				if (
					!suppliesMap[supplyItemId] ||
					this.quantities[supplyItemId] == null
				) {
					continue
				}
				supplyItems.push({
					id: suppliesMap[supplyItemId].id,
					name: suppliesMap[supplyItemId].name,
					price: suppliesMap[supplyItemId].price,
					quantity: this.quantities[supplyItemId],
					totalPrice:
						suppliesMap[supplyItemId].price *
						this.quantities[supplyItemId]
				})
			}
			return supplyItems
		},
		suppliesTotalPrice() {
			return this.reviewSupplies.reduce((total, supplyItem) => {
				return total + supplyItem.totalPrice
			}, 0)
		},
		suppliesTotal() {
			return Object.values(this.quantities).reduce((total, quantity) => {
				return total + quantity
			}, 0)
		}
	},
	methods: {
		setLoader(loading) {
			this.loading = loading
		},
		reviewStep(id) {
			this.reviewingStepId = id
		},
		async handleOptions(options) {
			try {
				if (!this.supplies || this.supplies.length == 0) {
					this.setLoader(true)
					await this.$store.dispatch("getSupplies")
					this.setLoader(false)
				}
	
				if (options.supplyItem) {
					this.entityType = options.entityType
					this.filterBySupplyItem = options.supplyItem
				}
	
				if (!this.showEntityStep) {
					if (this.$store.getters.restaurant) {
						this.entity = this.$store.getters.restaurant
						if (options.entityType != EntityType.RESTAURANT) {
							this.entityType = EntityType.RESTAURANT
						}
					} else if (this.$store.getters.business) {
						this.entity = this.$store.getters.business
						if (options.entityType != EntityType.BUSINESS) {
							this.entityType = EntityType.BUSINESS
						}
					}
					this.currentStep = 1
				}
			} catch (e) {
				this.close()
				throw e
			}
		},
		closed() {
			this.loading = false
			this.currentStep = this.showEntityStep ? 0 : 1
			this.quantities = {}
			this.filterBySupplyItem = null
			this.entityType = EntityType.BUSINESS
			this.entity = null
			this.$refs.entitySearchInput.clearSearch()
		},
		nextStep() {
			if (this.currentStep < this.lastStep) {
				this.setStep(this.currentStep + 1)
			}
		},
		setStep(id) {
			if (!this.stepsSeen.includes(this.currentStep)) {
				this.stepsSeen.push(this.currentStep)
			}
			if (id == RequestSupplyStep.REVIEW) {
				this.stepsSeen = [
					RequestSupplyStep.ENTITY,
					RequestSupplyStep.SUPPLY
				]
			}
			this.currentStep = id
		},
		async orderSupplies() {
			try {
				if (!this.canRequestSupplies) {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Order Supplies",
						message: "Required fields are missing or invalid",
						type: NotificationType.WARNING
					})
					const firstInvalidStep = this.steps.find(
						(step) => step.isInvalid
					)
					this.reviewingStepId = firstInvalidStep.id
					return false
				}
				this.loading = true
				const success = await this.requestSupplies()
				if (success) {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Order Supplies",
						message: "Supplies requested succesfully!",
						type: NotificationType.SUCCESS
					})
					this.close()
				} else {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Order Supplies",
						message:
							"Something went wrong, please contact support.",
						type: NotificationType.ERROR
					})
				}
				this.loading = false
			} catch (e) {
				this.loading = false
			}
		},
		requestSupplies() {
			if (this.entityType == EntityType.BUSINESS) {
				return this.$store.dispatch(
					"businessesModule/createSupplyRequests",
					{
						businessId: this.entity.id,
						supplies: this.reviewSupplies
					}
				)
			} else {
				return this.$store.dispatch(
					"restaurantsModule/createSupplyRequests",
					{
						restaurantId: this.entity.id,
						supplies: this.reviewSupplies
					}
				)
			}
		},
		autocompleteEntity(query) {
			if (this.entityType == EntityType.BUSINESS) {
				return this.$store.dispatch(
					"businessesModule/autocompleteBusinesses",
					query
				)
			} else {
				return this.$store.dispatch(
					"restaurantsModule/autocompleteRestaurants",
					query
				)
			}
		},
		typeaheadSerializer(result) {
			return {
				text: result.name
			}
		},
		entitySelected(entity) {
			this.entity = entity
		},
		showMoreSupplies() {
			this.filterBySupplyItem = null
		}
	},
	mounted: function () {
		this.currentStep = this.showEntityStep ? 0 : 1
	},
	watch: {
		entityType() {
			this.entity = null
			this.$refs.entitySearchInput.clearSearch()
			this.filterBySupplyItem = null
			this.quantities = {}
		}
	}
}
</script>

