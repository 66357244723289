<template>
	<div class="vld-parent">
		<loading :active.sync="loading" :is-full-page="false"></loading>
		<InsightBaseWidget
			:title="'Average Rating'"
			:footerIcon="footerIcon"
			:footerTitle="footerTitle"
		>
			<div class="average-rating-content" slot="content">
				<div class="average-score-statistic">
					{{ averageRatingText }} | 10
				</div>
				<SkSliderScale :value="averageRatingPercentage" />
			</div>
		</InsightBaseWidget>
	</div>
</template>

<style scoped>
.average-score-statistic {
	font-size: 36px;
	color: var(--sk-dark-navy);
	margin-bottom: 22px;
	font-weight: 600;
}

.average-rating-content {
	padding: 22px 0;
}
</style>

<script>
import InsightBaseWidget from "@/components/insights/InsightBaseWidget.vue"
import Loading from "vue-loading-overlay"
import SkSliderScale from "@/components/SkSliderScale.vue"

export default {
	name: "AverageRatingWidget",
	components: {
		InsightBaseWidget,
		Loading,
		SkSliderScale
	},
	props: {
		showFooter: Boolean
	},
	computed: {
		loading() {
			return this.$store.state.insightsModule.averageRating.loading
		},
		averageRatingText() {
			return !this.loading ? this.averageRating : "--"
		},
		averageRatingPercentage() {
			if (this.averageRating) {
				return this.averageRating * 10
			}
			return 0
		},
		averageRating() {
			const data = this.$store.state.insightsModule.averageRating.data
			if (data) {
				return data.toFixed(2).replace(/[.,]00$/, "")
			}
			return 0
		},
		footerIcon() {
			if (this.showFooter) {
				return "sk-icon-lightbulb-light"
			}
			return null
		},
		footerTitle() {
			if (this.showFooter) {
				return "Improving your accuracy can help to improve your customer ratings!"
			}
			return null
		}
	}
}
</script>