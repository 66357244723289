
<template>
	<div class="sk-widget-alt forgot-password-widget">
		<button class="button button-link-grey back-button" @click="backToLoginClicked">
			<i class="sk-icon-long-arrow-left-regular"></i>
			Back to Log In
		</button>
		<img src="/images/2k-logo-login.png" alt="2ndKitchen Logo" />
		<div class="sk-column">
			<h1>Forgot Password?</h1>
			<div class="forgot-password-subtitle">
				2ndKitchen will email you a link to reset your password.
			</div>
		</div>
		<div class="sk-row">
			<SkInput
				ref="emailInput"
				v-model="email"
				:name="'Email'"
				:placeholder="'Email'"
				:required="true"
				@onEnter="sendForgotPasswordEmail"
			/>
		</div>
		<div class="sk-row">
			<button
				@click="sendForgotPasswordEmail"
				class="button button-primary forgot-password-button"
			>
				Request Password Reset
			</button>
		</div>
		<SignupWidgetFooter :links="footerLinks" @linkClicked="linkClicked" />
	</div>
</template>

<style scoped>
.back-button {
	position: absolute;
	top: 0;
	left: 0;
}

h1 {
	color: var(--sk-dark-navy);
	font-size: 16px;
	margin-bottom: 12px !important;
}

.forgot-password-subtitle {
	font-size: 12px;
	color: var(--sk-grey2);
	margin-bottom: 10px;
}

.forgot-password-widget {
	width: 500px;
	max-width: 500px;
	height: auto;
	padding: 0;
	position: relative;
}

.forgot-password-widget img {
	height: 40px;
	margin: 60px auto;
	display: block;
}

.forgot-password-button {
	min-width: 140px;
	flex: 0 0 auto !important;
	margin: 10px auto 0 auto;
}
</style>

<script>
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import tracker from "@/utils/tracker"
import auth from "@/utils/auth"
import SkInput from "@/components/SkInput.vue"
import SignupWidgetFooter from "@/components/login/SignupWidgetFooter.vue"

export default {
	name: "Login",
	components: {
		SkInput,
		SignupWidgetFooter
	},
	data() {
		return {
			email: "",
			footerLinks: [
				{
					id: 0,
					text: `Not a 2ndKitchen Partner? <span class="signup-link">Get Started!</span>`
				},
				{
					id: 1,
					text: `Already a Partner? <span class="signup-link">Sign In!</span>`
				}
			]
		}
	},
	methods: {
		validate() {
			return this.$refs.emailInput.validate()
		},
		backToLoginClicked() {
			tracker.trackEvent("Back To Login Clicked")
			this.$emit("backToLogin")
		},
		async sendForgotPasswordEmail() {
			tracker.trackEvent("Request Password Reset Clicked")
			const isValid = this.validate()
			if (!isValid) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Forgot Password",
					message: "Your email is required in order to reset your password",
					type: NotificationType.ERROR
				})
				return
			}

			auth.sendForgotPasswordEmail(this.email).then((response) => {
				if (!response.success) {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Forgot Password",
						message:
							"Email is not registered. Please contact your customer success manager",
						type: NotificationType.ERROR
					})
				} else {
					let titleText = `Check your email for instructions to reset your password`
					if (!response.activated) {
						titleText = `Check your email for instructions to activate your account`
					}
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Forgot Password",
						message: titleText,
						type: NotificationType.SUCCESS
					})
					this.$emit("backToLogin")
				}
			})
		},
		linkClicked(link) {
			switch (link.id) {
				case 0: {
					tracker.trackEvent("Signup Clicked")
					window.open("https://2ndkitchen.com/")
					break
				}
				case 1: {
					tracker.trackEvent("Activate Clicked")
					this.$emit("backToLogin")
					break
				}
			}
		}
	}
}
</script>