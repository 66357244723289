<template>
	<SkBaseModal
		class="business-setup-modal"
		:isOpen="isOpen"
		:showCloseButton="false"
		:hasLoader="true"
		@close="cancel"
	>
		<loading :active.sync="loading" :is-full-page="false" />
		<div class="sk-modal-alt-title">
			<h1>New Business Setup</h1>
		</div>
		<SkModalStepNavbar
			:steps="steps"
			:currentStep="currentStep"
			@setStep="setStep"
		/>
		<transition-group name="fade-height" class="business-setup-steps">
			<div v-show="currentStep == 0" class="sk-modal-section" key="0">
				<div class="sk-row">
					<SkDropdownSelect
						class="business-type-dd sk-input"
						v-model="type"
						:options="types"
						:placeholder="'Business Type'"
					/>
				</div>
				<div class="sk-row">
					<EstablishmentTypeaheadInput
						class="optional-field"
						v-model="establishment"
						:placeholder="'Google Business Name'"
					/>
				</div>

				<div class="sk-row">
					<SkInput
						v-model="business.name"
						ref="businessNameInput"
						:required="true"
						:name="'Business Name'"
					/>
				</div>
				<div class="sk-row">
					<AddressTypeaheadInput
						v-model="address"
						:invalid="isAddressInvalid"
					/>
				</div>
				<div class="sk-row">
					<SkTelephoneInput
						v-model="phone"
						class="optional-field"
						:name="'Phone Number'"
						:type="'tel'"
					/>
				</div>
				<div class="sk-row">
					<SkInput
						v-model="businessSlug"
						ref="businessSlugInput"
						:name="'Slug'"
						:required="true"
					/>
				</div>
				<div class="sk-row">
					<SkInput
						v-model="business.review_link"
						class="optional-field"
						:name="'Reviews Link'"
					/>
				</div>
				<!-- <div class="sk-row">
					<SkDropdownSelect
						class="business-type-dd sk-input"
						v-model="deliveryMode"
						:options="deliveryModes"
						:placeholder="'Delivery Mode'"
					/>
				</div> -->
				<div class="sk-row sk-modal-section-title">
					Dropoff / Pickup Methods
				</div>
				<div
					v-for="deliveryMode in deliveryModes"
					:key="deliveryMode.id"
					class="sk-switch-label-wrapper delivery-mode-line-item"
					:class="{
						'delivery-mode-line-item-active': deliveryMode.active
					}"
				>
					<SkCheckbox
						@click.native="toggleDeliveryMode(deliveryMode)"
						v-model="deliveryMode.active"
						:disabled="true"
					/>
					<span>{{ deliveryMode.name }}</span>
					<div
						v-if="deliveryMode.active"
						class="sk-radio-group"
						:class="{
							'sk-radio-group-active': deliveryMode.isDefault
						}"
					>
						<SkRadio
							:option="deliveryMode.id"
							v-model="defaultDeliveryMode"
						/>
						<span>Default</span>
					</div>
				</div>
				<div class="sk-row business-logo-row">
					<transition name="expand-horizontally">
						<img
							v-if="businessCoverPreview"
							class="business-logo-preview"
							:src="businessCoverPreview"
						/>
					</transition>
					<FileUpload
						ref="businessCoverUploader"
						:entity="'business'"
						:entity_name="business.slug"
						@set="setBusinessCover"
						@loading="setLoader"
						@previewImageChanged="setBusinessCoverPreview"
					>
						<button
							v-if="!businessCoverPreview"
							@click="coverUploadClicked"
							class="
								button button-text
								sk-widget-section-text-button-light
							"
						>
							<i class="sk-icon-plus-regular"></i>
							Add Business Cover
							<span class="optional-label">Optional</span>
						</button>
						<button
							v-else
							@click="coverRemoveClicked"
							class="
								button button-text
								sk-widget-section-text-button-light
							"
						>
							<i class="sk-icon-times-regular"></i>
							Remove Business Cover
							<span class="optional-label">Optional</span>
						</button>
					</FileUpload>
				</div>
				<div
					class="sk-row business-logo-row"
					:class="{
						expanded: businessCoverPreview
					}"
				>
					<transition name="expand-horizontally">
						<img
							v-if="businessLogoPreview"
							class="business-logo-preview"
							:src="businessLogoPreview"
						/>
					</transition>
					<FileUpload
						ref="businessLogoUploader"
						:entity="'business'"
						:entity_name="business.slug"
						@set="setBusinessLogo"
						@loading="setLoader"
						@previewImageChanged="setBusinessLogoPreview"
					>
						<button
							v-if="!businessLogoPreview"
							@click="logoUploadClicked"
							class="
								button button-text
								sk-widget-section-text-button-light
							"
						>
							<i class="sk-icon-plus-regular"></i>
							Add Business Logo
							<span class="optional-label">Optional</span>
						</button>
						<button
							v-else
							@click="logoRemoveClicked"
							class="
								button button-text
								sk-widget-section-text-button-light
							"
						>
							<i class="sk-icon-times-regular"></i>
							Remove Business Logo
							<span class="optional-label">Optional</span>
						</button>
					</FileUpload>
				</div>
			</div>
			<div v-if="currentStep == 1" key="1" class="sk-modal-section">
				<div class="filter-group-header" @click="toggleExpandGroup(1)">
					Basic
					<div class="filter-group-divider"></div>
					<i
						class="sk-icon-chevron-down-regular"
						:class="{ flipped: expandedGroupIds[1] }"
					></i>
				</div>
				<transition-expand>
					<div v-if="expandedGroupIds[1]">
						<div class="advanced-filter-row">
							<SkInput
								v-model="business.service_fee"
								:name="'Service Fee'"
								:placeholder="'Service Fee'"
								:type="'number'"
								:group="'%'"
								ref="businessServiceFeeInput"
								:validation="requiredNumberRegex"
							/>
						</div>
						<div class="advanced-filter-row">
							<SkInput
								v-model="business.tax"
								:name="'Tax'"
								:placeholder="'Tax'"
								:type="'number'"
								:group="'%'"
								:min="0"
								ref="businessTaxInput"
								:validation="requiredNumberRegex"
							/>
						</div>
						<div class="advanced-filter-row">
							<SkInput
								v-model="business.min_order_amount"
								:name="'Minimum'"
								:placeholder="'Minimum'"
								:type="'number'"
								:group="'$'"
								ref="businessMinimumInput"
								:validation="requiredNumberRegex"
							/>
						</div>
					</div>
				</transition-expand>
				<div class="filter-group-header" @click="toggleExpandGroup(2)">
					Commission
					<div class="filter-group-divider"></div>
					<i
						class="sk-icon-chevron-down-regular"
						:class="{ flipped: expandedGroupIds[2] }"
					></i>
				</div>
				<transition-expand>
					<div v-if="expandedGroupIds[2]">
						<div class="advanced-filter-row">
							<SkInput
								class="commission-input"
								v-model="business.commission"
								:name="'Commission'"
								:placeholder="'Self Delivery Markup amount'"
								:type="'number'"
								:group="'$'"
								ref="businessCommissionInput"
							/>
						</div>
						<div class="advanced-filter-row">
							<SkInput
								class="commission-input"
								v-model="business.commission_threshold"
								:name="'Commission Threshold'"
								:placeholder="'Self Delivery Markup amount'"
								:type="'number'"
								ref="businessCommissionThresholdInput"
								:validation="requiredNumberRegex"
							/>
						</div>
					</div>
				</transition-expand>
				<div class="filter-group-header" @click="toggleExpandGroup(3)">
					<span class="filter-group-title">Mark up rules</span>
					<div class="filter-group-divider"></div>
					<i
						class="sk-icon-chevron-down-regular"
						:class="{ flipped: expandedGroupIds[3] }"
					></i>
				</div>
				<transition-expand>
					<div v-if="expandedGroupIds[3]">
						<div class="sk-row">
							<SkInput
								class="modal-commission-input"
								v-model="selfDeliveryMUR"
								:name="'Self Delivery Markup amount'"
								:placeholder="'Self Delivery Markup amount'"
								:type="'number'"
								:group="selfDeliveryMURTypeText"
								ref="selfDeliveryMURInput"
								:validation="requiredNumberRegex"
							/>
							<SkDropdownSelect
								ref="typeInput"
								:classes="'sk-input'"
								v-model="selfDeliveryMURType"
								:options="markupTypes"
							/>
						</div>
						<div class="sk-row">
							<SkInput
								class="modal-commission-input"
								v-model="courierMUR"
								:name="'Courier Markup amount'"
								:placeholder="'Courier Markup Amount'"
								:type="'number'"
								:group="courierMURTypeText"
								ref="courierMURInput"
								:validation="requiredNumberRegex"
							/>
							<SkDropdownSelect
								ref="typeInput"
								:classes="'sk-input'"
								v-model="courierMURType"
								:options="markupTypes"
							/>
						</div>
						<div class="sk-row">
							<SkInput
								class="modal-commission-input"
								v-model="eventsMUR"
								:name="'Events Markup amount'"
								:placeholder="'Events Markup Amount'"
								:type="'number'"
								:group="eventsMURTypeText"
								ref="eventsMURInput"
								:validation="requiredNumberRegex"
							/>
							<SkDropdownSelect
								ref="typeInput"
								:classes="'sk-input'"
								v-model="eventsMURType"
								:options="markupTypes"
							/>
						</div>
						<div class="sk-row">
							<SkInput
								class="modal-commission-input"
								v-model="toGoMUR"
								:name="'2Go Markup amount'"
								:placeholder="'2Go Markup Amount'"
								:type="'number'"
								:group="toGoMURTypeText"
								ref="toGoMURInput"
								:validation="requiredNumberRegex"
							/>
							<SkDropdownSelect
								ref="typeInput"
								:classes="'sk-input'"
								v-model="toGoMURType"
								:options="markupTypes"
							/>
						</div>
						<div class="sk-row">
							<SkInput
								class="modal-commission-input"
								v-model="prePackagedMUR"
								:name="'Catering Markup amount'"
								:placeholder="'Catering Markup Amount'"
								:type="'number'"
								:group="prePackagedMURTypeText"
								ref="prePackagedMURInput"
								:validation="requiredNumberRegex"
							/>
							<SkDropdownSelect
								ref="typeInput"
								:classes="'sk-input'"
								v-model="prePackagedMURType"
								:options="markupTypes"
							/>
						</div>
					</div>
				</transition-expand>
			</div>
			<div v-show="currentStep == 2" key="2" class="sk-modal-section">
				<section
					class="add-user-section"
					v-for="user in businessUsers"
					:key="user.uuid"
				>
					<div class="sk-row">
						<SkInput
							v-model="user.name"
							:ref="`userNameInput${user.uuid}`"
							:name="'Name'"
							:required="true"
						/>
					</div>
					<div class="sk-row">
						<SkInput
							v-model="user.email"
							:ref="`userEmailInput${user.uuid}`"
							:name="'Email'"
							:validation="emailRegex"
							:required="true"
						/>
					</div>
					<div class="sk-row">
						<SkTelephoneInput
							v-model="user.phone"
							class="optional-field"
							:ref="`userPhoneInput${user.uuid}`"
							:name="'Phone Number'"
							:type="'tel'"
							:required="true"
						/>
					</div>
					<button
						@click="removeUser(user)"
						class="
							button button-text
							sk-widget-section-text-button-light
						"
					>
						<i class="sk-icon-times-regular"></i>
						Clear User
					</button>
				</section>
				<div class="sk-row">
					<button
						@click="addNewUser"
						class="
							button button-text
							sk-widget-section-text-button-light
						"
					>
						<i class="sk-icon-plus-regular"></i>
						Add Another User
					</button>
				</div>
			</div>
			<div v-show="currentStep == 3" key="3" class="sk-modal-section">
				<section
					class="add-user-section"
					v-for="(restaurant, index) in restaurants"
					:key="restaurant.uuid"
				>
					<div class="sk-row">
						<TypeaheadInput
							:placeholder="'Restaurant'"
							:fetch="autocompleteRestaurants"
							:serializer="typeaheadSerializer"
							:query="restaurant.name"
							@selected="restaurantSelected(index, ...arguments)"
						/>
					</div>
					<div class="sk-row">
						<SkDropdownMultiSelect
							v-model="restaurant.menuIDs"
							:options="getRestaurantOptions(index)"
						/>
					</div>
					<button
						@click="removeRestaurant(restaurant)"
						class="
							button button-text
							sk-widget-section-text-button-light
						"
					>
						<i class="sk-icon-times-regular"></i>
						Remove Restaurant
					</button>
				</section>
				<div class="sk-row">
					<button
						@click="addNewRestaurant"
						class="
							button button-text
							sk-widget-section-text-button-light
						"
					>
						<i class="sk-icon-plus-regular"></i>
						Add Restaurant
					</button>
				</div>
			</div>
			<div v-if="currentStep == 4" key="4" class="sk-modal-section">
				<SetupReviewStep
					:stepName="'General'"
					:stepId="0"
					:invalid="generalStepInvalid"
					:complete="generalStepComplete"
					:progress="generalStepProgress"
					:maxProgress="7"
					:reviewingStepId="reviewingStepId"
					@reviewStep="reviewStep"
					@goToStep="setStep"
				>
					<div
						class="business-step-detail"
						:class="{
							invalid: business.name.length == 0
						}"
					>
						Business Name
						<b v-sk-unassigned="business.name"></b>
					</div>
					<div
						class="business-step-detail"
						:class="{
							invalid: type == null
						}"
					>
						Business Type
						<b v-sk-unassigned="type.text"></b>
					</div>
					<div class="business-step-detail">
						Phone Number
						<b v-sk-unassigned="formattedPhone"></b>
					</div>
					<div
						class="business-step-detail"
						:class="{
							invalid: business.slug.length == 0
						}"
					>
						Slug
						<b v-sk-unassigned="business.slug"></b>
					</div>
					<div
						class="business-step-detail"
						:class="{ invalid: !isAddressSet }"
					>
						Business Street Address
						<b v-sk-unassigned="formattedAddress"></b>
					</div>
					<div class="business-step-detail">
						Review Link
						<b v-sk-unassigned="business.review_link"></b>
					</div>
					<div class="business-step-detail">
						Delivery Modes
						<b v-html="businessDeliveryModesText"></b>
					</div>
				</SetupReviewStep>
				<SetupReviewStep
					:stepName="'Finance'"
					:stepId="1"
					:invalid="financeStepInvalid"
					:complete="financeStepComplete"
					:progress="financeStepProgress"
					:maxProgress="10"
					:reviewingStepId="reviewingStepId"
					@reviewStep="reviewStep"
					@goToStep="setStep"
				>
					<div
						class="business-step-detail"
						:class="{ invalid: business.service_fee === '' }"
					>
						Service Fee
						<b v-sk-unassigned="serviceFeeFormattedText"></b>
					</div>
					<div
						class="business-step-detail"
						:class="{ invalid: business.tax === '' }"
					>
						Tax
						<b v-sk-unassigned="taxFormattedText"></b>
					</div>
					<div
						class="business-step-detail"
						:class="{ invalid: business.min_order_amount === '' }"
					>
						Minimum
						<b v-sk-unassigned="minOrderAmountFormattedText"></b>
					</div>
					<div class="business-step-detail">
						Commission
						<b v-sk-unassigned="commissionFormattedText"></b>
					</div>
					<div class="business-step-detail">
						Commission Threshold
						<b v-sk-unassigned="business.commission_threshold"></b>
					</div>
					<div
						class="business-step-detail"
						:class="{ invalid: selfDeliveryMUR === '' }"
					>
						Self Delivery Markup
						<b v-sk-unassigned="formattedSelfDeliveryMURText"></b>
					</div>
					<div
						class="business-step-detail"
						:class="{ invalid: courierMUR === '' }"
					>
						3rd Party Markup
						<b v-sk-unassigned="formattedCourierMURText"></b>
					</div>
					<div
						class="business-step-detail"
						:class="{ invalid: eventsMUR === '' }"
					>
						Popup Markup
						<b v-sk-unassigned="formattedEventsMURText"></b>
					</div>
					<div
						class="business-step-detail"
						:class="{ invalid: toGoMUR === '' }"
					>
						2Go Markup
						<b v-sk-unassigned="formattedToGoMURText"></b>
					</div>
					<div
						class="business-step-detail"
						:class="{ invalid: prePackagedMUR === '' }"
					>
						Catering Markup
						<b v-sk-unassigned="formattedPrePackagedMURText"></b>
					</div>
				</SetupReviewStep>
				<SetupReviewStep
					:stepName="'Users'"
					:stepId="2"
					:invalid="usersStepInvalid"
					:complete="usersStepComplete"
					:progress="usersStepProgress"
					:maxProgress="businessUsersAdded.length"
					:reviewingStepId="reviewingStepId"
					@reviewStep="reviewStep"
					@goToStep="setStep"
				>
					<div
						v-for="user in businessUsersToReview"
						class="user-step-details"
						:key="user.uuid"
					>
						<div
							class="business-step-detail"
							:class="{
								invalid: user.nameInvalid
							}"
						>
							Name
							<b v-sk-unassigned="user.name"></b>
						</div>
						<div
							class="business-step-detail"
							:class="{
								invalid: user.emailInvalid
							}"
						>
							Email
							<b v-sk-unassigned="user.email"></b>
						</div>
						<div
							class="business-step-detail"
							:class="{
								invalid: user.phoneInvalid
							}"
						>
							Phone Number
							<b v-sk-unassigned="user.phone"></b>
						</div>
					</div>
				</SetupReviewStep>
				<SetupReviewStep
					:stepName="'Menus'"
					:stepId="3"
					:invalid="menuStepInvalid"
					:complete="menuStepComplete"
					:progress="menusMaxProgress"
					:maxProgress="menusMaxProgress"
					:reviewingStepId="reviewingStepId"
					@reviewStep="reviewStep"
					@goToStep="setStep"
				>
					<div
						v-for="restaurant in restaurants"
						class="user-step-details"
						:key="restaurant.uuid"
					>
						<div
							class="business-step-detail"
							:class="{
								invalid: restaurant.menuIDs.length == 0
							}"
						>
							{{ restaurant.name }}:
							<b
								v-sk-unassigned="
									getSelectedRestaurantMenuNames(restaurant)
								"
							></b>
						</div>
					</div>
				</SetupReviewStep>
				<div class="sk-row">
					<div class="sk-widget-alt sk-switch-label-wrapper">
						<SkCheckbox v-model="activateBusiness" />
						<div>Automatically Activate Business</div>
					</div>
				</div>
			</div>
		</transition-group>
		<div class="sk-modal-actions">
			<button @click="cancel" class="button button-text">Cancel</button>
			<button
				v-if="currentStep != lastStep"
				class="button button-primary"
				@click="nextStep"
			>
				Continue
				<i class="sk-icon-caret-right-solid"></i>
			</button>
			<button
				v-else
				class="button button-primary"
				:class="{
					'button-primary': canCreateBusiness,
					'button-grey': !canCreateBusiness
				}"
				@click="createBusiness"
			>
				Create Business
			</button>
		</div>
		<ConfirmationModal ref="confirmationModal" />
	</SkBaseModal>
</template>

<style scoped>
.filter-group-header {
	font-size: 14px;
	font-weight: 600;
	color: var(--sk-navy);
	display: flex;
	align-items: center;
	width: 100%;
	cursor: pointer;
	margin-top: 18px;
}

.filter-group-header .sk-icon-chevron-down-regular {
	margin-left: 15px;
	font-size: 10px;
	color: var(--sk-grey2);
}

.filter-group-header .filter-group-title {
	flex-shrink: 0;
}

.filter-group-divider {
	height: 1px;
	background: rgba(112, 120, 124, 0.35);
	width: 100%;
	margin-left: 15px;
}

.advanced-filter-row {
	margin-top: 20px;
}

.sk-modal-alt-title {
	margin-bottom: 0;
	position: relative;
}

.sk-modal-section .sk-widget-alt {
	padding: 15px;
}

.modal-commission-input {
	min-width: 350px;
}

.business-setup-steps {
	display: grid;
	grid-template-columns: 1fr;
}

.business-setup-steps > div {
	grid-row-start: 1;
	grid-column-start: 1;
}

.business-setup-steps > div {
	padding: 5px;
	margin: -5px;
}

.sk-widget-section-title {
	display: flex;
	align-items: center;
}

.sk-modal-actions .button i {
	margin-left: 30px;
	margin-right: 0px;
}

.business-logo-row {
	height: 20px;
}

.business-logo-row.expanded {
	margin-top: 50px;
}

.business-logo-row .sk-widget-section-text-button-light {
	margin-top: 0 !important;
}

.business-logo-preview {
	width: 75px;
	height: 75px;
	background: var(--sk-navy);
	border-radius: 5px;
	flex: 0 0 auto !important;
}

.business-timezone-row {
	flex-direction: column;
	background: var(--sk-grey);
	border-radius: 5px;
	padding: 12px 15px;
	color: var(--sk-grey3);
}

.business-timezone-row div:first-child {
	font-size: 14px;
	font-weight: 600;
}

.business-timezone-row div:last-child {
	font-size: 12px;
	font-style: italic;
}

.add-user-section {
	border-bottom: 1px solid var(--sk-grey);
	margin-bottom: 30px;
	padding-bottom: 10px;
}

.user-step-details + .user-step-details {
	padding-top: 20px;
	margin-top: 20px;
	border-top: 1px solid var(--sk-grey);
}

.business-step-detail {
	color: var(--sk-grey2);
	font-size: 12px;
}

.business-step-detail.invalid,
.business-step-detail.invalid .sk-muted-text,
.business-step-detail.invalid b {
	color: var(--sk-red) !important;
}

.business-step-detail:not(:last-child) {
	margin-bottom: 20px;
}

.business-step-detail .sk-muted-text {
	font-style: italic;
	font-weight: 400;
}

.business-step-detail .sk-muted-text,
.business-step-detail b {
	margin-left: 11px;
}

.business-step-detail b {
	color: var(--sk-grey3);
	font-weight: 600;
}

.sk-widget-section-text-button-light {
	font-size: 12px;
}

.sk-widget-section-text-button-light .optional-label {
	font-style: italic;
	margin-left: 10px;
}

.delivery-mode-line-item + .delivery-mode-line-item {
	margin-top: 15px;
}

.delivery-mode-line-item > span {
	margin: 0 20px 0 20px;
	color: var(--sk-grey3);
}

.delivery-mode-line-item-active > span {
	font-weight: 600;
	color: var(--sk-dark-navy);
}

.delivery-mode-line-item .sk-radio-group > span {
	color: var(--sk-grey2);
	font-style: italic;
}

.delivery-mode-line-item >>> .sk-radio {
	width: 15px;
	height: 15px;
}
</style>

<script>
import { v1 as uuidv1 } from "uuid"
import { CourierId } from "@arikgaisler/utils/enums/courierId"
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import { BusinessType } from "@arikgaisler/utils/enums/businessType"
import { EntityType } from "@/enums/entityType"
import { DeliveryMode } from "@arikgaisler/utils/enums/deliveryMode"
import { UserRole } from "@/enums/userRole"
import { MenuType } from "@arikgaisler/utils/enums/menuType"
import apiUtils from "@/services/utils"
import utils from "@/utils/utils"
import moment from "moment"
import "moment-timezone"
import gapi from "@/utils/gapi"
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import currencyFilterMixin from "@/mixins/currency-filter-mixin"
import businessMarkupRulesMixin from "@/mixins/business_markup_rules_mixin"
import phoneFilterMixin from "@/mixins/phone-filter-mixin"
import deliveryModeFormatterMixin from "@/mixins/business/delivery-mode-formatter-mixin"
import businessTypeFormatterMixin from "@/mixins/business/business-type-formatter-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import SkInput from "@/components/SkInput.vue"
import SkTelephoneInput from "@/components/SkTelephoneInput.vue"
import SkTimePicker from "@/components/SkTimePicker.vue"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"
import SkCheckbox from "@/components/SkCheckbox.vue"
import SkRadio from "@/components/SkRadio.vue"
import Loading from "vue-loading-overlay"
import TypeaheadInput from "@/components/TypeaheadInput.vue"
import FileUpload from "@/components/FileUpload.vue"
import SetupReviewStep from "@/components/modals/SetupReviewStep.vue"
import AddressTypeaheadInput from "@/components/AddressTypeaheadInput.vue"
import EstablishmentTypeaheadInput from "@/components/EstablishmentTypeaheadInput.vue"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue"
import TransitionExpand from "@/components/transitions/TransitionExpand.vue"
import SkDropdownMultiSelect from "@/components/SkDropdownMultiSelect.vue"
import restaurant_service from "@/services/restaurants"
import SkModalStepNavbar from "@/components/modals/SkModalStepNavbar.vue"

export default {
	name: "BusinessSetupModal",
	mixins: [
		skModalMixin,
		currencyFilterMixin,
		phoneFilterMixin,
		businessMarkupRulesMixin,
		deliveryModeFormatterMixin,
		businessTypeFormatterMixin
	],
	components: {
		SkBaseModal,
		SkInput,
		SkTelephoneInput,
		SkTimePicker,
		SkDropdownSelect,
		SkCheckbox,
		SkRadio,
		Loading,
		TypeaheadInput,
		SetupReviewStep,
		FileUpload,
		AddressTypeaheadInput,
		EstablishmentTypeaheadInput,
		ConfirmationModal,
		TransitionExpand,
		SkDropdownMultiSelect,
		SkModalStepNavbar
	},
	data: function () {
		return {
			loading: false,
			emailRegex: utils.emailRegex,
			requiredNumberRegex: /^[0-9][0-9]*$/,
			currentStep: 0,
			lastStep: 4,
			seenSteps: [],
			business: {},
			restaurants: [],
			businessLogoPreview: null,
			businessCoverPreview: null,
			expandedGroupIds: {},
			businessTimezoneId: null,
			businessTimezone: "N/A",
			businessUsers: [],
			timezoneSyncRequired: false,
			reviewingStepId: null,
			activateBusiness: false
		}
	},
	computed: {
		types() {
			const businessTypes = [
				BusinessType.BAR,
				BusinessType.HOTEL,
				BusinessType.RESIDENTIAL,
				BusinessType.COWORKING,
				BusinessType.AIRPORT
			]
			return businessTypes.map((type) => {
				return {
					id: type,
					text: this.$options.filters.businessTypeName(type)
				}
			})
		},
		menusMaxProgress() {
			let totalMenuCount = 0
			this.restaurants.forEach((restaurant) => {
				totalMenuCount += restaurant.menuIDs.length
			})
			return totalMenuCount
		},
		defaultDeliveryMode: {
			get: function () {
				if (!this.business.delivery_modes) {
					return null
				}
				const defaultDeliveryMode = this.business.delivery_modes.find(
					(deliveryMode) => deliveryMode.is_default
				)
				return defaultDeliveryMode
					? defaultDeliveryMode.delivery_mode
					: null
			},
			set: function (newDefaultDeliveryMode) {
				this.business.delivery_modes.forEach((deliveryMode) => {
					deliveryMode.is_default =
						deliveryMode.delivery_mode == newDefaultDeliveryMode
							? 1
							: 0
				})
			}
		},
		deliveryModes() {
			const businessDeliveryModes = this.business.delivery_modes
				? this.business.delivery_modes
				: []
			const activeDeliveryModes = businessDeliveryModes.map(
				(deliveryMode) => deliveryMode.delivery_mode
			)

			let deliveryModes = [
				{
					id: DeliveryMode.DIRECT_TO_LOCATION,
					name: this.$options.filters.deliveryModeName(
						DeliveryMode.DIRECT_TO_LOCATION,
						this.business.type
					)
				}
			]

			if (this.business.type == BusinessType.BAR) {
				deliveryModes.push({
					id: DeliveryMode.YAY_FOOD_POLE,
					name: this.$options.filters.deliveryModeName(
						DeliveryMode.YAY_FOOD_POLE,
						this.business.type
					)
				})
			}

			if (this.business.type == BusinessType.AIRPORT) {
				deliveryModes.push({
					id: DeliveryMode.LOCKER,
					name: this.$options.filters.deliveryModeName(
						DeliveryMode.LOCKER,
						this.business.type
					)
				})
			}

			if (this.business.type !== BusinessType.AIRPORT) {
				deliveryModes.push(
					{
						id: DeliveryMode.PICKUP_AREA,
						name: this.$options.filters.deliveryModeName(
							DeliveryMode.PICKUP_AREA,
							this.business.type
						)
					},
					{
						id: DeliveryMode.LAST_YARD,
						name: this.$options.filters.deliveryModeName(
							DeliveryMode.LAST_YARD,
							this.business.type
						)
					}
				)
			}

			deliveryModes = deliveryModes.concat([
				{
					id: DeliveryMode.DIRECT_TO_CUSTOMER,
					name: this.$options.filters.deliveryModeName(
						DeliveryMode.DIRECT_TO_CUSTOMER,
						this.business.type
					)
				}
			])

			deliveryModes.forEach((deliveryMode) => {
				deliveryMode.active = activeDeliveryModes.includes(
					deliveryMode.id
				)
				deliveryMode.isDefault =
					this.defaultDeliveryMode == deliveryMode.id
			})
			return deliveryModes
		},
		businessDeliveryModesText() {
			return this.deliveryModes
				.reduce((deliveryModeNames, deliveryMode) => {
					if (deliveryMode.active) {
						if (deliveryMode.isDefault) {
							deliveryModeNames.push(
								`${deliveryMode.name} (Default)`
							)
						} else {
							deliveryModeNames.push(deliveryMode.name)
						}
					}
					return deliveryModeNames
				}, [])
				.join(", ")
		},
		menuOptions() {
			var baseMenus = [
				{
					text: "Select Menu",
					id: null
				}
			]
			if (
				this.restaurant.menus != undefined &&
				this.restaurant.menus.length > 0
			) {
				let menuProducts = this.restaurant.menus.map((menu) => {
					return {
						text: `${menu.name}`,
						id: menu.id
					}
				})

				baseMenus = baseMenus.concat(menuProducts)
			}
			return baseMenus
		},
		serviceFeeFormattedText() {
			if (this.business.service_fee) {
				return this.business.service_fee + "%"
			} else {
				return ""
			}
		},
		taxFormattedText() {
			if (this.business.tax) {
				return this.business.tax + "%"
			} else {
				return ""
			}
		},
		minOrderAmountFormattedText() {
			if (this.business.min_order_amount) {
				return "$" + this.business.min_order_amount
			} else {
				return ""
			}
		},
		commissionFormattedText() {
			if (this.business.commission) {
				return this.business.commission + "%"
			} else {
				return ""
			}
		},
		address: {
			get: function () {
				return {
					placeId: this.business.external_location_id,
					address: this.business.address,
					city: this.business.city,
					state: this.business.state,
					zip: this.business.zip
				}
			},
			set: function (address) {
				this.business.external_location_id =
					address && address.placeId ? address.placeId : ""
				this.business.address =
					address && address.address ? address.address : ""
				this.business.city = address && address.city ? address.city : ""
				this.business.state =
					address && address.state ? address.state : ""
				this.business.zip = address && address.zip ? address.zip : ""
				this.timezoneSyncRequired = true
			}
		},
		type: {
			get: function () {
				if (!this.business || this.business.type == undefined) {
					return null
				}
				return this.types.find((type) => type.id == this.business.type)
			},
			set: function (newVal) {
				if (this.business && newVal != undefined) {
					this.business.type = newVal.id
				}
			}
		},
		establishment: {
			get: function () {
				return {
					placeId: this.business.external_establishment_id,
					name: this.business.external_establishment_name
				}
			},
			set: function (establishment) {
				this.business.external_establishment_id =
					establishment && establishment.placeId
						? establishment.placeId
						: ""
				this.business.external_establishment_name =
					establishment && establishment.name
						? establishment.name
						: ""
				if (!this.business.name || this.business.name.length == 0) {
					this.business.name =
						establishment && establishment.shortName
							? establishment.shortName
							: ""
				}
				if (!this.isAddressSet) {
					this.loading = true
					gapi.getPlaceDetails(establishment.placeId).then(
						(result) => {
							this.loading = false
							const addressComponents = result
								? result.address_components
								: null
							const data =
								gapi.parseAddressComponents(addressComponents)
							data.placeId = establishment.placeId
							this.address = data
							this.phone = result.international_phone_number
						}
					)
				}
			}
		},
		phone: {
			get: function () {
				return this.business.phone_number
			},
			set: function (phone) {
				this.business.phone_number = phone
			}
		},
		businessSlug: {
			get: function () {
				return this.business.slug
			},
			set: function (slug) {
				if (this.businessLogoPreview) {
					this.$refs.businessLogoUploader.reset()
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Business Setup",
						message:
							"Business logo must be reuploaded if the slug has changed since uploading",
						type: NotificationType.WARNING
					})
				}
				this.business.slug = slug
			}
		},
		formattedPhone() {
			if (this.phone) {
				return this.$options.filters.formatInternationalPhone(
					this.phone
				)
			} else {
				return null
			}
		},
		formattedAddress() {
			if (this.isAddressSet) {
				return `${this.business.address} ${this.business.city}, ${this.business.state}, ${this.business.zip}`
			}
			return null
		},
		isAddressSet() {
			let hasAddress =
				this.business.address != undefined &&
				this.business.address.length > 0
			let hasCity =
				this.business.city != undefined && this.business.city.length > 0
			let hasState =
				this.business.state != undefined &&
				this.business.state.length > 0
			let hasZip =
				this.business.zip != undefined && this.business.zip.length > 0
			return hasAddress && hasCity && hasState && hasZip
		},
		isAddressInvalid() {
			if (this.seenSteps.includes(0)) {
				return !this.isAddressSet
			}
			return false
		},
		businessUsersAdded() {
			return this.businessUsers.filter((user) => {
				return user.name != "" || user.phone != "" || user.email != ""
			})
		},
		emptyBusinessUsers() {
			return this.businessUsers.filter((user) => {
				return user.name == "" && user.phone == "" && user.email == ""
			})
		},
		businessUsersToReview() {
			return this.businessUsersAdded.map((user) => {
				let emailInvalid = true
				if (user.email.length > 0) {
					emailInvalid = utils.emailRegex.test(user.email) == false
				}
				let formattedPhone = user.phone
				if (user.phone.length > 0) {
					formattedPhone =
						this.$options.filters.formatInternationalPhone(
							user.phone
						)
				}
				return {
					name: user.name,
					email: user.email,
					phone: formattedPhone,
					emailInvalid: emailInvalid,
					nameInvalid: user.name.length == 0,
					phoneInvalid: user.name.length == 0
				}
			})
		},
		generalStepComplete() {
			return (
				this.isAddressSet &&
				this.business.slug.length > 0 &&
				this.business.name.length > 0
			)
		},
		generalStepInvalid() {
			return (
				!this.isAddressSet ||
				this.business.slug.length == 0 ||
				this.business.name.length == 0
			)
		},
		generalStepProgress() {
			let progress = 0
			if (this.business.name && this.business.name.length > 0) {
				progress += 1
			}
			if (this.business.slug && this.business.slug.length > 0) {
				progress += 1
			}
			if (this.isAddressSet) {
				progress += 1
			}
			if (this.business.email && this.business.email.length > 0) {
				progress += 1
			}
			if (this.phone) {
				progress += 1
			}
			if (this.type) {
				progress += 1
			}
			if (
				this.business.delivery_modes &&
				this.business.delivery_modes.length > 0
			) {
				progress += 1
			}
			return progress
		},
		financeStepComplete() {
			return (
				this.selfDeliveryMUR !== "" &&
				this.courierMUR !== "" &&
				this.eventsMUR !== "" &&
				this.toGoMUR !== "" &&
				this.prePackagedMUR !== "" &&
				this.business.min_order_amount !== "" &&
				this.business.tax !== "" &&
				this.business.service_fee !== ""
			)
		},
		financeStepInvalid() {
			const emptySelfDeliveryMUR = this.selfDeliveryMUR === ""
			const emptyCourierMUR = this.courierMUR === ""
			const emptyEventMUR = this.eventsMUR === ""
			const emptyToGoMUR = this.toGoMUR === ""
			const emptyPrePackagedMUR = this.prePackagedMUR === ""
			const emptyMinimum = this.business.min_order_amount == ""
			const emptyTax = !this.business.tax || this.business.tax < 0
			const emptyServiceFee = this.business.service_fee == ""
			return (
				emptySelfDeliveryMUR ||
				emptyCourierMUR ||
				emptyEventMUR ||
				emptyToGoMUR ||
				emptyPrePackagedMUR ||
				emptyMinimum ||
				emptyTax ||
				emptyServiceFee
			)
		},
		financeStepProgress() {
			let progress = 0
			if (this.courierMUR !== "") {
				progress += 1
			}
			if (this.eventsMUR !== "") {
				progress += 1
			}
			if (this.toGoMUR !== "") {
				progress += 1
			}
			if (this.prePackagedMUR !== "") {
				progress += 1
			}
			if (this.selfDeliveryMUR !== "") {
				progress += 1
			}
			if (this.business.tax !== "") {
				progress += 1
			}
			if (this.business.service_fee !== "") {
				progress += 1
			}
			if (this.business.min_order_amount !== "") {
				progress += 1
			}
			if (this.business.commission && this.business.commission > 0) {
				progress += 1
			}
			if (
				this.business.commission_threshold &&
				this.business.commission_threshold > 0
			) {
				progress += 1
			}
			return progress
		},
		usersStepComplete() {
			return (
				this.businessUsersAdded.length > 0 &&
				this.usersStepProgress == this.businessUsersAdded.length
			)
		},
		usersStepInvalid() {
			return this.businessUsersToReview.some(
				(user) =>
					user.emailInvalid || user.nameInvalid || user.phoneInvalid
			)
		},
		usersStepProgress() {
			return this.businessUsersToReview.filter(
				(user) =>
					!user.emailInvalid &&
					!user.nameInvalid &&
					!user.phoneInvalid
			).length
		},
		menuStepComplete() {
			return (
				this.restaurants.length > 0 &&
				this.menuStepProgress == this.restaurants.length
			)
		},
		menuStepInvalid() {
			return (
				this.restaurants.length > 1 &&
				this.restaurants.some(
					(restaurant) =>
						!restaurant.menuIDs || restaurant.menuIDs.length == 0
				)
			)
		},
		menuStepProgress() {
			return this.restaurants.filter(
				(restaurant) =>
					restaurant.menuIDs && restaurant.menuIDs.length > 0
			).length
		},
		canCreateBusiness() {
			return this.steps.every((step) => !step.isInvalid)
		},
		steps() {
			return [
				{
					id: 0,
					isActive: this.currentStep == 0,
					isInvalid: this.generalStepInvalid,
					isComplete: this.generalStepComplete,
					text: "General"
				},
				{
					id: 1,
					isActive: this.currentStep == 1,
					isInvalid: this.financeStepInvalid,
					isComplete: this.financeStepComplete,
					text: "Finance"
				},
				{
					id: 2,
					isActive: this.currentStep == 2,
					isInvalid: this.usersStepInvalid,
					isComplete: this.usersStepComplete,
					text: "Users"
				},
				{
					id: 3,
					isActive: this.currentStep == 3,
					isInvalid: this.menuStepInvalid,
					isComplete: this.menuStepComplete,
					text: "Menus"
				},
				{
					id: 4,
					isActive: this.currentStep == 4,
					isComplete:
						this.generalStepComplete &&
						this.financeStepComplete &&
						this.menuStepComplete &&
						this.usersStepComplete,
					text: "Review"
				}
			]
		}
	},
	methods: {
		handleOptions() {
			this.$refs.businessLogoUploader.reset()
			this.resetValidation("businessNameInput")
			this.resetValidation("businessEmailInput")
			this.resetValidation("businessSlugInput")
			this.resetValidation("selfDeliveryMURInput")
			this.resetValidation("courierMURInput")
			this.resetValidation("eventsMURInput")
			this.resetValidation("toGoMURInput")
			this.resetValidation("prePackagedMURInput")
			this.resetValidation("businessServiceFeeInput")
			this.resetValidation("businessTaxInput")
			this.resetValidation("businessMinimumInput")
			this.resetValidation("businessCommissionInput")
			this.resetValidation("businessCommissionThresholdInput")

			this.business = {
				logo: "",
				slug: "",
				name: "",
				phone_number: "",
				external_location_id: null,
				external_establishment_id: null,
				external_establishment_name: "",
				address: "",
				state: "",
				city: "",
				zip: "",
				delivery_id: CourierId.WALKING,
				service_fee: 12,
				min_order_amount: 12,
				tax: "",
				type: BusinessType.BAR,
				delivery_modes: [
					{
						delivery_mode: DeliveryMode.DIRECT_TO_LOCATION,
						is_default: 1
					}
				]
			}
			this.reviewingStepId = null
			this.businessTimezoneId = null
			this.businessTimezone = "N/A"
			this.businessUsers = []
			this.addNewUser()
			this.addNewRestaurant()
		},
		setLoader(loading) {
			this.loading = loading
		},
		toggleExpandGroup(groupId) {
			if (this.expandedGroupIds[groupId]) {
				this.$set(this.expandedGroupIds, groupId, false)
			} else {
				this.$set(this.expandedGroupIds, groupId, true)
			}
		},
		getSelectedRestaurantMenuNames(restaurant) {
			const menuNames = restaurant.menus
				.filter((menu) => restaurant.menuIDs.includes(menu.id))
				.map((selectedMenu) => {
					return selectedMenu.name
				})
			if (menuNames && menuNames.length > 0) {
				return menuNames.join()
			} else {
				return "N/A"
			}
		},
		async restaurantSelected(index, event) {
			const restaurant = await restaurant_service.getRestaurant(event.id)
			this.restaurants[index].name = restaurant.data.name
			this.restaurants[index].id = restaurant.data.id
			this.restaurants[index].menus = restaurant.data.menus
			this.menuIDs = []
		},
		async autocompleteRestaurants(query) {
			const results = await this.$store.dispatch(
				"restaurantsModule/autocompleteRestaurants",
				query
			)
			return results
		},
		typeaheadSerializer(result) {
			let hasAddress =
				result.address && result.city && result.state && result.zip
			return {
				text: result.name,
				description: hasAddress
					? `${result.address} <br> ${result.city}, ${result.state} ${result.zip}`
					: null
			}
		},
		logoUploadClicked(event) {
			if (!this.business.slug) {
				event.stopPropagation()
				event.preventDefault()
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Business Setup",
					message: "A slug is required before uploading a logo",
					type: NotificationType.WARNING
				})
			}
		},
		coverUploadClicked(event) {
			if (!this.business.slug) {
				event.stopPropagation()
				event.preventDefault()
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Business Setup",
					message: "A slug is required before uploading a banner",
					type: NotificationType.WARNING
				})
			}
		},
		logoRemoveClicked(event) {
			event.stopPropagation()
			event.preventDefault()
			this.$refs.businessLogoUploader.reset()
		},
		coverRemoveClicked(event) {
			event.stopPropagation()
			event.preventDefault()
			this.$refs.businessCoverUploader.reset()
		},
		setBusinessLogo(img) {
			this.business.logo = img
		},
		setBusinessCover(img) {
			this.business.img = img
		},
		setBusinessLogoPreview(src) {
			this.businessLogoPreview = src
		},
		setBusinessCoverPreview(src) {
			this.businessCoverPreview = src
		},
		async updateTimezone() {
			this.timezoneSyncRequired = false
			if (!this.isAddressSet) {
				this.timezone = "N/A"
			} else {
				if (this.$gmapApiPromiseLazy) {
					await this.$gmapApiPromiseLazy()
				}
				const fullAddress = `${this.business.address}, ${this.business.city}, ${this.business.state}, ${this.business.zip}`
				let geocoder = new google.maps.Geocoder()
				geocoder.geocode(
					{ address: fullAddress },
					async (results, status) => {
						if (status == "OK" && results && results[0]) {
							const location = results[0].geometry.location
							const lat = location.lat()
							const lng = location.lng()
							const time = Math.round(new Date().getTime() / 1000)
							const timezoneResponse = await apiUtils.getTimezone(
								lat,
								lng,
								time,
								process.env.VUE_APP_GAPI_NON_REFERRER_KEY
							)
							if (timezoneResponse.status == 200) {
								this.businessTimezoneId =
									timezoneResponse.timeZoneId
								this.businessTimezone = moment()
									.tz(timezoneResponse.timeZoneId)
									.format("z")
							}
						}
					}
				)
			}
		},
		nextStep() {
			if (this.currentStep < this.lastStep) {
				this.setStep(this.currentStep + 1)
			}
		},
		reviewStep(id) {
			this.reviewingStepId = id
		},
		async setStep(id, event) {
			if (!this.seenSteps.includes(this.currentStep)) {
				this.seenSteps.push(this.currentStep)
			}
			if (event) {
				event.stopPropagation()
			}
			if (this.currentStep == 0) {
				if (this.timezoneSyncRequired) {
					this.updateTimezone()
				}
				this.validateInput("businessNameInput")

				this.validateInput("businessSlugInput")
			}
			if (this.currentStep == 1) {
				this.validateInput("selfDeliveryMURInput")
				this.validateInput("courierMURInput")
				this.validateInput("eventsMURInput")
				this.validateInput("toGoMURInput")
				this.validateInput("prePackagedMURInput")
			}
			if (this.currentStep == 2) {
				this.businessUsersAdded.forEach((user) => {
					this.validateInput(`userNameInput${user.uuid}`)
					this.validateInput(`userEmailInput${user.uuid}`)
					if (user.phone.length > 0) {
						this.validateInput(`userPhoneInput${user.uuid}`)
					} else {
						this.resetValidation(`userPhoneInput${user.uuid}`)
					}
				})
				this.emptyBusinessUsers.forEach((user) => {
					this.resetValidation(`userNameInput${user.uuid}`)
					this.resetValidation(`userEmailInput${user.uuid}`)
					this.resetValidation(`userPhoneInput${user.uuid}`)
				})
			}
			this.currentStep = id
		},
		validateInput(inputRef) {
			if (this.$refs[inputRef]) {
				if (this.$refs[inputRef].length > 0) {
					this.$refs[inputRef][0].validate()
				} else {
					this.$refs[inputRef].validate()
				}
			}
		},
		resetValidation(inputRef) {
			if (this.$refs[inputRef]) {
				if (this.$refs[inputRef].length > 0) {
					this.$refs[inputRef][0].resetValidation()
				} else {
					this.$refs[inputRef].resetValidation()
				}
			}
		},
		newUser() {
			return {
				name: "",
				email: "",
				phone: "",
				sendActivation: true,
				uuid: uuidv1()
			}
		},
		newRestaurant() {
			return {
				name: "",
				uuid: uuidv1(),
				id: 0,
				menus: [],
				menuIDs: []
			}
		},
		addNewUser() {
			this.businessUsers.push(this.newUser())
		},
		addNewRestaurant() {
			this.restaurants.push(this.newRestaurant())
		},
		removeUser(user) {
			if (this.businessUsers.length > 1) {
				this.businessUsers = this.businessUsers.filter(
					(businessUser) => user.uuid != businessUser.uuid
				)
			} else {
				const user = this.newUser()
				this.businessUsers.splice(0, 1, user)
				this.resetValidation(`userNameInput${user.uuid}`)
				this.resetValidation(`userEmailInput${user.uuid}`)
				this.resetValidation(`userPhoneInput${user.uuid}`)
			}
		},
		removeRestaurant(restaurant) {
			if (this.restaurants.length > 1) {
				this.restaurants = this.restaurants.filter(
					(existingRestaurant) =>
						restaurant.uuid != existingRestaurant.uuid
				)
			} else {
				const restaurant = this.newRestaurant()
				this.restaurants.splice(0, 1, restaurant)
			}
		},
		async createBusinessMenus(businessId) {
			this.restaurants.forEach((restaurant) => {
				if (restaurant.menuIDs.length != 0) {
					restaurant.menuIDs.forEach(async (e) => {
						const menu = restaurant.menus.find(
							(menu) => menu.id == e
						)
						const menuData = {
							restaurant_id: restaurant.id,
							courier_id: menu.courier_id,
							delivery_fee: 0,
							delivery_modes: this.business.delivery_modes,
							id: e,
							is_backup: 0,
							is_late_night: 0,
							opening_hours: menu.opening_hours,
							min_order_amount: 12,
							name: menu.name,
							restaurant_commission: restaurant.commission,
							stock_status: 0,
							type: menu.type,
							products_categories: [],
							pre_order_allowed:
								menu.type == MenuType.PRE_PACKAGED ? 0 : 1
						}
						const payload = {
							menu: menuData,
							rest: restaurant,
							businessID: businessId
						}
						const success = await this.$store.dispatch(
							"businessesModule/addBusinessMenu",
							payload
						)
						if (success) {
							eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
								title: `Succesfully added ${menu.name}`,
								type: NotificationType.SUCCESS
							})
						} else {
							eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
								title: `Error adding ${menu.name}. Please try again.`,
								type: NotificationType.ERROR
							})
						}
					})
				}
			})
		},
		async createBusinessUsers(businessId) {
			if (this.businessUsersAdded.length > 0) {
				const users = this.businessUsersAdded.map((user) => {
					return {
						id: 0,
						entities: [
							{ id: businessId, type: EntityType.BUSINESS }
						],
						display_name: user.name,
						email: user.email,
						phone: user.phone,
						role_id: UserRole.BUSINESS_ADMIN
					}
				})
				const addAdminsPromises = users.map((user) => {
					return this.$store.dispatch("addAdminUser", user)
				})
				const admins = await Promise.all(addAdminsPromises)
				let atLeaseOneFailure = false
				admins.forEach((admin) => {
					if (admin == false) {
						atLeaseOneFailure = true
					}
				})
				if (atLeaseOneFailure) {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Business Setup",
						message: "Failed to create one or more users",
						type: NotificationType.WARNING
					})
					this.bugsnag.notify(
						"Failed to create one or more users",
						(event) => {
							event.addMetadata("error", {
								usersData: admins
							})
						}
					)
				}
			}
			return true
		},
		getRestaurantOptions(index) {
			let text = "Select Menu"
			if (
				this.restaurants[index].menuIDs &&
				this.restaurants[index].menuIDs.length > 0
			) {
				text = this.restaurants[index].menuIDs.length + " Selected"
			}
			var baseMenus = [
				{
					text: text,
					id: null
				}
			]
			if (
				this.restaurants[index].menus != undefined &&
				this.restaurants[index].menus.length > 0
			) {
				let menuProducts = this.restaurants[index].menus.map((menu) => {
					return {
						text: `${menu.name}`,
						id: menu.id
					}
				})

				baseMenus = baseMenus.concat(menuProducts)
			}
			return baseMenus
		},
		setActiveBusinessStatus(businessId) {
			if (this.activateBusiness) {
				const payload = {
					is_active: 1,
					business_id: businessId
				}
				this.$store.dispatch(
					"businessesModule/setBusinessActivationStatus",
					payload
				)
			}
		},
		toggleDeliveryMode(updatedDeliveryMode) {
			if (
				updatedDeliveryMode.active &&
				this.business.delivery_modes.length == 1
			) {
				return
			}

			let defaultDeliveryMode = this.defaultDeliveryMode

			if (updatedDeliveryMode.active && updatedDeliveryMode.isDefault) {
				const newDefaultDeliveryMode =
					this.business.delivery_modes.find(
						(deliveryMode) =>
							deliveryMode.delivery_mode != updatedDeliveryMode.id
					)
				defaultDeliveryMode = newDefaultDeliveryMode
					? newDefaultDeliveryMode.delivery_mode
					: null
			}

			const activeDeliveryModes = this.business.delivery_modes.map(
				(deliveryMode) => deliveryMode.delivery_mode
			)

			this.business.delivery_modes = this.deliveryModes.reduce(
				(deliveryModes, deliveryMode) => {
					if (
						updatedDeliveryMode.active &&
						deliveryMode.id == updatedDeliveryMode.id
					) {
						return deliveryModes
					}

					if (
						activeDeliveryModes.includes(deliveryMode.id) ||
						deliveryMode.id == updatedDeliveryMode.id
					) {
						deliveryModes.push({
							delivery_mode: deliveryMode.id,
							is_default:
								defaultDeliveryMode == deliveryMode.id ? 1 : 0
						})
					}

					return deliveryModes
				},
				[]
			)
		},
		async createBusiness() {
			try {
				if (!this.canCreateBusiness) {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Business Setup",
						message: "Required fields are missing or invalid",
						type: NotificationType.ERROR
					})
					const firstInvalidStep = this.steps.find(
						(step) => step.isInvalid
					)
					this.reviewingStepId = firstInvalidStep.id
					return false
				}

				if (!this.businessTimezoneId) {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Restaurant Setup",
						message:
							"The restaurant address does not have a valid timezone",
						type: NotificationType.ERROR
					})
					return
				}

				this.loading = true

				const business = await this.$store.dispatch(
					"businessesModule/addBusiness",
					this.business
				)
				if (business) {
					await this.createBusinessUsers(business.id)
					await this.createBusinessMenus(business.id)
					this.setActiveBusinessStatus(business.id)
					this.$store.dispatch(
						"getBusinessesByIds",
						{
							ids: [business.id],
							silently: true
						},
						{ root: true }
					)
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Business Setup",
						message: "The business was created successfully!"
					})
				} else {
					throw "Business Setup failed to create business"
				}
				this.close()
				this.loading = false
			} catch (e) {
				this.loading = false
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Business Setup",
					message: "Something went wrong, please try again.",
					type: NotificationType.ERROR
				})
				this.bugsnag.notify(e, (event) => {
					event.addMetadata("error", {
						restaurantData: this.business
					})
				})
			}
		},
		cancel() {
			this.$refs.confirmationModal
				.open({
					title: "You have unsaved changes",
					body: "Are you sure you want to close this modal without saving your changes?",
					confirmation: "Continue",
					cancel: "Never Mind"
				})
				.then((confirmed) => {
					if (confirmed) {
						this.close()
					}
				})
		},
		closed() {
			this.loading = false
			this.currentStep = 0
			this.seenSteps = []
		},
		opened() {
			this.loading = true
			this.$store
				.dispatch("businessesModule/getDefaultMURs")
				.finally(() => {
					this.loading = false
				})
		}
	}
}
</script>