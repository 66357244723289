import xlsx_helper from "@/utils/xlsx-helper"
import ActionTypes from "@/store/modules/finance/action-types"
import moment from "moment"
import enumUtils from "@/utils/enum-utils"

let exportPayoutBreakdownMixin = {
    methods: {
        async downloadBreakdown() {
			const orders = await this.$store.dispatch(
				`financeModule/${ActionTypes.GET_PAYOUT_ORDERS}`,
				this.payout.id
			)
			const orderWithExceptions = orders.filter(order => order.exceptions > 0)
			let exceptions = []
			if (orderWithExceptions.length > 0) {
				exceptions = await this.$store.dispatch(
					`financeModule/${ActionTypes.GET_PAYOUT_EXCEPTIONS}`,
					orderWithExceptions
				)
			}
			const payoutWs = this.generatePayoutWs(orders)
			const exceptionsWs = this.generateExceptionsWs(exceptions)

            xlsx_helper.exportMultiSheet([payoutWs, exceptionsWs], "Payouts Breakdown")
		},
		generatePayoutWs(orders) {
            const sheet = {
                name: "Payout Breakdown",
                json: [],
                options: {
                    header: [
                        "Order ID",
                        "Order Date",
                        "Cogs",
                        "Cogs Tax",
                        "Commission",
                        "Exception Amount",
                        "Tip to Restaurant"
                    ]
                }
            }
			if (orders != null) {
				orders.forEach((order) => {
					sheet.json.push({
						"Order ID": order.order_id,
						"Order Date": order.order_date,
						"Cogs": order.cogs,
						"Cogs Tax": order.cogs_tax,
						"Commission": order.restaurant_commission,
						"Exception Amount": order.exceptions,
						"Tip to Restaurant": order.restaurant_tip
                    })
				})
			}
			return sheet
		},
		generateExceptionsWs(exceptions) {
            const sheet = {
                name: "Payout Exceptions",
                json: [],
                options: {
                    header: [
                        "Order Date",
                        "Order ID",
                        "Exception Amount",
                        "Exception Reason"
                    ]
                }
            }

			if (exceptions != null) {
				exceptions.forEach((exception) => {
					const createdDate = moment
						.utc(exception.created_time_local)
						.format("YYYY-MM-DD h:mm a")

					sheet.json.push({
						"Order Date": createdDate,
						"Order ID": exception.order_id,
						"Exception Amount": exception.amount,
						"Exception Reason": enumUtils.textForExceptionReasonType(exception.type)
                    })
				})
			}

			return sheet
		}
    }
}

export default exportPayoutBreakdownMixin