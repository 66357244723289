import MutationTypes from "@/store/modules/insights/mutation-types"

export default {
    [MutationTypes.LOADING](state, key) {
        if (state[key]) {
            state[key].loading = true
        }
    },
    [MutationTypes.LOADED](state, key) {
        if (state[key]) {
            state[key].loading = false
        }
    },
    [MutationTypes.SET_FILTERS](state, filters) {
        state.filters.businessId = filters.businessId ? filters.businessId : null
        state.filters.restaurantId = filters.restaurantId ? filters.restaurantId : null
        state.filters.startDate = filters.startDate
        state.filters.endDate = filters.endDate
    },
    [MutationTypes.GOT_TOTAL_SALES_DATA](state, data) {
        if (data) {
            state.totalSales.data = data.map(periodData => {
                let sum = 0
                if (periodData.price_sum != undefined) {
                    sum = periodData.price_sum
                } else if (periodData.cogs_sum != undefined) {
                    sum = periodData.cogs_sum
                }

                return {
                    period: periodData.period,
                    sum: sum
                }
            })
        } else {
            state.totalSales.data = null
        }
    },
    [MutationTypes.GOT_TOTAL_ORDERS_DATA](state, data) {
        let orderedData = null
        if (data) {
            orderedData = Object.keys(data).map((date) => {
                return {
                    period: date,
                    total: data[date]
                }
            }).sort((a, b) => a.period.localeCompare(b.period))
        }
        state.totalOrders.data = orderedData
    },
    [MutationTypes.GOT_AVERAGE_TICKET_PRICE_BY_DOW](state, data) {
        state.averageTicketPriceByDow.data = data
    },
    [MutationTypes.GOT_TOP_ORDERS_BY_DOW](state, data) {
        state.topOrdersByDow.data = data
    },
    [MutationTypes.GOT_TOP_ORDERS_BY_HOUR](state, data) {
        state.topOrdersByHour.data = data
    },
    [MutationTypes.GOT_AVERAGE_RATING](state, data) {
        state.averageRating.data = data
    },
    [MutationTypes.GOT_AVERAGE_RESTAURANT_ACCURACY](state, data) {
        state.averageAccuracy.data = data
    },
    [MutationTypes.GOT_TOP_EXCEPTIONS](state, data) {
        state.topExceptions.data = data
    },
    [MutationTypes.GOT_MISSING_ITEMS_ORDERS](state, data) {
        state.missingItemsOrders.data = data
    },
    [MutationTypes.GOT_INCORRECT_ITEMS_ORDERS](state, data) {
        state.incorrectItemsOrders.data = data
    },
    [MutationTypes.GOT_RESTAURANT_CLOSED_ORDERS](state, data) {
        state.restaurantClosedOrders.data = data
    },
    [MutationTypes.GOT_RESTAURANT_AVERAGE_ACCEPT_TIME](state, data) {
        state.averageAcceptTime.data = data
    },
    [MutationTypes.GOT_RESTAURANT_AVERAGE_PREPARATION_TIME](state, data) {
        state.averagePreparationTime.data = data
    }
}