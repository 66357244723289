<template>
	<SkBaseModal
		class="view-prepackage-order-modal"
		:isOpen="isOpen"
		:hasLoader="true"
		@close="close"
	>
		<loading :active.sync="loading" :is-full-page="false"></loading>
		<div v-if="suborderOptions.length > 1" class="sk-modal-alt-title">
			<h1>{{ orderUsername }}</h1>
			<span>#{{ orderId }}</span>
			<i class="sk-icon-random-regular"></i>
			<span>{{ formattedSelectedSuborderOption }}</span>
			<SkDropdownSelect
				v-model="selectedSuborderOption"
				:options="suborderOptions"
				:showSelectedOption="false"
			/>
		</div>
		<div v-else class="sk-modal-alt-title">
			<h1>{{ orderUsername }}</h1>
			<span>#{{ orderId }}</span>
		</div>
		<div class="sk-modal-section">
			<SetupReviewStep
				:stepName="'Details'"
				:stepId="0"
				:editable="false"
				:showCompletion="false"
				:showProgress="false"
				:reviewingStepId="reviewingStepId"
				:openByDefault="true"
				@reviewStep="reviewStep"
			>
				<div class="setup-review-step-detail">
					Placed
					<div>
						<b>{{ placedDay }}</b>
						at
						<b>{{ placedTime }}</b>
					</div>
				</div>
				<div class="setup-review-step-detail">
					Delivered
					<div>
						<b>{{ deliveredDay }}</b>
						at
						<b>{{ deliveredTime }}</b>
					</div>
				</div>
				<div class="setup-review-step-detail" v-if="voucher">
					Voucher
					<div class="voucher-details">
						<i class="sk-icon-ticket-alt-solid"></i>
						<b>{{ voucher.code }}</b>
						<i>{{ voucher.amount }}</i>
					</div>
				</div>
			</SetupReviewStep>
			<SetupReviewStep
				:stepName="'Items'"
				:stepId="1"
				:editable="false"
				:showCompletion="false"
				:showProgress="false"
				:reviewingStepId="reviewingStepId"
				:openByDefault="true"
				@reviewStep="reviewStep"
			>
				<div
					class="order-product"
					v-for="(product, index) in products"
					:key="`${product.id}-${index}`"
				>
					<div>
						<div>
							{{ product.name }}
						</div>
						<div>
							<span class="quantity-label">Quantity:</span>
							{{ product.quantity }}
						</div>
					</div>
					<div v-if="showFullPrice">
						{{
							productPrices[`${product.id}_${index}`].price
								| currency
						}}
					</div>
					<div v-else>
						{{
							productPrices[`${product.id}_${index}`].cogs
								| currency
						}}
					</div>
				</div>
				<div class="sk-divider"></div>
				<div class="order-amount">
					<div>Tip</div>
					<div>{{ tip | currency }}</div>
				</div>
				<div class="order-amount">
					<div>Tax</div>
					<div v-if="showFullPrice">{{ tax | currency }}</div>
					<div v-else>{{ cogsTax | currency }}</div>
				</div>
				<div class="sk-divider"></div>
				<div class="order-amount order-total">
					<div>
						<div>Total</div>
						<div>
							<span class="quantity-label">Quantity:</span>
							{{ totalQuantity }}
						</div>
					</div>
					<div v-if="showFullPrice">
						{{ orderPrice | currency }}
					</div>
					<div v-else>
						{{ totalCogsWithTip | currency }}
					</div>
				</div>
			</SetupReviewStep>
			<SetupReviewStep
				v-if="hasIssues"
				:stepName="'Issues'"
				:stepId="2"
				:editable="false"
				:showCompletion="false"
				:showProgress="false"
				:reviewingStepId="reviewingStepId"
				:openByDefault="true"
				@reviewStep="reviewStep"
			>
				<div
					v-for="(issue, i) in issues"
					:key="i"
					class="setup-review-step-detail"
				>
					{{ issue.name }}
					<div>
						<b>{{ issue.reason }}</b>
					</div>
				</div>
			</SetupReviewStep>
		</div>
	</SkBaseModal>
</template>

<style scoped>
.sk-modal-alt-title h1 {
	margin-right: 15px;
}

.sk-modal-alt-title span {
	font-size: 12px;
	color: var(--sk-grey3);
}

.sk-modal-alt-title i {
	margin: 0 10px;
	font-size: 10px;
	color: var(--sk-grey2);
}

.sk-modal-alt-title >>> .sk-select {
	flex: 0;
}

.voucher-details {
	color: var(--sk-grey3);
	align-items: center;
	display: flex;
}

.voucher-details > * {
	margin-right: 10px;
	margin-left: 0;
}

.setup-review-step-detail b {
	margin-left: 0;
}

.order-product,
.order-amount {
	display: flex;
	justify-content: space-between;
	font-size: 12px;
	font-weight: 600;
}

.order-amount + .order-amount {
	margin-top: 10px;
}

.order-product + .order-product {
	margin-top: 10px;
}

.quantity-label {
	font-size: 10px;
	color: var(--sk-grey2);
}

.sk-divider {
	margin: 10px 0;
}
</style>

<script>
import moment from "moment"
import search_service from "@/services/search"
import { PermissionInfo } from "@/utils/permissions"
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import currencyFilterMixin from "@/mixins/currency-filter-mixin"
import orderProductPricing from "@/mixins/order-product-pricing"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import SetupReviewStep from "@/components/modals/SetupReviewStep.vue"
import Loading from "vue-loading-overlay"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"

export default {
	name: "OrderDetailsModal",
	mixins: [skModalMixin, currencyFilterMixin, orderProductPricing],
	components: {
		SkBaseModal,
		SetupReviewStep,
		Loading,
		SkDropdownSelect
	},
	props: {
		type: {
			type: Number,
			default: 1
		}
	},
	data: function () {
		return {
			order: null,
			reviewingStepId: null,
			loading: false,
			selectedSuborderOption: null
		}
	},
	computed: {
		business() {
			if (this.order) {
				return this.$store.getters.businessesMap[this.order.business.id]
			}
			return null
		},
		restaurant() {
			if (this.order && this.item) {
				return this.$store.state.restaurants[this.item.restaurant.id]
			}
			return null
		},
		offset() {
			if (this.business && this.business.timezone_offset) {
				return this.business.timezone_offset
			}
			if (this.restaurant && this.restaurant.timezone_offset) {
				return this.restaurant.timezone_offset
			}
			return 0
		},
		suborder() {
			if (this.order && this.selectedSuborderOption) {
				return this.order.sub_orders.find(
					(suborder) => suborder.id == this.selectedSuborderOption.id
				)
			}
			return null
		},
		item() {
			return this.suborder
		},
		orderId() {
			return this.order ? this.order.id : ""
		},
		orderUsername() {
			return this.order && this.order.user ? this.order.user.name : ""
		},
		voucher() {
			if (
				this.order &&
				this.order.coupon_uses &&
				this.order.coupon_uses.length > 0
			) {
				return this.order.coupon_uses[0]
			}
			return null
		},
		suborderDeliveredTime() {
			if (this.order) {
				const deliveredSuborder = this.order.sub_orders.find(
					(suborder) => {
						return (
							suborder.delivered_time &&
							suborder.delivered_time != ""
						)
					}
				)
				if (deliveredSuborder) {
					return deliveredSuborder.delivered_time
				}
			}
			return null
		},
		deliveredDay() {
			if (this.suborderDeliveredTime) {
				const deliveredMoment = moment
					.utc(this.suborderDeliveredTime)
					.add(this.offset, "m")
				return deliveredMoment.format("MMMM Do")
			}
			return "--"
		},
		deliveredTime() {
			if (this.suborderDeliveredTime) {
				const deliveredMoment = moment
					.utc(this.suborderDeliveredTime)
					.add(this.offset, "m")
				return deliveredMoment.format("h:mm a")
			}
			return "--"
		},
		placedDay() {
			if (this.order) {
				const createdTime = moment
					.utc(this.order.created_time)
					.add(this.offset, "m")
				return createdTime.format("MMMM Do")
			}
			return "--"
		},
		placedTime() {
			if (this.order) {
				const createdTime = moment
					.utc(this.order.created_time)
					.add(this.offset, "m")
				return createdTime.format("h:mm a")
			}
			return "--"
		},
		products() {
			return this.suborder ? this.suborder.products : []
		},
		totalQuantity() {
			return this.products.reduce((total, product) => {
				return product.quantity + total
			}, 0)
		},
		showFullPrice() {
			return this.$store.state.permissions.includes(
				PermissionInfo.ORDER_FULL_PRICE
			)
		},
		orderPrice() {
			return this.order ? this.order.price : 0
		},
		issues() {
			if (!this.order) {
				return []
			}
			let issues = []

			const refundsAllowed = this.$store.state.permissions.includes(
				PermissionInfo.ORDER_REFUNDS
			)
			const exceptionsAllowed = this.$store.state.permissions.includes(
				PermissionInfo.ORDER_EXCEPTIONS
			)

			let refunds =
				refundsAllowed && this.order && this.order.refunds
					? this.order.refunds
					: []
			let exceptions =
				exceptionsAllowed && this.order && this.order.exceptions
					? this.order.exceptions
					: []

			if (refunds.length > 0) {
				const refundTypeMap =
					this.$store.getters["ordersModule/refundTypeMap"]
				issues = issues.concat(
					refunds.map((refund) => {
						return {
							name: "Refund",
							reason:
								refund.reason_type == 10 || !refundTypeMap
									? refund.reason
									: refundTypeMap[refund.reason_type]
						}
					})
				)
			}
			if (exceptions.length > 0) {
				const exceptionTypeMap =
					this.$store.getters["ordersModule/exceptionTypeMap"]
				issues = issues.concat(
					exceptions.map((exception) => {
						return {
							name: "Exception",
							reason: exceptionTypeMap
								? exceptionTypeMap[exception.reason_type]
								: ""
						}
					})
				)
			}

			return issues
		},
		hasIssues() {
			return this.issues.length > 0
		},
		suborderOptions() {
			if (this.order) {
				return this.order.sub_orders.map((suborder, i) => {
					return {
						text: `${i + 1}`,
						id: suborder.id
					}
				})
			}
			return []
		},
		formattedSelectedSuborderOption() {
			if (
				this.selectedSuborderOption &&
				this.suborderOptions.length > 0
			) {
				const suborderLength = this.order.sub_orders.length
				return `${this.selectedSuborderOption.text} of ${suborderLength}`
			}
			return ""
		}
	},
	methods: {
		async handleOptions(options) {
			try {
				if (options.order) {
					this.order = options.order
				} else if (options.orderId) {
					this.loading = true
					const response = await search_service.searchOrders({
						filters: {
							ids: [options.orderId]
						}
					})

					const orders = response.data.orders
					if (this.isOpen && orders && orders.length > 0) {
						this.order = orders[0]
						if (this.order.sub_orders && this.order.sub_orders.length > 0) {
							this.selectedSuborderOption = {
								id: this.order.sub_orders[0].id,
								text: "1"
							}
						}
					}
					this.loading = false
				}
			} catch (e) {
				this.loading = false
				throw e
			}
		},
		closed() {
			this.order = null
			this.reviewingStepId = null
			this.loading = false
		},
		reviewStep(id) {
			this.reviewingStepId = id
		}
	}
}
</script>