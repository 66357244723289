<template>
	<div>
		<div class="sk-row sk-widget-alt">
			<div class="sk-radio-group">
				<SkRadio :option="0" v-model="exceptionType" />
				<div class="sk-radio-label">
					<div class="radio-label-title">
						<span>Full Exception</span>
						<span>{{ fullAmount | currency }}</span>
					</div>
					<div>Includes COGs, Tax, Fees & Tip</div>
				</div>
			</div>
		</div>
		<div class="sk-row sk-widget-alt">
			<div class="sk-radio-group">
				<SkRadio :option="1" v-model="exceptionType" />
				<div class="sk-radio-label">
					<div class="radio-label-title">
						<h2>Partial Exception</h2>
						<span>
							{{ partialAmount | currency }}
						</span>
					</div>
					<div
						v-for="product in suborderProducts"
						:key="product.uniqueId"
					>
						<div class="suborder-product">
							<SkCheckbox
								:checked="product.checked"
								@input="toggleProductException(product)"
							/>
							<span>
								{{ product.quantity }} {{ product.name }}
							</span>
							<span>
								{{ product.price | currency }}
							</span>
						</div>
						<div
							class="suborder-product-meta"
							v-for="meta in product.metas"
							:key="meta.uniqueId"
						>
							<SkCheckbox
								:checked="meta.checked"
								@input="toggleMetaException(meta)"
							/>
							<span>{{ meta.name }}</span>
							<span>
								{{ meta.price | currency }}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="sk-row sk-widget-alt">
			<div class="sk-radio-group">
				<SkRadio :option="2" v-model="exceptionType" />
				<div class="sk-radio-label">
					<h2>Custom Exception</h2>
					<div>
						<SkInput
							v-model="customAmount"
							:name="'Custom Exception'"
							:placeholder="placeholderText"
							:type="'number'"
						/>
					</div>
				</div>
			</div>
		</div>
		<div class="sk-row">
			<SkDropdownSelect
				:classes="'sk-input'"
				v-model="selectedRestaurant"
				:options="restaurants"
			/>
		</div>
		<div class="sk-row">
			<SkDropdownSelect
				:classes="'sk-input'"
				v-model="exceptionReason"
				:options="exceptions"
			/>
		</div>
		<transition-expand>
			<div v-if="showReasonInput" class="sk-row">
				<SkInput
					v-model="exceptionReasonText"
					:multiline="true"
					:name="'Exception Reason'"
				/>
			</div>
		</transition-expand>
	</div>
</template>

<style scoped>
.sk-row {
	margin-right: 0;
	margin-left: 0;
	width: 100%;
}

.sk-radio-group {
	width: 100%;
}
.sk-radio-group h2,
.radio-label-title span {
	font-weight: 600;
	font-size: 14px;
	color: var(--sk-navy);
}
.sk-radio-group h2,
.sk-radio-group h2 + div {
	font-size: 14px;
}
.sk-radio-group h2 + div {
	color: var(--sk-grey2);
}
.sk-radio-label {
	width: 100%;
}
.radio-label-title {
	display: flex;
	justify-content: space-between;
}
.sk-widget-alt >>> .sk-radio {
	width: 20px;
	height: 20px;
	flex-shrink: 0;
}
.suborder-product,
.suborder-product-meta {
	display: flex;
	align-items: center;
}

.suborder-product {
	margin-top: 10px;
}

.suborder-product + .suborder-product-meta {
	margin-top: 10px;
}

.suborder-product-meta {
	margin: 10px 0 10px 50px;
}

.suborder-product span,
.suborder-product-meta span {
	color: var(--sk-grey3);
}

.suborder-product span {
	font-size: 14px;
}

.suborder-product span + span,
.suborder-product-meta span + span {
	margin-left: 10px;
}

.suborder-product-meta span {
	font-size: 12px;
	font-style: italic;
}

.suborder-product >>> .sk-checkbox,
.suborder-product-meta >>> .sk-checkbox {
	margin-right: 20px;
}
</style>

<script>
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import { CourierId } from "@arikgaisler/utils/enums/courierId"
import { ExceptionReasonType } from "@/enums/exceptionReasonType.js"
import currencyFilterMixin from "@/mixins/currency-filter-mixin"
import SkRadio from "@/components/SkRadio.vue"
import SkInput from "@/components/SkInput.vue"
import SkCheckbox from "@/components/SkCheckbox.vue"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"
import utils from "@/utils/utils"
import TransitionExpand from "@/components/transitions/TransitionExpand.vue"

export const ExceptionType = {
	FULL: 0,
	PARTIAL: 1,
	CUSTOM: 2
}

export default {
	name: "ExceptionForm",
	mixins: [currencyFilterMixin],
	components: {
		SkRadio,
		SkInput,
		SkCheckbox,
		SkDropdownSelect,
		TransitionExpand
	},
	props: {
		type: {
			type: Number,
			default: 0
		},
		options: Object
	},
	data() {
		return {
			customAmount: null,
			exceptionReason: null,
			order: null,
			exception: {
				restaurant_id: null
			},
			exceptionType: ExceptionType.FULL,
			selectedProducts: [],
			selectedMetas: {},
			exceptionReasonText: ""
		}
	},
	computed: {
		selectedRestaurant: {
			get: function () {
				if (this.currentOrder && this.currentOrder.sub_orders) {
					const suborder = this.currentOrder.sub_orders.find(
						(suborder) => {
							const id =
								this.type == 1
									? suborder.restaurant.id
									: suborder.rest_id
							return this.exception.restaurant_id == id
						}
					)
					let name = "Restaurant Id"
					let id = null
					if (suborder) {
						name =
							this.type == 1
								? suborder.restaurant.name
								: suborder.rest_name
						id =
							this.type == 1
								? suborder.restaurant.id
								: suborder.rest_id
					}
					return {
						text: name,
						id: id
					}
				} else {
					return {
						text: "Restaurant",
						id: null
					}
				}
			},
			set: function (value) {
				this.exception.restaurant_id = value.id
			}
		},
		orderId() {
			return this.currentOrder ? this.currentOrder.id : ""
		},
		fullAmount() {
			return parseFloat(this.fullPrice).toFixed(2)
		},
		partialAmount() {
			const products = this.suborderProducts.filter((product) =>
				this.selectedProducts.includes(product.uniqueId)
			)
			const productPrices = products.reduce((amount, product) => {
				let price = parseFloat(product.price)
				amount = amount + price
				return amount
			}, 0)
			const metaPrices = Object.values(this.selectedMetas).reduce(
				(amount, meta) => {
					let metaPrice = meta.price
					amount = amount + metaPrice
					return amount
				},
				0
			)
			return productPrices + metaPrices
		},
		fullPrice() {
			var retVal = 0
			if (!this.currentOrder) {
				return retVal
			}
			for (var i = 0; i < this.currentOrder.sub_orders.length; i++) {
				const subOrder = this.currentOrder.sub_orders[i]
				let restaurant_id = 0
				if (this.type == 0) {
					restaurant_id = subOrder.rest_id
				} else if (subOrder.restaurant) {
					restaurant_id = subOrder.restaurant.id
				}

				if (restaurant_id == this.exception.restaurant_id) {
					for (var k = 0; k < subOrder.products.length; k++) {
						const product = subOrder.products[k]
						retVal =
							retVal + parseFloat(product.cog_item_total_cost)
						const metas = product.meta
							? product.meta
							: product.metas
						if (metas != undefined && metas.length > 0) {
							for (var j = 0; j < metas.length; j++) {
								let metaPrice =
									parseFloat(metas[j].price) *
									product.quantity
								retVal = parseFloat(retVal) + metaPrice
							}
						}
					}
				}
			}
			let tax = this.business ? this.business.tax : 0
			retVal = retVal + (tax / 100) * retVal
			if (
				this.currentOrder.sub_orders[0].courier_id ==
					CourierId.WALKING ||
				this.currentOrder.sub_orders[0].courier_id == CourierId.TOOKAN
			)
				retVal = parseFloat(retVal) + parseFloat(this.currentOrder.tip)
			return retVal
		},
		currentOrder() {
			if (this.order == null) {
				return this.$store.state.ordersModule.currentOrder
			} else {
				return this.order
			}
		},
		placeholderText() {
			return "Max $" + parseFloat(this.fullAmount).toFixed(2)
		},
		exceptions() {
			let exceptions = this.$store.state.ordersModule.exception_types.slice()
			exceptions.unshift({
				id: null,
				text: "Exception Reason"
			})
			return exceptions
		},
		showReasonInput(){
			return this.exceptionReason && this.exceptionReason.id == ExceptionReasonType.OTHER
		},
		business() {
			if (this.type == 0) {
				const businessId = this.currentOrder.business_id
				return this.$store.getters.businessesMap[businessId]
			} else {
				const slug = this.currentOrder.business.slug
				return this.$store.state.businesses[slug]
			}
		},
		restaurants() {
			let restaurants = []
			if (this.currentOrder != null) {
				let restaurantIds = []
				this.currentOrder.sub_orders.forEach((suborder) => {
					let restaurantId =
						this.type == 1
							? suborder.restaurant.id
							: suborder.rest_id
					if (!restaurantIds.includes(restaurantId)) {
						restaurantIds.push(restaurantId)
						restaurants.push({
							id: restaurantId,
							text:
								this.type == 1
									? suborder.restaurant.name
									: suborder.rest_name
						})
					}
				})
			}
			restaurants.unshift({
				id: null,
				text: "Exception Applies To"
			})
			return restaurants
		},
		suborderProducts() {
			if (!this.currentOrder) {
				return []
			}

			const suborders = this.currentOrder
				? this.currentOrder.sub_orders
				: []
			const tax = this.business && this.business.tax ? this.business.tax : 0
			return suborders
				.map((suborder) => suborder.products)
				.flat()
				.map((product, i) => {
					let metas = []
					let productMetas = null
					if (product.metas) {
						productMetas = product.metas
					} else if (product.meta) [(productMetas = product.meta)]
					if (productMetas) {
						metas = productMetas.map((meta) => {
							let uniqueId = `${product.id}-${meta.meta_id}-${meta.meta_val_id}-${i}`
							let metaPrice = parseFloat(meta.price) * product.quantity
							let taxes = (metaPrice * tax) / 100
							return {
								uniqueId: uniqueId,
								id: meta.meta_id,
								price: metaPrice + taxes,
								name: `${meta.label}: ${meta.value}`,
								checked:
									uniqueId in this.selectedMetas
										? true
										: false
							}
						})
					}
					let uniqueId = `${product.id}-${i}`
					let taxes = (parseFloat(product.cog_item_total_cost) * tax) / 100
					return {
						name: product.name,
						quantity: product.quantity,
						price: parseFloat(product.cog_item_total_cost) + taxes,
						uniqueId: uniqueId,
						id: product.id,
						checked: this.selectedProducts.includes(uniqueId),
						metas: metas
					}
				})
		}
	},
	methods: {
		toggleProductException(product) {
			if (this.exceptionType != ExceptionType.PARTIAL) {
				this.exceptionType = ExceptionType.PARTIAL
			}
			let productMetaIds = product.metas.map((meta) => {
				return { id: meta.uniqueId, price: meta.price }
			})
			if (product.checked) {
				const index = this.selectedProducts.indexOf(product.id)
				this.selectedProducts.splice(index, 1)
				if (productMetaIds) {
					productMetaIds.forEach((productMetaIdObj) => {
						if (productMetaIdObj.id in this.selectedMetas) {
							this.$delete(
								this.selectedMetas,
								productMetaIdObj.id
							)
						}
					})
				}
			} else if (!this.selectedProducts.includes(product.id)) {
				this.selectedProducts.push(product.uniqueId)
				let newProductMetaIds = productMetaIds.filter(
					(prodMeta) => !(prodMeta.id in this.selectedMetas)
				)
				if (newProductMetaIds) {
					newProductMetaIds.forEach(
						(productMeta) =>
							(this.selectedMetas[productMeta.id] = productMeta)
					)
				}
			}
		},
		toggleMetaException(meta) {
			if (this.exceptionType != ExceptionType.PARTIAL) {
				this.exceptionType = ExceptionType.PARTIAL
			}
			if (meta.checked) {
				this.$delete(this.selectedMetas, meta.uniqueId)
			} else if (!(meta.uniqueId in this.selectedMetas)) {
				this.$set(this.selectedMetas, meta.uniqueId, {
					id: meta.uniqueId,
					price: meta.price
				})
			}
		},
		validateExceptionData() {
			if (this.exception.amount <= 0 || !this.exception.amount) {
				throw "Please enter an amount"
			}
			if (
				this.exceptionReason == null ||
				this.exceptionReason.id == undefined
			) {
				throw "A reason is required"
			}
			if (this.selectedRestaurant.id == null) {
				throw "Please select a restaurant for the exception"
			}
			return true
		},
		async submit() {
			try {
				if (this.exceptionType == 0) {
					this.exception.amount = this.fullAmount
				} else if (this.exceptionType == 1) {
					this.exception.amount = this.partialAmount
				} else if (this.exceptionType == 2) {
					if (this.customAmount != null && this.customAmount > 0) {
						this.exception.amount = this.customAmount
					}
				}
				this.validateExceptionData()
				this.exception.reason_id = this.exceptionReason.id
				this.exception.reason_text = this.exceptionReasonText
				if (this.exception.id != undefined && this.exception.id > 0) {
					const success = await this.$store.dispatch(
						"ordersModule/updateException",
						this.exception
					)
					if (!success) {
						return false
					}
				} else {
					const success = await this.$store.dispatch("ordersModule/addException", {
						orderId: this.currentOrder.id,
						exception: this.exception
					})
					this.$store.dispatch(
						"ordersModule/orderStatusUpdate",
						this.currentOrder.id
					)
					if (!success) {
						return false
					}
				}
				return true
			} catch (e) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					type: NotificationType.WARNING,
					message: e.message ? e.message : e
				})
				return false
			}
		},
		resetForm() {
			this.customAmount = null
			this.exceptionReason = null
			this.exceptionType = 0
			this.selectedProducts = []
			this.selectedMetas = {}
			this.selectedRestaurant = { id: null }
			this.exception = {
				restaurant_id: null
			}
			this.order = null
		}
	},
	watch: {
		options: {
			immediate: true,
			deep: true,
			handler() {
				if (!this.options) {
					this.order = null
					this.exception = {
						restaurant_id: null
					}
					this.exceptionReason = null
					return
				}
				this.order = this.options.order
					? utils.clonedeep(this.options.order)
					: null

				if (this.options.exception) {
					this.exception = utils.clonedeep(this.options.exception)
				} else if (this.currentOrder) {
					const suborder = this.currentOrder.sub_orders[0]
					const id =
						this.type == 1
							? suborder.restaurant.id
							: suborder.rest_id
					this.exception = {
						restaurant_id: id
					}
				} else {
					this.exception = {
						restaurant_id: null
					}
				}

				if (this.exception) {
					this.selectedRestaurant = {
						id: this.exception.restaurant_id
					}
					this.exceptionReason = this.exceptions.find(
						(exception) => exception.id == this.exception.reason_id
					)
					this.exceptionReasonText = this.exception.reason_text ? this.exception.reason_text : ""
				}
			}
		},
		currentOrder(newVal) {
			if (newVal != null && newVal.sub_orders) {
				const suborder = newVal.sub_orders[0]
				const id =
					this.type == 1 ? suborder.restaurant.id : suborder.rest_id
				this.selectedRestaurant = {
					id: id
				}
			}
		},
		exceptionType(newVal) {
			if (newVal != 1) {
				this.selectedProducts = []
				this.selectedMetas = {}
			}
			if (newVal != 2) {
				this.customAmount = null
			}
		},
		customAmount(newVal) {
			if (newVal && newVal >= 0) {
				this.exceptionType = 2
			}
		}
	}
}
</script>