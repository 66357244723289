<template>
	<SkBaseModal
		class="view-prepackage-order-modal"
		:isOpen="isOpen"
		@close="close"
	>
		<div class="sk-modal-alt-title">
			<h1>{{ modalTitle }}</h1>
			<div :class="statusBadgeClasses">
				<span :class="statusClasses"></span>
				{{ statusLabel }}
			</div>
		</div>
		<div class="sk-modal-section">
			<SetupReviewStep
				:stepName="'Details'"
				:stepId="0"
				:editable="false"
				:showCompletion="false"
				:showProgress="false"
				:reviewingStepId="reviewingStepId"
				:openByDefault="true"
				@reviewStep="reviewStep"
			>
				<div class="setup-review-step-detail">
					Business
					<b v-sk-unassigned="businessName" />
				</div>
				<div class="setup-review-step-detail">
					Delivery On
					<b v-sk-unassigned="deliveryOn" />
				</div>
				<div class="setup-review-step-detail">
					Delivery Time
					<b v-sk-unassigned="deliveryTime" />
				</div>
				<div class="setup-review-step-detail">
					Delivery By
					<b v-sk-unassigned="deliveryBy" />
				</div>
			</SetupReviewStep>
			<SetupReviewStep
				:stepName="'Quantity & Type'"
				:stepId="1"
				:editable="false"
				:showCompletion="false"
				:showProgress="false"
				:reviewingStepId="reviewingStepId"
				:openByDefault="true"
				@reviewStep="reviewStep"
			>
				<div
					v-for="product in products"
					:key="product.id"
					class="setup-review-step-detail prepackaged-product-review"
				>
					<div>
						<b>{{ product.name }}</b>
						<b>{{ product.totalPrice | currency }}</b>
					</div>
					<div>
						Quantity:
						<b>{{ product.quantity }}</b>
					</div>
				</div>
				<div class="prepackaged-review-divider"></div>
				<div
					class="setup-review-step-detail prepackaged-product-review"
				>
					<div>
						<b>Total</b>
						<b>{{ productTotalPrice | currency }}</b>
					</div>
					<div>
						Quantity:
						<b>{{ productTotal }}</b>
					</div>
				</div>
			</SetupReviewStep>
		</div>
	</SkBaseModal>
</template>

<style scoped>
.view-prepackage-order-modal >>> .sk-modal-content {
	background: var(--sk-greybg2);
}

.prepackaged-product-review div:first-child {
	display: flex;
	justify-content: space-between;
}

.prepackaged-product-review div:first-child b {
	margin-left: 0;
}

.prepackaged-product-review div:last-child b {
	margin-left: 5px;
}

.prepackaged-review-divider {
	padding-bottom: 15px;
	margin-top: -5px;
	border-top: 1px solid var(--sk-grey);
}

.sk-modal-alt-title {
	z-index: 0;
	margin-bottom: 0;
	position: relative;
	justify-content: space-between;
}

.sk-modal-alt-title .pill-alt {
	margin-right: 20px;
}
</style>

<script>
import moment from "moment"
import { CourierId } from "@arikgaisler/utils/enums/courierId"
import currencyFilterMixin from "@/mixins/currency-filter-mixin"
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import SetupReviewStep from "@/components/modals/SetupReviewStep.vue"

export default {
	name: "ViewPrepackageOrderModal",
	mixins: [skModalMixin, currencyFilterMixin],
	components: {
		SkBaseModal,
		SetupReviewStep
	},
	data: function () {
		return {
			order: null,
			reviewingStepId: null
		}
	},
	computed: {
		business() {
			if (this.order) {
				return this.$store.getters.businessesMap[this.order.business_id]
			}
			return null
		},
		suborder() {
			if (this.order) {
				return this.order.sub_orders[0]
			}
			return null
		},
		orderDates() {
			if (this.suborder && this.business) {
				const orderMoment = moment
					.utc(this.suborder.delivery_slot)
					.add(this.business.timezone_offset, "m")
				return {
					date: orderMoment.format("YYYY-MM-DD HH:mm:ss"),
					shortDate: orderMoment.format("M/D"),
					day: orderMoment.format("dddd"),
					time: orderMoment.format("h:mm A")
				}
			}
			return null
		},
		modalTitle() {
			if (this.orderDates) {
				return `${this.orderDates.day} ${this.orderDates.shortDate} Order #${this.order.order_id}`
			}
			return ""
		},
		products() {
			if (this.suborder) {
				return this.suborder.products.map((product) => {
					return {
						id: product.product_id,
						name: product.name,
						quantity: product.quantity,
						totalPrice: product.total_price
					}
				})
			}
			return []
		},
		productTotal() {
			return this.products.reduce((total, product) => {
				return product.quantity + total
			}, 0)
		},
		productTotalPrice() {
			return this.products.reduce((total, product) => {
				return total + product.totalPrice
			}, 0)
		},
		businessName() {
			return this.business ? this.business.name : null
		},
		deliveryOn() {
			if (this.orderDates) {
				return `${this.orderDates.day} ${this.orderDates.shortDate}`
			}
			return null
		},
		deliveryTime() {
			if (this.orderDates) {
				return this.orderDates.time
			}
			return null
		},
		deliveryBy() {
			if (this.suborder) {
				switch (this.suborder.courier_id) {
					case CourierId.TOOKAN:
					case CourierId.WALKING: {
						const restaurant = this.$store.state.restaurants[this.suborder.rest_id]
						return restaurant ? restaurant.name : "N/A"
					}
					case CourierId.CUT_CATS:
						return "Cut Cats"
					case CourierId.POSTMATES:
						return "Postmates"
					case CourierId.DOORDASH:
						return "Door Dash"
				}
			}
			return null
		},
		cancelled() {
			return (
				(this.suborder && this.suborder.status == 4) ||
				this.order.cancelled == 1
			)
		},
		statusBadgeClasses() {
			if (this.suborder) {
				switch (this.suborder.status) {
					case 0:
						return "pill-alt pill-alt-green"
					case 4:
						return "pill-alt pill-alt-red"
					case 2:
						return "pill-alt pill-alt-grey"
				}
			}
			return "pill-alt pill-alt-green"
		},
		statusLabel() {
			if (this.suborder) {
				switch (this.suborder.status) {
					case 0:
						return "Upcoming"
					case 4:
						return "Cancelled"
					case 2:
						return "Completed"
				}
			}
			return "Upcoming"
		},
		statusClasses() {
			if (this.suborder) {
				switch (this.suborder.status) {
					case 0:
						return "status-dot green"
					case 4:
						return "status-dot red"
					case 2:
						return "status-dot grey"
				}
			}
			return "status-dot green"
		}
	},
	methods: {
		handleOptions(options) {
			this.order = options.order
		},
		closed() {
			this.order = null
		},
		reviewStep(id) {
			this.reviewingStepId = id
		}
	}
}
</script>