import { EventStatus } from "@arikgaisler/utils/enums/eventStatus"
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import Vue from "vue"
import business_service from "@/services/businesses"
import restaurant_service from "@/services/restaurants"
import ActionTypes from "@/store/modules/events/action-types"
import MutationTypes from "@/store/modules/events/mutation-types"
import orders_service from "@/services/orders"

const vue = new Vue()

export default {
	async [ActionTypes.EDIT_BUSINESS_EVENT]({ commit, dispatch }, payload) {
		let data = payload.data
		try {
			if (!data.business_id) {
				throw new Error("A business is required.")
			}

			if (data.contact_email && !data.contact_email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
				throw new Error("Event Coordinator Email is invalid")
			}

			let response = {}
			const name = data.name ? data.name : "a Popup"
			if (data.id) {
				if (payload.status == EventStatus.CONFIRMED) {
					await dispatch(ActionTypes.UPDATE_EVENT_STATUS, {
						status: EventStatus.IN_REVIEW,
						id: data.id
					})
				}
				response = await business_service.updateBusinessEvent({ event: data })
				if (response.status == 200) {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: `Successfully updated ${name} event`
					})
					commit(MutationTypes.UPDATED_EVENT, response.data.event)
				}
			} else {
				response = await business_service.scheduleBusinessEvent(data.business_id, data)
				if (response.status == 200) {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: `Successfully requested a ${name} event`
					})
					commit(MutationTypes.SCHEDULED_EVENT, response.data.event)
				}
			}
			return response.status == 200 ? true : false
		} catch (e) {
			vue.bugsnag.notify(
				"Unable to schedule a business event",
				event => {
					event.addMetadata("error", {
						error: e,
						businessId: data.business_id
					})
				}
			)
			eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
				title: e.message ? e.message : e,
				type: NotificationType.ERROR
			})
			return false
		}
	},
	async [ActionTypes.DUPLICATE_BUSINESS_EVENT]({ commit }, data) {
		try {
			let name = data.name ? data.name : ""
			let response = await business_service.scheduleBusinessEvent(data.business_id, data)
			if (response.status == 200) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: `Successfully duplicated ${name}`
				})
				commit(MutationTypes.SCHEDULED_EVENT, response.data.event)
			}
			return response.status == 200 ? true : false
		}
		catch (e) {
			vue.bugsnag.notify(
				"Unable to duplicate business event",
				event => {
					event.addMetadata("error", {
						error: e,
						businessId: data.business_id,
						eventId: data.id
					})
				}
			)
			eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
				title: e.message ? e.message : e,
				type: NotificationType.ERROR
			})
			return false
		}
	},
	async [ActionTypes.FILTER_EVENTS]({ commit, dispatch }, payload) {
		commit(MutationTypes.SET_EVENTS_FILTERS, payload)
		return dispatch(ActionTypes.GET_EVENTS)
	},
	async [ActionTypes.GET_EVENTS]({ state, commit }, options) {
		try {
			let payload = {}

			if (options && options.clear) {
				commit(MutationTypes.SET_EVENTS_FILTERS, {
					startDate: null,
					endDate: null,
					businessId: 0,
					restaurantId: 0,
					status: -1,
					type: -1
				})
			}

			if (state.filters.businessId) {
				payload.business_ids = [state.filters.businessId]
			}
			if (state.filters.restaurantId) {
				payload.restaurant_ids = [state.filters.restaurantId]
			}
			if (state.filters.status != -1) {
				payload.statuses = [state.filters.status]
			}
			if (state.filters.type != -1) {
				payload.types = [state.filters.type]
			}
			if (state.filters.startDate && state.filters.endDate) {
				payload.start_from = state.filters.startDate
				payload.start_to = state.filters.endDate
			} else if (state.filters.startDate || state.filters.endDate) {
				payload.start_from = state.filters.startDate || state.filters.endDate
				payload.start_to = state.filters.startDate || state.filters.endDate
			}
			commit(MutationTypes.GOT_EVENTS, await business_service.searchBusinessEvents(payload))
			return true
		} catch (e) {
			vue.bugsnag.notify(
				"Unable to get business events",
				event => {
					event.addMetadata("error", {
						error: e,
						filters: options
					})
				}
			)
			console.warn("Unable to search business events", e)
			return false
		}
	},
	async [ActionTypes.DELETE_EVENT]({ commit }, payload) {
		try {
			const response = await business_service.deleteBusinessEvent(payload.eventId)
			if (response.status == 200) {
				commit(MutationTypes.DELETED_EVENT, payload.eventId)
				return true
			}
			throw `API Error: ${response.status}`
		} catch (e) {
			vue.bugsnag.notify(
				"Unable to delete event",
				event => {
					event.addMetadata("error", {
						error: e,
						eventId: payload.eventId
					})
				}
			)
			return false
		}
	},
	async [ActionTypes.END_EVENT]({ commit }, eventId) {
		try {
			const response = await business_service.endBusinessEvent(eventId)
			if (response.status == 200) {
				commit(MutationTypes.ENDED_EVENT, eventId)
				return true
			}
			throw `API Error: ${response.status}`
		} catch (e) {
			vue.bugsnag.notify(
				"Unable to end event",
				event => {
					event.addMetadata("error", {
						error: e,
						eventId: eventId
					})
				}
			)
			return false
		}
	},
	async [ActionTypes.STOP_EVENT_PRE_ORDERS]({ commit }, eventId) {
		try {
			const response = await business_service.stopBusinessEventPreOrders(eventId)
			if (response.status == 200) {
				commit(MutationTypes.STOPPED_EVENT_PRE_ORDERS, eventId)
				return true
			}
			throw `API Error: ${response.status}`
		} catch (e) {
			vue.bugsnag.notify(
				"Unable to stop event pre orders",
				event => {
					event.addMetadata("error", {
						error: e,
						eventId: eventId
					})
				}
			)
			return false
		}
	},
	async [ActionTypes.UPDATE_EVENT_STATUS]({ commit }, payload) {
		try {
			let response = {}
			switch (payload.status) {
				case EventStatus.IN_REVIEW:
					response = await business_service.reviewBusinessEvent(payload.id)
					break
				case EventStatus.CONFIRMED:
					response = await business_service.confirmBusinessEvent(payload.id)
					break
				case EventStatus.CANCELLED:
					response = await business_service.cancelBusinessEvent(payload.id)
					break
			}
			if (response.status == 200) {
				commit(MutationTypes.UPDATED_EVENT_STATUS, payload)
				return true
			} else {
				throw "Unable to update event status"
			}
		} catch (e) {
			vue.bugsnag.notify(
				"Unable to update event status",
				event => {
					event.addMetadata("error", {
						error: e,
						eventId: payload.id,
						eventStatus: payload.status
					})
				}
			)
			return false
		}
	},
	async [ActionTypes.UPDATE_EVENT_FULFILLMENT_STATUS]({ commit }, payload) {
		try {
			const response = await business_service.fulfillBusinessEvent(payload.id, {
				status: payload.status
			})
			if (response.status == 200) {
				commit(MutationTypes.UPDATED_EVENT_FULFILLMENT_STATUS, payload)
				return true
			} else {
				throw "Unable to update event status"
			}
		} catch (e) {
			vue.bugsnag.notify(
				"Unable to update event fulfillment status",
				event => {
					event.addMetadata("error", {
						error: e,
						eventId: payload.id,
						fulfillmentStatus: payload.status
					})
				}
			)
			return false
		}
	},
	async [ActionTypes.UPDATE_EVENT_NOTES]({ commit }, payload) {
		try {
			const response = await business_service.updateBusinessEventNotes(payload.id, {
				event: {
					manifest_notes: payload.notes,
				}
			})

			if (response.status == 200) {
				commit(MutationTypes.UPDATED_EVENT_NOTES, payload)
				return true
			} else {
				throw `API Error: ${response.status}`
			}
		} catch (e) {
			vue.bugsnag.notify(
				"Unable to update event notes",
				event => {
					event.addMetadata("error", {
						error: e,
						id: payload.id,
						notes: payload.notes
					})
				}
			)
			return false
		}
	},
	async [ActionTypes.GET_EVENT]({ state, commit, dispatch }, id) {
		try {
			if (id) {
				commit(MutationTypes.GOT_EVENT, await business_service.getBusinessEvent(id))
			} else {
				commit(MutationTypes.GOT_EVENT, {})
			}
			if (state.event.restaurant_id) {
				await dispatch(ActionTypes.GET_EVENT_RESTAURANT_MENUS, state.event.restaurant_id)
			}

			return state.event
		} catch (e) {
			vue.bugsnag.notify(
				"Unable to get business event",
				event => {
					event.addMetadata("error", {
						error: e,
						id: id
					})
				}
			)
			console.warn("Unable to get business event", e)
			return false
		}
	},
	async [ActionTypes.GET_EVENT_ORDERS]({ state, commit }) {
		try {
			if (state.event.id) {
				const response = await orders_service.getEventOrders(state.event.id)
				if (response.status == 200) {
					commit(MutationTypes.GOT_EVENT_ORDERS, response.data.orders)
					return true
				} else {
					throw `API Error: ${response.status}`
				}
			}
		} catch (e) {
			vue.bugsnag.notify(
				"Unable to get business event orders",
				event => {
					event.addMetadata("error", {
						error: e,
						id: state.event.id
					})
				}
			)
			return false
		}
	},
	async [ActionTypes.GET_EVENT_MENUS]({ state, commit }, ids) {
		try {
			const menuIds = Object.keys(state.eventMenus)
			let missingIds = ids.filter((id) => !menuIds.includes(`${id}`))
			if (missingIds.length > 0) {
				const response = await restaurant_service.getMenusByIds(missingIds.join())
				commit(MutationTypes.GOT_EVENT_MENUS, response.data)
			}
			return true
		} catch (e) {
			vue.bugsnag.notify(
				"Unable to fetch event menus",
				event => {
					event.addMetadata("error", {
						error: e,
						menuIds: ids
					})
				}
			)
			console.warn("Unable to fetch event menus", e)
			return false
		}
	},
	async [ActionTypes.GET_EVENT_RESTAURANT_MENUS]({ state, commit }, restaurantId) {
		try {
			if (!state.restaurantMenus[restaurantId]) {
				const response = await restaurant_service.getRestaurantMenus(restaurantId)
				if (response.data.menus) {
					commit(MutationTypes.GOT_EVENT_RESTAURANT_MENUS, {
						menus: response.data.menus,
						restaurantId: restaurantId
					})
				} else {
					throw "No menus returned"
				}
			}
			return true
		} catch (e) {
			vue.bugsnag.notify(
				"Unable to fetch restaurant event menus",
				event => {
					event.addMetadata("error", {
						error: e,
						rest_id: restaurantId
					})
				}
			)
			console.warn("Unable to fetch restaurant event menus", e)
			return false
		}
	},
	async [ActionTypes.GET_EVENT_RESTAURANT_MENU_PRODUCTS]({ state, commit }, payload) {
		try {
			let restaurantMenus = state.restaurantMenus[payload.restaurantId] ? state.restaurantMenus[payload.restaurantId] : []
			let menu = restaurantMenus.find(menu => menu.id == payload.menuId)

			if (menu && menu.products) {
				commit(MutationTypes.GOT_EVENT_RESTAURANT_MENU_PRODUCTS, {
					products: menu.products,
					selectedProducts: payload.products,
					restaurantId: payload.restaurantId,
					menuId: payload.menuId
				})
				return true
			}
			const response = await restaurant_service.getMenuProducts(payload.menuId)
			if (response.data.products) {
				commit(MutationTypes.GOT_EVENT_RESTAURANT_MENU_PRODUCTS, {
					products: response.data.products,
					selectedProducts: payload.products,
					restaurantId: payload.restaurantId,
					menuId: payload.menuId
				})
			} else {
				throw "No products returned"
			}
			return true
		} catch (e) {
			vue.bugsnag.notify(
				"Unable to fetch restaurant event menu products",
				event => {
					event.addMetadata("error", {
						error: e,
						rest_id: payload.restaurantId,
						menu_id: payload.menuId
					})
				}
			)
			console.warn("Unable to fetch restaurant event menu products", e)
			return false
		}
	},
	async [ActionTypes.GET_EVENT_REMINDERS]({ state, commit }) {
		try {
			if (state.event.id) {
				const response = await business_service.getBusinessEventReminders(state.event.id)
				if (response.status == 200) {
					commit(MutationTypes.GOT_EVENT_REMINDERS, response.data.reminders)
					return true
				} else {
					throw `API Error: ${response.status}`
				}
			}
		} catch (e) {
			vue.bugsnag.notify(
				"Unable to get business event reminders",
				event => {
					event.addMetadata("error", {
						error: e,
						id: state.event.id
					})
				}
			)
			return false
		}
	},
	async [ActionTypes.UPDATE_EVENT_REMINDER]({ commit }, payload) {
		try {
			const response = await business_service.updateBusinessEventReminder(payload.id, {
				reminder: {
					type: payload.reminderType,
					remind_at: payload.remindAt
				}
			})

			if (response.status == 200) {
				commit(MutationTypes.UPDATED_EVENT_REMINDER, response.data.reminder)
				return true
			} else {
				throw `API Error: ${response.status}`
			}
		} catch (e) {
			vue.bugsnag.notify(
				"Unable to update event reminder",
				event => {
					event.addMetadata("error", {
						error: e,
						id: payload.id,
						notes: payload.notes
					})
				}
			)
			return false
		}
	},
	async [ActionTypes.ADD_EVENT_REMINDER]({ commit }, payload) {
		try {
			const response = await business_service.addEventReminder(payload.eventId, {
				type: payload.reminderType,
				remind_at: payload.remindAt
			})
			if (response.status == 200) {
				commit(MutationTypes.ADDED_EVENT_REMINDER, response.data.reminder)
				return true
			} else {
				throw `API Error: ${response.status}`
			}
		} catch (e) {
			vue.bugsnag.notify(
				"Unable to add event reminder",
				event => {
					event.addMetadata("error", {
						error: e,
						event_id: payload.eventId,
						notes: payload.notes
					})
				}
			)
			return false
		}
	},
	async [ActionTypes.DELETE_EVENT_REMINDER]({ commit }, reminderId) {
		try {
			let response = await business_service.deleteEventReminder(reminderId)
			if (response.status == 200) {
				commit(MutationTypes.DELETED_EVENT_REMINDER, reminderId)
				return true
			}
			throw `API Error: ${response.status}`
		} catch (e) {
			vue.bugsnag.notify(
				"Unable to delete event reminder",
				event => {
					event.addMetadata("error", {
						error: e,
						reminderId: reminderId
					})
				}
			)
			return false
		}
	},
	async [ActionTypes.GET_EVENT_MANIFEST](store, eventId) {
		try {
			const response = await business_service.getEventManifestURL(eventId)
			if (response.status == 200) {
				return response.data.manifest_url
			} else {
				return null
			}
		} catch (e) {
			vue.bugsnag.notify(
				"Unable to generate manifest",
				event => {
					event.addMetadata("error", {
						error: e,
						event_id: eventId
					})
				}
			)
			return null
		}
	},
}