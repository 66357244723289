let mobileResponsivenessMixin = {
  data: function() {
    return {
      mobileThresholdWidth: this.$mobileWidth,
      isMobile: false,
      windowSize: {
        width: 0,
        height: 0
      },
      resizeCallback: this.handleResize.bind(this)
    }
  },
  mounted: function() {
    window.addEventListener("resize", this.resizeCallback)
    this.handleResize()
  },
  beforeDestroy: function() {
    window.removeEventListener("resize", this.resizeCallback)
  },
  methods: {
    handleResize() {
      this.windowSize.width = document.documentElement.clientWidth
      this.windowSize.height = document.documentElement.clientHeight
      this.isMobile = this.windowSize.width < this.mobileThresholdWidth
    }
  }
}

export default mobileResponsivenessMixin
