import utils from "@/utils/utils"

let currencyFilterMixin = {
	filters: {
		currency: function (value) {
			if (!value || isNaN(parseFloat(value))) {
				return value
			}
			return utils.formatCurrency(value)
		}
	}
}

export default currencyFilterMixin
