<template>
	<div class="sk-page-content dashboard-page-content">
		<div class="dashboard-content-left">
			<BusinessDashboardHeader class="sk-row" />
			<div class="sk-row-inner">
				<BusinessDashboardMenus />
				<BusinessDashboardOrders />
			</div>
		</div>
		<div class="dashboard-content-right">
			<DashboardCta
				v-if="showVouchers"
				class="sk-row"
				:title="'Vouchers'"
				:message="'Delight your guests with free food'"
				:icon="'sk-icon-ticket-alt-solid'"
				:actions="voucherActions"
				@actionSelected="voucherActionSelected"
			/>
			<DashboardCta
				v-if="showMealBoxes"
				class="sk-row"
				:title="'Catering'"
				:message="'Provide groups with exclusive menus catered to your needs'"
				:icon="'sk-icon-sandwich-solid'"
				:actions="prepackagesActions"
				@actionSelected="prepackagesActionSelected"
			/>
			<DashboardCta
				class="sk-row"
				:title="'Supplies'"
				:message="'Boost customer awareness & interaction with 2K supplies'"
				:icon="'sk-icon-boxes-solid'"
				:actions="suppliesActions"
				@actionSelected="suppliesActionSelected"
			/>
			<DashboardCta
				v-if="showTraining"
				class="sk-row"
				:title="'Training'"
				:message="'Complete a quick training session for a free meal'"
				:icon="'sk-icon-book-solid'"
				:actions="trainingActions"
				@actionSelected="trainingActionSelected"
			/>
			<DashboardCta
				v-if="showFinance"
				class="sk-row"
				:title="'Finance'"
				:message="'Check invoices and keep your financial details up to date'"
				:icon="'sk-icon-dollar-sign-regular'"
				:actions="financesActions"
				@actionSelected="financesActionSelected"
			/>
			<DashboardCta
				class="sk-row"
				:title="'Events'"
				:message="'Events are a great way to boost business & engage customers'"
				:icon="'sk-icon-calendar-alt-regular'"
				:actions="eventsActions"
				@actionSelected="eventsActionSelected"
			/>
			<DashboardCta
				class="sk-row"
				:title="'Insights'"
				:message="'Dive deeper into your sales, orders and performance'"
				:icon="'sk-icon-analytics-solid'"
				:actions="insightsActions"
				@actionSelected="insightsActionSelected"
			/>
			<DashboardCta
				class="sk-row"
				:title="'Feedback'"
				:message="'Provide your feedback to help build a better experience'"
				:icon="'sk-icon-comment-edit-solid'"
				:actions="feedbackActions"
				@actionSelected="feedbackActionSelected"
			/>
		</div>
		<StaffTrainingIntroModal ref="staffTrainingIntroModal" :manual="true" />
		<RequestEventModal ref="requestEventModal" />
		<PrepackagesIntroModal ref="prepackagesIntroModal" />
		<RequestSuppliesModal ref="requestSuppliesModal" />
		<VoucherRequestModal ref="voucherRequestModal" />
		<OrderPrepackagesModal ref="orderPrepackagesModal" />
		<CannyFeedbackModal ref="cannyFeedbackModal" />
	</div>
</template>

<script>
import { PusherEvent } from "@/enums/pusherEvent"
import { PermissionPage } from "@/utils/permissions"
import { UserSubRole } from "@/enums/userRole"
import tracker from "@/utils/tracker"
import ActionTypes from "@/store/modules/businessDashboard/action-types"
import loaderMixin from "@/mixins/loader-mixin"
import pusherMixin from "@/mixins/pusher-mixin"
import BusinessDashboardHeader from "@/components/dashboard/business/BusinessDashboardHeader.vue"
import BusinessDashboardMenus from "@/components/dashboard/business/BusinessDashboardMenus.vue"
import BusinessDashboardOrders from "@/components/dashboard/business/BusinessDashboardOrders.vue"
import DashboardCta from "@/components/dashboard/DashboardCta.vue"
import RequestEventModal from "@/components/modals/RequestEventModal.vue"
import PrepackagesIntroModal from "@/components/modals/prepackages/PrepackagesIntroModal.vue"
import StaffTrainingIntroModal from "@/components/modals/StaffTrainingIntroModal.vue"
import RequestSuppliesModal from "@/components/modals/supplies/RequestSuppliesModal.vue"
import VoucherRequestModal from "@/components/modals/VoucherRequestModal.vue"
import OrderPrepackagesModal from "@/components/modals/prepackages/OrderPrepackagesModal.vue"
import CannyFeedbackModal from "@/components/modals/CannyFeedbackModal.vue"

export default {
	name: "BusinessDashboard",
	mixins: [loaderMixin, pusherMixin],
	components: {
		BusinessDashboardHeader,
		BusinessDashboardMenus,
		BusinessDashboardOrders,
		DashboardCta,
		PrepackagesIntroModal,
		RequestEventModal,
		StaffTrainingIntroModal,
		RequestSuppliesModal,
		VoucherRequestModal,
		OrderPrepackagesModal,
		CannyFeedbackModal
	},
	data: function () {
		return {
			suppliesActions: [
				{
					id: 0,
					text: "Order Supplies"
				},
				{
					id: 1,
					text: "View All"
				}
			],
			voucherActions: [
				{
					id: 0,
					text: "Create Voucher"
				},
				{
					id: 1,
					text: "View All"
				}
			],
			insightsActions: [
				{
					id: 0,
					text: "View All"
				}
			],
			financesActions: [
				{
					id: 0,
					text: "View Finances"
				}
			],
			feedbackActions: [
				{
					id: 0,
					text: "Provide Feedback"
				}
			],
			trainingActions: [
				{
					id: 0,
					text: "Complete Training"
				}
			],
			eventsActions: [
				{
					id: 0,
					text: "Create Event"
				},
				{
					id: 1,
					text: "View All"
				}
			]
		}
	},
	computed: {
		business() {
			return this.$store.state.businessDashboardModule.business
		},
		showVouchers() {
			const hasHotel = this.$store.getters.hasHotel
			const hasDrinkingPlace = this.$store.getters.hasDrinkingPlace
			const hasCoworking = this.$store.getters.hasCoworking
			const hasAirport = this.$store.getters.hasAirport
			return hasHotel || hasDrinkingPlace || hasCoworking || hasAirport
		},
		showMealBoxes() {
			const hasHotel = this.$store.getters.hasHotel
			const hasCoworking = this.$store.getters.hasCoworking
			const hasAirport = this.$store.getters.hasAirport
			return hasHotel || hasCoworking || hasAirport
		},
		showFinance() {
			return this.$store.state.permissions.includes(
				PermissionPage.FINANCE
			)
		},
		showTraining() {
			const hasHotel = this.$store.getters.hasHotel
			const hasDrinkingPlace = this.$store.getters.hasDrinkingPlace
			const hasAirport = this.$store.getters.hasAirport
			const isStaff =
				this.$store.state.user.sub_role_id == UserSubRole.STAFF
			const requiresTraining =
				this.$store.state.user.training_complete == 0
			return (
				isStaff &&
				requiresTraining &&
				(hasHotel || hasDrinkingPlace || hasAirport)
			)
		},
		loading() {
			return this.$store.state.businessDashboardModule.loading
		},
		prepackagesActions() {
			const actions = [
				{
					id: 1,
					text: "View All"
				}
			]

			if (this.$store.getters["prepackagesModule/canCreatePrepackages"]) {
				actions.unshift({
					id: 0,
					text: "Order Catering"
				})
			}

			return actions
		}
	},
	methods: {
		handlePusherEvent(event) {
			switch (event.name) {
				case PusherEvent.ORDER_STATUS: {
					this.$store.dispatch(
						`businessDashboardModule/${ActionTypes.GET_RECENT_ORDERS}`,
						this.business.id
					)
					break
				}
			}
		},
		voucherActionSelected(action) {
			switch (action.id) {
				case 0: {
					tracker.trackEvent("Business Dashboard CTA Clicked", {
						type: "Voucher Create"
					})
					this.$refs.voucherRequestModal.open()
					break
				}
				case 1: {
					tracker.trackEvent("Business Dashboard CTA Clicked", {
						type: "Voucher View"
					})
					this.$router.push({ path: "/promotions" })
					break
				}
			}
		},
		prepackagesActionSelected(action) {
			switch (action.id) {
				case 0: {
					tracker.trackEvent("Business Dashboard CTA Clicked", {
						type: "Catering Order"
					})
					this.$refs.orderPrepackagesModal.open()
					break
				}
				case 1: {
					if (
						this.$store.getters[
							"prepackagesModule/canCreatePrepackages"
						]
					) {
						tracker.trackEvent("Business Dashboard CTA Clicked", {
							type: "Catering View"
						})
						this.$router.push({ path: "/mealboxes" })
					} else {
						tracker.trackEvent("Business Dashboard CTA Clicked", {
							type: "Catering Intro"
						})
						this.$refs.prepackagesIntroModal.open()
					}
					break
				}
			}
		},
		suppliesActionSelected(action) {
			switch (action.id) {
				case 0: {
					tracker.trackEvent("Business Dashboard CTA Clicked", {
						type: "Supplies Request"
					})
					this.$refs.requestSuppliesModal.open()
					break
				}
				case 1: {
					tracker.trackEvent("Business Dashboard CTA Clicked", {
						type: "Supplies View"
					})
					this.$router.push({ path: "/supplies" })
					break
				}
			}
		},
		trainingActionSelected(action) {
			switch (action.id) {
				case 0: {
					tracker.trackEvent("Business Dashboard CTA Clicked", {
						type: "Training Complete"
					})
					this.$refs.staffTrainingIntroModal.open()
					break
				}
			}
		},
		financesActionSelected(action) {
			switch (action.id) {
				case 0: {
					tracker.trackEvent("Business Dashboard CTA Clicked", {
						type: "Finances View"
					})
					this.$router.push({ path: "/finance" })
					break
				}
			}
		},
		eventsActionSelected(action) {
			switch (action.id) {
				case 0: {
					tracker.trackEvent("Business Dashboard CTA Clicked", {
						type: "Events Request"
					})
					this.$refs.requestEventModal.open()
					break
				}
				case 1: {
					tracker.trackEvent("Business Dashboard CTA Clicked", {
						type: "Events View"
					})
					this.$router.push({ path: "/events" })
					break
				}
			}
		},
		insightsActionSelected(action) {
			switch (action.id) {
				case 0: {
					tracker.trackEvent("Business Dashboard CTA Clicked", {
						type: "Insights View"
					})
					this.$router.push({ path: "/insights" })
					break
				}
			}
		},
		feedbackActionSelected(action) {
			switch (action.id) {
				case 0: {
					tracker.trackEvent("Business Dashboard CTA Clicked", {
						type: "Feedback Request"
					})
					this.$refs.cannyFeedbackModal.open()
					break
				}
			}
		}
	},
	mounted: function () {
		this.$store.dispatch(
			`businessDashboardModule/${ActionTypes.GET_DASHBOARD_DATA}`
		)
		this.subscribe(this.handlePusherEvent)
	},
	watch: {
		loading: function (loading) {
			this.toggleLoader(loading)
		}
	}
}
</script>