<template>
	<SkBaseModal :isOpen="isOpen" @close="close" class="sk-modal-alt">
		<div class="sk-modal-alt-title">
			<h1>
				{{ titleTxt }}
			</h1>
		</div>
		<div class="sk-row" v-if="ownsMultipleBusinesses">
			<TypeaheadInput
				ref="businessInput"
				:placeholder="'Business'"
				:fetch="autocompleteBusinesses"
				:serializer="typeaheadSerializer"
				@selected="businessSelected"
			/>
		</div>
		<transition-expand>
			<div class="sk-row" v-if="showBusinesses">
				<div class="sk-pills-container">
					<transition-group name="list-slide">
						<div
							v-for="business in businesses"
							:key="business.id"
							@click="removeBusiness(business)"
							class="list-slide-item pill pill-grey"
						>
							{{ business.name }}
							<i class="sk-icon-times-regular"></i>
						</div>
					</transition-group>
				</div>
			</div>
		</transition-expand>
		<div class="sk-row">
			<SkInput
				ref="nameInput"
				:name="'Name'"
				:required="true"
				v-model="user.display_name"
			/>
		</div>
		<div class="sk-row">
			<SkInput
				ref="emailInput"
				:name="'Email'"
				:required="true"
				:type="'string'"
				v-model="user.email"
			/>
		</div>
		<div class="sk-row">
			<SkDropdownSelect
				class="business-type-dd sk-input"
				v-model="type"
				:options="types"
			/>
		</div>
		<div class="sk-modal-actions">
			<button @click="close" class="button button-text">Close</button>
			<button
				type="button"
				class="button button-success"
				@click="saveAdminUser"
			>
				<i class="sk-icon-check-regular"></i>
				{{ btnText }}
			</button>
		</div>
	</SkBaseModal>
</template>

<style scoped>
.sk-pills-container .pill {
	max-width: 100%;
}

.sk-pills-container .pill i {
	margin-right: 0;
}
</style>

<script>
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import { UserRole, UserSubRole } from "@/enums/userRole"
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import SkInput from "@/components/SkInput.vue"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"
import TypeaheadInput from "@/components/TypeaheadInput.vue"
import TransitionExpand from "@/components/transitions/TransitionExpand.vue"

export default {
	name: "ChefUserModal",
	mixins: [skModalMixin],
	components: {
		SkBaseModal,
		SkInput,
		SkDropdownSelect,
		TypeaheadInput,
		TransitionExpand
	},
	data: function () {
		return {
			business_id: 0,
			user: {
				id: 0,
				entities: [],
				display_name: "",
				email: "",
				phone: "",
				role_id: UserRole.BUSINESS_ADMIN,
				sub_role_id: null
			},
			types: [
				{ id: null, text: "Role" },
				{ id: UserSubRole.ADMIN, text: "Admin" },
				{ id: UserSubRole.STAFF, text: "Staff" }
			]
		}
	},
	computed: {
		ownsMultipleBusinesses() {
			return this.$store.getters.ownsMultipleEntities
		},
		showBusinesses() {
			if (this.ownsMultipleBusinesses) {
				return this.businesses.length > 0
			}
			if (this.businesses.length > 1) {
				return true
			}
			return false
		},
		businesses() {
			const businessesMap = this.$store.getters.businessesMap
			return this.user.entities
				.filter(
					(entity) => entity.type == 1 || !businessesMap[entity.id]
				)
				.map((entity) => {
					return {
						id: entity.id,
						name: businessesMap[entity.id].name
					}
				})
		},
		btnText() {
			return this.user.id && this.user.id > 0 ? "Update" : "Add"
		},
		titleTxt() {
			return this.user.id && this.user.id > 0
				? "Update User"
				: "Add New User"
		},
		type: {
			get: function () {
				if (!this.user || this.user.sub_role_id == null) {
					return this.types[0]
				}
				let typeStr = ""
				if (this.user.sub_role_id == UserSubRole.ADMIN) {
					typeStr = "Admin"
				}
				if (this.user.sub_role_id == UserSubRole.STAFF) {
					typeStr = "Staff"
				}
				return {
					id: this.user.sub_role_id,
					text: typeStr
				}
			},
			set: function (newVal) {
				if (this.user && newVal != undefined) {
					this.user.sub_role_id = newVal.id
				}
			}
		}
	},
	methods: {
		closed() {
			this.user = {
				id: 0,
				role_id: UserRole.BUSINESS_ADMIN,
				sub_role_id: null,
				display_name: null,
				email: null,
				phone: "",
				entities: []
			}
			this.type = this.types[0]
		},
		handleOptions(options) {
			if (options && options.user) {
				this.user.id = options.user.id
				this.user.display_name = options.user.display_name
				this.user.email = options.user.email
				this.user.entities = options.user.entities
				this.user.role_id = options.user.role_id
				this.user.sub_role_id = options.user.sub_role_id
			} else {
				if (
					!this.ownsMultipleBusinesses &&
					this.$store.getters.business
				) {
					this.user.entities = [
						{
							id: this.$store.getters.business.id,
							type: 1
						}
					]
				}
			}
		},
		autocompleteBusinesses(query) {
			return this.$store.dispatch(
				"businessesModule/autocompleteBusinesses",
				query
			)
		},
		typeaheadSerializer(result) {
			return {
				text: result.name
			}
		},
		businessSelected(business) {
			this.user.entities.push({
				id: business.id,
				type: 1
			})
			this.$refs.businessInput.clearSearch()
		},
		removeBusiness(business) {
			this.user.entities = this.user.entities.filter(
				(entity) => entity.id != business.id
			)
		},
		validateAdminUserData() {
			const validName = this.$refs.nameInput.validate()
			const validEmail = this.$refs.emailInput.validate()

			if (this.user.entities.length == 0) {
				throw "Please select a business"
			}
			if (!validName) {
				throw "Please enter a valid name"
			}
			if (!validEmail) {
				throw "Please enter a valid email"
			}
			if (
				this.user.sub_role_id == undefined ||
				this.user.sub_role_id == null
			) {
				throw "Please select a user role"
			}
			return true
		},
		async saveAdminUser() {
			try {
				this.validateAdminUserData()
				if (this.user.id > 0) {
					const success = await this.$store.dispatch(
						"updateAdminUser",
						this.user
					)
					if (success) {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Successfully updated user details"
						})
						this.close()
					}
				} else {
					const success = await this.$store.dispatch(
						"addAdminUser",
						this.user
					)
					if (success) {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Successfully added user"
						})
						this.close()
					} else {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Something went wrong, please try again",
							type: NotificationType.WARNING
						})
					}
				}
			} catch (e) {
				this.bugsnag.notify("Unable to update details", (event) => {
					event.addMetadata("error", {
						error: e
					})
				})
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title:
						e && !e.message
							? e
							: "Something went wrong, please try again",
					type: NotificationType.WARNING
				})
			}
		}
	}
}
</script>