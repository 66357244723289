<template>
	<SkBaseModal
		:isOpen="isOpen"
		:hasLoader="true"
		@close="close"
		class="sk-modal-alt"
	>
		<loading :active.sync="loading" :is-full-page="false" />
		<div class="sk-modal-alt-title">
			<h1>Add New Hub</h1>
		</div>
		<div class="sk-row">
			<TypeaheadInput
				:placeholder="'Hub City & State'"
				:fetch="autocompleteLocation"
				:serializer="typeaheadSerializer"
				:query="locationQuery"
				@selected="locationSelected"
			/>
		</div>
		<div class="sk-row">
			<SkInput
				ref="hubNameInput"
				:name="'Hub Name'"
				:placeholder="''"
				:required="true"
				v-model="hubName"
			/>
		</div>
		<div class="sk-modal-actions">
			<button @click="close" class="button button-text">Cancel</button>
			<button type="button" class="button button-primary" @click="addHub">
				<i class="sk-icon-check-regular"></i>
				Create New Hub
			</button>
		</div>
	</SkBaseModal>
</template>

<script>
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import ActionTypes from "@/store/modules/hubs/action-types"
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import SkInput from "@/components/SkInput.vue"
import TypeaheadInput from "@/components/TypeaheadInput.vue"
import Loading from "vue-loading-overlay"
import gapi from "@/utils/gapi"

export default {
	name: "AddHubModal",
	mixins: [skModalMixin],
	components: {
		SkBaseModal,
		SkInput,
		TypeaheadInput,
		Loading
	},
	data: function () {
		return {
			loading: false,
			locationQuery: "",
			location: null,
			hubName: ""
		}
	},
	methods: {
		typeaheadSerializer(result) {
			return {
				text: result.name
			}
		},
		autocompleteLocation(query) {
			return gapi.autocompleteLocation(query, ["(regions)"])
		},
		locationSelected(location) {
			this.location = location
		},
		handleOptions(options) {
			this.location = null
			this.hubName = ""
			if (options.hub && options.hub.locations && options.hub.locations.length > 0) {
				const location = options.hub.locations[0]
				this.location = {
					id: location.external_location_id,
					city: location.city,
					state: location.state
				}
				this.locationQuery = `${location.city}, ${location.state}`
			}
		},
		validateHub() {
			if (!this.location) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Add New Hub",
					message: "Please select a hub location",
					type: NotificationType.WARNING
				})
				return false
			} else if (!this.hubName || this.hubName.length == 0) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Add New Hub",
					message: "Please enter a valid hub name",
					type: NotificationType.WARNING
				})
				return false
			}
			return true
		},
		async addHub() {
			try {
				this.loading = true
				const isValid = this.validateHub()
				if (isValid) {
					const success = await this.$store.dispatch(
						`hubsModule/${ActionTypes.ADD_HUB}`,
						{
							name: this.hubName,
							location: this.location
						}
					)
					if (success) {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Add New Hub",
							message: `${this.hubName} hub was successfully added!`,
							type: NotificationType.SUCCESS
						})
						this.close()
					} else {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Add New Hub",
							message: "Something went wrong, please try again",
							type: NotificationType.ERROR
						})
					}
				}
				this.loading = false
			} catch (e) {
				this.loading = false
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Add New Hub",
					message: "Something went wrong, please try again",
					type: NotificationType.ERROR
				})
				throw e
			}
		}
	}
}
</script>