<template>
	<div class="centered-tab-content">
		<div class="sk-row sk-widget-alt business-guest-widget">
			<div class="sk-widget-section-header">
				<div class="sk-widget-section-title">Details</div>
			</div>
			<div class="business-guest-detail">
				<div>Name</div>
				<b>{{ businessGuestName }}</b>
			</div>
			<div class="business-guest-detail">
				<div>Email</div>
				<b>{{ businessGuestEmail }}</b>
			</div>
			<div class="business-guest-detail">
				<div>Phone</div>
				<b>{{ businessGuestPhone | formatInternationalPhone }}</b>
			</div>
			<div class="business-guest-detail">
				<div>Digital Menu</div>
				<b>{{ digitalMenuSentStatus }}</b>
			</div>
		</div>
		<div class="sk-row sk-widget-alt business-guest-widget">
			<div class="sk-widget-section-header">
				<div class="sk-widget-section-title">Stay</div>
			</div>
			<div class="business-guest-checkin">
				<div class="business-guest-detail">
					<div>Check In</div>
					<b>{{ checkInDate }}</b>
				</div>
				<div class="business-guest-detail-divider">-</div>
				<div class="business-guest-detail">
					<div>Check Out</div>
					<b>{{ checkOutDate }}</b>
				</div>
			</div>
		</div>
		<div
			class="sk-row sk-widget-alt business-guest-widget"
			v-if="hasVouchers"
		>
			<div class="sk-widget-section-header">
				<div class="sk-widget-section-title">Vouchers</div>
			</div>
			<div
				v-for="voucher in vouchers"
				:key="voucher.id"
				class="business-guest-voucher"
			>
				<i class="sk-icon-ticket-alt-solid"></i>
				<b>{{ voucher.code }}</b>
				<span>{{ voucher | voucherAmount }} off</span>
				<div
					v-if="voucher.num_of_uses >= voucher.usage_limit"
					class="pill-alt pill-alt-green"
				>
					Used
				</div>
				<div v-else class="pill-alt pill-alt-baby-blue">Unused</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.sk-widget-section-title {
	margin-bottom: 0;
}

.business-guest-widget {
	flex-direction: column;
}

.business-guest-detail + .business-guest-detail {
	margin-top: 18px;
}

.business-guest-detail {
	font-size: 10px;
	color: var(--sk-grey2);
}

.business-guest-detail b {
	font-size: 12px;
	color: var(--sk-grey3);
}

.business-guest-checkin {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.business-guest-checkin .business-guest-detail + .business-guest-detail {
	margin-top: 0;
}

.business-guest-detail-divider {
	margin: 0 15px;
	color: var(--sk-grey2);
	font-weight: 600;
}

.business-guest-voucher + .business-guest-voucher {
	margin-top: 12px;
}

.business-guest-voucher {
	display: flex;
	align-items: center;
}

.business-guest-voucher > * {
	margin-right: 10px;
}

.business-guest-voucher i,
.business-guest-voucher b {
	font-size: 12px;
	color: var(--sk-grey3);
}

.business-guest-voucher span {
	font-size: 10px;
	font-style: italic;
}
</style>

<script>
import moment from "moment"
import phoneFilterMixin from "@/mixins/phone-filter-mixin"
import { BusinessGuestAmenityType } from "@/enums/businessGuestAmenityType"

export default {
	name: "BusinessGuestDetails",
	mixins: [phoneFilterMixin],
	filters: {
		voucherAmount(voucher) {
			return voucher.type == 0
				? `$${voucher.amount}`
				: `${voucher.amount}%`
		}
	},
	computed: {
		businessGuest() {
			return this.$store.state.usersModule.currentBusinessGuest
		},
		businessGuestName() {
			return this.businessGuest ? this.businessGuest.name : ""
		},
		businessGuestPhone() {
			return this.businessGuest ? this.businessGuest.phone : ""
		},
		businessGuestEmail() {
			return this.businessGuest ? this.businessGuest.email : ""
		},
		checkInDate() {
			return this.businessGuest
				? moment.utc(this.businessGuest.check_in_date).format("MMM D")
				: ""
		},
		checkOutDate() {
			return this.businessGuest
				? moment.utc(this.businessGuest.check_out_date).format("MMM D")
				: ""
		},
		digitalMenuSentStatus() {
			if (this.businessGuest) {
				const digitalMenuAmenity = this.businessGuest.amenities.find(
					(amenity) =>
						amenity.type == BusinessGuestAmenityType.DIGITAL_MENU
				)
				if (
					digitalMenuAmenity &&
					digitalMenuAmenity.attributes &&
					digitalMenuAmenity.attributes.sent &&
					digitalMenuAmenity.attributes.sent.length > 0
				) {
					return `Sent on: ${moment
						.utc(digitalMenuAmenity.attributes.sent[0])
						.format("MMM D, h:mm a")}`
				}
				return "Not Yet Sent"
			}
			return "N/A"
		},
		hasVouchers() {
			return this.vouchers && this.vouchers.length > 0
		},
		vouchers() {
			if (this.businessGuest) {
				return this.businessGuest.voucherRequests
					? this.businessGuest.voucherRequests
					: []
			}
			return []
		}
	}
}
</script>