import vueDropzone from "vue2-dropzone"
import { SIGNATURE_API } from "@/services/utils"

let imageUploadMixin = {
  components: {
    vueDropzone
  },
  props: {
    titleText: String,
    recommendedSize: {
      type: String,
      default: "150px by 150px"
    },
    entity: String,
    entity_name: String,
    previewWidth: Number,
    previewHeight: Number,
    imgSrc: String,
    signingBaseUrl: {
      type: String,
      default: SIGNATURE_API
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disabledMessage: {
      type: String,
      default: "Uploading is disabled"
    }
  },
  data() {
    return {
      fileName: "",
      previewImgSrc: null,
      dropOptions: {
        url: "https://mockbin.org/bin/2d4d1830-c332-426a-9cf0-ac8f4610ce58/view",
        maxFiles: 1,
        autoProcessQueue: true,
        thumbnailWidth: this.previewWidth,
        thumbnailHeight: this.previewHeight,
        addRemoveLinks: true,
        dictRemoveFile: "Remove",
        headers: { 'Access-Control-Allow-Origin': '*', 'x-amz-meta-Cache-Control': 'max-age=31536000' },
        renameFilename: (f) => {
          if (this.entity && this.entity.includes("finance")) {
            let nameArr = f.split('.')
            this.fileName = "w9" + "." + nameArr[nameArr.length - 1]
          } else {
            this.fileName = f.replace(/\s+/g, '-').toLowerCase()
          }
          return this.fileName
        }
      },
      awss3: {
        signingURL: () => {
          return `${this.signingBaseUrl}?filename=${this.fileName}&type=${this.entity}&name=${this.entity_name}`
        },
        params: { type: this.entity, name: this.entity_name },
        sendFileToServer: true,
        withCredentials: false
      }
    }
  },
  computed: {
    dropzoneId() {
      return `${this.entity}_${this.entity_name}`
    },
    previewImage() {
      if (this.previewImgSrc) {
        return this.previewImgSrc
      } else {
        return this.imgSrc
      }
    }
  },
  methods: {
    loading(isLoading) {
      this.$emit("loading", isLoading)
    },
    success(file) {
      this.$emit("set", file.upload.filename)
      this.$emit("previewImageChanged", file.dataURL)
      this.previewImgSrc = file.dataURL
    },
    reset() {
      this.previewImgSrc = null
      this.$emit("previewImageChanged", null)
    },
    checkDisabled(e) {
      if (this.disabled) {
        e.preventDefault()
        e.stopPropagation()
        this.$emit("error", this.disabledMessage)
      }
    }
  },
  watch: {
    entity_name(newVal) {
      this.awss3.params.name = newVal
    }
  }
}

export default imageUploadMixin