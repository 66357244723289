<template>
	<div class="sk-table-row">
		<div class="sk-table-column-fit">
			<div :title="statusLabel" :class="statusBadgeClasses">
				<span :class="statusClasses"></span>
				{{ statusLabel }}
			</div>
		</div>

		<div class="sk-table-column-fit">
			{{ user.display_name }}
		</div>
		<div class="sk-table-column-fit">
			{{ user.email }}
		</div>
		<div class="sk-table-column-fit">
			{{ phone }}
		</div>
		<div class="sk-table-column-fit">
			<div>{{ role }}</div>
		</div>
		<div class="sk-table-column-fit">
			<div>{{ subrole }}</div>
		</div>
		<div class="sk-table-pills">
			<router-link
				v-for="entity in entities"
				:key="entity.id"
				:to="entity.link"
				tag="a"
				class="pill pill-grey"
				:title="entity.name"
			>
				<i class="sk-icon-link-regular"></i>
				{{ entity.name }}
			</router-link>
		</div>

		<div class="sk-table-column-fit">
			<div class="sk-table-actions">
				<button
					class="button button-icon"
					title="Edit"
					@click="editAdminUser"
				>
					<i class="sk-icon-pencil-solid"></i>
				</button>
				<SkDropdownSelect
					:options="allowedActions"
					:iconClasses="'sk-icon-ellipsis-v-regular'"
					:showSelectedOption="false"
					:position="'left'"
					@selected="actionSelected"
				/>
			</div>
		</div>
	</div>
</template>

<style scoped>
.pill i {
	margin-right: 10px;
}
</style>

<script>
import { UserRole, UserSubRole } from "@/enums/userRole"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"
import phoneFilterMixin from "@/mixins/phone-filter-mixin"

export default {
	name: "AdminUserLineItem",
	mixins: [phoneFilterMixin],
	components: {
		SkDropdownSelect
	},
	props: {
		user: Object
	},
	computed: {
		statusBadgeClasses() {
			return this.user.is_active == 1
				? "pill-alt pill-alt-green"
				: "pill-alt pill-alt-grey"
		},
		statusLabel() {
			return this.user.is_active == 1 ? "Active" : "Inactive"
		},
		statusClasses() {
			return this.user.is_active == 1
				? "status-dot green-pulsating"
				: "status-dot grey"
		},
		role() {
			switch (this.user.role_id) {
				case UserRole.SUPER_ADMIN: {
					return "Admin"
				}
				case UserRole.BUSINESS_ADMIN: {
					return "Business Admin"
				}
				case UserRole.RESAURANT_ADMIN: {
					return "Restaurant Admin"
				}
			}
			return "N/A"
		},
		subrole() {
			switch (this.user.sub_role_id) {
				case UserSubRole.ADMIN: {
					return "Admin"
				}
				case UserSubRole.STAFF: {
					return "Staff"
				}
			}
			return "N/A"
		},
		phone() {
			if (this.user.phone && this.user.phone != "") {
				let code = this.user.phone.startsWith("+") ? null : `+1`
				return this.$options.filters.formatInternationalPhone(
					this.user.phone,
					code
				)
			} else {
				return "N/A"
			}
		},
		entities() {
			var retVal = []
			if (this.user.role_id == 1 && this.user.entities.length == 0) {
				retVal.push({ name: "All" })
			} else {
				for (var i = 0; i < this.user.entities.length; i++) {
					const entity = this.user.entities[i]
					switch (entity.type) {
						case 1: {
							const business =
								this.$store.getters.businessesMap[entity.id]
							if (business && business.name) {
								const link = "/business/" + business.slug
								retVal.push({
									id: business.id,
									name: business.name,
									link: link
								})
							}
							break
						}
						case 2: {
							const restaurant =
								this.$store.state.restaurants[entity.id]
							if (restaurant && restaurant.name) {
								const link = "/restaurant/" + restaurant.id
								retVal.push({
									id: restaurant.id,
									name: restaurant.name,
									link: link
								})
							}
							break
						}
					}
				}
			}
			return retVal
		},
		allowedActions() {
			let actions = []
			actions.push({
				text: `<i class="sk-icon-check-regular"></i> Resend Activation Email`,
				id: 0
			})
			actions.push({
				text: `<i class="sk-icon-envelope-solid"></i> Send Password Reset Email`,
				id: 2
			})
			actions.push({
				text: `<i class="sk-icon-trash-alt-solid"></i> Delete`,
				id: 1
			})
			return actions
		}
	},
	methods: {
		editAdminUser() {
			this.$emit("editAdminUserClicked", this.user)
		},
		async actionSelected(action) {
			switch (action.id) {
				case 0:
					this.$emit("resendActivationLinkClicked", this.user)
					break
				case 1:
					this.$emit("deleteAdminUserClicked", this.user)
					break
				case 2:
					this.$emit("resendForgotPasswordEmail", this.user)
					break
			}
		}
	}
}
</script>