<template>
	<img :src="integrationLogoSrc" alt="Integration Logo" />
</template>

<script>
import { IntegrationType } from "@/enums/integrationType"

export default {
	name: "IntegrationLogo",
	props: {
		integrationType: Number
	},
	computed: {
		integrationLogoSrc() {
			if (this.integrationType) {
				switch (this.integrationType) {
					case IntegrationType.UNTAPPD:
						return "/images/untappd-logo.png"
					case IntegrationType.SQUARE:
						return "/images/square-logo.png"
					case IntegrationType.CHOWLY:
						return "/images/chowly-logo.png"
					case IntegrationType.CLOVER:
						return "/images/clover-logo.png"
					case IntegrationType.TOAST:
						return "/images/toast-logo.png"
					case IntegrationType.OTTER:
						return "/images/otter-logo.png"
					case IntegrationType.ORDERMARK:
						return "/images/ordermark-logo.png"
					case IntegrationType.REEF:
						return "/images/reef-logo.png"
					case IntegrationType.GRUBHUB:
						return "/images/grubhub-logo.png"
				}
			}
			return ""
		}
	}
}
</script>