<template>
	<div class="full-height-table-wrapper">
		<div class="sk-table sk-table-default">
			<div class="sk-table-header">
				<div class="sk-table-row">
					<div>
						<div>Name</div>
						<div class="column-subtitle">Business</div>
					</div>
					<div>Value</div>
					<div>
						<div>Availability</div>
						<div class="column-subtitle">Uses</div>
					</div>
					<div>
						<div>Distribution</div>
						<div class="column-subtitle">Payment</div>
					</div>
					<div>Guests</div>
					<div>Issuer</div>
					<div>Status</div>
					<div>Actions</div>
				</div>
			</div>
			<transition-group
				name="list"
				tag="div"
				class="sk-table-body"
				appear
			>
				<VoucherRequestLineItem
					v-for="voucherRequest in voucherRequests"
					:voucherRequest="voucherRequest"
					@deleteVoucherRequest="deleteVoucherRequest"
					@updateVoucherRequest="openUpdateVoucherRequestModal"
					@updateVoucherRequestStatus="updateVoucherRequestStatus"
					@viewVoucherRequest="viewVoucherRequest"
					:key="voucherRequest.id"
				/>
			</transition-group>
		</div>
		<PaginationFooter
			:contentText="'Voucher Orders'"
			:pageSize="20"
			:total="totalVoucherRequestsCount"
			ref="paginationFooter"
			@pageChanged="pageChanged"
		/>
		<ConfirmationModal ref="confirmationModal"></ConfirmationModal>
		<VoucherRequestModal ref="updateVoucherRequestModal" />
	</div>
</template>

<script>
import ActionTypes from "@/store/modules/coupons/action-types"
import PageHeader from "@/components/PageHeader.vue"
import PaginationFooter from "@/components/PaginationFooter.vue"
import VoucherRequestLineItem from "@/components/coupon/VoucherRequestLineItem.vue"
import loaderMixin from "@/mixins/loader-mixin"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue"
import { ConfirmationModalType } from "@/enums/confirmationModalType"
import VoucherRequestModal from "@/components/modals/VoucherRequestModal.vue"

export default {
	name: "VoucherRequests",
	mixins: [loaderMixin],
	components: {
		PageHeader,
		VoucherRequestLineItem,
		PaginationFooter,
		ConfirmationModal,
		VoucherRequestModal
	},
	props: {
		filters: Object
	},
	data: function () {
		return {
			pageRange: {
				page: 1,
				start: 0,
				end: 20
			}
		}
	},
	computed: {
		voucherRequests() {
			return this.$store.state.couponsModule.voucherRequests
		},
		totalVoucherRequestsCount() {
			return this.$store.state.couponsModule.totalVoucherRequestsCount
		},
	},
	mounted: function () {
		this.getVoucherRequests()
	},
	methods: {
		pageChanged(range) {
			this.pageRange = range
			this.getVoucherRequests()
		},
		getVoucherRequests() {
			this.toggleLoader(true)
			this.$store
				.dispatch(`couponsModule/${ActionTypes.GET_VOUCHER_REQUESTS}`, {
					businessId: this.filters.businessId,
					name: this.filters.name,
					issuedBy: this.filters.issuedBy,
					distributionType: this.filters.distributionType,
					paymentType: this.filters.paymentType,
					status: this.filters.voucherRequestStatus,
					pageSize: 20,
					pageStart: this.pageRange.start,
					used: this.filters.used
				})
				.finally(() => {
					this.toggleLoader(false)
				})
		},
		refreshVoucherRequests() {
			if (this.pageRange.page != 1) {
				this.$refs.paginationFooter.pageChanged(1)
			} else {
				this.getVoucherRequests()
			}
		},
		viewVoucherRequest(voucherRequest) {
			this.$router.push({ path: `/voucher_request/${voucherRequest.id}` })
		},
		openUpdateVoucherRequestModal(voucherRequest) {
			this.$refs.updateVoucherRequestModal.open(voucherRequest)
		},
		async updateVoucherRequestStatus(voucherRequest, status) {
			try {
				let title = `Approve ${voucherRequest.name} ?`
				let body = "This will create all the requested vouchers"
				let cancel = "Cancel"
				let confirmation = "Approve Voucher Request"
				let confirmationIcon = "sk-icon-check-regular"
				let confirmationType = ConfirmationModalType.GREEN
				const confirmed = await this.$refs.confirmationModal.open({
					title: title,
					body: body,
					cancel: cancel,
					confirmation: confirmation,
					confirmationIcon: confirmationIcon,
					type: confirmationType
				})
				if (confirmed) {
					const payload = { id: voucherRequest.id, status: status }
					this.$store.dispatch(
						`couponsModule/${ActionTypes.UPDATE_VOUCHER_REQUEST_STATUS}`,
						payload
					)
				}
				return confirmed
			} catch (e) {
				throw e
			}
		},
		async deleteVoucherRequest(voucherRequest) {
			try {
				let title = `Delete ${voucherRequest.name} ?`
				let body = "This will remove all unused vouchers"
				let cancel = "Keep Voucher Request"
				let confirmation = "Delete Voucher Request"
				let confirmationIcon = "sk-icon-times-regular"
				let confirmationType = ConfirmationModalType.RED
				const confirmed = await this.$refs.confirmationModal.open({
					title: title,
					body: body,
					cancel: cancel,
					confirmation: confirmation,
					confirmationIcon: confirmationIcon,
					type: confirmationType
				})
				if (confirmed) {
					this.$store.dispatch(
						`couponsModule/${ActionTypes.DELETE_VOUCHER_REQUEST}`,
						voucherRequest.id
					)
				}
				return confirmed
			} catch (e) {
				throw e
			}
		}
	},
	watch: {
		filters: {
			deep: true,
			handler() {
				this.refreshVoucherRequests()
			}
		}
	}
}
</script>