<template>
	<div class="sk-row">
		<div class="sk-widget-alt">
			<div class="sk-row order-summary" @click="toggleOrderDetails()">
				<div>
					<div :class="statusPillClass">
						{{ statusText }}
					</div>
					<div class="order-identifier">{{ order.id }}</div>
					<div class="pill pill-navy">
						{{ order.menu_type | menuTypeText }}
					</div>
					<div class="pill pill-navy" v-if="isPreOrder">Preorder</div>
					<div
						v-if="isInRoomBilling && paymentStatus != null"
						:class="inRoomBillingStatusBadgeClasses"
					>
						<i :class="inRoomBillingStatusIcon"></i>
						<b>Charge to Room:</b>
						<i>{{ inRoomBillingStatusText }}</i>
					</div>
				</div>
				<SkDropdownSelect
					v-if="actions.length > 0"
					:options="actions"
					:iconClasses="'sk-icon-ellipsis-v-regular'"
					:showSelectedOption="false"
					:position="'left'"
					@selected="actionSelected"
				/>
			</div>
			<transition-expand>
				<div v-if="isExpanded">
					<ul class="order-tabs sk-page-tabs">
						<li
							:class="{ 'active-tab': tab.active }"
							v-for="tab in orderDetailTabs"
							:key="tab.id"
							@click="orderDetailTabClicked(tab)"
						>
							{{ tab.text }}
							<span v-if="tab.badgeCount" class="tab-badge">
								{{ tab.badgeCount }}
							</span>
						</li>
					</ul>
					<transition name="fade" mode="out-in">
						<div v-if="activeDetailsTab.id == 0">
							<div
								v-for="(suborder, index) in order.sub_orders"
								:key="index"
							>
								<SuborderHeader
									:order="order"
									:suborder="suborder"
									:business="business"
									:restaurantId="suborder.restaurant.id"
									:expandedSuborder="expandedSuborder"
									:index="index"
									@toggleExpand="expand(suborder)"
								/>
								<transition-expand>
									<OrderReportStatus
										v-if="
											(expandedSuborder &&
												suborder.id ==
													expandedSuborder.id) ||
											order.sub_orders.length == 1
										"
										:item="suborder"
										:order="order"
									/>
								</transition-expand>
							</div>
						</div>
						<div v-if="activeDetailsTab.id == 1">
							<div
								v-for="(suborder, index) in order.sub_orders"
								:key="index"
							>
								<SuborderHeader
									:order="order"
									:suborder="suborder"
									:business="business"
									:restaurantId="suborder.restaurant.id"
									:expandedSuborder="expandedSuborder"
									:index="index"
									@toggleExpand="expand(suborder)"
								/>
								<transition-expand>
									<OrderProducts
										v-if="
											(expandedSuborder &&
												suborder.id ==
													expandedSuborder.id) ||
											order.sub_orders.length == 1
										"
										:item="suborder"
										:order="order"
										:business="business"
										:type="1"
									/>
								</transition-expand>
							</div>
						</div>
						<div v-if="activeDetailsTab.id == 2">
							<OrderActions
								:order="order"
								:orderActions="actions"
								@actionSelected="actionSelected"
							/>
						</div>
						<div v-if="activeDetailsTab.id == 3">
							<div
								v-for="(suborder, index) in suborderWarnings"
								:key="index"
							>
								<SuborderHeader
									:order="order"
									:suborder="suborder.suborder"
									:business="business"
									:restaurantId="
										suborder.suborder.restaurant.id
									"
									:expandedSuborder="expandedSuborder"
									:index="index"
									@toggleExpand="expand(suborder)"
								/>
								<transition-expand>
									<OrderWarnings
										:warnings="suborder.warnings"
									/>
								</transition-expand>
							</div>
						</div>
						<div v-if="activeDetailsTab.id == 4">
							<OrderNotes :notes="notes" />
						</div>
					</transition>
				</div>
			</transition-expand>
		</div>
	</div>
</template>

<style scoped>
.sk-widget-alt {
	overflow: visible;
}

.order-summary .pill-alt b {
	margin-right: 2px;
}

.order-summary .pill-alt i {
	margin-right: 10px;
}
</style>

<script>
import moment from "moment"
import { PermissionMethod, PermissionTab } from "@/utils/permissions"
import { CourierId } from "@arikgaisler/utils/enums/courierId"
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import orderMixin from "@/mixins/order-mixin"
import orderComponentMixin from "@/mixins/order-component-mixin"
import menuTypeFormatterMixin from "@/mixins/menu-type-formatter-mixin"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"
import TransitionExpand from "@/components/transitions/TransitionExpand.vue"
import SuborderHeader from "@/components/orders/SuborderHeader.vue"
import OrderReportStatus from "@/components/orderreport/OrderReportStatus.vue"
import OrderProducts from "@/components/orders/OrderProducts.vue"
import OrderActions from "@/components/orders/OrderActions.vue"
import OrderWarnings from "@/components/orders/OrderWarnings.vue"
import OrderNotes from "@/components/orders/OrderNotes.vue"

export default {
	name: "OrderReport",
	mixins: [orderMixin, orderComponentMixin, menuTypeFormatterMixin],
	components: {
		SkDropdownSelect,
		TransitionExpand,
		SuborderHeader,
		OrderReportStatus,
		OrderProducts,
		OrderActions,
		OrderWarnings,
		OrderNotes
	},
	props: {
		order: Object
	},
	computed: {
		orderTime() {
			const isWalking = this.order.sub_orders.some(
				(suborder) => suborder.courier_id == CourierId.WALKING
			)
			const timeKey = isWalking ? "completed_time" : "delivered_time"
			const hasTime = this.order.sub_orders.some(
				(suborder) => suborder[timeKey] && suborder[timeKey] != ""
			)
			if (hasTime) {
				const startTime = moment.utc(this.orderStartTime)
				if (this.orderEndTime) {
					return this.orderEndTime.from(startTime, true)
				}
			}
			return "N/A"
		},
		businessId() {
			return this.order ? this.order.business.id : null
		},
		isDelivered() {
			return (
				this.order &&
				this.order.sub_orders &&
				this.order.sub_orders.length > 0 &&
				this.order.sub_orders.every(
					(suborder) =>
						suborder.delivered_time != null &&
						suborder.delivered_time != undefined
				)
			)
		},
		isCancelled() {
			return (
				this.order &&
				this.order.sub_orders &&
				this.order.sub_orders.length > 0 &&
				this.order.sub_orders.every((suborder) => suborder.status == 4)
			)
		},
		statusPillClass() {
			if (this.isDelivered) {
				return "pill-alt pill-alt-green"
			} else if (this.isCancelled) {
				return "pill-alt pill-alt-red"
			}
			return "pill-alt pill-alt-grey"
		},
		statusText() {
			if (this.isDelivered) {
				return `Completed - ${this.orderTime}`
			} else if (this.isCancelled) {
				return "Cancelled - N/A"
			}
			return "Pending - N/A"
		},
		notes() {
			let notes = []
			if (this.order.notes && this.order.notes.length > 0) {
				this.order.notes.forEach((note) => {
					const userDisplayName = note.created_by
						? note.created_by.display_name
						: "N/A"
					notes.push({
						name: userDisplayName,
						description: note.text,
						iconClass: "sk-icon-receipt-solid"
					})
				})
			}
			return notes
		},
		actions() {
			const actions = []

			if (
				this.$store.state.permissions.includes(
					PermissionMethod.SEND_RECEIPT
				)
			) {
				actions.push({ id: 0, text: "Send Receipt" })
			}
			if (
				this.$store.state.permissions.includes(
					PermissionMethod.MARK_EXCEPTION
				)
			) {
				actions.push({ id: 1, text: "Mark Exception" })
			}
			if (
				this.$store.state.permissions.includes(
					PermissionMethod.ISSUE_REFUND
				)
			) {
				actions.push({ id: 2, text: "Issue Refund" })
			}
			if (
				this.$store.state.permissions.includes(
					PermissionMethod.CREATE_DELIVERY
				)
			) {
				actions.push({ id: 3, text: "Create Delivery" })
			}

			if (
				this.$store.state.permissions.includes(
					PermissionMethod.NOTIFY_GUEST
				)
			) {
				const notifyGuestText = this.delivered
					? "Re-Notify Guest"
					: "Notify Guest"
				actions.push({
					id: 4,
					text: notifyGuestText
				})
			}

			if (this.isInRoomBilling) {
				if (this.canDisputeCharge) {
					actions.push({
						id: 5,
						text: "Dispute Charge"
					})
				}
				if (this.canMarkPosted) {
					actions.push({
						id: 6,
						text: "Mark Posted",
						highlight: true
					})
				}
			}

			if (
				this.$store.state.permissions.includes(
					PermissionMethod.REPUSH_ORDER
				)
			) {
				actions.push({
					id: 7,
					text: "Repush Order"
				})
			}

			return actions
		},
		orderDetailTabs() {
			const tabs = [
				{
					id: 0,
					text: "Status"
				},
				{
					id: 1,
					text: "Details"
				}
			]

			if (
				this.$store.state.permissions.includes(
					PermissionTab.ORDER_ACTIONS
				)
			) {
				tabs.push({
					id: 2,
					text: "Actions"
				})
			}

			if (
				this.$store.state.permissions.includes(
					PermissionTab.ORDER_WARNINGS
				)
			) {
				tabs.push({
					id: 3,
					text: "Warnings",
					badgeCount:
						this.order.warnings && this.order.warnings.length
				})
			}

			if (
				this.$store.state.permissions.includes(
					PermissionTab.ORDER_NOTES
				)
			) {
				tabs.push({
					id: 4,
					text: "Notes",
					badgeCount: this.order.notes && this.order.notes.length
				})
			}

			tabs.forEach((tab) => (tab.active = tab.id == this.activeTabId))

			return tabs
		},
		activeDetailsTab() {
			return this.orderDetailTabs.find((tab) => tab.active)
		}
	},
	methods: {
		actionSelected(action) {
			switch (action.id) {
				case 0: {
					this.$emit("openReceipt", this.order)
					break
				}
				case 1: {
					this.$emit("openException", this.order)
					break
				}
				case 2: {
					this.$emit("openRefund", this.order)
					break
				}
				case 3: {
					this.$emit("openDelivery", this.order)
					break
				}
				case 4: {
					this.notifyGuest()
					break
				}
				case 5: {
					this.$emit("disputeCharge", this.order)
					break
				}
				case 6: {
					this.updateOrderTransactionStatus(
						1,
						"Successfully marked the order as posted",
						"Failed to mark the order as posted, please try again"
					)
					break
				}
				case 7: {
					this.$emit("repushOrder", this.order)
					break
				}
			}
		},
		orderDetailTabClicked(tab) {
			this.activeTabId = tab.id
		},
		openNotes() {
			this.$emit("openNotes", this.order)
		},
		notifyGuest() {
			const suborder =
				this.order.sub_orders.length > 0
					? this.order.sub_orders[0]
					: null

			this.$store
				.dispatch("ordersModule/notifyGuestOrderDelivered", {
					id: suborder.id,
					orderId: this.order.id
				})
				.then((success) => {
					if (success) {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Guest notified of order delivery"
						})
					} else {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Failed to notify guest, please try again",
							type: NotificationType.ERROR
						})
					}
				})
				.catch(() => {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Failed to notify guest, please try again",
						type: NotificationType.ERROR
					})
				})
		}
	}
}
</script>