import ActionTypes from "@/store/modules/coupons/action-types"
import { NotificationType } from "@/enums/notificationType"
import { eventBus, EventBusEvents } from "@/utils/eventBus"

let voucherActionsMixin = {
	computed: {
	},
	methods: {
		async printVoucher(voucherId, businessId) {
			const payload = { ids: [voucherId], businessId: businessId }
			this.toggleLoader(true)
			const url = await this.$store.dispatch(
				`couponsModule/${ActionTypes.PRINT_VOUCHER}`,
				payload
			)
			if (url && url != null && url != "") {
				window.open(url, "_blank")
			}
			else {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Unable to generate voucher file. Please try again or contact support.",
						type: NotificationType.ERROR
					})
			}
			this.toggleLoader(false)
		},
		async sendVoucherEmail(voucher) {
			this.toggleLoader(true)
			this.$refs.sendVoucherEmailModal.open({
				voucher: voucher
			})
			this.toggleLoader(false)
		},
	}
}

export default voucherActionsMixin