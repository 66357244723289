<template>
	<div class="password-row">
		<button @click="showPasswordClicked" class="button button-icon">
			<i :class="passwordInputIcon"></i>
		</button>
		<SkInput
			ref="passwordInput"
			v-model="model"
			:name="name"
			:placeholder="placeholder"
			:type="passwordInputType"
			:required="true"
			:tabindex="tabindex"
			@onEnter="onEnter()"
		/>
	</div>
</template>

<style scoped>
.password-row {
	position: relative;
}

.password-row .button-icon {
	position: absolute;
	right: 5px;
	top: 6px;
	z-index: 100;
	margin-right: 0 !important;
}
</style>

<script>
import SkInput from "@/components/SkInput.vue"
import tracker from "@/utils/tracker"

export default {
	name: "SkPasswordInput",
	components: {
		SkInput
	},
	props: {
		value: [String, Number],
		name: {
			type: String,
			default: "Password"
		},
		placeholder: {
			type: String,
			default: "Password"
		},
		tabindex: {
			type: Number,
			default: 0
		}
	},
	data: function () {
		return {
			passwordInputType: "password"
		}
	},
	computed: {
		passwordInputIcon() {
			return this.passwordInputType == "password"
				? "sk-icon-eye-solid"
				: "sk-icon-eye-slash-solid"
		},
		model: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit("input", val)
			}
		}
	},
	methods: {
		showPasswordClicked() {
			tracker.trackEvent("Show Password Clicked")
			this.passwordInputType =
				this.passwordInputType == "password" ? "text" : "password"
		},
		onEnter() {
			this.$emit("onEnter")
		},
		validate() {
			return this.$refs.passwordInput.validate()
		}
	}
}
</script>