<template>
	<div>
		<div class="sk-table sk-table-default">
			<div class="sk-table-header">
				<div class="sk-table-row">
					<div>Action</div>
					<div>Reason</div>
					<div>Status</div>
					<div>Amount</div>
				</div>
			</div>
			<div
				class="sk-table-row sk-widget"
				v-for="(action, index) in actions"
				:key="index"
			>
				<div v-if="action.type == actionTypes.COUPON">
					Coupon {{ action.name }}
				</div>
				<div v-else>
					{{ action.name }}
				</div>
				<div>
					{{ action.reason }}
				</div>
				<div>
					{{ action.type | statusLabel(action.status) }}
				</div>
				<div>
					{{ action.amount | currency }}
				</div>
			</div>
		</div>
		<SkDropdownSelect
			v-if="orderActions && orderActions.length > 0"
			:options="availableActions"
			:iconClasses="'sk-icon-life-ring-regular'"
			:classes="'button button-primary button-take-action'"
			:position="'left'"
			@selected="actionSelected"
		/>
	</div>
</template>

<style>
.button.button-primary.button-take-action {
	width: 118px;
	min-height: 35px;
	font-size: 10px;
	margin-left: auto;
	padding: 0 15px;
}

.button.button-primary.button-take-action .selected {
	font-size: 12px;
	color: var(--sk-white);
}

.button.button-primary.button-take-action i {
	margin-right: 0;
}
</style>

<script>
import { OrderActionType } from "@/enums/orderActionType"
import { PermissionInfo } from "@/utils/permissions"
import OrderAction from "@/models/OrderAction"
import currencyFilterMixin from "@/mixins/currency-filter-mixin"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"

export default {
	name: "OrderActions",
	mixins: [currencyFilterMixin],
	components: {
		SkDropdownSelect
	},
	filters: {
		statusLabel: function(type, status) {
			if (
				type == undefined ||
				type == null ||
				status == undefined ||
				status == null
			) {
				return ""
			}

			switch (type) {
				case OrderActionType.COUPON:
					return "ISSUED"
				case OrderActionType.EXCEPTION:
				case OrderActionType.REFUND: {
					switch (status) {
						case 0:
							return "PENDING"
						case 1:
							return "APPROVED"
						case 2:
							return "REJECTED"
					}
				}
			}
		}
	},
	props: {
		order: Object,
		orderActions: Array
	},
	data: function() {
		return {
			actionTypes: OrderActionType
		}
	},
	computed: {
		actions() {
			let actions = this.order.actions ? this.order.actions : []
			const notesAllowed = this.$store.state.permissions.includes(PermissionInfo.ORDER_NOTES)
			actions = actions.filter(action => {
				switch (action.type) {
					case OrderActionType.REFUND:
						return this.$store.state.permissions.includes(PermissionInfo.ORDER_REFUNDS)
					case OrderActionType.COUPON:
						return this.$store.state.permissions.includes(PermissionInfo.ORDER_COUPONS)
					case OrderActionType.EXCEPTION:
						return this.$store.state.permissions.includes(PermissionInfo.ORDER_EXCEPTIONS)
					case OrderActionType.NOTE:
						return notesAllowed
				}
			})

			if (this.order.survey_sent == 1 && notesAllowed) {
				actions.push(new OrderAction({
                    type: OrderActionType.NOTE, 
                    name: "Survey", 
                    reason: "Survey Sent",
                    status: "Sent"
                }))
			}

			return actions
		},
		availableActions() {
			const actions = this.orderActions ? this.orderActions.slice() : []
			actions.unshift({
				id: null,
				text: "Take Action"
			})
			return actions
		}
	},
	methods: {
		actionSelected(action) {
			this.$emit("actionSelected", action)
		}
	}
}
</script>