<template>
	<div class="sk-table-row">
		<div
			class="sk-table-column-checkbox"
			@click="batchSelectClicked($event)"
		>
			<SkCheckbox class="sk-checkbox-green" :checked="batchSelected" />
		</div>
		<div class="sk-table-column-fit">
			{{ payout_period }}
		</div>
		<div class="sk-table-column-fit">
			{{ payout.restaurant.name }}
		</div>
		<div>{{ fundingSource }}</div>
		<div>{{ w9Uploaded }}</div>
		<div>{{ payout.orders_count }}</div>
		<div>${{ salesNet.toFixed(2) }}</div>
		<div>${{ taxNet.toFixed(2) }}</div>
		<div>${{ payout.commission.toFixed(2) }}</div>
		<div>
			{{ feesPercentage.toFixed(2) }}
		</div>
		<div>${{ payout.tips.toFixed(2) }}</div>
		<div>${{ payout.exceptions.toFixed(2) }}</div>
		<div>${{ payout.amount.toFixed(2) }}</div>
		<div>
			<span :class="statusClasses"></span>
			{{ statusLabel }}
		</div>
		<div class="sk-table-column-fit">
			<div class="sk-table-actions">
				<button
					class="button button-icon"
					title="Approve"
					@click="approvePayout"
				>
					<i class="sk-icon-check-regular"></i>
				</button>
				<SkDropdownSelect
					:options="allowedActions"
					:iconClasses="'sk-icon-ellipsis-v-regular'"
					:showSelectedOption="false"
					:position="'left'"
					@selected="actionSelected"
				/>
			</div>
		</div>
	</div>
</template>

<style scoped>
.pill i {
	margin-right: 10px;
}
.sk-table-body .sk-table-row > .menu-schedules {
	white-space: normal;
}
.menu-schedules {
	padding: 20px 0;
}
.menu-schedules > div {
	display: inline-block;
	margin-right: 20px;
}
</style>

<script>
import moment from "moment"
import { FundingSource } from "@/enums/fundingSource"
import { PayoutStatus } from "@/enums/payoutStatus"
import exportPayoutBreakdownMixin from "@/mixins/finance/export-payout-breakdown-mixin"
import batchSelectableMixin from "@/mixins/batch-selectable-mixin"
import SkCheckbox from "@/components/SkCheckbox.vue"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"

export default {
	name: "RestaurantPayoutLineItem",
	mixins: [batchSelectableMixin, exportPayoutBreakdownMixin],
	components: {
		SkCheckbox,
		SkDropdownSelect
	},
	props: {
		payout: Object
	},
	computed: {
		payoutStatusClass() {
			return "status-dot yellow"
		},
		payoutStatusText() {
			return "Pending"
		},
		payout_period() {
			return (
				moment.utc(this.payout.from_date).format("MMM D") +
				" - " +
				moment.utc(this.payout.to_date).format("MMM D")
			)
		},
		fundingSource() {
			var retVal = "N/A"
			if (
				this.payout.funding_source != undefined &&
				this.payout.funding_source.type != undefined &&
				this.payout.funding_source.type.id == FundingSource.ACH
			)
				retVal = "ACH"
			return retVal
		},
		feesPercentage() {
			return (this.payout.commission / this.payout.cogs) * 100
		},
		fileName() {
			return (
				this.payout.restaurant.name + "_" + this.payout_period + ".xlsx"
			)
		},
		taxNet() {
			return this.payout.cogs_tax - this.exceptionsTax > 0
				? this.payout.cogs_tax - this.exceptionsTax
				: 0
		},
		cogsTaxPer() {
			return (this.payout.cogs_tax / this.payout.cogs) * 100
		},
		exceptionsPer() {
			return this.payout.exceptions / (1 + this.cogsTaxPer / 100)
		},
		exceptionsTax() {
			return this.payout.exceptions - this.exceptionsPer
		},
		salesNet() {
			return this.payout.cogs - this.exceptionsPer > 0
				? this.payout.cogs - this.exceptionsPer
				: 0
		},
		restaurant() {
			return this.$store.state.restaurants[this.payout.restaurant.id]
		},
		w9Uploaded() {
			return this.restaurant && this.restaurant.w9_uploaded == 1 ? "Y" : "N"
		},
		allowedActions() {
			let actions = []
			actions.push({
				text: `<i class="sk-icon-arrow-to-bottom-regular"></i> Download`,
				id: 0
			})
			actions.push({
				text: `<i class="sk-icon-pencil-solid"></i> Adjust`,
				id: 1
			})
			actions.push({
				text: `<i class="sk-icon-times-regular"></i> Clear`,
				id: 2
			})
			return actions
		},
		statusClasses() {
			switch (this.payout.status.value) {
				case PayoutStatus.PENDING:
					return "status-dot yellow"
				case PayoutStatus.APPROVED:
					return "status-dot green"
				case PayoutStatus.PROCESSING:
					return "status-dot green-pulsating"
				case PayoutStatus.COMPLETED:
					return "status-dot navy"
				case PayoutStatus.FAILED:
					return "status-dot red"
			}
			return ""
		},
		statusLabel() {
			switch (this.payout.status.value) {
				case PayoutStatus.PENDING:
					return "Pending"
				case PayoutStatus.APPROVED:
					return "Approved"
				case PayoutStatus.PROCESSING:
					return "Processing"
				case PayoutStatus.COMPLETED:
					return "Completed"
				case PayoutStatus.FAILED:
					return "Failed"
			}
			return "N/A"
		}
	},
	methods: {
		approvePayout() {
			let payload = { payout: this.payout, approve: true }
			this.$emit("approvePayout", payload)
		},
		actionSelected(action) {
			switch (action.id) {
				case 0:
					this.downloadBreakdown()
					break
				case 1:
					this.$emit("adjustPayoutAmount", {
						payout: this.payout
					})
					break
				case 2:
					this.$emit("clearPayout", {
						payout: this.payout
					})
					break
			}
		}
	}
}
</script>