<template>
	<div class="business-menus">
		<slot v-if="pagedRefunds.length > 0">
			<div class="sk-table sk-table-default">
				<div class="sk-table-header">
					<div class="sk-table-row">
						<div
							class="sk-table-column-checkbox"
							@click="batchSelectAllEntitiesClicked($event)"
						>
							<SkCheckbox
								class="sk-checkbox-green"
								:checked="allBatchableEntitiesSelected"
							/>
						</div>
						<div>Status</div>
						<div>Order #</div>
						<div>Date/Time</div>
						<div>Customer</div>
						<div>Refund</div>
						<div>Reason</div>
						<div>Issuer</div>
						<div>Actions</div>
					</div>
				</div>
				<transition-group
					name="list"
					tag="div"
					class="sk-table-body"
					appear
				>
					<RefundLineItem
						v-for="refund in pagedRefunds"
						:key="refund.id"
						:refund="refund"
						:batchSelectId="refund.id"
						:batchSelectedIds="batchSelectedIds"
						@batchSelected="batchSelectEntity"
						@setRefundStatus="setRefundStatus"
						@openOrderDetailsModal="openOrderDetailsModal"
						@editRefund="editRefund"
					/>
				</transition-group>
			</div>
			<PaginationFooter
				:contentText="'Refunds'"
				:pageSize="20"
				:total="refunds.length"
				@pageChanged="filterPage"
			/>
		</slot>
		<EmptyPlaceholderWidget
			v-else
			:title="`No Exceptions`"
			:description="`No exceptions. Woohoo!`"
		/>
		<ConfirmationModal ref="refundConfirmationModal" />
		<OrderDetailsModal ref="orderDetailsModal" />
		<ApproveRefundModal ref="approveRefundModal" />
		<RefundModal ref="refundModal" />
	</div>
</template>

<style scoped>
.business-menus {
	height: 100%;
	display: flex;
	flex-direction: column;
}
</style>

<script>
import { ConfirmationModalType } from "@/enums/confirmationModalType"
import moment from "moment"
import batchSelectMixin from "@/mixins/batch-select-mixin"
import RefundLineItem from "@/components/finance/RefundLineItem.vue"
import PaginationFooter from "@/components/PaginationFooter.vue"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue"
import EmptyPlaceholderWidget from "@/components/EmptyPlaceholderWidget.vue"
import SkCheckbox from "@/components/SkCheckbox.vue"
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import OrderDetailsModal from "@/components/modals/OrderDetailsModal.vue"
import ApproveRefundModal from "@/components/modals/approveRefund/ApproveRefundModal.vue"
import RefundModal from "@/components/modals/RefundModal.vue"

export default {
	name: "Refunds",
	mixins: [batchSelectMixin],
	components: {
		RefundLineItem,
		PaginationFooter,
		ConfirmationModal,
		EmptyPlaceholderWidget,
		SkCheckbox,
		OrderDetailsModal,
		ApproveRefundModal,
		RefundModal
	},
	props: {
		selectedIds: {
			type: Array
		},
		dateFrom: {
			type: String,
			default: ""
		},
		dateTo: {
			type: String,
			default: ""
		}
	},
	data: function () {
		return {
			pageRange: {
				page: 1,
				start: 0,
				end: 20
			}
		}
	},
	computed: {
		batchableEntityIds() {
			return this.pagedRefunds.map((refund) => refund.id)
		},
		refunds() {
			var retVal = this.$store.state.refunds
			if (this.dateFrom != null && this.dateFrom != "") {
				if (this.dateTo != null && this.dateTo != "") {
					retVal = retVal.filter(
						(refund) =>
							moment(refund.created_date).isSameOrAfter(
								this.dateFrom,
								"days"
							) &&
							moment(refund.created_date).isSameOrBefore(
								this.dateTo,
								"days"
							)
					)
				} else {
					retVal = retVal.filter((refund) =>
						moment(refund.created_date).isSame(
							this.dateFrom,
							"days"
						)
					)
				}
			}
			return retVal
		},
		pagedRefunds() {
			return this.refunds.slice(
				this.pageRange.start,
				this.pageRange.end
			)
		}
	},
	methods: {
		filterPage(range) {
			this.pageRange = range
		},
		async setRefundStatus(payload) {
			let refund = payload.refund
			let approve = payload.approve
			if (approve) {
				this.$refs.approveRefundModal.open({
					refund: refund
				})
			} else {
				const confirmed = await this.$refs.refundConfirmationModal.open(
					{
						title: `Are you sure you want to reject a refund of $${refund.amount} for ${refund.order.id}?`,
						confirmation: "Reject",
						confirmationIcon: "sk-icon-trash-alt-solid",
						cancel: "Cancel",
						type: ConfirmationModalType.RED
					}
				)
				if (confirmed) {
					const success = this.$store.dispatch("setRefundStatus", {
						id: refund.id,
						status: 0
					})
					const message = success
						? "Successfully rejected refund"
						: "Failed to reject refund"
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Refunds",
						message: message,
						type: success
							? NotificationType.SUCCESS
							: NotificationType.ERROR
					})
				}
			}
		},
		cleanUpBatchIds() {
			this.batchSelectedIds = []
			this.$emit("update:selectedIds", [])
		},
		openOrderDetailsModal(orderId) {
			this.$refs.orderDetailsModal.open({
				orderId: orderId
			})
		},
		editRefund(refund) {
			this.$refs.refundModal.open({
				refund: refund
			})
		}
	},
	watch: {
		batchSelectedIds:{ 
			immediate: true,
			handler(ids) {
				this.$emit("update:selectedIds", ids)
			}
		}
	}
}
</script>