<template>
	<div class="sk-page-content">
		<PageHeader
			:title="'Manage Restaurants'"
			:actions="actions"
			:filters="filters"
			@filterSet="filterSet"
			@filtersReset="clearFilters"
			@actionClicked="headerActionClicked"
		/>
		<div class="sk-table sk-table-default">
			<div class="sk-table-header">
				<div class="sk-table-row">
					<div>Status</div>
					<div>Restaurant</div>
					<div>Address</div>
					<div>Paired With</div>
					<div>POS Health</div>
					<div>Actions</div>
				</div>
			</div>
			<transition-group
				name="list"
				tag="div"
				class="sk-table-body"
				appear
			>
				<RestaurantLineItem
					v-for="restaurant in pagedRestaurants"
					:restaurant="restaurant"
					:key="restaurant.id"
					:pairings="restaurantPairings[restaurant.id]"
					@delete="deleteRestaurant"
				/>
			</transition-group>
		</div>
		<PaginationFooter
			:contentText="'Restaurants'"
			:pageSize="20"
			:total="restaurants.length"
			ref="paginationFooter"
			@pageChanged="filterPage"
		/>
		<RestaurantSetupModal ref="restaurantSetupModal" />
		<ConfirmationModal ref="confirmationModal" />
	</div>
</template>

<script>
import { ConfirmationModalType } from "@/enums/confirmationModalType"
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import { PermissionMethod } from "@/utils/permissions"
import tracker from "@/utils/tracker"
import PageHeader from "@/components/PageHeader.vue"
import PaginationFooter from "@/components/PaginationFooter.vue"
import RestaurantLineItem from "@/components/restaurant/RestaurantLineItem.vue"
import loaderMixin from "@/mixins/loader-mixin"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue"
import RestaurantSetupModal from "@/components/modals/RestaurantSetupModal.vue"

export default {
	name: "Restaurants",
	mixins: [loaderMixin],
	components: {
		PageHeader,
		PaginationFooter,
		RestaurantLineItem,
		ConfirmationModal,
		RestaurantSetupModal
	},
	data() {
		return {
			restaurant_id: 0,
			business_id: 0,
			stock_status: -1,
			city: null,
			state: null,
			pageRange: {
				page: 1,
				start: 0,
				end: 20
			},
			actions: []
		}
	},
	mounted: function () {
		if (this.$store.state.permissions.includes(PermissionMethod.NEW_RESTAURANT)) {
			this.actions.push({
				id: 0,
				text: `<i class="sk-icon-plus-regular"></i> New Restaurant`,
				classes: "button button-primary"
			})
		}
		this.$store.dispatch("restaurantsModule/clearRestaurant")
		this.$store.dispatch("getRestaurants")
	},
	computed: {
		loading() {
			return this.$store.state.loading
		},
		restaurantPairings() {
			return this.$store.getters.businessRestaurantPairings
		},
		cityArr() {
			const cities = []
			Object.values(this.$store.state.restaurants)
				.filter(
					(restaurant) =>
						restaurant.city != undefined &&
						restaurant.city.length != 0
				)
				.forEach((restaurant) => {
					if (!cities.includes(restaurant.city)) {
						cities.push(restaurant.city)
					}
				})
			return cities
		},
		stateArr() {
			const statesFound = []
			Object.values(this.$store.state.restaurants)
				.filter(
					(restaurant) =>
						restaurant.state != undefined &&
						restaurant.state.length != 0
				)
				.forEach((restaurant) => {
					if (!statesFound.includes(restaurant.state)) {
						statesFound.push(restaurant.state)
					}
				})
			let options = statesFound.sort().map((state, index) => {
				if (!state.text) {
					return {
						text: state,
						id: index
					}
				}
			})

			options.unshift({
				text: "State",
				id: null
			})
			return options
		},
		filters() {
			return [
				{
					id: 0,
					placeholder: "Status",
					type: "dropdown",
					items: [
						{
							text: "Status",
							id: null
						},
						{
							text: `<span class="status-dot dark-grey"></span> All`,
							id: -1
						},
						{
							text: `<span class="status-dot red"></span> Out of Stock`,
							id: 0
						},
						{
							text: `<span class="status-dot green"></span> In Stock`,
							id: 1
						}
					]
				},
				{
					id: 1,
					placeholder: "Restaurant Name",
					type: "search",
					fetch: (query) => {
						return this.$store.dispatch(
							"restaurantsModule/autocompleteRestaurants",
							query
						)
					},
					width: "250px",
					serializer: (i) => {
						return { text: i.name }
					}
				},
				{
					id: 2,
					placeholder: "Paired Business",
					type: "search",
					width: "250px",
					fetch: (query) => {
						return this.$store.dispatch(
							"businessesModule/autocompleteBusinesses",
							query
						)
					},
					serializer: (i) => {
						return { text: i.name }
					}
				},
				{
					id: 3,
					placeholder: "City",
					type: "search",
					items: this.cityArr,
					width: "200px",
					serializer: i => {
						return {
							text: i
						}
					}
				},
				{
					id: 4,
					placeholder: "State",
					type: "dropdown",
					items: this.stateArr
				}
			]
		},
		restaurants() {
			return Object.values(this.$store.state.restaurants)
				.slice()
				.sort()
				.filter((restaurant) => {
					let hasRequiredCity = this.city
						? restaurant.city == this.city
						: true
					let hasRequiredState = this.state
						? restaurant.state == this.state
						: true
					let hasRequiredStatus =
						this.stock_status != -1
							? restaurant.stock_status == this.stock_status
							: true
					let hasRequiredRestaurant = this.restaurant_id
						? restaurant.id == this.restaurant_id
						: true
					let hasRequiredBusiness = true
					if (this.business_id) {
						const pairings = this.restaurantPairings[restaurant.id]
						hasRequiredBusiness =
							pairings &&
							pairings.ids &&
							pairings.ids.includes(this.business_id)
								? true
								: false
					}
					return (
						hasRequiredCity &&
						hasRequiredState &&
						hasRequiredStatus &&
						hasRequiredBusiness &&
						hasRequiredRestaurant
					)
				})
		},
		pagedRestaurants() {
			return this.restaurants.slice(
				this.pageRange.start,
				this.pageRange.end
			)
		}
	},
	methods: {
		clearFilters() {
			this.stock_status = -1
			this.restaurant_id = 0
			this.business_id = 0
			this.city = null
			this.state = null
		},
		headerActionClicked(action) {
			switch (action.id) {
				case 0: {
					tracker.trackEvent("New Restaurant Clicked")
					this.$refs.restaurantSetupModal.open()
					break
				}
			}
		},
		async deleteRestaurant(restaurant) {
			const confirmed = await this.$refs.confirmationModal.open({
				title: `Delete ${restaurant.name}?`,
				body:
					"Are you sure you want to delete this restaurant? Deleting will permanently remove it and you will not be able to recover it.",
				confirmation: "Delete Restaurant",
				confirmationIcon: "sk-icon-trash-alt-solid",
				cancel: "Keep Restaurant",
				type: ConfirmationModalType.RED
			})
			if (confirmed) {
				tracker.trackEvent("Delete Restaurant Confirmed")
				const success = await this.$store.dispatch(
					"deleteRestaurant",
					restaurant.id
        )
        if (success) {
          eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
            title: `${restaurant.name} deleted successfully`,
          })
        } else {
          eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
            title: `Failed to delete ${restaurant.name}`,
            type: NotificationType.ERROR
          })
        }
			}
		},
		filterPage(range) {
			this.pageRange = range
		},
		filterSet(filter) {
			this.$refs.paginationFooter.pageChanged(1)
			switch (filter.id) {
				case 0:
					this.stock_status = filter.value.id
					break
				case 1:
					this.restaurant_id = filter.value ? filter.value.id : 0
					break
				case 2:
					this.business_id = filter.value ? filter.value.id : 0
					break
				case 3:
					this.city = filter.value ? filter.value : null
					break
				case 4:
					this.state = filter.value ? filter.value.text : null
					break
			}
		},
		filtersReset() {
			this.$refs.paginationFooter.pageChanged(1)
		}
	},
	watch: {
		pagedRestaurants(newValue) {
			let missingDevices = newValue
				.filter((restaurant) => !restaurant.devices)
				.map((restaurant) => restaurant.id)
			if (missingDevices.length > 0) {
				this.$store.dispatch("getRestaurantsPOSDevices", missingDevices)
			}
		},
		loading(loading) {
			this.toggleLoader(loading)
		}
	}
}
</script>