<template>
	<SkBaseModal
		:isOpen="isOpen"
		:showCloseButton="false"
		@close="close"
		:hasLoader="true"
	>
		<loading :active.sync="loading" :is-full-page="false"></loading>
		<div class="sk-modal-alt-title">
			<h1>In Stock</h1>
			<SkToggle :disabled="isNewProduct" v-model="productStockStatus" />
		</div>
		<div class="sk-row sk-widget-section">
			<div class="sk-widget-section-title">General</div>
			<RestaurantProductImageUpload
				ref="imageUpload"
				:titleText="'Product Image'"
				:imgSrc="productImg"
				:placeholderText="productImgPlaceholder"
				:recommendedSize="'500px x 500px'"
				:previewWidth="140"
				:previewHeight="140"
				:entity="productType"
				:entity_name="restaurant.slug"
				@set="setProductImage"
				@loading="setLoader"
			/>
			<SkInput
				:name="'Product Name'"
				:placeholder="'Name'"
				@change="updatedProductName"
				v-model="product.name"
			/>
			<SkInput
				:name="'Product Description'"
				:placeholder="'Description'"
				:multiline="true"
				@change="hasChanges = true"
				v-model="product.description"
			/>

			<SkDropdownMultiSelect v-model="productMenuIds" :options="menus" />

			<div class="sk-row">
				<TypeaheadInput
					ref="categoryTypeahead"
					:placeholder="'Category'"
					:items="categories"
					:serializer="categorySerializer"
					:query="category.text"
					@selected="categorySelected"
				/>
				<SkDropdownMultiSelect
					ref="labelDropdown"
					v-model="productLabelIds"
					:options="labels"
				/>
			</div>
			<div class="sk-switch-label-wrapper">
				<SkCheckbox v-model="containsAlcohol" />
				<span>Contains Alcohol</span>
			</div>
			<div class="sk-switch-label-wrapper">
				<SkCheckbox v-model="commentsEnabled" />
				<span>Comments Enabled</span>
			</div>
		</div>
		<div class="sk-row sk-widget-section">
			<div class="sk-row">
				<SkInput
					:name="'Restaurant Price'"
					:placeholder="'5.00'"
					:type="'number'"
					:disabled="!canEditPricing"
					v-model="product.cogs"
					:group="'$'"
				/>
			</div>
		</div>
		<div class="sk-row sk-widget-section">
			<div class="sk-widget-section-title">Add-ons</div>
			<RestaurantMenuProductAddon
				v-for="(meta, i) in product.metas"
				:key="`addon-${meta.id}`"
				:productId="product.id"
				:addon="meta"
				:isNewProduct="isNewProduct"
				@remove="removeAddon(i)"
				@edited="hasChanges = true"
				@loading="setLoader"
			/>
			<button
				class="button button-text sk-widget-section-text-button"
				@click="addAddon"
			>
				<i class="sk-icon-plus-regular"></i>
				New Addon
			</button>
		</div>
		<div class="sk-row sk-widget-section">
			<button
				class="sk-widget-section-button button button-primary"
				@click="saveProduct"
			>
				Save Item
			</button>
		</div>
	</SkBaseModal>
</template>

<style scoped>
.sk-modal-alt-title {
	align-items: center;
	justify-content: space-between;
	align-items: center;
	height: 50px;
}

.sk-modal-alt-title h1 {
	font-weight: 600;
	color: var(--sk-grey3);
	font-size: 16px;
}

.edit-product-modal-header >>> .sk-toggle {
	height: 20px;
}

.cogs-warning {
	margin-left: 15px;
}

.cogs-warning i {
	color: var(--sk-orange2);
}

.sk-widget-section:last-child {
	margin-bottom: 0;
}
</style>

<script>
import Vue from "vue"
import utils from "@/utils/utils"
import SkToggle from "@/components/SkToggle.vue"
import SkInput from "@/components/SkInput.vue"
import SkDropdownMultiSelect from "@/components/SkDropdownMultiSelect.vue"
import SkCheckbox from "@/components/SkCheckbox.vue"
import TypeaheadInput from "@/components/TypeaheadInput.vue"
import RestaurantMenuProductAddon from "@/components/restaurant-menu/RestaurantMenuProductAddon.vue"
import Loading from "vue-loading-overlay"
import RestaurantProductImageUpload from "@/components/restaurant/RestaurantProductImageUpload.vue"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import { PermissionMethod } from "@/utils/permissions"
import tracker from "@/utils/tracker"

export default {
	name: "EditProductModal",
	mixins: [skModalMixin],
	components: {
		SkBaseModal,
		SkToggle,
		SkInput,
		SkDropdownMultiSelect,
		SkCheckbox,
		TypeaheadInput,
		RestaurantMenuProductAddon,
		Loading,
		RestaurantProductImageUpload
	},
	data() {
		return {
			product: {},
			category: {},
			productLabelIds: [],
			productMenuIds: [],
			shouldSyncBusinessProducts: false,
			loading: false,
			hasChanges: false
		}
	},
	computed: {
		showPricingError() {
			return this.product && this.product.price < this.product.cogs
		},
		canEditPricing() {
			return this.$store.state.permissions.includes(PermissionMethod.EDIT_RESTAURANT_ITEM_PRICING)
		},
		restaurant() {
			return this.$store.state.restaurantsModule.currentRestaurant
		},
		isNewProduct() {
			return this.product.id == null || this.product.id == undefined
		},
		productType() {
			return "product"
		},
		containsAlcohol: {
			get: function() {
				return this.product.contains_alcohol
			},
			set: function(newVal) {
				if (newVal != undefined && newVal != null) {
					if (newVal) {
						this.product.contains_alcohol = 1
					} else {
						this.product.contains_alcohol = 0
					}
				}
			}
		},
		commentsEnabled: {
			get: function() {
				return this.product.comments_enabled
			},
			set: function(newVal) {
				if (newVal != undefined && newVal != null) {
					if (newVal) {
						this.product.comments_enabled = 1
					} else {
						this.product.comments_enabled = 0
					}
				}
			}
		},
		productImg() {
			if (!this.product.img || this.product.img.length == 0) {
				return null
			}
			const filenameArr = this.product.img.split(".")
			const sizedFileName =
				filenameArr[0] + "_" + "500x500." + filenameArr[1]
			return (
				this.$restaurantCDNURL + this.product.restaurant_slug + "/" + sizedFileName
			)
		},
		productImgPlaceholder() {
			return this.product.name ? this.product.name[0] : ""
		},
		categories() {
			const allCategories = this.$store.state.restaurantsModule.categories.map(
				category => {
					return {
						id: category.id,
						text: category.name
					}
				}
			)
			allCategories.unshift({
				id: null,
				text: "Category"
			})
			return allCategories
		},
		labels() {
			const allLabels = this.$store.state.restaurantsModule.labels.map(
				label => {
					return {
						id: label.id,
						text: label.name
					}
				}
			)
			allLabels.unshift({
				id: null,
				text: "Labels"
			})
			return allLabels
		},
		menus() {
			if (this.restaurant && this.restaurant.menus) {
				const menus = [
					{
						id: null,
						text: "Menus"
					}
				]
				this.restaurant.menus.forEach(menu => {
					menus.push({
						id: menu.id,
						text: menu.name
					})
				})
				return menus
			}
			return []
		},
		productStockStatus: {
			get: function() {
				return this.product.stock_status == 1
			},
			set: function(value) {
				this.updateStockStatus(value)
			}
		},
		productMenus: {
			get: function() {
				return this.productMenuIds
			},
			set: function(value) {
				this.hasChanges = true
				this.productMenuIds = value
			}
		}
	},
	methods: {
		async close() {
			if (this.hasChanges) {
				const promise = utils.createPromise()
				this.$emit("confirm", promise.resolve)
				const confirmed = await promise.promise
				if (confirmed) {
					this.saveProduct()
					return
				}
			}
			this.isOpen = false
			this.closed()
		},
		opened: function() {
			this.$refs.imageUpload.reset()
			this.shouldSyncBusinessProducts = false
			this.loading = false
			this.hasChanges = false
		},
		handleOptions: function(product) {
			this.product = utils.clonedeep(product)
			if (this.product.labels) {
				this.productLabelIds = this.product.labels.map(
					label => label.id
				)
			}

			this.productMenuIds = this.product.menu_ids ? this.product.menu_ids : []

			if (this.product.category_id && this.product.category_id >= 0) {
				this.category = this.categories.find(
					category => category.id == this.product.category_id
				)
			}
			if (this.product.id == undefined) {
				Vue.set(this.product, "category_id", null)
				Vue.set(this.product, "img", "")
				Vue.set(this.product, "item_img", "")
				Vue.set(this.product, "metas", [])
				Vue.set(this.product, "menu_ids", [])
				Vue.set(this.product, "price", "")
				Vue.set(this.product, "cogs", "")
				Vue.set(this.product, "name", "")
				Vue.set(this.product, "description", "")
				Vue.set(this.product, "contains_alcohol", 0)
				Vue.set(this.product, "comments_enabled", 1)
				this.productLabelIds = []
				this.category = {}
			}
		},
		setLoader: function(loading) {
			this.loading = loading
		},
		setProductImage: function(img) {
			this.product.img = img
			this.product.item_img = img
			this.hasChanges = true
		},
		categorySerializer(result) {
			return {
				text: result.text
			}
		},
		categorySelected(category) {
			if (category) {
				this.category = category
				this.product.category_id = category.id
			} else {
				this.category = {}
				this.product.category_id = null
			}
			this.hasChanges = true
		},
		updateStockStatus: async function(value) {
			try {
				tracker.trackEvent("Restaurant Product Stock Toggled", {
					active: value
				})
				this.loading = true
				await this.$store.dispatch(
					"restaurantsModule/updateProductStock",
					{
						productId: this.product.id,
						status: value
					}
				)
				if (this.product.metas) {
					this.product.metas
						.filter(
							meta =>
								meta.values != undefined && meta.values != null
						)
						.forEach(meta => {
							meta.values.forEach(
								item => (item.stock_status = value ? 1 : 0)
							)
						})
				}
				this.loading = false
			} catch (e) {
				this.loading = false
				throw e
			}
		},
		addAddon: function() {
			this.product.metas.push({
				key: "",
				required: 0,
				type: 0,
				values: []
			})
			this.hasChanges = true
		},
		removeAddon: function(index) {
			this.product.metas.splice(index, 1)
			this.hasChanges = true
		},
		updatedProductName() {
			this.shouldSyncBusinessProducts = true
			this.hasChanges = true
		},
		saveProduct: function() {
			this.product.metas.forEach(meta => {
				if (meta.values) {
					meta.values.forEach(value => {
						if (value.newOption) {
							delete value.id
							delete value.newOption
						}
					})
				}
			})
			this.$emit("save", {
				product: this.product,
				menuIds: this.productMenuIds,
				sync: this.shouldSyncBusinessProducts
			})
			this.hasChanges = false
			this.close()
		}
	},
	watch: {
		productLabelIds(ids) {
			if (
				this.product.labels == undefined ||
				this.product.labels == null
			) {
				this.product.labels = []
			}
			if (this.product) {
				const currentLabelIds = this.product.labels.map(
					label => label.id
				)

				const newId = ids.find(id => !currentLabelIds.includes(id))
				if (newId) {
					this.product.labels = ids.map(id => {
						return {
							id: id
						}
					})
					this.hasChanges = true
				} else {
					const removedId = currentLabelIds.find(
						id => !ids.includes(id)
					)
					if (removedId) {
						this.product.labels = this.product.labels.filter(
							label => label.id != removedId
						)
						this.hasChanges = true
					}
				}
			}
		}
	}
}
</script>