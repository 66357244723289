<template>
	<div class="event-reminders">
		<slot v-if="pagedReminders.length > 0">
			<div class="sk-table sk-table-default">
				<div class="sk-table-header">
					<div class="sk-table-row">
						<div>Status</div>
						<div>Type</div>
						<div>Date</div>
						<div>Time</div>
						<div>Actions</div>
					</div>
				</div>
				<transition-group
					name="list"
					tag="div"
					class="sk-table-body"
					appear
				>
					<EventReminderLineItem
						v-for="reminder in pagedReminders"
						:key="reminder.id"
						:reminder="reminder"
                        :event="event"
						@delete="deleteReminder"
						@edit="editReminderClicked"
					/>
				</transition-group>
			</div>
			<PaginationFooter
				:contentText="'Event Reminders'"
				:pageSize="20"
				:total="reminders.length"
				@pageChanged="filterPage"
			/>
		</slot>
		<EmptyPlaceholderWidget
			v-else
			:title="`No Scheduled Reminders`"
			:description="`You don’t have any scheduled reminders yet.`"
			:buttonText="'New Scheduled Reminder'"
			@actionClicked="handlePlaceholderClicked"
		/>
		<ConfirmationModal ref="confirmationModal" />
	</div>
</template>

<style scoped>
.event-reminders {
	height: 100%;
	display: flex;
	flex-direction: column;
}
</style>

<script>
import { ConfirmationModalType } from "@/enums/confirmationModalType"
import loaderMixin from "@/mixins/loader-mixin"
import PaginationFooter from "@/components/PaginationFooter.vue"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue"
import EmptyPlaceholderWidget from "@/components/EmptyPlaceholderWidget.vue"
import EventReminderLineItem from "@/components/events/EventReminderLineItem.vue"
import ActionTypes from "@/store/modules/events/action-types"

export default {
    name: "EventReminders",
    mixins: [loaderMixin],
	components: {
		PaginationFooter,
		ConfirmationModal,
		EmptyPlaceholderWidget,
		EventReminderLineItem
	},
	data: function() {
		return {
			pageRange: {
				page: 1,
				start: 0,
				end: 20
			}
		}
    },
    props: {
		event: Object
	},
	computed: {
		reminders() {
			return this.$store.state.eventsModule.eventReminders
				? this.$store.state.eventsModule.eventReminders
				: []
		},
		pagedReminders() {
			return this.reminders.slice(
				this.pageRange.start,
				this.pageRange.end
			)
		}
	},
	methods: {
		filterPage(range) {
			this.pageRange = range
		},
		async deleteReminder(reminder) {
			const confirmed = await this.$refs.confirmationModal.open({
				title: `Are you sure you want to remove this reminder?`,
				confirmation: "Delete Reminder",
				confirmationIcon: "sk-icon-trash-alt-solid",
				cancel: "Keep Reminder",
				type: ConfirmationModalType.RED
			})
			if (confirmed) {
				this.toggleLoader(true)
				this.$store
					.dispatch(`eventsModule/${ActionTypes.DELETE_EVENT_REMINDER}`, reminder.id)
				.finally(() => {
					this.toggleLoader(false)
				})
			}
		},
		handlePlaceholderClicked() {
			this.$emit("new", {})
		},
		editReminderClicked(reminder) {
			this.$emit("edit", reminder)
        },
        fetchReminders() {
			this.toggleLoader(true)
			this.$store
				.dispatch(`eventsModule/${ActionTypes.GET_EVENT_REMINDERS}`)
				.finally(() => {
					this.toggleLoader(false)
				})
		}
    },
    mounted: function () {
		this.fetchReminders()
	}
}
</script>