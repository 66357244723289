<template>
	<SkBaseModal
		class="edit-event-modal"
		:isOpen="isOpen"
		:showCloseButton="false"
		:hasLoader="true"
		@close="close"
	>
		<loading :active.sync="loading" :is-full-page="false" />
		<div class="sk-modal-alt-title">
			<h1 v-if="!event.id">Create New Event</h1>
			<h1 v-else>
				Edit Event
				<span class="sk-modal-alt-subtitle" v-if="event.name">
					: {{ event.name }}
				</span>
			</h1>
		</div>
		<SkModalStepNavbar
			:steps="steps"
			:currentStep="currentStep"
			@setStep="setStep"
		/>
		<transition-group name="fade-height" class="event-steps">
			<div
				v-if="currentStep == 0"
				class="sk-grid event-types-wrapper"
				key="0"
			>
				<div class="sk-widget event-type-widget">
					<div>
						<SkRadio
							:option="eventTypes.VIRTUAL"
							v-model="eventType"
						/>
					</div>
					<div class="event-type-description">
						<div>Virtual Popup</div>
						<div>
							Feature a limited-time menu from local restaurants
							to be delivered to your location
						</div>
					</div>
				</div>
				<div class="sk-widget event-type-widget">
					<div>
						<SkRadio
							:option="eventTypes.POPUP"
							v-model="eventType"
						/>
					</div>
					<div class="event-type-description">
						<div>Popup</div>
						<div>
							Feature a restaurant serving food in-person inside
							your location
						</div>
					</div>
				</div>
			</div>
			<div v-show="currentStep == 1" key="1" class="sk-modal-section">
				<div class="sk-row">
					<SkInput v-model="event.name" :name="'Event Name'" />
				</div>
				<div class="sk-row">
					<SkInput
						v-model="event.description"
						:name="'Event Description'"
						:multiline="true"
					/>
				</div>
				<div class="sk-row">
					<TypeaheadInput
						:disabled="event.id != null"
						:placeholder="'Business'"
						:fetch="autocompleteBusinesses"
						:serializer="typeaheadSerializer"
						:query="business.name"
						@selected="businessSelected"
					/>
				</div>
				<div class="sk-row">
					<SkInput
						v-model="event.contact_email"
						:name="'Event Coordinator Email'"
						:type="'email'"
					/>
				</div>
				<div class="sk-row">
					<SkInput
						v-model="event.manifest_notes"
						:name="'Event Notes'"
						:multiline="true"
					/>
				</div>
				<div class="sk-row" @click="canUploadBanner">
					<BannerUpload
						ref="bannerUpload"
						class="event-banner"
						:class="{ disabled: !business || !business.slug }"
						:titleText="'Event Photo'"
						:imgSrc="eventImage"
						:recommendedSize="'1500px x 550px'"
						:previewWidth="140"
						:previewHeight="140"
						:entity="'event'"
						:entity_name="bannerEntityName"
						@set="setEventImage"
						@loading="setLoader"
					/>
				</div>
			</div>
			<div
				v-if="currentStep == 2 && event.type == eventTypes.VIRTUAL"
				key="2"
				class="sk-modal-section"
			>
				<div class="sk-row">
					<VueCtkDateTimePicker
						v-model="localEventTimes.endDate"
						:label="'Event Date'"
						:no-clear-button="true"
						:no-label="true"
						:no-button-now="true"
						:only-date="true"
						:button-color="'#90DA36'"
						:format="'MM-DD-YYYY'"
						:formatted="'ll'"
						:color="'#2E3C49'"
						:output-format="'ddd MMM DD, YYYY'"
						:min-date="minStartDate"
						:no-value-to-custom-elem="true"
					>
						<SkInput
							:value="localEventTimes.endDate"
							:disabled="true"
							:name="'Event Date'"
						/>
					</VueCtkDateTimePicker>
				</div>
				<div class="sk-row">
					<SkTimePicker
						v-model="localEventTimes.endTime"
						:name="'Delivery Time'"
						:hint="'Time all food will be dropped off'"
						:disabled="businessTimezone == null"
						:inputFormat="'h:mm A'"
						@toggleDisabled="validateOffset"
					/>
				</div>
				<div class="sk-row">
					<VueCtkDateTimePicker
						v-model="localEventTimes.preorderEndDate"
						:label="'Cutoff Date'"
						:no-clear-button="true"
						:no-label="true"
						:no-button-now="true"
						:only-date="true"
						:button-color="'#90DA36'"
						:format="'MM-DD-YYYY'"
						:formatted="'ll'"
						:color="'#2E3C49'"
						:output-format="'ddd MMM DD, YYYY'"
						:min-date="minStartDate"
						:max-date="maxDate"
						:no-value-to-custom-elem="true"
					>
						<SkInput
							:value="localEventTimes.preorderEndDate"
							:disabled="true"
							:name="'Cutoff Date'"
						/>
					</VueCtkDateTimePicker>
				</div>
				<div class="sk-row">
					<SkTimePicker
						v-model="localEventTimes.preorderEndTime"
						:name="'Order Cutoff Time'"
						:hint="'Recieve Orders until set time'"
						:disabled="businessTimezone == null"
						:inputFormat="'h:mm A'"
						@toggleDisabled="validateOffset"
					/>
				</div>
			</div>
			<div
				v-if="currentStep == 2 && event.type == eventTypes.POPUP"
				key="3"
				class="sk-modal-section"
			>
				<div class="sk-row">
					<VueCtkDateTimePicker
						v-model="localEventTimes.startDate"
						:label="'Event Date'"
						:no-clear-button="true"
						:no-label="true"
						:no-button-now="true"
						:only-date="true"
						:button-color="'#90DA36'"
						:format="'MM-DD-YYYY'"
						:formatted="'ll'"
						:color="'#2E3C49'"
						:output-format="'ddd MMM DD, YYYY'"
						:min-date="minStartDate"
						:no-value-to-custom-elem="true"
					>
						<SkInput
							:value="localEventTimes.startDate"
							:disabled="true"
							:name="'Event Date'"
						/>
					</VueCtkDateTimePicker>
				</div>
				<div class="sk-row">
					<SkTimePicker
						v-model="localEventTimes.startTime"
						:name="'Start Time'"
						:disabled="businessTimezone == null"
						:inputFormat="'h:mm A'"
						@toggleDisabled="validateOffset"
					/>
				</div>
				<div class="sk-row">
					<SkTimePicker
						v-model="localEventTimes.endTime"
						:name="'End Time'"
						:disabled="businessTimezone == null"
						:inputFormat="'h:mm A'"
						@toggleDisabled="validateOffset"
					/>
				</div>
				<div class="sk-widget-section">
					<div class="sk-widget-section-title">
						Pre Orders
						<i
							class="hint-icon sk-icon-info-circle-light"
							v-sk-tooltip="
								'Pre Orders allow you to recieve orders before you start your popup.</br>This can help gauge interest and demand.'
							"
						></i>
					</div>
					<div class="sk-row">
						<SkToggle v-model="allowPreorders" :width="'40px'" />
					</div>
					<transition-expand>
						<div v-if="allowPreorders">
							<div class="sk-row">
								<VueCtkDateTimePicker
									v-model="localEventTimes.preorderEndDate"
									:label="'Cutoff Date'"
									:no-clear-button="true"
									:no-label="true"
									:no-button-now="true"
									:only-date="true"
									:button-color="'#90DA36'"
									:format="'MM-DD-YYYY'"
									:formatted="'ll'"
									:color="'#2E3C49'"
									:output-format="'ddd MMM DD, YYYY'"
									:min-date="minStartDate"
									:max-date="maxDate"
									:no-value-to-custom-elem="true"
								>
									<SkInput
										:value="localEventTimes.preorderEndDate"
										:disabled="true"
										:name="'Cutoff Date'"
									/>
								</VueCtkDateTimePicker>
							</div>
							<div class="sk-row">
								<SkTimePicker
									v-model="localEventTimes.preorderEndTime"
									:name="'Order Cutoff Time'"
									:hint="'Recieve Orders until set time'"
									:disabled="businessTimezone == null"
									:inputFormat="'h:mm A'"
									@toggleDisabled="validateOffset"
								/>
							</div>
						</div>
					</transition-expand>
				</div>
			</div>
			<div v-if="currentStep == 3" key="4" class="sk-modal-section">
				<div class="sk-row">
					<TypeaheadInput
						:placeholder="'Restaurant'"
						:fetch="autocompleteRestaurants"
						:serializer="typeaheadSerializer"
						:query="restaurant.name"
						@selected="restaurantSelected"
					/>
				</div>
				<div class="sk-row">
					<TypeaheadInput
						ref="menuTypeahead"
						:placeholder="'Menu'"
						:items="menus"
						:serializer="menuSerializer"
						:disabled="!event.restaurant_id"
						:query="menu.name"
						@selected="menuSelected"
						@focus="menuInputFocused"
					/>
				</div>
				<transition-expand>
					<div
						class="event-products dark-scrollbar"
						v-if="products && products.length > 0"
					>
						<draggable
							:value="products"
							:draggable="'.draggable'"
							@end="onValueMoveEnd"
						>
							<div
								class="event-product sk-widget"
								v-for="product in products"
								:class="{ draggable: product.added }"
								:key="product.id"
							>
								<button
									v-if="product.added"
									class="button button-icon"
								>
									<i class="sk-icon-bars-regular"></i>
								</button>
								<SkCheckbox
									@input="toggleEventProduct(product)"
									:checked="product.added"
								/>
								<div class="event-product-info">
									<div>{{ product.name }}</div>
									<RestaurantProductLabelIcons
										:labels="product.labels"
									></RestaurantProductLabelIcons>
									<div>{{ product.price | currency }}</div>
								</div>
							</div>
						</draggable>
					</div>
				</transition-expand>
				<div class="sk-row">
					<SkDropdownSelect
						v-model="deliveryMethod"
						class="sk-input"
						:options="deliveryMethods"
					/>
				</div>
				<div class="sk-row">
					<SkDropdownSelect
						v-model="deliveryMode"
						class="sk-input"
						:options="deliveryModes"
					/>
				</div>
			</div>
			<div v-if="currentStep == 4" key="5" class="sk-modal-section">
				<SetupReviewStep
					:stepName="'Type'"
					:stepId="0"
					:reviewingStepId="reviewingStepId"
					:showCompletion="false"
					:showProgress="false"
					@reviewStep="reviewStep"
					@goToStep="setStep"
				>
					<div class="setup-review-step-detail">
						Type
						<b>{{ eventType | eventTypeName }}</b>
					</div>
				</SetupReviewStep>
				<SetupReviewStep
					:stepName="'Details'"
					:stepId="1"
					:reviewingStepId="reviewingStepId"
					:showCompletion="false"
					:showProgress="false"
					@reviewStep="reviewStep"
					@goToStep="setStep"
				>
					<div class="setup-review-step-detail">
						Name
						<b v-sk-unassigned="business.name" />
					</div>
					<div class="setup-review-step-detail">
						Description
						<b v-sk-unassigned="business.description" />
					</div>
					<div class="setup-review-step-detail">
						Business Name
						<b v-sk-unassigned="business.name" />
					</div>
					<div class="setup-review-step-detail">
						Event Coordinator Email
						<b v-sk-unassigned="event.contact_email" />
					</div>
				</SetupReviewStep>
				<SetupReviewStep
					:stepName="'When'"
					:stepId="2"
					:reviewingStepId="reviewingStepId"
					:showCompletion="false"
					:showProgress="false"
					@reviewStep="reviewStep"
					@goToStep="setStep"
				>
					<slot v-if="event.type == eventTypes.VIRTUAL">
						<div class="setup-review-step-detail">
							Event Date
							<b v-sk-unassigned="localEventTimes.endDate" />
						</div>
						<div class="setup-review-step-detail">
							Order Cutoff Date
							<b v-sk-unassigned="localEventTimes.preorderEndDate" />
						</div>
						<div class="setup-review-step-detail">
							Order Cutoff Time
							<b v-sk-unassigned="formattedCutoffTime" />
						</div>
						<div class="setup-review-step-detail">
							Delivery Time
							<b v-sk-unassigned="formattedEndTime" />
						</div>
					</slot>
					<slot v-if="event.type == eventTypes.POPUP">
						<div class="setup-review-step-detail">
							Event Date
							<b v-sk-unassigned="localEventTimes.startDate" />
						</div>
						<div class="setup-review-step-detail">
							Start Time
							<b v-sk-unassigned="formattedStartTime" />
						</div>
						<div class="setup-review-step-detail">
							End Time
							<b v-sk-unassigned="formattedEndTime" />
						</div>
						<div v-if="allowPreorders" 
							class="setup-review-step-detail">
							Order Cutoff Date
							<b v-sk-unassigned="localEventTimes.preorderEndDate" />
						</div>
						<div v-if="allowPreorders" 
							class="setup-review-step-detail">
							Order Cutoff Time
							<b v-sk-unassigned="formattedCutoffTime" />
						</div>
					</slot>
				</SetupReviewStep>
				<SetupReviewStep
					:stepName="'Restaurant'"
					:stepId="3"
					:reviewingStepId="reviewingStepId"
					:showCompletion="false"
					:showProgress="false"
					@reviewStep="reviewStep"
					@goToStep="setStep"
				>
					<div class="setup-review-step-detail">
						Restaurant Name
						<b v-sk-unassigned="restaurant.name" />
					</div>
					<div class="setup-review-step-detail">
						Menu Name
						<b v-sk-unassigned="menu.name" />
					</div>
					<div class="setup-review-step-detail">
						Delivery Mode
						<b v-sk-unassigned="deliveryMode.text" />
					</div>
					<div v-if="event.type == eventTypes.VIRTUAL"
						class="setup-review-step-detail">
						Delivery Method
						<b v-sk-unassigned="deliveryMethod.text" />
					</div>
					<div v-if="event.type == eventTypes.VIRTUAL"
						class="setup-review-step-detail">
						<div
							class="menu-items-toggle"
							@click="reviewMenuItemsToggled"
						>
							Menu Items
							<i
								class="sk-icon-chevron-down-regular"
								:class="{ flipped: reviewingMenuItems }"
							></i>
						</div>
						<transition-expand>
							<div v-if="reviewingMenuItems" class="review-menu-product-items">
								<div
									class="review-menu-product-line-item"
									v-for="product in orderedEventProducts"
									:key="product.id"
								>
									<div>{{ product.name }}</div>
									<div>{{ product.price | currency }}</div>
								</div>
							</div>
						</transition-expand>
					</div>
				</SetupReviewStep>
			</div>
		</transition-group>
		<div class="sk-modal-actions">
			<button @click="saveEvent" class="button button-text">
				Save & Exit
			</button>
			<button
				v-if="currentStep != lastStep"
				class="button button-primary"
				@click="nextStep"
			>
				Continue
				<i class="sk-icon-caret-right-solid"></i>
			</button>
			<button v-else class="button button-primary" @click="saveEvent">
				{{ lastStepButtonText }}
			</button>
		</div>
		<ConfirmationModal ref="confirmationModal" />
	</SkBaseModal>
</template>

<style scoped>
.edit-event-modal >>> .sk-modal-content {
	background: var(--sk-greybg2);
}

.sk-modal-alt-title {
	margin-bottom: 0;
	position: relative;
}

.event-steps {
	display: grid;
	grid-template-columns: 1fr;
}

.event-steps > div {
	grid-row-start: 1;
	grid-column-start: 1;
}

.event-steps > div:not(.event-types-wrapper) {
	padding: 5px;
	margin: -5px;
}

.event-types-wrapper {
	grid-template-columns: 1fr 1fr;
	margin: 20px -5px;
	column-gap: 15px;
}

.event-type-widget {
	display: flex;
	align-items: center;
}

.event-type-widget >>> .sk-radio {
	width: 20px;
	height: 20px;
	margin-right: 15px;
}

.event-type-description > div:first-child {
	font-size: 14px;
	font-weight: 600;
	color: var(--sk-navy);
	margin-bottom: 5px;
}

.event-type-description > div:last-child {
	font-size: 12px;
	color: var(--sk-grey2);
}

.event-banner.disabled {
	pointer-events: none;
}

.sk-widget-section-title {
	display: flex;
	align-items: center;
}

.hint-icon {
	margin-left: 12px;
	font-size: 15px;
	color: var(--sk-grey2);
}

.event-products {
	max-height: 500px;
	overflow-y: auto;
	margin: -5px;
	padding: 5px;
}

.event-product {
	display: flex;
	align-items: center;
}

.event-product + .event-product {
	margin-top: 15px;
}

.event-product-info {
	font-size: 14px;
	font-weight: 600;
	color: var(--sk-navy);
	display: flex;
	flex: 1;
}

.event-product-info div:last-child {
	flex: 1;
	padding-left: 20px;
	text-align: right;
}

.event-product .button-icon {
	padding: 0;
	margin-right: 20px;
	min-height: auto;
	height: 17px;
	color: var(--sk-grey2);
}

.sk-modal-actions .button i {
	margin-left: 30px;
	margin-right: 0px;
}

.menu-items-toggle {
	color: var(--sk-grey2) !important;
	cursor: pointer;
}

.menu-items-toggle > i {
	font-size: 10px;
	margin-left: 10px;
}

.label-icons {
	display: flex;
	padding-left: 5px;
}

.review-menu-product-items {
	margin-top: 10px;
}

.review-menu-product-line-item {
	display: flex;
	justify-content: space-between;
}

.review-menu-product-line-item > div {
	font-size: 12px;
	color: var(--sk-grey3);
	font-weight: 600;
}
</style>

<script>
import moment from "moment"
import BusinessEvent from "@/models/BusinessEvent"
import { BusinessEventType } from "@arikgaisler/utils/enums/businessEventType"
import { MenuType } from "@arikgaisler/utils/enums/menuType"
import { DeliveryMode } from "@arikgaisler/utils/enums/deliveryMode"
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import { CourierId } from "@arikgaisler/utils/enums/courierId"
import ActionTypes from "@/store/modules/events/action-types"
import utils from "@/utils/utils"
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import currencyFilterMixin from "@/mixins/currency-filter-mixin"
import eventDisplayMixin from "@/mixins/events/event-display-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import SkInput from "@/components/SkInput.vue"
import SkTimePicker from "@/components/SkTimePicker.vue"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"
import SkRadio from "@/components/SkRadio.vue"
import SkCheckbox from "@/components/SkCheckbox.vue"
import SkToggle from "@/components/SkToggle.vue"
import BannerUpload from "@/components/BannerUpload.vue"
import Loading from "vue-loading-overlay"
import TypeaheadInput from "@/components/TypeaheadInput.vue"
import VueCtkDateTimePicker from "vue-ctk-date-time-picker"
import TransitionExpand from "@/components/transitions/TransitionExpand.vue"
import draggable from "vuedraggable"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue"
import RestaurantProductLabelIcons from "@/components/restaurant/RestaurantProductLabelIcons.vue"
import SkModalStepNavbar from "@/components/modals/SkModalStepNavbar.vue"
import SetupReviewStep from "@/components/modals/SetupReviewStep.vue"

class LocalEventTimes {
	constructor() {
		this.startDate = null
		this.endDate = null
		this.preorderEndDate = null
		this.startTime = null
		this.endTime = null
		this.preorderEndTime = null
	}
}

export default {
	name: "EditEventModal",
	mixins: [skModalMixin, currencyFilterMixin, eventDisplayMixin],
	components: {
		SkBaseModal,
		SkInput,
		SkTimePicker,
		SkDropdownSelect,
		SkRadio,
		SkCheckbox,
		SkToggle,
		BannerUpload,
		Loading,
		TypeaheadInput,
		VueCtkDateTimePicker,
		TransitionExpand,
		draggable,
		ConfirmationModal,
		RestaurantProductLabelIcons,
		SkModalStepNavbar,
		SetupReviewStep
	},
	data: function () {
		return {
			loading: false,
			eventTypes: BusinessEventType,
			event: new BusinessEvent(),
			localEventTimes: new LocalEventTimes(),
			currentStep: 0,
			lastStep: 4,
			allowPreorders: false,
			reviewingMenuItems: false,
			business: {},
			restaurant: {},
			menu: {},
			minStartDate: moment().format("YYYY-MM-DD"),
			deliveryModes: [
				{
					id: null,
					text: "Delivery Method"
				},
				{
					id: DeliveryMode.YAY_FOOD_POLE,
					text: this.$options.filters.eventDeliveryModeName(
						DeliveryMode.YAY_FOOD_POLE
					)
				},
				{
					id: DeliveryMode.PICKUP_AREA,
					text: this.$options.filters.eventDeliveryModeName(
						DeliveryMode.PICKUP_AREA
					)
				}
			],
			deliveryMethods: [
				{
					id: CourierId.WALKING,
					text: "Self Delivery"
				},
				{
					id: CourierId.DOORDASH,
					text: "DoorDash"
				},
				{
					id: CourierId.CUT_CATS,
					text: "Cut Cats"
				},
				{
					id: CourierId.POSTMATES,
					text: "Postmates"
				}
			],
			reviewingStepId: null
		}
	},
	filters: {
		formatDate(date, inFormat, outFormat) {
			if (!date) {
				return ""
			}
			return moment.utc(date, inFormat).format(outFormat)
		}
	},
	computed: {
		maxDate() {
			if (this.event.type == BusinessEventType.VIRTUAL) {
				return this.localEventTimes.endDate
					? moment
							.utc(
								this.localEventTimes.endDate,
								"ddd MMM DD, YYYY"
							)
							.format("YYYY-MM-DD")
					: null
			} else {
				return this.localEventTimes.startDate
					? moment
							.utc(
								this.localEventTimes.startDate,
								"ddd MMM DD, YYYY"
							)
							.format("YYYY-MM-DD")
					: null
			}
		},
		isTimeSet() {
			return (
				this.localEventTimes.startDate != null ||
				this.localEventTimes.startTime != null ||
				this.localEventTimes.endDate != null ||
				this.localEventTimes.endTime != null ||
				this.localEventTimes.preorderEndTime != null ||
				this.localEventTimes.preorderEndDate != null
			)
		},
		eventTypeStepComplete() {
			return this.event.type > 0
		},
		eventDetailsStepComplete() {
			return (
				this.event.name != null &&
				this.event.name.length > 0 &&
				this.event.description != null &&
				this.event.description.length > 0 &&
				this.event.business_id != null &&
				this.event.contact_email &&
				this.event.contact_email.length > 0 &&
				this.event.image != null &&
				this.event.image.length > 0
			)
		},
		eventWhenStepComplete() {
			if (this.event.type == BusinessEventType.VIRTUAL) {
				return (
					this.localEventTimes.endDate != null &&
					this.localEventTimes.preorderEndTime != null &&
					this.localEventTimes.endTime != null
				)
			} else if (this.event.type == BusinessEventType.POPUP) {
				const baseTimeComplete =
					this.localEventTimes.startDate &&
					this.localEventTimes.startTime &&
					this.localEventTimes.endTime
				if (this.allowPreorders) {
					return (
						baseTimeComplete &&
						this.localEventTimes.preorderEndTime != null
					)
				} else {
					return baseTimeComplete
				}
			}
			return true
		},
		eventRestaurantStepComplete() {
			let eventHasProducts =
				this.products &&
				this.products.filter((product) => product.addToEventMenu)
					.length > 0
			if (!eventHasProducts) {
				eventHasProducts = this.event.products.length > 0
			}
			return (
				this.event.restaurant_id &&
				this.event.menu_id &&
				eventHasProducts &&
				this.event.delivery_mode
			)
		},
		steps() {
			return [
				{
					id: 0,
					isActive: this.currentStep == 0,
					isComplete: this.eventTypeStepComplete,
					text: "Type"
				},
				{
					id: 1,
					isActive: this.currentStep == 1,
					isComplete: this.eventDetailsStepComplete,
					text: "Details"
				},
				{
					id: 2,
					isActive: this.currentStep == 2,
					isComplete: this.eventWhenStepComplete,
					text: "When"
				},
				{
					id: 3,
					isActive: this.currentStep == 3,
					isComplete: this.eventRestaurantStepComplete,
					text: "Restaurant"
				},
				{
					id: 4,
					isActive: this.currentStep == 4,
					isComplete: false,
					text: "Review"
				}
			]
		},
		bannerEntityName() {
			return this.business && this.business.slug ? this.business.slug : ""
		},
		eventType: {
			get: function () {
				return this.event.type
			},
			set: async function (type) {
				if (this.isTimeSet) {
					const confirmed = await this.$refs.confirmationModal.open({
						title: "Change event type?",
						body: "Changing event type will reset the event hours.",
						confirmation: "Change Type",
						cancel: "Cancel"
					})
					if (confirmed) {
						this.localEventTimes = new LocalEventTimes()
						this.event.type = type
					}
				} else {
					this.event.type = type
				}
			}
		},
		eventImage: {
			get: function () {
				return this.event.image
					? `${this.$eventsCDNURL}${this.business.slug}/${this.event.image}`
					: null
			},
			set: function (image) {
				this.event.image = image
			}
		},
		menus() {
			let menus = this.$store.state.eventsModule.restaurantMenus[
				this.event.restaurant_id
			]
			if (menus) {
				menus = menus.filter((menu) => menu.type == MenuType.POPUP)
			}
			return menus
		},
		products() {
			if (this.menus) {
				let menu = this.menus.find(
					(menu) => menu.id == this.event.menu_id
				)
				if (menu && menu.products) {
					let eventProducts = {}
					this.event.products.forEach((product) => {
						eventProducts[product.id] = product
					})
					return menu.products
						.map((product) => {
							return {
								id: product.id,
								name: product.name,
								price: product.price,
								labels: product.labels,
								position: eventProducts[product.id]
									? eventProducts[product.id].position
									: null,
								added: eventProducts[product.id] != undefined
							}
						})
						.sort((a, b) => {
							if (a.position == null && b.position == null) {
								return 0
							} else if (a.position == null) {
								return 1
							} else if (b.position == null) {
								return -1
							} else {
								return a.position - b.position
							}
						})
				}
			}
			return null
		},
		orderedEventProducts() {
			return this.products ? this.products.filter((product) => product.added) : []
		},
		deliveryMode: {
			get: function () {
				return this.deliveryModes.find(
					(mode) => mode.id == this.event.delivery_mode
				)
			},
			set: function (mode) {
				this.event.delivery_mode = mode.id
			}
		},
		deliveryMethod: {
			get: function () {
				let currentDeliveryMethod =
					this.event.courier_id && this.event.courier_id != null
						? this.event.courier_id
						: 0
				return this.deliveryMethods.find(
					(method) => method.id == currentDeliveryMethod
				)
			},
			set: function (method) {
				this.event.courier_id = method.id
			}
		},
		lastStepButtonText() {
			return this.event.id ? "Save" : "Create Event"
		},
		formattedStartTime() {
			if (this.localEventTimes.startTime) {
				return this.$options.filters.formatDate(this.localEventTimes.startTime, "hh:mm A", "h:mm A")
			}
			return null
		},
		formattedEndTime() {
			if (this.localEventTimes.endTime) {
				return this.$options.filters.formatDate(this.localEventTimes.endTime, "hh:mm A", "h:mm A")
			}
			return null
		},
		formattedCutoffTime() {
			if (this.localEventTimes.preorderEndTime) {
				return this.$options.filters.formatDate(this.localEventTimes.preorderEndTime, "hh:mm A", "h:mm A")
			}
			return null
		}
	},
	methods: {
		setLoader(loading) {
			this.loading = loading
		},
		async handleOptions(options) {
			try {
				this.$refs.bannerUpload.reset()
				this.reviewingMenuItems = false
				if (options.eventId) {
					if (
						!this.$store.state.eventsModule.event.id ||
						this.$store.state.eventsModule.event.id !=
							options.eventId
					) {
						this.loading = true
						await this.$store.dispatch(
							`eventsModule/${ActionTypes.GET_EVENT}`,
							options.eventId
						)
						this.loading = false
					}
					this.event = utils.clonedeep(
						this.$store.state.eventsModule.event
					)
					this.allowPreorders = this.event.pre_order_end_time != null
					this.setBusiness()
					this.setRestaurant()
					this.setLocalEventTimes()
				} else {
					this.allowPreorders = false
					this.event = new BusinessEvent()

					if (options.date) {
						this.localEventTimes.endDate = moment(options.date, "YYYY-MM-DD").format("ddd MMM DD, YYYY")
					}
				}
				if (options.step) {
					this.setStep(options.step)
				}
			} catch (e) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "An error occurred, please try again.",
					type: NotificationType.ERROR
				})
				this.close()
				throw e
			}
		},
		closed() {
			this.loading = false
			this.currentStep = 0
			this.reviewingStepId = null
			this.business = {}
			this.restaurant = {}
			this.menu = {}
			this.localEventTimes = new LocalEventTimes()
		},
		nextStep() {
			if (this.currentStep < this.lastStep) {
				this.setStep(this.currentStep + 1)
			}
		},
		async setStep(id) {
			this.currentStep = id

			if (this.currentStep == this.lastStep || this.currentStep == 3) {
				await this.getMenuIfNeeded()
			}

			if (this.currentStep == 3) {
				await this.getProductsIfNeeded()
			}
		},
		reviewStep(id) {
			this.reviewingStepId = id
		},
		setEventImage(img) {
			this.event.image = img
		},
		validateOffset() {
			if (this.businessTimezone == null) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "A business is required when setting the event time",
					type: NotificationType.WARNING
				})
			}
		},
		validateEvent() {
			try {
				if (!this.event.business_id) {
					throw "A business is required when creating an event"
				}
				return true
			} catch (e) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: e.message
						? "Something went wrong, please try again."
						: e,
					type: NotificationType.WARNING
				})
				throw e
			}
		},
		async saveEvent() {
			try {
				this.loading = true
				this.validateEvent()
				this.syncEventTimes()

				const success = await this.$store.dispatch(
					`eventsModule/${ActionTypes.EDIT_BUSINESS_EVENT}`,
					{
						data: this.event,
						status: this.event.status
					}
				)
				if (success) {
					this.close()
				}
				this.loading = false
			} catch (e) {
				this.loading = false
			}
		},
		autocompleteBusinesses(query) {
			return this.$store.dispatch(
				"businessesModule/autocompleteBusinesses",
				query
			)
		},
		async autocompleteRestaurants(query) {
			const results = await this.$store.dispatch(
				"restaurantsModule/autocompleteRestaurants",
				query
			)
			return results.filter((result) => result.supports_popup)
		},
		typeaheadSerializer(result) {
			let hasAddress =
				result.address && result.city && result.state && result.zip
			return {
				text: result.name,
				description: hasAddress
					? `${result.address} <br> ${result.city}, ${result.state} ${result.zip}`
					: null
			}
		},
		menuSerializer(result) {
			return {
				text: result.name
			}
		},
		setBusiness() {
			const businesses = this.$store.getters.businessesMap
			if (this.event.business_id && !this.business.id) {
				const business = businesses[this.event.business_id]
				this.business = business ? Object.assign({}, business) : {}
			}
		},
		setRestaurant() {
			const restaurants = this.$store.state.restaurants
			if (this.event.restaurant_id && !this.restaurant.id) {
				const restaurant = restaurants[this.event.restaurant_id]
				this.restaurant = restaurant
					? Object.assign({}, restaurant)
					: {}
			}
		},
		setLocalEventTimes() {
			if (this.event.start_date) {
				this.localEventTimes.startDate = this.$options.filters.formatEventDate(
					this.event.start_date,
					this.event.start_time,
					this.businessTimezone,
					"ddd MMM DD, YYYY"
				)
			}
			if (this.event.end_date) {
				this.localEventTimes.endDate = this.$options.filters.formatEventDate(
					this.event.end_date,
					this.event.end_time,
					this.businessTimezone,
					"ddd MMM DD, YYYY"
				)
			}
			if (this.event.pre_order_end_date) {
				this.localEventTimes.preorderEndDate = this.$options.filters.formatEventDate(
					this.event.pre_order_end_date,
					this.event.pre_order_end_time,
					this.businessTimezone,
					"ddd MMM DD, YYYY"
				)
			}
			if (this.event.start_time) {
				this.localEventTimes.startTime = this.$options.filters.timeWithOffset(
					this.event.end_date,
					this.event.start_time,
					this.businessTimezone
				)
			}
			if (this.event.end_time) {
				this.localEventTimes.endTime = this.$options.filters.timeWithOffset(
					this.event.end_date,
					this.event.end_time,
					this.businessTimezone
				)
			}
			if (this.event.pre_order_end_time) {
				this.localEventTimes.preorderEndTime = this.$options.filters.timeWithOffset(
					this.event.pre_order_end_date,
					this.event.pre_order_end_time,
					this.businessTimezone
				)
			}
		},
		syncEventTimes() {
			this.event.start_date = null
			this.event.start_time = null
			this.event.end_date = null
			this.event.end_time = null
			this.event.pre_order_end_date = null
			this.event.pre_order_end_time = null

			if (this.event.type == BusinessEventType.VIRTUAL) {
				if (this.localEventTimes.endDate) {
					this.event.end_date = this.removeOffsetFromDate(
						this.localEventTimes.endDate,
						this.localEventTimes.endTime,
						this.businessTimezone
					).format("YYYY-MM-DD")
					this.event.start_date = this.event.end_date
					this.localEventTimes.startDate = this.localEventTimes.endDate
				}
			} else if (this.event.type == BusinessEventType.POPUP) {
				if (this.localEventTimes.startDate) {
					this.event.start_date = this.removeOffsetFromDate(
						this.localEventTimes.startDate,
						this.localEventTimes.startTime,
						this.businessTimezone
					).format("YYYY-MM-DD")

					if (this.localEventTimes.startTime) {
						const start = moment(
							this.localEventTimes.startTime,
							"h:mm A"
						)
						const end = moment(
							this.localEventTimes.endTime,
							"h:mm A"
						)
						const endDateMoment = moment(
							`${this.localEventTimes.startDate} ${this.localEventTimes.endTime}`,
							"ddd MMM DD, YYYY h:mm A"
						)
						if (end.isBefore(start)) {
							endDateMoment.add(1, "day")
						}
						this.event.end_date = this.removeOffsetFromDate(
							endDateMoment.format("YYYY-MM-DD"),
							this.localEventTimes.endTime,
							this.businessTimezone,
							"YYYY-MM-DD",
							"h:mm A"
						).format("YYYY-MM-DD")
						this.localEventTimes.endDate = endDateMoment.format(
							"ddd MMM DD, YYYY"
						)
					} else {
						this.event.end_date = this.removeOffsetFromDate(
							this.localEventTimes.startDate,
							null,
							this.businessTimezone
						).format("YYYY-MM-DD")
						this.localEventTimes.endDate = this.localEventTimes.startDate
					}
				}
			}

			if (this.localEventTimes.startTime) {
				this.event.start_time = this.removeOffsetFromDate(
					this.localEventTimes.startDate,
					this.localEventTimes.startTime,
					this.businessTimezone
				).format("HH:mm:ss")
			}

			if (this.localEventTimes.endTime) {
				this.event.end_time = this.removeOffsetFromDate(
					this.localEventTimes.endDate,
					this.localEventTimes.endTime,
					this.businessTimezone
				).format("HH:mm:ss")
			}

			if (this.localEventTimes.preorderEndTime) {
				const preorderEndDate =
					this.localEventTimes.preorderEndDate == null
						? this.localEventTimes.endDate
						: this.localEventTimes.preorderEndDate

				const preorderMoment = this.removeOffsetFromDate(
					preorderEndDate,
					this.localEventTimes.preorderEndTime,
					this.businessTimezone
				)

				this.event.pre_order_end_date = preorderMoment.format(
					"YYYY-MM-DD"
				)
				this.event.pre_order_end_time = preorderMoment.format(
					"HH:mm:ss"
				)
			}
		},
		businessSelected(business) {
			this.business = business
			this.event.business_id = business.id
		},
		restaurantSelected(restaurant) {
			this.restaurant = restaurant
			this.event.restaurant_id = restaurant.id
			this.menu = {}
			this.event.products = []
			this.event.menu_id = undefined
		},
		menuSelected(menu) {
			this.menu = menu
			this.event.menu_id = menu.id
			this.event.products = []
			this.$store.dispatch(
				`eventsModule/${ActionTypes.GET_EVENT_RESTAURANT_MENU_PRODUCTS}`,
				{
					menuId: menu.id,
					restaurantId: this.event.restaurant_id,
					products: this.event.products
				}
			)
			if (!this.event.delivery_mode) {
				this.event.delivery_mode = menu.delivery_mode
					? menu.delivery_mode
					: DeliveryMode.YAY_FOOD_POLE
			}
		},
		menuInputFocused() {
			if (this.event.restaurant_id) {
				this.$store.dispatch(
					`eventsModule/${ActionTypes.GET_EVENT_RESTAURANT_MENUS}`,
					this.event.restaurant_id
				)

				if (this.$refs.menuTypeahead) {
					this.$refs.menuTypeahead.showResults()
				}

				if (this.event.menu_id) {
					this.$store.dispatch(
						`eventsModule/${ActionTypes.GET_EVENT_RESTAURANT_MENU_PRODUCTS}`,
						{
							menuId: this.event.menu_id,
							restaurantId: this.event.restaurant_id,
							products: this.event.products
						}
					)
				}
			}
		},
		getMenuIfNeeded() {
			if (
				!this.menu.id &&
				this.event.menu_id &&
				this.event.restaurant_id
			) {
				this.loading = true
				return this.$store
					.dispatch(
						`eventsModule/${ActionTypes.GET_EVENT_RESTAURANT_MENUS}`,
						this.event.restaurant_id
					)
					.then(() => {
						const restaurantMenu = this.menus.find(
							(menu) => this.event.menu_id == menu.id
						)
						if (restaurantMenu) {
							this.menu = restaurantMenu
						}
					})
					.finally(() => {
						this.loading = false
					})
			}
		},
		getProductsIfNeeded() {
			if (
				(!this.products || this.products.length == 0) &&
				this.event.menu_id &&
				this.event.restaurant_id
			) {
				this.loading = true
				return this.$store
					.dispatch(
						`eventsModule/${ActionTypes.GET_EVENT_RESTAURANT_MENU_PRODUCTS}`,
						{
							menuId: this.event.menu_id,
							restaurantId: this.event.restaurant_id,
							products: this.event.products
						}
					)
					.finally(() => {
						this.loading = false
					})
			}
		},
		reviewMenuItemsToggled() {
			this.reviewingMenuItems = !this.reviewingMenuItems
			if (this.reviewingMenuItems) {
				this.getProductsIfNeeded()
			}
		},
		canUploadBanner(event) {
			if (!this.event.business_id) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title:
						"Please select a business before uploading an event image.",
					type: NotificationType.WARNING
				})
				event.stopPropagation()
			}
		},
		removeOffsetFromDate(date, time, timezone, dateFormat, timeFormat) {
			let utcDate = date
			if (date && time) {
				let format =
					dateFormat && timeFormat
						? `${dateFormat} ${timeFormat}`
						: "ddd MMM DD, YYYY h:mm A"
				utcDate = moment.tz(`${date} ${time}`, format, timezone).utc()
			} else if (time) {
				let format =
					dateFormat && timeFormat
						? `${dateFormat} ${timeFormat}`
						: "ddd MMM DD, YYYY h:mm A"
				let currentDate = moment().format("ddd MMM DD, YYYY")
				utcDate = moment
					.tz(`${currentDate} ${time}`, format, timezone)
					.utc()
			} else {
				let format = dateFormat ? dateFormat : "ddd MMM DD, YYYY"
				utcDate = moment(date, format)
			}
			return utcDate
		},
		onValueMoveEnd(event) {
			if (event.oldIndex != event.newIndex) {
				const productId = this.products[event.oldIndex].id
				const productOrder = this.event.products
					.filter((product) => product.id != productId)
					.map((product) => product.id)

				productOrder.splice(event.newIndex, 0, productId)
				this.event.products.forEach((product) => {
					if (productOrder.indexOf(product.id) >= 0) {
						product.position = productOrder.indexOf(product.id)
					}
				})
			}
		},
		toggleEventProduct(selectedProduct) {
			const productIndex = this.event.products.findIndex(
				(product) => product.id == selectedProduct.id
			)
			if (productIndex >= 0) {
				this.event.products.splice(productIndex, 1)
			} else {
				selectedProduct.position = this.event.products.length
				this.event.products.push(selectedProduct)
			}
		}
	},
	watch: {
		allowPreorders(allow) {
			if (!allow) {
				this.localEventTimes.preorderEndDate = null
				this.localEventTimes.preorderEndTime = null
			}
		}
	}
}
</script>