<template>
	<SkBaseModal
		class="sk-modal-alt"
		:isOpen="isOpen"
		:headerColor="'#2E3C49'"
		:hasLoader="true"
		@close="close"
	>
		<div class="sk-modal-banner"></div>
		<div class="sk-row">
			<h2 class="welcome-title">Welcome to 2ndKitchen!</h2>
		</div>
		<div class="sk-row">
			<h1 class="sk-modal-title">Get Started with Training</h1>
		</div>
		<div class="sk-row staff-training-description">
			Before you begin exploring the dashboard, complete a quick
			2ndKitchen training session and earn a free meal!
		</div>
		<div
			class="sk-modal-actions"
			v-if="paperformPrefill && paperformFormName"
		>
			<button
				class="button button-primary"
				@click="openStaffTrainingForm"
				:data-paperform-id="paperformFormName"
				:data-prefill="paperformPrefill"
				data-popup-button="1"
			>
				Start Training
			</button>
		</div>
	</SkBaseModal>
</template>

<style scoped>
.sk-modal-alt >>> .sk-modal-body-top-radius {
	display: none;
}

.sk-modal-banner img {
	width: 140px;
}

.sk-modal-title {
	margin-bottom: 10px;
}

.welcome-title {
	color: var(--sk-orange);
	font-size: 12px;
	margin-bottom: -10px;
}

.staff-training-description {
	color: var(--sk-grey3);
	font-size: 12px;
	margin-bottom: 40px !important;
}

.sk-modal-title,
.welcome-title,
.staff-training-description {
	text-align: center;
}

.sk-modal-banner {
	background: url("/images/staff-training.jpg");
	background-size: contain;
	border-top-right-radius: 5px;
	border-top-left-radius: 5px;
	margin-top: -25px;
}

.sk-modal-actions {
	justify-content: center;
}
</style>

<script>
import { UserSubRole } from "@/enums/userRole"
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"

export default {
	name: "StaffTrainingIntroModal",
	mixins: [skModalMixin],
	components: {
		SkBaseModal
	},
	props: {
		manual: Boolean
	},
	data: function () {
		return {
			paperformOpen: false
		}
	},
	computed: {
		paperformFormName() {
			if (this.$store.getters.business) {
				let isHotel = this.$store.getters.hasHotel
				return isHotel
					? this.$paperformHotelName
					: this.$paperformDrinkingPlaceName
			}
		},
		paperformPrefill() {
			if (this.$store.getters.business) {
				let isHotel = this.$store.getters.hasHotel
				let slugKey = isHotel
					? this.$paperformHotelSlugKey
					: this.$paperformDrinkingPlaceSlugKey
				return `${slugKey}=${this.$store.getters.business.slug}`
			}
			return null
		}
	},
	methods: {
		openStaffTrainingForm() {
			this.paperformOpen = true
			this.close()
		}
	},
	mounted() {
		if (!this.manual) {
			eventBus.on(EventBusEvents.LOGGED_IN, () => {
				const hasHotel = this.$store.getters.hasHotel
				const hasDrinkingPlace = this.$store.getters.hasDrinkingPlace
				const isStaff =
					this.$store.state.user.sub_role_id == UserSubRole.STAFF
				const requiresTraining =
					this.$store.state.user.training_complete == 0
				if (isStaff && requiresTraining && (hasHotel || hasDrinkingPlace)) {
					this.open()
				}
			})
		}

		window.addEventListener("PaperformSubmission", () => {
			if (this.paperformOpen) {
				this.paperformOpen = false
				const paperformContainerQuery = document.getElementsByClassName(
					"Paperform__popupwrapperParent"
				)
				if (paperformContainerQuery.length > 0) {
					const paperformContainer = paperformContainerQuery[0]
					document.body.removeChild(paperformContainer)
					document.body.classList.remove("Paperform--locked")
				}
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Staff Training",
					message:
						"Thanks for completing your 2ndKitchen training. Check your email for your free meal voucher!",
					type: NotificationType.SUCCESS
				})
			}
		})
	}
}
</script>