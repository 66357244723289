import { v1 as uuidv1 } from "uuid"
export default class OrderAction {
    constructor(data) {
        this.name = data.name
        this.type = data.type
        this.reason = data.reason
        this.status = data.status
        this.amount = data.amount ? data.amount : ""
        this.id = data.id != undefined ? data.id : uuidv1()
    }
}