<template>
	<div>
		<transition-expand>
			<div
				class="sk-row sk-widget integrations-explainer"
				v-if="showExplainer"
			>
				<div>
					<div class="integrations-explainer-title">
						Utilize your tools better with 2ndKitchen
					</div>
					<div class="integrations-explainer-subtitle">
						With 2ndKitchen, you can integrate tools you already use
						to help streamline common tasks, reduce extra hardware,
						and more!
					</div>
					<div>
						<span>
							Looking for an integration you don’t see here?
						</span>
						<button
							class="button button-primary"
							@click="requestIntegration"
						>
							Request It!
						</button>
					</div>
				</div>
				<img
					class="integrations-explainer-img"
					src="/images/2k-integrations.png"
					alt="Integrations Image"
				/>
				<button class="button button-icon" @click="hideExplainer">
					<i class="sk-icon-times-regular"></i>
				</button>
			</div>
		</transition-expand>
		<div>
			<div class="sk-row integration-section-title">Available</div>
			<div class="sk-grid">
				<IntegrationOption
					v-for="integration in availableIntegrations"
					:key="integration.id"
					:integration="integration"
					@open="integrationClicked"
				/>
			</div>
			<div class="sk-row integration-section-title">Coming Soon</div>
			<div class="sk-grid">
				<IntegrationOption
					v-for="integration in comingSoonIntegrations"
					:key="integration.id"
					:integration="integration"
				/>
				<IntegrationOption
					:isPlaceholder="true"
					@request="requestIntegration"
				/>
			</div>
		</div>
		<IntegrationInfoModal
			ref="integrationInfoModal"
			@addIntegration="openAuthenticationFlow"
			@editIntegration="openEditIntegrationModal"
			@removeIntegration="openRemoveIntegrationModal"
		/>
		<RequestIntegrationModal ref="requestIntegrationModal" />
		<RemoveIntegrationModal ref="removeIntegrationModal" />
		<SquareIntegrationModal ref="squareIntegrationModal" />
		<ChowlyIntegrationModal ref="chowlyIntegrationModal" />
		<UntappdIntegrationModal ref="untappdIntegrationModal" />
		<OrdermarkIntegrationModal ref="ordermarkIntegrationModal" />
		<ReefIntegrationModal ref="reefIntegrationModal" />
		<GrubhubIntegrationModal ref="grubhubIntegrationModal" />
	</div>
</template>

<style scoped>
.integrations-explainer {
	background: var(--sk-dark-navy);
	color: var(--sk-white);
	font-size: 14px;
	justify-content: space-between;
	position: relative;
}

.integrations-explainer > div {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 145px;
}

.integrations-explainer-title {
	font-size: 20px;
}

.integrations-explainer-subtitle {
	font-size: 15px;
}

.integrations-explainer-title,
.integrations-explainer-subtitle {
	font-weight: 600;
}

.integrations-explainer .button:not(.button-icon) {
	min-height: 25px;
	font-weight: 400;
	margin-left: 15px;
}

.integrations-explainer .button-icon {
	position: absolute;
	top: 0;
	right: 5px;
}

.integrations-explainer-img {
	width: 122px;
	margin-left: 20px;
	align-self: center;
}

.integration-section-title {
	font-size: 14px;
	font-weight: 600;
	color: var(--sk-navy);
}

.sk-grid {
	grid-template-columns: 1fr 1fr 1fr 1fr;
	max-width: calc((250px * 4) + (20px * 3));
}

@media (min-width: 1200px) {
	.integrations-explainer-img {
		margin-right: 80px;
	}
}

@media (max-width: 980px) {
	.sk-grid {
		grid-template-columns: 1fr 1fr 1fr;
		max-width: calc((250px * 3) + (20px * 2));
	}
}

@media (max-width: 730px) {
	.sk-grid {
		grid-template-columns: 1fr 1fr;
		max-width: calc((250px * 2) + 20px);
	}
}

@media (max-width: 650px) {
	.integrations-explainer {
		flex-direction: column-reverse;
	}

	.integrations-explainer > div {
		align-items: center;
	}

	.integrations-explainer-title {
		margin-bottom: 15px;
		margin-top: 15px;
		text-align: center;
	}
	.integrations-explainer-subtitle {
		margin-bottom: 15px;
		text-align: center;
	}

	.integrations-explainer-img {
		margin: 0;
	}
}

@media (max-width: 520px) {
	.sk-grid {
		grid-template-columns: 1fr;
		max-width: 250px;
	}
}
</style>

<script>
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import { IntegrationType } from "@/enums/integrationType"
import tracker from "@/utils/tracker"
import utils from "@/utils/utils"
import oauth from "@/utils/oauth"
import loaderMixin from "@/mixins/loader-mixin"
import IntegrationOption from "@/components/IntegrationOption.vue"
import TransitionExpand from "@/components/transitions/TransitionExpand.vue"
import RequestIntegrationModal from "@/components/modals/RequestIntegrationModal.vue"
import RemoveIntegrationModal from "@/components/modals/RemoveIntegrationModal.vue"
import IntegrationInfoModal from "@/components/modals/IntegrationInfoModal.vue"
import SquareIntegrationModal from "@/components/modals/SquareIntegrationModal.vue"
import ChowlyIntegrationModal from "@/components/modals/ChowlyIntegrationModal.vue"
import UntappdIntegrationModal from "@/components/modals/UntappdIntegrationModal.vue"
import OrdermarkIntegrationModal from "@/components/modals/OrdermarkIntegrationModal.vue"
import ReefIntegrationModal from "@/components/modals/ReefIntegrationModal.vue"
import GrubhubIntegrationModal from "@/components/modals/GrubhubIntegrationModal.vue"

export default {
	name: "RestaurantIntegrations",
	mixins: [loaderMixin],
	components: {
		IntegrationOption,
		TransitionExpand,
		RequestIntegrationModal,
		RemoveIntegrationModal,
		IntegrationInfoModal,
		SquareIntegrationModal,
		ChowlyIntegrationModal,
		UntappdIntegrationModal,
		OrdermarkIntegrationModal,
		ReefIntegrationModal,
		GrubhubIntegrationModal
	},
	computed: {
		showExplainer() {
			return !this.$store.state.restaurantsModule.hideIntegrationExplainer
		},
		restaurant() {
			return this.$store.state.restaurantsModule.currentRestaurant
		},
		availableIntegrations() {
			return this.$store.state.restaurantsModule.integrations.filter(
				(integration) =>
					integration.isAvailable &&
					integration.type != IntegrationType.STARMICRONICS
			)
		},
		comingSoonIntegrations() {
			return this.$store.state.restaurantsModule.integrations.filter(
				(integration) => !integration.isAvailable
			)
		}
	},
	methods: {
		hideExplainer() {
			tracker.trackEvent("Hide Explainer Clicked")
			this.$store.dispatch("restaurantsModule/hideIntegrationsExplainer")
		},
		async integrationClicked(integration) {
			tracker.trackEvent("Integration Option Clicked", {
				type: integration.type
			})
			let extraParams = null
			if (integration.type == IntegrationType.REEF && integration.isEnabled){
				extraParams = await this.$store.dispatch(
							"restaurantsModule/getRestaurantBrands", integration.restaurant_id
						)
			}
			this.$refs.integrationInfoModal.open({
				integration: integration,
				extraParams: extraParams
			})
		},
		requestIntegration() {
			tracker.trackEvent("Integration Request Clicked")
			this.$refs.requestIntegrationModal.open()
		},
		showError(title, message) {
			eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
				title: title,
				message: message,
				type: NotificationType.ERROR
			})
		},
		async openAuthenticationFlow(integration) {
			try {
				let success = false
				let authToken = null
				if (integration.requiresOauth) {
					this.toggleLoader(true)
					authToken = await oauth.authenticate(integration)
					this.toggleLoader(false)
					if (!authToken) {
						this.showError(
							`${integration.name} Integration`,
							"Something went wrong, try again"
						)
						return
					}
				}

				const params = await this.getIntegrationParams(integration)
				if (!params) {
					return
				}

				this.toggleLoader(true)
				success = await this.$store.dispatch(
					"restaurantsModule/addRestaurantIntegration",
					{
						integration: integration,
						params: params,
						authToken: authToken
					}
				)

				if (success && integration.type == IntegrationType.SQUARE) {
					success = await this.postSquareAuthentication()
				}
				if (success) {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: `${integration.name} Integration`,
						message: "Integration Complete!"
					})
				} else {
					this.showError(
						`${integration.name} Integration`,
						"Something went wrong, try again"
					)
				}
				this.toggleLoader(false)
			} catch (e) {
				this.toggleLoader(false)
				if (e) {
					throw e
				}
			}
		},
		getIntegrationParams(integration) {
			switch (integration.type) {
				case IntegrationType.ORDERMARK: {
					return this.$refs.ordermarkIntegrationModal.open()
				}
				case IntegrationType.CHOWLY: {
					return this.$refs.chowlyIntegrationModal.open()
				}
				case IntegrationType.UNTAPPD: {
					return this.$refs.untappdIntegrationModal.open()
				}
				case IntegrationType.SQUARE: {
					const promise = utils.createPromise()
					promise.resolve({})
					return promise.promise
				}
				case IntegrationType.OTTER: {
					return {}
				}
				case IntegrationType.REEF: {
					return this.$refs.reefIntegrationModal.open()
				}
				case IntegrationType.GRUBHUB: {
					return this.$refs.grubhubIntegrationModal.open()
				}
			}
		},
		async postSquareAuthentication() {
			try {
				await this.$store.dispatch(
					"restaurantsModule/getRestaurantSquareLocations"
				)
				const params = await this.$refs.squareIntegrationModal.open()
				if (params) {
					await this.$store.dispatch(
						"restaurantsModule/updateRestaurantIntegration",
						{
							integrationType: IntegrationType.SQUARE,
							params: params
						}
					)
					return true
				}
				return false
			} catch (e) {
				return false
			}
		},
		async openEditIntegrationModal(integration, extraParams) {
			try {
				let promise = null
				switch (integration.type) {
					case IntegrationType.UNTAPPD:
						promise = this.$refs.untappdIntegrationModal.open({
							integration: integration
						})
						break
					case IntegrationType.SQUARE: {
						promise = this.$refs.squareIntegrationModal.open({
							integration: integration
						})
						break
					}
					case IntegrationType.CHOWLY:
						promise = this.$refs.chowlyIntegrationModal.open({
							integration: integration
						})
						break
					case IntegrationType.ORDERMARK:
						promise = this.$refs.ordermarkIntegrationModal.open({
							integration: integration
						})
						break
					case IntegrationType.REEF:
						promise = this.$refs.reefIntegrationModal.open({
							integration: integration,
							brands: extraParams
						})
						break
					case IntegrationType.GRUBHUB:
						promise = this.$refs.grubhubIntegrationModal.open({
							integration: integration
						})
						break
				}
				if (promise) {
					const params = await promise
					if (!params) {
						return
					}
					this.toggleLoader(true)
					const success = await this.$store.dispatch(
						"restaurantsModule/updateRestaurantIntegration",
						{
							integrationType: integration.type,
							params: params
						}
					)
					this.toggleLoader(false)
					if (success) {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: `${integration.name} Integration`,
							message: "Integration Updated!"
						})
					} else {
						this.showError(
							`${integration.name} Integration`,
							"Something went wrong, try again"
						)
					}
				}
			} catch (e) {
				this.toggleLoader(false)
				throw e
			}
		},
		openRemoveIntegrationModal(integration) {
			this.$refs.removeIntegrationModal.open({
				integration: integration
			})
		}
	},
	mounted() {
		this.toggleLoader(true)
		this.$store
			.dispatch("restaurantsModule/getRestaurantIntegrations")
			.finally(() => {
				this.toggleLoader(false)
			})
	}
}
</script>