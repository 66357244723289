<template>
	<div class="restaurant-item-wrapper">
		<div class="restaurant-item sk-widget">
			<div
				class="restaurant-item-content"
				:class="{ archived: isArchived }"
			>
				<div>
					<div>
						<h2>
							{{ product.name }}
							<RestaurantProductLabelIcons
								:labels="product.labels"
							/>
						</h2>
					</div>
					<div class="product-description">
						{{ product.description }}
					</div>
					<div class="product-price">
						{{ product.cogs | currency }}
					</div>
					<div class="sk-pills-container" v-if="product.menus">
						<router-link
							v-for="menu in product.menus"
							:key="menu.id"
							:to="menu.link"
							class="pill pill-grey"
							:title="menu.name"
							target="_blank"
						>
							<i class="sk-icon-link-regular"></i>
							{{ menu.name }}
						</router-link>
					</div>
				</div>
				<div
					class="product-img"
					:style="{ backgroundImage: `url(${backgroundPath})` }"
				></div>
				<div
					v-if="canBatchEditProducts"
					class="batch-select-checkbox"
					:class="{ checked: batchSelected }"
					@click="batchSelectClicked($event)"
				>
					<SkCheckbox
						class="sk-checkbox-primary"
						:checked="batchSelected"
					/>
				</div>
			</div>
			<RestaurantProductFooter
				:product="product"
				@actionSelected="actionSelected"
			/>
		</div>
	</div>
</template>

<style scoped>
.sk-pills-container {
	margin-top: 12px;
}

.restaurant-item-wrapper {
	min-width: 0;
	position: relative;
}

.restaurant-item-content > div {
	transition: opacity 0.3s ease;
}

.archived > div {
	opacity: 0.25;
}

.product-img {
	transition: all 0.3s ease !important;
}

.archived .product-img {
	filter: grayscale(1);
	opacity: 1;
}

.batch-select-checkbox {
	display: none;
	position: absolute;
	top: 10px;
	right: 10px;
}

.batch-select-checkbox.checked {
	display: block;
}

.batch-select-checkbox >>> .sk-checkbox {
	margin: 0;
	width: 25px;
	height: 25px;
}

.batch-select-checkbox >>> .sk-checkbox input:checked + span i {
	font-size: 15px;
}

.restaurant-item-wrapper:hover .batch-select-checkbox {
	display: block;
}
</style>

<script>
import { ProductStatus } from "@/enums/productStatus"
import { PermissionMethod } from "@/utils/permissions"
import currencyFilterMixin from "@/mixins/currency-filter-mixin"
import batchSelectableMixin from "@/mixins/batch-selectable-mixin"
import RestaurantProductLabelIcons from "@/components/restaurant/RestaurantProductLabelIcons.vue"
import RestaurantProductFooter from "@/components/restaurant/RestaurantProductFooter.vue"
import SkCheckbox from "@/components/SkCheckbox.vue"

export default {
	name: "RestaurantProduct",
	mixins: [currencyFilterMixin, batchSelectableMixin],
	components: {
		RestaurantProductFooter,
		RestaurantProductLabelIcons,
		SkCheckbox
	},
	props: {
		product: Object
	},
	computed: {
		backgroundPath() {
			if (this.product.img) {
				const filenameArr = this.product.img.split(".")
				const sizedFileName =
					filenameArr[0] + "_" + "500x500." + filenameArr[1]
				return (
					this.$restaurantCDNURL +
					this.product.restaurant_slug +
					"/" +
					sizedFileName
				)
			} else {
				return ""
			}
		},
		isArchived() {
			return this.product.status == ProductStatus.ARCHIVED
		},
		canBatchEditProducts() {
			return this.$store.state.permissions.includes(
				PermissionMethod.BATCH_UPDATE_RESTAURANT_ITEMS
			)
		}
	},
	methods: {
		actionSelected(actionId) {
			switch (actionId) {
				case 0:
					this.$emit("preview", this.product)
					break
				case 1:
					this.$emit("duplicate", this.product)
					break
				case 2:
					this.$emit("delete", this.product)
					break
				case 3:
					this.$emit("edit", this.product)
					break
				case 4:
					this.$emit("archive", this.product)
					break
				case 5:
					this.$emit("unarchive", this.product)
					break
			}
		}
	}
}
</script>
