export const GOT_PREPACKAGED_ORDERS = "GOT_PREPACKAGED_ORDERS"
export const GOT_WEEKLY_PREPACKAGED_ORDERS = "GOT_WEEKLY_PREPACKAGED_ORDERS"
export const GOT_PREPACKAGED_PRODUCTS = "GOT_PREPACKAGED_PRODUCTS"
export const CLEAR_PREPACKAGED_PRODUCTS = "CLEAR_PREPACKAGED_PRODUCTS"
export const ADDED_PREPACKAGED_ORDERS = "ADDED_PREPACKAGED_ORDERS"
export const UPDATED_PREPACKAGED_ORDER = "UPDATED_PREPACKAGED_ORDER"
export const CANCELLED_PREPACKAGED_ORDER = "CANCELLED_PREPACKAGED_ORDER"

export default {
	GOT_WEEKLY_PREPACKAGED_ORDERS,
	GOT_PREPACKAGED_ORDERS,
	GOT_PREPACKAGED_PRODUCTS,
	CLEAR_PREPACKAGED_PRODUCTS,
	ADDED_PREPACKAGED_ORDERS,
	UPDATED_PREPACKAGED_ORDER,
	CANCELLED_PREPACKAGED_ORDER
}