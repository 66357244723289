import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import { store } from "./store/store"
import moment from "moment-timezone"
import axios from "axios"
import VueAxios from "vue-axios"

// Import component
import Loading from "vue-loading-overlay"
import * as VueGoogleMaps from "vue2-google-maps"

// Import stylesheets
import "vue-loading-overlay/dist/vue-loading.css"
import "vue2-dropzone/dist/vue2Dropzone.min.css"
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css"
import "chartist-plugin-tooltips/dist/chartist-plugin-tooltip.css"
import "@/assets/styles/main.css"
import "intl-tel-input/build/css/intlTelInput.css"
import VueRx from "vue-rx"

import * as uiv from "uiv"
import "@/directives"

import BugsnagPluginVue from "@bugsnag/plugin-vue"
const bugsnagPluginVue = new BugsnagPluginVue(Vue)

import skPusher from "@arikgaisler/pusher"

if (window["bugsnagClient"]) {
	bugsnagPluginVue.load(window["bugsnagClient"])
}

dwolla.configure("prod")
Vue.use(VueAxios, axios)
Vue.use(Loading, {
	color: "#000000",
	loader: "spinner",
	width: 64,
	height: 64,
	backgroundColor: "#ffffff",
	opacity: 0.5
})
Vue.use(uiv)
Vue.use(moment)
Vue.use(VueGoogleMaps, {
	load: {
		key: process.env.VUE_APP_GAPI_KEY,
		libraries: "places,geometry"
	},
	installComponents: true
})
Vue.use(skPusher)
Vue.use(VueRx)

const host = process.env.VUE_APP_API_HOST

Vue.config.productionTip = false
Vue.prototype.bugsnag = window["bugsnagClient"]

Vue.prototype.$businessCDNURL = `${process.env.VUE_APP_BUSINESS_CDN_URL}/`
Vue.prototype.$eventsCDNURL = `${process.env.VUE_APP_IMAGES_CDN_URL}/events/`
Vue.prototype.$invoiceCDNURL = `${process.env.VUE_APP_FINANCE_CDN_URL}/invoices/`
Vue.prototype.$restaurantCDNURL = `${process.env.VUE_APP_IMAGES_CDN_URL}/restaurants/`
Vue.prototype.$chefCDNURL = `${process.env.VUE_APP_IMAGES_CDN_URL}/chef/`
Vue.prototype.$financeS3URL = `${process.env.VUE_APP_FINANCE_CDN_URL}/`
Vue.prototype.$webappUrl = `https://order${host}.2ndkitchen.com`
Vue.prototype.$mobileWidth = 1024
Vue.prototype.$paperformHotelName = "stafftraining"
Vue.prototype.$paperformDrinkingPlaceName = "barstaff"
Vue.prototype.$paperformHotelSlugKey = "dk26c"
Vue.prototype.$paperformDrinkingPlaceSlugKey = "dk26c"

new Vue({
	router,
	store,
	render: (h) => h(App)
}).$mount("#app")
