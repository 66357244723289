<template>
	<div class="restaurant-item sk-widget">
		<div class="restaurant-item-content">
			<div>
				<h2>
					{{ product.name }}
					<RestaurantProductLabelIcons :labels="product.labels" />
				</h2>
				<div class="product-description">{{ product.description }}</div>
				<div class="product-price">{{ product.cogs | currency }}</div>
			</div>
			<div
				class="product-img"
				:style="{ backgroundImage: `url(${backgroundPath})` }"
			>
				<button class="button button-default" @click="remove">
					<i class="sk-icon-times-regular"></i>
					Remove
				</button>
			</div>
		</div>
	</div>
</template>

<style scoped>
.restaurant-item-content .product-img {
	margin-bottom: -15px;
	position: relative;
}

.button-default {
	min-height: 30px;
	position: absolute;
	top: 13px;
	right: 10px;
	padding: 0 15px;
	align-items: center;
	display: flex;
	transition: 0.35s cubic-bezier(0.58, -0.46, 1, 1.19);
	opacity: 0;
	transform: translate(0, -10px);
	pointer-events: none;
}

.restaurant-item:hover .button-default {
	opacity: 1;
	transform: translate(0, 0);
	pointer-events: all;
}

.button-default,
.button-default i {
	font-size: 12px;
}
</style>

<script>
import currencyFilterMixin from "@/mixins/currency-filter-mixin"
import RestaurantProductLabelIcons from "@/components/restaurant/RestaurantProductLabelIcons.vue"

export default {
	name: "RestaurantMenuItem",
	mixins: [currencyFilterMixin],
	components: {
		RestaurantProductLabelIcons
	},
	props: {
		product: Object
	},
	computed: {
		backgroundPath() {
			if (this.product.img) {
				const filenameArr = this.product.img.split(".")
				const sizedFileName =
					filenameArr[0] + "_" + "500x500." + filenameArr[1]
				return (
					this.$restaurantCDNURL +
					this.product.restaurant_slug +
					"/" +
					sizedFileName
				)
			} else {
				return ""
			}
		}
	},
	methods: {
		remove() {
			this.$emit("remove", this.product)
		}
	}
}
</script>
