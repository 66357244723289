<template>
	<div class="active-orders">
		<transition-group
			v-if="!loading && pagedOrders.length > 0"
			name="list"
			tag="div"
			appear
		>
			<ActiveOrder
				v-for="order in pagedOrders"
				:key="order.id"
				:order="order"
				@openDetails="openActiveOrderDetails(order)"
				@reportIssue="openActiveOrderDetails(order, true)"
				@disputeCharge="openActiveOrderDetails(order, false, true)"
			/>
		</transition-group>
		<EmptyPlaceholderWidget
			v-else-if="!loading"
			:title="'No Active Orders'"
			:buttonText="'Refresh'"
			:buttonIcon="'sk-icon-sync-regular'"
			@actionClicked="placeholderActionClicked"
		/>
		<PaginationFooter
			v-if="!loading && pagedOrders.length > 0"
			ref="paginationFooter"
			:contentText="'Active Orders'"
			:pageSize="20"
			:total="filteredOrders.length"
			@pageChanged="filterPage"
		/>
		<ActiveOrderDetailsModal ref="activeOrderDetailsModal" />
	</div>
</template>

<style scoped>
.active-orders {
	height: 100%;
	display: flex;
	flex-direction: column;
}
</style>

<script>
import orderHelper from "@/utils/order-helper"
import PaginationFooter from "@/components/PaginationFooter.vue"
import ActiveOrder from "@/components/currentorders/ActiveOrder.vue"
import EmptyPlaceholderWidget from "@/components/EmptyPlaceholderWidget.vue"
import ActiveOrderDetailsModal from "@/components/modals/ActiveOrderDetailsModal.vue"

export default {
	name: "ActiveOrders",
	components: {
		PaginationFooter,
		ActiveOrder,
		EmptyPlaceholderWidget,
		ActiveOrderDetailsModal
	},
	props: {
		filterVals: Object
	},
	data() {
		return {
			timer: null,
			pageRange: {
				page: 1,
				start: 0,
				end: 20
			}
		}
	},
	computed: {
		loading() {
			return this.$store.state.ordersModule.loading
		},
		currentOrders() {
			const orders = this.$store.state.ordersModule.currentOrders
				? this.$store.state.ordersModule.currentOrders
				: []

			return orders.filter((order) => {
				const isUpcomingOrder = orderHelper.isUpcomingOrder(order)
				if (isUpcomingOrder) {
					return false
				}

				return true
			})
		},
		filteredOrders() {
			return this.currentOrders.filter((order) => {
				if (
					this.filterVals.orderId != null &&
					order.id != this.filterVals.orderId
				) {
					return false
				}

				if (this.filterVals.username) {
					const orderUsername = order.user_name
						.toLowerCase()
						.normalize("NFD")
						.replace(/[\u0300-\u036f]/g, "")
					const usernameFilter = this.filterVals.username
						.toLowerCase()
						.normalize("NFD")
						.replace(/[\u0300-\u036f]/g, "")
					if (!orderUsername.includes(usernameFilter)) {
						return false
					}
				}

				if (
					this.filterVals.phone &&
					!order.user_phone
						.replace(/[()\s-]/g, "")
						.includes(this.filterVals.phone.replace(/[()\s-]/g, ""))
				) {
					return false
				}

				return true
			})
		},
		pagedOrders() {
			return this.filteredOrders.slice(
				this.pageRange.start,
				this.pageRange.end
			)
		}
	},
	methods: {
		filterPage(range) {
			this.pageRange = range
		},
		openActiveOrderDetails(order, reportIssue, disputeCharge) {
			this.$refs.activeOrderDetailsModal.open({
				order: order,
				reportIssue: reportIssue,
				disputeCharge: disputeCharge
			})
		},
		placeholderActionClicked() {
			location.reload()
		},
		resetPagination() {
			if (this.$refs.paginationFooter) {
				this.$refs.paginationFooter.pageChanged(1)
			}
		}
	},
	mounted: function () {
		this.$store.dispatch("businessesModule/clearBusiness")
		this.$store.dispatch("ordersModule/getActiveOrdersData", true)
		if (this.timer == null) {
			this.timer = setInterval(() => {
				this.$store.dispatch("ordersModule/getActiveOrdersData", false)
			}, 300000)
		}
	},
	beforeDestroy: function () {
		if (this.timer != null) {
			clearInterval(this.timer)
			this.timer = null
		}
	},
	watch: {
		filterVals: {
			immediate: true,
			handler() {
				this.resetPagination()
			}
		}
	}
}
</script>