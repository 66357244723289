<template>
	<div class="sk-page-content">
		<PageHeader
			ref="pageHeader"
			:title="'Finance'"
			:tabs="tabs"
			:filters="filters"
			:actions="actions"
			:secondaryActions="secondaryActions"
			@actionClicked="headerActionClicked"
			@secondaryActionClicked="headerSecondaryActionClicked"
			@filterSet="filterSet"
			@filtersReset="clearFilters"
			@tabClicked="headerTabClicked"
		/>
		<transition name="slide" mode="out-in">
			<Exceptions
				:key="financeTabs.EXCEPTIONS"
				ref="exceptions"
				v-if="activeTab.id == financeTabs.EXCEPTIONS"
				:restaurantId="restaurantId"
				:dateFrom="startDate"
				:dateTo="endDate"
				:selectedIds.sync="batchSelectedIds"
			/>
			<Refunds
				:key="financeTabs.REFUNDS"
				v-if="activeTab.id == financeTabs.REFUNDS"
				ref="refunds"
				:dateFrom="startDate"
				:dateTo="endDate"
				:selectedIds.sync="batchSelectedIds"
			/>
			<RestaurantPayouts
				:key="financeTabs.RESTAURANT_PAYOUTS"
				v-if="activeTab.id == financeTabs.RESTAURANT_PAYOUTS"
				ref="payouts"
				:restaurantId="restaurantId"
				:dateFrom.sync="startDate"
				:dateTo.sync="endDate"
				:method="fundingSourceMethod"
				:w9Linked="w9Linked"
				:status="payoutStatus"
				:selectedIds.sync="batchSelectedIds"
				@setDefaultDate="setDefaultDate"
			/>
			<PayoutLocations
				:key="financeTabs.PAYOUT_LOCATIONS"
				v-if="activeTab.id == financeTabs.PAYOUT_LOCATIONS"
				:restaurantId="restaurantId"
				:w9Linked="w9Linked"
				:bankLinked="bankLinked"
			/>
			<InvoiceLocations
				:key="financeTabs.INVOICE_LOCATIONS"
				v-if="activeTab.id == financeTabs.INVOICE_LOCATIONS"
				:dateFrom="startDate"
				:dateTo="endDate"
				:businessId="businessId"
				:w9Linked="w9Linked"
				:bankLinked="bankLinked"
			/>
			<Invoices
				:key="financeTabs.INVOICES"
				v-if="activeTab.id == financeTabs.INVOICES"
				ref="invoices"
				:dateFrom="startDate"
				:dateTo="endDate"
				:businessId="businessId"
				:status="invoiceStatus"
				:selectedIds.sync="batchSelectedIds"
			/>
		</transition>
		<ConfirmationModal ref="confirmationModal" />
	</div>
</template>

<style>
.reject-refunds-icon,
.approve-refunds-icon {
	width: 15px;
}

.approve-refunds-icon {
	color: var(--sk-green);
}

.reject-refunds-icon {
	color: var(--sk-red);
}
</style>

<script>
import { UserRole } from "@/enums/userRole"
import { ConfirmationModalType } from "@/enums/confirmationModalType"
import { FundingSource } from "@/enums/fundingSource"
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import { saveAs } from "file-saver"
import { InvoiceStatus } from "@/enums/invoiceStatus"
import { PermissionTab } from "@/utils/permissions"
import { PayoutStatus } from "@/enums/payoutStatus"
import ActionTypes from "@/store/modules/finance/action-types"
import XLSX from "xlsx"
import xlsx_helper from "@/utils/xlsx-helper"
import moment from "moment"
import pluralizeFilterMixin from "@/mixins/pluralize-filter-mixin"
import PageHeader from "@/components/PageHeader.vue"
import Exceptions from "@/components/finance/Exceptions.vue"
import Refunds from "@/components/finance/Refunds.vue"
import Invoices from "@/components/finance/Invoices.vue"
import RestaurantPayouts from "@/components/finance/RestaurantPayouts.vue"
import PayoutLocations from "@/components/finance/PayoutLocations.vue"
import InvoiceLocations from "@/components/finance/InvoiceLocations.vue"
import loaderMixin from "@/mixins/loader-mixin"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue"

const FinanceTab = {
	EXCEPTIONS: 0,
	REFUNDS: 1,
	RESTAURANT_PAYOUTS: 2,
	PAYOUT_LOCATIONS: 3,
	INVOICE_LOCATIONS: 4,
	INVOICES: 5
}

export default {
	name: "Finance",
	mixins: [loaderMixin, pluralizeFilterMixin],
	components: {
		PageHeader,
		Exceptions,
		Refunds,
		Invoices,
		RestaurantPayouts,
		PayoutLocations,
		InvoiceLocations,
		ConfirmationModal
	},
	data: function () {
		return {
			didMount: false,
			financeTabs: FinanceTab,
			activeTabId: FinanceTab.EXCEPTIONS,
			restaurantId: -1,
			businessId: -1,
			startDate: null,
			endDate: null,
			fundingSourceMethod: -1,
			w9Linked: -1,
			bankLinked: -1,
			batchSelectedIds: [],
			invoiceStatus: -1,
			payoutStatus: PayoutStatus.PENDING
		}
	},
	created: function () {
		if (
			this.$store.state.permissions.includes(
				PermissionTab.FINANCE_EXCEPTIONS
			)
		) {
			this.activeTabId = FinanceTab.EXCEPTIONS
		} else if (
			this.$store.state.permissions.includes(
				PermissionTab.FINANCE_PAYOUT_LOCATIONS
			)
		) {
			this.activeTabId = FinanceTab.PAYOUT_LOCATIONS
		} else {
			this.activeTabId = FinanceTab.INVOICE_LOCATIONS
		}
	},
	mounted: function () {
		this.toggleLoader(true)
		this.$store.dispatch(`financeModule/${ActionTypes.GET_FINANCE}`)
		this.$store.dispatch(
			`financeModule/${ActionTypes.CLEAR_CURRENT_FINANCE_DETAILS}`
		)
		this.didMount = true
	},
	methods: {
		selectTab(tab) {
			this.$router.replace({ query: { t: tab } })
		},
		headerTabClicked(tabClicked) {
			this.activeTabId = tabClicked.id
			if (this.activeTabId == FinanceTab.INVOICES) {
				this.invoiceStatus = InvoiceStatus.PENDING
				this.$nextTick(() => {
					const filter = this.filters.find((filter) => filter.id == 7)
					const option = filter.items.find(
						(item) => item.id == InvoiceStatus.PENDING
					)
					this.$refs.pageHeader.filterSet(filter, option, true)
				})
			}
			if (this.$refs.pageHeader) {
				this.$refs.pageHeader.clearAll()
			}
		},
		clearFilters() {
			this.restaurantId = -1
			this.businessId = -1
			this.startDate = null
			this.endDate = null
			this.fundingSourceMethod = -1
			this.bankLinked = -1
			this.w9Linked = -1
			this.payoutStatus = PayoutStatus.PENDING
			this.invoiceStatus = -1
		},
		filterSet(filter) {
			switch (filter.id) {
				case 1: {
					this.startDate = filter.value.from
					this.endDate = filter.value.to
					break
				}
				case 2: {
					this.restaurantId = filter.value ? filter.value.id : 0
					break
				}
				case 3: {
					this.fundingSourceMethod = filter.value.id
					break
				}
				case 4: {
					this.bankLinked = filter.value ? filter.value.id : -1
					break
				}
				case 5: {
					this.w9Linked = filter.value ? filter.value.id : -1
					break
				}
				case 6: {
					this.businessId = filter.value ? filter.value.id : 0
					break
				}
				case 7: {
					this.invoiceStatus = filter.value.id
					break
				}
				case 8: {
					this.payoutStatus = filter.value.id
					break
				}
			}
		},
		async headerSecondaryActionClicked(action) {
			switch (action.id) {
				case 0: {
					const confirmed = await this.$refs.confirmationModal.open({
						title: `Are you sure you want to issue all approved payouts?`,
						confirmation: "Approve",
						confirmationIcon: "sk-icon-check-regular",
						cancel: "Cancel",
						type: ConfirmationModalType.GREEN
					})
					if (confirmed) {
						const success = this.$store.dispatch(
							`financeModule/${ActionTypes.ISSUE_PAYOUTS}`
						)
						if (success) {
							eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
								title: "Succesfully issues payment process requests"
							})
						} else {
							eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
								title: "Error processing payouts. Please try again",
								type: NotificationType.WARNING
							})
						}
					}
					break
				}
			}
		},
		headerActionClicked(action) {
			switch (this.activeTab.id) {
				case FinanceTab.EXCEPTIONS: {
					this.handleExceptionActionClicked(action)
					break
				}
				case FinanceTab.REFUNDS: {
					this.handleRefundActionClicked(action)
					break
				}
				case FinanceTab.RESTAURANT_PAYOUTS: {
					this.handlePayoutsActionClicked(action)
					break
				}
				case FinanceTab.INVOICES: {
					this.handleInvoiceActionClicked(action)
				}
			}
		},
		handleRefundActionClicked(action) {
			switch (action.id) {
				case 0: {
					this.batchSetRefundStatus(action.optionId)
					break
				}
			}
		},
		async handleExceptionActionClicked(action) {
			switch (action.id) {
				case 0: {
					switch (action.optionId) {
						case 0:
						case 1: {
							const exceptionCount = this.batchSelectedIds.length
							const titleTxt = this.$options.filters.pluralize(
								exceptionCount,
								"Exception"
							)
							const title =
								action.optionId == 0
									? `Approve ${exceptionCount} ${titleTxt}?`
									: `Reject ${exceptionCount} ${titleTxt}?`
							const confirmationTxt =
								action.optionId == 0 ? `Approve` : `Reject`
							const type =
								action.optionId == 0
									? ConfirmationModalType.GREEN
									: ConfirmationModalType.RED
							const confirmed =
								await this.$refs.confirmationModal.open({
									title: title,
									confirmation: confirmationTxt,
									confirmationIcon: "sk-icon-check-alt-solid",
									cancel: "Cancel",
									type: type
								})
							if (confirmed) {
								this.toggleLoader(true)
								const status = action.optionId == 0 ? 1 : 2
								const success = await this.$store.dispatch(
									"batchSetExceptionsStatus",
									{
										status: status,
										ids: this.batchSelectedIds
									}
								)
								if (success) {
									let successMessage =
										status == 1
											? `${exceptionCount} exceptions successfully accepted`
											: `${exceptionCount} exceptions successfully accepted`
									eventBus.emit(
										EventBusEvents.SHOW_NOTIFICATION,
										{
											title: "Exceptions",
											message: successMessage
										}
									)
									this.$refs.exceptions.cleanUpBatchIds()
								} else {
									let errorMessage =
										status == 1
											? "Failed to accept exceptions"
											: "Failed to reject exceptions"
									eventBus.emit(
										EventBusEvents.SHOW_NOTIFICATION,
										{
											title: "Exceptions",
											message: errorMessage,
											type: NotificationType.ERROR
										}
									)
								}
								this.toggleLoader(false)
							}
							break
						}
					}
					break
				}
			}
		},
		async handlePayoutsActionClicked(action) {
			switch (action.id) {
				case 0: {
					switch (action.optionId) {
						case 0:
						case 1: {
							const payoutCount = this.batchSelectedIds.length
							const payoutsCountText =
								this.$options.filters.pluralize(
									payoutCount,
									"Payout"
								)
							const title =
								action.optionId == 0
									? `Approve ${payoutCount} ${payoutsCountText}?`
									: `Clear ${payoutCount} ${payoutsCountText}?`
							const confirmationTxt =
								action.optionId == 0 ? `Approve` : `Clear`
							const type =
								action.optionId == 0
									? ConfirmationModalType.GREEN
									: ConfirmationModalType.RED
							const confirmed =
								await this.$refs.confirmationModal.open({
									title: title,
									confirmation: confirmationTxt,
									confirmationIcon: "sk-icon-check-alt-solid",
									cancel: "Cancel",
									type: type
								})
							if (confirmed) {
								this.toggleLoader(true)
								const status = action.optionId == 0 ? 1 : 2
								let success = false
								if (action.optionId == 0) {
									success = await this.$store.dispatch(
										`financeModule/${ActionTypes.BATCH_APPROVE_RESTAURANT_PAYOUTS}`,
										this.batchSelectedIds
									)
								} else if (action.optionId == 1) {
									success = await this.$store.dispatch(
										`financeModule/${ActionTypes.BATCH_COMPLETE_RESTAURANT_PAYOUTS}`,
										this.batchSelectedIds
									)
								}
								if (success) {
									let successMessage =
										status == 1
											? `${payoutCount} ${payoutsCountText} successfully approved`
											: `${payoutCount} ${payoutsCountText} successfully cleared`
									eventBus.emit(
										EventBusEvents.SHOW_NOTIFICATION,
										{
											title: "Payouts",
											message: successMessage
										}
									)
									this.$refs.payouts.cleanUpBatchIds()
								} else {
									let errorMessage =
										status == 1
											? "Failed to accept payouts"
											: "Failed to clear payouts"
									eventBus.emit(
										EventBusEvents.SHOW_NOTIFICATION,
										{
											title: "Payouts",
											message: errorMessage,
											type: NotificationType.ERROR
										}
									)
								}
								this.toggleLoader(false)
							}
							break
						}
						case 2: {
							let payouts = this.payouts.filter((payout) =>
								this.batchSelectedIds.includes(payout.id)
							)
							this.downloadExcel(payouts)
							break
						}
					}
					break
				}
				case 1:
					this.downloadExcel()
					break
			}
		},
		async handleInvoiceActionClicked(action) {
			switch (action.id) {
				case 0: {
					const invoiceCount = this.batchSelectedIds.length
					const invoiceCountText = this.$options.filters.pluralize(
						invoiceCount,
						"Invoice"
					)
					const confirmed = await this.$refs.confirmationModal.open({
						title: `Approve ${invoiceCount} ${invoiceCountText}?`,
						confirmation: "Approve",
						confirmationIcon: "sk-icon-check-alt-solid",
						cancel: "Cancel",
						type: ConfirmationModalType.GREEN
					})
					if (confirmed) {
						const response = await this.$store.dispatch(
							`financeModule/${ActionTypes.BATCH_APPROVE_BUSINESS_INVOICES}`,
							this.batchSelectedIds
						)

						let message = ""
						let notificationType = NotificationType.SUCCESS

						if (response == "success") {
							message = `${invoiceCount} ${invoiceCountText} successfully approved`
						} else if (response == "error") {
							message = `Failed to approve ${invoiceCountText}`
							notificationType = NotificationType.ERROR
						} else if (response == "partial") {
							message = `Some invoices may not have been approved. Please refresh and try again.`
							notificationType = NotificationType.ERROR
						}

						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Invoices",
							message: message,
							type: notificationType
						})
						this.$refs.invoices.cleanUpBatchIds()
					}
					break
				}
			}
		},
		async batchSetRefundStatus(optionId) {
			let confirmationTitle = ""
			let confirmationText = ""
			let successMessage = ""
			let errorMessage = ""
			let status = null
			const refundCount = this.batchSelectedIds.length
			const refundCountText = this.$options.filters.pluralize(
				refundCount,
				"Refund"
			)
			switch (optionId) {
				case 0: {
					status = 1
					confirmationTitle = `Approve ${refundCount} ${refundCountText}?`
					confirmationText = "Approve"
					successMessage = "Refunds successfully approved"
					errorMessage = "Failed to approve refunds"
					break
				}
				case 1: {
					status = 0
					confirmationTitle = `Reject ${refundCount} ${refundCountText}?`
					confirmationText = "Reject"
					successMessage = "Refunds successfully rejected"
					errorMessage = "Failed to reject refunds"
					break
				}
			}
			const confirmed = await this.$refs.confirmationModal.open({
				title: confirmationTitle,
				confirmation: confirmationText,
				confirmationIcon: "sk-icon-check-regular",
				cancel: "Cancel",
				type: ConfirmationModalType.GREEN
			})
			if (!confirmed) {
				return false
			}
			const success = await this.$store.dispatch("batchSetRefundStatus", {
				status: status,
				ids: this.batchSelectedIds
			})
			if (success) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Refunds",
					message: successMessage
				})
				this.$refs.refunds.cleanUpBatchIds()
			} else {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Refunds",
					message: errorMessage,
					type: NotificationType.ERROR
				})
			}
			return true
		},
		downloadExcel(payouts) {
			let wb = XLSX.utils.book_new()
			wb.SheetNames.push("Payouts")
			wb.Props = {
				Title: "2ndKitchen Report",
				Subject: "Report",
				Author: "2ndKitchen",
				CreatedDate: new Date()
			}
			const payoutsWsData = this.generatePayoutsData(payouts)
			const payoutWs = XLSX.utils.aoa_to_sheet(payoutsWsData)
			wb.Sheets["Payouts"] = payoutWs
			const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" })
			saveAs(
				new Blob([xlsx_helper.s2ab(wbout)], {
					type: "application/octet-stream"
				}),
				"payouts.xlsx"
			)
		},
		generatePayoutsData(payouts) {
			const wsData = [
				[
					"Payout Period",
					"Restaurant",
					"Method",
					"W9 Status",
					"Orders",
					"Sales",
					"Fees",
					"Fees %",
					"Taxes",
					"Tips",
					"Exceptions",
					"External Id",
					"Correlation Id",
					"Payout Total"
				]
			]
			payouts = payouts ? payouts : this.payouts
			payouts.forEach((item) => {
				const payoutPeriod =
					moment.utc(item.from_date).format("MMM D") +
					"-" +
					moment.utc(item.to_date).format("MMM D")
				const feesPer = (item.commission / item.cogs) * 100
				const cogs_tax_per = (item.cogs_tax / item.cogs) * 100
				const exceptions_per =
					item.exceptions / (1 + cogs_tax_per / 100)
				const exceptions_tax = item.exceptions - exceptions_per
				const sales_net =
					item.cogs - exceptions_per > 0
						? item.cogs - exceptions_per
						: 0
				const tax_net =
					item.cogs_tax - exceptions_tax > 0
						? item.cogs_tax - exceptions_tax
						: 0
				let fundingSource = "N/A"
				if (
					item.funding_source != undefined &&
					item.funding_source.type != undefined &&
					item.funding_source.type.id == FundingSource.ACH
				)
					fundingSource = "ACH"
				const restaurant =
					this.$store.state.restaurants[item.restaurant.id]
				const w9_text = restaurant.w9_uploaded == 1 ? "Y" : "N"
				const external_id = item.transaction
					? item.transaction.external_id
					: ""
				const correlation_id = item.correlation_id
					? item.correlation_id
					: ""

				const excelObj = [
					payoutPeriod,
					item.restaurant.name,
					fundingSource,
					w9_text,
					item.orders_count,
					parseFloat(sales_net.toFixed(2)),
					parseFloat(item.commission.toFixed(2)),
					parseFloat(feesPer.toFixed(2)),
					tax_net,
					item.tips,
					item.exceptions,
					external_id,
					correlation_id,
					item.amount
				]
				wsData.push(excelObj)
			})
			return wsData
		},
		setDefaultDate(data) {
			this.$refs.pageHeader.filterSet(
				this.filters[0],
				{
					start: data.start,
					end: data.end,
					formatted: {
						start: data.start,
						end: data.end
					}
				},
				true
			)
		}
	},
	computed: {
		isRestaurantAdmin() {
			return this.$store.state.user_role == UserRole.RESAURANT_ADMIN
		},
		payouts() {
			return this.$store.state.financeModule.restaurantPayouts
				.slice()
				.sort(function (a, b) {
					if (a.restaurant.name > b.restaurant.name) return 1
					else return -1
				})
		},
		tabs() {
			let allowedTabs = []
			if (this.allowed) {
				allowedTabs = allowedTabs.concat([
					{
						id: FinanceTab.EXCEPTIONS,
						text: "Exceptions"
					},
					{
						id: FinanceTab.REFUNDS,
						text: "Refunds"
					},
					{
						id: FinanceTab.RESTAURANT_PAYOUTS,
						text: "Payouts"
					},
					{
						id: FinanceTab.PAYOUT_LOCATIONS,
						text: "Restaurant Locations",
						overflowTab: true
					},
					{
						id: FinanceTab.INVOICE_LOCATIONS,
						text: "Business Locations",
						overflowTab: true
					},
					{
						id: FinanceTab.INVOICES,
						text: "Invoices"
					}
				])
			} else if (this.isRestaurantAdmin) {
				allowedTabs.push({
					id: FinanceTab.PAYOUT_LOCATIONS,
					text: "Locations"
				})
			} else {
				allowedTabs.push({
					id: FinanceTab.INVOICE_LOCATIONS,
					text: "Locations"
				})
			}
			return allowedTabs
				.map((tab) => {
					tab.active = tab.id == this.activeTabId
					return tab
				})
				.sort((a, b) => a.id - b.id)
		},
		filters() {
			let orderDateFilter = {
				id: 1,
				placeholder: "Order Date",
				type: "daterange"
			}
			let payoutRangeFilter = {
				id: 1,
				placeholder: "Payout Range",
				type: "daterange",
				shortcuts: [
					{
						key: "thisWeek",
						label: "This Week",
						value: "isoWeek"
					},
					{
						key: "lastWeek",
						label: "Last Week",
						value: "-isoWeek"
					},
					{
						key: "lastMonth",
						label: "Last Month",
						value: "-month"
					},
					{
						key: "thisMonth",
						label: "This Month",
						value: "month"
					},
					{
						key: "thisQuarter",
						label: "This Quarter",
						value: "quarter"
					}
				]
			}
			let invoiceRangeFilter = {
				id: 1,
				placeholder: "Invoice Range",
				type: "daterange"
			}
			let payoutMethodFilter = {
				id: 3,
				placeholder: "Method",
				type: "dropdown",
				items: [
					{
						text: `Method`,
						id: null
					},
					{
						text: `All`,
						id: 0
					},
					{
						text: `ACH`,
						id: 2
					},
					{
						text: `Bill.com`,
						id: 1
					}
				]
			}
			let restaurantSearchFilter = {
				id: 2,
				placeholder: "Restaurant",
				type: "search",
				fetch: (query) => {
					return this.$store.dispatch(
						"restaurantsModule/autocompleteRestaurants",
						query
					)
				},
				width: "250px",
				serializer: (i) => {
					return { text: i.name }
				}
			}
			let bankLinkedFilter = {
				id: 4,
				placeholder: "Bank Linked",
				type: "dropdown",
				items: [
					{
						text: `Bank Linked`,
						id: null
					},
					{
						text: `<span class="status-dot dark-grey"></span> All`,
						id: -1
					},
					{
						text: `<span class="status-dot green"></span> Linked`,
						id: 1
					},
					{
						text: `<span class="status-dot red"></span> Not Linked`,
						id: 0
					}
				]
			}
			let w9UploadedFilter = {
				id: 5,
				placeholder: "W9 Uploaded",
				type: "dropdown",
				items: [
					{
						text: `W9 Uploaded`,
						id: null
					},
					{
						text: `<span class="status-dot dark-grey"></span> All`,
						id: -1
					},
					{
						text: `<span class="status-dot green"></span> Uploaded`,
						id: 1
					},
					{
						text: `<span class="status-dot red"></span> Not Uploaded`,
						id: 0
					}
				]
			}
			let businessSearchFilter = {
				id: 6,
				placeholder: "Business",
				type: "search",
				fetch: (query) => {
					return this.$store.dispatch(
						"businessesModule/autocompleteBusinesses",
						query
					)
				},
				width: "250px",
				serializer: (i) => {
					return { text: i.name }
				}
			}
			let invoiceStatus = {
				id: 7,
				placeholder: "Invoice Status",
				type: "dropdown",
				items: [
					{
						text: `Invoice Status`,
						id: null
					},
					{
						text: `<span class="status-dot dark-grey"></span> All`,
						id: -1
					},
					{
						text: `<span class="status-dot yellow"></span> Pending`,
						id: InvoiceStatus.PENDING
					},
					{
						text: `<span class="status-dot yellow"></span> Approved`,
						id: InvoiceStatus.APPROVED
					},
					{
						text: `<span class="status-dot green-pulsating"></span> Processing`,
						id: InvoiceStatus.PROCESSING
					},
					{
						text: `<span class="status-dot green"></span> Paid`,
						id: InvoiceStatus.COMPLETED
					},
					{
						text: `<span class="status-dot red"></span> Failed`,
						id: InvoiceStatus.FAILED
					}
				]
			}

			let payoutStatusFilter = {
				id: 8,
				placeholder: "Payout Status",
				type: "dropdown",
				items: [
					{
						text: `<span class="status-dot yellow"></span> Pending`,
						id: null
					},
					{
						text: `<span class="status-dot dark-grey"></span> All`,
						id: -1
					},
					{
						text: `<span class="status-dot yellow"></span> Pending`,
						id: PayoutStatus.PENDING
					},
					{
						text: `<span class="status-dot green"></span> Approved`,
						id: PayoutStatus.APPROVED
					},
					{
						text: `<span class="status-dot green-pulsating"></span> Processing`,
						id: PayoutStatus.PROCESSING
					},
					{
						text: `<span class="status-dot navy"></span> Completed`,
						id: PayoutStatus.COMPLETED
					}
				]
			}

			switch (this.activeTabId) {
				case FinanceTab.EXCEPTIONS: {
					return [orderDateFilter, restaurantSearchFilter]
				}
				case FinanceTab.REFUNDS: {
					return [orderDateFilter]
				}
				case FinanceTab.RESTAURANT_PAYOUTS: {
					return [
						payoutRangeFilter,
						payoutMethodFilter,
						restaurantSearchFilter,
						w9UploadedFilter,
						payoutStatusFilter
					]
				}
				case FinanceTab.PAYOUT_LOCATIONS: {
					return [
						restaurantSearchFilter,
						bankLinkedFilter,
						w9UploadedFilter
					]
				}
				case FinanceTab.INVOICE_LOCATIONS: {
					return [
						businessSearchFilter,
						bankLinkedFilter,
						w9UploadedFilter
					]
				}
				case FinanceTab.INVOICES: {
					return [
						businessSearchFilter,
						invoiceRangeFilter,
						invoiceStatus
					]
				}
			}
			return []
		},
		actions() {
			switch (this.activeTab.id) {
				case FinanceTab.EXCEPTIONS: {
					const exceptionCount = this.batchSelectedIds.length
					if (exceptionCount > 0) {
						const exceptionCountText =
							this.$options.filters.pluralize(
								exceptionCount,
								"Exception"
							)
						return [
							{
								id: 0,
								text: `Update ${exceptionCount} ${exceptionCountText} <i class='sk-icon-sort-down-solid'></i> `,
								classes:
									"button button-primary button-icon-right",
								options: [
									{
										id: 0,
										text: "<i class='approve-refunds-icon sk-icon-check-regular'></i> Approve"
									},
									{
										id: 1,
										text: "<i class='reject-refunds-icon sk-icon-times-regular'></i> Reject"
									}
								]
							}
						]
					}
					break
				}
				case FinanceTab.REFUNDS: {
					const refundCount = this.batchSelectedIds.length
					if (refundCount > 0) {
						const refundCountText = this.$options.filters.pluralize(
							refundCount,
							"Refund"
						)
						return [
							{
								id: 0,
								text: `Update ${refundCount} ${refundCountText} <i class='sk-icon-sort-down-solid'></i> `,
								classes:
									"button button-primary button-icon-right",
								options: [
									{
										id: 0,
										text: "<i class='approve-refunds-icon sk-icon-check-regular'></i> Approve"
									},
									{
										id: 1,
										text: "<i class='reject-refunds-icon sk-icon-times-regular'></i> Reject"
									}
								]
							}
						]
					}
					break
				}
				case FinanceTab.RESTAURANT_PAYOUTS: {
					const payoutCount = this.batchSelectedIds.length
					if (payoutCount > 0) {
						const payoutCountText = this.$options.filters.pluralize(
							payoutCount,
							"Payout"
						)
						return [
							{
								id: 0,
								text: `Update ${payoutCount} ${payoutCountText} <i class='sk-icon-sort-down-solid'></i> `,
								classes:
									"button button-primary button-icon-right",
								options: [
									{
										id: 0,
										text: "<i class='approve-refunds-icon sk-icon-check-regular'></i> Approve"
									},
									{
										id: 1,
										text: "<i class='reject-refunds-icon sk-icon-times-regular'></i> Clear"
									},
									{
										id: 2,
										text: "<i class='sk-icon-arrow-to-bottom-regular'></i> Download Selected"
									}
								]
							}
						]
					} else {
						return [
							{
								id: 1,
								text: `<i class="sk-icon-arrow-to-bottom-regular"></i> Download All Payouts`,
								classes: "button button-primary"
							}
						]
					}
				}
				case FinanceTab.INVOICES: {
					const invoiceCount = this.batchSelectedIds.length
					if (invoiceCount > 0) {
						const invoiceCountText =
							this.$options.filters.pluralize(
								invoiceCount,
								"Invoice"
							)
						return [
							{
								id: 0,
								text: `Approve ${invoiceCount} ${invoiceCountText}`,
								classes: "button button-primary"
							}
						]
					}
				}
			}
			return null
		},
		secondaryActions() {
			switch (this.activeTab.id) {
				case FinanceTab.RESTAURANT_PAYOUTS: {
					const payoutCount = this.batchSelectedIds.length
					if (payoutCount == 0) {
						return [
							{
								id: 0,
								text: `Process Payments`
							}
						]
					}
				}
			}
			return null
		},
		activeTab() {
			return this.tabs.find((tab) => tab.active)
		},
		loading() {
			return this.$store.state.financeModule.loading || !this.didMount
		},
		allowed() {
			return this.$store.state.permissions.includes(
				PermissionTab.FINANCE_ADMIN
			)
		}
	},
	watch: {
		loading: function (newVal) {
			this.toggleLoader(newVal)
		}
	}
}
</script>