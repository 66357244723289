import Vue from "vue"

import { parseResponse } from "@arikgaisler/utils/api-utils"

let host = process.env.VUE_APP_API_HOST
const AUTH_API = `https://auth${host}.2ndkitchen.com`

export default {
    async authUser(payload) {
        return parseResponse(Vue.axios.post(`${AUTH_API}/admin_users/authenticate`, payload))
    },
    async activateUser(payload) {
        return parseResponse(Vue.axios.post(`${AUTH_API}/admin_users/activate`, payload))
    },
    async resetPassword(payload) {
        return parseResponse(Vue.axios.post(`${AUTH_API}/admin_users/reset_password`, payload))
    }
}