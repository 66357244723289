<template>
	<div class="sk-table-row">
		<div class="sk-table-column-fit">
			<div class="sk-pills-container">
				<div :title="statusLabel" :class="statusBadgeClasses">
					<span :class="statusClasses"></span>
					{{ statusLabel }}
				</div>
			</div>
		</div>
		<div :title="reminderType">
			{{ reminderType }}
		</div>
		<div>{{ reminderDate }}</div>
		<div>{{ reminderTime }}</div>
		<div class="sk-table-column-fit">
			<div class="sk-table-actions">
				<button
					v-if="canEdit"
					class="button button-icon"
					title="Edit"
					@click="editReminderClicked"
				>
					<i class="sk-icon-pencil-solid"></i>
				</button>
				<button
					v-if="canEdit"
					class="button button-icon"
					title="Delete"
					@click="deleteReminderClicked"
				>
					<i class="sk-icon-trash-alt-solid"></i>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import moment from "moment"
import { EventReminderType } from "@/enums/eventReminderType.js"

export default {
	name: "EventReminderLineItem",
	props: {
		reminder: Object,
		event: Object
	},
	computed: {
		canEdit() {
			return this.reminder.status == 0
		},
		business() {
			if (
				this.event.business_id &&
				this.$store.getters.businessesMap &&
				this.$store.getters.businessesMap[this.event.business_id]
			) {
				return this.$store.getters.businessesMap[this.event.business_id]
			}
			return null
		},
		statusLabel() {
			if (this.reminder != null) {
				switch (this.reminder.status) {
					case 0:
						return "Pending"
					case 1:
						return "Sent"
				}
			}
			return ""
		},
		reminderType() {
			if (this.reminder) {
				switch (this.reminder.type) {
					case EventReminderType.PRE_ORDER_EMAIL:
						return "Default"
					case EventReminderType.PRE_ORDER_LAST_CHANCE_EMAIL:
						return "Last Call"
				}
			}
			return ""
		},
		statusClasses() {
			if (this.reminder) {
				switch (this.reminder.status) {
					case 0:
						return "status-dot grey"
					case 1:
						return "status-dot navy"
				}
			}
			return ""
		},
		statusBadgeClasses() {
			if (this.reminder) {
				switch (this.reminder.status) {
					case 0:
						return "pill-alt pill-alt-grey"
					case 1:
						return "pill-alt pill-alt-navy"
				}
			}
			return ""
		},
		reminderDate() {
			let date = "--"
			let start = null
			if (this.reminder) {
				if (this.reminder.remind_at) {
					start = moment
						.utc(this.reminder.remind_at)
						.add(this.business.timezone_offset, "m")
					date = start.format("MMM DD, YYYY")
				}
			}

			return date
		},
		reminderTime() {
			let retVal = "--"
			if (this.reminder) {
				if (this.reminder.remind_at) {
					retVal = moment
						.utc(this.reminder.remind_at)
						.add(this.business.timezone_offset, "m")
						.format("h:mm A")
				}
			}
			return retVal
		}
	},
	methods: {
		deleteReminderClicked() {
			this.$emit("delete", this.reminder)
		},
		editReminderClicked() {
			this.$emit("edit", this.reminder)
		}
	}
}
</script>