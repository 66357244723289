<template>
	<div class="sk-page-content">
		<PageHeader
			ref="pageHeader"
			:title="'Supplies'"
			:tabs="tabs"
			:actions="actions"
			:filters="filters"
			@actionClicked="headerActionClicked"
			@filterSet="filterSet"
			@filtersReset="clearFilters"
			@tabClicked="headerTabClicked"
		/>
		<transition name="slide" mode="out-in">
			<SupplyShop
				key="0"
				v-if="activeTab.id == 0"
				@requestSupply="requestSupply"
			/>
			<SupplyHistory
				key="1"
				v-if="activeTab.id == 1"
				:filters="filterVals"
			/>
		</transition>
		<RequestSuppliesModal ref="requestSuppliesModal" />
	</div>
</template>

<script>
import { SupplyRequestStatus } from "@/enums/supplyRequestStatus"
import { EntityType } from "@/enums/entityType"
import loaderMixin from "@/mixins/loader-mixin"
import PageHeader from "@/components/PageHeader.vue"
import SupplyShop from "@/components/supplies/SupplyShop.vue"
import SupplyHistory from "@/components/supplies/SupplyHistory.vue"
import RequestSuppliesModal from "@/components/modals/supplies/RequestSuppliesModal.vue"

export default {
	name: "Supplies",
	mixins: [loaderMixin],
	components: {
		PageHeader,
		SupplyShop,
		SupplyHistory,
		RequestSuppliesModal
	},
	data: function () {
		return {
			activeTabId: 0,
			filterVals: {
				startDate: null,
				endDate: null,
				item: null,
				status: -1
			}
		}
	},
	computed: {
		tabs() {
			return [
				{
					id: 0,
					text: "Request",
					active: this.activeTabId == 0
				},
				{
					id: 1,
					text: "History",
					active: this.activeTabId == 1
				}
			]
		},
		supplies() {
			const supplies = this.$store.state.businessesModule.supplies.map(
				(supplyItem) => {
					return {
						entity: EntityType.BUSINESS,
						id: `${supplyItem.id}-0`,
						itemId: supplyItem.id,
						text: supplyItem.name
					}
				}
			)

			this.$store.state.restaurantsModule.supplies.forEach(
				(supplyItem) => {
					supplies.push({
						entity: EntityType.RESTAURANT,
						id: `${supplyItem.id}-1`,
						itemId: supplyItem.id,
						text: supplyItem.name
					})
				}
			)
			return supplies
		},
		filters() {
			switch (this.activeTabId) {
				case 1: {
					return [
						{
							id: 0,
							placeholder: "Request Date",
							type: "daterange"
						},
						{
							id: 1,
							placeholder: "Item",
							type: "search",
							width: "200px",
							items: this.supplies,
							serializer: (i) => {
								return { text: i.text }
							}
						},
						{
							id: 2,
							placeholder: "Status",
							type: "dropdown",
							items: [
								{
									text: `<span class="status-dot orange"></span> All`,
									id: -1
								},
								{
									text: `<span class="status-dot yellow"></span> Requested`,
									id: SupplyRequestStatus.PENDING
								},
								{
									text: `<span class="status-dot green"></span> Approved`,
									id: SupplyRequestStatus.APPROVED
								},
								{
									text: `<span class="status-dot red"></span> Rejected`,
									id: SupplyRequestStatus.REJECTED
								},
								{
									text: `<span class="status-dot red"></span> Cancelled`,
									id: SupplyRequestStatus.CANCELLED
								}
							]
						}
					]
				}
			}

			return []
		},
		actions() {
			switch (this.activeTabId) {
				case 0:
					return [
						{
							id: 0,
							text: `<i class="sk-icon-plus-regular"></i> Request Supplies`,
							classes: "button button-primary"
						}
					]
			}
			return []
		},
		activeTab() {
			return this.tabs.find((tab) => tab.active)
		}
	},
	methods: {
		headerTabClicked(tabClicked) {
			this.activeTabId = tabClicked.id
			this.$refs.pageHeader.clearAll()
			switch (tabClicked.id) {
				case 0: {
					this.toggleLoader(true)
					this.$store.dispatch("getSupplies").finally(() => {
						this.toggleLoader(false)
					})
					break
				}
				case 1: {
					this.toggleLoader(true)
					this.$store.dispatch("getSupplyRequests").finally(() => {
						this.toggleLoader(false)
					})
					break
				}
			}
		},
		clearFilters() {
			this.filterVals.startDate = null
			this.filterVals.endDate = null
			this.filterVals.item = null
			this.filterVals.status = -1
		},
		filterSet(filter) {
			switch (filter.id) {
				case 0: {
					this.filterVals.startDate = filter.value.from
					this.filterVals.endDate = filter.value.to
					break
				}
				case 1: {
					this.filterVals.item = filter.value
					break
				}
				case 2: {
					this.filterVals.status = filter.value.id
					break
				}
			}
		},
		headerActionClicked(action) {
			switch (action.id) {
				case 0: {
					this.$refs.requestSuppliesModal.open()
					break
				}
			}
		},
		requestSupply(options) {
			this.$refs.requestSuppliesModal.open({
				supplyItem: options.supplyItem,
				entityType: options.entityType
			})
		}
	},
	mounted: function () {
		this.toggleLoader(true)
		this.$store.dispatch("getSupplies").finally(() => {
			this.toggleLoader(false)
		})
	}
}
</script>