<template>
	<div class="prepackages-calendar-detail" :style="containerRightStyle">
		<div class="prepackages-banner">
			<div class="actions">
				<button class="button button-icon button-close" @click="close">
					<i class="sk-icon-times-regular"></i>
				</button>
				<button
					class="button button-icon"
					v-if="!prepackageCutoffPassed"
					@click="actionSelected({ id: 2 })"
					title="Edit"
				>
					<i class="sk-icon-pencil-solid"></i>
				</button>
				<button
					class="button button-icon"
					@click="actionSelected({ id: 0 })"
					v-else
					title="View"
				>
					<i class="sk-icon-eye-solid"></i>
				</button>
				<SkDropdownSelect
					v-if="actions.length > 0"
					class="button button-icon"
					:options="actions"
					:iconClasses="'sk-icon-ellipsis-v-regular'"
					:showSelectedOption="false"
					:position="'left'"
					@selected="actionSelected"
				/>
			</div>
			<div class="menu-name text-truncate">{{ menuName }} (#{{order.order_id}})</div>
		</div>
		<div class="prepackages-calendar-date">
			<b>{{ orderDay }}</b>
			<span>at</span>
			<b>{{ deliveryTime }}</b>
		</div>
		<div class="sk-row">
			<div class="prepackages-calendar-detail-line-item">
				<i class="sk-icon-align-left-regular"></i>
				<span>{{ orderQuantity }} Meals</span>
			</div>
		</div>
		<div class="sk-row">
			<div class="prepackages-calendar-detail-line-item">
				<i class="sk-icon-dollar-sign-regular"></i>
				<span>{{ orderPrice | currency }}</span>
			</div>
		</div>
		<div class="sk-row">
			<div class="prepackages-calendar-detail-line-item">
				<i class="sk-icon-user-solid"></i>
				<span>{{ orderIssuer }}</span>
			</div>
		</div>
		<div class="sk-row">
			<div class="prepackages-calendar-detail-line-item">
				<i class="sk-icon-building-solid"></i>
				<span>{{ businessName }}</span>
			</div>
		</div>
		<div class="sk-row">
			<div class="prepackages-status" :class="statusBadgeClasses">
				<span :class="statusClasses"></span>
				{{ statusLabel }}
			</div>
		</div>
	</div>
</template>

<style scoped>
.prepackages-calendar-detail {
	position: absolute;
	transform: translateY(-50%);
	width: 362px;
	background: var(--sk-greybg);
	box-shadow: 0px 5px 15px rgba(136, 136, 136, 0.25);
	border-radius: 5px;
	z-index: 1000;
	overflow: hidden;
}

.prepackages-banner {
	height: 86px;
	width: 100%;
	position: relative;
	background-color: var(--sk-dark-navy);
}

.prepackages-banner .actions {
	position: absolute;
	top: 0;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 25px;
	margin-top: 5px;
	padding: 0 10px;
}

.prepackages-banner .actions .button {
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--sk-white);
	background: rgba(46, 60, 73, 0.7);
	min-height: 25px;
	padding: 0;
	width: 25px;
	font-size: 10px;
}

.prepackages-banner .actions .button + .button {
	margin-left: 10px;
}

.prepackages-banner .actions .button-close {
	margin-right: auto;
}

.prepackages-banner .actions >>> .sk-icon-ellipsis-v-regular {
	margin: auto;
}

.menu-name {
	position: absolute;
	bottom: 0;
	left: 0;
	font-size: 14px;
	font-weight: 600;
	color: var(--sk-white);
	width: 100%;
	text-align: left;
	padding: 15px;
}

.prepackages-calendar-date {
	background: var(--sk-white);
	padding: 15px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	border: 1px solid var(--sk-grey);
	text-align: left;
	color: var(--sk-grey3);
	font-size: 12px;
}

.prepackages-calendar-date span {
	margin: 0 6px;
}

.prepackages-calendar-date b {
	font-weight: 600;
}

.prepackages-calendar-detail-line-item {
	text-align: left;
}

.prepackages-calendar-detail-line-item span {
	font-size: 12px;
	font-weight: 600;
	color: var(--sk-grey3);
}

.prepackages-calendar-detail-line-item i {
	margin-right: 20px;
	color: var(--sk-grey2);
	font-size: 10px;
}

.prepackages-status {
	flex: 0 0 auto !important;
}
</style>

<script>
import moment from "moment"
import prepackagedOrderMixin from "@/mixins/prepackaged-order-mixin"
import currencyFilterMixin from "@/mixins/currency-filter-mixin"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"

export default {
	name: "PrepackagesCalendarDetail",
	components: {
		SkDropdownSelect
	},
	mixins: [prepackagedOrderMixin, currencyFilterMixin],
	props: {
		selectedItem: Object,
		position: String
	},
	computed: {
		order() {
			return this.prepackage
		},
		prepackage() {
			return this.selectedItem
				? this.$store.state.prepackagesModule.prepackagedOrders.find(
						(prepackage) =>
							prepackage.order_id == this.selectedItem.id
				  )
				: null
		},
		menuName() {
			if (
				this.business &&
				this.business.menus &&
				this.suborder &&
				this.suborder.products.length > 0
			) {
				const product = this.suborder.products[0]
				const menu = this.business.menus.find(
					(menu) => menu.id == product.menu_id
				)
				return menu ? menu.name : "N/A"
			}
			return "N/A"
		},
		deliveryTime() {
			if (this.deliverySlot && this.deliverySlot != "") {
				let deliveryMoment = moment
					.utc(this.deliverySlot)
					.add(this.businessOffset, "m")
				return deliveryMoment.format("h:mm A")
			}
			return ""
		},
		containerRightStyle() {
			if (this.position == "right") {
				return {
					right: "calc(100% + 30px)"
				}
			} else {
				return {
					left: "calc(100% + 30px)"
				}
			}
		}
	},
	methods: {
		actionSelected(action) {
			this.$emit("actionSelected", {
				id: action.id,
				prepackage: this.prepackage,
				businessName: this.businessName
			})
		},
		close() {
			this.$emit("close")
		}
	}
}
</script>