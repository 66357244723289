<template>
	<SkBaseModal
		class="sk-modal-alt vld-parent"
		:isOpen="isOpen"
		@close="close"
	>
		<loading :active.sync="loading" :is-full-page="false"></loading>
		<div class="sk-modal-alt-title">
			<h1>
				Add Product:
				<span class="sk-modal-alt-subtitle">
					{{ currentMenuName }}
				</span>
			</h1>
		</div>
		<div class="sk-row">
			<div class="search-input">
				<i class="sk-icon-search-regular"></i>
				<input
					class="sk-input"
					placeholder="Search by Product Name"
					v-model="search"
				/>
			</div>
		</div>
		<transition-group
			tag="div"
			class="product-rows dark-scrollbar"
			name="list"
			appear
		>
			<div
				class="sk-row sk-widget-alt product-row"
				v-for="product in products"
				:key="product.id"
				@click="productSelected(product)"
			>
				<SkCheckbox :checked="productIds.includes(product.id)" />
				<div class="product-name">{{ product.name }}</div>
				<div>{{ product.price | currency }}</div>
			</div>
		</transition-group>
		<div class="sk-modal-actions">
			<button class="button button-text" @click="cancel">Cancel</button>
			<button class="button button-primary" @click="importProducts">
				<i class="sk-icon-check-regular"></i>
				Import Products
			</button>
		</div>
	</SkBaseModal>
</template>

<style scoped>
.search-input {
	position: relative;
}

.search-input i {
	position: absolute;
	left: 15px;
	top: 17.5px;
	color: var(--sk-grey2);
}

.search-input .sk-input {
	padding-left: 60px;
}

.product-rows {
	position: relative;
	margin: 0;
	height: 330px;
	overflow-y: auto;
	overflow-x: hidden;
	margin: 0 -5px 20px -5px;
	padding: 5px 5px 0 5px;
}

.product-row {
	display: flex;
	align-items: center;
	margin-left: 0;
	width: 100%;
}

.product-row:first-child {
	margin-top: 0;
}

.product-row >>> .sk-checkbox {
	margin-right: 30px;
}

.product-row .product-name {
	flex: 1;
}

.product-row div {
	font-size: 14px;
	font-weight: 600;
}
</style>

<script>
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import utils from "@/utils/utils"
import currencyFilterMixin from "@/mixins/currency-filter-mixin"
import Loading from "vue-loading-overlay"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import skPromiseModalMixin from "@/mixins/modals/sk-promise-modal-mixin"
import SkCheckbox from "@/components/SkCheckbox.vue"

export default {
	name: "AddMenuProductsModal",
	mixins: [skPromiseModalMixin, currencyFilterMixin],
	components: {
		Loading,
		SkBaseModal,
		SkCheckbox
	},
	data() {
		return {
			menuId: null,
			productIds: [],
			search: "",
			loading: false
		}
	},
	computed: {
		restaurant() {
			return this.$store.state.restaurantsModule.currentRestaurant
		},
		menuProductIds() {
			let products = this.$store.state.restaurantsModule
				.restaurantMenuProducts[this.currentMenuID]
				? this.$store.state.restaurantsModule.restaurantMenuProducts[
						this.currentMenuID
				  ]
				: []
			return products.map((product) => product.id)
		},
		products() {
			let regex = null
			if (this.search.length > 0) {
				const term = utils.sanitize(this.search)
				regex = utils.stringToRegex(term)
			}

			return this.$store.state.restaurantsModule.restaurantProducts
				.filter((product) => {
					if (this.menuProductIds.includes(product.id)) {
						return false
					}

					if (!regex) {
						return true
					}

					if (product.name && product.name.match(regex) !== null) {
						return true
					}

					if (this.productIds.includes(product.id)) {
						return true
					}
					return false
				})
				.sort((a, b) => {
					if (this.productIds.includes(a.id)) {
						return -1
					}
					if (this.productIds.includes(b.id)) {
						return 1
					}

					if (!regex) {
						return 0
					}

					const aIndex = a.name.indexOf(a.name.match(regex)[0])
					const bIndex = b.name.indexOf(b.name.match(regex)[0])
					if (aIndex < bIndex) {
						return -1
					}
					if (aIndex > bIndex) {
						return 1
					}
					return 0
				})
		},
		currentMenuID() {
			return this.$store.state.restaurantsModule.currentMenuID
		},
		currentMenu() {
			return this.restaurant.menus.find(
				(menu) => menu.id == this.currentMenuID
			)
		},
		currentMenuName() {
			return this.currentMenu ? this.currentMenu.name : ""
		}
	},
	methods: {
		opened() {
			this.menuId = null
			this.productIds = []
			this.search = ""
			this.loading = true
			this.$store
				.dispatch("restaurantsModule/getRestaurantProducts")
				.finally(() => {
					this.loading = false
				})
		},
		closed() {
			this.loading = false
		},
		importProducts() {
			if (this.productIds.length == 0) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					message: "Please select products to import",
					type: NotificationType.WARNING
				})
			} else {
				this.$store.dispatch(
					"restaurantsModule/batchAddProductsToMenu",
					{
						menuId: this.currentMenuID,
						productIds: this.productIds
					}
				)
				this.confirm({
					menuId: this.currentMenuID,
					productIds: this.productIds
				})
			}
		},
		productSelected(product) {
			if (!this.productIds.includes(product.id)) {
				this.productIds.push(product.id)
			} else {
				this.productIds = this.productIds.filter(
					(id) => product.id != id
				)
			}
		}
	}
}
</script>