<template>
	<div
		@click="integrationClicked"
		class="sk-widget integration-option"
		:class="{ unavailable: !isAvailable && !isPlaceholder }"
	>
		<slot v-if="!isPlaceholder">
			<IntegrationLogo :integrationType="integration.type" />
			<div class="integration-option-name">{{ name }}</div>
			<div class="integration-option-description">
				{{ description }}
			</div>
			<div v-if="isActive" class="integration-action enabled">
				<i class="sk-icon-check-regular"></i>
				Enabled
			</div>
			<div v-else-if="isAvailable" class="integration-action">
				<i class="sk-icon-plus-regular"></i>
				Add Integration
			</div>
			<div v-else class="integration-action unavailable">
				Coming Soon
			</div>
		</slot>
		<slot v-else>
			<i class="new-integration-icon sk-icon-plus-regular"></i>
			<div class="integration-option-name">New Integration</div>
			<div class="integration-option-description">
				Don’t see the integration you are looking for? Request it!
			</div>
			<div class="integration-action" @click="requestIntegration">
				Request Integration
			</div>
		</slot>
	</div>
</template>

<style scoped>
.integration-option {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	max-width: 250px;
	max-height: 250px;
	cursor: pointer;
}

.integration-option.unavailable {
	cursor: default;
}

.integration-option.unavailable img {
	filter: grayscale(1);
	opacity: 0.5;
}

.integration-option.unavailable .integration-option-name,
.integration-option.unavailable .integration-option-description {
	color: var(--sk-grey2);
}

.integration-option-name {
	margin-bottom: 15px;
}

.integration-option-description {
	max-height: 40px;
	height: 40px;
	margin-bottom: 15px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	text-align: center;
	overflow: hidden;
}

.integration-option-name {
	color: var(--sk-navy);
	font-size: 16px;
	font-weight: 600;
}

.integration-option-description {
	font-size: 12px;
	color: var(--sk-grey3);
}

.integration-option img,
.new-integration-icon {
	height: 50px;
	margin-bottom: 30px;
	margin-top: 10px;
}

.new-integration-icon {
	font-size: 60px;
	color: var(--sk-navy);
	width: auto;
}

.integration-action {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 50px;
	margin: auto 0 -15px 0;
	width: calc(100% + 40px);
	background: var(--sk-greybg2);
	color: var(--sk-grey3);
	box-shadow: 0px 0px 2px rgba(136, 136, 136, 0.25);
	font-size: 12px;
}

.integration-action.enabled {
	background: var(--sk-green);
	color: var(--sk-white);
	font-weight: 600;
}

.integration-action.unavailable {
	color: var(--sk-grey2);
	cursor: default;
}

.integration-action i {
	margin-right: 10px;
}
</style>

<script>
import Integration from "@/models/Integration"
import IntegrationLogo from "@/components/IntegrationLogo.vue"

export default {
	name: "IntegrationOption",
	components: {
		IntegrationLogo
	},
	props: {
		integration: Integration,
		isPlaceholder: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		name() {
			return this.integration ? this.integration.name : ""
		},
		description() {
			return this.integration ? this.integration.description : ""
		},
		isActive() {
			return this.integration && this.integration.isEnabled
		},
		isAvailable() {
			return this.integration && this.integration.isAvailable
		}
	},
	methods: {
		requestIntegration() {
			this.$emit("request")
		},
		openIntegration() {
			this.$emit("open", this.integration)
		},
		integrationClicked() {
			if (this.isPlaceholder) {
				this.requestIntegration()
			} else if (this.isAvailable) {
				this.openIntegration()
			}
		}
	}
}
</script>