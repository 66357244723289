export const GET_HUBS = "GET_HUBS"
export const ADD_HUB = "ADD_HUB"
export const GET_HUB = "GET_HUB"
export const UPDATE_HUB = "UPDATE_HUB"
export const GET_HUB_RESTAURANT_MENUS = "GET_HUB_RESTAURANT_MENUS"
export const ADD_HUB_RESTAURANT_MENU = "ADD_HUB_RESTAURANT_MENU"
export const GET_HUB_BUSINESSES = "GET_HUB_BUSINESSES"
export const ADD_HUB_BUSINESS = "ADD_HUB_BUSINESS"
export const UPDATE_HUB_BUSINESS_MENUS = "UPDATE_HUB_BUSINESS_MENUS"

export default {
    GET_HUBS,
    ADD_HUB,
    GET_HUB,
    UPDATE_HUB,
    GET_HUB_RESTAURANT_MENUS,
    ADD_HUB_RESTAURANT_MENU,
    GET_HUB_BUSINESSES,
    ADD_HUB_BUSINESS,
    UPDATE_HUB_BUSINESS_MENUS
}