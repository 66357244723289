import moment from "moment"
import pricing_service from "@/services/pricing"
import search_service from "@/services/search"
import ActionTypes from "@/store/modules/coupons/action-types"
import MutationTypes from "@/store/modules/coupons/mutation-types"
import xlsx_helper from "@/utils/xlsx-helper"
import Vue from "vue"
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import { CouponType } from "@arikgaisler/utils/enums/couponType"

const vue = new Vue()

function logError(msg, e) {
    console.warn(e)
    vue.bugsnag.notify(
        msg,
        event => {
            event.addMetadata("error", {
                error: e
            })
        }
    )
    eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
        title: msg,
        type: NotificationType.ERROR
    })
}
function transformCouponToSearchObj(coupon, business) {
    const searchObj = {
        code: coupon.code,
        id: coupon.id,
        type: coupon.type,
        coupon_type: coupon.coupon_type,
        num_of_uses: coupon.num_of_uses,
        usage_limit: coupon.usage_limit,
        amount: coupon.amount,
        ref_order_id: coupon.ref_order_id
    }
    if (business) {
        searchObj.business = {}
        searchObj.business.id = coupon.business_id
        searchObj.business.name = business.name
        searchObj.business.type = business.type
    }
    return searchObj
}


function couponSearchParamsToFilters(payload) {
    let filters = {}

    if (payload.code) {
        filters.codes = [payload.code]
    }
    if (payload.type && payload.type >= 0) {
        filters.coupon_types = [payload.type]
    } else {
        filters.coupon_types = [CouponType.DEFAULT, CouponType.CREDIT]
    }
    if (payload.businessId) {
        filters.business = {}
        filters.business.ids = [payload.businessId]
    }

    if (payload.used != undefined || payload.used != null) {
        filters.used = payload.used == 1 ? true : false
    }

    if (payload.couponGroup != undefined) {
        filters.coupon_group = payload.couponGroup
    }
    return filters
}

export default {
    async [ActionTypes.SEARCH_COUPONS]({ commit }, payload) {
        try {
            commit(MutationTypes.LOADING)
            const couponsResp = await search_service.searchCoupons({
                filters: couponSearchParamsToFilters(payload),
                sorting: [
                    {
                        sort_by: "create_date",
                        sort_order: "desc"
                    }
                ],
                pagination: {
                    offset: payload.pageStart,
                    limit: payload.pageSize
                }
            })
            commit(MutationTypes.GOT_COUPONS, couponsResp)
            commit(MutationTypes.LOADED)
        } catch (e) {
            logError("Unable to get coupons", e)
            commit(MutationTypes.LOADED)
            return false
        }
    },
    async [ActionTypes.SEARCH_VOUCHERS]({ commit }, payload) {
        try {
            commit(MutationTypes.LOADING)
            payload.type = CouponType.VOUCHER
            const vouchersResp = await search_service.searchCoupons({
                filters: couponSearchParamsToFilters(payload),
                sorting: [
                    {
                        sort_by: "create_date",
                        sort_order: "desc"
                    }
                ],
                pagination: {
                    offset: payload.pageStart,
                    limit: payload.pageSize
                }
            })
            const voucherRequestIds = vouchersResp.data.coupons.reduce((ids, voucher) => {
                if (voucher.voucher_request_id != null && !ids.includes(voucher.voucher_request_id)) {
                    ids.push(voucher.voucher_request_id)
                }
                return ids
            }, [])
            
            let voucherRequestMap = {}
            if (voucherRequestIds.length > 0) {
                const voucherRequestResponse = await search_service.searchVoucherRequests({
                    filters: {
                        ids: voucherRequestIds
                    }
                })
                voucherRequestMap = voucherRequestResponse.data.voucher_requests.reduce((voucherRequests, voucherRequest) => {
                    voucherRequests[voucherRequest.id] = voucherRequest
                    return voucherRequests
                }, {})
            }

            vouchersResp.data.coupons.forEach((voucher) => {
                const voucherRequest = voucherRequestMap[voucher.voucher_request_id]
                if (voucherRequest) {
                    voucher.voucherRequest = voucherRequest
                }
            })

            commit(MutationTypes.GOT_VOUCHERS, vouchersResp)
            commit(MutationTypes.LOADED)
        } catch (e) {
            logError("Unable to get vouchers", e)
            commit(MutationTypes.LOADED)
            return false
        }
    },
    async [ActionTypes.UPDATE_COUPON]({ commit, rootState, rootGetters }, coupon) {
        try {
            commit(MutationTypes.LOADING)
            coupon.created_by = rootState.user_id
            const business = rootGetters.businessesMap[coupon.business_id]
            const couponResp = await pricing_service.updateCoupon(coupon.id, { data: coupon })
            if (couponResp.status == 200) {
                const couponSearchObj = transformCouponToSearchObj(couponResp.data, business)
                commit(MutationTypes.UPDATED_COUPON, couponSearchObj)
                commit(MutationTypes.LOADED)
                return true
            }
            return false
        } catch (e) {
            logError("Unable to update coupon", e)
            commit(MutationTypes.LOADED)
            return false
        }
    },
    async [ActionTypes.ADD_COUPON]({ commit, rootState, rootGetters }, payload) {
        try {
            commit(MutationTypes.LOADING)
            payload.coupon.created_by = rootState.user_id
            const business = rootGetters.businessesMap[payload.coupon.business_id]
            const data = { 
                data: [payload.coupon]
            }

            if (payload.sendMessage) {
                data.send_msg = payload.sendMessage

                if (payload.msg) {
                    data.msg = payload.msg
                }
            }

            const couponResp = await pricing_service.addCoupon(data)
            if (couponResp.status == 200) {
                await commit(MutationTypes.ADDED_COUPON, transformCouponToSearchObj(couponResp.data.coupons[0], business))
                commit(MutationTypes.LOADED)
                return true
            }
            return false
        } catch (e) {
            logError("Unable to add coupon", e)
            commit(MutationTypes.LOADED)
            return false
        }
    },
    async [ActionTypes.GET_VOUCHER_REQUESTS]({ commit }, payload) {
        try {
            commit(MutationTypes.LOADING)
            const filters = {}
            payload = payload ? payload : {}

            if (payload.businessId) {
                filters.business = {
                    ids: [payload.businessId]
                }
            }

            if (payload.name && payload.name.length > 0) {
                filters.names = [payload.name]
            }

            if (payload.issuedBy) {
                filters.issued_by = [payload.issuedBy]
            }

            if (payload.distributionType >= 0) {
                filters.distribution_types = [payload.distributionType]
            }

            if (payload.paymentType >= 0) {
                filters.payment_types = [payload.paymentType]
            }

            if (payload.status >= 0) {
                filters.statuses = [payload.status]
            }

            if (payload.used != null) {
                filters.used = payload.used ? 1 : 0
            }

            const pagination = {
                limit: payload.pageSize,
                offset: payload.pageStart
            }

            const response = await search_service.searchVoucherRequests({
                filters: filters,
                sorting: [
                    {
                        sort_by: "created_date",
                        sort_order: "desc"
                    }
                ],
                pagination: pagination
            })
            commit(MutationTypes.GOT_VOUCHER_REQUESTS, response)
            commit(MutationTypes.LOADED)
        } catch (e) {
            logError("Unable to get voucher requests", e)
            commit(MutationTypes.LOADED)
            return false
        }
    },
    async [ActionTypes.GET_VOUCHER_REQUEST]({ state, commit }, id) {
        try {
            if (id) {
                commit(MutationTypes.GOT_VOUCHER_REQUEST, await pricing_service.getVoucherRequest(id))
            } else {
                commit(MutationTypes.GOT_VOUCHER_REQUEST, {})
            }
            return state.voucherRequest
        } catch (e) {
            vue.bugsnag.notify(
                "Unable to get voucher request",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        id: id
                    })
                }
            )
            console.warn("Unable to get voucher request", e)
            return false
        }
    },
    async [ActionTypes.GET_VOUCHER_REQUEST_VOUCHERS]({ state, commit }, id) {
        try {
            const response = await pricing_service.getVoucherRequestVouchers(id)
            if (response.status == 200) {
                commit(MutationTypes.GOT_VOUCHER_REQUEST_VOUCHERS, response.data.vouchers)
                return true
            } else {
                throw `API Error: ${response.status}`
            }

        } catch (e) {
            vue.bugsnag.notify(
                "Unable to get voucher request vouchers",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        id: state.event.id
                    })
                }
            )
            return false
        }
    },
    async [ActionTypes.ADD_VOUCHER_REQUEST]({ commit }, payload) {
        try {
            commit(MutationTypes.LOADING)
            const voucherReqResp = await pricing_service.addVoucherRequest(payload.voucherRequest)
            if (voucherReqResp.status == 200) {
                if (payload.menu) {
                    voucherReqResp.data.voucher_request.menu = payload.menu
                }
                commit(MutationTypes.ADDED_VOUCHER_REQUEST, voucherReqResp.data.voucher_request)
                commit(MutationTypes.LOADED)
                return true
            }
            return false
        } catch (e) {
            logError("Unable to add voucher request", e)
            commit(MutationTypes.LOADED)
            return false
        }
    },
    async [ActionTypes.UPDATE_VOUCHER_REQUEST]({ commit }, payload) {
        try {
            commit(MutationTypes.LOADING)
            const voucherRequestResp = await pricing_service.updateVoucherRequest(payload.voucherRequest.id, payload.voucherRequest)
            if (voucherRequestResp.status == 200) {
                voucherRequestResp.data.voucher_request.menu = payload.menu
                commit(MutationTypes.UPDATED_VOUCHER_REQUEST, voucherRequestResp.data.voucher_request)
                commit(MutationTypes.LOADED)
                return true
            }
            return false

        } catch (e) {
            logError("Unable to update voucher request", e)
            commit(MutationTypes.LOADED)
            return false
        }
    },
    async [ActionTypes.UPDATE_VOUCHER_REQUEST_STATUS]({ commit }, payload) {
        try {
            commit(MutationTypes.LOADING)
            let apiPayload = { "status": payload.status }
            const voucherRequestResp = await pricing_service.updateVoucherRequestStatus(payload.id, apiPayload)
            if (voucherRequestResp.status == 200) {
                commit(MutationTypes.UPDATED_VOUCHER_REQUEST, voucherRequestResp.data.voucher_request)
                commit(MutationTypes.LOADED)
                return true
            }
            return false

        } catch (e) {
            logError("Unable to update voucher request status", e)
            commit(MutationTypes.LOADED)
            return false
        }
    },
    async [ActionTypes.DELETE_VOUCHER_REQUEST]({ commit }, voucherRequestId) {
        try {
            const voucherRequestResp = await pricing_service.deleteVoucherRequest(voucherRequestId)
            if (voucherRequestResp.status == 200) {
                commit(MutationTypes.DELETED_VOUCHER_REQUEST, voucherRequestId)
                return true
            } else {
                throw "Unable to delete voucher request"
            }
        } catch (e) {
            logError("Unable to delete voucher request", e)
            return false
        }
    },
    async [ActionTypes.PRINT_VOUCHER](store, payload) {
        try {
            const apiPayload = { "ids": payload.ids, "business_id": payload.businessId }
            const printVoucherResp = await pricing_service.printVoucher(apiPayload)
            if (printVoucherResp.status == 200) {
                return printVoucherResp.data.url
            } else {
                throw "Unable to generate voucher file"
            }
        } catch (e) {
            logError("Unable to generate voucher file", e)
            return null
        }
    },
    async [ActionTypes.SEND_VOUCHER_EMAIL]({ commit }, payload) {
        try {
            let apiPayload = { "user_email": payload.userEmail }
            const resp = await pricing_service.sendVoucherEmail(payload.id, apiPayload)
            if (resp.status == 200) {
                commit(MutationTypes.VOUCHER_EMAIL_SENT, payload.id)
                return true
            } else {
                throw "Unable to send voucher"
            }
        } catch (e) {
            logError("Unable to send voucher", e)
            return false
        }
    },
    [ActionTypes.EXPORT_BATCH_UPLOAD_TEMPLATE]() {
        const exportData = [{
            "Code": "",
            "Amount": "",
            "Type": "",
            "Max Uses": "",
            "Business Id": "",
            "Staff Coupon": ""
        }]
        xlsx_helper.export(exportData, "Coupon Upload Template")
    },
    async [ActionTypes.BATCH_UPLOAD_COUPONS]({ commit, rootState, rootGetters }, file) {
        try {
            commit(MutationTypes.LOADING)
            if (!xlsx_helper.isValidXlSXFile(file)) {
                throw "The file selected was not a CSV or XLSX (excel) file."
            }
            const couponRows = await xlsx_helper.readFile(file)
            const totalCouponRows = couponRows.length
            const couponJSON = couponRows
                .filter((row) => row.Code && row.Amount && row.Type != undefined && row["Max Uses"] != undefined)
                .map((row) => {
                    return {
                        code: row.Code,
                        amount: row.Amount,
                        type: row.Type,
                        coupon_type: CouponType.DEFAULT,
                        usage_limit: row["Max Uses"],
                        business_id: row["Business Id"] ? row["Business Id"] : null,
                        is_staff: row["Staff Coupon"] ? row["Staff Coupon"] : 0,
                        created_by: rootState.user_id,
                    }
                })
            if (couponJSON.length <= 0) {
                throw "No coupons found in the file, please check the file and try again."
            }
            const couponsResp = await pricing_service.addCoupon({ "data": couponJSON })
            if (couponsResp.status == 200) {
                let couponsSearchObj = couponsResp.data.coupons.map(obj => {
                    return transformCouponToSearchObj(obj, rootGetters.businessesMap[obj.business_id])
                })
                commit(MutationTypes.ADDED_COUPONS, couponsSearchObj)
                eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
                    title: `Succesfully uploaded ${couponJSON.length}/${totalCouponRows} coupons!`
                })
            }
            commit(MutationTypes.LOADED)
        } catch (e) {
            logError(e.message ? "Unable to batch upload coupons" : e, e)
            commit(MutationTypes.LOADED)
            return false
        }
    },
    async [ActionTypes.DELETE_COUPON]({ commit }, coupon_id) {
        try {
            const couponResp = await pricing_service.deleteCoupon(coupon_id)
            if (couponResp.status == 200) {
                commit(MutationTypes.REMOVE_COUPON, coupon_id)
                return true
            } else {
                throw "Unable to delete coupon"
            }
        } catch (e) {
            logError("Unable to delete coupon", e)
            return false
        }
	},
	async [ActionTypes.UPDATE_VOUCHER]({ commit }, data) {
		try {
			let payload = {
				code: data.code,
				valid_to: moment(data.endDate).format(
					"YYYY-MM-DD hh:mm:ss.SSS"
				),
				usage_limit: data.usage_limit
			}
			const couponResp = await pricing_service.updateVoucher(payload)
			if (couponResp.status == 200) {
				commit(MutationTypes.UPDATE_VOUCHER, payload)
				return true
			}
		} catch (e) {
			logError(e.message, e)
			commit(MutationTypes.LOADED)
			return false
		}
	}
}