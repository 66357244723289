<template>
	<div class="sk-grid supply-shop-container">
		<SupplyItem
			v-for="supplyItem in businessSupplies"
			:key="`${supplyItem.id}-0`"
			:item="supplyItem"
			@request="requestBusinessSupply"
		/>
		<SupplyItem
			v-for="supplyItem in restaurantSupplies"
			:key="`${supplyItem.id}-1`"
			:item="supplyItem"
			@request="requestRestaurantSupply"
		/>
	</div>
</template>

<style scoped>
.supply-shop-container {
	min-width: 600px;
	max-width: 1200px;
	grid-template-columns: 1fr 1fr;
}

@media (min-width: 1280px) {
	.supply-shop-container {
		margin: 20px auto;
	}
}
</style>

<script>
import { EntityType } from "@/enums/entityType"
import SupplyItem from "@/components/supplies/SupplyItem.vue"

export default {
	name: "SupplyShop",
	components: {
		SupplyItem
	},
	computed: {
		businessSupplies() {
			return this.$store.state.businessesModule.supplies
		},
		restaurantSupplies() {
			return this.$store.state.restaurantsModule.supplies
		}
	},
	methods: {
		requestBusinessSupply(supplyItem) {
			this.$emit("requestSupply", {
				supplyItem: supplyItem,
				entityType: EntityType.BUSINESS
			})
		},
		requestRestaurantSupply(supplyItem) {
			this.$emit("requestSupply", {
				supplyItem: supplyItem,
				entityType: EntityType.RESTAURANT
			})
		}
	}
}
</script>