<template>
  <div class="dropzone-message">
    <img v-if="imgSrc" class="preview-image" :src="previewImage">
    <div v-else class="preview-image-placeholder">
      <span>{{placeholderLogo}}</span>
    </div>
    <div @click="checkDisabled">
      <div class="dropzone-title">{{titleText}}</div>
      <div class="dropzone-recommendation">recommended size {{recommendedSize}}</div>
      <vue-dropzone 
        :class="{'dropzone-disabled': disabled}"
        :id="dropzoneId" 
        :options="dropOptions" 
        :awss3="awss3" 
        :useCustomSlot="true"
        :includeStyling="false"
        @vdropzone-success="success"
        @vdropzone-processing="loading(true)"
        @vdropzone-queue-complete="loading(false)">
        <button class="button button-light-grey">
          <i class="sk-icon-camera-solid"></i>
          Change Photo
        </button>
      </vue-dropzone>
    </div>
  </div>
</template>

<style scoped>
.dz-message {
  display: block;
}

* >>> .dz-preview {
  display: none;
}

.dropzone-message {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.preview-image-placeholder,
.preview-image {
  border-radius: 50%;
  width: 75px;
  height: 75px;
  margin-right: 20px;
  object-fit: cover;
}

.preview-image-placeholder {
  background: var(--sk-navy);
  color: var(--sk-white);
  font-size: 50px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropzone-title,
.dropzone-recommendation {
  text-align: left;
}

.dropzone-title {
  font-size: 14px;
  font-weight: 600;
  color: var(--sk-grey3);
}

.dropzone-recommendation {
  color: var(--sk-grey2);
  font-size: 12px;
  margin-bottom: 10px;
}

.button-light-grey {
  max-height: 25px;
  min-height: 25px;
  height: 25px;
}

.dropzone-disabled {
  pointer-events: none;
}
</style>

<script>
import imageUploadMixin from "@/mixins/image-upload-mixin"

export default {
  name: "ImageUpload",
  mixins: [imageUploadMixin],
  computed: {
    placeholderLogo() {
      return this.placeholderText ? this.placeholderText[0].toUpperCase() : ""
    }
  },
  props: {
    placeholderText: String
  }
}
</script>