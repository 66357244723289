<template>
	<SkBaseModal class="sk-modal-alt" :isOpen="isOpen" @close="cancel">
		<button class="button button-icon button-close" @click="cancel()">
			<i class="sk-icon-times-regular"></i>
		</button>
		<div class="sk-modal-alt-title">
			<img src="/images/reef-logo.png" alt="Reef Logo" />
			<h1>Reef</h1>
		</div>
		<section
			class="add-store-section"
			v-for="store in storeIDs"
			:key="store.uuid"
		>
			<div class="sk-row">
				<SkInput
					v-model="store.id"
					:ref="`storeIDInput${store.uuid}`"
					:name="'Vessel ID'"
					:required="true"
				/>
			</div>
			<button
				@click="removeStore(store)"
				class="button button-text sk-widget-section-text-button-light"
			>
				<i class="sk-icon-times-regular"></i>
				Remove Store ID
			</button>
		</section>
		<div class="sk-row">
			<button
				@click="addNewStore"
				class="button button-text sk-widget-section-text-button-light"
			>
				<i class="sk-icon-plus-regular"></i>
				Add Store ID
			</button>
		</div>
		<div class="sk-modal-actions">
			<button class="button button-text" @click="cancel">Cancel</button>
			<button class="button button-primary" @click="saveIntegration">
				{{ confirmBtnText }}
			</button>
		</div>
	</SkBaseModal>
</template>
<style scoped>
.add-store-section {
	border-bottom: 1px solid var(--sk-grey);
	margin-bottom: 30px;
	padding-bottom: 10px;
}
</style>
<script>
import { v1 as uuidv1 } from "uuid"
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import skPromiseModalMixin from "@/mixins/modals/sk-promise-modal-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import SkInput from "@/components/SkInput.vue"

export default {
	name: "ReefIntegrationModal",
	mixins: [skPromiseModalMixin],
	components: {
		SkBaseModal,
		SkInput
	},
	data: function () {
		return {
			storeID: "",
			editing: false,
			storeIDs: []
		}
	},
	computed: {
		confirmBtnText() {
			return this.editing ? "Save Integration" : "Add Integration"
		}
	},
	methods: {
		handleOptions(options) {
			if (options.integration) {
				this.editing = true
				if (options.brands && options.brands.length > 0) {
					this.storeIDs = options.brands.map((brand) => {
						return {
							id: brand.external_id,
							uuid: uuidv1()
						}
					})
				} else {
					this.storeIDs = []
				}
			} else {
				this.editing = false
				this.storeIDs = []
				this.storeIDs.push(this.newStore())
			}
		},
		newStore() {
			return {
				id: "",
				uuid: uuidv1()
			}
		},
		addNewStore() {
			this.storeIDs.push(this.newStore())
		},
		validateInput(inputRef) {
			if (this.$refs[inputRef]) {
				if (this.$refs[inputRef].length > 0) {
					this.$refs[inputRef][0].validate()
				} else {
					this.$refs[inputRef].validate()
				}
			}
		},
		resetValidation(inputRef) {
			if (this.$refs[inputRef]) {
				if (this.$refs[inputRef].length > 0) {
					this.$refs[inputRef][0].resetValidation()
				} else {
					this.$refs[inputRef].resetValidation()
				}
			}
		},
		storeIDsValid() {
			this.storeIDs.forEach((store) => {
				const storeIDValid = this.validateInput(
					`storeIDInput${store.uuid}`
				)
				if (!storeIDValid) {
					return false
				}
			})
			return true
		},
		removeStore(store) {
			if (this.storeIDs.length > 1) {
				this.storeIDs = this.storeIDs.filter(
					(existingStore) => store.id != existingStore.id
				)
			} else {
				const store = this.newStore()
				this.storeIDs.splice(0, 1, store)
				this.resetValidation(`storeIDInput${store.uuid}`)
			}
		},
		saveIntegration() {
			if (!this.storeIDsValid()) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Reef Integration",
					message: "A Vessel ID is required",
					type: NotificationType.WARNING
				})
				return
			}
			this.confirm({
				storeIDs: this.storeIDs
			})
		}
	}
}
</script>