<template>
	<SkBaseModal :isOpen="isOpen" @close="close" class="sk-modal-alt">
		<div class="sk-modal-alt-title">
			<h1>
				Advanced Filtering
			</h1>
		</div>
		<div
			v-for="(groupId, index) in sortedGroups"
			class="filter-group"
			:style="{ zIndex: 1000 - index }"
			:key="groupId"
		>
			<div
				class="filter-group-header"
				@click="toggleExpandGroup(groupId)"
			>
				{{ groupId | groupName(filterGroups) }}
				<div class="filter-group-divider"></div>
				<i
					class="sk-icon-chevron-down-regular"
					:class="{ flipped: expandedGroupIds[groupId] }"
				></i>
			</div>
			<transition-expand>
				<div v-if="expandedGroupIds[groupId]">
					<div
						v-for="filter in filtersByGroup[groupId]"
						class="advanced-filter-row"
						:class="{
							'advanced-filter-third': filter.type == 'checkbox'
						}"
						:key="filter.id"
					>
						<slot
							v-if="
								filter.type == 'input' &&
								filterValues[filter.id]
							"
						>
							<SkInput
								:name="filter.placeholder"
								v-model="filterValues[filter.id].formatted"
								@change="filterSet(filter, $event.target.value)"
							/>
						</slot>
						<slot
							v-if="
								filter.type == 'search' &&
								filterValues[filter.id]
							"
						>
							<TypeaheadInput
								:placeholder="filter.placeholder"
								:items="filter.items"
								:serializer="filter.serializer"
								:fetch="filter.fetch"
								:query="filterValues[filter.id].formatted"
								@selected="filterSet(filter, ...arguments)"
							/>
						</slot>
						<slot
							v-if="
								filter.type == 'dropdown' &&
								filterValues[filter.id]
							"
						>
							<SkDropdownSelect
								:value="filterValues[filter.id].value"
								class="sk-input"
								:options="filter.items"
								@selected="filterSet(filter, ...arguments)"
							/>
						</slot>
						<slot
							v-if="
								(filter.type == 'date' ||
									filter.type == 'daterange') &&
								filterValues[filter.id]
							"
						>
							<VueCtkDateTimePicker
								:no-value-to-custom-elem="true"
								:no-clear-button="true"
								:no-header="true"
								:auto-close="true"
								:no-label="true"
								:no-button-now="true"
								:no-button="true"
								:only-date="true"
								:format="'MM-DD-YYYY'"
								:formatted="'ll'"
								:color="'#2E3C49'"
								:output-format="'MMM DD, YYYY'"
								:range="filter.type == 'daterange'"
								:noShortcuts="true"
								v-model="filterValues[filter.id].formatted"
								@input="filterSet(filter, ...arguments)"
							/>
						</slot>
						<slot
							v-if="
								filter.type == 'checkbox' &&
								filterValues[filter.id]
							"
						>
							<div
								class="checkbox-wrapper"
								@click="filterSet(filter, ...arguments)"
							>
								<SkCheckbox
									@click="filterSet(filter, ...arguments)"
									:checked="filterValues[filter.id].value"
								/>
								<span class="checkbox-label">
									{{ filter.label }}
								</span>
							</div>
						</slot>
					</div>
				</div>
			</transition-expand>
		</div>
		<div class="sk-modal-actions">
			<button @click="close" class="button button-text">
				Close
			</button>
			<button
				type="button"
				class="button button-primary"
				@click="applyFilters"
			>
				Apply Filters
			</button>
		</div>
	</SkBaseModal>
</template>

<style scoped>
.filter-group-header {
	font-size: 14px;
	font-weight: 600;
	color: var(--sk-navy);
	display: flex;
	align-items: center;
	width: 100%;
	cursor: pointer;
}

.filter-group {
	margin-bottom: 20px;
	position: relative;
}

.filter-group-divider {
	height: 1px;
	background: rgba(112, 120, 124, 0.35);
	width: 100%;
	margin-left: 15px;
}

.filter-group-header .sk-icon-chevron-down-regular {
	margin-left: 15px;
	font-size: 10px;
	color: var(--sk-grey2);
}

.advanced-filter-row {
	margin-top: 20px;
}

.advanced-filter-third {
	width: 33%;
	display: inline-block;
}

.checkbox-wrapper {
	display: flex;
	align-items: center;
}

.checkbox-wrapper .checkbox-label {
	margin: 0;
	font-size: 14px;
	color: var(--sk-grey2);
}
</style>

<script>
import moment from "moment"
import utils from "@/utils/utils"
import skPromiseModalMixin from "@/mixins/modals/sk-promise-modal-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import TransitionExpand from "@/components/transitions/TransitionExpand.vue"
import SkInput from "@/components/SkInput.vue"
import TypeaheadInput from "@/components/TypeaheadInput.vue"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"
import VueCtkDateTimePicker from "vue-ctk-date-time-picker"
import SkCheckbox from "@/components/SkCheckbox.vue"

export default {
	name: "AdvancedFilteringModal",
	filters: {
		groupName(groupId, groups) {
			if (groupId == -1) {
				return "Basic"
			} else {
				return groups[groupId]
			}
		},
		parseDateValue: function (value, filter) {
			if (value) {
				if (filter.type == "daterange") {
					return utils.formatDateRange(value)
				} else {
					return value
				}
			}
			return filter.placeholder
		}
	},
	mixins: [skPromiseModalMixin],
	components: {
		SkBaseModal,
		TransitionExpand,
		SkInput,
		TypeaheadInput,
		SkDropdownSelect,
		VueCtkDateTimePicker,
		SkCheckbox
	},
	props: {
		filters: {
			type: Array,
			default: () => []
		},
		filterGroups: {
			type: Object,
			default: () => {}
		}
	},
	data: function () {
		return {
			expandedGroupIds: {},
			filterValues: {}
		}
	},
	computed: {
		sortedGroups: function () {
			return Object.keys(this.filtersByGroup).sort()
		},
		filtersByGroup() {
			const groups = {
				"-1": []
			}
			this.filters.forEach((filter) => {
				if (filter.advancedGroupId != undefined) {
					if (!groups[filter.advancedGroupId]) {
						groups[filter.advancedGroupId] = []
					}
					groups[filter.advancedGroupId].push(filter)
				} else {
					groups["-1"].push(filter)
				}
			})

			return groups
		}
	},
	methods: {
		handleOptions(options) {
			this.filterValues = utils.clonedeep(options.filterValues)
		},
		closed() {
			setTimeout(() => {
				if (!this.isOpen) {
					this.filterValues = {}
				}
			}, 1000)
		},
		toggleExpandGroup(groupId) {
			if (this.expandedGroupIds[groupId]) {
				this.$set(this.expandedGroupIds, groupId, false)
			} else {
				this.$set(this.expandedGroupIds, groupId, true)
			}
		},
		filterSet(filter, option) {
			if (filter.type == "date") {
				this.filterValues[filter.id].value = option
					? moment(option).format("YYYY-MM-DD")
					: null

				if (this.filterValues[filter.id].value == null) {
					this.filterValues[filter.id].formatted = null
				}
			} else if (filter.type == "daterange") {
				if (option == null) {
					this.filterValues[filter.id].value = null
					this.filterValues[filter.id].formatted = null
				} else {
					this.filterValues[filter.id].value = {
						from: option.start
							? moment(option.start).format("YYYY-MM-DD")
							: null,
						to: option.end
							? moment(option.end).format("YYYY-MM-DD")
							: moment(option.start)
									.add(1, "days")
									.format("YYYY-MM-DD")
					}
				}
			} else if (filter.type == "checkbox") {
				this.filterValues[filter.id].value = this.filterValues[
					filter.id
				].value
					? false
					: true
			} else if (filter.type == "search") {
				this.filterValues[filter.id].value = option
				this.filterValues[filter.id].formatted = filter.serializer(
					option
				).text
			} else {
				this.filterValues[filter.id].value = option
				this.filterValues[filter.id].formatted = option
			}
		},
		async applyFilters() {
			this.confirm(this.filterValues)
		}
	},
	watch: {
		filters: {
			immediate: true,
			handler(newVal) {
				if (newVal) {
					newVal.forEach((filter) => {
						let groupId = filter.advancedGroupId
						if (
							groupId != undefined &&
							!this.expandedGroupIds[groupId]
						) {
							this.$set(this.expandedGroupIds, groupId, true)
						}
					})
				} else {
					this.expandedGroupIds = {}
				}
			}
		}
	}
}
</script>