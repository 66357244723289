<template>
	<div class="sk-page-content">
		<PageHeader
			:title="headerTitle"
			:actions="actions"
			:tabs="tabs"
			:breadcrumbs="breadcrumbs"
			@actionClicked="headerActionClicked"
			@tabClicked="headerTabClicked"
		>
			<SkToggle
				slot="title-decorator"
				:value="menu.stock_status"
				@input="updateMenuStock"
			/>
		</PageHeader>
		<transition name="slide" mode="out-in">
			<RestaurantMenuItems
				v-if="activeTab.id == 0 && didInitialLoad"
				key="0"
				:menu="menu"
				ref="restaurantMenuItems"
				@addItem="addItemClicked"
			/>
			<RestaurantMenuBasicInfo
				v-if="activeTab.id == 1 && didInitialLoad"
				key="1"
				:menu="menu"
				@save="saveRestaurantMenu"
			/>
			<RestaurantMenuSchedules
				v-if="activeTab.id == 2 && didInitialLoad"
				key="2"
				ref="restaurantMenuSchedules"
				:menu="menu"
				:hours="menu.opening_hours"
				@setHours="setHours"
				@save="saveRestaurantMenu"
			/>
		</transition>
		<ConfirmationModal ref="confirmationModal" />
		<AddMenuProductsModal ref="addMenuProductsModal" />
		<SyncBusinessMenusProductsModal ref="syncBusinessMenusProductsModal" />
	</div>
</template>

<script>
import { ConfirmationModalType } from "@/enums/confirmationModalType"
import { PermissionMethod, PermissionTab } from "@/utils/permissions"
import tracker from "@/utils/tracker"
import loaderMixin from "@/mixins/loader-mixin"
import PageHeader from "@/components/PageHeader.vue"
import SkToggle from "@/components/SkToggle.vue"
import RestaurantMenuItems from "@/components/restaurant-menu/RestaurantMenuItems.vue"
import RestaurantMenuBasicInfo from "@/components/restaurant-menu/RestaurantMenuBasicInfo.vue"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue"
import AddMenuProductsModal from "@/components/modals/AddMenuProductsModal.vue"
import RestaurantMenuSchedules from "@/components/restaurant-menu/RestaurantMenuSchedules.vue"
import SyncBusinessMenusProductsModal from "@/components/modals/SyncBusinessMenusProductsModal.vue"

export default {
	name: "RestaurantMenu",
	mixins: [loaderMixin],
	components: {
		PageHeader,
		SkToggle,
		RestaurantMenuItems,
		RestaurantMenuBasicInfo,
		ConfirmationModal,
		AddMenuProductsModal,
		RestaurantMenuSchedules,
		SyncBusinessMenusProductsModal
	},
	data: function () {
		return {
			from: null,
			didMount: false,
			didInitialLoad: false,
			activeTabId: 0
		}
	},
	mounted: function () {
		this.didMount = true
		this.$store.dispatch(
			"restaurantsModule/getRestaurantMenu",
			this.$route.params.menu_id
		)
	},
	methods: {
		headerActionClicked() {
			this.addProduct()
			tracker.trackEvent("New Menu Item Clicked")
		},
		async headerTabClicked(tabClicked) {
			tracker.trackEvent("Restaurant Menu Tab Clicked", {
				tab_name: tabClicked.text
			})
			if (
				this.activeTab.id == 2 &&
				this.$refs.restaurantMenuSchedules.hasChanges
			) {
				const confirmed = await this.$refs.confirmationModal.open({
					title: `You have unsaved changes`,
					body: `Are you sure you want to leave this page without saving your changes?`,
					confirmation: "Save Changes",
					cancel: "Continue Without Saving",
					type: ConfirmationModalType.WARNING
				})
				if (confirmed) {
					this.$refs.restaurantMenuSchedules.updateMenuHours()
					await this.saveRestaurantMenu()
				}
			}
			this.activeTabId = tabClicked.id
		},
		updateMenuStock(stockStatus) {
			this.$store.dispatch(
				"restaurantsModule/updateMenuStock",
				stockStatus
			)
		},
		async saveRestaurantMenu() {
			tracker.trackEvent("Restaurant Menu Save Clicked")
			await this.$store.dispatch(
				"restaurantsModule/updateRestaurantMenu",
				this.menu
			)
		},
		setHours(hours) {
			this.$store.dispatch("restaurantsModule/updateMenuHours", {
				menuId: this.menu.id,
				hours: hours
			})
		},
		addItemClicked() {
			this.addProduct()
		},
		addProduct() {
			this.$refs.addMenuProductsModal.open().then((data) => {
				if (data.menuId && data.productIds && this.$store.getters.isSuperAdmin) {
					const businessMenusToSync = []
					this.$store.state.restaurantsModule.restaurantBusinesses.forEach(
						(business) => {
							business.menus
								.filter((menu) => menu.id == data.menuId)
								.forEach((menu) => {
									businessMenusToSync.push({
										menu: menu,
										menuName: menu.name,
										businessId: business.id,
										businessName: business.name
									})
								})
						}
					)

					if (businessMenusToSync.length > 0) {
						this.$refs.syncBusinessMenusProductsModal.open({
							restaurantMenuIds: [data.menuId],
							productIds: data.productIds,
							businessMenusToSync: businessMenusToSync
						})
					}
				}
			})
		}
	},
	computed: {
		canCreateProduct() {
			return this.$store.state.permissions.includes(
				PermissionMethod.NEW_RESTAURANT_ITEM
			)
		},
		tabs() {
			const allowedTabs = [
				{
					id: 0,
					text: "Items"
				},
				{
					id: 2,
					text: "Schedules"
				}
			]

			if (
				this.$store.state.permissions.includes(
					PermissionTab.RESTAURANT_MENU_DETAILS
				)
			) {
				allowedTabs.push({
					id: 1,
					text: "Details"
				})
			}

			return allowedTabs
				.map((tab) => {
					tab.active = tab.id == this.activeTabId
					return tab
				})
				.sort((a, b) => a.id - b.id)
		},
		actions() {
			switch (this.activeTab.id) {
				case 0: {
					if (this.canCreateProduct) {
						return [
							{
								id: 0,
								text: `<i class="sk-icon-plus-regular"></i> New Item`,
								classes: "button button-primary"
							}
						]
					}
					break
				}
			}
			return null
		},
		activeTab() {
			return this.tabs ? this.tabs.find((tab) => tab.active) : null
		},
		loading() {
			return this.$store.state.restaurantsModule.loading || !this.didMount
		},
		restaurant() {
			return this.$store.state.restaurantsModule.currentRestaurant
		},
		headerTitle() {
			return this.menu ? this.menu.name : "..."
		},
		menu() {
			if (
				this.$route.params.menu_id != undefined &&
				this.restaurant != undefined
			) {
				return this.restaurant.menus.find(
					(menu) => menu.id == this.$route.params.menu_id
				)
			} else {
				return null
			}
		},
		breadcrumbs() {
			return [
				{
					name: "Restaurants",
					link: "/restaurants"
				},
				{
					name: this.restaurant ? this.restaurant.name : "Restaurant",
					link: `/restaurant/${this.$route.params.rest_slug}`
				},
				{
					name: this.menu ? this.menu.name : "Menu"
				}
			]
		}
	},
	watch: {
		loading: function (newVal) {
			this.toggleLoader(newVal)
			if (!newVal) {
				this.didInitialLoad = true
			}
		}
	}
}
</script>
