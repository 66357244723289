<template>
	<SkBaseModal
		class="sk-modal-alt"
		:isOpen="isOpen"
		:hasLoader="true"
		@close="close"
	>
		<loading :active.sync="loading" :is-full-page="false" />
		<div class="sk-modal-alt-title">
			<h1>Attach to Businesses?</h1>
		</div>
		<div class="sk-row">
			<div
				class="sk-switch-label-wrapper sk-modal-select-all"
				@click="selectAll"
			>
				<SkCheckbox :checked="allSelected" />
				<span>Select All</span>
			</div>
		</div>
		<div
			class="sk-row sk-widget-alt business-row"
			v-for="menuOption in menuOptions"
			:key="menuOption.key"
			@click="optionSelected(menuOption)"
		>
			<SkCheckbox :checked="selectedKeys.includes(menuOption.key)" />
			<div class="business-name">
				<b>{{ menuOption.businessName }}</b>
				({{ menuOption.restaurantName }} - {{ menuOption.menuName }})
			</div>
		</div>
		<div class="sk-modal-actions">
			<button class="button button-text" @click="close">Not Now</button>
			<button class="button button-primary" @click="attachMenus">
				Attach
			</button>
		</div>
	</SkBaseModal>
</template>

<style scoped>
.sk-switch-label-wrapper {
	margin-left: 20px;
}

.sk-checkbox {
	margin-right: 20px;
}

.business-name {
	font-size: 14px;
	color: var(--sk-dark-navy);
}

.business-row {
	align-items: center;
	margin: 15px 0;
}
</style>

<script>
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import ActionTypes from "@/store/modules/hubs/action-types"
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import SkCheckbox from "@/components/SkCheckbox.vue"
import Loading from "vue-loading-overlay"

export default {
	name: "AddHubRestaurantMenuToBusinesses",
	mixins: [skModalMixin],
	components: {
		SkBaseModal,
		SkCheckbox,
		Loading
	},
	data() {
		return {
			loading: false,
			selectedKeys: [],
			missingMenus: []
		}
	},
	computed: {
		menuOptions() {
			const menuOptions = []
			this.missingMenus.forEach((missingMenu) => {
				missingMenu.menus.forEach((menu) => {
					menuOptions.push({
						key: `${missingMenu.business.id}-${menu.id}`,
						businessId: missingMenu.business.id,
						businessName: missingMenu.business.name,
						menuId: menu.id,
						menuName: menu.name,
						restaurantName: missingMenu.restaurant.name
					})
				})
			})
			return menuOptions
		},
		allSelected() {
			return (
				!this.loading &&
				this.menuOptions.length > 0 &&
				this.selectedKeys.length == this.menuOptions.length
			)
		},
		businesses() {
			if (this.$store.state.hubsModule.currentHubBusinesses) {
				return this.$store.state.hubsModule.currentHubBusinesses
			}
			return []
		}
	},
	methods: {
		async handleOptions(options) {
			try {
				this.loading = true
				this.missingMenus = options.missingMenus
				this.selectedKeys = []
				await this.$store.dispatch(
					`hubsModule/${ActionTypes.GET_HUB_BUSINESSES}`
				)
				this.loading = false
			} catch (e) {
				this.loading = false
				this.close()
				throw e
			}
		},
		selectAll() {
			if (!this.allSelected) {
				this.selectedKeys = this.menuOptions.map((option) => option.key)
			} else {
				this.selectedKeys = []
			}
		},
		async attachMenus() {
			try {
				if (this.loading) {
					return
				}

				if (this.selectedKeys && this.selectedKeys.length > 0) {
					this.loading = true
					const hubBusinessMenus = {}

					this.selectedKeys.forEach((key) => {
						const pieces = key.split("-")
						const businessId = pieces[0]
						const menuId = pieces[1]
						if (!hubBusinessMenus[businessId]) {
							hubBusinessMenus[businessId] = {
								businessId: businessId,
								menuIds: []
							}
						}
						const businessMenu = hubBusinessMenus[businessId]
						if (!businessMenu.menuIds.includes(menuId)) {
							businessMenu.menuIds.push(menuId)
						}
					})
					const currentHubMenuIds =
						this.$store.state.hubsModule.currentHub
							.restaurant_menu_ids

					this.businesses.forEach((business) => {
						const hubBusinessMenu = hubBusinessMenus[business.id]
						if (hubBusinessMenu && business.menus) {
							business.menus.forEach((menu) => {
								if (currentHubMenuIds.includes(menu.id)) {
									hubBusinessMenu.menuIds.push(menu.id)
								}
							})
						}
					})

					const menuData = Object.values(hubBusinessMenus)
					let promises = menuData.map((menu) => {
						return this.$store.dispatch(
							`hubsModule/${ActionTypes.UPDATE_HUB_BUSINESS_MENUS}`,
							{
								id: menu.businessId,
								menuIds: menu.menuIds
							}
						)
					})

					const responses = await Promise.all(promises)
					const eventTitle = "Hubs"
					if (responses.every((response) => response == true)) {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: eventTitle,
							message: "Successfully attached menu(s)"
						})
						this.close()
					} else if (responses.some((response) => response == true)) {
						responses.forEach((response, i) => {
							const data = menuData[i]
							const business =
								this.$store.getters.businessesMap[data.id]
							const businessName =
								business && business.name
									? business.name
									: "N/A"
							if (response == true) {
								eventBus.emit(
									EventBusEvents.SHOW_NOTIFICATION,
									{
										title: eventTitle,
										message: `Successfully attached menu(s) to ${businessName}`
									}
								)
							} else {
								eventBus.emit(
									EventBusEvents.SHOW_NOTIFICATION,
									{
										title: eventTitle,
										message: `Failed to attach menu(s) to ${businessName}`,
										type: NotificationType.ERROR
									}
								)
							}
						})
						this.close()
					} else {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: eventTitle,
							message: `Failed to attach menu(s)`,
							type: NotificationType.ERROR
						})
					}
				} else {
					this.close()
				}
				this.loading = false
			} catch (e) {
				this.loading = false
				throw e
			}
		},
		optionSelected(option) {
			if (!this.selectedKeys.includes(option.key)) {
				this.selectedKeys.push(option.key)
			} else {
				this.selectedKeys = this.selectedKeys.filter(
					(key) => option.key != key
				)
			}
		}
	}
}
</script>