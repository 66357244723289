<template>
	<div class="sk-page-content">
		<PageHeader
			:title="'Manage Businesses'"
			:actions="actions"
			:filters="filters"
			@filterSet="filterSet"
			@filtersReset="clearFilters"
			@actionClicked="headerActionClicked"
		/>
		<div class="sk-table sk-table-default">
			<div class="sk-table-header">
				<div class="sk-table-row">
					<div>Status</div>
					<div>Business</div>
					<div>Address</div>
					<div>Paired With</div>
					<div>Devices</div>
					<div>Actions</div>
				</div>
			</div>
			<transition-group
				name="list"
				tag="div"
				class="sk-table-body"
				appear
			>
				<BusinessLineItem
					v-for="business in pagedBusinesses"
					@delete="deleteBusiness"
					:business="business"
					:key="business.id"
				/>
			</transition-group>
		</div>
		<PaginationFooter
			:contentText="'Businesses'"
			:pageSize="20"
			:total="businesses.length"
			ref="paginationFooter"
			@pageChanged="filterPage"
		/>
		<BusinessSetupModal ref="businessSetupModal" />
		<ConfirmationModal ref="confirmationModal" />
	</div>
</template>

<script>
import { PermissionMethod } from "@/utils/permissions"
import { BusinessType } from "@arikgaisler/utils/enums/businessType"
import { ConfirmationModalType } from "@/enums/confirmationModalType"
import tracker from "@/utils/tracker"
import businessTypeFormatterMixin from "@/mixins/business/business-type-formatter-mixin"
import PageHeader from "@/components/PageHeader.vue"
import BusinessLineItem from "@/components/business/BusinessLineItem.vue"
import PaginationFooter from "@/components/PaginationFooter.vue"
import loaderMixin from "@/mixins/loader-mixin"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue"
import BusinessSetupModal from "@/components/modals/business/BusinessSetupModal.vue"

export default {
	name: "Businesses",
	mixins: [loaderMixin, businessTypeFormatterMixin],
	components: {
		BusinessLineItem,
		PageHeader,
		PaginationFooter,
		BusinessSetupModal,
		ConfirmationModal
	},
	data() {
		return {
			business_id: 0,
			restaurant_id: 0,
			stock_status: -1,
			city: null,
			state: null,
			businessType: -1,
			pageRange: {
				page: 1,
				start: 0,
				end: 20
			},
			actions: []
		}
	},
	mounted: function () {
		this.$store.dispatch("getBusinesses")
		this.$store.dispatch("businessesModule/clearBusiness")
		if (
			this.$store.state.permissions.includes(
				PermissionMethod.NEW_BUSINESS
			)
		) {
			this.actions.push({
				id: 0,
				text: `<i class="sk-icon-plus-regular"></i> New Business`,
				classes: "button button-primary"
			})
		}
	},
	computed: {
		filters() {
			return [
				{
					id: 0,
					placeholder: "Status",
					type: "dropdown",
					items: [
						{
							text: `Status`,
							id: null
						},
						{
							text: `<span class="status-dot dark-grey"></span> All`,
							id: -1
						},
						{
							text: `<span class="status-dot red"></span> Out of Stock`,
							id: 0
						},
						{
							text: `<span class="status-dot green"></span> In Stock`,
							id: 1
						}
					]
				},
				{
					id: 1,
					placeholder: "Business Name",
					type: "search",
					width: "250px",
					fetch: (query) => {
						return this.$store.dispatch(
							"businessesModule/autocompleteBusinesses",
							query
						)
					},
					serializer: (i) => {
						return { text: i.name }
					}
				},
				{
					id: 2,
					placeholder: "Paired Restaurant",
					type: "search",
					fetch: (query) => {
						return this.$store.dispatch(
							"restaurantsModule/autocompleteRestaurants",
							query
						)
					},
					width: "250px",
					serializer: (i) => {
						return { text: i.name }
					}
				},
				{
					id: 5,
					placeholder: "Type",
					type: "dropdown",
					items: [
						{
							text: "Type",
							id: null
						},
						{
							id: BusinessType.BAR,
							text: this.$options.filters.businessTypeName(
								BusinessType.BAR
							)
						},
						{
							id: BusinessType.HOTEL,
							text: this.$options.filters.businessTypeName(
								BusinessType.HOTEL
							)
						},
						{
							id: BusinessType.RESIDENTIAL,
							text: this.$options.filters.businessTypeName(
								BusinessType.RESIDENTIAL
							)
						},
						{
							id: BusinessType.COWORKING,
							text: this.$options.filters.businessTypeName(
								BusinessType.COWORKING
							)
						},
						{
							id: BusinessType.AIRPORT,
							text: this.$options.filters.businessTypeName(
								BusinessType.AIRPORT
							)
						}
					]
				},
				{
					id: 3,
					placeholder: "City",
					type: "search",
					items: this.cityArr,
					width: "200px",
					serializer: (i) => {
						return {
							text: i
						}
					}
				},
				{
					id: 4,
					placeholder: "State",
					type: "dropdown",
					items: this.stateArr
				}
			]
		},
		loading() {
			return this.$store.state.loading
		},
		businesses() {
			return Object.values(this.$store.state.businesses)
				.sort()
				.filter((business) => {
					let hasRequiredType = true
					if (this.businessType > -1) {
						hasRequiredType = this.businessType == business.type
					}
					let hasRequiredCity = this.city
						? business.city == this.city
						: true
					let hasRequiredState = this.state
						? business.state == this.state
						: true
					let hasRequiredStatus =
						this.stock_status != -1
							? business.stock_status == this.stock_status
							: true
					let hasRequiredBusiness = this.business_id
						? business.id == this.business_id
						: true
					let hasRequiredRestaurant = true
					if (this.restaurant_id) {
						if (business.menus && business.menus.length > 0) {
							const businessRestaurantMenu = business.menus.find(
								(menu) =>
									menu.restaurant_id == this.restaurant_id
							)
							hasRequiredRestaurant =
								businessRestaurantMenu != undefined
						} else {
							hasRequiredRestaurant = false
						}
					}
					return (
						hasRequiredCity &&
						hasRequiredState &&
						hasRequiredStatus &&
						hasRequiredType &&
						hasRequiredBusiness &&
						hasRequiredRestaurant
					)
				})
		},
		pagedBusinesses() {
			return this.businesses.slice(
				this.pageRange.start,
				this.pageRange.end
			)
		},
		cityArr() {
			const cities = []
			Object.values(this.$store.state.businesses)
				.filter(
					(business) =>
						business.city != undefined && business.city.length != 0
				)
				.forEach((business) => {
					if (!cities.includes(business.city)) {
						cities.push(business.city)
					}
				})
			return cities
		},
		stateArr() {
			const statesFound = []
			Object.values(this.$store.state.businesses)
				.filter(
					(business) =>
						business.state != undefined &&
						business.state.length != 0
				)
				.forEach((business) => {
					if (!statesFound.includes(business.state)) {
						statesFound.push(business.state)
					}
				})

			let options = statesFound.sort().map((state, index) => {
				if (!state.text) {
					return {
						text: state,
						id: index
					}
				}
			})

			options.unshift({
				text: `State`,
				id: null
			})

			return options
		}
	},
	methods: {
		clearFilters() {
			this.stock_status = -1
			this.business_id = 0
			this.restaurant_id = 0
			this.city = null
			this.state = null
			this.businessType = -1
		},
		filterSet(filter) {
			this.$refs.paginationFooter.pageChanged(1)
			switch (filter.id) {
				case 0:
					this.stock_status = filter.value.id
					break
				case 1:
					this.business_id = filter.value ? filter.value.id : 0
					break
				case 2:
					this.restaurant_id = filter.value ? filter.value.id : 0
					break
				case 3:
					this.city = filter.value ? filter.value : null
					break
				case 4:
					this.state = filter.value ? filter.value.text : null
					break
				case 5:
					this.businessType = filter.value.id
					break
			}
		},
		headerActionClicked(action) {
			switch (action.id) {
				case 0: {
					tracker.trackEvent("New Business Clicked")
					this.$refs.businessSetupModal.open()
					break
				}
			}
		},
		filterPage(range) {
			this.pageRange = range
		},
		async deleteBusiness(business) {
			const confirmed = await this.$refs.confirmationModal.open({
				title: `Delete ${business.name}?`,
				body: "Are you sure you want to delete this business? Deleting will permanently remove it and you will not be able to recover it.",
				confirmation: "Delete Business",
				confirmationIcon: "sk-icon-trash-alt-solid",
				cancel: "Keep Business",
				type: ConfirmationModalType.RED
			})
			if (confirmed) {
				this.$store.dispatch(
					"businessesModule/deleteBusiness",
					business.id
				)
			}
		}
	},
	watch: {
		loading(newVal) {
			this.toggleLoader(newVal)
		}
	}
}
</script>