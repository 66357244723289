<template>
	<div class="sk-table-row">
		<div class="sk-table-column-fit">
			<b class="column-title-alt">{{ voucherRequest.name }}</b>
			<div :title="businessName" class="text-truncate">{{ businessName }}</div>
		</div>
		<div :title="amount">{{ amount }}</div>
		<div>
			<b class="column-title-alt">{{voucherMenu}}</b>
			<div>{{ voucherUsage }}</div>
		</div>
		<div>
			<b class="column-title-alt">{{distribution}}</b>
			<div>{{ payment }}</div>
		</div>
		<div
			class="guest-distributions"
			@click="isGuestsDistributionsOpen = !isGuestsDistributionsOpen"
		>
			<div class="num-of-distributions" v-if="numOfGuestsDistributions">
				{{ numOfGuestsDistributions }}
				<i
					v-if="numOfGuestsDistributions > 0"
					class="expand-guest-distribution-icon sk-icon-chevron-down-regular"
					:class="{ flipped: isGuestsDistributionsOpen }"
				></i>
			</div>
			<SkPopover v-model="isGuestsDistributionsOpen">
				<div
					v-for="(guestKey, i) in guestKeys"
					:key="i"
					class="guest-distribution"
				>
					<div>
						{{ guestKey }}
					</div>
					<div class="sk-muted-text">
						{{ guestDistributionsHash[guestKey] }} vouchers sent
					</div>
				</div>
			</SkPopover>
		</div>
		<div title="Issuer">{{ issuedBy }}</div>
		<div class="sk-table-column-fit">
			<span :class="statusClasses"></span>
			{{ statusLabel }}
		</div>
		<div class="sk-table-column-fit">
			<div class="sk-table-actions">
				<button
					class="button button-icon"
					@click="actionSelected({ id: 1 })"
					title="View"
				>
					<i class="sk-icon-eye-solid"></i>
				</button>
				<SkDropdownSelect
					:options="allowedActions"
					:iconClasses="'sk-icon-ellipsis-v-regular'"
					:showSelectedOption="false"
					:position="'left'"
					@selected="actionSelected"
				/>
			</div>
		</div>
	</div>
</template>

<style>
.pill i {
	margin-right: 10px;
}

.sk-table-body .sk-table-row > .guest-distributions {
	white-space: normal;
}

.guest-distributions {
	padding: 20px 0;
}

.num-of-distributions {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.guest-distribution > div {
	color: var(--sk-grey3);
}

.guest-distribution div:first-child {
	margin-bottom: 30px;
}

.guest-distribution:not(:first-child) div:first-child {
	margin-top: 30px;
}

.expand-guest-distribution-icon {
	margin-left: 20px;
	color: var(--sk-grey2);
	font-size: 10px;
}
</style>

<style scoped>
.text-truncate {
	max-width: 200px;
}
</style>

<script>
import { CouponType } from "@arikgaisler/utils/enums/couponType"
import { VoucherPaymentType } from "@/enums/voucherPaymentType"
import { VoucherDistributionType } from "@/enums/voucherDistributionType"
import { VoucherRequestStatus } from "@/enums/voucherRequestStatus"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"
import SkPopover from "@/components/SkPopover.vue"
import { PermissionMethod } from "@/utils/permissions"

export default {
	name: "VoucherRequestLineItem",
	components: {
		SkDropdownSelect,
		SkPopover
	},
	props: {
		voucherRequest: Object
	},
	data: function () {
		return {
			isGuestsDistributionsOpen: false
		}
	},
	computed: {
		businessName() {
			if (this.voucherRequest.business) {
				return this.voucherRequest.business.name
			} else if (this.business) {
				return this.business.name
			}
			return ""
		},
		business() {
			let business_id = this.voucherRequest.business
				? this.voucherRequest.business.id
				: this.voucherRequest.business_id
			if (business_id > 0) {
				return this.$store.getters.businessesMap[business_id]
			}
			return null
		},
		amount() {
			return "$" + this.voucherRequest.amount
		},
		numOfGuestsDistributions() {
			if (
				this.voucherRequest.guest_distributions &&
				this.voucherRequest.guest_distributions.length > 0
			) {
				return this.guestKeys.length
			} else {
				if (
					this.voucherRequest.user_email &&
					this.voucherRequest.user_email.length > 0
				) {
					return 1
				} else {
					return "N/A"
				}
			}
		},
		guestKeys(){
			if (this.guestDistributionsHash){
				return Object.keys(this.guestDistributionsHash)
			}
			else {
				return null
			}
		},
		guestDistributionsHash() {
			if (
				this.voucherRequest.guest_distributions &&
				this.voucherRequest.guest_distributions.length > 0
			) {
				return this.voucherRequest.guest_distributions.reduce(
					(allGuestsHash, currentGuest) => {
						if (!allGuestsHash[currentGuest.email]) {
							allGuestsHash[currentGuest.email] =
								currentGuest.quantity
						} 
						return allGuestsHash
					},
					{}
				)
			} else {
				let retVal = {}

				retVal[this.voucherRequest.user_email] =
					this.voucherRequest.quantity
				return retVal
			}
		},
		distribution() {
			switch (this.voucherRequest.distribution_type) {
				case VoucherDistributionType.PRINT_NOW:
					return "Printed"
				case VoucherDistributionType.SEND_TO_GUEST:
					return "Sent to Guest"
				case VoucherDistributionType.SEND_TO_ME:
					return "Send to Me"
				case VoucherDistributionType.DIGITAL_ONLY:
					return "N/A"
			}
			return "N/A"
		},
		payment() {
			switch (this.voucherRequest.payment_type) {
				case VoucherPaymentType.PRE_PAID:
					return "Pre Paid"
				case VoucherPaymentType.PAY_AS_YOU_GO:
					return "Pay as you Go"
			}
			return "N/A"
		},
		couponType() {
			if (
				this.coupon.coupon_type == CouponType.CREDIT ||
				this.coupon.ref_order_id != ""
			) {
				return "Credit"
			} else if (this.coupon.coupon_type == CouponType.DEFAULT) {
				return "Promotion"
			} else if (this.coupon.coupon_type == CouponType.VOUCHER) {
				return "Voucher"
			}
		},
		allowedActions() {
			let actions = []
			actions.push({
				text: `<i class="sk-icon-pencil-solid"></i> Edit`,
				id: 2
			})
			if (
				this.allowApprove &&
				this.voucherRequest.status == VoucherRequestStatus.REQUESTED
			) {
				actions.push({
					text: `<i class="sk-icon-check-regular"></i> Approve`,
					id: 4
				})
			}
			if (
				this.voucherRequest.payment_type != VoucherPaymentType.PRE_PAID
			) {
				actions.push({
					text: `<i class="sk-icon-times-regular"></i> Delete`,
					id: 3
				})
			}
			return actions
		},
		statusLabel() {
			if (this.voucherRequest.status == VoucherRequestStatus.REQUESTED) {
				return "Requested"
			} else if (
				this.voucherRequest.vouchers_used >=
				this.voucherRequest.quantity
			) {
				return "Used"
			} else {
				return "Approved"
			}
		},
		statusClasses() {
			if (this.voucherRequest.status == VoucherRequestStatus.REQUESTED) {
				return "status-dot yellow"
			} else if (
				this.voucherRequest.vouchers_used >=
				this.voucherRequest.quantity
			) {
				return "status-dot red"
			} else {
				return "status-dot green"
			}
		},
		voucherUsage() {
			return (
				this.voucherRequest.vouchers_used +
				"/" +
				this.voucherRequest.quantity
			)
		},
		allowApprove() {
			return this.$store.state.permissions.includes(
				PermissionMethod.APPROVE_VOUCHER_REQUEST
			)
		},
		issuedBy() {
			let id = -1
			if (this.voucherRequest.issued_by && this.voucherRequest.issued_by.id) {
				id = this.voucherRequest.issued_by.id
			} else if (this.voucherRequest.issued_by) {
				id = this.voucherRequest.issued_by
			}
			const adminUser = this.$store.getters.adminUsersMap[id]
			return adminUser ? adminUser.display_name : "N/A"
		},
		voucherMenu() {
			if (this.voucherRequest && this.voucherRequest.menu) {
				return this.voucherRequest.menu.name
			}
			return "All"
		}
	},
	methods: {
		actionSelected(action) {
			switch (action.id) {
				case 1:
					this.$emit("viewVoucherRequest", this.voucherRequest)
					break
				case 2:
					this.$emit("updateVoucherRequest", this.voucherRequest)
					break
				case 3:
					this.$emit("deleteVoucherRequest", this.voucherRequest)
					break
				case 4:
					this.$emit(
						"updateVoucherRequestStatus",
						this.voucherRequest,
						VoucherRequestStatus.APPROVED
					)
					break
			}
		}
	}
}
</script>