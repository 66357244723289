<template>
	<div>
		<div class="sk-column">
			<div class="sk-insight-section-title">Totals</div>
			<div class="sk-insight-section-description">
				Understand how performance has been overall
			</div>
		</div>
		<div class="sk-grid">
			<div class="sk-column">
				<TotalSalesStatistic :footerLink="ordersFooterLink" :exportCogs="true"/>
				<TotalOrdersStatistic :footerLink="ordersFooterLink" />
			</div>
			<TotalTabbedChart class="total-tabbed-chart" />
		</div>
		<div class="sk-column">
			<div class="sk-insight-section-title">Breakdowns</div>
			<div class="sk-insight-section-description">
				Dive into sales to assess the demand of your service
			</div>
		</div>
		<div class="sk-grid breakdown-statistics">
			<AverageTicketSalesChart :title="'Average Ticket Sales'" />
			<TopOrdersByDowChart :footerLink="ordersFooterLink" />
			<TopOrdersHourlyChart />
		</div>
	</div>
</template>

<style scoped>
.sk-grid {
	grid-template-columns: 1fr 1fr 1fr;
}

.breakdown-statistics {
	height: 310px;
}

.breakdown-statistics > * {
	flex: 1;
	display: flex;
}

.total-tabbed-chart {
	grid-column: 2 / span 2;
}
</style>

<script>
import ActionTypes from "@/store/modules/insights/action-types"
import TotalSalesStatistic from "@/components/insights/TotalSalesStatistic.vue"
import TotalOrdersStatistic from "@/components/insights/TotalOrdersStatistic.vue"
import TotalTabbedChart from "@/components/insights/TotalTabbedChart.vue"
import AverageTicketSalesChart from "@/components/insights/AverageTicketSalesChart.vue"
import TopOrdersByDowChart from "@/components/insights/TopOrdersByDowChart.vue"
import TopOrdersHourlyChart from "@/components/insights/TopOrdersHourlyChart.vue"

export default {
	name: "BusinessSalesAndOrders",
	components: {
		TotalSalesStatistic,
		TotalOrdersStatistic,
		TotalTabbedChart,
		AverageTicketSalesChart,
		TopOrdersByDowChart,
		TopOrdersHourlyChart
	},
	props: {
		filterVals: Object
	},
	data: function () {
		return {
			ordersFooterLink:
				"https://partnerhelp.2ndkitchen.com/en/article/how-can-i-improve-my-restaurants-order-volume-i171kv"
		}
	},
	watch: {
		filterVals: {
			immediate: true,
			deep: true,
			handler(filterVals) {
				if (!filterVals.startDate || !filterVals.endDate) {
					return
				}
				this.$store.dispatch(
					`insightsModule/${ActionTypes.GET_TOTAL_ORDERS_DATA}`
				)
				this.$store.dispatch(
					`insightsModule/${ActionTypes.GET_TOTAL_SALES_DATA}`, {
						showCogs: true
					}
				)
				this.$store.dispatch(
					`insightsModule/${ActionTypes.GET_AVERAGE_TICKET_PRICE_BY_DOW}`
				)
				this.$store.dispatch(
					`insightsModule/${ActionTypes.GET_TOP_ORDERS_BY_DOW}`
				)
				this.$store.dispatch(
					`insightsModule/${ActionTypes.GET_TOP_ORDERS_BY_HOUR}`
				)
			}
		}
	}
}
</script>