import MutationTypes from "./mutation-types"

export default {
	[MutationTypes.LOADING](state) {
		state.loading = true
	},
	[MutationTypes.LOADED](state) {
		state.loading = false
	},
	[MutationTypes.GOT_BUSINESS](state, business) {
		state.business = business
	},
	[MutationTypes.GOT_AVERAGE_RATING](state, avgRating) {
		state.businessAvgRating = avgRating ? avgRating.toFixed(2).replace(/[.,]00$/, "") : avgRating
	},
	[MutationTypes.GOT_TODAYS_MENUS](state, menus) {
		state.todaysBusinessMenus = menus
	},
	[MutationTypes.GOT_TODAYS_EVENTS](state, events) {
		state.todaysEvents = events.reduce((eventHash, event) => {
			eventHash[event.id] = event
			return eventHash
		}, {})
	},
	[MutationTypes.GOT_RECENT_ORDERS](state, orders) {
		state.recentOrders = orders ? orders : []
	}
}