import Vue from "vue"
import Vuex from "vuex"
import VuexPersistence from "vuex-persist"
import actions from "./actions"
import mutations from "./mutations"
import getters from "./getters"
import coupons from "@/store/modules/coupons/store.js"
import restaurants from "@/store/modules/restaurants/store.js"
import restaurantsModuleReducer from "@/store/modules/restaurants/reducer"
import ordersModuleReducer from "@/store/modules/orders/reducer"
import businesses from "@/store/modules/businesses/store.js"
import orders from "@/store/modules/orders/store.js"
import events from "@/store/modules/events/store"
import businessDashboard from "@/store/modules/businessDashboard/store"
import restaurantDashboard from "@/store/modules/restaurantDashboard/store"
import finance from "@/store/modules/finance/store"
import prepackages from "@/store/modules/prepackages/store"
import users from "@/store/modules/users/store"
import activityFeed from "@/store/modules/activityFeed/store"
import insights from "@/store/modules/insights/store"
import hubs from "@/store/modules/hubs/store"
import sales from "@/store/modules/sales/store"
import { UserRole } from "@/enums/userRole"
import localforage from "localforage"

let migrateStateIfNeeded = null
const supportsLocalforage = localforage.supports(localforage.INDEXEDDB) || localforage.supports(localforage.WEBSQL)
const oldStorage = localStorage.getItem("vuex")
if (supportsLocalforage && oldStorage) {
    migrateStateIfNeeded = async () => {
        let parsedOldStorage = {}
        try {
            parsedOldStorage = JSON.parse(oldStorage)
            await localforage.setItem("vuex", parsedOldStorage)
            localStorage.removeItem("vuex")
            return parsedOldStorage
        } catch(e) {
            localStorage.removeItem("vuex")
            return parsedOldStorage
        }
    }
}

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
    storage: localforage,
    asyncStorage: true,
    restoreState: migrateStateIfNeeded,
    reducer: (state) => {
        let adminUsers = state.admin_users ? state.admin_users : []
        if (adminUsers.length > 200) {
            adminUsers = adminUsers.filter((user) => user.role_id == UserRole.SUPER_ADMIN)
        }

        return {
            user_id: state.user_id,
            user_token: state.user_token,
            user_refresh_token: state.user_refresh_token,
            user_name: state.user_name,
            user_role: state.user_role,
            user_sub_role: state.user_sub_role,
            user_entities: state.user_entities,
            user: state.user,
            permissions: state.permissions,
            refund_reasons: state.refund_reasons,
            deliveryReasons: state.deliveryReasons,
            restaurants: state.restaurants,
            businesses: state.businesses,
            restaurantsModule: restaurantsModuleReducer(state.restaurantsModule),
            ordersModule: ordersModuleReducer(state.ordersModule),
            admin_users: adminUsers
        }
    }
})

export const store = new Vuex.Store({
    state: {
        initializing: false,
        business: null,
        currentOrders: [],
        restaurants: {},
        businesses: {},
        business_employees: [],
        coupons: [],
        loading: false,
        login: false,
        order_exceptions: [],
        refunds: [],
        refund_reasons: [],
        deliveryReasons: [],
        user_id: 0,
        user_token: "",
        user_refresh_token: "",
        user_name: "",
        user_role: 0,
        user_sub_role: 0,
        user_entities: [],
        user: {},
        permissions: [],
        totalTips: 0,
        totalPayouts: 0,
        nextPayout: null,
        employee_tips: [],
        employee_paid_tips: [],
        admin_users: [],
        menuTimeRanges: {
            breakfast: [700, 800, 900, 1000],
            lunch: [1100, 1200, 1300, 1400, 1500],
            dinner: [1600, 1700, 1800, 1900, 2000],
            latenight: [
                2100, 2200, 2300, 2400, 0, 100, 200, 300, 400, 500, 600
            ]
        }
    },
    modules:{
        couponsModule: coupons,
        restaurantsModule: restaurants,
        businessesModule: businesses,
        ordersModule: orders,
        eventsModule: events,
        businessDashboardModule: businessDashboard,
        restaurantDashboardModule: restaurantDashboard,
        financeModule: finance,
        prepackagesModule: prepackages,
        usersModule: users,
        activityFeedModule: activityFeed,
        insightsModule: insights,
        hubsModule: hubs,
        salesModule: sales
    },
    plugins: [vuexLocal.plugin],
    getters,
    mutations,
    actions
})