import skModalMixin from "@/mixins/modals/sk-modal-mixin"

let skPromiseModalMixin = {
  mixins: [skModalMixin],
  data: function()  {
    return {
      confirmed: null
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    handleOptions(options) {},
    opened() {},
    open(options) {
      this.handleOptions(options ? options : {})
      this.isOpen = true
      this.opened()
      return new Promise((resolve) => {
        this.confirmed = resolve
      })
    },
    confirm(response) {
      this.isOpen = false
      this.consumePromise(response)
    },
    cancel(response) {
      this.isOpen = false
      this.consumePromise(response)
    },
    consumePromise(didConfirm) {
      if (this.confirmed) {
        this.confirmed(didConfirm)
        this.confirmed = null
      }
    }
  }
}

export default skPromiseModalMixin