<template>
	<div class="menu-items">
		<div class="menu-items-sidebar dark-scrollbar">
			<h2
				v-if="isMobile"
				class="all-filters filter-title"
				@click="filtersExpanded = !filtersExpanded"
			>
				<span>Filters</span>
				<i
					class="sk-icon-chevron-down-regular"
					:class="{ flipped: categoriesExpanded }"
				></i>
			</h2>
			<transition-expand>
				<div v-if="filtersExpanded || !isMobile">
					<div
						class="product-search-input"
						:class="{ mobile: isMobile }"
					>
						<SkInput
							v-model="productNameSearch"
							:name="'Product Name'"
						/>
						<i class="sk-icon-search-regular"></i>
					</div>
					<h2
						class="filter-title"
						@click="categoriesExpanded = !categoriesExpanded"
					>
						<span>Categories</span>
						<i
							class="sk-icon-chevron-down-regular"
							:class="{ flipped: categoriesExpanded }"
						></i>
					</h2>
					<transition-expand>
						<div class="filter-list" v-if="categoriesExpanded">
							<div @click="filterByCategory(null)">
								<SkCheckbox
									:checked="filteredCategoryIds.length == 0"
								/>
								<span>All Categories</span>
							</div>
							<div
								v-for="category in restaurantCategories"
								:key="category.id"
								@click="filterByCategory(category.id)"
							>
								<SkCheckbox
									:checked="
										filteredCategoryIds.includes(
											category.id
										)
									"
								/>
								<span>{{ category.name }}</span>
							</div>
							<button
								class="button button-icon"
								v-if="canAddCategory"
								@click="addNewCategory"
							>
								<i class="sk-icon-plus-regular"></i>
								Add New Category
							</button>
						</div>
					</transition-expand>
					<h2
						class="filter-title"
						@click="statusExpanded = !statusExpanded"
					>
						<span>Status</span>
						<i
							class="sk-icon-chevron-down-regular"
							:class="{ flipped: statusExpanded }"
						></i>
					</h2>
					<transition-expand>
						<div class="filter-list" v-if="statusExpanded">
							<div class="sk-radio-group">
								<SkRadio
									:option="-1"
									v-model="filteredStatus"
								/>
								<span>All Products</span>
							</div>
							<div class="sk-radio-group">
								<SkRadio :option="1" v-model="filteredStatus" />
								<span>Active</span>
							</div>
							<div class="sk-radio-group">
								<SkRadio :option="2" v-model="filteredStatus" />
								<span>Archived</span>
							</div>
						</div>
					</transition-expand>
					<h2
						class="filter-title"
						@click="menusExpanded = !menusExpanded"
					>
						<span>Menus</span>
						<i
							class="sk-icon-chevron-down-regular"
							:class="{ flipped: menusExpanded }"
						></i>
					</h2>
					<transition-expand>
						<div class="filter-list" v-if="menusExpanded">
							<div @click="filterByMenu(null)">
								<SkCheckbox
									:checked="filteredMenuIds.length == 0"
								/>
								<span>All Menus</span>
							</div>
							<div
								v-for="menu in menus"
								:key="menu.id"
								@click="filterByMenu(menu.id)"
							>
								<SkCheckbox
									:checked="filteredMenuIds.includes(menu.id)"
								/>
								<span>{{ menu.name }}</span>
							</div>
						</div>
					</transition-expand>
					<h2
						class="filter-title"
						@click="labelsExpanded = !labelsExpanded"
					>
						<span>Labels</span>
						<i
							class="sk-icon-chevron-down-regular"
							:class="{ flipped: labelsExpanded }"
						></i>
					</h2>
					<transition-expand>
						<div class="filter-list" v-if="labelsExpanded">
							<div @click="filterByLabel(null)">
								<SkCheckbox
									:checked="filteredLabelIds.length == 0"
								/>
								<span>All Labels</span>
							</div>
							<div
								v-for="label in labels"
								:key="label.id"
								@click="filterByLabel(label.id)"
							>
								<SkCheckbox
									:checked="
										filteredLabelIds.includes(label.id)
									"
								/>
								<span>{{ label.name }}</span>
							</div>
						</div>
					</transition-expand>
					<h2
						class="filter-title"
						@click="originsExpanded = !originsExpanded"
					>
						<span>Origin</span>
						<i
							class="sk-icon-chevron-down-regular"
							:class="{ flipped: originsExpanded }"
						></i>
					</h2>
					<transition-expand>
						<div class="filter-list" v-if="originsExpanded">
							<div @click="filterByOrigin(null)">
								<SkCheckbox
									:checked="filteredOriginIds.length == 0"
								/>
								<span>All Origins</span>
							</div>
							<div @click="filterByOrigin(-1)">
								<SkCheckbox
									:checked="filteredOriginIds.includes(-1)"
								/>
								<span>2ndKitchen</span>
							</div>
							<div
								v-for="origin in origins"
								:key="origin.id"
								@click="filterByOrigin(origin.id)"
							>
								<SkCheckbox
									:checked="
										filteredOriginIds.includes(origin.id)
									"
								/>
								<span>{{ origin.name }}</span>
							</div>
						</div>
					</transition-expand>
				</div>
			</transition-expand>
		</div>
		<div class="menu-products dark-scrollbar" v-if="showProducts">
			<div
				v-for="(categoryData, categoryId) in categorizedProducts"
				:key="`category-${categoryId}`"
				:ref="`category-${categoryId}`"
			>
				<div class="sk-row category-title">
					{{ categoryData.category }}
				</div>
				<div class="sk-grid">
					<RestaurantProduct
						v-for="product in categoryData.products"
						:key="product.id"
						:product="product"
						@edit="editProduct"
						@delete="deleteProduct"
						@archive="archiveProduct"
						@unarchive="unarchiveProduct"
						@duplicate="duplicateProduct"
						@preview="previewProduct"
						:batchSelectId="product.id"
						:batchSelectedIds="batchSelectedIds"
						@batchSelected="batchSelectEntity"
					/>
				</div>
			</div>
		</div>
		<EmptyPlaceholderWidget
			v-else-if="!loader"
			:title="`No Items`"
			:description="`Your restaurant doesn’t have any items yet. Add an item to start serving food to your partners today!`"
			:buttonText="'New Item'"
			@actionClicked="newItem"
		/>
		<ConfirmationModal ref="confirmationModal" />
		<NewCategoryModal ref="newCategoryModal" />
		<EditProductModal
			ref="editProductModal"
			@confirm="confirmProductChanges"
			@save="saveProduct"
		/>
		<SyncBusinessesProductsModal ref="syncBusinessesProductsModal" />
		<SyncBusinessMenusProductsModal ref="syncBusinessMenusProductsModal" />
		<PreviewProductModal
			ref="previewProductModal"
			@edit="editProduct"
			@delete="deleteProduct"
			@duplicate="duplicateProduct"
			@preview="previewProduct"
		/>
	</div>
</template>

<style scoped>
.button-icon {
	padding: 25px 0 0 0;
}

.button-icon,
.button-icon i {
	font-size: 12px;
	color: var(--sk-grey3);
}

.button-icon i {
	margin-right: 10px;
}

.filter-title {
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.filter-title:not(:last-child) {
	margin-bottom: 30px;
}

.filter-title span {
	font-size: 14px;
	color: var(--sk-navy);
}

.all-filters span {
	color: var(--sk-grey3);
}

.filter-title i {
	font-size: 10px;
	color: var(--sk-grey2);
	margin-right: 30px;
}

.filter-list div {
	color: var(--sk-grey2);
	display: flex;
	align-items: center;
}

.filter-list > *:last-child {
	margin-bottom: 30px;
}

.filter-list span {
	font-size: 12px;
}

.filter-list div + div {
	margin-top: 20px;
}

.filter-list >>> .sk-radio {
	width: 15px;
	height: 15px;
	margin-right: 10px;
}

.product-search-input {
	margin: 0 15px 27px -10px;
	position: relative;
}

.product-search-input.mobile {
	margin: 15px;
}

.product-search-input >>> .sk-input {
	padding-right: 25px;
}

.product-search-input i {
	position: absolute;
	top: 18px;
	right: 15px;
	font-size: 10px;
	color: var(--sk-grey2);
}

@media (max-width: 700px) {
	.menu-items-sidebar {
		padding: 0 !important;
	}

	.filter-title {
		height: 50px;
		padding-left: 20px;
		margin-bottom: 0 !important;
	}

	.filter-list {
		background: var(--sk-greybg);
		padding-left: 20px;
		padding-top: 20px;
	}

	.all-filters + div {
		box-shadow: 0px 0px 2px rgba(136, 136, 136, 0.25);
	}

	.filter-title + .filter-list {
		box-shadow: 0px 0px 2px rgba(136, 136, 136, 0.25);
	}

	.filter-list > *:last-child {
		margin-bottom: 0;
		padding-bottom: 20px;
	}
}
</style>

<script>
import { ConfirmationModalType } from "@/enums/confirmationModalType"
import { MenuType } from "@arikgaisler/utils/enums/menuType"
import { PermissionMethod } from "@/utils/permissions"
import tracker from "@/utils/tracker"
import TransitionExpand from "@/components/transitions/TransitionExpand.vue"
import RestaurantProduct from "@/components/restaurant/RestaurantProduct.vue"
import mobileResponsivenessMixin from "@/mixins/mobile-responsiveness-mixin"
import loaderMixin from "@/mixins/loader-mixin"
import batchSelectMixin from "@/mixins/batch-select-mixin"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue"
import EmptyPlaceholderWidget from "@/components/EmptyPlaceholderWidget.vue"
import SkCheckbox from "@/components/SkCheckbox.vue"
import SkRadio from "@/components/SkRadio.vue"
import NewCategoryModal from "@/components/modals/NewCategoryModal.vue"
import EditProductModal from "@/components/modals/restaurantProducts/EditProductModal.vue"
import PreviewProductModal from "@/components/modals/PreviewProductModal.vue"
import SyncBusinessesProductsModal from "@/components/modals/SyncBusinessesProductsModal.vue"
import SyncBusinessMenusProductsModal from "@/components/modals/SyncBusinessMenusProductsModal.vue"
import SkInput from "@/components/SkInput.vue"
import utils from "@/utils/utils"

export default {
	name: "RestaurantProducts",
	mixins: [mobileResponsivenessMixin, loaderMixin, batchSelectMixin],
	components: {
		TransitionExpand,
		RestaurantProduct,
		ConfirmationModal,
		EmptyPlaceholderWidget,
		SkCheckbox,
		SkRadio,
		NewCategoryModal,
		EditProductModal,
		PreviewProductModal,
		SyncBusinessesProductsModal,
		SyncBusinessMenusProductsModal,
		SkInput
	},
	data: function () {
		return {
			filtersExpanded: false,
			categoriesExpanded: false,
			statusExpanded: false,
			menusExpanded: false,
			labelsExpanded: false,
			originsExpanded: false,
			mobileThresholdWidth: 700,
			filteredCategoryIds: [],
			filteredStatus: 1,
			filteredMenuIds: [],
			filteredLabelIds: [],
			filteredOriginIds: [],
			productNameSearch: ""
		}
	},
	props: {
		selectedIds: {
			type: Array
		}
	},
	computed: {
		canAddCategory() {
			return this.$store.state.permissions.includes(
				PermissionMethod.NEW_RESTAURANT_ITEM
			)
		},
		showProducts() {
			return this.products && this.products.length > 0
		},
		restaurantCategories() {
			if (this.products.length > 0) {
				let categoryIds = []
				let categories = []
				this.products.forEach((product) => {
					let categoryId =
						product.category_id != null ? product.category_id : -1
					if (product.status == 2) {
						categoryId = -2
					}
					if (!categoryIds.includes(categoryId)) {
						categories.push({
							name: this.categoriesMap[categoryId],
							id: categoryId
						})
						categoryIds.push(categoryId)
					}
				})
				return categories.sort((a, b) => {
					if (a.name < b.name) {
						return -1
					}
					if (a.name > b.name) {
						return 1
					}
					return 0
				})
			}
			return []
		},
		categoriesMap() {
			return this.$store.state.restaurantsModule.categories.reduce(
				(map, obj) => {
					map[obj.id] = obj.name
					return map
				},
				{}
			)
		},
		categorizedProducts() {
			let categoryProductsMap = {}
			this.filteredProducts.forEach((product) => {
				let categoryId =
					product.category_id != null ? product.category_id : -1
				if (product.status == 2) {
					categoryId = -2
				}
				if (categoryProductsMap[categoryId] == undefined) {
					categoryProductsMap[categoryId] = {
						category: this.categoriesMap[categoryId],
						products: []
					}
				}
				let menus = []
				if (product.menu_ids) {
					menus = product.menu_ids.map((menuId) => {
						return {
							id: menuId,
							name: this.menusHash[menuId]
								? this.menusHash[menuId].name
								: "",
							link: `/restaurant/${this.restaurant.id}/menu/${menuId}/`
						}
					})
				}
				categoryProductsMap[categoryId].products.push(
					Object.assign({ menus: menus }, product)
				)
			})
			return categoryProductsMap
		},
		products() {
			return this.$store.state.restaurantsModule.restaurantProducts
				? this.$store.state.restaurantsModule.restaurantProducts
				: []
		},
		filteredProducts() {
			let products = this.$store.state.restaurantsModule
				.restaurantProducts
				? this.$store.state.restaurantsModule.restaurantProducts
				: []

			let searchRegex = null
			if (this.productNameSearch.length > 0) {
				const term = utils.sanitize(this.productNameSearch)
				searchRegex = utils.stringToRegex(term)
			}

			return products.filter((product) => {
				if (this.filteredCategoryIds.length > 0) {
					let categoryId =
						product.category_id != null ? product.category_id : -1
					if (product.status == 2) {
						categoryId = -2
					}
					if (!this.filteredCategoryIds.includes(categoryId)) {
						return false
					}
				}

				if (
					this.filteredMenuIds.length > 0 &&
					!this.filteredMenuIds.some((menuId) =>
						product.menu_ids.includes(menuId)
					)
				) {
					return false
				}

				if (
					this.filteredLabelIds.length > 0 &&
					(product.labels.length == 0 ||
						!product.labels.some((label) =>
							this.filteredLabelIds.includes(label.id)
						))
				) {
					return false
				}
				if (
					this.filteredOriginIds.length > 0 &&
					((!this.filteredOriginIds.includes(-1) &&
						!product.restaurant_integration_id) ||
						(product.restaurant_integration_id &&
							!this.filteredOriginIds.includes(
								product.restaurant_integration_id
							)))
				) {
					return false
				}
				if (
					this.filteredStatus > -1 &&
					product.status != this.filteredStatus
				) {
					return false
				}

				if (searchRegex) {
					let noMatches = true
					if (product.name && product.name.match(searchRegex)) {
						noMatches = false
					} else if (
						product.description &&
						product.description.match(searchRegex)
					) {
						noMatches = false
					} else if (product.metas && product.metas.length > 0) {
						product.metas.some((meta) => {
							if (meta.key.match(searchRegex)) {
								noMatches = false
							} else if (meta.values && meta.values.length > 0) {
								const metaMatch = meta.values.some((value) =>
									value.name.match(searchRegex)
								)
								if (metaMatch) {
									noMatches = false
								}
							}
						})
					}

					if (noMatches) {
						return false
					}
				}

				return true
			})
		},
		restaurant() {
			return this.$store.state.restaurantsModule.currentRestaurant
		},
		menus() {
			return this.restaurant && this.restaurant.menus
				? this.restaurant.menus
				: []
		},
		menusHash() {
			return this.$store.getters["restaurantsModule/currentMenusHash"]
		},
		allLabels() {
			let labels = this.$store.state.restaurantsModule.labels
				? this.$store.state.restaurantsModule.labels
				: []
			return labels.reduce((hash, label) => {
				hash[label.id] = label.name
				return hash
			}, {})
		},
		labels() {
			let labelIds = []
			let labels = []
			this.products.forEach((product) => {
				if (product.labels && product.labels.length > 0) {
					product.labels.forEach((label) => {
						if (!labelIds.includes(label.id)) {
							labels.push({
								name: this.allLabels[label.id],
								id: label.id
							})
							labelIds.push(label.id)
						}
					})
				}
			})
			return labels.sort((a, b) => {
				if (a.name < b.name) {
					return -1
				}
				if (a.name > b.name) {
					return 1
				}
				return 0
			})
		},
		allOrigins() {
			let origins = this.$store.state.restaurantsModule.integrations
			return origins.reduce((hash, origin) => {
				hash[origin.id] = origin.name
				return hash
			}, {})
		},
		origins() {
			let originIds = []
			let origins = []
			this.products.forEach((product) => {
				if (product.restaurant_integration_id) {
					if (
						!originIds.includes(product.restaurant_integration_id) &&
						this.allOrigins[
								product.restaurant_integration_id
							]
					) {
						origins.push({
							name: this.allOrigins[
								product.restaurant_integration_id
							],
							id: product.restaurant_integration_id
						})
						originIds.push(product.restaurant_integration_id)
					}
				}
			})
			return origins.sort((a, b) => {
				if (a.name < b.name) {
					return -1
				}
				if (a.name > b.name) {
					return 1
				}
				return 0
			})
		}
	},
	methods: {
		applyFilter(id, ids) {
			ids.slice()
			if (!id) {
				ids = []
			} else if (ids.includes(id)) {
				ids = ids.filter((menuId) => menuId != id)
			} else {
				ids.push(id)
			}
			return ids
		},
		filterByLabel(id) {
			this.filteredLabelIds = this.applyFilter(id, this.filteredLabelIds)
		},
		filterByMenu(id) {
			this.filteredMenuIds = this.applyFilter(id, this.filteredMenuIds)
		},
		filterByCategory(id) {
			this.filteredCategoryIds = this.applyFilter(
				id,
				this.filteredCategoryIds
			)
		},
		filterByOrigin(id) {
			this.filteredOriginIds = this.applyFilter(
				id,
				this.filteredOriginIds
			)
		},
		addNewCategory() {
			tracker.trackEvent("Add New Category Clicked")
			this.$refs.newCategoryModal.open()
		},
		editProduct(product) {
			this.$refs.editProductModal.open(product)
		},
		previewProduct(product) {
			this.$refs.previewProductModal.open(product)
		},
		newItem() {
			this.$refs.editProductModal.open({})
		},
		async archiveProduct(product) {
			const confirmed = await this.$refs.confirmationModal.open({
				title: `Archive ${product.name}?`,
				body: "Are you sure you want to archive this product? Archiving will remove it from all of your menus. You can reactivate it at anytime.",
				confirmation: "Archive Product",
				confirmationIcon: "sk-icon-archive-solid",
				cancel: "Close",
				type: ConfirmationModalType.RED
			})

			if (confirmed) {
				this.$store.dispatch(
					"restaurantsModule/archiveProduct",
					product
				)
			}
		},
		async unarchiveProduct(product) {
			const confirmed = await this.$refs.confirmationModal.open({
				title: `Reactivate ${product.name}?`,
				body: "Are you sure you want to reactiavte this product?",
				confirmation: "Reactivate Product",
				confirmationIcon: "sk-icon-times-regular",
				cancel: "Close"
			})

			if (confirmed) {
				this.$store.dispatch(
					"restaurantsModule/unarchiveProduct",
					product
				)
			}
		},
		async deleteProduct(product) {
			const confirmed = await this.$refs.confirmationModal.open({
				title: `Are you sure you want to delete ${product.name}?`,
				confirmation: "Delete Product",
				confirmationIcon: "sk-icon-trash-alt-solid",
				cancel: "Keep Product",
				type: ConfirmationModalType.RED
			})

			if (confirmed) {
				this.$store.dispatch(
					"restaurantsModule/deleteProduct",
					product.id
				)
			}
		},
		duplicateProduct(product) {
			this.$store
				.dispatch("restaurantsModule/addProduct", product)
				.finally(() => {
					this.toggleLoader(false)
				})
		},
		async confirmProductChanges(resolve) {
			const confirmed = await this.$refs.confirmationModal.open({
				title: `You have unsaved changes`,
				body: `Are you sure you want to leave this page without saving your changes?`,
				confirmation: "Save Changes",
				cancel: "Continue Without Saving",
				type: ConfirmationModalType.WARNING
			})
			resolve(confirmed)
		},
		async saveProduct(payload) {
			try {
				tracker.trackEvent("Restaurant Product Save Clicked")
				this.toggleLoader(true)
				if (payload.product.id != undefined) {
					if (payload.sync) {
						const isEcommerceUpdate = payload.menuIds.length > 0 && payload.menuIds.every(
							(menuId) =>
								this.menusHash[menuId] &&
								this.menusHash[menuId].type ==
									MenuType.ECOMMERCE
						)
						const businessesToSync = this.getBusinessesToSync(
							payload.product
						)
						if (isEcommerceUpdate && businessesToSync.length > 0) {
							this.$refs.syncBusinessesProductsModal.syncAll(
								businessesToSync,
								payload.product
							)
						} else if (
							this.$store.getters.isSuperAdmin &&
							businessesToSync.length > 0
						) {
							await this.$refs.syncBusinessesProductsModal.open({
								businesses: businessesToSync,
								product: payload.product
							})
						}
					}
					await this.$store.dispatch(
						"restaurantsModule/updateProduct",
						payload.product
					)
				} else {
					const product = await this.$store.dispatch(
						"restaurantsModule/addProduct",
						payload.product
					)
					if (product) {
						payload.product = product
					} else {
						throw "No product returned"
					}
					if (payload.menuIds) {
						let businessMenus =
							this.getBusinessMenusToEnableProducts(
								payload.menuIds
							)
						if (businessMenus.length > 0) {
							this.$refs.syncBusinessMenusProductsModal.open({
								restaurantMenuIds: payload.menuIds,
								productIds: [payload.product.id],
								businessMenusToSync: businessMenus
							})
						}
					}
				}
				await this.updateMenuAssociations(payload)
				this.toggleLoader(false)
			} catch (e) {
				this.toggleLoader(false)
				throw e
			}
		},
		getBusinessesToSync(product) {
			const businessesToSync = []
			this.$store.state.restaurantsModule.restaurantBusinesses.forEach(
				(business) => {
					business.menus
						.filter(
							(menu) =>
								this.menusHash[menu.id] &&
								menu.products.find(
									(menuProduct) =>
										product.id == menuProduct.id
								)
						)
						.forEach((menu) => {
							businessesToSync.push({
								menu: menu,
								menuName: this.menusHash[menu.id].name,
								businessId: business.id,
								businessName: business.name
							})
						})
				}
			)
			return businessesToSync
		},
		getBusinessMenusToEnableProducts(menuIds) {
			const businessMenus = []
			this.$store.state.restaurantsModule.restaurantBusinesses.forEach(
				(business) => {
					business.menus
						.filter((menu) => menuIds.includes(menu.id))
						.forEach((menu) => {
							businessMenus.push({
								menu: menu,
								menuName: menu.name,
								businessId: business.id,
								businessName: business.name
							})
						})
				}
			)
			return businessMenus
		},
		async updateMenuAssociations(payload) {
			try {
				let promises = []
				const oldMenuIds = payload.product.menu_ids
				payload.product.menu_ids = payload.menuIds
				const menuIdsToAdd = payload.menuIds.filter(
					(menuId) => !oldMenuIds.includes(menuId)
				)
				if (menuIdsToAdd.length > 0) {
					promises = promises.concat(
						menuIdsToAdd.map((menuId) => {
							return this.$store
								.dispatch("restaurantsModule/addMenuProduct", {
									menuId: menuId,
									product: payload.product
								})
								.catch(() => {})
						})
					)
				}

				const menuIdsToRemove = oldMenuIds.filter(
					(menuId) => !payload.menuIds.includes(menuId)
				)
				if (menuIdsToRemove.length > 0) {
					promises = promises.concat(
						menuIdsToRemove.map((menuId) => {
							return this.$store
								.dispatch(
									"restaurantsModule/removeMenuProduct",
									{
										menuId: menuId,
										productId: payload.product.id
									}
								)
								.catch(() => {})
						})
					)
				}

				if (promises.length > 0) {
					await Promise.all(promises)
				}
				return true
			} catch (e) {
				return false
			}
		},
		exportProducts(confirmed) {
			return this.$store.dispatch("restaurantsModule/exportProducts", {
				exportPhotos: confirmed,
				products: this.filteredProducts
			})
		},
		cleanUpBatchIds() {
			this.batchSelectedIds = []
			this.$emit("update:selectedIds", [])
		}
	},
	mounted: function () {
		this.toggleLoader(true)
		this.$store.dispatch("restaurantsModule/getRestaurantIntegrations")
		Promise.all([
			this.$store.dispatch("restaurantsModule/getLabels"),
			this.$store.dispatch("restaurantsModule/getCategories"),
			this.$store.dispatch("restaurantsModule/getRestaurantProducts")
		]).finally(() => {
			this.toggleLoader(false)
		})
	},
	watch: {
		batchSelectedIds: {
			immediate: true,
			handler(ids) {
				this.$emit("update:selectedIds", ids)
			}
		}
	}
}
</script>
