import { store } from "@/store/store"
import { UserRole } from "@/enums/userRole"

export default {
    track(page) {
        if (store.state.user_role != UserRole.SUPER_ADMIN){
            mixpanel.track("Chef Page Action",{
                "page": page.name,
                "user": store.state.user_id
            })
        }
    },
    trackEvent(eventName, data) {
        if (store.state.user_role != UserRole.SUPER_ADMIN) {
            const eventData = Object.assign({}, {
                user: store.state.user_id,
                name: store.state.user_name,
                role: store.state.user_role,
                app: "Chef"
            }, data)
            mixpanel.track(eventName, eventData)
        }
    }
}