<template>
	<SkBaseModal
		class="sk-modal-alt vld-parent"
		:isOpen="isOpen"
		@close="close"
	>
		<div class="sk-modal-alt-title">
			<h1>Add Guest</h1>
		</div>
		<h2>Details</h2>
		<div class="sk-row" v-if="ownsMultipleBusinesses">
			<TypeaheadInput
				ref="businessInput"
				:placeholder="'Business'"
				:fetch="autocompleteBusinesses"
				:serializer="typeaheadSerializer"
				:query="business.name"
				@selected="businessSelected"
			/>
		</div>
		<div class="sk-row">
			<SkInput
				ref="nameInput"
				:name="'Guest name'"
				:placeholder="'Guest Name'"
				v-model="businessGuest.name"
				:required="true"
			/>
		</div>
		<div class="sk-row">
			<SkInput
				ref="emailInput"
				:name="'Email Address'"
				:placeholder="'Email Address'"
				v-model="businessGuest.email"
				:type="'email'"
				:required="true"
			/>
		</div>
		<div class="sk-row">
			<SkInput
				:name="'Phone'"
				:placeholder="'Phone (Optional)'"
				v-model="businessGuest.phone"
				:type="'tel'"
			/>
		</div>
		<h2>Stay</h2>

		<div class="sk-row">
			<VueCtkDateTimePicker
				ref="datesInput"
				:label="'Stay Dates'"
				:no-clear-button="true"
				:no-label="true"
				:no-button-now="true"
				:button-color="'#90DA36'"
				:format="'MM-DD-YYYY'"
				:formatted="'ll'"
				:color="'#2E3C49'"
				:range="true"
				:output-format="'YYYY-MM-DD'"
				:noShortcuts="true"
				v-model="businessGuestStayDates"
			></VueCtkDateTimePicker>
		</div>
		<div class="sk-row">
			<SkInput
				:name="'Room Number/Name (Optional)'"
				:placeholder="'Room Number/Name (Optional)'"
				v-model="businessGuest.room"
			/>
		</div>
		<div class="sk-switch-label-wrapper bottom-text">
			<SkCheckbox v-model="digitalMenu" />
			<span>Send guest a digital room service menu on check in date</span>
		</div>
		<div class="sk-modal-actions">
			<button @click="close" class="button button-text">Close</button>
			<button
				type="button"
				class="button button-success"
				@click="saveBusinessGuest"
			>
				<i class="sk-icon-check-regular"></i>
				{{ btnTxt }}
			</button>
		</div>
	</SkBaseModal>
</template>

<style scoped>
h2 {
	color: var(--sk-navy);
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 25px;
	margin-top: 25px;
}

.bottom-text {
	font-weight: normal;
	font-size: 12px;
	line-height: 15px;
	color: var(--sk-grey2) !important;
	padding-bottom: 15px;
}

.sk-modal-alt-title {
    z-index: 1;
}
</style>

<script>
import Vue from "vue"
import utils from "@/utils/utils"
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import SkInput from "@/components/SkInput.vue"
import SkCheckbox from "@/components/SkCheckbox.vue"
import TypeaheadInput from "@/components/TypeaheadInput.vue"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import phoneFilterMixin from "@/mixins/phone-filter-mixin"
import { BusinessGuestAmenityType } from "@/enums/businessGuestAmenityType"
import ActionTypes from "@/store/modules/users/action-types"
import VueCtkDateTimePicker from "vue-ctk-date-time-picker"
import moment from "moment"
import "moment-timezone"


export default {
	name: "BusinessGuestModal",
	mixins: [skModalMixin, phoneFilterMixin],
	components: {
		SkBaseModal,
		SkInput,
		SkCheckbox,
		TypeaheadInput,
		VueCtkDateTimePicker
	},
	data() {
		return {
			businessGuest: {},
			business: {}
		}
	},
	computed: {
		ownsMultipleBusinesses() {
			return this.$store.getters.ownsMultipleEntities
		},
		businesses() {
			return this.$store.getters.ownedBusinesses.map((entity) => {
				return {
							id: entity.id,
							name: entity.name,
							tz: entity.timezone
						}
			})
		},
		digitalMenu: {
			get: function () {
				let retVal = false
				if (
					this.businessGuest &&
					this.businessGuest.amenities &&
					this.businessGuest.amenities.length > 0
				) {
					const digitalMenuAmentiy = this.businessGuest.amenities.filter(
						(amenity) =>
							amenity.type ==
							BusinessGuestAmenityType.DIGITAL_MENU
					)
					if (digitalMenuAmentiy && digitalMenuAmentiy.length > 0) {
						retVal = true
					}
				}
				return retVal
			},
			set: function (val) {
				if (val) {
					const digitalMenuAmentiy = this.businessGuest.amenities.filter(
						(amenity) =>
							amenity.type ==
							BusinessGuestAmenityType.DIGITAL_MENU
					)
					if (!digitalMenuAmentiy || digitalMenuAmentiy.length == 0) {
						const amenityObj = {
							type: BusinessGuestAmenityType.DIGITAL_MENU,
							attributes: {}
						}

						if (!this.businessGuest.amenities) {
							this.businessGuest.amenities = []
						}
						this.businessGuest.amenities.push(amenityObj)
					}
				} else {
					let amenityIndex = this.businessGuest.amenities.findIndex(
						(amenity) =>
							amenity.type ==
							BusinessGuestAmenityType.DIGITAL_MENU
					)
					if (amenityIndex >= 0) {
						this.businessGuest.amenities.splice(amenityIndex, 1)
					}
				}
			}
		},
		businessGuestStayDates: {
			get: function () {
				return {
					start: this.businessGuest.check_in_date,
					end: this.businessGuest.check_out_date
				}
			},
			set: function (val) {
				this.businessGuest.check_in_date = val.start
				this.businessGuest.check_out_date = val.end
			}
		},
		btnTxt() {
			if (this.businessGuest.id) {
				return "Update Guest"
			} else {
				return "Add Guest"
			}
		}
	},
	methods: {
		autocompleteBusinesses(query) {
			return this.$store.dispatch(
				"businessesModule/autocompleteBusinesses",
				query
			)
		},
		typeaheadSerializer(result) {
			return {
				text: result.name
			}
		},
		businessSelected(business) {
			this.businessGuest.business_id = business.id
			this.business = this.businesses.find(
				(business) => business.id == this.businessGuest.business_id
			)
			
		},
		validateBusinessGuestData() {
			const validName = this.$refs.nameInput.validate()
			const validEmail = this.$refs.emailInput.validate()
			if (
				!this.businessGuest.check_in_date &&
				!this.businessGuest.check_out_date
			) {
				throw "Please select guest stay dates"
			}
			if (!validName) {
				throw "Please enter a valid guest name"
			}
			if (!validEmail) {
				throw "Please enter a valid guest email"
			}
			return true
		},
		async saveBusinessGuest() {
			try {
				this.validateBusinessGuestData()
				this.businessGuest.check_in_date = moment
					.utc(this.businessGuest.check_in_date, "YYYY-MM-DD")
					.tz(this.business.tz)
				this.businessGuest.check_out_date = moment
					.utc(this.businessGuest.check_out_date, "YYYY-MM-DD")
					.tz(this.business.tz)
				if (this.businessGuest.id) {
					const resp = await this.$store.dispatch(
						`usersModule/${ActionTypes.UPDATE_BUSINESS_GUEST}`,
						this.businessGuest
					)
					if (resp) {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Successfully updated guest"
						})
						this.close()
					} else {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Something went wrong, please try again",
							type: NotificationType.WARNING
						})
					}
				} else {
					const resp = await this.$store.dispatch(
						`usersModule/${ActionTypes.ADD_BUSINESS_GUEST}`,
						this.businessGuest
					)
					if (resp) {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Successfully added guest"
						})
						this.close()
					} else {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Something went wrong, please try again",
							type: NotificationType.WARNING
						})
					}
				}
			} catch (e) {
				this.bugsnag.notify("Unable to save guest", (event) => {
					event.addMetadata("error", {
						error: e
					})
				})
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title:
						e && !e.message
							? e
							: "Something went wrong, please try again",
					type: NotificationType.WARNING
				})
			}
		},
		close() {
			this.isOpen = false
			this.closed()
		},
		handleOptions: function (payload) {
			if (payload == undefined || payload.businessGuest == undefined) {
				Vue.set(this.businessGuest, "name", "")
				Vue.set(this.businessGuest, "email", "")
				Vue.set(this.businessGuest, "phone", "")
				Vue.set(this.businessGuest, "check_in_date", null)
				Vue.set(this.businessGuest, "check_out_date", null)
				Vue.set(this.businessGuest, "digitalMenu", true)
				Vue.set(this.businessGuest, "room", "")
				Vue.set(this.businessGuest, "id", null)
				if (!this.ownsMultipleBusinesses) {
					this.business = this.businesses[0]
					Vue.set(this.businessGuest, "business_id", this.business.id)
				}
				else {
					this.business = {}
					Vue.set(this.businessGuest, "business_id", null)
				}
				const amenityObj = [
					{
						type: BusinessGuestAmenityType.DIGITAL_MENU,
						attributes: {}
					}
				]
				Vue.set(this.businessGuest, "amenities", amenityObj)
			} else {
				this.businessGuest = utils.clonedeep(payload.businessGuest)
				this.business = this.businesses.find(
					(business) => business.id == this.businessGuest.business_id
				)
			}
		},
	},
	
}
</script>