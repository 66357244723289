<template>
	<div class="centered-tab-content fixed-button-bottom">
		<div class="sk-row sk-widget sk-widget-section">
			<div class="sk-widget-section-title">Name</div>
			<SkInput
				:name="'Menu Name'"
				:placeholder="'Name'"
				v-model="menu.name"
			/>
		</div>
		<div class="sk-row sk-widget sk-widget-section">
			<div class="sk-widget-section-title">Menu Type</div>
			<div
				v-for="menuTypeOption in menuTypeOptions"
				:key="menuTypeOption.id"
				class="sk-radio-group"
			>
				<SkRadio :option="menuTypeOption.id" v-model="menu.type" />
				<span>{{ menuTypeOption.text }}</span>
			</div>
		</div>
		<div class="sk-row sk-widget sk-widget-section">
			<div class="sk-widget-section-title">Delivery</div>
			<div class="sk-radio-group">
				<SkRadio
					:option="courierIds.POSTMATES"
					v-model="menu.courier_id"
				/>
				<span>PostMates</span>
			</div>
			<div class="sk-radio-group">
				<SkRadio
					:option="courierIds.DOORDASH"
					v-model="menu.courier_id"
				/>
				<span>DoorDash</span>
			</div>
			<div class="sk-radio-group">
				<SkRadio
					:option="courierIds.CUT_CATS"
					v-model="menu.courier_id"
				/>
				<span>Cut Cats</span>
			</div>
			<div class="sk-radio-group">
				<SkRadio :option="courierIds.RELAY" v-model="menu.courier_id" />
				<span>Relay</span>
			</div>
			<div class="sk-radio-group">
				<SkRadio
					:option="courierIds.WALKING"
					v-model="menu.courier_id"
				/>
				<span>Self Delivery</span>
			</div>
			<div class="sk-radio-group">
				<SkRadio
					:option="courierIds.TOOKAN"
					v-model="menu.courier_id"
				/>
				<span>Bar Delivery</span>
			</div>
		</div>
		<div class="sk-row sk-widget sk-widget-section">
			<div class="sk-widget-section-title">Paired with</div>
			<div class="sk-pills-container">
				<router-link
					v-for="pairing in businessPairings"
					:key="pairing.id"
					:to="pairing.link"
					tag="div"
					class="pill pill-grey"
					:title="pairing.name"
				>
					<i class="sk-icon-link-regular"></i>
					{{ pairing.name }}
				</router-link>
			</div>
		</div>
		<button class="button button-primary button-absolute" @click="saveMenu">
			Save Details
		</button>
	</div>
</template>

<style scoped>
* >>> .sk-radio {
	width: 15px;
	height: 15px;
	margin-right: 10px;
}

.commission-input {
	max-width: 260px;
}
</style>

<script>
import { MenuType } from "@arikgaisler/utils/enums/menuType"
import { CourierId } from "@arikgaisler/utils/enums/courierId"
import { PermissionMethod } from "@/utils/permissions"
import menuTypeFormatterMixin from "@/mixins/menu-type-formatter-mixin"
import SkInput from "@/components/SkInput.vue"
import SkRadio from "@/components/SkRadio.vue"

export default {
	name: "RestaurantMenuBasicInfo",
	mixins: [menuTypeFormatterMixin],
	components: {
		SkInput,
		SkRadio
	},
	props: {
		menu: Object
	},
	data: function () {
		return {
			courierIds: CourierId
		}
	},
	computed: {
		menuTypeOptions() {
			const menuTypes = [
				MenuType.DEFAULT,
				MenuType.POPUP,
				MenuType.EMBEDDED,
				MenuType.PRE_PACKAGED,
				MenuType.ESSENTIALS
			]

			return menuTypes.map((menuType) => {
				return {
					id: menuType,
					text: this.$options.filters.menuTypeText(menuType)
				}
			})
		},
		businessPairings() {
			const businesses =
				this.$store.state.restaurantsModule.restaurantBusinesses
			if (!businesses) {
				return []
			}
			return businesses
				.filter(
					(business) =>
						business.menus.find(
							(menu) => menu.id == this.menu.id
						) != undefined
				)
				.map((business) => {
					var link = ""
					if (this.canLinkToBusiness) {
						link =
							"/business/" +
							business.slug +
							"/menu/" +
							this.menu.id
					}
					return {
						name: business.name,
						slug: business.slug,
						link: link
					}
				})
		},
		canLinkToBusiness() {
			return this.$store.state.permissions.includes(
				PermissionMethod.LINK_TO_BUSINESS
			)
		}
	},
	methods: {
		saveMenu() {
			this.$emit("save")
		}
	}
}
</script>
