import actions from "./actions"
import mutations from "./mutations"

const state = {
	loading: false,
	business: null,
	businessAvgRating: null,
	todaysBusinessMenus: [],
	todaysEvents: {},
	recentOrders: []
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}