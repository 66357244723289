import MutationTypes from "./mutation-types"

export default {
	[MutationTypes.GOT_NEAREST_LOCATIONS_RESULTS](state, payload) {
		if (payload.locations) {
			state.searchedLocations = payload.locations.map(location => {
				return {
					latitude: location.address.details ? location.address.details.geometry.location.lat() : "",
					longitude: location.address.details ? location.address.details.geometry.location.lng() : "",
					formattedAddress: `${location.address.address}, ${location.address.city}, ${location.address.state} ${location.address.zip}`,
					address: location.address,
					type: location.type
				}
			})
		} else {
			state.searchedLocations = []
		}
		const locationIds = payload.inNetworkResults.map(result => {
			if (result.business) {
				return result.business.external_location_id
			} else if (result.restaurant) {
				return result.restaurant.external_location_id
			}
		})
		let results = payload.inNetworkResults
		payload.outOfNetworkResults.forEach((result) => {
			if (!locationIds.includes(result.place_id)) {
				results.push({
					name: result.name,
					address: result.vicinity,
					rating: result.avg_score,
					distance: result.distance,
					latitude: result.geometry.location.lat(),
					longitude: result.geometry.location.lng()
				})
			}
		})

		state.locationResults = results.sort((a, b) => a.distance - b.distance)
	}
}