<template>
	<div class="sidebar-activity-feed">
		<button
			@click="activityFeedToggled"
			class="button button-notifications"
			:class="{
				expanded: isExpanded,
				'button-notifications-unread': newNotifications,
				'button-primary-unread': !isExpanded && newNotifications
			}"
		>
			<i :class="buttonIcon"></i>
			<span>{{ buttonText }}</span>
		</button>
		<SkPopover v-model="showActivityFeed" @clickedOutside="clickedOutside">
			<img class="left-arrow" src="/images/left-arrow.svg" />
			<div class="activity-feed-header">
				<div>
					<i class="sk-icon-bell-solid"></i>
					Notifications
				</div>
			</div>
			<slot v-if="activities.length > 0">
				<SideBarActivityFeedItem
					v-for="activityItem in activities"
					:key="activityItem.id"
					:activity="activityItem"
				/>
			</slot>
			<div class="activity-feed-empty" v-else>
				<img
					class="activity-empty-img"
					src="/images/2k-no-activity-feed.png"
					alt="Empty Activity Feed"
				/>
				<div class="activity-empty-title">No Recent Activity</div>
			</div>
			<div class="activity-feed-footer" @click="viewAllNotifications">
				View All Notifications
			</div>
		</SkPopover>
	</div>
</template>

<style scoped>
.sidebar-wrapper-hovering .sidebar-activity-feed {
	overflow: visible;
}

.sidebar-activity-feed {
	position: relative;
	margin-bottom: 20px;
	overflow: hidden;
}

.button-notifications,
.button-notifications:hover,
.button-notifications:active {
	padding: 0 8.5px;
	height: 31px;
	width: 31px;
	min-height: 31px;
	margin-left: 21px;
	display: flex;
	align-items: center;
	transition: all 0.25s linear;
	font-weight: 600;
	border: 0;
	background: var(--sk-white);
	color: var(--sk-grey2);
}

.button-notifications-unread,
.button-notifications-unread:hover,
.button-notifications-unread:active {
	background: linear-gradient(
		90deg,
		var(--sk-orange2) 0%,
		var(--sk-orange) 100%
	);
	color: var(--sk-white);
}

.button-primary-unread {
	padding: 0 6px;
}

.button i {
	width: 15px;
	display: inline-block;
	margin-right: 0 !important;
	font-size: 15px !important;
}

.button > span {
	white-space: nowrap;
	opacity: 0;
	width: 100%;
	font-size: 12px;
	font-weight: 400;
	transition: opacity 0.15s linear;
}

.button.expanded {
	width: 185px;
	padding: 0 9px;
}

.button.expanded > span {
	transition: opacity 0.15s linear 0.25s;
	opacity: 1;
}

.sidebar-activity-feed >>> .sk-popover {
	left: calc(100% + 10px);
	width: 315px;
	max-height: 285px;
	background: var(--sk-white);
	border-radius: 5px;
	border: 1px solid var(--sk-grey);
	padding: 0;
	overflow: visible;
	box-shadow: none;
	top: -30px;
}

.left-arrow {
	width: 23px;
	height: 21px;
	position: absolute;
	left: -20px;
	top: 5px;
}

.activity-feed-header {
	height: 43px;
	border-bottom: 1px solid var(--sk-grey);
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 20px;
	font-size: 12px;
	color: var(--sk-dark-navy);
	font-weight: 600;
}

.activity-feed-header i {
	margin-right: 15px;
}

.activity-feed-footer {
	height: 40px;
	background: var(--sk-greybg2);
	border-top: 1px solid var(--sk-grey);
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 10px;
	color: var(--sk-grey2);
	margin-top: 20px;
	cursor: pointer;
	overflow: hidden;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

.sidebar-activity-feed >>> .activity-feed-item {
	margin-top: 20px;
}

.activity-feed-empty {
	margin-top: 20px;
}

.activity-empty-img {
	width: 125px;
	margin: 0 auto;
	display: block;
}

.activity-empty-title {
	margin-top: 10px;
	text-align: center;
	font-size: 12px;
	color: var(--sk-grey3);
}
</style>

<script>
import ActionTypes from "@/store/modules/activityFeed/action-types"
import SkPopover from "@/components/SkPopover.vue"
import SideBarActivityFeedItem from "@/components/sidebar/SideBarActivityFeedItem.vue"

export default {
	name: "SideBarActivityFeed",
	components: {
		SkPopover,
		SideBarActivityFeedItem
	},
	props: {
		isExpanded: Boolean
	},
	data: function () {
		return {
			showActivityFeed: false
		}
	},
	computed: {
		feed() {
			return this.$store.state.activityFeedModule
		},
		activities() {
			return this.feed.activities.slice(0, 3)
		},
		newNotifications() {
			return this.feed.totalNew > 0
		},
		buttonText() {
			return this.newNotifications ? "New Notifications" : "Notifications"
		},
		buttonIcon() {
			return this.newNotifications
				? "sk-icon-bell-on-solid"
				: "sk-icon-bell-solid"
		}
	},
	methods: {
		activityFeedToggled() {
			this.showActivityFeed = !this.showActivityFeed
			this.$emit("toggled", this.showActivityFeed)
			this.$store.dispatch(
				`activityFeedModule/${ActionTypes.READ_ACTIVITES}`,
				this.activities
			)
		},
		viewAllNotifications() {
			this.$router.replace("/")
			this.close()
			this.$emit("resetHover")
		},
		clickedOutside() {
			this.close()
		},
		close() {
			this.showActivityFeed = false
			this.$emit("toggled", false)
		}
	},
	mounted: function () {
		this.$store.dispatch(
			`activityFeedModule/${ActionTypes.GET_ACTIVITY_FEED}`,
			{
				offset: 0
			}
		)
	}
}
</script>