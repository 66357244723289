import { render, staticRenderFns } from "./BusinessAdminInfo.vue?vue&type=template&id=b4131736&scoped=true&"
import script from "./BusinessAdminInfo.vue?vue&type=script&lang=js&"
export * from "./BusinessAdminInfo.vue?vue&type=script&lang=js&"
import style0 from "./BusinessAdminInfo.vue?vue&type=style&index=0&id=b4131736&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4131736",
  null
  
)

export default component.exports