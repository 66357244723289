<template>
	<div class="supply-history">
		<div>
			<div class="sk-table sk-table-default">
				<div class="sk-table-header">
					<div class="sk-table-row">
						<div>Request Date</div>
						<div v-if="showSendToColumn">Send To</div>
						<div>Item</div>
						<div>Quantity</div>
						<div>Price</div>
						<div>Status</div>
						<div>Actions</div>
					</div>
				</div>
				<transition-group
					name="list"
					tag="div"
					class="sk-table-body"
					appear
				>
					<SupplyRequestLineItem
						v-for="supplyRequest in pagedSupplyRequests"
						:key="supplyRequest.id"
						:supplyRequest="supplyRequest"
						@approve="approveSupplyRequest"
						@reject="rejectSupplyRequest"
						@cancel="cancelSupplyRequest"
					/>
				</transition-group>
			</div>
		</div>
		<PaginationFooter
			:contentText="'Supply Requests'"
			:pageSize="20"
			:total="supplyRequests.length"
			ref="paginationFooter"
			@pageChanged="filterPage"
		/>
		<ConfirmationModal ref="confirmationModal" />
	</div>
</template>

<style scoped>
.supply-history {
	height: 100%;
	display: flex;
	flex-direction: column;
}
</style>

<script>
import moment from "moment"
import { ConfirmationModalType } from "@/enums/confirmationModalType"
import { SupplyRequestStatus } from "@/enums/supplyRequestStatus"
import { NotificationType } from "@/enums/notificationType"
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import SupplyRequestLineItem from "@/components/supplies/SupplyRequestLineItem.vue"
import PaginationFooter from "@/components/PaginationFooter.vue"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue"
import { EntityType } from "@/enums/entityType"

export default {
	name: "SupplyHistory",
	components: {
		SupplyRequestLineItem,
		PaginationFooter,
		ConfirmationModal
	},
	props: {
		filters: Object
	},
	data: function () {
		return {
			pageRange: {
				page: 1,
				start: 0,
				end: 20
			}
		}
	},
	computed: {
		showSendToColumn() {
			return this.$store.getters.ownsMultipleEntities
		},
		supplyRequests() {
			let filterStartDate = null
			let filterEndDate = null
			if (
				this.filters &&
				this.filters.startDate &&
				this.filters.endDate
			) {
				filterStartDate = moment(
					this.filters.startDate,
					"YYYY-MM-DD"
				).startOf("d")
				filterEndDate = moment(
					this.filters.endDate,
					"YYYY-MM-DD"
				).endOf("d")
			}
			return this.$store.getters.supplyRequests
				.filter((supplyRequest) => {
					if (this.filters && this.filters.status != -1) {
						if (this.filters.status != supplyRequest.status.value) {
							return false
						}
					}

					if (filterStartDate != null && filterEndDate != null) {
						let createdDate = moment
							.utc(supplyRequest.created_date)
							.local()
						if (
							!createdDate.isBetween(
								filterStartDate,
								filterEndDate
							)
						) {
							return false
						}
					}

					if (this.filters && this.filters.item) {
						if (
							this.filters.item.entity == EntityType.BUSINESS &&
							!supplyRequest.business
						) {
							return false
						} else if (
							this.filters.item.entity == EntityType.RESTAURANT &&
							!supplyRequest.restaurant
						) {
							return false
						}

						if (
							supplyRequest.supply.id != this.filters.item.itemId
						) {
							return false
						}
					}

					return true
				})
				.sort((a, b) => {
					const aWeight = moment.utc(a.created_date).valueOf()
					const bWeight = moment.utc(b.created_date).valueOf()
					return bWeight - aWeight
				})
		},
		pagedSupplyRequests() {
			return this.supplyRequests.slice(
				this.pageRange.start,
				this.pageRange.end
			)
		}
	},
	methods: {
		showError() {
			eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
				title: "Supply Request",
				message: "Something went wrong, please try again",
				type: NotificationType.ERROR
			})
		},
		async approveSupplyRequest(supplyRequest) {
			const confirmed = await this.$refs.confirmationModal.open({
				title: "Approve Supply Request?",
				body: `Are you sure you want to approve the supply request for ${supplyRequest.supply.name}?`,
				confirmation: "Approve",
				confirmationIcon: "sk-icon-check-regular",
				cancel: "Cancel",
				type: ConfirmationModalType.GREEN
			})
			if (confirmed) {
				const success = await this.updateRequestStatus(
					supplyRequest,
					SupplyRequestStatus.APPROVED
				)

				if (success) {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Supply Request",
						message: "Supply request was successfully approved",
						type: NotificationType.SUCCESS
					})
				} else {
					this.showError()
				}
			}
		},
		async rejectSupplyRequest(supplyRequest) {
			const confirmed = await this.$refs.confirmationModal.open({
				title: "Reject Supply Request?",
				body: `Are you sure you want to approve the supply request for ${supplyRequest.supply.name}?`,
				confirmation: "Approve",
				confirmationIcon: "sk-icon-check-regular",
				cancel: "Cancel",
				type: ConfirmationModalType.RED
			})
			if (confirmed) {
				const success = await this.updateRequestStatus(
					supplyRequest,
					SupplyRequestStatus.REJECTED
				)

				if (success) {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Supply Request",
						message: "Supply request was successfully rejected",
						type: NotificationType.SUCCESS
					})
				} else {
					this.showError()
				}
			}
		},
		async cancelSupplyRequest(supplyRequest) {
			const confirmed = await this.$refs.confirmationModal.open({
				title: "Cancel Supply Request?",
				body: `Are you sure you want to cancel the supply request for ${supplyRequest.supply.name}?`,
				confirmation: "Cancel Supply Request",
				confirmationIcon: "sk-icon-check-regular",
				cancel: "Cancel",
				type: ConfirmationModalType.RED
			})
			if (confirmed) {
				const success = await this.updateRequestStatus(
					supplyRequest,
					SupplyRequestStatus.CANCELLED
				)

				if (success) {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Supply Request",
						message: "Supply request was successfully cancelled",
						type: NotificationType.SUCCESS
					})
				} else {
					this.showError()
				}
			}
		},
		updateRequestStatus(supplyRequest, status) {
			if (supplyRequest.business) {
				return this.$store.dispatch(
					"businessesModule/setSupplyRequestStatus",
					{
						supplyRequest: supplyRequest,
						status: status
					}
				)
			} else if (supplyRequest.restaurant) {
				return this.$store.dispatch(
					"restaurantsModule/setSupplyRequestStatus",
					{
						supplyRequest: supplyRequest,
						status: status
					}
				)
			}
		},
		filterPage(range) {
			this.pageRange = range
		}
	},
	watch: {
		filters: {
			deep: true,
			handler() {
				this.$refs.paginationFooter.pageChanged(1)
			}
		}
	}
}
</script>