import actions from "./actions"
import mutations from "./mutations"

const state = {
	searchedLocations: [],
	locationResults: []
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}