<template>
  <div class="restaurant-pos-devices">
    <div class="sk-grid" v-if="showDevices">
      <div v-for="device in devices" :key="device.id">
        <RestaurantPOS :device="device" />
      </div>
    </div>
    <EmptyPlaceholderWidget 
      v-else-if="showNoDevices"
      :title="`No Devices`" 
      :description="`You don’t have any devices yet.`"/>
  </div>
</template>

<style scoped>
.restaurant-pos-devices {
  height: 100%;
}

@media (min-width: 800px) {
  .sk-grid {
    grid-template-columns: 1fr 1fr;
    max-width: 880px;
  } 
}

@media (min-width: 1300px) {
  .sk-grid {
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 1330px;
  } 
}

@media (min-width: 1400px) {
  .sk-grid {
    margin-left: auto;
    margin-right: auto;
  } 
}
</style>

<script>
import RestaurantPOS from "@/components/restaurant/RestaurantPOS.vue"
import EmptyPlaceholderWidget from "@/components/EmptyPlaceholderWidget.vue"

export default {
  name: "RestaurantPOSDevices",
	components: {
    RestaurantPOS,
    EmptyPlaceholderWidget
  },
	computed: {
		restaurant() {
			return this.$store.state.restaurantsModule.currentRestaurant
    },
    devices() {
      return this.$store.state.restaurantsModule.restaurantPOSDevices
    },
    showDevices() {
      return this.devices.length > 0 && !this.$store.state.restaurantsModule.loading
    },
    showNoDevices() {
      return (this.devices.length == 0 || !this.devices) && !this.$store.state.restaurantsModule.loading
    }
  },
  mounted: function() {
    if (this.devices.length == 0) {
      this.$store.dispatch("restaurantsModule/getRestaurantPOSDevices", this.restaurant.id)
    }
  }
}
</script>