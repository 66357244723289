export const EDIT_BUSINESS_EVENT = "EDIT_BUSINESS_EVENT"
export const DUPLICATE_BUSINESS_EVENT = "DUPLICATE_BUSINESS_EVENT"
export const GET_EVENTS = "GET_EVENTS"
export const FILTER_EVENTS = "FILTER_EVENTS"
export const UPDATE_EVENT_STATUS = "UPDATE_EVENT_STATUS"
export const UPDATE_EVENT_FULFILLMENT_STATUS = "UPDATE_EVENT_FULFILLMENT_STATUS"
export const UPDATE_EVENT_NOTES = "UPDATE_EVENT_NOTES"
export const DELETE_EVENT = "DELETE_EVENT"
export const END_EVENT = "END_EVENT"
export const STOP_EVENT_PRE_ORDERS = "STOP_EVENT_PRE_ORDERS"
export const GET_EVENT = "GET_EVENT"
export const GET_EVENT_ORDERS = "GET_EVENT_ORDERS"
export const GET_EVENT_MENUS = "GET_EVENT_MENUS"
export const GET_EVENT_RESTAURANT_MENUS = "GET_EVENT_RESTAURANT_MENUS"
export const GET_EVENT_RESTAURANT_MENU_PRODUCTS = "GET_EVENT_RESTAURANT_MENU_PRODUCTS"
export const GET_EVENT_REMINDERS = "GET_EVENT_REMINDERS"
export const UPDATE_EVENT_REMINDER = "UPDATE_EVENT_REMINDER"
export const ADD_EVENT_REMINDER = "ADD_EVENT_REMINDER"
export const GET_EVENT_MANIFEST = "GET_EVENT_MANIFEST"
export const DELETE_EVENT_REMINDER = "DELETE_EVENT_REMINDER"

export default {
  EDIT_BUSINESS_EVENT,
  DUPLICATE_BUSINESS_EVENT,
  GET_EVENTS,
  FILTER_EVENTS,
  UPDATE_EVENT_STATUS,
  UPDATE_EVENT_FULFILLMENT_STATUS,
  UPDATE_EVENT_NOTES,
  UPDATE_EVENT_REMINDER,
  ADD_EVENT_REMINDER,
  DELETE_EVENT_REMINDER,
  DELETE_EVENT,
  END_EVENT,
  STOP_EVENT_PRE_ORDERS,
  GET_EVENT,
  GET_EVENT_ORDERS,
  GET_EVENT_MENUS,
  GET_EVENT_RESTAURANT_MENUS,
  GET_EVENT_RESTAURANT_MENU_PRODUCTS,
  GET_EVENT_REMINDERS,
  GET_EVENT_MANIFEST
}