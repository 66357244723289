<template>
	<SkBaseModal :isOpen="isOpen" @close="close" class="sk-modal-alt">
		<div class="sk-modal-alt-title">
			<h1>
				Send Receipt:
				<span class="sk-modal-alt-subtitle">#{{ orderId }}</span>
			</h1>
		</div>
		<div class="sk-row">
			<SkInput
				v-model="email"
				ref="emailInput"
				:validation="emailRegex"
				:required="true"
				:name="'Email'"
				:placeholder="placeholderText"
				:type="'email'"
			/>
		</div>
		<div class="sk-modal-actions">
			<button @click="close" class="button button-text">
				Close
			</button>
			<button
				type="button"
				class="button button-success"
				@click="sendReceipt"
			>
				<i class="sk-icon-check-regular"></i>
				Send Receipt
			</button>
		</div>
	</SkBaseModal>
</template>

<script>
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import SkInput from "@/components/SkInput.vue"
import utils from "@/utils/utils"

export default {
	name: "SendReceiptModal",
	mixins: [skModalMixin],
	components: {
		SkBaseModal,
		SkInput
	},
	data() {
		return {
			email: "",
			emailRegex: utils.emailRegex
		}
	},
	props: {
		type: {
			type: Number,
			default: 0
		}
	},
	computed: {
		orderId() {
			return this.currentOrder ? this.currentOrder.id : ""
		},
		currentOrder() {
			return this.$store.state.ordersModule.currentOrder
		},
		placeholderText() {
			return !this.currentOrder || this.currentOrder.email == ""
				? "Email"
				: this.currentOrder.email
		}
	},
	methods: {
		sendReceipt() {
			const validEmail = this.$refs.emailInput.validate()
			if (validEmail) {
				this.$store.dispatch("ordersModule/sendReceipt", [
					this.email,
					this.$store.state.ordersModule.currentOrder.id
				])
				this.$store.dispatch(
					"ordersModule/orderStatusUpdate",
					this.$store.state.ordersModule.currentOrder.id
				)
				this.email = ""
				this.close()
			}
		}
	},
	watch: {
		currentOrder: function(newVal) {
			if (newVal) {
				this.email = this.type == 0 ? newVal.email : newVal.user.email
			}
		}
	}
}
</script>