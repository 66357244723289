var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showSidebar)?_c('div',{staticClass:"sidebar-wrapper",class:{
		'sidebar-content-expanded': _vm.isExpanded,
		'sidebar-wrapper-hovering': _vm.hovering
	}},[_c('div',{staticClass:"sidebar-header"},[_vm._m(0),_c('span',{staticClass:"sk-icon-ellipsis-v-regular",on:{"click":_vm.toggleSidebar}})]),_c('div',{ref:"sidebarContent",staticClass:"sidebar-content"},[_vm._m(1),_c('SideBarActivityFeed',{ref:"activityFeed",attrs:{"isExpanded":_vm.expandedActivityFeed},on:{"toggled":_vm.overflowMenuToggled,"resetHover":_vm.resetHover}}),_c('ul',{staticClass:"sidebar-menu dark-scrollbar"},[_c('li',{staticClass:"sidebar-item",class:{
					'sidebar-item-active':
						_vm.menuID == _vm.sidebarMenuIds.DASHBOARD
				},attrs:{"aria-haspopup":"true"},on:{"click":_vm.closeSidebar}},[_c('router-link',{attrs:{"to":"/dashboard"}},[_c('i',{staticClass:"sk sk-icon-home-lg-alt-solid"}),_c('span',{staticClass:"sidebar-item-text"},[_vm._v("Dashboard")])])],1),(_vm.insightsAllowed)?_c('li',{staticClass:"sidebar-item",class:{
					'sidebar-item-active':
						_vm.menuID == _vm.sidebarMenuIds.INSIGHTS
				},attrs:{"aria-haspopup":"true"},on:{"click":_vm.closeSidebar}},[_c('router-link',{attrs:{"to":"/insights"}},[_c('i',{staticClass:"sk sk-icon-analytics-solid"}),_c('span',{staticClass:"sidebar-item-text"},[_vm._v("Insights")])])],1):_vm._e(),_c('li',{staticClass:"sidebar-item",class:{
					'sidebar-item-active':
						_vm.menuID == _vm.sidebarMenuIds.ACTIVE_ORDERS
				},attrs:{"aria-haspopup":"true"},on:{"click":_vm.closeSidebar}},[_c('router-link',{attrs:{"to":"/orders"}},[_c('i',{staticClass:"sk sk-icon-receipt-solid"}),_c('span',{staticClass:"sidebar-item-text"},[_vm._v("Orders")])])],1),(_vm.businessManagementAllowed)?_c('li',{staticClass:"sidebar-item",class:{
					'sidebar-item-active': _vm.menuID == _vm.sidebarMenuIds.BUSINESS
				},attrs:{"aria-haspopup":"true"},on:{"click":_vm.closeSidebar}},[_c('router-link',{attrs:{"to":_vm.businessLink}},[_c('i',{staticClass:"sk sk-icon-building-solid"}),_c('span',{staticClass:"sidebar-item-text"},[_vm._v(" "+_vm._s(_vm.businessText)+" ")])])],1):_vm._e(),(_vm.restaurantManagementAllowed)?_c('li',{staticClass:"sidebar-item",class:{
					'sidebar-item-active':
						_vm.menuID == _vm.sidebarMenuIds.RESTAURANT
				},attrs:{"aria-haspopup":"true"},on:{"click":_vm.closeSidebar}},[_c('router-link',{attrs:{"to":_vm.restaurantLink}},[_c('i',{staticClass:"sk sk-icon-utensils-solid"}),_c('span',{staticClass:"sidebar-item-text"},[_vm._v(" "+_vm._s(_vm.restaurantText)+" ")])])],1):_vm._e(),_c('li',{staticClass:"sidebar-item",class:{
					'sidebar-item-active': _vm.menuID == _vm.sidebarMenuIds.EVENTS
				},attrs:{"aria-haspopup":"true"},on:{"click":_vm.closeSidebar}},[_c('router-link',{attrs:{"to":"/events"}},[_c('i',{staticClass:"sk sk-icon-calendar-alt-regular"}),_c('span',{staticClass:"sidebar-item-text"},[_vm._v("Events")])])],1),(_vm.managePromotionsAllowed)?_c('li',{staticClass:"sidebar-item",class:{
					'sidebar-item-active':
						_vm.menuID == _vm.sidebarMenuIds.PROMOTIONS
				},attrs:{"aria-haspopup":"true"},on:{"click":_vm.closeSidebar}},[_c('router-link',{attrs:{"to":"/promotions"}},[_c('i',{staticClass:"sk sk-icon-tag-solid"}),_c('span',{staticClass:"sidebar-item-text"},[_vm._v("Promotions")])])],1):_vm._e(),(_vm.prepackagesAllowed)?_c('li',{staticClass:"sidebar-item",class:{
					'sidebar-item-active':
						_vm.menuID == _vm.sidebarMenuIds.PREPACKAGES
				},attrs:{"aria-haspopup":"true"},on:{"click":_vm.closeSidebar}},[_c('router-link',{attrs:{"to":"/catering"}},[_c('i',{staticClass:"sk sk-icon-sandwich-solid"}),_c('span',{staticClass:"sidebar-item-text"},[_vm._v("Catering")])])],1):_vm._e(),(_vm.salesToolPageAllowed)?_c('li',{staticClass:"sidebar-item",class:{
					'sidebar-item-active': _vm.menuID == _vm.sidebarMenuIds.SALES
				},attrs:{"aria-haspopup":"true"},on:{"click":_vm.closeSidebar}},[_c('router-link',{attrs:{"to":"/sales"}},[_c('i',{staticClass:"sk sk-icon-map-marker-alt-solid1"}),_c('span',{staticClass:"sidebar-item-text"},[_vm._v("Check Location")])])],1):_vm._e(),(_vm.hubsManagementAllowed)?_c('li',{staticClass:"sidebar-item",class:{
					'sidebar-item-active': _vm.menuID == _vm.sidebarMenuIds.HUBS
				},attrs:{"aria-haspopup":"true"},on:{"click":_vm.closeSidebar}},[_c('router-link',{attrs:{"to":"/hubs"}},[_c('i',{staticClass:"sk sk-icon-globe-americas-solid"}),_c('span',{staticClass:"sidebar-item-text"},[_vm._v("Hubs")])])],1):_vm._e(),(_vm.financeManagementAllowed)?_c('li',{staticClass:"sidebar-item",class:{
					'sidebar-item-active': _vm.menuID == _vm.sidebarMenuIds.FINANCE
				},attrs:{"aria-haspopup":"true"},on:{"click":_vm.closeSidebar}},[_c('router-link',{attrs:{"to":"/finance"}},[_c('i',{staticClass:"sk sk-icon-dollar-sign-regular"}),_c('span',{staticClass:"sidebar-item-text"},[_vm._v("Finance")])])],1):_vm._e(),(_vm.userPageAllowed)?_c('li',{staticClass:"sidebar-item",class:{
					'sidebar-item-active': _vm.menuID == _vm.sidebarMenuIds.USERS
				},attrs:{"aria-haspopup":"true"},on:{"click":_vm.closeSidebar}},[_c('router-link',{attrs:{"to":"/users"}},[_c('i',{staticClass:"sk sk-icon-users-crown-solid"}),_c('span',{staticClass:"sidebar-item-text"},[_vm._v("Users")])])],1):_vm._e(),(_vm.suppliesPageAllowed)?_c('li',{staticClass:"sidebar-item",class:{
					'sidebar-item-active': _vm.menuID == _vm.sidebarMenuIds.SUPPLIES
				},attrs:{"aria-haspopup":"true"},on:{"click":_vm.closeSidebar}},[_c('router-link',{attrs:{"to":"/supplies"}},[_c('i',{staticClass:"sk sk-icon-boxes-solid"}),_c('span',{staticClass:"sidebar-item-text"},[_vm._v("Request Supplies")])])],1):_vm._e()]),_c('div',{staticClass:"sidebar-user-section"},[_c('div',{staticClass:"sidebar-user-picture"},[_vm._v(" "+_vm._s(_vm.userNameInitial)+" ")]),_c('div',{staticClass:"sidebar-user-details"},[_c('div',{staticClass:"sidebar-username text-truncate"},[_vm._v(" "+_vm._s(_vm.username)+" ")]),_c('div',{staticClass:"sidebar-user-subtitle text-truncate"},[_vm._v(" "+_vm._s(_vm.userSubtitle)+" ")])]),_c('SkDropdownSelect',{ref:"userMenu",attrs:{"options":_vm.userMenuOptions,"iconClasses":'sk-icon-chevron-down-regular',"showSelectedOption":false,"position":'top'},on:{"selected":_vm.userMenuActionSelected,"toggled":_vm.overflowMenuToggled}})],1)],1),(_vm.isExpanded)?_c('transition',{attrs:{"name":"fade"}},[_c('div',{staticClass:"sidebar-mobile-backdrop",on:{"click":_vm.toggleSidebar}})]):_vm._e()],1):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"sidebar-logo-mobile",attrs:{"href":"/"}},[_c('img',{attrs:{"alt":"Logo","src":"/images/logo-sidebar-1.png"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"sidebar-logo",attrs:{"href":"/"}},[_c('img',{attrs:{"alt":"Logo","src":"/images/logo-sidebar-1.png"}}),_c('img',{attrs:{"alt":"Logo","src":"/images/logo-sidebar-2.png"}})])}]

export { render, staticRenderFns }