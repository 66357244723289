<template>
	<SkBaseModal :isOpen="isOpen" @close="close" class="sk-modal-alt">
		<div class="sk-modal-alt-title">
			<h1>Clear {{ payoutPeriod }} payout for {{ restaurantName }}?</h1>
		</div>
		<SkInput
			ref="reasonInput"
			:name="'Reason'"
			:multiline="true"
			:required="true"
			v-model="reason"
		/>
		<div class="sk-modal-actions">
			<button @click="close" class="button button-text">
				Close
			</button>
			<button
				type="button"
				class="button button-success"
				@click="clearPayout"
			>
				<i class="sk-icon-check-regular"></i>
				Clear Payout
			</button>
		</div>
	</SkBaseModal>
</template>

<script>
import ActionTypes from '@/store/modules/finance/action-types'
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import SkInput from "@/components/SkInput.vue"
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import moment from "moment"

export default {
	name: "ClearPayoutModal",
	mixins: [skModalMixin],
	components: {
		SkBaseModal,
		SkInput
	},
	data() {
		return {
			payoutId: 0,
			reason: ""
		}
	},
	computed: {
		currentPayout() {
			return this.$store.state.financeModule.restaurantPayouts.find(
				payout => payout.id == this.payoutId
			)
		},
		restaurantName() {
			if (this.currentPayout) {
				return this.currentPayout.restaurant.name
			}
		},
		payoutPeriod() {
			if (this.currentPayout) {
				return (
					moment.utc(this.currentPayout.from_date).format("MMM D") +
					"-" +
					moment.utc(this.currentPayout.to_date).format("MMM D")
				)
			}
		}
	},
	methods: {
		handleOptions(options) {
			if (options) {
				this.payoutId = options.id
				this.reason = ""
			}
		},
		validateAdjustPayoutData() {
			const validReason = this.$refs.reasonInput.validate()
			return validReason
		},
		async clearPayout() {
			if (this.validateAdjustPayoutData()) {
				const success = await this.$store.dispatch(
					`financeModule/${ActionTypes.CLEAR_RESTAURANT_PAYOUT}`,
					{
						id: this.payoutId,
						reason: this.reason
					}
				)
				if (success) {
					if (success) {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: `Successfully cleared payout `
						})
					} else {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: `Error clearing payout . Please try again`,
							type: NotificationType.ERROR
						})
					}
				}
				this.close()
			}
		},
		closed() {
			this.payoutId = 0
			this.reason = ""
		}
	}
}
</script>