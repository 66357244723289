<template>
	<div>
		<SkCalendar @dateChanged="onDateChanged">
			<EventCalendarDay
				slot="calendar-days"
				v-for="day in monthDays"
				:key="day.text"
				:day="day"
				@createEvent="createEvent"
				@actionSelected="actionSelected"
			/>
		</SkCalendar>
	</div>
</template>

<script>
import { BusinessEventType } from "@arikgaisler/utils/enums/businessEventType"
import eventStatusMixin from "@/mixins/events/event-status-mixin"
import moment from "moment"
import SkCalendar from "@/components/SkCalendar.vue"
import EventCalendarDay from "@/components/events/EventCalendarDay.vue"

export default {
	name: "EventCalendar",
	mixins: [eventStatusMixin],
	components: {
		SkCalendar,
		EventCalendarDay
	},
	data: function () {
		return {
			currentMonth: 0,
			currentYear: 0
		}
	},
	computed: {
		events() {
			return this.$store.state.eventsModule.events
		},
		eventsByDate() {
			const dates = {}
			const isDst = moment(
				`${this.currentYear}-${this.currentMonth}`,
				"YYYY-MM"
			).isDST()
			this.events.forEach((event) => {
				let eventDate = null
				let eventTime = null
				let offset = 0

				const businesses = this.$store.getters.businessesMap
				let business = businesses[event.business_id]

				if (business && business.timezone_offset) {
					offset = isDst
						? business.timezone_offset
						: business.timezone_offset - 60
				}

				switch (event.type) {
					case BusinessEventType.POPUP: {
						eventDate = event.start_date
						eventTime = event.start_time
						break
					}
					case BusinessEventType.VIRTUAL: {
						eventDate = event.end_date
						eventTime = event.end_time
						break
					}
				}
				const fullDate = moment
					.utc(`${eventDate} ${eventTime}`, "YYYY-MM-DD HH:mm:ss")
					.add(offset, "m")
				const date = fullDate.format("YYYY-MM-DD")
				if (!dates[date]) {
					dates[date] = []
				}
				dates[date].push(event)
			})
			return dates
		},
		monthDays() {
			const daysInMonth = moment(
				`${this.currentYear}-${this.currentMonth}`,
				"YYYY-MM"
			).daysInMonth()
			const days = []
			for (let i = 1; i <= daysInMonth; i++) {
				let date = moment(
					`${this.currentYear}-${this.currentMonth}-${i}`,
					"YYYY-MM-DD"
				).format("YYYY-MM-DD")
				let events = this.eventsByDate[date]
					? this.eventsByDate[date]
					: []
				days.push({
					text: `${i}`,
					events: events,
					date: date
				})
			}
			return days
		}
	},
	methods: {
		updateEventStatus(event, businessName, status) {
			this.$emit("updateEventStatus", event, businessName, status)
		},
		updateEventFulfillmentStatus(event, businessName, status) {
			this.$emit(
				"updateEventFulfillmentStatus",
				event,
				businessName,
				status
			)
		},
		editEvent(event) {
			this.$emit("edit", event)
		},
		deleteEvent(event, businessName) {
			this.$emit("deleteEvent", event, businessName)
		},
		duplicateEvent(event) {
			this.$emit("duplicateEvent", event)
		},
		endEvent(event, businessName) {
			this.$emit("endEvent", event, businessName)
		},
		onDateChanged(date) {
			this.currentMonth = date.month
			this.currentYear = date.year
			this.emitDateRange()
		},
		emitDateRange() {
			const currentDate = moment(
				`${this.currentYear}-${this.currentMonth}`,
				"YYYY-MM"
			)
			const currentMonth = currentDate.format("MM")
			const endingMonth = currentDate
				.endOf("month")
				.add(1, "day")
				.format("MM")
			const endingYear =
				currentMonth == "12" ? this.currentYear + 1 : this.currentYear

			this.$emit("filterByDateRange", {
				from: `${this.currentYear}-${currentMonth}-01`,
				to: `${endingYear}-${endingMonth}-01`
			})
		},
		createEvent(date) {
			this.$emit("createEvent", date)
		}
	}
}
</script>