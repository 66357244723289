<template>
	<div class="sk-table-row">
		<div
			class="sk-table-column-checkbox"
			@click="batchSelectClicked($event)"
		>
			<SkCheckbox class="sk-checkbox-green" :checked="batchSelected" />
		</div>
		<div class="sk-table-column-fit">
			<span :class="refundStatusClass"></span>
			{{ refundStatusText }}
		</div>
		<div class="sk-table-column-fit">
			{{ refund.order.id }}
		</div>
		<div class="sk-table-column-fit">
			{{ created_date }}
		</div>
		<div>{{ refund.order.user.name }}</div>
		<div>${{ refund.amount }}</div>
		<div class="refund-reason text-truncate" :title="refund.reason">
			{{ refund.reason }}
		</div>
		<div>
			{{ refund.created_by.display_name }}
		</div>
		<div class="sk-table-column-fit">
			<div class="sk-table-actions">
				<button
					class="button button-icon"
					title="Approve"
					@click="approveRefund"
				>
					<i class="sk-icon-check-regular"></i>
				</button>
				<SkDropdownSelect
					:options="allowedActions"
					:iconClasses="'sk-icon-ellipsis-v-regular'"
					:showSelectedOption="false"
					:position="'left'"
					@selected="actionSelected"
				/>
			</div>
		</div>
	</div>
</template>

<style scoped>
.pill i {
	margin-right: 10px;
}
.sk-table-body .sk-table-row > .menu-schedules {
	white-space: normal;
}
.menu-schedules {
	padding: 20px 0;
}
.menu-schedules > div {
	display: inline-block;
	margin-right: 20px;
}

.refund-reason {
	max-width: 300px;
}

.order-pill {
	max-width: 1000px !important;
}
</style>

<script>
import moment from "moment"
import batchSelectableMixin from "@/mixins/batch-selectable-mixin"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"
import SkCheckbox from "@/components/SkCheckbox.vue"

export default {
	name: "RefundLineItem",
	mixins: [batchSelectableMixin],
	components: {
		SkCheckbox,
		SkDropdownSelect
	},
	props: {
		refund: Object
	},
	computed: {
		refundStatusClass() {
			return "status-dot yellow"
		},
		refundStatusText() {
			return "Pending"
		},
		created_date() {
			return moment
				.utc(this.refund.created_date)
				.format("MMMM Do YYYY, h:mm a")
		},
		allowedActions() {
			return [
				{
					text: `<i class="sk-icon-times-regular"></i> Reject`,
					id: 0
				},
				{
					text: `<i class="sk-icon-pencil-solid"></i> Edit`,
					id: 1
				},
				{
					text: `<i class="sk-icon-eye-solid"></i> View Order`,
					id: 2
				}
			]
		}
	},
	methods: {
		approveRefund() {
			this.$emit("setRefundStatus", {
				refund: this.refund,
				approve: true
			})
		},
		rejectRefund() {
			this.$emit("setRefundStatus", {
				refund: this.refund,
				approve: false
			})
		},
		editRefund() {
			this.$emit("editRefund", this.refund)
		},
		openOrderDetails() {
			this.$emit("openOrderDetailsModal", this.refund.order.id)
		},
		actionSelected(action) {
			switch (action.id) {
				case 0: {
					this.rejectRefund()
					break
				}
				case 1: {
					this.editRefund()
					break
				}
				case 2: {
					this.openOrderDetails()
					break
				}
			}
		}
	}
}
</script>