import { MURType } from "@/enums/murType"

let restaurantCommissionMixin = {
    computed: {
        prePackagedCommission: {
            get: function () {
                var retVal = 0
                if (
                    this.restaurant &&
                    this.restaurant != null &&
                    this.restaurant.commissions != null &&
                    this.restaurant.commissions.length > 0
                ) {
                    const relCom = this.restaurant.commissions.find(
                        e => e.type == MURType.PRE_PACKAGED
                    )
                    if (relCom != undefined) {
                        retVal = relCom.commission
                    }
                }
                return retVal
            },
            set: function (newVal) {
                if (
                    this.restaurant &&
                    this.restaurant != null &&
                    this.restaurant.commissions != null &&
                    this.restaurant.commissions.length > 0
                ) {
                    let currentCom = this.restaurant.commissions.find(
                        e => e.type == MURType.PRE_PACKAGED
                    )
                    if (currentCom != undefined) {
                        currentCom.commission = newVal
                    } else {
                        this.restaurant.commissions.push({
                            commission: newVal,
                            type: MURType.PRE_PACKAGED
                        })
                    }
                } else {
                    this.restaurant.commissions = []
                    this.restaurant.commissions.push({
                        commission: newVal,
                        type: MURType.PRE_PACKAGED
                    })
                }
            }
        },
        toGoCommission: {
            get: function () {
                var retVal = 0
                if (
                    this.restaurant &&
                    this.restaurant != null &&
                    this.restaurant.commissions != null &&
                    this.restaurant.commissions.length > 0
                ) {
                    const relCom = this.restaurant.commissions.find(
                        e => e.type == MURType.TO_GO
                    )
                    if (relCom != undefined) {
                        retVal = relCom.commission
                    }
                }
                return retVal
            },
            set: function (newVal) {
                if (
                    this.restaurant &&
                    this.restaurant != null &&
                    this.restaurant.commissions != null &&
                    this.restaurant.commissions.length > 0
                ) {
                    let currentCom = this.restaurant.commissions.find(
                        e => e.type == MURType.TO_GO
                    )
                    if (currentCom != undefined) {
                        currentCom.commission = newVal
                    } else {
                        this.restaurant.commissions.push({
                            commission: newVal,
                            type: MURType.TO_GO
                        })
                    }
                } else {
                    this.restaurant.commissions = []
                    this.restaurant.commissions.push({
                        commission: newVal,
                        type: MURType.TO_GO
                    })
                }
            }
        },
        eventCommission: {
            get: function () {
                var retVal = 8
                if (
                    this.restaurant &&
                    this.restaurant != null &&
                    this.restaurant.commissions != null &&
                    this.restaurant.commissions.length > 0
                ) {
                    const relCom = this.restaurant.commissions.find(
                        e => e.type == MURType.EVENT
                    )
                    if (relCom != undefined) {
                        retVal = relCom.commission
                    }
                }
                return retVal
            },
            set: function (newVal) {
                if (
                    this.restaurant &&
                    this.restaurant != null &&
                    this.restaurant.commissions != null &&
                    this.restaurant.commissions.length > 0
                ) {
                    let currentCom = this.restaurant.commissions.find(
                        e => e.type == MURType.EVENT
                    )
                    if (currentCom != undefined) {
                        currentCom.commission = newVal
                    } else {
                        this.restaurant.commissions.push({
                            commission: newVal,
                            type: MURType.EVENT
                        })
                    }
                } else {
                    this.restaurant.commissions = []
                    this.restaurant.commissions.push({
                        commission: newVal,
                        type: MURType.EVENT
                    })
                }
            }
        },
        courierCommission: {
            get: function () {
                var retVal = 25
                if (
                    this.restaurant &&
                    this.restaurant != null &&
                    this.restaurant.commissions != null &&
                    this.restaurant.commissions.length > 0
                ) {
                    const relCom = this.restaurant.commissions.find(
                        e => e.type == MURType.COURIER_DELIVERY
                    )
                    if (relCom != undefined) {
                        retVal = relCom.commission
                    }
                }
                return retVal
            },
            set: function (newVal) {
                if (
                    this.restaurant &&
                    this.restaurant != null &&
                    this.restaurant.commissions != null &&
                    this.restaurant.commissions.length > 0
                ) {
                    let currentCom = this.restaurant.commissions.find(
                        e => e.type == MURType.COURIER_DELIVERY
                    )
                    if (currentCom != undefined) {
                        currentCom.commission = newVal
                    } else {
                        this.restaurant.commissions.push({
                            commission: newVal,
                            type: MURType.COURIER_DELIVERY
                        })
                    }
                } else {
                    this.restaurant.commissions = []
                    this.restaurant.commissions.push({
                        commission: newVal,
                        type: MURType.COURIER_DELIVERY
                    })
                }
            }
        },
        selfDeliveryCommission: {
            get: function () {
                var retVal = 12
                if (
                    this.restaurant &&
                    this.restaurant != null &&
                    this.restaurant.commissions != null &&
                    this.restaurant.commissions.length > 0
                ) {
                    const relCom = this.restaurant.commissions.find(
                        e => e.type == MURType.SELF_DELIVERY
                    )
                    if (relCom != undefined) {
                        retVal = relCom.commission
                    }
                }
                return retVal
            },
            set: function (newVal) {
                if (
                    this.restaurant &&
                    this.restaurant != null &&
                    this.restaurant.commissions != null &&
                    this.restaurant.commissions.length > 0
                ) {
                    let currentCom = this.restaurant.commissions.find(
                        e => e.type == MURType.SELF_DELIVERY
                    )
                    if (currentCom != undefined) {
                        currentCom.commission = newVal
                    } else {
                        this.restaurant.commissions.push({
                            commission: newVal,
                            type: MURType.SELF_DELIVERY
                        })
                    }
                } else {
                    this.restaurant.commissions = []
                    this.restaurant.commissions.push({
                        commission: newVal,
                        type: MURType.SELF_DELIVERY
                    })
                }
            }
        }
    }
}

export default restaurantCommissionMixin