<template>
	<div class="sk-row sk-widget" @click="headerClicked">
		<div class="pill pill-grey" v-if="isPreorder">
			<i class="sk-icon-calendar-alt-solid"></i>
			{{ suborderDate }}
		</div>
		<div class="pill pill-grey">
			<i class="sk-icon-clock-regular-1"></i>
			{{ suborderTime }}
		</div>
		<router-link
			class="pill pill-grey"
			target="_blank"
			:to="`/restaurant/${restaurantId}`"
		>
			<i class="sk-icon-utensils-solid"></i>
			{{ restaurant.name }}
		</router-link>
		<div class="pill pill-grey suborder-id">
			<b>Id:</b>
			{{ suborder.id }}
		</div>
		<div class="suborder-count">
			<span>{{ index + 1 }}</span>
			of
			<span>{{ totalSuborders }}</span>
		</div>
		<div class="toggle-indicator">
			<i
				class="sk-icon-chevron-down-regular"
				:class="{ flipped: expanded }"
			></i>
		</div>
	</div>
</template>

<style scoped>
.sk-widget {
	align-items: center;
	margin: 15px 0 !important;
	display: flex;
}

.pill {
	border-radius: 5px;
}

.pill:not(a) {
	cursor: default;
}

.pill,
.pill i {
	font-size: 10px;
}

.suborder-id b {
	margin-right: 5px;
}

.suborder-count,
.suborder-count span {
	font-size: 12px;
}
.suborder-count {
	color: var(--sk-grey2);
	margin-left: 30px;
}

.suborder-count span {
	color: var(--sk-grey3);
}

.toggle-indicator {
	flex: 1;
	text-align: right;
}

.toggle-indicator i {
	font-size: 10px;
}
</style>

<script>
import orderHelper from "@/utils/order-helper"

export default {
	name: "SuborderHeader",
	props: {
		suborder: Object,
		order: Object,
		business: Object,
		restaurantId: Number,
		expandedSuborder: Object,
		index: Number
	},
	computed: {
		restaurant() {
			return this.$store.state.restaurants[this.restaurantId]
		},
		expanded() {
			return (
				this.expandedSuborder &&
				this.expandedSuborder.id == this.suborder.id
			)
		},
		totalSuborders() {
			return this.order.sub_orders.length
		},
		preorderDate() {
			return orderHelper.preorderDate(
				this.order,
				this.suborder,
				this.business.timezone
			)
		},
		isPreorder() {
			return orderHelper.isPreorder(this.order, this.suborder)
		},
		suborderTime() {
			if (this.isPreorder) {
				return this.preorderDate.format("h:mm A")
			} else {
				return "ASAP"
			}
		},
		suborderDate() {
			if (this.isPreorder) {
				return this.preorderDate.format("MMMM DD")
			} else {
				return null
			}
		}
	},
	methods: {
		headerClicked(e) {
			e.stopPropagation()
			this.$emit("toggleExpand")
		}
	}
}
</script>