<template>
  <div class="sk-table-row">
    <div>
      <span :class="stockClasses"></span> {{stockText}}
    </div>
    <div>{{restaurant.name}}</div>
    <div class="sk-table-column-fit" v-html="restaurantAddress"></div>
    <div class="sk-table-pills">
      <router-link 
        v-for="pairing in businessPairings"
        :key="pairing.id"
        :to="`/business/${pairing.slug}`" 
        tag="a" 
        class="pill pill-grey"
        :title="pairing.name">
        <i class="sk-icon-link-regular"></i> {{pairing.name}}
      </router-link>
    </div>
    <div class="sk-table-pills">
      <RestaurantPOSLineItem v-for="device in devices" :key="device.external_id" :device="device" />
    </div>
    <div class="sk-table-column-fit">
      <div class="sk-table-actions">
        <router-link tag="button" :to="`/restaurant/${restaurant.id}`" class="button button-icon" title="Edit" @click.native="editRestaurantClicked">
          <i class="sk-icon-pencil-solid"></i>
        </router-link>
         <button class="button button-icon" @click="deleteRestaurant" title="Delete" v-if="canDelete">
          <i class="sk-icon-trash-alt-solid"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import RestaurantPOSLineItem from "@/components/restaurant/RestaurantPOSLineItem.vue"
  import { PermissionMethod } from "@/utils/permissions"
import tracker from "@/utils/tracker"

  export default {
    name: "RestaurantLineItem",
    components: {
      RestaurantPOSLineItem
    },
    props: {
      restaurant: {
        type: Object,
        default: null
      },
      pairings: {
        type: Object,
        default: null
      }
    },
    computed: {
      businessPairings() {
        return this.pairings && this.pairings.businesses ? this.pairings.businesses : []
      },
      restaurantAddress() {
        let hasAddress = this.restaurant.address && this.restaurant.city && this.restaurant.state && this.restaurant.zip
        return hasAddress ? `<div class="column-title">${this.restaurant.address}</div><div class="column-subtitle">${this.restaurant.city}, ${this.restaurant.state}</div>` : "N/A"
      },
      stockClasses() {
        return this.restaurant.stock_status == 1 ? "status-dot green" : "status-dot red"
      },
      stockText() {
        return this.restaurant.stock_status == 1 ? "In Stock" : "Out of Stock"
      },
      devices() {
        return this.restaurant.devices ? this.restaurant.devices : []
      },
      tabletBattery() {
        const level = this.hasKiosk ? `${this.business.kiosks[0].battery_status}%` : "N/A"
        return `<span class="kiosk-battery"><i class="sk-icon-battery-quarter-regular"></i> Battery: ${level}</span>`
      },
      canDelete() {
        return this.$store.state.permissions.includes(PermissionMethod.DELETE_RESTAURANT)
      },
    },
    methods: {
      deleteRestaurant() {
        tracker.trackEvent("Delete Restaurant Clicked")
        this.$emit("delete", this.restaurant)
      },
      editRestaurantClicked() {
        tracker.trackEvent("Edit Restaurant Clicked")
      }
    }
  }
</script>