<template>
	<div>
		<div class="sk-table sk-table-default">
			<div class="sk-table-header">
				<div class="sk-table-row">
					<div>Name</div>
					<div>Type</div>
					<div>Description</div>
					<div>Courier Instructions</div>
					<div>Customer Instructions</div>
					<div>Actions</div>
				</div>
			</div>
			<transition-group
				name="list"
				tag="div"
				class="sk-table-body"
				appear
			>
				<BusinessDropoffLocationsLineItem
					v-for="dropoffLocation in pagedDropoffLocations"
					:dropoffLocation="dropoffLocation"
					:key="dropoffLocation.id"
					@delete="deleteDropoffLocation"
					@update="openDropoffLocationModal"
				/>
			</transition-group>
		</div>
		<ConfirmationModal ref="confirmationModal" />
		<DropoffLocationModal ref="dropoffLocationModal" />
	</div>
</template>

<script>
import tracker from "@/utils/tracker"
import PageHeader from "@/components/PageHeader.vue"
import BusinessDropoffLocationsLineItem from "@/components/business/BusinessDropoffLocationsLineItem.vue"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue"
import DropoffLocationModal from "@/components/modals/DropoffLocationModal.vue"
import { ConfirmationModalType } from "@/enums/confirmationModalType"
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"

export default {
	name: "BusinessDropoffLocations",
	components: {
		BusinessDropoffLocationsLineItem,
		PageHeader,
		ConfirmationModal,
		DropoffLocationModal
	},
	data() {
		return {
			pageRange: {
				page: 1,
				start: 0,
				end: 20
			}
		}
	},
	computed: {
		loading() {
			return this.$store.state.loading
		},
		dropoffLocations() {
			return this.$store.state.businessesModule.currentBusiness
				.locations[0].dropoff_locations
		},
		pagedDropoffLocations() {
			return this.dropoffLocations.slice(
				this.pageRange.start,
				this.pageRange.end
			)
		}
	},
	methods: {
		async deleteDropoffLocation(dropoffLocation) {
			const confirmed = await this.$refs.confirmationModal.open({
				title: `Are you sure you want to delete ${dropoffLocation.text}?`,
				confirmation: "Delete Location",
				confirmationIcon: "sk-icon-trash-alt-solid",
				cancel: "Keep Location",
				type: ConfirmationModalType.RED
			})
			if (confirmed) {
				tracker.trackEvent("Delete Location Confirmed")
				const success = await this.$store.dispatch(
					"businessesModule/deleteDropoffLocation",
					dropoffLocation.id
				)
				if (success) {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: `${dropoffLocation.text} deleted successfully`
					})
				} else {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: `Failed to delete ${dropoffLocation.text}`,
						type: NotificationType.ERROR
					})
				}
			}
		},
		async openDropoffLocationModal(dropoffLocation) {
			this.$refs.dropoffLocationModal.open({
				dropoffLocation: dropoffLocation
			})
		}
	},
	watch: {
		loading(newVal) {
			this.toggleLoader(newVal)
		}
	}
}
</script>
