<template>
	<div class="sk-table-row">
		<div>
			{{ user.display_name }}
		</div>
		<div>
			{{ user.email }}
		</div>
		<div>
			{{ role }}
		</div>
		<div class="sk-table-pills">
			<router-link
				v-for="business in businesses"
				:key="business.id"
				:to="business.link"
				tag="a"
				class="pill pill-grey"
				:title="business.name"
			>
				<i class="sk-icon-link-regular"></i>
				{{ business.name }}
			</router-link>
		</div>
		<div>
			<div :title="statusLabel" :class="statusBadgeClasses">
				<span :class="statusClasses"></span>
				{{ statusLabel }}
				<span v-if="statusLastSeen" class="last-seen">
					{{ statusLastSeen }}
				</span>
			</div>
		</div>
		<div class="sk-table-column-fit">
			<div class="sk-table-actions">
				<button
					class="button button-icon"
					title="Edit"
					@click="editChefUser"
				>
					<i class="sk-icon-pencil-solid"></i>
				</button>
				<SkDropdownSelect
					:options="allowedActions"
					:iconClasses="'sk-icon-ellipsis-v-regular'"
					:showSelectedOption="false"
					:position="'left'"
					@selected="actionSelected"
				/>
			</div>
		</div>
	</div>
</template>

<style scoped>
.pill i {
	margin-right: 10px;
}

.status-pill.pill-alt {
	max-width: 100%;
}

.last-seen {
	font-weight: 400;
	margin-left: 10px;
}
</style>

<script>
import moment from "moment"
import { UserSubRole } from "@/enums/userRole"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"

export default {
	name: "ChefUserLineItem",
	components: {
		SkDropdownSelect
	},
	props: {
		user: Object
	},
	computed: {
		statusBadgeClasses() {
			return this.user.is_active == 1
				? "status-pill pill-alt pill-alt-green"
				: "status-pill pill-alt pill-alt-grey"
		},
		statusLabel() {
			return this.user.is_active == 1 ? "Active" : "Inactive"
		},
		statusLastSeen() {
			if (this.user.last_login_date) {
				return moment(this.user.last_login_date).fromNow()
			}
			return null
		},
		statusClasses() {
			return this.user.is_active == 1
				? "status-dot green"
				: "status-dot grey"
		},
		role() {
			switch (this.user.sub_role_id) {
				case UserSubRole.ADMIN: {
					return "Admin"
				}
				case UserSubRole.STAFF: {
					return "Staff"
				}
			}
			return "N/A"
		},
		businesses() {
			let businesses = []
			if (this.user.entities && this.user.entities.length > 0) {
				const businessEntities = this.user.entities.filter(
					(entity) => entity.type == 1
				)
				businesses = businessEntities
					.filter(
						(entity) =>
							this.$store.getters.businessesMap[entity.id] !=
							undefined
					)
					.map((entity) => {
						let business = this.$store.getters.businessesMap[
							entity.id
						]
						let link = "/business/" + business.slug
						return {
							id: business.id,
							name: business.name,
							link: link
						}
					})
			}
			return businesses
		},
		allowedActions() {
			return [
				{
					text: `<i class="sk-icon-check-regular"></i> Resend Activation Email`,
					id: 0
				},
				{
					text: `<i class="sk-icon-envelope-regular"></i> Send Password Reset Email`,
					id: 2
				},
				{
					text: `<i class="sk-icon-trash-alt-solid"></i> Delete`,
					id: 1
				}
			]
		}
	},
	methods: {
		editChefUser() {
			this.$emit("editChefUserClicked", this.user)
		},
		async actionSelected(action) {
			switch (action.id) {
				case 0:
					this.$emit("resendActivationLinkClicked", this.user)
					break
				case 1:
					this.$emit("deleteAdminUserClicked", this.user)
					break
			}
		}
	}
}
</script>