var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SkBaseModal',{attrs:{"isOpen":_vm.isOpen,"showCloseButton":false,"hasLoader":true},on:{"close":_vm.close}},[_c('loading',{attrs:{"active":_vm.loading,"is-full-page":false},on:{"update:active":function($event){_vm.loading=$event}}}),_c('div',{staticClass:"sk-modal-alt-title"},[_c('h1',[_vm._v("Location")])]),_vm._l((_vm.locationSearches),function(location,i){return _c('div',{key:i},[_c('div',{staticClass:"sk-row"},[_c('div',{staticClass:"sk-widget-alt",class:{
					'entity-selected':
						location.type == _vm.entityTypes.BUSINESS,
					disabled: !_vm.canAddLocations
				}},[_c('div',{staticClass:"sk-radio-group"},[_c('sk-radio',{attrs:{"option":_vm.entityTypes.BUSINESS},model:{value:(location.type),callback:function ($$v) {_vm.$set(location, "type", $$v)},expression:"location.type"}}),_c('span',[_vm._v("Business")])],1)]),_c('div',{staticClass:"sk-widget-alt",class:{
					'entity-selected':
						location.type == _vm.entityTypes.RESTAURANT,
					disabled: !_vm.canAddLocations
				}},[_c('div',{staticClass:"sk-radio-group"},[_c('sk-radio',{attrs:{"option":_vm.entityTypes.RESTAURANT},model:{value:(location.type),callback:function ($$v) {_vm.$set(location, "type", $$v)},expression:"location.type"}}),_c('span',[_vm._v("Restaurant")])],1)])]),_c('div',{staticClass:"sk-row"},[_c('AddressTypeaheadInput',{ref:"addressInput",refInFor:true,attrs:{"placesFields":['address_components', 'geometry.location']},model:{value:(location.address),callback:function ($$v) {_vm.$set(location, "address", $$v)},expression:"location.address"}})],1)])}),_c('div',{staticClass:"sk-modal-actions"},[_c('button',{staticClass:"button button-text",on:{"click":_vm.clearLocation}},[_vm._v(" Clear All ")]),_c('button',{staticClass:"button button-primary",on:{"click":_vm.searchLocation}},[_vm._v(" Search ")])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }