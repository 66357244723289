<template>
	<div class="sk-page-content">
		<audio ref="newOrderSound">
			<source :src="notificationUrl" />
		</audio>
		<PageHeader
			ref="pageHeader"
			:title="'Orders'"
			:actions="actions"
			:tabs="tabs"
			:filters="filters"
			:filterGroups="filterGroups"
			@actionClicked="headerActionClicked"
			@tabClicked="headerTabClicked"
			@filterSet="filterSet"
			@filtersSet="filtersSet"
			@allFiltersReset="allFiltersReset"
		/>
		<transition name="slide" mode="out-in">
			<ActiveOrders
				:key="activeTabIds.ACTIVE_ORDERS"
				:filterVals="filterVals"
				v-if="activeTabId == activeTabIds.ACTIVE_ORDERS"
				ref="activeOrders"
			/>
			<UpcomingOrders
				:key="activeTabIds.UPCOMING_ORDERS"
				:filterVals="filterVals"
				v-if="activeTabId == activeTabIds.UPCOMING_ORDERS"
				ref="upcomingOrders"
			/>
			<AdminActiveOrders
				:key="activeTabIds.ADMIN_ACTIVE_ORDERS"
				:filterVals="filterVals"
				:pusher="pusher"
				v-if="
					activeTabId == activeTabIds.ADMIN_ACTIVE_ORDERS ||
					activeTabId == activeTabIds.ADMIN_UPCOMING_ORDERS
				"
				ref="adminActiveOrders"
				:filterUpcomingOrders="
					activeTabId == activeTabIds.ADMIN_UPCOMING_ORDERS
				"
				@repushOrder="repushOrder"
			/>
			<OrderReports
				:key="activeTabIds.HISTORY"
				:filterVals="filterVals"
				v-if="activeTabId == activeTabIds.HISTORY"
				ref="orderReports"
				@repushOrder="repushOrder"
			/>
		</transition>
		<ConfirmationModal ref="confirmationModal" />
	</div>
</template>

<script>
import moment from "moment"
import utils from "@/utils/utils"
import { PusherEvent } from "@/enums/pusherEvent"
import { PermissionTab } from "@/utils/permissions"
import { BusinessType } from "@arikgaisler/utils/enums/businessType"
import { MenuType } from "@arikgaisler/utils/enums/menuType"
import { ScoreComparisonType } from "@/enums/scoreComparisonType"
import { OrderStatusFilter } from "@/enums/orderStatusFilter"
import { OrderWarningType } from "@/enums/orderWarningType"
import { CourierId } from "@arikgaisler/utils/enums/courierId"
import { ConfirmationModalType } from "@/enums/confirmationModalType"
import { NotificationType } from "@/enums/notificationType"
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { PaymentType } from "@arikgaisler/utils/enums/paymentType"
import { OrderTransactionStatus } from "@/enums/orderTransactionStatus"
import orderHelper from "@/utils/order-helper"
import pusherMixin from "@/mixins/pusher-mixin"
import loaderMixin from "@/mixins/loader-mixin"
import businessTypeFormatterMixin from "@/mixins/business/business-type-formatter-mixin"
import menuTypeFormatterMixin from "@/mixins/menu-type-formatter-mixin"
import PageHeader from "@/components/PageHeader.vue"
import ActiveOrders from "@/components/currentorders/ActiveOrders.vue"
import UpcomingOrders from "@/components/currentorders/UpcomingOrders.vue"
import AdminActiveOrders from "@/components/currentorders/AdminActiveOrders.vue"
import OrderReports from "@/components/orderreport/OrderReports.vue"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue"
import ActionTypes from "@/store/modules/orders/action-types"

const CurrentOrdersTabId = {
	ACTIVE_ORDERS: 0,
	UPCOMING_ORDERS: 1,
	ADMIN_ACTIVE_ORDERS: 2,
	ADMIN_UPCOMING_ORDERS: 3,
	HISTORY: 4
}

const CurrentOrdersFilterId = {
	DATE_RANGE: 0,
	USER_NAME: 1,
	USER_PHONE: 2,
	ORDER_ID: 3,
	LOCAL_ORDER_ID: 4,
	BUSINESS: 5,
	RESTAURANT: 6,
	CLAIMED_STATUS: 7,
	ORDER_STATUS: 8,
	ORDER_STATUS_HISTORY: 9,
	BUSINESS_TYPE: 10,
	ORDER_TYPE: 11,
	ADVANCED_COUPONS: 12,
	ADVANCED_REFUNDS: 13,
	ADVANCED_EXCEPTIONS: 14,
	ADVANCED_USER_NAME: 15,
	ADVANCED_USER_PHONE: 16,
	ADVANCED_USER_EMAIL: 17,
	ADVANCED_NPS_RANGE: 18,
	ADVANCED_NPS_SCORE: 19,
	ADVANCED_WARNINGS: 20,
	ADVANCED_COURIER: 21,
	ADVANCED_LABELS: 22,
	ADVANCED_PAYMENT_TYPES: 23,
	ADVANCED_PAYMENT_STATUSES: 24
}

export default {
	name: "CurrentOrders",
	mixins: [
		pusherMixin,
		loaderMixin,
		businessTypeFormatterMixin,
		menuTypeFormatterMixin
	],
	components: {
		PageHeader,
		ActiveOrders,
		UpcomingOrders,
		AdminActiveOrders,
		OrderReports,
		ConfirmationModal
	},
	data() {
		return {
			activeTabId: CurrentOrdersTabId.ACTIVE_ORDERS,
			activeTabIds: CurrentOrdersTabId,
			filterVals: {
				claimStatus: -1,
				phone: null,
				username: null,
				startDate: null,
				endDate: null,
				orderId: null,
				businessId: 0,
				restaurantId: 0,
				orderStatus: null,
				businessType: null,
				orderType: null,
				hasCoupons: null,
				hasRefunds: null,
				hasExceptions: null,
				email: null,
				npsComparisonType: null,
				npsValue: null,
				warningType: null,
				courierId: null,
				orderLabelId: null,
				paymentType: null,
				paymentStatus: null
			},
			notificationUrl: `${this.$chefCDNURL}common/chef-notification-1.mp3`
		}
	},
	created: function () {
		if (
			this.$store.state.permissions.includes(
				PermissionTab.ADMIN_ACTIVE_ORDERS
			)
		) {
			this.subscribeToPusher()
			this.activeTabId = CurrentOrdersTabId.ADMIN_ACTIVE_ORDERS
		} else {
			this.subscribeToPusher()
			this.activeTabId = CurrentOrdersTabId.ACTIVE_ORDERS
		}
	},
	computed: {
		currentOrders() {
			return this.$store.state.ordersModule.currentOrders
				? this.$store.state.ordersModule.currentOrders
				: []
		},
		unassignedActiveOrderCount() {
			return this.currentOrders.filter(
				(order) =>
					!order.admin_user_id && !orderHelper.isUpcomingOrder(order)
			).length
		},
		unassignedUpcomingOrderCount() {
			return this.currentOrders.filter(
				(order) =>
					!order.admin_user_id && orderHelper.isUpcomingOrder(order)
			).length
		},
		tabs() {
			let tabs = []

			if (
				this.$store.state.permissions.includes(
					PermissionTab.ADMIN_ACTIVE_ORDERS
				)
			) {
				tabs = tabs.concat([
					{
						id: CurrentOrdersTabId.ADMIN_ACTIVE_ORDERS,
						text: "Active",
						badgeCount: this.unassignedActiveOrderCount
					},
					{
						id: CurrentOrdersTabId.ADMIN_UPCOMING_ORDERS,
						text: "Upcoming",
						badgeCount: this.unassignedUpcomingOrderCount
					}
				])
			} else {
				tabs = tabs.concat([
					{
						id: CurrentOrdersTabId.ACTIVE_ORDERS,
						text: "Active"
					},
					{
						id: CurrentOrdersTabId.UPCOMING_ORDERS,
						text: "Upcoming"
					}
				])
			}

			tabs.push({
				id: CurrentOrdersTabId.HISTORY,
				text: "History"
			})

			tabs.forEach((tab) => (tab.active = this.activeTabId == tab.id))

			return tabs
		},
		isDateFilterClearable() {
			return Object.keys(this.filterVals).some((filterValKey) => {
				const filterVal = this.filterVals[filterValKey]
				if (
					filterValKey == "businessId" ||
					filterValKey == "restaurantId"
				) {
					return filterVal != 0
				}
				return filterVal != -1 && filterVal != null
			})
		},
		filters() {
			const dateRangeFilter = {
				id: CurrentOrdersFilterId.DATE_RANGE,
				type: "daterange",
				placeholder: "Dates",
				shortcuts: [
					{
						key: "thisWeek",
						label: "This Week",
						value: "isoWeek"
					},
					{
						key: "lastWeek",
						label: "Last Week",
						value: "-isoWeek"
					},
					{
						key: "lastMonth",
						label: "Last Month",
						value: "-month"
					},
					{
						key: "thisMonth",
						label: "This Month",
						value: "month"
					},
					{
						key: "thisQuarter",
						label: "This Quarter",
						value: "quarter"
					}
				],
				clearable: this.isDateFilterClearable
			}
			const usernameFilter = {
				id: CurrentOrdersFilterId.USER_NAME,
				placeholder: "User Name",
				type: "input"
			}
			const userPhoneFilter = {
				id: CurrentOrdersFilterId.USER_PHONE,
				placeholder: "User Phone",
				type: "input"
			}
			const orderIdFilter = {
				id: CurrentOrdersFilterId.ORDER_ID,
				type: "input",
				placeholder: "Order Id",
				width: "160px"
			}
			const localOrderIdFilter = {
				id: CurrentOrdersFilterId.LOCAL_ORDER_ID,
				placeholder: "Order Id",
				type: "search",
				items: this.currentOrders,
				width: "200px",
				serializer: (i) => {
					return { text: i.id, id: i.id }
				}
			}

			const businessFilter = {
				id: CurrentOrdersFilterId.BUSINESS,
				placeholder: "Business",
				type: "search",
				width: "280px",
				fetch: (query) => {
					return this.$store.dispatch(
						"businessesModule/autocompleteBusinesses",
						query
					)
				},
				serializer: (i) => {
					return { text: i.name }
				}
			}
			const restaurantFilter = {
				id: CurrentOrdersFilterId.RESTAURANT,
				type: "search",
				placeholder: "Restaurant",
				width: "280px",
				fetch: (query) => {
					return this.$store.dispatch(
						"restaurantsModule/autocompleteRestaurants",
						query
					)
				},
				serializer: (i) => {
					return { text: i.name }
				}
			}
			const claimedStatusFilter = {
				id: CurrentOrdersFilterId.CLAIMED_STATUS,
				placeholder: "Status",
				type: "dropdown",
				items: [
					{
						text: `All Statuses`,
						id: -1
					},
					{
						text: `Claimed By Me`,
						id: 1
					},
					{
						text: `Needs Supervisor`,
						id: 2
					}
				]
			}
			const orderStatusFilter = {
				id: CurrentOrdersFilterId.ORDER_STATUS,
				placeholder: "Order Status",
				type: "multiselect",
				items: [
					{
						text: `Order Status`,
						id: null
					},
					{
						text: "Awaiting Accept",
						id: OrderStatusFilter.AWAITING_ACCEPT
					},
					{
						text: "Awaiting Courier Acknowledge",
						id: OrderStatusFilter.AWAITING_ACKNOWLEDGE
					},
					{
						text: "Awaiting Pickup",
						id: OrderStatusFilter.AWAITING_PICKUP
					},
					{
						text: "Awaiting Delivery Confirmation",
						id: OrderStatusFilter.AWAITING_DELIVERY_CONFIRMATION
					},
					{
						text: "Delivered",
						id: OrderStatusFilter.DELIVERED
					},
					{
						text: "Cancelled",
						id: OrderStatusFilter.CANCELLED
					}
				]
			}
			const orderStatusHistoryTab = {
				id: CurrentOrdersFilterId.ORDER_STATUS_HISTORY,
				type: "dropdown",
				placeholder: "Status",
				advancedGroupId: 0,
				items: [
					{
						text: `Status`,
						id: null
					},
					{
						text: "New",
						id: 0
					},
					{
						text: "Accepted",
						id: 1
					},
					{
						text: "Picked Up",
						id: 2
					},
					{
						text: "Delivered",
						id: 3
					},
					{
						text: "Cancelled",
						id: 4
					},
					{
						text: "Received",
						id: 5
					}
				]
			}
			const orderTypeOptions = [
				{
					text: `Order Type`,
					id: null
				},
				{
					text: this.$options.filters.menuTypeText(MenuType.DEFAULT),
					id: MenuType.DEFAULT
				},
				{
					text: this.$options.filters.menuTypeText(MenuType.POPUP),
					id: MenuType.POPUP
				},
				{
					text: this.$options.filters.menuTypeText(MenuType.EMBEDDED),
					id: MenuType.EMBEDDED
				}
			]
			switch (this.activeTabId) {
				case CurrentOrdersTabId.ACTIVE_ORDERS:
				case CurrentOrdersTabId.UPCOMING_ORDERS: {
					return [usernameFilter, userPhoneFilter]
				}
				case CurrentOrdersTabId.ADMIN_ACTIVE_ORDERS:
				case CurrentOrdersTabId.ADMIN_UPCOMING_ORDERS: {
					return [
						localOrderIdFilter,
						businessFilter,
						claimedStatusFilter,
						orderStatusFilter,
						usernameFilter,
						userPhoneFilter,
						{
							id: CurrentOrdersFilterId.ADVANCED_LABELS,
							type: "dropdown",
							placeholder: "Labels",
							advancedGroupId: 4,
							items: [
								{
									text: "Order Label",
									id: null
								},
								{
									text: "First Order",
									id: 0
								},
								{
									text: "Pre Order",
									id: 1
								},
								{
									text: "2GO",
									id: 2
								},
								{
									text: "Popup",
									id: 3
								}
							]
						},
						{
							id: CurrentOrdersFilterId.ADVANCED_WARNINGS,
							type: "dropdown",
							placeholder: "Warnings",
							advancedGroupId: 4,
							items: [
								{
									text: "Order Warning",
									id: null
								},
								{
									text: "Tablet Unhealthy",
									id: OrderWarningType.POS_DEVICE_UNHEALTHY
								},
								{
									text: "Delivery Not Picked up",
									id: OrderWarningType.DELIVERY_NOT_PICKUP_UP
								},
								{
									text: "Delivery Cancelled",
									id: OrderWarningType.DELIVERY_WAS_CANCELLED
								},
								{
									text: "Restaurant Nearly Closed",
									id: OrderWarningType.RESTAURANT_NEAR_CLOSED
								},
								{
									text: "Business Issue",
									id: OrderWarningType.BUSINESS_REPORTED_ISSUE
								},
								{
									text: "Delivery not created",
									id: OrderWarningType.DELIVERY_NOT_CREATED
								},
								{
									text: "Large Basket Size",
									id: OrderWarningType.LARGE_BASKET_SIZE
								}
							]
						},
						{
							id: CurrentOrdersFilterId.ORDER_TYPE,
							type: "dropdown",
							placeholder: "Order Type",
							advancedGroupId: 4,
							items: orderTypeOptions
						},
						{
							id: CurrentOrdersFilterId.ADVANCED_COURIER,
							type: "dropdown",
							placeholder: "Name",
							advancedGroupId: 5,
							items: [
								{
									text: "Courier Name",
									id: null
								},
								{
									id: CourierId.WALKING,
									text: "Self Delivery"
								},
								{
									id: CourierId.DOORDASH,
									text: "DoorDash"
								},
								{
									id: CourierId.CUT_CATS,
									text: "Cut Cats"
								},
								{
									id: CourierId.POSTMATES,
									text: "Postmates"
								}
							]
						}
					]
				}
				case CurrentOrdersTabId.HISTORY: {
					return [
						dateRangeFilter,
						orderIdFilter,
						businessFilter,
						restaurantFilter,
						orderStatusHistoryTab,
						{
							id: CurrentOrdersFilterId.BUSINESS_TYPE,
							type: "dropdown",
							placeholder: "Business Type",
							advancedGroupId: 0,
							items: [
								{
									text: `Business Type`,
									id: null
								},
								{
									text: this.$options.filters.businessTypeName(
										BusinessType.BAR
									),
									id: BusinessType.BAR
								},
								{
									text: this.$options.filters.businessTypeName(
										BusinessType.HOTEL
									),
									id: BusinessType.HOTEL
								},
								{
									text: this.$options.filters.businessTypeName(
										BusinessType.RESIDENTIAL
									),
									id: BusinessType.RESIDENTIAL
								},
								{
									text: this.$options.filters.businessTypeName(
										BusinessType.COWORKING
									),
									id: BusinessType.COWORKING
								},
								{
									text: this.$options.filters.businessTypeName(
										BusinessType.AIRPORT
									),
									id: BusinessType.AIRPORT
								}
							]
						},
						{
							id: CurrentOrdersFilterId.ORDER_TYPE,
							type: "dropdown",
							placeholder: "Order Type",
							advancedGroupId: 0,
							items: orderTypeOptions
						},
						{
							id: CurrentOrdersFilterId.ADVANCED_PAYMENT_TYPES,
							type: "dropdown",
							placeholder: "Payment Type",
							advancedGroupId: 0,
							items: [
								{
									text: "Payment Type",
									id: null
								},
								{
									text: "Credit Card",
									id: PaymentType.CREDIT_CARD
								},
								{
									text: "Apple Pay",
									id: PaymentType.APPLE_PAY
								},
								{
									text: "Google Pay",
									id: PaymentType.GOOGLE_PAY
								},
								{
									text: "Cash",
									id: PaymentType.CASH
								},
								{
									text: "Charge to Room",
									id: PaymentType.IN_ROOM_BILLING
								}
							]
						},
						{
							id: CurrentOrdersFilterId.ADVANCED_PAYMENT_STATUSES,
							type: "dropdown",
							placeholder: "Payment Status",
							advancedGroupId: 0,
							items: [
								{
									text: "Payment Status",
									id: null
								},
								{
									text: "Pending",
									id: OrderTransactionStatus.PENDING
								},
								{
									text: "Posted",
									id: OrderTransactionStatus.POSTED
								},
								{
									text: "Disputed",
									id: OrderTransactionStatus.DISPUTED
								}
							]
						},
						{
							id: CurrentOrdersFilterId.ADVANCED_COUPONS,
							type: "checkbox",
							label: "Coupons",
							advancedGroupId: 1
						},
						{
							id: CurrentOrdersFilterId.ADVANCED_REFUNDS,
							type: "checkbox",
							label: "Refunds",
							advancedGroupId: 1
						},
						{
							id: CurrentOrdersFilterId.ADVANCED_EXCEPTIONS,
							type: "checkbox",
							label: "Exceptions",
							advancedGroupId: 1
						},
						{
							id: CurrentOrdersFilterId.ADVANCED_USER_NAME,
							type: "input",
							placeholder: "Name",
							advancedGroupId: 2
						},
						{
							id: CurrentOrdersFilterId.ADVANCED_USER_PHONE,
							type: "input",
							placeholder: "Phone",
							advancedGroupId: 2
						},
						{
							id: CurrentOrdersFilterId.ADVANCED_USER_EMAIL,
							type: "input",
							placeholder: "Email",
							advancedGroupId: 2
						},
						{
							id: CurrentOrdersFilterId.ADVANCED_NPS_RANGE,
							type: "dropdown",
							placeholder: "NPS Range",
							advancedGroupId: 3,
							items: [
								{
									text: `NPS Range`,
									id: null
								},
								{
									text: "Equals",
									id: ScoreComparisonType.EQUALS
								},
								{
									text: "Does not Equal",
									id: ScoreComparisonType.NOT_EQUALS
								},
								{
									text: "Less then",
									id: ScoreComparisonType.LESS_THAN
								},
								{
									text: "Greater then",
									id: ScoreComparisonType.GREATER_THAN
								}
							]
						},
						{
							id: CurrentOrdersFilterId.ADVANCED_NPS_SCORE,
							type: "input",
							placeholder: "NPS Score Value",
							advancedGroupId: 3
						}
					]
				}
			}
			return []
		},
		filterGroups() {
			switch (this.activeTabId) {
				case CurrentOrdersTabId.HISTORY: {
					return {
						0: "Details",
						1: "Actions",
						2: "User",
						3: "NPS"
					}
				}
				case CurrentOrdersTabId.ADMIN_ACTIVE_ORDERS:
				case CurrentOrdersTabId.ADMIN_UPCOMING_ORDERS: {
					return {
						4: "Details",
						5: "Courier"
					}
				}
			}
		},
		actions() {
			switch (this.activeTabId) {
				case CurrentOrdersTabId.ACTIVE_ORDERS:
				case CurrentOrdersTabId.UPCOMING_ORDERS:
				case CurrentOrdersTabId.ADMIN_ACTIVE_ORDERS:
				case CurrentOrdersTabId.ADMIN_UPCOMING_ORDERS: {
					return [
						{
							id: 0,
							text: `<i class="sk-icon-sync-regular"></i> Refresh`,
							classes: "button button-primary"
						}
					]
				}
				case CurrentOrdersTabId.HISTORY: {
					return [
						{
							id: 1,
							text: `<i class="sk-icon-download"></i> Download CSV`,
							classes: "button button-primary"
						}
					]
				}
			}
		}
	},
	methods: {
		subscribeToPusher() {
			this.subscribeToAdminChannel()
			this.subscribe(this.handlePusherEvent)
		},
		headerTabClicked(tab) {
			this.$refs.pageHeader.clearAll()
			this.activeTabId = tab.id
			this.$nextTick(() => {
				this.setDefaultFilters()
			})
		},
		headerActionClicked(action) {
			switch (action.id) {
				case 0: {
					location.reload()
					break
				}
				case 1: {
					if (this.$refs.orderReports) {
						this.$refs.orderReports.exportOrders()
					}
					break
				}
			}
		},

		handlePusherEvent(event) {
			switch (event.name) {
				case PusherEvent.ORDER_WARNING: {
					this.$store.dispatch(
						"ordersModule/addOrderWarning",
						event.data
					)
					break
				}
				case PusherEvent.ORDER_NOTE: {
					if (
						event.data.note.created_by != this.$store.state.user_id
					) {
						const payload = {
							orderId: event.data.order_id,
							note: event.data.note
						}
						this.$store.dispatch(
							"ordersModule/addOrderNote",
							payload
						)
					}
					break
				}
				case PusherEvent.ORDER_STATUS: {
					if (event.data.action == "new") {
						this.$store
							.dispatch(
								"ordersModule/getOrderStatus",
								event.data.id
							)
							.then(() => {
								return this.$store.dispatch(
									"ordersModule/getCurrentOrderTransactions",
									event.data.id
								)
							})
							.then((order) => {
								if (order) {
									this.$refs.newOrderSound
										.play()
										.catch(() => {})
								}
							})
					} else {
						this.$store.dispatch(
							"ordersModule/orderStatusUpdate",
							event.data.id
						)
					}
					break
				}
				case PusherEvent.DELIVERY_STATUS: {
					this.$store.dispatch(
						`ordersModule/${ActionTypes.REFRESH_ORDER_DELIVERY}`,
						event.data
					)
					break
				}
				case PusherEvent.ORDER_TRANSACTION_STATUS_CHANGED: {
					if (event.data.admin_user_id != this.$store.state.user_id) {
						this.$store.dispatch(
							`ordersModule/${ActionTypes.ORDER_TRANSACTION_STATUS_CHANGED}`,
							{
								orderId: event.data.order_id,
								status: event.data.status
							}
						)
					}
					break
				}
				case PusherEvent.ORDER_REFUND_CREATED:
				case PusherEvent.ORDER_REFUND_UPDATED: {
					if (event.data.admin_user_id != this.$store.state.user_id) {
						this.$store.dispatch(
							`ordersModule/${ActionTypes.UPDATE_CURRENT_ORDER_REFUND}`,
							{
								orderId: event.data.order_id,
								refund: event.data.refund
							}
						)
					}
					break
				}
				case PusherEvent.ORDER_EXCEPTION_CREATED:
				case PusherEvent.ORDER_EXCEPTION_UPDATED: {
					this.$store.dispatch(
						`ordersModule/${ActionTypes.UPDATE_CURRENT_ORDER_EXCEPTION}`,
						{
							orderId: event.data.order_id,
							exception: event.data.exception
						}
					)
					break
				}
			}
		},
		updateFilterVals(filter, filterVals) {
			const currentFilterVals = filterVals ? filterVals : this.filterVals
			const updatedFilterVals = utils.clonedeep(currentFilterVals)
			switch (filter.id) {
				case CurrentOrdersFilterId.LOCAL_ORDER_ID: {
					updatedFilterVals.orderId = filter.value
						? filter.value.id
						: null
					break
				}
				case CurrentOrdersFilterId.ORDER_ID: {
					updatedFilterVals.orderId = filter.value
					break
				}
				case CurrentOrdersFilterId.BUSINESS: {
					updatedFilterVals.businessId = filter.value
						? filter.value.id
						: 0
					break
				}
				case CurrentOrdersFilterId.BUSINESS_TYPE: {
					updatedFilterVals.businessType = filter.value
						? filter.value.id
						: 0
					break
				}
				case CurrentOrdersFilterId.RESTAURANT: {
					updatedFilterVals.restaurantId = filter.value
						? filter.value.id
						: 0
					break
				}
				case CurrentOrdersFilterId.CLAIMED_STATUS: {
					updatedFilterVals.claimStatus = filter.value
						? filter.value.id
						: -1
					break
				}
				case CurrentOrdersFilterId.ORDER_STATUS: {
					updatedFilterVals.orderStatus =
						filter.value && filter.value.length > 0
							? filter.value
							: null
					break
				}
				case CurrentOrdersFilterId.ORDER_TYPE: {
					updatedFilterVals.orderType = filter.value
						? filter.value.id
						: null
					break
				}
				case CurrentOrdersFilterId.USER_NAME:
				case CurrentOrdersFilterId.ADVANCED_USER_NAME: {
					updatedFilterVals.username = filter.value
					break
				}
				case CurrentOrdersFilterId.USER_PHONE:
				case CurrentOrdersFilterId.ADVANCED_USER_PHONE: {
					updatedFilterVals.phone = filter.value
						? filter.value.match(/\d+/g).join("")
						: null
					break
				}
				case CurrentOrdersFilterId.ADVANCED_USER_EMAIL: {
					updatedFilterVals.email = filter.value ? filter.value : null
					break
				}
				case CurrentOrdersFilterId.DATE_RANGE: {
					updatedFilterVals.startDate = filter.value
						? filter.value.from
						: null
					updatedFilterVals.endDate = filter.value
						? filter.value.to
						: null
					break
				}
				case CurrentOrdersFilterId.ADVANCED_COUPONS: {
					updatedFilterVals.hasCoupons = filter.value
						? filter.value
						: null
					break
				}
				case CurrentOrdersFilterId.ADVANCED_REFUNDS: {
					updatedFilterVals.hasRefunds = filter.value
						? filter.value
						: null
					break
				}
				case CurrentOrdersFilterId.ADVANCED_EXCEPTIONS: {
					updatedFilterVals.hasExceptions = filter.value
						? filter.value
						: null
					break
				}
				case CurrentOrdersFilterId.ADVANCED_NPS_RANGE: {
					updatedFilterVals.npsComparisonType = filter.value
						? filter.value.id
						: null
					break
				}
				case CurrentOrdersFilterId.ADVANCED_NPS_SCORE: {
					updatedFilterVals.npsValue = filter.value
						? parseInt(filter.value)
						: null
					break
				}
				case CurrentOrdersFilterId.ADVANCED_WARNINGS: {
					updatedFilterVals.warningType = filter.value
						? filter.value.id
						: null
					break
				}
				case CurrentOrdersFilterId.ADVANCED_COURIER: {
					updatedFilterVals.courierId = filter.value
						? filter.value.id
						: null
					break
				}
				case CurrentOrdersFilterId.ADVANCED_LABELS: {
					updatedFilterVals.orderLabelId = filter.value
						? filter.value.id
						: null
					break
				}
				case CurrentOrdersFilterId.ADVANCED_PAYMENT_TYPES: {
					updatedFilterVals.paymentType = filter.value
						? filter.value.id
						: null
					break
				}
				case CurrentOrdersFilterId.ADVANCED_PAYMENT_STATUSES: {
					updatedFilterVals.paymentStatus = filter.value
						? filter.value.id
						: null
					break
				}
			}
			return updatedFilterVals
		},
		filterSet(filter) {
			this.filterVals = this.updateFilterVals(filter)
		},
		filtersSet(filters) {
			let updatedFilterVals = this.filterVals
			filters.forEach((filter) => {
				updatedFilterVals = this.updateFilterVals(
					filter,
					updatedFilterVals
				)
			})
			this.filterVals = updatedFilterVals
		},
		allFiltersReset() {
			this.filterVals = {
				claimStatus: -1,
				phone: null,
				username: null,
				startDate: null,
				endDate: null,
				orderId: null,
				businessId: 0,
				restaurantId: 0,
				orderStatus: null,
				businessType: null,
				orderType: null,
				hasCoupons: null,
				hasRefunds: null,
				hasExceptions: null,
				email: null,
				npsComparisonType: null,
				npsValue: null,
				warningType: null,
				courierId: null,
				orderLabelId: null,
				paymentType: null,
				paymentStatus: null
			}
			this.$nextTick(() => {
				this.setDefaultFilters()
			})
		},
		setDefaultFilters() {
			if (this.activeTabId == CurrentOrdersTabId.HISTORY) {
				const dateFilter = this.filters.find((filter) => filter.id == 0)
				const start = moment.utc().add(-1, "weeks")
				const end = moment.utc()
				this.$refs.pageHeader.filterSet(
					dateFilter,
					{
						start: start.format("MMM DD, YYYY"),
						end: end.format("MMM DD, YYYY"),
						formatted: {
							start: start.format("MMM DD, YYYY"),
							end: end.format("MMM DD, YYYY")
						}
					},
					true
				)
			}
		},
		async repushOrder(order) {
			const confirmed = await this.$refs.confirmationModal.open({
				title: `Are you sure you want to repush ${order.id}?`,
				body: "This will create a new order on behalf of the customer",
				confirmation: "Repush Order",
				cancel: "Close",
				type: ConfirmationModalType.WARNING
			})
			if (confirmed) {
				this.toggleLoader(true)
				const success = await this.$store.dispatch(
					"ordersModule/repushOrder",
					order
				)
				this.toggleLoader(false)

				let message = `#${order.id} was repushed to the restaurant!`
				let notificationType = NotificationType.SUCCESS

				if (!success) {
					message = `Failed to repush ${order.id}`
					notificationType = NotificationType.ERROR
				}
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Orders",
					message: message,
					type: notificationType
				})
			}
		}
	}
}
</script>