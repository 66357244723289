<template>
	<SkBaseModal :isOpen="isOpen" @close="close" class="sk-modal-alt">
		<div class="sk-modal-alt-title">
			<h1>Add External Record</h1>
		</div>
		<div class="sk-row">
			<SkInput
				ref="descriptionInput"
				:required="true"
				:name="'Description'"
				:multiline="true"
				v-model="description"
			/>
		</div>
		<div class="sk-row">
			<SkInput
				ref="amountInput"
				:required="true"
				:name="'Amount'"
				:type="'number'"
				v-model="amount"
			/>
		</div>
		<div class="sk-modal-actions">
			<button @click="close" class="button button-text">Close</button>
			<button
				type="button"
				class="button button-primary"
				@click="addExternalRecord"
			>
				<i class="sk-icon-check-regular"></i>
				Add Record
			</button>
		</div>
	</SkBaseModal>
</template>

<script>
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import ActionTypes from "@/store/modules/finance/action-types"
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import SkInput from "@/components/SkInput.vue"

export default {
	name: "AddInvoiceRecordModal",
	mixins: [skModalMixin],
	components: {
		SkBaseModal,
		SkInput
	},
	data: function () {
		return {
			invoice: null,
			description: "",
			amount: 0
		}
	},
	methods: {
		handleOptions(options) {
			this.invoice = options.invoice
		},
		closed() {
			this.invoice = null
			this.description = ""
			this.$refs.descriptionInput.resetValidation()
			this.$refs.amountInput.resetValidation()
			this.amount = 0
		},
		validateInputs() {
			const descriptionValid = this.$refs.descriptionInput.validate()
			const amountValid = this.$refs.amountInput.validate()
			return descriptionValid && amountValid
		},
		async addExternalRecord() {
			if (!this.validateInputs()) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Invoices",
					message: "All fields are required to create an external record",
					type: NotificationType.WARNING
				})
				return
			}
			const success = await this.$store.dispatch(
				`financeModule/${ActionTypes.ADD_BUSINESS_INVOICE_RECORD}`,
				{
					invoice: this.invoice,
					description: this.description,
					amount: this.amount
				}
			)
			if (success) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Invoices",
					message: "External record added"
				})
				this.close()
			} else {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Invoices",
					message: "Failed to add external record, please try again",
					type: NotificationType.ERROR
				})
			}
		}
	}
}
</script>