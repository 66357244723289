<template>
	<div class="sk-ancillary-page">
		<h2>Terms of Service</h2>
		<b>Last updated: August 07, 2019</b>
		<br />

		<p>
			These Terms of Use (“Terms”) form a binding agreement between you
			(“you”, “your”) and 2ndKitchen Inc. (“2ndKitchen”, “we” or “us”).
			Please read these Terms and our Privacy Policy (“Privacy Policy”)
			carefully because by accessing or using the 2ndKitchen Inc. website
			located at www.2ndkitchen.com (“Site”), as well as by using,
			offering or making available our customized food ordering services
			accessible via our web-based application or other applications
			(“Apps”) you are acknowledging that you have read, understood and
			agree to be bound by these Terms and the Privacy Policy. To make
			these Terms easier to read, the Site, our services and Apps are
			collectively called the “Services”. If you do not agree to the Terms
			or the Privacy Policy, you are not authorized to access or otherwise
			use the Services. If you are accessing, using, or making available
			the Services on behalf of a company (such as your employer) or other
			legal entity, you represent and warrant that you have the authority
			to bind that company or other legal entity to these Terms. In that
			case, “you” and “your” will refer to that company or other legal
			entity.
			<br />
			<br />

			<b>
				IMPORTANT NOTICE REGARDING ARBITRATION: WHEN YOU AGREE TO THESE
				TERMS YOU ARE AGREEING (WITH LIMITED EXCEPTION) TO RESOLVE ANY
				DISPUTE BETWEEN YOU AND 2NDKITCHEN THROUGH BINDING, INDIVIDUAL
				ARBITRATION RATHER THAN IN COURT. PLEASE REVIEW SECTION 19
				“DISPUTE RESOLUTION” BELOW CAREFULLY FOR DETAILS REGARDING
				ARBITRATION (INCLUDING THE PROCEDURE TO OPT OUT OF ARBITRATION).
			</b>
		</p>

		<b>1. Privacy Policy</b>
		Please refer to our Privacy Policy for information on how we collect,
		use and disclose your information. The policy is located at
		www.2ndkitchen.com/privacy
		<br />

		<b>2. Changes to Terms and Services.</b>
		Our Services are evolving, and hence we may update them and the Terms
		from time to time. If we modify the Terms, we’ll let you know either by
		posting the updated Terms on or Site and our Apps or through other
		communications. It’s important that you review the Terms whenever we
		update them or you use the Services. If you continue to use the Services
		after we have posted updated Terms, you are agreeing to be bound by the
		updated Terms. If you don’t agree to be bound by the updated Terms,
		then, except as otherwise provided in Section 19.6 “Effect of Changes on
		Arbitration,” you may not use the Services anymore. We may, at our sole
		discretion, change or discontinue all or any part of the offerings we
		provide via our Services, at any time and without notice.
		<br />

		<b>3. Our Services</b>
		We provide a platform that enables bars, hotels, lounges, stores and
		other establishments (
		<b>“Businesses”</b>
		) to connect with neighboring restaurants (“Restaurants”) and create a
		customized in-store menu for Businesses with items offered by one or
		more Restaurants (the “Menu”). Hungry patrons of each Business (
		<b>“Customers”</b>
		) can order from the Menu (
		<b>“Order”</b>
		) through our Apps by either using in-store tablets or kiosks or by
		following a customized link, scanning a QR code, or texting to receive a
		link, using their personal devices, in each case as offered or made
		available by the Business. Once the Customer places an Order with a
		Restaurant through our Apps, the Restaurant is notified and starts
		preparing the Order. Once the Order is ready, the Restaurant or a
		third-party delivery service delivers the Order to the Customer
		directly, to a designated area at the Business, or outside the Business.
		<br />

		<b>4. Terms Specific to Customers.</b>
		If you are a Customer, you agree to the following terms:
		<br />
		&nbsp;&nbsp;&nbsp;&nbsp;
		<b>4.1 Disclaimer</b>
		WE WANT TO MAKE YOUR EXPERIENCE AS SEAMLESS AS POSSIBLE BUT PLEASE NOTE
		THAT WE ARE NEITHER A FOOD PREPARATION NOR A DELIVERY SERVICE AND THAT
		WE ARE NOT A PARTY TO THE TRANSACTION BETWEEN YOU AND THE RESTAURANT.
		The Restaurants operate independently from us and may cancel an Order
		based at their own discretion. We do not guarantee, nor are we
		responsible for, the Restaurant’s compliance with applicable laws,
		regulations, and standards pertaining to the preparation, sale and
		marketing of food, including, without limitation, food preparation and
		food safety. You acknowledge and agree that the information on the Menu,
		including description, ingredient list, allergens and dietary tags, is
		provided by the Restaurant and we do not verify or guarantee the
		accuracy of any such information. We will not be responsible or liable
		for any illness, health problem or other loss or damage resulting from
		your reliance on the information displayed on the Menu or from your
		consumption of the Order. You acknowledge and agree that it is the
		responsibility of the Restaurant to deliver the Order to you and that we
		will not be liable or responsible for any delivery services provided by
		the Restaurant or on behalf of the Restaurant.
		<br />
		&nbsp;&nbsp;&nbsp;&nbsp;
		<b>4.2 Release.</b>
		If you are dissatisfied with your Order or the delivery of your Order,
		you agree to address such dispute directly with the Restaurant where you
		placed your Order and you hereby release us (and our officers,
		directors, agents, investors, subsidiaries, and employees) (collectively
		“Releasees”) from, and covenant not to sue Releasees for any and all
		claims, demands, or damages (actual or consequential) of any kind or
		nature, known and unknown, suspected and unsuspected, disclosed or
		undisclosed, arising out of or in any way connected with such dispute.
		If such a dispute arises, we may, but are not obliged to, facilitate
		communication between you and the Restaurant, or a third party on behalf
		of the Restaurant, in connection with such dispute.
		<br />

		<b>5. Terms specific to a Business.</b>
		If you are a Business, you agree to the following terms:
		<br />
		&nbsp;&nbsp;&nbsp;&nbsp;
		<b>5.1 2ndKitchen Equipment.</b>
		If we provide you with any equipment in connection with the Services,
		such as a kiosk or a tablet (“2ndKitchen Equipment”), you acknowledge
		and agree that we remain the sole and exclusive owner of such 2ndKitchen
		Equipment. You agree to exercise reasonable care, consistent with good
		business judgment, to preserve the 2ndKitchen Equipment in good working
		order and to inform us without undue delay if you become aware that
		2ndKitchen Equipment gets damaged or causes errors with respect to the
		provision of the Services.
		<br />
		&nbsp;&nbsp;&nbsp;&nbsp;
		<b>5.2 Order Delivery.</b>
		By offering or making available the Services, you agree that you will
		use reasonable efforts to facilitate and cooperate in the delivery of
		Orders to Customers inside your Business as reasonably necessary to
		complete delivery of an Order to the respective Customer.
		<br />

		<b>6. Who May Use the Services?</b>
		You may use the Services only if you are 18 years or older and capable
		of forming a binding contract with us and are not barred from using the
		Services under applicable law.
		<br />
		<b>7. Feedback.</b>
		<br />
		We welcome feedback, comments and suggestions for improvements to the
		Services (“Feedback”). You can submit Feedback by emailing us at
		support@2ndkitchen.com. You grant to us a non-exclusive, transferable,
		worldwide, perpetual, irrevocable, fully-paid, royalty-free license,
		with the right to sublicense, under any and all intellectual property
		rights that you own or control to use, copy, modify, create derivative
		works based upon and otherwise exploit the Feedback for any purpose.
		<br />
		<b>8. Payments.</b>
		If you are a Customer, you agree to the following terms:
		<br />
		&nbsp;&nbsp;&nbsp;&nbsp;
		<b>8.1 Prices.</b>
		You agree to pay the price plus any applicable taxes and other charges
		displayed in the Services for each Order you place (each such Order a
		“Transaction”). Please note that the prices for the Menu items displayed
		on the Services include a fee for our Services and may be different than
		the prices offered for the same items by the Restaurant or other
		third-party services. We have no obligation to itemize the costs,
		profits or margins of such prices and we reserve the right to change
		such prices at any time, at our discretion.
		<br />
		&nbsp;&nbsp;&nbsp;&nbsp;
		<b>8.2 Payment Terms.</b>
		You expressly authorize us (or our third-party payment processor) to
		charge you for each Transaction. We may ask you to supply additional
		information relevant to your Transaction, including your credit card
		number, the expiration date of your credit card, your email and postal
		addresses and your cell phone number for billing and notification (such
		information, “Payment Information”). You represent and warrant that you
		have the legal right to use all payment method(s) represented by any
		such Payment Information and that such Payment Information is accurate.
		When you initiate a Transaction, you authorize us to provide your
		Payment Information to third parties so we can complete your Transaction
		and to charge your payment method for the Transaction
		<br />
		&nbsp;&nbsp;&nbsp;&nbsp;
		<b>8.3 No Refunds.</b>
		Charges paid by you through the Services are final and non-refundable,
		unless otherwise determined by us. We may, in our sole discretion, offer
		credits or refunds on a case-by-case basis including, by way of example,
		in the event of an error with your order or the amounts you were
		charged.
		<br />
		&nbsp;&nbsp;&nbsp;&nbsp;
		<b>8.4 Cancellations by Customer.</b>
		If you cancel an Order after submitting it, you may incur a cancellation
		fee up to the total cost of the order, even if cancelled before the
		delivery of the Order. The cancellation fee, if any, will be
		communicated to you via the Services.
		<br />
		<b>9. Communications.</b>
		By using the Services as a Customer, you agree to receive communications
		from us, including via e-mail, text message and calls as needed to
		complete and inform you about the status of your Order. By using the
		Services as a Business, you agree to receive communications from us,
		including via e-mail, text message and calls as needed to operate our
		Services.
		<br />
		<b>10. Content Ownership, Responsibility and Removal.</b>
		<br />
		&nbsp;&nbsp;&nbsp;&nbsp;
		<b>10.1 Definitions.</b>
		For purposes of these Terms: (i) “Content” means text, graphics, images,
		music, software, audio, video, works of authorship of any kind, logos,
		trademarks, service marks, names and descriptions of dishes, and
		information (including nutritional and dietary information) or other
		materials that are posted, generated, provided or otherwise made
		available through the Services; and (ii) “User Content” means any
		Content that you provide to be made available through the Services,
		including, but not limited to ratings and reviews. Content includes
		without limitation User Content.
		<br />
		&nbsp;&nbsp;&nbsp;&nbsp;
		<b>10.2 Our Content Ownership.</b>
		We do not claim any ownership rights in any User Content and nothing in
		these Terms will be deemed to restrict any rights that you may have to
		use and exploit your User Content. Subject to the foregoing, we and our
		licensors exclusively own all right, title and interest in and to the
		Services and Content, including all associated intellectual property
		rights. You acknowledge that the Services and Content are protected by
		copyright, trademark, and other laws of the United States and foreign
		countries. You agree not to remove, alter or obscure any copyright,
		trademark, service mark or other proprietary rights notices incorporated
		in or accompanying the Services or Content.
		<br />
		&nbsp;&nbsp;&nbsp;&nbsp;
		<b>10.3 Rights in Content Granted by Us.</b>
		Subject to your compliance with these Terms, we grant to you a limited,
		non-exclusive, non-transferable license, with no right to sublicense, to
		download, view, copy, display and print the Content solely in connection
		with your permitted use of the Services.
		<br />
		&nbsp;&nbsp;&nbsp;&nbsp;
		<b>10.4 Rights in User Content Granted by You.</b>
		By making any User Content available through the Services you hereby
		grant to us a non-exclusive, transferable, worldwide, royalty-free
		license, with the right to sublicense, to use, copy, modify, create
		derivative works based upon, distribute, publicly display, and publicly
		perform your User Content in connection with operating and providing the
		Services and Content to you and others.
		<br />
		&nbsp;&nbsp;&nbsp;&nbsp;
		<b>10.5 Your Responsibility for User Content.</b>
		You are solely responsible for all your User Content. You represent and
		warrant that you own all your User Content or you have all rights that
		are necessary to grant us the license rights in your User Content under
		these Terms. You also represent and warrant that neither your User
		Content, nor your use and provision of your User Content to be made
		available through the Services, nor any use of your User Content by us
		on or through the Services will infringe, misappropriate or violate a
		third party’s intellectual property rights, or rights of publicity or
		privacy, or result in the violation of any applicable law or regulation.
		<br />
		&nbsp;&nbsp;&nbsp;&nbsp;
		<b>10.6 Ratings and Reviews.</b>
		To the extent that you are asked to rate and post reviews of Restaurants
		(“Ratings” and “Reviews”), such ratings and reviews are not endorsed by
		us and do not represent the view of us or our affiliates. We do not
		assume liability for Ratings and Reviews or for any claims for economic
		loss resulting from such Ratings and Reviews. Because we strive to
		maintain a high level of integrity with respect to Ratings and Reviews
		posted or otherwise made available through the Services, you agree that:
		(i) you will base any Rating or Review on first-hand experience with
		Restaurant; (ii) you will not provide a Rating or Review for any
		Restaurant for which you have an ownership interest, employment
		relationship or other affiliation or for any of that company’s
		competitors; (iii) you will not submit a Rating or Review in exchange
		for payment, free food items, or other benefits from a Restaurant or
		Business; and (iv) your review will comply with the terms of this
		Agreement. If we determine, in our sole discretion, that any Rating or
		Review could diminish the integrity of the Ratings and Reviews, we may
		exclude such User Content without notice.
		<br />
		&nbsp;&nbsp;&nbsp;&nbsp;
		<b>10.7 Removal of User Content.</b>
		You can remove your User Content by specifically deleting it. However,
		in certain instances, some of your User Content (such as posts or
		comments you make) may not be completely removed and copies of your User
		Content may continue to exist on the Services. We are not responsible or
		liable for the removal or deletion of (or the failure to remove or
		delete) any of your User Content.
		<br />
		<b>11. Prohibited Use Policy.</b>
		By using the Services, you agree that:
		<br />
		<ul style="list-style-type: disc">
			<li>
				You will not post, upload, publish, submit or transmit any
				Content that: (i) infringes, misappropriates or violates a third
				party’s patent, copyright, trademark, trade secret, moral rights
				or other intellectual property rights, or rights of publicity or
				privacy; (ii) violates, or encourages any conduct that would
				violate, any applicable law or regulation or would give rise to
				civil liability; (iii) is fraudulent, false, misleading or
				deceptive; (iv) is defamatory, obscene, pornographic, vulgar or
				offensive; (v) promotes discrimination, bigotry, racism, hatred,
				harassment or harm against any individual or group; (vi) is
				violent or threatening or promotes violence or actions that are
				threatening to any person or entity; or (vii) promotes illegal
				or harmful activities or substances.
			</li>
			<li>
				You will not use, display, mirror or frame the Services or any
				individual element within the Services, our name, any of our
				trademarks, logos or other proprietary information, or the
				layout and design of any page or form contained on a page,
				without our express written consent.
			</li>
			<li>
				You will not access, tamper with, or use non-public areas of the
				Services, our computer systems, or the technical delivery
				systems of our providers.
			</li>
			<li>
				You will not attempt to probe, scan or test the vulnerability of
				any of our systems or networks or breach any security or
				authentication measures.
			</li>
			<li>
				You will not avoid, bypass, remove, deactivate, impair,
				descramble or otherwise circumvent any technological measure
				implemented by us or any of our providers or any other third
				party (including another user) to protect the Services or
				Content.
			</li>
			<li>
				You will not attempt to access or search the Services or Content
				or download Content from the Services through the use of any
				engine, software, tool, agent, device or mechanism (including
				spiders, robots, crawlers, data mining tools or the like) other
				than the software and/or search agents provided by us or other
				generally available third-party web browsers.
			</li>
			<li>
				You will not send any unsolicited or unauthorized advertising,
				promotional materials, email, junk mail, spam, chain letters or
				other form of solicitation.
			</li>
			<li>
				You will not use any meta tags or other hidden text or metadata
				utilizing our trademarks, logos, URLs or product names without
				our express written consent.
			</li>
			<li>
				You will not use the Services or Content, or any portion
				thereof, for any commercial purpose or for the benefit of any
				third party or in any manner not permitted by these Terms.
			</li>
			<li>
				You will not forge any TCP/IP packet header or any part of the
				header information in any email or newsgroup posting, or in any
				way use the Services or Content to send altered, deceptive or
				false source-identifying information.
			</li>
			<li>
				You will not attempt to decipher, decompile, disassemble or
				reverse engineer any of the software used to provide the
				Services.
			</li>
			<li>
				You will not interfere with, or attempt to interfere with, the
				access of any user, host or network, including, without
				limitation, sending a virus, overloading, flooding, spamming, or
				mail-bombing the Services.
			</li>
			<li>
				You will not collect or store any personally identifiable
				information from the Services from other users of the Services
				without their express permission.
			</li>
			<li>
				You will not impersonate or misrepresent your affiliation with
				any person or entity.
			</li>
			<li>You will not violate any applicable law or regulation.</li>
			<li>
				You will not conduct any systematic retrieval of data or other
				Content from the Services, and you will not copy any Content
				displayed through the Services for republication in any format
				or media without our authorization or permission.
			</li>
			<li>
				You will not use the Services to cause nuisance, annoyance or
				inconvenience.
			</li>
			<li>
				You will not encourage or enable any other individual to do any
				of the foregoing.
			</li>
		</ul>
		<b>12. DMCA/Copyright Policy.</b>
		<br />
		We respect copyright law and expect our users to do the same. It is our
		policy to terminate Services for users who repeatedly infringe or are
		believed to be repeatedly infringing the rights of copyright holders. It
		is our policy to handle copyright infringement claims by users and third
		parties in accordance with the Digital Millennium Copyright Act
		(“DMCA”).
		<br />
		<b>13. Links to Third Party Websites or Resources.</b>
		<br />
		The Services may contain links to third-party websites or resources. We
		provide these links only as a convenience and are not responsible for
		the content, products or services on or available from those websites or
		resources or links displayed on such websites. You acknowledge sole
		responsibility for and assume all risk arising from, your use of any
		third-party websites or resources.
		<br />
		<b>14. Termination.</b>
		<br />
		&nbsp;&nbsp;&nbsp;&nbsp;
		<b>14.1 By Us.</b>
		We may terminate your access to and use of the Services, at our sole
		discretion, at any time and without notice to you. You hereby
		acknowledge and agree that we are in no way liable for any damage that
		this action may cause you.
		<br />
		&nbsp;&nbsp;&nbsp;&nbsp;
		<b>14.2 By You.</b>
		You may terminate your use of the Services at any time. If you are a
		Business, you agree to notify us 30 days in advance of the termination
		date and to return any devices or materials that we provided in order
		for the Business to make available the Services to Customers, such as
		kiosks or tablets, without undue delay at the Business’ cost.
		<br />
		<b>15. Warranty Disclaimers.</b>
		The following disclaimers are made on behalf of 2ndKitchen, our
		affiliates, subsidiaries, parents, successors and assigns, and each of
		our respective officers, directors, employees, agents, and shareholders.
		<b>
			THE SERVICES ARE PROVIDED ON AN “AS IS” BASIS AND WITHOUT ANY
			WARRANTY OR CONDITION, EXPRESS, IMPLIED OR STATUTORY. WE DO NOT
			GUARANTEE AND DO NOT PROMISE ANY SPECIFIC RESULTS FROM USE OF THE
			SERVICES. TO THE FULLEST EXTENT PERMITTED BY LAW, WE SPECIFICALLY
			DISCLAIM ANY IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS
			FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. SOME STATES DO NOT
			ALLOW THE DISCLAIMER OF IMPLIED WARRANTIES, SO THE FOREGOING
			DISCLAIMER MAY NOT APPLY TO YOU. WE DO NOT WARRANT THAT YOUR USE OF
			THE SERVICES WILL BE ACCURATE, COMPLETE, RELIABLE, CURRENT, SECURE,
			UNINTERRUPTED, ALWAYS AVAILABLE, OR ERROR- FREE, OR WILL MEET YOUR
			REQUIREMENTS, THAT ANY DEFECTS IN THE SERVICES WILL BE CORRECTED, OR
			THAT THE SERVICES IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. WE
			DISCLAIM LIABILITY FOR, AND NO WARRANTY IS MADE WITH RESPECT TO,
			CONNECTIVITY AND AVAILABILITY OF THE SERVICES.
		</b>
		<br />

		<b>16. Indemnification.</b>
		You agree to indemnify, hold harmless and defend 2ndKitchen, its
		officers, directors, shareholders, employees, and agents from and
		against any and all losses, costs, damages, claims, suits, actions,
		judgments, demands, obligations, debts, liabilities, and expenses
		whatsoever (including, without limitation, reasonable attorneys’ fees,
		court costs and investigation expenses) (each, an “Action”) arising
		from, or on account of, or related to the following: (i) your use,
		offering, or making available of the Services; (ii) your User Content,
		or (ii) any breach by you of these Terms.
		<br />
		<b>17. Limitation of Liability.</b>
		THE EXCLUSIONS AND LIMITATIONS OF DAMAGES SET FORTH BELOW ARE
		FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN US AND YOU.
		<br />
		&nbsp;&nbsp;&nbsp;&nbsp;
		<b>17.1 Disclaimer of Certain Damages.</b>
		NEITHER WE NOR ANY OTHER PARTY INVOLVED IN CREATING, PRODUCING, OR
		DELIVERING THE SERVICES WILL BE LIABLE FOR ANY INCIDENTAL, SPECIAL,
		EXEMPLARY OR CONSEQUENTIAL DAMAGES, OR DAMAGES FOR LOST PROFITS, LOST
		REVENUES, LOST SAVINGS, LOST BUSINESS OPPORTUNITY, LOSS OF DATA OR
		GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE OR THE
		COST OF SUBSTITUTE SERVICES OF ANY KIND ARISING OUT OF OR IN CONNECTION
		WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE SERVICES,
		WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE),
		PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT WE OR
		ANY OTHER PARTY HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE,
		EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS
		ESSENTIAL PURPOSE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
		LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE
		ABOVE LIMITATION MAY NOT APPLY TO YOU.
		<br />
		&nbsp;&nbsp;&nbsp;&nbsp;
		<b>17.2 Cap on Liability.</b>
		IN NO EVENT WILL OUR TOTAL LIABILITY ARISING OUT OF OR IN CONNECTION
		WITH THESE TERMS OR FROM THE USE, OFFERING, OR MAKING AVAILABLE OF OR
		INABILITY TO USE THE SERVICES OR CONTENT EXCEED THE AMOUNTS YOU HAVE
		PAID TO 2NDKITCHEN FOR USE OF THE SERVICES OR CONTENT OR ONE HUNDRED
		DOLLARS ($100), IF YOU HAVE NOT HAD ANY PAYMENT OBLIGATIONS TO
		2NDKITCHEN, AS APPLICABLE.
		<br />
		<b>18. Governing Law and Forum Choice.</b>
		These Terms and any action related thereto will be governed by the
		Federal Arbitration Act, federal arbitration law, and the laws of the
		State of Illinois, without regard to its conflict of laws provisions.
		Except as otherwise expressly set forth in Section 19 “Dispute
		Resolution,” the exclusive jurisdiction for all Disputes (defined below)
		that you and 2ndKitchen are not required to arbitrate will be the state
		and federal courts located in the Chicago, Illinois, and you and
		2ndKitchen each waive any objection to jurisdiction and venue in such
		courts.
		<br />
		<b>19. Dispute Resolution.</b>
		<br />
		&nbsp;&nbsp;&nbsp;&nbsp;
		<b>19.1 Mandatory Arbitration of Disputes.</b>
		We each agree that any dispute, claim or controversy arising out of or
		relating to these Terms or the breach, termination, enforcement,
		interpretation or validity thereof or the use of the Services or Content
		(collectively, “Disputes”) will be resolved solely by binding,
		individual arbitration and not in a class, representative or
		consolidated action or proceeding. You and Client agree that the U.S.
		Federal Arbitration Act governs the interpretation and enforcement of
		these Terms, and that you and Client are each waiving the right to a
		trial by jury or to participate in a class action. This arbitration
		provision shall survive termination of these Terms.
		<br />
		&nbsp;&nbsp;&nbsp;&nbsp;
		<b>19.2 Exceptions and Opt-out.</b>
		As limited exceptions to Section 19.1 above: (i) you may seek to resolve
		a Dispute in small claims court if it qualifies; and (ii) we each retain
		the right to seek injunctive or other equitable relief from a court to
		prevent (or enjoin) the infringement or misappropriation of our
		intellectual property rights. In addition, you will retain the right to
		opt out of arbitration entirely and litigate any Dispute if you provide
		us with written notice of your desire to do so by email at
		info@2ndkitchen.com or by regular mail at 18 Bartol St # 1038. San
		Francisco, CA 94133 within thirty (30) days following the date you first
		agree to these Terms.
		<br />
		&nbsp;&nbsp;&nbsp;&nbsp;
		<b>19.3 Conducting Arbitration and Arbitration Rules.</b>
		The arbitration will be conducted by the American Arbitration
		Association (“AAA”) under its Consumer Arbitration Rules (the “AAA
		Rules”) then in effect, except as modified by these Terms. The AAA Rules
		are available at www.adr.org or by calling 1-800-778-7879. A party who
		wishes to start arbitration must submit a written Demand for Arbitration
		to AAA and give notice to the other party as specified in the AAA Rules.
		The AAA provides a form Demand for Arbitration at www.adr.org. If your
		claim is for U.S. $10,000 or less, you may choose whether the
		arbitration will be conducted solely on the basis of documents submitted
		to the arbitrator, through a telephonic or video-conference hearing, or
		by an in-person hearing as established by the AAA Rules. If your claim
		exceeds U.S. $10,000, the right to a hearing will be determined by the
		AAA Rules. Any arbitration hearings will take place in the county (or
		parish) where you live, unless we both agree to a different location.
		The parties agree that the arbitrator shall have exclusive authority to
		decide all issues relating to the interpretation, applicability,
		enforceability and scope of this arbitration agreement
		<br />
		&nbsp;&nbsp;&nbsp;&nbsp;
		<b>19.4 Arbitration Costs.</b>
		Payment of all filing, administration and arbitrator fees will be
		governed by the AAA Rules. We’ll pay for all filing, administration and
		arbitrator fees and expenses if your Dispute is for less than $10,000,
		unless the arbitrator finds your Dispute frivolous. If we prevail in
		arbitration we’ll pay all of our attorneys’ fees and costs and won’t
		seek to recover them from you. If you prevail in arbitration you will be
		entitled to an award of attorneys’ fees and expenses to the extent
		provided under applicable law.
		<br />
		&nbsp;&nbsp;&nbsp;&nbsp;
		<b>19.5 Class Action Waiver.</b>
		YOU AND 2NDKITCHEN AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER
		ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS
		MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. Further, if
		the parties’ dispute is resolved through arbitration, the arbitrator may
		not consolidate another person's claims with your claims, and may not
		otherwise preside over any form of a representative or class proceeding.
		If this specific provision is found to be unenforceable, then the
		entirety of this Dispute Resolution section shall be null and void.
		<br />
		&nbsp;&nbsp;&nbsp;&nbsp;
		<b>19.6 Effect of Changes on Arbitration.</b>
		Notwithstanding the provisions of Section 2 “Changes to Terms or
		Services” above, if Client changes any of the terms of this Section 19
		“Dispute Resolution” after the date you first accepted these Terms (or
		accepted any subsequent changes to these Terms), you may reject any such
		change by sending us written notice (including by email to
		info@2ndkitchen.com) within 30 days of the date such change became
		effective, as indicated in the “Last Updated” date above or in the date
		of Client’s email to you notifying you of such change. By rejecting any
		change, you are agreeing that you will arbitrate any Dispute between you
		and Client in accordance with the terms of this Section 19 “Dispute
		Resolution” as of the date you first accepted these Terms (or accepted
		any subsequent changes to these Terms).
		<br />
		&nbsp;&nbsp;&nbsp;&nbsp;
		<b>19.7 Severability.</b>
		With the exception of any of the provisions in Section 19.5 of these
		Terms ("Class Action Waiver"), if an arbitrator or court of competent
		jurisdiction decides that any part of these Terms is invalid or
		unenforceable, the other parts of these Terms will still apply.
		<br />
		<b>20. General Terms.</b>
		<br />
		&nbsp;&nbsp;&nbsp;&nbsp;
		<b>20.1 No Joint Venture or Partnership.</b>
		No joint venture, partnership, employment, or agency relationship exists
		between you, 2ndKitchen or any third-party provider as a result of this
		Agreement or use of the Software or Services.
		<br />
		&nbsp;&nbsp;&nbsp;&nbsp;
		<b>20.2 Entire Agreement.</b>
		These Terms constitute the entire and exclusive understanding and
		agreement between us and you regarding the Services and Content, and
		these Terms supersede and replace any and all prior oral or written
		understandings or agreements between us and you regarding the Services
		and Content. If any provision of these Terms is held invalid or
		unenforceable by an arbitrator or a court of competent jurisdiction,
		that provision will be enforced to the maximum extent permissible and
		the other provisions of these Terms will remain in full force and
		effect. You may not assign or transfer these Terms, by operation of law
		or otherwise, without our prior written consent. Any attempt by you to
		assign or transfer these Terms, without such consent, will be null. We
		may freely assign or transfer these Terms without restriction. Subject
		to the foregoing, these Terms will bind and inure to the benefit of the
		parties, their successors and permitted assigns.
		<br />
		&nbsp;&nbsp;&nbsp;&nbsp;
		<b>20.3 Notices.</b>
		Any notices or other communications provided by us under these Terms,
		including those regarding modifications to these Terms, will be given:
		(i) via email; or (ii) by posting to the Services. For notices made by
		e-mail, the date of receipt will be deemed the date on which such notice
		is transmitted.
		<br />
		&nbsp;&nbsp;&nbsp;&nbsp;
		<b>20.4 Waiver of Rights.</b>
		Our failure to enforce any right or provision of these Terms will not be
		considered a waiver of such right or provision. The waiver of any such
		right or provision will be effective only if in writing and signed by a
		duly authorized representative of 2ndKitchen. Except as expressly set
		forth in these Terms, the exercise by either party of any of its
		remedies under these Terms will be without prejudice to its other
		remedies under these Terms or otherwise.
		<br />
		&nbsp;&nbsp;&nbsp;&nbsp;
		<b>20.5 Contact Information.</b>
		If you have any questions about these Terms or the Services, please
		contact us at info@2ndkitchen.com
		<br />
	</div>
</template>

<script>

export default {
	name: "Terms"
}
</script>