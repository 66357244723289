import Vue from "vue"
import pricing_service from "@/services/pricing"
import orders_service from "@/services/orders"
import business_service from "@/services/businesses"
import pos_service from "@/services/pos"
import search_service from "@/services/search"
import delivery_service from "@/services/delivery"
import payment_service from "@/services/payments"
import api from "@/api"
import { OrderActionType } from "@/enums/orderActionType"
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import { UserRole } from "@/enums/userRole"
import { ScoreComparisonType } from "@/enums/scoreComparisonType"
import moment from "moment"
import xlsx_helper from "@/utils/xlsx-helper"
import { CourierId } from "@arikgaisler/utils/enums/courierId"
import { PermissionInfo } from "@/utils/permissions"
import { PaymentType } from "@arikgaisler/utils/enums/paymentType"
import { DeliveryMode } from "@arikgaisler/utils/enums/deliveryMode"
import { OrderStatus } from "@arikgaisler/utils/enums/orderStatus"
import ActionTypes from "@/store/modules/orders/action-types"
import MutationTypes from "@/store/modules/orders/mutation-types"
import OrderAction from "@/models/OrderAction"
import orderHelper from "@/utils/order-helper"

const vue = new Vue()

function orderSearchParamsToFilters(payload) {
	let filters = {}
	if (payload.orderId) {
		filters.ids = [payload.orderId]
	} else {
		if (payload.startDate) {
			filters.created_time_local_from = moment
				.utc(payload.startDate, "YYYY-MM-DD")
				.format()
		} else if (!payload.noPlaceholdDates) {
			filters.created_time_local_from = moment
				.utc()
				.add(-1, "weeks")
				.format()
		}

		if (payload.endDate) {
			filters.created_time_local_to = moment
				.utc(payload.endDate, "YYYY-MM-DD")
				.format()
		} else if (!payload.noPlaceholdDates) {
			filters.created_time_local_to = moment.utc().format()
		}

		if (payload.orderStatus) {
			filters.statuses = [payload.orderStatus]
		}

		if (payload.businessId || payload.businessType) {
			filters.business = {}

			if (payload.businessId) {
				filters.business.ids = [payload.businessId]
			}

			if (payload.businessType) {
				filters.business.types = [payload.businessType]
			}
		}

		if (payload.restaurantId) {
			filters.restaurant = {
				ids: [payload.restaurantId]
			}
		}

		if (payload.username || payload.phone || payload.email) {
			filters.user = {}
			if (payload.username) {
				filters.user.names = [payload.username]
			}
			if (payload.phone) {
				filters.user.phone_numbers = [payload.phone]
			}
			if (payload.email) {
				filters.user.emails = [payload.email]
			}
		}

		if (payload.hasCoupons) {
			filters.has_coupons = payload.hasCoupons
		}

		if (payload.hasRefunds) {
			filters.has_refunds = payload.hasRefunds
		}

		if (payload.hasExceptions) {
			filters.has_exceptions = payload.hasExceptions
		}

		if (payload.orderType) {
			filters.menu_types = [payload.orderType]
		}

		if (payload.npsComparisonType && payload.npsValue) {
			filters.score = {}
			switch (payload.npsComparisonType) {
				case ScoreComparisonType.EQUALS: {
					filters.score.eq = payload.npsValue
					break
				}
				case ScoreComparisonType.NOT_EQUALS: {
					filters.score.not_eq = payload.npsValue
					break
				}
				case ScoreComparisonType.GREATER_THAN: {
					filters.score.gt = payload.npsValue
					break
				}
				case ScoreComparisonType.LESS_THAN: {
					filters.score.lt = payload.npsValue
					break
				}
			}
		}

		if (payload.paymentType != null || payload.paymentStatus != null) {
			filters.payment = {}

			if (payload.paymentType != null) {
				filters.payment.types = [payload.paymentType]
			}

			if (payload.paymentStatus != null) {
				filters.payment.statuses = [payload.paymentStatus]
			}
		}
	}
	return filters
}

export default {
	async updateOrderActions({ commit }, payload) {
		try {
			const warningsPromise = orders_service.getOrdersWarnings(
				payload.orderIdsStr
			)
			warningsPromise.catch((e) => {
				vue.bugsnag.notify(
					"Failed to fetch order actions warnings",
					(event) => {
						event.addMetadata("error", {
							error: e,
							orderIds: payload.orderIdsStr
						})
					}
				)
			})

			const warningsResp = await warningsPromise

			payload.orders.forEach((order) => {
				if (
					warningsResp &&
					warningsResp.data &&
					warningsResp.data.order_warnings[order.id]
				) {
					let warnings = warningsResp.data.order_warnings[order.id]
					commit("gotOrderWarnings", {
						warnings: warnings,
						order: order
					})
				}
			})
			return true
		} catch (e) {
			vue.bugsnag.notify(
				"Failed to set actions on active orders",
				(event) => {
					event.addMetadata("error", {
						error: e,
						payload: payload
					})
				}
			)
			return false
		}
	},
	async getCurrentOrdersData({ commit, dispatch }, withLoading) {
		if (withLoading) {
			commit("loading")
		}
		const responses = await Promise.all([orders_service.getCurrentOrders()])
		const orders = responses[0].data.orders
		const orderIdsStr = orders.map((order) => order.id).join()
		await dispatch("updateOrderActions", {
			orders: orders,
			orderIdsStr: orderIdsStr
		})

		commit("gotCurrentOrders", orders)
		if (withLoading) {
			commit("loaded")
		}
	},
	async getActiveOrdersData({ commit, dispatch }, withLoading) {
		try {
			if (withLoading) {
				commit("loading")
			}
			const responses = await Promise.all([
				orders_service.getActiveOrders()
			])
			const orders = responses[0].data.orders
			commit("gotCurrentOrders", orders)
			await dispatch("getCurrentOrdersTransactions")

			if (withLoading) {
				commit("loaded")
			}
		} catch (e) {
			if (withLoading) {
				commit("loaded")
			}
			vue.bugsnag.notify("Failed to get active orders", (event) => {
				event.addMetadata("error", {
					error: e
				})
			})
			return false
		}
	},
	async getCurrentOrdersTransactions({ state, commit }) {
		try {
			const inRoomBillingOrders = state.currentOrders.filter(
				(order) => order.payment_method == PaymentType.IN_ROOM_BILLING
			)
			const inRoomBillingOrderIds = inRoomBillingOrders.map(
				(order) => order.id
			)

			if (inRoomBillingOrderIds.length > 0) {
				const response = await payment_service.getOrdersTransactions(
					inRoomBillingOrderIds
				)
				if (response.status == 200) {
					commit(
						"gotCurrentOrdersTransactions",
						response.data.order_transactions
					)
				} else {
					throw `API Error: ${response.status}`
				}
			}
			return true
		} catch (e) {
			vue.bugsnag.notify(
				"Failed to get current orders transactions",
				(event) => {
					event.addMetadata("error", {
						error: e
					})
				}
			)
			return false
		}
	},
	async getCurrentOrderTransactions({ commit }, orderId) {
		try {
			const response = await payment_service.getOrdersTransactions(
				orderId
			)
			if (response.status == 200) {
				commit(
					"gotCurrentOrdersTransactions",
					response.data.order_transactions
				)
			} else {
				throw `API Error: ${response.status}`
			}
			return true
		} catch (e) {
			vue.bugsnag.notify(
				"Failed to get current orders transactions",
				(event) => {
					event.addMetadata("error", {
						error: e
					})
				}
			)
			return false
		}
	},
	async orderStatusUpdate(
		{ rootGetters, state, commit, dispatch },
		order_id
	) {
		try {
			let orderData = await orders_service.getOrderStatus(order_id)
			let order = orderData.data
			const isSuperAdmin = rootGetters.isSuperAdmin
			if (!isSuperAdmin) {
				const restaurantIds = order.sub_orders.reduce(
					(ids, suborder) => {
						ids.push(suborder.rest_id)
						return ids
					},
					[]
				)
				const ownsBusiness = rootGetters.ownedBusinesses.find(
					(business) => business.id == order.business_id
				)
				const ownsRestaurant = rootGetters.ownedRestaurants.find(
					(restaurant) => restaurantIds.includes(restaurant.id)
				)
				if (!ownsBusiness && !ownsRestaurant) {
					return null
				}
			}
			let copyActions = false
			if (
				isSuperAdmin &&
				state.currentOrder &&
				state.currentOrder.id == order_id
			) {
				await dispatch(ActionTypes.REFRESH_ORDER_ACTIONS, order)
			} else {
				copyActions = true
			}
			commit("orderStatusChanged", {
				order: order,
				copyActions: copyActions
			})
			return true
		} catch (e) {
			vue.bugsnag.notify("Failed to update order status", (event) => {
				event.addMetadata("error", {
					error: e,
					orderId: order_id
				})
			})
			return false
		}
	},
	async updateDeliveryStatus({ commit }, data) {
		try {
			let payload = {
				status: data.status,
				delivery_req_id: data.orderId
			}
			const response = await orders_service.updateDeliveryStatus(
				data.orderId,
				payload
			)
			if (response.status == 200) {
				commit("updatedOrderStatus", response.data)
			}
			return response.status == 200
		} catch (e) {
			vue.bugsnag.notify("Failed to update delivery status", (event) => {
				event.addMetadata("error", {
					error: e,
					suborderId: data.orderId
				})
			})
			return false
		}
	},
	async getOrderStatus({ rootGetters, commit }, order_id) {
		try {
			let orderData = await orders_service.getOrderStatus(order_id)
			let order = orderData.data
			const isSuperAdmin = rootGetters.isSuperAdmin
			if (!isSuperAdmin) {
				const restaurantIds = order.sub_orders.reduce(
					(ids, suborder) => {
						ids.push(suborder.rest_id)
						return ids
					},
					[]
				)
				const ownsBusiness = rootGetters.ownedBusinesses.find(
					(business) => business.id == order.business_id
				)
				const ownsRestaurant = rootGetters.ownedRestaurants.find(
					(restaurant) => restaurantIds.includes(restaurant.id)
				)
				if (!ownsBusiness && !ownsRestaurant) {
					return null
				}
			}
			commit(MutationTypes.GOT_ORDER_ACTIONS, {
				actions: [],
				order: order
			})
			commit("gotOrderWarnings", {
				warnings: [],
				order: order
			})
			commit("gotOrderStatus", order)
			return order
		} catch (e) {
			vue.bugsnag.notify("Failed to get order status", (event) => {
				event.addMetadata("error", {
					error: e,
					orderId: order_id
				})
			})
			return null
		}
	},
	addOrderWarning({ commit }, payload) {
		if (payload.warning) {
			commit("gotOrderWarning", {
				order_id: payload.order_id,
				warning: JSON.parse(payload.warning)
			})
		}
	},
	async setCurrentOrder({ commit, dispatch }, payload) {
		try {
			if (!payload || !payload.order) {
				commit("setCurrentOrder", null)
			} else {
				commit("setCurrentOrder", payload.order)
				if (payload.refresh && payload.order) {
					dispatch(ActionTypes.REFRESH_ORDER_ACTIONS, payload.order)
					if (payload.order.delivery_mode == DeliveryMode.LAST_YARD) {
						dispatch(
							ActionTypes.REFRESH_ORDER_DELIVERIES,
							payload.order
						)
					}
				}
			}
		} catch (e) {
			vue.bugsnag.notify("Failed to fetch order details", (event) => {
				event.addMetadata("error", {
					error: e,
					payload: payload
				})
			})
		}
	},
	async setDropoffLocation({ commit }, slug) {
		try {
			const response = await business_service.getBusiness(
				JSON.parse(JSON.stringify(slug))
			)
			const orderDropoffLocation =
				response.data.locations[0].dropoff_locations
			commit("setDropoffLocations", orderDropoffLocation)
		} catch (e) {
			vue.bugsnag.notify(
				"Failed to fetch order dropoff locations details",
				(event) => {
					event.addMetadata("error", {
						error: e,
						payload: slug
					})
				}
			)
		}
	},
	async updateSupervisor({ commit }, arr) {
		const order_id = arr[0]
		const needs_supervisor = arr[1]
		const payload = { needs_supervisor: needs_supervisor }
		const order_status = await orders_service.updateSupervisor(
			order_id,
			payload
		)
		if (order_status != null && order_status.data != null) {
			commit("orderStatusChanged", {
				order: order_status.data,
				copyActions: true
			})
		}
	},
	async updateSuppress({ commit }, arr) {
		const order_id = arr[0]
		const suppress_msg = arr[1]
		const payload = { suppress_msgs: suppress_msg }
		const order_status = await orders_service.updateSuppress(
			order_id,
			payload
		)
		if (order_status != null && order_status.data != null) {
			commit("orderStatusChanged", {
				order: order_status.data,
				copyActions: true
			})
		}
	},
	async claimOrder({ commit, rootState }, order) {
		try {
			const response = await orders_service.claimOrder(order.id, {
				user_id: rootState.user_id
			})

			if (response.status == 200) {
				commit("claimedOrder", {
					user: rootState.user,
					order: order
				})
			} else {
				throw `API Error: ${response.status}`
			}
		} catch (e) {
			vue.bugsnag.notify("Failed to claim order", (event) => {
				event.addMetadata("error", {
					error: e,
					order: order
				})
			})
			eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
				title: "Failed to claim order",
				type: NotificationType.ERROR
			})
			return false
		}
	},
	async refreshPOSOrder(store, sub_order_id) {
		try {
			const resp = await pos_service.refreshPOSOrder(sub_order_id)
			if (resp.status == 200) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Order re-sent to restaurant"
				})
			}
			return resp.status == 200
		} catch (e) {
			vue.bugsnag.notify("Failed to refresh POS order", (event) => {
				event.addMetadata("error", {
					error: e,
					suborderId: sub_order_id
				})
			})
			eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
				title: "Failed to re-send order to the restaurant",
				type: NotificationType.ERROR
			})
			return false
		}
	},
	async updateOrderStatus({ commit }, data) {
		try {
			let payload = {
				status: data.status
			}
			if (data.time) {
				payload.readyTime = data.time
			}

			const response = await orders_service.updateOrderStatus(
				data.orderId,
				data.status,
				payload
			)
			if (response && response.status == 200) {
				commit("updatedOrderStatus", {
					orderId: data.orderId,
					status: data.status
				})
			}
			return response && response.status == 200
		} catch (e) {
			vue.bugsnag.notify("Failed to update order status", (event) => {
				event.addMetadata("error", {
					error: e,
					orderId: data.orderId,
					status: data.status
				})
			})
			return false
		}
	},
	async notifyGuestOrderDelivered({ commit }, data) {
		try {
			const response = await orders_service.updateOrderStatus(
				data.id,
				OrderStatus.COMPLETED,
				{
					force_delivery: true
				}
			)
			if (response && response.status == 200) {
				commit("updatedOrderStatus", {
					orderId: data.orderId,
					status: OrderStatus.COMPLETED
				})
				return true
			}
			return false
		} catch (e) {
			vue.bugsnag.notify(
				"Failed to notify guest order was delivered",
				(event) => {
					event.addMetadata("error", {
						error: e,
						data: data
					})
				}
			)
			return false
		}
	},
	async cancelOrder({ commit }, data) {
		try {
			const response = await orders_service.updateOrderStatus(
				data.suborderId,
				OrderStatus.CANCELLED
			)
			if (response.status == 200) {
				commit("updatedOrderStatus", {
					orderId: data.orderId,
					status: OrderStatus.CANCELLED
				})
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Order was cancelled"
				})
			} else {
				throw `API Error: ${response.status}`
			}
			const order_status = await orders_service.getOrderStatus(
				data.orderId
			)
			if (order_status != null && order_status.data != null) {
				commit("orderStatusChanged", {
					order: order_status.data,
					copyActions: true
				})
			}
			return true
		} catch (e) {
			vue.bugsnag.notify("Failed to cancel order", (event) => {
				event.addMetadata("error", {
					error: e,
					data: data
				})
			})
			return false
		}
	},
	async repushOrder(store, order) {
		try {
			const response = await orders_service.duplicateOrder(order.id)
			return response.status == 200
		} catch (e) {
			vue.bugsnag.notify("Failed to repush order", (event) => {
				event.addMetadata("error", {
					error: e,
					data: order
				})
			})
			return false
		}
	},
	async createDelivery({ commit }, payload) {
		let courierName = ""
		switch (payload.courierId) {
			case CourierId.POSTMATES: {
				courierName = "Postmates"
				break
			}
			case CourierId.DOORDASH: {
				courierName = "DoorDash"
				break
			}
			case CourierId.CUT_CATS: {
				courierName = "Cut Cats"
				break
			}
		}
		try {
			const deliveryResp = await delivery_service.createDelivery({
				order_id: payload.order.id,
				sub_order_id: payload.order.sub_orders[0].id,
				courier_id: payload.courierId,
				force: true,
				reason_id: payload.deliveryReason,
				reason_text: payload.deliveryReasonText
					? payload.deliveryReasonText
					: undefined
			})
			if (deliveryResp.data.code == "ok") {
				const notePayload = {
					note: `Created ${courierName} Delivery`
				}
				await commit(
					"addedException",
					await orders_service.addOrderNote(
						payload.order.id,
						notePayload
					)
				)
				return true
			}
			return false
		} catch (e) {
			vue.bugsnag.notify(
				`Failed to create ${courierName} delivery`,
				(event) => {
					event.addMetadata("error", {
						error: e,
						payload: payload
					})
				}
			)
			return false
		}
	},
	async getOrderExceptionReasons({ commit }) {
		try {
			const response = await orders_service.getExceptionTypes()
			if (response.status == 200) {
				commit("gotOrderExceptionReasons", response.data.data)
				return true
			}
			throw `API Error: ${response.status}`
		} catch (e) {
			vue.bugsnag.notify("Failed to get exception types", (event) => {
				event.addMetadata("error", {
					error: e
				})
			})
			return false
		}
	},
	async addException({ commit }, data) {
		try {
			const response = await orders_service.addException(data.orderId, {
				exception_data: data.exception
			})
			commit("addedException", response)
			if (response.status == 200) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: `Exception was applied to #${data.orderId}!`
				})
				return true
			}
			throw `API Error: ${response.status}`
		} catch (e) {
			vue.bugsnag.notify("Failed to add exception", (event) => {
				event.addMetadata("error", {
					error: e,
					data: data
				})
			})
			return false
		}
	},
	async updateException({ commit }, exception) {
		try {
			const response = await orders_service.updateException(
				exception.id,
				{
					exception_data: {
						amount: exception.amount,
						reason_id: exception.reason_id
					}
				}
			)
			if (response.status == 200) {
				commit("updatedException", response.data.exception, {
					root: true
				})
				return true
			}
			return true
		} catch (e) {
			vue.bugsnag.notify("Failed to upate exception", (event) => {
				event.addMetadata("error", {
					error: e,
					exception: exception
				})
			})
			return false
		}
	},
	async addOrderIssue({ commit }, payload) {
		try {
			const response = await orders_service.addOrderWarning(
				payload.orderId,
				{
					text: payload.issueComment,
					type: 5,
					extra_params: {
						sub_order_id: payload.suborderId
					}
				}
			)
			if (response.status == 200 && response.data.order_warning) {
				commit("gotOrderWarning", {
					order_id: payload.orderId,
					warning: response.data.order_warning
				})
				return response.data.order_warning
			}
			return false
		} catch (e) {
			vue.bugsnag.notify("Failed to add order issue", (event) => {
				event.addMetadata("error", {
					error: e,
					payload: payload
				})
			})
			return false
		}
	},
	async addOrderNote({ commit }, payload) {
		try {
			const orderId = payload.orderId
			const note = payload.note
			if (!note.id) {
				const notePayload = { note: note }
				const response = await orders_service.addOrderNote(
					orderId,
					notePayload
				)
				if (response.status == 200) {
					const mutationPayload = {
						order_id: orderId,
						note: response.data.data.order_note
					}
					commit("gotOrderNote", mutationPayload)
					return true
				}
			}
			return false
		} catch (e) {
			vue.bugsnag.notify("Failed to add order note", (event) => {
				event.addMetadata("error", {
					error: e,
					payload: payload
				})
			})
			return false
		}
	},
	async sendReceipt({ commit }, arr) {
		const email = arr[0]
		const orderId = arr[1]
		var payload = { to_email: email }
		const resp = await api.sendReceipt(payload, orderId)
		if (resp.status == 200) {
			const notePayload = { note: "Receipt Sent" }
			await commit(
				"addedException",
				await orders_service.addOrderNote(orderId, notePayload)
			)
			eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
				title: `Receipt was sent to ${email} for #${orderId}!`
			})
		}
	},
	async sendSurvey({ commit }, orderId) {
		try {
			const orderStatus = await orders_service.sendOrderSurvey(orderId)
			if (orderStatus != null && orderStatus != undefined) {
				commit("orderStatusChanged", {
					order: orderStatus,
					copyActions: true
				})
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: `Survey was sent for #${orderId}!`
				})
			}
			return true
		} catch (e) {
			vue.bugsnag.notify("Failed to send survey", (event) => {
				event.addMetadata("error", {
					error: e,
					orderId: orderId
				})
			})
			return false
		}
	},
	clearOrderReports({ commit }) {
		commit("clearOrderReports")
	},
	async searchOrders({ commit, getters }, payload) {
		try {
			commit("loading")
			const response = await search_service.searchOrders({
				filters: orderSearchParamsToFilters(payload),
				sorting: [
					{
						sort_by: "created_time",
						sort_order: "desc"
					}
				],
				pagination: {
					offset: payload.pageStart,
					limit: payload.pageSize
				}
			})
			if (response.status == 200) {
				commit("gotOrderReports", {
					reports: response.data,
					exceptionTypeMap: getters.exceptionTypeMap,
					refundTypeMap: getters.refundTypeMap
				})
			} else {
				throw `API Error: ${response.status}`
			}
			commit("loaded")
		} catch (e) {
			commit("loaded")
			vue.bugsnag.notify("Failed to search order reports", (event) => {
				event.addMetadata("error", {
					error: e,
					filters: payload
				})
			})
		}
	},
	async exportOrders({ rootState, rootGetters }, payload) {
		try {
			const response = await search_service.searchOrders({
				filters: orderSearchParamsToFilters(payload),
				pagination: {
					offset: 0,
					limit: payload.totalOrders
				}
			})

			let exportData = []

			if (response.status != 200) {
				throw `API Error: ${response.status}`
			}

			response.data.orders.forEach((order) => {
				if (order.sub_orders) {
					order.sub_orders.forEach((suborder) => {
						suborder.products.forEach((product) => {
							const metaPrice = product.metas
								? product.metas.reduce(
										(total, meta) => total + meta.price,
										0
								  )
								: 0
							if (rootState.user_role == UserRole.SUPER_ADMIN) {
								const business =
									rootGetters.businessesMap[order.business.id]
								let taxes = ""
								let fees = ""
								if (business) {
									fees = `${
										(business.service_fee / 100) *
										product.subtotal
									}`
									taxes = `${
										(business.tax / 100) * product.subtotal
									}`
								}
								exportData.push({
									"Order ID": order.id,
									"Business Name": order.business.name,
									"Restaurant Name": suborder.restaurant.name,
									"Order Time": order.created_time_local
										? moment
												.utc(order.created_time_local)
												.format("LLLL")
										: "",
									Product: product.name,
									Quantity: product.quantity,
									"Item Price": product.subtotal,
									"Item Cogs": product.cog_item_total_cost,
									"Product Category": product.category
										? product.category.name
										: "",
									"Add-ons / Metatags (Price)": metaPrice,
									"Tax (Applies to Order)": taxes,
									"Fees (Applies to Order)": fees,
									"Tip Total (Applies to Order)": order.tip,
									"Price Total (Applies to Order)":
										order.price,
									"Accepted Time": suborder.accept_time_local
										? moment
												.utc(suborder.accept_time_local)
												.format("LLLL")
										: "",
									"Completed Time":
										suborder.completed_time_local
											? moment
													.utc(
														suborder.completed_time_local
													)
													.format("LLLL")
											: "",
									"Pickup Time": suborder.pickup_time_local
										? moment
												.utc(suborder.pickup_time_local)
												.format("LLLL")
										: "",
									"Delivered Time":
										suborder.delivered_time_local
											? moment
													.utc(
														suborder.delivered_time_local
													)
													.format("LLLL")
											: "",
									"Payment Method":
										order.payment_method &&
										order.payment_method.name
											? order.payment_method.name
											: "N/A"
								})
							} else {
								let data = {
									"Order ID": order.id,
									"Business Name": order.business.name,
									"Restaurant Name": suborder.restaurant.name,
									"Order Time": order.created_time_local
										? moment
												.utc(order.created_time_local)
												.format("LLLL")
										: "",
									Product: product.name,
									"Product Category": product.category
										? product.category.name
										: "",
									Quantity: product.quantity,
									"Add-ons / Metatags": metaPrice,
									"Payment Method":
										order.payment_method &&
										order.payment_method.name
											? order.payment_method.name
											: "N/A"
								}
								if (
									rootState.permissions.includes(
										PermissionInfo.ORDER_COGS
									)
								) {
									const showTip =
										suborder.courier_id ==
											CourierId.WALKING ||
										suborder.courier_id == CourierId.TOOKAN
									data["Item Price"] =
										product.cog_item_total_cost
									if (showTip) {
										data["tips"] = order.tip
									}
								} else if (
									rootState.permissions.includes(
										PermissionInfo.ORDER_FULL_PRICE
									)
								) {
									data["Item Price"] = product.subtotal
									data["tips"] = order.tip
								}
								exportData.push(data)
							}
						})
					})
				}
			})

			xlsx_helper.export(exportData, "2ndKitchen Report")
			return true
		} catch (e) {
			vue.bugsnag.notify("Failed to search order reports", (event) => {
				event.addMetadata("error", {
					error: e,
					filters: payload
				})
			})
			return false
		}
	},
	async addRefund(store, payload) {
		try {
			const response = await payment_service.addRefund(payload.orderId, {
				amount: payload.amount,
				reason_id: payload.reasonId,
				reason: payload.reason
			})
			if (response.status == 200) {
				return true
			}
			throw `API Error ${response.status}`
		} catch (e) {
			vue.bugsnag.notify("Failed to add refund", (event) => {
				event.addMetadata("error", {
					error: e,
					payload: payload
				})
			})
			return false
		}
	},
	async updateRefund({ commit }, payload) {
		try {
			const response = await payment_service.updateRefund(
				payload.refundId,
				{
					refund: {
						amount: payload.amount,
						reason_id: payload.reasonId,
						reason: payload.reason
					}
				}
			)
			if (response.status == 200) {
				commit("updatedRefund", response.data.refund, { root: true })
				return true
			}
			throw `API Error ${response.status}`
		} catch (e) {
			vue.bugsnag.notify("Failed to update refund", (event) => {
				event.addMetadata("error", {
					error: e,
					payload: payload
				})
			})
			return false
		}
	},
	async [ActionTypes.REFRESH_ORDER_ACTIONS]({ commit, getters }, order) {
		try {
			const logException = (message, e) => {
				vue.bugsnag.notify(message, (event) => {
					event.addMetadata("error", {
						error: e,
						orderIds: order.id
					})
				})
			}

			if (!order) {
				logException(
					"No order",
					new Error("Failed to update order actions")
				)
				return
			}

			const exceptionsPromise = orders_service.getOrdersExceptions(
				order.id
			)
			const refundsPromise = api.getOrderRefunds(order.id)
			const couponsPromise = pricing_service.getOrdersCoupons(order.id)
			const warningsPromise = orders_service.getOrdersWarnings(order.id)
			const notesPromise = orders_service.getOrdersNotes(order.id)
			const businessPromise = business_service.getBusiness(
				order.business_slug
			)
			exceptionsPromise.catch((e) =>
				logException("Failed to fetch order actions exceptions", e)
			)
			refundsPromise.catch((e) =>
				logException("Failed to fetch order actions refunds", e)
			)
			couponsPromise.catch((e) =>
				logException("Failed to fetch order actions coupons", e)
			)
			warningsPromise.catch((e) =>
				logException("Failed to fetch order actions warnings", e)
			)
			let couponsUsesResp = null

			const promises = await Promise.allSettled([
				exceptionsPromise,
				refundsPromise,
				couponsPromise,
				warningsPromise,
				notesPromise,
				businessPromise
			])

			const exceptionsResp =
				promises[0] && promises[0].value ? promises[0].value : null
			const refundsResp =
				promises[1] && promises[1].value ? promises[1].value : null
			const couponsResp =
				promises[2] && promises[2].value ? promises[2].value : null
			const warningsResp =
				promises[3] && promises[3].value ? promises[3].value : null
			const notesResp =
				promises[4] && promises[4].value ? promises[4].value : null
			const businessResp =
				promises[5] && promises[5].value ? promises[5].value : null

			let actionsArr = []
			if (order.coupon_used == 1) {
				if (couponsUsesResp == null) {
					const couponsUsesPromise =
						pricing_service.getOrdersCouponUses(order.id)
					couponsUsesPromise.catch((e) =>
						logException(
							"Failed to fetch order actions coupon uses",
							e
						)
					)
					couponsUsesResp = await couponsUsesPromise
				}
				if (couponsUsesResp.data && couponsUsesResp.data.coupon_uses) {
					commit("gotOrderCoupons", {
						uses: couponsUsesResp.data.coupon_uses,
						order: order
					})
				}
			}

			if (couponsResp && couponsResp.data && couponsResp.data.coupons) {
				couponsResp.data.coupons.forEach((coupon) => {
					actionsArr.push(
						new OrderAction({
							type: OrderActionType.COUPON,
							name: coupon.code,
							reason: coupon.reason,
							status: "Issued",
							amount: coupon.amount,
							id: coupon.id
						})
					)
				})
			}

			if (refundsResp && refundsResp.data && refundsResp.data.refunds) {
				refundsResp.data.refunds.forEach((refund) => {
					actionsArr.push(
						new OrderAction({
							type: OrderActionType.REFUND,
							name: "Refund",
							reason: refund.reason,
							status: refund.status,
							amount: refund.amount,
							id: refund.id
						})
					)
				})
			}

			if (
				exceptionsResp &&
				exceptionsResp.data &&
				exceptionsResp.data.exceptions
			) {
				exceptionsResp.data.exceptions.forEach((exception) => {
					const exceptionReason =
						getters.exceptionTypeMap[exception.reason_id]
					actionsArr.push(
						new OrderAction({
							type: OrderActionType.EXCEPTION,
							name: "Exception",
							reason: exceptionReason,
							status: exception.status,
							amount: exception.amount,
							id: exception.id
						})
					)
				})
			}
			if (
				warningsResp &&
				warningsResp.data &&
				warningsResp.data.order_warnings[order.id]
			) {
				let warnings = warningsResp.data.order_warnings[order.id]
				commit("gotOrderWarnings", {
					warnings: warnings,
					order: order
				})
			}

			if (
				notesResp &&
				notesResp.data &&
				notesResp.data.orders_notes[order.id]
			) {
				let notes = notesResp.data.orders_notes[order.id]
				commit("gotOrderNotes", {
					notes: notes,
					order: order
				})
			}

			if (businessResp && businessResp.data) {
				let business = businessResp.data
				commit("gotBusiness", business)
			}

			commit(MutationTypes.GOT_ORDER_ACTIONS, {
				actions: actionsArr,
				order: order
			})
			return true
		} catch (e) {
			vue.bugsnag.notify(
				"Failed to set actions on current order",
				(event) => {
					event.addMetadata("error", {
						error: e,
						payload: order
					})
				}
			)
			return false
		}
	},
	async [ActionTypes.REFRESH_ORDER_DELIVERIES]({ state, commit }, order) {
		try {
			if (state.currentOrder && order.id == state.currentOrder.id) {
				const promises = []
				order.sub_orders.forEach((suborder) => {
					if (
						suborder.delivery_job_id &&
						suborder.delivery_job_id != ""
					) {
						promises.push(
							delivery_service.getDelivery(
								suborder.delivery_job_id
							)
						)
					}
				})

				if (promises.length > 0) {
					const responses = await Promise.allSettled(promises)
					const deliveries = responses.reduce(
						(deliveries, response) => {
							if (
								response.value &&
								response.value.data &&
								response.value.data.delivery
							) {
								deliveries.push(response.value.data.delivery)
							}

							return deliveries
						},
						[]
					)
					commit(MutationTypes.GOT_ORDER_DELIVERIES, deliveries)
				}
			}
			return true
		} catch (e) {
			vue.bugsnag.notify(
				"Failed to refresh order deliveries",
				(event) => {
					event.addMetadata("error", {
						error: e,
						order: order
					})
				}
			)
			return false
		}
	},
	async [ActionTypes.REFRESH_ORDER_DELIVERY]({ state, commit }, payload) {
		try {
			if (
				state.currentOrder &&
				payload.order_id == state.currentOrder.id
			) {
				const response = await delivery_service.getDelivery(
					payload.delivery_id
				)
				if (response && response.data && response.data.delivery) {
					commit(MutationTypes.GOT_ORDER_DELIVERY, {
						orderId: payload.order_id,
						suborderId: payload.sub_order_id,
						delivery: response.data.delivery
					})
				} else {
					throw `Missing order delivery data`
				}
			}
			return true
		} catch (e) {
			vue.bugsnag.notify("Failed to refresh order delivery", (event) => {
				event.addMetadata("error", {
					error: e,
					payload: payload
				})
			})
			return false
		}
	},
	async [ActionTypes.UPDATE_ORDER_TRANSACTION_STATUS]({ commit }, payload) {
		try {
			const response = await payment_service.updateOrderTransactionStatus(
				payload.orderId,
				{
					status: payload.status,
					status_reason: payload.reasonText
				}
			)
			if (response.status == 200) {
				commit(
					MutationTypes.UPDATED_ORDER_TRANSACTION_STATUS,
					response.data.order_transaction
				)
				return true
			}
			throw `API Error: ${response.status}`
		} catch (e) {
			vue.bugsnag.notify(
				"Failed to update order transaction status",
				(event) => {
					event.addMetadata("error", {
						error: e,
						payload: payload
					})
				}
			)
			return false
		}
	},
	[ActionTypes.REMOVE_OLD_ORDERS]({ state, commit }) {
		const now = moment.utc()
		let hasChanges = false
		const updatedOrders = state.currentOrders.filter((order) => {
			let preorderDate = null
			order.sub_orders.find((suborder) => {
				preorderDate = orderHelper.preorderDate(order, suborder)
				if (preorderDate) {
					return true
				}
			})
			let dateToCheck = preorderDate ? preorderDate : order.created_time
			let cutoffMoment = moment.utc(dateToCheck).add(2, "h")
			if (now.isBefore(cutoffMoment)) {
				return true
			} else {
				hasChanges = true
				return false
			}
		})
		if (hasChanges) {
			commit(MutationTypes.RECALCULATED_CURRENT_ORDERS, updatedOrders)
		}
	},
	[ActionTypes.ORDER_TRANSACTION_STATUS_CHANGED]({ commit }, payload) {
		commit(MutationTypes.UPDATED_ORDER_TRANSACTION_STATUS, {
			order_id: payload.order_id,
			status: payload.status
		})
	},
	[ActionTypes.UPDATE_CURRENT_ORDER_REFUND](
		{ state, commit, getters },
		payload
	) {
		if (state.currentOrder && state.currentOrder.id == payload.orderId) {
			commit(MutationTypes.UPDATED_CURRENT_ORDER_REFUND, {
				refund: payload.refund,
				refundTypeMap: getters.refundTypeMap
			})
		}
	},
	[ActionTypes.UPDATE_CURRENT_ORDER_EXCEPTION](
		{ state, commit, getters },
		payload
	) {
		if (state.currentOrder && state.currentOrder.id == payload.orderId) {
			commit(MutationTypes.UPDATED_CURRENT_ORDER_EXCEPTION, {
				exception: payload.exception,
				exceptionTypeMap: getters.exceptionTypeMap
			})
		}
	}
}
