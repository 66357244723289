export const GOT_COUPONS = "GOT_COUPONS"
export const GOT_VOUCHERS = "GOT_VOUCHERS"
export const UPDATED_COUPON = "UPDATED_COUPON"
export const ADDED_COUPON = "ADDED_COUPON"
export const ADDED_COUPONS = "ADDED_COUPONS"
export const GOT_VOUCHER_REQUESTS = "GOT_VOUCHER_REQUESTS"
export const GOT_VOUCHER_REQUEST = "GOT_VOUCHER_REQUEST"
export const GOT_VOUCHER_REQUEST_VOUCHERS = "GOT_VOUCHER_REQUEST_VOUCHERS"
export const ADDED_VOUCHER_REQUEST = "ADDED_VOUCHER_REQUEST"
export const UPDATED_VOUCHER_REQUEST = "UPDATED_VOUCHER_REQUEST"
export const DELETED_VOUCHER_REQUEST = "DELETED_VOUCHER_REQUEST"
export const VOUCHER_EMAIL_SENT = "VOUCHER_EMAIL_SENT"
export const REMOVE_COUPON = "REMOVE_COUPON"
export const UPDATE_VOUCHER = "UPDATE_VOUCHER"
export const LOADING = "LOADING"
export const LOADED = "LOADED"
export const UPDATING = "UPDATING"
export const UPDATED = "UPDATED"

export default {
  GOT_COUPONS,
  GOT_VOUCHERS,
  UPDATED_COUPON,
  ADDED_COUPON,
  ADDED_COUPONS,
  GOT_VOUCHER_REQUESTS,
  GOT_VOUCHER_REQUEST,
  GOT_VOUCHER_REQUEST_VOUCHERS,
  ADDED_VOUCHER_REQUEST,
  UPDATED_VOUCHER_REQUEST,
  DELETED_VOUCHER_REQUEST,
  VOUCHER_EMAIL_SENT,
  REMOVE_COUPON,
  UPDATE_VOUCHER,
  LOADING,
  LOADED,
  UPDATING,
  UPDATED
}