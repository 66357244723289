<template>
	<SkBaseModal :isOpen="isOpen" @close="close" class="sk-modal-alt">
		<div class="sk-modal-alt-title">
			<h1>
				{{ title }}
				<span class="sk-modal-alt-subtitle">{{ subtitle }}</span>
			</h1>
		</div>
		<div class="sk-row">
			<SkInput
				ref="codeInput"
				:name="'Coupon Code'"
				:placeholder="'ABCDEF'"
				:required="true"
				v-model="coupon.code"
			/>
		</div>
		<div class="sk-row">
			<SkInput
				ref="amountInput"
				:name="'Coupon Amount'"
				:required="true"
				:type="'number'"
				v-model="coupon.amount"
			/>
			<SkDropdownSelect
				ref="typeInput"
				:classes="'sk-input'"
				v-model="couponType"
				:options="couponTypes"
			/>
		</div>
		<div class="sk-row">
			<TypeaheadInput
				:placeholder="'Applies To'"
				:fetch="autocompleteBusinesses"
				:serializer="typeaheadSerializer"
				:query="business.name"
				@selected="businessSelected"
			/>
		</div>
		<div class="sk-row">
			<SkInput
				ref="amountInput"
				:name="'Total Uses'"
				:placeholder="''"
				:disabled="true"
				:type="'number'"
				v-model="coupon.num_of_uses"
			/>
			<SkDropdownSelect
				ref="typeInput"
				:classes="'sk-input'"
				v-model="couponMaxUses"
				:options="couponMaxUsesValues"
			/>
		</div>

		<div class="sk-modal-actions">
			<button @click="close" class="button button-text">Close</button>
			<button
				type="button"
				class="button button-success"
				@click="saveCoupon"
			>
				<i class="sk-icon-check-regular"></i>
				{{ saveButtonText}}
			</button>
		</div>
	</SkBaseModal>
</template>

<script>
import ActionTypes from "@/store/modules/coupons/action-types"
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import SkInput from "@/components/SkInput.vue"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"
import TypeaheadInput from "@/components/TypeaheadInput.vue"
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"

export default {
	name: "CouponModal",
	mixins: [skModalMixin],
	components: {
		SkBaseModal,
		SkInput,
		SkDropdownSelect,
		TypeaheadInput
	},
	data: function () {
		return {
			business_id: null,
			ref_order_id: "",
			couponType: {
				id: null,
				text: "Type"
			},
			coupon: {
				id: null,
				code: "",
				amount: "",
				reason: "",
				type: null,
				ref_order_id: "",
				business_id: null,
				usage_limit: 0,
				num_of_uses: 0
			},
			couponTypes: [
				{
					id: null,
					text: "Type"
				},
				{
					id: 0,
					text: "$"
				},
				{
					id: 1,
					text: "%"
				}
			],
			couponMaxUses: {
				id: null,
				text: "Max Uses"
			},
			couponMaxUsesValues: [
				{
					id: null,
					text: "Max Uses"
				},
				{
					id: 0,
					text: "Unlimited"
				},
				{
					id: 1,
					text: "1"
				},
				{
					id: 2,
					text: "2"
				},
				{
					id: 3,
					text: "3"
				},
				{
					id: 4,
					text: "4"
				},
				{
					id: 5,
					text: "5"
				}
			]
		}
	},
	computed: {
		order() {
			return this.$store.state.ordersModule.currentOrder
		},
		orderId() {
			return this.ref_order_id
		},
		orderCoupon() {
			return (
				this.ref_order_id != "" &&
				this.ref_order_id != null &&
				this.ref_order_id != undefined
			)
		},
		title() {
			if (this.coupon.id) {
				return "Edit Coupon:"
			} else {
				if (this.ref_order_id && this.ref_order_id != "") {
					return "Issue Coupon"
				} else {
					return "Issue Promotion"
				}
			}
		},
		subtitle() {
			if (this.coupon.id) {
				return this.coupon.code
			} else {
				if (this.ref_order_id && this.ref_order_id != "") {
					return this.ref_order_id
				}
			}
			return ""
		},
		saveButtonText() {
			if (this.coupon.id) {
				return "Save Coupon"
			} else {
				if (this.ref_order_id && this.ref_order_id != "") {
					return "Issue Coupon"
				} else {
					return "Issue Promotion"
				}
			}
		},
		business() {
			if (this.business_id > 0) {
				return this.$store.getters.businessesMap[this.business_id]
			}
			return {}
		}
	},
	methods: {
		handleOptions(options) {
			if (options) {
				if (options.coupon) {
					this.coupon = {
						id: options.coupon.id,
						code: options.coupon.code,
						amount: options.coupon.amount,
						type: options.coupon.type,
						reason: options.coupon.reason,
						ref_order_id: options.coupon.ref_order_id,
						usage_limit: options.coupon.usage_limit,
						num_of_uses: options.coupon.num_of_uses
					}

					if (options.coupon.business_id) {
						this.business_id = options.coupon.business_id
					} else if (options.coupon.business) {
						this.business_id = options.coupon.business.id
					} else {
						this.business_id = null
					}
					this.coupon.business_id = this.business_id
					this.couponType =
						options.coupon.type == 0
							? { id: 0, text: "$" }
							: { id: 1, text: "%" }
					this.couponMaxUses =
						options.coupon.usage_limit == 0
							? {
									id: 0,
									text: "Unlimited"
							  }
							: {
									id: options.coupon.usage_limit,
									text: options.coupon.usage_limit.toString()
							  }
				} else {
					this.initCoupon()
				}
				if (options.ref_order_id) {
					this.ref_order_id = options.ref_order_id
				}
			} else {
				this.initCoupon()
			}
		},
		initCoupon() {
			this.couponMaxUses = {
				id: 0,
				text: "Unlimited"
			}
			this.business_id = null
			this.couponType = {
				id: null,
				text: "Type"
			}
			this.coupon = {
				code: "",
				amount: "",
				type: null,
				reason: "",
				ref_order_id: "",
				business_id: 0,
				usage_limit: 0,
				num_of_uses: 0
			}
		},
		validateCouponData() {
			const validCode = this.$refs.codeInput.validate()
			const validAmount = this.$refs.amountInput.validate()
			const validType = this.couponType != null
			return validCode && validAmount && validType
		},
		async autocompleteBusinesses(query) {
			const results = await this.$store.dispatch(
				"businessesModule/autocompleteBusinesses",
				query
			)
			return results
		},
		async businessSelected(event) {
			this.business_id = event.id
		},
		typeaheadSerializer(result) {
			return {
				text: result.name
			}
		},
		async saveCoupon() {
			const isCouponDataValid = this.validateCouponData()
			if (!isCouponDataValid) {
				return
			}
			this.coupon.business_id = this.business_id
			this.coupon.usage_limit = this.couponMaxUses.id
			this.coupon.type = this.couponType.id
			if (this.coupon.id && this.coupon.id > 0) {
				const retVal = await this.$store.dispatch(
					`couponsModule/${ActionTypes.UPDATE_COUPON}`,
					this.coupon
				)
				if (retVal) {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Coupon " + this.coupon.code + " updated!"
					})
					this.close()
				} else {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Error updating coupon, please try again",
						type: NotificationType.WARNING
					})
				}
			} else {
				const added = await this.$store.dispatch(
					`couponsModule/${ActionTypes.ADD_COUPON}`,
					{
						coupon: this.coupon
					}
				)
				if (added) {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Coupon " + this.coupon.code + " added!"
					})
					this.close()
				} else {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Error adding coupon, please try again",
						type: NotificationType.WARNING
					})
				}
			}
		}
	}
}
</script>