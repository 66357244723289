<template>
	<div class="sk-table-row">
		<div>{{ dropoffLocation.text }}</div>
		<div>
			<div
				class="text-truncate"
				@click="isDeliveryModeOpen = !isDeliveryModeOpen"
			>
				{{ deliveryMode }}
			</div>
		</div>
		<div>
			<div
				class="text-truncate"
				@click="isDescriptionOpen = !isDescriptionOpen"
			>
				{{ description }}
				<i
					v-if="hasDescription"
					class="expand-popover-icon sk-icon-chevron-down-regular"
					:class="{ flipped: isDescriptionOpen }"
				></i>
			</div>
			<SkPopover v-if="hasDescription" v-model="isDescriptionOpen">
				<div>{{ description }}</div>
			</SkPopover>
		</div>
		<div>
			<div
				class="text-truncate"
				@click="isCourierInstructionsOpen = !isCourierInstructionsOpen"
			>
				{{ courierInstructions }}
				<i
					v-if="hasCourierInstructions"
					class="expand-popover-icon sk-icon-chevron-down-regular"
					:class="{ flipped: isCourierInstructionsOpen }"
				></i>
			</div>
			<SkPopover
				v-if="hasCourierInstructions"
				v-model="isCourierInstructionsOpen"
			>
				<div>{{ courierInstructions }}</div>
			</SkPopover>
		</div>
		<div>
			<div
				class="text-truncate"
				@click="
					isCustomerInstructionsOpen = !isCustomerInstructionsOpen
				"
			>
				{{ customerInstructions }}
				<i
					v-if="hasCustomerInstructions"
					class="expand-popover-icon sk-icon-chevron-down-regular"
					:class="{ flipped: isCustomerInstructionsOpen }"
				></i>
			</div>
			<SkPopover
				v-if="hasCustomerInstructions"
				v-model="isCustomerInstructionsOpen"
			>
				<div>{{ customerInstructions }}</div>
			</SkPopover>
		</div>
		<div class="sk-table-column-fit">
			<div class="sk-table-actions">
				<button
					class="button button-icon"
					@click="actionSelected({ id: 2 })"
					title="Edit"
				>
					<i class="sk-icon-pencil-solid"></i>
				</button>
				<SkDropdownSelect
					:options="allowedActions"
					:iconClasses="'sk-icon-ellipsis-v-regular'"
					:showSelectedOption="false"
					:position="'left'"
					@selected="actionSelected"
				/>
			</div>
		</div>
	</div>
</template>


<style scoped>
.text-truncate {
	max-width: 200px;
}
</style>

<script>
import deliveryModeFormatterMixin from "@/mixins/business/delivery-mode-formatter-mixin"
import SkPopover from "@/components/SkPopover.vue"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"

export default {
	name: "BusinessDropoffLocationsLineItem",
	mixins: [deliveryModeFormatterMixin],
	components: {
		SkPopover,
		SkDropdownSelect
	},
	props: {
		dropoffLocation: Object
	},
	data: function () {
		return {
			isDescriptionOpen: false,
			isDeliveryModeOpen: false,
			isCourierInstructionsOpen: false,
			isCustomerInstructionsOpen: false
		}
	},
	computed: {
		hasDescription() {
			return this.dropoffLocation.description != undefined
		},
		description() {
			if (
				this.hasDescription &&
				this.dropoffLocation.description !== ""
			) {
				return this.dropoffLocation.description
			}
			return "N/A"
		},
		hasCourierInstructions() {
			return (
				this.dropoffLocation.courier_dropoff_instructions != undefined
			)
		},
		courierInstructions() {
			if (
				this.hasCourierInstructions &&
				this.dropoffLocation.courier_dropoff_instructions !== ""
			) {
				return this.dropoffLocation.courier_dropoff_instructions
			}
			return "N/A"
		},
		hasCustomerInstructions() {
			return (
				this.dropoffLocation.customer_pickup_instructions != undefined
			)
		},
		customerInstructions() {
			if (
				this.hasCustomerInstructions &&
				this.dropoffLocation.customer_pickup_instructions !== ""
			) {
				return this.dropoffLocation.customer_pickup_instructions
			}
			return "N/A"
		},
		business() {
			return this.$store.state.businessesModule.currentBusiness
		},
		deliveryMode() {
			if (this.dropoffLocation.delivery_mode != null) {
				return this.$options.filters.deliveryModeName(
					this.dropoffLocation.delivery_mode,
					this.business.type
				)
			}
		},
		allowedActions() {
			let actions = [
				{
					text: `<i class="sk-icon-trash-alt-solid"></i> Delete`,
					id: 1,
					highlight: true
				},
				{
					text: `<i class="sk-icon-pencil-solid"></i> Edit`,
					id: 2
				}
			]
			return actions
		}
	},
	methods: {
		deleteDropoffLocation() {
			this.$emit("delete", this.dropoffLocation)
		},
		editDropoffLocation() {
			this.$emit("update", this.dropoffLocation)
		},
		actionSelected(action) {
			switch (action.id) {
				case 1: {
					this.deleteDropoffLocation()
					break
				}
				case 2: {
					this.editDropoffLocation()
					break
				}
			}
		},
		toggleDeliveryMode() {
			this.isDeliveryModeOpen = !this.isDeliveryModeOpen
		}
	}
}
</script>
