import ActionTypes from "./action-types"
import MutationTypes from "./mutation-types"
import search_service from "@/services/search"
import Vue from "vue"
import gapi from "@/utils/gapi"
import { EntityType } from "@/enums/entityType"

const vue = new Vue()

export default {
	async [ActionTypes.GET_NEAREST_LOCATIONS]({ commit }, payload) {
		try {
			if (payload.locations.length == 1) {
				const location = payload.locations[0]
				const details = location.address && location.address.details ? location.address.details : null
				if (!details || !details.geometry) {
					throw "No gapi details available"
				}
				const latitude = details.geometry.location.lat()
				const longitude = details.geometry.location.lng()
				const radius = 3
				const recommendationParams = {
					distance: radius,
					latitude: latitude,
					longitude: longitude,
					order_by: "distance",
					sort_by: "asc",
					limit: 20
				}
				let responses = null
				if (location.type == EntityType.RESTAURANT) {
					responses = await Promise.all([
						gapi.nearbySearch(latitude, longitude, radius, "bar,bowling_alley,cafe,night_club,lodging"),
						search_service.searchRecommendedBusinesses(recommendationParams)
					])
				} else {
					responses = await Promise.all([
						gapi.nearbySearch(latitude, longitude, radius, "restaurant"),
						search_service.searchRecommendedRestaurants(recommendationParams)
					])
				}

				let inNetworkResults = []
				if (location.type == EntityType.RESTAURANT) {
					inNetworkResults = responses[1].data && responses[1].data.businesses ? responses[1].data.businesses : []
				} else {
					inNetworkResults = responses[1].data && responses[1].data.restaurants ? responses[1].data.restaurants : []
				}

				commit(MutationTypes.GOT_NEAREST_LOCATIONS_RESULTS, {
					locations: payload.locations,
					inNetworkResults: inNetworkResults,
					outOfNetworkResults: responses[0] ? responses[0] : []
				})
			}
			return true
		} catch (e) {
			vue.bugsnag.notify(
				"Failed to get nearest businesses",
				event => {
					event.addMetadata("error", {
						error: e,
						params: payload
					})
				}
			)
			return false
		}
	},
	[ActionTypes.CLEAR_LOCATIONS]({ commit }) {
		commit(MutationTypes.GOT_NEAREST_LOCATIONS_RESULTS, {
			inNetworkResults: [],
			outOfNetworkResults: []
		})
	}
}
