
import actions from "./actions"
import mutations from "./mutations"
import getters from "./getters"

const state = {
    loading: false,
    updating: false,
    currentRestaurant: {},
    restaurantMenuProducts: {},
    restaurantPOSDevices: [],
    restaurantProducts: [],
    restaurantBusinesses: [],
    currentMenuID: 0,
    categories: [],
    labels: [],
    integrations: [],
    squareLocations: [],
    hideIntegrationExplainer: false,
    supplies: [],
    supplyRequests: []
}

export default{
    namespaced: true, 
    state,
    mutations,
    actions,
    getters
};