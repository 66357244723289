<template>
	<div class="business-menus">
		<slot v-if="pagedPayouts.length > 0">
			<div class="sk-table sk-table-default">
				<div class="sk-table-header">
					<div class="sk-table-row">
						<div
							class="sk-table-column-checkbox"
							@click="batchSelectAllEntitiesClicked($event)"
						>
							<SkCheckbox
								class="sk-checkbox-green"
								:checked="allBatchableEntitiesSelected"
							/>
						</div>
						<div>Period</div>
						<div>Location</div>
						<div>Method</div>
						<div>W9</div>
						<div>Orders</div>
						<div>Sales</div>
						<div>Taxes</div>
						<div>Fees</div>
						<div>%</div>
						<div>Tips</div>
						<div>Exceptions</div>
						<div>Payout</div>
						<div>Status</div>
						<div>Actions</div>
					</div>
				</div>
				<transition-group
					name="list"
					tag="div"
					class="sk-table-body"
					appear
				>
					<RestaurantPayoutLineItem
						v-for="payout in pagedPayouts"
						:key="payout.id"
						:payout="payout"
						:batchSelectId="payout.id"
						:batchSelectedIds="batchSelectedIds"
						@batchSelected="batchSelectEntity"
						@approvePayout="approvePayout"
						@adjustPayoutAmount="adjustPayoutAmount"
						@clearPayout="clearPayout"
					/>
				</transition-group>
			</div>
			<PaginationFooter
				:contentText="'Payouts'"
				:pageSize="20"
				:total="payouts.length"
				@pageChanged="filterPage"
			/>
		</slot>
		<EmptyPlaceholderWidget
			v-else
			:title="`No Payouts left. Good job Jon!`"
			:description="`No payouts data available`"
		/>
		<ConfirmationModal ref="confirmationModal" />
		<ApproveRestaurantPayoutModal ref="approvePayoutModal" />
		<AdjustPayoutAmountModal ref="adjustPayoutAmountModal" />
		<ClearPayoutModal ref="clearPayoutModal" />
	</div>
</template>

<style scoped>
.business-menus {
	height: 100%;
	display: flex;
	flex-direction: column;
}
</style>

<script>
import { PayoutStatus } from "@/enums/payoutStatus"
import ActionTypes from "@/store/modules/finance/action-types"
import loaderMixin from "@/mixins/loader-mixin"
import RestaurantPayoutLineItem from "@/components/finance/RestaurantPayoutLineItem.vue"
import PaginationFooter from "@/components/PaginationFooter.vue"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue"
import EmptyPlaceholderWidget from "@/components/EmptyPlaceholderWidget.vue"
import ApproveRestaurantPayoutModal from "@/components/finance/modals/ApproveRestaurantPayoutModal.vue"
import AdjustPayoutAmountModal from "@/components/modals/AdjustPayoutAmountModal.vue"
import ClearPayoutModal from "@/components/modals/ClearPayoutModal.vue"
import SkCheckbox from "@/components/SkCheckbox.vue"
import batchSelectMixin from "@/mixins/batch-select-mixin"
import moment from "moment"

export default {
	name: "RestaurantPayouts",
	mixins: [batchSelectMixin, loaderMixin],
	components: {
		RestaurantPayoutLineItem,
		PaginationFooter,
		ConfirmationModal,
		EmptyPlaceholderWidget,
		ApproveRestaurantPayoutModal,
		AdjustPayoutAmountModal,
		ClearPayoutModal,
		SkCheckbox
	},
	props: {
		restaurantId: Number,
		method: Number,
		dateFrom: {
			type: String,
			default: ""
		},
		dateTo: {
			type: String,
			default: ""
		},
		w9Linked: {
			type: Number,
			default: -1
		},
		status: {
			type: Number,
			default: PayoutStatus.PENDING
		}
	},
	data: function () {
		return {
			pageRange: {
				page: 1,
				start: 0,
				end: 20
			},
			lastUpdatedDates: {
				dateFrom: null,
				dateTo: null
			}
		}
	},
	computed: {
		batchableEntityIds() {
			return this.pagedPayouts.map((payout) => payout.id)
		},
		payouts() {
			let retVal = this.$store.state.financeModule.restaurantPayouts
			if (this.status != null && this.status != -1) {
				retVal = retVal.filter(
					(payout) => payout.status.value == this.status
				)
			}

			if (this.restaurantId != -1) {
				retVal = retVal.filter(
					(payout) => payout.restaurant.id == this.restaurantId
				)
			}
			if (this.method != -1 && this.method > 0) {
				retVal = retVal.filter(
					(payout) =>
						payout.funding_source != undefined &&
						payout.funding_source.type.id == this.method
				)
			}

			if (this.dateFrom != null && this.dateFrom != "") {
				if (this.dateTo != null && this.dateTo != "") {
					retVal = retVal.filter(
						(payout) =>
							moment
								.utc(payout.from_date)
								.isSameOrAfter(this.dateFrom, "days") &&
							moment(payout.to_date).isSameOrBefore(
								this.dateTo,
								"days"
							)
					)
				} else {
					retVal = retVal.filter((refund) =>
						moment(refund.created_date).isSame(
							this.dateFrom,
							"days"
						)
					)
				}
			}
			if (this.w9Linked != -1) {
				if (this.w9Linked == 0) {
					retVal = retVal.filter(
						(payout) =>
							this.$store.state.restaurants[payout.restaurant.id]
								.w9_uploaded != 1
					)
				} else {
					retVal = retVal.filter(
						(payout) =>
							this.$store.state.restaurants[payout.restaurant.id]
								.w9_uploaded == 1
					)
				}
			}

			return retVal
				.slice()
				.sort((a, b) =>
					a.restaurant.name > b.restaurant.name ? 1 : -1
				)
		},
		pagedPayouts() {
			return this.payouts.slice(this.pageRange.start, this.pageRange.end)
		},
		dateChanged() {
			return `${this.dateFrom}_${this.dateTo}`
		}
	},
	methods: {
		filterPage(range) {
			this.pageRange = range
		},
		adjustPayoutAmount(payload) {
			this.$refs.adjustPayoutAmountModal.open({
				id: payload.payout.id,
				amount: payload.payout.amount
			})
		},
		clearPayout(payload) {
			this.$refs.clearPayoutModal.open({
				id: payload.payout.id
			})
		},
		cleanUpBatchIds() {
			this.batchSelectedIds = []
			this.$emit("update:selectedIds", [])
		},
		async approvePayout(payload) {
			try {
				const resp = await this.$refs.approvePayoutModal.open({
					payout: payload.payout
				})
				if (resp.approved) {
					var sendReceipt = 0
					if (resp.sendReceipt) {
						sendReceipt = 1
					}
					let apiPayload = {
						id: payload.payout.id,
						sendReceipt: sendReceipt
					}
					const updateResp = await this.$store.dispatch(
						`financeModule/${ActionTypes.UPDATE_RESTAURANT_PAYOUT}`,
						apiPayload
					)
					if (updateResp) {
						this.$store.dispatch(
							`financeModule/${ActionTypes.APPROVE_RESTAURANT_PAYOUT}`,
							payload.payout.id
						)
					}
				}
			} catch (e) {
				this.bugsnag.notify("Unable to approve payouts", (event) => {
					event.addMetadata("error", {
						error: e,
						payload: payload
					})
				})
			}
		},
		setDefaultDate() {
			const end = moment.utc().hours(0).minutes(0).seconds(0)
			const start = moment
				.utc()
				.hours(0)
				.minutes(0)
				.seconds(0)
				.add(-1, "M")
			this.lastUpdatedDates = {
				start: start.format("YYYY-MM-DD"),
				end: end.format("YYYY-MM-DD")
			}
			this.$emit("update:dateFrom", start.format("YYYY-MM-DD"))
			this.$emit("update:dateTo", end.format("YYYY-MM-DD"))
			this.$emit("setDefaultDate", {
				start: start.format("MMM DD, YYYY"),
				end: end.format("MMM DD, YYYY")
			})
			return this.lastUpdatedDates
		}
	},
	watch: {
		batchSelectedIds: {
			immediate: true,
			handler(ids) {
				this.$emit("update:selectedIds", ids)
			}
		},
		dateChanged(newVal) {
			let lastUpdatedDates = `${this.lastUpdatedDates.start}_${this.lastUpdatedDates.end}`
			if (lastUpdatedDates == newVal) {
				return
			}

			let filters = {
				start: this.dateFrom,
				end: this.dateTo
			}

			if (filters.start == null || filters.end == null) {
				filters = this.setDefaultDate()
			}

			this.toggleLoader(true)
			this.$store
				.dispatch(
					`financeModule/${ActionTypes.GET_RESTAURANT_PAYOUTS}`,
					filters
				)
				.finally(() => {
					this.toggleLoader(false)
				})
		}
	},
	mounted: function () {
		this.toggleLoader(true)
		let defaultDates = this.setDefaultDate()
		this.$store
			.dispatch(`financeModule/${ActionTypes.GET_RESTAURANT_PAYOUTS}`, {
				start: defaultDates.start,
				end: defaultDates.end
			})
			.finally(() => {
				this.toggleLoader(false)
			})
	}
}
</script>