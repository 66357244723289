import Vue from 'vue'
import moment from "moment"
import { OrderActionType } from "@/enums/orderActionType"
import MutationTypes from "@/store/modules/orders/mutation-types"
import OrderAction from "@/models/OrderAction"
import { OrderStatus } from '@arikgaisler/utils/enums/orderStatus'

export default {
	gotOrderWarnings(state, payload) {
		Vue.set(payload.order, "warnings", payload.warnings)
	},
	gotOrderNotes(state, payload) {
		Vue.set(payload.order, "notes", payload.notes)
	},
	gotBusiness(state, payload) {
		state.currentBusiness = payload
	},
	claimedOrder(state, payload) {
		Vue.set(payload.order, "admin_user_id", payload.user.id)
	},
	gotCurrentOrders(state, orders) {
		state.currentOrders = orders
	},
	gotCurrentOrdersTransactions(state, transactions) {
		const transactionOrderIds = transactions.map(
			(transaction) => transaction.order_id
		)
		for (let i = 0; i < state.currentOrders.length; i++) {
			let order = state.currentOrders[i]
			const transactionIndex = transactionOrderIds.indexOf(order.id)
			if (transactionIndex > -1) {
				Vue.set(order, "transaction", transactions[transactionIndex])
				if (transactionOrderIds.length == 1) {
					break
				}
			}
		}
	},
	gotOrderStatus(state, payload) {
		state.currentOrders.unshift(payload)
	},
	gotOrderWarning(state, payload) {
		const order = state.currentOrders.find(
			(order) => order.id == payload.order_id
		)
		if (order && payload.warning) {
			if (!order.warnings) {
				Vue.set(order, "warnings", [payload.warning])
			} else {
				order.warnings.push(payload.warning)
			}
		}
		if (
			state.currentOrder &&
			state.currentOrder.id == payload.order_id &&
			payload.warning
		) {
			if (!state.currentOrder.warnings) {
				Vue.set(state.currentOrder, "warnings", [payload.warning])
			} else {
				state.currentOrder.warnings.push(payload.warning)
			}
		}
	},
	gotOrderNote(state, payload) {
		const order = state.currentOrders.find(
			(order) => order.id == payload.order_id
		)
		if (order && payload) {
			if (!order.notes) {
				Vue.set(order, "notes", [payload.note])
			} else {
				order.notes.push(payload.note)
			}
		}
	},
	orderStatusChanged(state, payload) {
		let currentOrderIndex = state.currentOrders.findIndex(
			(order) => order.id == payload.order.id
		)
		if (payload.copyActions && currentOrderIndex > -1) {
			let existingOrder = state.currentOrders[currentOrderIndex]
			if (existingOrder && existingOrder.warnings) {
				payload.order.warnings = existingOrder.warnings.slice()
			}
			if (existingOrder && existingOrder.actions) {
				payload.order.actions = existingOrder.actions.slice()
			}
		}

		if (currentOrderIndex > -1) {
			Vue.set(state.currentOrders, currentOrderIndex, payload.order)
		}

		if (state.currentOrder && state.currentOrder.id == payload.order.id) {
			state.currentOrder = payload.order
		}

		let orderReportIndex = state.orderReports.findIndex(
			(order) => order.id == payload.order.id
		)
		if (orderReportIndex >= 0) {
			let actions = payload.order.actions ? payload.order.actions : []
			Vue.set(state.orderReports[orderReportIndex], "actions", actions)
		}
	},
	gotOrderCoupons(state, payload) {
		payload.order.coupons = payload.uses
	},
	setCurrentOrder(state, order) {
		state.currentOrder = order

		if (!order) {
			state.currentOrderDeliveries = null
		}
	},
	updatedOrderStatus(state, payload) {
		const order = state.currentOrders.find(
			(order) => order.id == payload.orderId
		)
		if (!order) {
			return
		}

		if (payload.status == OrderStatus.PROCESSING) {
			Vue.set(order, "accept_time", moment.utc())
		} else if (payload.status == OrderStatus.COMPLETED) {
			Vue.set(order, "completed_time", moment.utc())
		} else if (payload.status == OrderStatus.CANCELLED) {
			Vue.set(order, "cancelled", 1)
		} else if (payload.status == 3) {
			Vue.set(order, "pickup_time", moment.utc())
		} else if (payload.status == 4) {
			Vue.set(order, "delivered_time", moment.utc())
		}
	},
	addedException() {},
	gotOrderExceptionReasons(state, payload) {
		state.exception_types = payload.exceptions
	},
	gotOrderReports(state, payload) {
		payload.reports.orders.forEach((order) => {
			let actions = []
			if (order.refunds) {
				actions = actions.concat(
					order.refunds.map((refund) => {
						return new OrderAction({
							type: OrderActionType.REFUND,
							name: "Refund",
							reason:
								refund.reason_type == 10
									? refund.reason
									: payload.refundTypeMap[refund.reason_type],
							status: refund.status,
							amount: refund.amount,
							id: refund.id
						})
					})
				)
			}
			if (order.exceptions) {
				actions = actions.concat(
					order.exceptions.map((exception) => {
						return new OrderAction({
							type: OrderActionType.EXCEPTION,
							name: "Exception",
							reason: payload.exceptionTypeMap[
								exception.reason_type
							],
							status: exception.status,
							amount: exception.amount,
							id: exception.id
						})
					})
				)
			}
			order.actions = actions
			order.coupons = order.coupon_uses
		})
		state.orderReports = payload.reports.orders
		state.totalOrderReportsCount = payload.reports.order_count
	},
	clearOrderReports(state) {
		state.orderReports = []
		state.totalOrderReportsCount = 0
	},
	loading(state) {
		state.loading = true
	},
	loaded(state) {
		state.loading = false
	},
	setDropoffLocations(state, payload) {
		state.orderDropoffLocations = payload
	},
	[MutationTypes.GOT_ORDER_ACTIONS](state, payload) {
		Vue.set(payload.order, "actions", payload.actions)
	},
	[MutationTypes.GOT_ORDER_DELIVERIES](state, payload) {
		state.currentOrderDeliveries = payload
	},
	[MutationTypes.GOT_ORDER_DELIVERY](state, payload) {
		if (state.currentOrder && payload.orderId == state.currentOrder.id) {
			if (!state.currentOrderDeliveries) {
				state.currentOrderDeliveries = [payload.delivery]
				return
			}

			const deliveryIndex = state.currentOrderDeliveries.findIndex(
				(delivery) => delivery.id == payload.delivery.id
			)
			if (deliveryIndex > -1) {
				state.currentOrderDeliveries.splice(
					deliveryIndex,
					1,
					payload.delivery
				)
			} else {
				state.currentOrderDeliveries.push(payload.delivery)
				const suborder = state.currentOrder.sub_orders.find(
					(suborder) => suborder.id == payload.suborderId
				)
				if (suborder) {
					Vue.set(suborder, "delivery_job_id", payload.delivery.id)
				}
			}
		}
	},
	[MutationTypes.RECALCULATED_CURRENT_ORDERS](state, orders) {
		state.currentOrders = orders
	},
	[MutationTypes.UPDATED_ORDER_TRANSACTION_STATUS](
		state,
		updatedTransaction
	) {
		const orderId = updatedTransaction.order_id
		if (state.orderReports.length > 0) {
			const order = state.orderReports.find(
				(order) => order.id == orderId
			)
			if (
				order &&
				order.payment_method &&
				order.payment_method.payment_status
			) {
				order.payment_method.payment_status.status =
					updatedTransaction.status
			}
		}
		if (state.currentOrders.length > 0) {
			const order = state.currentOrders.find(
				(order) => order.id == orderId
			)
			if (order && order.transaction) {
				order.transaction.status = updatedTransaction.status
			}
		}
	},
	[MutationTypes.UPDATED_CURRENT_ORDER_REFUND](state, payload) {
		const refundAction = new OrderAction({
			type: OrderActionType.REFUND,
			name: "Refund",
			reason:
				payload.refund.reason_id == 10
					? payload.refund.reason
					: payload.refundTypeMap[payload.refund.reason_id],
			status: payload.refund.status,
			amount: payload.refund.amount,
			id: payload.refund.refund_id
		})
		if (state.currentOrder && state.currentOrder.actions) {
			const refundIndex = state.currentOrder.actions.findIndex(
				(action) =>
					action.type == OrderActionType.REFUND &&
					refundAction.id == action.id
			)
			if (refundIndex > -1) {
				state.currentOrder.actions.splice(refundIndex, 1, refundAction)
			} else {
				state.currentOrder.actions.push(refundAction)
			}
		} else {
			Vue.set(state.currentOrder, "actions", [refundAction])
		}
	},
	[MutationTypes.UPDATED_CURRENT_ORDER_EXCEPTION](state, payload) {
		const exceptionAction = new OrderAction({
			type: OrderActionType.EXCEPTION,
			name: "Exception",
			reason: payload.exceptionTypeMap[payload.exception.reason_id],
			status: payload.exception.status,
			amount: payload.exception.amount,
			id: payload.exception.exception_id
		})
		if (state.currentOrder && state.currentOrder.actions) {
			const exceptionIndex = state.currentOrder.actions.findIndex(
				(action) =>
					action.type == OrderActionType.EXCEPTION &&
					action.id == exceptionAction.id
			)
			if (exceptionIndex > -1) {
				state.currentOrder.actions.splice(
					exceptionIndex,
					1,
					exceptionAction
				)
			} else {
				state.currentOrder.actions.push(exceptionAction)
			}
		} else {
			Vue.set(state.currentOrder, "actions", [exceptionAction])
		}
	}
}