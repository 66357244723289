import { store } from "@/store/store"
import { UserRole, UserSubRole } from "@/enums/userRole"

export const PermissionMethod = {
    NEW_BUSINESS: "new_business",
    DELETE_BUSINESS: "delete_business",
    NEW_BUSINESS_RESTAURANT: "new_business_restaurant",
    EDIT_BUSINESS_PRODUCTS: "edit_business_products",
    NEW_BUSINESS_MENU: "new_business_menu",
    DELETE_BUSINESS_MENU: "delete_business_menu",
    NEW_EVENT: "new_event",
    EDIT_EVENT: "edit_event",
    SAVE_EVENT: "save_event",
    CANCEL_EVENT: "cancel_event",
    DELETE_EVENT: "delete_event",
    CONFIRM_EVENT: "confirm_event",
    ACCEPT_EVENT: "accept_event",
    COMPLETE_EVENT: "complete_event",
    DELIVER_EVENT: "deliver_event",
    END_EVENT: "end_event",
    SEND_RECEIPT: "send_receipt",
    MARK_EXCEPTION: "mark_exception",
    EDIT_VOUCHER: "edit_voucher",
    APPROVE_VOUCHER_REQUEST: "approve_voucher_request",
    NEW_RESTAURANT: "new_restaurant",
    NEW_RESTAURANT_MENU: "new_restaurant_menu",
    NEW_RESTAURANT_ITEM: "new_restaurant_item",
    BATCH_UPDATE_RESTAURANT_ITEMS: "batch_update_restaurant_items",
    IMPORT_RESTAURANT_ITEM: "import_restaurant_item",
    ISSUE_REFUND: "issue_refund",
    APPROVE_PAYOUTS: "approve_payouts",
    LINK_TO_BUSINESS: "link_to_business",
    EDIT_RESTAURANT: "edit_restaurant",
    EDIT_RESTAURANT_ITEM_PRICING: "edit_restaurant_item_pricing",
    EDIT_RESTAURANT_SCHEDULE: "edit_restaurant_schedule",
    DELETE_RESTAURANT: "delete_restaurant",
    DELETE_RESTAURANT_MENU: "delete_restaurant_menu",
    ARCHIVE_RESTAURANT_ITEM: "archive_restaurant_item",
    UNARCHIVE_RESTAURANT_ITEM: "unarchive_restaurant_item",
    DELETE_RESTAURANT_ITEM: "delete_restaurant_item",
    APPROVE_SUPPLY_REQUEST: "approve_supply_request",
    REJECT_SUPPLY_REQUEST: "reject_supply_request",
    CREATE_DELIVERY: "create_delivery",
    NOTIFY_GUEST: "notify_guest",
    MARK_IN_ROOM_BILLING_POSTED: "mark_in_room_billing_posted",
    MARK_IN_ROOM_BILLING_DISPUTED: "mark_in_rool_billing_disputed",
    REPUSH_ORDER: "repush_order"
}

export const PermissionTab = {
    BUSINESS_ADMIN: "business_admin",
    RESTAURANT_ADMIN: "restaurant_admin",
    FINANCE_ADMIN: "finance_admin",
    USERS_ADMIN: "users_admin",
    ADMIN: "admin",
    EVENT_COGS: "event_cogs",
    ORDER_COGS: "order_cogs",
    BUSINESS_MENU_DETAILS: "business_menu_details",
    RESTAURANT_MENU_DETAILS: "restaurant_menu_details",
    BUSINESS_MENU_HOURS: "business_menu_hours",
    RESTAURANT_MENU_HOURS: "restaurant_menu_hours",
    MENU_DETAILS: "menu_details",
    MENU_HOURS: "menu_hours",
    ORDER_ACTIONS: "order_actions",
    ORDER_WARNINGS: "order_warnings",
    ORDER_NOTES: "order_notes",
    COUPONS: "coupons",
    CHEF_ADMINS: "chef_admins",
    FINANCE_EXCEPTIONS: "finance_exceptions",
    FINANCE_PAYOUT_LOCATIONS: "finance_payout_locations",
    FINANCE_INVOICE_LOCATIONS: "finance_invoice_locations",
    ACTIVE_ORDERS: "active_orders",
    ADMIN_ACTIVE_ORDERS: "admin_active_orders"
}

export const PermissionPage = {
    BUSINESS_DASHBOARD: "business_dashboard",
    BUSINESS_INSIGHTS: "business_insights",
    RESTAURANT_INSIGHTS: "restaurant_insights",
    ACTIVE_ORDERS: "active_orders",
    ORDER_REPORTS: "order_reports",
    MANAGE_BUSINESSES: "manage_businesses",
    MANAGE_RESTAURANTS: "manage_restaurants",
    MANAGE_EVENTS: "manage_events",
    MANAGE_PROMOTIONS: "manage_promotions",
    SALES: "sales",
    FINANCE: "finance",
    USERS: "user",
    SUPPLIES: "supplies",
    PREPACKAGES: "prepackages",
    HUBS: "hubs"
}

export const PermissionInfo = {
    ORDER_REFUNDS: "order_refunds",
    ORDER_EXCEPTIONS: "order_exceptions",
    ORDER_COUPONS: "order_coupons",
    ORDER_NOTES: "order_notes",
    ORDER_COGS: "order_cogs",
    INSIGHTS_SALES_DOWNLOAD: "insight_sales_download",
    ORDER_FULL_PRICE: "order_full_price",
    MARKUP_RULES: "markup_rules",
    RESTAURANT_PAYOUTS: "restaurant_payouts",
    BUSINESS_INVOICES: "business_invoices",
    BUSINESS_SUPPLIES: "business_supplies",
    RESTAURANT_SUPPLIES: "restaurant_supplies",
    RESTAURANT_INTEGRATION_GRUBHUB: "restaurant_integration_grubhub",
    BUSINESS_MENU_CATEGORY_HOURS: "business_menu_category_hours"
}

export default {
    calculatePermissions(role, subrole, extraData) {
        let permissions = []
        switch (role) {
            case UserRole.SUPER_ADMIN: {
                const hiddenTabs = [PermissionTab.CHEF_ADMINS]
                const hiddenMethods = []
                const hiddenPages = []
                const hiddenInfo = []

                const methods = Object.values(PermissionMethod).filter(method => !hiddenMethods.includes(method))
                const tabs = Object.values(PermissionTab).filter(tab => !hiddenTabs.includes(tab))
                const pages = Object.values(PermissionPage).filter(page => !hiddenPages.includes(page))
                const info = Object.values(PermissionInfo).filter(info => !hiddenInfo.includes(info))
                permissions = [].concat(methods, tabs, pages, info)
                break
            }
            case UserRole.BUSINESS_ADMIN: {
                permissions = this.calculateBusinessAdminPermissions(subrole, extraData)
                break
            }
            case UserRole.RESAURANT_ADMIN: {
                permissions = this.calculateRestaurantAdminPermissions(subrole)
                break
            }
        }
        return permissions
    },
    calculateBusinessAdminPermissions(subrole, extraData) {
        let permissions = [].concat([
            PermissionPage.BUSINESS_DASHBOARD,
            PermissionPage.MANAGE_BUSINESSES,
            PermissionPage.SUPPLIES,
            PermissionInfo.BUSINESS_SUPPLIES,
            PermissionPage.USERS,
            PermissionTab.CHEF_ADMINS,
            PermissionInfo.ORDER_FULL_PRICE,
            PermissionPage.BUSINESS_INSIGHTS,
            PermissionMethod.NOTIFY_GUEST,
            PermissionMethod.MARK_IN_ROOM_BILLING_POSTED,
            PermissionMethod.MARK_IN_ROOM_BILLING_DISPUTED
        ])

        if (extraData.hasHotel || extraData.hasCoworking) {
            permissions = permissions.concat([
                PermissionPage.MANAGE_PROMOTIONS,
                PermissionPage.PREPACKAGES
            ])
        }

        switch (subrole) {
            case UserSubRole.ADMIN: {
                permissions = permissions.concat([
                    PermissionTab.ORDER_ACTIONS,
                    PermissionInfo.ORDER_REFUNDS,
                    PermissionPage.USERS,
                    // PermissionPage.FINANCE,
                    PermissionTab.CHEF_ADMINS,
                    // PermissionTab.FINANCE_INVOICE_LOCATIONS,
                    PermissionInfo.BUSINESS_INVOICES
                ])
                break
            }
        }

        return permissions
    },
    calculateRestaurantAdminPermissions(subrole) {
        let permissions = [].concat([
            PermissionPage.MANAGE_RESTAURANTS,
            PermissionPage.SUPPLIES,
            PermissionInfo.RESTAURANT_SUPPLIES,
            PermissionInfo.ORDER_COGS,
            PermissionPage.RESTAURANT_INSIGHTS,
            PermissionInfo.INSIGHTS_SALES_DOWNLOAD,
            PermissionTab.ORDER_ACTIONS,
            PermissionInfo.ORDER_EXCEPTIONS
        ])
        switch (subrole) {
            case UserSubRole.ADMIN: {
                permissions = permissions.concat([
                    PermissionPage.FINANCE,
                    PermissionTab.FINANCE_PAYOUT_LOCATIONS,
                    PermissionInfo.RESTAURANT_PAYOUTS
                ])
                break
            }
            case UserSubRole.STAFF: {
                break
            }
        }

        return permissions
    },
    //BACKWARD COMPATIBILITY
    method_allowed(method) {
        return store.state.permissions.includes(method)
    },
    tab_allowed(tab) {
        return store.state.permissions.includes(tab)
    },
    page_allowed(page) {
        return store.state.permissions.includes(page)
    },
    info_allowed(info) {
        return store.state.permissions.includes(info)
    },
}