export const REQUEST_PREPACKAGES = "REQUEST_PREPACKAGES"
export const GET_PREPACKAGED_ORDERS = "GET_PREPACKAGED_ORDERS"
export const GET_WEEKLY_PREPACKAGED_ORDERS = "GET_WEEKLY_PREPACKAGED_ORDERS"
export const GET_PREPACKAGED_PRODUCTS = "GET_PREPACKAGED_PRODUCTS"
export const CLEAR_PREPACKAGED_PRODUCTS = "CLEAR_PREPACKAGED_PRODUCTS"
export const ADD_PREPACKAGED_ORDERS = "ADD_PREPACKAGED_ORDERS"
export const UPDATE_PREPACKAGED_ORDER = "UPDATE_PREPACKAGED_ORDER"
export const CANCEL_PREPACKAGED_ORDER = "CANCEL_PREPACKAGED_ORDER"
export const GET_PREPACKAGED_MANIFEST = "GET_PRE_PACKAGED_MANIFEST"

export default {
	REQUEST_PREPACKAGES,
	GET_PREPACKAGED_ORDERS,
	GET_WEEKLY_PREPACKAGED_ORDERS,
	GET_PREPACKAGED_PRODUCTS,
	CLEAR_PREPACKAGED_PRODUCTS,
	ADD_PREPACKAGED_ORDERS,
	UPDATE_PREPACKAGED_ORDER,
	CANCEL_PREPACKAGED_ORDER,
	GET_PREPACKAGED_MANIFEST
}