<template>
	<div>
		<div class="sk-row">
			<div class="sk-widget sk-event-review-widget">
				<div class="sk-widget-line-item-header">
					<span>Distribution Type</span>
				</div>
				<div class="sk-widget-line-item">
					<div>{{ distributionTypeName }}</div>
				</div>
			</div>
		</div>
		<div class="sk-row">
			<div class="sk-widget sk-event-review-widget">
				<div class="sk-widget-line-item-header">
					<span>Voucher Request Details</span>
				</div>
				<div class="sk-widget-line-item">
					<div>Name</div>
					<div>{{ voucherRequestTitle }}</div>
				</div>
				<div class="sk-widget-line-item">
					<div>Description</div>
					<div v-sk-unassigned="voucherRequest.description"></div>
				</div>
				<div class="sk-widget-line-item">
					<div>Business Name</div>
					<div v-sk-unassigned="businessName"></div>
				</div>
				<div class="sk-widget-line-item">
					<div>Voucher Value</div>
					<div v-sk-unassigned="amount"></div>
				</div>
				<div class="sk-widget-line-item">
					<div>Quantity</div>
					<div v-sk-unassigned="voucherRequest.quantity"></div>
				</div>
			</div>
		</div>
		<div class="sk-row" v-if="showGuestDetails">
			<div class="sk-widget sk-event-review-widget">
				<div class="sk-widget-line-item-header">
					<span>Guest Details ({{ guestsLengthStr }})</span>
				</div>
				<section
					class="add-user-section"
					v-for="(guestKey, index) in guestKeys"
					:key="guestKey"
				>
					<div
						class="filter-group-header"
						@click="toggleExpandGroup(index)"
					>
						Guest #{{ index + 1 }}
						<div class="filter-group-divider"></div>
						<i
							class="sk-icon-chevron-down-regular"
							:class="{ flipped: expandedGroupIds[index] }"
						></i>
					</div>
					<transition-expand>
						<div v-if="expandedGroupIds[index]">
							<div class="sk-widget-line-item">
								<div>Email</div>
								<div v-sk-unassigned="guestKey"></div>
							</div>
							<div class="sk-widget-line-item">
								<div>Name</div>
								<div
									v-sk-unassigned="
										guestDistributionsHash[guestKey].name
									"
								></div>
							</div>
							<div class="sk-widget-line-item">
								<div>Phone</div>
								<div
									v-sk-unassigned="
										guestDistributionsHash[guestKey].phone
									"
								></div>
							</div>
							<div class="sk-widget-line-item">
								<div>Room</div>
								<div
									v-sk-unassigned="
										guestDistributionsHash[guestKey].room
									"
								></div>
							</div>
							<div class="sk-widget-line-item">
								<div>Vouchers Sent</div>
								<div
									v-sk-unassigned="
										guestDistributionsHash[guestKey]
											.quantity
									"
								></div>
							</div>
						</div>
					</transition-expand>
				</section>
			</div>
		</div>
		<div class="sk-row">
			<div class="sk-widget sk-event-review-widget">
				<div class="sk-widget-line-item-header">
					<span>Payment Type</span>
				</div>
				<div class="sk-widget-line-item">
					<div>{{ paymentTypeName }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.add-user-section {
	border-bottom: 1px solid var(--sk-grey);
	margin-bottom: 30px;
	padding-bottom: 10px;
}

.filter-group-header {
	font-size: 14px;
	font-weight: 600;
	color: var(--sk-navy);
	display: flex;
	align-items: center;
	width: 100%;
	cursor: pointer;
	margin-top: 18px;
	padding: 0 20px;
}

.filter-group-header .sk-icon-chevron-down-regular {
	margin-left: 15px;
	font-size: 10px;
	color: var(--sk-grey2);
}

.filter-group-divider {
	height: 1px;
	background: rgba(112, 120, 124, 0.35);
	margin-left: 15px;
}
</style>
<script>
import { VoucherDistributionType } from "@/enums/voucherDistributionType"
import { VoucherPaymentType } from "@/enums/voucherPaymentType"
import currencyFilterMixin from "@/mixins/currency-filter-mixin"
import pluralizeFilterMixin from "@/mixins/pluralize-filter-mixin"
import loaderMixin from "@/mixins/loader-mixin"
import TransitionExpand from "@/components/transitions/TransitionExpand.vue"
import phoneFilterMixin from "@/mixins/phone-filter-mixin"

export default {
	name: "VoucherRequestDetails",
	mixins: [
		loaderMixin,
		currencyFilterMixin,
		phoneFilterMixin,
		pluralizeFilterMixin
	],
	components: {
		TransitionExpand
	},
	props: {
		voucherRequest: Object
	},
	data: function () {
		return {
			expandedGroupIds: {}
		}
	},
	computed: {
		voucherRequestTitle() {
			return this.voucherRequest.name
				? this.voucherRequest.name
				: "Voucher Request"
		},
		isEditable() {
			return true
		},
		phone() {
			if (
				this.voucherRequest.user_phone &&
				this.voucherRequest.user_phone != ""
			) {
				let code = this.voucherRequest.user_phone.startsWith("+")
					? null
					: `+1`
				return this.$options.filters.formatInternationalPhone(
					this.voucherRequest.user_phone,
					code
				)
			} else {
				return "N/A"
			}
		},
		guestKeys() {
			if (this.guestDistributionsHash) {
				return Object.keys(this.guestDistributionsHash)
			} else {
				return null
			}
		},
		guestsLengthStr() {
			const guestsCountText = this.$options.filters.pluralize(
				this.guestKeys.length,
				"Guest"
			)
			return `${this.guestKeys.length} ${guestsCountText}`
		},
		guestDistributionsHash() {
			if (
				this.voucherRequest.guest_distributions &&
				this.voucherRequest.guest_distributions.length > 0
			) {
				return this.voucherRequest.guest_distributions.reduce(
					(allGuestsHash, currentGuest) => {
						if (!allGuestsHash[currentGuest.email]) {
							allGuestsHash[currentGuest.email] = currentGuest
						}
						return allGuestsHash
					},
					{}
				)
			} else {
				let retVal = {}

				retVal[this.voucherRequest.user_email] = {
					id: this.voucherRequest.id,
					quantity: this.voucherRequest.quantity,
					name: this.voucherRequest.user_name,
					phone: this.voucherRequest.user_phone,
					email: this.voucherRequest.user_email
				}
				return retVal
			}
		},
		distributionTypeName() {
			switch (this.voucherRequest.distribution_type) {
				case VoucherDistributionType.PRINT_NOW:
					return "Printed"
				case VoucherDistributionType.SEND_TO_GUEST:
					return "Sent to Guest"
				case VoucherDistributionType.SEND_TO_ME:
					return "Send to Me"
				case VoucherDistributionType.DIGITAL_ONLY:
					return "N/A"
			}
			return "N/A"
		},
		paymentTypeName() {
			switch (this.voucherRequest.payment_type) {
				case VoucherPaymentType.PRE_PAID:
					return "Pre Paid"
				case VoucherPaymentType.PAY_AS_YOU_GO:
					return "Pay as you Go"
			}
			return "N/A"
		},
		businessName() {
			if (this.voucherRequest.business_id > 0) {
				return this.$store.getters.businessesMap[
					this.voucherRequest.business_id
				].name
			}
			return ""
		},
		amount() {
			return "$" + this.voucherRequest.amount
		},
		showGuestDetails() {
			return (
				this.voucherRequest.distribution_type ==
				VoucherDistributionType.SEND_TO_GUEST
			)
		}
	},
	methods: {
		openEditModal(step) {
			this.$emit("editVoucherRequest", {
				eventId: this.voucherRequest.id,
				step: step
			})
		},
		toggleExpandGroup(groupId) {
			if (this.expandedGroupIds[groupId]) {
				this.$set(this.expandedGroupIds, groupId, false)
			} else {
				this.$set(this.expandedGroupIds, groupId, true)
				Object.keys(this.expandedGroupIds).forEach((key) => {
					if (key != groupId) {
						this.$set(this.expandedGroupIds, key, false)
					}
				})
			}
		}
	}
}
</script>