<template>
	<div class="invoice-locations">
		<slot v-if="pagedLocations.length > 0">
			<div class="sk-table sk-table-default">
				<div class="sk-table-header">
					<div class="sk-table-row">
						<div>Business</div>
						<div>Address</div>
						<div>Next Invoice</div>
						<div>Bank Account</div>
						<div>W9</div>
						<div>Actions</div>
					</div>
				</div>
				<transition-group
					name="list"
					tag="div"
					class="sk-table-body"
					appear
				>
					<InvoiceLocationLineItem
						v-for="location in pagedLocations"
						:key="location.funding_source.entity_id"
						:location="location"
					/>
				</transition-group>
			</div>
			<PaginationFooter
				:contentText="'Locations'"
				:pageSize="20"
				:total="locations.length"
				@pageChanged="filterPage"
			/>
		</slot>
		<EmptyPlaceholderWidget
			v-else
			:title="`No Locations`"
			:description="`No registered locations`"
		/>
	</div>
</template>

<style scoped>
.invoice-locations {
	height: 100%;
	display: flex;
	flex-direction: column;
}
</style>

<script>
import { FundingSource } from "@/enums/fundingSource"
import InvoiceLocationLineItem from "@/components/finance/InvoiceLocationLineItem.vue"
import PaginationFooter from "@/components/PaginationFooter.vue"
import EmptyPlaceholderWidget from "@/components/EmptyPlaceholderWidget.vue"

export default {
	name: "InvoiceLocations",
	components: {
		InvoiceLocationLineItem,
		PaginationFooter,
		EmptyPlaceholderWidget
	},
	props: {
		dateFrom: {
			type: String,
			default: ""
		},
		dateTo: {
			type: String,
			default: ""
		},
		businessId: {
			type: Number,
			default: -1
		},
		w9Linked: {
			type: Number,
			default: -1
		},
		bankLinked: {
			type: Number,
			default: -1
		}
	},
	data: function () {
		return {
			pageRange: {
				page: 1,
				start: 0,
				end: 20
			},
			menuDeleted: false
		}
	},
	computed: {
		locations() {
			let locations = this.$store.state.financeModule.businessesFinanceDetails
			if (this.businessId != -1) {
				locations = locations.filter(
					(location) =>
						location.funding_source.entity_id == this.businessId
				)
			}
			if (this.bankLinked != -1) {
				if (this.bankLinked == 0) {
					locations = locations.filter(
						(location) =>
							location.funding_source.processor_id !=
							FundingSource.ACH
					)
				} else {
					locations = locations.filter(
						(location) =>
							location.funding_source.processor_id ==
							FundingSource.ACH
					)
				}
			}
			if (this.w9Linked != -1) {
				if (this.w9Linked == 0) {
					locations = locations.filter(
						(location) =>
							this.$store.state.restaurants[
								location.funding_source.entity_id
							].w9_uploaded != 1
					)
				} else {
					locations = locations.filter(
						(location) =>
							this.$store.state.restaurants[
								location.funding_source.entity_id
							].w9_uploaded == 1
					)
				}
			}
			return locations
		},
		pagedLocations() {
			return this.locations.slice(
				this.pageRange.start,
				this.pageRange.end
			)
		}
	},
	methods: {
		filterPage(range) {
			this.pageRange = range
		}
	}
}
</script>