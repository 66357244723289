<template>
	<SkBaseModal :isOpen="isOpen" @close="close" class="sk-modal-alt">
		<div class="sk-modal-alt-title">
			<h1>Create Delivery for #{{ orderId }}</h1>
		</div>
		<div class="sk-row">
			<SkDropdownSelect
				v-model="courier"
				:classes="'sk-input'"
				:options="couriers"
			/>
		</div>
		<div class="sk-row">
			<SkDropdownSelect
				:classes="'sk-input'"
				v-model="deliveryReason"
				:options="reasons"
			/>
		</div>
		<transition-expand>
			<div v-if="showReasonInput" class="sk-row">
				<SkInput
					v-model="deliveryReasonText"
					:multiline="true"
					:name="'Refund Reason'"
				/>
			</div>
		</transition-expand>
		<div class="sk-row sk-muted-text">
			Make sure to cancel any ongoing deliveries
		</div>
		<div class="sk-modal-actions">
			<button @click="close" class="button button-text">Close</button>
			<button
				type="button"
				class="button button-success"
				@click="createDelivery"
			>
				<i class="sk-icon-check-regular"></i>
				Create Delivery
			</button>
		</div>
	</SkBaseModal>
</template>

<style scoped>
.sk-muted-text {
	font-size: 12px;
}
</style>

<script>
import { CourierId } from "@arikgaisler/utils/enums/courierId"
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"
import SkInput from "@/components/SkInput.vue"
import TransitionExpand from "@/components/transitions/TransitionExpand.vue"

export default {
	name: "OrderDeliveryModal",
	mixins: [skModalMixin],
	components: {
		SkBaseModal,
		SkDropdownSelect,
		SkInput,
		TransitionExpand
	},
	data: function () {
		return {
			courierId: CourierId.POSTMATES,
			couriers: [
				{
					id: CourierId.POSTMATES,
					text: "Postmates"
				},
				{
					id: CourierId.DOORDASH,
					text: "DoorDash"
				}
			],
			loading: false,
			deliveryReason: null,
			deliveryReasonOtherId: 4,
			deliveryReasonText: ""
		}
	},
	computed: {
		order() {
			return this.$store.state.ordersModule.currentOrder
		},
		orderId() {
			return this.order ? this.order.id : ""
		},
		courier: {
			get: function () {
				return this.couriers.find(
					(courier) => courier.id == this.courierId
				)
			},
			set: function (newVal) {
				this.courierId = newVal.id
			}
		},
		showReasonInput() {
			return this.deliveryReason
				? this.deliveryReasonOtherId == this.deliveryReason.id
				: false
		},
		reasons() {
			let reasons = this.$store.state.deliveryReasons.slice()
			reasons.unshift({
				id: null,
				text: "Delivery Reason"
			})
			return reasons
		}
	},
	methods: {
		closed() {
			this.loading = false
			this.courierId = CourierId.POSTMATES
		},
		async createDelivery() {
			try {
				if (
					this.deliveryReason == null ||
					this.deliveryReason.id == undefined
				) {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Order Delivery",
						message: "A reason is required",
						type: NotificationType.WARNING
					})
					return
				}
				this.loading = true
				let createDeliverySuccess = await this.$store.dispatch(
					"ordersModule/createDelivery",
					{
						order: this.order,
						courierId: this.courierId,
						deliveryReason: this.deliveryReason.id,
						deliveryReasonText: this.deliveryReasonText
					}
				)
				let updateOrderSuccess = await this.$store.dispatch(
					"ordersModule/orderStatusUpdate",
					this.order.id
				)

				if (createDeliverySuccess && updateOrderSuccess) {
					const courier = this.couriers.find(
						(courier) => this.courierId == courier.id
					)
					const orderText = courier
						? `${courier.text} Order`
						: "Order"
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Order Delivery",
						message: `${orderText} delivery created for #${this.orderId}`,
						type: NotificationType.SUCCESS
					})
					this.close()
				} else {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Order Delivery",
						message: "Something went wrong, please try again.",
						type: NotificationType.ERROR
					})
				}
				this.loading = false
			} catch (e) {
				this.loading = false
				throw e
			}
		}
	}
}
</script>