var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SkCalendarDay',{attrs:{"dayText":_vm.day.text,"totalItems":_vm.prepackagesByBusiness.length,"disabled":_vm.dayDisabled},nativeOn:{"click":function($event){return _vm.orderPrepackages.apply(null, arguments)}},scopedSlots:_vm._u([{key:"calendar-short-list-item",fn:function(calendarDay){return _vm._l((_vm.prepackagesByBusinessShortList),function(prepackageBusiness){return _c('div',{key:prepackageBusiness.businessId,staticClass:"prepackage",class:{
				selected:
					!prepackageBusiness.hasMultiplePrepackages &&
					calendarDay.selectedItem &&
					prepackageBusiness.prepackages[0].id ==
						calendarDay.selectedItem.id
			},on:{"click":function($event){prepackageBusiness.hasMultiplePrepackages
					? calendarDay.viewMore()
					: calendarDay.selectItem(
							prepackageBusiness.prepackages[0]
					  )}}},[_c('span',{class:prepackageBusiness.statusClass}),_c('span',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(prepackageBusiness.businessName)+" ")]),(prepackageBusiness.hasMultiplePrepackages)?_c('b',[_vm._v(" "+_vm._s(prepackageBusiness.prepackagesCount)+" ")]):_vm._e()])})}},{key:"calendar-full-list-item",fn:function(calendarDay){return _vm._l((_vm.prepackagesByBusiness),function(prepackageBusiness){return _c('div',{key:prepackageBusiness.businessId},[_c('div',{staticClass:"prepackage",on:{"click":function($event){return _vm.prepackageBusinessClicked(prepackageBusiness)}}},[_c('span',{class:prepackageBusiness.statusClass}),_c('span',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(prepackageBusiness.businessName)+" ")]),(prepackageBusiness.hasMultiplePrepackages)?_c('b',[_vm._v(" "+_vm._s(prepackageBusiness.prepackagesCount)+" ")]):_vm._e()]),(prepackageBusiness.hasMultiplePrepackages)?_vm._t("default",function(){return _vm._l((prepackageBusiness.prepackages),function(prepackage){return _c('div',{key:prepackage.id,staticClass:"prepackage prepackage-menu",on:{"click":function($event){return calendarDay.selectItem(prepackage)}}},[_c('span',{class:prepackage.statusClass}),_c('span',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(prepackage.menuName)+" ")])])})}):_vm._e()],2)})}},{key:"calendar-detail",fn:function(calendarDay){return [_c('PrepackagesCalendarDetail',{attrs:{"selectedItem":calendarDay.selectedItem,"position":calendarDay.position},on:{"actionSelected":_vm.actionSelected,"close":function($event){return calendarDay.close()}}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }