<template>
	<div class="menu-items mobile-button-bottom">
		<div v-if="!isMobile">
			<h2 class="categories-title">Your Categories</h2>
			<draggable
				v-model="menuCategories"
				@end="onCategoryMoveEnd"
				:move="checkMove"
				draggable=".is-draggable"
			>
				<div
					class="menu-category product-category"
					:class="{ 'is-draggable': editAllowed }"
					v-for="category in menuCategories"
					:key="category.id"
					@click="scrollToCategory(category.id)"
				>
					<i
						v-if="editAllowed"
						class="draggable-icon sk-icon-bars-regular"
					></i>
					{{ category.name }} ({{ category.count }})
				</div>
			</draggable>
		</div>
		<div v-else>
			<div
				class="all-categories"
				@click="categoriesExpanded = !categoriesExpanded"
			>
				Categories
				<i
					class="sk-icon-chevron-down-regular"
					:style="allCategoriesIconStyle"
				></i>
			</div>
			<transition-expand>
				<div v-if="categoriesExpanded">
					<div
						class="product-category"
						v-for="category in menuCategories"
						:key="category.id"
					>
						{{ category.name }} ({{ category.count }})
					</div>
				</div>
			</transition-expand>
		</div>
		<div class="menu-products" v-if="showMenuProducts">
			<div
				v-for="category in menuProducts"
				:key="`category-${category.id}`"
				:ref="`category-${category.id}`"
			>
				<div class="sk-row">
					<div class="business-detail-section">
						<div class="sk-row category-title">
							{{ category.categoryName }}
						</div>
						<div>
							<div class="business-detail-label">
								<SkToggle
									:value="
										isCategoryProductsToggled(category.id)
									"
									@input="toggleAll($event, category.id)"
								/>
							</div>
						</div>
					</div>
				</div>
				<draggable
					:value="category.products"
					@input="draggableSetter"
					@end="onMoveEnd($event, category.id)"
					:move="checkMove"
					draggable=".is-draggable"
					class="sk-grid"
				>
					<BusinessMenuItem
						v-for="product in category.products"
						:key="product.id"
						:product="product"
						@itemChanged="hasChanges = true"
					/>
				</draggable>
			</div>
		</div>
		<EmptyPlaceholderWidget
			v-else
			:title="`No Items`"
			:description="`Your menu doesn’t have any items yet.`"
		/>
		<ConfirmationModal ref="confirmationModal" />
		<button
			v-if="editAllowed"
			class="button button-primary button-absolute"
			@click="saveBusinessMenu"
		>
			Save Details
		</button>
	</div>
</template>

<style scoped>
.button-icon {
	padding: 35px 0 0 0;
}

.button-icon,
.button-icon i {
	font-size: 12px;
	color: var(--sk-grey3);
}

.button-icon i {
	margin-right: 10px;
}

.business-detail-section {
	display: flex;
	justify-content: space-between;
}

.business-detail-label span:first-child {
	font-size: 14px;
	color: var(--sk-grey2);
	margin-right: 10px;
}

.business-detail-label span:last-child {
	font-size: 14px;
	color: var(--sk-grey3);
}

.draggable-icon {
	cursor: move;
}
</style>

<script>
import { PermissionMethod } from "@/utils/permissions"
import TransitionExpand from "@/components/transitions/TransitionExpand.vue"
import BusinessMenuItem from "@/components/business-menu/BusinessMenuItem.vue"
import mobileResponsivenessMixin from "@/mixins/mobile-responsiveness-mixin"
import loaderMixin from "@/mixins/loader-mixin"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue"
import EmptyPlaceholderWidget from "@/components/EmptyPlaceholderWidget.vue"
import draggable from "vuedraggable"
import SkToggle from "@/components/SkToggle.vue"

export default {
	name: "BusinessMenuItems",
	mixins: [mobileResponsivenessMixin, loaderMixin],
	components: {
		TransitionExpand,
		BusinessMenuItem,
		ConfirmationModal,
		EmptyPlaceholderWidget,
		draggable,
		SkToggle
	},
	data: function () {
		return {
			categoriesExpanded: false,
			mobileThresholdWidth: 700,
			hasChanges: false
		}
	},
	computed: {
		showMenuProducts() {
			return this.products && this.products.length > 0
		},
		menu() {
			return this.business.menus.find(
				(menu) => menu.id == this.$route.params.menu_id
			)
		},
		products() {
			return this.$store.state.businessesModule
				.currentBusinessRestaurantMenuProducts
		},
		menuCategories: {
			get() {
				return this.menuProducts
					.filter(
						(menuCategory) =>
							menuCategory.products &&
							menuCategory.products.length > 0
					)
					.map((menuCategory) => {
						return {
							count: menuCategory.products.length,
							name: menuCategory.categoryName,
							id: menuCategory.id
						}
					})
					.sort(this.sortCategories)
			},
			set: function () {}
		},
		totalCategories() {
			return this.$store.state.businessesModule
				.currentBusinessMenuCategories
		},
		categoriesMap() {
			return this.totalCategories.reduce((map, obj) => {
				map[obj.id] = obj.name
				return map
			}, {})
		},
		menuProducts() {
			let categoryProductsMap = {}
			this.products.forEach((product) => {
				let categoryId =
					product.category_id != null ? product.category_id : -1
				if (categoryProductsMap[categoryId] == undefined) {
					categoryProductsMap[categoryId] = {
						category: this.categoriesMap[categoryId],
						products: []
					}
				}
				categoryProductsMap[categoryId].products.push(product)
			})

			return Object.keys(categoryProductsMap)
				.map((categoryId) => {
					const products = categoryProductsMap[
						categoryId
					].products.sort((a, b) => {
						const isAinMenu =
							this.menu.products != undefined &&
							this.menu.products.some((prod) => prod.id == a.id)
						const isBinMenu =
							this.menu.products != undefined &&
							this.menu.products.some((prod) => prod.id == b.id)
						if (isAinMenu && !isBinMenu) {
							return -1
						}
						if (!isAinMenu && isBinMenu) {
							return 1
						}
						if (!isAinMenu && !isBinMenu) {
							return 0
						}
						if (isAinMenu && isBinMenu) {
							const prodA = this.menu.products.find(
								(e) => e.id == a.id
							)
							const prodB = this.menu.products.find(
								(e) => e.id == b.id
							)

							if (
								prodA.position == null &&
								prodB.position == null
							) {
								return 0
							} else if (prodA.position == null) {
								return 1
							} else if (prodB.position == null) {
								return -1
							} else {
								return prodA.position - prodB.position
							}
						}
					})

					let categoryPosition = null
					const productCategory = this.menu.products_categories.find(
						(e) => e.category_id == categoryId
					)

					if (productCategory) {
						categoryPosition = productCategory.position
					}

					return {
						id: categoryId,
						categoryName: categoryProductsMap[categoryId].category,
						categoryPosition: categoryPosition,
						products: products
					}
				})
				.sort(this.sortCategories)
		},
		business() {
			return this.$store.state.businessesModule.currentBusiness
		},
		allCategoriesIconStyle() {
			return {
				transform: this.categoriesExpanded
					? "rotate(180deg)"
					: "rotate(0)"
			}
		},
		editAllowed() {
			return this.$store.state.permissions.includes(
				PermissionMethod.EDIT_BUSINESS_PRODUCTS
			)
		}
	},
	methods: {
		saveBusinessMenu() {
			this.$emit("save")
		},
		toggleAll(event, id) {
			const menuCategory = this.menuProducts.find(
				(menuProduct) => menuProduct.id == id
			)
			if (!menuCategory) {
				return false
			}
			const products = menuCategory.products
			if (event) {
				products.forEach((product) => {
					if (this.menu != undefined) {
						if (
							this.menu.products == undefined ||
							!this.menu.products.some((e) => e.id == product.id)
						) {
							this.$store.dispatch(
								"businessesModule/addBusinessMenuItem",
								[product, this.menu.id]
							)
						}
					}
				})
			} else {
				products.forEach((product) => {
					this.$store.dispatch(
						"businessesModule/removeBusinessMenuItem",
						[product.id, this.menu.id]
					)
				})
			}
			this.hasChanges = true
		},
		isCategoryProductsToggled(id) {
			const menuCategory = this.menuProducts.find(
				(menuProduct) => menuProduct.id == id
			)
			if (!menuCategory) {
				return false
			}
			const products = menuCategory.products
			return (
				this.menu.products != undefined &&
				products.some((e) =>
					this.menu.products.some((a) => a.id == e.id)
				)
			)
		},
		savedBusinessMenu() {
			this.hasChanges = false
		},
		checkMove: function () {
			return true
		},
		onMoveEnd: function (event, categoryID) {
			if (event.oldIndex != event.newIndex) {
				const menuCategory = this.menuProducts.find(
					(menuProduct) => menuProduct.id == categoryID
				)
				if (!menuCategory) {
					return false
				}
				const productId = menuCategory.products[event.oldIndex].id
				const menuId = this.menu.id
				const productsInOrder = menuCategory.products
					.filter((product) => product.id != productId)
					.map((product) => product.id)
				productsInOrder.splice(event.newIndex, 0, productId)
				const obj = {
					menuId: menuId,
					order: productsInOrder
				}
				this.$store.dispatch(
					"businessesModule/updateMenuItemPosition",
					obj
				)
			}
		},
		onCategoryMoveEnd: function (event) {
			if (event.oldIndex != event.newIndex) {
				const categoryID = this.menuCategories[event.oldIndex].id
				const menuId = this.menu.id
				const categoriesInOrder = this.menuCategories
					.filter((category) => category.id != categoryID)
					.map((category) => category.id)
				categoriesInOrder.splice(event.newIndex, 0, categoryID)
				const obj = {
					menuId: menuId,
					order: categoriesInOrder
				}
				this.$store.dispatch(
					"businessesModule/updateBusinessCategoryPosition",
					obj
				)
			}
		},
		scrollToCategory(id) {
			const ref = this.$refs[`category-${id}`]
			if (ref && ref[0] && ref[0].scrollIntoView) {
				ref[0].scrollIntoView({
					behavior: "smooth"
				})
			}
		},
		draggableSetter() {},
		sortCategories(a, b) {
			const isAinMenu = this.menu.products_categories.some(
				(category) => category.category_id == a.id
			)
			const isBinMenu = this.menu.products_categories.some(
				(category) => category.category_id == b.id
			)
			if (isAinMenu && !isBinMenu) {
				return -1
			}
			if (!isAinMenu && isBinMenu) {
				return 1
			}
			if (!isAinMenu && !isBinMenu) {
				return 0
			}
			if (isAinMenu && isBinMenu) {
				const prodA = this.menu.products_categories.find(
					(e) => e.category_id == a.id
				)
				const prodB = this.menu.products_categories.find(
					(e) => e.category_id == b.id
				)
				if (prodA.position == null && prodB.position == null) {
					return 0
				} else if (prodA.position == null) {
					return 1
				} else if (prodB.position == null) {
					return -1
				} else {
					return prodA.position - prodB.position
				}
			}
		}
	}
}
</script>
