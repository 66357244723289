<template>
	<SkBaseModal
		class="canny-modal"
		:isOpen="isOpen"
		:hasLoader="true"
		@close="close"
	>
		<loading :active.sync="loading" :is-full-page="false" />
		<div data-canny />
	</SkBaseModal>
</template>

<style scoped>
.canny-modal >>> .sk-modal-body {
    max-width: 815px;
	min-height: 500px;
	background: var(--sk-white);
}
</style>

<script>
import auth from "@/utils/auth"
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import Loading from "vue-loading-overlay"

export default {
	name: "CannyFeedbackModal",
	mixins: [skModalMixin],
	components: {
		SkBaseModal,
		Loading
	},
	data: function () {
		return {
			boardToken: "3b7a7828-65f3-f9e1-6126-bc135153adb3",
            loading: false
		}
	},
	methods: {
		async opened() {
			try {
				this.loading = true
				const ssoResponse = await auth.generateCannySSO()
                this.loading = false
				if (ssoResponse.status == 200) {
					window.Canny("render", {
						boardToken: this.boardToken,
						basePath: this.$route.path,
						ssoToken: ssoResponse.data
					})
				} else {
					throw "Missing Canny SSO response"
				}
			} catch (e) {
				this.loading = false
				throw e
			}
		},
		closed() {
			this.loading = false
		}
	},
	mounted: function () {
		if (!window.Canny) {
			// prettier-ignore
			(function(w,d,i,s){function l(){if(!d.getElementById(i)){var f=d.getElementsByTagName(s)[0],e=d.createElement(s);e.type="text/javascript",e.async=!0,e.src="https://canny.io/sdk.js",f.parentNode.insertBefore(e,f)}}if("function"!=typeof w.Canny){var c=function(){c.q.push(arguments)};c.q=[],w.Canny=c,"complete"===d.readyState?l():w.attachEvent?w.attachEvent("onload",l):w.addEventListener("load",l,!1)}})(window,document,"canny-jssdk","script");
		}
	}
}
</script>