<template>
	<TypeaheadInput
		:placeholder="placeholder"
		:fetch="autocompleteEstablishment"
		:serializer="establishmentSerializer"
		:query="establishmentQuery"
		:invalid="invalid"
		@selected="establishmentSelected"
		@blur="resetQuery"
	/>
</template>

<style scoped>
</style>

<script>
import gapi from "@/utils/gapi"
import TypeaheadInput from "@/components/TypeaheadInput.vue"

export default {
	name: "EstablishmentTypeaheadInput",
	components: {
		TypeaheadInput
	},
	props: {
		value: null,
		placeholder: {
			type: String,
			default: "Establishment Name"
		},
		invalid: {
			type: Boolean,
			default: false
		}
	},
	data: function () {
		return {
			establishmentQuery: ""
		}
	},
	computed: {
		model: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit("input", val)
			}
		}
	},
	methods: {
		autocompleteEstablishment(query) {
			return gapi.autocompleteEstablishment(query, ["establishment"])
		},
		establishmentSelected(location) {
			if (location && location.id) {
				let data = {
					placeId: location.id,
					name: location.name,
                    shortName: location.shortName
				}
				this.model = data
			} else {
				this.model = null
			}
		},
		establishmentSerializer(result) {
			return {
				text: result.name
			}
		},
		resetQuery() {
			if (
				(this.search == "" || !this.search) &&
				this.model &&
				this.model.placeId != null
			) {
				this.model = null
			}
		}
	},
	watch: {
		model: {
			immediate: true,
			handler(newVal) {
				this.establishmentQuery = newVal && newVal.name ? newVal.name : ""
			}
		}
	}
}
</script>