<template>
	<SkBaseModal :isOpen="isOpen" @close="close" class="sk-modal-alt">
		<div class="sk-modal-alt-title">
			<h1>
				{{ titleTxt }}
			</h1>
		</div>
		<div class="sk-row">
			<SkDropdownSelect
				class="business-type-dd sk-input"
				v-model="type"
				:options="types"
			/>
		</div>
		<div class="sk-row">
			<SkDropdownSelect
				class="business-type-dd sk-input"
				v-model="role"
				:options="roles"
			/>
		</div>
		<div class="sk-row">
			<SkInput
				ref="nameInput"
				:name="'Name'"
				:required="true"
				v-model="user.display_name"
			/>
		</div>
		<div class="sk-row">
			<SkInput
				ref="emailInput"
				:name="'Email'"
				:required="true"
				:type="'string'"
				v-model="user.email"
			/>
		</div>
		<div class="sk-row">
			<SkInput
				ref="phoneInput"
				:name="'Phone Number'"
				:type="'tel'"
				v-model="user.phone"
			/>
		</div>

		<div class="sk-row sk-widget-section" v-if="entitiesRequired">
			<div class="sk-widget-section-title">{{ entitiesText }}</div>

			<AdminUserEntity
				v-for="entity in entities"
				:key="entity.id"
				:entity="entity"
				@userAdminEntityRemoveClicked="removeUserAdminEntity"
			/>

			<button
				class="button button-text sk-widget-section-text-button"
				@click="addUserEntity"
			>
				<i class="sk-icon-plus-regular"></i>
				Add Allowed Entity
			</button>
		</div>

		<div id="email-error" v-if="error">
			{{ errMsg }}
		</div>
		<div class="sk-modal-actions">
			<button @click="close" class="button button-text">
				Close
			</button>
			<button
				type="button"
				class="button button-success"
				@click="saveAdminUser"
			>
				<i class="sk-icon-check-regular"></i>
				{{ btnText }}
			</button>
		</div>
	</SkBaseModal>
</template>

<style scoped>

.entity-button span:first-child {
	font-size: 14px;
	color: var(--sk-grey2);
}

.entity-button span:last-child {
	font-size: 14px;
	color: var(--sk-grey3);
}

.entity-section {
	display: flex;
	justify-content: space-between;
}

.entity-selector {
	width: 100%;
}
</style>

<script>
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import AdminUserEntity from "@/components/users/AdminUserEntity.vue"
import { UserRole, UserSubRole } from "@/enums/userRole"
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import SkInput from "@/components/SkInput.vue"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"
import TypeaheadInput from "@/components/TypeaheadInput.vue"
import phoneFilterMixin from "@/mixins/phone-filter-mixin"

export default {
	name: "AdminUserModal",
	mixins: [skModalMixin, phoneFilterMixin],
	components: {
		SkBaseModal,
		SkInput,
		SkDropdownSelect,
		TypeaheadInput,
		AdminUserEntity
	},
	data: function() {
		return {
			error: false,
			errMsg: "",
			business_id: 0,
			user: {
				id: 0,
				entities: [],
				display_name: "",
				email: "",
				phone: "",
				role_id: UserRole.BUSINESS_ADMIN,
				sub_role_id: UserRole.ADMIN
			},
			types: [
				{ id: UserRole.BUSINESS_ADMIN, text: "Business Admin" },
				{ id: UserRole.RESAURANT_ADMIN, text: "Restaurant Admin" }
			],
			roles: [
				{ id: UserSubRole.ADMIN, text: "Admin" },
				{ id: UserSubRole.STAFF, text: "Staff" }
			]
		}
	},
	computed: {
		btnText() {
			return this.user.id && this.user.id > 0 ? "Update" : "Add"
		},
		titleTxt() {
			return this.user.id && this.user.id > 0
				? "Update Chef User"
				: "Add Chef User"
		},
		entities() {
			return this.user.entities
		},
		entitiesRequired() {
			return this.user.role_id != UserRole.SUPER_ADMIN
		},
		entitiesText() {
			return this.user.role_id == UserRole.BUSINESS_ADMIN
				? "Allowed Businesses"
				: "Allowed Restaurants"
		},
		type: {
			get: function() {
				if (!this.user || this.user.role_id == null) {
					return null
				}
				var typeStr = ""
				if (this.user.role_id == UserRole.BUSINESS_ADMIN) {
					typeStr = "Business Admin"
				}
				if (this.user.role_id == UserRole.RESAURANT_ADMIN) {
					typeStr = "Restaurant Admin"
				}
				return {
					id: this.user.role_id,
					text: typeStr
				}
			},
			set: function(newVal) {
				if (this.user && newVal != undefined) {
					this.user.role_id = newVal.id
					this.user.entities = []
				}
			}
		},
		role: {
			get: function() {
				if (!this.user || this.user.sub_role_id == null) {
					return null
				}
				let typeStr = ""
				if (this.user.sub_role_id == UserSubRole.ADMIN) {
					typeStr = "Admin"
				}
				if (this.user.sub_role_id == UserSubRole.STAFF) {
					typeStr = "Staff"
				}
				return {
					id: this.user.sub_role_id,
					text: typeStr
				}
			},
			set: function(newVal) {
				if (this.user && newVal != undefined) {
					this.user.sub_role_id = newVal.id
				}
			}
		}
	},
	methods: {
		closed() {
			this.user = {
				id: 0,
				role_id: UserRole.BUSINESS_ADMIN,
				sub_role_id: UserSubRole.ADMIN,
				display_name: null,
				email: null,
				phone: '',
				entities: []
			}
		},
		handleOptions(options) {
			if (options && options.user) {
				this.user.id = options.user.id
				this.user.display_name = options.user.display_name
				this.user.email = options.user.email
				this.user.entities = options.user.entities
				this.user.role_id = options.user.role_id
				this.user.sub_role_id = options.user.sub_role_id
			}
		},
		typeaheadSerializer(result) {
			return {
				text: result.name
			}
		},
		removeUserAdminEntity(id) {
			this.user.entities = this.user.entities.filter(
				entity => entity.id != id
			)
		},
		addUserEntity() {
			if (this.user.role_id == UserRole.BUSINESS_ADMIN) {
				this.user.entities.push({
					id: 0,
					type: 1
				})
			} else if (this.user.role_id == UserRole.RESAURANT_ADMIN) {
				this.user.entities.push({
					id: 0,
					type: 2
				})
			}
		},
		validateAdminUserData() {
			const validName = this.$refs.nameInput.validate()
			const validEmail = this.$refs.emailInput.validate()
			if (!this.user.role_id || this.user.role_id == null || this.user.role_id == 0) {
				throw "Please select a user type"
			}
			if (!validName) {
				throw "Please enter a valid name"
			}
			if (!validEmail) {
				throw "Please enter a valid email"
			}
			return true
		},
		async saveAdminUser() {
			try {
				this.validateAdminUserData()
				if (this.user.id > 0) {
					const resp = await this.$store.dispatch("updateAdminUser", this.user)
					if (resp) {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Successfully updated user details"
						})
						this.close()
					}
				} else {
					const resp = await this.$store.dispatch("addAdminUser", this.user)
					if (resp) {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Successfully added user"
						})
						this.close()
					} else {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Something went wrong, please try again",
							type: NotificationType.WARNING
						})
					}
				}
			} catch (e) {
				this.bugsnag.notify("Unable to update details", event => {
					event.addMetadata("error", {
						error: e
					})
				})
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title:
						e && !e.message
							? e
							: "Something went wrong, please try again",
					type: NotificationType.WARNING
				})
			}
		}
	}
}
</script>