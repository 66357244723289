<template>
	<div class="sk-widget-alt" :class="{ 'in-network': isInNetwork, selected: selected }">
		<h3>{{ resultName }}</h3>
		<div class="result-address">{{ resultAddress }}</div>
		<div class="result-insights-summary">
			<span v-for="(insight, i) in insights" :key="i">
				<i :class="insight.icon"></i>
				{{ insight.text }}
			</span>
		</div>
	</div>
</template>

<style scoped>
.sk-widget-alt {
	height: 150px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	border-left: 4px solid var(--sk-dark-navy);
}

.in-network {
	border-left: 4px solid var(--sk-green7);
}

.selected {
	border-right: 1px solid var(--sk-dark-navy);
    border-top: 1px solid var(--sk-dark-navy);
    border-bottom: 1px solid var(--sk-dark-navy);
}

.selected.in-network {
	border-right: 1px solid var(--sk-green7);
    border-top: 1px solid var(--sk-green7);
    border-bottom: 1px solid var(--sk-green7);
}

h3 {
	font-size: 16px;
	font-weight: 600;
	color: var(--sk-dark-navy);
	margin-bottom: 10px;
}

.result-address {
	font-size: 12px;
	color: var(--sk-grey2);
	margin-bottom: 15px;
}

.result-insights-summary {
	display: flex;
	align-items: center;
}

.result-insights-summary > span {
	font-size: 10px;
	color: var(--sk-grey2);
}

.result-insights-summary > span + span {
	margin-left: 25px;
}

.result-insights-summary > span i {
	margin-right: 5px;
}
</style>

<script>
import salesToolResultMixin from "@/mixins/sales/sales-tool-result-mixin"

export default {
	name: "SalesToolResult",
	mixins: [salesToolResultMixin],
	props: {
		result: Object,
		selected: Boolean
	},
	computed: {
		insights() {
			let insights = [
				{
					icon: "sk-icon-map-marker-alt-solid1",
					text: `${this.resultDistance} miles`
				}
			]

			if (this.isHubPartner) {
				insights.push({
					icon: "sk-icon-device_hub_black_24dp",
					text: "Hub Partner"
				})
			}

			if (this.accuracy) {
				insights.push({
					icon: "sk-icon-bullseye-arrow-regular",
					text: `${this.accuracy}%`
				})
			}

			if (this.rating) {
				insights.push({
					icon: "sk-icon-star-solid",
					text: this.rating
				})
			}

			if (
				this.pairings &&
				this.pairings.length > 0 &&
				insights.length < 4
			) {
				insights.push({
					icon: "sk-icon-users-crown-solid",
					text: this.pairings.length
				})
			}

			if (this.result.orders && insights.length < 4) {
				insights.push({
					icon: "sk-icon-receipt-solid",
					text: this.result.orders
				})
			}

			return insights
		}
	}
}
</script>