import Vue from "vue"
import moment from "moment"
import { UserRole, UserSubRole } from "@/enums/userRole"
import { eventBus, EventBusEvents } from "@/utils/eventBus"

export default {
    gotRestaurantsPOSDevices(state, devices) {
        devices.forEach((device) => {
            if (state.restaurants[device.restaurant_id]) {
                if (!state.restaurants[device.restaurant_id].devices) {
                    Vue.set(state.restaurants[device.restaurant_id], "devices", [])
                }
                state.restaurants[device.restaurant_id].devices.push(device)
            }
        })
    },
    gotRestaurants(state, payload) {
        var rest = payload.data.restaurants[0]
        for (var i = 0; i < payload.data.restaurants.length; i++) {
            rest = payload.data.restaurants[i]
            if (rest != undefined) {
                Vue.set(state.restaurants, rest.id, rest)
            }
        }
    },
    gotRestaurantsByIds(state, payload) {
        payload.restaurants.forEach(restaurant => {
            Vue.set(state.restaurants, restaurant.id, restaurant)
        })
    },
    addedRestaurant(state, restaurant) {
        Vue.set(state.restaurants, restaurant.id, restaurant)
    },
    deletedRestaurant(state, restaurant_id) {
        if (state.restaurants[restaurant_id]) {
            Vue.delete(state.restaurants, restaurant_id)
        }
    },
    removeBusiness(state, slug) {
        Vue.delete(state.businesses, slug)
    },
    gotBusinesses(state, payload) {
        state.businesses = {}
        for (let i = 0; i < payload.data.data.businesses.length; i++) {
            let business = payload.data.data.businesses[i]
            if (business != undefined) {
                Vue.set(state.businesses, business.slug, business)
            }
        }
    },
    gotBusinessesByIds(state, payload) {
        payload.data.businesses.forEach(business => {
            Vue.set(state.businesses, business.slug, business)
        })
    },
    gotExceptionTypes(state, payload) {
        state.exception_types = payload.data.exceptions
    },
    gotRefundTypes(state, payload) {
        state.refund_reasons = payload.data.data.reasons
    },
    gotDeliveryReasons(state, payload) {
        state.deliveryReasons = payload.data.reasons
    },
    restaurantW9Updated(state, restaurantId) {
        state.restaurants[restaurantId].w9_uploaded = 1
    },
    businessW9Updated(state, slug) {
        const business = state.businesses[slug]
        if (business && state.currentBusiness && business.slug == state.currentBusiness.slug) {
            Vue.set(state.currentBusiness, "w9_uploaded", 1)
        }
        business.w9_uploaded = 1
    },
    gotBusinessEmployees(state, payload) {
        state.business_employees = []
        for (var i = 0; i < payload.data.data.employees.length; i++) {
            var obj = payload.data.data.employees[i]
            if (obj.phone.startsWith('+')) {
                obj.phone = obj.phone.substring(2)
            }
            state.business_employees.push(obj)
        }
    },
    addBusinessEmployeeTip() {

    },
    gotBusinessEmployeesPaidTips(state, payload) {
        state.employee_paid_tips = payload.data.data.payouts
    },
    gotBusinessTipAmounts(state, payload) {
        if (payload.data.code == "ok") {
            state.totalTips = payload.data.data.commissions.reduce((total, tip) => {
                return total + (tip.tip_commission_total - tip.tip_commission_paid)
            }, 0)
            state.totalPayouts = payload.data.data.commissions.reduce((total, tip) => {
                return total + tip.tip_commission_paid
            }, 0)
            const now = moment()
            const dayOfMonth = now.date()
            if (dayOfMonth == 15) {
                state.nextPayout = "Today"
            } else if (dayOfMonth > 15) {
                state.nextPayout = now.add(1, "M").format('MMM') + " 1st"
            } else if (dayOfMonth < 15) {
                state.nextPayout = now.format('MMM') + ' 15th'
            }
        } else {
            state.totalPayouts = 0
            state.totalTips = 0
            state.nextPayout = null
        }
    },
    gotRefunds(state, payload) {
        let refunds = payload.data.data.refunds ? payload.data.data.refunds : []
        refunds = refunds.sort((a, b) => b.id - a.id)
        state.refunds = refunds
    },
    updatedRefund(state, updatedRefund) {
        const refund = state.refunds.find(refund => refund.id == updatedRefund.id)
        if (refund != null) {
            refund.amount = updatedRefund.amount
            refund.reason_id = updatedRefund.reason_id
            refund.reason = updatedRefund.reason
        }
    },
    removeRefund(state, refundId) {
        for (var i = 0; i < state.refunds.length; i++) {
            if (state.refunds[i].id == refundId) {
                state.refunds.splice(i, 1)
                break;
            }
        }
    },
    removeRefunds(state, refundIds) {
        state.refunds = state.refunds.filter(refund => !refundIds.includes(refund.id))
    },
    removeException(state, exception_id) {
        for (var i = 0; i < state.order_exceptions.length; i++) {
            if (state.order_exceptions[i].id == exception_id) {
                state.order_exceptions.splice(i, 1)
                break;
            }
        }
    },
    updatedException(state, updatedException) {
        const index = state.order_exceptions.findIndex(exception => exception.id == updatedException.id)
        if (index > -1) {
            state.order_exceptions.splice(index, 1, updatedException)
        }
    },
    batchRemovedExceptions(state, exceptions) {
        exceptions.forEach(exception => {
            let index = state.order_exceptions.findIndex(order_exception => order_exception.id == exception.id)
            if (index >= 0) {
                state.order_exceptions.splice(index, 1)
            }
        })
    },
    gotOrderExceptions(state, payload) {
        state.order_exceptions = payload.data.exceptions
    },
    updatedBusiness(state, payload) {
        if (payload != null && payload.data != null) {
            if (state.businesses != null && state.businesses != undefined) {
                state.businesses[payload.data.slug] = payload.data
            }
            eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
                title: `Successfully updated ${payload.data.business.slug}`,
            })
        }
    },
    initializing(state, initializing) {
        state.initializing = initializing
    },
    loading(state) {
        state.loading = true;
    },
    loaded(state) {
        state.loading = false;
    },
    clearBusinesses(state) {
        state.businesses = {}
        state.totalPayouts = 0
        state.totalTips = 0
        state.nextPayout = null
        state.nextPayout = null
        state.business_employees = []
    },
    clearRestaurants(state) {
        state.restaurants = {};
    },
    userAuthenticated(state, userResp) {
        if (userResp.status == 200) {
            state.user_id = userResp.data.user_id
            state.user_token = userResp.data.access_token
            state.user_refresh_token = userResp.data.refresh_token
        }
    },
    userLoggedOut(state) {
        state.user_id = 0
        state.user_name = ""
        state.user_role = UserRole.DEFAULT
        state.user_sub_role = UserSubRole.STAFF
        state.user_token = ""
        state.user_refresh_token = ""
        state.user_entities = []
        state.user = {}
    },
    calculatedPermissions(state, permissions) {
        state.permissions = permissions
    },
    gotAdminUser(state, payload) {
        if (payload.status == 200) {
            state.user = payload.data.user
            state.user_name = payload.data.user.display_name
            state.user_role = payload.data.user.role_id
            state.user_sub_role = payload.data.user.sub_role_id
            state.user_entities = payload.data.user.entities
        }
    },
    gotAdminUsers(state, payload) {
        if (payload.status == 200) {
            state.admin_users = payload.data.data.users
        }
    },
    addedAdminUser(state,payload){
        state.admin_users.unshift(payload)
    },
    removedAdminUser(state, user_id){
        let adminUserIndex = state.admin_users.findIndex(user => user.id == user_id)
		if (adminUserIndex >= 0) {
			state.admin_users.splice(adminUserIndex, 1)
		}
    },
    updatedAdminUser(state, payload){
        let adminUserIndex = state.admin_users.findIndex(user => user.id == payload.id)
		if (adminUserIndex >= 0) {
			state.admin_users.splice(adminUserIndex, 1, payload)
		}
    },
    loggedIn(state, val) {
        state.login = val
    }
}