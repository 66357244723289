<template>
	<div>
		<div class="sk-column">
			<div class="sk-insight-section-title">Accuracy</div>
			<div class="sk-insight-section-description">
				Mistakes happen! Utilize accuracy to review where improvements
				can be made.
			</div>
		</div>
		<div class="sk-grid">
			<RestaurantAverageAccuracyChart />
			<RestaurantTopExceptionsChart class="top-exceptions-widget" />
			<RestaurantExceptionStatistic
				:title="'Missing Items'"
				:footerTitle="'Double check orders before they leave the restaurant.'"
				:loading="missingItemsOrders.loading"
				:data="missingItemsOrders.data"
			/>
			<RestaurantExceptionStatistic
				:title="'Incorrect Items'"
				:footerTitle="'Watch carefully to be sure you place the correct items for each order'"
				:loading="incorrectItemsOrders.loading"
				:data="incorrectItemsOrders.data"
			/>
			<RestaurantExceptionStatistic
				:title="'Downtime'"
				:loading="restaurantClosedOrders.loading"
				:data="restaurantClosedOrders.data"
			/>
		</div>
		<div class="sk-grid"></div>
		<div class="sk-column">
			<div class="sk-insight-section-title">Performance</div>
			<div class="sk-insight-section-description">
				It’s all about timing! Optimize your response times for high
				performance.
			</div>
		</div>
		<div class="sk-grid">
			<RestaurantAverageAcceptTimeWidget />
			<RestaurantAveragePreparationTimeWidget />
		</div>
		<div class="sk-column">
			<div class="sk-insight-section-title">Ratings</div>
			<div class="sk-insight-section-description">
				How do your customers feel about their experience?
			</div>
		</div>
		<div class="sk-grid">
			<AverageRatingWidget :showFooter="true" />
		</div>
	</div>
</template>

<style scoped>
.sk-grid {
	grid-template-columns: repeat(6, 1fr);
}

.sk-grid > * {
	grid-column: span 2;
}

.top-exceptions-widget {
	grid-column: span 3;
}
</style>

<script>
import ActionTypes from "@/store/modules/insights/action-types"
import RestaurantAverageAccuracyChart from "@/components/insights/RestaurantAverageAccuracyChart.vue"
import RestaurantTopExceptionsChart from "@/components/insights/RestaurantTopExceptionsChart.vue"
import RestaurantExceptionStatistic from "@/components/insights/RestaurantExceptionStatistic.vue"
import AverageRatingWidget from "@/components/insights/AverageRatingWidget.vue"
import RestaurantAverageAcceptTimeWidget from "@/components/insights/RestaurantAverageAcceptTimeWidget.vue"
import RestaurantAveragePreparationTimeWidget from "@/components/insights/RestaurantAveragePreparationTimeWidget.vue"

export default {
	name: "RestaurantQualityAndOperations",
	components: {
		RestaurantAverageAccuracyChart,
		RestaurantTopExceptionsChart,
		RestaurantExceptionStatistic,
		AverageRatingWidget,
		RestaurantAverageAcceptTimeWidget,
		RestaurantAveragePreparationTimeWidget
	},
	props: {
		filterVals: Object
	},
	computed: {
		missingItemsOrders() {
			return this.$store.state.insightsModule.missingItemsOrders
		},
		incorrectItemsOrders() {
			return this.$store.state.insightsModule.incorrectItemsOrders
		},
		restaurantClosedOrders() {
			return this.$store.state.insightsModule.restaurantClosedOrders
		}
	},
	watch: {
		filterVals: {
			immediate: true,
			deep: true,
			handler(filterVals) {
				if (!filterVals.startDate || !filterVals.endDate) {
					return
				}
				this.$store.dispatch(
					`insightsModule/${ActionTypes.GET_AVERAGE_RESTAURANT_ACCURACY}`
				)
				this.$store.dispatch(
					`insightsModule/${ActionTypes.GET_TOP_EXCEPTIONS}`
				)
				this.$store.dispatch(
					`insightsModule/${ActionTypes.GET_MISSING_ITEMS_ORDERS}`
				)
				this.$store.dispatch(
					`insightsModule/${ActionTypes.GET_INCORRECT_ITEMS_ORDERS}`
				)
				this.$store.dispatch(
					`insightsModule/${ActionTypes.GET_RESTAURANT_CLOSED_ORDERS}`
				)
				this.$store.dispatch(
					`insightsModule/${ActionTypes.GET_AVERAGE_RATING}`
				)
				this.$store.dispatch(
					`insightsModule/${ActionTypes.GET_RESTAURANT_AVERAGE_ACCEPT_TIME}`
				)
				this.$store.dispatch(
					`insightsModule/${ActionTypes.GET_RESTAURANT_AVERAGE_PREPARATION_TIME}`
				)
			}
		}
	}
}
</script>