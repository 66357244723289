<template>
	<div class="sk-widget-alt">
		<div class="business-summary">
			<div class="business-title">
				<h1>{{ businessName }}</h1>
				<img
					v-if="businessLogo"
					:src="businessLogo"
					alt="Business Logo"
				/>
				<SkDropdownSelect
					v-if="showBusinessSelector"
					:showSelectedOption="false"
					:options="businessOptions"
					@selected="filterBusiness"
				/>
			</div>
			<div class="header-line-item">
				<i class="sk-icon-clock-regular-1"></i>
				<b>Serving Hours</b>
				{{ servingHours }}
			</div>
			<div class="header-line-item">
				<i class="sk-icon-star-solid"></i>
				{{ businessRating }}
			</div>
			<div class="header-line-item">
				<i class="sk-icon-link-regular"></i>
				<a :href="webappLink" target="_blank">
					{{ formattedWebappLink }}
				</a>
			</div>
		</div>
		<div class="business-img" :style="businessImgStyle"></div>
	</div>
</template>

<style scoped>
.sk-widget-alt {
	overflow: visible;
	z-index: 1001;
    position: relative;
}

.business-summary >>> .sk-select {
	width: auto;
}

.business-summary {
	flex: 1;
}

.business-title {
	display: flex;
	align-items: center;
	margin-bottom: 23px;
}

.business-title h1 {
	font-size: 20px;
	color: var(--sk-dark-navy);
	margin-bottom: 0;
}

.business-title img {
	width: 30px;
	height: 30px;
	border-radius: 2px;
	margin-left: 10px;
}

.header-line-item + .header-line-item {
	margin-top: 15px;
}

.header-line-item {
	display: flex;
	align-items: center;
	font-size: 10px;
	color: var(--sk-grey3);
}

.header-line-item a {
	color: var(--sk-grey3);
	text-decoration: none;
}

.header-line-item i {
	margin-right: 10px;
	font-size: 10px;
	color: var(--sk-grey2);
}

.header-line-item b {
	margin-right: 5px;
}

.business-img {
	width: 290px;
	border-radius: 5px;
	background-size: cover !important;
}
</style>

<script>
import moment from "moment"
import momentHelper from "@/utils/moment-helper"
import ActionTypes from "@/store/modules/businessDashboard/action-types"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"
import { MenuType } from "@arikgaisler/utils/enums/menuType"

export default {
	name: "BusinessDashboardHeader",
	components: {
		SkDropdownSelect
	},
	computed: {
		showBusinessSelector() {
			return this.$store.getters.ownsMultipleEntities
		},
		businessOptions() {
			return this.$store.getters.ownedBusinesses
				.filter((business) => business.name && business.name != "")
				.map((business) => {
					return {
						id: business.id,
						text: business.name
					}
				})
				.sort((a, b) => {
					if (a.text < b.text) {
						return -1
					}
					if (a.text > b.text) {
						return 1
					}
					return 0
				})
		},
		business() {
			return this.$store.state.businessDashboardModule.business
		},
		businessName() {
			return this.business ? this.business.name : ""
		},
		businessTimezone() {
			return this.business && this.business.timezone_offset
				? this.business.timezone_offset
				: 0
		},
		businessLogo() {
			if (
				this.business &&
				this.business.logo &&
				this.business.logo != ""
			) {
				let logoSuffix =
					this.business.logo && this.business.logo.length > 0
						? this.business.logo
						: `${this.business.slug}-logo.jpg`
				return `${this.$businessCDNURL}${this.business.slug}/${logoSuffix}`
			}
			return null
		},
		businessRating() {
			if (this.$store.state.businessDashboardModule.businessAvgRating) {
				return this.$store.state.businessDashboardModule
					.businessAvgRating
			}
			return "--"
		},
		businessImg() {
			if (
				this.business &&
				this.business.img != "" &&
				this.business.img != undefined
			) {
				return (
					this.$businessCDNURL +
					this.business.slug +
					"/" +
					this.business.img
				)
			}
			return ""
		},
		businessImgStyle() {
			if (this.businessImg) {
				const gradient =
					"linear-gradient(0deg, rgba(47, 72, 88, 0.5) 0%, rgba(47, 72, 88, 0.5) 100%) 0% 0% / cover,"
				return {
					background: `${gradient}url(${this.businessImg}) center no-repeat`
				}
			} else {
				return ""
			}
		},
		webappLink() {
			if (this.business) {
				return `${this.$webappUrl}/${this.business.slug}?utm_source=dashboard`
			}
			return ""
		},
		formattedWebappLink() {
			if (this.business) {
				return `${this.$webappUrl}/${this.business.slug}`
			}
			return ""
		},
		servingHours() {
			const menus =
				this.$store.state.businessDashboardModule.todaysBusinessMenus
			const servingRanges = []
			const dailyHours = menus
				.map((menu) => {
					let hours = []
					if (menu.data.type == MenuType.POPUP) {
						const event =
							this.$store.state.businessDashboardModule
								.todaysEvents[menu.data.event_id]
						if (event) {
							hours.push({
								open_time: moment
									.utc(event.start_time, "HH:mm:ss")
									.add(this.businessTimezone, "m")
									.format("HH:mm:ss"),
								close_time: moment
									.utc(event.end_time, "HH:mm:ss")
									.add(this.businessTimezone, "m")
									.format("HH:mm:ss"),
								dow: momentHelper.normalizeDayOfWeek(
									moment
										.utc(event.start_time, "HH:mm:ss")
										.add(this.businessTimezone, "m")
								)
							})
						}
					} else if (menu.schedules && menu.schedules.length > 0) {
						hours = menu.schedules.map((schedule) => {
							return {
								open_time: schedule.openTime,
								close_time: schedule.closeTime,
								dow: schedule.dow
							}
						})
					}

					return hours
				})
				.flat()
				.sort((dailyHourA, dailyHourB) => {
					const openTimeANum = momentHelper.numericalTime(
						dailyHourA.open_time
					)
					const openTimeBNum = momentHelper.numericalTime(
						dailyHourB.open_time
					)
					return openTimeANum - openTimeBNum
				})

			if (dailyHours.length == 0) {
				return "Closed"
			}

			dailyHours.forEach((dailyHour) => {
				if (
					!dailyHour ||
					!dailyHour.open_time ||
					!dailyHour.close_time
				) {
					return
				}

				if (servingRanges.length == 0) {
					servingRanges.push({
						openTime: dailyHour.open_time,
						closeTime: dailyHour.close_time,
						dow: dailyHour.dow
					})
				} else {
					const dailyHourBounds = {
						openTime: dailyHour.open_time,
						closeTime: dailyHour.close_time,
						dow: dailyHour.dow
					}
					const fitsWithinBounds = servingRanges.find(
						(range, index) => {
							const withinRangeBounds =
								momentHelper.isWithinBounds(
									range,
									dailyHourBounds
								)
							const withinDailyHourBounds =
								momentHelper.isWithinBounds(
									dailyHourBounds,
									range
								)
							if (
								withinRangeBounds.openTimeInBounds &&
								withinRangeBounds.closeTimeInBounds
							) {
								return true
							} else if (
								withinDailyHourBounds.openTimeInBounds &&
								withinDailyHourBounds.closeTimeInBounds
							) {
								servingRanges[index] = dailyHourBounds
								return true
							} else if (
								withinRangeBounds.openTimeInBounds &&
								!withinRangeBounds.closeTimeInBounds
							) {
								servingRanges[index].closeTime =
									dailyHourBounds.closeTime
								return true
							}
							return false
						}
					)

					if (!fitsWithinBounds) {
						servingRanges.push(dailyHourBounds)
					}
				}
			})

			return servingRanges
				.sort(
					(dailyHourA, dailyHourB) => dailyHourA.dow - dailyHourB.dow
				)
				.map((range) => {
					let openTime = moment
						.utc(range.openTime, "HH:mm:ss")
						.format("h:mm A")
					let closeTime = moment
						.utc(range.closeTime, "HH:mm:ss")
						.format("h:mm A")
					if (openTime == "12:00 AM") {
						openTime = "Midnight"
					}

					if (closeTime == "12:00 AM") {
						closeTime = "Midnight"
					}
					return `${openTime} - ${closeTime}`
				})
				.join(", ")
		}
	},
	methods: {
		filterBusiness(business) {
			return this.$store.dispatch(
				`businessDashboardModule/${ActionTypes.GET_DASHBOARD_DATA}`,
				{
					businessId: business.id
				}
			)
		}
	}
}
</script>