<template>
	<SkBaseModal
		:isOpen="isOpen"
		:hasLoader="true"
		@close="close"
		class="sk-modal-alt"
	>
		<loading :active.sync="loading" :is-full-page="false" />
		<div class="sk-modal-alt-title">
			<h1>
				Issue Coupon:
				<span class="sk-modal-alt-subtitle">#{{ orderId }}</span>
			</h1>
		</div>
		<div class="sk-row">
			<SkInput
				ref="codeInput"
				:name="'Coupon Code'"
				:placeholder="'ABCDEF'"
				:required="true"
				v-model="coupon.code"
			/>
		</div>
		<div class="sk-row">
			<SkInput
				ref="amountInput"
				:name="'Coupon Amount'"
				:placeholder="''"
				:required="true"
				:type="'number'"
				v-model="coupon.amount"
			/>
			<SkDropdownSelect
				ref="typeInput"
				:classes="'sk-input'"
				v-model="couponType"
				:options="couponTypes"
			/>
		</div>
		<div class="sk-row">
			<SkDropdownSelect
				ref="typeInput"
				:classes="'sk-input'"
				v-model="couponReason"
				:options="couponReasons"
			/>
		</div>
		<transition-expand>
			<div v-if="isOtherCouponReason" class="sk-row">
				<SkInput
					ref="reasonInput"
					:name="'Coupon Reason'"
					:placeholder="''"
					:required="true"
					:multiline="true"
					v-model="coupon.reason"
				/>
			</div>
		</transition-expand>
		<div class="sk-row" v-if="canSendText">
			<div>
				<div class="sk-switch-label-wrapper">
					<SkCheckbox v-model="sendCustomerText" />
					<span>Send automated customer text</span>
				</div>
				<div class="default-customer-message">
					{{ defaultCustomerText }}
				</div>
				<div
					class="button button-text-navy button-auto"
					@click="editCustomerText = !editCustomerText"
				>
					{{ editCustomerTextButton }}
				</div>
			</div>
		</div>
		<transition-expand>
			<div class="sk-row" v-if="canSendText && editCustomerText">
				<div>
					<SkInput
						ref="customCustomerTextInput"
						:name="'Customer Text'"
						:placeholder="'Enter a custom message'"
						:required="true"
						:multiline="true"
						v-model="customCustomerText"
					/>
					<div class="custom-message-parameters">
						<button
							class="button button-navy button-auto"
							@click="parameterClicked('code')"
							@mousedown="parameterMouseDown"
						>
							<i class="sk-icon-plus-regular"></i>
							Code
						</button>
						<button
							class="button button-navy button-auto"
							@click="parameterClicked('amount')"
							@mousedown="parameterMouseDown"
						>
							<i class="sk-icon-plus-regular"></i>
							Amount
						</button>
					</div>
				</div>
			</div>
		</transition-expand>
		<div class="sk-modal-actions">
			<button @click="close" class="button button-text">Close</button>
			<button
				type="button"
				class="button button-primary"
				@click="saveCoupon"
			>
				<i class="sk-icon-check-regular"></i>
				Issue Coupon
			</button>
		</div>
	</SkBaseModal>
</template>

<style scoped>
.sk-switch-label-wrapper > span {
	font-size: 14px;
	color: var(--sk-grey3);
}

.default-customer-message {
	font-size: 12px;
	color: var(--sk-grey2);
	margin: 8px 0 12px 25px;
}

.custom-message-parameters > .button {
	height: 28px;
	margin-top: 17px;
	font-size: 10px;
	font-weight: 400;
}

.custom-message-parameters > .button i {
	font-size: 10px;
}

.custom-message-parameters > .button + .button {
	margin-left: 15px;
}
</style>

<script>
import { CouponReason } from "@/enums/couponReason"
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import ActionTypes from "@/store/modules/coupons/action-types"
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import SkInput from "@/components/SkInput.vue"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"
import TransitionExpand from "@/components/transitions/TransitionExpand.vue"
import SkCheckbox from "@/components/SkCheckbox.vue"
import Loading from "vue-loading-overlay"

export default {
	name: "OrderCouponModal",
	mixins: [skModalMixin],
	components: {
		SkBaseModal,
		SkInput,
		SkDropdownSelect,
		TransitionExpand,
		SkCheckbox,
		Loading
	},
	computed: {
		order() {
			return this.$store.state.ordersModule.currentOrder
		},
		orderId() {
			return this.order ? this.order.id : ""
		},
		isOtherCouponReason() {
			return this.couponReason.id == CouponReason.OTHER
		},
		canSendText() {
			return (
				this.order != null &&
				this.couponReason.id == CouponReason.LATE_ORDER
			)
		},
		editCustomerTextButton() {
			return this.editCustomerText
				? "Use Default Customer Text"
				: "Change Customer Text"
		}
	},
	data: function () {
		return {
			loading: false,
			couponType: {
				id: null,
				text: "Type"
			},
			couponReason: {
				id: null,
				text: "Coupon Reason"
			},
			coupon: {
				code: "",
				amount: null,
				type: null,
				reason_id: null,
				reason: ""
			},
			couponTypes: [
				{
					id: null,
					text: "Type"
				},
				{
					id: 0,
					text: "$"
				},
				{
					id: 1,
					text: "%"
				}
			],
			couponReasons: [
				{
					id: null,
					text: "Coupon Reason"
				},
				{
					id: CouponReason.RESTAURANT_CLOSED,
					text: "Restaurant Closed"
				},
				{
					id: CouponReason.LATE_ORDER,
					text: "Late Order"
				},
				{
					id: CouponReason.MISSING_ITEM,
					text: "Missing Item"
				},
				{
					id: CouponReason.WRONG_ITEM,
					text: "Wrong Item"
				},
				{
					id: CouponReason.OTHER,
					text: "Other"
				}
			],
			sendCustomerText: false,
			editCustomerText: false,
			defaultCustomerText:
				"We realize your order is taking longer than usual, so we've issued you ${amount} off your next one - just use code {code} at checkout. Thanks for your patience!",
			customCustomerText: ""
		}
	},
	methods: {
		opened() {
			this.loading = false
			this.couponType = this.couponTypes[0]
			this.couponReason = this.couponReasons[0]
			this.coupon = {
				code: "",
				amount: null,
				type: null,
				reason_id: null,
				reason: ""
			}
			this.sendCustomerText = false
			this.editCustomerText = false
			this.customCustomerText = ""
		},
		validateCouponData() {
			const validCode = this.$refs.codeInput.validate()
			const validAmount = this.$refs.amountInput.validate()
			const validType = this.couponType.id != null
			const validReasonId = this.couponReason.id != null
			const validReason =
				this.validReasonId && this.isOtherCouponReason
					? this.$refs.reasonInput.validate()
					: true
			return (
				validCode &&
				validAmount &&
				validType &&
				validReasonId &&
				validReason
			)
		},
		async saveCoupon() {
			try {
				const isCouponDataValid = this.validateCouponData()
				if (!isCouponDataValid) {
					return
				}
				this.coupon.ref_order_id = this.order.id
				this.coupon.business_id = this.order.business_id
				this.coupon.usage_limit = 1
				this.coupon.type = this.couponType.id
				this.coupon.reason_id = this.couponReason.id
				this.loading = true
				const success = await this.$store.dispatch(
					`couponsModule/${ActionTypes.ADD_COUPON}`,
					{
						coupon: this.coupon,
						sendMessage: this.canSendText && this.sendCustomerText,
						msg:
							this.sendCustomerText &&
							this.customCustomerText.length > 0
								? this.customCustomerText
								: null
					}
				)

				if (success) {
					await this.$store.dispatch(
						"ordersModule/orderStatusUpdate",
						this.order.id
					)
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: `Successfully created coupon ${this.coupon.code}`,
						type: NotificationType.SUCCESS
					})
					this.close()
				} else {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Something went wrong, please try again",
						type: NotificationType.ERROR
					})
				}
				this.loading = false
				return true
			} catch (e) {
				this.loading = false
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Something went wrong, please try again",
					type: NotificationType.ERROR
				})
				return false
			}
		},
		parameterClicked(parameter) {
			const inputWrapper = this.$refs.customCustomerTextInput
			if (inputWrapper && inputWrapper.$el) {
				const result = inputWrapper.$el.getElementsByTagName("textarea")
				if (result && result.length > 0) {
					const input = result[0]
					const startPosition = input.selectionStart
					const endPosition = input.selectionEnd
					let placeholder = `\${${parameter}}`
					if (startPosition || startPosition == "0") {
						this.customCustomerText =
							this.customCustomerText.substring(
								0,
								startPosition
							) +
							placeholder +
							this.customCustomerText.substring(
								endPosition,
								this.customCustomerText.length
							)
					} else {
						this.customCustomerText += placeholder
					}
				}
			}
		},
		parameterMouseDown(event) {
			event.preventDefault()
		}
	}
}
</script>