import Vue from "vue"

Vue.directive("sk-tooltip", {
  bind: function (el, binding) {
    el.addEventListener("mouseenter", function () {
      let tooltip = document.createElement("div")
      let tooltipDimension = el.getBoundingClientRect()
      let id = new Date().getTime()
      el.tooltipId = id
      tooltip.setAttribute("id", `${id}`)
      if (binding.modifiers.right) {
        tooltip.setAttribute("class", "sk-tooltip sk-tooltip-left")
        tooltip.innerHTML = `<span class="sk-arrow-left"></span>${binding.value}`
        tooltip.style.top = (window.pageYOffset + tooltipDimension.top) + (tooltipDimension.height / 2) + "px"
        tooltip.style.left = window.pageXOffset + tooltipDimension.left + tooltipDimension.width + 10 + "px"
      } else {
        tooltip.setAttribute("class", "sk-tooltip")
        tooltip.innerHTML = `<span class="sk-arrow-up"></span>${binding.value}`
        tooltip.style.top = (window.pageYOffset + tooltipDimension.top) + tooltipDimension.height + 10 + "px"
        tooltip.style.left = tooltipDimension.left + (tooltipDimension.width / 2) + "px"
      }
      document.body.appendChild(tooltip)
    })

    el.addEventListener("mouseleave", function () {
      let tooltip = document.getElementById(el.tooltipId)
      if (tooltip) {
        tooltip.style.opacity = "0"
        setTimeout(() => {
          if (tooltip) {
            document.body.removeChild(tooltip)
          }
        }, 250)
      }
    })
  }
})