import { MenuType } from "@arikgaisler/utils/enums/menuType"
import moment from "moment"
import "moment-timezone"

let menuCategorizationMixin = {
	data: function () {
		return {
			menuCategoryTimeRanges: this.$store.state.menuTimeRanges
		}
	},
	computed: {
		menus() {
			return []
		},
		menuCategories() {
			const categories = {
				breakfast: [],
				lunch: [],
				dinner: [],
				latenight: [],
				catering: [],
				popups: [],
				misc: []
			}

			this.menus.forEach((menu) => {
				let isBreakfast = false
				let isLunch = false
				let isDinner = false
				let isLateNight = false
				let isCatering = menu.type == MenuType.PRE_PACKAGED
				let isPopup = menu.type == MenuType.POPUP

				if (menu.opening_hours) {
					menu.opening_hours.forEach((hour) => {
						if (isBreakfast && isLunch && isDinner && isLateNight) {
							return
						}
						
						let schedules = []
						const openTime = parseFloat(
							moment
								.utc(hour.open_time, "HH:mm")
								.add(menu.timezoneOffset, "m")
								.format("HH:mm")
								.replace(":", "")
						)
						let closeTime = parseFloat(
							moment
								.utc(hour.close_time, "HH:mm")
								.add(menu.timezoneOffset, "m")
								.format("HH:mm")
								.replace(":", "")
						)

						if (openTime > closeTime) {
							schedules.push({
								openTime: openTime, 
								closeTime: 2400
							})
							schedules.push({
								openTime: 0,
								closeTime: closeTime
							})
						} else {
							schedules.push({
								openTime: openTime,
								closeTime: closeTime
							})
						}

						closeTime = closeTime == 0 ? 2400 : closeTime

						if (!isBreakfast) {
							isBreakfast = this.menuCategoryTimeRanges.breakfast.some(
								(hour) => schedules.some(schedule => hour >= schedule.openTime && hour <= schedule.closeTime)
							)
						}

						if (!isLunch) {
							isLunch = this.menuCategoryTimeRanges.lunch.some(
								(hour) => schedules.some(schedule => hour >= schedule.openTime && hour <= schedule.closeTime)
							)
						}

						if (!isDinner) {
							isDinner = this.menuCategoryTimeRanges.dinner.some(
								(hour) => schedules.some(schedule => hour >= schedule.openTime && hour <= schedule.closeTime)
							)
						}

						if (!isLateNight) {
							isLateNight = this.menuCategoryTimeRanges.latenight.some(
								(hour) => schedules.some(schedule => hour >= schedule.openTime && hour <= schedule.closeTime)
							)
						}
					})
				}

				if (isCatering) {
					categories.catering.push(menu)
				} else if (isPopup) {
						categories.popups.push(menu)
				} else {
					if (isBreakfast) {
						categories.breakfast.push(menu)
					}
	
					if (isLunch) {
						categories.lunch.push(menu)
					}
	
					if (isDinner) {
						categories.dinner.push(menu)
					}
	
					if (isLateNight) {
						categories.latenight.push(menu)
					}
					
					if (!isBreakfast && !isLunch && !isDinner && !isLateNight && !isCatering && !isPopup){
						categories.misc.push(menu)
					}
				}

			})

			const breakfastRange = this.formatTimeRange(this.menuCategoryTimeRanges.breakfast)
			const lunchRange = this.formatTimeRange(this.menuCategoryTimeRanges.lunch)
			const dinnerRange = this.formatTimeRange(this.menuCategoryTimeRanges.dinner)
			const latenightRange = this.formatTimeRange(this.menuCategoryTimeRanges.latenight)

			return [
				{
					name: "Breakfast",
					timeRange: `${breakfastRange.start} - ${breakfastRange.end}`,
					menuCount: categories.breakfast.length,
					menus: categories.breakfast
				},
				{
					name: "Lunch",
					timeRange: `${lunchRange.start} - ${lunchRange.end}`,
					menuCount: categories.lunch.length,
					menus: categories.lunch
				},
				{
					name: "Dinner",
					timeRange: `${dinnerRange.start} - ${dinnerRange.end}`,
					menuCount: categories.dinner.length,
					menus: categories.dinner
				},
				{
					name: "Late Night",
					timeRange: `${latenightRange.start} - ${latenightRange.end}`,
					menuCount: categories.latenight.length,
					menus: categories.latenight
				},
				{
					name: "Catering",
					menuCount: categories.catering.length,
					menus: categories.catering
				},
				{
					name: "Popup",
					menuCount: categories.popups.length,
					menus: categories.popups
				},
				{
					name: "Misc",
					timeRange: `N/A`,
					menuCount: categories.misc.length,
					menus: categories.misc
				},
			]
		}
	},
	methods: {
		formatTimeRange(range) {
			const rangeStart = range[0].toString()
			const rangeEnd = range[range.length - 1].toString()
			const parsedRangeStart = rangeStart.length > 3 ? `${rangeStart[0]}${rangeStart[1]}:00` : `${rangeStart[0]}:00`
			const parsedRangeEnd = rangeEnd.length > 3 ? `${rangeEnd[0]}${rangeEnd[1]}:00` : `${rangeEnd[0]}:00`
			return {
				start: moment.utc(parsedRangeStart, "HH:mm").format("h:mm A"),
				end: moment.utc(parsedRangeEnd, "HH:mm").format("h:mm A")
			}
		}
	}
}

export default menuCategorizationMixin