export const REFRESH_ORDER_ACTIONS = "REFRESH_ORDER_ACTIONS"
export const REFRESH_ORDER_DELIVERIES = "REFRESH_ORDER_DELIVERIES"
export const REFRESH_ORDER_DELIVERY = "REFRESH_ORDER_DELIVERY"
export const UPDATE_ORDER_TRANSACTION_STATUS = "UPDATE_ORDER_TRANSACTION_STATUS"
export const ORDER_TRANSACTION_STATUS_CHANGED = "ORDER_TRANSACTION_STATUS_CHANGED"
export const UPDATE_CURRENT_ORDER_REFUND = "UPDATE_CURRENT_ORDER_REFUND"
export const UPDATE_CURRENT_ORDER_EXCEPTION = "UPDATE_CURRENT_ORDER_EXCEPTION"
export const REMOVE_OLD_ORDERS = "REMOVE_OLD_ORDERS"

export default {
    REFRESH_ORDER_ACTIONS,
    REFRESH_ORDER_DELIVERIES,
    REFRESH_ORDER_DELIVERY,
    UPDATE_ORDER_TRANSACTION_STATUS,
    ORDER_TRANSACTION_STATUS_CHANGED,
    UPDATE_CURRENT_ORDER_REFUND,
    UPDATE_CURRENT_ORDER_EXCEPTION,
    REMOVE_OLD_ORDERS
}