<template>
	<SkBaseModal :isOpen="isOpen" :hasLoader="true" @close="close">
		<loading :active.sync="loading" :is-full-page="false" />
		<div class="modal-splash"></div>
		<div class="modal-inner-content">
			<h3 class="event-modal-subtitle">Introducing</h3>
			<h1 class="event-modal-title">Events</h1>
			<div class="event-modal-description">
				You can now schedule food popups through 2ndKitchen!
			</div>
			<ul class="event-benefits">
				<li>Boost Orders</li>
				<li>Reach New Customers</li>
				<li>Work with New Businesses</li>
			</ul>
			<div class="event-modal-description">
				Join 2ndKitchen's network of restaurants providing food and
				powering events!
			</div>
			<button class="button button-primary" @click="joinNetworkClicked">
				Join Network
			</button>
		</div>
	</SkBaseModal>
</template>

<style scoped>
* >>> .sk-modal-body {
	max-width: 725px;
	width: 95%;
	min-height: 510px;
}

* >>> .sk-modal-content {
	min-height: 510px;
	flex-direction: row;
	display: flex;
	padding-left: 0;
	padding-top: 0;
	padding-bottom: 0;
	z-index: 100;
	position: relative;
}

* >>> .sk-modal-body-bottom-radius {
	z-index: 0;
}

.modal-splash {
	margin: -4px 0 -4px 0;
	background-image: url("/images/2k-calendar.png");
	background-size: auto 115%;
	background-position: center;
	max-width: 299px;
	min-width: 299px;
	flex: 2;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	box-shadow: 0px 0px 3px rgba(136, 136, 136, 0.25);
}

.modal-inner-content {
	flex: 1;
	padding: 55px 0 31px 28px;
}

.event-modal-title,
.event-modal-subtitle {
	font-weight: 600;
}

.event-modal-title {
	color: var(--sk-orange);
	font-size: 64px;
	margin: 0 0 11px -5px;
}

.event-modal-subtitle {
	color: var(--sk-dark-navy);
	font-size: 24px;
	margin: 0;
}

.event-modal-description {
	color: var(--sk-grey3);
	font-size: 14px;
	margin-bottom: 16px;
}

.event-benefits {
	margin-top: 20px;
	margin-bottom: 20px;
	color: var(--sk-grey3);
	font-size: 12px;
}

.event-benefits li {
	margin-bottom: 30px;
	padding-left: 18px;
}

.button-primary {
	width: 100%;
	max-width: 300px;
	display: block;
	margin: 40px auto 0 auto;
}

@media (max-width: 650px) {
	.modal-splash {
		display: none;
	}
}
</style>

<script>
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import restaurant_service from "@/services/restaurants"
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import Loading from "vue-loading-overlay"

export default {
	name: "JoinPopupNetworkModal",
	mixins: [skModalMixin],
	components: {
		SkBaseModal,
		Loading
	},
	data: function () {
		return {
			restaurantId: 0,
			loading: false
		}
	},
	methods: {
		handleOptions(options) {
			this.loading = false
			this.restaurantId = options.restaurantId ? options.restaurantId : 0
		},
		async joinNetworkClicked() {
			try {
				this.loading = true
				const response = await restaurant_service.requestPopup(
					this.restaurantId
				)
				if (response.status == 200) {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title:
							"Your request to join the popup network is processing"
					})
					this.close()
				} else {
					throw `API Error: ${response.status}`
				}
			} catch (e) {
				this.loading = false
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Something went wrong, please try again",
					type: NotificationType.ERROR
				})
				this.bugsnag.notify("Failed to request popup", (event) => {
					event.addMetadata("error", {
						error: e,
						restaurant_id: this.restaurantId
					})
				})
			}
		}
	}
}
</script>