<template>
	<SkBaseModal class="sk-modal-alt" :isOpen="isOpen" @close="cancel">
		<div class="sk-modal-alt-title">
			<h1>
				Import Untappd Menu
			</h1>
		</div>
		<div class="sk-row">
			<SkDropdownMultiSelect
				v-model="externalMenuIds"
				:options="externalMenuOptions"
			/>
		</div>
		<div class="sk-row">
			<SkDropdownSelect
				:classes="'sk-input'"
				v-model="selectedMenu"
				:options="menuOptions"
			/>
		</div>
		<div class="sk-modal-actions">
			<button class="button button-text" @click="cancel(false)">
				Cancel
			</button>
			<button class="button button-primary" @click="importProducts">
				<i class="sk-icon-check-regular"></i>
				Import Products
			</button>
		</div>
	</SkBaseModal>
</template>

<style scoped>
</style>

<script>
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import skPromiseModalMixin from "@/mixins/modals/sk-promise-modal-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import SkDropdownMultiSelect from "@/components/SkDropdownMultiSelect.vue"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"

export default {
	name: "SelectExternalMenuModal",
	mixins: [skPromiseModalMixin],
	components: {
		SkBaseModal,
		SkDropdownMultiSelect,
		SkDropdownSelect
	},
	data() {
		return {
			externalMenus: [],
			externalMenuIds: [],
			selectedMenu: null
		}
	},
	computed: {
		externalMenuOptions() {
			const options = [
				{
					id: null,
					text: "Select menus to import from Untappd"
				}
			]
			this.externalMenus.forEach(menu => {
				options.push({
					id: menu.id,
					text: menu.name
				})
			})
			return options
		},
		menuOptions() {
			const options = [
				{
					id: null,
					text: "Select menu to import to"
				}
			]
			if (
				this.$store.state.restaurantsModule.currentRestaurant &&
				this.$store.state.restaurantsModule.currentRestaurant.menus
			) {
				this.$store.state.restaurantsModule.currentRestaurant.menus.forEach(
					menu => {
						options.push({
							id: menu.id,
							text: menu.name
						})
					}
				)
			}

			return options
		}
	},
	methods: {
		handleOptions(menus) {
			this.externalMenus = menus
			this.selectedMenu = null
		},
		importProducts() {
			if (!this.externalMenus || this.externalMenus.length == 0) {
				this.showWarning("Please select at least one Untappd menu")
				return
			}

			if (!this.selectedMenu) {
				this.showWarning("Please select a menu to import to")
				return
			}
			const selectedExternalMenus = this.externalMenus.filter(menu =>
				this.externalMenuIds.includes(menu.id)
			)
			this.confirm({
				externalMenus: selectedExternalMenus,
				menu: this.selectedMenu
			})
		},
		showWarning(message) {
			eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
				title: message,
				type: NotificationType.WARNING
			})
		}
	}
}
</script>