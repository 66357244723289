export const SidebarMenuId = {
    "DASHBOARD": 1,
    "ACTIVE_ORDERS": 2,
    "BUSINESS": 3,
    "RESTAURANT": 4,
    "PROMOTIONS": 5,
    // "REPORTS": 6,
    "FINANCE": 7,
    // "FAQ": 8,
    "SUPPLIES": 9,
    "USERS": 10,
    "SALES": 11,
    "EVENTS": 12,
    "PREPACKAGES": 13,
    "INSIGHTS": 14,
    "HUBS": 15
}