<template>
	<div
		:class="{
			'sk-input-group': group,
			focused: focused || hasContent,
			'is-editing': focused,
			'has-error': errorMsg,
			'sk-input-multiline': multiline
		}"
	>
		<div class="sk-input">
			<textarea
				v-if="multiline"
				v-model="content"
				:type="type"
				:ref="'skInput'"
				:name="name"
				:placeholder="placeholder"
				:disabled="disabled"
				:readonly="readonly"
				@change="handleChange"
				@input="handleInput"
				@focus="setFocused"
				@blur="setBlurred"
				@keyup="handleKeyUp"
				@wheel="handleWheel"
				@keydown.down="onArrowDown"
				@keydown.up="onArrowUp"
				:tabindex="tabindex"
			></textarea>
			<input
				v-else
				v-model="content"
				:type="type"
				:min="min"
				:ref="'skInput'"
				:name="name"
				:placeholder="placeholder"
				:disabled="disabled"
				:readonly="readonly"
				@change="handleChange"
				@input="handleInput"
				@focus="setFocused"
				@blur="setBlurred"
				@keyup="handleKeyUp"
				@wheel="handleWheel"
				@keydown.down="onArrowDown"
				@keydown.up="onArrowUp"
				:tabindex="tabindex"
			/>
			<label class="sk-placeholder" @click="focusInput">
				{{ label }}
			</label>
		</div>
		<div class="sk-input-group-right" v-if="group">{{ group }}</div>
		<i
			v-if="hint"
			class="hint-icon sk-icon-info-circle-light"
			v-sk-tooltip="hint"
		></i>
	</div>
</template>

<style lang="scss" scoped>
.has-error .sk-input {
	border: 1px solid var(--sk-red);

	.sk-placeholder {
		color: var(--sk-red);
	}
}

.is-editing .sk-input {
	border: 1px solid var(--sk-navy);
}

.focused .sk-input {
	.sk-placeholder {
		top: 7px;
		font-size: 10px;
	}

	input,
	input:hover,
	input:focus,
	textarea,
	textarea:hover,
	textarea:focus {
		opacity: 1;
	}
}

.sk-input {
	position: relative;
	overflow: hidden;

	input,
	textarea {
		resize: none;
		opacity: 0;
		top: 20px;
		color: var(--sk-grey3);
		opacity: 0;
		border: 0;
		padding-bottom: 0;
		font-size: 14px;
		outline: 0;
		background: none;

		&::placeholder {
			color: var(--sk-grey2);
		}
	}
}

.sk-placeholder,
input,
textarea {
	width: 100%;
	position: absolute;
	left: 0;
	padding-left: 15px;
	transition: all 0.25s ease-in;
	font-size: 14px;
}

.sk-placeholder {
	margin-bottom: 0;
	color: var(--sk-grey2);
	top: calc((100% - 21px) / 2);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.sk-input-multiline .sk-input {
	min-height: 100px;
}

.sk-input-multiline .sk-placeholder {
	top: 21px;
	white-space: normal;
}

.sk-input-multiline.focused .sk-placeholder {
	white-space: nowrap;
}

.sk-input textarea {
	height: calc(100% - 20px);
}

.hint-icon {
	position: absolute;
	right: 10px;
	top: 17.5px;
	font-size: 15px;
	color: var(--sk-grey2);
}
</style>

<script>
import phoneFilterMixin from "@/mixins/phone-filter-mixin"

export default {
	name: "SkInput",
	mixins: [phoneFilterMixin],
	props: {
		name: String,
		type: {
			type: String,
			default: "text"
		},
		required: {
			type: Boolean,
			default: false
		},
		validation: RegExp,
		placeholder: {
			type: String,
			default: ""
		},
		value: [String, Number],
		autoFocus: {
			type: Boolean,
			default: true
		},
		group: {
			type: String
		},
		multiline: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		hint: {
			type: String,
			default: null
		},
		readonly: {
			type: Boolean,
			default: false
		},
		tabindex: {
			type: Number,
			default: 0
		},
		min: {
			type: Number,
			default: null
		}
	},
	data() {
		return {
			focused: false,
			content: this.maskInput(this.value),
			rawValue: this.value,
			errorMsg: null,
			validationEnabled: false
		}
	},
	computed: {
		label: function () {
			return this.errorMsg ? this.errorMsg : this.name
		},
		hasContent() {
			if (this.content == null || this.content == undefined) {
				return false
			} else {
				if (this.type == "number") {
					return Number.isFinite(this.rawValue)
				} else {
					return this.content.length > 0
				}
			}
		}
	},
	methods: {
		scrollIntoView(element, behavior) {
			let scrollTop = window.pageYOffset || element.scrollTop
			let rect = element.getBoundingClientRect()
			const finalOffset = rect.top + scrollTop - rect.height - 10

			window.scrollTo({
				top: finalOffset,
				behavior: behavior || "auto"
			})
		},
		maskInput(value) {
			if (value == null) {
				this.rawValue = value
				this.content = undefined
				return
			}
			if (this.type === "tel") {
				this.rawValue = value.replace(/[\s)(-]/g, "")
				this.content = this.formatPhoneNumber(this.rawValue)
			} else if (this.type === "number") {
				const newValue = Number(value)
				if (this.min != null) {
					if (newValue >= this.min) {
						this.rawValue = value === "" ? "" : newValue
						this.content = value
					} else {
						this.rawValue = this.rawValue
						this.content = this.rawValue
					}
				} else {
					this.rawValue = value === "" ? "" : newValue
					this.content = value
				}
			} else {
				this.rawValue = value
				this.content = this.rawValue
			}
			return this.content
		},
		handleInput(e) {
			if (!e.isTrusted) {
				return
			}
			this.maskInput(e.target.value)
			if (this.type == "number" && this.rawValue === "") {
				this.$emit("input", null)
			} else {
				this.$emit("input", this.rawValue)
			}
		},
		handleKeyUp(e) {
			if (
				(e.key && e.key == "Enter") ||
				(e.keyCode && e.keyCode === 13)
			) {
				this.$emit("onEnter")
			}
		},
		handleWheel(e) {
			e.preventDefault()
			if (e.stopProgragation) {
				e.stopProgragation()
			}
			return false
		},
		handleChange(e) {
			this.$emit("change", e)
		},
		setFocused(e) {
			this.$emit("focus", e)
			this.focused = true
			if (this.autoFocus) {
				this.scrollIntoView(this.$refs.skInput, "smooth")
			}
		},
		setBlurred(e) {
			if (!this.$refs.skInput) {
				return
			}

			this.$emit("blur", e)
			this.focused = false

			if (this.rawValue != this.$refs.skInput.value) {
				this.maskInput(this.$refs.skInput.value)
				if (this.type == "number" && this.rawValue == "") {
					this.$emit("input", null)
				} else {
					this.$emit("input", this.rawValue)
				}
			}
			if (this.validationEnabled) {
				this.validate()
			}
		},
		validate() {
			this.validationEnabled = true
			let msg = null
			if (this.required && (!this.content || this.content.length <= 0)) {
				msg = this.name + " Required"
			}
			if (this.validation && !this.validation.test(this.rawValue)) {
				msg = this.name + " Invalid"
			}
			this.errorMsg = msg
			if (msg) {
				return false
			}
			return true
		},
		resetValidation() {
			this.validationEnabled = false
			this.errorMsg = null
		},
		formatPhoneNumber() {
			const USNumber = !this.rawValue.startsWith("+")
			if (USNumber) {
				const formatted = this.rawValue
					.replace(/\D/g, "+1")
					.match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
				return !formatted[2]
					? formatted[1]
					: "+1 (" +
							formatted[1] +
							") " +
							formatted[2] +
							(formatted[3] ? "-" + formatted[3] : "")
			}
			return this.$options.filters.maskPhoneInput(this.rawValue)
		},
		focusInput() {
			this.$refs.skInput.focus()
		},
		onArrowDown(e) {
			this.$emit("arrowDown", e)
		},
		onArrowUp(e) {
			this.$emit("arrowUp", e)
		}
	},
	watch: {
		value(value) {
			if (value != this.rawValue) {
				this.maskInput(value)
			}
		}
	}
}
</script>