<template>
	<div class="centered-tab-content mobile-button-bottom">
		<div v-if="!newBusiness" class="sk-row sk-widget-alt sk-widget-section">
			<div class="sk-widget-section-title">Details</div>
			<div class="business-detail-section">
				<div class="business-detail-label">
					<span>Launch Date</span>
					<span>{{ launchDate }}</span>
				</div>
				<div class="business-detail-label">
					<span>Business Active</span>
					<SkToggle
						:value="isBusinessActive"
						@input="setActiveStatus($event)"
					/>
				</div>
			</div>
		</div>
		<div class="sk-row sk-widget-alt sk-widget-section">
			<div class="sk-widget-section-title">General</div>
			<SkInput
				ref="slugInput"
				:name="'Business Slug'"
				:placeholder="'Slug'"
				:validation="/^[a-zA-Z0-9]{0,10}$/"
				v-model="slug"
				@input="slugUpdate"
				@change="slugChanged"
			/>
			<SkDropdownSelect
				class="business-type-dd sk-input"
				v-model="type"
				:options="types"
			/>
			<div class="sk-switch-label-wrapper">
				<SkCheckbox v-model="business.allow_asap" />
				<span>Allow ASAP</span>
			</div>
		</div>
		<div class="sk-row sk-widget-alt sk-widget-section">
			<div class="sk-widget-section-title">Dropoff / Pickup Methods</div>
			<div
				v-for="deliveryMode in deliveryModes"
				:key="deliveryMode.id"
				class="sk-switch-label-wrapper delivery-mode-line-item"
				:class="{
					'delivery-mode-line-item-active': deliveryMode.active
				}"
			>
				<SkCheckbox
					@click.native="toggleDeliveryMode(deliveryMode)"
					:value="deliveryMode.active"
					:disabled="true"
				/>
				<span>{{ deliveryMode.name }}</span>
				<div
					v-if="deliveryMode.active"
					class="sk-radio-group"
					:class="{ 'sk-radio-group-active': deliveryMode.isDefault }"
				>
					<SkRadio
						:option="deliveryMode.id"
						v-model="defaultDeliveryMode"
					/>
					<span>Default</span>
				</div>
			</div>
		</div>
		<div class="sk-row sk-widget-alt sk-widget-section">
			<div class="sk-widget-section-title">Finance</div>
			<div class="sk-row">
				<SkInput
					v-model="business.service_fee"
					:name="'Service Fee'"
					:placeholder="'Service Fee'"
					:type="'number'"
					:group="'%'"
				/>
				<SkInput
					v-model="business.tax"
					:name="'Tax'"
					:placeholder="'Tax'"
					:type="'number'"
					:group="'%'"
					:min="0"
				/>
			</div>

			<SkInput
				v-model="business.min_order_amount"
				:name="'Minimum'"
				:placeholder="'Minimum'"
				:type="'number'"
				:group="'$'"
			/>
		</div>
		<div class="sk-row sk-widget-alt sk-widget-section">
			<div class="sk-widget-section-title">Mark up Rules</div>
			<div class="sk-row">
				<SkInput
					class="commission-input"
					v-model="selfDeliveryMUR"
					:name="'Self Delivery Markup amount'"
					:placeholder="'Self Delivery Markup amount'"
					:type="'number'"
					:group="selfDeliveryMURTypeText"
				/>
				<SkDropdownSelect
					ref="typeInput"
					:classes="'sk-input'"
					v-model="selfDeliveryMURType"
					:options="markupTypes"
				/>
			</div>
			<div class="sk-row">
				<SkInput
					class="commission-input"
					v-model="courierMUR"
					:name="'Courier Markup amount'"
					:placeholder="'Courier Markup Amount'"
					:type="'number'"
					:group="courierMURTypeText"
				/>
				<SkDropdownSelect
					ref="typeInput"
					:classes="'sk-input'"
					v-model="courierMURType"
					:options="markupTypes"
				/>
			</div>
			<div class="sk-row">
				<SkInput
					class="commission-input"
					v-model="eventsMUR"
					:name="'Events Markup amount'"
					:placeholder="'Events Markup Amount'"
					:type="'number'"
					:group="eventsMURTypeText"
				/>
				<SkDropdownSelect
					ref="typeInput"
					:classes="'sk-input'"
					v-model="eventsMURType"
					:options="markupTypes"
				/>
			</div>
			<div class="sk-row">
				<SkInput
					class="commission-input"
					v-model="toGoMUR"
					:name="'2Go Markup amount'"
					:placeholder="'2Go Markup Amount'"
					:type="'number'"
					:group="toGoMURTypeText"
				/>
				<SkDropdownSelect
					ref="typeInput"
					:classes="'sk-input'"
					v-model="toGoMURType"
					:options="markupTypes"
				/>
			</div>
			<div class="sk-row">
				<SkInput
					class="commission-input"
					v-model="prePackagedMUR"
					:name="'Catering Markup amount'"
					:placeholder="'Catering Markup Amount'"
					:type="'number'"
					:group="prePackagedMURTypeText"
				/>
				<SkDropdownSelect
					ref="typeInput"
					:classes="'sk-input'"
					v-model="prePackagedMURType"
					:options="markupTypes"
				/>
			</div>
		</div>
		<div class="sk-row sk-widget-alt sk-widget-section">
			<div class="sk-widget-section-title">Commission</div>
			<div class="sk-row">
				<SkInput
					class="commission-input"
					v-model="business.commission"
					:name="'Commission'"
					:placeholder="'Self Delivery Markup amount'"
					:type="'number'"
					:group="'$'"
				/>
				<SkInput
					class="commission-input"
					v-model="business.commission_threshold"
					:name="'Commission Threshold'"
					:placeholder="'Self Delivery Markup amount'"
					:type="'number'"
				/>
			</div>
		</div>
		<div class="sk-row sk-widget-alt sk-widget-section">
			<div class="sk-widget-section-title">Extras</div>
			<textarea
				class="sk-input"
				v-model="business.notes"
				placeholder="Special Instructions"
				type="text"
			></textarea>
			<textarea
				class="sk-input"
				v-model="business.pickup_area_instructions"
				placeholder="Pickup Area Instructions"
				type="text"
			></textarea>
		</div>
		<button
			class="button button-primary button-absolute"
			@click="saveBusinessInfo"
		>
			Save Details
		</button>
	</div>
</template>

<style scoped>
.sk-widget-section >>> .sk-radio {
	width: 15px;
	height: 15px;
	margin-right: 10px;
}

.business-detail-label span:first-child {
	font-size: 14px;
	color: var(--sk-grey2);
	margin-right: 10px;
}

.business-detail-label span:last-child,
.sk-switch-label-wrapper span {
	font-size: 14px;
	color: var(--sk-grey3);
}

.sk-input-group {
	max-width: 260px;
}

.business-detail-section {
	display: flex;
	justify-content: space-between;
}

.business-type-dd {
	width: 100%;
	display: inline-block;
	min-width: 58px;
}

.delivery-mode-line-item + .delivery-mode-line-item {
	margin-top: 15px;
}

.delivery-mode-line-item > span {
	margin: 0 20px 0 20px;
	color: var(--sk-grey3);
}

.delivery-mode-line-item-active > span {
	font-weight: 600;
	color: var(--sk-dark-navy);
}

.delivery-mode-line-item .sk-radio-group > span {
	color: var(--sk-grey2);
	font-style: italic;
}
</style>

<script>
import moment from "moment"
import { NotificationType } from "@/enums/notificationType"
import { DeliveryMode } from "@arikgaisler/utils/enums/deliveryMode"
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { BusinessType } from "@arikgaisler/utils/enums/businessType"
import businessMarkupRulesMixin from "@/mixins/business_markup_rules_mixin"
import deliveryModeFormatterMixin from "@/mixins/business/delivery-mode-formatter-mixin"
import businessTypeFormatterMixin from "@/mixins/business/business-type-formatter-mixin"
import SkInput from "@/components/SkInput.vue"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"
import SkRadio from "@/components/SkRadio.vue"
import SkToggle from "@/components/SkToggle.vue"
import SkCheckbox from "@/components/SkCheckbox.vue"

export default {
	name: "BusinessAdminInfo",
	mixins: [
		businessMarkupRulesMixin,
		deliveryModeFormatterMixin,
		businessTypeFormatterMixin
	],
	components: {
		SkInput,
		SkRadio,
		SkDropdownSelect,
		SkToggle,
		SkCheckbox
	},
	data: function () {
		return {
			slug: ""
		}
	},
	computed: {
		types() {
			const businessTypes = [
				BusinessType.BAR,
				BusinessType.HOTEL,
				BusinessType.RESIDENTIAL,
				BusinessType.COWORKING,
				BusinessType.AIRPORT
			]
			return businessTypes.map((type) => {
				return {
					id: type,
					text: this.$options.filters.businessTypeName(type)
				}
			})
		},
		business() {
			return this.$store.state.businessesModule.currentBusiness
		},
		launchDate() {
			if (
				this.business != null &&
				this.business != undefined &&
				this.business.launch_date != undefined
			) {
				return moment
					.utc(this.business.launch_date)
					.format("ddd, MMMM Do YYYY")
			} else {
				return "N/A"
			}
		},
		deliveryModes() {
			let activeDeliveryModes = []
			if (
				this.business.delivery_modes &&
				this.business.delivery_modes.length > 0
			) {
				activeDeliveryModes = this.business.delivery_modes.map(
					(deliveryMode) => deliveryMode.delivery_mode
				)
			}

			let deliveryModes = [
				{
					id: DeliveryMode.DIRECT_TO_LOCATION,
					name: this.$options.filters.deliveryModeName(
						DeliveryMode.DIRECT_TO_LOCATION,
						this.business.type
					)
				}
			]

			if (this.business.type == BusinessType.BAR) {
				deliveryModes.push({
					id: DeliveryMode.YAY_FOOD_POLE,
					name: this.$options.filters.deliveryModeName(
						DeliveryMode.YAY_FOOD_POLE,
						this.business.type
					)
				})
			}

			if (this.business.type == BusinessType.AIRPORT) {
				deliveryModes.push({
					id: DeliveryMode.LOCKER,
					name: this.$options.filters.deliveryModeName(
						DeliveryMode.LOCKER,
						this.business.type
					)
				})
			}

			if (this.business.type !== BusinessType.AIRPORT) {
				deliveryModes.push(
					{
						id: DeliveryMode.PICKUP_AREA,
						name: this.$options.filters.deliveryModeName(
							DeliveryMode.PICKUP_AREA,
							this.business.type
						)
					},
					{
						id: DeliveryMode.LAST_YARD,
						name: this.$options.filters.deliveryModeName(
							DeliveryMode.LAST_YARD,
							this.business.type
						)
					}
				)
			}

			deliveryModes = deliveryModes.concat([
				{
					id: DeliveryMode.DIRECT_TO_CUSTOMER,
					name: this.$options.filters.deliveryModeName(
						DeliveryMode.DIRECT_TO_CUSTOMER,
						this.business.type
					)
				}
			])
			deliveryModes.forEach((deliveryMode) => {
				deliveryMode.active = activeDeliveryModes.includes(
					deliveryMode.id
				)
				deliveryMode.isDefault =
					this.defaultDeliveryMode == deliveryMode.id
			})
			return deliveryModes
		},
		defaultDeliveryMode: {
			get: function () {
				if (this.business && this.business.delivery_modes) {
					const deliveryMode = this.business.delivery_modes.find(
						(deliveryMode) => deliveryMode.is_default
					)
					return deliveryMode ? deliveryMode.delivery_mode : null
				}
				return null
			},
			set: function (deliveryMode) {
				this.$store.dispatch(
					"businessesModule/updateDefaultDeliveryMode",
					{
						defaultDeliveryMode: deliveryMode
					}
				)
			}
		},
		businessSlug: {
			get: function () {
				return this.business.slug
			}
		},
		isBusinessActive: {
			get: function () {
				return this.business.is_active == 1
			},
			set: function (newVal) {
				if (newVal) {
					this.business.is_active = 1
				} else {
					this.business.is_active = 0
				}
			}
		},
		newBusiness() {
			return this.$route.params.business_slug != undefined ? false : true
		},
		type: {
			get: function () {
				if (!this.business || this.business.type == undefined) {
					return null
				}
				return this.types.find((type) => type.id == this.business.type)
			},
			set: function (newVal) {
				if (this.business && newVal != undefined) {
					this.business.type = newVal.id
				}
			}
		}
	},
	methods: {
		slugChanged() {
			if (this.business.logo != undefined) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Changing slug requires to re-upload images",
					type: NotificationType.WARNING
				})
			}
		},
		slugUpdate() {
			const isValid = this.$refs.slugInput.validate()
			if (isValid) {
				this.business.slug = `${this.slug}`
			}
		},
		setLocalType() {
			const newType = this.types.find(
				(type) => type.id == this.business.type
			)
			if (newType != undefined) {
				this.type = newType
			}
		},
		validateDeliveryModes() {
			const hasActiveDeliveryMode = this.deliveryModes.find(
				(deliveryMode) => deliveryMode.active
			)
			return hasActiveDeliveryMode && this.defaultDeliveryMode != null
		},
		saveBusinessInfo() {
			const deliveryModesValid = this.validateDeliveryModes()
			if (!deliveryModesValid) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "At least one delivery mode must be selected",
					type: NotificationType.WARNING
				})
				return
			}

			this.$emit("save")
		},
		setActiveStatus(event) {
			this.$emit("activate", event)
		},
		toggleDeliveryMode(updatedDeliveryMode) {
			this.$store.dispatch("businessesModule/updateDeliveryModes", {
				updatedDeliveryMode: updatedDeliveryMode
			})
		}
	},
	watch: {
		businessSlug: {
			immediate: true,
			handler(newVal) {
				if (newVal != undefined) {
					this.slug = `${newVal}`
				}
			}
		},
		type: {
			immediate: true,
			handler() {
				this.setLocalType()
			}
		},
		business: {
			immediate: true,
			handler(business) {
				if (business) {
					this.setLocalType()
				}
			}
		}
	}
}
</script>