<template>
	<SkBaseModal
		class="sk-modal-alt"
		:isOpen="isOpen"
		:headerColor="'#2E3C49'"
		:hasLoader="true"
		@close="close"
	>
		<loading :active.sync="loading" :is-full-page="false" />
		<div class="sk-modal-banner">
			<img src="/images/2k-mealboxes.png" alt="Catering" />
		</div>
		<div class="sk-row">
			<h1 class="prepackages-title">Introducing Catering</h1>
		</div>
		<div class="sk-row prepackages-detail">
			Evolve your food experience with customized, sanitary catering orders.
		</div>
		<div class="sk-row">
			<h1 class="prepackages-title">Benefits</h1>
		</div>
		<div class="sk-row">
			<span class="prepackages-detail">
				<i class="sk-icon-check-regular"></i>
				Delight guests with delicious and easy to-go catering orders
			</span>
		</div>
		<div class="sk-row">
			<span class="prepackages-detail">
				<i class="sk-icon-check-regular"></i>
				Reduce costs with options as low as a few dollars per guest
			</span>
		</div>
		<div class="sk-row">
			<span class="prepackages-detail">
				<i class="sk-icon-check-regular"></i>
				Reduce waste by ordering as many as you need the day before
			</span>
		</div>
		<div class="sk-modal-actions">
			<button class="button button-primary" @click="requestPrepackages">
				Get Started with Catering
			</button>
		</div>
	</SkBaseModal>
</template>

<style scoped>
.sk-modal-banner img {
	width: 140px;
}

.prepackages-title {
	color: var(--sk-dark-navy);
	font-weight: 600;
	margin-bottom: -5px;
}

h1.prepackages-title {
	font-size: 18px;
}

h2.prepackages-title {
	font-size: 14px;
}

.prepackages-detail {
	font-size: 12px;
	color: var(--sk-grey3);
	display: flex;
	align-items: center;
}

.prepackages-detail i {
	margin-right: 20px;
	font-size: 13px;
}

.sk-modal-actions {
	margin-top: 30px;
	justify-content: center;
}
</style>

<script>
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import Loading from "vue-loading-overlay"
import ActionTypes from "@/store/modules/prepackages/action-types"
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"

export default {
	name: "PrepackagesIntroModal",
	mixins: [skModalMixin],
	components: {
		SkBaseModal,
		Loading
	},
	data: function () {
		return {
			loading: false
		}
	},
	computed: {},
	methods: {
		closed() {
			this.loading = false
		},
		async requestPrepackages() {
			this.loading = true
			const success = await this.$store.dispatch(
				`prepackagesModule/${ActionTypes.REQUEST_PREPACKAGES}`
			)
			const message = success
				? "Thanks for submitting your request! Your customer success manager will reach out with next steps"
				: "Something went wrong, please try again"
			eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
				title: "Catering",
				message: message,
				type: success
					? NotificationType.SUCCESS
					: NotificationType.ERROR
			})
			this.loading = false
			if (success) {
				this.close()
			}
		}
	}
}
</script>