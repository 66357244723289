<template>
	<div class="sk-page-content">
		<PageHeader
			:title="headerTitle"
			:actions="actions"
			:secondaryActions="secondaryActions"
			:tabs="tabs"
			:breadcrumbs="breadcrumbs"
			@actionClicked="headerActionClicked"
			@secondaryActionClicked="secondaryHeaderActionClicked"
			@tabClicked="headerTabClicked"
		/>
		<input
			type="file"
			ref="fileInput"
			style="display: none"
			@change="batchUploadFilesChanged"
		/>
		<transition name="slide" mode="out-in">
			<BusinessBasicInfo
				key="0"
				v-if="activeTab.id == 0"
				class="centered-tab-content"
				@save="saveBusiness"
			/>
			<BusinessMenus
				key="1"
				v-if="activeTab.id == 1"
				:menuAdded="menuAdded"
				@openNewMenuModal="openNewBusinessMenuModal"
				@save="saveBusiness"
			/>
			<BusinessAdminInfo
				key="3"
				v-if="activeTab.id == 3"
				class="centered-tab-content"
				@save="saveBusiness"
				@activate="activateBusiness"
			/>
			<Schedule
				key="4"
				v-if="activeTab.id == 4"
				:hours="business.opening_hours"
				:offset="offset"
				:timezone="timezone"
				@setHours="setBusinessHours"
				@save="saveBusiness"
			/>
			<BusinessDropoffLocations
				key="5"
				v-if="activeTab.id == 5"
				@save="saveBusiness"
			/>
		</transition>
		<NewBusinessMenuModal ref="newBusinessMenuModal" />
		<DropoffLocationModal ref="dropoffLocationModal" />
		<ConfirmationModal ref="businessConfirmationModal" />
	</div>
</template>

<script>
import { NotificationType } from "@/enums/notificationType"
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { ConfirmationModalType } from "@/enums/confirmationModalType"
import { MenuType } from "@arikgaisler/utils/enums/menuType"
import PageHeader from "@/components/PageHeader.vue"
import NewBusinessMenuModal from "@/components/modals/NewBusinessMenuModal.vue"
import DropoffLocationModal from "@/components/modals/DropoffLocationModal.vue"
import loaderMixin from "@/mixins/loader-mixin"
import { PermissionMethod, PermissionTab } from "@/utils/permissions"
import BusinessMenus from "@/components/business/BusinessMenus.vue"
import BusinessBasicInfo from "@/components/business/BusinessBasicInfo.vue"
import BusinessAdminInfo from "@/components/business/BusinessAdminInfo.vue"
import BusinessDropoffLocations from "@/components/BusinessDropoffLocations.vue"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue"
import Schedule from "@/components/Schedule.vue"

export default {
	name: "Business",
	mixins: [loaderMixin],
	components: {
		PageHeader,
		BusinessMenus,
		NewBusinessMenuModal,
		DropoffLocationModal,
		BusinessBasicInfo,
		BusinessAdminInfo,
		ConfirmationModal,
		Schedule,
		BusinessDropoffLocations
	},
	data: function () {
		return {
			didMount: false,
			activeTabId: 0,
			menuAdded: false
		}
	},
	mounted: function () {
		this.toggleLoader(true)

		if (
			this.$route.params.business_slug != undefined &&
			this.$route.params.business_slug != ""
		) {
			this.$store.dispatch(
				"businessesModule/getBusiness",
				this.$route.params.business_slug
			)
		} else {
			this.$store.dispatch("businessesModule/clearBusiness")
			this.$store.dispatch("businessesModule/getDefaultMURs")
		}
		this.didMount = true
	},
	methods: {
		headerActionClicked(action) {
			switch (action.id) {
				case 0:
					this.openNewBusinessMenuModal()
					break
				case 5:
					this.openDropoffLocationModal()
			}
		},
		secondaryHeaderActionClicked(action) {
			switch (this.activeTab.id) {
				case 1: {
					switch (action.id) {
						case 0: {
							let url = `${this.$webappUrl}/${this.$route.params.business_slug}/menu/preview`
							window.open(url, "_blank")
							break
						}
					}
					break
				}
				case 5: {
					switch (action.id) {
						case 1: {
							this.$refs.fileInput.click()
							break
						}
					}
					break
				}
			}
		},
		openNewBusinessMenuModal() {
			this.$refs.newBusinessMenuModal.open()
		},
		openDropoffLocationModal() {
			this.$refs.dropoffLocationModal.open()
		},
		headerTabClicked(tabClicked) {
			this.activeTabId = tabClicked.id
		},
		async activateBusiness(activate) {
			if (activate) {
				const confirmed =
					await this.$refs.businessConfirmationModal.open({
						title: `Are you sure you want to activate ${this.business.name}?`,
						confirmation: "Activate",
						confirmationIcon: "sk-icon-check-regular",
						cancel: "Cancel",
						type: ConfirmationModalType.GREEN
					})
				if (confirmed) {
					const payload = {
						is_active: 1,
						business_id: this.business.id
					}
					this.$store.dispatch(
						"businessesModule/setBusinessActivationStatus",
						payload
					)
				}
			} else {
				const confirmed =
					await this.$refs.businessConfirmationModal.open({
						title: `Are you sure you want to deactivate ${this.business.name}?`,
						confirmation: "Deactivate",
						confirmationIcon: "sk-icon-check-trash-solid",
						cancel: "Cancel",
						type: ConfirmationModalType.RED
					})
				if (confirmed) {
					const payload = {
						is_active: 0,
						business_id: this.business.id
					}
					this.$store.dispatch(
						"businessesModule/setBusinessActivationStatus",
						payload
					)
				}
			}
		},
		async saveBusiness() {
			try {
				this.toggleLoader(true)
				const businessValidation = this.validateBusiness()
				if (!businessValidation.valid) {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: businessValidation.reason,
						type: NotificationType.ERROR
					})
					this.toggleLoader(false)
					return false
				}
				if (this.$route.params.business_slug != undefined) {
					await this.$store.dispatch(
						"businessesModule/updateBusiness",
						this.business
					)
					this.menuAdded = false
				} else {
					const added = await this.$store.dispatch(
						"businessesModule/addBusiness",
						this.business
					)
					if (added) {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Business",
							message: `${this.business.name} Added`
						})
						this.$router.push({ path: "/businesses" })
					} else {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Business",
							message: "Failed to add business, please try again",
							type: NotificationType.ERROR
						})
					}
				}
				this.toggleLoader(false)
			} catch (e) {
				console.error(e)
				this.toggleLoader(false)
			}
		},
		validateBusiness() {
			let reason = null
			if (
				this.business.address == "" ||
				this.business.address == undefined
			) {
				reason = "Address is empty"
			} else if (
				this.business.city == "" ||
				this.business.city == undefined
			) {
				reason = "City is empty"
			} else if (
				this.business.state == "" ||
				this.business.state == undefined
			) {
				reason = "State is empty"
			} else if (
				this.business.zip == "" ||
				this.business.zip == undefined
			) {
				reason = "Zipcode is empty"
			} else if (
				this.business.slug == "" ||
				this.business.slug == undefined
			) {
				reason = "Business slug is empty"
			}
			return {
				valid: !reason,
				reason: reason
			}
		},
		setBusinessHours(hours) {
			this.$store.dispatch("businessesModule/updateBusinessHours", {
				hours: hours
			})
		},
		batchUploadFilesChanged(event) {
			const files = Object.values(event.target.files)
			if (!files || !files[0]) {
				return
			}
			this.$store.dispatch(`businessesModule/addBatchDropoffLocation`, {
				file: files[0],
				locationId: this.locationId
			})
			this.$refs.fileInput.value = ""
		}
	},
	computed: {
		tabs() {
			let allowedTabs = [
				{
					id: 0,
					text: "Details"
				},
				{
					id: 5,
					text: "Dropoff Locations"
				}
			]
			if (
				this.$store.state.permissions.includes(
					PermissionTab.BUSINESS_ADMIN
				)
			) {
				allowedTabs.push({
					id: 3,
					text: "Admin"
				})
			}
			if (this.toGoEnabledBusiness) {
				allowedTabs.push({
					id: 4,
					text: "2Go Pickup Hours"
				})
			}
			if (!this.newBusiness) {
				allowedTabs.push({
					id: 1,
					text: "Menus"
				})
			}
			return allowedTabs
				.map((tab) => {
					tab.active = tab.id == this.activeTabId
					return tab
				})
				.sort((a, b) => a.id - b.id)
		},
		actions() {
			switch (this.activeTab.id) {
				case 1: {
					if (
						this.$store.state.permissions.includes(
							PermissionMethod.NEW_BUSINESS_MENU
						)
					) {
						return [
							{
								id: 0,
								text: `<i class="sk-icon-plus-regular"></i> New Menu`,
								classes: "button button-primary"
							}
						]
					}
					break
				}
				case 5: {
					return [
						{
							id: 5,
							text: `<i class="sk-icon-plus-regular"></i> Add Dropoff Locations`,
							classes: "button button-primary"
						}
					]
				}
			}
			return []
		},
		secondaryActions() {
			switch (this.activeTab.id) {
				case 1: {
					return [
						{
							text: `<i class="sk-icon-eye-solid"></i> Preview Menus`,
							id: 0
						}
					]
				}
				case 5: {
					return [
						{
							text: `Batch Upload`,
							id: 1
						}
					]
				}
			}
			return []
		},
		activeTab() {
			return this.tabs.find((tab) => tab.active)
		},
		loading() {
			return this.$store.state.businessesModule.loading || !this.didMount
		},
		allowed() {
			return this.$store.state.permissions.includes(
				PermissionTab.BUSINESS_ADMIN
			)
		},
		business() {
			return this.$store.state.businessesModule.currentBusiness
		},
		locationId() {
			return this.$store.state.businessesModule.currentBusiness
				.locations[0].id
		},
		toGoEnabledBusiness() {
			if (this.business && this.business.menus) {
				return (
					this.business.menus.findIndex(
						(menu) =>
							menu.type == MenuType.DONATION ||
							menu.type == MenuType.ECOMMERCE
					) >= 0
				)
			}
			return false
		},
		headerTitle() {
			if (this.newBusiness) {
				return "New Business"
			} else {
				return this.$store.state.businessesModule.currentBusiness.name
					? this.$store.state.businessesModule.currentBusiness.name
					: "..."
			}
		},
		newBusiness() {
			return this.$route.params.business_slug != undefined ? false : true
		},
		offset() {
			return this.$store.getters["businessesModule/offset"]
		},
		timezone() {
			return this.$store.getters["businessesModule/timezone"]
		},
		breadcrumbs() {
			return [
				{
					name: "Businesses",
					link: "/businesses"
				},
				{
					name: this.headerTitle
				}
			]
		}
	},
	watch: {
		loading: function (newVal) {
			this.toggleLoader(newVal)
		}
	}
}
</script>