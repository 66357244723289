<template>
	<SkCalendarDay
		@click.native="orderPrepackages"
		:dayText="day.text"
		:totalItems="prepackagesByBusiness.length"
		:disabled="dayDisabled"
	>
		<template slot="calendar-short-list-item" slot-scope="calendarDay">
			<div
				class="prepackage"
				v-for="prepackageBusiness in prepackagesByBusinessShortList"
				:class="{
					selected:
						!prepackageBusiness.hasMultiplePrepackages &&
						calendarDay.selectedItem &&
						prepackageBusiness.prepackages[0].id ==
							calendarDay.selectedItem.id
				}"
				:key="prepackageBusiness.businessId"
				@click="
					prepackageBusiness.hasMultiplePrepackages
						? calendarDay.viewMore()
						: calendarDay.selectItem(
								prepackageBusiness.prepackages[0]
						  )
				"
			>
				<span :class="prepackageBusiness.statusClass"></span>
				<span class="text-truncate">
					{{ prepackageBusiness.businessName }}
				</span>
				<b v-if="prepackageBusiness.hasMultiplePrepackages">
					{{ prepackageBusiness.prepackagesCount }}
				</b>
			</div>
		</template>
		<template slot="calendar-full-list-item" slot-scope="calendarDay">
			<div
				v-for="prepackageBusiness in prepackagesByBusiness"
				:key="prepackageBusiness.businessId"
			>
				<div
					class="prepackage"
					@click="prepackageBusinessClicked(prepackageBusiness)"
				>
					<span :class="prepackageBusiness.statusClass"></span>
					<span class="text-truncate">
						{{ prepackageBusiness.businessName }}
					</span>
					<b v-if="prepackageBusiness.hasMultiplePrepackages">
						{{ prepackageBusiness.prepackagesCount }}
					</b>
				</div>
				<slot v-if="prepackageBusiness.hasMultiplePrepackages">
					<div
						class="prepackage prepackage-menu"
						v-for="prepackage in prepackageBusiness.prepackages"
						:key="prepackage.id"
						@click="calendarDay.selectItem(prepackage)"
					>
						<span :class="prepackage.statusClass"></span>
						<span class="text-truncate">
							{{ prepackage.menuName }}
						</span>
					</div>
				</slot>
			</div>
		</template>
		<template slot="calendar-detail" slot-scope="calendarDay">
			<PrepackagesCalendarDetail
				:selectedItem="calendarDay.selectedItem"
				:position="calendarDay.position"
				@actionSelected="actionSelected"
				@close="calendarDay.close()"
			/>
		</template>
	</SkCalendarDay>
</template>

<style scoped>
.prepackage {
	font-size: 12px;
	color: var(--sk-grey3);
	background: var(--sk-white);
	border: 1px solid var(--sk-grey);
	border-radius: 5px;
	padding: 2.5px 15px;
	display: flex;
	align-items: center;
	cursor: pointer;
	width: min-content;
	max-width: 100%;
	transition: background 0.25s ease-in, color 0.25s ease-in;
}

.prepackage-menu {
	margin-left: 30px;
	max-width: calc(100% - 50px);
}

.prepackage.selected {
	background: var(--sk-dark-navy);
	color: var(--sk-white);
}

.prepackage:hover {
	filter: brightness(95%);
}

.prepackage + .prepackage {
	margin-top: 2px;
}

.prepackage b {
	color: var(--sk-dark-navy);
	font-size: 12px;
	margin-left: 10px;
}

.prepackage > .status-dot {
	flex-shrink: 0;
}
</style>

<script>
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import moment from "moment"
import tracker from "@/utils/tracker"
import SkCalendarDay from "@/components/SkCalendarDay.vue"
import PrepackagesCalendarDetail from "@/components/prepackages/PrepackagesCalendarDetail.vue"

export default {
	name: "PrepackagesCalendarDay",
	components: {
		SkCalendarDay,
		PrepackagesCalendarDetail
	},
	props: {
		day: Object,
		selectedItem: Object
	},
	computed: {
		prepackagesByBusiness() {
			if (!this.day.prepackages || this.day.prepackages.length == 0) {
				return []
			}
			const businesses = this.$store.getters.businessesMap
			const prepackagesByBusiness = this.day.prepackages.reduce(
				(prepackages, prepackage) => {
					if (!prepackages[prepackage.business_id]) {
						prepackages[prepackage.business_id] = []
					}
					const business = businesses[prepackage.business_id]
					const product =
						prepackage.sub_orders &&
						prepackage.sub_orders[0].products &&
						prepackage.sub_orders[0].products.length > 0
							? prepackage.sub_orders[0].products[0]
							: null
					const menu =
						business && product
							? business.menus.find(
									(menu) => menu.id == product.menu_id
							  )
							: null
					prepackages[prepackage.business_id].push({
						id: prepackage.order_id,
						businessName: business ? business.name : "N/A",
						menuName: menu ? menu.name : "N/A",
						businessId: prepackage.business_id,
						statusClass: this.statusClassForPrepackage(prepackage)
					})
					return prepackages
				},
				{}
			)
			return Object.values(prepackagesByBusiness).map((prepackages) => {
				return {
					businessId: prepackages[0].businessId,
					businessName: prepackages[0].businessName,
					prepackagesCount: prepackages.length,
					prepackages: prepackages,
					hasMultiplePrepackages: prepackages.length > 1,
					statusClass: prepackages[0].statusClass
				}
			})
		},
		prepackagesByBusinessShortList() {
			return this.prepackagesByBusiness.slice(0, 2)
		},
		dayDisabled() {
			const dayMoment = moment(this.day.date, "YYYY-MM-DD")
			const minDate = moment().startOf("d").add(1, "d")
			const maxDate = moment(minDate.format("YYYY-MM-DD"), "YYYY-MM-DD")
				.add(2, "M")
				.endOf("d")
			if (!dayMoment.isBetween(minDate, maxDate, "d", "[]")) {
				return true
			}
			return false
		}
	},
	methods: {
		orderPrepackages(event) {
			if (this.$el == event.target) {
				if (this.dayDisabled) {
					const dayMoment = moment(this.day.date).format("M/d")
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Catering",
						message: `Unable to order for ${dayMoment}`,
						type: NotificationType.WARNING
					})
					return
				}
				this.$emit("orderPrepackages", this.day.date)
				tracker.trackEvent("Add Prepackage Clicked", {
					context: "Calendar"
				})
			}
		},
		selectItem(event) {
			this.$emit("selectItem", event)
		},
		actionSelected(event) {
			this.$emit("actionSelected", event)
		},
		prepackageBusinessClicked(prepackageBusiness) {
			if (!prepackageBusiness.hasMultiplePrepackages) {
				this.selectItem(prepackageBusiness.prepackages[0])
			}
		},
		statusClassForPrepackage(prepackage) {
			if (
				prepackage &&
				prepackage.sub_orders &&
				prepackage.sub_orders.length > 0
			) {
				const suborder = prepackage.sub_orders[0]
				const isCancelled =
					(suborder && suborder.status == 4) ||
					prepackage.cancelled == 1
				let isUpcoming =
					suborder && [0, 1, 3, 5].includes(suborder.status)
				if (
					isUpcoming &&
					suborder &&
					suborder.delivery_slot &&
					suborder.delivery_slot != ""
				) {
					const deliveryMoment = moment.utc(suborder.delivery_slot)
					const deliveryPassed = deliveryMoment.isBefore(moment.utc())
					if (deliveryPassed) {
						isUpcoming = false
					}
				}

				if (isCancelled) {
					return "status-dot red"
				} else if (isUpcoming) {
					return "status-dot navy"
				} else {
					return "status-dot grey"
				}
			}

			return ""
		}
	}
}
</script>