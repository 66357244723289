import actions from "./actions"
import mutations from "./mutations"

const state = {
	loading: false,
	restaurantPayouts: [],
	businessInvoices: [],
	restaurantsFinanceDetails: [],
	businessesFinanceDetails: [],
	currentRestaurantPayoutHistory: [],
	currentBusinessInvoiceHistory: [],
	currentBusinessInvoiceHistoryTotalCount: 0,
	currentFinanceDetails: {
		amount: 0,
		funding_source: null
	}
}

export default{
	namespaced: true, 
	state,
	mutations,
	actions
}