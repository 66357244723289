<template>
	<div class="business-guests">
		<slot v-if="businessGuests.length > 0">
			<div class="sk-table sk-table-default">
				<div class="sk-table-header">
					<div class="sk-table-row">
						<div>Name</div>
						<div>Email</div>
						<div>Phone</div>
						<div>Check In Date</div>
						<div>Check Out Date</div>
						<div>Room</div>
						<div>Checked In By</div>
						<div>Actions</div>
					</div>
				</div>
				<transition-group
					name="list"
					tag="div"
					class="sk-table-body"
					appear
				>
					<BusinessGuestLineItem
						v-for="businessGuest in businessGuests"
						:key="businessGuest.id"
						:businessGuest="businessGuest"
						@editBusinessGuestClicked="
							openBusinessGuestModalClicked
						"
						@sendBusinessGuestDigitalMenu="
							sendBusinessGuestDigitalMenu
						"
						@sendBusinessGuestVoucherClicked="
							sendBusinessGuestVoucherClicked
						"
						@deleteBusinessGuestClicked="deleteBusinessGuest"
					/>
				</transition-group>
			</div>
			<PaginationFooter
				:contentText="'Guests'"
				:pageSize="20"
				:total="totalBusinessGuests"
				@pageChanged="filterPage"
			/>
		</slot>
		<EmptyPlaceholderWidget v-else :title="`No Guests Found`" />
		<ConfirmationModal ref="businessGuestConfirmationModal" />
		<BusinessGuestVoucherModal ref="sendBusinessGuestVoucherModal" />
	</div>
</template>

<style scoped>
.business-guests {
	height: 100%;
	display: flex;
	flex-direction: column;
}
</style>

<script>
import ActionTypes from "@/store/modules/users/action-types"
import loaderMixin from "@/mixins/loader-mixin"
import businessGuestActionsMixin from "@/mixins/users/business-guest-actions-mixin"
import BusinessGuestLineItem from "@/components/users/BusinessGuestLineItem.vue"
import PaginationFooter from "@/components/PaginationFooter.vue"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue"
import BusinessGuestVoucherModal from "@/components/modals/businessGuests/BusinessGuestVoucherModal.vue"
import EmptyPlaceholderWidget from "@/components/EmptyPlaceholderWidget.vue"

export default {
	name: "BusinessGuests",
	mixins: [loaderMixin, businessGuestActionsMixin],
	components: {
		BusinessGuestLineItem,
		PaginationFooter,
		ConfirmationModal,
		BusinessGuestVoucherModal,
		EmptyPlaceholderWidget
	},
	props: {
		filter: Object
	},
	data: function () {
		return {
			pageRange: {
				page: 1,
				start: 0,
				end: 20
			}
		}
	},
	mounted: async function () {
		this.getBusinessGuests()
	},
	computed: {
		businessGuests() {
			return this.$store.state.usersModule.businessGuests
		},
		totalBusinessGuests() {
			return this.$store.state.usersModule.totalBusinessGuestsCount
		}
	},
	methods: {
		getBusinessGuests() {
			this.toggleLoader(true)
			this.$store
				.dispatch(
					`usersModule/${ActionTypes.SEARCH_BUSINESS_GUESTS}`,
					Object.assign(
						{
							pageStart: this.pageRange.start,
							pageSize: 20
						},
						this.filter
					)
				)
				.finally(() => {
					this.toggleLoader(false)
				})
		},
		filterPage(range) {
			this.pageRange = range
			this.getBusinessGuests()
		},
		openBusinessGuestModalClicked(businessGuest) {
			this.$emit("editBusinessGuestClicked", businessGuest)
		}
	},
	watch: {
		filter: {
			deep: true,
			handler() {
				this.getBusinessGuests()
			}
		}
	}
}
</script>