export const GET_ACTIVITY_FEED = "GET_ACTIVITY_FEED"
export const ADD_ACTIVITY = "ADD_ACTIVITY"
export const READ_ACTIVITES = "READ_ACTIVITES"
export const RESET_ACTIVITY_FEED = "RESET_ACTIVITY_FEED"

export default {
  GET_ACTIVITY_FEED,
  ADD_ACTIVITY,
  READ_ACTIVITES,
  RESET_ACTIVITY_FEED
}