import actions from "./actions"
import mutations from "./mutations"

const state = {
	activities: [],
	total: 0,
	totalRead: 0,
	totalNew: 0,
	hasMore: true,
	offset: 0,
	limit: 20,
	fetching: false
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}