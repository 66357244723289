let pluralizeFilterMixin = {
    filters: {
        pluralize: function(amount, singular, plural) {
            if (!plural) {
                plural = `${singular}s`
            }
            if (amount != undefined && amount != null && (amount > 1 || amount < 1)) {
                return plural
            }
            return singular
        }
    }
}

export default pluralizeFilterMixin
