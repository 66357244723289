<template>
	<div class="mobile-button-bottom">
		<div class="sk-row sk-widget-alt sk-widget-section">
			<div class="sk-widget-section-title">General</div>
			<BannerUpload
				:titleText="'Cover Photo'"
				:imgSrc="cover"
				:recommendedSize="'1500px x 550px'"
				:previewWidth="140"
				:previewHeight="140"
				:entity="'business'"
				:entity_name="business.slug"
				:disabled="imageUploadDisabled"
				:disabledMessage="'Please enter a slug before uploading a cover'"
				@set="setBusinessCover"
				@loading="toggleLoader"
				@error="imageUploadError"
			/>
			<ImageUpload
				:titleText="'Logo'"
				:imgSrc="logo"
				:placeholderText="business.slug"
				:previewWidth="140"
				:previewHeight="140"
				:entity="'business'"
				:entity_name="business.slug"
				:disabled="imageUploadDisabled"
				:disabledMessage="'Please enter a slug before uploading a logo'"
				@set="setBusinessLogo"
				@loading="toggleLoader"
				@error="imageUploadError"
			/>
			<EstablishmentTypeaheadInput
				v-model="establishment"
				:placeholder="'Google Business Name'"
			/>
			<SkInput
				:name="'Business Name'"
				:placeholder="'Name'"
				v-model="business.name"
			/>
			<AddressTypeaheadInput v-model="address" />
			<SkInput
				ref="reviewLinkInput"
				:name="'Review Link'"
				:placeholder="'http://example.com'"
				:validation="
					/^$|(https?:\/\/)?[\w\-~]+(\.[\w\-~]+)+(\/[\w\-~]*)*(#[\w\-]*)?(\?.*)?/
				"
				v-model="business.review_link"
			/>
		</div>
		<div class="sk-row sk-widget-alt sk-widget-section">
			<div class="sk-widget-section-title">Contact</div>
			<SkTelephoneInput
				v-for="(phone, i) in phones"
				:key="`phone-${i}`"
				:name="'Business Phone Number'"
				:placeholder="'(555) 555-5555'"
				:type="'tel'"
				v-model="phones[i]"
			/>
			<button
				class="button button-text sk-widget-section-text-button"
				@click="addPhone"
			>
				<i class="sk-icon-plus-regular"></i>
				Add Phone Number
			</button>
		</div>
		<div
			class="sk-row sk-widget-alt sk-widget-section"
			v-if="showAccountInfo"
		>
			<div class="sk-widget-section-title">Account</div>
			<div class="business-detail-section">
				<div class="business-detail-label">
					<span>Launch Date</span>
					<span>{{ launchDate }}</span>
				</div>
				<div class="business-detail-label">
					<span>Business ID</span>
					<span>{{ business.id }}</span>
				</div>
			</div>
		</div>
		<button
			class="button button-primary button-absolute"
			@click="saveBusinessInfo"
		>
			Save Details
		</button>
	</div>
</template>

<style scoped>
.business-detail-section {
	display: flex;
	justify-content: space-between;
}

.business-detail-label span:first-child {
	font-size: 14px;
	color: var(--sk-grey2);
	margin-right: 10px;
}

.business-detail-label span:last-child {
	font-size: 14px;
	color: var(--sk-grey3);
}
</style>

<script>
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import gapi from "@/utils/gapi"
import moment from "moment"
import loaderMixin from "@/mixins/loader-mixin"
import phoneFilterMixin from "@/mixins/phone-filter-mixin"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"
import SkInput from "@/components/SkInput.vue"
import SkTelephoneInput from "@/components/SkTelephoneInput"
import ImageUpload from "@/components/ImageUpload.vue"
import BannerUpload from "@/components/BannerUpload.vue"
import AddressTypeaheadInput from "@/components/AddressTypeaheadInput.vue"
import EstablishmentTypeaheadInput from "@/components/EstablishmentTypeaheadInput.vue"

export default {
	name: "BusinessBasicInfo",
	mixins: [loaderMixin, phoneFilterMixin],
	components: {
		ImageUpload,
		BannerUpload,
		SkDropdownSelect,
		SkInput,
		SkTelephoneInput,
		AddressTypeaheadInput,
		EstablishmentTypeaheadInput
	},
	data: function () {
		return {
			phones: [],
			emails: []
		}
	},
	computed: {
		business() {
			return this.$store.state.businessesModule.currentBusiness
		},
		logo() {
			if (
				this.$store.state.businessesModule.currentBusiness.logo != "" &&
				this.$store.state.businessesModule.currentBusiness.logo !=
					undefined
			) {
				return (
					this.$businessCDNURL +
					this.$route.params.business_slug +
					"/" +
					this.$store.state.businessesModule.currentBusiness.logo
				)
			} else {
				return ""
			}
		},
		launchDate() {
			return moment
				.utc(this.business.launch_date)
				.format("ddd, MMMM Do YYYY")
		},
		cover() {
			if (
				this.$store.state.businessesModule.currentBusiness.img != "" &&
				this.$store.state.businessesModule.currentBusiness.img !=
					undefined
			) {
				return (
					this.$businessCDNURL +
					this.$route.params.business_slug +
					"/" +
					this.$store.state.businessesModule.currentBusiness.img
				)
			} else {
				return ""
			}
		},
		phone() {
			return this.business && this.business.phone_number
				? this.business.phone_number
				: ""
		},
		address: {
			get: function () {
				return {
					placeId: this.business.external_location_id,
					address: this.business.address,
					city: this.business.city,
					state: this.business.state,
					zip: this.business.zip
				}
			},
			set: function (address) {
				this.business.external_location_id =
					address && address.placeId ? address.placeId : ""
				this.business.address =
					address && address.address ? address.address : ""
				this.business.city = address && address.city ? address.city : ""
				this.business.state =
					address && address.state ? address.state : ""
				this.business.zip = address && address.zip ? address.zip : ""
			}
		},
		establishment: {
			get: function () {
				return {
					placeId: this.business.external_establishment_id,
					name: this.business.external_establishment_name
				}
			},
			set: function (establishment) {
				if (establishment) {
					this.business.external_establishment_id =
						establishment.placeId ? establishment.placeId : ""
					this.business.external_establishment_name =
						establishment.name ? establishment.name : ""
					if (!this.business.name || this.business.name.length == 0) {
						this.business.name = establishment.shortName
							? establishment.shortName
							: ""
					}
					if (!this.address || !this.address.placeId) {
						this.toggleLoader(true)
						gapi.getPlaceDetails(establishment.placeId).then(
							(result) => {
								this.toggleLoader(false)
								const addressComponents = result
									? result.address_components
									: null
								const data =
									gapi.parseAddressComponents(
										addressComponents
									)
								data.placeId = establishment.placeId
								this.address = data
							}
						)
					}
				} else {
					this.business.external_establishment_id = null
					this.business.external_establishment_name = ""
				}
			}
		},
		showAccountInfo() {
			return this.$route.params.business_slug != undefined
		},
		imageUploadDisabled() {
			return !this.business.slug || this.business.slug.length == 0
		}
	},
	methods: {
		validate() {
			const reviewLinkValid = this.business.review_link
				? this.$refs.reviewLinkInput.validate()
				: true
			return reviewLinkValid
		},
		saveBusinessInfo() {
			const isValid = this.validate()
			if (isValid) {
				this.updatePhone()
				this.$emit("save")
			} else {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Business Details",
					message: "Some information entered is invalid",
					type: NotificationType.WARNING
				})
			}
		},
		setBusinessLogo(img) {
			this.$store.dispatch("businessesModule/setCurrentBusinessLogo", img)
		},
		setBusinessCover(img) {
			this.$store.dispatch(
				"businessesModule/setCurrentBusinessCover",
				img
			)
		},
		updatePhone() {
			let validPhoneNums = []
			this.phones.forEach((phone) => {
				const internationalNumber =
					this.$options.filters.internationalPhone(phone, "+1")
				if (internationalNumber) {
					validPhoneNums.push(internationalNumber)
				}
			})
			validPhoneNums = validPhoneNums.filter((phone) => phone.length != 0)
			this.phones = validPhoneNums
			this.business.phone_number = this.phones.join(";")
		},
		addPhone() {
			this.phones.push("")
		},
		addEmail() {
			this.emails.push("")
		},
		imageUploadError(message) {
			eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
				title: "Business Details",
				message: message,
				type: NotificationType.WARNING
			})
		}
	},
	watch: {
		phone: {
			immediate: true,
			handler(newVal) {
				if (newVal != undefined) {
					this.phones = newVal.split(";")
				}
			}
		},
		email: {
			immediate: true,
			handler(newVal) {
				if (newVal != undefined) {
					this.emails = newVal.split(";")
				}
			}
		}
	},
	beforeDestroy: function () {
		this.updatePhone()
	}
}
</script>