import MutationTypes from "@/store/modules/hubs/mutation-types"
import Vue from "vue"

export default {
    [MutationTypes.GOT_HUBS](state, hubs) {
        state.hubs = hubs
    },
    [MutationTypes.ADDED_HUB](state, hub) {
        if (!state.hubs[hub.external_location_id]) {
            Vue.set(state.hubs, hub.external_location_id, [])
        } 
        state.hubs[hub.external_location_id].push(hub)
    },
    [MutationTypes.GOT_HUB](state, hub) {
        state.currentHub = hub
    },
    [MutationTypes.GOT_HUB_RESTAURANT_MENUS](state, menus) {
        state.currentHubRestaurantMenus = menus
    },
    [MutationTypes.GOT_HUB_BUSINESSES](state, businesses) {
        state.currentHubBusinesses = businesses
    },
    [MutationTypes.ADDED_HUB_BUSINESS](state, payload) {
        const business = state.currentHubBusinesses.find(business => business.id == payload.businessId)
        if (business) {
            const businessMenuIds = business.menus.map(menu => menu.id)
            const missingMenuIds = payload.menuIds.filter(menuId => !businessMenuIds.includes(menuId))
            if (missingMenuIds.length > 0) {
                state.currentHubRestaurantMenus
                    .filter(menu => missingMenuIds.includes(menu.id))
                    .forEach(menu => {
                        business.menus.push(menu)
                    })
            }
        }
    },
    [MutationTypes.UPDATED_HUB_BUSINESS_MENUS](state, payload) {
        let menuIds = payload.menuIds.slice()
        let menuIdsToAdd = menuIds.slice()
        const business = state.currentHubBusinesses.find(business => business.id == payload.businessId)
        const hubMenuIds = state.currentHub && state.currentHub.restaurant_menu_ids ? state.currentHub.restaurant_menu_ids : []
        if (business && hubMenuIds) {
            business.menus = business.menus.filter(menu => {
                if (hubMenuIds.includes(menu.id) && !menuIds.includes(menu.id)) {
                    return false
                }
                menuIdsToAdd = menuIdsToAdd.filter(id => id != menu.id)
                return true
            })
            const menus = payload.menus.filter(menu => menuIdsToAdd.includes(menu.id))
            business.menus = business.menus.concat(menus)
        }
    }
}