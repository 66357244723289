
<template>
	<div class="sk-time-picker" @click="handleDisabled">
		<SkInput
			ref="timePickerInput"
			@focus="togglePopover(true)"
			@blur="togglePopover(false)"
			:disabled="disabled"
			:value="inputValue"
			:readonly="true"
			:name="name"
			:hint="hint"
			:placeholder="placeholder"
			:required="required"
		/>
		<SkTimePickerPopover :show="showTimePicker" v-model="model" />
	</div>
</template>

<style scoped>
.sk-time-picker {
	position: relative;
}

.sk-time-picker >>> .sk-time-picker-popover {
	margin-top: 0px;
}
</style>

<script>
import moment from "moment"
import SkInput from "@/components/SkInput.vue"
import SkTimePickerPopover from "@/components/SkTimePickerPopover.vue"

export default {
	name: "SkTimePicker",
	components: {
		SkInput,
		SkTimePickerPopover
	},
	props: {
		name: String,
		placeholder: String,
		value: String,
		disabled: {
			type: Boolean,
			default: false
		},
		hint: {
			type: String,
			default: null
		},
		required: {
			type: Boolean,
			default: false
		},
		inputFormat: {
			type: String,
			default: null
		}
	},
	data: function () {
		return {
			showTimePicker: false,
			validationEnabled: false
		}
	},
	computed: {
		model: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit("input", val)
			}
		},
		inputValue() {
			if (this.value && this.inputFormat) {
				return moment
					.utc(this.value, "HH:mm A")
					.format(this.inputFormat)
			}
			return this.value
		}
	},
	methods: {
		togglePopover(show) {
			if (show) {
				this.$emit("shown")
			} else {
				this.$emit("hidden")
			}
			this.showTimePicker = show
		},
		handleDisabled() {
			if (this.disabled) {
				this.$emit("toggleDisabled")
				return
			}
		},
		validate() {
			this.validationEnabled = true
			this.$refs["timePickerInput"].validate()
		},
		resetValidation() {
			this.validationEnabled = false
			this.$refs["timePickerInput"].resetValidation()
		}
	},
	watch: {
		value() {
			if (this.validationEnabled) {
				this.$nextTick(() => {
					this.validate()
				})
			}
		}
	}
}
</script>