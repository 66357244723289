<template>
	<SkBaseModal :isOpen="isOpen" @close="close" class="sk-modal-alt">
		<div class="sk-modal-alt-title">
			<h1>
				Issue Exception:
				<span class="sk-modal-alt-subtitle">#{{ orderId }}</span>
			</h1>
		</div>
		<ExceptionForm ref="exceptionForm" :options="options" :type="type" />
		<div class="sk-modal-actions">
			<button @click="close" class="button button-text">Close</button>
			<button
				type="button"
				class="button button-success"
				@click="submitExceptionForm"
			>
				<i class="sk-icon-check-regular"></i>
				{{ submitButtonText }}
			</button>
		</div>
	</SkBaseModal>
</template>

<script>
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import ExceptionForm from "@/components/forms/ExceptionForm.vue"

export default {
	name: "ExceptionModal",
	mixins: [skModalMixin],
	components: {
		SkBaseModal,
		ExceptionForm
	},
	props: {
		type: {
			type: Number,
			default: 0
		}
	},
	data() {
		return {
			options: null
		}
	},
	computed: {
		orderId() {
			return this.currentOrder ? this.currentOrder.id : ""
		},
		currentOrder() {
			if (this.options == null || this.options.order == null) {
				return this.$store.state.ordersModule.currentOrder
			} else {
				return this.options.order
			}
		},
		submitButtonText() {
			return this.options && this.options.exception
				? "Update Exception"
				: "Issue Exception"
		}
	},
	methods: {
		handleOptions(options) {
			this.options = options
		},
		closed() {
			this.$refs.exceptionForm.resetForm()
		},
		submitExceptionForm() {
			this.$refs.exceptionForm.submit().then((success) => {
				if (success) {
					this.close()
				}
			})
		}
	}
}
</script>