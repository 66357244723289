<template>
	<SkBaseModal :isOpen="isOpen" @close="close" class="sk-modal-alt">
		<div class="sk-modal-alt-title">
			<h1>Send voucher {{ code }} to:</h1>
		</div>
		<div class="sk-row">
			<SkInput
				v-model="email"
				ref="emailInput"
				:validation="emailRegex"
				:required="true"
				:name="'Email'"
				:placeholder="placeholderText"
				:type="'email'"
			/>
		</div>
		<div class="sk-modal-actions">
			<button @click="close" class="button button-text">Close</button>
			<button
				type="button"
				class="button button-success"
				@click="sendVoucherEmail"
			>
				<i class="sk-icon-check-regular"></i>
				Send Voucher
			</button>
		</div>
	</SkBaseModal>
</template>

<script>
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import SkInput from "@/components/SkInput.vue"
import utils from "@/utils/utils"
import ActionTypes from "@/store/modules/coupons/action-types"
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"

export default {
	name: "SendVoucherEmailModal",
	mixins: [skModalMixin],
	components: {
		SkBaseModal,
		SkInput
	},
	data() {
		return {
			email: "",
			emailRegex: utils.emailRegex,
			id: 0,
			code: ""
		}
	},
	computed: {
		placeholderText() {
			return "Email"
		}
	},
	methods: {
		opened: function () {
			this.email = ""
		},
		handleOptions(options) {
			if (options && options.voucher) {
				this.id = options.voucher.id
				this.code = options.voucher.code
			}
		},
		async sendVoucherEmail() {
			const validEmail = this.$refs.emailInput.validate()
			if (validEmail) {
				let payload = { id: this.id, userEmail: this.email }
				const resp = await this.$store.dispatch(
					`couponsModule/${ActionTypes.SEND_VOUCHER_EMAIL}`,
					payload
				)
				if (resp) {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Succesfully sent voucher to " + this.email,
						type: NotificationType.SUCCESS
					})
				} else {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Unable to send voucher email. Please try again",
						type: NotificationType.ERROR
					})
				}
				this.email = ""
				this.code = ""
				this.close()
			}
		}
	}
}
</script>