import { EventStatus } from "@arikgaisler/utils/enums/eventStatus"
import { ConfirmationModalType } from "@/enums/confirmationModalType"
import { PreOrderFulfillmentStatus } from "@arikgaisler/utils/enums/preOrderFulfillmentStatus"
import { EventBusEvents } from "@/enums/eventBusEvents"
import { eventBus } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import { RouteName } from "@/router"
import ActionTypes from "@/store/modules/events/action-types"

let eventActionsMixin = {
    methods: {
        deleteEvent: async function (event, businessName) {
            const confirmed = await this.$refs.confirmationModal.open({
                title: `Delete ${businessName} event?`,
                body: "Are you sure you want to delete this event? Deleting will permanently remove it from the calendar and you will not be able to recover it.",
                confirmation: "Delete Event",
                confirmationIcon: "sk-icon-trash-alt-solid",
                cancel: "Remain Cancelled",
                type: ConfirmationModalType.RED
            })
            if (confirmed) {
                this.$store.dispatch(`eventsModule/${ActionTypes.DELETE_EVENT}`, {
                    eventId: event.id
                }).then(success => {
                    if (success) {
                        eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
                            title: `${businessName} Event`,
                            message: `Successfully deleted ${businessName}'s event`
                        })
                        if (this.$route.name == RouteName.EVENT) {
                            this.$router.push({ path: `/events` })
                        }
                    } else {
                        eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
                            title: `${businessName} Event`,
                            message: `Failed to delete ${businessName}'s event`,
                            type: NotificationType.ERROR
                        })
                    }
                })
            }
        },
        updateEventStatus: async function (event, businessName, status) {
            try {
                let title = ""
                let body = ""
                let confirmationIcon = "sk-icon-check-regular"
                let confirmation = ""
                let cancel = ""
                let confirmationType = ConfirmationModalType.GREEN
                let successMsg = ""
                let errorMsg = ""

                switch (status) {
                    case EventStatus.CANCELLED:
                        title = `Cancel ${businessName} Event`
                        body = "Are you sure you want to cancel this event? Canceling will remove it from the schedule, but you will still be able to view it."
                        cancel = "Keep Event"
                        confirmation = "Cancel Event"
                        confirmationIcon = "sk-icon-times-regular"
                        confirmationType = ConfirmationModalType.RED
                        successMsg = `Successfully cancelled ${businessName}'s Event`
                        errorMsg = `Failed to cancel ${businessName}'s Event`
                        break
                    case EventStatus.CONFIRMED:
                        title = `Confirm ${businessName} Event`
                        body = "Are you sure you’re ready to confirm this event? You can Edit the event up until the start time, but you will need to Confirm the event again."
                        cancel = "Keep Editing"
                        confirmation = "Confirm Event"
                        successMsg = `Successfully confirmed ${businessName}'s Event`
                        errorMsg = `Failed to confirm ${businessName}'s Event`
                        break
                    case EventStatus.IN_REVIEW:
                        title = `Restore ${businessName} Event`
                        body = "Are you sure you want to restore this event? Reinstating will return it to the schedule and allow it to be edited."
                        cancel = "Remain Cancelled"
                        confirmation = "Restore Event"
                        successMsg = `Successfully restored ${businessName}'s Event`
                        errorMsg = `Failed to restore ${businessName}'s Event`
                        break
                }

                const confirmed = await this.$refs.confirmationModal.open({
                    title: title,
                    body: body,
                    cancel: cancel,
                    confirmation: confirmation,
                    confirmationIcon: confirmationIcon,
                    type: confirmationType
                })
                if (confirmed) {
                    const success = await this.$store.dispatch(`eventsModule/${ActionTypes.UPDATE_EVENT_STATUS}`, {
                        status: status,
                        id: event.id
                    })

                    if (success) {
                        eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
                            title: `${businessName} Event`,
                            message: successMsg
                        })
                    } else {
                        eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
                            title: `${businessName} Event`,
                            message: errorMsg,
                            type: NotificationType.ERROR
                        })
                    }
                }
                return confirmed
            } catch (e) {
                throw e
            }
        },
        async duplicateEvent(event) {
            const success = await this.$store.dispatch(`eventsModule/${ActionTypes.DUPLICATE_BUSINESS_EVENT}`, event)
            if (success) {
                eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
                    title: event.name,
                    message: `${event.name} succesfully duplicated`
                })
            } else {
                eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
                    title: `${event.name} Duplicate Event`,
                    message: "Error duplicating event",
                    type: NotificationType.ERROR
                })
            }
        },
        async getEventManifestUrl(event) {
            const url = await this.$store.dispatch(`eventsModule/${ActionTypes.GET_EVENT_MANIFEST}`, event.id)
            if (!url || url == null || url == '') {
                eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
                    title: `${event.name} Manifest Download`,
                    message: "Error downloading event manifest",
                    type: NotificationType.ERROR
                })
            }
            return url 
        },
        async updateEventFulfillmentStatus(event, businessName, status) {
            try {
                let title = ""
                let body = ""
                let successMsg = ""
                let errorMsg = ""

                switch (status) {
                    case PreOrderFulfillmentStatus.IN_PROGRESS:
                        title = `Mark ${businessName} Event as accepted`
                        body = "Are you sure you want to accept this event?"
                        successMsg = "Marked event as accepted!"
                        errorMsg = "Failed to mark event as accepted"
                        break
                    case PreOrderFulfillmentStatus.COMPLETED:
                        title = `Mark ${businessName} Event as picked up`
                        body = "Are you sure you want to mark this event as picked up?"
                        successMsg = "Marked event as completed!"
                        errorMsg = "Failed to mark event as completed"
                        break
                    case PreOrderFulfillmentStatus.DELIVERED:
                        title = `Mark ${businessName} Event as delivered`
                        body = "Are you sure you want to mark this event as delivered?"
                        successMsg = "Marked event as delivered!"
                        errorMsg = "Failed to mark event as delivered"
                        break
                }

                const confirmed = await this.$refs.confirmationModal.open({
                    title: title,
                    body: body,
                    confirmation: "Mark Event",
                    confirmationIcon: "sk-icon-check-regular"
                })
                if (confirmed) {
                    const success = await this.$store.dispatch(`eventsModule/${ActionTypes.UPDATE_EVENT_FULFILLMENT_STATUS}`, {
                        status: status,
                        id: event.id
                    })
                    if (success) {
                        eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
                            title: `${businessName} Event`,
                            message: successMsg
                        })
                    } else {
                        eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
                            title: `${businessName} Event`,
                            message: errorMsg,
                            type: NotificationType.ERROR
                        })
                    }
                }
                return confirmed
            } catch (e) {
                throw e
            }
        },
        async endEvent(event, businessName) {
            try {
                const confirmed = await this.$refs.confirmationModal.open({
                    title: `End ${businessName} Event?`,
                    body: "Are you sure you want to end this event?",
                    confirmation: "End Event",
                    confirmationIcon: "sk-icon-check-regular"
                })
                if (confirmed) {
                    const success = await this.$store.dispatch(`eventsModule/${ActionTypes.END_EVENT}`, event.id)
                    if (success) {
                        eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
                            title: `${businessName} Event`,
                            message: "The event has been ended"
                        })
                    } else {
                        eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
                            title: `${businessName} Event`,
                            message: "Failed to end the event",
                            type: NotificationType.ERROR
                        })
                    }
                }
                return confirmed
            } catch (e) {
                throw e
            }
        },
        async stopEventPreOrders(event) {
            try {
                const confirmed = await this.$refs.confirmationModal.open({
                    title: `Stop Pre Orders for ${event.name} ?`,
                    body: "Are you sure you want to stop pre orders for this event?",
                    confirmation: "Stop Pre Orders",
                    confirmationIcon: "sk-icon-check-regular"
                })
                if (confirmed) {
                    const success = await this.$store.dispatch(`eventsModule/${ActionTypes.STOP_EVENT_PRE_ORDERS}`, event.id)
                    if (success) {
                        eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
                            title: `${event.name}`,
                            message: "Pre orders have been stopped"
                        })
                    } else {
                        eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
                            title: `${event.name}`,
                            message: "Failed to stop pre orders for the event",
                            type: NotificationType.ERROR
                        })
                    }
                }
                return confirmed
            } catch (e) {
                throw e
            }
        }
    }
}

export default eventActionsMixin