<template>
	<GmapMap
		ref="map"
		:class="{ 'gmaps-hide-footer': hideFooter }"
		:center="center"
		:zoom="11"
		:options="mapOptions"
	>
		<GmapInfoWindow
			:options="infoOptions"
			:position="infoWindowPos"
			:opened="infoWinOpen"
			@closeclick="infoWinOpen = false"
		></GmapInfoWindow>
		<GmapMarker
			:key="index"
			v-for="(marker, index) in markers"
			:position="marker.position"
			:title="marker.title"
			:icon="marker.icon"
			@click="markerClicked(marker, index)"
			@mouseover="markerHovered(marker, index)"
			@mouseout="markerHovered(marker, index)"
		></GmapMarker>
		<div slot="visible">
			<div class="map-legend" v-if="legend && legend.length > 0">
				<div
					v-for="(item, i) in legend"
					:key="`legend-${i}`"
					v-html="item.text"
				></div>
			</div>
		</div>
	</GmapMap>
</template>

<style>
.gmaps-hide-footer .gm-style-cc {
	display: none;
}

.gmaps-hide-footer a[href^="http://maps.google.com/maps"]
{
	display: none !important;
}

.gmaps-hide-footer a[href^="https://maps.google.com/maps"]
{
	display: none !important;
}

.gm-style .gm-style-iw-c {
	box-shadow: none;
	border: 1px solid var(--sk-grey);
}

.gm-style .gm-style-iw-t::after {
	box-shadow: none;
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-top: 18px solid var(--sk-grey);
	transform: none;
	left: -10px;
}

.gmnoprint > div:not(.gmnoprint),
.gm-control-active.gm-fullscreen-control {
	background-color: rgba(255, 255, 255, 0.85) !important;
	box-shadow: none !important;
	border: 1px solid var(--sk-grey) !important;
	border-radius: 5px !important;
}
</style>

<style scoped>
* >>> .vue-map {
	width: 100%;
	position: relative;
	border-radius: 5px;
}

.map-legend {
	display: flex;
	align-items: center;
	position: absolute;
	bottom: 10px;
	left: 10px;
	z-index: 1000;
	background-color: rgba(255, 255, 255, 0.85);
	padding: 10px 15px;
	border-radius: 5px;
	border: 1px solid var(--sk-grey);
	max-width: 80%;
	font-size: 10px;
	color: var(--sk-grey3);
}

.map-legend > div {
	display: flex;
	align-items: center;
}

.map-legend > div + div {
	margin-left: 30px;
}
</style>

<script>
export default {
	name: "SkMap",
	props: {
		hideFooter: {
			type: Boolean,
			default: true
		},
		center: {
			type: Object,
			default: () => {
				return { lat: 41.8, lng: -87.6 }
			}
		},
		markers: Array,
		legend: Array
	},
	data: function () {
		return {
			currentMarkerIndex: null,
			infoWinOpen: false,
			infoWindowPos: null,
			infoOptions: {
				content: "",
				pixelOffset: {
					width: 0,
					height: -23
				},
				disableAutoPan: true
			},
			mapOptions: {
				disableDefaultUI: true,
				scaleControl: true,
				zoomControl: true,
				rotateControl: true,
				fullscreenControl: true,
				styles: [
					{
						featureType: "water",
						elementType: "geometry",
						stylers: [
							{
								color: "#e9e9e9"
							},
							{
								lightness: 17
							}
						]
					},
					{
						featureType: "landscape",
						elementType: "geometry",
						stylers: [
							{
								color: "#f5f5f5"
							},
							{
								lightness: 20
							}
						]
					},
					{
						featureType: "road.highway",
						elementType: "geometry.fill",
						stylers: [
							{
								color: "#ffffff"
							},
							{
								lightness: 17
							}
						]
					},
					{
						featureType: "road.highway",
						elementType: "geometry.stroke",
						stylers: [
							{
								color: "#ffffff"
							},
							{
								lightness: 29
							},
							{
								weight: 0.2
							}
						]
					},
					{
						featureType: "road.arterial",
						elementType: "geometry",
						stylers: [
							{
								color: "#ffffff"
							},
							{
								lightness: 18
							}
						]
					},
					{
						featureType: "road.local",
						elementType: "geometry",
						stylers: [
							{
								color: "#ffffff"
							},
							{
								lightness: 16
							}
						]
					},
					{
						featureType: "poi",
						elementType: "geometry",
						stylers: [
							{
								color: "#f5f5f5"
							},
							{
								lightness: 21
							}
						]
					},
					{
						featureType: "poi.park",
						elementType: "geometry",
						stylers: [
							{
								color: "#dedede"
							},
							{
								lightness: 21
							}
						]
					},
					{
						elementType: "labels.text.stroke",
						stylers: [
							{
								visibility: "on"
							},
							{
								color: "#ffffff"
							},
							{
								lightness: 16
							}
						]
					},
					{
						elementType: "labels.text.fill",
						stylers: [
							{
								saturation: 36
							},
							{
								color: "#333333"
							},
							{
								lightness: 40
							}
						]
					},
					{
						elementType: "labels.icon",
						stylers: [
							{
								visibility: "off"
							}
						]
					},
					{
						featureType: "transit",
						elementType: "geometry",
						stylers: [
							{
								color: "#f2f2f2"
							},
							{
								lightness: 19
							}
						]
					},
					{
						featureType: "administrative",
						elementType: "geometry.fill",
						stylers: [
							{
								color: "#fefefe"
							},
							{
								lightness: 20
							}
						]
					},
					{
						featureType: "administrative",
						elementType: "geometry.stroke",
						stylers: [
							{
								color: "#fefefe"
							},
							{
								lightness: 17
							},
							{
								weight: 1.2
							}
						]
					}
				]
			}
		}
	},
	methods: {
		fitBounds() {
			this.$nextTick(() => {
				this.$refs.map.$mapPromise.then((map) => {
					if (this.markers && this.markers.length > 0) {
						let bounds = new google.maps.LatLngBounds()
						this.markers.forEach((marker) => {
							let position = new google.maps.LatLng(
								marker.position.lat,
								marker.position.lng
							)
							bounds.extend(position)
						})
						map.fitBounds(bounds)
					}
				})
			})
		},
		markerHovered(marker, index) {
			this.$emit("markerHovered", {
				marker: marker,
				index: index
			})
			this.toggleInfoWindow(marker, index)
		},
		markerClicked(marker, index) {
			this.$emit("markerClicked", {
				marker: marker,
				index: index
			})
		},
		toggleInfoWindow(marker, index) {
			this.infoWindowPos = marker.position
			this.infoOptions.content = marker.title
			this.infoWinOpen =
				this.currentMarkerIndex == index ? !this.infoWinOpen : true
			this.currentMarkerIndex = this.infoWinOpen ? index : null
		}
	},
	watch: {
		markers: {
			immediate: true,
			handler() {
				if (this.markers && this.markers.length > 0) {
					this.fitBounds()
				}
				this.infoWindowPos = null
				this.currentMarkerIndex = null
				this.infoOptions.content = ""
				this.infoWinOpen = false
			}
		}
	}
}
</script>