import { MURType } from "@/enums/murType.js"

let businessMarkupRulesMixin = {
    data: function() {
		return {
			markupTypes: [
				{
					id: null,
					text: "Markup Type"
				},
				{
					id: 1,
					text: "$"
				},
				{
					id: 2,
					text: "%"
				}
			]
		}
	},
    computed: {       
        prePackagedMURTypeText() {
			const prePackagedMUR = this.findBusinessMUR(
				MURType.PRE_PACKAGED
			)
			if (prePackagedMUR) {
				return prePackagedMUR.markup_type == 1 ? "$" : "%"
			} else {
				const defaultMUR = this.findDefaultMUR(MURType.PRE_PACKAGED)
				return defaultMUR.markup_type == 1 ? "$" : "%"
			}
		},
        selfDeliveryMURTypeText() {
			const selfDeliveryMUR = this.findBusinessMUR(MURType.SELF_DELIVERY)
			if (selfDeliveryMUR) {
				return selfDeliveryMUR.markup_type == 1 ? "$" : "%"
			} else {
				const defaultMUR = this.findDefaultMUR(MURType.SELF_DELIVERY)
				return defaultMUR.markup_type == 1 ? "$" : "%"
			}
		},
		courierMURTypeText() {
			const selfDeliveryMUR = this.findBusinessMUR(
				MURType.COURIER_DELIVERY
			)
			if (selfDeliveryMUR) {
				return selfDeliveryMUR.markup_type == 1 ? "$" : "%"
			} else {
				const defaultMUR = this.findDefaultMUR(MURType.COURIER_DELIVERY)
				return defaultMUR.markup_type == 1 ? "$" : "%"
			}
		},
		eventsMURTypeText() {
			const selfDeliveryMUR = this.findBusinessMUR(MURType.EVENT)
			if (selfDeliveryMUR) {
				return selfDeliveryMUR.markup_type == 1 ? "$" : "%"
			} else {
				const defaultMUR = this.findDefaultMUR(MURType.EVENT)
				return defaultMUR.markup_type == 1 ? "$" : "%"
			}
		},
		toGoMURTypeText() {
			const selfDeliveryMUR = this.findBusinessMUR(MURType.TO_GO)
			if (selfDeliveryMUR) {
				return selfDeliveryMUR.markup_type == 1 ? "$" : "%"
			} else {
				const defaultMUR = this.findDefaultMUR(MURType.TO_GO)
				return defaultMUR.markup_type == 1 ? "$" : "%"
			}
		},
        prePackagedMURType: {
			get: function() {
				const prePackagedMUR = this.findBusinessMUR(
					MURType.PRE_PACKAGED
                )
				if (prePackagedMUR) {
					return {
						id: prePackagedMUR.markup_type,
						text: prePackagedMUR.markup_type == 1 ? "$" : "%"
					}
				} else {
					const defaultMUR = this.findDefaultMUR(
						MURType.PRE_PACKAGED
                    )
					return {
						id: defaultMUR.markup_type,
						text: defaultMUR.markup_type == 1 ? "$" : "%"
					}
				}
			},
			set: function(newVal) {
				this.setBusinessMURType(MURType.PRE_PACKAGED, newVal.id)
			}
        },
        prePackagedMUR: {
			get: function() {
				if (this.businessMURs.length > 0) {
					const prePackagedMUR = this.findBusinessMUR(
						MURType.PRE_PACKAGED
					)
					if (prePackagedMUR) {
						return prePackagedMUR.markup_value
					} else {
						const defaultMUR = this.findDefaultMUR(
							MURType.PRE_PACKAGED
						)
						if (defaultMUR) {
							return defaultMUR.markup_value
						}
					}
				}
				else {
						const defaultMUR = this.findDefaultMUR(
							MURType.PRE_PACKAGED
						)
						if (defaultMUR) {
							return defaultMUR.markup_value
						}
					}
			},
			set: function(newVal) {
				this.setBusinessMURValue(MURType.PRE_PACKAGED, newVal)
			}
		},
		selfDeliveryMURType: {
			get: function() {
				const selfDeliveryMUR = this.findBusinessMUR(
					MURType.SELF_DELIVERY
				)
				if (selfDeliveryMUR) {
					return {
						id: selfDeliveryMUR.markup_type,
						text: selfDeliveryMUR.markup_type == 1 ? "$" : "%"
					}
				} else {
					const defaultMUR = this.findDefaultMUR(
						MURType.SELF_DELIVERY
					)
					return {
						id: defaultMUR.markup_type,
						text: defaultMUR.markup_type == 1 ? "$" : "%"
					}
				}
			},
			set: function(newVal) {
				this.setBusinessMURType(MURType.SELF_DELIVERY, newVal.id)
			}
		},
		selfDeliveryMUR: {
			get: function() {
				if (this.businessMURs.length > 0) {
					const selfDeliveryMUR = this.findBusinessMUR(
						MURType.SELF_DELIVERY
					)
					if (selfDeliveryMUR) {
						return selfDeliveryMUR.markup_value
					} else {
						const defaultMUR = this.findDefaultMUR(
							MURType.SELF_DELIVERY
						)
						if (defaultMUR) {
							return defaultMUR.markup_value
						}
					}
				}
				else {
						const defaultMUR = this.findDefaultMUR(
							MURType.SELF_DELIVERY
						)
						if (defaultMUR) {
							return defaultMUR.markup_value
						}
					}
			},
			set: function(newVal) {
				this.setBusinessMURValue(MURType.SELF_DELIVERY, newVal)
			}
		},
		courierMURType: {
			get: function() {
				const courierMUR = this.findBusinessMUR(
					MURType.COURIER_DELIVERY
				)

				if (courierMUR) {
					return {
						id: courierMUR.markup_type,
						text: courierMUR.markup_type == 1 ? "$" : "%"
					}
				} else {
					const defaultMUR = this.findDefaultMUR(
						MURType.COURIER_DELIVERY
					)
					return {
						id: defaultMUR.markup_type,
						text: defaultMUR.markup_type == 1 ? "$" : "%"
					}
				}
			},
			set: function(newVal) {
				this.setBusinessMURType(MURType.COURIER_DELIVERY, newVal.id)
			}
		},
		courierMUR: {
			get: function() {
				if (this.businessMURs.length > 0) {
					const courierMUR = this.findBusinessMUR(
						MURType.COURIER_DELIVERY
					)
					if (courierMUR) {
						return courierMUR.markup_value
					}
					else {
						const defaultMUR = this.findDefaultMUR(
							MURType.COURIER_DELIVERY
						)
						if (defaultMUR) {
							return defaultMUR.markup_value
						}
					}
				}
				else {
						const defaultMUR = this.findDefaultMUR(
							MURType.COURIER_DELIVERY
						)
						if (defaultMUR) {
							return defaultMUR.markup_value
						}
					}
			},
			set: function(newVal) {
				this.setBusinessMURValue(MURType.COURIER_DELIVERY, newVal)
			}
		},
		eventsMURType: {
			get: function() {
				const eventsMUR = this.findBusinessMUR(MURType.EVENT)
				if (eventsMUR) {
					return {
						id: eventsMUR.markup_type,
						text: eventsMUR.markup_type == 1 ? "$" : "%"
					}
				} else {
					const defaultMUR = this.findDefaultMUR(MURType.EVENT)
					return {
						id: defaultMUR.markup_type,
						text: defaultMUR.markup_type == 1 ? "$" : "%"
					}
				}
			},
			set: function(newVal) {
				this.setBusinessMURType(MURType.EVENT, newVal.id)
			}
		},
		eventsMUR: {
			get: function() {
				if (this.businessMURs.length > 0) {
					const eventsMUR = this.findBusinessMUR(MURType.EVENT)
					if (eventsMUR) {
						return eventsMUR.markup_value
					}
					else {
						const defaultMUR = this.findDefaultMUR(
							MURType.EVENT
						)
						if (defaultMUR) {
							return defaultMUR.markup_value
						}
					}
				}
				else {
						const defaultMUR = this.findDefaultMUR(
							MURType.EVENT
						)
						if (defaultMUR) {
							return defaultMUR.markup_value
						}
					}
			},
			set: function(newVal) {
				this.setBusinessMURValue(MURType.EVENT, newVal)
			}
		},
		toGoMURType: {
			get: function() {
				const toGoMUR = this.findBusinessMUR(MURType.TO_GO)
				if (toGoMUR) {
					return {
						id: toGoMUR.markup_type,
						text: toGoMUR.markup_type == 1 ? "$" : "%"
					}
				} else {
					const defaultMUR = this.findDefaultMUR(MURType.TO_GO)
					return {
						id: defaultMUR.markup_type,
						text: defaultMUR.markup_type == 1 ? "$" : "%"
					}
				}
			},
			set: function(newVal) {
				this.setBusinessMURType(MURType.TO_GO, newVal.id)
			}
		},
		toGoMUR: {
			get: function() {
				if (this.businessMURs.length > 0) {
					const toGoMUR = this.findBusinessMUR(MURType.TO_GO)
					if (toGoMUR) {
						return toGoMUR.markup_value
					}
					else {
						const defaultMUR = this.findDefaultMUR(
							MURType.TO_GO
						)
						if (defaultMUR) {
							return defaultMUR.markup_value
						}
					}
				}
				else {
						const defaultMUR = this.findDefaultMUR(
							MURType.TO_GO
						)
						if (defaultMUR) {
							return defaultMUR.markup_value
						}
					}
			},
			set: function(newVal) {
				this.setBusinessMURValue(MURType.TO_GO, newVal)
			}
		},
		formattedToGoMURText(){
			let mur = this.toGoMUR
			let murType = this.toGoMURType
			if (murType.id == 1){
				return murType.text + mur
			}
			else {
				return mur + murType.text
			}
		},
		formattedSelfDeliveryMURText(){
			let mur = this.selfDeliveryMUR
			let murType = this.selfDeliveryMURType
			if (murType.id == 1){
				return murType.text + mur
			}
			else {
				return mur + murType.text
			}
		},
		formattedCourierMURText(){
			let mur = this.courierMUR
			let murType = this.courierMURType
			if (murType.id == 1){
				return murType.text + mur
			}
			else {
				return mur + murType.text
			}
		},
		formattedEventsMURText(){
			let mur = this.eventsMUR
			let murType = this.eventsMURType
			if (murType.id == 1){
				return murType.text + mur
			}
			else {
				return mur + murType.text
			}
		},
		formattedPrePackagedMURText(){
			let mur = this.eventsMUR
			let murType = this.eventsMURType
			if (murType.id == 1){
				return murType.text + mur
			}
			else {
				return mur + murType.text
			}
		},
		defaultMURs() {
			return this.$store.state.businessesModule.defaultMURs
		},
		businessMURs() {
			return this.$store.state.businessesModule.currentBusinessMURs
		},
    },
    methods: {
        findBusinessMUR(type) {
			return this.businessMURs.find(e => e.type == type)
		},
		findDefaultMUR(type) {
			return this.defaultMURs.find(e => e.id == type)
		},
		setBusinessMURValue(type, value) {
			const payload = {
				markup_value: value,
				type: type
			}
			this.$store.dispatch(
				"businessesModule/setBusinessMURValue",
				payload
			)
		},
		setBusinessMURType(type, markup_type) {
			const payload = {
				markup_type: markup_type,
				type: type
			}
			this.$store.dispatch("businessesModule/setBusinessMURType", payload)
		}
    }
}

export default businessMarkupRulesMixin