<template>
	<div class="average-accuracy-widget vld-parent">
		<loading :active.sync="loading" :is-full-page="false"></loading>
		<InsightBaseWidget
			:title="'Average Accuracy'"
			:footerIcon="'sk-icon-lightbulb-light'"
			:footerTitle="'Improving your accuracy can help to improve your customer ratings!'"
		>
			<div class="average-accuracy-content" slot="content">
				<transition name="statistic">
					<div class="average-accuracy-statistic" v-if="!loading">
						{{ averageAccuracy }}%
					</div>
				</transition>
				<SkChart
					v-if="!loading"
					:type="'Pie'"
					:data="chartData"
					:options="chartOptions"
					:eventHandlers="eventHandlers"
				/>
			</div>
		</InsightBaseWidget>
	</div>
</template>

<style scoped>
.average-accuracy-widget >>> .sk-widget-alt {
	height: 100%;
}

.average-accuracy-content {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 40px 0;
	position: relative;
	min-height: 255px;
	flex: 1;
}

.average-accuracy-statistic {
	font-size: 36px;
	position: absolute;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	color: var(--sk-dark-navy);
	font-weight: 600;
}

/* statisitic transition */
.statistic-enter-active,
.statistic-leave-active {
	transition: transform 0.35s ease-out, opacity 0.25s ease-out;
}

.statistic-enter,
.statistic-leave-to {
	transform: translateY(40px);
	opacity: 0;
}

.statistic-enter-to,
.statistic-leave {
	transform: translateY(0);
	opacity: 1;
}
</style>

<script>
import chartist from "chartist"
import InsightBaseWidget from "@/components/insights/InsightBaseWidget.vue"
import Loading from "vue-loading-overlay"
import SkChart from "@/components/SkChart.vue"

export default {
	name: "RestaurantAverageAccuracyChart",
	components: {
		InsightBaseWidget,
		Loading,
		SkChart
	},
	data: function () {
		return {
			chartOptions: {
				height: "175px",
				width: "175px",
				donut: true,
				donutWidth: 20,
				startAngle: 0,
				total: 100,
				chartPadding: 0,
				showLabel: false
			},
			eventHandlers: [
				{
					event: "draw",
					fn(event) {
						if (event.type === "slice" && event.index > 0) {
							let pathLength = event.element._node.getTotalLength()

							event.element.attr({
								"stroke-dasharray":
									pathLength + "px " + pathLength + "px"
							})

							let animationDefinition = {
								"stroke-dashoffset": {
									id: "anim" + event.index,
									dur: 1000,
									from: -pathLength + "px",
									to: "0px",
									easing: chartist.Svg.Easing.easeOutQuint,
									fill: "freeze"
								}
							}
							event.element.attr({
								"stroke-dashoffset": -pathLength + "px"
							})
							event.element.animate(animationDefinition, false)
						}
					}
				}
			]
		}
	},
	computed: {
		loading() {
			return this.$store.state.insightsModule.averageAccuracy.loading
		},
		averageAccuracy() {
			let data = this.$store.state.insightsModule.averageAccuracy.data
			if (data != null) {
				return data.toFixed(2).replace(/[.,]00$/, "")
			}
			return 0
		},
		chartData() {
			return {
				series: [
					{
						value: 100,
						className: "ct-insights-unfilled"
					},
					{
						value: this.averageAccuracy,
						className: "ct-insights-donut ct-series-b"
					}
				]
			}
		}
	}
}
</script>