<template>
	<SkBaseModal class="sk-modal-alt" :isOpen="isOpen" @close="cancel">
		<button class="button button-icon button-close" @click="cancel()">
			<i class="sk-icon-times-regular"></i>
		</button>
		<div class="sk-modal-alt-title">
			<img src="/images/untappd-logo.png" alt="Untappd Logo" />
			<h1>
				Untappd
			</h1>
		</div>
		<div class="sk-row">
			<SkInput
				v-model="username"
				ref="usernameInput"
				:name="'Username'"
				:required="true"
			/>
		</div>
		<div class="sk-row">
			<SkInput
				v-model="apiKey"
				ref="apiKeyInput"
				:name="'API Key'"
				:required="true"
			/>
		</div>
		<div class="sk-row">
			<div class="sk-switch-label-wrapper">
				<SkCheckbox v-model="autoSync" />
				<span>Auto Sync</span>
			</div>
		</div>
		<div class="sk-modal-actions">
			<button class="button button-text" @click="cancel">
				Cancel
			</button>
			<button class="button button-primary" @click="saveIntegration">
				{{ confirmBtnText }}
			</button>
		</div>
	</SkBaseModal>
</template>

<style scoped>
.sk-switch-label-wrapper {
    color: var(--sk-grey2);
}
</style>

<script>
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import skPromiseModalMixin from "@/mixins/modals/sk-promise-modal-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import SkInput from "@/components/SkInput.vue"
import SkCheckbox from "@/components/SkCheckbox.vue"

export default {
	name: "UntappdIntegrationModal",
	mixins: [skPromiseModalMixin],
	components: {
		SkBaseModal,
		SkInput,
		SkCheckbox
	},
	data: function () {
		return {
			username: "",
			apiKey: "",
			autoSync: false,
			editing: false
		}
	},
	computed: {
		confirmBtnText() {
			return this.editing ? "Save Integration" : "Add Integration"
		}
	},
	methods: {
		handleOptions(options) {
            if (options.integration) {
                this.editing = true
                this.username = options.integration.username ? options.integration.username : ""
                this.apiKey = options.integration.apiKey ? options.integration.apiKey : ""
                this.autoSync = options.integration.autoSync ? options.integration.autoSync : false
            } else {
                this.editing = false
            }
		},
		saveIntegration() {
			const isAPIkeyValid = this.$refs.apiKeyInput.validate()
			const isUsernameValid = this.$refs.usernameInput.validate()
			if (!isAPIkeyValid || !isUsernameValid) {
				let message = !isAPIkeyValid
					? "An API key is required"
					: "A username is required"
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Untappd Integration",
					message: message,
					type: NotificationType.WARNING
				})
				return
			}
			this.confirm({
				username: this.username,
				apiKey: this.apiKey,
				autoSync: this.autoSync
			})
		}
	}
}
</script>