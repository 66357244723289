<template>
	<div class="total-orders-statistic vld-parent">
		<loading :active.sync="showLoader" :is-full-page="false"></loading>
		<InsightBaseWidget
			:title="'Total Orders'"
			:contentText="statistic"
			:footerIcon="'sk-icon-lightbulb-light'"
			:footerTitle="'Learn more about utilizing this information'"
			:footerLinkText="'View'"
			:actions="actions"
			@actionSelected="actionSelected"
			@footerClicked="footerClicked"
		/>
	</div>
</template>

<style scoped>
.total-orders-statistic {
	display: flex;
}
</style>

<script>
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import pluralizeFilterMixin from "@/mixins/pluralize-filter-mixin"
import InsightBaseWidget from "@/components/insights/InsightBaseWidget.vue"
import Loading from "vue-loading-overlay"

export default {
	name: "TotalOrdersStatistic",
	mixins: [pluralizeFilterMixin],
	components: {
		InsightBaseWidget,
		Loading
	},
	props: {
		footerArticleId: String
	},
	data: function () {
		return {
			exporting: false,
			actions: [
				{
					text: `<i class="sk-icon-download"></i> Download`,
					id: 0
				}
			]
		}
	},
	computed: {
		totalOrders() {
			const data = this.$store.state.insightsModule.totalOrders.data
			if (data) {
				return data.reduce((total, dailyData) => {
					total += dailyData.total
					return total
				}, 0)
			}
			return null
		},
		statistic() {
			if (this.totalOrders) {
				const suffix = this.$options.filters.pluralize(this.totalOrders, "Order")
				return `${this.totalOrders} ${suffix}`
			}
			return "--"
		},
		showLoader() {
			return this.loadingData || this.exporting
		},
		loadingData() {
			return this.$store.state.insightsModule.totalOrders.loading
		}
	},
	methods: {
		actionSelected() {
			this.exporting = true
			const module = this.$store.state.insightsModule
			const downloadFilters = {
				startDate: module.filters.startDate,
				endDate: module.filters.endDate
			}

			if (module.filters.businessId) {
				downloadFilters.businessId = module.filters.businessId
			}

			if (module.filters.restaurantId) {
				downloadFilters.restaurantId = module.filters.restaurantId
			}

			if (this.totalOrders) {
				downloadFilters.totalOrders = this.totalOrders
			}

			this.$store
				.dispatch("ordersModule/exportOrders", downloadFilters)
				.then((success) => {
					this.exporting = false
					if (success) {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Insights",
							message: "Orders successfully exported!"
						})
					} else {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Insights",
							message: "Failed to export orders",
							type: NotificationType.ERROR
						})
					}
				})
				.catch((e) => {
					this.exporting = false
					throw e
				})
		},
		footerClicked() {
			$crisp.push([
				"do",
				"helpdesk:article:open",
				["en", this.footerArticleId]
			])
		}
	}
}
</script>