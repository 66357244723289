<template>
	<div>
		<div class="sk-widget-alt sk-widget-line-items">
			<div class="sk-widget-line-item-header">
				<div>{{ summaryTitle }}</div>
				<span>{{ timezone | formatTimezone }}</span>
			</div>
			<div class="sk-widget-line-item">
				<div class="menu-schedule-summary-day-name">Sunday</div>
				<div v-if="scheduleSummary[6]">
					{{ scheduleSummary[6] | formatSchedule }}
				</div>
				<div v-else class="menu-schedule-summary-day-closed">
					Closed
				</div>
			</div>
			<div class="sk-widget-line-item">
				<div class="menu-schedule-summary-day-name">Monday</div>
				<div v-if="scheduleSummary[0]">
					{{ scheduleSummary[0] | formatSchedule }}
				</div>
				<div v-else class="menu-schedule-summary-day-closed">
					Closed
				</div>
			</div>
			<div class="sk-widget-line-item">
				<div class="menu-schedule-summary-day-name">Tuesday</div>
				<div v-if="scheduleSummary[1]">
					{{ scheduleSummary[1] | formatSchedule }}
				</div>
				<div v-else class="menu-schedule-summary-day-closed">
					Closed
				</div>
			</div>
			<div class="sk-widget-line-item">
				<div class="menu-schedule-summary-day-name">Wednesday</div>
				<div v-if="scheduleSummary[2]">
					{{ scheduleSummary[2] | formatSchedule }}
				</div>
				<div v-else class="menu-schedule-summary-day-closed">
					Closed
				</div>
			</div>
			<div class="sk-widget-line-item">
				<div class="menu-schedule-summary-day-name">Thursday</div>
				<div v-if="scheduleSummary[3]">
					{{ scheduleSummary[3] | formatSchedule }}
				</div>
				<div v-else class="menu-schedule-summary-day-closed">
					Closed
				</div>
			</div>
			<div class="sk-widget-line-item">
				<div class="menu-schedule-summary-day-name">Friday</div>
				<div v-if="scheduleSummary[4]">
					{{ scheduleSummary[4] | formatSchedule }}
				</div>
				<div v-else class="menu-schedule-summary-day-closed">
					Closed
				</div>
			</div>
			<div class="sk-widget-line-item">
				<div class="menu-schedule-summary-day-name">Saturday</div>
				<div v-if="scheduleSummary[5]">
					{{ scheduleSummary[5] | formatSchedule }}
				</div>
				<div v-else class="menu-schedule-summary-day-closed">
					Closed
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
</style>

<script>
import moment from "moment"

export default {
	name: "MenuScheduleSummary",
	filters: {
		formatSchedule: function (schedule) {
			if (!schedule) return ""
			return `${schedule.openTime} - ${schedule.closeTime}`
		},
		formatTimezone: function (timezone) {
			if (timezone) {
				return moment().tz(timezone).format("z")
			}
			return "N/A"
		}
	},
	props: {
		summaryTitle: {
			type: String,
			default: "Regular Hours"
		},
		scheduleSummary: Object,
		timezone: String
	},
	computed: {},
	methods: {}
}
</script>