export const LOADING = "LOADING"
export const LOADED = "LOADED"
export const SET_FILTERS = "SET_FILTERS"
export const GOT_TOTAL_SALES_DATA = "GOT_TOTAL_SALES_DATA"
export const GOT_TOTAL_ORDERS_DATA = "GOT_TOTAL_ORDERS_DATA"
export const GOT_AVERAGE_TICKET_PRICE_BY_DOW = "GOT_AVERAGE_TICKET_PRICE_BY_DOW"
export const GOT_TOP_ORDERS_BY_DOW = "GOT_TOP_ORDERS_BY_DOW"
export const GOT_TOP_ORDERS_BY_HOUR = "GOT_TOP_ORDERS_BY_HOUR"
export const GOT_AVERAGE_RATING = "GOT_AVERAGE_RATING"
export const GOT_AVERAGE_RESTAURANT_ACCURACY = "GOT_AVERAGE_RESTAURANT_ACCURACY"
export const GOT_TOP_EXCEPTIONS = "GOT_TOP_EXCEPTIONS"
export const GOT_MISSING_ITEMS_ORDERS = "GOT_MISSING_ITEMS_ORDERS"
export const GOT_INCORRECT_ITEMS_ORDERS = "GOT_INCORRECT_ITEMS_ORDERS"
export const GOT_RESTAURANT_CLOSED_ORDERS = "GOT_RESTAURANT_CLOSED_ORDERS"
export const GOT_RESTAURANT_AVERAGE_ACCEPT_TIME = "GOT_RESTAURANT_AVERAGE_ACCEPT_TIME"
export const GOT_RESTAURANT_AVERAGE_PREPARATION_TIME = "GOT_RESTAURANT_AVERAGE_PREPARATION_TIME"

export default {
    LOADING,
    LOADED,
    SET_FILTERS,
    GOT_TOTAL_SALES_DATA,
    GOT_TOTAL_ORDERS_DATA,
    GOT_AVERAGE_TICKET_PRICE_BY_DOW,
    GOT_TOP_ORDERS_BY_DOW,
    GOT_TOP_ORDERS_BY_HOUR,
    GOT_AVERAGE_RATING,
    GOT_AVERAGE_RESTAURANT_ACCURACY,
    GOT_TOP_EXCEPTIONS,
    GOT_MISSING_ITEMS_ORDERS,
    GOT_INCORRECT_ITEMS_ORDERS,
    GOT_RESTAURANT_CLOSED_ORDERS,
    GOT_RESTAURANT_AVERAGE_ACCEPT_TIME,
    GOT_RESTAURANT_AVERAGE_PREPARATION_TIME
}
