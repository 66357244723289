
<template>
	<transition-expand>
		<div class="sk-time-picker-popover" v-if="show">
			<div>
				<div
					class="sk-time-picker-element"
					:class="{
						selected: hour == selectedHour,
						disabled: disabledHourSlots.includes(hour)
					}"
					@mousedown="hourClicked(hour, $event)"
					v-for="hour in hours"
					:key="`hour-${hour}`"
				>
					{{ hour }}
				</div>
			</div>
			<div>
				<div
					class="sk-time-picker-element"
					:class="{
						selected: minute == selectedMinutes,
						disabled: disabledMinuteSlots.includes(minute)
					}"
					@mousedown="minutesClicked(minute, $event)"
					v-for="minute in minutes"
					:key="`time-${minute}`"
				>
					{{ minute | formatMinutes }}
				</div>
			</div>
			<div>
				<div
					class="sk-time-picker-element"
					:class="{
						selected: timePeriod == selectedTimePeriod,
						disabled: disabledTimePeriods.includes(timePeriod)
					}"
					@mousedown="timePeriodClicked(timePeriod, $event)"
					v-for="timePeriod in timePeriods"
					:key="`time-period-${timePeriod}`"
				>
					{{ timePeriod }}
				</div>
			</div>
		</div>
	</transition-expand>
</template>

<style scoped>
.sk-time-picker-popover {
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	box-shadow: 0px 0px 3px rgba(136, 136, 136, 0.25);
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	padding: 0 20px;
	z-index: 5;
	background: var(--sk-white);
	margin-top: -5px;
}

.sk-time-picker-popover > div {
	display: flex;
	flex-wrap: wrap;
	padding: 10px 0;
}

.sk-time-picker-popover > div:not(:last-child) {
	border-bottom: 1px solid var(--sk-grey);
}

.sk-time-picker-element {
	width: 18px;
	height: 18px;
	font-size: 14px;
	color: var(--sk-grey3);
	margin-right: 10px;
	cursor: pointer;
}

.sk-time-picker-element.selected {
	font-weight: 600;
	color: var(--sk-navy);
}

.sk-time-picker-element.disabled {
	color: var(--sk-grey);
	pointer-events: none;
}
</style>

<script>
import timePickerMixin from "@/mixins/time-picker-mixin"
import TransitionExpand from "@/components/transitions/TransitionExpand.vue"

export default {
	name: "SkTimePickerPopover",
	mixins: [timePickerMixin],
	components: {
		TransitionExpand
	},
	props: {
		show: Boolean
	},
	computed: {
		disabledSlots() {
			const slots = {
				AM: {},
				PM: {}
			}
			if (this.disabledHours && this.disabledHours.length > 0) {
				this.hours.forEach((hour) => {
					let amHour = hour < 10 ? `0${hour}` : hour
					let pmHour = hour + 12

					if (hour == 12) {
						amHour = "00"
						pmHour = "12"
					}

					this.minutes.forEach((minute) => {
						let paddedMinutes = minute == 0 ? "00" : minute
						let amTime = `${amHour}:${paddedMinutes}`
						let pmTime = `${pmHour}:${paddedMinutes}`

						if (this.disabledHours && this.disabledHours.includes(amTime)) {
							if (!slots["AM"][hour]) {
								slots["AM"][hour] = []
							}
							slots["AM"][hour].push(minute)
						}
						if (this.disabledHours && this.disabledHours.includes(pmTime)) {
							if (!slots["PM"][pmHour]) {
								slots["PM"][pmHour] = []
							}
							slots["PM"][pmHour].push(minute)
						}
					})
				})
			}
			return slots
		},
		disabledHourSlots() {
			let hourSlots = []
			const disabledHourSlots =
				this.disabledSlots[this.selectedTimePeriod]
			if (disabledHourSlots) {
				for (let hourSlot in disabledHourSlots) {
					if (
						disabledHourSlots[hourSlot].length ==
						this.minutes.length
					) {
						if (this.selectedTimePeriod == "PM" && hourSlot != 12) {
							hourSlot -= 12
						}
						hourSlots.push(parseFloat(hourSlot))
					}
				}
			}
			return hourSlots
		},
		disabledMinuteSlots() {
			const disabledHourSlots =
				this.disabledSlots[this.selectedTimePeriod]
			const selectedHour =
				this.selectedTimePeriod == "PM"
					? parseFloat(this.selectedHour) + 12
					: this.selectedHour
			if (disabledHourSlots && disabledHourSlots[selectedHour]) {
				return disabledHourSlots[selectedHour]
			}
			return []
		},
		disabledTimePeriods() {
			const intervals = this.hours.length * this.minutes.length
			const totalAMDisabledIntervals = Object.values(
				this.disabledSlots["AM"]
			).flat().length
			const totalPMDisabledIntervals = Object.values(
				this.disabledSlots["PM"]
			).flat().length
			const disabledTimePeriods = []
			if (totalAMDisabledIntervals == intervals) {
				disabledTimePeriods.push("AM")
			}
			if (totalPMDisabledIntervals == intervals) {
				disabledTimePeriods.push("PM")
			}

			if (this.selectedTimePeriod == "PM" && !disabledTimePeriods.includes("AM")) {
				const selectedHour = `${this.selectedHour}:${this.selectedMinutes}`
				if (this.disabledHours && this.disabledHours.includes(selectedHour)) {
					disabledTimePeriods.push("AM")
				}
			} else if (!disabledTimePeriods.includes("PM")) {
				const selectedHour = `${parseFloat(this.selectedHour) + 12}:${this.selectedMinutes}`
				if (this.disabledHours && this.disabledHours.includes(selectedHour)) {
					disabledTimePeriods.push("PM")
				}
			}

			return disabledTimePeriods
		}
	},
	methods: {
		hourClicked(hour, e) {
			e.preventDefault()
			this.selectHour(hour)
		},
		minutesClicked(minute, e) {
			e.preventDefault()
			this.selectMinutes(minute)
		},
		timePeriodClicked(timePeriod, e) {
			e.preventDefault()
			this.selectTimePeriod(timePeriod)
		}
	}
}
</script>