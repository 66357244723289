import Vue from "vue"

Vue.directive("sk-unassigned", function (el, binding) {
    if (binding.value == undefined || binding.value == null || binding.value === "") {
        const label = el.getAttribute("label")
        el.innerHTML = label ? label : "Unassigned"
        el.classList.add("sk-muted-text")
    } else {
        el.innerHTML = binding.value
        el.classList.remove("sk-muted-text")
    }
})