<template>
	<div class="sk-page-content">
		<PageHeader
			:title="headerTitle"
			:tabs="tabs"
			:breadcrumbs="breadcrumbs"
			@tabClicked="headerTabClicked"
		/>
		<transition name="slide" mode="out-in">
			<BusinessMenuItems
				v-if="activeTab.id == 0 && !loading"
				key="0"
				@save="saveBusinessMenu"
				ref="businessMenuItems"
			/>
			<BusinessMenuBasicInfo
				v-if="activeTab.id == 1 && !loading"
				key="1"
				:menu="menu"
				@save="saveBusinessMenu"
			/>
			<Schedule
				v-if="activeTab.id == 2 && !loading"
				key="2"
				ref="BusinessSchedules"
				:hours="menu.opening_hours"
				:availabilityHours="restaurantMenuHours"
				:availabilityScheduleName="'Restaurant Menu Hours'"
				:offset="offset"
				:timezone="timezone"
				@setHours="setHours"
				@save="saveBusinessMenu"
			/>
		</transition>
		<ConfirmationModal ref="confirmationModal" />
	</div>
</template>

<script>
import { ConfirmationModalType } from "@/enums/confirmationModalType"
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { MenuType } from "@arikgaisler/utils/enums/menuType"
import PageHeader from "@/components/PageHeader.vue"
import loaderMixin from "@/mixins/loader-mixin"
import BusinessMenuItems from "@/components/business-menu/BusinessMenuItems.vue"
import BusinessMenuBasicInfo from "@/components/business-menu/BusinessMenuBasicInfo.vue"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue"
import Schedule from "@/components/Schedule.vue"
import tracker from "@/utils/tracker"
import { PermissionTab } from "@/utils/permissions.js"

export default {
	name: "BusinessMenu",
	mixins: [loaderMixin],
	components: {
		PageHeader,
		BusinessMenuItems,
		BusinessMenuBasicInfo,
		ConfirmationModal,
		Schedule
	},
	data: function () {
		return {
			from: null,
			didMount: false,
			activeTabId: 0
		}
	},
	mounted: function () {
		this.toggleLoader(true)
		this.didMount = true
		this.$store.dispatch(
			"businessesModule/getCurrentBusinessRestaurantMenuProducts",
			this.$route.params.menu_id
		)
	},
	methods: {
		async headerTabClicked(tabClicked) {
			tracker.trackEvent("Business Menu Tab Clicked", {
				tab_name: tabClicked.text
			})
			if (
				this.activeTab.id == 0 &&
				this.$refs.businessMenuItems.hasChanges
			) {
				const confirmed = await this.$refs.confirmationModal.open({
					title: `You have unsaved changes`,
					body: `Are you sure you want to leave this page without saving your changes?`,
					confirmation: "Save Changes",
					cancel: "Continue Without Saving",
					type: ConfirmationModalType.WARNING
				})
				if (confirmed) {
					await this.saveBusinessMenu()
				}
			}
			this.tabs.forEach((tab) => {
				tab.active = tabClicked.id == tab.id
			})
			this.activeTabId = tabClicked.id
		},
		async saveBusinessMenu() {
			try {
				this.toggleLoader(true)
				const success = await this.$store.dispatch(
					"businessesModule/updateBusinessMenu",
					{	
						business: this.business,
						menu: this.menu
					}
				)
				if (this.activeTab.id == 0) {
					this.$refs.businessMenuItems.savedBusinessMenu()
				}
				this.toggleLoader(false)
				if (success) {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: `${this.menu.name} Updated`
					})
				}
			} catch (e) {
				console.error(e)
				this.toggleLoader(false)
			}
		},
		setHours(hours) {
			this.$store.dispatch("businessesModule/updateMenuHours", {
				menuId: this.menu.id,
				hours: hours
			})
		}
	},
	computed: {
		tabs() {
			var allowedTabs = [
				{
					id: 0,
					text: "Items",
					active: true
				}
			]
			if (
				this.$store.state.permissions.includes(
					PermissionTab.BUSINESS_MENU_DETAILS
				)
			) {
				allowedTabs.push({
					id: 1,
					text: "Details",
					active: false
				})
			}

			const scheduleTabAllowed =
				this.$store.state.permissions.includes(
					PermissionTab.BUSINESS_MENU_HOURS
				) &&
				this.menu &&
				this.menu.type != MenuType.POPUP

			if (scheduleTabAllowed) {
				allowedTabs.push({
					id: 2,
					text: "Schedules",
					active: false
				})
			}
			return allowedTabs
				.map((tab) => {
					tab.active = tab.id == this.activeTabId
					return tab
				})
				.sort((a, b) => a.id - b.id)
		},
		activeTab() {
			return this.tabs.find((tab) => tab.active)
		},
		loading() {
			return this.$store.state.restaurantsModule.loading || !this.didMount
		},
		business() {
			return this.$store.state.businessesModule.currentBusiness
		},
		restaurantMenu() {
			return this.restaurant.menus.find((e) => e.id == this.menu.id)
		},
		restaurantMenuHours() {
			return this.restaurantMenu ? this.restaurantMenu.opening_hours : null
		},
		restaurant() {
			return this.$store.state.businessesModule.currentBusinessRestaurants.find(
				(e) => e.id == this.menu.restaurant_id
			)
		},
		headerTitle() {
			if (
				this.menu &&
				this.menu.name != null &&
				this.menu.name != undefined
			)
				return this.menu.name
			else {
				return this.restaurantMenu ? this.restaurantMenu.name : "..."
			}
		},
		menu() {
			if (
				this.$route.params.menu_id != undefined &&
				this.business != undefined
			) {
				return this.business.menus.find(
					(menu) => menu.id == this.$route.params.menu_id
				)
			} else {
				return null
			}
		},
		offset() {
			return this.$store.getters["businessesModule/offset"]
		},
		timezone() {
			return this.$store.getters["businessesModule/timezone"]
		},
		breadcrumbs() {
			return [
				{
					name: "Businesses",
					link: "/businesses"
				},
				{
					name: this.business ? this.business.name : "Business",
					link: `/business/${this.$route.params.business_slug}`
				},
				{
					name: this.menu ? this.menu.name : "Menu"
				}
			]
		}
	},
	watch: {
		loading: function (newVal) {
			this.toggleLoader(newVal)
		}
	}
}
</script>