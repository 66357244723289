import actions from "./actions"
import mutations from "./mutations"

const state = {
  events: [],
  event: {},
  eventMenus: {},
  eventOrders: [], 
  eventReminders: [],
  restaurantMenus: {},
  filters: {
    startDate: null,
    endDate: null,
    businessId: 0,
    restaurantId: 0,
    status: -1,
    type: -1
  }
}

export default{
  namespaced: true, 
  state,
  mutations,
  actions
}