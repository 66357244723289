export const UPDATE_VOUCHER_REQUEST = "UPDATE_VOUCHER_REQUEST"
export const UPDATE_VOUCHER_REQUEST_STATUS = "UPDATE_VOUCHER_REQUEST_STATUS"
export const DELETE_VOUCHER_REQUEST = "DELETE_VOUCHER_REQUEST"
export const UPDATE_COUPON = "UPDATE_COUPON"
export const ADD_COUPON = "ADD_COUPON"
export const EXPORT_BATCH_UPLOAD_TEMPLATE = "EXPORT_BATCH_UPLOAD_TEMPLATE"
export const BATCH_UPLOAD_COUPONS = "BATCH_UPLOAD_COUPONS"
export const DELETE_COUPON = "DELETE_COUPON"
export const SEARCH_COUPONS = "SEARCH_COUPONS"
export const GET_VOUCHER_REQUESTS = "GET_VOUCHER_REQUESTS"
export const GET_VOUCHER_REQUEST = "GET_VOUCHER_REQUEST"
export const GET_VOUCHER_REQUEST_VOUCHERS = "GET_VOUCHER_REQUEST_VOUCHERS"
export const ADD_VOUCHER_REQUEST = "ADD_VOUCHER_REQUEST"
export const PRINT_VOUCHER = "PRINT_VOUCHER"
export const SEND_VOUCHER_EMAIL = "SEND_VOUCHER_EMAIL"
export const UPDATE_VOUCHER = "UPDATE_VOUCHER"

export default {
  UPDATE_COUPON,
  ADD_COUPON,
  EXPORT_BATCH_UPLOAD_TEMPLATE,
  BATCH_UPLOAD_COUPONS,
  DELETE_COUPON,
  SEARCH_COUPONS,
  GET_VOUCHER_REQUEST,
  GET_VOUCHER_REQUESTS,
  GET_VOUCHER_REQUEST_VOUCHERS,
  ADD_VOUCHER_REQUEST,
  UPDATE_VOUCHER_REQUEST,
  UPDATE_VOUCHER_REQUEST_STATUS,
  DELETE_VOUCHER_REQUEST,
  PRINT_VOUCHER,
  SEND_VOUCHER_EMAIL,
  UPDATE_VOUCHER
}