import restaurant_service from "@/services/restaurants"
import business_service from "@/services/businesses"
import api from "@/api"
import xlsx_helper from "@/utils/xlsx-helper"
import { UserRole } from "@/enums/userRole"
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import Vue from "vue"
import { PermissionInfo } from "@/utils/permissions"
import { SupplyRequestStatus } from "@/enums/supplyRequestStatus"
import { DeliveryMode } from "@arikgaisler/utils/enums/deliveryMode"
import utils from "@/utils/utils"

const vue = new Vue()

export default {
	async getBusiness({ commit, dispatch }, slug) {
		try {
			commit("loading")
			commit("clearBusiness")
			const responses = await Promise.all([
				business_service.getBusiness(slug),
				business_service.getDefaultMUR()
			])
			commit("gotBusiness", responses[0])
			commit("gotDefaultMURs", responses[1].data.types)
			await dispatch("getBusinessRestaurants")
			await dispatch("getBusinessMURs")
			commit("loaded")
			return true
		} catch (e) {
			vue.bugsnag.notify("Failed to get business", (event) => {
				event.addMetadata("error", {
					error: e
				})
			})
			return false
		}
	},
	async getDefaultMURs({ commit }) {
		const murResp = await business_service.getDefaultMUR()
		if (murResp.status == 200) {
			commit("gotDefaultMURs", murResp.data.types)
		}
	},
	async getBusinessMURs({ state, rootState, commit }) {
		try {
			if (
				state.currentBusiness &&
				rootState.permissions.includes(PermissionInfo.MARKUP_RULES)
			) {
				const murResp = await business_service.getBusinessMUR(
					state.currentBusiness.id
				)
				if (murResp.status == 200) {
					commit("gotCurrentBusinessMURs", murResp.data.markup_rules)
				} else {
					throw `API Error: ${murResp.status}`
				}
			}
			return true
		} catch (e) {
			vue.bugsnag.notify("Failed to get business MURs", (event) => {
				event.addMetadata("error", {
					error: e
				})
			})
			return false
		}
	},
	setBusinessMURValue({ commit }, payload) {
		commit("setBusinessMURValue", payload)
	},
	setBusinessMURType({ commit }, payload) {
		commit("setBusinessMURType", payload)
	},
	removeBusinessMUR({ commit }, type) {
		commit("removeBusinessMUR", type)
	},
	async getBusinessRestaurants({ state, commit }) {
		try {
			if (state.currentBusiness && state.currentBusiness.menus) {
				let restaurantIds = []
				state.currentBusiness.menus.forEach((menu) => {
					if (!restaurantIds.includes(menu.restaurant_id)) {
						restaurantIds.push(menu.restaurant_id)
					}
				})
				if (restaurantIds.length > 0) {
					const response =
						await restaurant_service.getRestaurantsByIds(
							restaurantIds,
							"opening_hours,integration,menus"
						)
					if (response.status == 200) {
						commit("gotBusinessRestaurants", response.restaurants)
					} else {
						throw `API Error: ${response.status}`
					}
				}
			}
		} catch (e) {
			vue.bugsnag.notify(
				"Failed to get business restaurants",
				(event) => {
					event.addMetadata("error", {
						error: e
					})
				}
			)
			return false
		}
	},
	async getCurrentBusinessRestaurantMenuProducts({ commit }, menu_id) {
		commit("loading")
		const menu_resp = await restaurant_service.getMenuProducts(menu_id)
		commit("gotCurrentBusinessRestaurantMenu", menu_resp.data.products)
		for (var i = 0; i < menu_resp.data.products.length; i++) {
			var idArr = []
			const product = menu_resp.data.products[i]
			var catIdStr = ""
			if (idArr.indexOf(product.id) == -1) {
				idArr.push(product.id)
				if (i != 0) {
					catIdStr = catIdStr + ","
					catIdStr = catIdStr + product.id.toString()
				}
			}
			const response = await restaurant_service.getCategories(catIdStr)
			if (response.status == 200 && response.data.categories) {
				commit("gotCategories", response.data.categories)
				commit("loaded")
				return true
			}
		}
		commit("loaded")
		return true
	},
	async addBusinessMenu({ commit, state }, obj) {
		try {
			const menu = obj.menu
			const rest = obj.rest
			if (
				!state.currentBusinessRestaurants.some((e) => e.id == rest.id)
			) {
				commit("gotBusinessRestaurant", rest)
			}
			const currentBusinessID = obj.businessID
				? obj.businessID
				: state.currentBusiness.id
			const menuResp = await business_service.addBusinessMenu(
				currentBusinessID,
				menu.id,
				menu
			)
			if (menuResp.status == 200) {
				commit("addBusinessMenu", menuResp.data.menu)
				return true
			}
			return false
		} catch (e) {
			vue.bugsnag.notify("Failed to add business menu", (event) => {
				event.addMetadata("error", {
					error: e
				})
			})
			return false
		}
	},
	async addDropoffLocation({ commit }, obj) {
		try {
			const dropoffLocationResp =
				await business_service.addDropoffLocation(obj)
			if (dropoffLocationResp.status == 200) {
				commit(
					"addDropoffLocation",
					dropoffLocationResp.data.dropoff_locations
				)
				return true
			}
			return false
		} catch (e) {
			vue.bugsnag.notify("Failed to add dropoff location", (event) => {
				event.addMetadata("error", {
					error: e
				})
			})
			return false
		}
	},
	async addBatchDropoffLocation({ commit }, obj) {
		try {
			if (!xlsx_helper.isValidXlSXFile(obj.file)) {
				throw "The file selected was not a CSV or XLSX (excel) file."
			}
			const dropoffLocationRows = await xlsx_helper.readFile(obj.file)
			const totaldropoffLocationRows = dropoffLocationRows.length
			const dropoffLocationJSON = dropoffLocationRows
				.filter((row) => row.Name && row.Type != undefined)
				.map((row) => {
					return {
						text: row.Name,
						delivery_mode: utils.businessModeValue(row.Type),
						description: row.Description ? row.Description : "",
						courier_dropoff_instructions:
							row["Courier Dropoff Instructions"],
						customer_pickup_instructions:
							row["Customer Pickup Instructions"]
					}
				})
			if (dropoffLocationJSON.length <= 0) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "No dropoff locations found in the file, please check the file and try again.",
					type: NotificationType.WARNING
				})
				return false
			}
			const dropoffLocationResp =
				await business_service.addDropoffLocation({
					dropoff_locations: dropoffLocationJSON,
					business_location_id: obj.locationId
				})
			if (dropoffLocationResp.status == 200) {
				commit(
					"addDropoffLocation",
					dropoffLocationResp.data.dropoff_locations
				)
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: `Succesfully uploaded ${dropoffLocationJSON.length}/${totaldropoffLocationRows} dropoff locations!`
				})
			}
		} catch (e) {
			vue.bugsnag.notify(
				"Unable to batch upload dropoff locations",
				(event) => {
					event.addMetadata("error", {
						error: e
					})
				}
			)
			eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
				title: "Unable to batch upload dropoff locations",
				type: NotificationType.ERROR
			})
			return false
		}
	},
	async updateDropoffLocation({ commit }, obj) {
		const dropoff_location_id = obj.dropoff_location_data.id
		try {
			const dropoffLocationResp =
				await business_service.updateDropoffLocation(
					dropoff_location_id,
					obj
				)
			if (dropoffLocationResp.status == 200) {
				commit(
					"updateDropoffLocation",
					dropoffLocationResp.data.dropoff_location
				)
				return true
			}
			return false
		} catch (e) {
			vue.bugsnag.notify("Failed to update dropoff location", (event) => {
				event.addMetadata("error", {
					error: e
				})
			})
			return false
		}
	},
	async updateBusinessMenu({ commit }, payload) {
		try {
			const menuResp = await business_service.updateBusinessMenu(
				payload.business.id,
				payload.menu.id,
				{ menu: payload.menu }
			)
			if (menuResp.status == 200) {
				commit("updatedBusinessMenu", menuResp.data.menu)
				return true
			}
			return false
		} catch (e) {
			vue.bugsnag.notify("Failed to update business menu", (event) => {
				event.addMetadata("error", {
					error: e
				})
			})
			return false
		}
	},
	setCurrentBusinessCover({ commit }, img) {
		commit("setCurrentBusinessCover", img)
	},
	setCurrentBusinessLogo({ commit }, img) {
		commit("setCurrentBusinessLogo", img)
	},
	async addBusiness({ state, commit }, business) {
		try {
			business.type = parseInt(business.type)
			business.admin_user_id = 7 //hard code for now
			const businessResp = await business_service.addBusiness({
				business: business
			})
			if (
				businessResp.data != undefined &&
				businessResp.data.business != undefined
			) {
				commit("addBusiness", businessResp)
				if (
					business.kiosks != undefined &&
					business.kiosks.length > 0
				) {
					await business_service.addKiosk(
						businessResp.data.business.id,
						business.kiosks[0]
					)
				}
				if (state.currentBusinessMURs.length > 0) {
					for (var i = 0; i < state.currentBusinessMURs.length; i++) {
						const mur = state.currentBusinessMURs[i]
						const payload = {
							type: mur.type,
							markup_type: mur.markup_type,
							markup_value: mur.markup_value
						}
						await business_service.addBusinessMUR(
							businessResp.data.business.id,
							payload
						)
					}
				}
				return businessResp.data.business
			}
			return false
		} catch (e) {
			vue.bugsnag.notify("Failed to add business", (event) => {
				event.addMetadata("error", {
					error: e,
					business: business
				})
			})
			return false
		}
	},
	async updateBusiness({ state }, business) {
		try {
			business.type = parseInt(business.type)
			var payload = { business: business }
			const busResp = await business_service.updateBusiness(
				business.id,
				payload
			)
			if (busResp.data.code == "ok") {
				if (state.currentBusinessMURs.length > 0) {
					for (var i = 0; i < state.currentBusinessMURs.length; i++) {
						const mur = state.currentBusinessMURs[i]
						if (mur.id > 0) {
							const payload = {
								markup_rule: {
									markup_type: mur.markup_type,
									markup_value: mur.markup_value
								}
							}

							await business_service.updateBusinessMUR(
								mur.id,
								payload
							)
						} else {
							const payload = {
								type: mur.type,
								markup_type: mur.markup_type,
								markup_value: mur.markup_value
							}

							await business_service.addBusinessMUR(
								business.id,
								payload
							)
						}
					}
				}
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: `${business.name} Updated`
				})
				return true
			}
			return false
		} catch (e) {
			vue.bugsnag.notify("Failed to update business", (event) => {
				event.addMetadata("error", {
					error: e,
					business: business
				})
			})
			return false
		}
	},
	async setBusinessActivationStatus(store, obj) {
		const is_active = obj.is_active ? 1 : 0
		var payload = { is_active: is_active }
		const business_id = obj.business_id
		await business_service.setActivatedBusiness(business_id, payload)
	},
	updateBusinessHours({ commit }, payload) {
		commit("updatedBusinessHours", payload)
	},
	updateMenuHours({ commit }, payload) {
		commit("updatedMenuHours", payload)
	},
	updateMenuItemPosition({ commit }, obj) {
		commit("updateMenuItemPosition", obj)
	},
	updateBusinessCategoryPosition({ commit }, obj) {
		commit("updateBusinessCategoryPosition", obj)
	},
	addBusinessMenuItem({ commit }, arr) {
		commit("addBusinessMenuItem", arr)
	},
	removeBusinessMenuItem({ commit }, arr) {
		commit("removeBusinessMenuItem", arr)
	},
	removeBusinessMenu({ commit }, id) {
		commit("removeBusinessMenu", id)
	},
	async deleteDropoffLocation({ commit }, dropoff_location_id) {
		const dropoffLocationResp =
			await business_service.deleteDropoffLocation(dropoff_location_id)
		if (dropoffLocationResp.status == 200) {
			commit("deletedDropoffLocation", dropoff_location_id)
			return true
		}
		return false
	},
	async deleteBusiness({ commit, rootGetters }, businessId) {
		const businessResp = await business_service.deleteBusiness(businessId)
		if (businessResp.data.code == "ok") {
			const business = rootGetters.businessesMap[businessId]
			if (business) {
				commit("removeBusiness", business.slug, { root: true })
			}
		}
	},
	async rebootKiosk(store, kiosk_id) {
		const kiosk_status = await business_service.rebootKiosk(kiosk_id)
		if (
			kiosk_status != null &&
			kiosk_status.data != null &&
			kiosk_status.data.status == "ok"
		) {
			eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
				title: "Kiosk succesfully rebooted!"
			})
		}
	},
	async launchBusiness({ commit }, arr) {
		const business_id = arr[0]
		const business_slug = arr[1]
		const business_status = await business_service.launchBusiness(
			business_id
		)
		if (
			business_status != null &&
			business_status.data != null &&
			business_status.data.code == "ok"
		) {
			commit("updateBusinessLaunchDate", business_slug)
			eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
				title: "Business Launched!"
			})
		}
	},
	async enableRewards({ commit }, business_id) {
		const businessResp = await business_service.enableRewards(business_id)
		if (businessResp.data.code == "ok") {
			commit("enabledRewards", business_id)
		}
	},
	async addBusinessKiosk({ commit }) {
		commit("addBusinessKiosk")
	},
	async updateBusinessKiosk(store, kiosk) {
		const payload = { kiosk: kiosk }
		const resp = await business_service.updateKiosk(kiosk.id, payload)
		if (resp.data.code == "ok") {
			if (kiosk.payment_mode == 1) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Kiosk Switched to App Mode Payments"
				})
			} else if (kiosk.payment_mode == 0) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Kiosk Switched to Square Payments"
				})
			}
		}
	},
	async removeBusinessKiosk({ commit }) {
		commit("removeBusinessKiosk")
	},
	async getSupplies({ commit }) {
		try {
			const suppliesResp = await business_service.getBusinessSupplies()
			if (suppliesResp.status == 200) {
				commit("gotSupplies", suppliesResp.data.supplies)
			}
			return suppliesResp.status == 200
		} catch (e) {
			vue.bugsnag.notify("Failed to get business supplies", (event) => {
				event.addMetadata("error", {
					error: e
				})
			})
			return false
		}
	},
	async getSupplyRequests({ commit }, status) {
		try {
			const payload = status != undefined ? { status: status } : null
			const resp = await business_service.getBusinessSupplyRequests(
				payload
			)
			if (resp.status == 200) {
				commit("gotSupplyRequests", resp.data.requests)
			}
			return resp.status == 200
		} catch (e) {
			vue.bugsnag.notify(
				"Failed to get business supply requests",
				(event) => {
					event.addMetadata("error", {
						error: e
					})
				}
			)
			return false
		}
	},
	async createSupplyRequests(store, payload) {
		try {
			const promises = payload.supplies.map((supplyItem) => {
				return business_service.createBusinessSuppliesRequest({
					business_id: payload.businessId,
					supply_id: supplyItem.id,
					quantity: supplyItem.quantity
				})
			})
			const responses = await Promise.all(promises)
			const success = responses.every(
				(response) => response.status == 200
			)
			if (success) {
				return true
			}
			return false
		} catch (e) {
			vue.bugsnag.notify(
				"Failed to create business supply requests",
				(event) => {
					event.addMetadata("error", {
						error: e,
						data: payload
					})
				}
			)
			return false
		}
	},
	async setSupplyRequestStatus({ commit }, payload) {
		try {
			const id = payload.supplyRequest.id
			let statusStr = "approve"
			if (payload.status == SupplyRequestStatus.REJECTED) {
				statusStr = "reject"
			} else if (payload.status == SupplyRequestStatus.CANCELLED) {
				statusStr = "cancel"
			}
			const resp = await business_service.setSupplyRequestStatus(
				id,
				statusStr
			)
			if (resp.status == 200) {
				if (payload.status == SupplyRequestStatus.REJECTED) {
					commit("removeSupplyRequest", id)
				} else {
					commit("updateSupplyRequest", {
						id: id,
						status: status
					})
				}
			}
			return resp.status == 200
		} catch (e) {
			vue.bugsnag.notify(
				"Failed to set supply request status",
				(event) => {
					event.addMetadata("error", {
						error: e,
						payload: payload
					})
				}
			)
			return false
		}
	},
	async clearBusiness({ commit }) {
		commit("clearBusiness")
	},
	async autocompleteBusinesses({ rootState }, query) {
		try {
			const response = await business_service.autocomplete(query)
			let results = []
			if (response.data.businesses) {
				if (rootState.user_role == UserRole.SUPER_ADMIN) {
					results = response.data.businesses
				} else {
					const entityIds = rootState.user_entities.map(
						(entity) => entity.id
					)
					results = response.data.businesses.filter(
						(result) => result.id && entityIds.includes(result.id)
					)
				}
			}
			return results
		} catch (e) {
			console.warn(e)
			return []
		}
	},
	async sendBusinessRestaurantRequest(store, arr) {
		const business_id = arr[0]
		const msg = arr[1]
		const payload = { notes: msg }
		const resp = await api.sendBusinessRestaurantRequest(
			business_id,
			payload
		)
		if (resp.data.code == "ok") {
			eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
				title: "Your request has been sent to your Account Manager!"
			})
		}
	},
	async getEmployees({ commit }) {
		commit("loading")
		const employeeResp = await business_service.getBusinessEmployees()
		await commit("gotBusinessEmployees", employeeResp.data.employees)
		commit("loaded")
	},
	async addBusinessEmployee({ commit }, payload) {
		try {
			const employeeResp = await business_service.addBusinessEmployee(
				payload.business_id,
				payload
			)
			if (employeeResp.status == 200) {
				await commit("gotBusinessEmployee", employeeResp.data.employee)
				return true
			}
			return false
		} catch (e) {
			vue.bugsnag.notify("Failed to add employee", (event) => {
				event.addMetadata("error", {
					error: e
				})
			})
			return false
		}
	},
	async updateBusinessEmployee({ commit }, employee) {
		try {
			var payload = { employee: employee }
			const employeeResp = await business_service.updateBusinessEmployee(
				employee.id,
				payload
			)
			if (employeeResp.status == 200) {
				await commit(
					"updatedBusinessEmployee",
					employeeResp.data.employee
				)
				return true
			}
			return false
		} catch (e) {
			vue.bugsnag.notify("Failed to update employee", (event) => {
				event.addMetadata("error", {
					error: e
				})
			})
			return false
		}
	},
	async updateBusinessEmployeeStatus({ commit }, statusObj) {
		try {
			const payload = {
				status: statusObj.status
			}
			const employeeResp =
				await business_service.updateBusinessEmployeeStatus(
					statusObj.id,
					payload
				)
			if (employeeResp.status == 200) {
				await commit("businessEmployeeStatusUpdated", statusObj)
				return true
			}
			return false
		} catch (e) {
			vue.bugsnag.notify("Failed to update employee status", (event) => {
				event.addMetadata("error", {
					error: e
				})
			})
			return false
		}
	},
	async removeBusinessEmployee({ commit }, employee_id) {
		try {
			const employeeResp = await business_service.removeBusinessEmployee(
				employee_id
			)
			if (employeeResp.status == 200) {
				await commit("removedBusinessEmployee", employee_id)
				return true
			}
			return false
		} catch (e) {
			vue.bugsnag.notify("Failed to remove employee", (event) => {
				event.addMetadata("error", {
					error: e
				})
			})
			return false
		}
	},
	async setW9Uploaded({ state, commit, rootGetters }, businessId) {
		try {
			const restResp = await business_service.updateBusiness(businessId, {
				business: {
					w9_uploaded: 1
				}
			})
			if (restResp.status == 200) {
				let business = rootGetters.businessesMap[businessId]
				if (
					!business &&
					state.currentBusiness &&
					businessId == state.currentBusiness.id
				) {
					business = state.currentBusiness
				}

				if (business) {
					commit("businessW9Updated", business.slug, { root: true })
				}
			}
			return restResp.status == 200
		} catch (e) {
			vue.bugsnag.notify("Failed to set w9 uploaded", (event) => {
				event.addMetadata("error", {
					error: e,
					businessId: businessId
				})
			})
			return false
		}
	},
	updateDeliverySlots({ commit }, payload) {
		commit("updateDeliverySlots", payload)
	},
	updateDefaultDeliveryMode({ state, commit }, payload) {
		let existingDeliveryModes = []
		let deliveryModes = []
		if (payload.menuId) {
			const menu =
				state.currentBusiness && state.currentBusiness.menus
					? state.currentBusiness.menus.find(
							(menu) => menu.id == payload.menuId
					  )
					: null
			deliveryModes =
				menu && menu.delivery_modes ? menu.delivery_modes.slice() : []
		} else {
			deliveryModes = state.currentBusiness.delivery_modes
				? state.currentBusiness.delivery_modes.slice()
				: []
		}
		const updatedDeliveryModes = deliveryModes.map((deliveryMode) => {
			existingDeliveryModes.push(deliveryMode.delivery_mode)
			return {
				deliveryMode: deliveryMode.delivery_mode,
				isDefault:
					payload.defaultDeliveryMode == deliveryMode.delivery_mode
			}
		})

		if (!existingDeliveryModes.includes(payload.defaultDeliveryMode)) {
			updatedDeliveryModes.push({
				deliveryMode: payload.defaultDeliveryMode,
				isDefault: true
			})
		}

		if (payload.menuId) {
			commit("updateMenuDeliveryModes", {
				menuId: payload.menuId,
				updatedDeliveryModes: updatedDeliveryModes
			})
		} else {
			commit("updateDeliveryModes", updatedDeliveryModes)
		}
	},
	updateDeliveryModes({ commit, state }, payload) {
		let updatedDeliveryModes = []
		let deliveryModes = []
		if (payload.menuId) {
			const menu =
				state.currentBusiness && state.currentBusiness.menus
					? state.currentBusiness.menus.find(
							(menu) => menu.id == payload.menuId
					  )
					: null
			deliveryModes =
				menu && menu.delivery_modes ? menu.delivery_modes.slice() : []
		} else {
			deliveryModes = state.currentBusiness.delivery_modes
				? state.currentBusiness.delivery_modes.slice()
				: []
		}
		let defaultDeliveryMode = null
		deliveryModes.find((deliveryMode) => {
			if (deliveryMode.is_default) {
				defaultDeliveryMode = deliveryMode.delivery_mode
				return true
			}
		})

		if (
			deliveryModes &&
			deliveryModes.length == 1 &&
			payload.updatedDeliveryMode.active == 1
		) {
			return
		}

		if (!deliveryModes || deliveryModes.length == 0) {
			updatedDeliveryModes = [
				{
					deliveryMode: payload.updatedDeliveryMode.id,
					isDefault: 1
				}
			]
			defaultDeliveryMode = payload.updatedDeliveryMode.id
		} else {
			if (
				payload.updatedDeliveryMode.active &&
				defaultDeliveryMode == payload.updatedDeliveryMode.id
			) {
				deliveryModes.find((deliveryMode) => {
					if (
						deliveryMode.delivery_mode !=
						payload.updatedDeliveryMode.id
					) {
						defaultDeliveryMode = deliveryMode.delivery_mode
						return true
					}
				})
			}

			const existingDeliveryModeIds = deliveryModes.map(
				(deliveryMode) => deliveryMode.delivery_mode
			)
			const availableDeliveryModes = [
				DeliveryMode.DIRECT_TO_CUSTOMER,
				DeliveryMode.DIRECT_TO_LOCATION,
				DeliveryMode.LAST_YARD,
				DeliveryMode.PICKUP_AREA,
				DeliveryMode.YAY_FOOD_POLE,
				DeliveryMode.LOCKER
			]
			updatedDeliveryModes = availableDeliveryModes.reduce(
				(modes, mode) => {
					if (
						payload.updatedDeliveryMode.active &&
						payload.updatedDeliveryMode.id == mode
					) {
						return modes
					}

					if (
						existingDeliveryModeIds.includes(mode) ||
						payload.updatedDeliveryMode.id == mode
					) {
						modes.push({
							deliveryMode: mode,
							isDefault: mode == defaultDeliveryMode
						})
					}

					return modes
				},
				[]
			)
		}

		if (payload.menuId) {
			commit("updateMenuDeliveryModes", {
				menuId: payload.menuId,
				updatedDeliveryModes: updatedDeliveryModes
			})
		} else {
			commit("updateDeliveryModes", updatedDeliveryModes)
		}
	}
}
