<template>
	<div class="vld-parent">
		<loading :active.sync="loading" :is-full-page="false"></loading>
		<InsightBaseWidget
			:title="'Average Preparation Time'"
			:footerIcon="'sk-icon-lightbulb-light'"
			:footerTitle="'Improving your accuracy can help to improve your customer ratings!'"
		>
			<div class="average-preparation-time-content" slot="content">
				<div class="average-preparation-time-statistic">
					{{ averagePreparationTimeText }}
				</div>
				<SkSliderScale
					:value="averagePreparationTimePercentage"
					:invert="true"
				/>
			</div>
		</InsightBaseWidget>
	</div>
</template>

<style scoped>
.average-preparation-time-statistic {
	font-size: 36px;
	color: var(--sk-dark-navy);
	margin-bottom: 22px;
	font-weight: 600;
}

.average-preparation-time-content {
	padding: 22px 0;
}
</style>

<script>
import pluralizeFilterMixin from "@/mixins/pluralize-filter-mixin"
import InsightBaseWidget from "@/components/insights/InsightBaseWidget.vue"
import Loading from "vue-loading-overlay"
import SkSliderScale from "@/components/SkSliderScale.vue"

export default {
	name: "RestaurantAveragePreparationTimeWidget",
	mixins: [pluralizeFilterMixin],
	components: {
		InsightBaseWidget,
		Loading,
		SkSliderScale
	},
	computed: {
		loading() {
			return this.$store.state.insightsModule.averagePreparationTime
				.loading
		},
		averagePreparationTimeText() {
			if (!this.loading) {
				let time = parseFloat(this.averagePreparationMinutes)
				let suffix = this.$options.filters.pluralize(time, "Minute")
				return `${this.averagePreparationMinutes} ${suffix}`
			}
			return "--"
		},
		averagePreparationTimePercentage() {
			if (this.averagePreparationMinutes) {
				if (this.averagePreparationMinutes >= 30) {
					return 100
				}
				if (this.averagePreparationMinutes < 1) {
					return 0
				}
				return (this.averagePreparationMinutes / 30) * 100
			}
			return 0
		},
		averagePreparationMinutes() {
			const data = this.$store.state.insightsModule.averagePreparationTime
				.data
			if (data) {
				let minutes = data / 60
				return minutes.toFixed(2).replace(/[.,]00$/, "")
			}
			return 0
		}
	}
}
</script>