import { VoucherPaymentType } from "@/enums/voucherPaymentType.js"
import { VoucherDistributionType } from "@/enums/voucherDistributionType.js"

export default class VoucherRequest {
    constructor() {
        this.id = 0
        this.paymentType = VoucherPaymentType.PRE_PAID
        this.distributionType = VoucherDistributionType.PRINT_NOW
        this.name = ""
        this.description = ""
        this.startDate = null
        this.endDate = null
        this.amount = null
        this.quantity = null
        this.businessId = null
        this.requestedAt = null
        this.guests = []
        this.menuId = null
    }

    static convertToPayload(voucherRequestData) {
        return {
            id: voucherRequestData.id,
            paymentType: voucherRequestData.paymentType,
            distributionType: voucherRequestData.distributionType,
            name: voucherRequestData.name,
            description: voucherRequestData.description,
            startDate: voucherRequestData.startDate,
            endDate: voucherRequestData.endDate,
            amount: voucherRequestData.amount,
            quantity: voucherRequestData.quantity,
            businessId: voucherRequestData.businessId,
            requestedAt: voucherRequestData.requestedAt,
            guests: voucherRequestData.guests,
            menu_id: voucherRequestData.menuId
        }
    }
}