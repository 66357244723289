import MutationTypes from "@/store/modules/coupons/mutation-types"
import { VoucherDistributionType } from "@/enums/voucherDistributionType"

export default {
	[MutationTypes.GOT_COUPONS](state, payload) {
		state.coupons = payload.data.coupons
		state.totalCouponsCount = payload.data.coupon_count
	},
	[MutationTypes.GOT_VOUCHERS](state, payload) {
		state.vouchers = payload.data.coupons
		state.totalVouchersCount = payload.data.coupon_count
	},
	[MutationTypes.UPDATED_COUPON](state, payload) {
		const index = state.coupons.findIndex(
			(coupon) => coupon.id == payload.id
		)
		if (index >= 0) {
			state.coupons.splice(index, 1, payload)
		}
	},
	[MutationTypes.ADDED_COUPON](state, payload) {
		state.coupons.unshift(payload)
	},
	[MutationTypes.ADDED_COUPONS](state, coupons) {
		if (coupons) {
			state.coupons = coupons.concat(state.coupons)
		}
	},
	[MutationTypes.REMOVE_COUPON](state, coupon_id) {
		state.coupons = state.coupons.filter(function (val) {
			return val.id != coupon_id
		})
	},
	[MutationTypes.GOT_VOUCHER_REQUESTS](state, payload) {
		state.voucherRequests = payload.data.voucher_requests
		state.totalVoucherRequestsCount = payload.data.voucher_request_count
	},
	[MutationTypes.GOT_VOUCHER_REQUEST](state, payload) {
		if (payload.data && payload.data.voucher_request) {
			state.voucherRequest = payload.data.voucher_request
		} else {
			state.voucherRequest = {}
		}
		state.voucherRequestVouchers = []
	},
	[MutationTypes.GOT_VOUCHER_REQUEST_VOUCHERS](state, vouchers) {
		state.voucherRequestVouchers = vouchers ? vouchers : []
	},
	[MutationTypes.ADDED_VOUCHER_REQUEST](state, payload) {
		state.voucherRequests.unshift(payload)
	},
	[MutationTypes.UPDATED_VOUCHER_REQUEST](state, payload) {
		const index = state.voucherRequests.findIndex(
			(voucherRequest) => voucherRequest.id == payload.id
		)
		if (index >= 0) {
			state.voucherRequests.splice(index, 1, payload)
		}
	},
	[MutationTypes.DELETED_VOUCHER_REQUEST](state, voucherRequestId) {
		state.voucherRequests = state.voucherRequests.filter(
			(voucherRequest) => voucherRequest.id != voucherRequestId
		)
	},
	[MutationTypes.VOUCHER_EMAIL_SENT](state, id) {
		const voucher = state.coupons.find((coupon) => coupon.id == id)
		if (voucher && voucher != null) {
			voucher.distribution_type = VoucherDistributionType.SEND_TO_GUEST
		}
	},
	[MutationTypes.UPDATE_VOUCHER](state, payload) {
		const index = state.vouchers.findIndex(
			(voucher) => voucher.code == payload.code
		)
	},
	[MutationTypes.LOADING](state) {
		state.loading = true
	},
	[MutationTypes.LOADED](state) {
		state.loading = false
	},
	[MutationTypes.UPDATING](state) {
		state.updating = true
	},
	[MutationTypes.UPDATED](state) {
		state.updating = false
	}
}