<template>
	<div class="sk-row sk-widget-alt active-order" @click="openOrderDetails">
		<div class="pill-alt pill-alt-grey">{{ orderTime }}</div>
		<div>
			<div class="order-title">
				<div class="order-name">{{ order.user_name }}</div>
				<div
					v-if="isInRoomBilling && paymentStatus != null"
					:class="inRoomBillingStatusBadgeClasses"
				>
					<i :class="inRoomBillingStatusIcon"></i>
					<b>Charge to Room:</b>
					<i>{{ inRoomBillingStatusText }}</i>
				</div>
			</div>
			<div class="order-info">
				<span>#{{ order.id }}</span>
				<span v-if="hasTable">{{ tableNumber }}</span>
				<span v-if="showBusinessName">{{ businessName }}</span>
			</div>
		</div>
		<div class="order-status">
			<div class="order-status-title">
				<b>{{ orderStatusTitle }}</b>
				{{ orderStatusTime }}
			</div>
			<div class="order-status-progress">
				<div
					:class="{
						complete: !isCancelledOrder,
						danger: isCancelledOrder
					}"
				></div>
				<div
					:class="{
						complete: accepted && !isCancelledOrder
					}"
				></div>
				<div
					:class="{
						complete: pickedUp && !isCancelledOrder
					}"
				></div>
				<div
					:class="{
						complete: delivered && !isCancelledOrder
					}"
				></div>
			</div>
		</div>
		<SkDropdownSelect
			v-if="orderActions"
			:options="orderActions"
			:iconClasses="'sk-icon-ellipsis-v-regular'"
			:showSelectedOption="false"
			:position="'left'"
			@selected="orderActionSelected"
		/>
	</div>
</template>

<style scoped>
.active-order {
	align-items: center;
	overflow: visible;
	cursor: pointer;
}

.sk-select {
	flex-grow: 0;
	width: 25px;
	margin-left: 25px;
}

.sk-select >>> .option {
	color: var(--sk-grey2);
}

.active-order >>> .sk-select > .selected i {
	margin-left: 0;
	padding-left: 15px;
	height: 40px;
	align-items: center;
	display: flex;
}

.order-status {
	margin-left: auto;
}

.order-status-title b {
	margin-right: 5px;
}

.pill-alt {
	font-weight: 400;
	margin-right: 30px;
}

.order-name {
	font-size: 14px;
	font-weight: 600;
	color: var(--sk-dark-navy);
}

.order-name + .pill-alt {
	margin-left: 15px;
}

.order-title {
	margin-bottom: 5px;
	display: flex;
	align-items: center;
}

.order-title .pill-alt {
	margin-left: 15px;
	margin-right: 15px;
}

.order-title .pill-alt b {
	margin-right: 2px;
}

.order-title .pill-alt i {
	margin-right: 10px;
}

.order-info > span {
	font-size: 12px;
	color: var(--sk-grey3);
}

.order-info > span + span {
	margin-left: 15px;
}
</style>

<script>
import moment from "moment"
import "moment-timezone"
import orderMixin from "@/mixins/order-mixin"
import activeOrderMixin from "@/mixins/active-order-mixin"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import { PermissionMethod } from "@/utils/permissions"

export default {
	name: "ActiveOrder",
	mixins: [orderMixin, activeOrderMixin],
	components: {
		SkDropdownSelect
	},
	props: {
		order: Object
	},
	computed: {
		businessId() {
			return this.order ? this.order.business_id : null
		},
		orderActions() {
			const actions = [
				{
					id: 0,
					text: `<i class="sk-icon-question-solid"></i> Report Issue`
				}
			]

			if (
				this.$store.state.permissions.includes(
					PermissionMethod.NOTIFY_GUEST
				)
			) {
				const notifyGuestText = this.delivered
					? "Re-Notify Guest"
					: "Notify Guest"

				actions.push({
					id: 1,
					text: `<i class="sk-icon-bell-on-solid"></i> ${notifyGuestText}`
				})
			}

			if (this.isInRoomBilling) {
				if (this.canDisputeCharge) {
					actions.push({
						id: 2,
						text: "Dispute Charge"
					})
				}
				if (this.canMarkPosted) {
					actions.push({
						id: 3,
						text: "Mark Posted",
						highlight: true
					})
				}
			}

			return actions.length > 0 ? actions : null
		},
		suborder() {
			if (!this.order) {
				return
			}
			return this.order.sub_orders.length > 0
				? this.order.sub_orders[0]
				: null
		},
		orderTime() {
			return moment
				.tz(this.order.created_time, this.timezone)
				.format("h:mm a")
		},
		orderStatusTitle() {
			if (this.isCancelledOrder) {
				return "Cancelled"
			} else if (this.delivered) {
				return "Delivered"
			} else if (this.pickedUp) {
				return "Picked up"
			} else if (this.accepted) {
				return "Accepted"
			} else {
				return "Placed"
			}
		},
		orderStatusTime() {
			let time = ""
			if (this.isCancelledOrder || !this.suborder) {
				return ""
			} else if (this.delivered) {
				time = this.deliveredTime
			} else if (this.pickedUp) {
				time = this.pickedUpTime
			} else if (this.accepted) {
				time = this.suborder.accept_time
			} else {
				time = this.order.created_time
			}
			const formattedTime = moment
				.tz(time, this.timezone)
				.format("h:mm a")
			return `${formattedTime} ${this.formattedTimezone}`
		},
		orderTransaction() {
			return this.order.transaction
		}
	},
	methods: {
		orderActionSelected(action) {
			switch (action.id) {
				case 0: {
					this.$emit("reportIssue", this.order)
					break
				}
				case 1: {
					this.notifyGuest()
					break
				}
				case 2: {
					this.$emit("disputeCharge", this.order)
					break
				}
				case 3: {
					this.loading = true
					this.updateOrderTransactionStatus(
						1,
						"Successfully marked the order as posted",
						"Failed to mark the order as posted, please try again"
					)
						.then(() => {
							this.loading = false
						})
						.catch(() => {
							this.loading = false
						})
					break
				}
			}
		},
		openOrderDetails() {
			this.$emit("openDetails", this.order)
		},
		notifyGuest() {
			this.$store
				.dispatch("ordersModule/notifyGuestOrderDelivered", {
					id: this.suborder.id,
					orderId: this.order.id
				})
				.then((success) => {
					if (success) {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Guest notified of order delivery"
						})
					} else {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Failed to notify guest, please try again",
							type: NotificationType.ERROR
						})
					}
				})
				.catch(() => {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Failed to notify guest, please try again",
						type: NotificationType.ERROR
					})
				})
		}
	}
}
</script>