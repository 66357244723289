
<template>
	<div class="sk-widget-alt sk-widget-schedule">
		<div class="sk-widget-line-item-header-mutliline">
			<div class="sk-widget-line-item-header-title">Set Open Hours</div>
			<div class="sk-widget-line-item-header-subtitle">
				Set Open Hours Select all days that share the same hours
			</div>
			<button class="button button-icon" @click="remove" v-if="!readonly">
				<i class="sk-icon-times-regular"></i>
			</button>
		</div>
		<div class="days-of-the-week">
			<div
				v-for="day in daysOfTheWeek"
				class="day-of-the-week"
				:class="{
					selected: day.selected,
					unavailable: day.unavailable
				}"
				:key="`${day.dow}-${day.selected ? 1 : 0}-${
					day.unavailable ? 1 : 0
				}`"
				@click="selectDay(day)"
			>
				{{ day.name }}
			</div>
		</div>
		<div class="sk-row">
			<input
				class="sk-input"
				placeholder="Open"
				readonly
				:value="openTime"
				:disabled="readonly"
				@focus="openInput(true)"
				@blur="openInput(false)"
			/>
			<input
				class="sk-input"
				placeholder="Close"
				readonly
				:value="closeTime"
				:disabled="readonly"
				@focus="closeInput(true)"
				@blur="closeInput(false)"
			/>
		</div>
		<SkTimePickerPopover
			:show="showTimePicker"
			v-model="selectedTime"
			:disabledHours="unavailableHours"
		/>
	</div>
</template>

<style scoped>
.sk-widget-schedule {
	padding: 0;
	margin-bottom: 20px;
	position: relative;
	overflow: visible;
	width: 430px;
}

.sk-widget-line-item-header-mutliline {
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

.sk-widget-schedule > div:nth-child(2),
.sk-widget-schedule > div:nth-child(3) {
	padding: 20px 20px 0 20px;
}

.sk-widget-schedule > div:nth-child(3) {
	padding-bottom: 20px;
}

.days-of-the-week {
	display: flex;
	justify-content: space-between;
}

.day-of-the-week {
	font-size: 12px;
	background: var(--sk-white);
	border: 1px solid var(--sk-navy);
	color: var(--sk-navy);
	border-radius: 5px;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.day-of-the-week.selected {
	background: var(--sk-navy);
	border-color: var(--sk-navy);
	color: var(--sk-white);
}

.day-of-the-week.unavailable {
	background: var(--sk-grey);
	color: var(--sk-grey2);
	border-color: var(--sk-grey);
	cursor: default;
}

.button-icon {
	position: absolute;
	top: 0;
	right: 0;
}
</style>

<script>
import moment from "moment"
import SkTimePickerPopover from "@/components/SkTimePickerPopover.vue"
import momentHelper from "@/utils/moment-helper"

export default {
	name: "MenuSchedule",
	filters: {
		formatTime: function (value, offset) {
			if (!value) return ""
			return moment
				.utc(value, "HH:mm:ss")
				.add(offset, "m")
				.format("h:mm A")
		}
	},
	components: {
		SkTimePickerPopover
	},
	props: {
		schedule: Object,
		availabilitySchedule: Object,
		offset: Number,
		readonly: {
			type: Boolean,
			default: false
		}
	},
	data: function () {
		return {
			editingOpenTime: false,
			editingCloseTime: false,
			editedOpenTime: null,
			editedCloseTime: null
		}
	},
	computed: {
		showTimePicker() {
			return this.editingOpenTime || this.editingCloseTime
		},
		openTime() {
			if (this.editedOpenTime) {
				return this.$options.filters.formatTime(this.editedOpenTime)
			}
			return this.$options.filters.formatTime(
				this.schedule.openTime,
				this.offset
			)
		},
		closeTime() {
			if (this.editedCloseTime) {
				return this.$options.filters.formatTime(this.editedCloseTime)
			}
			return this.$options.filters.formatTime(
				this.schedule.closeTime,
				this.offset
			)
		},
		selectedTime: {
			get: function () {
				if (this.editingOpenTime) {
					return this.$options.filters.formatTime(
						this.schedule.openTime,
						this.offset
					)
				} else if (this.editingCloseTime) {
					return this.$options.filters.formatTime(
						this.schedule.closeTime,
						this.offset
					)
				} else {
					return ""
				}
			},
			set: function (val) {
				const time = moment(val, "h:mm A").format("HH:mm:ss")
				this.timePickerUpdate(time)
			}
		},
		localAvailabilitySchedule() {
			if (this.availabilitySchedule) {
				return Object.entries(this.availabilitySchedule).reduce(
					(schedules, entry) => {
						schedules[entry[0]] = {
							openTime: moment
								.utc(entry[1].openTime, "HH:mm:ss")
								.add(this.offset, "m")
								.format("HH:mm:ss"),
							closeTime: moment
								.utc(entry[1].closeTime, "HH:mm:ss")
								.add(this.offset, "m")
								.format("HH:mm:ss"),
							dow: entry[1].dow
						}
						return schedules
					},
					{}
				)
			}
			return null
		},
		localSchedule() {
			if (!this.schedule) {
				return null
			}

			return {
				openTime: this.schedule.openTime
					? moment
							.utc(this.schedule.openTime, "HH:mm:ss")
							.add(this.offset, "m")
							.format("HH:mm:ss")
					: null,
				closeTime: this.schedule.closeTime
					? moment
							.utc(this.schedule.closeTime, "HH:mm:ss")
							.add(this.offset, "m")
							.format("HH:mm:ss")
					: null,
				days: this.schedule.days,
				reservedDays: this.schedule.reservedDays
			}
		},
		unavailableDays() {
			let reservedDays = []
			const dows = [0, 1, 2, 3, 4, 5, 6]

			if (this.localAvailabilitySchedule) {
				reservedDays = dows.filter(
					(dow) => !this.localAvailabilitySchedule[dow]
				)
			}
			if (this.schedule) {
				this.schedule.reservedDays.forEach((day) => {
					if (!this.schedule.days.includes(day)) {
						reservedDays.push(day)
					}
				})

				const availableDays = dows.filter(
					(dow) => !reservedDays.includes(dow)
				)

				if (this.localAvailabilitySchedule) {
					availableDays.forEach((day) => {
						const dowAvailability =
							this.localAvailabilitySchedule[day]
						if (!dowAvailability) {
							reservedDays.push(day)
							return
						}
					})
				}

				if (
					this.localAvailabilitySchedule &&
					(this.localSchedule.openTime ||
						this.localSchedule.closeTime)
				) {
					const scheduleTime = {
						openTime: this.localSchedule.openTime
							? this.localSchedule.openTime
							: this.localSchedule.closeTime,
						closeTime: this.localSchedule.closeTime
							? this.localSchedule.closeTime
							: this.localSchedule.openTime
					}
					if (availableDays && availableDays.length > 0) {
						if (scheduleTime.openTime)
							availableDays.forEach((day) => {
								const dowAvailability =
									this.localAvailabilitySchedule[day]
								const withinAvailability =
									momentHelper.isWithinBounds(
										dowAvailability,
										scheduleTime
									)
								if (
									!withinAvailability.openTimeInBounds ||
									!withinAvailability.closeTimeInBounds
								) {
									reservedDays.push(day)
								}
							})
					} else {
						const dowAvailabilities = Object.values(
							this.localAvailabilitySchedule
						)
						if (!dowAvailabilities) {
							reservedDays = reservedDays.concat(dows)
							return
						}
						dowAvailabilities.forEach((dowAvailability) => {
							const withinAvailability =
								momentHelper.isWithinBounds(
									dowAvailability,
									scheduleTime
								)
							if (
								!withinAvailability.openTimeInBounds ||
								!withinAvailability.closeTimeInBounds
							) {
								reservedDays.push(dowAvailability.dow)
							}
						})
					}
				} else if (
					this.localAvailabilitySchedule &&
					this.schedule.days &&
					this.schedule.days.length > 0
				) {
					this.schedule.days.forEach((day) => {
						for (let availabilityScheduleDay in this
							.localAvailabilitySchedule) {
							availabilityScheduleDay = parseFloat(
								availabilityScheduleDay
							)
							if (
								this.schedule.days.includes(
									availabilityScheduleDay
								) ||
								reservedDays.includes(availabilityScheduleDay)
							) {
								continue
							}
							const dowAvailability =
								this.localAvailabilitySchedule[
									availabilityScheduleDay
								]
							const scheduleDay =
								this.localAvailabilitySchedule[day]
							const withinAvailability =
								momentHelper.isWithinBounds(
									scheduleDay,
									dowAvailability
								)
							if (
								!withinAvailability.openTimeInBounds ||
								!withinAvailability.closeTimeInBounds
							) {
								reservedDays.push(availabilityScheduleDay)
							}
						}
					})
				}
			}

			return reservedDays
		},
		unavailableHours() {
			let unavailableHours = []
			if (
				this.schedule &&
				this.schedule.days.length > 0 &&
				this.schedule.days.some((day) =>
					this.unavailableDays.includes(day)
				)
			) {
				unavailableHours = momentHelper.generateSlots(
					"00:00",
					"24:00",
					15
				)
			} else if (this.localAvailabilitySchedule) {
				const availabilitySchedules = Object.values(
					this.localAvailabilitySchedule
				).filter((schedule) =>
					this.schedule.days.includes(schedule.dow)
				)
				if (availabilitySchedules.length > 0) {
					let commonRange = null
					availabilitySchedules
						.sort((scheduleA, scheduleB) => {
							const openTimeANum = momentHelper.numericalTime(
								scheduleA.openTime
							)
							const openTimeBNum = momentHelper.numericalTime(
								scheduleB.openTime
							)
							return openTimeANum - openTimeBNum
						})
						.forEach((schedule) => {
							if (!commonRange) {
								commonRange = schedule
							} else {
								commonRange =
									momentHelper.intersectWithinRanges(
										commonRange,
										schedule
									)
							}
						})
					if (commonRange) {
						let openTime = moment
							.utc(commonRange.openTime, "HH:mm:ss")

						if (commonRange.openTime != "00:00:00") {
							openTime.subtract(15, "m")
						}
						openTime = openTime.format("HH:mm:ss")

						let closeTime = moment
							.utc(commonRange.closeTime, "HH:mm:ss")
						if (commonRange.closeTime != "23:45:00") {
							closeTime.add(15, "m")
						}
						closeTime = closeTime.format("HH:mm:ss")

						if (
							momentHelper.numericalTime(closeTime) <
							momentHelper.numericalTime(openTime)
						) {
							unavailableHours = unavailableHours.concat(
								momentHelper.generateSlots(
									closeTime,
									openTime,
									15
								)
							)
						} else {
							unavailableHours = unavailableHours.concat(
								momentHelper.generateSlots(
									"00:00:00",
									openTime,
									15
								)
							)
							unavailableHours = unavailableHours.concat(
								momentHelper.generateSlots(
									closeTime,
									"24:00:00",
									15
								)
							)
						}
					}
				}
			}
			return unavailableHours
		},
		daysOfTheWeek() {
			return [
				{
					name: "Sun",
					dow: 6,
					selected: this.schedule.days.includes(6),
					unavailable: this.unavailableDays.includes(6)
				},
				{
					name: "Mon",
					dow: 0,
					selected: this.schedule.days.includes(0),
					unavailable: this.unavailableDays.includes(0)
				},
				{
					name: "Tue",
					dow: 1,
					selected: this.schedule.days.includes(1),
					unavailable: this.unavailableDays.includes(1)
				},
				{
					name: "Wed",
					dow: 2,
					selected: this.schedule.days.includes(2),
					unavailable: this.unavailableDays.includes(2)
				},
				{
					name: "Thu",
					dow: 3,
					selected: this.schedule.days.includes(3),
					unavailable: this.unavailableDays.includes(3)
				},
				{
					name: "Fri",
					dow: 4,
					selected: this.schedule.days.includes(4),
					unavailable: this.unavailableDays.includes(4)
				},
				{
					name: "Sat",
					dow: 5,
					selected: this.schedule.days.includes(5),
					unavailable: this.unavailableDays.includes(5)
				}
			]
		}
	},
	methods: {
		openInput(focused) {
			this.editingOpenTime = focused
			if (!focused && this.editedOpenTime) {
				this.updateTime({ openTime: this.editedOpenTime })
				this.editedOpenTime = null
			}
		},
		closeInput(focused) {
			this.editingCloseTime = focused
			if (!focused && this.editedCloseTime) {
				this.updateTime({ closeTime: this.editedCloseTime })
				this.editedCloseTime = null
			}
		},
		selectDay(day) {
			if (day.unavailable || this.readonly) {
				return
			}
			if (day.selected) {
				this.$emit("dayUnselected", {
					dow: day.dow,
					key: this.schedule.key
				})
			} else {
				this.$emit("daySelected", {
					dow: day.dow,
					key: this.schedule.key
				})
			}
		},
		timePickerUpdate(time) {
			if (this.editingOpenTime) {
				this.editedOpenTime = time
			} else if (this.editingCloseTime) {
				this.editedCloseTime = time
			}
		},
		updateTime(payload) {
			const scheduleTime = {
				openTime: this.schedule.openTime,
				closeTime: this.schedule.closeTime
			}
			if (payload.openTime) {
				scheduleTime.openTime = moment
					.utc(payload.openTime, "HH:mm:ss")
					.add(-1 * this.offset, "m")
					.format("HH:mm:ss")
			}

			if (payload.closeTime) {
				scheduleTime.closeTime = moment
					.utc(payload.closeTime, "HH:mm:ss")
					.add(-1 * this.offset, "m")
					.format("HH:mm:ss")
			}
			this.$emit("updateTime", {
				time: scheduleTime,
				key: this.schedule.key
			})
		},
		remove() {
			this.$emit("removeHours", this.schedule)
		}
	}
}
</script>
