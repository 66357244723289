<template>
	<SkBaseModal class="sk-modal-alt" :isOpen="isOpen" @close="cancel">
		<button class="button button-icon button-close" @click="cancel(false)">
			<i class="sk-icon-times-regular"></i>
		</button>
		<div class="sk-modal-alt-title">
			<h1>Confirm Menu Changes?</h1>
		</div>
		<div class="sk-modal-description">
			Where would you like to apply these changes to?
		</div>
		<div>
			<div
				class="sk-switch-label-wrapper sk-modal-select-all"
				@click="selectAll"
			>
				<SkCheckbox :checked="allSelected" />
				<span class="sk-modal-select-all-label">Select All</span>
			</div>
			<div
				v-for="menu in businessMenus"
				:key="`${menu.businessId}-${menu.menu.id}`"
				class="sk-switch-label-wrapper"
			>
				<SkCheckbox v-model="menu.sync" />
				<span>{{ menu.businessName }} - {{ menu.menuName }}</span>
			</div>
		</div>
		<div class="sk-modal-actions">
			<button class="button button-text" @click="cancel(false)">
				Don't Apply
			</button>
			<button class="button button-primary" @click="applyChanges">
				<i class="sk-icon-check-regular"></i>
				Apply Changes
			</button>
		</div>
	</SkBaseModal>
</template>

<script>
import skPromiseModalMixin from "@/mixins/modals/sk-promise-modal-mixin"
import SkCheckbox from "@/components/SkCheckbox.vue"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from '@/enums/notificationType'

export default {
	name: "SyncBusinessMenusHoursModal",
	mixins: [skPromiseModalMixin],
	components: {
		SkCheckbox,
		SkBaseModal
	},
	data: function () {
		return {
			businessMenus: []
		}
	},
	computed: {
		allSelected() {
			return (
				!this.loading && this.businessMenus.every((menu) => menu.sync)
			)
		}
	},
	methods: {
		selectAll() {
			if (this.allSelected) {
				this.businessMenus.forEach((menu) => {
					this.$set(menu, "sync", false)
				})
			} else {
				this.businessMenus.forEach((menu) => {
					this.$set(menu, "sync", true)
				})
			}
		},
		handleOptions(options) {
			this.businessMenus = options.businesses
			this.hours = options.hours
		},
		applyChanges() {
			let confirmedBusinessMenus = this.businessMenus
				.filter((menu) => menu.sync)
				.reduce((businesses, business) => {
					if (!businesses[business.businessId]) {
						businesses[business.businessId] = []
					}
					businesses[business.businessId].push(business.menu.id)
					return businesses
				}, {})
			this.confirm(confirmedBusinessMenus)
			this.syncBusinessMenusHours(confirmedBusinessMenus, this.hours)
			this.businessMenus = []
			this.hours = null
		},
		syncBusinessMenusHours(businesses, hours) {
			Object.keys(businesses).forEach((confirmedBusiness) => {
				let business =
					this.$store.state.restaurantsModule.restaurantBusinesses.find(
						(business) => business.id == confirmedBusiness
					)
				let menus = businesses[confirmedBusiness]
				business.menus
					.filter((menu) => menus.includes(menu.id))
					.forEach((menu) => {
						menu.opening_hours = hours
						this.$store
							.dispatch("businessesModule/updateBusinessMenu", {
								business: business,
								menu: menu
							})
							.then((success) => {
								if (!success) {
									throw "Failed to update business menu"
								}
							})
							.catch(() => {
								eventBus.emit(
									EventBusEvents.SHOW_NOTIFICATION,
									{
										title: `Failed to update ${business.name} menu`,
										type: NotificationType.ERROR
									}
								)
							})
					})
			})
		}
	}
}
</script>