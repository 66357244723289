<template>
	<div class="admin-users">
		<slot v-if="pagedAdminUsers.length > 0">
			<div class="sk-table sk-table-default">
				<div class="sk-table-header">
					<div class="sk-table-row">
						<div>Name</div>
						<div>Email</div>
						<div>Role</div>
						<div>Business</div>
						<div>Status</div>
						<div>Actions</div>
					</div>
				</div>
				<transition-group
					name="list"
					tag="div"
					class="sk-table-body"
					appear
				>
					<ChefUserLineItem
						v-for="adminUser in adminUsers"
						:key="adminUser.id"
						:user="adminUser"
						@editChefUserClicked="openChefUserModalClicked"
						@deleteAdminUserClicked="deleteAdminUser"
						@resendActivationLinkClicked="resendActivationLink"
					/>
				</transition-group>
			</div>
			<PaginationFooter
				:contentText="'Users'"
				:pageSize="20"
				:total="adminUsers.length"
				@pageChanged="filterPage"
			/>
		</slot>
		<EmptyPlaceholderWidget v-else :title="`No Users Found`" />
		<ConfirmationModal ref="adminUserConfirmationModal" />
	</div>
</template>

<style scoped>
.admin-users {
	height: 100%;
	display: flex;
	flex-direction: column;
}
</style>

<script>
import { UserRole } from "@/enums/userRole"
import adminUserTableMixin from "@/mixins/users/admin-user-table-mixin"
import ChefUserLineItem from "@/components/users/ChefUserLineItem.vue"
import PaginationFooter from "@/components/PaginationFooter.vue"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue"
import EmptyPlaceholderWidget from "@/components/EmptyPlaceholderWidget.vue"

export default {
	name: "ChefUsers",
	mixins: [adminUserTableMixin],
	components: {
		ChefUserLineItem,
		PaginationFooter,
		ConfirmationModal,
		EmptyPlaceholderWidget
	},
	computed: {
		adminUsers() {
			let users = this.$store.state.admin_users
				.filter(
					(user) =>
						user.role_id != UserRole.SUPER_ADMIN &&
						user.role_id != UserRole.ADMIN
				)
				.sort((a, b) => b.id - a.id)
			if (this.filter.businessId > 0) {
				users = users.filter(
					(user) =>
						user.role_id == UserRole.BUSINESS_ADMIN &&
						user.entities.findIndex(
							(entity) => entity.id == this.filter.businessId
						) >= 0
				)
			}
			if (this.filter.name != "") {
				users = users.filter(
					(user) =>
						user.display_name.toLowerCase() ==
						this.filter.name.toLowerCase()
				)
			}
			if (this.filter.email != "") {
				users = users.filter(
					(user) =>
						user.email.toLowerCase() ==
						this.filter.email.toLowerCase()
				)
			}
			if (this.filter.isActive != -1) {
				users = users.filter(
					(user) => user.is_active == this.filter.isActive
				)
			}
			return users
		}
	},
	methods: {
		openChefUserModalClicked(user) {
			this.$emit("editChefUserClicked", user)
		}
	}
}
</script>