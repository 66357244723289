import Vue from 'vue'
import { store } from "@/store/store"
import { parseResponse } from '@arikgaisler/utils/api-utils';

let host = process.env.VUE_APP_API_HOST

const PAYMENTS_API = `https://payment${host}.2ndkitchen.com`

export default {
    async getRestaurantFundingSources(restaurant_id) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.get(`${PAYMENTS_API}/funding_sources?entity_types=2&entity_ids=${restaurant_id}`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getRestaurantsActiveFundingSources(restaurant_ids) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.get(`${PAYMENTS_API}/funding_sources?entity_types=2&is_active=1&entity_ids=${restaurant_ids}`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getBusinessFundingSources(business_id) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.get(`${PAYMENTS_API}/funding_sources?entity_types=1&entity_ids=${business_id}`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getBusinessesActiveFundingSources(business_ids) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.get(`${PAYMENTS_API}/funding_sources?entity_types=1&is_active=1&entity_ids=${business_ids}`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async addFundingSource(payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.post(`${PAYMENTS_API}/funding_sources/ach`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async updateFundingSource(funding_source_id, payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.put(`${PAYMENTS_API}/funding_sources/${funding_source_id}`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async deleteFundingSource(funding_source_id){
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.delete(`${PAYMENTS_API}/funding_sources/${funding_source_id}`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getFundingSource(funding_source_id) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.get(`${PAYMENTS_API}/funding_sources/${funding_source_id}`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async payInvoice(invoice_id, payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.post(`${PAYMENTS_API}/businesses_billing_collections/${invoice_id}/pay`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async issuePayouts() {
        const tokenStr = 'Bearer ' + store.state.user_token
        return Vue.axios.post(`${PAYMENTS_API}/restaurants_payouts/issue_payment`,null, { 'headers': { 'Authorization': tokenStr } });
    },
    async verifyFundingSource(funding_source_id,payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return Vue.axios.post(`${PAYMENTS_API}/funding_sources/${funding_source_id}/verify`,payload, { 'headers': { 'Authorization': tokenStr } });
    },
    async updateRefund(refund_id,payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.put(`${PAYMENTS_API}/refunds/${refund_id}`,payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async setRefundStatus(refund_id, status) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.put(`${PAYMENTS_API}/refunds/${refund_id}/${status}`, null, { 'headers': { 'Authorization': tokenStr } }))
    },
    async batchSetRefundsStatus(payload, status) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.post(`${PAYMENTS_API}/refunds/batch-${status}`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async addRefund(order_id, payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return Vue.axios.post(`${PAYMENTS_API}/orders/${order_id}/refunds`, payload, { 'headers': { 'Authorization': tokenStr } })
    },
    async getOrdersTransactions(order_ids) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.get(`${PAYMENTS_API}/order_transactions?order_ids=${order_ids}`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async updateOrderTransactionStatus(order_id, payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.put(`${PAYMENTS_API}/orders/${order_id}/transactions`, payload, { 'headers': { 'Authorization': tokenStr } }))
    }
}