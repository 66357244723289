<template>
	<div class="sk-table-row">
		<div>
			<div class="sk-table-pill sk-table-column-fit">
				<router-link
					:key="restaurant.id"
					:to="`/restaurant/${restaurant.id}/menu/${menu.id}`"
					tag="a"
					class="pill pill-grey"
					:title="restaurant.name"
				>
					<i class="sk-icon-utensils-solid"></i>
					{{ restaurant.name }}
				</router-link>
			</div>
		</div>
		<div>{{ menu.name }}</div>
		<div
			class="menu-schedules"
			@click="togglePopover('isHoursPopoverOpen')"
		>
			<div class="menu-today" v-if="todaysSchedule">
				<div>
					<div class="sk-muted-text">
						{{ todaysSchedule.daysText }}
					</div>
					<div>{{ todaysSchedule.timesText }}</div>
				</div>
				<i
					class="expand-schedule-icon sk-icon-chevron-down-regular"
					:class="{ flipped: isHoursPopoverOpen }"
				></i>
			</div>
			<SkPopover v-model="isHoursPopoverOpen">
				<div
					v-for="(schedule, i) in menuSchedule"
					:key="i"
					class="menu-schedule"
				>
					<div class="sk-muted-text">
						{{ schedule.daysText }}
					</div>
					<div>
						{{ schedule.timesText }}
					</div>
				</div>
			</SkPopover>
		</div>
		<div @click="togglePopover('isBusinessesPopoverOpen')">
			{{ businessCount }}
			<i
				v-if="businessCount > 0"
				class="sk-icon-chevron-down-regular"
			></i>
			<SkPopover
				v-if="businessCount > 0"
				v-model="isBusinessesPopoverOpen"
			>
				<div v-for="business in businesses" :key="business.id">
					{{ business.name }}
				</div>
			</SkPopover>
		</div>
		<div>
			<div :title="statusLabel" :class="statusBadgeClass">
				<span :class="statusClasses"></span>
				{{ statusLabel }}
			</div>
		</div>
		<div class="sk-table-column-fit">
			<div class="sk-table-actions">
				<router-link
					:key="menu.id"
					:to="`/restaurant/${restaurant.id}/menu/${menu.id}`"
					tag="button"
					class="button button-icon"
					title="Edit"
				>
					<i class="sk-icon-pencil-solid"></i>
				</router-link>
			</div>
		</div>
	</div>
</template>

<style scoped>
.sk-table-row .sk-icon-chevron-down-regular {
	font-size: 10px;
	margin-left: 10px;
}

.sk-table-pill > .pill.pill-grey {
	max-width: 400px;
}

.menu-today {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.menu-schedule > div {
	color: var(--sk-grey3);
}

.menu-schedule div:first-child {
	margin-bottom: 5px;
}

.menu-schedule:not(:first-child) div:first-child {
	margin-top: 5px;
}
</style>

<script>
import momentHelper from "@/utils/moment-helper"
import moment from "moment"
import "moment-timezone"
import SkPopover from "@/components/SkPopover.vue"

export default {
	name: "HubRestaurantMenuLineItem",
	components: {
		SkPopover
	},
	props: {
		menu: Object
	},
	data: function () {
		return {
			isHoursPopoverOpen: false,
			isBusinessesPopoverOpen: false
		}
	},
	computed: {
		hasActiveBusiness() {
			return this.businesses.some((business) => business.is_active)
		},
		statusBadgeClass() {
			return this.hasActiveBusiness
				? "pill-alt pill-alt-green"
				: "pill-alt pill-alt-grey"
		},
		statusLabel() {
			return this.hasActiveBusiness ? "Active" : "Inactive"
		},
		statusClasses() {
			return this.hasActiveBusiness
				? "status-dot green"
				: "status-dot grey"
		},
		restaurant() {
			return this.menu.restaurant
		},
		menuSchedule() {
			return momentHelper.hoursToSchedule(
				this.menu.opening_hours,
				this.offset
			)
		},
		todaysSchedule() {
			const date = moment()
			let dow = date.day()
			const normalizedDow = dow == 0 ? 6 : dow - 1
			const todaysSchedule = this.menuSchedule.find((schedule) =>
				schedule.dows.includes(Number(normalizedDow))
			)
			if (todaysSchedule) {
				return {
					daysText: momentHelper.dowNames[normalizedDow],
					timesText: todaysSchedule.timesText
				}
			}
			return null
		},
		businesses() {
			return this.menu && this.menu.businesses ? this.menu.businesses : []
		},
		businessCount() {
			return this.businesses.length
		},
		offset() {
			return this.restaurant && this.restaurant.timezone_offset
				? this.restaurant.timezone_offset
				: 0
		}
	},
	methods: {
		togglePopover(name) {
			this.isHoursPopoverOpen =
				name == "isHoursPopoverOpen" ? !this.isHoursPopoverOpen : false
			this.isBusinessesPopoverOpen =
				name == "isBusinessesPopoverOpen"
					? !this.isBusinessesPopoverOpen
					: false
		}
	}
}
</script>