<template>
	<SkBaseModal
		:isOpen="isOpen"
		:hasLoader="true"
		@close="close"
		class="sk-modal-alt"
	>
		<loading :active.sync="loading" :is-full-page="false" />
		<div class="sk-modal-alt-title">
			<h1>
				Approve Refund:
				<span class="sk-modal-alt-subtitle">#{{ orderId }}</span>
			</h1>
		</div>
		<div class="sk-modal-description">
			Are you sure you want to approve a refund of
			<b>{{ refundAmount | currency }}</b>
			for
			<b>{{ orderId }}</b>
		</div>
		<RefundAttachedException
			v-for="exception in exceptions"
			:class="{
				editing: editingException && exception.id == editingException.id
			}"
			:key="exception.id"
			:exception="exception"
			:order="order"
			:expanded="expandedExceptions[exception.id]"
			@expand="toggleExceptionExpand(exception)"
			@reject="rejectException"
			@edit="editException"
		/>
		<div class="sk-row">
			<button
				class="button button-text new-exception-button"
				@click="expandExceptionForm"
			>
				<i class="sk-icon-paperclip-regular"></i>
				Attach New Exception
			</button>
		</div>
		<transition-expand>
			<ExceptionForm
				v-if="exceptionFormExpanded"
				ref="exceptionForm"
				class="exception-form"
				:options="{ order: order, exception: editingException }"
				:type="1"
			/>
		</transition-expand>
		<div class="sk-modal-actions">
			<slot v-if="exceptionFormExpanded">
				<button
					@click="collapseExceptionForm"
					class="button button-text"
				>
					Cancel
				</button>
				<button
					type="button"
					class="button button-primary"
					@click="submitExceptionForm"
				>
					{{ attachCopy }}
				</button>
			</slot>
			<slot v-else>
				<button @click="close" class="button button-text">Close</button>
				<button
					type="button"
					class="button button-primary"
					@click="approveRefund"
				>
					{{ approveCopy }}
				</button>
			</slot>
		</div>
	</SkBaseModal>
</template>

<style scoped>
.sk-modal-description {
	color: var(--sk-grey3);
}

.new-exception-button {
	min-height: 17px;
	font-size: 12px;
	font-weight: 600;
	color: var(--sk-dark-navy) !important;
	text-align: left;
	align-items: center;
	display: flex;
	margin-top: 10px;
	margin-bottom: 10px;
}

.sk-modal-body-bottom-radius {
	z-index: -1;
}

.editing >>> .sk-widget-alt {
	border: 1px solid var(--sk-dark-navy);
}
</style>

<script>
import utils from "@/utils/utils"
import search_service from "@/services/search"
import orders_service from "@/services/orders"
import currencyFilterMixin from "@/mixins/currency-filter-mixin"
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import ExceptionForm from "@/components/forms/ExceptionForm.vue"
import Loading from "vue-loading-overlay"
import TransitionExpand from "@/components/transitions/TransitionExpand.vue"
import RefundAttachedException from "@/components/modals/approveRefund/RefundAttachedException.vue"
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"

export default {
	name: "ApproveRefundModal",
	mixins: [skModalMixin, currencyFilterMixin],
	components: {
		SkBaseModal,
		ExceptionForm,
		Loading,
		TransitionExpand,
		RefundAttachedException
	},
	props: {
		type: {
			type: Number,
			default: 0
		}
	},
	data() {
		return {
			loading: false,
			refund: null,
			exceptions: [],
			order: null,
			editingException: null,
			exceptionFormExpanded: false,
			expandedExceptions: {},
			exceptionActions: [
				{
					id: 0,
					text: "Remove"
				},
				{
					id: 1,
					text: "Edit"
				}
			]
		}
	},
	computed: {
		orderId() {
			return this.refund ? this.refund.order.id : ""
		},
		refundAmount() {
			return this.refund ? this.refund.amount : ""
		},
		approveCopy() {
			if (this.exceptions.length > 1) {
				return "Approve Refund & Exceptions"
			} else if (this.exceptions.length > 0) {
				return "Approve Refund & Exception"
			}
			return "Approve Refund"
		},
		attachCopy() {
			return this.editingException ? "Save Exception" : "Attach Exception"
		}
	},
	methods: {
		async handleOptions(options) {
			try {
				this.refund = utils.clonedeep(options.refund)
				this.loading = true
				await this.fetchExceptions()
				this.loading = false
			} catch (e) {
				this.loading = false
				this.close()
				throw e
			}
		},
		closed() {
			if (this.$refs.exceptionForm) {
				this.$refs.exceptionForm.resetForm()
			}
			this.refund = null
			this.order = null
			this.exceptions = []
			this.exceptionFormExpanded = false
			this.expandedExceptions = {}
		},
		async fetchOrder() {
			if (this.order) {
				return true
			}
			const response = await search_service.searchOrders({
				filters: {
					ids: [this.refund.order.id]
				}
			})

			const orders = response.data.orders
			if (this.isOpen && orders && orders.length > 0) {
				this.order = orders[0]
				return true
			}
			return false
		},
		async fetchExceptions() {
			const response = await orders_service.getOrdersExceptions(
				this.refund.order.id
			)
			if (this.isOpen && response.data.exceptions) {
				this.exceptions = response.data.exceptions.filter((exception) => exception.status != 2)
			}
		},
		async submitExceptionForm() {
			try {
				this.loading = true
				const success = await this.$refs.exceptionForm.submit()
				if (success) {
					await this.fetchExceptions()
					this.exceptionFormExpanded = false
				}
				this.loading = false
				return true
			} catch (e) {
				this.loading = false
				throw e
			}
		},
		async approveRefund() {
			try {
				this.loading = true
				const promises = []

				promises.push(
					this.$store.dispatch("setRefundStatus", {
						id: this.refund.id,
						status: 1
					})
				)

				const unapprovedExceptions = this.exceptions.filter(
					(exception) => exception.status == 0
				)

				if (unapprovedExceptions.length > 0) {
					unapprovedExceptions.forEach((exception) => {
						promises.push(
							this.$store
								.dispatch("setExceptionStatus", [
									exception.id,
									1
								])
								.catch(() => {})
						)
					})
				}

				const responses = await Promise.all(promises)
				const success = responses.every((response) => response == true)
				const refundApproved = responses[0] == true

				if (success) {
					let message =
						this.exceptions.length > 0
							? "Succesfully approved refund and exception(s)"
							: "Succesfully approved refund"
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Refunds",
						message: message,
						type: NotificationType.SUCCESS
					})
					this.close()
				} else if (refundApproved) {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Refunds",
						message: "Refund was approved succesfully",
						type: NotificationType.SUCCESS
					})

					responses.forEach((response, i) => {
						if (i == 0) {
							return
						}
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Refunds",
							message: "Failed to approve exception",
							type: NotificationType.ERROR
						})
					})
					this.close()
				} else {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Refunds",
						message: "Something went wrong, please try again.",
						type: NotificationType.ERROR
					})
				}
				this.loading = false
			} catch (e) {
				this.loading = false
				throw e
			}
		},
		toggleExceptionExpand(exception) {
			this.loading = true
			this.fetchOrder()
				.then(() => {
					const expanded = this.expandedExceptions[exception.id]
					let expand = expanded ? false : true
					this.$set(this.expandedExceptions, exception.id, expand)
				})
				.finally(() => {
					this.loading = false
				})
		},
		expandExceptionForm() {
			this.loading = true
			this.editingException = null
			this.fetchOrder()
				.then(() => {
					this.exceptionFormExpanded = true
				})
				.finally(() => {
					this.loading = false
				})
		},
		collapseExceptionForm() {
			this.exceptionFormExpanded = false
			this.editingException = null
		},
		editException(exception) {
			this.expandExceptionForm()
			this.editingException = exception
		},
		async rejectException(exception) {
			try {
				this.loading = true
				const success = await this.$store.dispatch(
					"setExceptionStatus",
					[exception.id, 2]
				)

				if (success) {
					this.fetchExceptions()
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Refunds",
						message: "Exception was succesfully rejected",
						type: NotificationType.SUCCESS
					})
					await this.fetchExceptions()
				} else {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Refunds",
						message: "Failed to reject exception, please try again",
						type: NotificationType.ERROR
					})
				}
				this.loading = false
			} catch (e) {
				this.loading = false
				throw e
			}
		}
	}
}
</script>