<template>
	<div class="sk-widget-alt">
		<div class="sk-insight-widget-header">
			<div v-if="!hasTitleSlot" class="sk-insight-widget-title">
				{{ title }}
			</div>
			<slot v-else name="title"></slot>
			<SkDropdownSelect
				v-if="hasMultipleActions"
				:options="actions"
				:iconClasses="'sk-icon-ellipsis-v-regular'"
				:showSelectedOption="false"
				:position="'left'"
				@selected="actionSelected"
			/>
			<button
				v-else-if="hasActions"
				class="button button-icon"
				:title="actionText"
				@click="actionSelected(action)"
			>
				<span v-if="actionIcon" v-html="actionIcon"></span>
				<span v-else>{{ actionText }}</span>
			</button>
		</div>
		<slot v-if="hasTabsSlot" name="tabs"></slot>
		<div v-if="!hasContentSlot" class="sk-insight-widget-content">
			{{ contentText }}
		</div>
		<slot v-else name="content"></slot>
		<div
			v-if="hasFooter"
			class="sk-insight-widget-footer"
			@click="footerClicked"
		>
			<i :class="footerIcon"></i>
			<span>{{ footerTitle }}</span>
			<span class="footer-link" v-if="footerLinkText">{{ footerLinkText }}</span>
		</div>
	</div>
</template>

<style scoped>
.sk-widget-alt {
	display: flex;
	flex-direction: column;
}

.sk-insight-widget-header {
	margin: -15px -20px 0 -20px;
	padding: 20px 18px 0 18px;
	display: flex;
	justify-content: space-between;
	height: 60px;
	border-bottom: 1px solid var(--sk-grey);
	background: var(--sk-white);
}

.sk-insight-widget-header .button-icon {
	padding: 0;
	align-self: flex-start;
}

.sk-insight-widget-header .sk-select {
	flex: 0;
	height: 18px;
}

.sk-insight-widget-title {
	font-weight: 600;
	font-size: 14px;
	color: var(--sk-dark-navy);
}

.sk-insight-widget-content {
	flex-grow: 1;
	font-size: 36px;
	color: var(--sk-dark-navy);
	display: flex;
	align-items: center;
}

.sk-insight-widget-footer {
	display: flex;
	align-items: center;
	margin: auto -20px -15px -20px;
	padding: 0 18px;
	height: 50px;
	background: var(--sk-greybg2);
	border-top: 1px solid var(--sk-grey);
	color: var(--sk-grey2);
	font-size: 12px;
	cursor: pointer;
}

.sk-insight-widget-footer i {
	margin-right: 13px;
	font-size: 20px;
}

.footer-link {
	color: var(--sk-grey3);
	margin-left: auto;
}
</style>

<script>
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"

export default {
	name: "InsightBaseWidget",
	components: {
		SkDropdownSelect
	},
	props: {
		title: String,
		contentText: [String, Number],
		actions: Array,
		footerIcon: String,
		footerTitle: String,
		footerLinkText: String
	},
	computed: {
		hasTitleSlot() {
			return !!this.$slots.title
		},
		hasTabsSlot() {
			return !!this.$slots.tabs
		},
		hasContentSlot() {
			return !!this.$slots.content
		},
		hasActions() {
			return this.actions && this.actions.length > 0
		},
		hasMultipleActions() {
			return this.hasActions && this.actions.length > 1
		},
		action() {
			return this.hasActions ? this.actions[0] : null
		},
		actionText() {
			if (!this.hasActions) {
				return ""
			}
			return this.actionIcon
				? this.action.text.replace(this.actionIcon, "")
				: this.action.text
		},
		actionIcon() {
			const text = this.action ? this.action.text : null

			if (!text) {
				return null
			}

			const iconFound = text.match(/<\s*i[^>]*>(.*?)<\s*\/\s*i>/)
			if (iconFound && iconFound.length > 0) {
				return iconFound[0]
			}

			return null
		},
		hasFooter() {
			return false
		}
	},
	methods: {
		actionSelected(action) {
			this.$emit("actionSelected", action)
		},
		footerClicked() {
			this.$emit("footerClicked")
		}
	}
}
</script>