<template>
	<div
		class="sk-select"
		tabindex="0"
		:class="selectInputClasses"
		@blur="onBlur"
		@click="toggleDropdown"
	>
		<slot v-if="hasCustomTrigger && model"></slot>
		<div
			class="selected"
			v-else-if="model"
			:class="{ placeholder: model.id == null }"
		>
			<span v-html="model.text" v-if="showSelectedOption"></span>
			<i
				:class="[
					iconClasses,
					iconClasses == 'sk-icon-chevron-down-regular' && open
						? 'flipped'
						: '',
					!showSelectedOption ? 'dropdown-icon' : ''
				]"
			></i>
		</div>
		<div
			class="options dark-scrollbar"
			ref="optionsList"
			:class="{ 'select-hide': !open }"
			:style="positionStyle"
		>
			<div
				class="option"
				v-for="option of validOptions"
				:key="option.id"
				:class="{
					selected: !showSelectedOption || option.id == model.id,
					highlight: option.highlight
				}"
				@mousedown="selectOption(option, $event)"
			>
				<div class="option-text" v-html="option.text"></div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.sk-select {
	position: relative;
	width: 100%;
	text-align: left;
	outline: none;
	height: 100%;
	display: flex;
	cursor: pointer;
}

.has-error {
	border: 1px solid var(--sk-red);
}

.has-error .selected.placeholder {
	color: var(--sk-red);
}

.selected i {
	margin-left: auto;
	text-align: center;
	transition: 0.35s cubic-bezier(0.58, -0.46, 1, 1.19);
}

.selected .dropdown-icon {
	margin-left: 15px;
}

.selected span {
	margin-right: 15px;
}

.selected {
	align-items: center;
	display: flex;
	font-size: 14px;
	width: 100%;
}

.selected.placeholder {
	color: var(--sk-grey2);
}

.options {
	padding: 20px;
	color: var(--sk-white);
	border-radius: 5px;
	overflow: hidden;
	position: absolute;
	background: var(--sk-white);
	box-shadow: 0px 1px 4px rgba(136, 136, 136, 0.25);
	z-index: 800;
	max-height: 300px;
	overflow-y: auto;
}

.option {
	color: var(--sk-grey2);
	cursor: pointer;
	user-select: none;
	font-size: 14px;
}

.option.selected {
	color: var(--sk-grey3);
}

.option + .option {
	margin-top: 30px;
}

.option-text {
	display: flex;
	align-items: center;
	white-space: nowrap;
	flex-grow: 1;
}

.option:hover {
	color: var(--sk-grey3);
}

.select-hide {
	display: none;
}

.options >>> i {
	margin-right: 20px;
}

.option:not(.highlight) + .highlight {
	border-top: 1px solid var(--sk-grey);
	margin-top: 20px;
  margin-bottom: -20px;
}

.highlight + .highlight {
	margin-top: 20px;
}

.highlight:last-child {
	border-bottom: 1px solid var(--sk-grey);
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

.highlight {
	border-left: 1px solid var(--sk-grey);
	border-right: 1px solid var(--sk-grey);
	background: var(--sk-greybg);
	margin: -20px 0 -20px -20px;
	width: calc(100% + 40px);
	padding: 15px 20px;
}
</style>

<script>
import utils from "@/utils/utils"

export default {
	name: "SkDropdownSelect",
	props: {
		value: {
			type: Object
		},
		position: {
			type: String,
			default: "bottom"
		},
		showSelectedOption: {
			type: Boolean,
			default: true
		},
		options: {
			type: Array,
			required: true
		},
		classes: {
			type: String,
			default: ""
		},
		iconClasses: {
			type: String,
			default: "sk-icon-chevron-down-regular"
		},
		required: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			open: false,
			defaultModel: {},
			validateEnabled: false,
			invalid: false
		}
	},
	computed: {
		model: {
			get() {
				return this.value != undefined ? this.value : this.defaultModel
			},
			set(val) {
				if (
					this.value == undefined &&
					val != null &&
					val != undefined
				) {
					this.defaultModel = val
				}
				this.$emit("input", val)
			}
		},
		validOptions() {
			return this.options.filter((option) => option.id != null).sort((a, b) => {
				if (a.highlight === b.highlight) {
					return 0
				} else if (a.highlight) {
					return 1
				}
				return -1
			})
		},
		positionStyle() {
			switch (this.position) {
				case "top":
					return {
						left: "0",
						bottom: "calc(100% + 20px)"
					}
				case "bottom":
					return {
						top: "calc(100% + 20px)",
						left: "0"
					}
				case "right":
					return {
						left: "calc(100% + 20px)",
						top: "0"
					}
				case "left":
					return {
						right: "100%",
						top: "0"
					}
			}
		},
		hasCustomTrigger() {
			return !!this.$slots.default
		},
		selectInputClasses() {
			let classes = this.classes
			if (this.invalid) {
				classes += " has-error "
			}
			if (this.open) {
				classes += " is-open"
			}
			return classes
		}
	},
	mounted: function () {
		this.reset(this.options)
	},
	methods: {
		selectOption(option, event) {
			event.stopPropagation()
			this.model = option
			this.open = false
			this.$emit("selected", option)
		},
		onBlur() {
			this.open = false
		},
		reset(options, option) {
			if (!this.model || this.model.id == undefined) {
				this.model = options && options.length > 0 ? options[0] : null
			} else if (
				options.length > 0 &&
				options.findIndex((val) => val.id == this.model.id) == -1
			) {
				this.model = options[0]
			} else if (option) {
				this.model = option
			}
			this.validateEnabled = false
		},
		validate() {
			this.validationEnabled = true
			if (this.required && this.model.id == null) {
				this.invalid = true
				return false
			}
			this.invalid = false
			return true
		},
		resetValidation() {
			this.validationEnabled = false
			this.invalid = false
		},
		setOption(option) {
			this.model = option
			if (this.validationEnabled) {
				this.validate()
			}
		},
		toggleDropdown(event) {
			event.stopPropagation()
			this.open = !this.open
			if (this.open) {
				this.$nextTick(() => {
					if (
						!utils.isInViewport(this.$refs.optionsList) &&
						event.target &&
						event.target.scrollIntoView
					) {
						event.target.scrollIntoView({
							behavior: "smooth",
							block: "center"
						})
					}
				})
			}
		}
	},
	watch: {
		options(newVal) {
			if (this.model && this.model.id != null) {
				const foundOption = newVal.find(
					(option) =>
						option.id == this.model.id &&
						option.text == this.model.text
				)
				if (!foundOption) {
					this.reset(newVal)
				}
			}
		},
		open(newVal) {
			this.$emit("toggled", newVal)
		}
	}
}
</script>