
<template>
	<div class="sk-page-content">
		<PageHeader
			:title="voucherRequestTitle"
			:tabs="tabs"
			:filters="filters"
			:breadcrumbs="breadcrumbs"
			@tabClicked="headerTabClicked"
			@filterSet="filterSet"
			@filtersReset="clearFilters"
		>
			<div
				slot="title-decorator"
				class="status-badge"
				:class="statusBadgeClasses"
			>
				<span :class="statusClasses"></span>
				{{ statusLabel }}
			</div>
		</PageHeader>
		<transition name="slide" mode="out-in">
			<VoucherRequestDetails
				v-if="activeTab.id == 0"
				key="0"
				class="centered-tab-content"
				:voucherRequest="voucherRequest"
				@editVoucherRequest="editVoucherRequest"
			/>
			<VoucherRequestVouchers
				v-if="activeTab.id == 1"
				key="1"
				:voucherRequest="voucherRequest"
				:filters="filterVals"
			/>
		</transition>
		<ConfirmationModal ref="confirmationModal" />
	</div>
</template>

<style scoped>
.pill-alt {
	margin-left: 25px;
}

@media (max-width: 560px) {
	.status-badge {
		margin-left: 0;
		margin-top: 5px;
		margin-bottom: 5px;
	}
}
</style>
  
<script>
import voucherRequestStatusMixin from "@/mixins/vouchers/voucher-request-status-mixin"
import loaderMixin from "@/mixins/loader-mixin"
import PageHeader from "@/components/PageHeader.vue"
import VoucherRequestDetails from "@/components/coupon/VoucherRequestDetails.vue"
import VoucherRequestVouchers from "@/components/coupon/VoucherRequestVouchers.vue"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue"

export default {
	name: "VoucherRequest",
	mixins: [voucherRequestStatusMixin, loaderMixin],
	components: {
		PageHeader,
		VoucherRequestDetails,
		VoucherRequestVouchers,
		ConfirmationModal
	},
	data: function () {
		return {
			activeTabId: 0,
			filterVals: {
				code: ""
			}
		}
	},
	computed: {
		voucherRequest() {
			return this.$store.state.couponsModule.voucherRequest
		},
		voucherRequestTitle() {
			return this.voucherRequest.name
				? this.voucherRequest.name
				: "Voucher Request"
		},
		activeTab() {
			return this.tabs.find((tab) => tab.id == this.activeTabId)
		},
		tabs() {
			let allowedTabs = []
			allowedTabs.push({
				id: 0,
				text: "Details"
			})
			allowedTabs.push({
				id: 1,
				text: "Vouchers"
			})
			return allowedTabs
				.map((tab) => {
					tab.active = tab.id == this.activeTabId
					return tab
				})
				.sort((a, b) => a.id - b.id)
		},
		filters() {
			if (this.activeTabId == 1) {
				return [
					{
						id: 0,
						type: "input",
						placeholder: "Code",
						width: "160px"
					}
				]
			}
		},
		breadcrumbs() {
			return [
				{
					name: "Promotions",
					link: "/promotions"
				},
				{
					name: this.voucherRequestTitle
				}
			]
		}
	},
	methods: {
		headerTabClicked(tabClicked) {
			this.activeTabId = tabClicked.id
		},
		editVoucherRequest(options) {
			this.$refs.editVoucherRequestModal.open(options)
		},
		clearFilters() {
			this.filterVals.code = ""
		},
		filterSet(filter) {
			switch (filter.id) {
				case 0:
					this.filterVals.code = filter.value ? filter.value : ""
					break
			}
		}
	}
}
</script>