
<template>
	<div class="sk-page-content">
		<PageHeader
			ref="pageHeader"
			:title="'Promotions'"
			:filters="filters"
			:tabs="tabs"
			:actions="actions"
			:secondaryActions="secActions"
			@filterSet="filterSet"
			@allFiltersReset="filtersReset"
			@actionClicked="headerActionClicked"
			@secondaryActionClicked="secondaryHeaderActionClicked"
			@tabClicked="headerTabClicked"
		/>
		<input
			type="file"
			ref="fileInput"
			style="display: none"
			@change="batchUploadFilesChanged"
		/>
		<transition name="slide" mode="out-in">
			<Coupons
				key="0"
				v-if="activeTab && activeTab.id == 0"
				:filters="filterVals"
			/>
			<VoucherRequests
				key="1"
				v-if="activeTab && activeTab.id == 1"
				:filters="filterVals"
			/>
			<Vouchers
				key="2"
				v-if="activeTab && activeTab.id == 2"
				:filters="filterVals"
			/>
		</transition>
		<ConfirmationModal ref="confirmationModal"></ConfirmationModal>
		<CouponModal ref="couponModal" />
		<VoucherRequestModal ref="voucherRequestModal" />
	</div>
</template>
  
<script>
import { VoucherDistributionType } from "@/enums/voucherDistributionType"
import { VoucherPaymentType } from "@/enums/voucherPaymentType"
import { PermissionTab } from "@/utils/permissions"
import { VoucherRequestStatus } from "@/enums/voucherRequestStatus"
import ActionTypes from "@/store/modules/coupons/action-types"
import PageHeader from "@/components/PageHeader.vue"
import CouponLineItem from "@/components/coupon/CouponLineItem.vue"
import Coupons from "@/components/coupon/Coupons.vue"
import Vouchers from "@/components/coupon/Vouchers.vue"
import VoucherRequests from "@/components/coupon/VoucherRequests.vue"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue"
import CouponModal from "@/components/modals/CouponModal.vue"
import VoucherRequestModal from "@/components/modals/VoucherRequestModal.vue"

export default {
	name: "Promotions",
	components: {
		PageHeader,
		CouponLineItem,
		ConfirmationModal,
		CouponModal,
		VoucherRequestModal,
		Coupons,
		Vouchers,
		VoucherRequests
	},
	data: function () {
		return {
			activeTabId: -1,
			filterVals: {
				businessId: 0,
				code: "",
				status: -1,
				name: "",
				issuedBy: 0,
				distributionType: -1,
				paymentType: -1,
				voucherRequestStatus: -1,
				used: 0,
				couponGroup: 0
			}
		}
	},
	computed: {
		adminUsers() {
			return this.$store.state.admin_users
		},
		secActions() {
			if (this.activeTab) {
				switch (this.activeTab.id) {
					case 0:
						return [
							{
								id: 0,
								text: `<i class="sk-icon-plus-regular"></i> Batch Upload`
							},
							{
								id: 1,
								text: `<i class="sk-icon-link-regular"></i> Download Template`
							}
						]
					case 1:
					case 2: {
						return null
					}
				}
			}
		},
		actions() {
			if (this.activeTab) {
				switch (this.activeTab.id) {
					case 0: {
						return [
							{
								id: 0,
								text: `<i class="sk-icon-plus-regular"></i> New Promotion`,
								classes: "button button-primary"
							}
						]
					}
					case 1: {
						return [
							{
								id: 1,
								text: `<i class="sk-icon-plus-regular"></i> Request Vouchers`,
								classes: "button button-primary"
							}
						]
					}
					case 2: {
						return []
					}
				}
			}
		},
		activeTab() {
			return this.tabs.find((tab) => tab.active)
		},
		couponsAllowed() {
			return this.$store.state.permissions.includes(PermissionTab.COUPONS)
		},
		ownsMultipleEntities() {
			return this.$store.getters.ownsMultipleEntities
		},
		tabs() {
			let allowedTabs = []
			if (this.couponsAllowed) {
				allowedTabs.push({
					id: 0,
					text: "Coupons"
				})
			}
			allowedTabs.push({
				id: 1,
				text: "Voucher Requests"
			})
			allowedTabs.push({
				id: 2,
				text: "Vouchers"
			})
			return allowedTabs
				.map((tab) => {
					tab.active = tab.id == this.activeTabId
					return tab
				})
				.sort((a, b) => a.id - b.id)
		},
		filters() {
			let retVal = []
			if (this.activeTabId == 1) {
				if (this.ownsMultipleEntities) {
					retVal.push({
						id: 1,
						placeholder: "Business",
						type: "search",
						width: "280px",
						fetch: (query) => {
							return this.$store.dispatch(
								"businessesModule/autocompleteBusinesses",
								query
							)
						},
						serializer: (i) => {
							return { text: i.name }
						}
					})
				}
				retVal = retVal.concat([
					{
						id: 2,
						placeholder: "Name",
						type: "input",
						width: "280px"
					},
					{
						id: 3,
						placeholder: "Issuer",
						type: "search",
						width: "280px",
						items: this.adminUsers,
						serializer: (i) => {
							return { text: i.display_name }
						}
					},
					{
						id: 4,
						placeholder: "Distribution",
						type: "dropdown",
						items: [
							{
								text: `Distribution`,
								id: null
							},
							{
								text: `Print`,
								id: VoucherDistributionType.PRINT_NOW
							},
							{
								text: `Ship to Me`,
								id: VoucherDistributionType.SEND_TO_ME
							},
							{
								text: `Sent to Guest`,
								id: VoucherDistributionType.SEND_TO_GUEST
							},
							{
								text: `Send Later`,
								id: VoucherDistributionType.DIGITAL_ONLY
							}
						]
					},
					{
						id: 5,
						placeholder: "Payment",
						type: "dropdown",
						items: [
							{
								text: `Payment`,
								id: null
							},
							{
								text: `Pre Paid`,
								id: VoucherPaymentType.PRE_PAID
							},
							{
								text: `Pay as you Go`,
								id: VoucherPaymentType.PAY_AS_YOU_GO
							}
						]
					},
					{
						id: 6,
						placeholder: "Status",
						type: "dropdown",
						items: [
							{
								text: `Status`,
								id: null
							},
							{
								text: `<span class="status-dot green"></span> Approved`,
								id: VoucherRequestStatus.APPROVED
							},
							{
								text: `<span class="status-dot red"></span> Used`,
								id: VoucherRequestStatus.VOUCHERS_DISTRIBUTED
							},
							{
								text: `<span class="status-dot yellow"></span> Requested`,
								id: VoucherRequestStatus.REQUESTED
							}
						]
					},
					{
						id: 7,
						placeholder: "Usage",
						type: "dropdown",
						items: [
							{
								text: `All`,
								id: -1
							},
							{
								text: "Unused",
								id: 0
							},
							{
								text: "Used",
								id: 1
							}
						]
					}
				])
			} else {
				retVal = [
					{
						id: 0,
						type: "input",
						placeholder: "Code",
						width: "160px"
					},
					{
						id: 1,
						placeholder: "Business",
						type: "search",
						width: "280px",
						fetch: (query) => {
							return this.$store.dispatch(
								"businessesModule/autocompleteBusinesses",
								query
							)
						},
						serializer: (i) => {
							return { text: i.name }
						}
					}
				]
			}

			if (this.activeTabId == 0) {
				retVal.push({
						id: 8,
						placeholder: "Coupon Group",
						type: "dropdown",
						items: [
							{
								text: `All Coupon Groups`,
								id: 0
							},
							{
								text: "Universal",
								id: 1
							},
							{
								text: "Business Specific",
								id: 2
							}
						]
					})
			}
			return retVal
		}
	},
	mounted: async function () {
		this.activeTabId = this.couponsAllowed ? 0 : 1
	},
	methods: {
		filterSet(filter) {
			switch (filter.id) {
				case 0: {
					this.filterVals.code = filter.value
					break
				}
				case 1: {
					this.filterVals.businessId = filter.value
						? filter.value.id
						: 0
					break
				}
				case 2: {
					this.filterVals.name = filter.value
					break
				}
				case 3: {
					this.filterVals.issuedBy = filter.value
						? filter.value.id
						: 0
					break
				}
				case 4: {
					this.filterVals.distributionType = filter.value.id
					break
				}
				case 5: {
					this.filterVals.paymentType = filter.value.id
					break
				}
				case 6: {
					this.filterVals.voucherRequestStatus = filter.value.id
					break
				}
				case 7: {
					this.filterVals.used =
						filter.value.id != -1 ? filter.value.id : null
					break
				}
				case 8: {
					this.filterVals.couponGroup = filter.value.id
				}
			}
		},
		filtersReset() {
			this.filterVals = {
				businessId: 0,
				status: -1,
				code: "",
				name: 0,
				issuedBy: 0,
				distributionType: -1,
				paymentType: -1,
				voucherRequestStatus: -1,
				used: 0,
				couponGroup: 0
			}
			this.setDefaultFilters()
		},
		headerTabClicked(tabClicked) {
			this.$refs.pageHeader.clearAll()
			this.activeTabId = tabClicked.id
			this.$nextTick(() => {
				this.setDefaultFilters()
			})
		},
		setDefaultFilters() {
			if (this.activeTabId == 1) {
				const filter = this.filters.find((filter) => filter.id == 7)
				this.$refs.pageHeader.filterSet(
					filter,
					filter.items.find(
						(item) => item.id == this.filterVals.used
					),
					true
				)
			}
		},
		headerActionClicked(action) {
			switch (action.id) {
				case 0: {
					this.$refs.couponModal.open({})
					break
				}
				case 1: {
					this.$refs.voucherRequestModal.open({})
					break
				}
			}
		},
		secondaryHeaderActionClicked(action) {
			switch (action.id) {
				case 0: {
					this.$refs.fileInput.click()
					break
				}
				case 1: {
					this.$store.dispatch(
						`couponsModule/${ActionTypes.EXPORT_BATCH_UPLOAD_TEMPLATE}`
					)
					break
				}
			}
		},
		batchUploadFilesChanged(event) {
			const files = Object.values(event.target.files)
			if (!files || !files[0]) {
				return
			}
			this.$store.dispatch(
				`couponsModule/${ActionTypes.BATCH_UPLOAD_COUPONS}`,
				files[0]
			)
			this.$refs.fileInput.value = ""
		}
	}
}
</script>