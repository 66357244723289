<template>
	<div class="prepackages-list">
		<div class="sk-table sk-table-default">
			<div class="sk-table-header">
				<div class="sk-table-row">
					<div>Delivery Date</div>
					<div>Total Quantity</div>
					<div>Price</div>
					<div>Issuer</div>
					<div v-if="canFilterForBusiness">Business</div>
					<div>Status</div>
					<div>Actions</div>
				</div>
			</div>
			<transition-group
				name="list"
				tag="div"
				class="sk-table-body"
				appear
			>
				<PrepackagesListOrderLineItem
					v-for="order in pagedPrepackagedOrders"
					:key="order.order_id"
					:order="order"
					:canFilterForBusiness="canFilterForBusiness"
					@openPrepackagesModal="openPrepackagesModal"
					@downloadManifest="downloadManifest"
				/>
			</transition-group>
		</div>
		<PaginationFooter
			:contentText="'Catering orders'"
			:pageSize="20"
			:total="prepackagedOrders.length"
			ref="paginationFooter"
			@pageChanged="filterPage"
		/>
	</div>
</template>

<style scoped>
.prepackages-list {
	display: flex;
	flex-direction: column;
	height: 100%;
}
</style>

<script>
import ActionTypes from "@/store/modules/prepackages/action-types"
import loaderMixin from "@/mixins/loader-mixin"
import prepackagedOrderMixin from "@/mixins/prepackaged-order-mixin"
import PrepackagesListOrderLineItem from "@/components/prepackages/PrepackagesListOrderLineItem.vue"
import PaginationFooter from "@/components/PaginationFooter.vue"

export default {
	name: "PrepackagesList",
	mixins: [loaderMixin, prepackagedOrderMixin],
	components: {
		PrepackagesListOrderLineItem,
		PaginationFooter
	},
	props: {
		filters: Object,
		canFilterForBusiness: {
			type: Boolean,
			default: false
		}
	},
	data: function () {
		return {
			pageRange: {
				page: 1,
				start: 0,
				end: 20
			}
		}
	},
	computed: {
		prepackagedOrders() {
			return this.$store.state.prepackagesModule.prepackagedOrders
		},
		pagedPrepackagedOrders() {
			return this.prepackagedOrders.slice(
				this.pageRange.start,
				this.pageRange.end
			)
		},
		
	},
	mounted: function () {
		this.getPrepackagedOrders()
	},
	methods: {
		openPrepackagesModal(options) {
			this.$emit("openPrepackagesModal", options)
		},
		async downloadManifest(payload) {
			this.$emit("downloadManifest", payload)
		},
		getPrepackagedOrders() {
			this.toggleLoader(true)
			const filters = Object.assign({}, this.filters, {
				statuses: !this.filters.statuses
					? [0, 1, 2, 3, 5]
					: this.filters.statuses
			})
			this.$store
				.dispatch(
					`prepackagesModule/${ActionTypes.GET_PREPACKAGED_ORDERS}`,
					filters
				)
				.finally(() => {
					this.toggleLoader(false)
				})
		},
		filterPage(range) {
			this.pageRange = range
		}
	},
	watch: {
		filters: {
			deep: true,
			handler() {
				this.$refs.paginationFooter.pageChanged(1)
				this.getPrepackagedOrders()
			}
		}
	}
}
</script>