<template>
	<SkBaseModal
		class="sk-modal-alt"
		:isOpen="isOpen"
		:hasLoader="true"
		@close="close"
	>
		<loading :active.sync="loading" :is-full-page="false" />
		<div class="sk-modal-alt-title">
			<h1>Update Attached Menus: {{ businessName }}</h1>
		</div>
		<div class="sk-row">
			<div
				class="sk-switch-label-wrapper sk-modal-select-all"
				@click="selectAll"
			>
				<SkCheckbox :checked="allSelected" />
				<span>Select All</span>
			</div>
		</div>
		<div
			class="sk-row sk-widget-alt menu-row"
			v-for="menuOption in menuOptions"
			:key="menuOption.id"
			@click="menuSelected(menuOption)"
		>
			<SkCheckbox :checked="menuIds.includes(menuOption.id)" />
			<div class="menu-name">
				<b>{{ menuOption.name }}</b>
				({{ menuOption.restaurantName }})
			</div>
		</div>
		<div class="sk-modal-actions">
			<button class="button button-text" @click="close">Cancel</button>
			<button class="button button-primary" @click="updateMenus">
				Update Menus
			</button>
		</div>
	</SkBaseModal>
</template>

<style scoped>
.sk-switch-label-wrapper {
	margin-left: 20px;
}

.sk-checkbox {
	margin-right: 20px;
}

.menu-name {
	font-size: 14px;
	color: var(--sk-dark-navy);
}

.menu-row {
	align-items: center;
	margin: 15px 0;
}
</style>

<script>
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import restaurant_service from "@/services/restaurants"
import ActionTypes from "@/store/modules/hubs/action-types"
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import SkCheckbox from "@/components/SkCheckbox.vue"
import Loading from "vue-loading-overlay"

export default {
	name: "UpdateHubBusinessMenus",
	mixins: [skModalMixin],
	components: {
		SkBaseModal,
		SkCheckbox,
		Loading
	},
	data() {
		return {
			loading: false,
			businessId: 0,
			menuIds: [],
			restaurantMenus: []
		}
	},
	computed: {
		business() {
			const businesses = this.$store.state.hubsModule.currentHubBusinesses
			return businesses.find((business) => business.id == this.businessId)
		},
		businessName() {
			return this.business && this.business.name ? this.business.name : ""
		},
		menuOptions() {
			return this.restaurantMenus.map((menu) => {
				const restaurant =
					this.$store.state.restaurants[menu.restaurant_id]
				const restaurantName =
					restaurant && restaurant.name ? restaurant.name : "N/A"
				return {
					id: menu.id,
					name: menu.name,
					restaurantName: restaurantName
				}
			})
		},
		allSelected() {
			return (
				!this.loading &&
				this.menuOptions.length > 0 &&
				this.menuIds.length == this.menuOptions.length
			)
		},
		businesses() {
			if (this.$store.state.hubsModule.currentHubBusinesses) {
				return this.$store.state.hubsModule.currentHubBusinesses
			}
			return []
		}
	},
	methods: {
		handleOptions(options) {
			this.loading = false
			this.menuIds = []
			this.restaurantMenus = []
			this.businessId = options.businessId
			this.getRestaurantMenus().catch((e) => {
				this.close()
				throw e
			})
		},
		async getRestaurantMenus() {
			try {
				this.loading = true
				const menuIds =
					this.$store.state.hubsModule.currentHub.restaurant_menu_ids.join()
				const response = await restaurant_service.getMenusByIds(
					menuIds,
					"opening_hours"
				)
				if (response.status == 200 && response.data.menus) {
					this.restaurantMenus = response.data.menus
					const businessMenuIds = this.business.menus.map(
						(menu) => menu.id
					)
					this.menuIds = businessMenuIds.filter((menuId) =>
						menuIds.includes(menuId)
					)
				}
				this.loading = false
			} catch (e) {
				this.loading = false
				throw e
			}
		},
		selectAll() {
			if (!this.allSelected) {
				this.menuIds = this.menuOptions.map((menu) => menu.id)
			} else {
				this.menuIds = []
			}
		},
		async updateMenus() {
			try {
				if (!this.loading) {
					this.loading = true
					const menus = this.restaurantMenus.filter((menu) =>
						this.menuIds.includes(menu.id)
					)
					const success = await this.$store.dispatch(
						`hubsModule/${ActionTypes.UPDATE_HUB_BUSINESS_MENUS}`,
						{
							id: this.businessId,
							menuIds: this.menuIds,
							menus
						}
					)

					if (success) {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Hubs",
							message: "Successfully updated menus"
						})
						this.close()
					} else {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Hubs",
							message: "Failed to update menus",
							type: NotificationType.ERROR
						})
					}
				}
				this.loading = false
			} catch (e) {
				this.loading = false
				throw e
			}
		},
		menuSelected(menu) {
			if (!this.menuIds.includes(menu.id)) {
				this.menuIds.push(menu.id)
			} else {
				this.menuIds = this.menuIds.filter(
					(menuId) => menu.id != menuId
				)
			}
		}
	}
}
</script>