<template>
	<SkBaseModal
		class="sk-modal-alt"
		:isOpen="isOpen"
		:hasLoader="true"
		@close="close"
	>
		<button class="button button-icon button-close" @click="close">
			<i class="sk-icon-times-regular"></i>
		</button>
		<div class="sk-modal-alt-title">
			<IntegrationLogo :integrationType="integration.type" />
			<h1>
				{{ integrationTitle }}
			</h1>
		</div>
		<SkModalTabs
			v-if="showTabs"
			:tabs="tabs"
			:isVisible="isOpen"
			@tabClicked="modalTabClicked"
		/>
		<slot v-if="integration.type != undefined">
			<transition-group name="fade-height" mode="out-in">
				<div v-if="activeTabId == 0" class="sk-modal-section" key="0">
					<div class="sk-widget-line-item" v-if="username != null">
						<div>User</div>
						<div>{{ username }}</div>
					</div>
					<div class="sk-widget-line-item" v-if="apiKey != null">
						<div>API Key</div>
						<div>{{ apiKey }}</div>
					</div>
					<div class="sk-widget-line-item" v-if="location != null">
						<div>Location</div>
						<div>
							{{ location.name }}
						</div>
					</div>
					<div class="sk-widget-line-item" v-if="locationId != null">
						<div>Location Id</div>
						<div class="text-truncate" :title="locationId">
							{{ locationId }}
						</div>
					</div>
					<div class="sk-widget-line-item" v-if="autoSync != null">
						<div>Auto Sync</div>
						<div>{{ autoSync }}</div>
					</div>
					<div class="sk-widget-line-item" v-if="lastSynced != null">
						<div>Last Synced</div>
						<div>
							{{ lastSynced }}
						</div>
					</div>
					<div v-if="hasBrands">
						<div class="sk-widget-line-item" v-for="brand in extraParams" :key="brand.id">
							<div>Store ID</div>
							<div>
								{{ brand.external_id }}
							</div>
						</div>
					</div>
					<div class="sk-modal-actions" v-if="editable">
						<button class="button button-text" @click="close">
							Cancel
						</button>
						<button
							class="button button-primary"
							@click="editIntegration"
						>
							<i class="sk-icon-check-regular"></i>
							Edit Credentials
						</button>
					</div>
				</div>
				<div v-if="activeTabId == 1" class="sk-modal-section" key="1">
					<div class="sk-row integration-description">
						{{ integration.description }}
					</div>
					<div class="sk-row sk-modal-section-title">Benefits</div>
					<div
						class="benefit-line-item"
						v-for="(benefit, index) in benefits"
						:key="index"
					>
						<i class="sk-icon-check-regular"></i>
						{{ benefit }}
					</div>
					<div class="sk-modal-actions">
						<button class="button button-text" @click="close">
							Cancel
						</button>
						<button
							v-if="isIntegrationEnabled"
							class="button button-primary"
							@click="removeIntegration"
						>
							<i class="sk-icon-times-regular"></i>
							Remove Integration
						</button>
						<button
							v-else
							class="button button-primary"
							@click="addIntegration"
						>
							<i class="sk-icon-check-regular"></i>
							Add Integration
						</button>
					</div>
				</div>
			</transition-group>
		</slot>
	</SkBaseModal>
</template>

<style scoped>
.integration-description {
	color: var(--sk-grey3);
	margin-bottom: 30px;
	font-size: 12px;
}

.benefit-line-item {
	padding: 10px 0;
	color: var(--sk-grey3);
	font-size: 12px;
	display: flex;
	align-items: center;
}

.benefit-line-item i {
	margin-right: 20px;
}

.sk-modal-actions {
	margin-top: 10px;
}
</style>

<script>
import { IntegrationType } from "@/enums/integrationType"
import tracker from "@/utils/tracker"
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import SkModalTabs from "@/components/modals/SkModalTabs.vue"
import IntegrationLogo from "@/components/IntegrationLogo.vue"

export default {
	name: "IntegrationInfoModal",
	mixins: [skModalMixin],
	components: {
		SkBaseModal,
		IntegrationLogo,
		SkModalTabs
	},
	data: function () {
		return {
			activeTabId: 1,
			integration: {},
			extraParams: null
		}
	},
	computed: {
		tabs() {
			return [
				{
					id: 0,
					text: "Credentials",
					active: this.activeTabId == 0
				},
				{
					id: 1,
					text: "Details",
					active: this.activeTabId == 1
				}
			]
		},
		hasCredentialsTab() {
			return (
				this.integration.type == IntegrationType.UNTAPPD ||
				this.integration.type == IntegrationType.SQUARE ||
				this.integration.type == IntegrationType.CHOWLY ||
				this.integration.type == IntegrationType.REEF ||
				this.integration.type == IntegrationType.GRUBHUB
			)
		},
		showTabs() {
			return this.isIntegrationEnabled && this.hasCredentialsTab
		},
		isIntegrationEnabled() {
			return this.integration && this.integration.isEnabled
		},
		integrationTitle() {
			if (this.integration) {
				switch (this.integration.type) {
					case IntegrationType.UNTAPPD:
						return `${this.integration.name} Menu Integration`
					case IntegrationType.SQUARE:
						return `${this.integration.name} POS Integration`
					case IntegrationType.CHOWLY:
						return "POS Integration"
					case IntegrationType.OTTER:
					case IntegrationType.ORDERMARK:
					case IntegrationType.REEF:
					case IntegrationType.GRUBHUB:
						return this.integration.name
				}
			}
			return ""
		},
		benefits() {
			if (this.integration) {
				switch (this.integration.type) {
					case IntegrationType.UNTAPPD:
						return [
							"Manage all your drinks inventory through your UnTappd account."
						]
					case IntegrationType.CHOWLY:
						return [
							"Receive orders directly to your in house POS and Printer system",
							"No additional hardware required"
						]
					case IntegrationType.SQUARE:
						return [
							"Manage all your menu items through the Square POS interface",
							"Receive 2ndKitchen orders directly into your own in house Square POS",
							"No need for additional hardware or training"
						]
					case IntegrationType.OTTER:
						return [
							"Aggregation - all your delivery orders and performance data in one easy to understand place.",
							"Management and optimization - auto-accept and print, 86 items, and pause/unpause delivery channels through a single click.",
							"Expansion - sign up for new delivery platforms and grow your business through virtual brands.",
							"Partnership - receive round the clock support from a dedicated account manager who will do whatever it takes to help you succeed in delivery."
						]
					case IntegrationType.ORDERMARK:
						return [
							"Syncs and manages all your orders seamlessly through a single printer and tablet",
							"Provides in-depth insights that improve cost optimization and drive sales",
							"Provides access to unlimited orders, services, and 24/7 customer support"
						]
					case IntegrationType.REEF:
						return [
							"Syncs REEF/NBRHD menu items, including vessel-specific menus and brands",
							"Syncs and manages all orders between 2ndKitchen and REEF/NBRHD",
							"Provides in-depth insights, reporting and financials"
						]
					case IntegrationType.GRUBHUB:
						return [
							"Import all your menu items from your GrubHub menu."
						]
				}
			}
			return []
		},
		username() {
			return this.integration &&
				this.integration.type == IntegrationType.UNTAPPD
				? this.integration.username
				: null
		},
		apiKey() {
			return this.integration &&
				(this.integration.type == IntegrationType.UNTAPPD ||
					this.integration.type == IntegrationType.CHOWLY)
				? this.integration.apiKey
				: null
		},
		location() {
			if (
				this.integration &&
				this.integration.type == IntegrationType.SQUARE
			) {
				const locationId = this.integration.locationId
				if (locationId) {
					const location =
						this.$store.state.restaurantsModule.squareLocations.find(
							(location) => location.id == locationId
						)
					return location != undefined ? location : null
				}
			}
			return null
		},
		locationId() {
			if (
				this.integration &&
				(this.integration.type == IntegrationType.ORDERMARK ||
					this.integration.type == IntegrationType.GRUBHUB)
			) {
				return this.integration.locationId
			}
			return null
		},
		hasBrands() {
			if (
				this.integration &&
				this.integration.type == IntegrationType.REEF && this.extraParams && this.extraParams.length > 0
			) {
				return true
			}
			return false
		},
		autoSync() {
			if (
				this.integration &&
				this.integration.type == IntegrationType.UNTAPPD
			) {
				return this.integration.autoSync ? "On" : "Off"
			}
			return null
		},
		lastSynced() {
			return this.integration &&
				(this.integration.type == IntegrationType.UNTAPPD ||
					this.integration.type == IntegrationType.SQUARE ||
					this.integration.type == IntegrationType.ORDERMARK)
				? this.integration.lastSynced
				: null
		},
		editable() {
			return this.integration.type != IntegrationType.ORDERMARK
		}
	},
	methods: {
		handleOptions(options) {
			this.integration = options.integration
			this.activeTabId =
				this.integration.isEnabled && this.hasCredentialsTab ? 0 : 1
			if (options.extraParams) {
				this.extraParams = options.extraParams
			}
		},
		closed() {
			setTimeout(() => {
				if (!this.isOpen) {
					this.integration = {}
				}
			}, 400)
		},
		modalTabClicked(tab) {
			this.activeTabId = tab.id
		},
		addIntegration() {
			tracker.trackEvent("Add Integration Clicked", {
				type: this.integration.type
			})
			this.close()
			this.$emit("addIntegration", this.integration)
		},
		editIntegration() {
			tracker.trackEvent("Edit Integration Clicked", {
				type: this.integration.type
			})
			this.close()
			this.$emit("editIntegration", this.integration, this.extraParams)
		},
		removeIntegration() {
			tracker.trackEvent("Remove Integration Clicked", {
				type: this.integration.type
			})
			this.close()
			this.$emit("removeIntegration", this.integration)
		}
	}
}
</script>