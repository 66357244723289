import { store } from "@/store/store"
import { parseResponse } from "@arikgaisler/utils/api-utils"
import Vue from "vue"

let host = process.env.VUE_APP_API_HOST

const SEARCH_API = `https://indexing${host}.2ndkitchen.com`

export default {
    async searchOrders(payload) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.post(`${SEARCH_API}/orders/search`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getAverageOrderAccuracy(payload) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.post(`${SEARCH_API}/search/orders/avg_accuracy`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getAverageOrderAcceptTimeByPeriod(payload) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.post(`${SEARCH_API}/search/orders/avg_time_to_accept_by_period`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getAverageOrderDeliveryTimeByPeriod(query, payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.post(`${SEARCH_API}/search/orders/avg_time_to_deliver_by_period?period=${query.period}`,payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getAverageOrderAcceptTime(payload) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.post(`${SEARCH_API}/search/orders/avg_time_to_accept`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getAverageOrderPickupTime(payload) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.post(`${SEARCH_API}/search/orders/avg_time_to_pickup`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getAverageOrdersByPeriod(query, payload) {
        const tokenStr = "Bearer " + store.state.user_token
        let url = `${SEARCH_API}/search/orders/avg_orders_by_period?period=${query.period}`
        if (query.sub_period) {
            url = `${url}&sub_period=${query.sub_period}`
        }
        return parseResponse(Vue.axios.post(url, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getAveragePriceByPeriod(period, payload) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.post(`${SEARCH_API}/search/orders/avg_price_by_period?period=${period}`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getTotalCogSales(payload) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.post(`${SEARCH_API}/search/orders/total_cogs_by_period`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getTotalSales(payload) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.post(`${SEARCH_API}/search/orders/total_price_by_period`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getTotalOrdersByPeriod(period, payload) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.post(`${SEARCH_API}/search/orders/total_by_period?period=${period}`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async searchTopProducts(payload) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.post(`${SEARCH_API}/search/top_products`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async searchCoupons(payload) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.post(`${SEARCH_API}/search/coupons`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getRestaurantPayouts(payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.post(`${SEARCH_API}/restaurants-payouts/search`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getBusinessBillingInvoices(payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.post(`${SEARCH_API}/business_billing_collections/search`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async searchVoucherRequests(payload) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.post(`${SEARCH_API}/voucher_requests/search`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async searchOrdersFinancialRecords(payload) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.post(`${SEARCH_API}/search/orders_financial_records`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async searchExceptionReasons(payload) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.post(`${SEARCH_API}/orders_exceptions/search/reasons`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async searchAverageOrderScore(payload) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.post(`${SEARCH_API}/orders/avg_scores`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async searchHubs(payload) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.post(`${SEARCH_API}/aggregations/hub_locations/location`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async searchRecommendedBusinesses(payload) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.post(`${SEARCH_API}/recommend/businesses`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async searchRecommendedRestaurants(payload) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.post(`${SEARCH_API}/recommend/restaurants`, payload, { 'headers': { 'Authorization': tokenStr } }))
    }
}