import Vue from "vue"
import utils from "@/utils/utils"
import Integration from "@/models/Integration"
import moment from "moment"
import { RestaurantPOSDevice } from "@/enums/restaurantPOSDevice"
import { SupplyRequestStatus } from "@/enums/supplyRequestStatus"
import { IntegrationType } from "@/enums/integrationType"
import { PermissionInfo } from "@/utils/permissions"

export default {
    gotRestaurant(state, payload) {
        state.currentRestaurant = payload
        state.restaurantPOSDevices = []
        state.restaurantProducts = []
        state.integrations = []
        state.squareLocations = []
        if (!state.currentRestaurant.opening_hours || state.currentRestaurant.opening_hours == null || state.currentRestaurant.opening_hours.length == 0){
            let hoursDict = {}
            state.currentRestaurant.menus.forEach(menu => {
                menu.opening_hours.forEach(openingHours => {
                    if (hoursDict[openingHours.day_of_week]){
                        let hourObj = hoursDict[openingHours.day_of_week]
                        let openHourObj = moment.utc(hourObj.open_time, "HH:mm:ss")
                        let closeHourOj = moment.utc(hourObj.close_time, "HH:mm:ss")
                        let menuOpenHour = moment.utc(openingHours.open_time, "HH:mm:ss")
                        let menuCloseHour = moment.utc(openingHours.close_time, "HH:mm:ss")
                        if (menuOpenHour < openHourObj){
                            hourObj.open_time = openingHours.open_time
                        }
                        if (menuCloseHour > closeHourOj){
                            hourObj.close_time = openingHours.close_time
                        }
                    }
                    else {
                        hoursDict[openingHours.day_of_week] = {day_of_week: openingHours.day_of_week, open_time: openingHours.open_time, close_time: openingHours.close_time}
                    }
                });
                
            });
            for (var day in hoursDict){
                state.currentRestaurant.opening_hours.push(hoursDict[day])
            }
        }
    },
    gotRestaurantBusinesses(state, payload) {
        state.restaurantBusinesses = payload.data.businesses
    },
    gotRestaurantPOSDevices(state, payload) {
        if (state.currentRestaurant && payload.id == state.currentRestaurant.id) {
            state.restaurantPOSDevices = payload.devices.filter(device => device.pos_system_id == RestaurantPOSDevice.TABLET)
        }
    },
    gotRestaurantProducts(state, payload) {
        state.restaurantProducts = payload
    },
    updatedProduct(state, product) {
        const products = state.restaurantProducts
        const productIndex = products.findIndex(item => product.id == item.id)
        if (productIndex >= 0) {
            Vue.set(state.restaurantProducts, productIndex, product)
        }
        state.currentRestaurant.menus
            .filter(menu => state.restaurantMenuProducts[menu.id] != undefined || state.restaurantMenuProducts[menu.id] != null)
            .forEach(menu => {
                const products = state.restaurantMenuProducts[menu.id]
                const index = products.findIndex(item => product.id == item.id)
                if (index >= 0) {
                    Vue.set(state.restaurantMenuProducts, index, product)
                }
            })
    },
    updatedRestaurantProducts(state, data) {
        data.products.forEach(updatedProduct => {
            const productIndex = state.restaurantProducts.findIndex(product => updatedProduct.id == product.id)
            if (productIndex >= 0) {
                state.restaurantProducts.splice(productIndex, 1, updatedProduct)
            } else {
                state.restaurantProducts.push(updatedProduct)
            }
        })
    },
    updatedProductMetaValueStock(state, payload) {
        const products = state.restaurantProducts
        const product = products.find(product => product.id == payload.productId)
        if (product != undefined && product.metas != undefined && product.metas != null) {
            const meta = product.metas.find(meta => meta.id == payload.metaId)
            if (meta != undefined && meta.values != undefined && meta.values != null) {
                const value = meta.values.find(value => value.id == payload.valueId)
                if (value != undefined) {
                    value.stock_status = payload.status
                }
            }
        }
    },
    updatedProductMetaStock(state, payload) {
        const products = state.restaurantProducts
        const product = products.find(product => product.id == payload.productId)
        if (product != undefined && product.metas != undefined && product.metas != null) {
            const meta = product.metas.find(meta => meta.id == payload.metaId)
            if (meta != undefined && meta.values != undefined && meta.values != null) {
                meta.values.forEach(value => {
                    value.stock_status = payload.status
                })
            }
        }
    },
    updatedMenuStock(state, status) {
        const currentMenu = state.currentRestaurant.menus.find(menu => menu.id == state.currentMenuID)
        if (currentMenu != undefined) {
            currentMenu.stock_status = status
            const menuProducts = state.restaurantMenuProducts[state.currentMenuID]
            menuProducts.forEach(product => {
                product.stock_status = status
            })
        }
    },
    updateProductStock(state, payload) {
        const product  = state.restaurantProducts.find(product => product.id == payload.productId)
        if (product != undefined) {
            product.stock_status = payload.status
            if (product.metas != undefined && product.metas != null) {
                product.metas
                    .filter(meta => meta.values != undefined && meta.values != null && meta.values.length != 0)
                    .forEach(meta => {
                        meta.values.forEach(value => value.stock_status = payload.status)
                    })
            }
        }
    },
    updatedRestaurantStock(state, status) {
        state.currentRestaurant.stock_status = status
    },
    addRestaurantMenuProducts(state, arr) {
        const menu_id = arr[0]
        const products = arr[1]
        Vue.set(state.restaurantMenuProducts, menu_id, products)
    },
    setCurrentRestaurantLogo(state, img) {
        state.currentRestaurant.logo = img
    },
    updatedMenu(state, menu) {
        const currentMenuIndex = state.currentRestaurant.menus.findIndex(localMenu => localMenu.id == menu.id)
        if (currentMenuIndex >= 0) {
            state.currentRestaurant.menus.splice(currentMenuIndex, 1, menu)
        }
    },
    addedMenu(state, menu){
        state.currentRestaurant.menus.push(menu)
        Vue.set(state.restaurantMenuProducts, menu.id, [])
    },
    addedNewMenuProducts(state, payload) {
        if (state.restaurantMenuProducts[payload.menuId]) {
            state.restaurantMenuProducts[payload.menuId] = state.restaurantMenuProducts[payload.menuId].concat(payload.products)
        }
    },
    addedNewMenuProduct(state, payload) {
        state.restaurantMenuProducts[payload.menuId].push(payload.product)
    },
    addedProduct(state, product) {
        product.menu_ids = []
        state.restaurantProducts.push(product)
    },
    addedProducts(state, products) {
        state.restaurantProducts = state.restaurantProducts.concat(products)
    },
    archivedProduct(state, productId) {
        const product = state.restaurantProducts.find(product => product.id == productId)
        if (product) {
            product.status = 2
            product.menu_ids = []
            const menuIds = product.menu_ids ? product.menu_ids : []
            menuIds.forEach(id => {
                const products = state.restaurantMenuProducts[id]
                const index = products ? products.findIndex(item => productId == item.id) : -1
                if (index >= 0) {
                    state.restaurantMenuProducts[id].splice(index, product)
                }
            })
        }
    },
    unarchivedProduct(state, productId) {
        const product = state.restaurantProducts.find(product => product.id == productId)
        if (product) {
            product.status = 1
        }
    },
    deletedProduct(state, productId) {
        let restProductIndex = state.restaurantProducts.findIndex(product => product.id == productId)
        if (restProductIndex >= 0) {
            const product = state.restaurantProducts[restProductIndex]
            if (product.menu_ids && product.menu_ids.length > 0) {
                product.menu_ids
                    .filter(id => state.restaurantMenuProducts[id] != undefined || state.restaurantMenuProducts[id] != null)
                    .forEach(id => {
                        const products = state.restaurantMenuProducts[id]
                        const index = products ? products.findIndex(item => productId == item.id) : -1
                        if (index >= 0) {
                            state.restaurantMenuProducts[id].splice(index, product)
                        }
                    })
            }
            state.restaurantProducts.splice(restProductIndex, 1)
        }

    },
    batchDeletedProducts(state, productIds) {
        let deletedProducts = state.restaurantProducts.filter((deletedProduct) => {
            return productIds.includes(deletedProduct.id) 
            
        })
        state.restaurantProducts = state.restaurantProducts.filter((product) => {
            return !productIds.includes(product.id) 
          
        })
        deletedProducts.forEach(deletedProduct => {
            if (deletedProduct.menu_ids && deletedProduct.menu_ids.length > 0) {
                deletedProduct.menu_ids
                    .filter(id => state.restaurantMenuProducts[id] != undefined || state.restaurantMenuProducts[id] != null)
                    .forEach(id => {
                        const products = state.restaurantMenuProducts[id]
                        const index = products ? products.findIndex(item => deletedProduct.id == item.id) : -1
                        if (index >= 0) {
                            state.restaurantMenuProducts[id].splice(index, deletedProduct)
                        }
                    })
            }
        })
    },
    addedMenuProducts(state, payload) {
        const catalog = state.restaurantProducts.reduce((products, product) => {
            products[product.id] = product
            return products
        }, {})
        const addedProducts = payload.products.map(product => {
            const restaurantProduct = catalog[product.product_id]
            const extendedProduct = utils.clonedeep(restaurantProduct)
            extendedProduct.stock_status = product.stock_status
            return extendedProduct
        })
        const restaurantMenuProducts = state.restaurantMenuProducts[payload.menuId]
        state.restaurantMenuProducts[payload.menuId] = restaurantMenuProducts.concat(addedProducts)
    },
    addedMenuProduct(state, payload) {
        if (!payload.product.menu_ids.includes(payload.menuId)) {
            payload.product.menu_ids.push(payload.menuId)
        }
        if (!state.restaurantMenuProducts[payload.menuId]) {
            Vue.set(state.restaurantMenuProducts, payload.menuId, [])
        }
        const extendedProduct = utils.clonedeep(payload.product)
        extendedProduct.stock_status = payload.stock_status
        state.restaurantMenuProducts[payload.menuId].push(extendedProduct)
        const restaurantProduct = state.restaurantProducts.find(product => product.id == payload.product.id)
        if (restaurantProduct) {
            restaurantProduct.menu_ids = payload.product.menu_ids
        }
    },
    removedMenuProduct(state, payload) {
        let products = state.restaurantMenuProducts[payload.menuId]
        if (products) {
            let index = products.findIndex(product => product.id == payload.productId)
            if (index >= 0) {
                products.splice(index, 1)
            }
        }
        const restaurantProduct = state.restaurantProducts.find(product => product.id == payload.productId)
        if (restaurantProduct) {
            restaurantProduct.menu_ids = restaurantProduct.menu_ids.filter(id => id != payload.menuId)
        }
    },
    deletedMenu(state, menu_id) {
        for (var i = 0; i < state.currentRestaurant.menus.length; i++){
            if (state.currentRestaurant.menus[i].id == menu_id){
                if (state.currentMenuID == menu_id){
                    if (i == 0){
                        if (state.currentRestaurant.menus.length > 1)
                            state.currentMenuID = state.currentRestaurant.menus[1].id
                    }
                    else
                        state.currentMenuID = state.currentRestaurant.menus[i-1].id
                }
                state.currentRestaurant.menus.splice(i,1)
            }
        }
        delete state.restaurantMenuProducts[menu_id] 
    },
    updatedMenuHours(state, payload) {
        const currentMenu = state.currentRestaurant.menus.find(menu => menu.id == payload.menuId)
        if (currentMenu != undefined) {
            currentMenu.opening_hours = payload.hours
        }
    },
    setCurrentMenuID(state, id) {
        state.currentMenuID = id
    },
    loading(state) {
        state.loading = true
    },
    loaded(state) {
        state.loading = false
    },
    clearRestaurant(state) {
        state.currentRestaurant = {}
        state.restaurantMenus = {}
        state.restaurantPOSDevices = []
        state.restaurantProducts = []
        state.restaurantMenuProducts = []
        state.categories = []
        state.labels = []
        state.integrations = []
        state.squareLocations = []
    },
    addedCategory(state, category) {
        state.categories.push(category)
    },
    gotCategories(state, categories) {
        categories.push({
            id: -1,
            name: "General"
        })
        categories.push({
            id: -2,
            name: "Archived"
        })
        state.categories = categories
    },
    gotLabels(state, labels) {
        state.labels = labels
    },
    updatedRestaurantHours(state, payload) {
        state.currentRestaurant.opening_hours = payload.hours
    },
    addedRestaurantHoursException(state, exception) {
        if (state.currentRestaurant && state.currentRestaurant.opening_hours_exceptions) {
            state.currentRestaurant.opening_hours_exceptions.push(exception)
        }
    },
    updatedRestaurantHoursException(state, updatedException) {
        const index = state.currentRestaurant.opening_hours_exceptions.findIndex(exception => exception.id == updatedException.id)
        if (index >= 0) {
            state.currentRestaurant.opening_hours_exceptions.splice(index, 1, updatedException)
        }
    },
    removedRestaurantHoursException(state, id) {
        const index = state.currentRestaurant.opening_hours_exceptions.findIndex(exception => exception.id == id)
        if (index >= 0) {
            state.currentRestaurant.opening_hours_exceptions.splice(index, 1)
        }
    },
    gotRestaurantIntegrations(state, payload) {
        let activeIntegrations = []
        let integrations = []
        const grubhubIntegrationAllowed = payload.permissions.includes(PermissionInfo.RESTAURANT_INTEGRATION_GRUBHUB)
        
        payload.active.forEach(data => {
            if (data.type == IntegrationType.GRUBHUB && !grubhubIntegrationAllowed) {
                return
            }
            activeIntegrations.push(data.type)
            integrations.push(new Integration(data.type, data))
        })

        payload.available.forEach(data => {
            if (!activeIntegrations.includes(data.id)) {
                const isGrubhub = data.id == IntegrationType.GRUBHUB
                if (!grubhubIntegrationAllowed && isGrubhub) {
                    return
                }
                const isActive = isGrubhub ? true : data.is_active
                integrations.push(new Integration(data.id, {
                    is_active: isActive
                }))
            }
        })

        state.integrations = integrations
    },
    updatedRestaurantIntegration(state, data) {
        const newIntegration = new Integration(data.type, data)
        const index = state.integrations.findIndex(integration => integration.type == newIntegration.type)
        if (index >= 0) {
            state.integrations.splice(index, 1, newIntegration)
        } else {
            state.integrations.push(newIntegration)
        }
    },
    gotRestaurantSquareLocations(state, locations) {
        state.squareLocations = locations
    },
    hideIntegrationsExplainer(state) {
        state.hideIntegrationExplainer = true
    },
    gotSupplies(state, supplies) {
        state.supplies = supplies
    },
    gotSupplyRequests(state, supplyRequests) {
        state.supplyRequests = supplyRequests
    },
    removeSupplyRequest(state, id) {
        const index = state.supplyRequests.findIndex(request => request.id == id)
        if (index > -1) {
            state.supplyRequests.splice(index, 1)
        }
    },
    updateSupplyRequest(state, payload) {
        let statusStr = "APPROVED"
        if (payload.status == SupplyRequestStatus.REJECTED) {
            statusStr = "REJECTED"
        } else if (payload.status == SupplyRequestStatus.CANCELLED) {
            statusStr = "CANCELLED"
        }
        const supplyRequest = state.supplyRequests.find(request => request.id == payload.id)
        if (supplyRequest) {
            state.supplyRequests.status.name = statusStr
            state.supplyRequests.status.value = status
        }
    },
}