<template>
	<div class="sk-page-content">
		<PageHeader
			:title="'Users'"
			:tabs="tabs"
			:actions="actions"
			:filters="filters"
			@actionClicked="headerActionClicked"
			@filterSet="filterSet"
			@filtersReset="clearFilters"
			@tabClicked="headerTabClicked"
		/>
		<transition name="slide" mode="out-in">
			<StaffUsers
				key="0"
				v-if="activeTab.id == 0"
				:filter="filter"
				@editStaffUserClicked="openEditStaffUserModal"
			/>
			<AdminUsers
				key="1"
				v-if="activeTab.id == 1"
				:filter="filter"
				@editAdminUserClicked="openEditAdminUserModal"
			/>
			<ChefUsers
				key="2"
				v-if="activeTab.id == 2"
				:filter="filter"
				@editChefUserClicked="openEditChefUserModal"
			/>
			<BusinessGuests
				key="3"
				v-if="activeTab.id == 3"
				:filter="filter"
				@editBusinessGuestClicked="openEditBusinessGuestModal"
			/>
		</transition>
		<ConfirmationModal ref="payoutsConfirmationModal" />
		<StaffUserModal ref="newStaffUserModal" />
		<AdminUserModal ref="newAdminUserModal" />
		<ChefUserModal ref="newChefUserModal" />
		<BusinessGuestModal ref="newBusinessGuestModal" />
	</div>
</template>

<script>
import { PermissionTab } from "@/utils/permissions"
import loaderMixin from "@/mixins/loader-mixin"
import PageHeader from "@/components/PageHeader.vue"
import StaffUsers from "@/components/users/StaffUsers.vue"
import AdminUsers from "@/components/users/AdminUsers.vue"
import ChefUsers from "@/components/users/ChefUsers.vue"
import BusinessGuests from "@/components/users/BusinessGuests.vue"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue"
import StaffUserModal from "@/components/modals/StaffUserModal.vue"
import AdminUserModal from "@/components/modals/AdminUserModal.vue"
import ChefUserModal from "@/components/modals/ChefUserModal.vue"
import BusinessGuestModal from "@/components/modals/businessGuests/BusinessGuestModal.vue"

export default {
	name: "Users",
	mixins: [loaderMixin],
	components: {
		PageHeader,
		StaffUsers,
		AdminUsers,
		ChefUsers,
		BusinessGuests,
		ConfirmationModal,
		StaffUserModal,
		AdminUserModal,
		ChefUserModal,
		BusinessGuestModal
	},
	data: function () {
		return {
			didMount: false,
			activeTabId: 0,
			filter: {
				businessId: 0,
				name: "",
				email: "",
				phone: "",
				status: null,
				isActive: -1,
				restaurantId: 0,
				checkInDate: {},
				checkOutDate: {},
				adminUserId: 0
			},
		}
	},
	created: function () {
		this.activeTabId = this.showHotelChefUsersTab ? 2 : 0
	},
	mounted: function () {
		this.toggleLoader(true)
		this.$store.dispatch("getAdminUsers")
		this.$store.dispatch("businessesModule/getEmployees")
		this.didMount = true
	},
	methods: {
		openEditAdminUserModal(payload) {
			this.$refs.newAdminUserModal.open({
				user: payload
			})
		},
		openEditStaffUserModal(payload) {
			this.$refs.newStaffUserModal.open({
				user: payload
			})
		},
		openEditChefUserModal(payload) {
			this.$refs.newChefUserModal.open({
				user: payload
			})
		},
		openEditBusinessGuestModal(payload) {
			this.$refs.newBusinessGuestModal.open({
				businessGuest: payload
			})
		},
		headerTabClicked(tabClicked) {
			this.activeTabId = tabClicked.id
		},
		clearFilters() {
			this.filter.businessId = 0
			this.filter.name = ""
			this.filter.email = ""
			this.filter.phone = ""
			this.filter.status = null
			this.filter.isActive = -1
			this.filter.restaurantId = 0
			this.filter.checkInDate = {}
			this.filter.checkOutDate = {}
			this.filter.adminUserId = 0
		},
		filterSet(filter) {
			switch (filter.id) {
				case 1:
					this.filter.businessId = filter.value ? filter.value.id : 0
					break
				case 2:
					this.filter.name = filter.value ? filter.value : ""
					break
				case 3:
					this.filter.email = filter.value ? filter.value : ""
					break
				case 4:
					this.filter.phone = filter.value ? filter.value : ""
					break
				case 5:
					this.filter.status = filter.value ? filter.value.id : null
					break
				case 6:
					this.filter.restaurantId = filter.value
						? filter.value.id
						: null
					break
				case 7:
					this.filter.isActive = filter.value ? filter.value.id : null
					break
				case 8:
					this.filter.checkInDate = filter.value ? filter.value : {}
					break
				case 9:
					this.filter.checkOutDate = filter.value ? filter.value : {}
					break
				case 10:
					this.filter.adminUserId = filter.value
						? filter.value.id
						: null
					break
			}
		},
		headerActionClicked(action) {
			switch (action.id) {
				case 0: {
					this.$refs.newStaffUserModal.open()
					break
				}
				case 1: {
					this.$refs.newAdminUserModal.open()
					break
				}
				case 2: {
					this.$refs.newChefUserModal.open()
					break
				}
				case 3: {
					this.$refs.newBusinessGuestModal.open()
					break
				}
			}
		}
	},
	computed: {
		ownsMultipleBusinesses() {
			return this.$store.getters.ownsMultipleEntities
		},
		adminUsers() {
			return this.$store.state.admin_users
		},
		tabs() {
			let allowedTabs = []

			if (this.showAdminTab) {
				allowedTabs.push({
					id: 0,
					text: "Staff"
				})
				allowedTabs.push({
					id: 1,
					text: "Chef Users"
				})
				allowedTabs.push({
					id: 3,
					text: "Guests"
				})
			} else if (this.showHotelChefUsersTab) {
				allowedTabs.push({
					id: 2,
					text: "Chef Users"
				})
				allowedTabs.push({
					id: 3,
					text: "Guests"
				})
			} else {
				allowedTabs.push({
					id: 0,
					text: "Staff"
				})
			}

			return allowedTabs
				.map((tab) => {
					tab.active = tab.id == this.activeTabId
					return tab
				})
				.sort((a, b) => a.id - b.id)
		},
		filters() {
			let hasRestaurantFilter = this.activeTabId == 1
			let hasPhoneFilter = this.activeTabId != 2
			let hasStatusFilter = this.activeTabId == 0
			let hasActiveFilter = this.activeTabId == 1 || this.activeTabId == 2
			let hasBusinessFilter = this.ownsMultipleBusinesses
			let hasDatesFilters = this.activeTabId == 3
			let retVal = []
			if (hasBusinessFilter){
				retVal.push({
					id: 1,
					placeholder: "Business",
					type: "search",
					fetch: (query) => {
						return this.$store.dispatch(
							"businessesModule/autocompleteBusinesses",
							query
						)
					},
					width: "250px",
					serializer: (i) => {
						return { text: i.name }
					}
				})
			}
			if (hasRestaurantFilter) {
				retVal.push({
					id: 6,
					placeholder: "Restaurant",
					type: "search",
					fetch: (query) => {
						return this.$store.dispatch(
							"restaurantsModule/autocompleteRestaurants",
							query
						)
					},
					width: "250px",
					serializer: (i) => {
						return { text: i.name }
					}
				})
			}


			retVal.push({
				id: 2,
				type: "input",
				placeholder: "Name",
				width: "160px"
			})
			retVal.push({
				id: 3,
				type: "input",
				placeholder: "Email",
				width: "160px"
			})
			if (hasPhoneFilter) {
				retVal.push({
					id: 4,
					type: "input",
					placeholder: "Phone",
					width: "160px"
				})
			}
			if (hasDatesFilters){
				retVal.push({
					id: 8,
					type: "daterange",
					placeholder: "Check In Dates"
				})
				retVal.push({
					id: 9,
					type: "daterange",
					placeholder: "Check Out Dates"
				})
				retVal.push({
					id: 10,
					placeholder: "Checked In By",
					type: "search",
					width: "280px",
					items: this.adminUsers,
					serializer: (i) => {
						return { text: i.display_name }
					}
				})
			}
			if (hasActiveFilter) {
				retVal.push({
					id: 7,
					placeholder: "Status",
					type: "dropdown",
					items: [
						{
							text: `Status`,
							id: null
						},
						{
							text: `Activated`,
							id: 1
						},
						{
							text: `Inactive`,
							id: 0
						}
					]
				})
			}
			if (hasStatusFilter) {
				retVal.push({
					id: 5,
					placeholder: "Status",
					type: "dropdown",
					items: [
						{
							text: `Status`,
							id: null
						},
						{
							text: `Eligible`,
							id: 1
						},
						{
							text: `Ineligible`,
							id: 0
						}
					]
				})
			}

			return retVal
		},
		actions() {
			switch (this.activeTab.id) {
				case 0:
					return [
						{
							id: 0,
							text: `<i class="sk-icon-plus-regular"></i> New Staff Member`,
							classes: "button button-primary"
						}
					]
				case 1:
					return [
						{
							id: 1,
							text: `<i class="sk-icon-plus-regular"></i> New Chef User`,
							classes: "button button-primary"
						}
					]
				case 2:
					return [
						{
							id: 2,
							text: `<i class="sk-icon-plus-regular"></i> New User`,
							classes: "button button-primary"
						}
					]
				case 3:
					return [
						{
							id: 3,
							text: `<i class="sk-icon-plus-regular"></i> New Guest`,
							classes: "button button-primary"
						}
					]
			}
			return []
		},
		activeTab() {
			return this.tabs.find((tab) => tab.active)
		},
		loading() {
			return (
				this.$store.state.loading ||
				this.$store.state.businessesModule.loading ||
				!this.didMount
			)
		},
		showAdminTab() {
			return this.$store.state.permissions.includes(
				PermissionTab.USERS_ADMIN
			)
		},
		showHotelChefUsersTab() {
			return this.$store.state.permissions.includes(
				PermissionTab.CHEF_ADMINS
			)
		}
	},
	watch: {
		loading: function (newVal) {
			this.toggleLoader(newVal)
		}
	}
}
</script>