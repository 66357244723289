<template>
	<div>
		<div class="sk-column">
			<div class="sk-insight-section-title">Totals</div>
			<div class="sk-insight-section-description">
				Understand how performance has been overall
			</div>
		</div>
		<div class="sk-grid">
			<div class="sk-column">
				<TotalSalesStatistic :footerArticleId="ordersFooterArticleId" />
				<TotalOrdersStatistic :footerArticleId="ordersFooterArticleId" />
			</div>
			<TotalTabbedChart class="total-tabbed-chart" />
		</div>
		<div class="sk-column">
			<div class="sk-insight-section-title">Breakdowns</div>
			<div class="sk-insight-section-description">
				Dive into sales to assess the demand of your service
			</div>
		</div>
		<div class="sk-grid breakdown-statistics">
			<AverageTicketSalesChart :title="'Average Ticket Sales'" />
			<TopOrdersByDowChart :footerArticleId="ordersFooterArticleId" />
			<TopOrdersHourlyChart />
		</div>
	</div>
</template>

<style scoped>
.sk-grid {
	grid-template-columns: 1fr 1fr 1fr;
}

.breakdown-statistics {
	height: 310px;
}

.breakdown-statistics > * {
	flex: 1;
	display: flex;
}

.total-tabbed-chart {
	grid-column: 2 / span 2;
}
</style>

<script>
import ActionTypes from "@/store/modules/insights/action-types"
import TotalSalesStatistic from "@/components/insights/TotalSalesStatistic.vue"
import TotalOrdersStatistic from "@/components/insights/TotalOrdersStatistic.vue"
import TotalTabbedChart from "@/components/insights/TotalTabbedChart.vue"
import AverageTicketSalesChart from "@/components/insights/AverageTicketSalesChart.vue"
import TopOrdersByDowChart from "@/components/insights/TopOrdersByDowChart.vue"
import TopOrdersHourlyChart from "@/components/insights/TopOrdersHourlyChart.vue"
import { BusinessType } from "@arikgaisler/utils/enums/businessType"

export default {
	name: "BusinessSalesAndOrders",
	components: {
		TotalSalesStatistic,
		TotalOrdersStatistic,
		TotalTabbedChart,
		AverageTicketSalesChart,
		TopOrdersByDowChart,
		TopOrdersHourlyChart
	},
	props: {
		filterVals: Object
	},
	computed: {
		ordersFooterArticleId() {
			let businessType = BusinessType.BAR
			if (
				this.$store.getters.ownedBusinesses &&
				this.$store.getters.ownedBusinesses.length > 0
			) {
				businessType = this.$store.getters.ownedBusinesses[0].type
			}
			switch (businessType) {
				case BusinessType.BAR:
					return "how-can-i-improve-my-bars-order-volume-31rebi"
				case BusinessType.HOTEL:
					return "how-can-i-improve-my-hotels-order-volume-19tl7ct"
				case BusinessType.RESIDENTIAL:
					return "how-can-i-improve-my-buildings-order-volume-120zcgp"
			}
		}
	},
	watch: {
		filterVals: {
			immediate: true,
			deep: true,
			handler(filterVals) {
				if (!filterVals.startDate || !filterVals.endDate) {
					return
				}
				this.$store.dispatch(
					`insightsModule/${ActionTypes.GET_TOTAL_ORDERS_DATA}`
				)
				this.$store.dispatch(
					`insightsModule/${ActionTypes.GET_TOTAL_SALES_DATA}`, {
						showCogs: false
					}
				)
				this.$store.dispatch(
					`insightsModule/${ActionTypes.GET_AVERAGE_TICKET_PRICE_BY_DOW}`
				)
				this.$store.dispatch(
					`insightsModule/${ActionTypes.GET_TOP_ORDERS_BY_DOW}`
				)
				this.$store.dispatch(
					`insightsModule/${ActionTypes.GET_TOP_ORDERS_BY_HOUR}`
				)
			}
		}
	}
}
</script>