<template>
	<SkBaseModal :isOpen="isOpen" :showCloseButton="false" @close="close">
		<div class="sk-modal-banner">
			<div v-if="isInNetwork" class="pill-alt pill-alt-green">
				In Network
			</div>
			<SkMap class="sales-tool-result-map" :markers="markers" />
		</div>
		<div class="sk-column">
			<div class="sales-tool-result-section result-location">
				<div>
					<h3 class="result-location-name">{{ resultName }}</h3>
					<div>{{ resultAddress }}</div>
					<div class="result-location-distance">
						{{ resultDistance }} miles
					</div>
				</div>
				<img
					v-if="resultLogoSrc"
					class="result-logo"
					:src="resultLogoSrc"
					alt="Logo"
				/>
			</div>
			<div class="sales-tool-result-section" v-if="showInsights">
				<h3>
					<i class="sk-icon-file-chart-line-solid"></i>
					Insights
				</h3>
				<div class="sk-row result-insights">
					<div
						v-if="pairings && pairings.length > 0"
						class="result-insight"
						@click="isPairingsOpen = !isPairingsOpen"
					>
						<div>Partners</div>
						<b>
							{{ pairings.length }}
							<i
								class="sk-icon-chevron-down-regular flippable"
								:class="{ flipped: isPairingsOpen }"
							></i>
						</b>
						<SkPopover v-model="isPairingsOpen">
							<div v-for="pairing in pairings" :key="pairing.id">
								{{ pairing.name }}
							</div>
						</SkPopover>
					</div>
					<div
						v-for="(insight, i) in insights"
						:key="i"
						class="result-insight"
					>
						<div>{{ insight.title }}</div>
						<b>{{ insight.text }}</b>
					</div>
				</div>
			</div>
			<!-- <div class="sales-tool-result-section">
				<h3>
					<i class="sk-icon-cogs-solid"></i>
					Configurations
				</h3>
				<div class="result-insight">
					<div>Delivery</div>
					<b>Doordash</b>
				</div>
			</div> -->
		</div>
	</SkBaseModal>
</template>

<style scoped>
* >>> .sk-modal-body-top-radius {
	display: none;
}

.sk-modal-banner {
	height: 150px;
	display: block;
	background: none;
	margin-bottom: 30px;
}

.sk-modal-banner .pill-alt {
	position: absolute;
	z-index: 100;
	left: 30px;
	top: 23px;
}

* >>> .vue-map {
	height: 150px;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

* >>> .sk-modal-content {
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

h3 {
	font-size: 16px;
	font-weight: 600;
	color: var(--sk-dark-navy);
	margin-bottom: 30px;
}

h3 i {
	font-size: 12px;
	color: var(--sk-grey3);
	margin-right: 12px;
}

.result-location {
	display: flex;
	justify-content: space-between;
}

.result-location-name {
	margin-bottom: 10px;
}

.result-location-distance {
	font-size: 10px;
}

.sk-column {
	margin: 0 10px;
	width: calc(100% - 20px);
}

.result-insights {
	width: 100%;
	margin: 0;
	justify-content: space-between;
}

.result-insight {
	flex: 0 0 auto !important;
}

.result-insight > b {
	font-weight: 600;
	font-size: 14px;
	color: var(--sk-dark-navy);
	margin-top: 5px;
}

.result-insight .sk-icon-chevron-down-regular {
	font-size: 10px;
	color: var(--sk-grey2);
	margin-left: 10px;
}

.sales-tool-result-section + .sales-tool-result-section {
	padding-top: 25px;
	border-top: 1px solid var(--sk-grey);
}

.sales-tool-result-section {
	font-size: 12px;
	color: var(--sk-grey3);
}

.result-logo {
	width: 75px;
	height: 75px;
	border-radius: 50%;
	object-fit: cover;
}
</style>

<script>
import SkMap from "@/components/SkMap.vue"
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import salesToolResultMixin from "@/mixins/sales/sales-tool-result-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import SkPopover from "@/components/SkPopover.vue"

export default {
	name: "SalesToolResultModal",
	mixins: [skModalMixin, salesToolResultMixin],
	components: {
		SkBaseModal,
		SkMap,
		SkPopover
	},
	data: function () {
		return {
			result: null,
			isPairingsOpen: false
		}
	},
	computed: {
		showInsights() {
			return (
				this.insights.length > 0 ||
				(this.pairings && this.pairings.length > 0)
			)
		},
		insights() {
			const insights = []
			if (this.result) {
				if (this.result.orders && this.result.orders > 0) {
					insights.push({
						title: "Total Orders",
						text: this.result.orders
					})
				}

				if (this.rating) {
					insights.push({
						title: this.isInNetwork ? "NPS Score" : "Google Rating",
						text: this.rating
					})
				}

				if (this.accuracy) {
					insights.push({
						title: "Accuracy",
						text: `${this.accuracy}%`
					})
				}
			}
			return insights
		},
		resultPosition() {
			if (!this.result) {
				return null
			}

			if (this.result.business) {
				return {
					lat: this.result.business.latitude,
					lng: this.result.business.longitude
				}
			} else if (this.result.restaurant) {
				return {
					lat: this.result.restaurant.latitude,
					lng: this.result.restaurant.longitude
				}
			} else {
				return {
					lat: this.result.latitude,
					lng: this.result.longitude
				}
			}
		},
		resultLogoSrc() {
			if (!this.result) {
				return null
			}

			if (this.result.business && this.result.business.logo != "") {
				return (
					this.$businessCDNURL +
					this.result.business.slug +
					"/" +
					this.result.business.logo
				)
			} else if (
				this.result.restaurant &&
				this.result.restaurant.logo != ""
			) {
				let logoSuffix =
					this.result.restaurant.logo &&
					this.result.restaurant.logo.length > 0
						? this.result.restaurant.logo
						: `${this.result.restaurant.slug}-logo.jpg`
				return `${this.$restaurantCDNURL}${this.result.restaurant.slug}/${logoSuffix}`
			}
			return null
		},
		markers() {
			const locations = this.$store.state.salesModule.searchedLocations
			if (locations.length > 0 && this.result) {
				return [
					{
						position: this.resultPosition,
						title: this.resultName,
						icon: {
							url: this.isInNetwork
								? "/images/map-marker-green.png"
								: "/images/map-marker-navy.png",
							size: new google.maps.Size(23, 23),
							scaledSize: new google.maps.Size(23, 23)
						}
					},
					{
						position: {
							lat: locations[0].latitude,
							lng: locations[0].longitude
						},
						title: locations[0].formattedAddress,
						icon: {
							url: "/images/map-marker-orange.png",
							size: new google.maps.Size(23, 23),
							scaledSize: new google.maps.Size(23, 23)
						}
					}
				]
			}
			return []
		}
	},
	methods: {
		handleOptions(options) {
			this.isPairingsOpen = false
			this.result = options.result
		}
	}
}
</script>