import moment from "moment"
import "moment-timezone"

export default {
    isUpcomingOrder(order) {
        const isPreorder = this.isPreorder(order)
        const orderNotAccepted = order.sub_orders.some(
            suborder =>
                suborder.accept_time == null ||
                suborder.accept_time == undefined ||
                suborder.accept_time == ""
        )
        let isUpcoming = isPreorder && orderNotAccepted
        let meetsTimeThreshold = false
        if (isUpcoming) {
            let utcPreorderDate = order.scheduled_date ? order.scheduled_date : order.sub_orders.find(suborder => suborder.delivery_slot).delivery_slot
            const now = moment.utc()
            let duration = moment.duration(moment.utc(utcPreorderDate).diff(now))
            meetsTimeThreshold = duration.asMinutes() > 60
        }
        return isUpcoming && meetsTimeThreshold
    },
    isPreorder(order, suborder) {
        const hasScheduledDate =
            order.scheduled_date != undefined &&
            order.scheduled_date != null &&
            order.scheduled_date != ""
        let hasDeliverySlot = false

        if (suborder) {
            hasDeliverySlot = suborder && suborder.delivery_slot != null && suborder.delivery_slot != undefined && suborder.delivery_slot != ""
        } else {
            hasDeliverySlot = order && order.sub_orders.some(e => e.delivery_slot != null && e.delivery_slot != undefined && e.delivery_slot != "")
        }
        return hasDeliverySlot || hasScheduledDate
    },
    preorderDate(order, suborder, timezone) {
        let date = null

        if (order.scheduled_date != null && order.scheduled_date != undefined && order.scheduled_date != "") {
            date = order.scheduled_date
        } else if (suborder) {
            if (suborder.delivery_slot != null && suborder.delivery_slot != undefined && suborder.delivery_slot != "") {
                date = suborder.delivery_slot
            }
        } else {
            const suborder = order.sub_orders.find(
                suborder =>
                    suborder.delivery_slot != null &&
                    suborder.delivery_slot != "" &&
                    suborder.delivery_slot != undefined
            )
            date = suborder ? suborder.delivery_slot : null
        }

        if (date != null && timezone) {
            date = moment.tz(date, timezone)
        }

        return date
    },
}  