import { parsePhoneNumberFromString, AsYouType } from "libphonenumber-js"

let phoneFilterMixin = {
	filters: {
		maskPhoneInput: function (phone) {
			if (phone) {
				return new AsYouType().input(phone)
			}
			return ""
		},
		formatInternationalPhone: function (phone, code) {
			if (phone) {
				const number = code ? `${code}${phone}` : phone
				const phoneNumber = parsePhoneNumberFromString(number)
				if (
					phoneNumber != undefined &&
					phoneNumber != null &&
					phoneNumber.isValid()
				) {
					return phoneNumber.formatInternational()
				}
				return phone
			}
			return ""
		},
		internationalPhone: function (phone, code) {
			if (phone) {
				const number = code ? `${code}${phone}` : phone
				const phoneNumber = parsePhoneNumberFromString(number)
				if (
					phoneNumber != null &&
					phoneNumber != undefined &&
					phoneNumber.isValid()
				) {
					return phoneNumber.InternationalNumber
				}
				return phone
			}
			return ""
		}
	}
}

export default phoneFilterMixin
