<template>
	<div class="sk-table-row">
		<div class="sk-table-column-fit">
			<div :title="statusLabel" :class="statusBadgeClasses">
				<span :class="statusClasses"></span>
				{{ statusLabel }}
			</div>
		</div>

		<div class="sk-table-column-fit">
			{{ user.name }}
		</div>
		<div class="sk-table-column-fit">
			{{ user.email }}
		</div>
		<div class="sk-table-column-fit">
			{{ phone }}
		</div>
		<div class="sk-table-pills sk-table-column-fit">
			<router-link
				:key="user.id"
				:to="businessLink"
				tag="a"
				class="pill pill-grey"
				:title="name"
			>
				<i class="sk-icon-link-regular"></i>
				{{ name }}
			</router-link>
		</div>
		<div class="sk-table-column-fit">
			<div>{{ user.title }}</div>
		</div>
		<div class="sk-table-column-fit">
			<div class="sk-table-actions">
				<button
					class="button button-icon"
					title="Edit"
					@click="editStaffUser"
				>
					<i class="sk-icon-pencil-solid"></i>
				</button>
				<SkDropdownSelect
					:options="allowedActions"
					:iconClasses="'sk-icon-ellipsis-v-regular'"
					:showSelectedOption="false"
					:position="'left'"
					@selected="actionSelected"
				/>
			</div>
		</div>
	</div>
</template>

<style scoped>
.pill i {
	margin-right: 10px;
}
.sk-table-body .sk-table-row > .menu-schedules {
	white-space: normal;
}
.menu-schedules {
	padding: 20px 0;
}
.menu-schedules > div {
	display: inline-block;
	margin-right: 20px;
}
</style>

<script>
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"
import phoneFilterMixin from "@/mixins/phone-filter-mixin"

export default {
	name: "StaffUserLineItem",
	mixins: [phoneFilterMixin],
	components: {
		SkDropdownSelect
	},
	props: {
		user: Object
	},
	computed: {
		businessLink() {
			return this.business != undefined
				? `/business/${this.business.id}`
				: "/businesses'"
		},
		statusBadgeClasses() {
			return this.user.is_active == 1
				? "pill-alt pill-alt-green"
				: "pill-alt pill-alt-grey"
		},
		statusLabel() {
			return this.user.is_active == 1 ? "Eligible" : "Not Eligible"
		},
		statusClasses() {
			return this.user.is_active == 1
				? "status-dot green-pulsating"
				: "status-dot grey"
		},
		business() {
			let retVal =
				this.$store.getters.businessesMap[this.user.business_id]
			return retVal
		},
		name() {
			if (this.business != undefined) {
				return this.business.name
			} else {
				return ""
			}
		},
		phone() {
			if (this.user.phone && this.user.phone != "") {
				let code = this.user.phone.startsWith("+") ? null : `+1`
				return this.$options.filters.formatInternationalPhone(
					this.user.phone,
					code
				)
			} else {
				return "N/A"
			}
		},
		allowedActions() {
			let actions = []
			if (this.user.is_active == 1) {
				actions.push({
					text: `<i class="sk-icon-times-regular"></i> Deactivate`,
					id: 0
				})
			} else {
				actions.push({
					text: `<i class="sk-icon-check-regular"></i> Activate`,
					id: 0
				})
			}
			actions.push({
				text: `<i class="sk-icon-trash-alt-solid"></i> Delete`,
				id: 1
			})
			return actions
		}
	},
	methods: {
		editStaffUser() {
			this.$emit("updateStaffUserClicked", this.user)
		},
		actionSelected(action) {
			switch (action.id) {
				case 0:
					if (this.user.is_active == 1) {
						this.$store.dispatch(
							"businessesModule/updateBusinessEmployeeStatus",
							{
								id: this.user.id,
								status: 2
							}
						)
					} else {
						this.$store.dispatch(
							"businessesModule/updateBusinessEmployeeStatus",
							{
								id: this.user.id,
								status: 1
							}
						)
					}
					break
				case 1:
					this.$emit("removeEmployeeClicked", this.user)
					break
			}
		}
	}
}
</script>