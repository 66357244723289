<template>
	<div class="business-guest-orders">
		<div v-if="orders.length > 0" class="sk-table sk-table-default">
			<div class="sk-table-header">
				<div class="sk-table-row">
					<div>Order Number</div>
					<div>Placed</div>
					<div>Delivered</div>
					<div>Items</div>
					<div>Price</div>
					<div>Voucher</div>
					<div>NPS</div>
					<div>Actions</div>
				</div>
			</div>
			<transition-group
				name="list"
				tag="div"
				class="sk-table-body"
				appear
			>
				<BusinessGuestOrderLineItem
					v-for="order in orders"
					:key="order.id"
					:order="order"
					@viewOrder="openOrderDetailsModal"
				/>
			</transition-group>
		</div>
		<EmptyPlaceholderWidget
			v-else
			:title="`No Orders Yet`"
			:description="`This guest has not placed any orders yet`"
		/>
		<OrderDetailsModal ref="orderDetailsModal" />
	</div>
</template>

<style scoped>
.business-guest-orders {
	height: 100%;
	display: flex;
	flex-direction: column;
}
</style>

<script>
import ActionTypes from "@/store/modules/users/action-types"
import loadingMixin from "@/mixins/loader-mixin"
import BusinessGuestOrderLineItem from "@/components/users/BusinessGuestOrderLineItem.vue"
import EmptyPlaceholderWidget from "@/components/EmptyPlaceholderWidget.vue"
import OrderDetailsModal from "@/components/modals/OrderDetailsModal.vue"

export default {
	name: "BusinessGuestOrders",
	mixins: [loadingMixin],
	components: {
		BusinessGuestOrderLineItem,
		EmptyPlaceholderWidget,
		OrderDetailsModal
	},
	computed: {
		businessGuest() {
			return this.$store.state.usersModule.currentBusinessGuest
		},
		orders() {
			return this.businessGuest && this.businessGuest.enrichedOrders
				? this.businessGuest.enrichedOrders
				: []
		}
	},
	mounted: function () {
		this.toggleLoader(true)
		this.$store
			.dispatch(
				`usersModule/${ActionTypes.GET_CURRENT_BUSINESS_GUEST_ORDERS}`
			)
			.finally(() => {
				this.toggleLoader(false)
			})
	},
	methods: {
		openOrderDetailsModal(order) {
			this.$refs.orderDetailsModal.open({
				order: order
			})
		}
	}
}
</script>