<template>
	<div class="vld-parent">
		<loading :active.sync="loading" :is-full-page="false"></loading>
		<InsightBaseWidget
			:title="'Average Acceptance Time'"
			:footerIcon="footerIcon"
			:footerTitle="footerTitle"
		>
			<div class="average-accept-time-content" slot="content">
				<div class="average-accept-time-statistic">
					{{ averageAcceptTimeText }}
				</div>
				<SkSliderScale
					:value="averageAcceptTimePercentage"
					:invert="true"
				/>
			</div>
		</InsightBaseWidget>
	</div>
</template>

<style scoped>
.average-accept-time-statistic {
	font-size: 36px;
	color: var(--sk-dark-navy);
	margin-bottom: 22px;
	font-weight: 600;
}

.average-accept-time-content {
	padding: 22px 0;
}
</style>

<script>
import pluralizeFilterMixin from "@/mixins/pluralize-filter-mixin"
import InsightBaseWidget from "@/components/insights/InsightBaseWidget.vue"
import Loading from "vue-loading-overlay"
import SkSliderScale from "@/components/SkSliderScale.vue"

export default {
	name: "RestaurantAverageAcceptTimeWidget",
	mixins: [pluralizeFilterMixin],
	components: {
		InsightBaseWidget,
		Loading,
		SkSliderScale
	},
	computed: {
		loading() {
			return this.$store.state.insightsModule.averageAcceptTime.loading
		},
		averageAcceptTimeText() {
			if (!this.loading) {
				let time = parseFloat(this.averageAcceptMinutes)
				let suffix = this.$options.filters.pluralize(time, "Minute")
				return `${this.averageAcceptMinutes} ${suffix}`
			}
			return "--"
		},
		averageAcceptTimePercentage() {
			if (this.averageAcceptMinutes) {
				if (this.averageAcceptMinutes >= 5) {
					return 100
				}
				if (this.averageAcceptMinutes < 1) {
					return 0
				}
				return (this.averageAcceptMinutes / 5) * 100
			}
			return 0
		},
		averageAcceptMinutes() {
			const data = this.$store.state.insightsModule.averageAcceptTime.data
			if (data) {
				let minutes = data / 60
				return minutes.toFixed(2).replace(/[.,]00$/, "")
			}
			return 0
		},
		reachedThreshold() {
			//Todo
			return false
		},
		footerIcon() {
			if (this.loading) {
				return ""
			}
			if (this.reachedThreshold) {
				return "sk-icon-smile-regular"
			}

			return "sk-icon-frown-regular"
		},
		footerTitle() {
			if (this.loading) {
				return ""
			}
			//TODO
			if (this.reachedThreshold) {
				return "Good Timing!"
			}

			return "Bad Timing!"
		}
	}
}
</script>