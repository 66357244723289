<template>
	<SkTabs
		class="sk-row"
		:tabs="tabs"
		:isVisible="isVisible"
		@tabClicked="tabClicked"
	/>
</template>

<script>
import SkTabs from "@/components/SkTabs.vue"

export default {
	name: "SkModalTabs",
	components: {
		SkTabs
	},
	props: {
		tabs: Array,
		isVisible: Boolean
	},
	methods: {
		tabClicked(tab) {
			this.$emit("tabClicked", tab)
		}
	}
}
</script>