<template>
	<div class="centered-tab-content mobile-button-bottom">
		<div class="sk-row sk-widget sk-widget-section">
			<div class="sk-widget-section-title">Name</div>
			<SkInput
				:name="'Menu Name'"
				:placeholder="'Name'"
				v-model="menu.name"
			/>
		</div>
		<div class="sk-row sk-widget sk-widget-section">
			<div class="sk-widget-section-title">Menu Type</div>
			<div v-if="!isPrePackagedMenu">
				<div class="sk-switch-label-wrapper">
					<SkCheckbox v-model="menu.is_backup" />
					<span>Is Backup</span>
				</div>
				<div class="sk-switch-label-wrapper">
					<SkCheckbox v-model="menu.is_late_night" />
					<span>Is Late Night</span>
				</div>
			</div>
			<div v-else>
				<div class="sk-switch-label-wrapper">
					<SkCheckbox v-model="preOrdersAllowed" />
					<span>User Facing</span>
				</div>
			</div>
		</div>
		<div class="sk-row sk-widget sk-widget-section">
			<div class="sk-widget-section-title">Delivery</div>
			<div class="sk-radio-group">
				<SkRadio
					:option="courierIds.POSTMATES"
					v-model="menu.courier_id"
				/>
				<span>PostMates</span>
			</div>
			<div class="sk-radio-group">
				<SkRadio
					:option="courierIds.DOORDASH"
					v-model="menu.courier_id"
				/>
				<span>DoorDash</span>
			</div>
			<div class="sk-radio-group">
				<SkRadio
					:option="courierIds.CUT_CATS"
					v-model="menu.courier_id"
				/>
				<span>Cut Cats</span>
			</div>
			<div class="sk-radio-group">
				<SkRadio :option="courierIds.RELAY" v-model="menu.courier_id" />
				<span>Relay</span>
			</div>
			<div class="sk-radio-group">
				<SkRadio
					:option="courierIds.WALKING"
					v-model="menu.courier_id"
				/>
				<span>Self Delivery</span>
			</div>
			<div class="sk-radio-group">
				<SkRadio
					:option="courierIds.TOOKAN"
					v-model="menu.courier_id"
				/>
				<span>Bar Delivery</span>
			</div>
			<div class="sk-radio-group">
				<SkRadio :option="courierIds.REEF" v-model="menu.courier_id" />
				<span>Reef</span>
			</div>
		</div>
		<div class="sk-row sk-widget sk-widget-section">
			<div class="sk-widget-section-title">Dropoff/ Pickup Method</div>
			<div
				v-for="deliveryMode in deliveryModes"
				:key="deliveryMode.id"
				class="sk-switch-label-wrapper delivery-mode-line-item"
				:class="{
					'delivery-mode-line-item-active': deliveryMode.active
				}"
			>
				<SkCheckbox
					@click.native="toggleDeliveryMode(deliveryMode)"
					:value="deliveryMode.active"
					:disabled="true"
				/>
				<span>{{ deliveryMode.name }}</span>
				<div
					v-if="deliveryMode.active"
					class="sk-radio-group"
					:class="{ 'sk-radio-group-active': deliveryMode.isDefault }"
				>
					<SkRadio
						:option="deliveryMode.id"
						v-model="defaultDeliveryMode"
					/>
					<span>Default</span>
				</div>
			</div>
		</div>
		<div
			v-if="showDeliverySlots"
			class="sk-row sk-widget sk-widget-section"
		>
			<div class="sk-widget-section-title">Delivery Slots</div>
			<div
				class="sk-row"
				v-for="(slot, i) in deliverySlots"
				:key="slot.id"
			>
				<SkTimePicker
					v-model="slot.formatted"
					:name="'Delivery Slot'"
					:placeholder="'Select a delivery slot time'"
					@input="
						updateDeliverySlot(slot.formatted, slot.cutoffDelta, i)
					"
				/>
				<SkInput
					:name="'Cutoff Offset (Hours)'"
					:placeholder="'Cutoff Offset (Hours)'"
					:value="slot.cutoffDelta"
					:type="'number'"
					@input="updateDeliverySlot(slot.formatted, $event, i)"
				/>
				<button
					class="button button-icon remove-delivery-slot-button"
					@click="removeDeliverySlot(i)"
				>
					<i class="sk-icon-trash-alt-solid"></i>
				</button>
			</div>
			<div class="sk-row">
				<button
					class="
						button button-text
						sk-widget-section-text-button-light
					"
					@click="addDeliverySlot"
				>
					<i class="sk-icon-plus-regular"></i>
					Add Delivery Slot
				</button>
			</div>
		</div>
		<div class="sk-row sk-widget sk-widget-section">
			<div class="sk-widget-section-title">Finance</div>
			<div class="sk-row">
				<SkInput
					class="commission-input"
					v-model="menu.min_order_amount"
					:name="'Minimum'"
					:placeholder="'Minimum'"
					:type="'number'"
					:group="'$'"
				/>
			</div>
		</div>
		<div class="sk-row sk-widget sk-widget-section">
			<div class="sk-widget-section-title">Restaurant</div>
			<div class="sk-pills-container">
				<router-link
					:key="restaurant.id"
					:to="`/restaurant/${restaurant.id}/menu/${menu.id}`"
					tag="div"
					class="pill pill-grey"
					:title="restaurant.name"
				>
					<i class="sk-icon-link-regular"></i>
					{{ restaurant.name }}
				</router-link>
			</div>
		</div>
		<button class="button button-primary button-absolute" @click="saveMenu">
			Save Details
		</button>
	</div>
</template>

<style scoped>
* >>> .sk-radio {
	width: 15px;
	height: 15px;
	margin-right: 10px;
}

.commission-input {
	max-width: 260px;
}

.sk-pills-container {
	overflow: hidden;
	position: relative;
}

.remove-delivery-slot-button {
	flex-grow: 0 !important;
}

.delivery-mode-line-item + .delivery-mode-line-item {
	margin-top: 15px;
}

.delivery-mode-line-item > span {
	margin: 0 20px 0 20px;
	color: var(--sk-grey3);
}

.delivery-mode-line-item-active > span {
	font-weight: 600;
	color: var(--sk-dark-navy);
}

.delivery-mode-line-item .sk-radio-group > span {
	color: var(--sk-grey2);
	font-style: italic;
}
</style>

<script>
import moment from "moment"
import { MenuType } from "@arikgaisler/utils/enums/menuType"
import { CourierId } from "@arikgaisler/utils/enums/courierId"
import { DeliveryMode } from "@arikgaisler/utils/enums/deliveryMode"
import { BusinessType } from "@arikgaisler/utils/enums/businessType"
import loaderMixin from "@/mixins/loader-mixin"
import deliveryModeFormatterMixin from "@/mixins/business/delivery-mode-formatter-mixin"
import SkInput from "@/components/SkInput.vue"
import SkRadio from "@/components/SkRadio.vue"
import SkCheckbox from "@/components/SkCheckbox.vue"
import SkTimePicker from "@/components/SkTimePicker.vue"

export default {
	name: "BusinessMenuBasicInfo",
	mixins: [loaderMixin, deliveryModeFormatterMixin],
	components: {
		SkInput,
		SkRadio,
		SkCheckbox,
		SkTimePicker
	},
	props: {
		menu: Object
	},
	data: function () {
		return {
			courierIds: CourierId,
			deliverySlot: null
		}
	},
	computed: {
		showDeliverySlots() {
			return this.business.type != BusinessType.BAR
		},
		business() {
			return this.$store.state.businessesModule.currentBusiness
		},
		offset() {
			return this.$store.getters["businessesModule/offset"]
		},
		restaurant() {
			return this.$store.state.restaurants[this.menu.restaurant_id]
		},
		isPopupMenu() {
			return this.menu.type == MenuType.POPUP
		},
		isPrePackagedMenu() {
			return this.menu.type == MenuType.PRE_PACKAGED
		},
		preOrdersAllowed: {
			get: function () {
				return this.menu.pre_order_allowed == 1
			},
			set: function (val) {
				val
					? (this.menu.pre_order_allowed = 1)
					: (this.menu.pre_order_allowed = 0)
			}
		},
		deliverySlots() {
			if (this.menu.delivery_slots) {
				return this.menu.delivery_slots.map((slot, i) => {
					const formattedTime = slot.time
						? moment
								.utc(slot.time, "HH:mm:ss")
								.add(this.offset, "m")
								.format("h:mm a")
						: ""
					return {
						id: i,
						time: slot.time,
						cutoffDelta: slot.cutoff_delta
							? slot.cutoff_delta / 60
							: null,
						formatted: formattedTime
					}
				})
			}
			return null
		},
		deliveryModes() {
			let activeDeliveryModes = []
			if (
				this.menu.delivery_modes &&
				this.menu.delivery_modes.length > 0
			) {
				activeDeliveryModes = this.menu.delivery_modes.map(
					(deliveryMode) => deliveryMode.delivery_mode
				)
			}

			let deliveryModes = [
				{
					id: DeliveryMode.DIRECT_TO_LOCATION,
					name: this.$options.filters.deliveryModeName(
						DeliveryMode.DIRECT_TO_LOCATION,
						this.business.type
					)
				}
			]

			if (this.business.type == BusinessType.BAR) {
				deliveryModes.push({
					id: DeliveryMode.YAY_FOOD_POLE,
					name: this.$options.filters.deliveryModeName(
						DeliveryMode.YAY_FOOD_POLE,
						this.business.type
					)
				})
			}

			if (this.business.type == BusinessType.AIRPORT) {
				deliveryModes.push({
					id: DeliveryMode.LOCKER,
					name: this.$options.filters.deliveryModeName(
						DeliveryMode.LOCKER,
						this.business.type
					)
				})
			}

			if (this.business.type !== BusinessType.AIRPORT) {
				deliveryModes.push(
					{
						id: DeliveryMode.PICKUP_AREA,
						name: this.$options.filters.deliveryModeName(
							DeliveryMode.PICKUP_AREA,
							this.business.type
						)
					},
					{
						id: DeliveryMode.LAST_YARD,
						name: this.$options.filters.deliveryModeName(
							DeliveryMode.LAST_YARD,
							this.business.type
						)
					}
				)
			}

			deliveryModes = deliveryModes.concat([
				{
					id: DeliveryMode.DIRECT_TO_CUSTOMER,
					name: this.$options.filters.deliveryModeName(
						DeliveryMode.DIRECT_TO_CUSTOMER,
						this.business.type
					)
				}
			])
			deliveryModes.forEach((deliveryMode) => {
				deliveryMode.active = activeDeliveryModes.includes(
					deliveryMode.id
				)
				deliveryMode.isDefault =
					this.defaultDeliveryMode == deliveryMode.id
			})
			return deliveryModes
		},
		defaultDeliveryMode: {
			get: function () {
				if (this.menu && this.menu.delivery_modes) {
					const deliveryMode = this.menu.delivery_modes.find(
						(deliveryMode) => deliveryMode.is_default
					)
					return deliveryMode ? deliveryMode.delivery_mode : null
				}
				return null
			},
			set: function (deliveryMode) {
				this.$store.dispatch(
					"businessesModule/updateDefaultDeliveryMode",
					{
						defaultDeliveryMode: deliveryMode,
						menuId: this.menu.id
					}
				)
			}
		}
	},
	methods: {
		saveMenu() {
			this.$emit("save")
		},
		addDeliverySlot() {
			let slots = this.menu.delivery_slots.slice()

			slots.push({
				time: null,
				cutoff_delta: null
			})

			this.$store.dispatch("businessesModule/updateDeliverySlots", {
				menuId: this.menu.id,
				slots: slots
			})
		},
		updateDeliverySlot(time, cutoffDelta, index) {
			if (time || cutoffDelta) {
				const updatedSlot = {
					time: time
						? moment(time, "h:mm a")
								.subtract(this.offset, "m")
								.format("HH:mm:ss")
						: null,
					cutoff_delta: cutoffDelta ? cutoffDelta * 60 : null
				}

				const slots = this.deliverySlots.map((slot, i) => {
					if (i == index) {
						return updatedSlot
					} else {
						return {
							time: slot.time,
							cutoff_delta: slot.cutoffDelta * 60
						}
					}
				})

				this.$store.dispatch("businessesModule/updateDeliverySlots", {
					menuId: this.menu.id,
					slots: slots
				})
			}
		},
		removeDeliverySlot(index) {
			const slots = this.menu.delivery_slots.filter(
				(deliverySlot, i) => i != index
			)
			this.$store.dispatch("businessesModule/updateDeliverySlots", {
				menuId: this.menu.id,
				slots: slots
			})
		},
		toggleDeliveryMode(updatedDeliveryMode) {
			this.$store.dispatch("businessesModule/updateDeliveryModes", {
				updatedDeliveryMode: updatedDeliveryMode,
				menuId: this.menu.id
			})
		}
	}
}
</script>
