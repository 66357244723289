import { ExceptionReasonType } from "@/enums/exceptionReasonType"

export default {
    textForExceptionReasonType(type) {
        switch (type) {
            case ExceptionReasonType.RESTAURANT_CLOSED:
                return "Downtime"
            case ExceptionReasonType.CANCELLED:
                return "Order Cancelled"
            case ExceptionReasonType.CASH_PAYMENT:
                return "Cash Payment"
            case ExceptionReasonType.COURIER_REQUESTED:
                return "Courier Requested"
            case ExceptionReasonType.DUPLICATE_ORDER:
                return "Duplicate Order"
            case ExceptionReasonType.LATE_DELIVERY:
                return "Late Delivery"
            case ExceptionReasonType.MISSING_ITEM:
                return "Missing Item"
            case ExceptionReasonType.NOT_ACCEPTED:
                return "Not Accepted"
            case ExceptionReasonType.REVERSE_EXCEPTION:
                return "Reverse Exception"
            case ExceptionReasonType.TEST_ORDER:
                return "Test Order"
            case ExceptionReasonType.UNDELIVERED:
                return "Undelivered"
            case ExceptionReasonType.WRONG_ITEM:
                return "Incorrect Item"
        }
        return ""
    }
}