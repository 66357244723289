<template>
	<BusinessDashboard v-if="businessDashboardAllowed" />
	<RestaurantDashboard v-else />
</template>

<script>
import { PermissionPage } from "@/utils/permissions"
import BusinessDashboard from "@/components/dashboard/business/BusinessDashboard.vue"
import RestaurantDashboard from "@/components/dashboard/restaurant/RestaurantDashboard.vue"

export default {
	name: "Dashboard",
	components: {
		BusinessDashboard,
		RestaurantDashboard
	},
	computed: {
		businessDashboardAllowed() {
			return this.$store.state.permissions.includes(PermissionPage.BUSINESS_DASHBOARD)
		}
	}
}
</script>