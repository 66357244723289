
<template>
	<div class="fixed-button-bottom">
		<div class="sk-row sk-widget sk-widget-section">
			<div class="sk-widget-section-title">General</div>
			<ImageUpload
				:titleText="'Logo'"
				:imgSrc="logo"
				:placeholderText="restaurant.slug"
				:previewWidth="140"
				:previewHeight="140"
				:entity="restaurantType"
				:entity_name="restaurant.slug"
				@set="setRestaurantLogo"
			/>
			<EstablishmentTypeaheadInput
				v-model="establishment"
				:placeholder="'Google Restaurant Name'"
			/>
			<SkInput
				:name="'Restaurant Name'"
				:placeholder="'Name'"
				v-model="restaurant.name"
			/>
			<AddressTypeaheadInput v-model="address" />
		</div>
		<div class="sk-row sk-widget sk-widget-section">
			<div class="sk-widget-section-title">Contact</div>
			<SkTelephoneInput
				v-for="(phone, i) in phones"
				:key="`phone-${i}`"
				:name="'Restaurant Phone Number'"
				:placeholder="'(555) 555-5555'"
				:type="'tel'"
				v-model="phones[i]"
			/>
			<button
				class="button button-text sk-widget-section-text-button-light"
				@click="addPhone"
			>
				<i class="sk-icon-plus-regular"></i>
				Add Phone Number
			</button>
			<SkInput
				v-for="(email, i) in emails"
				:key="`email-${i}`"
				:name="'Restaurant Email'"
				:placeholder="'jsmith@gmail.com'"
				v-model="emails[i]"
			/>
			<button
				class="button button-text sk-widget-section-text-button-light"
				@click="addEmail"
			>
				<i class="sk-icon-plus-regular"></i>
				Add Email
			</button>
		</div>
		<button
			class="button button-primary button-absolute"
			@click="saveRestaurantInfo"
		>
			Save Details
		</button>
	</div>
</template>

<script>
import gapi from "@/utils/gapi"
import phoneFilterMixin from "@/mixins/phone-filter-mixin"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"
import SkInput from "@/components/SkInput.vue"
import SkTelephoneInput from "@/components/SkTelephoneInput.vue"
import ImageUpload from "@/components/ImageUpload.vue"
import AddressTypeaheadInput from "@/components/AddressTypeaheadInput.vue"
import EstablishmentTypeaheadInput from "@/components/EstablishmentTypeaheadInput.vue"

export default {
	name: "RestaurantBasicInfo",
	mixins: [phoneFilterMixin],
	components: {
		ImageUpload,
		SkDropdownSelect,
		SkInput,
		SkTelephoneInput,
		AddressTypeaheadInput,
		EstablishmentTypeaheadInput
	},
	data: function () {
		return {
			phones: [],
			emails: []
		}
	},
	computed: {
		restaurant() {
			return this.$store.state.restaurantsModule.currentRestaurant
		},
		restaurantType() {
			return "restaurant"
		},
		logo() {
			if (
				this.restaurant.logo != "" &&
				this.restaurant.logo != undefined
			) {
				return (
					this.$restaurantCDNURL +
					this.restaurant.slug +
					"/" +
					this.restaurant.logo
				)
			} else {
				return ""
			}
		},
		phone() {
			return this.restaurant && this.restaurant.phone_number
				? this.restaurant.phone_number
				: ""
		},
		email() {
			return this.restaurant && this.restaurant.email
				? this.restaurant.email
				: ""
		},
		address: {
			get: function () {
				return {
					placeId: this.restaurant.external_location_id,
					address: this.restaurant.address,
					city: this.restaurant.city,
					state: this.restaurant.state,
					zip: this.restaurant.zip
				}
			},
			set: function (address) {
				this.restaurant.external_location_id =
					address && address.placeId ? address.placeId : ""
				this.restaurant.address =
					address && address.address ? address.address : ""
				this.restaurant.city =
					address && address.city ? address.city : ""
				this.restaurant.state =
					address && address.state ? address.state : ""
				this.restaurant.zip = address && address.zip ? address.zip : ""
			}
		},
		establishment: {
			get: function () {
				return {
					placeId: this.restaurant.external_establishment_id,
					name: this.restaurant.external_establishment_name
				}
			},
			set: function (establishment) {
				if (establishment) {
					this.restaurant.external_establishment_id =
						establishment.placeId ? establishment.placeId : ""
					this.restaurant.external_establishment_name =
						establishment.name ? establishment.name : ""
					if (
						!this.restaurant.name ||
						this.restaurant.name.length == 0
					) {
						this.restaurant.name = establishment.shortName
							? establishment.shortName
							: ""
					}
					if (!this.address || !this.address.placeId) {
						this.$emit("toggleLoader", true)
						gapi.getPlaceDetails(establishment.placeId).then(
							(result) => {
								this.$emit("toggleLoader", false)
								const addressComponents = result
									? result.address_components
									: null
								const data =
									gapi.parseAddressComponents(
										addressComponents
									)
								data.placeId = establishment.placeId
								this.address = data
							}
						)
					}
				} else {
					this.restaurant.external_establishment_id = null
					this.restaurant.external_establishment_name = ""
				}
			}
		}
	},
	methods: {
		setRestaurantLogo(img) {
			this.$store.dispatch(
				"restaurantsModule/setCurrentRestaurantLogo",
				img
			)
		},
		saveRestaurantInfo() {
			this.updateEmail()
			this.updatePhone()
			this.$emit("save")
		},
		updateEmail() {
			this.emails = this.emails.filter((email) => email.length != 0)
			this.restaurant.email = this.emails.join(";")
		},
		updatePhone() {
			let validPhoneNums = []
			this.phones.forEach((phone) => {
				const nationalNumber = this.$options.filters.internationalPhone(
					phone,
					"+1"
				)
				if (nationalNumber) {
					validPhoneNums.push(nationalNumber)
				}
			})
			validPhoneNums = validPhoneNums.filter((phone) => phone.length != 0)
			this.phones = validPhoneNums
			this.restaurant.phone_number = this.phones.join(";")
		},
		addPhone() {
			this.phones.push("")
		},
		addEmail() {
			this.emails.push("")
		}
	},
	watch: {
		phone: {
			immediate: true,
			handler(newVal) {
				this.phones = newVal.split(";")
			}
		},
		email: {
			immediate: true,
			handler(newVal) {
				this.emails = newVal.split(";")
			}
		}
	},
	beforeDestroy: function () {
		this.updateEmail()
		this.updatePhone()
	}
}
</script>