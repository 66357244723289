<template>
  <div ref="chart"></div>
</template>

<style>
  .ct-grid {
    stroke-dasharray: 0;
    stroke: var(--sk-grey);
  }
  
  .ct-horizontal:not(.ct-label) {
    display: none;
  }

  .ct-label {
    color: var(--sk-grey2);
    font-size: 12px;
  }

  .ct-series-a .ct-bar,
  .ct-series-a .ct-slice-donut {
    stroke: var(--sk-grey);
  }

  .ct-series-b .ct-bar,
  .ct-series-b .ct-slice-donut {
    stroke: var(--sk-orange);
  }

  .ct-series-a .ct-line,
  .ct-series-a .ct-point {
    stroke: var(--sk-orange);
  }

  .ct-series-b .ct-line,
  .ct-series-b .ct-point {
    stroke: var(--sk-grey);
  }

  .ct-bar {
    stroke-width: 4%;
  }

  .ct-point {
    stroke-width: 7px;
    stroke-linecap: round;
  }

  .ct-series-a .ct-slice-donut-solid {
    stroke: var(--sk-grey);
  }

  .ct-series-b .ct-slice-donut-solid {
    stroke: var(--sk-orange);
  }

  .ct-chart-line {
    overflow: visible;
  }

  .ct-chart-line .ct-series-a,
  .ct-chart-line .ct-series-b {
    stroke-dasharray: 5093;
    stroke-dashoffset: 5093;
    animation-name: DrawLine;
    animation-fill-mode: both;
    animation-timing-function: ease-in;
    animation-duration: 1s; 
    fill: none;
  }
  
  .ct-chart-line .ct-series-b {
    animation-delay: 0.5s;
  }


  .ct-slice-donut-animate .ct-slice-donut {
    animation-name: DrawLine;
    animation-fill-mode: forwards;
    animation-duration: 0.5s; 
    stroke-dashArray: 300;
    stroke-dashoffset: -300;
  }

  .ct-line-mini {
    stroke-width: 3px;
  }

  .ct-series-a .ct-area {
    fill: #ff6f00;
    fill-opacity: 0.15;
  }

  .ct-series-b .ct-area {
    fill: var(--sk-grey);
    fill-opacity: 0.15;
  }

  .ct-label.ct-horizontal {
    justify-content: center !important;
  }

  .ct-insights-donut {
    stroke-linecap: round;
  }

  .ct-insights-donut.ct-series-b .ct-slice-donut {
    stroke: var(--sk-orange2);
  }

  .ct-insights-unfilled {
    stroke: rgba(255, 133, 37, 0.2);
  }

  .ct-insights-donut .ct-slice-donut,
  .ct-insights-unfilled .ct-slice-donut {
    fill: var(--sk-white);
  }

  @keyframes DrawLine {
    to { stroke-dashOffset: 0; }
  }
</style>

<script>
import chartist from "chartist"
// eslint-disable-next-line no-unused-vars
import chartistTooltip from "chartist-plugin-tooltips"

export default {
  name: "SkChart",
  props: {
    data: {
      type: Object,
      default() {
        return {
          series: [],
          labels: []
        }
      }
    },
    options: {
      type: Object,
      default() {
        return {}
      }
    },
    type: {
      type: String,
      required: true,
      validator(val) {
        return val === "Pie" || val === "Line" || val === "Bar"
      }
    },
    eventHandlers: {
      type: Array,
      default() {
        return []
      }
    },
    responsiveOptions: {
      type: Array,
      default() {
        return []
      }
    },
    tooltip: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  data () {
    return {
      chart: null,
      error: { onError: false, message: "" },
      noData: false,
      message: "",
      classNoData: ""
    }
  },
  watch: {
    options: { handler: "redraw", deep: true },
    data: { handler: "redraw", deep: true },
    type: "draw",
    eventHandlers: "resetEventHandlers"
  },
  mounted: function() {
    this.draw()
  },
  methods: {
    clear() {
      this.noData = false
      this.message = ""
      if (this.error.onError) {
        this.error = { onError: false, message: "" }
      }
    },
    draw() {
      if (this.haveNoData()) {
        return this.setNoData()
      }
      this.clear()
      if (this.tooltip) {
        this.options.plugins = [ 
          chartist.plugins.tooltip({
            appendToBody: true,
            class: "sk-chart-tooltip",
            transformTooltipTextFnc: this.options && this.options.transformTooltipText ? this.options.transformTooltipText : null
          })
        ]
      }
      this.chart = new chartist[this.type](this.$refs.chart, this.data, this.options, this.responsiveOptions)
      if (this.options.width && this.options.height) {
        this.chart.on("created", () => {
          this.chart.detach()
        })
      }
      this.setEventHandlers()
    },
    haveNoData() {
      return !this.data ||
        !this.data.series ||
        this.data.series.length < 1 ||
        (
          (this.type !== 'Pie' && !this.options.distributeSeries) &&
          this.data.series.every(series => {
            if (Array.isArray(series)) {
              return !series.length
            }
            return !series.data.length
          })
        )
    },
    redraw() {
      if (this.error.onError) {
        return this.draw()
      } else if (this.haveNoData()) {
        return this.setNoData()
      }
      this.clear()
      this.chart.update(this.data, this.options)
    },
    resetEventHandlers(eventHandlers, oldEventHandler) {
      if (!this.chart) {
        return
      }
      for (let item of oldEventHandler) {
        this.chart.off(item.event, item.fn)
      }
      for (let item of eventHandlers) {
        this.chart.on(item.event, item.fn)
      }
    },
    setEventHandlers() {
      if (this.eventHandlers) {
        for (let item of this.eventHandlers) {
          this.chart.on(item.event, item.fn)
        }
      }
    },
    setNoData() {
      this.error = { onError: true }
      this.noData = true
      // this.message = this.error.message
    }
  }
}

</script>