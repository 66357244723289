<template>
	<div class="restaurant-menus">
		<slot v-if="pagedExceptions.length > 0">
			<div class="sk-table sk-table-default">
				<div class="sk-table-header">
					<div class="sk-table-row">
						<div>Status</div>
						<div>Title</div>
						<div>Date</div>
						<div>Time</div>
						<div>Actions</div>
					</div>
				</div>
				<transition-group
					name="list"
					tag="div"
					class="sk-table-body"
					appear
				>
					<RestaurantHoursExceptionLineItem
						v-for="exception in pagedExceptions"
						:key="exception.id"
						:exception="exception"
						@delete="deleteException"
						@edit="editExceptionClicked"
					/>
				</transition-group>
			</div>
			<PaginationFooter
				:contentText="'Closure Hours'"
				:pageSize="20"
				:total="exceptions.length"
				@pageChanged="filterPage"
			/>
		</slot>
		<EmptyPlaceholderWidget
			v-else
			:title="`No Closure Hours`"
			:description="`You don’t have any closure hours yet.`"
			:buttonText="'New Closure Hours'"
			@actionClicked="handlePlaceholderClicked"
		/>
		<ConfirmationModal ref="confirmationModal" />
	</div>
</template>

<style scoped>
.restaurant-menus {
	height: 100%;
	display: flex;
	flex-direction: column;
}
</style>

<script>
import { ConfirmationModalType } from "@/enums/confirmationModalType"
import loaderMixin from "@/mixins/loader-mixin"
import PaginationFooter from "@/components/PaginationFooter.vue"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue"
import EmptyPlaceholderWidget from "@/components/EmptyPlaceholderWidget.vue"
import RestaurantHoursExceptionLineItem from "@/components/restaurant/RestaurantHoursExceptionLineItem.vue"

export default {
    name: "RestaurantHoursExceptions",
    mixins: [loaderMixin],
	components: {
		PaginationFooter,
		ConfirmationModal,
		EmptyPlaceholderWidget,
		RestaurantHoursExceptionLineItem
	},
	data: function() {
		return {
			pageRange: {
				page: 1,
				start: 0,
				end: 20
			}
		}
	},
	computed: {
		restaurant() {
			return this.$store.state.restaurantsModule.currentRestaurant
		},
		exceptions() {
			return this.restaurant
				? this.restaurant.opening_hours_exceptions
				: []
		},
		pagedExceptions() {
			return this.exceptions.slice(
				this.pageRange.start,
				this.pageRange.end
			)
		}
	},
	methods: {
		filterPage(range) {
			this.pageRange = range
		},
		async deleteException(exception) {
			const confirmed = await this.$refs.confirmationModal.open({
				title: `Are you sure you want to delete ${exception.title} closure hours?`,
				confirmation: "Delete Closure Hours",
				confirmationIcon: "sk-icon-trash-alt-solid",
				cancel: "Keep Closure Hours",
				type: ConfirmationModalType.RED
			})
			if (confirmed) {
				this.toggleLoader(true)
				this.$store
					.dispatch(
						"restaurantsModule/removeRestaurantHoursException",
						exception
					)
					.finally(() => {
						this.toggleLoader(false)
					})
			}
		},
		handlePlaceholderClicked() {
			this.$emit("openNewHoursExceptionModal")
		},
		editExceptionClicked(exception) {
			this.$emit("openNewHoursExceptionModal", exception)
		}
	}
}
</script>