<template>
	<div>
		<div class="dashboard-business-title">
			<h2>Serving Today</h2>
			<div v-if="todaysBusinesses.length > 0" class="title-badge">
				{{ todaysBusinesses.length }}
			</div>
			<router-link :to="restaurantMenuLink">View All Menus</router-link>
		</div>
		<RestaurantDashboardBusiness
			v-for="(business, i) in todaysBusinesses"
			:key="business.data.id"
			:business="business.data"
			:menus="business.menus"
			:style="{zIndex: 1000 - i}"
		/>
		<DashboardEmptySection
			v-if="todaysBusinesses.length == 0"
			:title="'No Business Menus to Serve'"
			:message="'No active or upcoming business menus for the remainder of the day. Check your menus to see what you’re expecting tomorrow.'"
			:link="restaurantMenuLink"
			:linkText="'View All Menus'"
		/>
	</div>
</template>

<style scoped>
h2 {
	font-size: 16px;
	color: var(--sk-dark-navy);
	margin-bottom: 0;
}

.dashboard-business-title {
	padding-left: 30px;
	display: flex;
	align-items: center;
	margin-bottom: 26px;
}

.title-badge {
	padding: 5px 7px;
	color: var(--sk-orange);
	background: var(--sk-orange3);
	font-size: 12px;
	font-weight: 600;
	display: flex;
	align-items: center;
	border-radius: 4px;
	margin-left: 15px;
}

.dashboard-business-title a {
	margin-left: auto;
	color: var(--sk-grey3);
	font-size: 10px;
	text-decoration: none;
}

.dashboard-business + .dashboard-business {
	margin-top: 10px;
}

.dashboard-business:last-child {
	margin-bottom: 10px;
}
</style>

<script>
import momentHelper from "@/utils/moment-helper"
import DashboardEmptySection from "@/components/dashboard/DashboardEmptySection.vue"
import RestaurantDashboardBusiness from "@/components/dashboard/restaurant/RestaurantDashboardBusiness.vue"

export default {
	name: "RestaurantDashboardBusinesses",
	components: {
		DashboardEmptySection,
		RestaurantDashboardBusiness
	},
	computed: {
		restaurant() {
			return this.$store.state.restaurantDashboardModule.restaurant
		},
		restaurantMenuLink() {
			if (this.restaurant) {
				return `/restaurant/${this.restaurant.id}?tabId=2`
			}
			return ""
		},
		todaysBusinesses() {
			return Object.values(
				this.$store.state.restaurantDashboardModule.todaysBusinesses
			)
				.filter((business) => {
					return business.menus.some(
						(menu) =>
							menu.data.stock_status == 1 ||
							(menu.schedules &&
								menu.schedules.some(
									(schedule) => schedule.isUpcoming
								))
					)
				})
				.sort((businessA, businessB) => {
					if (businessA.inStock || businessB.inStock) {
						return -1
					}

					let openScheduleA = null
					let openScheduleB = null
					businessA.menus.find((menu) => {
						const schedule = menu.schedules.find(
							(schedule) =>
								schedule.isUpcoming &&
								schedule.rangesFrom == schedule.dow
						)
						if (schedule) {
							openScheduleA = schedule
							return true
						}
					})
					businessB.menus.find((menu) => {
						const schedule = menu.schedules.find(
							(schedule) =>
								schedule.isUpcoming &&
								schedule.rangesFrom == schedule.dow
						)
						if (schedule) {
							openScheduleB = schedule
							return true
						}
					})
					const openTimeANum = openScheduleA
						? momentHelper.numericalTime(openScheduleA.openTime)
						: 0
					const openTimeBNum = openScheduleB
						? momentHelper.numericalTime(openScheduleB.openTime)
						: 0
					return openTimeANum - openTimeBNum
				})
		}
	}
}
</script>