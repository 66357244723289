<template>
	<div class="sk-ancillary-page">
		<h2>Privacy Policy</h2>
		<b>Updated and Effective August August 07, 2019</b>
		<br />

		<p>
			We are concerned about privacy issues and want you to be familiar
			with how we collect, use and disclose Personal Information (as
			defined below). This Privacy Policy describes our practices in
			connection with privacy and the Personal Information that we collect
			through this website (2ndKitchen.com), the related mobile sites and
			mobile application(s), and selected other domains (collectively, the
			"Sites"). Except as indicated below, this Privacy Policy does not
			address the collection, use or disclosure of information by us or by
			third parties on any websites other than the Sites. As used in this
			Agreement, "2K," "we," "us," and "our" shall mean 2ndKitchen Inc. By
			accessing and/or using the Sites, you agree to all the terms and
			conditions of this Privacy Policy. If you do not agree to all the
			terms and conditions of this Privacy Policy, do not use the Sites.
			We may change this Privacy Policy from time to time and without
			prior notice, except that if we make a material change, we will
			notify you prior to the effective date of the change. Changes to
			this Privacy Policy will be effective as soon as they are posted,
			unless a later Effective Date is provided. By continuing to access
			and/or use the Sites after the Effective Date of a revised Privacy
			Policy, you agree to be bound by the revised Privacy Policy. For
			clarity, if you do not agree to the new terms of the Privacy Policy,
			do not continue using the Sites. The most current version of this
			policy will always be posted on the Sites.
			<br />
		</p>

		1. What Types of Information Do We and Our Third Party Service Providers
		Collect From You
		<br />
		<b>Personal Information</b>
		is information such as your name, tied to a postal address, telephone
		number, email address, or other data that, when taken together, can
		uniquely identify you. Personal Information also includes certain
		sensitive information related to your finances or, for business
		accounts, related to the finances of your employer, such as a credit
		card number or other payment account number (including the three (3) or
		four (4) digit validation code for your credit card). In order for you
		to take advantage of the services provided through the Sites, we may
		require that you furnish Personal Information or, for business accounts,
		that your employer furnish Personal Information for you. We and our
		third party service providers collect your Personal Information when you
		voluntarily provide it to us or, for business accounts, when your
		employer provides it to us.
		<br />
		<b>Non-Personal Information</b>
		(or
		<b>"Non-PII"</b>
		) is aggregated information, demographic information and any other
		information that does not uniquely identify you. We and our third party
		service providers may collect your Non-Personal Information, including
		information about the device you are using in accessing and/or using the
		Sites, information from referring websites, and your interaction with
		the Sites. We and our third party service providers may also aggregate
		Personal Information in a manner such that the end-product does not
		uniquely identify you or any other user of the Sites, for example, by
		using Personal Information to calculate the percentage of our users who
		have a particular telephone area code. This collective information
		constitutes Non-Personal Information.
		<br />
		<b>Cookies and Other Technology</b>
		A cookie is a small piece of data that is sent by 2K to your Internet
		browser and stored on your computer's hard drive, and that delivers
		information about you and your activity on the Sites. Pixel tags and web
		beacons are small graphic files that can function in various ways (such
		as allowing us to track how you view an email that we send you, and
		track your activity on the Sites) and are commonly used in conjunction
		with cookies. We, our third party service providers and other third
		parties detailed below under "How Do We and Our Third Party Service
		Providers Use Information Collected From You?" may use cookies, pixel
		tags, web beacons, and other similar technologies to better serve you
		with more tailored information and to facilitate your ongoing use of the
		Sites. If you do not want information collected through the use of
		cookies, there is a simple procedure in most browsers that allows you to
		decline the use of cookies.
		<br />
		<b>How Do We Respond to Do Not Track Signals</b>
		Some browsers allow you to indicate that you would not like your online
		activities tracked, using “Do Not Track” indicators; however we are not
		obligated to respond to these indicators, and we are not currently set
		up to respond to them. This means that we may use latent information
		about your online activities to improve your use of our Sites consistent
		with this policy.
		<b>IP Addresses</b>
		are the Internet Protocol addresses of the computers that you are using.
		Your IP Address is automatically assigned to the computer that you are
		using by your Internet Service Provider (ISP) and is identified and
		logged automatically in our server log files whenever you visit the
		Sites, along with the time(s) of your visit(s) and the page(s) of the
		Sites that you visited. Collecting IP Addresses is standard practice on
		the Internet and is done automatically by many websites.
		<br />
		<b>Location Information.</b>
		We may obtain information about your physical location, such as by use
		of GPS and other geolocation features in your device, or by inference
		from other information we collect. For example, your IP Address
		indicates the general geographic region from which you are connecting to
		the Internet.
		<br />
		2.How Do We and Our Third Party Service Providers Use Information
		Collected From You?
		<br />
		<b>Personal Information.</b>
		We and our third party service providers may use your Personal
		Information and any third party’s Personal Information that you may
		provide, as applicable:
		<ul style="list-style-type: disc">
			<li>
				to process your registration on our Sites, including verifying
				your information to ensure its accuracy and validity.
			</li>
			<li>
				to fulfill your requests (e.g., to allow you to order food
				through our restaurant partners and have it delivered to you, or
				to provide you with information that you request).
			</li>
			<li>
				to fulfill your purchases (e.g., to process credit card payments
				in connection with your food order).
			</li>
			<li>
				to customize content on our Sites (e.g., the restaurants that
				will deliver to your location).
			</li>
			<li>
				to send you important information regarding the Sites, such as
				certain changes to our terms, conditions, policies and/or other
				administrative information. Because this information may be
				material to your use of the Sites, you may not opt out of
				receiving such communications.
			</li>
			<li>
				to inform you of products, programs, services and promotions
				that we believe may be of interest to you and to permit you to
				participate in sweepstakes, contests and similar promotions
				(collectively, "Promotions").
			</li>
			<li>
				to permit you to refer us to your friends so that we may send
				them a promotional offer through the Sites. If you wish to use
				this feature, you may be required to provide us with, and we may
				use, your friend's name and email address. By using this
				functionality, you affirm that you are entitled to use and
				provide us with your friend's name and email address for this
				purpose. We will not use your friend's Personal Information for
				any purpose other than to allow a promotional offer to be sent
				and/or to troubleshoot any issues with respect such promotional
				offer, unless we disclose such other purpose to you at the time
				you provide your friend's Personal Information to us.
			</li>
			<li>
				for our internal business purposes, such as data analysis,
				audits, developing new products, enhancing our website,
				improving our services, identifying usage trends and determining
				the effectiveness of our promotional campaigns.
			</li>
			<li>
				for purposes disclosed at the time you provide your Personal
				Information or as otherwise set forth in this Privacy Policy.
			</li>
		</ul>
		<b>Non-Personal Information.</b>
		Because your Non-Personal Information does not personally identify you,
		we and our third party service providers may use such information for
		any purpose. For example, we may work with third parties for the purpose
		of advertisement delivery on the Sites, including online behavioral
		advertising (“OBA”) and multi-site advertising. The Non-Personal
		Information collected about your visits to the Sites may be used by
		third parties in order to provide advertisements about goods and
		services of interest to you. These third parties may also set cookies or
		web beacons to assist with advertisement delivery services. If you would
		like to obtain more information about these practices, or if you would
		like to opt out of targeted advertising from participating advertisers,
		please visit http://www.networkadvertising.org/choices/ or
		http://www.aboutads.info/choices/.
		<br />
		In addition, we reserve the right to share your Non-Personal Information
		with our affiliates and with other third parties, for any purpose. In
		some instances, we and our third party service providers may combine
		Non-Personal Information with Personal Information. If we do combine any
		Non-Personal Information with Personal Information, the combined
		information will be treated by us as Personal Information hereunder (as
		long as it is so combined).
		<br />

		3. How Do We and Our Third Party Service Providers Share Your Personal
		Information With Others?
		<br />
		We and our third party service providers may disclose Personal
		Information:
		<ul style="list-style-type: disc">
			<li>
				to our affiliates, including Seamless Europe, Ltd., so that they
				also may use such Personal Information for the purposes
				described in this Privacy Policy. 2K is the party responsible
				for the management of the jointly-used Personal Information.
			</li>
			<li>
				to provide services, or to enable third party service providers
				to provide services, such as payment processing, website
				hosting, data analysis, infrastructure provisioning, IT
				services, customer service, email delivery services, and other
				similar services.
			</li>
			<li>
				to third parties such as attorneys, collection agencies,
				tribunals or law enforcement authorities pursuant to valid
				requests in connection with alleged violations of 2K’s Terms of
				Use or other alleged contract violations, actual or alleged
				infringement, actual or suspected harm to persons or property,
				or alleged violations of laws, rules or regulations.
			</li>
			<li>
				to detect, prevent or otherwise address actual or suspected
				fraud, security or technical issues, and to verify your purchase
				to holder(s) of any credit card(s) or other payment account(s)
				used to place order(s) through your account.
			</li>
			<li>
				as required to fulfill your order (e.g., to our restaurant
				partners to enable them to fulfill your order).
			</li>
			<li>
				to identify you to any person to whom you send messages through
				the Sites.
			</li>
			<li>
				to third party sponsors of Promotions (irrespective of whether
				such Promotions are hosted by us), or otherwise in accordance
				with the rules applicable to such Promotions. You should
				carefully review the rules, if any, of each Promotion in which
				you participate, as they may contain additional important
				information about our, a sponsor's and/or other third parties'
				use of your Personal Information. To the extent that the rules
				of those Promotions concerning the treatment of your Personal
				Information conflict with this Privacy Policy, the rules of
				those Promotions shall control.
			</li>
			<li>
				to an affiliate or other third party in the event of any
				reorganization, merger, sale, joint venture, assignment,
				transfer or other disposition of all or any portion of our
				business, assets or stock (including without limitation in
				connection with any bankruptcy and/or similar proceedings).
			</li>
			<li>
				as we believe to be appropriate: (a) under applicable law,
				including laws outside your country of residence; (b) to comply
				with legal process; (c) to respond to requests from public and
				government authorities, including public and government
				authorities outside your country of residence; (d) to enforce
				our terms and conditions; (e) to protect our operations and/or
				those of any of our affiliates; (f) to protect our rights,
				privacy, safety and/or property, and/or that of our affiliates,
				you and/or others; (g) to permit us to pursue available remedies
				and/or limit the damages that we may sustain; and (h) for
				recordkeeping purposes.
			</li>
		</ul>

		4.Other Important Notices Regarding Our Privacy Practices.
		<br />
		<b>Forums and Profiles.</b>
		We and our third party service providers may make available through the
		Sites certain services (for example, message boards, chat functionality,
		"profile pages" and blogs) to which you may be able to post information
		and materials. Please note that any information you provide in
		connection with such services may become public, and may be available to
		visitors to the Sites and to the general public, including without
		limitation the ratings and reviews that accompany many restaurant
		listings and the comments section of our blog posts. We urge you to
		exercise discretion and caution when deciding to disclose your Personal
		Information, and/or any other information and/or materials, on the
		Sites. NEITHER WE, NOR ANY OF OUR THIRD PARTY SERVICE PROVIDERS, NOR ANY
		OTHER PERSON OR ENTITY DULY AUTHORIZED BY US AND/OR BY OUR THIRD PARTY
		SERVICE PROVIDERS, IS OR SHALL BE RESPONSIBLE FOR ANY USE BY ANY
		UNAUTHORIZED PERSON OR ENTITY OF ANY PERSONAL INFORMATION YOU DISCLOSE
		THROUGH THE SITES.
		<br />
		<b>Third Party Sites.</b>
		Except as expressly set forth herein, this Privacy Policy does not
		address, and neither we nor our third party service providers are
		responsible for, the privacy, information or other practices of other
		websites and/or any third parties, including without limitation any of
		our affiliates and/or any third party operating any site to which the
		Sites contains a link. The inclusion of a link to a third party website
		on the Sites does not imply endorsement of the linked site by us or by
		our affiliates. Please note that your access and/or use of any third
		party websites, including by providing any information, materials and/or
		other content to such sites, is entirely at your own risk.
		<br />
		<b>Security.</b>
		We and our third party service providers use reasonable organizational,
		technical and administrative measures to protect Personal Information
		under our control. However, no data transmission over the Internet or
		data storage system can be guaranteed to be 100% secure. Please do not
		send us credit card information and/or other sensitive information
		through email. If you have reason to believe that your interaction with
		us is not secure (for example, if you feel that the security of any
		account you might have with us has been compromised), you must
		immediately notify us of the problem by contacting us in accordance with
		the "Contacting Us" section below.
		<br />
		<b>Unsubscribe.</b>
		If you do not wish to receive marketing-related emails from us, you may
		unsubscribe from receiving them by clicking on the "unsubscribe" link at
		the bottom of any of those emails. You may also unsubscribe from
		receiving order updates via text by replying “STOP”.
		<br />
		<b>Changing Personal Information.</b>
		You may review, correct and/or update certain elements of your Personal
		Information by emailing us at info@2ndKitchen.com. We and our third
		party service providers are not responsible for altering Personal
		Information from the databases and/or other records of third parties
		with whom we and our third party service providers have shared your
		Personal Information. Retention Period. We and our third party service
		providers will retain Personal Information for the period reasonably
		necessary to fulfill the purposes outlined in this Privacy Policy,
		unless a longer retention period is required or permitted by law. Note
		Regarding the Use of the Site by Children. Under no circumstances are
		the Sites directed to and/or intended for use by individuals under the
		age of thirteen (13). Such individuals are prohibited from using the
		Sites and should not provide Personal Information through the Sites.
		<br />
		<b>Disclaimer.</b>
		If you choose to access the Sites, you do so at your own risk, and are
		responsible for complying with all applicable laws, rules and
		regulations. We may limit the availability of the Sites, in whole or in
		part, to any person, geographic area and/or jurisdiction we choose, at
		any time and in our sole discretion. By using the Sites and submitting
		any Personal Information, you consent to the transfer of Personal
		Information to other countries, which may provide a different level of
		data security than your country of residence provides.
		<br />
		<b>California Privacy Rights.</b>
		If you are a user of the Sites and a California resident, California’s
		“Shine the Light” law (California Civil Code 1798.83) permits you to
		request and obtain from us once a year, free of charge, information
		regarding our disclosure of your Personal Information (if any) to third
		parties for direct marketing purposes. If this law applies to you and
		you would like to make such a request, please submit your request in
		writing to the address provided below.
		<br />
		<b>Contacting Us.</b>
		If you have any questions regarding this Privacy Policy, please contact
		us by email at info@2ndKitchen.com, or write to the following address:
		2K Inc. 18 Bartol Street, #1038, San Francisco CA, 94113. Please note
		that email communications will not necessarily be secure; accordingly
		you should not include credit card information and/or other sensitive
		personal information in your email correspondence with us.
		<br />
	</div>
</template>

<script>
export default {
	name: "Privacy"
}
</script>