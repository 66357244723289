<template>
	<div class="sk-popover-wrapper" v-sk-click-outside="clickedOutside" :click-outside-active="model">
		<div class="sk-popover dark-scrollbar" tabindex="0" v-if="model">
			<slot></slot>
		</div>
	</div>
</template>

<style scoped>
.sk-popover-wrapper {
	position: relative;
	width: 100%;
	height: 0;
	display: block !important;
	margin: 0 !important;
	padding: 0 !important;
}

.sk-popover {
	outline: none;
	padding: 20px;
	color: var(--sk-grey3);
	border-radius: 5px;
	overflow: hidden;
	position: absolute;
	background: var(--sk-white);
	box-shadow: 0px 1px 4px rgba(136, 136, 136, 0.25);
	z-index: 800;
	max-height: 300px;
	min-width: 150px;
	overflow-y: auto;
	top: calc(100% + 20px);
	left: 0;
}
</style>

<script>
export default {
	name: "SkPopover",
	props: {
		value: null
	},
	computed: {
		model: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit("input", val)
			}
		}
	},
	methods: {
		clickedOutside() {
			if (this.model) {
				this.model = false
				this.$emit("clickedOutside")
			}
		}
	}
}
</script>