<template>
	<div class="sk-row">
		<div class="sk-widget-alt review-widget">
			<div
				class="review-step-header"
				@click="reviewStep"
				:class="{ expanded: openByDefaultVal || isReviewingStep }"
			>
				<div class="review-step-title">
					<div
						v-if="showCompletion"
						class="review-step-status"
						:class="{
							invalid: invalid,
							complete: complete
						}"
					>
						<i class="sk-icon-exclamation-solid"></i>
						<i class="sk-icon-check-regular"></i>
					</div>
					<h2>{{ stepName }}</h2>
					<span v-if="showProgress">
						{{ progress }}/{{ maxProgress }}
					</span>
				</div>
				<i
					class="flippable sk-icon-chevron-down-regular"
					:class="{ flipped: openByDefaultVal || isReviewingStep }"
				></i>
			</div>
			<transition-expand>
				<div v-if="isReviewingStep" class="review-step-details">
					<div>
						<slot></slot>
					</div>
				</div>
			</transition-expand>
		</div>
		<button class="button button-text" v-if="editable" @click="goToStep">
			EDIT
		</button>
	</div>
</template>

<style scoped>
.review-widget {
	display: flex;
	flex-direction: column;
	padding: 0;
}

.review-step-header {
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
	cursor: pointer;
	padding: 20px;
	height: 63px;
}

.review-step-header.expanded {
	border-bottom: 1px solid var(--sk-grey);
}

.review-step-title {
	display: flex;
	align-items: center;
}

.review-widget + .button-text {
	width: 64px;
	height: 63px;
	flex: 0 !important;
	color: var(--sk-dark-navy);
	font-weight: 600;
	font-size: 10px;
	align-self: flex-start;
}

.review-step-title h2 {
	font-size: 14px;
	font-weight: 600;
	margin: 0 20px 0 0;
	color: var(--sk-dark-navy);
}

.review-step-title span {
	font-size: 14px;
	color: var(--sk-grey2);
}

.review-widget .sk-icon-chevron-down-regular {
	font-size: 14px;
	color: var(--sk-grey2);
}

.review-step-status {
	width: 15px;
	height: 15px;
	font-size: 9px;
	border: 1px solid var(--sk-grey2);
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--sk-white);
	margin-right: 13px;
}

.review-step-status i {
	display: none;
}

.review-step-status.complete {
	background: var(--sk-green);
	border: 0;
}

.review-step-status.invalid .sk-icon-exclamation-solid {
	display: block;
}

.review-step-status.complete .sk-icon-check-regular {
	display: block;
}

.review-step-status.invalid {
	background: var(--sk-red);
	border: 0;
}

.review-step-details > div {
	padding: 20px;
}
</style>

<script>
import TransitionExpand from "@/components/transitions/TransitionExpand.vue"

export default {
	name: "SetupReviewStep",
	components: {
		TransitionExpand
	},
	data() {
		return {
			openByDefaultVal: false
		}
	},
	props: {
		stepName: String,
		stepId: Number,
		editable: {
			type: Boolean,
			default: true
		},
		invalid: Boolean,
		complete: Boolean,
		progress: Number,
		maxProgress: Number,
		reviewingStepId: Number,
		openByDefault: {
			type: Boolean,
			default: false
		},
		showCompletion: {
			type: Boolean,
			default: true
		},
		showProgress: {
			type: Boolean,
			default: true
		}
	},
	computed: {
		isReviewingStep() {
			return this.reviewingStepId == this.stepId || this.openByDefaultVal
		}
	},
	created: function () {
		this.openByDefaultVal = this.openByDefault
	},
	methods: {
		reviewStep() {
			if (this.stepId == this.reviewingStepId) {
				this.openByDefaultVal = false
				this.$emit("reviewStep", null)
			} else {
				if (this.reviewingStepId == null) {
					this.openByDefaultVal = false
				}
				this.$emit("reviewStep", this.stepId)
			}
		},
		goToStep() {
			this.$emit("goToStep", this.stepId)
		}
	}
}
</script>