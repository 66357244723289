
<template>
	<div class="sk-page-content">
		<PageHeader
			:title="'Locations'"
			:tabs="tabs"
			:actions="actions"
			@actionClicked="headerActionClicked"
		/>
		<div class="sk-row">
			<div class="sk-column sales-map">
				<div
					@click="openSearch"
					class="sk-widget-alt sales-map-address"
				>
					<i
						:class="{ empty: addresses.length == 0 }"
						class="sk-icon-map-marker-alt-solid1"
					></i>
					<div
						class="sales-map-addresses"
						v-if="addresses.length > 0"
					>
						<div v-for="(address, i) in addresses" :key="i">
							{{ address }}
						</div>
					</div>
					<div v-else class="sales-map-addresses">
						<div>--</div>
					</div>
					<span>Edit</span>
				</div>
				<SkMap
					:markers="markers"
					:legend="legend"
					@markerClicked="markerClicked"
					@markerHovered="markerHovered"
				/>
			</div>
			<transition-group
				tag="div"
				class="sk-grid dark-scrollbar"
				name="list"
				appear
			>
				<SalesToolResult
					v-for="(result, i) in pagedResults"
					:result="result"
					:selected="selectedResultIndex == i"
					:key="`result-${i}`"
					ref="salesToolResult"
					@click.native="openResultModal(result)"
				/>
				<button
					v-if="hasMoreResults"
					:key="results.length + 1"
					@click="loadMoreResults"
					class="button button-text view-more-button"
				>
					-- View More Results --
				</button>
			</transition-group>
		</div>
		<LocationSearchModal ref="locationSearchModal" />
		<SalesToolResultModal ref="salesToolResultModal" />
	</div>
</template>

<style scoped>
.sales-map,
.sk-grid {
	height: 735px;
}

.sales-map {
	width: 36%;
	flex: 0 0 auto !important;
	margin-right: 36px !important;
}

.sales-map >>> .vue-map {
	height: 640px;
}

.sales-map-addresses {
	color: var(--sk-dark-navy);
	font-weight: 600;
}

.sales-map-address {
	font-size: 14px;
	display: flex;
	align-items: center;
	cursor: pointer;
	max-height: 75px;
}

.sales-map-address i {
	color: var(--sk-orange);
	margin-right: 15px;
}

.sales-map-address i.empty {
	color: var(--sk-grey);
}

.sales-map-address span {
	color: var(--sk-grey2);
	font-size: 12px;
	margin-left: auto;
}

.sk-grid {
	flex: 1;
	margin: 0;
	grid-template-columns: 1fr 1fr;
	grid-auto-rows: min-content;
	border-left: 1px solid var(--sk-grey);
	padding-left: 40px;
	overflow-y: scroll;
}

.view-more-button {
	display: flex;
	grid-column: span 2;
	min-height: 50px;
	align-items: center;
	justify-content: center;
}
</style>
  
<script>
import PageHeader from "@/components/PageHeader.vue"
import loaderMixin from "@/mixins/loader-mixin"
import SalesToolResult from "@/components/sales-tool/SalesToolResult.vue"
import SkMap from "@/components/SkMap.vue"
import LocationSearchModal from "@/components/modals/sales-tools/LocationSearchModal.vue"
import SalesToolResultModal from "@/components/modals/sales-tools/SalesToolResultModal.vue"
import ActionTypes from "@/store/modules/sales/action-types"

export default {
	name: "SalesTool",
	mixins: [loaderMixin],
	components: {
		PageHeader,
		SalesToolResult,
		SkMap,
		LocationSearchModal,
		SalesToolResultModal
	},
	data: function () {
		return {
			selectedResultIndex: null,
			tabs: [
				{
					id: 0,
					text: "Availability",
					active: true
				}
			],
			actions: [
				{
					id: 0,
					text: `<i class="sk-icon-search-regular"></i> Search for Partner`,
					classes: "button button-primary"
				}
			],
			resultsLimit: 8
		}
	},
	computed: {
		addresses() {
			return this.$store.state.salesModule.searchedLocations.map(
				(location) => {
					return location.formattedAddress
				}
			)
		},
		results() {
			return this.$store.state.salesModule.locationResults
		},
		pagedResults() {
			return this.results.slice(0, this.resultsLimit)
		},
		markers() {
			let markers = this.pagedResults.map((result) => {
				const inNetworkIcon = {
					url: "/images/map-marker-green.png",
					size: new google.maps.Size(23, 23),
					scaledSize: new google.maps.Size(23, 23)
				}
				if (result.business) {
					return {
						position: {
							lat: result.business.latitude,
							lng: result.business.longitude
						},
						title: result.business.name,
						icon: inNetworkIcon
					}
				} else if (result.restaurant) {
					return {
						position: {
							lat: result.restaurant.latitude,
							lng: result.restaurant.longitude
						},
						title: result.restaurant.name,
						icon: inNetworkIcon
					}
				} else {
					return {
						position: {
							lat: result.latitude,
							lng: result.longitude
						},
						title: result.name,
						icon: {
							url: "/images/map-marker-navy.png",
							size: new google.maps.Size(23, 23),
							scaledSize: new google.maps.Size(23, 23)
						}
					}
				}
			})

			this.$store.state.salesModule.searchedLocations.forEach(
				(location) => {
					markers.push({
						position: {
							lat: location.latitude,
							lng: location.longitude
						},
						title: location.formattedAddress,
						icon: {
							url: "/images/map-marker-orange.png",
							size: new google.maps.Size(23, 23),
							scaledSize: new google.maps.Size(23, 23)
						}
					})
				}
			)

			return markers
		},
		legend() {
			return [
				{
					text: `<span class="status-dot orange"></span> Input Location`
				},
				{
					text: `<span class="status-dot green"></span> In Network`
				},
				{
					text: `<span class="status-dot navy"></span> Out of Network`
				}
			]
		},
		hasMoreResults() {
			return this.pagedResults.length < this.results.length
		}
	},
	methods: {
		headerActionClicked(action) {
			switch (action.id) {
				case 0: {
					this.openSearch()
					break
				}
			}
		},
		openSearch() {
			this.$refs.locationSearchModal.open()
		},
		openResultModal(result) {
			this.$refs.salesToolResultModal.open({
				result: result
			})
		},
		loadMoreResults() {
			if (this.resultsLimit < this.results.length + 8) {
				this.resultsLimit += 8
			} else {
				this.resultsLimit = this.results.length
			}
		},
		markerClicked(options) {
			this.openResultModal(this.results[options.index])
		},
		markerHovered(options) {
			if (this.selectedResultIndex == options.index) {
				this.selectedResultIndex = null
			} else {
				this.selectedResultIndex = options.index
				const ref = this.$refs.salesToolResult
					? this.$refs.salesToolResult[options.index]
					: null
				const el = ref && ref.$el ? ref.$el : null
				if (el && el.scrollIntoView) {
					el.scrollIntoView({
						behavior: "smooth"
					})
				}
			}
		}
	},
	mounted: function () {
		this.$store.dispatch(`salesModule/${ActionTypes.CLEAR_LOCATIONS}`)
	},
	watch: {
		pagedResults() {
			if (this.pagedResults.length == 0) {
				this.selectedResultIndex = null
			}
		}
	}
}
</script>