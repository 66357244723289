<template>
	<div>
		<div class="dashboard-menus-title">
			<h2>Today's Menus</h2>
			<div v-if="todaysBusinessMenus.length > 0" class="title-badge">
				{{ todaysBusinessMenus.length }}
			</div>
			<router-link :to="businessMenuLink">View All Menus</router-link>
		</div>
		<BusinessDashboardMenu
			v-for="menu in todaysBusinessMenus"
			:key="menu.data.id"
			:menu="menu.data"
			:schedules="menu.schedules"
		/>
		<DashboardEmptySection
			v-if="todaysBusinessMenus.length == 0"
			:title="'No Menus to Serve'"
			:message="'No active or upcoming menus for the remainder of the day. Check your menus to see what you’re expecting tomorrow.'"
			:link="businessMenuLink"
			:linkText="'View All Menus'"
		/>
	</div>
</template>

<style scoped>
h2 {
	font-size: 16px;
	color: var(--sk-dark-navy);
	margin-bottom: 0;
}

.dashboard-menus-title {
	padding-left: 30px;
	display: flex;
	align-items: center;
	margin-bottom: 26px;
}

.title-badge {
	padding: 5px 7px;
	color: var(--sk-orange);
	background: var(--sk-orange3);
	font-size: 12px;
	font-weight: 600;
	display: flex;
	align-items: center;
	border-radius: 4px;
	margin-left: 15px;
}

.dashboard-menus-title a {
	margin-left: auto;
	color: var(--sk-grey3);
	font-size: 10px;
	text-decoration: none;
}

.dashboard-menu + .dashboard-menu {
	margin-top: 10px;
}
</style>

<script>
import momentHelper from "@/utils/moment-helper"
import BusinessDashboardMenu from "@/components/dashboard/business/BusinessDashboardMenu.vue"
import DashboardEmptySection from "@/components/dashboard/DashboardEmptySection.vue"

export default {
	name: "BusinessDashboardMenus",
	components: {
		BusinessDashboardMenu,
		DashboardEmptySection
	},
	computed: {
		business() {
			return this.$store.state.businessDashboardModule.business
		},
		businessMenuLink() {
			if (this.business) {
				return `/business/${this.business.id}`
			}
			return ""
		},
		todaysBusinessMenus() {
			return this.$store.state.businessDashboardModule.todaysBusinessMenus
				.filter(
					(menu) =>
						menu.data.stock_status == 1 ||
						(menu.schedules &&
							menu.schedules.some(
								(schedule) => schedule.isUpcoming
							))
				)
				.sort((menuA, menuB) => {
					if (menuA.data.stock_status == 1 || menuB.data.stock_status == 1) {
						return -1
					}
					const openScheduleA = menuA.schedules.find(
						(schedule) => schedule.rangesFrom == schedule.dow
					)
					const openScheduleB = menuB.schedules.find(
						(schedule) => schedule.rangesFrom == schedule.dow
					)
					const openTimeANum = openScheduleA
						? momentHelper.numericalTime(openScheduleA.openTime)
						: 0
					const openTimeBNum = openScheduleB
						? momentHelper.numericalTime(openScheduleB.openTime)
						: 0
					return openTimeANum - openTimeBNum
				})
		}
	}
}
</script>