import { DeliveryMode } from "@arikgaisler/utils/enums/deliveryMode"
import { BusinessEventType } from "@arikgaisler/utils/enums/businessEventType"

export default class BusinessEvent {
    constructor() {
        this.status = null
        this.type = BusinessEventType.VIRTUAL
        this.image = ""
        this.name = ""
        this.description = ""
        this.contact_email = ""
        this.start_date = null
        this.start_time = null
        this.end_date = null
        this.end_time = null
        this.pre_order_end_date = null
        this.pre_order_end_time = null
        this.business_id = null
        this.restaurant_id = null
        this.menu_id = null
        this.delivery_mode = DeliveryMode.YAY_FOOD_POLE
        this.products = []
    }
}