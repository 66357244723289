<template>
	<div class="sk-table-row">
		<div>
			{{ businessGuest.name }}
		</div>
		<div>
			{{ businessGuest.email }}
		</div>
		<div>
			{{ phone }}
		</div>
		<div>
			{{ check_in_date }}
		</div>
		<div>
			{{ check_out_date }}
		</div>
		<div>
			{{ room }}
		</div>
		<div>
			{{ adminUser }}
		</div>
		<div class="sk-table-column-fit">
			<div class="sk-table-actions">
				<router-link
					tag="button"
					:to="`/users/guest/${businessGuest.id}`"
					class="button button-icon"
					title="View"
				>
					<i class="sk-icon-eye-solid"></i>
				</router-link>
				<SkDropdownSelect
					:options="allowedActions"
					:iconClasses="'sk-icon-ellipsis-v-regular'"
					:showSelectedOption="false"
					:position="'left'"
					@selected="actionSelected"
				/>
			</div>
		</div>
	</div>
</template>


<script>
import moment from "moment"
import phoneFilterMixin from "@/mixins/phone-filter-mixin"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"

export default {
	name: "BusinessGuestLineItem",
	mixins: [phoneFilterMixin],
	components: {
		SkDropdownSelect
	},
	props: {
		businessGuest: Object
	},
	computed: {
		adminUser() {
			if (
				this.businessGuest &&
				this.businessGuest.admin_user_id &&
				this.businessGuest.admin_user_id > 0
			) {
				const adminUser =
					this.$store.getters.adminUsersMap[
						this.businessGuest.admin_user_id
					]
				return adminUser ? adminUser.display_name : "N/A"
			} else {
				return "N/A"
			}
		},
		phone() {
			if (this.businessGuest.phone && this.businessGuest.phone != "") {
				let code = this.businessGuest.phone.startsWith("+")
					? null
					: `+1`
				return this.$options.filters.formatInternationalPhone(
					this.businessGuest.phone,
					code
				)
			} else {
				return "N/A"
			}
		},
		check_in_date() {
			return moment.utc(this.businessGuest.check_in_date).format("MMM D")
		},
		check_out_date() {
			return moment.utc(this.businessGuest.check_out_date).format("MMM D")
		},
		room() {
			return this.businessGuest.room == ""
				? "N/A"
				: this.businessGuest.room
		},
		allowedActions() {
			return [
				{
					text: `<i class="sk-icon-pencil-solid"></i> Edit`,
					id: 3
				},
				{
					text: `<i class="sk-icon-check-regular"></i> Send Digital Menu`,
					id: 0
				},
				{
					text: `<i class="sk-icon-ticket-alt-solid"></i> Send Voucher`,
					id: 2
				},
				{
					text: `<i class="sk-icon-trash-alt-solid"></i> Delete`,
					id: 1
				}
			]
		}
	},
	methods: {
		actionSelected(action) {
			switch (action.id) {
				case 0: {
					this.$emit(
						"sendBusinessGuestDigitalMenu",
						this.businessGuest
					)
					break
				}
				case 1: {
					this.$emit("deleteBusinessGuestClicked", this.businessGuest)
					break
				}
				case 2: {
					this.$emit(
						"sendBusinessGuestVoucherClicked",
						this.businessGuest
					)
					break
				}
				case 3: {
					this.$emit("editBusinessGuestClicked", this.businessGuest)
					break
				}
			}
		}
	}
}
</script>