export const SEARCH_BUSINESS_GUESTS = "SEARCH_BUSINESS_GUESTS"
export const ADD_BUSINESS_GUEST = "ADD_BUSINESS_GUEST"
export const GET_CURRENT_BUSINESS_GUEST = "GET_CURRENT_BUSINESS_GUEST"
export const GET_CURRENT_BUSINESS_GUEST_ORDERS = "GET_CURRENT_BUSINESS_GUEST_ORDERS"
export const UPDATE_BUSINESS_GUEST = "UPDATE_BUSINESS_GUEST"
export const DELETE_BUSINESS_GUEST = "DELETE_BUSINESS_GUEST"
export const SEND_BUSINESS_GUEST_DIGITAL_MENU = "SEND_BUSINESS_GUEST_DIGITAL_MENU"
export const SEND_BUSINESS_GUEST_VOUCHER = "SEND_BUSINESS_GUEST_VOUCHER"


export default {
    SEARCH_BUSINESS_GUESTS,
    ADD_BUSINESS_GUEST,
    UPDATE_BUSINESS_GUEST,
    DELETE_BUSINESS_GUEST,
    GET_CURRENT_BUSINESS_GUEST,
    GET_CURRENT_BUSINESS_GUEST_ORDERS,
    SEND_BUSINESS_GUEST_DIGITAL_MENU,
    SEND_BUSINESS_GUEST_VOUCHER
}