
<template>
	<div class="hub-businesses">
		<CollapsibleSection
			v-for="(businessType, i) in businessTypes"
			:key="i"
			:index="i"
			class="hub-collapsible-section"
			:title="businessType.name"
			:badgeText="businessType.businessCount"
		>
			<div slot="expanded">
				<div class="sk-table sk-table-default">
					<div class="sk-table-header">
						<div class="sk-table-row">
							<div>Business</div>
							<div>Menus</div>
							<div>Hours</div>
							<div>Status</div>
							<div>Actions</div>
						</div>
					</div>
					<div class="sk-table-body">
						<HubBusinessLineItem
							v-for="business in businessType.businesses"
							:key="business.id"
							:business="business"
							@editMenus="editMenus"
						/>
					</div>
				</div>
			</div>
			<div slot="collapsed">
				<div class="sk-pills-container">
					<router-link
						v-for="business in businessType.businesses"
						:key="business.id"
						:to="`/business/${business.slug}`"
						tag="a"
						class="pill pill-grey"
						:title="business.name"
					>
						<i :class="businessType.icon"></i>
						{{ business.name }}
					</router-link>
					<div class="pill pill-grey" @click="addNewBusiness">
						<i class="sk-icon-plus-regular"></i>
						New {{ businessType.name }} Business
					</div>
				</div>
			</div>
		</CollapsibleSection>
		<UpdateHubBusinessMenus ref="updateHubBusinessMenus" />
	</div>
</template>

<style scoped>
.hub-businesses {
	height: 100%;
}

.hub-collapsible-section {
	margin-top: 37px;
}

.sk-table {
	width: 100%;
	margin: 0;
}

.sk-pills-container > .pill.pill-grey {
	max-width: 100%;
}
</style>
  
<script>
import { BusinessType } from "@arikgaisler/utils/enums/businessType"
import loaderMixin from "@/mixins/loader-mixin"
import businessTypeFormatterMixin from "@/mixins/business/business-type-formatter-mixin"
import ActionTypes from "@/store/modules/hubs/action-types"
import CollapsibleSection from "@/components/CollapsibleSection.vue"
import HubBusinessLineItem from "@/components/hubs/HubBusinessLineItem.vue"
import UpdateHubBusinessMenus from "@/components/modals/hubs/UpdateHubBusinessMenus.vue"

export default {
	name: "HubBusinesses",
	mixins: [loaderMixin, businessTypeFormatterMixin],
	components: {
		CollapsibleSection,
		HubBusinessLineItem,
		UpdateHubBusinessMenus
	},
	computed: {
		businessTypes() {
			const businessTypes = {}
			businessTypes[BusinessType.BAR] = []
			businessTypes[BusinessType.HOTEL] = []
			businessTypes[BusinessType.RESIDENTIAL] = []
			businessTypes[BusinessType.COWORKING] = []
			businessTypes[BusinessType.AIRPORT] = []

			const businesses = this.$store.state.hubsModule.currentHubBusinesses
			if (businesses) {
				businesses.forEach((business) => {
					if (business && businessTypes[business.type]) {
						businessTypes[business.type].push(business)
					}
				})
			}

			return [
				{
					name: this.$options.filters.businessTypeName(
						BusinessType.HOTEL
					),
					icon: "sk-icon-bed-empty-solid",
					businesses: businessTypes[BusinessType.HOTEL],
					businessCount: businessTypes[BusinessType.HOTEL].length
				},
				{
					name: this.$options.filters.businessTypeName(
						BusinessType.RESIDENTIAL
					),
					icon: "sk-icon-couch-solid",
					businesses: businessTypes[BusinessType.RESIDENTIAL],
					businessCount:
						businessTypes[BusinessType.RESIDENTIAL].length
				},
				{
					name: this.$options.filters.businessTypeName(
						BusinessType.BAR
					),
					icon: "sk-icon-beer-solid",
					businesses: businessTypes[BusinessType.BAR],
					businessCount: businessTypes[BusinessType.BAR].length
				},
				{
					name: this.$options.filters.businessTypeName(
						BusinessType.COWORKING
					),
					icon: "sk-icon-briefcase-solid",
					businesses: businessTypes[BusinessType.COWORKING],
					businessCount: businessTypes[BusinessType.COWORKING].length
				},
				{
					name: this.$options.filters.businessTypeName(
						BusinessType.AIRPORT
					),
					icon: "sk-icon-plane-engines-solid-1",
					businesses: businessTypes[BusinessType.AIRPORT],
					businessCount: businessTypes[BusinessType.AIRPORT].length
				}
			]
		}
	},
	methods: {
		addNewBusiness() {
			this.$emit("openAddHubBusinessModal")
		},
		editMenus(businessId) {
			this.$refs.updateHubBusinessMenus.open({
				businessId: businessId
			})
		}
	},
	mounted: function () {
		this.toggleLoader(true)
		this.$store
			.dispatch(`hubsModule/${ActionTypes.GET_HUB_BUSINESSES}`)
			.finally(() => {
				this.toggleLoader(false)
			})
	}
}
</script>