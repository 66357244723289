<template>
	<div>
		<div :class="{ 'sk-column': isMobile, 'sk-row': !isMobile }">
			<div>
				<div class="sk-widget order-info">
					<div class="sk-widget-title">Order Totals</div>
					<div class="order-info-subtitle" v-if="orders.length > 0">
						<div>{{ orders.length }}</div>
						<div>{{ ordersPriceTotal | currency }}</div>
					</div>
					<div class="order-info-subtitle" v-else>
						<div>N/A</div>
					</div>
				</div>
			</div>
			<div>
				<div class="sk-widget order-info">
					<div class="sk-widget-title">Delivery Time</div>
					<div class="order-info-subtitle">
						<div>
							{{ deliveryDate | formatEventDate(deliveryTime, businessTimezone, "MMMM Do") }}
						</div>
						<div>
							{{ deliveryDate | timeWithOffset(deliveryTime, businessTimezone) }}
						</div>
					</div>
				</div>
			</div>
			<div>
				<div class="sk-widget order-info">
					<div class="sk-widget-title">Location</div>
					<div class="order-info-subtitle">
						<div>{{ businessAddress }}</div>
					</div>
				</div>
			</div>
			<div>
				<div class="sk-widget order-info" @click="addNotesClicked">
					<div class="sk-widget-title">Notes</div>
					<div
						class="order-info-subtitle"
						v-if="event.manifest_notes"
					>
						<div>{{ event.manifest_notes }}</div>
					</div>
					<div class="order-info-subtitle" v-else>
						<button class="button button-light-grey">
							Add Notes
						</button>
					</div>
				</div>
			</div>
		</div>
		<slot v-if="orders.length > 0">
			<EventOrderLineItem
				v-for="order in orders"
				:key="order.id"
				:order="order"
				:business="business"
				:event="event"
			/>
		</slot>
		<EmptyPlaceholderWidget
			v-else-if="!loader"
			:title="'No Orders'"
			:description="'There aren\'t any orders yet for this event.'"
			:buttonText="'Refresh'"
			:buttonIcon="'sk-icon-sync-regular'"
			@actionClicked="fetchOrders"
		/>
		<AddEventNotesModal ref="addEventNotesModal" />
	</div>
</template>

<style scoped>
.sk-widget-title {
	color: var(--sk-grey3);
	font-size: 14px;
}

.order-info {
	min-height: 100px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.order-info-subtitle {
	display: flex;
	justify-content: space-between;
}

.order-info-subtitle > .button {
	height: 25px;
	min-height: 25px;
	font-size: 12px;
}

.order-info-subtitle > div {
	color: var(--sk-navy);
	font-size: 20px;
	font-weight: 600;
}

.sk-table-default > .sk-table-row > * {
	font-size: 12px;
	color: var(--sk-grey3);
	padding-right: 25px !important;
}
</style>

<script>
import { BusinessEventType } from "@arikgaisler/utils/enums/businessEventType"
import tracker from "@/utils/tracker"
import moment from "moment"
import ActionTypes from "@/store/modules/events/action-types"
import currencyFilterMixin from "@/mixins/currency-filter-mixin"
import loaderMixin from "@/mixins/loader-mixin"
import eventDisplayMixin from "@/mixins/events/event-display-mixin"
import mobileResponsivenessMixin from "@/mixins/mobile-responsiveness-mixin"
import EmptyPlaceholderWidget from "@/components/EmptyPlaceholderWidget.vue"
import EventOrderLineItem from "@/components/events/EventOrderLineItem.vue"
import AddEventNotesModal from "@/components/modals/AddEventNotesModal.vue"

export default {
	name: "EventOrders",
	mixins: [
		currencyFilterMixin,
		loaderMixin,
		eventDisplayMixin,
		mobileResponsivenessMixin
	],
	components: {
		EmptyPlaceholderWidget,
		EventOrderLineItem,
		AddEventNotesModal
	},
	props: {
		event: Object
	},
	computed: {
		business() {
			if (
				this.event.business_id &&
				this.$store.getters.businessesMap &&
				this.$store.getters.businessesMap[this.event.business_id]
			) {
				return this.$store.getters.businessesMap[this.event.business_id]
			}
			return null
		},
		businessAddress() {
			return this.business ? this.business.address : ""
		},
		orders() {
			const orders = this.$store.state.eventsModule.eventOrders
				? this.$store.state.eventsModule.eventOrders
				: []
			return orders.map((order) => {
				const createdTime = moment
					.utc(order.created_time)
					.format("HH:mm:ss")

				const suborder = order.sub_orders[0]
				const prices = {}
				let totalPrice = 0
				let itemCount = 0

				suborder.products.forEach((product) => {
					itemCount++
					const cogs =
						parseFloat(product.cog_item_total_cost)
					const subtotal =
						parseFloat(product.subtotal)
					const metaPrice = product.meta.reduce((total, meta) => {
						return (
							parseFloat(meta.price) *
								parseFloat(product.quantity) +
							parseFloat(total)
						)
					}, 0)
					prices[product.id] = {
						cogs: cogs + metaPrice,
						subtotal: subtotal + metaPrice
					}
					totalPrice += cogs + metaPrice
				})

				return {
					data: order,
					totalPrice: totalPrice,
					prices: prices,
					itemCount: itemCount,
					createdTime: createdTime
				}
			})
		},
		ordersPriceTotal() {
			if (this.orders.length > 0) {
				return this.orders.reduce((total, order) => {
					return total + order.totalPrice
				}, 0)
			}
			return 0
		},
		deliveryDate() {
			switch (this.event.type) {
				case BusinessEventType.VIRTUAL: {
					return this.event.end_date
				}
				case BusinessEventType.POPUP: {
					return this.event.start_date
				}
			}
		},
		deliveryTime() {
			switch (this.event.type) {
				case BusinessEventType.VIRTUAL: {
					return this.event.end_time
				}
				case BusinessEventType.POPUP: {
					return this.event.start_time
				}
			}
		}
	},
	methods: {
		fetchOrders() {
			this.toggleLoader(true)
			this.$store
				.dispatch(`eventsModule/${ActionTypes.GET_EVENT_ORDERS}`)
				.finally(() => {
					this.toggleLoader(false)
				})
		},
		addNotesClicked() {
			tracker.trackEvent("Add Event Notes Clicked")
			this.$refs.addEventNotesModal.open({
				eventId: this.event.id,
				notes: this.event.manifest_notes
			})
		}
	},
	mounted: function () {
		this.fetchOrders()
	}
}
</script>