<template>
	<SkBaseModal
		class="sk-modal-alt"
		:isOpen="isOpen"
		:hasLoader="true"
		@close="close"
	>
		<loading :active.sync="loading" :is-full-page="false" />
		<button class="button button-icon button-close" @click="close">
			<i class="sk-icon-times-regular"></i>
		</button>
		<div class="sk-modal-alt-title">
			<IntegrationLogo :integrationType="integration.type"/>
			<h1>
				{{ integration.name }}
			</h1>
		</div>
		<div class="sk-modal-description">
			To ensure integration removal goes smoothly, 2ndKitchen support will
			assist. Submit this request to start the process.
		</div>
		<div class="sk-row">
			<SkInput
				v-model="removalReason"
				:name="'Reason (Optional)'"
				:multiline="true"
			/>
		</div>
		<div class="sk-modal-actions">
			<button class="button button-text" @click="close">
				Cancel
			</button>
			<button
				class="button button-primary"
				@click="requestIntegrationRemoval"
			>
				<i class="sk-icon-trash-alt-solid"></i>
				Remove Integration
			</button>
		</div>
	</SkBaseModal>
</template>

<script>
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import { IntegrationType } from "@/enums/integrationType"
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import Loading from "vue-loading-overlay"
import SkInput from "@/components/SkInput.vue"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import IntegrationLogo from "@/components/IntegrationLogo.vue"

export default {
	name: "RemoveIntegrationModal",
	mixins: [skModalMixin],
	components: {
		Loading,
		SkBaseModal,
		SkInput,
		IntegrationLogo
	},
	computed: {
		integrationLogoSrc() {
			if (this.integration) {
				switch (this.integration.type) {
					case IntegrationType.UNTAPPD:
						return "/images/untappd-logo.png"
					case IntegrationType.SQUARE:
						return "/images/square-logo.png"
					case IntegrationType.CHOWLY:
						return "/images/chowly-logo.png"
					case IntegrationType.OTTER:
						return "images/otter-logo.png"
					case IntegrationType.REEF:
						return "/images/reef-logo.png"
					case IntegrationType.GRUBHUB:
						return "/images/grubhub-logo.png"
				}
			}
			return ""
		}
	},
	data: function () {
		return {
			integration: {},
			removalReason: "",
			loading: false
		}
	},
	methods: {
		opened() {
			this.loading = false
		},
		handleOptions(options) {
			this.integration = options.integration
		},
		closed() {
			this.loading = false
		},
		async requestIntegrationRemoval() {
			this.loading = true
			const success = await this.$store.dispatch(
				"restaurantsModule/requestRestaurantIntegrationRemoval",
				this.integration
			)
			this.loading = false

			if (success) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Request Submitted",
					message: "Your integration removal request has been sent!"
				})
				this.close()
			} else {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Request Error",
					message: "Something went wrong, please try again",
					type: NotificationType.ERROR
				})
			}
		}
	}
}
</script>