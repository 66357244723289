var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sk-row"},[_c('div',{staticClass:"sk-widget-alt"},[_c('div',{staticClass:"sk-row order-summary",on:{"click":function($event){return _vm.toggleOrderDetails(true)}}},[_c('div',[(_vm.isUpcomingOrder)?_c('div',{staticClass:"pill pill-grey",class:_vm.timeIndicatorClasses},[_c('i',{staticClass:"sk-icon-calendar-alt-solid"}),_vm._v(" "+_vm._s(_vm.preorderDateDay)+" ")]):_c('div',{staticClass:"pill pill-grey",class:_vm.timeIndicatorClasses},[_c('i',{staticClass:"sk-icon-hourglass-half-regular"}),_vm._v(" "+_vm._s(_vm.orderTime)+" ")]),_c('div',{staticClass:"order-identifier"},[_vm._v(_vm._s(_vm.order.id))]),(_vm.isPreOrder)?_c('div',{staticClass:"pill pill-navy"},[_vm._v(" Pre Order ")]):_vm._e(),(_vm.isPopupOrder)?_c('div',{staticClass:"pill pill-navy"},[_vm._v("Popup")]):_vm._e(),(_vm.isRecentLaunch)?_c('div',{staticClass:"pill pill-orange"},[_vm._v(" First Order ")]):_vm._e(),(_vm.showWarningIndicator)?_c('div',{directives:[{name:"sk-tooltip",rawName:"v-sk-tooltip",value:(_vm.warningTooltip),expression:"warningTooltip"}],staticClass:"order-warning-indicator"},[_c('i',{staticClass:"sk-icon-exclamation-triangle-solid"})]):_vm._e()]),_c('div',[_c('div',{staticClass:"order-status-title"},[_vm._v(" "+_vm._s(_vm.orderStatusTitle)+" ")]),_c('div',{staticClass:"order-status-progress"},[_c('div',{class:{
							complete: !_vm.isCancelledOrder,
							danger: _vm.isCancelledOrder
						}}),(_vm.isUpcomingOrder)?_c('div',{class:{
							complete: _vm.received && !_vm.isCancelledOrder,
							danger:
								(!_vm.received && _vm.showDangerIndicator) ||
								_vm.isCancelledOrder
						}}):_vm._e(),_c('div',{class:{
							complete: _vm.accepted && !_vm.isCancelledOrder,
							danger:
								(!_vm.accepted && _vm.showDangerIndicator) ||
								_vm.isCancelledOrder
						}}),_c('div',{class:{
							complete: _vm.acknowledged && !_vm.isCancelledOrder,
							danger:
								(!_vm.acknowledged &&
									_vm.accepted &&
									_vm.showDangerIndicator) ||
								_vm.isCancelledOrder
						}}),_c('div',{class:{
							complete: _vm.pickedUp && !_vm.isCancelledOrder,
							danger:
								(!_vm.pickedUp &&
									_vm.acknowledged &&
									_vm.showDangerIndicator) ||
								_vm.isCancelledOrder
						}}),_c('div',{class:{
							complete: _vm.delivered && !_vm.isCancelledOrder,
							danger:
								(!_vm.delivered &&
									_vm.pickedUp &&
									_vm.showDangerIndicator) ||
								_vm.isCancelledOrder
						}})])]),_c('SkDropdownSelect',{attrs:{"options":_vm.orderActions,"iconClasses":'sk-icon-ellipsis-v-regular',"showSelectedOption":false,"position":'left'},on:{"selected":_vm.orderActionSelected}})],1),_c('transition-expand',[(_vm.isExpanded)?_c('div',[_c('ul',{staticClass:"order-tabs sk-page-tabs"},_vm._l((_vm.orderDetailTabs),function(tab){return _c('li',{key:tab.id,class:{ 'active-tab': tab.active },on:{"click":function($event){return _vm.orderDetailTabClicked(tab)}}},[_vm._v(" "+_vm._s(tab.text)+" "),(tab.badgeCount)?_c('span',{staticClass:"tab-badge"},[_vm._v(" "+_vm._s(tab.badgeCount)+" ")]):_vm._e()])}),0),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.activeDetailsTab.id == 0)?_c('div',_vm._l((_vm.order.sub_orders),function(suborder,index){return _c('div',{key:index},[_c('SuborderHeader',{attrs:{"order":_vm.order,"suborder":suborder,"business":_vm.business,"restaurantId":suborder.rest_id,"expandedSuborder":_vm.expandedSuborder,"index":index},on:{"toggleExpand":function($event){return _vm.expand(suborder)}}}),_c('transition-expand',[(
										(_vm.expandedSuborder &&
											suborder.id ==
												_vm.expandedSuborder.id) ||
										_vm.order.sub_orders.length == 1
									)?_c('CurrentOrderStatus',{attrs:{"item":suborder,"order":_vm.order}}):_vm._e()],1)],1)}),0):_vm._e(),(_vm.activeDetailsTab.id == 1)?_c('div',_vm._l((_vm.order.sub_orders),function(suborder,index){return _c('div',{key:index},[_c('SuborderHeader',{attrs:{"order":_vm.order,"suborder":suborder,"business":_vm.business,"restaurantId":suborder.rest_id,"expandedSuborder":_vm.expandedSuborder,"index":index},on:{"toggleExpand":function($event){return _vm.expand(suborder)}}}),_c('transition-expand',[(
										(_vm.expandedSuborder &&
											suborder.id ==
												_vm.expandedSuborder.id) ||
										_vm.order.sub_orders.length == 1
									)?_c('OrderProducts',{attrs:{"item":suborder,"order":_vm.order,"business":_vm.business}}):_vm._e()],1)],1)}),0):_vm._e(),(_vm.activeDetailsTab.id == 2)?_c('div',[_c('OrderActions',{attrs:{"order":_vm.order,"orderActions":_vm.orderActions},on:{"actionSelected":_vm.orderActionSelected}})],1):_vm._e(),(_vm.activeDetailsTab.id == 3)?_c('div',_vm._l((_vm.suborderWarnings),function(suborder,index){return _c('div',{key:index},[_c('SuborderHeader',{attrs:{"order":_vm.order,"suborder":suborder.suborder,"business":_vm.business,"restaurantId":suborder.suborder.rest_id,"expandedSuborder":_vm.expandedSuborder,"index":index},on:{"toggleExpand":function($event){return _vm.expand(suborder)}}}),_c('transition-expand',[_c('OrderWarnings',{attrs:{"warnings":suborder.warnings}})],1)],1)}),0):_vm._e(),(_vm.activeDetailsTab.id == 4)?_c('div',[_c('OrderNotes',{attrs:{"notes":_vm.notes}}),_c('button',{staticClass:"button button-primary button-add-note",attrs:{"position":"right"},on:{"click":_vm.openNotes}},[_vm._v(" Add Note ")])],1):_vm._e()])],1):_vm._e()])],1),_c('div',{staticClass:"order-assignee"},[_c('button',{staticClass:"sk-widget-alt button button-text",on:{"click":_vm.claimOrder}},[_c('i',{staticClass:"sk-icon-eye-solid"}),_vm._v(" "+_vm._s(_vm.claimedUser)+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }