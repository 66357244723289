import Vue from "vue"
import { store } from "../store/store"
import { parseResponse } from "@arikgaisler/utils/api-utils"

let host = process.env.VUE_APP_API_HOST

const PRICING_API = `https://pricing${host}.2ndkitchen.com`

export default {
    async getCoupons() {
        return parseResponse(Vue.axios.get(`${PRICING_API}/coupons`))
    },
    async updateCoupon(coupon_id, payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.put(`${PRICING_API}/coupons/${coupon_id}`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async addCoupon(payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.post(`${PRICING_API}/coupons`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async deleteCoupon(coupon_id) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.delete(`${PRICING_API}/coupons/${coupon_id}`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getOrdersCouponUses(order_ids) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.get(`${PRICING_API}/coupon_uses?order_ids=${order_ids}`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getOrdersCoupons(order_ids) {
        return parseResponse(Vue.axios.get(`${PRICING_API}/coupons/orders?order_ids=${order_ids}`))
    },
    async getVoucherRequests() {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.get(`${PRICING_API}/voucher_requests`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async addVoucherRequest(payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.post(`${PRICING_API}/voucher_requests`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getVoucherRequest(id) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.get(`${PRICING_API}/voucher_requests/${id}`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getVoucherRequestVouchers(id) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.get(`${PRICING_API}/voucher_requests/${id}/vouchers`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async updateVoucherRequest(id, payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.put(`${PRICING_API}/voucher_requests/${id}`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async deleteVoucherRequest(id) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.delete(`${PRICING_API}/voucher_requests/${id}`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async printVoucher(payload){
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.post(`${PRICING_API}/coupons/generate_file`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async sendVoucherEmail(id,payload){
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.post(`${PRICING_API}/coupons/${id}/send_email`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async updateVoucherRequestStatus(id, payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.put(`${PRICING_API}/voucher_requests/${id}/status`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getVoucherUsage(payload){
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.post(`${PRICING_API}/voucher_requests/usage`, payload, { 'headers': { 'Authorization': tokenStr } }))
	},
	async updateVoucher(payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.post(`${PRICING_API}/coupons/reactivate`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
}