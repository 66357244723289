<template>
	<div class="centered-tab-content">
		<div class="sk-row sk-widget-alt sk-widget-section">
			<div class="sk-widget-section-header">
				<div class="sk-widget-section-title">Bank Account</div>
				<div :class="linkedStatusBadgeClasses">
					<span :class="linkedStatusClasses"></span>
					{{ linkedStatusLabel }}
				</div>
			</div>
			<div v-if="!isLinked" class="sk-widget-section-subtitle">
				Link your bank account with 2ndKitchen.
				<br />
				We use Plaid for a 100% secure and 256-bit encrypted connection.
			</div>
			<slot v-else>
				<div class="sk-widget-section-subtitle">
					We use Plaid for a 100% secure and 256-bit encrypted
					connection.
				</div>
				<div class="sk-widget-section-detail">
					<div>Bank</div>
					<div>{{ account.bank_institution_name }}</div>
				</div>
				<div class="sk-widget-section-detail">
					<div>Account Name</div>
					<div>{{ account.bank_account_name }}</div>
				</div>
				<div
					v-if="account.bank_account_number != ''"
					class="sk-widget-section-detail"
				>
					<div>Account No.</div>
					<div>{{ account.bank_account_number }}</div>
				</div>
				<div class="sk-widget-section-detail">
					<div>Type</div>
					<div>{{ accountType }}</div>
				</div>
			</slot>
			<div class="sk-widget-section-footer">
				<slot v-if="isLinked">
					<button
						class="button button-text button-text-navy button-auto"
						@click="unlinkAccountClicked"
					>
						<i class="sk-icon-unlink-regular"></i>
						Unlink Account
					</button>
				</slot>
				<slot v-else>
					<button
						class="button button-primary button-small"
						@click="linkWithBankCredentials"
					>
						<i class="sk-icon-plus-regular"></i>
						Link Bank Credentials
					</button>
					<button
						class="button button-primary button-small"
						@click="linkWithBankAccount"
					>
						<i class="sk-icon-plus-regular"></i>
						Link Bank Account
					</button>
				</slot>
			</div>
		</div>
		<div class="sk-row sk-widget-alt sk-widget-section">
			<div class="sk-widget-section-header">
				<div class="sk-widget-section-title">W-9</div>
				<div :class="w9StatusBadgeClasses">
					<span :class="w9StatusClasses"></span>
					{{ w9StatusLabel }}
				</div>
			</div>
			<div class="sk-widget-section-subtitle">
				W-9s are required when payouts exceed $700
			</div>
			<div class="sk-widget-section-footer">
				<FileUpload
					v-if="!isW9Uploaded"
					:entity="'restaurant_finance'"
					:entity_name="restaurant.slug"
					:signingBaseUrl="secureSignatureUrl"
					@set="w9FileChanged"
					@loading="toggleLoader"
				>
					<button class="button button-primary button-small">
						<i class="sk-icon-plus-regular"></i>
						Upload W-9
					</button>
				</FileUpload>
				<slot v-else>
					<!-- <button
						class="button button-text button-text-navy button-auto"
						@click="downloadW9"
					>
						<i class="sk-icon-folder-download-regular"></i>
						Download W-9
					</button> -->
					<FileUpload
						v-if="isW9Uploaded"
						class="dropzone-button-text-container"
						:entity="'restaurant_finance'"
						:entity_name="restaurant.slug"
						:signingBaseUrl="secureSignatureUrl"
						@set="w9FileChanged"
						@loading="toggleLoader"
					>
						<button
							class="button button-text button-text-navy button-auto"
						>
							<i class="sk-icon-folder-upload-regular"></i>
							Reupload W-9
						</button>
					</FileUpload>
				</slot>
			</div>
		</div>
		<div class="sk-row sk-widget-alt sk-widget-section">
			<div class="sk-widget-section-header">
				<div class="sk-widget-section-title">Next Payout</div>
			</div>
			<div class="sk-widget-section-detail">
				<div>Payout Range:</div>
				<div>{{ nextPayoutDate }}</div>
			</div>
			<div class="sk-widget-section-detail">
				<div>Payout Amount:</div>
				<div>{{ nextPayoutAmount | currency }}</div>
			</div>
			<div class="sk-widget-section-footer">
				<button
					class="button button-text button-text-navy button-auto"
					@click="payoutHistoryClicked"
				>
					<i class="sk-icon-file-invoice-dollar-solid"></i>
					See Payout History
				</button>
			</div>
		</div>
		<LinkBankAccountModal ref="linkBankAccountModal" />
		<ConfirmationModal ref="removeSourceConfirmationModal" />
	</div>
</template>

<style scoped>
.sk-widget-section-footer
	> .button:not(.button-text)
	+ .button:not(.button-text) {
	margin-left: 15px;
}

.sk-widget-section-footer > .button-text + .button-text,
.sk-widget-section-footer > .button-text + .dropzone-message {
	margin-left: 40px;
}

.sk-widget-section-footer
	>>> .dropzone-button-text-container.dropzone-message
	> div {
	height: 23px;
}
</style>

<script>
import { SECURE_SIGNATURE_API } from "@/services/utils"
import { ConfirmationModalType } from "@/enums/confirmationModalType"
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import ActionTypes from "@/store/modules/finance/action-types"
import moment from "moment"
import loaderMixin from "@/mixins/loader-mixin"
import currencyFilterMixin from "@/mixins/currency-filter-mixin"
import LinkBankAccountModal from "@/components/modals/LinkBankAccountModal.vue"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue"
import FileUpload from "@/components/FileUpload.vue"
import plaidMixin from "@/mixins/finance/plaid-mixin"
import financeDetailsDisplayMixin from "@/mixins/finance/finance-details-display-mixin"

export default {
	name: "PayoutDetailsBasicInfo",
	mixins: [
		currencyFilterMixin,
		loaderMixin,
		plaidMixin,
		financeDetailsDisplayMixin
	],
	components: {
		LinkBankAccountModal,
		ConfirmationModal,
		FileUpload
	},
	mounted: function () {
		this.initPlaid()
	},
	data: function() {
		return {
			secureSignatureUrl: SECURE_SIGNATURE_API
		}
	},
	computed: {
		restaurant() {
			return this.$store.state.restaurants[this.$route.params.entity_id]
		},
		isW9Uploaded() {
			return this.restaurant != null
				? this.restaurant.w9_uploaded == 1
				: false
		},
		nextPayoutDate() {
			var retVal = ""
			const now = moment()
			const dayOfMonth = now.date()
			if (dayOfMonth == 15) retVal = "Today"
			if (dayOfMonth > 15) {
				//    retVal = now.add(1,'M').format('MMM') + ' 1'
				retVal =
					now.format("MMM") +
					" 16 - " +
					now.format("MMM") +
					" " +
					now.daysInMonth()
			}
			if (dayOfMonth < 15)
				retVal =
					now.format("MMM") + " 1 " + "- " + now.format("MMM") + " 15"
			return retVal
		},
		nextPayoutAmount() {
			return this.financeDetails.amount != 0
				? this.financeDetails.amount
				: "N/A"
		}
	},
	methods: {
		onPlaidSuccess(public_token, metadata) {
			this.addRestaurantFundingSource(public_token, metadata)
		},
		addRestaurantFundingSource(token, metadata) {
			this.$store.dispatch(
				`financeModule/${ActionTypes.ADD_FUNDING_SOURCE}`,
				{
					entityId: this.restaurant.id,
					entityType: 2,
					validationType: 2,
					token: token,
					metadata: metadata
				}
			)
		},
		downloadW9() {
			// utils.downloadFile(
			// 	`${this.$financeS3URL}restaurants/${this.restaurant.slug}/w9.pdf`
			// )
		},
		payoutHistoryClicked() {
			this.$emit("showPayoutHistory")
		},
		linkWithBankCredentials() {
			this.plaid_handler.open()
		},
		linkWithBankAccount() {
			this.$refs.linkBankAccountModal.open({
				restaurant_id: this.restaurant.id
			})
		},
		async unlinkAccountClicked() {
			const confirmed = await this.$refs.removeSourceConfirmationModal.open(
				{
					title: `Are you sure you want to unlink your funding account?`,
					confirmation: "Unlink Account",
					confirmationIcon: "sk-icon-trash-alt-solid",
					cancel: "Keep Account",
					type: ConfirmationModalType.RED
				}
			)
			if (confirmed) {
				this.$store.dispatch(
					`financeModule/${ActionTypes.REMOVE_FUNDING_SOURCE}`,
					this.account.id
				)
			}
		},
		async w9FileChanged() {
			const w9Uploaded = await this.$store.dispatch(
				"restaurantsModule/setW9Uploaded",
				this.restaurant.id
			)
			if (w9Uploaded) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "W9 successfully uploaded"
				})
			}
		}
	}
}
</script>