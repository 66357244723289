<template>
	<div class="sk-table-row">
		<div class="sk-table-column-fit">
			{{ requestDate }}
		</div>
		<div
			v-if="showSendToColumn"
			class="short-name text-truncate"
			:title="sendToLabel"
		>
			{{ sendToLabel }}
		</div>
		<div>
			{{ supplyRequest.supply.name }}
		</div>
		<div>
			{{ supplyRequest.quantity }}
		</div>
		<div>
			{{ supplyRequest.total_price | currency }}
		</div>
		<div class="sk-pills-container">
			<div :title="statusLabel" :class="statusBadgeClasses">
				<span :class="statusClasses"></span>
				{{ statusLabel }}
			</div>
		</div>
		<div class="sk-table-column-fit">
			<div class="sk-table-actions">
				<button
					v-if="isCancellable"
					class="button button-icon"
					@click="cancelSupplyRequest"
					title="Cancel"
				>
					<i class="sk-icon-trash-alt-solid"></i>
				</button>
				<SkDropdownSelect
					v-if="allowedActions"
					class="secondary-actions"
					:options="allowedActions"
					:iconClasses="'sk-icon-ellipsis-v-regular'"
					:showSelectedOption="false"
					:position="'left'"
					@selected="actionOptionClicked"
				/>
			</div>
		</div>
	</div>
</template>

<style scoped>
.short-name {
	max-width: 200px;
}
</style>

<script>
import { SupplyRequestStatus } from "@/enums/supplyRequestStatus"
import moment from "moment"
import currencyFilterMixin from "@/mixins/currency-filter-mixin"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"
import { PermissionMethod } from "@/utils/permissions"

export default {
	name: "SupplyRequestLineItem",
	mixins: [currencyFilterMixin],
	components: {
		SkDropdownSelect
	},
	props: {
		supplyRequest: Object
	},
	computed: {
		showSendToColumn() {
			return this.$store.getters.ownsMultipleEntities
		},
		requestDate() {
			return moment.utc(this.supplyRequest.created_date).local().format("MMMM Do YYYY, h:mm a")
		},
		sendToLabel() {
			if (this.supplyRequest.restaurant) {
				const restaurant = this.$store.state.restaurants[
					this.supplyRequest.restaurant.id
				]
				return restaurant ? restaurant.name : "N/A"
			} else if (this.supplyRequest.business) {
				const business = this.$store.getters.businessesMap[
					this.supplyRequest.business.id
				]
				return business ? business.name : "N/A"
			}
		},
		supplyStatus() {
			return this.supplyRequest.status.value
		},
		statusLabel() {
			switch (this.supplyStatus) {
				case SupplyRequestStatus.PENDING:
					return "Requested"
				case SupplyRequestStatus.APPROVED:
					return "Approved"
				case SupplyRequestStatus.REJECTED:
					return "Rejected"
				case SupplyRequestStatus.CANCELLED:
					return "Cancelled"
			}
		},
		statusClasses() {
			switch (this.supplyStatus) {
				case SupplyRequestStatus.PENDING:
					return "status-dot yellow"
				case SupplyRequestStatus.APPROVED:
					return "status-dot green"
				case SupplyRequestStatus.REJECTED:
				case SupplyRequestStatus.CANCELLED:
					return "status-dot red"
			}
		},
		statusBadgeClasses() {
			switch (this.supplyStatus) {
				case SupplyRequestStatus.PENDING:
					return "pill-alt pill-alt-yellow"
				case SupplyRequestStatus.APPROVED:
					return "pill-alt pill-alt-green"
				case SupplyRequestStatus.REJECTED:
				case SupplyRequestStatus.CANCELLED:
					return "pill-alt pill-alt-red"
			}
		},
		isRejectable() {
			return (
				this.$store.state.permissions.includes(
					PermissionMethod.REJECT_SUPPLY_REQUEST
				) && this.supplyStatus == SupplyRequestStatus.PENDING
			)
		},
		isApprovable() {
			return (
				this.$store.state.permissions.includes(
					PermissionMethod.APPROVE_SUPPLY_REQUEST
				) && this.supplyStatus == SupplyRequestStatus.PENDING
			)
		},
		isCancellable() {
			return this.supplyStatus == SupplyRequestStatus.PENDING
		},
		allowedActions() {
			if (this.isApprovable && this.isRejectable) {
				return [
					{
						text: `<i class="sk-icon-check-regular"></i> Approve`,
						id: 0
					},
					{
						text: `<i class="sk-icon-times-regular"></i> Reject`,
						id: 1
					}
				]
			}
			return null
		}
	},
	methods: {
		cancelSupplyRequest() {
			this.$emit("cancel", this.supplyRequest)
		},
		actionOptionClicked(action) {
			switch (action.id) {
				case 0: {
					this.$emit("approve", this.supplyRequest)
					break
				}
				case 1: {
					this.$emit("reject", this.supplyRequest)
					break
				}
			}
		}
	}
}
</script>