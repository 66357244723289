import { takeWhile, filter } from "rxjs/operators"
import { PusherEvent } from "@/enums/pusherEvent"

let pusherMixin = {
	subscriptions: function() {
		return {
			pusherEvents: this.pusher.events
		}
	},
	data: function() {
		return {
			alive: false,
			subscribed: false
		}
	},
	computed: {
		privateChannelName() {
			return `admin_user-${this.$store.state.user_id}`
		}
	},
	methods: {
		subscribeToAdminChannel() {
			const isSuperAdmin = this.$store.getters.isSuperAdmin
			if (isSuperAdmin && !this.pusher.channels.includes("admin")) {
				this.pusher.subscribe("admin")
			}
			if (!this.pusher.channels.includes(this.privateChannelName)) {
				this.pusher.subscribe(this.privateChannelName)
			}
		},
		subscribe(callback) {
			if (this.subscribed) {
				return
			}
			this.subscribed = true
			this.$observables.pusherEvents
				.pipe(takeWhile(() => this.alive))
				.pipe(filter(event => event && event.name))
				.subscribe(event => {
					try {
						const fromPrivateChannel = event.channel == this.privateChannelName
						const subscribedToGeneralChannel = this.pusher.channels.includes("admin")
						const isGeneralEvent = this.isGeneralEvent(event.name)
						if (subscribedToGeneralChannel && fromPrivateChannel && isGeneralEvent) {
							return
						}
						callback(event)
					} catch (e) {
						this.bugsnag.notify(e)
						console.error(e)
					}
				})
		},
		unsubscribe() {
			this.alive = false
			this.subscribed = false
			this.$nextTick(() => {
				this.alive = true
			})
		},
		isGeneralEvent(eventName) {
			if (eventName.includes("pusher:")) {
				return false
			}
			switch (eventName) {
				case PusherEvent.ACTIVITY_ADDED:
					return false
			}
			return true
		}
	},
	created: function() {
		this.alive = true
	},
	beforeDestroy: function() {
		this.subscribed = false
		this.alive = false
	}
}

export default pusherMixin