var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sk-select",class:_vm.classes,attrs:{"tabindex":"0"},on:{"blur":_vm.onBlur,"click":_vm.toggleDropdown}},[_c('div',{staticClass:"selected placeholder"},[(_vm.showSelectedOptions && _vm.selectedOptionsText)?_c('span',[_vm._v(" "+_vm._s(_vm.selectedOptionsText)+" ")]):(_vm.placeholder && _vm.placeholder.text)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.placeholder.text)}}):_vm._e(),_c('i',{staticClass:"dropdown-icon",class:[
				_vm.iconClasses,
				_vm.iconClasses == 'sk-icon-chevron-down-regular' && _vm.open
					? 'flipped'
					: ''
			]})]),_c('div',{ref:"optionsList",staticClass:"options dark-scrollbar",class:{ 'select-hide': !_vm.open },style:(_vm.positionStyle)},_vm._l((_vm.validOptions),function(option){return _c('div',{key:option.id,staticClass:"option",class:{
				selected: _vm.model.includes(option.id)
			}},[_c('div',{staticClass:"sk-row"},[_c('div',{staticClass:"sk-switch-label-wrapper",on:{"click":function($event){return _vm.optionSelected(option, $event)}}},[_c('SkCheckbox',{attrs:{"checked":_vm.model.includes(option.id)},on:{"click":function($event){return _vm.checkboxClicked($event)}}}),_c('div',{staticClass:"option-text",domProps:{"innerHTML":_vm._s(option.text)}})],1)])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }