<template>
	<SkCalendarDay
		@click.native="createEvent"
		:dayText="day.text"
		:totalItems="eventsList.length"
	>
		<template slot="calendar-short-list-item" slot-scope="calendarDay">
			<div
				class="event"
				v-for="event in eventsShortList"
				:class="{
					selected:
						calendarDay.selectedItem &&
						event.id == calendarDay.selectedItem.id
				}"
				:key="event.id"
				@click="calendarDay.selectItem(event)"
			>
				<span :class="event.statusClass"></span>
				<span class="text-truncate">{{ event.businessName }}</span>
			</div>
		</template>
		<template slot="calendar-full-list-item" slot-scope="calendarDay">
			<div
				class="event"
				v-for="event in eventsList"
				:class="{
					selected:
						calendarDay.selectedItem &&
						event.id == calendarDay.selectedItem.id
				}"
				:key="event.id"
				@click="calendarDay.selectItem(event)"
			>
				<span :class="event.statusClass"></span>
				<span class="text-truncate">
					{{ event.businessName }}
				</span>
			</div>
		</template>
		<template slot="calendar-detail" slot-scope="calendarDay">
			<EventCalendarDetail
				:selectedItem="calendarDay.selectedItem"
				:position="calendarDay.position"
				@actionSelected="actionSelected"
				@close="calendarDay.close()"
			/>
		</template>
	</SkCalendarDay>
</template>

<style scoped>
.event {
	font-size: 12px;
	color: var(--sk-grey3);
	background: var(--sk-white);
	border: 1px solid var(--sk-grey);
	border-radius: 5px;
	padding: 2.5px 15px;
	display: flex;
	align-items: center;
	cursor: pointer;
	width: min-content;
	max-width: 100%;
	transition: background 0.25s ease-in, color 0.25s ease-in;
}

.event.selected {
	background: var(--sk-navy);
	color: var(--sk-white);
}

.event:hover {
	filter: brightness(95%);
}

.event + .event {
	margin-top: 2px;
}

.event > .status-dot {
	flex-shrink: 0;
}
</style>

<script>
import { EventStatus } from "@arikgaisler/utils/enums/eventStatus"
import SkCalendarDay from "@/components/SkCalendarDay.vue"
import EventCalendarDetail from "@/components/events/EventCalendarDetail.vue"

export default {
	name: "EventCalendarDay",
	components: {
		SkCalendarDay,
		EventCalendarDetail
	},
	props: {
		day: Object,
		selectedItem: Object
	},
	computed: {
		eventsShortList() {
			if (!this.day.events || this.day.events.length == 0) {
				return []
			}
			return this.day.events.slice(0, 2).map((event) => {
				const businesses = this.$store.getters.businessesMap
				let business = businesses[event.business_id]
				return {
					id: event.id,
					statusClass: this.statusClassForEvent(event),
					businessName: business ? business.name : "N/A"
				}
			})
		},
		eventsList() {
			if (!this.day.events || this.day.events.length == 0) {
				return []
			}
			return this.day.events.map((event) => {
				const businesses = this.$store.getters.businessesMap
				let business = businesses[event.business_id]
				return {
					id: event.id,
					statusClass: this.statusClassForEvent(event),
					businessName: business ? business.name : "N/A"
				}
			})
		}
	},
	methods: {
		statusClassForEvent(event) {
			switch (event.status) {
				case EventStatus.REQUESTED:
					return "status-dot grey"
				case EventStatus.IN_REVIEW:
					return "status-dot yellow"
				case EventStatus.CONFIRMED:
				case EventStatus.ONGOING:
				case EventStatus.PREORDER:
				case EventStatus.PRE_ORDER_FULFILLMENT:
					return "status-dot green"
				case EventStatus.COMPLETED:
					return "status-dot navy"
				case EventStatus.CANCELLED:
					return "status-dot red"
			}
		},
		createEvent(event) {
			if (this.$el == event.target) {
				this.$emit("createEvent", this.day.date)
			}
		},
		selectItem(event) {
			this.$emit("selectItem", event)
		},
		actionSelected(event) {
			this.$emit("actionSelected", event)
		}
	}
}
</script>