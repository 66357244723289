<template>
	<div class="dropzone-message" :class="{'dragging-over': draggedOver}">
		<vue-dropzone
			:id="dropzoneId"
			:options="dropOptions"
			:awss3="awss3"
			:useCustomSlot="true"
			:includeStyling="false"
            @vdropzone-drag-over="dragEnter"
            @vdropzone-drag-leave="dragLeave"
            @vdropzone-drop="drop"
			@vdropzone-success="success"
			@vdropzone-processing="loading(true)"
			@vdropzone-queue-complete="loading(false)"
		>
			<img v-if="imgSrc" class="preview-image" :src="previewImage" />
			<div v-else class="preview-image-placeholder">
				<span>{{ placeholderLogo }}</span>
			</div>
		</vue-dropzone>
		<div>
			<div class="dropzone-title">{{ titleText }}</div>
			<div class="dropzone-recommendation">
				recommended size {{ recommendedSize }}
			</div>
			<vue-dropzone
				:id="dropzoneId"
				:options="dropOptions"
				:awss3="awss3"
				:useCustomSlot="true"
				:includeStyling="false"
				@vdropzone-success="success"
				@vdropzone-processing="loading(true)"
				@vdropzone-queue-complete="loading(false)"
			>
				<button class="button button-outline-navy">
					<i class="sk-icon-camera-solid"></i>
					Change
				</button>
			</vue-dropzone>
			<button
				class="button button-outline-navy remove-image-button"
				@click="removeImage"
			>
				<i class="sk-icon-trash-alt-solid"></i>
				Delete
			</button>
		</div>
	</div>
</template>

<style scoped>
.dz-message {
	display: block;
}

.dz-clickable {
    display: inline-block;
}

* >>> .dz-preview {
	display: none;
}

.dropzone-message {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.preview-image-placeholder,
.preview-image {
	border-radius: 5px;
	width: 115px;
	height: 115px;
	margin-right: 20px;
	object-fit: cover;
    transition: opacity 0.5s ease-in-out;
}

.dragging-over .preview-image-placeholder,
.dragging-over .preview-image {
    opacity: 0.75;
}

.preview-image-placeholder {
	background: var(--sk-navy);
	color: var(--sk-white);
	font-size: 50px;
	font-weight: 600;
	display: flex;
	align-items: center;
	justify-content: center;
}

.dropzone-title,
.dropzone-recommendation {
	text-align: left;
}

.dropzone-title {
	font-size: 14px;
	font-weight: 600;
	color: var(--sk-dark-navy);
}

.dropzone-recommendation {
	color: var(--sk-grey2);
	font-size: 12px;
	margin-bottom: 25px;
}

.button-outline-navy {
	max-height: 27px;
	min-height: 27px;
	height: 27px;
	font-size: 11px;
	padding: 0 15px;
}

.remove-image-button {
	margin-left: 15px;
}
</style>

<script>
import imageUploadMixin from "@/mixins/image-upload-mixin"

export default {
	name: "RestaurantProductImageUpload",
	mixins: [imageUploadMixin],
    data: function() {
        return {
            draggedOver: false
        }
    },
	computed: {
		placeholderLogo() {
			return this.placeholderText
				? this.placeholderText[0].toUpperCase()
				: ""
		}
	},
	props: {
		placeholderText: String
	},
	methods: {
		removeImage() {
			this.reset()
			this.$emit("set", "")
		},
        dragEnter() {
            this.draggedOver = true
        },
        dragLeave() {
            this.draggedOver = false
        },
        drop() {
            this.draggedOver = false
        }
	}
}
</script>