import { MenuType } from "@arikgaisler/utils/enums/menuType"
import { BusinessEventType } from "@arikgaisler/utils/enums/businessEventType"
import ActionTypes from "./action-types"
import MutationTypes from "./mutation-types"
import search_service from "@/services/search"
import business_service from "@/services/businesses"
import restaurant_service from "@/services/restaurants"
import orders_service from "@/services/orders"
import moment from "moment"
import Vue from "vue"
import momentHelper from "@/utils/moment-helper"

const vue = new Vue()

export default {
	async [ActionTypes.GET_DASHBOARD_DATA]({ commit, dispatch, rootGetters }, payload) {
		try {
			commit(MutationTypes.LOADING)
			const businessId = payload && payload.businessId ? payload.businessId : rootGetters.business.id
			await Promise.all([
				dispatch(ActionTypes.FILTER_BY_BUSINESS, businessId),
				dispatch(ActionTypes.GET_RECENT_ORDERS, businessId)
			])
			await Promise.all([
				dispatch(ActionTypes.GET_AVERAGE_RATING),
				dispatch(ActionTypes.GET_TODAYS_MENUS)
			])
			await dispatch(ActionTypes.GET_TODAYS_EVENTS)
			commit(MutationTypes.LOADED)
			return true
		} catch (e) {
			commit(MutationTypes.LOADED)
			vue.bugsnag.notify(
                "Failed to get business dashboard data",
                event => {
                    event.addMetadata("error", {
                        error: e,
						params: payload
                    })
                }
            )
			return false
		}
	},
	async [ActionTypes.FILTER_BY_BUSINESS]({ commit }, businessId) {
		try {
			const response = await business_service.getBusiness(businessId)
			if (response.status == 200) {
				commit(MutationTypes.GOT_BUSINESS, response.data)
			} else {
				throw `API Error: ${response.status}`
			}
			return true
		} catch (e) {
            vue.bugsnag.notify(
                "Failed to get dashboard business",
                event => {
                    event.addMetadata("error", {
                        error: e,
						businessId: businessId
                    })
                }
            )
            return false
        }
	},
	async [ActionTypes.GET_AVERAGE_RATING]({ state, commit }) {
        try {
			const end = moment.utc().hours(0).minutes(0).seconds(0)
			const start = moment
				.utc()
				.hours(0)
				.minutes(0)
				.seconds(0)
				.add(-1, "M")
            let averageOrderScoreFilters = {
                created_time_local_from: start.format(),
                created_time_local_to: end.format()
            }

			averageOrderScoreFilters.business = {
				ids: [state.business.id]
			}

            const response = await search_service.searchAverageOrderScore({
                filters: averageOrderScoreFilters
            })
            if (response.status == 200 && response.data) {
                commit(MutationTypes.GOT_AVERAGE_RATING, response.data.avg_score)
            } else {
				throw `API Error: ${response.status}`
            }
			return true
        } catch (e) {
			commit(MutationTypes.GOT_AVERAGE_RATING, null)
            vue.bugsnag.notify(
                "Failed to get dashboard average rating data",
                event => {
                    event.addMetadata("error", {
                        error: e,
						businessId: state.business ? state.business.id : 0
                    })
                }
            )
            return false
        }
    },
	async [ActionTypes.GET_TODAYS_MENUS]({ state, commit }) {
		try {
			const timezoneOffset = state.business && state.business.timezone_offset ? state.business.timezone_offset : 0
			const availableMenus = []
			const now = moment().utc().add(timezoneOffset, "m")
			const todaysDate = now.format("YYYY-MM-DD")
			const normalizedDow = momentHelper.normalizeDayOfWeek(now)
			const restaurantIds = []
			const restaurantScheduleExceptions = {}
			let menus = state.business && state.business.menus ? state.business.menus : []
			menus = menus.filter(menu => {
				const inStockPopupEvent = menu.stock_status == 1 && menu.type == MenuType.POPUP
				const allowedMenuTypes = [MenuType.DEFAULT, MenuType.ESSENTIALS]
				const validMenu = menu.opening_hours && menu.opening_hours.length > 0 && allowedMenuTypes.includes(menu.type)
				if (inStockPopupEvent || validMenu) {
					if (!restaurantIds.includes(menu.restaurant_id)) {
						restaurantIds.push(menu.restaurant_id)
					}
					return true
				}
			})
			if (restaurantIds.length > 0 && menus.length > 0) {
				const restaurantResponse = await restaurant_service.getRestaurantsByIds(restaurantIds.join(","), "opening_hours_exceptions")
				if (restaurantResponse.status == 200) {
					restaurantResponse.restaurants.forEach((restaurant) => {
						if (restaurant.opening_hours_exceptions && restaurant.opening_hours_exceptions.length > 0) {
							const now = moment.utc().startOf("d")
							restaurantScheduleExceptions[restaurant.id] = restaurant.opening_hours_exceptions.reduce((hourExceptions, hourException) => {
								const startTime = hourException.open_time ? hourException.open_time : "00:00"
								const closeTime = hourException.close_time ? hourException.close_time : "23:59"
								const startDate = moment.utc(`${hourException.start_date} ${startTime}`, "YYYY-MM-DD HH:mm:ss")
								const endDate = moment.utc(`${hourException.end_date} ${closeTime}`, "YYYY-MM-DD HH:mm:ss")
								const localStartDate = startDate.clone()
								const localEndDate = endDate.clone()
								if (now.isBetween(startDate.startOf("d"), endDate.startOf("d"), "days", "[]")) {
									if (hourException.open_time) {
										localStartDate.add(state.business.timezone_offset, "m")
									}
									if (hourException.close_time) {
										localEndDate.add(state.business.timezone_offset, "m")
									}
									const dow = momentHelper.normalizeDayOfWeek(moment.utc().add(state.business.timezone_offset, "m"))
									hourExceptions.push({
										dow: dow,
										openTime: startTime,
										localOpenTime: localStartDate.format("HH:mm:ss"),
										closeTime: closeTime,
										localCloseTime: localEndDate.format("HH:mm:ss"),
										allDay: localStartDate.format("HH:mm") == "00:00" && localEndDate.format("HH:mm") == "23:59",
										schedules: momentHelper.createScheduleFromRange(startTime, closeTime, dow, state.business.timezone_offset)
									})
								}
								return hourExceptions
							}, [])
						}

					})
				}
				menus.forEach(menu => {
					if (menu.type == MenuType.POPUP) {
						availableMenus.push({
							schedules: null,
							data: menu
						})
					} else {
						const openingHoursIndex = menu.opening_hours.findIndex(openingHours => openingHours.day_of_week == normalizedDow)
						if (openingHoursIndex <= -1) {
							return
						}
						let schedule = {
							0: [],
							1: [],
							2: [],
							3: [],
							4: [],
							5: [],
							6: []
						}

						menu.opening_hours.forEach((hour) => {
							const schedules = momentHelper.createScheduleFromRange(hour.open_time, hour.close_time, hour.day_of_week, timezoneOffset)
							for (let scheduleDow in schedules) {
								schedule[scheduleDow] = schedule[scheduleDow].concat(schedules[scheduleDow])
							}
						})

						let dailySchedules = Object.values(schedule).flat().reduce((schedules, schedule) => {
							if (schedule.rangesFrom == normalizedDow || schedule.dow == normalizedDow) {
								schedules.push(schedule)
							}
							return schedules
						}, [])

						if (restaurantScheduleExceptions[menu.restaurant_id] && restaurantScheduleExceptions[menu.restaurant_id].length > 0) {
							restaurantScheduleExceptions[menu.restaurant_id].forEach((hourException) => {
								if (hourException.allDay) {
									dailySchedules = []
								} else {
									dailySchedules = dailySchedules.reduce((schedules, schedulePiece) => {
										if (hourException.schedules[schedulePiece.dow] && hourException.schedules[schedulePiece.dow].length > 0) {
											let adjustedOpeningHours = null
											hourException.schedules[schedulePiece.dow].find((exceptionSchedulePiece) => {
												adjustedOpeningHours = momentHelper.intersectWithinRanges(exceptionSchedulePiece, schedulePiece)
												if (adjustedOpeningHours) {
													return true
												}
												return false
											})
											if (adjustedOpeningHours) {
												schedules.push(Object.assign({}, schedulePiece, {
													openTime: adjustedOpeningHours.openTime,
													closeTime: adjustedOpeningHours.closeTime,
													dow: schedulePiece.dow
												}))
											}
										} else {
											schedules.push(schedulePiece)
										}
										return schedules
									}, [])
								}
							})
						}
						
						dailySchedules.forEach((schedule) => {
							const openMoment = moment.utc(`${todaysDate} ${schedule.openTime}`, "YYYY-MM-DD HH:mm:ss")
							const closeMoment = moment.utc(`${todaysDate} ${schedule.closeTime}`, "YYYY-MM-DD HH:mm:ss")
							if (openMoment.isAfter(now) || now.isBetween(openMoment, closeMoment, "m", "[)")) {
								schedule.isUpcoming = true
							}
						})
	
						if (dailySchedules.length > 0) {
							availableMenus.push({
								schedules: dailySchedules,
								data: menu
							})			
						}
					}
				})
			}

			const defaultMenus = availableMenus.filter(menu => menu.data.is_backup == 0)
			if (defaultMenus.length == 0) {
				const backupMenu = availableMenus.find(menu => menu.data.is_backup)
				if (backupMenu) {
					commit(MutationTypes.GOT_TODAYS_MENUS, [backupMenu])
				} else {
					commit(MutationTypes.GOT_TODAYS_MENUS, [])
				}
			} else {
				commit(MutationTypes.GOT_TODAYS_MENUS, defaultMenus)
			}
		} catch (e) {
			commit(MutationTypes.GOT_TODAYS_MENUS, [])
            vue.bugsnag.notify(
                "Failed to get todays business menus",
                event => {
                    event.addMetadata("error", {
                        error: e,
						businessId: state.business ? state.business.id : 0
                    })
                }
            )
            return false
        }
	},
	async [ActionTypes.GET_TODAYS_EVENTS]({ state, commit }) {
		try {
			const eventMenus = state.todaysBusinessMenus.filter(menu => menu.data.event_id != null)

			if (eventMenus.length > 0) {
				const eventIds = eventMenus.map(menu => menu.data.event_id)
				const response = await business_service.getBusinessEvents(eventIds.join())
				if (response.status == 200 && response.data.events) {
					const popupEvents = response.data.events.filter(event => event.type == BusinessEventType.POPUP)
					commit(MutationTypes.GOT_TODAYS_EVENTS, popupEvents)
				} else {
					throw `API Error: ${response.status}`
				}
			} else {
				commit(MutationTypes.GOT_TODAYS_EVENTS, [])
			}
			return true
		} catch (e) {
			commit(MutationTypes.GOT_TODAYS_EVENTS, [])
			vue.bugsnag.notify(
				"Failed to get todays business events",
				event => {
					event.addMetadata("error", {
						error: e,
						businessId: state.business ? state.business.id : 0
					})
				}
			)
			return false
		}
	},
	async [ActionTypes.GET_RECENT_ORDERS]({ state, commit }, businessId) {
		try {
            const response = await orders_service.getActiveOrders({
				business_ids: businessId
			})
			if (response.status == 200 && response.data.orders) {
				const orders = response.data.orders.slice(0, 3)
				commit(MutationTypes.GOT_RECENT_ORDERS, orders)
			} else {
				throw `API Error: ${response.status}`
			}
			return true
		} catch (e) {
			commit(MutationTypes.GOT_RECENT_ORDERS, [])
			vue.bugsnag.notify(
				"Failed to get recent orders",
				event => {
					event.addMetadata("error", {
						error: e,
						businessId: state.business ? state.business.id : 0
					})
				}
			)
			return false
		}
	}
}