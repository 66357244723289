
<template>
	<div class="sk-page-content">
		<PageHeader
			:title="'Hubs'"
			:tabs="tabs"
			:actions="actions"
			:filters="filters"
			@filterSet="filterSet"
			@actionClicked="headerActionClicked"
			@tabClicked="headerTabClicked"
			@allFiltersReset="filtersReset"
		/>
		<div v-if="hasHubs">
			<CollapsibleSection
				v-for="(hub, i) in hubs"
				:key="hub.id"
				:index="i"
				class="hub-location-section"
				:title="hub.name"
				:badgeText="hub.locationCount"
			>
				<div slot="expanded">
					<div class="sk-table sk-table-default">
						<div class="sk-table-header">
							<div class="sk-table-row">
								<div>Location</div>
								<div>Businesses</div>
								<div>Restaurants</div>
								<div>Menus</div>
								<div>Status</div>
								<div>Actions</div>
							</div>
						</div>
						<div class="sk-table-body">
							<HubLocationLineItem
								v-for="location in hub.locations"
								:key="location.id"
								:location="location"
							/>
						</div>
					</div>
				</div>
				<div slot="collapsed">
					<div class="sk-pills-container">
						<router-link
							v-for="location in hub.locations"
							:key="location.id"
							:to="`/hub/${location.id}`"
							tag="a"
							class="pill pill-grey"
							:title="location.name"
						>
							<i class="sk-icon-map-pin-solid"></i>
							{{ location.name }}
						</router-link>
						<div
							class="pill pill-grey"
							@click="addNewlocationToHub(hub)"
						>
							<i class="sk-icon-plus-regular"></i>
							New {{ hub.city }} Hub
						</div>
					</div>
				</div>
			</CollapsibleSection>
		</div>
		<EmptyPlaceholderWidget
			v-else-if="noHubsFound"
			:title="`No Hubs Found`"
		/>
		<AddHubModal ref="addHubModal" />
	</div>
</template>

<style scoped>
.hub-location-section {
	margin-top: 37px;
}

.sk-table {
	width: 100%;
	margin: 0;
}

.sk-table-default .sk-table-header .sk-table-row > * {
	color: var(--sk-grey3);
}

.sk-pills-container > .pill.pill-grey {
	max-width: 100%;
}
</style>
  
<script>
import ActionTypes from "@/store/modules/hubs/action-types"
import loaderMixin from "@/mixins/loader-mixin"
import PageHeader from "@/components/PageHeader.vue"
import CollapsibleSection from "@/components/CollapsibleSection.vue"
import HubLocationLineItem from "@/components/hubs/HubLocationLineItem.vue"
import EmptyPlaceholderWidget from "@/components/EmptyPlaceholderWidget.vue"
import AddHubModal from "@/components/modals/hubs/AddHubModal.vue"

export default {
	name: "Hubs",
	mixins: [loaderMixin],
	components: {
		PageHeader,
		CollapsibleSection,
		HubLocationLineItem,
		EmptyPlaceholderWidget,
		AddHubModal
	},
	data: function () {
		return {
			activeTabId: 0,
			businessId: 0
		}
	},
	computed: {
		actions() {
			return [
				{
					id: 0,
					text: `<i class="sk-icon-plus-regular"></i> Add New Hub`,
					classes: "button button-primary"
				}
			]
		},
		tabs() {
			return [
				{
					id: 0,
					text: "Locations",
					active: true
				}
			]
		},
		filters() {
			return [
				{
					id: 0,
					type: "search",
					placeholder: "Business",
					width: "280px",
					fetch: (query) => {
						return this.$store.dispatch(
							"businessesModule/autocompleteBusinesses",
							query
						)
					},
					serializer: (i) => {
						return { text: i.name }
					}
				}
			]
		},
		hubs() {
			const hubs = this.$store.state.hubsModule.hubs
			if (hubs) {
				return Object.values(hubs).map((locations) => {
					const location = locations[0]
					return {
						id: location.external_location_id,
						name: `${location.city}, ${location.state}`,
						locationCount: locations ? `${locations.length}` : "",
						city: location.city,
						state: location.state,
						locations: locations
					}
				})
			}
			return []
		},
		hasHubs() {
			return this.hubs && this.hubs.length > 0
		},
		noHubsFound() {
			return !this.hasHubs && !this.loader
		}
	},
	mounted: async function () {
		this.getHubs()
	},
	methods: {
		headerActionClicked() {
			this.$refs.addHubModal.open()
		},
		headerTabClicked(tabClicked) {
			this.activeTabId = tabClicked.id
		},
		addNewlocationToHub(hub) {
			this.$refs.addHubModal.open({
				hub: hub
			})
		},
		filterSet(filter) {
			switch (filter.id) {
				case 0: {
					this.businessId = filter.value ? filter.value.id : null
				}
			}
			this.getHubs()
		},
		filtersReset() {
			this.businessId = 0
			this.getHubs()
		},
		getHubs() {
			if (!this.loader) {
				this.toggleLoader(true)
				this.$store
					.dispatch(`hubsModule/${ActionTypes.GET_HUBS}`, {
						businessId: this.businessId
					})
					.finally(() => {
						this.toggleLoader(false)
					})
			}
		}
	}
}
</script>