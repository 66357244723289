import { DeliveryMode } from "@arikgaisler/utils/enums/deliveryMode"

const clonedeep = require('lodash.clonedeep')
const isEqual = require('lodash.isequal')
const currencyNumberFormatter = new Intl.NumberFormat("en-US", { 
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2, 
})

const numberFormatter = new Intl.NumberFormat("en-US", { 
  maximumFractionDigits: 2, 
})

export default {
  formatCurrency(value) {
    return currencyNumberFormatter.format(value)
  },
  formatNumber(value) {
    return numberFormatter.format(value)
  },
  isObjEmpty(obj) {
    return !obj || Object.keys(obj).length === 0
  },
  stringToRegex(text) {
    const escaped = text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    return new RegExp(escaped, 'gi')
  },
  sanitize(text) {
    return text.replace(/</g, '&lt;').replace(/>/g, '&gt;')
  },
  isInViewport(elem) {
    var bounding = elem.getBoundingClientRect()
    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
  },
  delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  },
  createPromise() {
    let resolve = null
    let reject = null
    const promise = new Promise((res, rej) => {
      resolve = res
      reject = rej
    })
    return {
      promise: promise,
      resolve: resolve,
      reject: reject
    }
  },
  clonedeep(object) {
    return clonedeep(object)
  },
  isObjectEqual(objectA, objectB) {
    return isEqual(objectA, objectB)
  },
  async downloadFiles(filePaths) {
    try {
      for (var i = 0; i < filePaths.length; i++) {
        await this.delay(1000 * i)
        const path = filePaths[i]
        const anchorTag = document.createElement("a")
        anchorTag.target = "_self"
        anchorTag.href = path
        anchorTag.download = path
        anchorTag.style.display = "none"
        document.body.appendChild(anchorTag)
        anchorTag.click()
        await this.delay(100)
        anchorTag.remove()
      }
      return true
    } catch (e) {
      return false
    }
  },
  downloadFile(path) {
    const anchorTag = document.createElement("a")
    anchorTag.target = "_blank"
    anchorTag.href = path
    anchorTag.download = path
    anchorTag.style.display = "none"
    anchorTag.click()
  },
  openPhoneLink: function (phone, code) {
    let number = code ? `${code}${phone}` : phone
    let title = "Toky",
      w = 300,
      h = 500
    var _options = "scrollbars=no,resizable=no"
    var url = "https://app.toky.co/business/dialer#?call=" + number
    // Fixes dual-screen position Most browsers Firefox
    var dualScreenLeft =
      window.screenLeft != undefined ? window.screenLeft : screen.left
    var dualScreenTop =
      window.screenTop != undefined ? window.screenTop : screen.top
    var width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : screen.width
    var height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
        ? document.documentElement.clientHeight
        : screen.height

    var _left = width / 2 - w / 2 + dualScreenLeft
    var _top = height / 2 - h / 2 + dualScreenTop
    var newWindow = window.open(
      url,
      title,
      _options +
      ", width=" +
      w +
      ", height=" +
      h +
      ", top=" +
      _top +
      ", left=" +
      _left
    )

    // Puts focus on the newWindow
    if (window.focus) newWindow.focus()
    return false
	},
	businessModeValue: function (businessType) {
		switch (businessType) {
			case "Direct to Customer":
				return DeliveryMode.DIRECT_TO_CUSTOMER
			case "Room Service":
			case "To Suite" :
			case "To Apartment":
			case "Table Number":
				return DeliveryMode.DIRECT_TO_LOCATION
			case "Lobby Pickup Area":
			case "Pickup Area":
				return DeliveryMode.PICKUP_AREA
			case "Yay Food Pole":
				return DeliveryMode.YAY_FOOD_POLE
			case "Last Yard":
				return DeliveryMode.LAST_YARD
		}
		return 0
	},
  emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
}