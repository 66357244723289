<template>
	<SkBaseModal
		class="voucher-request-modal"
		:isOpen="isOpen"
		:showCloseButton="false"
		:hasLoader="true"
		@close="close"
	>
		<loading :active.sync="loading" :is-full-page="false" />
		<div class="sk-modal-alt-title">
			<h1 v-if="!editMode">Request Vouchers</h1>
			<h1 v-else>
				Edit Voucher Request
				<span class="sk-modal-alt-subtitle" v-if="voucherRequest.name">
					: {{ voucherRequest.name }}
				</span>
			</h1>
		</div>
		<SkModalStepNavbar
			v-if="steps.length > 0"
			:steps="steps"
			:currentStep="currentStep"
			@setStep="setStep"
		/>
		<transition-group name="fade-height" class="voucher-steps">
			<div
				v-if="currentStep == 0"
				class="sk-grid distribution-types-wrapper"
				key="0"
			>
				<div class="sk-widget voucher-type-widget">
					<div>
						<SkRadio
							:option="distributionTypes.PRINT_NOW"
							v-model="distributionType"
						/>
					</div>
					<div class="voucher-type-description">
						<div>Print Now</div>
						<div>
							Sends you an email containing printable voucher
							codes to hand out to guests
						</div>
					</div>
				</div>
				<div class="sk-widget voucher-type-widget">
					<div>
						<SkRadio
							:option="distributionTypes.SEND_TO_ME"
							v-model="distributionType"
						/>
					</div>
					<div class="voucher-type-description">
						<div>Ship to Me</div>
						<div>
							2ndKitchen will create and send vouchers directly to
							your business
						</div>
					</div>
				</div>
				<div class="sk-widget voucher-type-widget">
					<div>
						<SkRadio
							:option="distributionTypes.SEND_TO_GUEST"
							v-model="distributionType"
						/>
					</div>
					<div class="voucher-type-description">
						<div>Send to Guest</div>
						<div>Send Voucher directly to a guest's email</div>
					</div>
				</div>
				<div class="sk-widget voucher-type-widget">
					<div>
						<SkRadio
							:option="distributionTypes.DIGITAL_ONLY"
							v-model="distributionType"
						/>
					</div>
					<div class="voucher-type-description">
						<div>Send Later</div>
						<div>
							Decide how to distribute your vouchers at a later
							date
						</div>
					</div>
				</div>
			</div>
			<div v-show="currentStep == 1" key="1" class="sk-modal-section">
				<div class="sk-row">
					<TypeaheadInput
						:disabled="editMode"
						:placeholder="'Business'"
						:fetch="autocompleteBusinesses"
						:serializer="typeaheadSerializer"
						:query="business.name"
						@selected="businessSelected"
					/>
				</div>
				<div class="sk-row">
					<SkInput v-model="voucherRequest.name" :name="'Title'" />
				</div>
				<div class="sk-row">
					<SkInput
						v-model="voucherRequest.description"
						:name="'Notes'"
						:multiline="true"
					/>
				</div>
				<div class="sk-row" v-if="!editMode">
					<SkInput
						v-model="voucherRequest.amount"
						:name="'Voucher Value'"
						:placeholder="'Voucher Value'"
						:type="'number'"
						:group="'$'"
					/>
				</div>
				<div class="sk-row" v-if="!editMode && !showEmailField">
					<SkInput
						v-model="voucherRequest.quantity"
						:type="'number'"
						:name="'Number of Vouchers'"
					/>
				</div>
				<div class="sk-row">
					<VueCtkDateTimePicker
						v-model="voucherRequestEndDate"
						:label="'Valid Until'"
						:no-clear-button="true"
						:no-label="true"
						:no-button-now="true"
						:only-date="true"
						:button-color="'#90DA36'"
						:format="'MM-DD-YYYY'"
						:formatted="'ll'"
						:color="'#2E3C49'"
						:output-format="'ddd MMM DD, YYYY'"
						:min-date="minStartDate"
						:no-value-to-custom-elem="true"
					>
						<SkInput
							class="optional-field"
							:value="voucherRequestEndDate"
							:disabled="true"
							:name="'Valid Until'"
						/>
					</VueCtkDateTimePicker>
				</div>
				<div class="sk-row">
					<SkDropdownSelect
						ref="typeInput"
						:classes="'sk-input'"
						v-model="voucherRequestMenu"
						:options="menuOptions"
						:clearable="true"
					/>
				</div>
			</div>
			<div
				v-if="currentStep == 2 && !showEmailField"
				key="2"
				class="sk-grid distribution-types-wrapper"
			>
				<div
					class="sk-widget voucher-type-widget"
					v-if="
						voucherRequest.distributionType !=
						distributionTypes.SEND_TO_GUEST
					"
				>
					<div>
						<SkRadio
							:option="paymentTypes.PRE_PAID"
							v-model="paymentType"
						/>
					</div>
					<div class="voucher-type-description">
						<div class="prepay-row">
							<div>Pre Pay</div>
							<div class="discount-label pill pill-navy">
								Save 10%
							</div>
						</div>
						<div>
							Pay in advance for all the requested vouchers. This
							includes a 10% discount
							<br />
							on the total payment (Minimum of 20 vouchers)
						</div>
					</div>
				</div>
				<div class="sk-widget voucher-type-widget">
					<div>
						<SkRadio
							:option="paymentTypes.PAY_AS_YOU_GO"
							v-model="paymentType"
						/>
					</div>
					<div class="voucher-type-description">
						<div>Pay as you Go</div>
						<div>
							Pay only for the vouchers used within the monthly
							billing cycle.
							<br />
							Vouchers will be charged at face value.
						</div>
					</div>
				</div>
				<div class="bottom-text">
					<span>
						Payment will be charged on your next biiling cycle
					</span>
				</div>
			</div>
			<div
				v-if="currentStep == 2 && showEmailField"
				key="2"
				class="sk-modal-section"
			>
				<section
					class="add-user-section"
					v-for="(guest, index) in guestDistributions"
					:key="guest.uuid"
				>
					<div
						class="filter-group-header"
						@click="toggleExpandGroup(index)"
					>
						Guest #{{ index + 1 }}
						<div class="filter-group-divider"></div>
						<i
							class="sk-icon-chevron-down-regular"
							:class="{ flipped: expandedGroupIds[index] }"
						></i>
					</div>
					<transition-expand>
						<div v-if="expandedGroupIds[index]">
							<div class="advanced-filter-row">
								<SkInput
									v-model="guest.quantity"
									:ref="`guestQuantityInput${guest.uuid}`"
									:name="'Number of Vouchers'"
									:type="'number'"
									:required="true"
								/>
							</div>
							<div class="advanced-filter-row">
								<SkInput
									v-model="guest.email"
									:ref="`guestEmailInput${guest.uuid}`"
									:name="'Email'"
									:validation="emailRegex"
									:required="true"
								/>
							</div>
							<div class="advanced-filter-row">
								<SkInput
									v-model="guest.name"
									:ref="`guestNameInput${guest.uuid}`"
									:name="'Name (Optional)'"
									:required="false"
								/>
							</div>
							<div class="advanced-filter-row">
								<SkInput
									v-model="guest.phone"
									class="optional-field"
									:ref="`guestPhoneInput${guest.uuid}`"
									:name="'Phone Number (Optional)'"
									:type="'tel'"
									:required="false"
								/>
							</div>
							<div class="advanced-filter-row">
								<SkInput
									v-model="guest.room"
									class="optional-field"
									:ref="`guestRoomInput${guest.uuid}`"
									:name="'Room (Optional)'"
									:required="false"
								/>
							</div>

							<button
								@click="removeGuestDistribution(guest)"
								class="
									button button-text
									sk-widget-section-text-button-light
								"
							>
								<i class="sk-icon-times-regular"></i>
								Clear Guest
							</button>
						</div>
					</transition-expand>
				</section>

				<div class="sk-row">
					<button
						@click="addNewGuestDistribution(false)"
						class="
							button button-text
							sk-widget-section-text-button-light
						"
					>
						<i class="sk-icon-plus-regular"></i>
						Add Another Guest
					</button>
				</div>
			</div>
			<div v-if="currentStep == 3" key="5" class="sk-modal-section">
				<SetupReviewStep
					:stepName="'Distribution'"
					:stepId="0"
					:invalid="!distributionStepComplete"
					:complete="distributionStepComplete"
					:progress="distributionStepProgress"
					:maxProgress="1"
					:reviewingStepId="reviewingStepId"
					:openByDefault="openByDefault"
					@reviewStep="reviewStep"
					@goToStep="setStep"
				>
					<div
						class="setup-review-step-detail"
						:class="{
							invalid: voucherRequest.distributionType < 0
						}"
					>
						Type
						<b v-sk-unassigned="distributionTypeName"></b>
					</div>
				</SetupReviewStep>
				<SetupReviewStep
					:stepName="'Details'"
					:stepId="1"
					:invalid="!generalStepComplete"
					:complete="generalStepComplete"
					:progress="generalStepProgress"
					:maxProgress="generalMaxProgress"
					:reviewingStepId="reviewingStepId"
					:openByDefault="true"
					@reviewStep="reviewStep"
					@goToStep="setStep"
				>
					<div
						class="setup-review-step-detail"
						:class="{
							invalid: voucherRequest.businessId <= 0
						}"
					>
						Business
						<b v-sk-unassigned="business.name"></b>
					</div>
					<div class="setup-review-step-detail">
						Voucher Title
						<b v-sk-unassigned="voucherRequest.name"></b>
					</div>
					<div class="setup-review-step-detail">
						Voucher Notes
						<b v-sk-unassigned="voucherRequest.description"></b>
					</div>
					<div
						class="setup-review-step-detail"
						:class="{
							invalid: voucherRequest.amount <= 0
						}"
					>
						Voucher Value
						<b v-sk-unassigned="voucherRequest.amount"></b>
					</div>
					<div
						class="setup-review-step-detail"
						:class="{
							invalid: totalQuantity <= 0
						}"
					>
						Number of Vouchers
						<b v-sk-unassigned="totalQuantity"></b>
					</div>
					<div class="setup-review-step-detail">
						Valid Until
						<b
							v-sk-unassigned="voucherRequestEndDateDisplayName"
						></b>
					</div>
					<div class="setup-review-step-detail">
						Valid Menu
						<b v-sk-unassigned="voucherRequestMenuDisplayName"></b>
					</div>
				</SetupReviewStep>
				<SetupReviewStep
					v-if="showEmailField"
					:stepName="'Guests'"
					:stepId="2"
					:invalid="guestDistributionStepInvalid"
					:complete="guestDistributionStepComplete"
					:progress="guestDistributionStepProgress"
					:maxProgress="guestDistributionsAdded.length"
					:reviewingStepId="reviewingStepId"
					:openByDefault="true"
					@reviewStep="reviewStep"
					@goToStep="setStep"
				>
					<div
						v-for="guest in guestDistributionsToReview"
						class="user-step-details"
						:key="guest.uuid"
					>
						<div class="setup-review-step-detail">
							Name:
							<b v-sk-unassigned="guest.name"></b>
						</div>
						<div
							class="setup-review-step-detail"
							:class="{
								invalid: guest.emailInvalid
							}"
						>
							Email:
							<b v-sk-unassigned="guest.email"></b>
						</div>
						<div class="setup-review-step-detail">
							Phone Number:
							<b v-sk-unassigned="guest.phone"></b>
						</div>
						<div class="setup-review-step-detail">
							Room:
							<b v-sk-unassigned="guest.room"></b>
						</div>
						<div
							class="setup-review-step-detail"
							:class="{
								invalid: guest.quantityInvalid
							}"
						>
							Number of Vouchers:
							<b v-sk-unassigned="guest.quantity"></b>
						</div>
					</div>
				</SetupReviewStep>
				<SetupReviewStep
					:stepName="'Payment'"
					:stepId="2"
					:invalid="!paymentStepComplete"
					:complete="paymentStepComplete"
					:progress="paymentStepProgress"
					:maxProgress="1"
					:reviewingStepId="reviewingStepId"
					:openByDefault="true"
					@reviewStep="reviewStep"
					@goToStep="setStep"
				>
					<div
						class="setup-review-step-detail"
						:class="{
							invalid: voucherRequest.paymentType < 0
						}"
					>
						Type
						<b v-sk-unassigned="paymentTypeName"></b>
					</div>
				</SetupReviewStep>
			</div>
		</transition-group>
		<div class="sk-modal-actions">
			<button @click="close" class="button button-text">Close</button>
			<button
				v-if="currentStep != lastStep"
				class="button button-primary"
				@click="nextStep"
			>
				Continue
				<i class="sk-icon-caret-right-solid"></i>
			</button>
			<button
				v-else
				class="button"
				:class="{
					'button-primary': canCreateVoucherRequest,
					'button-grey': !canCreateVoucherRequest
				}"
				@click="saveVoucherRequest"
			>
				Save Voucher Request
			</button>
		</div>
		<ConfirmationModal ref="confirmationModal" />
	</SkBaseModal>
</template>

<style scoped>
.voucher-request-modal >>> .sk-modal-content {
	background: var(--sk-greybg2);
}

.sk-modal-alt-title {
	margin-bottom: 0;
	position: relative;
}

.voucher-steps {
	display: grid;
	grid-template-columns: 1fr;
}

.voucher-steps > div {
	grid-row-start: 1;
	grid-column-start: 1;
}

.voucher-steps > div:not(.distribution-types-wrapper) {
	padding: 5px;
	margin: -5px;
}

.distribution-types-wrapper {
	grid-template-columns: 1fr;
	margin: 20px -5px;
	column-gap: 15px;
}

.voucher-type-widget {
	display: flex;
	align-items: center;
}

.voucher-type-widget >>> .sk-radio {
	width: 20px;
	height: 20px;
	margin-right: 15px;
}

.voucher-type-description > div:first-child {
	font-size: 14px;
	font-weight: 600;
	color: var(--sk-navy);
	margin-bottom: 5px;
}

.voucher-type-description > div:last-child {
	font-size: 12px;
	color: var(--sk-grey2);
}

.sk-modal-actions .button i {
	margin-left: 30px;
	margin-right: 0px;
}

.bottom-text {
	font-style: italic;
	font-weight: normal;
	font-size: 12px;
	line-height: 15px;
	color: var(--sk-grey2) !important;
}

.prepay-row {
	display: flex;
	flex-direction: row;
}
.discount-label {
	padding: 5px 15px;
	height: 23px;
	border-radius: 5px;
	margin-left: 10px;
}

.add-user-section {
	border-bottom: 1px solid var(--sk-grey);
	margin-bottom: 30px;
	padding-bottom: 10px;
}

.sk-modal-section .sk-widget-alt {
	padding: 15px;
}

.filter-group-header {
	font-size: 14px;
	font-weight: 600;
	color: var(--sk-navy);
	display: flex;
	align-items: center;
	width: 100%;
	cursor: pointer;
	margin-top: 18px;
}

.filter-group-header .sk-icon-chevron-down-regular {
	margin-left: 15px;
	font-size: 10px;
	color: var(--sk-grey2);
}

.filter-group-divider {
	height: 1px;
	background: rgba(112, 120, 124, 0.35);
	margin-left: 15px;
}

.advanced-filter-row {
	margin-top: 20px;
}

.user-step-details + .user-step-details {
	padding-top: 20px;
	margin-top: 20px;
	border-top: 1px solid var(--sk-grey);
}

.business-step-detail {
	color: var(--sk-grey2);
	font-size: 12px;
}

.business-step-detail.invalid,
.business-step-detail.invalid .sk-muted-text,
.business-step-detail.invalid b {
	color: var(--sk-red) !important;
}
</style>

<script>
import moment from "moment"
import "moment-timezone"
import { v1 as uuidv1 } from "uuid"
import VoucherRequest from "@/models/VoucherRequest"
import { VoucherDistributionType } from "@/enums/voucherDistributionType"
import { VoucherPaymentType } from "@/enums/voucherPaymentType"
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import { UserRole } from "@/enums/userRole"
import utils from "@/utils/utils"
import business_service from "@/services/businesses"
import ActionTypes from "@/store/modules/coupons/action-types"
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import phoneFilterMixin from "@/mixins/phone-filter-mixin"
import currencyFilterMixin from "@/mixins/currency-filter-mixin"
import eventDisplayMixin from "@/mixins/events/event-display-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import SkInput from "@/components/SkInput.vue"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"
import SkRadio from "@/components/SkRadio.vue"
import SkToggle from "@/components/SkToggle.vue"
import Loading from "vue-loading-overlay"
import TypeaheadInput from "@/components/TypeaheadInput.vue"
import VueCtkDateTimePicker from "vue-ctk-date-time-picker"
import TransitionExpand from "@/components/transitions/TransitionExpand.vue"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue"
import SetupReviewStep from "@/components/modals/SetupReviewStep.vue"
import SkModalStepNavbar from "@/components/modals/SkModalStepNavbar.vue"

export default {
	name: "VoucherRequestModal",
	mixins: [
		skModalMixin,
		currencyFilterMixin,
		eventDisplayMixin,
		phoneFilterMixin
	],
	components: {
		SkBaseModal,
		SkInput,
		SkDropdownSelect,
		SkRadio,
		SkToggle,
		Loading,
		TypeaheadInput,
		VueCtkDateTimePicker,
		TransitionExpand,
		ConfirmationModal,
		SetupReviewStep,
		SkModalStepNavbar
	},
	data: function () {
		return {
			loading: false,
			reviewingStepId: null,
			openByDefault: true,
			paymentTypes: VoucherPaymentType,
			distributionTypes: VoucherDistributionType,
			voucherRequest: new VoucherRequest(),
			currentStep: 0,
			lastStep: 3,
			business: {},
			businessMenus: [],
			seenSteps: [],
			expandedGroupIds: {},
			emailRegex: utils.emailRegex,
			minStartDate: moment().format("YYYY-MM-DD")
		}
	},
	computed: {
		guestDistributions: {
			get: function () {
				return this.voucherRequest.guests
			},
			set: function (guestDistributions) {
				this.voucherRequest.guests = guestDistributions
			}
		},
		totalQuantity() {
			let quantity = 0
			if (
				this.voucherRequest.distributionType ==
				VoucherDistributionType.SEND_TO_GUEST
			) {
				this.voucherRequest.guests.forEach((guest) => {
					if (guest.quantity && guest.quantity > 0) {
						quantity = quantity + guest.quantity
					}
				})
			} else {
				quantity = this.voucherRequest.quantity
			}
			return quantity
		},
		guestDistributionsToReview() {
			return this.guestDistributionsAdded.map((guest) => {
				let emailInvalid = true
				let quantityInvalid = true
				if (guest.email.length > 0) {
					emailInvalid = utils.emailRegex.test(guest.email) == false
				}
				let formattedPhone = guest.phone
				if (guest.phone.length > 0) {
					formattedPhone =
						this.$options.filters.formatInternationalPhone(
							guest.phone
						)
				}
				if (guest.quantity) {
					quantityInvalid = guest.quantity <= 0
				}
				return {
					name: guest.name,
					email: guest.email,
					phone: formattedPhone,
					room: guest.room,
					quantity: guest.quantity,
					emailInvalid: emailInvalid,
					quantityInvalid: quantityInvalid,
					uuid: guest.uuid
				}
			})
		},
		distributionTypeStepComplete() {
			return this.voucherRequest.distributionType >= 0
		},
		generalStepComplete() {
			var validEmail = true
			if (
				this.voucherRequest.distributionType ==
					VoucherDistributionType.SEND_TO_GUEST &&
				this.$refs.emailInput
			) {
				validEmail = this.$refs.emailInput.validate()
			}
			return (
				this.voucherRequest.amount > 0 &&
				this.totalQuantity > 0 &&
				this.business.id > 0 &&
				validEmail
			)
		},
		guestDistributionStepComplete() {
			return (
				this.guestDistributionsAdded.length > 0 &&
				this.guestDistributionStepProgress ==
					this.guestDistributionsAdded.length
			)
		},
		guestDistributionStepInvalid() {
			return this.guestDistributionsToReview.some(
				(guest) => guest.emailInvalid || guest.quantityInvalid
			)
		},
		guestDistributionStepProgress() {
			return this.guestDistributionsToReview.filter(
				(guest) => !guest.emailInvalid && !guest.quantityInvalid
			).length
		},
		paymentStepComplete() {
			return this.voucherRequest.paymentType >= 0
		},
		distributionTypeName() {
			let retVal = ""
			switch (this.voucherRequest.distributionType) {
				case VoucherDistributionType.PRINT_NOW:
					retVal = "Print Now"
					break
				case VoucherDistributionType.SEND_TO_GUEST:
					retVal = "Send to Guest"
					break
				case VoucherDistributionType.SEND_TO_ME:
					retVal = "Ship to Me"
					break
				case VoucherDistributionType.DIGITAL_ONLY:
					retVal = "Send Later"
					break
			}
			return retVal
		},
		showEmailField() {
			return (
				this.voucherRequest.distributionType ==
				VoucherDistributionType.SEND_TO_GUEST
			)
		},
		emailFieldName() {
			if (
				this.voucherRequest.distributionType ==
				VoucherDistributionType.PRINT_NOW
			) {
				return "Receipient Email"
			}
			if (
				this.voucherRequest.distributionType ==
				VoucherDistributionType.SEND_TO_GUEST
			) {
				return "Guest Email"
			}
			return ""
		},
		paymentTypeName() {
			let retVal = ""
			switch (this.voucherRequest.paymentType) {
				case VoucherPaymentType.PRE_PAID:
					retVal = "Pre Pay"
					break
				case VoucherPaymentType.PAY_AS_YOU_GO:
					retVal = "Pay as You Go"
					break
			}
			return retVal
		},
		generalMaxProgress() {
			return this.voucherRequest.distributionType ==
				VoucherDistributionType.SEND_TO_GUEST
				? 7
				: 6
		},
		steps() {
			let allowedSteps = []
			if (this.voucherRequest.id == 0) {
				allowedSteps.push({
					id: 0,
					isActive: this.currentStep == 0,
					isComplete: this.distributionTypeStepComplete,
					text: "Distribution"
				})
				allowedSteps.push({
					id: 1,
					isActive: this.currentStep == 1,
					isComplete: this.generalStepComplete,
					text: "Details"
				})
				if (
					this.voucherRequest.distributionType ==
					VoucherDistributionType.SEND_TO_GUEST
				) {
					allowedSteps.push({
						id: 2,
						isActive: this.currentStep == 2,
						isComplete: this.guestDistributionStepComplete,
						isInvalid: this.guestDistributionStepInvalid,
						text: "Guest Details"
					})
				} else {
					allowedSteps.push({
						id: 2,
						isActive: this.currentStep == 2,
						isComplete: this.paymentStepComplete,
						text: "Payment"
					})
				}
				allowedSteps.push({
					id: 3,
					isActive: this.currentStep == 3,
					isComplete: false,
					text: "Review"
				})
			}

			return allowedSteps
		},
		distributionStepComplete() {
			return this.voucherRequest.distributionType >= 0
		},
		guestEmailValid() {
			if (
				this.voucherRequest.distributionType ==
					VoucherDistributionType.SEND_TO_GUEST &&
				this.$refs.emailInput
			) {
				return this.$refs.emailInput.validate()
			}
			return true
		},
		generalStepProgress() {
			let progress = 0
			if (
				this.voucherRequest.name &&
				this.voucherRequest.name.length > 0
			) {
				progress += 1
			}
			if (
				this.voucherRequest.description &&
				this.voucherRequest.description.length > 0
			) {
				progress += 1
			}
			if (this.voucherRequestEndDate != null) {
				progress += 1
			}
			if (this.voucherRequest.amount > 0) {
				progress += 1
			}
			if (this.voucherRequest.quantity > 0) {
				progress += 1
			}
			if (
				this.voucherRequest.distributionType ==
				VoucherDistributionType.SEND_TO_GUEST
			) {
				progress += 1
			}
			return progress
		},
		distributionStepProgress() {
			let progress = 0
			if (this.voucherRequest.distributionType >= 0) {
				progress += 1
			}
			return progress
		},
		paymentStepProgress() {
			let progress = 0
			if (this.voucherRequest.paymentType >= 0) {
				progress += 1
			}
			return progress
		},
		distributionType: {
			get: function () {
				return this.voucherRequest.distributionType
			},
			set: function (type) {
				this.voucherRequest.distributionType = type
				if (
					this.voucherRequest.distributionType ==
					VoucherDistributionType.SEND_TO_GUEST
				) {
					this.paymentType = VoucherPaymentType.PAY_AS_YOU_GO
				}
			}
		},
		paymentType: {
			get: function () {
				return this.voucherRequest.paymentType
			},
			set: function (type) {
				this.voucherRequest.paymentType = type
			}
		},
		voucherRequestEndDate: {
			get: function () {
				if (this.voucherRequest.endDate) {
					let voucherRequestEndDate = moment(
						this.voucherRequest.endDate,
						"ddd MMM DD, YYYY"
					)
					return voucherRequestEndDate.format("ddd MMM DD, YYYY")
				} else {
					return null
				}
			},
			set: function (date) {
				this.voucherRequest.endDate = date
			}
		},
		voucherRequestEndDateDisplayName() {
			return this.voucherRequestEndDate
				? this.voucherRequestEndDate
				: "N/A"
		},
		menuOptions() {
			const options = [
				{
					id: null,
					text: "Valid Menu <span class='optional-text'>Optional</span>"
				}
			]

			this.businessMenus.forEach((menu) => {
				options.push({
					id: menu.id,
					text: menu.name
				})
			})
			return options
		},
		voucherRequestMenu: {
			get: function () {
				return this.menuOptions.find(
					(menu) => menu.id == this.voucherRequest.menuId
				)
			},
			set: function (value) {
				this.voucherRequest.menuId =
					value && value.id != -1 ? value.id : null
			}
		},
		voucherRequestMenuDisplayName() {
			const menuOption = this.menuOptions.find(
				(menuOption) => menuOption.id == this.voucherRequest.menuId
			)
			if (menuOption) {
				return menuOption.text
			}
			return null
		},
		lastStepButtonText() {
			return this.voucherRequest.id ? "Save" : "Create Voucher Request"
		},
		businessTimezone() {
			if (
				this.business &&
				this.business.timezone != null &&
				this.business.timezone != undefined
			) {
				return this.business.timezone
			}
			return null
		},
		userRole() {
			return this.$store.state.user_role
		},
		editMode() {
			return this.voucherRequest.id > 0
		},
		guestDistributionsAdded() {
			return this.guestDistributions.filter((guest) => {
				return (
					guest.name != "" ||
					guest.phone != "" ||
					guest.email != "" ||
					guest.room != "" ||
					guest.quantity != ""
				)
			})
		},
		emptyGuestDistributions() {
			return this.guestDistributions.filter((guest) => {
				return (
					guest.name == "" &&
					guest.phone == "" &&
					guest.email == "" &&
					guest.room == ""
				)
			})
		},
		canCreateVoucherRequest() {
			return this.steps.every((step) => !step.isInvalid)
		}
	},
	methods: {
		reviewStep(id) {
			this.reviewingStepId = id
		},
		toggleExpandGroup(groupId) {
			if (this.expandedGroupIds[groupId]) {
				this.$set(this.expandedGroupIds, groupId, false)
			} else {
				this.$set(this.expandedGroupIds, groupId, true)
				Object.keys(this.expandedGroupIds).forEach((key) => {
					if (key != groupId) {
						this.$set(this.expandedGroupIds, key, false)
					}
				})
			}
		},
		async handleOptions(options) {
			try {
				if (options.id) {
					if (
						this.userRole == UserRole.BUSINESS_ADMIN &&
						this.$store.getters.business
					) {
						this.currentStep = 1
						this.lastStep = 1
					} else {
						this.currentStep = 1
						this.lastStep = 3
					}
					this.voucherRequest = new VoucherRequest()
					this.voucherRequest.id = options.id
					this.voucherRequest.businessId = options.business
						? options.business.id
						: options.business_id
					this.voucherRequest.paymentType = options.payment_type
					this.voucherRequest.distributionType =
						options.distribution_type
					this.voucherRequest.name = options.name
					this.voucherRequest.description = options.description
					this.voucherRequest.startDate = options.valid_from
						? moment
								.utc(options.valid_from)
								.format("ddd MMM DD, YYYY")
						: null
					this.voucherRequest.endDate = options.valid_to
						? moment
								.utc(options.valid_to)
								.format("ddd MMM DD, YYYY")
						: null
					this.voucherRequest.amount = options.amount
					this.voucherRequest.quantity = options.quantity
					if (options.menu_id) {
						this.voucherRequest.menuId = options.menu_id
							? options.menu_id
							: null
					} else if (options.menu && options.menu.id) {
						this.voucherRequest.menuId = options.menu.id
					}
					this.setBusiness()
				} else {
					this.voucherRequest = new VoucherRequest()
					if (
						this.userRole == UserRole.BUSINESS_ADMIN &&
						this.$store.getters.business
					) {
						this.voucherRequest.businessId =
							this.$store.getters.business.id
						this.setBusiness()
					}
					this.addNewGuestDistribution(true)
				}
				if (options.step) {
					this.setStep(options.step)
				}
			} catch (e) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "An error occurred, please try again.",
					type: NotificationType.ERROR
				})
				this.close()
				throw e
			}
		},
		resetValidation(inputRef) {
			if (this.$refs[inputRef]) {
				if (this.$refs[inputRef].length > 0) {
					this.$refs[inputRef][0].resetValidation()
				} else {
					this.$refs[inputRef].resetValidation()
				}
			}
		},
		newGuest() {
			return {
				name: "",
				email: "",
				phone: "",
				room: "",
				guestID: "",
				quantity: "",
				uuid: uuidv1()
			}
		},
		addNewGuestDistribution(initializing) {
			let invalidGuest =
				!initializing &&
				(!this.guestDistributionsToReview ||
					this.guestDistributionsToReview.length == 0)
			if (
				this.guestDistributionsToReview &&
				this.guestDistributionsToReview.length > 0
			) {
				this.guestDistributionsToReview.forEach((guest) => {
					if (guest.emailInvalid || !guest.email) {
						this.validateInput(`guestEmailInput${guest.uuid}`)
						invalidGuest = true
					}
					if (guest.quantityInvalid || !guest.quantity) {
						this.validateInput(`guestQuantityInput${guest.uuid}`)
						invalidGuest = true
					}
				})
			}
			if (!invalidGuest) {
				const guest = this.newGuest()
				this.guestDistributions.push(guest)
				this.toggleExpandGroup(this.guestDistributions.length - 1)
				this.resetValidation(`guestEmailInput${guest.uuid}`)
				this.resetValidation(`guestQuantityInput${guest.uuid}`)
			}
		},
		removeGuestDistribution(guest) {
			if (this.guestDistributions.length > 1) {
				this.guestDistributions = this.guestDistributions.filter(
					(guestDistribution) => guest.uuid != guestDistribution.uuid
				)
			} else {
				const guest = this.newGuest()
				this.guestDistributions.splice(0, 1, guest)
				this.resetValidation(`guestNameInput${guest.uuid}`)
				this.resetValidation(`guestEmailInput${guest.uuid}`)
				this.resetValidation(`guestPhoneInput${guest.uuid}`)
				this.resetValidation(`guestRoomInput${guest.uuid}`)
				this.resetValidation(`guestQuantityInput${guest.uuid}`)
			}
		},
		closed() {
			this.loading = false
			this.currentStep = 0
			this.business = {}
			this.businessMenus = []
			this.seenSteps = []
			this.voucherRequest = new VoucherRequest()
		},
		nextStep() {
			if (this.currentStep < this.lastStep) {
				if (this.voucherRequest.id == 0) {
					this.setStep(this.currentStep + 1)
				} else {
					if (this.currentStep == 1) {
						this.setStep(this.currentStep + 2)
					} else {
						this.setStep(this.currentStep + 1)
					}
				}
			}
		},
		async setStep(id, event) {
			if (!this.seenSteps.includes(this.currentStep)) {
				this.seenSteps.push(this.currentStep)
			}
			if (event) {
				event.stopPropagation()
			}
			this.currentStep = id
		},
		validateInput(inputRef) {
			if (this.$refs[inputRef]) {
				if (this.$refs[inputRef].length > 0) {
					this.$refs[inputRef][0].validate()
				} else {
					this.$refs[inputRef].validate()
				}
			}
		},
		validateVoucherRequest() {
			try {
				if (!this.voucherRequest.businessId) {
					throw "A business is required when creating a voucher request"
				}
				if (
					this.voucherRequest.quantity < 20 &&
					this.voucherRequest.paymentType ==
						VoucherPaymentType.PRE_PAID
				) {
					throw "There is a 20 voucher minimum for Pre Paid"
				}
				if (!this.canCreateVoucherRequest) {
					throw "Please fill in all mandatory fields"
				}
				return true
			} catch (e) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: e.message
						? "Something went wrong, please try again."
						: e,
					type: NotificationType.WARNING
				})
				throw e
			}
		},
		async saveVoucherRequest() {
			try {
				this.loading = true
				this.validateVoucherRequest()
				if (this.voucherRequest.endDate) {
					let tempEndDateMoment = moment.tz(
						this.voucherRequest.endDate,
						"ddd MMM DD, YYYY",
						this.businessTimezone
					)
					this.voucherRequest.endDate = tempEndDateMoment
						.utc()
						.format("YYYY-MM-DD HH:mm:ss")
				} else {
					this.voucherRequest.endDate = null
				}
				if (
					this.voucherRequest.distributionType ==
					VoucherDistributionType.SEND_TO_GUEST
				) {
					this.voucherRequest.quantity = this.totalQuantity
				} else {
					this.voucherRequest.guests = []
				}
				let success = false
				const voucherRequestPayload = VoucherRequest.convertToPayload(
					this.voucherRequest
				)
				let menuData = null
				if (
					this.voucherRequestMenu &&
					this.voucherRequestMenu.id != null
				) {
					menuData = {
						id: this.voucherRequestMenu.id,
						name: this.voucherRequestMenu.text
					}
				}
				if (this.voucherRequest.id > 0) {
					success = await this.$store.dispatch(
						`couponsModule/${ActionTypes.UPDATE_VOUCHER_REQUEST}`,
						{
							voucherRequest: voucherRequestPayload,
							menu: menuData
						}
					)
				} else {
					success = await this.$store.dispatch(
						`couponsModule/${ActionTypes.ADD_VOUCHER_REQUEST}`,
						{
							voucherRequest: voucherRequestPayload,
							menu: menuData
						}
					)
				}
				if (success) {
					this.close()
				}
				this.loading = false
			} catch (e) {
				this.loading = false
			}
		},
		autocompleteBusinesses(query) {
			return this.$store.dispatch(
				"businessesModule/autocompleteBusinesses",
				query
			)
		},
		typeaheadSerializer(result) {
			let hasAddress =
				result.address && result.city && result.state && result.zip
			return {
				text: result.name,
				description: hasAddress
					? `${result.address} <br> ${result.city}, ${result.state} ${result.zip}`
					: null
			}
		},
		setBusiness() {
			const businesses = this.$store.getters.businessesMap
			if (this.voucherRequest.businessId && !this.business.id) {
				const business = businesses[this.voucherRequest.businessId]
				this.business = business ? utils.clonedeep(business) : {}
				this.getBusinessMenus()
			}
		},
		businessSelected(business) {
			this.business = business
			this.voucherRequest.businessId = business.id
			this.getBusinessMenus()
		},
		getBusinessMenus() {
			if (this.business) {
				this.loading = true
				business_service
					.getBusiness(this.business.slug, "menus")
					.then((response) => {
						if (response.data && response.data.menus) {
							this.businessMenus = response.data.menus
						}
					})
					.finally(() => {
						this.loading = false
					})
			}
		}
	}
}
</script>