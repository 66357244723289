
import Vue from "vue"
import Vuex from "vuex"
import VuexPersistence from "vuex-persist"
import actions from "./actions"
import mutations from "./mutations"
import getters from "./getters"

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    reducer: (state) => {
        return {
            defaultMURs: state.defaultMURs
        }
    }
})

const state = {
    loading: false,
    updating: false,
    business: null,
    businesses: {},
    business_employees: [],
    currentBusiness: {},
    currentBusinessRestaurants: [],
    currentBusinessRestaurantMenus: {},
    currentBusinessRestaurantMenuProducts: [],
    currentBusinessMenuCategories: [],
    defaultMURs: [],
    currentBusinessMURs: [],
    supplies: [],
    supplyRequests: [],
}

export default{
    namespaced: true, 
    state,
    mutations,
    actions,
    getters,
    plugins: [vuexLocal.plugin],
};