// Only use event bus for sending the following:
// Named events that will be received by subscribers
// Events that pass one time data (not to be stored anywhere). If you want to store data use VUEX actions/mutations

// Approved Reasons to use this eventbus:
// Opening APPWIDE modals (logging in etc)
// Emitting an event to a component that's NOT a direct parent or child.

import Vue from "vue"
import { EventBusEvents } from "@/enums/eventBusEvents"

const _bus = new Vue()

const eventBus = {
    emit(event, data) {
        _bus.$emit(event, data)
    },
    on(event, callback) {
        _bus.$on(event, callback)
    }
}

export {
    EventBusEvents,
    eventBus
}