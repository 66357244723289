<template>
	<SkBaseModal
		:isOpen="isOpen"
		:showCloseButton="false"
		:hasLoader="true"
		@close="cancel"
	>
		<loading :active.sync="loading" :is-full-page="false" />
		<div class="sk-modal-alt-title">
			<h1>Go Offline?</h1>
		</div>
		<div class="sk-row" v-for="option in options" :key="option.id">
			<div class="sk-radio-group">
				<SkRadio :option="option.id" v-model="selectedOptionId" />
				<span>{{ option.text }}</span>
			</div>
		</div>
		<div class="sk-row">
			<div class="sk-radio-group">
				<SkRadio :option="4" v-model="selectedOptionId" />
				<span>Custom</span>
				<div class="custom-incrementor">
					<button @click="incrementCustomHours" class="button-reset">
						<i class="sk-icon-plus-regular"></i>
					</button>
					<span class="custom-hours">{{ customHours }}</span>
					<button @click="decrementCustomHours" class="button-reset">
						<i class="sk-icon-minus"></i>
					</button>
					<span class="custom-hours-suffix">
						{{ customHoursSuffix }}
					</span>
				</div>
			</div>
		</div>
		<div class="sk-modal-actions">
			<button @click="cancel()" class="button button-text">
				Cancel & Stay Online
			</button>
			<button class="button button-primary" @click="goOffline">
				Go Offline
			</button>
		</div>
	</SkBaseModal>
</template>

<style scoped>
* >>> .sk-radio {
	width: 15px;
	height: 15px;
	margin-right: 20px;
}

.sk-radio-group span {
	font-size: 12px;
}

.custom-incrementor {
	display: flex;
	align-items: center;
	margin-left: 20px;
}

.custom-incrementor i {
	font-size: 8px;
	color: var(--sk-orange);
}

.custom-incrementor button {
	cursor: pointer;
}

.custom-hours {
	font-weight: 600;
	margin: 0 16px;
}

.custom-hours-suffix {
	font-size: 12px;
	color: var(--sk-grey3);
	margin-left: 15px;
}
</style>

<script>
import pluralizeFilterMixin from "@/mixins/pluralize-filter-mixin"
import skPromiseModalMixin from "@/mixins/modals/sk-promise-modal-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import SkRadio from "@/components/SkRadio.vue"
import Loading from "vue-loading-overlay"

export default {
	name: "RestaurantOutOfStockModal",
	mixins: [skPromiseModalMixin, pluralizeFilterMixin],
	components: {
		SkBaseModal,
		SkRadio,
		Loading
	},
	data: function () {
		return {
			loading: false,
			options: [
				{ id: 0, text: "1 Hour", minutes: 60 },
				{ id: 1, text: "3 Hour", minutes: 180 },
				{ id: 2, text: "All Day", minutes: null }
			],
			customOptionId: 4,
			customMinutes: 60,
			selectedOptionId: null
		}
	},
	computed: {
		customHours() {
			return this.customMinutes / 60
		},
		customHoursSuffix() {
			return this.$options.filters.pluralize(this.customHours, "Hour")
		}
	},
	methods: {
		handleOptions() {},
		setLoader(loading) {
			this.loading = loading
		},
		closed() {
			this.loading = false
		},
		opened() {
			this.loading = false
			this.selectedOptionId = this.options[0].id
			this.customMinutes = 60
		},
		goOffline() {
			let minutes = undefined
			if (this.selectedOptionId == this.customOptionId) {
				minutes = this.customMinutes
			} else {
				const option = this.options.find(
					(option) => option.id == this.selectedOptionId
				)
				minutes = option.minutes ? option.minutes : undefined
			}
			this.confirm({
                minutes: minutes
            })
		},
		incrementCustomHours() {
			this.selectedOptionId = this.customOptionId
			this.customMinutes = this.customMinutes + 60
		},
		decrementCustomHours() {
			if (this.customMinutes > 60) {
                this.selectedOptionId = this.customOptionId
				this.customMinutes = this.customMinutes - 60
			}
		}
	}
}
</script>