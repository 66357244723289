<template>
	<div class="sk-row">
		<div class="entity-section">
			<div class="entity-selector">
				<TypeaheadInput
					:placeholder="placeholder"
					:fetch="autocompleteEntities"
					:serializer="typeaheadSerializer"
					:query="entityName"
					@selected="entitySelected"
				/>
			</div>
			<div class="entity-button">
				<button class="button button-icon" @click="removeEntity">
					<i class="sk-icon-trash-alt-solid"></i>
				</button>
			</div>
		</div>
	</div>
</template>

<style scoped>
* >>> .sk-radio {
	width: 15px;
	height: 15px;
	margin-right: 10px;
}
.restaurant-product-addon {
	margin-left: -100px;
	margin-right: -20px;
}

.entity-button span:first-child {
	font-size: 14px;
	color: var(--sk-grey2);
}

.entity-button span:last-child {
	font-size: 14px;
	color: var(--sk-grey3);
}

.entity-section {
	display: flex;
	justify-content: space-between;
}

.entity-selector {
	width: 100%;
}
</style>

<script>
import TypeaheadInput from "@/components/TypeaheadInput.vue"

export default {
	name: "AdminUserEntity",
	components: {
		TypeaheadInput
	},
	props: {
		entity: Object
	},
	methods: {
		entitySelected(event) {
            this.entity.id = event.id
        },
		removeEntity() {
			this.$emit("userAdminEntityRemoveClicked", this.entity.id)
		},
		async autocompleteEntities(query) {
			var results = []
			if (this.entity.type == 1) {
				results = await this.$store.dispatch(
					"businessesModule/autocompleteBusinesses",
					query
				)
			} else {
				results = await this.$store.dispatch(
					"restaurantsModule/autocompleteRestaurants",
					query
				)
			}
			return results
		},
		typeaheadSerializer(result) {
			return {
				text: result.name
			}
		}
	},
	computed: {
		entityName() {
			if (this.entity.id > 0) {
				if (this.entity.type == 1) {
					return this.$store.getters.businessesMap[this.entity.id].name
				} else {
					return this.$store.state.restaurants[this.entity.id].name
				}
			}
		},
		placeholder() {
			return this.entity.type == 1 ? "Business" : "Restaurant"
		}
	}
}
</script>