import { ExceptionStatus } from "@/enums/exceptionStatus"
import { ExceptionReasonType } from "@/enums/exceptionReasonType"
import ActionTypes from "@/store/modules/insights/action-types"
import MutationTypes from "@/store/modules/insights/mutation-types"
import search_service from "@/services/search"
import Vue from "vue"

const vue = new Vue()

export default {
    [ActionTypes.SET_FILTERS]({ commit }, filters) {
        commit(MutationTypes.SET_FILTERS, filters)    
    },
    async [ActionTypes.GET_TOTAL_SALES_DATA]({ state, commit }, payload) {
        try {
            commit(MutationTypes.LOADING, "totalSales")
            commit(MutationTypes.GOT_TOTAL_SALES_DATA, null)
            let totalSalesFilters = {
                created_time_local_from: `${state.filters.startDate}T00:00:00Z`,
                created_time_local_to: `${state.filters.endDate}T23:59:59Z`
            }

            if (state.filters.businessId) {
                totalSalesFilters.business = {
                    ids: [state.filters.businessId]
                }
            }
            
            if (state.filters.restaurantId) {
                totalSalesFilters.restaurant = {
                    ids: [state.filters.restaurantId]
                }
            }

            let response = null
            let data = null
            if (payload.showCogs) {
                response = await search_service.getTotalCogSales({
                    filters: totalSalesFilters
                })
                data = response && response.status == 200 && response.data.total_cogs_by_period
            } else {
                response = await search_service.getTotalSales({
                    filters: totalSalesFilters
                })
                data = response && response.status == 200 && response.data.total_price_by_period
            }

            if (data) {
                commit(MutationTypes.LOADED, "totalSales")
                commit(MutationTypes.GOT_TOTAL_SALES_DATA, data)
            } else {
                let status = response && response.status ? response.status : ""
                throw `API Error ${status}`
            }
        } catch (e) {
            commit(MutationTypes.LOADED, "totalSales")
            vue.bugsnag.notify(
                "Failed to get insights total sales data",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        filters: state.filters
                    })
                }
            )
            return false
        }
    },
    async [ActionTypes.GET_TOTAL_ORDERS_DATA]({ state, commit }) {
		try {
            commit(MutationTypes.LOADING, "totalOrders")
            commit(MutationTypes.GOT_TOTAL_ORDERS_DATA, null)
            
            let getTotalOrdersFilter = {
                created_time_local_from: `${state.filters.startDate}T00:00:00Z`,
                created_time_local_to: `${state.filters.endDate}T23:59:59Z`
            }

            if (state.filters.businessId) {
                getTotalOrdersFilter.business = {
                    ids: [state.filters.businessId]
                }
            }

            if (state.filters.restaurantId) {
                getTotalOrdersFilter.restaurant = {
                    ids: [state.filters.restaurantId]
                }
            }

            const response = await search_service.getTotalOrdersByPeriod("day", {
                filters: getTotalOrdersFilter
            })

            if (response.status == 200 && response.data.total_orders_by_period) {
                commit(MutationTypes.GOT_TOTAL_ORDERS_DATA, response.data.total_orders_by_period)
                commit(MutationTypes.LOADED, "totalOrders")
                return true
            } else {
                throw `API Error: ${response.status}`
            }
		} catch (e) {
            commit(MutationTypes.LOADED, "totalOrders")
            vue.bugsnag.notify(
                "Failed to get insights total orders data",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        filters: state.filters
                    })
                }
            )
			return false
		}
	},
    async [ActionTypes.GET_AVERAGE_TICKET_PRICE_BY_DOW]({ state, commit }) {
        try {
            commit(MutationTypes.LOADING, "averageTicketPriceByDow")
            commit(MutationTypes.GOT_AVERAGE_TICKET_PRICE_BY_DOW, null)

            let averageTicketPriceFilters = {
                created_time_local_from: `${state.filters.startDate}T00:00:59Z`,
                created_time_local_to: `${state.filters.endDate}T23:59:59Z`
            }

            if (state.filters.businessId) {
                averageTicketPriceFilters.business = {
                    ids: [state.filters.businessId]
                }
            }

            if (state.filters.restaurantId) {
                averageTicketPriceFilters.restaurant = {
                    ids: [state.filters.restaurantId]
                }
            }

            const response = await search_service.getAveragePriceByPeriod("dayOfWeek", {
                filters: averageTicketPriceFilters
            })

            if (response.status == 200 && response.data && response.data.avg_orders) {
                commit(MutationTypes.GOT_AVERAGE_TICKET_PRICE_BY_DOW, response.data.avg_orders)
                commit(MutationTypes.LOADED, "averageTicketPriceByDow")
                return true
            } else {
                throw `API Error: ${response.status}`
            }
        } catch (e) {
            commit(MutationTypes.LOADED, "averageTicketPriceByDow")
            vue.bugsnag.notify(
                "Failed to get insights average ticket price by dow data",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        filters: state.filters
                    })
                }
            )
			return false
        }
    },
    async [ActionTypes.GET_TOP_ORDERS_BY_DOW]({ state, commit }) {
        try {
            commit(MutationTypes.LOADING, "topOrdersByDow")
            commit(MutationTypes.GOT_TOP_ORDERS_BY_DOW, null)

            let getTopOrdersFilters = {
                created_time_local_from: `${state.filters.startDate}T00:00:00Z`,
                created_time_local_to: `${state.filters.endDate}T23:59:59Z`
            }

            if (state.filters.businessId) {
                getTopOrdersFilters.business = {
                    ids: [state.filters.businessId]
                }
            }

            if (state.filters.restaurantId) {
                getTopOrdersFilters.restaurant = {
                    ids: [state.filters.restaurantId]
                }
            }

            const response = await search_service.getAverageOrdersByPeriod({
                period: "dayOfWeek"
            }, {
                filters: getTopOrdersFilters
            })

            if (response.status == 200 && response.data && response.data.avg_orders) {
                commit(MutationTypes.GOT_TOP_ORDERS_BY_DOW, response.data.avg_orders)
                commit(MutationTypes.LOADED, "topOrdersByDow")
                return true
            } else {
                throw `API Error: ${response.status}`
            }
        } catch (e) {
            commit(MutationTypes.LOADED, "topOrdersByDow")
            vue.bugsnag.notify(
                "Failed to get insights top orders by dow data",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        filters: state.filters
                    })
                }
            )
			return false
        }
    },
    async [ActionTypes.GET_TOP_ORDERS_BY_HOUR]({ state, commit }) {
        try {
            commit(MutationTypes.LOADING, "topOrdersByHour")
            commit(MutationTypes.GOT_TOP_ORDERS_BY_HOUR, null)

            let getTopOrdersFilters = {
                created_time_local_from: `${state.filters.startDate}T00:00:00Z`,
                created_time_local_to: `${state.filters.endDate}T23:59:59Z`
            }

            if (state.filters.businessId) {
                getTopOrdersFilters.business = {
                    ids: [state.filters.businessId]
                }
            }

            if (state.filters.restaurantId) {
                getTopOrdersFilters.restaurant = {
                    ids: [state.filters.restaurantId]
                }
            }

            const response = await search_service.getAverageOrdersByPeriod({
                period: "dayOfWeek",
                sub_period: "hourOfDay"
            }, {
                filters: getTopOrdersFilters
            })

            if (response.status == 200 && response.data && response.data.avg_orders) {
                commit(MutationTypes.GOT_TOP_ORDERS_BY_HOUR, response.data.avg_orders)
                commit(MutationTypes.LOADED, "topOrdersByHour")
                return true
            } else {
                throw `API Error: ${response.status}`
            }
        } catch (e) {
            commit(MutationTypes.LOADED, "topOrdersByHour")
            vue.bugsnag.notify(
                "Failed to get insights top orders by dow data",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        filters: state.filters
                    })
                }
            )
            return false
        }
    },
    async [ActionTypes.GET_AVERAGE_RATING]({ state, commit }) {
        try {
            commit(MutationTypes.LOADING, "averageRating")
            commit(MutationTypes.GOT_AVERAGE_RATING, null)

            let averageOrderScoreFilters = {
                created_time_local_from: `${state.filters.startDate}T00:00:00Z`,
                created_time_local_to: `${state.filters.endDate}T23:59:59Z`
            }

            if (state.filters.businessId) {
                averageOrderScoreFilters.business = {
                    ids: [state.filters.businessId]
                }
            }

            if (state.filters.restaurantId) {
                averageOrderScoreFilters.restaurant = {
                    ids: [state.filters.restaurantId]
                }
            }
            const response = await search_service.searchAverageOrderScore({
                filters: averageOrderScoreFilters
            })
            if (response.status == 200 && response.data && response.data.avg_score) {
                commit(MutationTypes.GOT_AVERAGE_RATING, response.data.avg_score)
                commit(MutationTypes.LOADED, "averageRating")
                return true
            } else {
                throw `API Error: ${response.status}`
            }
        } catch (e) {
            commit(MutationTypes.LOADED, "averageRating")
            vue.bugsnag.notify(
                "Failed to get insights average rating data",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        filters: state.filters
                    })
                }
            )
            return false
        }
    },
    async [ActionTypes.GET_AVERAGE_RESTAURANT_ACCURACY]({ state, commit }) {
        try {
            commit(MutationTypes.LOADING, "averageAccuracy")
            commit(MutationTypes.GOT_AVERAGE_RESTAURANT_ACCURACY, null)

            let averageAccuracyFilters = {
                created_time_local_from: `${state.filters.startDate}T00:00:00Z`,
                created_time_local_to: `${state.filters.endDate}T23:59:59Z`
            }

            if (state.filters.restaurantId) {
                averageAccuracyFilters.restaurant = {
                    ids: [state.filters.restaurantId]
                }
            }

            const response = await search_service.getAverageOrderAccuracy({
                filters: averageAccuracyFilters
            })
            
            if (response.status == 200 && response.data && response.data.avg_accuracy) {
                commit(MutationTypes.GOT_AVERAGE_RESTAURANT_ACCURACY, response.data.avg_accuracy)
                commit(MutationTypes.LOADED, "averageAccuracy")
                return true
            } else {
                throw `API Error: ${response.status}`
            }
        } catch (e) {
            commit(MutationTypes.LOADED, "averageAccuracy")
            vue.bugsnag.notify(
                "Failed to get insights average acccuracy data",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        filters: state.filters
                    })
                }
            )
            return false
        }
    },
    async [ActionTypes.GET_TOP_EXCEPTIONS]({ state, commit }) {
        try {
            commit(MutationTypes.LOADING, "topExceptions")
            commit(MutationTypes.GOT_TOP_EXCEPTIONS, null)

            let topExceptionsFilters = {
                created_time_local_from: `${state.filters.startDate}T00:00:00Z`,
                created_time_local_to: `${state.filters.endDate}T23:59:59Z`
            }

            if (state.filters.restaurantId) {
                topExceptionsFilters.restaurant = {
                    ids: [state.filters.restaurantId]
                }
            }

            const exceptionReasonResponse = await search_service.searchExceptionReasons({
                filters: topExceptionsFilters
            })

            if (exceptionReasonResponse.status == 200 && exceptionReasonResponse.data && exceptionReasonResponse.data.exception_reasons) {
                commit(MutationTypes.GOT_TOP_EXCEPTIONS, exceptionReasonResponse.data.exception_reasons)
                commit(MutationTypes.LOADED, "topExceptions")
                return true
            } else {
                throw `API Error: ${exceptionReasonResponse.status}`
            }
        } catch (e) {
            commit(MutationTypes.LOADED, "topExceptions")
            vue.bugsnag.notify(
                "Failed to get insights top exceptions data",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        filters: state.filters
                    })
                }
            )
            return false
        }
    },
    async [ActionTypes.GET_MISSING_ITEMS_ORDERS]({ state, commit }) {
        try {
            commit(MutationTypes.LOADING, "missingItemsOrders")
            commit(MutationTypes.GOT_MISSING_ITEMS_ORDERS, null)

            let missingItemsOrdersFilters = {
                created_time_local_from: `${state.filters.startDate}T00:00:00Z`,
                created_time_local_to: `${state.filters.endDate}T23:59:59Z`,
                exception: {
                    reason_types: [ExceptionReasonType.MISSING_ITEM],
                    statuses: [ExceptionStatus.APPROVED, ExceptionStatus.PENDING]
                }
            }

            if (state.filters.restaurantId) {
                missingItemsOrdersFilters.restaurant = {
                    ids: [state.filters.restaurantId]
                }
            }

            const response = await search_service.searchOrders({
                filters: missingItemsOrdersFilters,
                pagination: {
                    offset: 0,
                    limit: 5
                },
                sorting: [
                    {
                        sort_by: "created_time_local",
                        sort_order: "desc"
                    }
                ]
            })
            if (response.status == 200 && response.data && response.data.orders) {
                commit(MutationTypes.GOT_MISSING_ITEMS_ORDERS, response.data)
                commit(MutationTypes.LOADED, "missingItemsOrders")
                return true
            } else {
                throw `API Error: ${response.status}`
            }
        } catch (e) {
            commit(MutationTypes.LOADED, "missingItemsOrders")
            vue.bugsnag.notify(
				"Failed to get insights missing items orders",
				event => {
					event.addMetadata("error", {
						error: e,
                        filters: state.filters
					})
				}
			)
            return false
        }
    },
    async [ActionTypes.GET_INCORRECT_ITEMS_ORDERS]({ state, commit }) {
        try {
            commit(MutationTypes.LOADING, "incorrectItemsOrders")
            commit(MutationTypes.GOT_INCORRECT_ITEMS_ORDERS, null)

            let incorrectItemsOrdersFilters = {
                created_time_local_from: `${state.filters.startDate}T00:00:00Z`,
                created_time_local_to: `${state.filters.endDate}T23:59:59Z`,
                exception: {
                    reason_types: [ExceptionReasonType.WRONG_ITEM],
                    statuses: [ExceptionStatus.APPROVED, ExceptionStatus.PENDING]
                }
            }

            if (state.filters.restaurantId) {
                incorrectItemsOrdersFilters.restaurant = {
                    ids: [state.filters.restaurantId]
                }
            }

            const response = await search_service.searchOrders({
                filters: incorrectItemsOrdersFilters,
                pagination: {
                    offset: 0,
                    limit: 5
                },
                sorting: [
                    {
                        sort_by: "created_time_local",
                        sort_order: "desc"
                    }
                ]
            })
            if (response.status == 200 && response.data && response.data.orders) {
                commit(MutationTypes.GOT_INCORRECT_ITEMS_ORDERS, response.data)
                commit(MutationTypes.LOADED, "incorrectItemsOrders")
                return true
            } else {
                throw `API Error: ${response.status}`
            }
        } catch (e) {
            commit(MutationTypes.LOADED, "incorrectItemsOrders")
            vue.bugsnag.notify(
                "Failed to get insights incorrect items orders",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        filters: state.filters
                    })
                }
            )
            return false
        }
    },
    async [ActionTypes.GET_RESTAURANT_CLOSED_ORDERS]({ state, commit }) {
        try {
            commit(MutationTypes.LOADING, "restaurantClosedOrders")
            commit(MutationTypes.GOT_RESTAURANT_CLOSED_ORDERS, null)

            let restaurantClosedOrdersFilters = {
                created_time_local_from: `${state.filters.startDate}T00:00:00Z`,
                created_time_local_to: `${state.filters.endDate}T23:59:59Z`,
                exception: {
                    reason_types: [ExceptionReasonType.RESTAURANT_CLOSED],
                    statuses: [ExceptionStatus.APPROVED, ExceptionStatus.PENDING]
                }
            }

            if (state.filters.restaurantId) {
                restaurantClosedOrdersFilters.restaurant = {
                    ids: [state.filters.restaurantId]
                }
            }

            const response = await search_service.searchOrders({
                filters: restaurantClosedOrdersFilters,
                pagination: {
                    offset: 0,
                    limit: 5
                },
                sorting: [
                    {
                        sort_by: "created_time_local",
                        sort_order: "desc"
                    }
                ]
            })
            if (response.status == 200 && response.data && response.data.orders) {
                commit(MutationTypes.GOT_RESTAURANT_CLOSED_ORDERS, response.data)
                commit(MutationTypes.LOADED, "restaurantClosedOrders")
                return true
            } else {
                throw `API Error: ${response.status}`
            }
        } catch (e) {
            commit(MutationTypes.LOADED, "restaurantClosedOrders")
            vue.bugsnag.notify(
                "Failed to get insights restaurant closed orders",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        filters: state.filters
                    })
                }
            )
            return false
        }
    },
    async [ActionTypes.GET_RESTAURANT_AVERAGE_ACCEPT_TIME]({ state, commit }) {
        try {
            commit(MutationTypes.LOADING, "averageAcceptTime")
            commit(MutationTypes.GOT_RESTAURANT_AVERAGE_ACCEPT_TIME, null)

            let averageAcceptTimeFilters = {
                created_time_local_from: `${state.filters.startDate}T00:00:00Z`,
                created_time_local_to: `${state.filters.endDate}T23:59:59Z`
            }

            if (state.filters.restaurantId) {
                averageAcceptTimeFilters.restaurant = {
                    ids: [state.filters.restaurantId]
                }
            }

            const response = await search_service.getAverageOrderAcceptTime({
                filters: averageAcceptTimeFilters
            })

            if (response.status == 200 && response.data && response.data.avg_time_to_accept) {
                commit(MutationTypes.GOT_RESTAURANT_AVERAGE_ACCEPT_TIME, response.data.avg_time_to_accept)
                commit(MutationTypes.LOADED, "averageAcceptTime")
                return true
            } else {
                throw `API Error: ${response.status}`
            }
        } catch (e) {
            commit(MutationTypes.LOADED, "averageAcceptTime")
            vue.bugsnag.notify(
                "Failed to get insights average time to accept",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        filters: state.filters
                    })
                }
            )
            return false
        }
    },
    async [ActionTypes.GET_RESTAURANT_AVERAGE_PREPARATION_TIME]({ state, commit }) {
        try {
            commit(MutationTypes.LOADING, "averagePreparationTime")
            commit(MutationTypes.GOT_RESTAURANT_AVERAGE_PREPARATION_TIME, null)

            let averagePreparationTimeFilters = {
                created_time_local_from: `${state.filters.startDate}T00:00:00Z`,
                created_time_local_to: `${state.filters.endDate}T23:59:59Z`
            }

            if (state.filters.restaurantId) {
                averagePreparationTimeFilters.restaurant = {
                    ids: [state.filters.restaurantId]
                }
            }

            const response = await search_service.getAverageOrderPickupTime({
                filters: averagePreparationTimeFilters
            })

            if (response.status == 200 && response.data && response.data.avg_time_to_pickup) {
                commit(MutationTypes.GOT_RESTAURANT_AVERAGE_PREPARATION_TIME, response.data.avg_time_to_pickup)
                commit(MutationTypes.LOADED, "averagePreparationTime")
                return true
            } else {
                throw `API Error: ${response.status}`
            }
        } catch (e) {
            commit(MutationTypes.LOADED, "averagePreparationTime")
            vue.bugsnag.notify(
                "Failed to get insights average time to prepare",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        filters: state.filters
                    })
                }
            )
            return false
        }
    }
}