import { FundingSource } from "@/enums/fundingSource.js"
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import { InvoiceModuleId } from "@/enums/invoiceModuleId"
import { PermissionInfo } from "@/utils/permissions"
import { ExceptionStatus } from "@/enums/exceptionStatus"
import Vue from "vue"
import moment from "moment"
import "moment-timezone"
import ActionTypes from "@/store/modules/finance/action-types"
import MutationTypes from "@/store/modules/finance/mutation-types"
import payment_service from "@/services/payments"
import finance_service from "@/services/finance"
import search_service from "@/services/search"
import orders_service from "@/services/orders"
import xlsx_helper from "@/utils/xlsx-helper"
import { ExceptionReasonType } from "@/enums/exceptionReasonType"

const vue = new Vue()

export default {
	async [ActionTypes.GET_FINANCE]({ commit, dispatch }) {
		try {
			commit(MutationTypes.LOADING)
			await Promise.all([
				dispatch("getExceptions", true, { root: true }),
				dispatch("getRefunds", true, { root: true })
			])

			await Promise.all([
				dispatch(ActionTypes.GET_RESTAURANTS_FINANCE_DETAILS),
				dispatch(ActionTypes.GET_BUSINESSES_FINANCE_DETAILS)
			])

			commit(MutationTypes.LOADED)
		} catch (e) {
			commit(MutationTypes.LOADED)
			vue.bugsnag.notify("Failed to get finance", (event) => {
				event.addMetadata("error", {
					error: e
				})
			})
			return false
		}
	},
	async [ActionTypes.GET_RESTAURANTS_FINANCE_DETAILS]({ commit, rootState }) {
		try {
			if (
				rootState.permissions.includes(
					PermissionInfo.RESTAURANT_PAYOUTS
				)
			) {
				const restaurantIds = Object.keys(rootState.restaurants)
				const payoutAmountsPayload = {
					restaurant_ids: restaurantIds
				}

				const promises = await Promise.all([
					payment_service.getRestaurantsActiveFundingSources(
						restaurantIds.join(",")
					),
					finance_service.batchGetPendingPayoutAmounts(
						payoutAmountsPayload
					)
				])

				if (promises[0].status == 200) {
					commit(MutationTypes.GOT_RESTAURANTS_FINANCE_DETAILS, {
						fundingSources: promises[0].data.funding_sources,
						pendingAmounts: promises[1].data.amounts
					})
					return true
				}
			}
			return false
		} catch (e) {
			commit(MutationTypes.LOADED)
			vue.bugsnag.notify(
				"Failed to get restaurants finance details",
				(event) => {
					event.addMetadata("error", {
						error: e
					})
				}
			)
			return false
		}
	},
	async [ActionTypes.GET_BUSINESSES_FINANCE_DETAILS]({
		commit,
		rootState,
		rootGetters
	}) {
		try {
			if (
				rootState.permissions.includes(PermissionInfo.BUSINESS_INVOICES)
			) {
				const businessIds = Object.keys(rootGetters.businessesMap)
				const invoiceAmountsPayout = {
					business_ids: businessIds
				}

				const promises = await Promise.all([
					payment_service.getBusinessesActiveFundingSources(
						businessIds.join(",")
					),
					finance_service.batchGetPendingInvoiceAmount(
						invoiceAmountsPayout
					)
				])

				if (promises[0].status == 200) {
					commit(MutationTypes.GOT_BUSINESSES_FINANCE_DETAILS, {
						fundingSources: promises[0].data.funding_sources,
						pendingAmounts: promises[1].data.amounts
					})
					return true
				}
			}
			return false
		} catch (e) {
			commit(MutationTypes.LOADED)
			vue.bugsnag.notify(
				"Failed to get businesses finance details",
				(event) => {
					event.addMetadata("error", {
						error: e
					})
				}
			)
			return false
		}
	},
	async [ActionTypes.GET_FUNDING_SOURCE](store, fundingSourceId) {
		try {
			const response = await payment_service.getFundingSource(
				fundingSourceId
			)
			if (response.status == 200) {
				return response.data.funding_source
			}
			return null
		} catch (e) {
			vue.bugsnag.notify("Failed to get get funding source", (event) => {
				event.addMetadata("error", {
					error: e,
					fundingSourceId: fundingSourceId
				})
			})
			return null
		}
	},
	async [ActionTypes.ADD_FUNDING_SOURCE]({ commit }, payload) {
		try {
			commit(MutationTypes.LOADING)
			const paymentPayload = {
				entity_id: payload.entityId,
				entity_type: payload.entityType,
				validation_type: payload.validationType,
				validation_data: {
					validator_public_token: payload.token,
					validator_account_id: payload.metadata.account_id,
					bank_account_name: payload.metadata.account.name,
					bank_account_number: payload.metadata.account.mask,
					bank_account_type: payload.metadata.account.subtype,
					bank_institution_name: payload.metadata.institution.name
				}
			}
			if (payload.autoPay) {
				paymentPayload.auto_pay = 1
			}
			const paymentResp = await payment_service.addFundingSource(
				paymentPayload
			)
			if (
				paymentResp.status == 200 &&
				paymentResp.data.funding_source != undefined &&
				paymentResp.data.funding_source != null
			) {
				commit(
					MutationTypes.SET_CURRENT_FUNDING_ACCOUNT,
					paymentResp.data.funding_source
				)
			}
			commit(MutationTypes.LOADED)
			return true
		} catch (e) {
			commit(MutationTypes.LOADED)
			vue.bugsnag.notify("Failed to get add funding source", (event) => {
				event.addMetadata("error", {
					error: e
				})
			})
			return false
		}
	},
	async [ActionTypes.TOGGLE_FUNDING_SOURCE_AUTO_PAY]({ commit }, options) {
		try {
			const fundingSource = options.fundingSource
			if (!options.silent) {
				commit(MutationTypes.LOADING)
			}
			const updatedAutoPay = fundingSource.auto_pay ? 0 : 1
			const response = await payment_service.updateFundingSource(
				fundingSource.id,
				{
					funding_source: {
						auto_pay: updatedAutoPay
					}
				}
			)
			if (response.status == 200) {
				commit(MutationTypes.UPDATED_FUNDING_SOURCE_AUTO_PAY, {
					fundingSource: fundingSource,
					autoPay: updatedAutoPay
				})
			}
			if (!options.silent) {
				commit(MutationTypes.LOADED)
			}
			return true
		} catch (e) {
			if (!options.silent) {
				commit(MutationTypes.LOADED)
			}
			vue.bugsnag.notify("Failed to toggle funding source", (event) => {
				event.addMetadata("error", {
					error: e
				})
			})
			return false
		}
	},
	async [ActionTypes.ADD_MANUAL_RESTAURANT_FUNDING_SOURCE](
		store,
		restaurantId
	) {
		const paymentPayload = {
			entity_id: restaurantId,
			entity_type: 2,
			validation_type: 1
		}
		const paymentResp = await payment_service.addFundingSource(
			paymentPayload
		)
		if (
			paymentResp.status == 200 &&
			paymentResp.data.funding_source != undefined &&
			paymentResp.data.funding_source != null
		) {
			return {
				id: paymentResp.data.funding_source.id,
				token: paymentResp.data.processor_token
			}
		}
		return null
	},
	async [ActionTypes.VERIFY_MANUAL_RESTAURANT_FUNDING_SOURCE](
		{ commit },
		payload
	) {
		commit(MutationTypes.LOADING)
		const paymentPayload = {
			processor_funding_source_id: payload.external_id
		}
		const paymentResp = await payment_service.verifyFundingSource(
			payload.funding_source_id,
			paymentPayload
		)
		if (
			paymentResp.data.code == "ok" &&
			paymentResp.data.data.funding_source != undefined &&
			paymentResp.data.data.funding_source != null
		) {
			commit(
				MutationTypes.SET_CURRENT_FUNDING_ACCOUNT,
				paymentResp.data.data.funding_source
			)
		}
		commit(MutationTypes.LOADED)
	},
	async [ActionTypes.REMOVE_FUNDING_SOURCE]({ commit }, fundingSourceId) {
		try {
			commit(MutationTypes.LOADING)
			const paymentsResp = await payment_service.deleteFundingSource(
				fundingSourceId
			)
			if (paymentsResp.status == 200) {
				commit(MutationTypes.SET_CURRENT_FUNDING_ACCOUNT, null)
			}
			commit(MutationTypes.LOADED)
			return true
		} catch (e) {
			commit(MutationTypes.LOADED)
			vue.bugsnag.notify(
				"Failed to get remove funding source",
				(event) => {
					event.addMetadata("error", {
						error: e,
						fundingSourceId: fundingSourceId
					})
				}
			)
			return false
		}
	},
	async [ActionTypes.GET_CURRENT_RESTAURANT_FINANCE_DETAILS](
		{ commit },
		restaurantId
	) {
		try {
			commit(MutationTypes.LOADING)
			let currentFundingSource = null
			let pendingAmount = 0
			const endOfDay = moment.utc().hours(0).minutes(0).seconds(0)
			const payoutHistoryMonths = moment
				.utc()
				.hours(0)
				.minutes(0)
				.seconds(0)
				.add(-6, "M")
			const historyPayload = {
				to_date: endOfDay.format(),
				from_date: payoutHistoryMonths.format(),
				statuses: [1, 2, 3],
				restaurants_ids: [restaurantId]
			}

			const responses = await Promise.all([
				payment_service.getRestaurantFundingSources(restaurantId),
				finance_service.getRestaurantPendingPayoutAmount(restaurantId),
				search_service.getRestaurantPayouts(historyPayload)
			])
			const fundingSourceResponse = responses[0]
			const pendingAmountResponse = responses[1]
			const payoutHistoryResponse = responses[2]

			if (fundingSourceResponse.status == 200) {
				const relFundingAccount =
					fundingSourceResponse.data.funding_sources.find(
						(e) => e.is_active == 1
					)
				if (
					relFundingAccount != undefined &&
					relFundingAccount.type == FundingSource.ACH
				) {
					currentFundingSource = relFundingAccount
				}
			}

			if (pendingAmountResponse.status == 200) {
				pendingAmount = pendingAmountResponse.data.amount
			}

			commit(MutationTypes.GOT_CURRENT_FINANCE_DETAILS, {
				amount: pendingAmount,
				funding_source: currentFundingSource
			})
			commit(
				MutationTypes.GOT_CURRENT_RESTAURANT_PAYOUT_HISTORY,
				payoutHistoryResponse
			)
			commit(MutationTypes.LOADED)
			return true
		} catch (e) {
			vue.bugsnag.notify(
				"Failed to get current restaurant finance details",
				(event) => {
					event.addMetadata("error", {
						error: e,
						restaurantId: restaurantId
					})
				}
			)
			commit(MutationTypes.LOADED)
			return false
		}
	},
	async [ActionTypes.GET_CURRENT_BUSINESS_FINANCE_DETAILS](
		{ commit },
		businessId
	) {
		try {
			commit(MutationTypes.LOADING)
			let currentFundingSource = null
			let pendingAmount = 0
			const pendingAmountPayload = {
				business_ids: [businessId]
			}

			const responses = await Promise.all([
				payment_service.getBusinessFundingSources(businessId),
				finance_service.batchGetPendingInvoiceAmount(
					pendingAmountPayload
				)
			])

			const fundingSourceResponse = responses[0]
			const pendingAmountResponse = responses[1]

			if (fundingSourceResponse.status == 200) {
				const relFundingAccount =
					fundingSourceResponse.data.funding_sources.find(
						(e) => e.is_active == 1
					)
				if (
					relFundingAccount != undefined &&
					relFundingAccount.type == FundingSource.ACH
				) {
					currentFundingSource = relFundingAccount
				}
			}

			if (
				pendingAmountResponse.status == 200 &&
				pendingAmountResponse.data.amounts.length > 0
			) {
				pendingAmount = pendingAmountResponse.data.amounts[0].amount
			}

			commit(MutationTypes.GOT_CURRENT_FINANCE_DETAILS, {
				amount: pendingAmount,
				funding_source: currentFundingSource
			})
			commit(MutationTypes.LOADED)
			return true
		} catch (e) {
			commit(MutationTypes.LOADED)
			vue.bugsnag.notify(
				"Failed to get current business finance details",
				(event) => {
					event.addMetadata("error", {
						error: e,
						restaurantId: businessId
					})
				}
			)
			return false
		}
	},
	[ActionTypes.CLEAR_CURRENT_FINANCE_DETAILS]({ commit }) {
		commit(MutationTypes.CLEAR_CURRENT_FINANCE_DETAILS)
	},
	async [ActionTypes.GET_CURRENT_BUSINESS_INVOICE_HISTORY](
		{ commit },
		payload
	) {
		try {
			commit(MutationTypes.LOADING)
			let toDate = null
			let fromDate = null
			let statuses = []
			if (payload.startDate && payload.endDate) {
				fromDate = `${payload.startDate}T00:00:00Z`
				toDate = `${payload.endDate}T00:00:00Z`
			} else {
				toDate = moment.utc().hours(0).minutes(0).seconds(0).format()
				fromDate = moment
					.utc()
					.hours(0)
					.minutes(0)
					.seconds(0)
					.add(-6, "M")
					.format()
			}

			if (payload.statuses) {
				statuses = payload.statuses
			}

			const filters = {
				to_date: toDate,
				from_date: fromDate,
				statuses: statuses,
				business: {
					ids: [payload.businessId]
				}
			}

			const paginagion = {
				limit: payload.pageSize,
				offset: payload.pageStart
			}

			const response = await search_service.getBusinessBillingInvoices({
				filters: filters,
				pagination: paginagion
			})
			commit(MutationTypes.GOT_CURRENT_BUSINESS_INVOICE_HISTORY, response)
			commit(MutationTypes.LOADED)
			return response.status == 200
		} catch (e) {
			commit(MutationTypes.LOADED)
			vue.bugsnag.notify(
				"Failed to get current business invoice history",
				(event) => {
					event.addMetadata("error", {
						error: e,
						payload: payload
					})
				}
			)
			return false
		}
	},
	async [ActionTypes.PAY_BUSINESS_INVOICE]({ dispatch, commit }, payload) {
		try {
			if (payload.autoPay) {
				await dispatch(ActionTypes.TOGGLE_FUNDING_SOURCE_AUTO_PAY, {
					fundingSource: payload.invoice.funding_source
				})
			}
			const paymentResponse = await payment_service.payInvoice(
				payload.invoice.id,
				{
					funding_source_id: payload.invoice.funding_source.id
				}
			)
			if (paymentResponse.status == 200) {
				commit(MutationTypes.PAID_BUSINESS_INVOICE, payload.invoice)
				return true
			}
		} catch (e) {
			vue.bugsnag.notify("Failed to pay business invoice", (event) => {
				event.addMetadata("error", {
					error: e
				})
			})
			return false
		}
	},
	async [ActionTypes.DOWNLOAD_INVOICE_USAGE_BREAKDOWN](
		{ commit, rootGetters },
		invoice
	) {
		try {
			commit(MutationTypes.LOADING)
			const moduleNames = {}
			const moduleSourceIds = {}
			const moduleSheets = {}
			const exportableModules = [
				InvoiceModuleId.PAY_AS_YOU_GO_VOUCHER_USE,
				InvoiceModuleId.PRE_PAID_VOUCHER_REQUEST,
				InvoiceModuleId.ADMIN_ORDER
			]
			moduleNames[InvoiceModuleId.PAY_AS_YOU_GO_VOUCHER_USE] =
				"Pay as you go Vouchers"
			moduleNames[InvoiceModuleId.PRE_PAID_VOUCHER_REQUEST] =
				"Pre paid vouchers"
			moduleNames[InvoiceModuleId.ADMIN_ORDER] = "Catering Orders"

			if (invoice && invoice.modules) {
				const overviewSheetJson = invoice.modules
					.filter((module) => exportableModules.includes(module.id))
					.map((module) => {
						return {
							Module: moduleNames[module.id]
								? moduleNames[module.id]
								: "N/A",
							"# of Records": module.count,
							Amount: module.amount
						}
					})

				moduleSheets[-1] = {
					moduleId: -1,
					name: "Overview",
					json: overviewSheetJson,
					options: {
						header: ["Module", "# of Records", "Amount"]
					}
				}

				const recordsResponse =
					await finance_service.getBusinessBillingInvoiceRecords(
						invoice.id
					)
				if (recordsResponse.status == 200) {
					recordsResponse.data.records.forEach((record) => {
						if (!moduleSourceIds[record.type]) {
							moduleSourceIds[record.type] = []
						}

						if (record.type == InvoiceModuleId.EXTERNAL_RECORD) {
							moduleSourceIds[record.type].push({
								description: record.source_id,
								amount: record.amount
							})
						} else {
							moduleSourceIds[record.type].push(record.source_id)
						}
					})

					let voucherRequestIds = []
					const payAsYouGoVoucherRequestIds = []

					if (
						moduleSourceIds[
							InvoiceModuleId.PAY_AS_YOU_GO_VOUCHER_USE
						]
					) {
						const couponResponse =
							await search_service.searchCoupons({
								filters: {
									ids: moduleSourceIds[
										InvoiceModuleId
											.PAY_AS_YOU_GO_VOUCHER_USE
									]
								}
							})
						if (
							couponResponse.status == 200 &&
							couponResponse.data.coupons.length > 0
						) {
							const payAsYouGoData = []
							couponResponse.data.coupons.forEach((coupon) => {
								if (coupon.uses && coupon.uses.length > 0) {
									payAsYouGoVoucherRequestIds.push(
										coupon.voucher_request_id
									)
									voucherRequestIds.push(
										coupon.voucher_request_id
									)
									let business =
										rootGetters.businessesMap[
											coupon.business.id
										]
									coupon.uses.forEach((usage) => {
										let usedDate = null
										if (business) {
											usedDate = moment
												.utc(usage.date_used)
												.tz(business.timezone)
												.format("MMM D, YYYY h:m a z")
										} else {
											usedDate = moment
												.utc(usage.date_used)
												.local()
												.format("MMM D, YYYY h:m a z")
										}
										payAsYouGoData.push({
											_voucher_id:
												coupon.voucher_request_id,
											"Voucher Code": coupon.code,
											"Used Date": usedDate,
											"Order Id": usage.order_id,
											"Guest Name": "",
											"Guest Email": "",
											"Voucher Request Name": "",
											"Voucher Request Description": ""
										})
									})
								}
							})
							moduleSheets[
								InvoiceModuleId.PAY_AS_YOU_GO_VOUCHER_USE
							] = {
								moduleId:
									InvoiceModuleId.PAY_AS_YOU_GO_VOUCHER_USE,
								name: moduleNames[
									InvoiceModuleId.PAY_AS_YOU_GO_VOUCHER_USE
								],
								json: payAsYouGoData,
								options: {
									header: [
										"Voucher Code",
										"Used Date",
										"Order Id",
										"Guest Name",
										"Guest Email",
										"Voucher Request Name",
										"Voucher Request Description"
									]
								}
							}
						}
					}

					if (
						moduleSourceIds[
							InvoiceModuleId.PRE_PAID_VOUCHER_REQUEST
						]
					) {
						voucherRequestIds = voucherRequestIds.concat(
							moduleSourceIds[
								InvoiceModuleId.PRE_PAID_VOUCHER_REQUEST
							]
						)
					}

					if (voucherRequestIds.length > 0) {
						const voucherRequestResponse =
							await search_service.searchVoucherRequests({
								filters: {
									ids: voucherRequestIds
								}
							})

						if (
							voucherRequestResponse.status == 200 &&
							voucherRequestResponse.data.voucher_requests
						) {
							const payAsYouGoVoucherRequests = {}
							moduleSheets[
								InvoiceModuleId.PRE_PAID_VOUCHER_REQUEST
							] = {
								moduleId:
									InvoiceModuleId.PRE_PAID_VOUCHER_REQUEST,
								name: moduleNames[
									InvoiceModuleId.PRE_PAID_VOUCHER_REQUEST
								],
								json: [],
								options: {
									header: [
										"Id",
										"Issued Date",
										"Name",
										"Description",
										"Amount"
									]
								}
							}
							voucherRequestResponse.data.voucher_requests.forEach(
								(voucherRequest) => {
									if (
										payAsYouGoVoucherRequestIds.includes(
											voucherRequest.id
										)
									) {
										payAsYouGoVoucherRequests[
											voucherRequest.id
										] = voucherRequest
									} else {
										let business =
											rootGetters.businessesMap[
												voucherRequest.business.id
											]
										let issueDate = null
										if (business) {
											issueDate = moment
												.utc(
													voucherRequest.created_date
												)
												.tz(business.timezone)
												.format("MMM D, YYYY h:m a z")
										} else {
											issueDate = moment
												.utc(
													voucherRequest.created_date
												)
												.local()
												.format("MMM D, YYYY h:m a z")
										}
										moduleSheets[
											InvoiceModuleId
												.PRE_PAID_VOUCHER_REQUEST
										].json.push({
											Id: voucherRequest.id,
											"Issue Date": issueDate,
											"Issued By":
												voucherRequest.issued_by
													.display_name,
											Name: voucherRequest.name,
											Description:
												voucherRequest.description,
											Amount: voucherRequest.amount
										})
									}
								}
							)
							moduleSheets[
								InvoiceModuleId.PAY_AS_YOU_GO_VOUCHER_USE
							].json.forEach((moduleData) => {
								const voucherRequest =
									payAsYouGoVoucherRequests[
										moduleData._voucher_id
									]
								moduleData["Voucher Request Name"] =
									voucherRequest.name
								moduleData["Voucher Request Description"] =
									voucherRequest.description
								delete moduleData._voucher_id
							})
						}
					}

					if (moduleSourceIds[InvoiceModuleId.ADMIN_ORDER]) {
						moduleSheets[InvoiceModuleId.ADMIN_ORDER] = {
							moduleId: InvoiceModuleId.ADMIN_ORDER,
							name: moduleNames[InvoiceModuleId.ADMIN_ORDER],
							json: []
						}

						const adminOrdersResponse =
							await orders_service.searchAdminOrders({
								order_ids:
									moduleSourceIds[InvoiceModuleId.ADMIN_ORDER]
							})
						if (adminOrdersResponse.status == 200) {
							let productColumns = []
							adminOrdersResponse.data.admin_orders.forEach(
								(order) => {
									if (
										order.sub_orders &&
										order.sub_orders.length > 0
									) {
										let business =
											rootGetters.businessesMap[
												order.business_id
											]
										let deliverySlot =
											order.sub_orders[0].delivery_slot
										let date = null
										if (business) {
											date = moment
												.utc(deliverySlot)
												.tz(business.timezone)
												.format("MMM D, YYYY h:m a z")
										} else {
											date = moment
												.utc(deliverySlot)
												.local()
												.format("MMM D, YYYY h:m a z")
										}
										let adminOrderJson = {
											Date: date,
											"Order Id": order.order_id
										}
										let quantity = 0
										let amount = 0
										order.sub_orders[0].products.forEach(
											(product) => {
												adminOrderJson[product.name] =
													product.quantity
												if (
													!productColumns.includes(
														product.name
													)
												) {
													productColumns.push(
														product.name
													)
												}
												quantity += product.quantity
												amount += product.total_price
											}
										)
										adminOrderJson["Total Quantity"] =
											quantity
										adminOrderJson["Total Amount"] = amount
										moduleSheets[
											InvoiceModuleId.ADMIN_ORDER
										].json.push(adminOrderJson)
									}
								}
							)

							let headerColumns = ["Date", "Order Id"].concat(
								productColumns
							)
							headerColumns = headerColumns.concat([
								"Total Quantity",
								"Total Amount"
							])

							moduleSheets[InvoiceModuleId.ADMIN_ORDER].options =
								{
									header: headerColumns
								}
						}
					}

					if (moduleSourceIds[InvoiceModuleId.EXTERNAL_RECORD]) {
						moduleSourceIds[
							InvoiceModuleId.EXTERNAL_RECORD
						].forEach((externalRecord) => {
							moduleSheets[-1].json.push({
								Module: externalRecord.description,
								"# of Records": 1,
								Amount: externalRecord.amount
							})
						})
					}
				}
			}
			const from = moment.utc(invoice.from_date).format("MM-DD")
			const to = moment.utc(invoice.to_date).format("MM-DD")
			const filename = `${from}_${to}`
			const sheets = Object.values(moduleSheets).sort(
				(a, b) => a.moduleId - b.moduleId
			)
			xlsx_helper.exportMultiSheet(sheets, filename)
			commit(MutationTypes.LOADED)
			return true
		} catch (e) {
			commit(MutationTypes.LOADED)
			vue.bugsnag.notify(
				"Failed to export invoice breakdown",
				(event) => {
					event.addMetadata("error", {
						error: e,
						invoiceId: invoice.id
					})
				}
			)
			return false
		}
	},
	async [ActionTypes.GET_RESTAURANT_PAYOUTS]({ commit, rootState }, filters) {
		try {
			if (
				rootState.permissions.includes(
					PermissionInfo.RESTAURANT_PAYOUTS
				)
			) {
				const response = await search_service.getRestaurantPayouts({
					from_date: `${filters.start}T23:59:59Z`,
					to_date: `${filters.end}T00:00:00Z`
				})
				commit(MutationTypes.GOT_RESTAURANT_PAYOUTS, response)
				return response.status == 200
			} else {
				return false
			}
		} catch (e) {
			vue.bugsnag.notify("Failed to get restaurant payouts", (event) => {
				event.addMetadata("error", {
					error: e
				})
			})
			return false
		}
	},
	async [ActionTypes.APPROVE_RESTAURANT_PAYOUT]({ commit }, payout_id) {
		// commit('loading')
		const resp = await finance_service.approveRestaurantPayout(payout_id)
		if (resp.data.code == "ok") {
			commit(MutationTypes.REMOVE_RESTAURANT_PAYOUT, payout_id)
		}
		//  commit('loaded')
	},
	async [ActionTypes.ADJUST_RESTAURANT_PAYOUT_AMOUNT]({ commit }, obj) {
		try {
			let payoutId = obj.id
			let payload = {
				adjustment: obj.amount,
				reason: obj.reason
			}
			const resp = await finance_service.adjustRestaurantPayoutAmount(
				payoutId,
				payload
			)
			if (resp.status == 200) {
				commit(MutationTypes.ADJUSTED_RESTAURANT_PAYOUT, obj)
				return true
			} else {
				return false
			}
		} catch (e) {
			vue.bugsnag.notify("Failed to adjust payout", (event) => {
				event.addMetadata("error", {
					error: e
				})
			})
			return false
		}
	},
	async [ActionTypes.BATCH_APPROVE_RESTAURANT_PAYOUTS]({ commit }, ids) {
		try {
			const payload = { payout_ids: ids }
			const resp = await finance_service.batchApproveRestaurantPayouts(
				payload
			)
			if (resp.status == 200) {
				commit(MutationTypes.BATCH_REMOVED_PAYOUTS, ids)
				return true
			}
			return false
		} catch (e) {
			vue.bugsnag.notify("Failed to approve payouts", (event) => {
				event.addMetadata("error", {
					error: e
				})
			})
			return false
		}
	},
	async [ActionTypes.CLEAR_RESTAURANT_PAYOUT]({ commit }, obj) {
		try {
			let payoutId = obj.id
			let payload = {
				reason: obj.reason
			}
			const resp = await finance_service.clearRestaurantPayout(
				payoutId,
				payload
			)
			if (resp.status == 200) {
				commit(MutationTypes.REMOVE_RESTAURANT_PAYOUT, payoutId)
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: `Successfully cleared payout`
				})
				return true
			} else {
				return false
			}
		} catch (e) {
			eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
				title: `Error adjusting payout amount. Please try again`,
				type: NotificationType.ERROR
			})
			return false
		}
	},
	async [ActionTypes.BATCH_COMPLETE_RESTAURANT_PAYOUTS]({ commit }, ids) {
		try {
			const payload = { payout_ids: ids }
			const resp = await finance_service.batchCompleteRestaurantPayouts(
				payload
			)
			if (resp.status == 200) {
				commit(MutationTypes.BATCH_REMOVED_PAYOUTS, ids)
				return true
			}
			return false
		} catch (e) {
			vue.bugsnag.notify("Failed to complete payouts", (event) => {
				event.addMetadata("error", {
					error: e
				})
			})
			return false
		}
	},
	async [ActionTypes.GET_PAYOUT_ORDERS](store, payout_id) {
		try {
			const resp = await finance_service.getPayoutOrders(payout_id)
			if (resp.data.code == "ok") {
				return resp.data.data.orders
			} else {
				return null
			}
		} catch (e) {
			vue.bugsnag.notify("Failed to get payout orders", (event) => {
				event.addMetadata("error", {
					error: e
				})
			})
			return null
		}
	},
	async [ActionTypes.GET_PAYOUT_EXCEPTIONS]({ rootGetters }, orders) {
		try {
			const orderIds = orders.map((order) => order.order_id)
			let exceptionReasonTypes = undefined
			if (rootGetters.isRestaurantAdmin) {
				exceptionReasonTypes = [
					ExceptionReasonType.MISSING_ITEM,
					ExceptionReasonType.WRONG_ITEM,
					ExceptionReasonType.RESTAURANT_CLOSED
				]
			}
			const response = await search_service.searchOrders({
				filters: {
					ids: orderIds,
					exception: {
						statuses: [ExceptionStatus.APPROVED],
						reason_types: exceptionReasonTypes
					}
				}
			})
			if (response.status == 200 && response.data.orders) {
				return response.data.orders
					.map((order) => {
						return order.exceptions.map((exception) => {
							return {
								created_time_local: order.created_time_local,
								order_id: order.id,
								amount: exception.amount,
								type: exception.reason_type
							}
						})
					})
					.flat()
			} else {
				return []
			}
		} catch (e) {
			vue.bugsnag.notify("Failed to get payout exceptions", (event) => {
				event.addMetadata("error", {
					error: e
				})
			})
			return []
		}
	},
	async [ActionTypes.ISSUE_PAYOUTS]() {
		const resp = await payment_service.issuePayouts()
		if (resp.data.code == "ok") {
			return true
		} else {
			return false
		}
	},
	async [ActionTypes.UPDATE_RESTAURANT_PAYOUT](store, payload) {
		let payout_id = payload.id
		let apiPayload = {
			payout: { restaurant_email_enabled: payload.sendReceipt }
		}
		const resp = await finance_service.updateRestaurantPayout(
			payout_id,
			apiPayload
		)
		if (resp.data.code == "ok") {
			return true
		} else {
			return false
		}
	},
	async [ActionTypes.GET_BUSINESS_INVOICES]({ commit }, payload) {
		try {
			const response = await search_service.getBusinessBillingInvoices(
				payload
			)
			commit(MutationTypes.GOT_BUSINESS_INVOICES, response)
			return response.status == 200
		} catch (e) {
			vue.bugsnag.notify("Failed to get business invoices", (event) => {
				event.addMetadata("error", {
					error: e
				})
			})
			return false
		}
	},
	async [ActionTypes.ADD_BUSINESS_INVOICE_RECORD]({ commit }, payload) {
		try {
			commit(MutationTypes.LOADING)
			const response = await finance_service.addBusinessInvoiceRecord(
				payload.invoice.id,
				{
					description: payload.description,
					amount: payload.amount
				}
			)
			if (response.status == 200) {
				commit(MutationTypes.ADDED_BUSINESS_INVOICE_RECORD, payload)
			}
			commit(MutationTypes.LOADED)
			return response.status == 200
		} catch (e) {
			commit(MutationTypes.LOADED)
			vue.bugsnag.notify(
				"Failed to add invoice external record",
				(event) => {
					event.addMetadata("error", {
						error: e,
						id: payload.invoice.id
					})
				}
			)
			return false
		}
	},
	async [ActionTypes.APPROVE_BUSINESS_INVOICE]({ commit }, invoice) {
		try {
			commit(MutationTypes.LOADING)
			const response = await finance_service.approveBusinessInvoice(
				invoice.id
			)
			if (response.status == 200) {
				commit(MutationTypes.REMOVE_BUSINESS_INVOICE, invoice)
			}
			commit(MutationTypes.LOADED)
			return response.status == 200
		} catch (e) {
			commit(MutationTypes.LOADED)
			vue.bugsnag.notify("Failed to approve invoice", (event) => {
				event.addMetadata("error", {
					error: e,
					id: invoice.id
				})
			})
			return false
		}
	},
	async [ActionTypes.BATCH_APPROVE_BUSINESS_INVOICES]({ commit }, ids) {
		try {
			commit(MutationTypes.LOADING)
			const response = await finance_service.batchApproveBusinessInvoices(
				{
					business_billing_collection_ids: ids
				}
			)

			if (response.status == 200) {
				let approvedIds = ids
				if (response.data.errors.length > 0) {
					response.data.errors.forEach((error) => {
						const index = approvedIds.indexOf(
							error.business_billing_collection_id
						)
						approvedIds.splice(index, 1)
					})
				}
				commit(
					MutationTypes.BATCH_APPROVED_BUSINESS_INVOICES,
					approvedIds
				)
				commit(MutationTypes.LOADED)
				return response.data.errors.length == 0 ? "success" : "partial"
			}
			commit(MutationTypes.LOADED)
			return "error"
		} catch (e) {
			commit(MutationTypes.LOADED)
			vue.bugsnag.notify("Failed to approve invoices", (event) => {
				event.addMetadata("error", {
					error: e,
					ids: ids
				})
			})
			return "error"
		}
	},
	async [ActionTypes.COMPLETE_BUSINESS_INVOICE]({ commit }, invoice) {
		try {
			commit(MutationTypes.LOADING)
			const response = await finance_service.completeBusinessInvoice(
				invoice.id
			)
			if (response.status == 200) {
				commit(MutationTypes.REMOVE_BUSINESS_INVOICE, invoice)
			}
			commit(MutationTypes.LOADED)
			return response.status == 200
		} catch (e) {
			commit(MutationTypes.LOADED)
			vue.bugsnag.notify("Failed to complete invoice", (event) => {
				event.addMetadata("error", {
					error: e,
					id: invoice.id
				})
			})
			return false
		}
	}
}