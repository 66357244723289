<template>
  <SkBaseModal 
    :isOpen="isOpen" 
    @close="close">
    <div>
      <div class="product-image" :style="productImage">
        <div class="preview-disclaimer">
          <i class="sk-icon-eye-solid"></i>
          Preview
        </div>
      </div>
      <div class="product-body">
        <div class="product-title">
          <div>{{product.name}}</div>
          <div>{{product.cogs | currency}}</div>
        </div>
        <div class="product-description">
          {{product.description}}
        </div>
        <div class="product-addons" v-if="product.metas && product.metas.length > 0">
          <div v-for="(addon) in product.metas" 
            :key="`addon-${addon.id}`"
            :productId="product.id">
            <div class="product-addons-title">
              {{addon.key}}
              <div class="product-addons-title-required" v-if="addon.required == 1">Required</div>
            </div>
            <div 
              v-for="(value, i) in addon.values" 
              :key="`option${i}-${value.id}`" 
              class="product-addons-option">
              {{value.name}}
              <div class="product-addons-option-price" v-if="value.price">{{value.price | currency}}</div>
            </div>
          </div>
        </div>
      </div>
      <RestaurantProductFooter
        :product="product"
        :hiddenActions="[0]"
        @actionSelected="actionSelected"/>
    </div>
  </SkBaseModal>
</template>

<style scoped>

* >>> .sk-modal-content {
  padding: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

* >>> .product-footer {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

* >>> .sk-modal-body-top-radius {
  display: none;
}

.preview-disclaimer {
  position: absolute;
  top: 18px;
  left: 26px;
  color: var(--sk-white);
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.preview-disclaimer i {
  margin-right: 10px;
  font-size: 13px;
}

.product-image {
  height: 333px;
  flex-shrink: 0;
  background-position: center center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.product-title {
  display: flex;
  justify-content: space-between;
}

.product-title div {
  color: var(--sk-navy);
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 12px;
  margin-top: 15px;
}
.product-description {
  margin-bottom: 17px;
  font-size: 12px;
  color: var(--sk-grey3);
}

.product-body {
  background: var(--sk-white);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-top: -18px;
  overflow: hidden;
}

.product-addons {
  background: var(--sk-greybg);
  box-shadow: 0px 0px 2px rgba(136, 136, 136, 0.25);
}

.product-addons:last-child {
  padding-bottom: 20px;
}

.product-addons-title,
.product-addons-option {
  display: flex;
  align-items: center;
}

.product-addons-title {
  color: var(--sk-navy);
  font-size: 16px;
  font-weight: 600;
  padding-top: 20px;
}

.product-addons-title-required {
  color: var(--sk-orange);
  font-size: 12px;
  font-weight: 600;
  margin-left: 10px;
}

.product-addons-option {
  color: var(--sk-grey3);
  font-size: 12px;
  margin-top: 15px;
  display: flex;
}

.product-addons-option-price {
  color: var(--sk-grey2);
  font-size: 12px;
  font-weight: 600;
  margin-left: 10px;
}

.product-title,
.product-description,
.product-addons  {
  padding: 0 18px;
}

* >>> .product-footer {
  margin: 0;
}

@media only screen and (min-width: 500px){
  * >>> .actions-overflow {
    display: none;
  }

  * >>> .button-icon:not(.edit-button):not(.preview-allowed) {
    display: block;
  }
}

</style>

<script>
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import currencyFilterMixin from "@/mixins/currency-filter-mixin"
import RestaurantProductFooter from "@/components/restaurant/RestaurantProductFooter.vue"

export default {
  name: "PreviewProductModal",
  mixins: [skModalMixin, currencyFilterMixin],
  components: {
    SkBaseModal,
    RestaurantProductFooter
  },
  data: function() {
    return {
      product: {}
    }
  },
  computed: {
    restaurant() {
      return this.$store.state.restaurantsModule.currentRestaurant
    },
    productImage() {
      if (this.product && this.product.img && this.restaurant) {
        const filenameArr = this.product.img.split(".")
        const sizedFileName = `${filenameArr[0]}_500x500.${filenameArr[1]}`
        return {
          backgroundImage: `url('${this.$restaurantCDNURL}${this.restaurant.slug}/${sizedFileName}')`
        }
      } else {
        return ""
      }
    }
  },
  methods: {
    handleOptions(product) {
      this.product = product
    },
    actionSelected(actionId) {
      this.close()
      switch(actionId) {
        case 0:
          this.$emit("preview", this.product)
          break
        case 1:
          this.$emit("duplicate", this.product)
          break
        case 2:
          this.$emit("delete", this.product)
          break
        case 3:
          this.$emit("edit", this.product)
          break
      }
    }
  }
}
</script>