<template>
	<div
		class="sk-widget-alt sk-widget-hover dashboard-business"
		@click="goToMenu"
	>
		<div>
			<div class="business-top">
				<div class="business-summary">
					<div class="business-title">
						<div class="business-name">{{ businessName }}</div>
						<img
							v-if="businessLogo"
							:src="businessLogo"
							alt="Business Logo"
						/>
					</div>
					<div class="business-hours">
						<i class="sk-icon-clock-regular-1"></i>
						<b>Serving</b>
						{{ servingHours }}
					</div>
				</div>
				<div v-if="temporarilyClosed" class="pill-alt pill-alt-red">
					Temporarily Closed
				</div>
				<div v-else-if="nowServing" class="pill-alt pill-alt-green">
					Now Serving
				</div>
				<div v-else class="pill-alt pill-alt-baby-blue">Upcoming</div>
			</div>
		</div>
		<div class="selected-menu">
			<SkDropdownSelect
				:options="menuOptions"
				@selected="menuOptionSelected"
			>
				<span class="selected-menu-name">{{ selectedMenuName }}</span>
				<span class="selected-menu-hours">{{ selectedMenuHours }}</span>
				<i class="sk-icon-chevron-down-regular"></i>
			</SkDropdownSelect>
		</div>
	</div>
</template>

<style scoped>
.sk-widget-alt {
	padding-bottom: 12px;
	cursor: pointer;
}

.business-top {
	display: flex;
	margin-bottom: 18px;
}

.business-summary {
	flex: 1;
	margin-top: 7px;
}

.business-title {
	display: inline-flex;
}

.business-name {
	font-weight: 600;
	font-size: 14px;
	color: var(--sk-dark-navy);
	flex: 1;
	margin-bottom: 18px;
	margin-right: 15px;
}

.business-top img {
	width: 25px;
	height: 25px;
	border-radius: 2px;
	margin-left: auto;
	margin-right: 10px;
}

.business-hours {
	font-size: 10px;
	color: var(--sk-grey3);
	display: flex;
	align-items: center;
}

.business-hours i,
.business-hours b {
	margin-right: 5px;
}

.selected-menu {
	display: flex;
	align-items: center;
	color: var(--sk-grey3);
	font-size: 10px;
	border-top: 1px solid var(--sk-grey-border);
	margin: 20px -20px -12px -20px;
	padding: 12px 20px 12px 20px;
}

.selected-menu-name {
	color: var(--sk-dark-navy);
	font-weight: 600;
	margin-right: 10px;
}

.selected-menu-hours {
	flex: 1;
}

* >>> .sk-select .option-text {
	font-size: 10px;
	color: var(--sk-grey3);
}

* >>> .sk-select .option-text b {
	margin-right: 10px;
}
</style>

<script>
import moment from "moment"
import momentHelper from "@/utils/moment-helper"
import { MenuType } from "@arikgaisler/utils/enums/menuType"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"

export default {
	name: "RestaurantDashboardBusiness",
	components: {
		SkDropdownSelect
	},
	props: {
		business: Object,
		menus: Array
	},
	data: function () {
		return {
			selectedMenuId: null
		}
	},
	computed: {
		nowServing() {
			return this.business.stock_status == 1
		},
		schedules() {
			return this.menus.map((menu) => menu.schedules)
		},
		temporarilyClosed() {
			if (
				this.schedules &&
				this.schedules.length > 0 &&
				!this.business.stock_status
			) {
				const now = moment.utc().add(this.businessTimezone, "m")
				const shouldBeInStock = this.schedules.some((schedule) => {
					const openTime = moment.utc(schedule.openTime, "HH:mm:ss")
					const closeTime = moment.utc(schedule.closeTime, "HH:mm:ss")

					if (closeTime.isBefore(openTime, "m")) {
						closeTime.add(1, "d")
					}
					return now.isBetween(openTime, closeTime, "m")
				})
				if (shouldBeInStock) {
					return true
				}
			}
			return false
		},
		businessName() {
			return this.business ? this.business.name : ""
		},
		businessLogo() {
			if (
				this.business &&
				this.business.logo &&
				this.business.logo != ""
			) {
				let logoSuffix =
					this.business.logo && this.business.logo.length > 0
						? this.business.logo
						: `${this.business.slug}-logo.jpg`
				return `${this.$businessCDNURL}${this.business.slug}/${logoSuffix}`
			}
			return null
		},
		businessTimezone() {
			return this.business ? this.business.timezone_offset : 0
		},
		selectedMenuName() {
			return this.selectedMenu ? this.selectedMenu.name : ""
		},
		menuHours() {
			const menuHours = {}
			this.menus.forEach((menu) => {
				const menuData = menu.data
				if (menuData.type == MenuType.POPUP) {
					const event =
						this.$store.state.restaurantDashboardModule
							.todaysEvents[menuData.event_id]
					if (event) {
						const openTimeMoment = moment
							.utc(`${event.start_time}`, "HH:mm:ss")
							.add(this.businessTimezone, "m")
						const closeTimeMoment = moment
							.utc(`${event.end_time}`, "HH:mm:ss")
							.add(this.businessTimezone, "m")
						menuHours[menuData.id] = `${openTimeMoment.format(
							"h:mm A"
						)} - ${closeTimeMoment.format("h:mm A")}`
					}
				} else {
					menuHours[menuData.id] = menu.schedules
						.slice()
						.sort((dailyHourA, dailyHourB) => {
							if (dailyHourA == -1 || dailyHourB == -1) {
								return -1
							}

							if (dailyHourA.dow != dailyHourB.dow) {
								return dailyHourA.dow - dailyHourB.dow
							}

							const openTimeANum = momentHelper.numericalTime(
								dailyHourA.openTime
							)
							const openTimeBNum = momentHelper.numericalTime(
								dailyHourB.closeTime
							)

							return openTimeANum - openTimeBNum
						})
						.reduce((range, schedule) => {
							let openTime = moment
								.utc(schedule.openTime, "HH:mm:ss")
								.format("h:mm A")
							let closeTime = moment
								.utc(schedule.closeTime, "HH:mm:ss")
								.format("h:mm A")
							if (openTime == "12:00 AM") {
								openTime = "Midnight"
							}
							if (closeTime == "12:00 AM") {
								closeTime = "Midnight"
							}
							if (range.length > 0) {
								return `${range}, ${openTime} - ${closeTime}`
							} else {
								return `${openTime} - ${closeTime}`
							}
						}, "")
				}
			})
			return menuHours
		},
		selectedMenuHours() {
			return this.menuHours[this.selectedMenuId]
		},
		menuOptions() {
			return this.menus.map((menu) => {
				return {
					id: menu.data.id,
					text: `<b>${menu.data.name}</b> ${
						this.menuHours[menu.data.id]
					}`
				}
			})
		},
		selectedMenu() {
			const menu = this.menus.find(
				(menu) => menu.data.id == this.selectedMenuId
			)
			if (menu) {
				return menu.data
			}
			return null
		},
		servingHours() {
			const servingRanges = []
			const dailyHours = this.menus
				.map((menu) => {
					let hours = []
					if (menu.data.type == MenuType.POPUP) {
						const event =
							this.$store.state.restaurantDashboardModule
								.todaysEvents[menu.data.event_id]
						if (event) {
							hours.push({
								open_time: moment
									.utc(event.start_time, "HH:mm:ss")
									.add(this.businessTimezone, "m")
									.format("HH:mm:ss"),
								close_time: moment
									.utc(event.end_time, "HH:mm:ss")
									.add(this.businessTimezone, "m")
									.format("HH:mm:ss"),
								dow: momentHelper.normalizeDayOfWeek(
									moment
										.utc(event.start_time, "HH:mm:ss")
										.add(this.businessTimezone, "m")
								)
							})
						}
					} else if (menu.schedules && menu.schedules.length > 0) {
						hours = menu.schedules.map((schedule) => {
							return {
								open_time: schedule.openTime,
								close_time: schedule.closeTime,
								dow: schedule.dow
							}
						})
					}

					return hours
				})
				.flat()
				.sort((dailyHourA, dailyHourB) => {
					const openTimeANum = momentHelper.numericalTime(
						dailyHourA.open_time
					)
					const openTimeBNum = momentHelper.numericalTime(
						dailyHourB.open_time
					)
					return openTimeANum - openTimeBNum
				})

			dailyHours.forEach((dailyHour) => {
				if (
					!dailyHour ||
					!dailyHour.open_time ||
					!dailyHour.close_time
				) {
					return
				}

				if (servingRanges.length == 0) {
					servingRanges.push({
						openTime: dailyHour.open_time,
						closeTime: dailyHour.close_time,
						dow: dailyHour.dow
					})
				} else {
					const dailyHourBounds = {
						openTime: dailyHour.open_time,
						closeTime: dailyHour.close_time,
						dow: dailyHour.dow
					}
					const fitsWithinBounds = servingRanges.find(
						(range, index) => {
							const withinRangeBounds =
								momentHelper.isWithinBounds(
									range,
									dailyHourBounds
								)
							const withinDailyHourBounds =
								momentHelper.isWithinBounds(
									dailyHourBounds,
									range
								)
							if (
								withinRangeBounds.openTimeInBounds &&
								withinRangeBounds.closeTimeInBounds
							) {
								return true
							} else if (
								withinDailyHourBounds.openTimeInBounds &&
								withinDailyHourBounds.closeTimeInBounds
							) {
								servingRanges[index] = dailyHourBounds
								return true
							} else if (
								withinRangeBounds.openTimeInBounds &&
								!withinRangeBounds.closeTimeInBounds
							) {
								servingRanges[index].closeTime =
									dailyHourBounds.closeTime
								return true
							}
							return false
						}
					)

					if (!fitsWithinBounds) {
						servingRanges.push(dailyHourBounds)
					}
				}
			})

			return servingRanges
				.sort(
					(dailyHourA, dailyHourB) => dailyHourA.dow - dailyHourB.dow
				)
				.map((range) => {
					let openTime = moment
						.utc(range.openTime, "HH:mm:ss")
						.format("h:mm A")
					let closeTime = moment
						.utc(range.closeTime, "HH:mm:ss")
						.format("h:mm A")

					if (openTime == "12:00 AM" && closeTime == "12:00 AM") {
						return "All Day"
					}

					if (range.openTime == "12:00 AM") {
						openTime = "Midnight"
					}

					if (closeTime == "12:00 AM") {
						closeTime = "Midnight"
					}
					return `${openTime} - ${closeTime}`
				})
				.join(", ")
		}
	},
	methods: {
		goToMenu() {
			const restaurant =
				this.$store.state.restaurantDashboardModule.restaurant
			this.$router.push({
				path: `/restaurant/${restaurant.id}/menu/${this.selectedMenuId}`
			})
		},
		menuOptionSelected(menuOption) {
			this.selectedMenuId = menuOption.id
		}
	},
	watch: {
		menus: {
			immediate: true,
			handler(menus) {
				const menuIds = menus.map((menu) => menu.data.id)
				if (
					this.menus.length > 0 &&
					!menuIds.includes(this.selectedMenuId)
				) {
					this.selectedMenuId = menuIds[0]
				}
			}
		}
	}
}
</script>