import utils from "@/utils/utils"

let salesToolResultMixin = {
	computed: {
		resultName() {
			if (!this.result) {
				return ""
			}

			if (this.result.business) {
				return this.result.business.name
			} else if (this.result.restaurant) {
				return this.result.restaurant.name
			}
			return this.result.name
		},
		resultAddress() {
			if (!this.result) {
				return ""
			}

			if (this.result.business) {
				return `${this.result.business.address}, ${this.result.business.city}, ${this.result.business.state} ${this.result.business.zip}`
			} else if (this.result.restaurant) {
				return `${this.result.restaurant.address}, ${this.result.restaurant.city}, ${this.result.restaurant.state} ${this.result.restaurant.zip}`
			} else if (this.result.address) {
				return this.result.address
			}
			return ""
		},
		resultDistance() {
			return this.result ? utils.formatNumber(this.result.distance) : ""
		},
		isInNetwork() {
			return this.result && (this.result.business || this.result.restaurant) ? true : false
		},
		isHubPartner() {
			if (!this.result) {
				return false
			}
			if (this.result.business) {
				return this.result.business.hub_location_id
			} else if (this.result.restaurant) {
				return this.result.restaurant.hub_location_id
			}
			return false
		},
		accuracy() {
			if (!this.result) {
				return null
			}

			if (this.result.accuracy > 0) {
				return utils.formatNumber(this.result.accuracy)
			}
			return null
		},
		rating() {
			return this.result && this.result.avg_score ? utils.formatNumber(this.result.avg_score) : 0
		},
		pairings() {
			if (this.result && this.isInNetwork) {
				if (this.result.business) {
					let business =
						this.$store.getters.businessesMap[this.result.business.id]
					if (business && business.menus) {
						const pairings = []
						const restaurantIds = []
						business.menus.forEach((menu) => {
							const restaurant = this.$store.state.restaurants[
								menu.restaurant_id
							]
							if (!restaurantIds.includes(menu.restaurant_id)) {
								restaurantIds.push(menu.restaurant_id)
								pairings.push({
									id: menu.restaurant_id,
									name: restaurant ? restaurant.name : "N/A"
								})
							}
						})
						return pairings
					}
				} else if (this.result.restaurant) {
					return this.$store.getters.businessRestaurantPairings[this.result.restaurant.id]
				}
			}
			return null
		}
	}
}

export default salesToolResultMixin