import { BusinessType } from "@arikgaisler/utils/enums/businessType"

let businessTypeFormatterMixin = {
	filters: {
		businessTypeName: function (businessType) {
			switch (businessType) {
				case BusinessType.BAR:
					return "Drinking Place"
				case BusinessType.HOTEL:
					return "Hotel"
				case BusinessType.RESIDENTIAL:
					return "Residential"
				case BusinessType.COWORKING:
					return "Coworking"
				case BusinessType.AIRPORT:
					return "Airport"
			}
			return ""
		}
	}
}

export default businessTypeFormatterMixin
