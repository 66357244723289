export const GOT_EVENTS = "GOT_EVENTS"
export const UPDATED_EVENT_STATUS = "UPDATED_EVENT_STATUS"
export const UPDATED_EVENT_FULFILLMENT_STATUS = "UPDATED_EVENT_FULFILLMENT_STATUS"
export const SCHEDULED_EVENT = "SCHEDULED_EVENT"
export const UPDATED_EVENT = "UPDATED_EVENT"
export const UPDATED_EVENT_NOTES = "UPDATED_EVENT_NOTES"
export const DELETED_EVENT = "DELETED_EVENT"
export const ENDED_EVENT = "ENDED_EVENT"
export const STOPPED_EVENT_PRE_ORDERS = "STOPPED_EVENT_PRE_ORDERS"
export const GOT_EVENT = "GOT_EVENT"
export const GOT_EVENT_ORDERS = "GOT_EVENT_ORDERS"
export const GOT_EVENT_MENUS = "GOT_EVENT_MENUS"
export const SET_EVENTS_FILTERS = "SET_EVENTS_FILTERS"
export const GOT_EVENT_RESTAURANT_MENUS = "GOT_EVENT_RESTAURANT_MENUS"
export const GOT_EVENT_RESTAURANT_MENU_PRODUCTS = "GOT_EVENT_RESTAURANT_MENU_PRODUCTS"
export const GOT_EVENT_REMINDERS = "GOT_EVENT_REMINDERS"
export const UPDATED_EVENT_REMINDER = "UPDATED_EVENT_REMINDER"
export const ADDED_EVENT_REMINDER = "ADDED_EVENT_REMINDER"
export const DELETED_EVENT_REMINDER = "DELETED_EVENT_REMINDER"

export default {
  GOT_EVENTS,
  UPDATED_EVENT_STATUS,
  UPDATED_EVENT_FULFILLMENT_STATUS,
  SCHEDULED_EVENT,
  UPDATED_EVENT,
  UPDATED_EVENT_NOTES,
  UPDATED_EVENT_REMINDER,
  ADDED_EVENT_REMINDER,
  DELETED_EVENT_REMINDER,
  DELETED_EVENT,
  ENDED_EVENT,
  STOPPED_EVENT_PRE_ORDERS,
  GOT_EVENT,
  GOT_EVENT_ORDERS,
  SET_EVENTS_FILTERS,
  GOT_EVENT_MENUS,
  GOT_EVENT_RESTAURANT_MENUS,
  GOT_EVENT_RESTAURANT_MENU_PRODUCTS,
  GOT_EVENT_REMINDERS
}