<template>
	<div class="sk-row">
		<div class="sk-widget-alt">
			<div class="sk-row order-summary" @click="toggleOrderDetails(true)">
				<div>
					<div
						v-if="isUpcomingOrder"
						:class="timeIndicatorClasses"
						class="pill pill-grey"
					>
						<i class="sk-icon-calendar-alt-solid"></i>
						{{ preorderDateDay }}
					</div>
					<div
						v-else
						class="pill pill-grey"
						:class="timeIndicatorClasses"
					>
						<i class="sk-icon-hourglass-half-regular"></i>
						{{ orderTime }}
					</div>
					<div class="order-identifier">{{ order.id }}</div>
					<div class="pill pill-navy" v-if="isPreOrder">
						Pre Order
					</div>
					<div class="pill pill-navy" v-if="isPopupOrder">Popup</div>
					<div class="pill pill-orange" v-if="isRecentLaunch">
						First Order
					</div>
					<div
						class="order-warning-indicator"
						v-if="showWarningIndicator"
						v-sk-tooltip="warningTooltip"
					>
						<i class="sk-icon-exclamation-triangle-solid"></i>
					</div>
				</div>
				<div>
					<div class="order-status-title">
						{{ orderStatusTitle }}
					</div>
					<div class="order-status-progress">
						<div
							:class="{
								complete: !isCancelledOrder,
								danger: isCancelledOrder
							}"
						></div>
						<div
							v-if="isUpcomingOrder"
							:class="{
								complete: received && !isCancelledOrder,
								danger:
									(!received && showDangerIndicator) ||
									isCancelledOrder
							}"
						></div>
						<div
							:class="{
								complete: accepted && !isCancelledOrder,
								danger:
									(!accepted && showDangerIndicator) ||
									isCancelledOrder
							}"
						></div>
						<div
							:class="{
								complete: acknowledged && !isCancelledOrder,
								danger:
									(!acknowledged &&
										accepted &&
										showDangerIndicator) ||
									isCancelledOrder
							}"
						></div>
						<div
							:class="{
								complete: pickedUp && !isCancelledOrder,
								danger:
									(!pickedUp &&
										acknowledged &&
										showDangerIndicator) ||
									isCancelledOrder
							}"
						></div>
						<div
							:class="{
								complete: delivered && !isCancelledOrder,
								danger:
									(!delivered &&
										pickedUp &&
										showDangerIndicator) ||
									isCancelledOrder
							}"
						></div>
					</div>
				</div>
				<SkDropdownSelect
					:options="orderActions"
					:iconClasses="'sk-icon-ellipsis-v-regular'"
					:showSelectedOption="false"
					:position="'left'"
					@selected="orderActionSelected"
				/>
			</div>
			<transition-expand>
				<div v-if="isExpanded">
					<ul class="order-tabs sk-page-tabs">
						<li
							:class="{ 'active-tab': tab.active }"
							v-for="tab in orderDetailTabs"
							:key="tab.id"
							@click="orderDetailTabClicked(tab)"
						>
							{{ tab.text }}
							<span v-if="tab.badgeCount" class="tab-badge">
								{{ tab.badgeCount }}
							</span>
						</li>
					</ul>
					<transition name="fade" mode="out-in">
						<div v-if="activeDetailsTab.id == 0">
							<div
								v-for="(suborder, index) in order.sub_orders"
								:key="index"
							>
								<SuborderHeader
									:order="order"
									:suborder="suborder"
									:business="business"
									:restaurantId="suborder.rest_id"
									:expandedSuborder="expandedSuborder"
									:index="index"
									@toggleExpand="expand(suborder)"
								/>
								<transition-expand>
									<CurrentOrderStatus
										v-if="
											(expandedSuborder &&
												suborder.id ==
													expandedSuborder.id) ||
											order.sub_orders.length == 1
										"
										:item="suborder"
										:order="order"
									/>
								</transition-expand>
							</div>
						</div>
						<div v-if="activeDetailsTab.id == 1">
							<div
								v-for="(suborder, index) in order.sub_orders"
								:key="index"
							>
								<SuborderHeader
									:order="order"
									:suborder="suborder"
									:business="business"
									:restaurantId="suborder.rest_id"
									:expandedSuborder="expandedSuborder"
									:index="index"
									@toggleExpand="expand(suborder)"
								/>
								<transition-expand>
									<OrderProducts
										v-if="
											(expandedSuborder &&
												suborder.id ==
													expandedSuborder.id) ||
											order.sub_orders.length == 1
										"
										:item="suborder"
										:order="order"
										:business="business"
									/>
								</transition-expand>
							</div>
						</div>
						<div v-if="activeDetailsTab.id == 2">
							<OrderActions
								:order="order"
								:orderActions="orderActions"
								@actionSelected="orderActionSelected"
							/>
						</div>
						<div v-if="activeDetailsTab.id == 3">
							<div
								v-for="(suborder, index) in suborderWarnings"
								:key="index"
							>
								<SuborderHeader
									:order="order"
									:suborder="suborder.suborder"
									:business="business"
									:restaurantId="suborder.suborder.rest_id"
									:expandedSuborder="expandedSuborder"
									:index="index"
									@toggleExpand="expand(suborder)"
								/>
								<transition-expand>
									<OrderWarnings
										:warnings="suborder.warnings"
									/>
								</transition-expand>
							</div>
						</div>
						<div v-if="activeDetailsTab.id == 4">
							<OrderNotes :notes="notes" />
							<button
								class="button button-primary button-add-note"
								position="right"
								@click="openNotes"
							>
								Add Note
							</button>
						</div>
					</transition>
				</div>
			</transition-expand>
		</div>
		<div class="order-assignee">
			<button
				class="sk-widget-alt button button-text"
				@click="claimOrder"
			>
				<i class="sk-icon-eye-solid"></i>
				{{ claimedUser }}
			</button>
		</div>
	</div>
</template>

<style scoped>
.sk-widget-alt {
	overflow: visible;
}

.order-warning-indicator {
	padding: 7px;
}

.order-warning-indicator i {
	color: var(--sk-yellow);
}

.order-assignee button {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	min-height: 75px;
	max-height: 75px;
	width: 100%;
	border: 1px solid var(--sk-grey);
	border-radius: 5px;
}

.order-assignee button i {
	margin-right: 0;
	margin-bottom: 10px;
}

.note-widget + .note-widget {
	margin-top: 15px;
}

.note-widget {
	font-size: 12px;
	color: var(--sk-grey2);
	display: flex;
	align-items: center;
}

.note-name {
	display: flex;
	align-items: center;
	flex-shrink: 0;
	font-weight: 600;
	margin-right: 15px;
}

.note-name i {
	margin-right: 15px;
}

.note-description {
	flex-grow: 1;
}

.note-widget > .button {
	display: flex;
	align-items: center;
	min-height: 30px;
	font-size: 10px;
	padding: 5px 15px;
	white-space: nowrap;
	margin-left: 15px;
}

.note-widget > .button i {
	margin-left: 15px;
	margin-right: 0;
	font-size: 10px;
}

.button.button-primary.button-add-note {
	width: 118px;
	min-height: 35px;
	font-size: 10px;
	padding: 10px 15px;
	margin-top: 8px;
	margin-left: auto;
	display: flex;
	justify-content: center;
}

.button.button-primary.button-add-note .selected {
	font-size: 12px;
	color: var(--sk-white);
}

.button.button-primary.button-add-note i {
	margin-right: 0;
}
</style>

<script>
import utils from "@/utils/utils"
import { CourierId } from "@arikgaisler/utils/enums/courierId"
import moment from "moment"
import orderMixin from "@/mixins/order-mixin"
import orderComponentMixin from "@/mixins/order-component-mixin"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"
import TransitionExpand from "@/components/transitions/TransitionExpand.vue"
import SuborderHeader from "@/components/orders/SuborderHeader.vue"
import CurrentOrderStatus from "@/components/currentorders/CurrentOrderStatus.vue"
import OrderProducts from "@/components/orders/OrderProducts.vue"
import OrderActions from "@/components/orders/OrderActions.vue"
import OrderWarnings from "@/components/orders/OrderWarnings.vue"
import OrderNotes from "@/components/orders/OrderNotes.vue"

export default {
	name: "CurrentOrder",
	mixins: [orderMixin, orderComponentMixin],
	components: {
		SkDropdownSelect,
		TransitionExpand,
		SuborderHeader,
		CurrentOrderStatus,
		OrderProducts,
		OrderActions,
		OrderWarnings,
		OrderNotes
	},
	props: {
		order: Object
	},
	computed: {
		businessId() {
			return this.order ? this.order.business_id : null
		},
		orderDetailTabs() {
			const tabs = [
				{
					id: 0,
					text: "Status"
				},
				{
					id: 1,
					text: "Details"
				},
				{
					id: 2,
					text: "Actions"
				},
				{
					id: 3,
					text: "Warnings",
					badgeCount: this.warnings.length
				},
				{
					id: 4,
					text: "Notes",
					badgeCount: this.notes.length
				}
			]

			tabs.forEach((tab) => (tab.active = tab.id == this.activeTabId))

			return tabs
		},
		activeDetailsTab() {
			return this.orderDetailTabs.find((tab) => tab.active)
		},
		orderActions() {
			const actions = [
				{
					id: 0,
					text: "Send Survey"
				},
				{
					id: 1,
					text: "Send Receipt"
				},
				{
					id: 2,
					text: "Issue Coupon"
				},
				{
					id: 3,
					text: "Mark Exception"
				},
				{
					id: 4,
					text: "Issue Refund"
				},
				{
					id: 5,
					text: "Create Delivery"
				},
				{
					id: 12,
					text: "Repush Order"
				},
				{
					id: 11,
					text: "Add Note"
				}
			]

			if (!this.needsSupervisor) {
				actions.push({
					id: 6,
					text: "Supervisor Review"
				})
			} else {
				actions.push({
					id: 7,
					text: "Remove Supervisor Review"
				})
			}

			if (!this.isSuppressed) {
				actions.push({
					id: 8,
					text: "Disable Survey"
				})
			} else {
				actions.push({
					id: 9,
					text: "Enable Survey"
				})
			}
			if (!this.isCancelledOrder) {
				actions.push({
					id: 10,
					text: "Cancel Order"
				})
			}
			return actions
		},
		preorderDateDay() {
			return this.orderScheduledDate
				? this.orderScheduledDate.format("MMMM DD")
				: ""
		},
		timeIndicatorClasses() {
			if (this.delivered) {
				return "pill-green"
			} else if (this.showDangerIndicator) {
				return "pill-red"
			} else {
				return "pill-grey"
			}
		},
		warningTooltip() {
			if (this.warnings && this.warnings.length > 0) {
				let warnings = this.warnings.map(
					(warning) => `<div>${warning.text}</div>`
				)
				return `<div>${warnings.join("")}</div>`
			}
			return null
		},
		showWarningIndicator() {
			return this.warnings.length > 0
		},
		showDangerIndicator() {
			if (this.order.cancelled == 1) {
				return true
			}
			if (!this.orderTimeStarted) {
				return false
			}

			if (!this.accepted && this.orderTimeinMins >= 5) {
				return true
			}

			if (
				!this.pickedUp &&
				!this.acknowledged &&
				this.orderTimeinMins >= 15
			) {
				return true
			}
			if (!this.pickedUp && this.orderTimeinMins >= 30) {
				return true
			}

			if (!this.delivered && this.orderTimeinMins >= 45) {
				return true
			}

			return false
		},
		orderTime() {
			if (this.order.cancelled == 1) {
				return "N/A"
			}
			return this.orderEndTime.from(moment.utc(this.orderStartTime), true)
		},
		orderTimeinMins() {
			return this.orderEndTime.diff(
				moment.utc(this.orderStartTime),
				"minutes"
			)
		},
		received() {
			const allSubordersReceived = this.order.sub_orders.every(
				(suborder) => {
					return (
						suborder.received_time && suborder.received_time != ""
					)
				}
			)
			return this.order.sub_orders.length > 0 && allSubordersReceived
		},
		accepted() {
			const allSubordersAccepted = this.order.sub_orders.every(
				(suborder) => {
					return suborder.accept_time && suborder.accept_time != ""
				}
			)
			return this.order.sub_orders.length > 0 && allSubordersAccepted
		},
		acknowledged() {
			const allSubordersAcknowledged = this.order.sub_orders.every(
				(suborder) => {
					if (suborder.courier_id == CourierId.WALKING) {
						return (
							suborder.accept_time && suborder.accept_time != ""
						)
					} else {
						return (
							suborder.acknowledged_time &&
							suborder.acknowledged_time != ""
						)
					}
				}
			)
			return this.order.sub_orders.length > 0 && allSubordersAcknowledged
		},
		pickedUp() {
			const allSubordersPickedUp = this.order.sub_orders.every(
				(suborder) => {
					if (suborder.courier_id == CourierId.WALKING) {
						return (
							suborder.completed_time &&
							suborder.completed_time != ""
						)
					} else {
						return (
							suborder.pickup_time && suborder.pickup_time != ""
						)
					}
				}
			)
			return this.order.sub_orders.length > 0 && allSubordersPickedUp
		},
		delivered() {
			const allSubordersDelivered = this.order.sub_orders.some(
				(suborder) => {
					if (suborder.courier_id == CourierId.WALKING) {
						return (
							suborder.completed_time &&
							suborder.completed_time != ""
						)
					} else {
						return (
							suborder.delivered_time &&
							suborder.delivered_time != ""
						)
					}
				}
			)
			return this.order.sub_orders.length > 0 && allSubordersDelivered
		},
		claimedUser() {
			if (this.order.admin_user_id == this.$store.state.user_id) {
				const user = this.$store.state.user
				return user ? user.display_name : "N/A"
			} else if (this.order.admin_user_id) {
				const user = this.$store.getters.adminUsersMap[this.order.admin_user_id]
				return user ? user.display_name : "N/A"
			}
			return "Claim"
		},
		isSuppressed() {
			return this.order.suppress_msgs == 1
		},
		needsSupervisor() {
			return this.order.needs_supervisor == 1
		},
		isRecentLaunch() {
			if (this.business) {
				const launchDate = this.business.launch_date
				return launchDate
					? moment().diff(launchDate, "weeks") < 2
					: true
			}
			return false
		},
		orderStatusTitle() {
			if (this.isCancelledOrder) {
				return "Cancelled"
			} else if (this.delivered) {
				return "Delivered"
			} else if (this.pickedUp) {
				return "Awaiting Delivery Confirmation"
			} else if (this.acknowledged) {
				return "Awaiting Courier Pickup"
			} else if (this.accepted) {
				return "Awaiting Courier Acknowledge"
			} else {
				return "Awaiting Order Accept"
			}
		},
		notes() {
			let notes = []
			this.order.sub_orders.forEach((suborder) => {
				const restaurant =
					this.$store.state.restaurants[suborder.rest_id]
				if (restaurant && restaurant.notes) {
					let phone = restaurant.phone_number
						? restaurant.phone_number.split(";")[0]
						: ""
					notes.push({
						type: 0,
						name: restaurant.name,
						description: restaurant.notes,
						iconClass: "sk-icon-utensils-solid",
						actionText: "Call Restaurant",
						phone: phone
					})
				}
			})
			if (this.business && this.business.notes) {
				let phone = this.business.phone_number
					? this.business.phone_number.split(";")[0]
					: ""
				notes.push({
					type: 1,
					name: this.business.name,
					description: this.business.notes,
					iconClass: "sk-icon-building-solid",
					actionText: "Call Business",
					phone: phone
				})
			}
			if (this.order.notes && this.order.notes.length > 0) {
				this.order.notes.forEach((note) => {
					const userDisplayName = this.$store.getters.adminUsersMap[
						note.created_by
					]
						? this.$store.getters.adminUsersMap[note.created_by]
								.display_name
						: "N/A"
					notes.push({
						name: userDisplayName,
						description: note.text,
						iconClass: "sk-icon-receipt-solid"
					})
				})
			}
			return notes
		}
	},
	methods: {
		openNotes() {
			this.$emit("openNotes", this.order)
		},
		claimOrder: function () {
			this.$store.dispatch("ordersModule/claimOrder", this.order)
		},
		updateSupervisor: function (status) {
			this.$store.dispatch("ordersModule/updateSupervisor", [
				this.order.id,
				status
			])
		},
		updateSuppress: function (supress) {
			this.$store.dispatch("ordersModule/updateSuppress", [
				this.order.id,
				supress
			])
		},
		orderActionSelected(action) {
			switch (action.id) {
				case 0:
					this.$emit("openSurvey", this.order)
					break
				case 1:
					this.$emit("openReceipt", this.order)
					break
				case 2:
					this.$emit("openCoupon", this.order)
					break
				case 3:
					this.$emit("openException", this.order)
					break
				case 4:
					this.$emit("openRefund", this.order)
					break
				case 5:
					this.$emit("openDelivery", this.order)
					break
				case 6:
					this.updateSupervisor(1)
					break
				case 7:
					this.updateSupervisor(0)
					break
				case 8:
					this.updateSuppress(1)
					break
				case 9:
					this.updateSuppress(0)
					break
				case 10:
					this.$emit("openCancel", this.order)
					break
				case 11:
					this.$emit("openNotes", this.order)
					break
				case 12: {
					this.$emit("repushOrder", this.order)
					break
				}
			}
		},
		orderDetailTabClicked(tab) {
			this.activeTabId = tab.id
		},
		noteActionClicked(note) {
			utils.openPhoneLink(note.phone)
		}
	}
}
</script>