<template>
  <div class="restaurant-product-addon">
    <div class="sk-row-inner">
      <button class="button button-icon" 
        @click="removeAddon">
        <i class="sk-icon-trash-alt-solid"></i>
      </button>
      <SkInput
        :name="'Add-on Name'" 
        :placeholder="'Addon'"
        v-model="addon.key"
        @change="$emit(`edited`)"/>
      <SkToggle 
        v-model="inStock"
        :disabled="isNewProduct"/>
    </div>
    <div class="sk-row-inner addon-line-item-padded">
      <SkDropdownSelect
        :classes="'sk-input'"
        :options="maxAllowedOptions" 
        v-model="maxAllowedOption"
        @change="$emit(`edited`)"/>
      <SkDropdownSelect
        :classes="'sk-input'"
        :options="requiredOptions"
        v-model="isRequired"
        @change="$emit(`edited`)"/>
    </div>
    <div class="sk-divider"></div>
    <draggable
				v-model="addonValues"
				@end="onValueMoveEnd"
				:move="checkMove"
        :draggable="'.draggable'"
			>
      <div class="sk-row-inner" v-for="(value, i) in addonValues" :key="`option${i}-${value.id}`" :class="{draggable: reorderingEnabled}">
        <button v-if="reorderingEnabled" class="button button-icon">
          <i class="sk-icon-bars-regular"></i>
        </button>
        <button v-else class="button button-icon" 
          @click="removeOption(i)">
          <i class="sk-icon-times-regular"></i>
        </button>
        <SkInput
          :name="'Add-on Option'" 
          :placeholder="'Option'"
          @change="$emit(`edited`)"
          v-model="value.name"/>
        <SkInput
          :name="'Upcharge'" 
          :placeholder="'1.00'"
          :type="'number'"
          @change="$emit(`edited`)"
          v-model="value.price"/>
        <SkToggle 
          v-model="value.stock_status"
          :disabled="isNewProduct"
          @input="updateValueStockStatus(value, ...arguments)"/>
      </div>
    </draggable>
    <div class="sk-row-inner">
      <button class="button button-text sk-widget-section-text-button-light" @click="addOption">
        <i class="sk-icon-plus-regular"></i>
        New Option
      </button>
    </div>
    <div class="sk-row-inner">
      <button class="button button-text sk-widget-section-text-button-light" @click="toggleReordering">
        <i :class="reorderingIcon"></i>
        {{ reorderingText }}
      </button>
    </div>
  </div>
</template>

<style scoped>
.restaurant-product-addon + .restaurant-product-addon {
  margin-top: 0;
}

.restaurant-product-addon {
  background: var(--sk-greybg);
  margin-left: -20px;
  margin-right: -20px;
}

.addon-line-item-padded {
  padding-right: 60px;
  padding-left: 40px;
}

.button-icon {
  max-width: 20px;
  color: var(--sk-grey2);
  padding: 0;
}

.button-icon i {
  font-size: 15px;
}
</style>

<script>
import SkInput from "@/components/SkInput.vue"
import SkToggle from "@/components/SkToggle.vue"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"
import draggable from "vuedraggable"

export default {
  name: "RestaurantMenuProductAddon",
  components: {
    SkToggle,
    SkDropdownSelect,
    SkInput,
    draggable
  },
  props: {
    addon: Object,
    productId: Number,
    isNewProduct: Boolean
  },
  data: function() {
    return {
      requiredOptions: [{
        id: 0,
        text: "Not Required"
      },
      {
        id: 1,
        text: "Required"
      }],
      reorderingEnabled: false
    }
  },
  computed: {
    addonValues: {
      get: function() {
        return this.addon.values.slice().sort((a, b) => {
          if (a.position == null && b.position == null) {
						return 0
          } else if (a.position == null) {
            return 1
          } else if (b.position == null) {
            return -1
          } else {
            return a.position - b.position
          }
        })
      },
      set: function() {}
    },
    inStock: {
      get: function() {
        if (!this.addon.values || this.addon.values.length == 0) {
          return true
        }
        return this.addon.values.some(value => value.stock_status == 1)
      },
      set: async function(value) {
        this.updateStockStatus(value)
      }
    },
    isRequired: {
      get: function() {
        return this.requiredOptions.find(option => option.id == this.addon.required)
      },
      set: function(value) {
        if (value.id != null) {
          this.addon.required = value.id
        }
      }
    },
    maxAllowedOption: {
      get: function() {
        if (this.addon.max_allowed_values > this.maxAllowedOptions.length) {
          return this.maxAllowedOptions[this.maxAllowedOptions.length - 1]
        }
        return this.maxAllowedOptions.find(option => option.id == this.addon.max_allowed_values)
      },
      set: function(value) {
        if (value.id) {
          this.addon.max_allowed_values = value.id
        }
      }
    },
    maxAllowedOptions() {
      let options = [{
        text: "Unlimited",
        id: null
      }]
      this.addon.values.forEach((value, i) => {
        options.push({
          text: `${i + 1}`,
          id: i + 1
        })
      })

      return options
    },
    reorderingText() {
      return this.reorderingEnabled ? "Set Options Order" : "Reorder Options"
    },
    reorderingIcon() {
      return this.reorderingEnabled ? "sk-icon-save-solid" : "sk-icon-bars-regular"
    }
  },
  methods: {
    removeAddon() {
      this.$emit("remove")
      this.$emit("edited")
    },
    removeOption(index) {
      const optionToRemove = this.addonValues[index]
      const optionIndex = this.addon.values.findIndex(option => option.id == optionToRemove.id)
      if (optionIndex > -1) {
        this.addon.values.splice(optionIndex, 1)
        this.$emit("edited")
      }
    },
    addOption() {
      const option = {
        price: 0, 
        name: "",
        id: this.addon.values.length + 1,
        newOption: true
      }
      this.addon.values.push(option)
      this.$emit("edited")
    },
    toggleReordering() {
      this.reorderingEnabled = !this.reorderingEnabled
    },
    checkMove() {
			return this.reorderingEnabled
    },
    onValueMoveEnd(event) {
			if (event.oldIndex != event.newIndex) {
				const addonId = this.addonValues[event.oldIndex].id
				const order = this.addonValues
					.filter(value => value.id != addonId)
          .map(value => value.id)
          
				order.splice(event.newIndex, 0, addonId)

        this.addon.values.forEach(value => {
          if (order.indexOf(value.id) >= 0) {
            value.position = order.indexOf(value.id)
          }
        })
        this.$emit("edited")
			}
		},
    async updateStockStatus(stockStatus) {
      try {
        if (this.addon.values && this.addon.values.length > 0) {
          this.$emit("loading", true)
          const success = await this.$store.dispatch("restaurantsModule/updateProductMetaStock", {
            productId: this.productId,
            metaId: this.addon.id,
            status: stockStatus
          })
          this.$emit("loading", false)
          if (!success) {
            throw "Failed to Update Meta Stock Status"
          }
          this.addon.values.forEach(value => value.stock_status = stockStatus ? 1 : 0)
        }
      } catch (e) {
        this.$emit("loading", false)
        throw e
      }
    },
    async updateValueStockStatus(value, stockStatus) {
      try {
        this.$emit("loading", true)
        const success = await this.$store.dispatch("restaurantsModule/updateProductMetaValueStock", {
          productId: this.productId,
          valueId: value.id,
          metaId: this.addon.meta_id,
          status: stockStatus
        })
        if (!success) {
          throw "Failed to Update Meta Value Stock Status"
        }
        this.$emit("loading", false)
      } catch (e) {
        this.$emit("loading", false)
        value.stock_status = !stockStatus
        throw e
      }
    }
  }
}
</script>
