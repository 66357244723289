import { EventStatus } from "@arikgaisler/utils/enums/eventStatus"
import { BusinessEventType } from "@arikgaisler/utils/enums/businessEventType"
import { PreOrderFulfillmentStatus } from "@arikgaisler/utils/enums/preOrderFulfillmentStatus"
import moment from "moment"
import { PermissionMethod } from "@/utils/permissions"

let eventStatusMixin = {
	computed: {
		isCancelable() {
			return (
				this.$store.state.permissions.includes(PermissionMethod.CANCEL_EVENT) &&
				this.event.status != EventStatus.CANCELLED &&
				this.event.status != EventStatus.ONGOING &&
				this.event.status != EventStatus.COMPLETED &&
				this.event.status != EventStatus.PREORDER &&
				this.event.status != EventStatus.PRE_ORDER_FULFILLMENT
			)
		},
		isEditable() {
			return (
				this.$store.state.permissions.includes(PermissionMethod.EDIT_EVENT) &&
				this.event.status != EventStatus.CANCELLED &&
				this.event.status != EventStatus.ONGOING &&
				this.event.status != EventStatus.COMPLETED &&
				this.event.status != EventStatus.PREORDER &&
				this.event.status != EventStatus.PRE_ORDER_FULFILLMENT
			)
		},
		isConfirmable() {
			if (this.$store.state.permissions.includes(PermissionMethod.CONFIRM_EVENT)) {
				let isConfirmable = false
				let hasValidDates = false
				let isInTheFuture = false
				if (this.event.status && this.event.status == EventStatus.IN_REVIEW) {
					let date = null
					let hasName = this.event.name && this.event.name.length > 0
					let hasProducts = this.event.products && this.event.products.length > 0
					isConfirmable = hasName && this.event.restaurant_id != null && this.event.business_id != null && this.event.menu_id != null && hasProducts
					if (this.event.type == BusinessEventType.VIRTUAL) {
						hasValidDates = this.event.end_date != null && this.event.pre_order_end_time != null && this.event.end_time != null
						date = this.event.end_date
					} else if (this.event.type == BusinessEventType.POPUP) {
						hasValidDates = this.event.start_date != null && this.event.start_time != null && this.event.end_time != null
						date = this.event.start_date
					}
					isInTheFuture = date && moment.utc(date).isBefore(moment.utc().startOf("day")) == false
					return isConfirmable && hasValidDates && isInTheFuture
				}
			}
			return false
		},
		isDeletable() {
			return this.$store.state.permissions.includes(PermissionMethod.DELETE_EVENT) && this.event.status == EventStatus.CANCELLED
		},
		isAcceptable() {
			return (
				this.$store.state.permissions.includes(PermissionMethod.ACCEPT_EVENT) &&
				this.event.type == BusinessEventType.VIRTUAL &&
				(this.event.status == EventStatus.PRE_ORDER_FULFILLMENT || this.event.status == EventStatus.COMPLETED) &&
				this.event.pre_order_fulfillment_status == PreOrderFulfillmentStatus.UNACCEPTED
			)
		},
		isCompletable() {
			return (
				this.$store.state.permissions.includes(PermissionMethod.COMPLETE_EVENT) &&
				this.event.type == BusinessEventType.VIRTUAL &&
				(this.event.status == EventStatus.PRE_ORDER_FULFILLMENT || this.event.status == EventStatus.COMPLETED) &&
				this.event.pre_order_fulfillment_status == PreOrderFulfillmentStatus.IN_PROGRESS
			)
		},
		isDeliverable() {
			return (
				this.$store.state.permissions.includes(PermissionMethod.DELIVER_EVENT) &&
				this.event.type == BusinessEventType.VIRTUAL &&
				this.event.pre_order_fulfillment_status == PreOrderFulfillmentStatus.COMPLETED
			)
		},
		canBeEnded() {
			return (
				this.$store.state.permissions.includes(PermissionMethod.END_EVENT) &&
				this.event.status == EventStatus.ONGOING
			)
		},
		canStopPreOrders() {
			return (
				this.event.pre_order_end_time != null && this.event.end_time != null && this.event.status == EventStatus.PREORDER
			)
		},
		statusLabel() {
			if (this.event.status != null) {
				switch (this.event.status) {
					case EventStatus.REQUESTED:
						return "Requested"
					case EventStatus.IN_REVIEW:
						return "In Review"
					case EventStatus.CONFIRMED:
						return "Confirmed"
					case EventStatus.ONGOING:
						return "Ongoing"
					case EventStatus.PREORDER:
						return "Preordering"
					case EventStatus.PRE_ORDER_FULFILLMENT:
						return "Delivering"
					case EventStatus.COMPLETED:
						return "Completed"
					case EventStatus.CANCELLED:
						return "Cancelled"
				}
			}
			return "Unassigned"
		},
		statusClasses() {
			if (this.event.status != null) {
				switch (this.event.status) {
					case EventStatus.REQUESTED:
						return "status-dot grey"
					case EventStatus.IN_REVIEW:
						return "status-dot yellow"
					case EventStatus.CONFIRMED:
						return "status-dot green"
					case EventStatus.ONGOING:
					case EventStatus.PREORDER:
					case EventStatus.PRE_ORDER_FULFILLMENT:
						return "status-dot green-pulsating"
					case EventStatus.COMPLETED:
						return "status-dot navy"
					case EventStatus.CANCELLED:
						return "status-dot red"
				}
			}
			return ""
		},
		statusBadgeClasses() {
			if (this.event.status != null) {
				switch (this.event.status) {
					case EventStatus.REQUESTED:
						return "pill-alt pill-alt-grey"
					case EventStatus.IN_REVIEW:
						return "pill-alt pill-alt-yellow"
					case EventStatus.CONFIRMED:
					case EventStatus.ONGOING:
					case EventStatus.PREORDER:
					case EventStatus.PRE_ORDER_FULFILLMENT:
						return "pill-alt pill-alt-green"
					case EventStatus.COMPLETED:
						return "pill-alt pill-alt-navy"
					case EventStatus.CANCELLED:
						return "pill-alt pill-alt-red"
				}
			}
			return ""
		},
		allowedActions() {
			let actions = []
			if (this.isConfirmable && this.isEditable) {
				actions.push({
					text: `<i class="sk-icon-pencil-solid"></i> Edit`,
					id: 1
				})
			}

			if (this.isCancelable) {
				actions.push({
					text: `<i class="sk-icon-times-regular"></i> Cancel`,
					id: 0
				})
			}

			if (this.isDeletable) {
				actions.push({
					text: `<i class="sk-icon-undo-regular"></i> Restore`,
					id: 5
				})
			}

			if (actions.length > 0) {
				actions.push({
					text: `<i class="sk-icon-eye-solid"></i> View`,
					id: 2
				})
			}

			if (this.$store.state.permissions.includes(PermissionMethod.NEW_EVENT)) {
				actions.push({
					text: `<i class="sk-icon-copy-solid"></i> Duplicate Event`,
					id: 9
				})
			}

			if (this.isAcceptable) {
				actions.push({
					text: `<div class="event-action-option"><i class="sk-icon-bullseye-regular"></i> Mark Accepted</div>`,
					id: 6
				})
			} else if (this.isCompletable) {
				actions.push({
					text: `<div class="event-action-option"><i class="sk-icon-bullseye-regular"></i> Mark Picked Up</div>`,
					id: 7
				})
			} else if (this.isDeliverable) {
				actions.push({
					text: `<div class="event-action-option"><i class="sk-icon-bullseye-regular"></i> Mark Delivered</div>`,
					id: 8
				})
			}

			if (this.canBeEnded) {
				actions.push({
					text: `<div class="event-action-option"><i class="sk-icon-bullseye-regular"></i> End Event</div>`,
					id: 10
				})
			}
			if (this.canStopPreOrders) {
				actions.push({
					text: `<div class="event-action-option"><i class="sk-icon-times-regular"></i> Stop Pre Orders</div>`,
					id: 11
				})
			}

			return actions.length > 0 ? actions : null
		}
	},
	methods: {
		actionSelected(action) {
			switch (action.id) {
				case 0:
					this.$emit(
						"updateEventStatus",
						action.event,
						action.businessName,
						EventStatus.CANCELLED
					)
					break
				case 1:
					this.$emit("edit", action.event)
					break
				case 2:
					this.$router.push({ path: `/event/${action.event.id}` })
					break
				case 3:
					this.$emit("deleteEvent", action.event, action.businessName)
					break
				case 4:
					this.$emit(
						"updateEventStatus",
						action.event,
						action.businessName,
						EventStatus.CONFIRMED
					)
					break
				case 5:
					this.$emit(
						"updateEventStatus",
						action.event,
						action.businessName,
						EventStatus.IN_REVIEW
					)
					break
				case 6:
					this.$emit(
						"updateEventFulfillmentStatus",
						action.event,
						action.businessName,
						PreOrderFulfillmentStatus.IN_PROGRESS
					)
					break
				case 7:
					this.$emit(
						"updateEventFulfillmentStatus",
						action.event,
						action.businessName,
						PreOrderFulfillmentStatus.COMPLETED
					)
					break
				case 8:
					this.$emit(
						"updateEventFulfillmentStatus",
						action.event,
						action.businessName,
						PreOrderFulfillmentStatus.DELIVERED
					)
					break
				case 9:
					this.$emit("duplicateEvent", action.event)
					break
				case 10:
					this.$emit("endEvent", action.event, action.businessName)
					break
				case 11:
					this.$emit("stopEventPreOrders", action.event)
					break
			}
		}
	}
}

export default eventStatusMixin
