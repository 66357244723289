<template>
	<div class="sk-page-content">
		<PageHeader
			:title="'Guest'"
			:tabs="tabs"
			:actions="actions"
			:secondaryActions="secondaryActions"
			:breadcrumbs="breadcrumbs"
			@actionClicked="headerActionClicked"
			@secondaryActionClicked="secondaryHeaderActionClicked"
			@tabClicked="headerTabClicked"
		>
			<div slot="title-decorator" class="guest-name-title">
				{{ businessGuestName }}
			</div>
		</PageHeader>
		<transition name="slide" mode="out-in">
			<BusinessGuestDetails key="0" v-if="activeTab.id == 0" />
			<BusinessGuestOrders key="1" v-if="activeTab.id == 1" />
		</transition>
		<BusinessGuestModal ref="newBusinessGuestModal" />
		<ConfirmationModal ref="businessGuestConfirmationModal" />
		<BusinessGuestVoucherModal ref="sendBusinessGuestVoucherModal" />
	</div>
</template>

<style scoped>
.guest-name-title {
	font-size: 24px;
	margin-left: 5px;
	color: var(--sk-dark-navy);
}
</style>

<script>
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import ActionTypes from "@/store/modules/users/action-types"
import loaderMixin from "@/mixins/loader-mixin"
import businessGuestUserActions from "@/mixins/users/business-guest-actions-mixin"
import PageHeader from "@/components/PageHeader.vue"
import BusinessGuestDetails from "@/components/users/BusinessGuestDetails.vue"
import BusinessGuestOrders from "@/components/users/BusinessGuestOrders.vue"
import BusinessGuestModal from "@/components/modals/businessGuests/BusinessGuestModal.vue"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue"
import BusinessGuestVoucherModal from "@/components/modals/businessGuests/BusinessGuestVoucherModal.vue"

export default {
	name: "BusinessGuest",
	mixins: [loaderMixin, businessGuestUserActions],
	components: {
		PageHeader,
		BusinessGuestDetails,
		BusinessGuestOrders,
		BusinessGuestModal,
		ConfirmationModal,
		BusinessGuestVoucherModal
	},
	data: function () {
		return {
			didMount: false,
			activeTabId: 0,
			loading: false
		}
	},
	mounted: function () {
		this.loading = true
		this.$store
			.dispatch(
				`usersModule/${ActionTypes.GET_CURRENT_BUSINESS_GUEST}`,
				this.$route.params.guest_id
			)
			.finally(() => {
				this.loading = false
				if (
					this.$store.state.usersModule.currentBusinessGuest == null
				) {
					this.$router.push({ path: "/users" })
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Guest Error",
						message: "Guest not found",
						type: NotificationType.ERROR
					})
				}
			})
	},
	methods: {
		headerTabClicked(tabClicked) {
			this.activeTabId = tabClicked.id
		},
		headerActionClicked(action) {
			switch (action.id) {
				case 0: {
					this.openEditBusinessGuestModal(this.businessGuest)
					break
				}
			}
		},
		secondaryHeaderActionClicked(action) {
			switch (action.id) {
				case 0: {
					this.sendBusinessGuestDigitalMenu(this.businessGuest)
					break
				}
				case 1: {
					this.deleteBusinessGuest(this.businessGuest)
					break
				}
				case 2: {
					this.sendBusinessGuestVoucherClicked(this.businessGuest)
					break
				}
				case 3: {
					this.openEditBusinessGuestModal(this.businessGuest)
					break
				}
			}
		}
	},
	computed: {
		businessGuest() {
			return this.$store.state.usersModule.currentBusinessGuest
		},
		businessGuestName() {
			return this.businessGuest ? this.businessGuest.name : ""
		},
		tabs() {
			let allowedTabs = [
				{
					id: 0,
					text: "Details"
				},
				{
					id: 1,
					text: "Orders"
				}
			]

			return allowedTabs
				.map((tab) => {
					tab.active = tab.id == this.activeTabId
					return tab
				})
				.sort((a, b) => a.id - b.id)
		},
		actions() {
			switch (this.activeTab.id) {
				case 0: {
					return [
						{
							id: 0,
							text: `<i class="sk-icon-pencil-solid"></i> Edit Guest`,
							classes: "button button-primary"
						}
					]
				}
			}
			return []
		},
		secondaryActions() {
			switch (this.activeTab.id) {
				case 0: {
					return [
						{
							text: `<i class="sk-icon-pencil-solid"></i> Edit`,
							id: 3
						},
						{
							text: `<i class="sk-icon-check-regular"></i> Send Digital Menu`,
							id: 0
						},
						{
							text: `<i class="sk-icon-ticket-alt-solid"></i> Send Voucher`,
							id: 2
						},
						{
							text: `<i class="sk-icon-trash-alt-solid"></i> Delete`,
							id: 1
						}
					]
				}
			}
			return null
		},
		activeTab() {
			return this.tabs.find((tab) => tab.active)
		},
		breadcrumbs() {
			return [
				{
					name: "Users",
					link: "/users"
				},
				{
					name: this.businessGuestName
				}
			]
		}
	},
	watch: {
		loading: function (newVal) {
			this.toggleLoader(newVal)
		}
	}
}
</script>