export const UPDATED_ORDER_TRANSACTION_STATUS = "UPDATED_ORDER_TRANSACTION_STATUS"
export const UPDATED_CURRENT_ORDER_REFUND = "UPDATED_CURRENT_ORDER_REFUND"
export const UPDATED_CURRENT_ORDER_EXCEPTION = "UPDATED_CURRENT_ORDER_EXCEPTION"
export const RECALCULATED_CURRENT_ORDERS = "RECALCULATED_CURRENT_ORDERS"
export const GOT_ORDER_ACTIONS = "GOT_ORDER_ACTIONS"
export const GOT_ORDER_DELIVERIES = "GOT_ORDER_DELIVERIES"
export const GOT_ORDER_DELIVERY = "GOT_ORDER_DELIVERY"

export default {
    UPDATED_ORDER_TRANSACTION_STATUS,
    UPDATED_CURRENT_ORDER_REFUND,
    UPDATED_CURRENT_ORDER_EXCEPTION,
    RECALCULATED_CURRENT_ORDERS,
    GOT_ORDER_ACTIONS,
    GOT_ORDER_DELIVERIES,
    GOT_ORDER_DELIVERY
}