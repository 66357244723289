<template>
	<SkBaseModal :isOpen="isOpen" @close="close" class="sk-modal-alt">
		<div class="sk-modal-alt-title">
			<h1>
				{{ titleTxt }}
			</h1>
		</div>
		<div class="sk-row">
			<TypeaheadInput
				:placeholder="'Business'"
				:fetch="autocompleteBusinesses"
				:serializer="typeaheadSerializer"
				:query="business.name"
				@selected="businessSelected"
				:disabled="this.user.id > 0"
			/>
		</div>
		<div class="sk-row">
			<SkInput
				ref="nameInput"
				:name="'Name'"
				:required="true"
				v-model="user.name"
			/>
		</div>
		<div class="sk-row">
			<SkInput
				ref="emailInput"
				:name="'Email'"
				:type="'string'"
				v-model="user.email"
			/>
		</div>
		<div class="sk-row">
			<SkInput
				ref="phoneInput"
				:name="'Phone Number'"
				:type="'tel'"
				:required="true"
				v-model="user.phone"
			/>
		</div>
		<div class="sk-row">
			<SkInput
				ref="roleInput"
				:name="'Role'"
				:type="'string'"
				v-model="user.title"
			/>
		</div>
		<div id="email-error" v-if="error">
			{{ errMsg }}
		</div>
		<div class="sk-modal-actions">
			<button @click="close" class="button button-text">
				Close
			</button>
			<button
				type="button"
				class="button button-success"
				@click="saveStaffUser"
			>
				<i class="sk-icon-check-regular"></i>
				{{ btnText }}
			</button>
		</div>
	</SkBaseModal>
</template>

<script>
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import SkInput from "@/components/SkInput.vue"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"
import TypeaheadInput from "@/components/TypeaheadInput.vue"
import phoneFilterMixin from "@/mixins/phone-filter-mixin"

export default {
	name: "StaffUserModal",
	mixins: [skModalMixin, phoneFilterMixin],
	components: {
		SkBaseModal,
		SkInput,
		SkDropdownSelect,
		TypeaheadInput
	},
	data: function() {
		return {
			error: false,
			errMsg: "",
			business_id: 0,
			user: {
				id: 0,
				business_id: 0,
				name: "",
				title: "",
				email: "",
				phone: ""
			}
		}
	},
	computed: {
		business() {
			var retVal = {}
			if (this.user.business_id > 0) {
				retVal = this.$store.getters.businessesMap[this.user.business_id]
			}
			return retVal
		},
		btnText() {
			return this.user.id && this.user.id > 0 ? "Update" : "Add"
		},
		titleTxt() {
			return this.user.id && this.user.id > 0
				? "Update Staff Member"
				: "Add Staff Member"
		}
	},
	methods: {
		closed() {
			this.user = {
				id: 0,
				business_id: 0,
				name: null,
				title: null,
				email: null,
				phone: null
			}
		},
		handleOptions(options) {
			if (options && options.user) {
				this.user.id = options.user.id
				this.user.name = options.user.name
				this.user.title = options.user.title
				this.user.email = options.user.email
				this.user.is_active = options.user.is_active
				this.user.business_id = options.user.business_id
				this.user.phone = options.user.phone
			}
		},
		typeaheadSerializer(result) {
			return {
				text: result.name
			}
		},
		async businessSelected(event) {
			this.user.business_id = event.id
		},
		validateStaffData() {
			const validName = this.$refs.nameInput.validate()
			const validEmail = this.$refs.emailInput.validate()
			const validPhone = this.$refs.phoneInput.validate()
			if (this.user.business_id == null || this.user.business_id == 0) {
				throw "Please select a business"
			}
			if (!validName) {
				throw "Please enter a valid name"
			}
			if (!validEmail && !validPhone) {
				if (!validEmail) {
					throw "Please enter a valid email"
				}
				if (!validPhone) {
					throw "Please enter a valid phone number"
				}
			}
			return true
		},
		async autocompleteBusinesses(query) {
			const results = await this.$store.dispatch(
				"businessesModule/autocompleteBusinesses",
				query
			)
			return results
		},
		async saveStaffUser() {
			try {
				this.validateStaffData()
				if (this.user.id > 0) {
					const resp = await this.$store.dispatch(
						"businessesModule/updateBusinessEmployee",
						this.user
					)
					if (resp) {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Successfully updated staff details"
						})
						this.close()
					}
				} else {
					const resp = await this.$store.dispatch(
						"businessesModule/addBusinessEmployee",
						this.user
					)
					if (resp) {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Successfully added staff member"
						})
						this.close()
					} else {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Something went wrong, please try again",
							type: NotificationType.WARNING
						})
					}
				}
			} catch (e) {
				this.bugsnag.notify("Unable to update details", event => {
					event.addMetadata("error", {
						error: e
					})
				})
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title:
						e && !e.message
							? e
							: "Something went wrong, please try again",
					type: NotificationType.WARNING
				})
			}
		}
	}
}
</script>