<template>
	<div class="signup-footer">
		<div
			v-for="link in links"
			v-html="link.text"
			:key="link.id"
			@click="linkClicked(link)"
		></div>
	</div>
</template>

<style scoped>
.signup-footer {
	margin-top: 40px;
	height: 50px;
	border-top: 1px solid var(--sk-grey);
	background: var(--sk-greybg2);
	display: flex;
	align-items: center;
	color: var(--sk-grey2);
	cursor: pointer;
}

.signup-footer div {
	font-size: 12px;
	flex: 1;
	text-align: center;
	position: relative;
}

.signup-footer div + div::before {
	content: "";
	width: 1px;
	height: 33px;
	background: var(--sk-grey);
	display: inline-block;
	position: absolute;
	left: 1px;
	top: -8px;
}

* >>> .signup-link {
	color: var(--sk-dark-navy);
	font-weight: 600;
	margin-left: 5px;
}
</style>

<script>
export default {
	name: "SignupWidgetFooter",
	props: {
		links: {
			type: Array,
			default: () => []
		}
	},
	methods: {
		linkClicked(link) {
			// tracker.trackEvent("Signup Clicked")
			// window.open("https://2ndkitchen.com/")
			this.$emit("linkClicked", link)
		}
	}
}
</script>