<template>
	<div class="vld-parent" :class="disabledDateClasses">
		<loading :active.sync="loading" :is-full-page="false"></loading>
		<InsightBaseWidget
			:footerIcon="'sk-icon-lightbulb-light'"
			:footerTitle="'Learn more about utilizing this information'"
			:footerLinkText="'View'"
			@footerClicked="footerClicked"
		>
			<div class="header-title" slot="title">
				<b>Top Orders</b>
				Day of Week
			</div>
			<SkChart
				slot="content"
				:type="'Line'"
				:data="chartData"
				:options="chartOptions"
				:tooltip="true"
				:eventHandlers="eventHandlers"
			/>
		</InsightBaseWidget>
	</div>
</template>

<style scoped>
.header-title {
	font-size: 14px;
	color: var(--sk-dark-navy);
}
</style>

<script>
import chartist from "chartist"
import InsightBaseWidget from "@/components/insights/InsightBaseWidget.vue"
import Loading from "vue-loading-overlay"
import SkChart from "@/components/SkChart.vue"

export default {
	name: "TopOrdersByDowChart",
	components: {
		InsightBaseWidget,
		Loading,
		SkChart
	},
	props: {
		footerArticleId: String
	},
	data: function () {
		return {
			chartOptions: {
				height: "230px",
				fullWidth: true,
				axisX: {
					offset: 35,
					labelOffset: {
						x: -10,
						y: 10
					}
				},
				axisY: {
					offset: 0,
					showLabel: false,
					showGrid: false
				},
				chartPadding: {
					top: 20,
					right: 5,
					bottom: 0,
					left: 5
				},
				showArea: true,
				showLine: false,
				lineSmooth: false
			},
			eventHandlers: [
				{
					event: "draw",
					fn(event) {
						if (event.type === "area") {
							event.element.animate({
								d: {
									begin: 2000 * event.index,
									dur: 2000,
									from: event.path
										.clone()
										.scale(1, 0)
										.translate(0, event.chartRect.height())
										.stringify(),
									to: event.path.clone().stringify(),
									easing: chartist.Svg.Easing.easeOutQuint
								}
							})
						} else if (event.type === "point") {
							if (
								event.series &&
								event.series[event.index] == ""
							) {
								event.element.attr({
									opacity: 0
								})
							} else {
								let delay = 1000 + event.index * 20
								event.element.animate({
									opacity: {
										begin: delay,
										dur: 1000,
										from: 0,
										to: 1,
										easing: chartist.Svg.Easing.easeOutQuint
									}
								})
							}
						}
					}
				}
			]
		}
	},
	computed: {
		loading() {
			return this.$store.state.insightsModule.topOrdersByDow.loading
		},
		chartData() {
			const data = this.$store.state.insightsModule.topOrdersByDow.data
			let series = []
			let labels = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]

			if (data && data.totals) {
				const dowMap = data.totals.reduce((dowMap, total) => {
					dowMap[total.dayOfWeek] = total.avg
					return dowMap
				}, {})
				labels.forEach((label, index) => {
					let dow = index + 1
					if (dowMap[dow] != undefined) {
						series.push(dowMap[dow])
					} else {
						series.push("")
					}
				})
			}

			return {
				series: [series],
				labels: labels
			}
		},
		disabledDows() {
			const data = this.$store.state.insightsModule.topOrdersByDow.data
			if (data && data.totals) {
				let weeklyDows = ["1", "2", "3", "4", "5", "6", "7"]
				const daysAvailable = data.totals.map(
					(total) => total.dayOfWeek
				)
				return weeklyDows.filter((dow) => !daysAvailable.includes(dow))
			}
			return []
		},
		disabledDateClasses() {
			const data = this.$store.state.insightsModule.topOrdersByDow.data
			if (data && data.totals) {
				let disabledClassMap = {
					1: "monday-disabled",
					2: "tuesday-disabled",
					3: "wednesday-disabled",
					4: "thursday-disabled",
					5: "friday-disabled",
					6: "saturday-disabled",
					7: "sunday-disabled"
				}

				let disabledDows = ""
				this.disabledDows.forEach((dow) => {
					disabledDows += `${disabledClassMap[dow]} `
				})
				return disabledDows
			}
			return ""
		}
	},
	methods: {
		footerClicked() {
			$crisp.push([
				"do",
				"helpdesk:article:open",
				["en", this.footerArticleId]
			])
		}
	}
}
</script>