<template>
	<div class="full-height-table-wrapper">
		<div class="sk-table sk-table-default">
			<div class="sk-table-header">
				<div class="sk-table-row">
					<div>Code</div>
					<div>Value</div>
					<div>Business</div>
					<div>Validity Dates</div>
					<div>Payment</div>
					<div>Distribution</div>
					<div>Status</div>
					<div>Actions</div>
				</div>
			</div>
			<transition-group
				name="list"
				tag="div"
				class="sk-table-body"
				appear
			>
				<VoucherLineItem
					v-for="voucherRequestVoucher in pagedVoucherRequestVouchers"
					:voucher="voucherRequestVoucher"
					:key="voucherRequestVoucher.id"
					@printVoucher="printVoucher"
					@sendVoucherEmail="sendVoucherEmail"
				/>
			</transition-group>
		</div>
		<PaginationFooter
			:contentText="'Vouchers'"
			:pageSize="20"
			:total="totalVouchersCount"
			ref="paginationFooter"
			@pageChanged="filterPage"
		/>
		<ConfirmationModal ref="confirmationModal"></ConfirmationModal>
		<SendVoucherEmailModal ref="sendVoucherEmailModal" />
	</div>
</template>

<script>
import ActionTypes from "@/store/modules/coupons/action-types"
import PageHeader from "@/components/PageHeader.vue"
import PaginationFooter from "@/components/PaginationFooter.vue"
import VoucherLineItem from "@/components/coupon/VoucherLineItem.vue"
import loaderMixin from "@/mixins/loader-mixin"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue"
import SendVoucherEmailModal from "@/components/modals/SendVoucherEmailModal.vue"
import voucherActionsMixin from "@/mixins/vouchers/voucher-actions-mixin"

export default {
	name: "Vouchers",
	mixins: [loaderMixin, voucherActionsMixin],
	components: {
		PageHeader,
		VoucherLineItem,
		PaginationFooter,
		ConfirmationModal,
		SendVoucherEmailModal
	},
	props: {
		voucherRequest: Object,
		filters: Object
	},
	data: function () {
		return {
			pageRange: {
				page: 1,
				start: 0,
				end: 20
			}
		}
	},
	computed: {
		voucherRequestVouchers() {
			var arr = this.$store.state.couponsModule.voucherRequestVouchers
			if (this.filters && this.filters.code != "") {
				arr = arr.filter((voucher) => voucher.code == this.filters.code)
			}
			return arr
		},
		pagedVoucherRequestVouchers() {
			return this.voucherRequestVouchers.slice(
				this.pageRange.start,
				this.pageRange.end
			)
		},
		totalVouchersCount() {
			return this.$store.state.couponsModule.voucherRequestVouchers.length
		}
	},
	mounted: async function () {
		this.fetchVoucherRequestOrders()
	},
	methods: {
		filterPage: function (range) {
			this.pageRange = range
		},
		fetchVoucherRequestOrders() {
			this.toggleLoader(true)
			this.$store
				.dispatch(
					`couponsModule/${ActionTypes.GET_VOUCHER_REQUEST_VOUCHERS}`,
					this.voucherRequest.id
				)
				.finally(() => {
					this.toggleLoader(false)
				})
		}
	}
}
</script>