import Vue from 'vue'
import { parseResponse } from "@arikgaisler/utils/api-utils"
import { store } from './store/store'

let host = process.env.VUE_APP_API_HOST

const BASE_API = `https://orders${host}.2ndkitchen.com`

const PRICING_API = `https://pricing${host}.2ndkitchen.com`

const BUSINESS_API = `https://business${host}.2ndkitchen.com`

const PAYMENT_API = `https://payment${host}.2ndkitchen.com`

const NOTIFICATION_API = `https://notification${host}.2ndkitchen.com`

const DELIVERY_API = `https://delivery${host}.2ndkitchen.com`

const FORKLIFT_API = `https://forklift${host}.2ndkitchen.com`

const FEED_API = `https://feed${host}.2ndkitchen.com`

export default {
    async getBusinesses() {
        const tokenStr = 'Bearer ' + store.state.user_token
        return Vue.axios.get(`${BUSINESS_API}/businesses?include=kiosks,menus`, { 'headers': { 'Authorization': tokenStr } })
    },
    async getBusinessesByIds(ids) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.get(`${BUSINESS_API}/businesses?include=kiosks,menus&ids=${ids}`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async updateBusiness(business_id, payload) {
        return Vue.axios.put(`${BUSINESS_API}/businesses/${business_id}`, payload);
    },
    async getCoupons() {
        return Vue.axios.get(`${PRICING_API}/coupons`);
    },
    async getCoupon(coupon_id) {
        return Vue.axios.get(`${PRICING_API}/coupons/${coupon_id}`);
    },
    async getRefundTypes() {
        const tokenStr = 'Bearer ' + store.state.user_token
        return Vue.axios.get(`${PAYMENT_API}/refunds_reasons`, { 'headers': { 'Authorization': tokenStr } });
    },
    async getDeliveryReasons() {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.get(`${DELIVERY_API}/deliveries_reasons`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getOrdersCountByDates(start,end, business_id, restaurant_id) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.get(`${BASE_API}/orders/count?start=${start}&end=${end}&business_id=${business_id}&rest_id=${restaurant_id}`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getOrdersScoreAvgsByDates(start,end, business_id) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return Vue.axios.get(`${BASE_API}/orders/scores?start=${start}&end=${end}&business_id=${business_id}`, { 'headers': { 'Authorization': tokenStr } });
    },
    async getBusinessInsights(payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.post(`${BUSINESS_API}/insights`,payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async rebootKiosk(kiosk_id) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return Vue.axios.put(`${BUSINESS_API}/kiosks/${kiosk_id}/reboot`, null, { 'headers': { 'Authorization': tokenStr } });
    },
    async launchBusiness(business_id) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return Vue.axios.post(`${BUSINESS_API}/businesses/${business_id}/launch`, null, { 'headers': { 'Authorization': tokenStr } });
    },
    async getExceptions() {
        const tokenStr = 'Bearer ' + store.state.user_token
        return Vue.axios.get(`${BASE_API}/order_exceptions?status=0`, { 'headers': { 'Authorization': tokenStr } });
    },
    async setExceptionStatus(exception_id,payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.put(`${BASE_API}/exceptions/${exception_id}/status`, payload, { 'headers': { 'Authorization': tokenStr } }))
    },
    async getOrderRefunds(order_ids) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.get(`${PAYMENT_API}/refunds?order_ids=${order_ids}`,{ 'headers': { 'Authorization': tokenStr } }))
    },
    async getRefunds() {
        const tokenStr = 'Bearer ' + store.state.user_token
        return Vue.axios.get(`${PAYMENT_API}/refunds/search?status=0`,{ 'headers': { 'Authorization': tokenStr } });
    },
    async sendReceipt(payload, order_id) {
        return parseResponse(Vue.axios.post(`${NOTIFICATION_API}/orders/${order_id}/receipt`, payload))
    },
    async cancelDelivery(delivery_id) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return Vue.axios.delete(`${DELIVERY_API}/deliveries/${delivery_id}/cancel`, { 'headers': { 'Authorization': tokenStr } });
    },
    async sendBusinessRestaurantRequest(business_id,payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return Vue.axios.post(`${BUSINESS_API}/businesses/${business_id}/restaurants/requests`,payload, { 'headers': { 'Authorization': tokenStr } });
    },
    async getBusinessTips(business_id) {
        const tokenStr = 'Bearer ' + store.state.user_token
        if (business_id > 0)
            return Vue.axios.get(`${BUSINESS_API}/businesses_commissions?businesses_ids=${business_id}`, { 'headers': { 'Authorization': tokenStr } });
        else
            return Vue.axios.get(`${BUSINESS_API}/businesses_commissions`, { 'headers': { 'Authorization': tokenStr } });
    },
    async payoutBusinessEmployee(payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return Vue.axios.post(`${BUSINESS_API}/businesses_employees_tip_payouts`, payload, { 'headers': { 'Authorization': tokenStr } });
    },
    async searchBusinessEmployeeTips(payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return Vue.axios.post(`${BUSINESS_API}/businesses_employees_tip_payouts/search`, payload, { 'headers': { 'Authorization': tokenStr } })
    },
    async activateEmployee(payload) {
        return Vue.axios.post(`${BUSINESS_API}/businesses_employees/activate`, payload)
    },
    async sendEvent(payload) {
        return parseResponse(Vue.axios.post(`${FORKLIFT_API}/events`, payload))
    },
    async getFeed(payload) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.get(`${FEED_API}/feeds/me?limit=${payload.limit}&offset=${payload.offset}`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async readFeedActivities(payload) {
        const tokenStr = "Bearer " + store.state.user_token
        return parseResponse(Vue.axios.post(`${FEED_API}/activities/read`, payload, { 'headers': { 'Authorization': tokenStr } }))
    }
}