
<template>
	<div class="sk-page-content">
		<PageHeader
			:title="'Events'"
			:tabs="tabs"
			:actions="actions"
			:filters="filters"
			@filterSet="filterSet"
			@allFiltersReset="filtersReset"
			@actionClicked="headerActionClicked"
			@tabClicked="headerTabClicked"
		/>
		<EventsList
			v-if="activeTabId == 0"
			ref="eventsList"
			@updateEventStatus="updateEventStatus"
			@updateEventFulfillmentStatus="updateEventFulfillmentStatus"
			@duplicateEvent="duplicateEvent"
			@edit="editEvent"
			@deleteEvent="deleteEvent"
			@endEvent="endEvent"
			@stopEventPreOrders="stopEventPreOrders"
			@resetFilters="filtersReset"
		/>
		<EventsCalendar
			v-else-if="activeTabId == 1"
			@updateEventStatus="updateEventStatus"
			@updateEventFulfillmentStatus="updateEventFulfillmentStatus"
			@duplicateEvent="duplicateEvent"
			@edit="editEvent"
			@deleteEvent="deleteEvent"
			@filterByDateRange="filterByDate"
			@endEvent="endEvent"
			@createEvent="createEventWithDate"
		/>
		<ConfirmationModal ref="confirmationModal" />
		<EditEventModal ref="editEventModal" />
	</div>
</template>
  
<script>
import { PermissionMethod } from "@/utils/permissions"
import { EventStatus } from "@arikgaisler/utils/enums/eventStatus"
import { BusinessEventType } from "@arikgaisler/utils/enums/businessEventType"
import ActionTypes from "@/store/modules/events/action-types"
import loaderMixin from "@/mixins/loader-mixin"
import eventActionsMixin from "@/mixins/events/event-actions-mixin"
import PageHeader from "@/components/PageHeader.vue"
import EventsList from "@/components/events/EventsList.vue"
import EventsCalendar from "@/components/events/EventsCalendar.vue"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue"
import EditEventModal from "@/components/modals/EditEventModal.vue"

export default {
	name: "Events",
	mixins: [loaderMixin, eventActionsMixin],
	components: {
		PageHeader,
		ConfirmationModal,
		EventsList,
		EventsCalendar,
		EditEventModal
	},
	data: function () {
		return {
			activeTabId: 0
		}
	},
	computed: {
		actions() {
			if (!this.$store.state.permissions.includes(PermissionMethod.NEW_EVENT)) {
				return []
			}
			return [
				{
					id: 0,
					text: `<i class="sk-icon-plus-regular"></i> New Event`,
					classes: "button button-primary"
				}
			]
		},
		filters() {
			let filters = [
				{
					id: 0,
					placeholder: "Status",
					type: "dropdown",
					items: [
						{
							text: `<span class="status-dot orange"></span> All`,
							id: -1
						},
						{
							text: `<span class="status-dot grey"></span> Requested`,
							id: EventStatus.REQUESTED
						},
						{
							text: `<span class="status-dot yellow"></span> In Review`,
							id: EventStatus.IN_REVIEW
						},
						{
							text: `<span class="status-dot green"></span> Confirmed`,
							id: EventStatus.CONFIRMED
						},
						{
							text: `<span class="status-dot red"></span> Cancelled`,
							id: EventStatus.CANCELLED
						},
						{
							text: `<span class="status-dot green-pulsating"></span> Ongoing`,
							id: EventStatus.ONGOING
						},
						{
							text: `<span class="status-dot green-pulsating"></span> Preordering`,
							id: EventStatus.PREORDER
						},
						{
							text: `<span class="status-dot green-pulsating"></span> Delivering`,
							id: EventStatus.PRE_ORDER_FULFILLMENT
						},
						{
							text: `<span class="status-dot navy"></span> Completed`,
							id: EventStatus.COMPLETED
						}
					]
				},
				{
					id: 4,
					placeholder: "Type",
					type: "dropdown",
					items: [
						{
							text: `Type`,
							id: null
						},
						{
							text: `All`,
							id: -1
						},
						{
							text: `On Premise`,
							id: BusinessEventType.POPUP
						},
						{
							text: `Virtual`,
							id: BusinessEventType.VIRTUAL
						}
					]
				},
				{
					id: 1,
					placeholder: "Business Name",
					type: "search",
					width: "280px",
					fetch: (query) => {
						return this.$store.dispatch(
							"businessesModule/autocompleteBusinesses",
							query
						)
					},
					serializer: (i) => {
						return { text: i.name }
					}
				},
				{
					id: 3,
					placeholder: "Restaurant Name",
					type: "search",
					fetch: (query) => {
						return this.$store.dispatch(
							"restaurantsModule/autocompleteRestaurants",
							query
						)
					},
					width: "280px",
					serializer: (i) => {
						return { text: i.name }
					}
				}
			]

			if (this.activeTabId != 1) {
				filters.push({
					id: 2,
					placeholder: "Date",
					type: "daterange"
				})
			}
			return filters
		},
		tabs() {
			let tabs = [
				{
					id: 0,
					text: "List"
				},
				{
					id: 1,
					text: "Calendar"
				}
			]
			return tabs.map((tab) => {
				tab.active = tab.id == this.activeTabId
				return tab
			})
		}
	},
	methods: {
		createEventWithDate(date) {
			if (this.$store.state.permissions.includes(PermissionMethod.NEW_EVENT)) {
				this.$refs.editEventModal.open({
					date: date
				})
			}
		},
		editEvent(event) {
			this.$refs.editEventModal.open({
				eventId: event.id
			})
		},
		filterSet(filter) {
			if (this.$refs.eventsList) {
				this.$refs.eventsList.resetPagination()
			}
			if (filter.id == 0) {
				this.$store.dispatch(
					`eventsModule/${ActionTypes.FILTER_EVENTS}`,
					{
						status: filter.value.id
					}
				)
			} else if (filter.id == 1) {
				this.$store.dispatch(
					`eventsModule/${ActionTypes.FILTER_EVENTS}`,
					{
						businessId: filter.value ? filter.value.id : 0
					}
				)
			} else if (filter.id == 2) {
				this.$store.dispatch(
					`eventsModule/${ActionTypes.FILTER_EVENTS}`,
					{
						startDate: filter.value.from,
						endDate: filter.value.to
					}
				)
			} else if (filter.id == 3) {
				this.$store.dispatch(
					`eventsModule/${ActionTypes.FILTER_EVENTS}`,
					{
						restaurantId: filter.value ? filter.value.id : 0
					}
				)
			} else if (filter.id == 4) {
				this.$store.dispatch(
					`eventsModule/${ActionTypes.FILTER_EVENTS}`,
					{
						type: filter.value ? filter.value.id : -1
					}
				)
			}
		},
		filterByDate(range) {
			this.toggleLoader(true)
			this.$store
				.dispatch(`eventsModule/${ActionTypes.FILTER_EVENTS}`, {
					startDate: range.from,
					endDate: range.to
				})
				.finally(() => {
					this.toggleLoader(false)
				})
		},
		filtersReset() {
			this.toggleLoader(true)
			if (this.$refs.eventsList) {
				this.$refs.eventsList.resetPagination()
			}
			if (this.activeTabId == 0) {
				this.$store
					.dispatch(`eventsModule/${ActionTypes.GET_EVENTS}`, {
						clear: true
					})
					.finally(() => {
						this.toggleLoader(false)
					})
			} else {
				this.$store
					.dispatch(`eventsModule/${ActionTypes.FILTER_EVENTS}`, {
						businessId: 0,
						restaurantId: 0,
						status: -1,
						type: -1
					})
					.finally(() => {
						this.toggleLoader(false)
					})
			}
		},
		headerActionClicked(action) {
			if (action.id == 0) {
				this.$refs.editEventModal.open()
			}
		},
		headerTabClicked(tabClicked) {
			this.activeTabId = tabClicked.id
		}
	}
}
</script>