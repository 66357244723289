<template>
	<div class="sk-table-row">
		<div class="sk-table-column-fit">
			{{ menu.name }}
		</div>
		<div class="sk-table-column-fit">
			{{ menu.menuTypeText }}
		</div>
		<div class="menu-schedules">
			<div v-for="(schedule, i) in menuSchedule" :key="i">
				<div class="column-subtitle">
					{{ schedule.daysText }}
				</div>
				<div class="column-title">
					{{ schedule.timesText }}
				</div>
			</div>
		</div>
		<div class="sk-table-column-fit">
			{{ menu.courierText }}
		</div>
		<div class="sk-table-pills">
			<router-link
				v-for="pairing in pairings"
				:key="pairing.id"
				:to="pairing.link"
				tag="a"
				class="pill pill-grey"
				:title="pairing.name"
			>
				<i class="sk-icon-link-regular"></i>
				{{ pairing.name }}
			</router-link>
		</div>
		<div class="sk-table-column-fit">
			<div class="sk-table-actions">
				<router-link
					tag="a"
					class="button button-icon"
					title="Edit"
					:to="editPath"
					@click.native="editRestaurantMenuClicked"
				>
					<i class="sk-icon-pencil-solid"></i>
				</router-link>
				<button
					class="button button-icon"
					title="Delete"
					@click="deleteMenu"
					v-if="canDelete"
				>
					<i class="sk-icon-trash-alt-solid"></i>
				</button>
			</div>
		</div>
	</div>
</template>

<style scoped>
.pill i {
	margin-right: 10px;
}
.sk-table-body .sk-table-row > .menu-schedules {
	white-space: normal;
}

.menu-schedules {
	padding: 20px 0;
}

.menu-schedules > div {
	display: inline-block;
	margin-right: 20px;
}
</style>

<script>
import moment from "moment"
import { PermissionMethod } from "@/utils/permissions"
import tracker from "@/utils/tracker"

export default {
	name: "RestaurantMenuLineItem",
	props: {
		menu: Object,
		pairings: {
			type: Array,
			default: () => []
		}
	},
	computed: {
		canDelete() {
			return this.$store.state.permissions.includes(PermissionMethod.DELETE_RESTAURANT_MENU)
		},
		editPath() {
			return `/restaurant/${this.menu.restaurant_id}/menu/${this.menu.id}`
		},
		menuSchedule() {
			if (this.menu.opening_hours) {
				let schedule = {}
				let scheduleSummary = []
				const daily = {
					0: "M",
					1: "T",
					2: "W",
					3: "TH",
					4: "F",
					5: "SA",
					6: "SU"
				}

				this.menu.opening_hours
					.slice()
					.sort((a, b) => a.day_of_week - b.day_of_week)
					.forEach(hour => {
						if (
							schedule[`${hour.open_time}-${hour.close_time}`] ==
							undefined
						) {
							schedule[`${hour.open_time}-${hour.close_time}`] = [
								hour.day_of_week
							]
						} else if (
							!schedule[
								`${hour.open_time}-${hour.close_time}`
							].includes(hour.day_of_week)
						) {
							schedule[
								`${hour.open_time}-${hour.close_time}`
							].push(hour.day_of_week)
						}
					})

				for (let hours in schedule) {
					let days = schedule[hours].sort()
					let isRange = days.length > 2

					for (var i = 1; i < days.length; i++) {
						let day = days[i]
						let prevDay = days[i - 1]
						if (day != prevDay + 1) {
							isRange = false
							break
						}
					}

					if (isRange) {
						days.splice(1, days.length - 2)
						days = days.map(day => daily[day])
					} else {
						days = days.map(day => daily[day])
					}
					const offset = this.$store.getters[
						"restaurantsModule/offset"
					]
					const time = hours.split("-").map(time => {
						const offsetTime = moment(time, "h:mm").add(offset, "m")
						if (offsetTime.minutes() > 0) {
							return offsetTime.format("h:mm A")
						} else {
							return offsetTime.format("h A")
						}
					})
					scheduleSummary.push({
						days: days,
						times: time,
						isRange: isRange,
						daysText: isRange ? days.join(" - ") : days.join(", "),
						timesText: time.join(" - ")
					})
				}

				return scheduleSummary
			}

			return []
		}
	},
	methods: {
		deleteMenu() {
			tracker.trackEvent("Delete Restaurant Menu Clicked")
			this.$emit("delete", this.menu)
		},
		editRestaurantMenuClicked() {
			tracker.trackEvent("Edit Restaurant Menu Clicked")
		}
	}
}
</script>