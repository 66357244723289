<template>
	<div class="total-tabbed-chart vld-parent">
		<loading :active.sync="loading" :is-full-page="false"></loading>
		<TabbedInsightWidget
			:title="'Total'"
			:tabs="tabs"
			@tabClicked="tabClicked"
		>
			<div class="total-tabbed-chart-content" slot="content">
				<SkChart
					:type="'Line'"
					:data="chartData"
					:options="chartOptions"
					:tooltip="true"
					:eventHandlers="eventHandlers"
				/>
			</div>
		</TabbedInsightWidget>
	</div>
</template>

<style>
.total-tabbed-chart .ct-label.ct-vertical {
	justify-content: flex-start;
}
</style>

<style scoped>
.total-tabbed-chart {
	display: flex;
	height: 580px;
	padding-right: 10px;
}

.total-tabbed-chart-content {
	margin-top: 50px;
	padding-bottom: 15px;
}
</style>

<script>
import chartist from "chartist"
import moment from "moment"
import TabbedInsightWidget from "@/components/insights/TabbedInsightWidget.vue"
import Loading from "vue-loading-overlay"
import SkChart from "@/components/SkChart.vue"
import utils from "@/utils/utils"

export default {
	name: "TotalTabbedWidget",
	components: {
		TabbedInsightWidget,
		Loading,
		SkChart
	},
	data: function () {
		return {
			activeTabId: 0,
			chartOptions: {
				height: "409px",
				fullWidth: true,
				axisY: {
					offset: 74,
					labelOffset: {
						x: 0,
						y: 0
					},
					onlyInteger: true,
					labelInterpolationFnc: (value) => {
						if (this.activeTabId == 0) {
							return utils.formatCurrency(value)
						}
						return value
					}
				},
				axisX: {
					offset: 100,
					labelOffset: {
						x: -10,
						y: 65
					}
				},
				chartPadding: {
					top: 20,
					right: 18,
					bottom: 0,
					left: 0
				},
				showArea: true,
				showLine: false,
				lineSmooth: false,
				transformTooltipText: (value) => {
					if (this.activeTabId == 0) {
						return utils.formatCurrency(value)
					}
					return value
				}
			},
			eventHandlers: [
				{
					event: "draw",
					fn(event) {
						if (event.type === "area") {
							event.element.animate({
								d: {
									begin: 2000 * event.index,
									dur: 2000,
									from: event.path
										.clone()
										.scale(1, 0)
										.translate(0, event.chartRect.height())
										.stringify(),
									to: event.path.clone().stringify(),
									easing: chartist.Svg.Easing.easeOutQuint
								}
							})
						} else if (event.type === "point") {
							let delay = 1000 + (event.index * 20)
							event.element.animate({
								opacity: {
									begin: delay,
									dur: 1000,
									from: 0,
									to: 1,
									easing: chartist.Svg.Easing.easeOutQuint
								}
							})
						} else if (
							event.type === "label" &&
							event.axis.units.pos === "y"
						) {
							event.element.attr({
								y: event.y + 5,
								"text-anchor": "middle"
							})
						}
					}
				}
			]
		}
	},
	computed: {
		loading() {
			switch (this.activeTabId) {
				case 0: {
					return this.$store.state.insightsModule.totalSales.loading
				}
				case 1: {
					return this.$store.state.insightsModule.totalOrders.loading
				}
			}
			return false
		},
		tabs() {
			let tabs = [
				{
					id: 0,
					text: "Sales"
				},
				{
					id: 1,
					text: "Orders"
				}
			]
			return tabs.map((tab) => {
				tab.active = tab.id == this.activeTabId
				return tab
			})
		},
		chartData() {
			let data = null
			let valueKey = ""

			switch (this.activeTabId) {
				case 0: {
					data = this.$store.state.insightsModule.totalSales.data
					valueKey = "sum"
					break
				}
				case 1: {
					data = this.$store.state.insightsModule.totalOrders.data
					valueKey = "total"
					break
				}
			}

			if (!data) {
				return null
			}
			let series = []
			let labels = []

			let labelSpacing = 0

			if (data.length > 7) {
				labelSpacing = Math.floor(data.length / 7)
			}

			data.forEach((dailyData, i) => {
				let label = moment.utc(dailyData.period).format("M/DD")
				labels.push(i % labelSpacing && i != 0 ? "" : label)
				series.push({
					meta: label,
					value: parseFloat(dailyData[valueKey]).toFixed(2)
				})
			})

			return {
				series: [series],
				labels: labels
			}
		}
	},
	methods: {
		tabClicked(tab) {
			this.activeTabId = tab.id
		}
	}
}
</script>