<template>
	<div class="sk-widget-alt sk-widget-hover dashboard-order">
		<div class="order-title">
			<div class="order-name">{{ order.user_name }}</div>
			<div class="order-id">
				<i
					v-if="this.orderScheduledDate"
					class="sk-icon-stopwatch-regular"
				></i>
				#{{ order.id }}
			</div>
		</div>
		<div v-if="!showBusinessName" class="order-detail">
			{{ tableNumber }}
		</div>
		<div v-else class="order-detail">
			{{ businessNameLine }}
		</div>
		<div class="order-detail">
			{{ orderDay }}
			<span>at</span>
			{{ orderTime }}
		</div>
		<div class="order-status">
			<div>{{ statusLabel }}</div>
			<div class="order-statuses">
				<div class="status-complete"></div>
				<div :class="{ 'status-complete': accepted }"></div>
				<div :class="{ 'status-complete': pickedUp }"></div>
				<div :class="{ 'status-complete': delivered }"></div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.sk-widget-alt {
	padding-bottom: 10px;
	cursor: pointer;
}

.order-title {
	display: flex;
	align-items: center;
	margin-bottom: 13px;
}

.order-name {
	font-weight: 600;
	font-size: 14px;
	color: var(--sk-dark-navy);
	flex: 1;
}

.order-id {
	color: var(--sk-grey3);
	font-size: 10px;
}

.order-id i {
	margin-right: 5px;
}

.order-detail {
	color: var(--sk-grey3);
	font-size: 10px;
}

.order-detail span {
	color: var(--sk-grey2);
}

.order-status {
	border-top: 1px solid var(--sk-grey-border);
	margin: 20px -20px 0 -20px;
	padding: 12px 20px 0 20px;
	font-size: 10px;
	color: var(--sk-grey2);
	font-style: italic;
}

.order-statuses {
	display: flex;
	margin-top: 10px;
}

.order-statuses > div {
	flex: 1;
	height: 5px;
	background: var(--sk-grey);
}

.order-statuses > div + div {
	margin-left: 15px;
}

.order-statuses .status-complete {
	background: var(--sk-green);
}
</style>

<script>
import { EntityType } from "@/enums/entityType"
import orderHelper from "@/utils/order-helper"
import moment from "moment"
import activeOrderMixin from "@/mixins/active-order-mixin"

export default {
	name: "DashboardOrder",
	mixins: [activeOrderMixin],
	props: {
		order: Object,
		entityType: [String, Number]
	},
	computed: {
		orderScheduledDate() {
			return orderHelper.preorderDate(
				this.order,
				this.suborder,
				this.business.timezone
			)
		},
		orderMoment() {
			if (this.orderScheduledDate) {
				return this.orderScheduledDate
			} else {
				return moment.tz(this.order.created_time, this.timezone)
			}
		},
		orderTime() {
			if (this.orderMoment) {
				return this.orderMoment.format("h:mm A")
			}
			return ""
		},
		orderDay() {
			if (this.orderMoment) {
				return this.orderMoment.format("MMMM Do")
			}
			return ""
		},
		statusLabel() {
			if (this.delivered) {
				return "Delivered"
			} else if (this.pickedUp) {
				return "Picked Up"
			} else if (this.accepted) {
				return "Accepted"
			} else {
				return "Placed"
			}
		},
		businessName() {
			return this.business ? this.business.name : null
		},
		businessNameLine() {
			let tableNumber =
				this.tableNumber.length > 0 ? `- ${this.tableNumber}` : ""
			return `${this.businessName} ${tableNumber}`
		},
		showBusinessName() {
			return this.businessName && this.entityType == EntityType.RESTAURANT
		}
	}
}
</script>