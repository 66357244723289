<template>
	<div class="admin-users">
		<slot v-if="pagedAdminUsers.length > 0">
			<div class="sk-table sk-table-default">
				<div class="sk-table-header">
					<div class="sk-table-row">
						<div>Status</div>
						<div>Name</div>
						<div>Email</div>
						<div>Phone</div>
						<div>Role</div>
						<div class="sub-role-header">Sub Role</div>
						<div>Locations</div>
						<div>Actions</div>
					</div>
				</div>
				<transition-group
					name="list"
					tag="div"
					class="sk-table-body"
					appear
				>
					<AdminUserLineItem
						v-for="adminUser in pagedAdminUsers"
						:key="adminUser.id"
						:user="adminUser"
						@editAdminUserClicked="openAdminUserModalClicked"
						@deleteAdminUserClicked="deleteAdminUser"
						@resendActivationLinkClicked="resendActivationLink"
						@resendForgotPasswordEmail="resendForgotPasswordEmail"
					/>
				</transition-group>
			</div>
			<PaginationFooter
				:contentText="'Users'"
				:pageSize="20"
				:total="adminUsers.length"
				@pageChanged="filterPage"
			/>
		</slot>
		<EmptyPlaceholderWidget
			v-else
			:title="`No Users Found`"
		/>
		<ConfirmationModal ref="adminUserConfirmationModal" />
	</div>
</template>

<style scoped>
.admin-users {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.sub-role-header {
	white-space: nowrap;
}
</style>

<script>
import { UserRole } from "@/enums/userRole"
import adminUserTableMixin from "@/mixins/users/admin-user-table-mixin"
import AdminUserLineItem from "@/components/users/AdminUserLineItem.vue"
import PaginationFooter from "@/components/PaginationFooter.vue"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue"
import EmptyPlaceholderWidget from "@/components/EmptyPlaceholderWidget.vue"

export default {
	name: "AdminUsers",
	mixins: [adminUserTableMixin],
	components: {
		AdminUserLineItem,
		PaginationFooter,
		ConfirmationModal,
		EmptyPlaceholderWidget
	},
	computed: {
		adminUsers() {
			let retVal = this.$store.state.admin_users
				.filter(
					user =>
						user.role_id != UserRole.SUPER_ADMIN &&
						user.role_id != UserRole.ADMIN
				)
                .sort((a, b) => b.id - a.id)
            let that = this
            if (this.filter.businessId > 0) {
				retVal = retVal.filter(
					user => user.role_id == UserRole.BUSINESS_ADMIN && user.entities.findIndex(entity => entity.id == that.filter.businessId) >= 0
				)
			}
            if (this.filter.restaurantId > 0) {
				retVal = retVal.filter(
					user => user.role_id == UserRole.RESAURANT_ADMIN && user.entities.findIndex(entity => entity.id == that.filter.restaurantId) >= 0
				)
            }
            if (this.filter.name != "") {
				retVal = retVal.filter(
					user => user.display_name.toLowerCase() == that.filter.name.toLowerCase()
				)
			}
			if (this.filter.email != "") {
				retVal = retVal.filter(
					user =>
						user.email.toLowerCase() == that.filter.email.toLowerCase()
				)
			}
			if (this.filter.phone != "") {
				retVal = retVal.filter(
					user => user.phone == that.filter.phone
				)
			}
			if (this.filter.isActive != -1) {
				retVal = retVal.filter(
					user => user.is_active == that.filter.isActive
				)
			}
			return retVal
		}
	},
	methods: {
		async openAdminUserModalClicked(payload) {
			this.$emit("editAdminUserClicked", payload)
		}
	}
}
</script>