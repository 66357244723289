<template>
	<SkBaseModal :isOpen="isOpen" @close="cancel">
		<button class="button button-icon button-close" @click="cancel(false)">
			<i class="sk-icon-times-regular"></i>
		</button>
		<div class="sk-modal-alt-title">
		<h1 > Approve Payout
		</h1>
		</div>
		<div class="sk-modal-description">Approve ${{ payout.amount }} Payout for
			{{ payout.restaurant.name }}?</div>
		<div>
			<div class="sk-switch-label-wrapper">
				<SkCheckbox v-model="sendReceipt" />
				<span>Send Receipt</span>
			</div>
		</div>
		<div class="sk-modal-actions">
			<button
				class="button button-text"
				@click="cancel({ approved: false })"
			>
				Cancel
			</button>
			<button class="button button-success" @click="applyChanges">
				<i class="sk-icon-check-regular"></i>
				Approve
			</button>
		</div>
	</SkBaseModal>
</template>

<script>
import skPromiseModalMixin from "@/mixins/modals/sk-promise-modal-mixin"
import SkCheckbox from "@/components/SkCheckbox.vue"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
export default {
	name: "ApprovePayoutModal",
	mixins: [skPromiseModalMixin],
	components: {
		SkCheckbox,
		SkBaseModal
	},
	data: function() {
		return {
			sendReceipt: true,
			payout: { restaurant: {} }
		}
	},
	methods: {
		applyChanges() {
			this.confirm({ approved: true, sendReceipt: this.sendReceipt })
		},
		handleOptions(options) {
			this.sendReceipt = true
			if (options) {
				this.payout = options.payout ? options.payout : {}
			}
		}
	}
}
</script>