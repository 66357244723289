<template>
	<div>
		<div
			class="sk-widget-alt note-widget"
			v-for="(note, index) in notes"
			:key="index"
		>
			<div class="note-name">
				<i :class="note.iconClass"></i>
				{{ note.name }}
			</div>
			<div class="note-description">
				{{ note.description }}
			</div>
			<button
				v-if="note.actionText"
				class="button button-light-grey"
				@click="noteActionClicked(note)"
			>
				{{ note.actionText }}
				<i class="sk-icon-phone-solid"></i>
			</button>
		</div>
	</div>
</template>

<style scoped>
.note-widget + .note-widget {
	margin-top: 15px;
}

.note-widget {
	font-size: 12px;
	color: var(--sk-grey2);
	display: flex;
	align-items: center;
}

.note-name {
	display: flex;
	align-items: center;
	flex-shrink: 0;
	font-weight: 600;
	margin-right: 15px;
}

.note-name i {
	margin-right: 15px;
}

.note-description {
	flex-grow: 1;
}

.note-widget > .button {
	display: flex;
	align-items: center;
	min-height: 30px;
	font-size: 10px;
	padding: 5px 15px;
	white-space: nowrap;
	margin-left: 15px;
}

.note-widget > .button i {
	margin-left: 15px;
	margin-right: 0;
	font-size: 10px;
}
</style>

<script>
import utils from "@/utils/utils"

export default {
	name: "OrderNotes",
	props: {
		notes: {
			type: Array
		}
	},
	methods: {
		noteActionClicked(note) {
			utils.openPhoneLink(note.phone)
		}
	}
}
</script>