<template>
	<div class="fixed-button-bottom menu-schedules-container">
		<div class="sk-row">
			<div class="sk-column">
				<MenuScheduleSummary
					:scheduleSummary="scheduleSummary"
					:timezone="timezone"
				/>
				<MenuScheduleSummary
					v-if="availabilitySchedule"
					:scheduleSummary="availabilityScheduleSummary"
					:timezone="timezone"
					:summaryTitle="availabilityScheduleName"
				/>
			</div>
			<div v-if="!readonly">
				<MenuSchedule
					v-for="schedule in aggregatedSchedule"
					:key="schedule.id"
					:schedule="schedule"
					:availabilitySchedule="availabilitySchedule"
					:offset="offset"
					:readonly="readonly"
					@daySelected="selectDay"
					@dayUnselected="unselectDay"
					@updateTime="updateTime"
					@removeHours="removeHours"
				/>
				<div class="sk-widget-alt menu-schedule-add-hours">
					<div>
						<div>Add Open Hours</div>
						<div>
							Days with no hours set will be marked as 'Closed'
						</div>
					</div>
					<button class="button button-primary" @click="addHours">
						<i class="sk-icon-plus-regular"></i>
						Hours
					</button>
				</div>
			</div>
		</div>
		<button
			class="button button-primary button-absolute"
			@click="saveHours"
		>
			Save Details
		</button>
	</div>
</template>

<script>
import loaderMixin from "@/mixins/loader-mixin"
import menuScheduleMixin from "@/mixins/menu-schedule-mixin"
import MenuSchedule from "@/components/MenuSchedule.vue"
import MenuScheduleSummary from "@/components/MenuScheduleSummary.vue"

export default {
	name: "Schedule",
	mixins: [loaderMixin, menuScheduleMixin],
	components: {
		MenuSchedule,
		MenuScheduleSummary
	},
	props: {
		offset: Number,
		timezone: {
			type: String,
			default: ""
		},
		readonly: {
			type: Boolean,
			default: false
		},
		availabilityScheduleName: String
	},
	methods: {
		saveHours() {
			const openingHours = this.normalizedOpeningHours()
			this.$emit("setHours", openingHours)
			this.$emit("save")
		}
	}
}
</script>