<template>
	<SkBaseModal :isOpen="isOpen" @close="close" class="sk-modal-alt">
		<div class="sk-modal-alt-title">
			<h1>
				{{ title }}
				<span class="sk-modal-alt-subtitle">{{ subtitle }}</span>
			</h1>
		</div>
		<div class="sk-row">
			<VueCtkDateTimePicker
				v-model="voucherEndDate"
				:label="'Valid Until'"
				:no-clear-button="true"
				:no-label="true"
				:no-button-now="true"
				:only-date="true"
				:button-color="'#90DA36'"
				:format="'MM-DD-YYYY'"
				:formatted="'ll'"
				:color="'#2E3C49'"
				:output-format="'ddd MMM DD, YYYY'"
				:min-date="minStartDate"
				:no-value-to-custom-elem="true"
			>
				<SkInput
					class="optional-field"
					:value="voucherEndDate"
					:disabled="true"
					:name="'Valid Until'"
					ref="dateInput"
				/>
			</VueCtkDateTimePicker>
		</div>
		<div class="sk-row">
			<SkDropdownSelect
				ref="maxUsersInput"
				:name="'Max Uses'"
				:classes="'sk-input'"
				v-model="voucherMaxUses"
				:options="voucherMaxUsesValues"
			/>
		</div>

		<div class="sk-modal-actions">
			<button @click="close" class="button button-text">Cancel</button>
			<button
				type="button"
				class="button button-success"
				@click="saveVoucher"
			>
				<i class="sk-icon-check-regular"></i>
				{{ saveButtonText }}
			</button>
		</div>
	</SkBaseModal>
</template>

<script>
import moment from "moment"
import "moment-timezone"
import ActionTypes from "@/store/modules/coupons/action-types"
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import SkInput from "@/components/SkInput.vue"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"
import TypeaheadInput from "@/components/TypeaheadInput.vue"
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import VueCtkDateTimePicker from "vue-ctk-date-time-picker"

export default {
	name: "VoucherModal",
	mixins: [skModalMixin],
	components: {
		SkBaseModal,
		SkInput,
		SkDropdownSelect,
		TypeaheadInput,
		VueCtkDateTimePicker
	},
	data: function () {
		return {
			voucher: {
				id: null,
				code: "",
				endDate: null,
				usage_limit: 0,
				num_of_uses: 0
			},
			voucherMaxUses: {
				id: null,
				text: "Max Uses"
			},
			minStartDate: moment().format("YYYY-MM-DD"),
			voucherMaxUsesValues: [
				{
					id: null,
					text: "Max Uses"
				},
				{
					id: 0,
					text: "Unlimited"
				},
				{
					id: 1,
					text: "1"
				},
				{
					id: 2,
					text: "2"
				},
				{
					id: 3,
					text: "3"
				},
				{
					id: 4,
					text: "4"
				},
				{
					id: 5,
					text: "5"
				}
			]
		}
	},
	computed: {
		voucherEndDate: {
			get: function () {
				if (this.voucher.endDate) {
					let voucherEndDate = moment(
						this.voucher.endDate,
						"ddd MMM DD, YYYY"
					)
					return voucherEndDate.format("ddd MMM DD, YYYY")
				} else {
					return null
				}
			},
			set: function (date) {
				this.voucher.endDate = date
			}
		},
		order() {
			return this.$store.state.ordersModule.currentOrder
		},
		title() {
			return "Edit Voucher:"
		},
		subtitle() {
			return this.voucher.code
		},
		saveButtonText() {
			return "Save Voucher"
		},
		business() {
			if (this.business_id > 0) {
				return this.$store.getters.businessesMap[this.business_id]
			}
			return {}
		}
	},
	methods: {
		handleOptions(options) {
			if (options) {
				if (options.voucher) {
					this.voucher = {
						id: options.voucher.id,
						code: options.voucher.code,
						usage_limit: options.voucher.usage_limit,
						num_of_uses: options.voucher.num_of_uses,
						endDate: options.voucher.endDate
					}
					this.voucherMaxUses =
						options.voucher.usage_limit == 0
							? {
									id: 0,
									text: "Unlimited"
							  }
							: {
									id: options.voucher.usage_limit,
									text: options.voucher.usage_limit.toString()
							  }
				} else {
					this.initVoucher()
				}
			} else {
				this.initVoucher()
			}
		},
		initVoucher() {
			this.voucherMaxUses = {
				id: 0,
				text: "Unlimited"
			}
			this.voucher = {
				code: "",
				endDate: null,
				usage_limit: 0,
				num_of_uses: 0
			}
		},
		validateVoucherData() {
			const validDate = this.$refs.dateInput.validate()
			const validMaxUsersAmount = this.$refs.maxUsersInput.validate()
			return validDate && validMaxUsersAmount
		},
		async saveVoucher() {
			const isVoucherDataValid = this.validateVoucherData()
			if (!isVoucherDataValid) {
				return
			}
			this.voucher.usage_limit = this.voucherMaxUses.id
			if (this.voucher.id && this.voucher.id > 0) {
				const retVal = await this.$store.dispatch(
					`couponsModule/${ActionTypes.UPDATE_VOUCHER}`,
					this.voucher
				)
				if (retVal) {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Voucher " + this.voucher.code + " updated!"
					})
					this.close()
					setTimeout(() => {
						this.$emit("refreshVouchers")
					}, 2000)
				}
			}
		}
	}
}
</script>