import Vue from "vue"

function bind(el, binding, vnode) {
    const clickOutsideActive = vnode.data.attrs["click-outside-active"]
    if (clickOutsideActive && !el.clickOutsideEvent) {
        let vm = vnode.context
        let callback = binding.value
    
        el.clickOutsideEvent = function (event) {
            if (!(el == event.target || el.contains(event.target))) {
                return callback.call(vm, event)
            }
        }

        //We need to wait for the browser to update the dom before adding the click handler to the body
        //otherwise we risk calling the handler too early
        setTimeout(() => {
            document.body.addEventListener('click', el.clickOutsideEvent)
        }, 0)
    }
}

function unbind(el) {
    if (el.clickOutsideEvent) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
        el.clickOutsideEvent = null
    }
}

Vue.directive("sk-click-outside", {
    bind: bind,
    update(el, binding, vnode, oldVnode) {
        const clickOutsideActiveOld = oldVnode.data.attrs["click-outside-active"]
        const clickOutsideActive = vnode.data.attrs["click-outside-active"]
        if (clickOutsideActiveOld != clickOutsideActive) {
            if (clickOutsideActive) {
                bind(el, binding, vnode)
            } else {
                unbind(el)
            }
        }
    },
    unbind: unbind
})