<template>
	<transition name="fade">
		<div
			class="sk-modal"
			tabindex="-1"
			role="dialog"
			aria-hidden="true"
			v-show="isOpen"
		>
			<transition name="slide" mode="out-in" appear>
				<div class="sk-modal-body" role="document" v-show="isOpen">
					<div
						class="sk-modal-body-top-radius"
						:style="headerStyle"
					></div>
					<button
						v-if="showCloseButton"
						class="button button-icon button-close"
						@click="close"
					>
						<i class="sk-icon-times-regular"></i>
					</button>
					<div
						class="sk-modal-content"
						:class="{ 'vld-parent': hasLoader }"
					>
						<slot name="default"></slot>
						<transition name="fade" mode="out-in">
							<div v-if="showContentBackdrop" @click="contentBackdropClicked" class="sk-modal-content-backdrop"></div>
						</transition>
					</div>
					<div class="sk-modal-body-bottom-radius"></div>
				</div>
			</transition>
			<div class="sk-modal-backdrop" @click="close()"></div>
		</div>
	</transition>
</template>

<style scoped>
.sk-modal >>> .vld-overlay {
	z-index: 10000000;
	margin-top: -4px;
	border-radius: 4px;
	margin-bottom: -4px;
}
</style>

<script>
export default {
	name: "SkBaseModal",
	props: {
		isOpen: Boolean,
		showCloseButton: {
			type: Boolean,
			default: true
		},
		hasLoader: {
			type: Boolean,
			default: false
		},
		headerColor: String,
		showContentBackdrop: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		headerStyle() {
			const styles = {}
			if (this.headerColor) {
				styles.backgroundColor = this.headerColor
			}
			return styles
		}
	},
	methods: {
		contentBackdropClicked(e) {
			this.$emit("contentBackdropClicked", e)
		},
		close() {
			this.$emit("close")
		}
	}
}
</script>