<template>
	<div class="full-height-table-wrapper">
		<div class="sk-table sk-table-default">
			<div class="sk-table-header">
				<div class="sk-table-row">
					<div>Code</div>
					<div>Value</div>
					<div>Business</div>
					<div>Validity Dates</div>
					<div>Payment</div>
					<div>Distribution</div>
					<div>Uses</div>
					<div>Status</div>
					<div>Actions</div>
				</div>
			</div>
			<transition-group
				name="list"
				tag="div"
				class="sk-table-body"
				appear
			>
				<VoucherLineItem
					v-for="voucher in coupons"
					:voucher="voucher"
					:key="voucher.id"
					@printVoucher="printVoucher"
					@sendVoucherEmail="sendVoucherEmail"
					@updateVoucher="openVoucherModal"
				/>
			</transition-group>
		</div>
		<PaginationFooter
			:contentText="'Vouchers'"
			:pageSize="20"
			:total="totalVouchersCount"
			ref="paginationFooter"
			@pageChanged="filterPage"
		/>
		<ConfirmationModal ref="confirmationModal"></ConfirmationModal>
		<SendVoucherEmailModal ref="sendVoucherEmailModal" />
		<VoucherModal ref="voucherModal" @refreshVouchers="refreshVouchers" />
	</div>
</template>

<script>
import ActionTypes from "@/store/modules/coupons/action-types"
import PaginationFooter from "@/components/PaginationFooter.vue"
import VoucherLineItem from "@/components/coupon/VoucherLineItem.vue"
import loaderMixin from "@/mixins/loader-mixin"
import voucherActionsMixin from "@/mixins/vouchers/voucher-actions-mixin"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue"
import SendVoucherEmailModal from "@/components/modals/SendVoucherEmailModal.vue"
import VoucherModal from "@/components/modals/VoucherModal.vue"

export default {
	name: "Vouchers",
	mixins: [loaderMixin, voucherActionsMixin],
	components: {
		VoucherLineItem,
		PaginationFooter,
		ConfirmationModal,
		SendVoucherEmailModal,
		VoucherModal
	},
	props: {
		filters: Object
	},
	data: function () {
		return {
			pageRange: {
				page: 1,
				start: 0,
				end: 20
			},
			filterVals: {
				businessId: 0,
				code: ""
			}
		}
	},
	computed: {
		coupons() {
			return this.$store.state.couponsModule.vouchers
		},
		pagedCoupons() {
			return this.vouchers.slice(this.pageRange.start, this.pageRange.end)
		},
		totalVouchersCount() {
			return this.$store.state.couponsModule.totalVouchersCount
		}
	},
	mounted: function () {
		this.getVouchers()
	},
	methods: {
		getVouchers() {
			this.toggleLoader(true)
			this.$store
				.dispatch(
					`couponsModule/${ActionTypes.SEARCH_VOUCHERS}`,
					Object.assign(
						{
							pageStart: this.pageRange.start,
							pageSize: 20
						},
						this.filterVals
					)
				)
				.finally(() => {
					this.toggleLoader(false)
				})
		},
		refreshVouchers() {
			if (this.pageRange.page != 1) {
				this.$refs.paginationFooter.pageChanged(1)
			} else {
				this.getVouchers()
			}
		},
		filterPage(range) {
			this.pageRange = range
			this.getVouchers()
		},
		async openVoucherModal(voucher) {
			this.$refs.voucherModal.open({
				voucher: voucher
			})
		}
	},
	watch: {
		filters: {
			deep: true,
			handler(val) {
				if (
					val.businessId != this.filterVals.businessId ||
					val.code != this.filterVals.code
				) {
					this.filterVals.code = val.code
					this.filterVals.businessId = val.businessId
					this.refreshVouchers()
				}
			}
		}
	}
}
</script>