<template>
	<SkBaseModal
		class="sk-modal-alt vld-parent"
		:isOpen="isOpen"
		@close="close"
	>
		<loading :active.sync="loading" :is-full-page="false" />
		<div class="sk-modal-alt-title">
			<h1>
				{{ title }}
			</h1>
		</div>
		<div class="sk-row">
			<SkDropdownSelect
				ref="typeInput"
				:classes="'sk-input'"
				v-model="reminderType"
				:options="reminderTypes"
			/>
		</div>
		<div class="sk-row">
			<VueCtkDateTimePicker
				v-model="reminderDate"
				:label="'Reminder Date'"
				:no-clear-button="true"
				:no-label="true"
				:no-button-now="true"
				:only-date="true"
				:button-color="'#90DA36'"
				:format="'MM-DD-YYYY'"
				:formatted="'ll'"
				:color="'#2E3C49'"
				:output-format="'ddd MMM DD, YYYY'"
				:min-date="minStartDate"
				:no-value-to-custom-elem="true"
			>
				<SkInput
					:value="reminderDate"
					:disabled="true"
					:name="'Reminder Date'"
				/>
			</VueCtkDateTimePicker>
		</div>
		<div class="sk-row">
			<SkTimePicker
				v-model="reminderTime"
				:name="'Reminder Time'"
				:hint="'Reminder Time to be sent'"
				:disabled="business == null"
			/>
		</div>
		<div class="sk-modal-actions">
			<button class="button button-primary" @click="saveReminder">
				Save Reminder
			</button>
		</div>
	</SkBaseModal>
</template>

<style scoped>
h2 {
	color: var(--sk-navy);
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 25px;
	margin-top: 25px;
}

* >>> .sk-radio {
	width: 15px;
	height: 15px;
	margin-right: 10px;
}

.sk-modal-actions {
	justify-content: center;
}
</style>

<script>
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import moment from "moment"
import Loading from "vue-loading-overlay"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import SkModalMixin from "@/mixins/modals/sk-modal-mixin"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"
import VueCtkDateTimePicker from "vue-ctk-date-time-picker"
import SkTimePicker from "@/components/SkTimePicker.vue"
import SkInput from "@/components/SkInput.vue"
import { EventReminderType } from "@/enums/eventReminderType"
import ActionTypes from "@/store/modules/events/action-types"

export default {
	name: "EventReminderModal",
	mixins: [SkModalMixin],
	components: {
		Loading,
		SkBaseModal,
		SkDropdownSelect,
		VueCtkDateTimePicker,
		SkTimePicker,
		SkInput
	},
	data: function() {
		return {
			loading: false,
			title: "Schedule New Event Reminder",
			id: null,
			reminderDateStr: null,
			reminderTimeStr: null,
			minStartDate: moment().format("YYYY-MM-DD"),
			reminderType: {
				id: null,
				text: "Type"
			},
			reminderTypes: [
				{
					id: null,
					text: "Type"
				},
				{
					id: EventReminderType.PRE_ORDER_EMAIL,
					text: "Default"
				},
				{
					id: EventReminderType.PRE_ORDER_LAST_CHANCE_EMAIL,
					text: "Last Call"
				}
			]
		}
	},
	props: {
		event: Object
	},
	computed: {
		business() {
			if (
				this.event.business_id &&
				this.$store.getters.businessesMap &&
				this.$store.getters.businessesMap[this.event.business_id]
			) {
				return this.$store.getters.businessesMap[this.event.business_id]
			}
			return null
		},
		reminderDate: {
			get: function() {
				return this.reminderDateStr
			},
			set: function(newVal) {
				this.reminderDateStr = newVal
			}
		},
		reminderTime: {
			get: function() {
				return this.reminderTimeStr
			},
			set: function(newVal) {
				this.reminderTimeStr = newVal
			}
		}
	},
	methods: {
		handleOptions(options) {
			if (options.id) {
				this.title = "Edit Scheduled Event Reminder"
				this.id = options.id
				this.reminderType = {
					id: options.type,
					text:
						options.type == EventReminderType.PRE_ORDER_EMAIL
							? "Default"
							: "Last Call"
				}
				this.reminderDateStr = moment
					.utc(options.remind_at)
					.add(this.business.timezone_offset, "m")
					.format("ddd MMM DD, YYYY")
				this.reminderTimeStr = moment
					.utc(options.remind_at)
					.add(this.business.timezone_offset, "m")
					.format("h:mm A")
			}
		},
		closed() {
			this.loading = false
			this.id = null
			this.reminderDateStr = null
            this.reminderTimeStr = null
            this.reminderType = {
				id: null,
				text: "Type"
			}
		},
		validateData() {
			if (!this.reminderType.id == null) {
				throw "Please enter a valid reminder type"
			}
			if (!this.reminderDateStr) {
				throw "Please enter a valid reminder date"
			}
			if (!this.reminderTimeStr) {
				throw "Please enter a valid reminder time"
			}
			return true
		},
		async saveReminder() {
			try {
				this.validateData()
				this.loading = true
				let dateTimeStr =
					this.reminderDateStr + " " + this.reminderTimeStr
				let reminderDateObj = moment(
					dateTimeStr,
					"ddd MMM DD, YYYY h:mm A"
				)
					.subtract(this.business.timezone_offset, "m")
					.format("YYYY-MM-DD HH:mm:ss")
				this.loading = false

				let success = false
				if (this.id) {
					const data = {
						id: this.id,
						reminderType: this.reminderType.id,
						remindAt: reminderDateObj
					}
					success = await this.$store.dispatch(
						`eventsModule/${ActionTypes.UPDATE_EVENT_REMINDER}`,
						data
					)
				} else {
					const data = {
						eventId: this.event.id,
						reminderType: this.reminderType.id,
						remindAt: reminderDateObj
					}
					success = await this.$store.dispatch(
						`eventsModule/${ActionTypes.ADD_EVENT_REMINDER}`,
						data
					)
				}
				if (!success) {
					throw "Something went wrong, please try again"
				}
				this.loading = false
				this.close()
			} catch (e) {
				this.loading = false
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: e.message ? e.message : e,
					type: NotificationType.WARNING
				})
			}
		}
	}
}
</script>