import Vue from "vue"
import { parseResponse } from "@arikgaisler/utils/api-utils"

export const SIGNATURE_API = process.env.VUE_APP_API_AWS_SIGNATURE_URL
export const SECURE_SIGNATURE_API = process.env.VUE_APP_AWS_SECURE_SIGNATURE_URL
export const CANNY_SSO_API = "https://jo6ed6ev3l.execute-api.us-east-1.amazonaws.com/generate"

export default {
    async getAWSSignature(params) {
        const signatureUrl = `${SIGNATURE_API}?name=${params.slug}&type=${params.type}&files=${params.filePaths}`
        return Vue.axios.get(signatureUrl)
    },
    async getTimezone(latitude, longitude, timestamp, key) {
        return parseResponse(Vue.axios.get(`https://maps.googleapis.com/maps/api/timezone/json?location=${latitude},${longitude}&timestamp=${timestamp}&key=${key}`))
    }
}