<template>
	<SkBaseModal class="sk-modal-alt" :isOpen="isOpen" @close="cancel">
		<button class="button button-icon button-close" @click="cancel()">
			<i class="sk-icon-times-regular"></i>
		</button>
		<div class="sk-modal-alt-title">
			<img src="/images/ordermark-logo.png" alt="Ordermark Logo" />
			<h1>Ordermark</h1>
		</div>
		<div class="sk-row">
			<SkInput
				v-model="locationId"
				ref="locationIdInput"
				:name="'Location Id'"
				:required="true"
			/>
		</div>
		<div class="sk-modal-actions">
			<button class="button button-text" @click="cancel">Cancel</button>
			<button class="button button-primary" @click="saveIntegration">
				{{ confirmBtnText }}
			</button>
		</div>
	</SkBaseModal>
</template>

<script>
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import skPromiseModalMixin from "@/mixins/modals/sk-promise-modal-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import SkInput from "@/components/SkInput.vue"

export default {
	name: "OrdermarkIntegrationModal",
	mixins: [skPromiseModalMixin],
	components: {
		SkBaseModal,
		SkInput
	},
	data: function () {
		return {
			locationId: "",
			editing: false
		}
	},
	computed: {
		confirmBtnText() {
			return this.editing ? "Save Integration" : "Add Integration"
		}
	},
	methods: {
		handleOptions(options) {
			if (options.integration) {
				this.editing = true
				this.locationId = options.integration.apiKey
					? options.integration.apiKey
					: ""
			} else {
				this.editing = false
			}
		},
		saveIntegration() {
			const isLocationIdValid = this.$refs.locationIdInput.validate()
			if (!isLocationIdValid) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Ordermark Integration",
					message: "A location id is required",
					type: NotificationType.WARNING
				})
				return
			}
			this.confirm({
				locationId: this.locationId
			})
		}
	}
}
</script>