
import user_service from "@/services/users"
import pricing_service from "@/services/pricing"
import search_service from "@/services/search"
import Vue from "vue"
import ActionTypes from '@/store/modules/users/action-types'
import MutationTypes from '@/store/modules/users/mutation-types'
import moment from "moment"
import { BusinessGuestAmenityType } from "@/enums/businessGuestAmenityType"

const vue = new Vue()

function businessGuestSearchParamsToFilters(payload) {
    let filters = {}
    if (payload.name) {
        filters.name = payload.name
    }
    if (payload.email) {
        filters.email = payload.email
    }
    if (payload.adminUserId) {
        filters.admin_user_ids = [payload.adminUserId]
    }
    if (payload.checkInDate) {
        filters.check_in_date = {}
        if (payload.checkInDate.from) {
            filters.check_in_date.gte = moment
                .utc(payload.checkInDate.from, "YYYY-MM-DD")
        }
        if (payload.checkInDate.to) {
            filters.check_in_date.lte = moment
                .utc(payload.checkInDate.to, "YYYY-MM-DD")
        }
    }
    if (payload.checkOutDate) {
        filters.check_out_date = {}
        if (payload.checkOutDate.from) {
            filters.check_out_date.gte = moment
                .utc(payload.checkOutDate.from, "YYYY-MM-DD")
        }
        if (payload.checkOutDate.to) {
            filters.check_out_date.lte = moment
                .utc(payload.checkOutDate.to, "YYYY-MM-DD")
        }
    }
    return filters
}

export default {
    async [ActionTypes.ADD_BUSINESS_GUEST]({ commit }, payload) {
        try {
            const businessGuestResp = await user_service.addBusinessGuest(payload)
            if (businessGuestResp.status == 200) {
                commit(MutationTypes.GOT_BUSINESS_GUEST, businessGuestResp.data)
                return true
            }
            else {
                throw `API Error: ${businessGuestResp.status}`
            }
        }
        catch (e) {
            commit(MutationTypes.LOADED)
            vue.bugsnag.notify(
                "Failed to add business guest",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        filters: payload
                    })
                }
            )
            return false
        }
    },
    async [ActionTypes.GET_CURRENT_BUSINESS_GUEST]({ commit }, businessGuestId) {
        try {
            const businessGuestResp = await user_service.getBusinessGuest(businessGuestId)
            const businessGuest = businessGuestResp.data.business_guest
            if (!businessGuest) {
                throw "Business guest unavailable"
            }
            const voucherAmenities = businessGuest.amenities.filter(amenity => {
                return amenity.type == BusinessGuestAmenityType.VOUCHER && amenity.attributes && amenity.attributes.voucher_requests.length > 0
            })
            if (voucherAmenities.length > 0) {
                const voucherRequests = voucherAmenities.map((voucherAmenity) => voucherAmenity.attributes.voucher_requests).flat()
                const promises = voucherRequests.map((voucherRequestId) => {
                    return pricing_service.getVoucherRequestVouchers(voucherRequestId)
                })
                const responses = await Promise.all(promises)
                const vouchers = responses.map((response) => {
                    return response.data && response.data.vouchers ? response.data.vouchers : []
                }).flat()
                businessGuest.voucherRequests = vouchers ? vouchers : []
            }
            commit(MutationTypes.GOT_CURRENT_BUSINESS_GUEST, businessGuest)
            return true
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to get current business guest",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        businessGuestId: businessGuestId
                    })
                }
            )
            return false
        }
    },
    async [ActionTypes.GET_CURRENT_BUSINESS_GUEST_ORDERS]({ commit, state }) {
        try {
            if (state.currentBusinessGuest && state.currentBusinessGuest.orders && state.currentBusinessGuest.orders.length > 0) {
                const searchResponse = await search_service.searchOrders({
                    filters: {
                        ids: state.currentBusinessGuest.orders
                    }
                })
                if (searchResponse.data && searchResponse.data.orders) {
                    commit(MutationTypes.GOT_CURRENT_BUSINESS_GUEST_ORDERS, searchResponse.data.orders)
                }
            }
            return true
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to get current business guest orders",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        businessGuest: state.currentBusinessGuest
                    })
                }
            )
            return false
        }
    },
    async [ActionTypes.UPDATE_BUSINESS_GUEST]({ commit }, payload) {
        try {
            const guestPayload = { "business_guest": payload }
            const businessGuestResp = await user_service.updateBusinessGuest(payload.id, guestPayload)
            if (businessGuestResp.status == 200) {
                commit(MutationTypes.GOT_BUSINESS_GUEST, businessGuestResp.data)
                return true
            }
            else {
                throw `API Error: ${businessGuestResp.status}`
            }
        }
        catch (e) {
            commit(MutationTypes.LOADED)
            vue.bugsnag.notify(
                "Failed to add business guest",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        filters: payload
                    })
                }
            )
            return false
        }
    },
    async [ActionTypes.SEND_BUSINESS_GUEST_DIGITAL_MENU]({ commit }, businessGuestID) {
        try {
            let businessGuestPayload = {
                attributes: {
                    force: true
                }
            }
            const businessGuestAmenityResp = await user_service.addBusinessGuestAmenity(businessGuestID, BusinessGuestAmenityType.DIGITAL_MENU, businessGuestPayload)
            if (businessGuestAmenityResp.status == 200) {
                const businessGuestResp = await user_service.getBusinessGuest(businessGuestID)
                commit(MutationTypes.GOT_BUSINESS_GUEST, businessGuestResp.data)
                return true
            }
            else {
                throw `API Error: ${businessGuestAmenityResp.status}`
            }
        }
        catch (e) {
            commit(MutationTypes.LOADED)
            vue.bugsnag.notify(
                "Failed to send business guest digital menu",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        filters: businessGuestID
                    })
                }
            )
            return false
        }
    },
    async [ActionTypes.SEND_BUSINESS_GUEST_VOUCHER]({ commit, state, dispatch }, payload) {
        try {
            let businessGuestPayload = {
                attributes: {
                    amount: payload.amount,
                    quantity: payload.quantity,
                    endDate: payload.endDate
                }
            }
            const businessGuestAmenityResp = await user_service.addBusinessGuestAmenity(payload.businessGuestId, BusinessGuestAmenityType.VOUCHER, businessGuestPayload)
            if (businessGuestAmenityResp.status == 200) {
                if (state.currentBusinessGuest && state.currentBusinessGuest.id == payload.businessGuestId) {
                    await dispatch(ActionTypes.GET_CURRENT_BUSINESS_GUEST, payload.businessGuestId)
                } else {
                    const businessGuestResp = await user_service.getBusinessGuest(payload.businessGuestId)
                    commit(MutationTypes.GOT_BUSINESS_GUEST, businessGuestResp.data)
                }
                return true
            }
            else {
                throw `API Error: ${businessGuestAmenityResp.status}`
            }
        }
        catch (e) {
            commit(MutationTypes.LOADED)
            vue.bugsnag.notify(
                "Failed to send business guest voucher",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        filters: payload
                    })
                }
            )
            return false
        }
    },
    async [ActionTypes.DELETE_BUSINESS_GUEST]({ commit }, id) {
        try {
            const businessGuestResp = await user_service.deleteBusinessGuest(id)
            if (businessGuestResp.status == 200) {
                commit(MutationTypes.BUSINESS_GUEST_DELETED, id)
                return true
            }
            else {
                throw `API Error: ${businessGuestResp.status}`
            }
        }
        catch (e) {
            commit(MutationTypes.LOADED)
            vue.bugsnag.notify(
                "Failed to delete business guest",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        filters: id
                    })
                }
            )
            return false
        }
    },
    async [ActionTypes.SEARCH_BUSINESS_GUESTS]({ commit }, payload) {
        try {
            commit(MutationTypes.LOADING)
            const response = await user_service.searchBusinessGuests({
                filters: businessGuestSearchParamsToFilters(payload),
                pagination: {
                    offset: payload.pageStart,
                    limit: payload.pageSize
                }
            })
            if (response.status == 200) {
                commit(MutationTypes.GOT_BUSINESS_GUESTS, response.data)
            } else {
                throw `API Error: ${response.status}`
            }
            commit(MutationTypes.LOADED)
        } catch (e) {
            commit(MutationTypes.LOADED)
            vue.bugsnag.notify(
                "Failed to search business guests",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        filters: payload
                    })
                }
            )
        }
    },

}