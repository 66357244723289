<template>
	<SkBaseModal :isOpen="isOpen" :showCloseButton="false" @close="close">
		<div class="sk-modal-action-header">
			<h1 class="sk-modal-title">New Menu</h1>
			<button @click="addMenus" class="button button-primary">
				Add Menu
			</button>
		</div>
		<div class="sk-row">
			<TypeaheadInput
				:placeholder="'Restaurant'"
				:fetch="autocompleteRestaurants"
				:serializer="typeaheadSerializer"
				:query="restaurant.name"
				@selected="restaurantSelected"
			/>
		</div>
		<div class="sk-row">
			<SkDropdownMultiSelect v-model="menuIDs" :options="menuOptions" />
		</div>
		<div></div>
	</SkBaseModal>
</template>

<script>
import { MenuType } from "@arikgaisler/utils/enums/menuType"
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import { DeliveryMode } from "@arikgaisler/utils/enums/deliveryMode"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import SkDropdownMultiSelect from "@/components/SkDropdownMultiSelect.vue"
import TypeaheadInput from "@/components/TypeaheadInput.vue"
import restaurant_service from "@/services/restaurants"

export default {
	name: "NewBusinessMenuModal",
	mixins: [skModalMixin],
	components: {
		SkBaseModal,
		SkDropdownMultiSelect,
		TypeaheadInput
	},
	data: function () {
		return {
			menuName: "",
			restaurant: {},
			menuIDs: [],
			menus: [
				{
					text: "Select Menu",
					id: null
				}
			],
			menuID: null
		}
	},
	computed: {
		business() {
			return this.$store.state.businessesModule.currentBusiness
		},
		menuOptions() {
			var baseMenus = [
				{
					text: "Select Menu",
					id: null
				}
			]
			if (
				this.restaurant.menus != undefined &&
				this.restaurant.menus.length > 0
			) {
				let menuProducts = this.restaurant.menus
					.filter(
						(e) => !this.business.menus.some((m) => m.id == e.id)
					)
					.map((menu) => {
						return {
							text: `${menu.name}`,
							id: menu.id
						}
					})

				baseMenus = baseMenus.concat(menuProducts)
			}
			return baseMenus
		},
		selectedMenu: {
			get: function () {
				return this.menuOptions.find(
					(option) => option.id == this.menuID
				)
			},
			set: function (value) {
				this.menuID = value.id
			}
		}
	},
	methods: {
		opened: function () {
			this.menuName = ""
			this.menuID = null
			this.menuIDs = []
			this.restaurant = {}
			this.menus = [
				{
					text: "Select Menu",
					id: null
				}
			]
		},
		async autocompleteRestaurants(query) {
			const results = await this.$store.dispatch(
				"restaurantsModule/autocompleteRestaurants",
				query
			)
			return results
		},
		async restaurantSelected(event) {
			const restaurant = await restaurant_service.getRestaurant(event.id)
			this.restaurant = restaurant.data
			this.menuIDs = []
		},
		async addMenus() {
			if (this.menuIDs.length != 0) {
				this.menuIDs.forEach(async (e) => {
					const rest = this.restaurant
					const menu = this.restaurant.menus.find(
						(menu) => menu.id == e
					)
					let deliveryModes = [
						{
							business_id: this.business.id,
							menu_id: menu.id,
							delivery_mode: DeliveryMode.DIRECT_TO_LOCATION,
							is_default: 1
						}
					]

					if (
						this.business.delivery_modes &&
						this.business.delivery_modes.length > 0
					) {
						deliveryModes =
							this.business.delivery_modes.map(
								(deliveryMode) => {
									return {
										business_id: this.business.id,
										menu_id: menu.id,
										delivery_mode: deliveryMode.delivery_mode,
										is_default: deliveryMode.is_default
									}
								}
							)
					} else if (this.business.delivery_mode) {
						deliveryModes = [
							{
								business_id: this.business.id,
								menu_id: menu.id,
								delivery_mode: this.business.delivery_mode,
								is_default: 1
							}
						]
					}

					const menuData = {
						restaurant_id: this.restaurant.id,
						courier_id: menu.courier_id,
						delivery_fee: 0,
						delivery_modes: deliveryModes,
						id: e,
						is_backup: 0,
						is_late_night: 0,
						opening_hours: menu.opening_hours,
						min_order_amount: 12,
						name: menu.name,
						restaurant_commission: this.restaurant.commission,
						stock_status: 0,
						type: menu.type,
						products_categories: [],
						pre_order_allowed:
							menu.type == MenuType.PRE_PACKAGED ? 0 : 1
					}
					const payload = { menu: menuData, rest: rest }
					const success = await this.$store.dispatch(
						"businessesModule/addBusinessMenu",
						payload
					)
					if (success) {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: `Succesfully added ${menu.name}`,
							type: NotificationType.SUCCESS
						})
					} else {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: `Error adding ${menu.name}. Please try again.`,
							type: NotificationType.ERROR
						})
					}
				})
				this.close()
			} else {
				this.close()
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Please select a menu to import",
					type: NotificationType.ERROR
				})
			}
		},
		typeaheadSerializer(result) {
			let hasAddress =
				result.address && result.city && result.state && result.zip
			return {
				text: result.name,
				description: hasAddress
					? `${result.address} <br> ${result.city}, ${result.state} ${result.zip}`
					: null
			}
		}
	}
}
</script>