<template>
	<div
		class="sk-column"
		:style="{ zIndex: 1000 - index, position: 'relative' }"
	>
		<div
			class="sk-widget-alt hub-collapisble-title-widget"
			@click="toggleExpand"
		>
			<div class="hub-collapsible-badge">{{ badgeText }}</div>
			<div class="hub-collapsible-title">{{ title }}</div>
			<div class="hub-collapsible-subtitle" v-if="subtitle">
				{{ subtitle }}
			</div>
			<i
				class="flippable sk-icon-chevron-down-regular"
				:class="{ flipped: expanded }"
			></i>
		</div>
		<transition-expand>
			<div class="expandable-content" v-if="expanded" key="0">
				<slot name="expanded"></slot>
			</div>
			<div class="expandable-content" v-else key="1">
				<slot name="collapsed"></slot>
			</div>
		</transition-expand>
	</div>
</template>

<style scoped>
.expandable-content {
	flex: auto !important;
	margin-bottom: 0 !important;
}

.hub-collapisble-title-widget {
	width: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	width: fit-content;
	padding: 0;
	position: relative;
	overflow: visible;
	cursor: pointer;
}

.hub-collapsible-badge {
	padding: 0 10px;
	color: var(--sk-orange);
	background: var(--sk-orange3);
	font-size: 14px;
	font-weight: 600;
	position: absolute;
	left: -1px;
	top: -1px;
	height: calc(100% + 2px);
	display: flex;
	align-items: center;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}

.hub-collapsible-title,
.hub-collapsible-subtitle {
	color: var(--sk-dark-navy);
}

.hub-collapsible-title {
	padding: 8px 15px 8px 43px;
	font-size: 16px;
	font-weight: 600;
}

.hub-collapsible-subtitle {
	padding: 8px 15px 8px 0px;
	font-size: 14px;
}

.hub-collapisble-title-widget > i {
	font-size: 10px;
	margin-right: 20px;
	color: var(--sk-dark-navy);
}
</style>

<script>
import TransitionExpand from "@/components/transitions/TransitionExpand.vue"

export default {
	name: "CollapsibleSection",
	components: {
		TransitionExpand
	},
	props: {
		title: String,
		subtitle: [String],
		badgeText: [String, Number],
		defaultExpanded: {
			type: Boolean,
			default: false
		},
		index: Number
	},
	data: function () {
		return {
			expanded: false
		}
	},
	methods: {
		toggleExpand() {
			this.expanded = !this.expanded
		}
	},
	mounted: function () {
		this.expanded = this.defaultExpanded
	}
}
</script>