var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sk-select",class:_vm.selectInputClasses,attrs:{"tabindex":"0"},on:{"blur":_vm.onBlur,"click":_vm.toggleDropdown}},[(_vm.hasCustomTrigger && _vm.model)?_vm._t("default"):(_vm.model)?_c('div',{staticClass:"selected",class:{ placeholder: _vm.model.id == null }},[(_vm.showSelectedOption)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.model.text)}}):_vm._e(),_c('i',{class:[
				_vm.iconClasses,
				_vm.iconClasses == 'sk-icon-chevron-down-regular' && _vm.open
					? 'flipped'
					: '',
				!_vm.showSelectedOption ? 'dropdown-icon' : ''
			]})]):_vm._e(),_c('div',{ref:"optionsList",staticClass:"options dark-scrollbar",class:{ 'select-hide': !_vm.open },style:(_vm.positionStyle)},_vm._l((_vm.validOptions),function(option){return _c('div',{key:option.id,staticClass:"option",class:{
				selected: !_vm.showSelectedOption || option.id == _vm.model.id,
				highlight: option.highlight
			},on:{"mousedown":function($event){return _vm.selectOption(option, $event)}}},[_c('div',{staticClass:"option-text",domProps:{"innerHTML":_vm._s(option.text)}})])}),0)],2)}
var staticRenderFns = []

export { render, staticRenderFns }