let orderProductPricing = {
	props: {
		type: {
			type: Number,
			default: 0
		}
	},
	computed: {
		productPrices() {
			const products = this.item.products
			const prices = {}
			products.forEach((product, index) => {
				let key = `${product.id}_${index}`
				prices[key] = {
					price: this.calcProductPrice(product, key),
					cogs: this.calcProductCogsPrice(product, key)
				}
			})
			return prices
		},
		productMetas() {
			const products = this.item.products
			const metas = {}
			products.forEach((product, index) => {
				let key = `${product.id}_${index}`
				metas[key] = this.type == 1 ? product.metas : product.meta
			})
			return metas
		},
		tax() {
			if (this.business) {
				const fees =
					this.business.service_fee / 100 + this.business.tax / 100
				return (this.subtotal * fees).toFixed(2)
			}
			return 0
		},
		cogsTax() {
			if (this.business) {
				const fees = this.business.tax / 100
				return (this.totalCogs * fees).toFixed(2)
			}
			return 0
		},
		tip() {
			return this.order ? parseFloat(this.order.tip.toFixed(2)) : 0
		},
		subtotal() {
			let price = 0
			if (this.products && this.products.length > 0) {
				this.products.forEach((product, index) => {
					let key = `${product.id}_${index}`
					price = price + parseFloat(product.subtotal)
					const metas = this.productMetas[key]
					if (metas && metas.length > 0) {
						metas.forEach((meta) => {
							price =
								price +
								(parseFloat(meta.price) * product.quantity)
						})
					}
				})
			}

			return price
		},
		totalCogs() {
			let price = 0
			if (this.products && this.products.length > 0) {
				this.products.forEach((product, index) => {
					let key = `${product.id}_${index}`
					price = price + parseFloat(product.cog_item_total_cost)
					const metas = this.productMetas[key]
					if (metas && metas.length > 0) {
						metas.forEach((meta) => {
							price =
								price +
								(parseFloat(meta.price) * product.quantity)
						})
					}
				})
			}
			return price
		},
		totalCogsWithTax() {
			return parseFloat(this.totalCogs) + parseFloat(this.cogsTax)
		},
		totalCogsWithTip() {
			return parseFloat(this.tip) + this.totalCogsWithTax
		}
	},
	methods: {
		calcProductCogsPrice(product, key) {
			let price = parseFloat(product.cog_item_total_cost)
			const metas = this.productMetas[key]
			if (metas != null && metas != undefined) {
				metas.forEach((meta) => {
					price =
						price +
						parseFloat(meta.price) * parseFloat(product.quantity)
				})
			}
			return price
		},
		calcProductPrice(product, key) {
			let price = parseFloat(product.subtotal)
			const metas = this.productMetas[key]
			if (metas != null && metas != undefined) {
				metas.forEach((meta) => {
					price =
						price +
						parseFloat(meta.price) * parseFloat(product.quantity)
				})
			}
			return price
		}
	}
}

export default orderProductPricing