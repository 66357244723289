<template>
	<SkBaseModal
		:isOpen="isOpen"
		:hasLoader="true"
		@close="close"
		class="sk-modal-alt"
	>
		<loading :active.sync="loading" :is-full-page="false" />
		<div class="sk-modal-alt-title">
			<h1>Add New Business</h1>
		</div>
		<div class="sk-row">
			<TypeaheadInput
				:placeholder="'Business'"
				:fetch="autocompleteBusinesses"
				:serializer="typeaheadSerializer"
				:query="businessQuery"
				@selected="businessSelected"
			/>
		</div>
		<div class="sk-row">
			<SkDropdownMultiSelect
				v-model="menuIds"
				:options="menuOptions"
				:class="{ disabled: !menuOptions }"
			/>
		</div>
		<div class="sk-modal-actions">
			<button @click="close" class="button button-text">Cancel</button>
			<button
				type="button"
				class="button button-primary"
				@click="addBusiness"
			>
				<i class="sk-icon-check-regular"></i>
				Add New Business
			</button>
		</div>
	</SkBaseModal>
</template>

<script>
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import ActionTypes from "@/store/modules/hubs/action-types"
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import SkDropdownMultiSelect from "@/components/SkDropdownMultiSelect.vue"
import TypeaheadInput from "@/components/TypeaheadInput.vue"
import Loading from "vue-loading-overlay"

export default {
	name: "AddHubBusinessModal",
	mixins: [skModalMixin],
	components: {
		SkBaseModal,
		SkDropdownMultiSelect,
		TypeaheadInput,
		Loading
	},
	data: function () {
		return {
			loading: false,
			businessQuery: "",
			business: null,
			menuIds: []
		}
	},
	computed: {
		hubBusinessIds() {
			return this.$store.state.hubsModule.currentHub
				? this.$store.state.hubsModule.currentHub.business_ids
				: []
		},
		hubMenuIds() {
			return this.$store.state.hubsModule.currentHub
				? this.$store.state.hubsModule.currentHub.restaurant_menu_ids
				: []
		},
		restaurantMenus() {
			return this.$store.state.hubsModule.currentHubRestaurantMenus
		},
		menuOptions() {
			const options = [
				{
					text: "Select Menu",
					id: null
				}
			]

			this.restaurantMenus.forEach((menu) => {
				options.push({
					text: menu.name,
					id: menu.id
				})
			})

			return options
		}
	},
	methods: {
		async handleOptions() {
			try {
				this.loading = false
				this.businessQuery = ""
				this.business = null
				this.menus = []
				this.menuIds = []
				if (this.hubMenuIds.length > 0) {
					this.loading = true
					await this.$store.dispatch(
						`hubsModule/${ActionTypes.GET_HUB_RESTAURANT_MENUS}`
					)
					this.loading = false
				}
			} catch (e) {
				this.loading = false
				this.close()
				throw e
			}
		},
		typeaheadSerializer(result) {
			return {
				text: result.name
			}
		},
		autocompleteBusinesses(query) {
			return this.$store.dispatch(
				"businessesModule/autocompleteBusinesses",
				query
			)
		},
		businessSelected(business) {
			if (
				!business ||
				!this.business ||
				business.id != this.business.id
			) {
				this.menuIds = []
			}
			this.business = business
		},
		validateBusiness() {
			if (!this.business) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Add New Hub Business",
					message: "Please select a business to continue",
					type: NotificationType.WARNING
				})
				return false
			}
			if (
				this.business &&
				this.hubBusinessIds.includes(this.business.id)
			) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Add New Hub Business",
					message: `${this.business.name} is already a part of the hub`,
					type: NotificationType.WARNING
				})
				return false
			}
			return true
		},
		async addBusiness() {
			try {
				this.loading = true
				const isValid = this.validateBusiness()
				if (isValid) {
					const success = await this.$store.dispatch(
						`hubsModule/${ActionTypes.ADD_HUB_BUSINESS}`,
						{
							businessId: this.business.id,
							menuIds: this.menuIds
						}
					)
					if (success) {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Add New Hub Business",
							message: `${this.business.name} was successfully added!`,
							type: NotificationType.SUCCESS
						})
						this.close()
					} else {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Add New Hub Business",
							message: "Something went wrong, please try again",
							type: NotificationType.ERROR
						})
					}
				}
				this.loading = false
			} catch (e) {
				this.loading = false
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Add New Hub Business",
					message: "Something went wrong, please try again",
					type: NotificationType.ERROR
				})
				throw e
			}
		}
	}
}
</script>