let batchSelectMixin = {
    data: function() {
        return {
            batchSelectedIds: []
        }
    },
    computed: {
        batchableEntityIds() {
            return []
        },
        allBatchableEntitiesSelected() {
            if (this.batchableEntityIds.length > 0) {
                return this.batchableEntityIds.every((entityId) =>
                    this.batchSelectedIds.includes(entityId)
                )
            }
            return false
        },
    },
    methods: {
        batchSelectAllEntitiesClicked(event) {
            event.stopPropagation()
            if (!this.allBatchableEntitiesSelected) {
                this.batchableEntityIds.forEach((entityId) => {
                    if (!this.batchSelectedIds.includes(entityId)) {
                        this.batchSelectedIds.push(entityId)
                    }
                })
            } else {
                this.batchSelectedIds = this.batchSelectedIds.filter((entityId) => {
                    return !this.batchableEntityIds.includes(entityId)
                })
            }
        },
        batchSelectEntity(entityId) {
            const index = this.batchSelectedIds.indexOf(entityId)
            if (index > -1) {
                this.batchSelectedIds.splice(index, 1)
            } else {
                this.batchSelectedIds.push(entityId)
            }
        },
        cleanUpBatchIds() {}
    },
    beforeDestroy: function() {
        this.batchSelectedIds = []
        this.cleanUpBatchIds()
    }
}

export default batchSelectMixin