<template>
	<SkBaseModal
		class="sk-modal-alt"
		:isOpen="isOpen"
		:showCloseButton="false"
		:hasLoader="true"
		:showContentBackdrop="showContentBackdrop"
		@close="close"
		@contentBackdropClicked="contentBackdropClicked"
	>
		<loading :active.sync="loading" :is-full-page="false"></loading>
		<div class="sk-modal-alt-title">
			<h1>{{ title }}</h1>
		</div>
		<BatchEditProductPropertyWidget
			:title="'Stock Status'"
			:propertyValue="stockStatusText"
			ref="propertyWidget0"
			@expanded="toggleBackdrop($event, 0)"
		>
			<div
				:class="{ selected: this.stockStatus == 1 }"
				class="property-option"
				@click="setStockStatus(1)"
			>
				In Stock
			</div>
			<div
				:class="{ selected: this.stockStatus == 0 }"
				class="property-option"
				@click="setStockStatus(0)"
			>
				Out of Stock
			</div>
		</BatchEditProductPropertyWidget>
		<BatchEditProductPropertyWidget
			:title="'Product Image'"
			:propertyValue="productImageText"
			ref="propertyWidget1"
			@expanded="toggleBackdrop($event, 1)"
		>
			<div class="upload-image-row">
				<FileUpload
					:entity="'product'"
					:entity_name="productImageEntityName"
					@set="setProductImage"
					@loading="setLoader"
				>
					<div
						class="
							button button-text-navy button-auto
							upload-image-text
						"
					>
						<i class="sk-icon-plus-regular"></i>
						Upload New Image
					</div>
				</FileUpload>
				<div
					v-if="productImage"
					class="
						button button-text-navy button-auto
						upload-image-text
					"
					@click="setProductImage(null)"
				>
					<i class="sk-icon-times-regular"></i>
					Remove Image
				</div>
				<img
					v-if="productImage"
					class="uploaded-image"
					:src="productImage"
					alt="New Product Image"
				/>
			</div>
		</BatchEditProductPropertyWidget>
		<BatchEditProductPropertyWidget
			:title="'Product Name'"
			:propertyValue="productNameText"
			@expanded="toggleBackdrop($event, 2)"
			ref="propertyWidget2"
		>
			<SkInput :name="'Update Product Name'" v-model="updates.name" />
			<div class="sk-divider property-divider"></div>
			<div
				class="property-option property-option-result"
				v-for="suggestion in nameSuggestions"
				@click="setProductName(suggestion.text)"
				:key="suggestion.id"
			>
				{{ suggestion.text }}
			</div>
		</BatchEditProductPropertyWidget>
		<BatchEditProductPropertyWidget
			:title="'Menus'"
			:propertyValue="menuText"
			ref="propertyWidget3"
			@expanded="toggleBackdrop($event, 3)"
		>
			<SkInput :name="'Search Menus'" v-model="menuSearch" />
			<div class="sk-divider property-divider"></div>
			<div
				v-if="hasMenus"
				class="property-option property-option-result"
				@click="setMenu(null)"
			>
				Remove all menus
			</div>
			<div
				class="property-option property-option-result"
				v-for="suggestion in menuSuggestions"
				@click="setMenu(suggestion)"
				:key="suggestion.id"
			>
				<SkCheckbox :checked="suggestion.checked" />
				{{ suggestion.text }}
			</div>
		</BatchEditProductPropertyWidget>
		<BatchEditProductPropertyWidget
			:title="'Category'"
			:propertyValue="categoryText"
			ref="propertyWidget4"
			@expanded="toggleBackdrop($event, 4)"
		>
			<SkInput :name="'Search Categories'" v-model="categorySearch" />
			<div class="sk-divider property-divider"></div>
			<div
				v-if="hasCategory"
				class="property-option property-option-result"
				@click="setCategory(null)"
			>
				Remove Category
			</div>
			<div
				class="property-option property-option-result"
				v-for="suggestion in categorySuggestions"
				@click="setCategory(suggestion)"
				:key="suggestion.id"
			>
				{{ suggestion.text }}
			</div>
		</BatchEditProductPropertyWidget>
		<BatchEditProductPropertyWidget
			:title="'Labels'"
			:propertyValue="labelText"
			ref="propertyWidget5"
			@expanded="toggleBackdrop($event, 5)"
		>
			<SkInput :name="'Search Labels'" v-model="labelSearch" />
			<div class="sk-divider property-divider"></div>
			<div
				v-if="hasLabels"
				class="property-option property-option-result"
				@click="setLabel(null)"
			>
				Remove all labels
			</div>
			<div
				class="property-option property-option-result"
				v-for="suggestion in labelSuggestions"
				@click="setLabel(suggestion)"
				:key="suggestion.id"
			>
				<SkCheckbox :checked="suggestion.checked" />
				{{ suggestion.text }}
			</div>
		</BatchEditProductPropertyWidget>
		<BatchEditProductPropertyWidget
			:title="'Alcohol'"
			:propertyValue="containsAlcoholText"
			ref="propertyWidget6"
			@expanded="toggleBackdrop($event, 6)"
		>
			<div
				:class="{ selected: this.containsAlcohol == 1 }"
				class="property-option"
				@click="setContainsAlcohol(1)"
			>
				Yes
			</div>
			<div
				:class="{ selected: this.containsAlcohol == 0 }"
				class="property-option"
				@click="setContainsAlcohol(0)"
			>
				No
			</div>
		</BatchEditProductPropertyWidget>
		<BatchEditProductPropertyWidget
			:title="'Comments Enabled'"
			:propertyValue="commentsEnabledText"
			ref="propertyWidget9"
			@expanded="toggleBackdrop($event, 9)"
		>
			<div
				:class="{ selected: this.commentsEnabled == 1 }"
				class="property-option"
				@click="setCommentsEnabled(1)"
			>
				Yes
			</div>
			<div
				:class="{ selected: this.commentsEnabled == 0 }"
				class="property-option"
				@click="setCommentsEnabled(0)"
			>
				No
			</div>
		</BatchEditProductPropertyWidget>
		<BatchEditProductPropertyWidget
			:title="'Price'"
			:propertyValue="productPriceText"
			ref="propertyWidget7"
			@expanded="toggleBackdrop($event, 7)"
		>
			<div class="sk-row">
				<SkInput
					:name="'Update Total Price'"
					:type="'number'"
					v-model="productPrice"
				/>
				<SkInput
					:name="'Add or Subtract from Price'"
					:type="'number'"
					v-model="productPriceDelta"
				/>
			</div>
		</BatchEditProductPropertyWidget>
		<BatchEditProductPropertyWidget
			:title="'Status'"
			:propertyValue="statusText"
			ref="propertyWidget8"
			@expanded="toggleBackdrop($event, 8)"
		>
			<div
				:class="{ selected: this.status == 1 }"
				class="property-option"
				@click="setStatus(1)"
			>
				Active
			</div>
			<div
				:class="{ selected: this.status == 2 }"
				class="property-option"
				@click="setStatus(2)"
			>
				Archived
			</div>
		</BatchEditProductPropertyWidget>
		<div class="sk-row" v-if="updates.name">
			<div class="sk-switch-label-wrapper">
				<SkCheckbox v-model="syncBusinessProducts" />
				<span class="price-override-description">
					Sync Business Products (Name only)
				</span>
			</div>
		</div>
		<div class="sk-modal-actions">
			<button @click="close" class="button button-text">Close</button>
			<button class="button button-primary" @click="updateProducts">
				Update {{ pluralizedProductsCopy }}
			</button>
		</div>
	</SkBaseModal>
</template>

<style scoped>
.property-option {
	font-size: 12px;
	color: var(--sk-grey2);
}

.property-option.selected {
	color: var(--sk-orange);
	font-weight: 600;
}

.property-option + .property-option {
	margin-top: 15px;
}

.sk-divider.property-divider {
	margin: 13px -20px;
	width: calc(100% + 40px);
}

.property-option-result {
	margin-left: 10px;
	align-items: center;
	display: flex;
}

.upload-image-row {
	display: flex;
	align-items: center;
	height: 54px;
}

.upload-image-text {
	margin-top: 15px;
	font-weight: 400 !important;
}

.dropzone-message .upload-image-text {
	padding: 0;
}

.uploaded-image {
	margin-left: auto;
	width: 100px;
	height: 54px;
	object-fit: cover;
}
</style>

<script>
import utils from "@/utils/utils"
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import pluralizeFilterMixin from "@/mixins/pluralize-filter-mixin"
import currencyFilterMixin from "@/mixins/currency-filter-mixin"
import SkInput from "@/components/SkInput.vue"
import SkDropdownMultiSelect from "@/components/SkDropdownMultiSelect.vue"
import SkCheckbox from "@/components/SkCheckbox.vue"
import Loading from "vue-loading-overlay"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import BatchEditProductPropertyWidget from "@/components/modals/restaurantProducts/BatchEditProductPropertyWidget.vue"
import FileUpload from "@/components/FileUpload.vue"
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import { ProductStatus } from "@/enums/productStatus"

export default {
	name: "BatchEditProductsModal",
	mixins: [skModalMixin, pluralizeFilterMixin, currencyFilterMixin],
	components: {
		SkBaseModal,
		SkInput,
		SkDropdownMultiSelect,
		SkCheckbox,
		Loading,
		BatchEditProductPropertyWidget,
		FileUpload
	},
	data() {
		return {
			loading: false,
			products: [],
			updates: {},
			showContentBackdrop: false,
			expandedIndex: null,
			categorySearch: "",
			labelSearch: "",
			menuSearch: "",
			syncBusinessProducts: true
		}
	},
	computed: {
		restaurant() {
			return this.$store.state.restaurantsModule.currentRestaurant
		},
		menusHash() {
			const menus =
				this.restaurant && this.restaurant.menus
					? this.restaurant.menus
					: []
			return menus.reduce((hash, menu) => {
				hash[menu.id] = menu.name
				return hash
			}, {})
		},
		pluralizedProductsCopy() {
			return this.$options.filters.pluralize(
				this.products.length,
				"Product"
			)
		},
		title() {
			return `Edit ${this.products.length} ${this.pluralizedProductsCopy}`
		},
		stockStatus() {
			let status = null
			if (this.updates.stock_status != undefined) {
				status = this.updates.stock_status
			} else if (
				this.products &&
				this.products.length > 0 &&
				this.products.every(
					(product) =>
						this.products[0].stock_status == product.stock_status
				)
			) {
				status = this.products[0].stock_status
			}

			return status
		},
		stockStatusText() {
			if (this.stockStatus != null) {
				return this.stockStatus ? "In Stock" : "Out of Stock"
			}
			return "Multiple"
		},
		status() {
			let status = null
			if (this.updates.status != undefined) {
				status = this.updates.status
			} else if (
				this.products.length > 0 &&
				this.products.every(
					(product) => this.products[0].status == product.status
				)
			) {
				status = this.products[0].status
			}

			return status
		},
		statusText() {
			if (this.status != null) {
				return this.status == ProductStatus.ACTIVE
					? "Active"
					: "Archived"
			}
			return "Multiple"
		},
		containsAlcohol() {
			let containsAlcohol = null
			if (this.updates.contains_alcohol != undefined) {
				containsAlcohol = this.updates.contains_alcohol
			} else if (
				this.products.length > 0 &&
				this.products.every(
					(product) =>
						this.products[0].contains_alcohol ==
						product.contains_alcohol
				)
			) {
				containsAlcohol = this.products[0].contains_alcohol
			}

			return containsAlcohol
		},
		containsAlcoholText() {
			if (this.containsAlcohol != null) {
				return this.containsAlcohol ? "Yes" : "No"
			}
			return "Multiple"
		},
		commentsEnabled() {
			let commentsEnabled = null
			if (this.updates.comments_enabled != undefined) {
				commentsEnabled = this.updates.comments_enabled
			} else if (
				this.products.length > 0 &&
				this.products.every(
					(product) =>
						this.products[0].comments_enabled ==
						product.comments_enabled
				)
			) {
				commentsEnabled = this.products[0].comments_enabled
			}

			return commentsEnabled
		},
		commentsEnabledText() {
			if (this.commentsEnabled != null) {
				return this.commentsEnabled ? "Yes" : "No"
			}
			return "Multiple"
		},
		nameSuggestions() {
			if (!this.products || this.products.length == 0) {
				return []
			}

			let products = []

			if (this.updates.name && this.updates.name.length > 0) {
				const nameRegex = utils.stringToRegex(this.updates.name)
				products = this.products
					.filter(
						(product) =>
							product.name &&
							product.name.match(nameRegex) !== null
					)
					.sort((a, b) => {
						const aIndex = a.name.indexOf(
							a.name.match(nameRegex)[0]
						)
						const bIndex = b.name.indexOf(
							b.name.match(nameRegex)[0]
						)
						if (aIndex < bIndex) {
							return -1
						}
						if (aIndex > bIndex) {
							return 1
						}
						return 0
					})
					.splice(0, 3)
			} else {
				products = this.products.slice(0, 3)
			}

			const suggestions = products.map((product) => {
				return {
					id: product.id,
					text: product.name
				}
			})

			if (!suggestions) {
				return {
					id: -1,
					text: this.updates.name
				}
			}

			return suggestions
		},
		productNameText() {
			return this.updates.name ? this.updates.name : "Multiple"
		},
		categorySuggestions() {
			let categories = this.$store.state.restaurantsModule.categories
				? this.$store.state.restaurantsModule.categories
				: []

			if (this.categorySearch && this.categorySearch.length > 0) {
				const categoriesRegex = utils.stringToRegex(this.categorySearch)
				categories = categories
					.filter(
						(category) =>
							category.name &&
							category.name.match(categoriesRegex) !== null
					)
					.sort((a, b) => {
						const aIndex = a.name.indexOf(
							a.name.match(categoriesRegex)[0]
						)
						const bIndex = b.name.indexOf(
							b.name.match(categoriesRegex)[0]
						)
						if (aIndex < bIndex) {
							return -1
						}
						if (aIndex > bIndex) {
							return 1
						}
						return 0
					})
					.splice(0, 3)
			} else {
				categories = categories.slice(0, 3)
			}

			const suggestions = categories.map((category) => {
				return {
					id: category.id,
					text: category.name
				}
			})

			return suggestions
		},
		categoryText() {
			let categories = this.$store.state.restaurantsModule.categories
				? this.$store.state.restaurantsModule.categories
				: []
			let categoryId = null
			if (this.updates.category_id > -1) {
				categoryId = this.updates.category_id
			} else if (
				this.products.length > 0 &&
				this.products.every(
					(product) =>
						product.category_id == this.products[0].category_id
				)
			) {
				categoryId = this.products[0].category_id
			}

			if (categoryId != null) {
				const category = categories.find(
					(category) => category.id == categoryId
				)
				return category ? category.name : "N/A"
			} else if (this.updates.category_id == null) {
				return "None"
			}

			return "Multiple"
		},
		hasCategory() {
			if (this.updates.category_id != null) {
				return true
			}
			return this.products.some((product) => product.category_id > -1)
		},
		labelsMap() {
			let labels = this.$store.state.restaurantsModule.labels
				? this.$store.state.restaurantsModule.labels
				: []
			return labels.reduce((hash, label) => {
				hash[label.id] = label.name
				return hash
			}, {})
		},
		labelSuggestions() {
			let suggestions = []
			const labels = this.$store.state.restaurantsModule.labels
				? this.$store.state.restaurantsModule.labels
				: []
			const selectedLabelIds = this.updates.added_labels
				? this.updates.added_labels
				: []
			const removedLabelIds = this.updates.removed_labels
				? this.updates.removed_labels
				: []
			let preselectedLabelIds = []
			if (this.products) {
				this.products.forEach((product) => {
					if (product.labels) {
						product.labels.forEach((label) => {
							if (
								!preselectedLabelIds.includes(label.id) &&
								!removedLabelIds.includes(label.id)
							) {
								preselectedLabelIds.push(label.id)
							}
						})
					}
				})
			}

			if (selectedLabelIds.length > 0 || preselectedLabelIds.length > 0) {
				const onlyPreselectedLabelIds = preselectedLabelIds.filter(
					(labelId) => !selectedLabelIds.includes(labelId)
				)
				suggestions = selectedLabelIds
					.concat(onlyPreselectedLabelIds)
					.map((labelId) => {
						const selected = selectedLabelIds.includes(labelId)
						const preselected =
							preselectedLabelIds.includes(labelId)
						let text = this.labelsMap[labelId]
							? this.labelsMap[labelId]
							: ""
						let productCount =
							(preselected && selected) || selected
								? this.products.length
								: this.products.filter(
										(product) =>
											product.labels &&
											product.labels.some(
												(label) => label.id == labelId
											)
								  ).length
						return {
							id: labelId,
							text: `${text} (${productCount}/${this.products.length})`,
							checked: selected || preselected,
							selected: selected,
							preselected: preselected
						}
					})
			}

			let unselectedLabels = []
			if (this.labelSearch && this.labelSearch.length > 0) {
				const labelRegex = utils.stringToRegex(this.labelSearch)
				unselectedLabels = labels
					.filter(
						(label) =>
							label.name &&
							label.name.match(labelRegex) !== null &&
							!selectedLabelIds.includes(label.id) &&
							!preselectedLabelIds.includes(label.id)
					)
					.sort((a, b) => {
						const aIndex = a.name.indexOf(
							a.name.match(labelRegex)[0]
						)
						const bIndex = b.name.indexOf(
							b.name.match(labelRegex)[0]
						)
						if (aIndex < bIndex) {
							return -1
						}
						if (aIndex > bIndex) {
							return 1
						}
						return 0
					})
					.splice(0, 3)
			} else {
				unselectedLabels = labels
					.filter((label) => {
						return (
							!selectedLabelIds.includes(label.id) &&
							!preselectedLabelIds.includes(label.id)
						)
					})
					.slice(0, 3)
			}

			unselectedLabels = unselectedLabels.map((label) => {
				return {
					id: label.id,
					text: label.name,
					checked: false,
					selected: false,
					preselected: false
				}
			})

			return suggestions.concat(unselectedLabels)
		},
		labelText() {
			if (this.updates.added_labels) {
				return this.updates.added_labels.length > 0
					? "Multiple"
					: "None"
			} else if (this.hasLabels) {
				if (
					this.updates.removed_labels &&
					this.updates.removed_labels.length > 0
				) {
					const remainingLabelIds = this.products
						.map((product) => {
							return product.labels
								? product.labels.map((label) => label.id)
								: []
						})
						.flat()
						.filter(
							(labelId) =>
								!this.updates.removed_labels.includes(labelId)
						)
					return remainingLabelIds.length > 0 ? "Multiple" : "None"
				} else {
					return "Multiple"
				}
			}
			return "None"
		},
		hasLabels() {
			if (
				this.updates.added_labels &&
				this.updates.added_labels.length > 0
			) {
				return true
			}
			if (
				this.updates.removed_labels &&
				this.updates.removed_labels.length > 0
			) {
				const remainingLabelIds = this.products
					.map((product) => {
						return product.labels
							? product.labels.map((label) => label.id)
							: []
					})
					.flat()
					.filter(
						(labelId) =>
							!this.updates.removed_labels.includes(labelId)
					)
				return remainingLabelIds.length > 0
			}
			return this.products.some(
				(product) => product.labels && product.labels.length > 0
			)
		},
		productImageText() {
			if (this.updates.img) {
				return this.updates.img
			} else if (
				this.products.length > 0 &&
				this.products.every(
					(product) => this.products[0].img == product.img
				)
			) {
				return this.products[0].img
			}

			return "Multiple"
		},
		productImageEntityName() {
			return this.restaurant ? this.restaurant.slug : ""
		},
		productImage() {
			if (
				!this.updates.img ||
				this.updates.img.length == 0 ||
				!this.restaurant
			) {
				return null
			}
			const filenameArr = this.updates.img.split(".")
			const sizedFileName =
				filenameArr[0] + "_" + "500x500." + filenameArr[1]
			return (
				this.$restaurantCDNURL +
				this.restaurant.slug +
				"/" +
				sizedFileName
			)
		},
		productPrice: {
			get: function () {
				return this.updates.cogs != undefined ? this.updates.cogs : ""
			},
			set: function (value) {
				this.$set(this.updates, "cogs", value)
			}
		},
		productPriceDelta: {
			get: function () {
				return this.updates.cogs_delta != undefined
					? this.updates.cogs_delta
					: ""
			},
			set: function (value) {
				this.$set(this.updates, "cogs_delta", value)
			}
		},
		productPriceText() {
			if (this.updates.cogs != undefined) {
				let cogs = this.updates.cogs
				if (this.updates.cogs_delta != undefined) {
					cogs += this.updates.cogs_delta
				}
				return this.$options.filters.currency(cogs)
			} else if (this.updates.cogs_delta != undefined) {
				return this.updates.cogs_delta > 0
					? `+${this.$options.filters.currency(
							this.updates.cogs_delta
					  )}`
					: this.$options.filters.currency(this.updates.cogs_delta)
			} else if (
				this.products.length > 0 &&
				this.products.every(
					(product) => this.products[0].cogs == product.cogs
				)
			) {
				return this.$options.filters.currency(this.products[0].cogs)
			}
			return "Multiple"
		},
		menuSuggestions() {
			let suggestions = []
			const menus =
				this.restaurant && this.restaurant.menus
					? this.restaurant.menus
					: []
			const selectedMenuIds = this.updates.added_menus
				? this.updates.added_menus
				: []
			const removedMenuIds = this.updates.removed_menus
				? this.updates.removed_menus
				: []
			let preselectedMenuIds = []
			if (this.products) {
				this.products.forEach((product) => {
					product.menu_ids.forEach((menuId) => {
						if (
							!preselectedMenuIds.includes(menuId) &&
							!removedMenuIds.includes(menuId)
						) {
							preselectedMenuIds.push(menuId)
						}
					})
				})
			}

			if (selectedMenuIds.length > 0 || preselectedMenuIds.length > 0) {
				const onlyPreselectedMenuIds = preselectedMenuIds.filter(
					(menuId) => !selectedMenuIds.includes(menuId)
				)
				suggestions = selectedMenuIds
					.concat(onlyPreselectedMenuIds)
					.map((menuId) => {
						const selected = selectedMenuIds.includes(menuId)
						const preselected = preselectedMenuIds.includes(menuId)
						let text = this.menusHash[menuId]
							? this.menusHash[menuId]
							: ""
						let productCount =
							(preselected && selected) || selected
								? this.products.length
								: this.products.filter((product) =>
										product.menu_ids.includes(menuId)
								  ).length

						return {
							id: menuId,
							text: `${text} (${productCount}/${this.products.length})`,
							checked: selected || preselected,
							selected: selected,
							preselected: preselected
						}
					})
			}

			let unselectedMenus = []
			if (this.menuSearch && this.menuSearch.length > 0) {
				const menuRegex = utils.stringToRegex(this.menuSearch)
				unselectedMenus = menus
					.filter(
						(menu) =>
							menu.name &&
							menu.name.match(menuRegex) !== null &&
							!selectedMenuIds.includes(menu.id) &&
							!preselectedMenuIds.includes(menu.id)
					)
					.sort((a, b) => {
						const aIndex = a.name.indexOf(
							a.name.match(menuRegex)[0]
						)
						const bIndex = b.name.indexOf(
							b.name.match(menuRegex)[0]
						)
						if (aIndex < bIndex) {
							return -1
						}
						if (aIndex > bIndex) {
							return 1
						}
						return 0
					})
					.splice(0, 3)
			} else {
				unselectedMenus = menus
					.filter(
						(menu) =>
							!selectedMenuIds.includes(menu.id) &&
							!preselectedMenuIds.includes(menu.id)
					)
					.slice(0, 3)
			}

			unselectedMenus = unselectedMenus.map((menu) => {
				return {
					id: menu.id,
					text: menu.name,
					checked: false,
					selected: false,
					preselected: false
				}
			})

			return suggestions.concat(unselectedMenus)
		},
		menuText() {
			if (this.updates.added_menus) {
				return this.updates.added_menus.length > 0 ? "Multiple" : "None"
			} else if (this.hasMenus) {
				if (this.updates.removed_menus) {
					const remainingMenuIds = this.products
						.map((product) => {
							return product.menu_ids ? product.menu_ids : []
						})
						.flat()
						.filter(
							(menuId) =>
								!this.updates.removed_menus.includes(menuId)
						)
					return remainingMenuIds.length > 0 ? "Multiple" : "None"
				} else {
					return "Multiple"
				}
			}
			return "None"
		},
		hasMenus() {
			if (
				this.updates.added_menus &&
				this.updates.added_menus.length > 0
			) {
				return true
			}
			if (this.updates.removed_menus) {
				const remainingMenuIds = this.products
					.map((product) => {
						return product.menu_ids ? product.menu_ids : []
					})
					.flat()
					.filter(
						(menuId) => !this.updates.removed_menus.includes(menuId)
					)
				return remainingMenuIds.length > 0
			}
			return this.products.some(
				(product) => product.menu_ids && product.menu_ids.length > 0
			)
		}
	},
	methods: {
		opened() {
			this.loading = false
			this.updates = {}
		},
		closed() {
			this.showContentBackdrop = false
			this.expandedIndex = null
			this.categorySearch = ""
			this.labelSearch = ""
			this.menuSearch = ""
			this.syncBusinessProducts = true
			this.products = []
		},
		handleOptions(productIds) {
			let products = this.$store.state.restaurantsModule
				.restaurantProducts
				? this.$store.state.restaurantsModule.restaurantProducts
				: []
			products = products
				.filter((product) => productIds.includes(product.id))
				.map((product) => utils.clonedeep(product))
			this.products = products
		},
		setLoader(loading) {
			this.loading = loading
		},
		setStockStatus(stockStatus) {
			this.$set(this.updates, "stock_status", stockStatus)
		},
		setProductName(name) {
			this.$set(this.updates, "name", name)
		},
		setContainsAlcohol(containsAlcohol) {
			this.$set(this.updates, "contains_alcohol", containsAlcohol)
		},
		setCommentsEnabled(commentsEnabled) {
			this.$set(this.updates, "comments_enabled", commentsEnabled)
		},
		setStatus(status) {
			this.$set(this.updates, "status", status)
		},
		setCategory(category) {
			if (category) {
				this.$set(this.updates, "category_id", category.id)
			} else {
				this.categorySearch = ""
				this.$set(this.updates, "category_id", null)
			}
		},
		setLabel(label) {
			if (label) {
				let addLabelIds = this.updates.added_labels
					? this.updates.added_labels
					: []
				let removeLabelIds = this.updates.removed_labels
					? this.updates.removed_labels
					: []
				if (label.checked) {
					addLabelIds = addLabelIds.filter(
						(labelId) => labelId != label.id
					)
					removeLabelIds.push(label.id)
				} else {
					removeLabelIds = removeLabelIds.filter(
						(labelId) => labelId != label.id
					)
					addLabelIds.push(label.id)
				}
				this.$set(this.updates, "added_labels", addLabelIds)
				this.$set(this.updates, "removed_labels", removeLabelIds)
			} else {
				this.labelSearch = ""
				const removeLabelIds = this.products
					.map((product) => {
						return product.labels
							? product.labels.map((label) => label.id)
							: []
					})
					.flat()
				this.$set(this.updates, "added_labels", [])
				this.$set(this.updates, "removed_labels", removeLabelIds)
			}
		},
		setProductImage(img) {
			this.$set(this.updates, "img", img)
		},
		setMenu(menu) {
			if (menu) {
				let addMenuIds = this.updates.added_menus
					? this.updates.added_menus
					: []
				let removeMenuIds = this.updates.removed_menus
					? this.updates.removed_menus
					: []
				if (menu.checked) {
					addMenuIds = addMenuIds.filter(
						(menuId) => menuId != menu.id
					)
					removeMenuIds.push(menu.id)
				} else {
					addMenuIds.push(menu.id)
					removeMenuIds = removeMenuIds.filter(
						(menuId) => menuId != menu.id
					)
				}
				this.$set(this.updates, "added_menus", addMenuIds)
				this.$set(this.updates, "removed_menus", removeMenuIds)
			} else {
				this.menuSearch = ""
				const removeMenuIds = this.products
					.map((product) => {
						return product.menu_ids ? product.menu_ids : []
					})
					.flat()
				this.$set(this.updates, "added_menus", [])
				this.$set(this.updates, "removed_menus", removeMenuIds)
			}
		},
		toggleBackdrop(expanded, index) {
			this.showContentBackdrop = expanded
			this.expandedIndex = expanded ? index : null
		},
		contentBackdropClicked() {
			const expandedWidget =
				this.$refs[`propertyWidget${this.expandedIndex}`]
			if (expandedWidget) {
				expandedWidget.toggleExpand()
			}
		},
		async updateProducts() {
			if (utils.isObjEmpty(this.updates)) {
				this.close()
				return
			}
			let status = null
			if ("status" in this.updates) {
				status = this.updates.status
				delete this.updates.status
			}

			try {
				this.setLoader(true)
				const productIDs = this.products.map((product) => product.id)
				if (!utils.isObjEmpty(this.updates)) {
					const batchSuccess = await this.$store.dispatch(
						"restaurantsModule/batchUpdateProducts",
						{
							productIds: productIDs,
							updates: this.updates
						}
					)

					if (batchSuccess) {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Batch Edit Products",
							message: "Updated products successfully!"
						})
						await this.syncAllBusinessMenuProducts()
						this.close()
					} else {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Batch Edit Products",
							message: "Failed to update products",
							type: NotificationType.ERROR
						})
					}
				}
				if (status != null) {
					const commandName =
						status == ProductStatus.ARCHIVED
							? "batchArchiveProducts"
							: "batchUnArchiveProducts"
					const successText =
						status == ProductStatus.ARCHIVED
							? "Succesfully Archived Products"
							: "Succesfully Activated Products"
					const failureText =
						status == ProductStatus.ARCHIVED
							? "Failed to archive products"
							: "Failed to activate products"
					const titleText =
						status == ProductStatus.ARCHIVED
							? "Batch Archive Products"
							: "Batch Activate Products"
					const batchStatusSuccess = await this.$store.dispatch(
						`restaurantsModule/${commandName}`,
						productIDs
					)
					if (batchStatusSuccess) {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: titleText,
							message: successText
						})
						this.close()
					} else {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: titleText,
							message: failureText,
							type: NotificationType.ERROR
						})
					}
				}
				this.setLoader(false)
			} catch (e) {
				this.setLoader(false)
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Batch Edit Products",
					message: "Something went wrong please, try again",
					type: NotificationType.ERROR
				})
				throw e
			}
		},
		syncAllBusinessMenuProducts() {
			if (this.syncBusinessProducts && this.updates.name) {
				const productMap = this.products.reduce((map, product) => {
					map[product.id] = product
					return map
				}, {})
				const businessMenus = {}
				this.$store.state.restaurantsModule.restaurantBusinesses.forEach(
					(business) => {
						business.menus
							.filter(
								(menu) =>
									this.menusHash[menu.id] &&
									menu.products.find(
										(menuProduct) =>
											productMap[menuProduct.id]
									)
							)
							.forEach((menu) => {
								if (!businessMenus[business.id]) {
									businessMenus[business.id] = []
								}
								businessMenus[business.id].push({
									menu: menu,
									business: business
								})
							})
					}
				)
				const promises = []
				Object.values(businessMenus).forEach((businessMenu) => {
					businessMenu.forEach((businessMenu) => {
						const menu = businessMenu.menu
						const business = businessMenu.business
						let shouldUpdateMenu = false
						menu.products.forEach((product) => {
							if (productMap[product.id]) {
								shouldUpdateMenu = true
								product.name = productMap[product.id].name
							}
						})
						if (shouldUpdateMenu) {
							let promise = this.$store.dispatch(
								"businessesModule/updateBusinessMenu",
								{
									business: business,
									menu: menu
								}
							)
							promise
								.then((success) => {
									if (!success) {
										throw "Error"
									}
									eventBus.emit(
										EventBusEvents.SHOW_NOTIFICATION,
										{
											title: "Menu Changes",
											message: `${menu.name} has been updated!`
										}
									)
								})
								.catch(() => {
									eventBus.emit(
										EventBusEvents.SHOW_NOTIFICATION,
										{
											title: "Menu Changes",
											message: `Failed to update ${menu.name}`,
											type: NotificationType.ERROR
										}
									)
									this.$bugsnag.notify(
										"Failed to sync business menu product names",
										(event) => {
											event.addMetadata("error", {
												business: business,
												products: this.products
											})
										}
									)
								})
							promises.push(promise)
						}
					})
				})
				return promises
			}
			return false
		}
	}
}
</script>