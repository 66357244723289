<template>
	<div class="sk-page-header-container">
		<div class="sk-page-header">
			<div class="sk-page-header-left">
				<div>
					<h1>{{ title }}</h1>
					<slot name="title-decorator"></slot>
					<div class="sk-page-header-breadcrumbs">
						<div
							class="sk-page-header-breadcrumb"
							v-for="(breadcrumb, i) in breadcrumbs"
							:key="i"
						>
							<span
								v-if="i > 0 && i < breadcrumbs.length"
								class="sk-page-header-breadcrumb-divider"
							>
								>
							</span>
							<router-link
								v-if="breadcrumb.link"
								:to="breadcrumb.link"
								:title="breadcrumb.name"
							>
								{{ breadcrumb.name }}
							</router-link>
							<span v-else>
								{{ breadcrumb.name }}
							</span>
						</div>
					</div>
				</div>
				<div class="sk-page-header-actions">
					<ul class="sk-page-tabs" v-if="tabs && !isMobile">
						<li
							:class="{ 'active-tab': tab.active }"
							v-for="tab in visibleTabs"
							:key="tab.id"
							@click="tabClicked(tab)"
						>
							{{ tab.text }}
							<span v-if="tab.badgeCount" class="tab-badge">
								{{ tab.badgeCount }}
							</span>
						</li>
						<li
							v-if="showMoreTabs"
							:class="{ 'active-tab': moreTabSelected }"
						>
							<SkDropdownSelect
								:options="moreTabs"
								@selected="tabClicked"
							>
								<div class="more-tabs-text">
									<span>{{ moreTabsText }}</span>
									<i
										class="
											sk-icon-chevron-down-regular
											flippable
										"
									></i>
								</div>
							</SkDropdownSelect>
						</li>
					</ul>
					<ul class="sk-page-tabs" v-if="tabs && isMobile">
						<li
							:class="{ 'active-tab': tabs[0].active }"
							@click="tabClicked(tabs[0])"
						>
							{{ tabs[0].text }}
						</li>
						<li>
							<SkDropdownSelect
								:options="moreTabs"
								@selected="tabClicked"
							/>
						</li>
					</ul>
					<button
						class="filter-button"
						@click="toggleFilters"
						v-if="filters && filters.length > 0"
						:class="{ active: showingFilters }"
					>
						<i class="sk-icon-sliders-h-regular"></i>
						Filter
					</button>
					<transition name="fade">
						<div class="filter-pills" v-if="!showingFilters">
							<button
								class="pill pill-grey"
								v-for="filter in filterPills"
								:key="`${filter.type}-${filter.id}`"
								@click="clearFilter(filter)"
							>
								{{ filter | parseFilterLabels }}
								<i class="sk-icon-times-regular"></i>
							</button>
						</div>
					</transition>
				</div>
			</div>
			<div
				class="sk-page-header-right"
				v-if="actions || secondaryActions"
			>
				<button
					v-for="action in normalActions"
					:key="action.id"
					:class="action.classes"
					@click="actionClicked(action)"
					v-html="action.text"
				></button>
				<SkDropdownSelect
					v-for="action in actionsWithOptions"
					:key="action.id"
					:options="action.options"
					:showSelectedOption="false"
					@selected="actionClicked(action, $event)"
				>
					<button
						:class="action.classes"
						v-html="action.text"
					></button>
				</SkDropdownSelect>
				<SkDropdownSelect
					v-if="secondaryActions"
					:options="secondaryActions"
					:iconClasses="'sk-icon-ellipsis-v-regular'"
					:showSelectedOption="false"
					:position="'left'"
					@selected="secondaryActionClicked"
				/>
			</div>
		</div>
		<TransitionExpand>
			<div class="sk-page-header-filters" v-show="showingFilters">
				<div
					class="sk-page-filter"
					v-for="filter in basicFilters"
					:key="filter.id"
					:style="{ width: filter.width ? filter.width : undefined }"
					:class="{
						'sk-page-filter-search': filter.type == 'search'
					}"
				>
					<slot v-if="filter.type == 'input'">
						<input
							class="page-header-input"
							:ref="`input-${filter.id}`"
							:placeholder="filter.placeholder"
							v-model="filterValues[filter.id].formatted"
							@change="filterSet(filter, $event.target.value)"
						/>
					</slot>
					<slot v-if="filter.type == 'search'">
						<TypeaheadInput
							class="page-header-search"
							:showLabel="false"
							:ref="`search-${filter.id}`"
							:placeholder="filter.placeholder"
							:items="filter.items"
							:serializer="filter.serializer"
							:fetch="filter.fetch"
							:query="filterValues[filter.id].formatted"
							@selected="filterSet(filter, ...arguments)"
						/>
					</slot>
					<slot
						v-if="
							filter.type == 'dropdown' && filterValues[filter.id]
						"
					>
						<SkDropdownSelect
							class="page-header-dropdown"
							:ref="`dropdown-${filter.id}`"
							:options="filter.items"
							:value="filterValues[filter.id].value"
							@selected="filterSet(filter, ...arguments)"
						/>
					</slot>
					<slot
						v-if="
							filter.type == 'date' || filter.type == 'daterange'
						"
					>
						<VueCtkDateTimePicker
							v-if="filterValues[filter.id] != undefined"
							:no-value-to-custom-elem="true"
							:no-clear-button="true"
							:no-header="true"
							:auto-close="true"
							:no-label="true"
							:no-button-now="true"
							:no-button="true"
							:only-date="true"
							:format="'MM-DD-YYYY'"
							:formatted="'ll'"
							:color="'#2E3C49'"
							:output-format="'MMM DD, YYYY'"
							:range="filter.type == 'daterange'"
							:noShortcuts="!filter.shortcuts"
							:customShortcuts="
								filter.shortcuts ? filter.shortcuts : null
							"
							v-model="filterValues[filter.id].formatted"
							@input="filterSet(filter, ...arguments)"
						>
							<span
								class="date-button"
								:class="{ selected: filter.value != null }"
							>
								<i
									v-if="
										filterValues[filter.id].formatted &&
										filter.clearable
									"
									@click.stop="clearFilter(filter, true)"
									class="sk-icon-times-regular clear-icon"
								></i>
								{{
									filterValues[filter.id].formatted
										| parseDateValue(filter)
								}}
								<i class="sk-icon-calendar-alt-regular"></i>
							</span>
						</VueCtkDateTimePicker>
					</slot>
					<slot v-if="filter.type == 'multiselect'">
						<SkDropdownMultiSelect
							class="page-header-multiselect"
							:ref="`multiselect-${filter.id}`"
							:options="filter.items"
							:showSelectedOptions="true"
							@input="filterSet(filter, ...arguments)"
						/>
					</slot>
				</div>
				<div class="sk-page-filter-right">
					<div
						v-if="hasAdvancedFilters"
						class="
							sk-page-filter
							pill-alt pill-alt-grey
							advanced-filter
						"
						@click="openAdvancedFilterModal"
					>
						Advanced Filtering
					</div>
					<div class="sk-page-filter clear-filters" @click="clearAll">
						Clear All
						<i class="sk-icon-times-regular"></i>
					</div>
					<div
						class="sk-page-filter close-filters"
						@click="toggleFilters"
					>
						<i class="sk-icon-chevron-up-regular"></i>
					</div>
				</div>
			</div>
		</TransitionExpand>
		<AdvancedFilteringModal
			v-if="hasAdvancedFilters"
			ref="advancedFilteringModal"
			:filters="filters"
			:filterGroups="filterGroups"
		/>
	</div>
</template>

<style>
.page-header-input,
.page-header-search .sk-input,
.page-header-search .sk-input:focus {
	box-shadow: none;
	font-size: 14px;
	height: 40px;
	min-height: 40px;
	border: none;
}

.page-header-multiselect.sk-select,
.page-header-multiselect.sk-select:focus {
	box-shadow: none;
	font-size: 14px;
	height: 40px;
	min-height: 40px;
	border: none;
}

.page-header-multiselect .sk-row {
	margin: 0;
	width: 100%;
}

.page-header-multiselect .option + .option {
	margin-top: 15px;
}

.page-header-input {
	outline: none !important;
}

.page-header-input::placeholder {
	color: var(--sk-grey2);
}

.page-header-input {
	color: var(--sk-grey3);
}

.page-header-search {
	width: 100%;
}

.page-header-dropdown .selected,
.page-header-multiselect .selected {
	color: var(--sk-grey3);
}

.sk-page-filter .date-time-picker {
	color: var(--sk-grey2) !important;
}

.sk-page-filter .date-time-picker span {
	font-family: "Source Sans Pro";
}

.sk-page-tabs .sk-select.is-open .more-tabs-text i {
	transform: rotate(180deg);
	display: inline-block;
}
</style>

<style scoped>
.sk-page-header-container {
	z-index: 1000;
}

.sk-page-header {
	box-shadow: 0px 0px 4px rgba(136, 136, 136, 0.25);
	height: 110px;
	background: var(--sk-white);
	padding: 0 40px;
	display: flex;
	justify-content: space-between;
	margin-left: -1px;
}

.sk-page-header h1 {
	color: var(--sk-dark-navy);
	font-size: 24px;
	font-weight: 700;
	margin: 0;
	max-height: 30px;
	overflow: hidden;
}

.sk-page-header-left {
	display: flex;
	flex-direction: column;
}

.sk-page-header-left > div:first-child {
	display: flex;
	align-items: center;
	margin-top: 26px;
}

.sk-page-header-left >>> .sk-switch {
	height: 20px;
}

.sk-page-header h1 + .sk-switch {
	margin-left: 20px;
}

.sk-page-header-right,
.filter-pills {
	display: flex;
}

.sk-page-header-right >>> .sk-select {
	height: 50px;
	align-self: center;
}

.sk-page-header .button {
	height: 48px;
	align-self: center;
	flex-shrink: 0;
}

.sk-page-header .button + .button {
	margin-left: 10px;
}

.sk-page-header-actions {
	display: flex;
	height: 100%;
	align-items: flex-end;
}

.sk-page-tabs + .filter-button {
	margin-left: 65px;
}

.sk-page-tabs .more-tabs-text {
	display: flex;
	align-items: center;
}

.sk-page-tabs .more-tabs-text .selected {
	color: var;
}

.sk-page-tabs .more-tabs-text i {
	margin-left: 10px;
}

.filter-button {
	border: 0;
	height: 40px;
	color: var(--sk-grey2);
	display: flex;
	background: none;
	margin-right: 15px;
	cursor: pointer;
	align-items: center;
}

.filter-button.active {
	color: var(--sk-navy);
}

.filter-button .sk-icon-sliders-h-regular {
	display: flex;
	margin-right: 15px;
}

.sk-page-header-filters {
	background: var(--sk-white);
	width: 100%;
	display: flex;
	box-shadow: 0px 0px 4px rgba(136, 136, 136, 0.25);
	max-height: 50px;
	align-items: center;
}

.sk-page-filter {
	display: flex;
	align-items: center;
	height: 100%;
	color: var(--sk-grey2);
	padding: 0 20px;
	height: 40px;
	flex-shrink: 0;
	font-size: 14px;
}

.sk-page-filter .sk-icon-calendar-alt-regular,
.sk-page-filter .sk-icon-times-regular {
	margin-left: 15px;
}

.sk-page-filter .selected {
	color: var(--sk-grey3);
}

.sk-page-filter:first-child {
	margin-left: 21px;
}

.sk-page-filter-search {
	width: 308px;
}

.clear-filters,
.date-button,
.close-filters,
.advanced-filter {
	cursor: pointer;
}

.date-button {
	align-items: center;
	display: flex;
}

.date-button .clear-icon {
	margin-right: 5px;
}

.advanced-filter {
	height: 23px;
	font-size: 10px;
	color: var(--sk-grey3) !important;
	align-self: center;
	border-radius: 15px;
}

.pill {
	margin-bottom: 6px;
}

.pill i {
	margin-left: 10px;
}

.sk-page-filter-right {
	display: flex;
	flex-grow: 1;
	justify-content: flex-end;
}

.sk-page-header-breadcrumbs {
	display: flex;
	align-items: center;
	height: 100%;
	margin-left: 35px;
}

.sk-page-header-breadcrumb,
.sk-page-header-breadcrumb > a {
	font-size: 12px;
	color: var(--sk-grey2);
}

.sk-page-header-breadcrumb-divider {
	margin: 0 10px;
}

@media (max-width: 560px) {
	.sk-page-header {
		padding: 0 20px;
	}

	.sk-page-header-left > div:first-child {
		flex-direction: column;
		align-items: flex-start;
		margin-top: 20px;
	}

	.sk-page-header-left {
		flex: 1;
	}

	.sk-page-header-left >>> .sk-switch {
		margin: 0;
		align-self: flex-start;
		margin-top: 5px;
	}

	.sk-page-header h1 {
		white-space: nowrap;
	}

	.sk-page-header h1 + .sk-switch {
		margin-left: 0;
	}

	.sk-page-header .button {
		font-size: 12px;
		min-height: 25px;
		padding: 0 8px;
		margin-left: 10px;
	}

	.sk-page-header .button,
	.sk-page-header-right >>> .sk-select {
		align-self: flex-start;
		margin-top: 20px;
		height: 25px;
	}
}

@media (max-width: 1024px) {
	.sk-page-header-filters {
		height: auto;
		flex-direction: column;
		align-items: flex-start;
		max-height: 100%;
	}

	.sk-page-filter-search {
		margin-left: 6px;
	}

	.sk-page-filter:not(.sk-page-filter-search) {
		margin-left: 20px;
	}
}
</style>

<script>
// ======== Emitted Events ==========
// filterSet: Triggered when a single filter is set
// filtersSet: Triggered when using advanced filters and all filters are set together
// filtersReset: Triggered when a single filter is reset
// allFiltersReset: Triggered when all the filters have been reset
// tabClicked: Triggered when a tab is clicked
// actionClicked: Triggered when an action is clicked
// secondaryActionClicked: Triggered when a secondary action is clicked

import Vue from "vue"
import moment from "moment"
import mobileResponsivenessMixin from "@/mixins/mobile-responsiveness-mixin"
import TypeaheadInput from "@/components/TypeaheadInput.vue"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"
import SkDropdownMultiSelect from "@/components/SkDropdownMultiSelect.vue"
import VueCtkDateTimePicker from "vue-ctk-date-time-picker"
import TransitionExpand from "@/components/transitions/TransitionExpand.vue"
import AdvancedFilteringModal from "@/components/modals/AdvancedFilteringModal.vue"

function formatDate(value, filter) {
	if (value) {
		if (filter.type == "daterange") {
			if (value.start && value.end) {
				return `${value.start} - ${value.end}`
			} else if (value.start) {
				return value.start
			} else if (value.end) {
				return value.end
			}
		} else {
			return value
		}
	}
	return filter.placeholder
}

export default {
	name: "PageHeader",
	mixins: [mobileResponsivenessMixin],
	components: {
		TypeaheadInput,
		SkDropdownSelect,
		SkDropdownMultiSelect,
		VueCtkDateTimePicker,
		TransitionExpand,
		AdvancedFilteringModal
	},
	filters: {
		parseFilterLabels: function (filter) {
			let text = ""
			if (filter.type == "search") {
				const serializer = filter.serializer
					? filter.serializer
					: (i) => {
							return { text: i }
					  }
				text = serializer(filter.value).text
			} else if (filter.type == "date" || filter.type == "daterange") {
				text = formatDate(filter.formatted, filter)
			} else if (filter.type == "checkbox") {
				text = filter.label
			} else if (filter.type == "multiselect") {
				text = filter.formatted
			} else {
				text = filter.value.text ? filter.value.text : filter.value
			}

			if (!text) return ""
			return text.replace(/(<([^>]+)>)/gi, "")
		},
		parseDateValue: function (value, filter) {
			return formatDate(value, filter)
		}
	},
	computed: {
		hasAdvancedFilters() {
			return (
				this.filters.find(
					(filter) => filter.advancedGroupId != undefined
				) != undefined
			)
		},
		basicFilters() {
			return this.filters.filter(
				(filter) => filter.advancedGroupId == undefined
			)
		},
		filterPills() {
			return this.filters
				.filter((filter) => {
					if (
						this.filterValues[filter.id] &&
						this.filterValues[filter.id].value
					) {
						if (filter.type == "multiselect") {
							return this.filterValues[filter.id].value.length > 0
						}
						return true
					}
					return false
				})
				.map((filter) => {
					return Object.assign(
						{},
						filter,
						this.filterValues[filter.id]
					)
				})
		},
		moreTabsText() {
			const activeMoreTab = this.moreTabs.find((tab) => tab.active)
			if (activeMoreTab) {
				return activeMoreTab.text
			}
			return "More"
		},
		moreTabSelected() {
			const activeMoreTab = this.moreTabs.find((tab) => tab.active)
			if (activeMoreTab) {
				return true
			}
			return false
		},
		visibleTabs() {
			if (this.showMoreTabs) {
				return this.tabs.filter((tab) => !tab.overflowTab)
			}
			return this.tabs
		},
		showMoreTabs() {
			return this.tabs && this.tabs.length > 5 && this.moreTabs.length > 0
		},
		moreTabs() {
			if (!this.tabs) {
				return []
			}
			if (!this.isMobile) {
				let moreTabs = this.tabs.filter((tab) => tab.overflowTab)
				if (moreTabs.length > 0) {
					moreTabs.unshift({
						text: "More",
						id: null
					})
					return moreTabs
				}
			} else {
				let tabs = this.tabs.slice()
				tabs.shift()
				tabs.unshift({
					text: "More",
					id: null
				})
				return tabs
			}
			return []
		},
		normalActions() {
			return this.actions.filter(
				(action) => !action.options || action.options.length == 0
			)
		},
		actionsWithOptions() {
			return this.actions.filter(
				(action) => action.options && action.options.length > 0
			)
		}
	},
	data: function () {
		return {
			showingFilters: false,
			filterValues: {},
			mobileThresholdWidth: 560
		}
	},
	props: {
		title: {
			type: String,
			default: null,
			required: true
		},
		actions: {
			type: Array,
			default: null
		},
		secondaryActions: {
			type: Array,
			default: null
		},
		tabs: {
			type: Array,
			default: null
		},
		filters: {
			type: Array,
			default: () => []
		},
		filterGroups: {
			type: Object,
			default: () => {}
		},
		breadcrumbs: Array
	},
	methods: {
		toggleFilters() {
			this.showingFilters = !this.showingFilters
		},
		tabClicked(tab) {
			this.$emit("tabClicked", tab)
		},
		actionClicked(action, option) {
			const data = {
				id: action.id
			}
			if (option) {
				data.optionId = option.id
			}
			this.$emit("actionClicked", data)
		},
		secondaryActionClicked(action) {
			this.$emit("secondaryActionClicked", action)
		},
		filterSet(filter, option, silent) {
			if (filter.type == "date") {
				this.filterValues[filter.id].value = option
					? moment(option).format("YYYY-MM-DD")
					: null

				if (this.filterValues[filter.id].value == null) {
					this.filterValues[filter.id].formatted = null
				}
			} else if (filter.type == "daterange") {
				if (option == null) {
					this.filterValues[filter.id].value = null
					this.filterValues[filter.id].formatted = null
				} else {
					this.filterValues[filter.id].value = {
						from: option.start
							? moment(option.start).format("YYYY-MM-DD")
							: null,
						to: option.end
							? moment(option.end)
									.add(1, "days")
									.format("YYYY-MM-DD")
							: moment(option.start)
									.add(1, "days")
									.format("YYYY-MM-DD")
					}

					if (option.formatted) {
						this.filterValues[filter.id].formatted =
							option.formatted
					}
				}
			} else if (filter.type == "search") {
				this.filterValues[filter.id].value = option
				this.filterValues[filter.id].formatted = option
					? filter.serializer(option).text
					: option
			} else if (filter.type == "multiselect") {
				const pageHeaderFilter = this.filters.find(
					(pageHeaderFilter) => filter.id == pageHeaderFilter.id
				)
				this.filterValues[filter.id].value = option
				if (pageHeaderFilter && option.length > 0) {
					this.filterValues[filter.id].formatted =
						pageHeaderFilter.items
							.filter((item) => option.includes(item.id))
							.map((item) => item.text)
							.join(", ")
				} else {
					this.filterValues[filter.id].formatted = option.join(", ")
				}
			} else {
				this.filterValues[filter.id].value = option
				this.filterValues[filter.id].formatted = option
			}
			if (!silent) {
				this.$emit(
					"filterSet",
					Object.assign({}, this.filterValues[filter.id], filter)
				)
			}
		},
		clearAll() {
			this.filters.forEach((filter) => {
				this.clearFilter(filter)
			})
			if (!this.filters || this.filters.length == 0) {
				this.filterValues = {}
			}
			this.$emit("allFiltersReset")
		},
		clearFilter(filter, propagate) {
			let defaultValue = null
			if (filter.type == "dropdown") {
				const dropdown = this.$refs[`dropdown-${filter.id}`]
				if (dropdown && dropdown[0]) {
					dropdown[0].reset(filter.items, filter.items[0])
				}
				let defaultOption = filter.items.find((item) => item.id == null)
				if (defaultOption) {
					defaultValue = defaultOption
				} else {
					defaultValue =
						filter.items && filter.items.length > 0
							? filter.items[0]
							: null
				}
			} else if (filter.type == "multiselect") {
				const multiselect = this.$refs[`multiselect-${filter.id}`]
				if (multiselect && multiselect[0]) {
					multiselect[0].reset()
				}
				defaultValue = []
			}
			const silent = propagate ? false : true
			this.filterSet(filter, defaultValue, silent)
			this.$emit("filtersReset", filter.id)
		},
		async openAdvancedFilterModal() {
			const filterValues = await this.$refs.advancedFilteringModal.open({
				filterValues: this.filterValues
			})
			const appliedFilters = this.filters.map((filter) =>
				Object.assign({}, filterValues[filter.id], filter)
			)
			this.filterValues = filterValues
			this.$emit("filtersSet", appliedFilters)
			appliedFilters
				.filter((filter) => filter.type == "dropdown")
				.forEach((filter) => {
					const dropdown = this.$refs[`dropdown-${filter.id}`]
					if (dropdown && dropdown[0]) {
						dropdown[0].setOption(filter.value)
					}
				})
		}
	},
	watch: {
		filters: {
			immediate: true,
			handler(newVal) {
				if (newVal && newVal.length > 0) {
					newVal.forEach((filter) => {
						if (!this.filterValues[filter.id]) {
							Vue.set(this.filterValues, filter.id, {
								value: null,
								formatted: null
							})
						}
					})
				}
				this.showingFilters = newVal && newVal.length
			}
		}
	}
}
</script>