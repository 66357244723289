import moment from "moment"
import "moment-timezone"

let menuScheduleMixin = {
  filters: {
    formatSchedule: function (schedule) {
      if (!schedule) return ""
      return `${schedule.openTime} - ${schedule.closeTime}`
    },
    formatTimezone: function(timezone) {
      if (timezone) {
        return moment().tz(timezone).format("z")
      }
      return "N/A"
    }
  },
  props: {
    hours: Array,
    availabilityHours: Array
  },
  data: function() {
    return {
      aggregatedSchedule: () => [],
      availabilitySchedule: null,
      hasChanges: false
    }
  },
  computed: {
    scheduleSummary() {
      return this.aggregatedSchedule.reduce((map, schedule) => {
        schedule.days.forEach(day => {
          map[day] = {}
          map[day].openTime = schedule.openTime ? moment.utc(schedule.openTime, "HH:mm:ss").add(this.offset, "m").format("h:mm A") : ""
          map[day].closeTime = schedule.closeTime ? moment.utc(schedule.closeTime, "HH:mm:ss").add(this.offset, "m").format("h:mm A") : ""
        })
        return map
      }, {})
    },
    availabilityScheduleSummary() {
      if (this.availabilitySchedule) {
        const aggregatedSchedule = {}
        for (let availabilityDay in this.availabilitySchedule) {
          let openTime = this.availabilitySchedule[availabilityDay].openTime
          let closeTime = this.availabilitySchedule[availabilityDay].closeTime
          aggregatedSchedule[availabilityDay] = {
            openTime: moment.utc(openTime, "HH:mm:ss").add(this.offset, "m").format("h:mm A"),
            closeTime: moment.utc(closeTime, "HH:mm:ss").add(this.offset, "m").format("h:mm A")
          }
        }
        return aggregatedSchedule
      }
      return {}
    }
  },
  methods: {
    addHours() {
      const length = Object.keys(this.aggregatedSchedule).length
      const lastIndex = length > 0 ? length : 0
      const scheduleId = length > 0 ? this.aggregatedSchedule[lastIndex - 1].id + 1 : 0
      const reservedDays = []
      this.aggregatedSchedule
        .flatMap(schedule => schedule.reservedDays)
        .map(dow => {
          if (!reservedDays.includes(dow)) {
            reservedDays.push(dow)
          }
        })

      this.aggregatedSchedule.push({
        days: [],
        openTime: null,
        closeTime: null,
        reservedDays: reservedDays,
        key: `00:00:00-new-${lastIndex +  1}`,
        id: scheduleId
      })
    },
    removeHours(schedule) {
      const reservedDays = []
			this.aggregatedSchedule
        .flatMap(schedule => schedule.reservedDays)
        .map(dow => {
          if (!reservedDays.includes(dow) && !schedule.days.includes(dow)) {
            reservedDays.push(dow)
          }
        })
        this.aggregatedSchedule.forEach(schedule => {
          schedule.reservedDays = reservedDays
        })
			const index = this.aggregatedSchedule.findIndex(aggSchedule => aggSchedule.id == schedule.id)
			if (index > -1) {
				this.aggregatedSchedule.splice(index, 1)
			}
    },
    selectDay(payload) {
      this.aggregatedSchedule.forEach(schedule => {
        if (schedule.key == payload.key) {
          schedule.days.push(payload.dow)
        }
        schedule.reservedDays.push(payload.dow)
      })
      this.hasChanges = true
    },
    unselectDay(payload) {
      this.aggregatedSchedule.forEach(schedule => {
        if (schedule.key == payload.key) {
          schedule.days = schedule.days.filter(day => day != payload.dow)
        }
        schedule.reservedDays = schedule.reservedDays.filter(dow =>  dow != payload.dow)
      })
      this.hasChanges = true
    },
    updateTime(payload) {
      let schedule = this.aggregatedSchedule.find(schedule => schedule.key == payload.key)

      if (schedule) {
        schedule.openTime = payload.time.openTime,
        schedule.closeTime = payload.time.closeTime
      }
      this.hasChanges = true
    },
    initAggregatedSchedule(openingHours) {
      this.hasChanges = false
      const schedules = {}
      const reservedDays = []
      openingHours.slice()
        .sort((a, b) =>  b.day_of_week - a.day_of_week)
        .forEach((schedule, i) => {
          const key = `${schedule.open_time}-${schedule.close_time}`
          if (!schedules[key]) {
            schedules[key] = {
              days: [],
              openTime: schedule.open_time,
              closeTime: schedule.close_time,
              reservedDays: reservedDays,
              key: key,
              id: i
            }
          }
          schedules[key].days.push(schedule.day_of_week)
          reservedDays.push(schedule.day_of_week)
        })
      
      this.aggregatedSchedule = Object.values(schedules).sort((a, b) => a.id - b.id)
    },
    initAvailabilitySchedule(availabilityHours) {
      if (!availabilityHours || availabilityHours.length == 0) {
        this.availabilitySchedule = null
      } else {
        const schedules = {}
        availabilityHours.slice()
          .sort((a, b) =>  b.day_of_week - a.day_of_week)
          .forEach((schedule) => {
            const key = `${schedule.open_time}-${schedule.close_time}`
            if (!schedules[key]) {
              schedules[schedule.day_of_week] = {
                openTime: schedule.open_time,
                closeTime: schedule.close_time,
                dow: schedule.day_of_week
              }
            }
          })
        
        this.availabilitySchedule = schedules
      }
    },
    normalizedOpeningHours() {
      const openingHours = []
      this.aggregatedSchedule.slice()
        .sort((a, b) => b.id - a.id)
        .forEach(schedule => {
          schedule.days.forEach(day => {
            openingHours.push({
              open_time: schedule.openTime,
              close_time: schedule.closeTime,
              day_of_week: day
            })
          })
        })
      return openingHours
    }
  },
  watch: {
    hours: {
      immediate: true,
      deep: true,
      handler(hours) {
        const openingHours = hours ? hours : []
        this.initAggregatedSchedule(openingHours)
      }
    },
    availabilityHours: {
      immediate: true,
      deep: true,
      handler(hours) {
        this.initAvailabilitySchedule(hours)
      }
    }
  }
}

export default menuScheduleMixin