<template>
	<div class="sk-table sk-table-default sk-table-tabbed">
		<div class="sk-table-header">
			<div class="sk-table-row">
				<div>Quantity</div>
				<div>Item</div>
				<div v-if="showCogs">COGS</div>
				<div v-if="showFullPrice">Price</div>
			</div>
		</div>
		<div
			class="sk-table-row sk-widget"
			v-for="(product, index) in item.products"
			:key="`${product.id}-${index}`"
		>
			<div>
				{{ product.quantity }}
			</div>
			<div>
				{{ product.name }}
				<div
					class="order-product-meta"
					v-for="meta in productMetas[`${product.id}_${index}`]"
					:key="`${product.id}-${meta.meta_id}-${meta.meta_val_id}`"
				>
					{{ meta.label }}:
					<b>{{ meta.value }}</b>
				</div>
				<div
					v-if="product.comments.length > 0"
					class="order-product-meta"
				>
					Comments:
					<b>{{ product.comments }}</b>
					<br />
				</div>
			</div>
			<div v-if="showCogs">
				{{ productPrices[`${product.id}_${index}`].cogs | currency }}
			</div>
			<div v-if="showFullPrice">
				{{ productPrices[`${product.id}_${index}`].price | currency }}
			</div>
		</div>
		<div class="sk-table-row">
			<div v-if="showFullPrice">Taxes</div>
			<div v-else-if="showCogs">Taxes & Fees</div>
			<div></div>
			<div v-if="showCogs">
				{{ cogsTax | currency }}
			</div>
			<div v-if="showFullPrice">
				{{ tax | currency }}
			</div>
		</div>
		<div class="sk-table-row" v-if="showTip">
			<div>Tip</div>
			<div v-if="showCogs"></div>
			<div v-if="showFullPrice"></div>
			<div>
				{{ tip | currency }}
			</div>
		</div>
		<slot v-if="showCoupons">
			<div
				class="sk-table-row"
				v-for="coupon in order.coupons"
				:key="coupon.code"
			>
				<div>Coupon ({{ coupon.code }})</div>
				<div></div>
				<div v-if="showCogs"></div>
				<div v-if="showFullPrice">- {{ coupon.amount | currency }}</div>
			</div>
		</slot>
		<div class="sk-table-row suborder-total-row">
			<div>Total</div>
			<div></div>
			<div v-if="showCogsWithTip">
				{{ totalCogsWithTip | currency }}
			</div>
			<div v-else-if="showCogs">
				{{ totalCogsWithTax | currency }}
			</div>
			<div v-if="showFullPrice">
				{{ order.price | currency }}
			</div>
		</div>
	</div>
</template>

<style scoped>
.sk-table-default > .sk-table-row > * {
	font-size: 12px;
	color: var(--sk-grey3);
	padding-right: 25px !important;
}

.suborder-total-row > div {
	color: var(--sk-grey3);
	font-weight: 600;
}
</style>

<script>
import { PermissionInfo } from "@/utils/permissions"
import currencyFilterMixin from "@/mixins/currency-filter-mixin"
import orderProductPricing from "@/mixins/order-product-pricing"
import { CourierId } from "@arikgaisler/utils/enums/courierId"

export default {
	name: "OrderProducts",
	mixins: [currencyFilterMixin, orderProductPricing],
	props: {
		business: Object,
		item: Object,
		order: Object,
		type: {
			type: Number,
			default: 0
		}
	},
	computed: {
		showCoupons() {
			return this.order.coupons != undefined
		},
		showCogs() {
			return this.$store.state.permissions.includes(
				PermissionInfo.ORDER_COGS
			)
		},
		showFullPrice() {
			return this.$store.state.permissions.includes(
				PermissionInfo.ORDER_FULL_PRICE
			)
		},
		showCogsWithTip() {
			return (
				this.showCogs &&
				(this.item.courier_id == CourierId.WALKING ||
					this.item.courier_id == CourierId.TOOKAN)
			)
		},
		showTip() {
			return (
				this.showFullPrice ||
				this.item.courier_id == CourierId.WALKING ||
				this.item.courier_id == CourierId.TOOKAN
			)
		},
		products() {
			return this.item.products
		}
	}
}
</script>