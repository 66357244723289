
import actions from "./actions"
import mutations from "./mutations"

const state = {
    loading: false,
    businessGuests: [],
    currentBusinessGuest: null,
    totalBusinessGuestsCount: 0
}

export default{
    namespaced: true, 
    state,
    mutations,
    actions
};