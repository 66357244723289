<template>
	<div class="sk-slider-scale" :class="{ inverse: invert }">
		<div class="sk-slider-scale-track" ref="sliderTrack"></div>
		<div class="sk-slider-scale-knob" :style="sliderKnobStyle"></div>
	</div>
</template>

<style scoped>
.sk-slider-scale {
	position: relative;
	height: 15px;
	display: flex;
	align-items: center;
}

.sk-slider-scale-track,
.sk-slider-scale-knob {
	background-image: linear-gradient(
		89.99deg,
		#e80622 0%,
		#fcbf49 50.52%,
		#3ce800 100%
	);
}

.inverse .sk-slider-scale-track,
.inverse .sk-slider-scale-knob {
	background-image: linear-gradient(
		89.99deg,
		#3ce800 0%,
		#fcbf49 50.52%,
		#e80622 100%
	);
}

.sk-slider-scale-track {
	width: 100%;
	height: 5px;
	border-radius: 50px;
	z-index: 1;
}

.sk-slider-scale-knob {
	transition: left 0.35s ease-in-out, background-position 0.35s ease-in-out;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
	position: absolute;
	z-index: 2;
	height: 15px;
	width: 15px;
	border-radius: 50%;
	top: 0;
	left: 0;
}
</style>

<script>
export default {
	name: "SkSliderScale",
	props: {
		value: {
			type: [Number, String],
			default: 0
		},
		invert: {
			type: Boolean,
			default: false
		}
	},
	data: function () {
		return {
			isMounted: false
		}
	},
	computed: {
		sliderKnobStyle() {
			if (!this.isMounted) {
				return
			}
			let percentage = this.value
			if (this.value > 100) {
				percentage = 100
			}
			let width = this.$refs.sliderTrack
				? this.$refs.sliderTrack.offsetWidth
				: 0

			return {
				left: `calc(${percentage}% - 7.5px)`,
				backgroundSize: `${width}px`,
				backgroundPosition: `${percentage}%`
			}
		}
	},
	mounted: function () {
		this.isMounted = true
	}
}
</script>