let timePickerMixin = {
  filters: {
    formatMinutes: function (value) {
      if (!value) return "00"
      return value
    }
  },
  data: function() {
    return {
      selectedHour: 1,
      selectedMinutes: 0,
      selectedTimePeriod: "AM",
      hours: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      minutes: [0, 15, 30, 45],
      timePeriods: ["AM", "PM"]
    }
  },
  props: {
    value: String,
    disabledHours: Array
  },
  methods: {
    selectHour(hour) {
      this.selectedHour = hour
      this.updateTime()
    },
    selectMinutes(minutes) {
      this.selectedMinutes = minutes
      this.updateTime()
    },
    selectTimePeriod(timePeriod) {
      this.selectedTimePeriod = timePeriod
      this.updateTime()
    },
    updateTime() {
      let hour = Number(this.selectedHour)
      let minutes = Number(this.selectedMinutes)
      let time = `${hour < 10 ? "0" + hour : hour}:${minutes == 0 ? "00" : minutes} ${this.selectedTimePeriod}`
      this.$emit("input", time)
    },
    updateSelectedTime(value) {
      if (value) {
        const data = value.split(/[:\s]/)
        this.selectedHour = data[0]
        this.selectedMinutes = data[1]
        this.selectedTimePeriod = data[2]
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: function(newVal) {
        this.updateSelectedTime(newVal)
      }
    }
  }
}

export default timePickerMixin