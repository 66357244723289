<template>
	<div>
		<div class="calendar-monthly-header">
			<button class="button button-icon" @click="previousMonth()">
				<i class="sk-icon-chevron-left-regular"></i>
			</button>
			<div class="calendar-monthly-header-title">
				{{ monthName }} - {{ currentYear }}
			</div>
			<button class="button button-icon" @click="nextMonth()">
				<i class="sk-icon-chevron-right-regular"></i>
			</button>
		</div>
		<div class="sk-grid">
			<div class="weekday-column">Sunday</div>
			<div class="weekday-column">Monday</div>
			<div class="weekday-column">Tuesday</div>
			<div class="weekday-column">Wednesday</div>
			<div class="weekday-column">Thursday</div>
			<div class="weekday-column">Friday</div>
			<div class="weekday-column">Saturday</div>
			<div v-for="day in emptyDays" :key="`empty-${day}`"></div>
            <slot name="calendar-days"></slot>
		</div>
	</div>
</template>

<style scoped>
.calendar-monthly-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 60px 40px 0 40px;
}

.calendar-monthly-header-title {
	font-size: 20px;
	font-weight: 600;
	color: var(--sk-navy);
}

.sk-grid {
	min-width: 1000px;
	max-width: 1200px;
	grid-template-columns: repeat(7, 1fr);
	margin: 40px auto;
	row-gap: 0;
}

.sk-grid > div {
	height: 105px;
	display: flex;
}

.weekday-column {
	color: var(--sk-grey2);
	font-size: 14px;
	justify-content: center;
}
</style>

<script>
import moment from "moment"

export default {
	name: "SkCalendar",
	data: function () {
		return {
			currentMonth: 0,
			currentYear: 0
		}
	},
	computed: {
		monthName() {
			return moment()
				.month(this.currentMonth - 1)
				.format("MMMM")
		},
		emptyDays() {
			const startingDay = moment(
				`${this.currentYear}-${this.currentMonth}`,
				"YYYY-MM"
			)
				.startOf("month")
				.day()
			return startingDay == 7 ? 0 : startingDay
		}
	},
	methods: {
		previousMonth() {
			this.changeMonth(-1)
		},
		nextMonth() {
			this.changeMonth(1)
		},
		changeMonth(difference) {
			if (difference > 0) {
				if (this.currentMonth >= 12) {
					this.currentMonth = 1
					this.currentYear = this.currentYear + 1
				} else {
					this.currentMonth = this.currentMonth + difference
				}
			} else if (difference < 0) {
				if (this.currentMonth <= 1) {
					this.currentMonth = 12
					this.currentYear = this.currentYear - 1
				} else {
					this.currentMonth = this.currentMonth + difference
				}
			}
			this.emitDateRange()
		},
		emitDateRange() {
			this.$emit("dateChanged", {
                month: this.currentMonth,
                year: this.currentYear
            })
		}
	},
	beforeMount: function () {
		this.currentMonth = moment().month() + 1
		this.currentYear = moment().year()
	},
	mounted: function () {
		this.emitDateRange()
	}
}
</script>