<template>
	<div class="sk-table-row">
		<div>
			<div class="sk-table-pill sk-table-column-fit">
				<router-link
					:key="business.id"
					:to="`/business/${business.slug}`"
					tag="a"
					class="pill pill-grey"
					:title="business.name"
				>
					<i :class="businessIcon"></i>
					{{ business.name }}
				</router-link>
			</div>
		</div>
		<div @click="togglePopover('isMenusPopoverOpen')">
			{{ menusCount }}
			<i v-if="menusCount > 0" class="sk-icon-chevron-down-regular"></i>
			<SkPopover v-if="menusCount > 0" v-model="isMenusPopoverOpen">
				<div v-for="menu in menus" :key="menu.id">
					{{ menu.name }}
					<span class="business-menu-preference">
						({{ menu.preference }})
					</span>
				</div>
			</SkPopover>
		</div>
		<div
			class="menu-schedules"
			@click="togglePopover('isHoursPopoverOpen')"
		>
			<div class="menu-today" v-if="todaysSchedule">
				<div>
					<div class="sk-muted-text">
						{{ todaysSchedule.daysText }}
					</div>
					<div>{{ todaysSchedule.timesText }}</div>
				</div>
				<i
					class="expand-schedule-icon sk-icon-chevron-down-regular"
					:class="{ flipped: isHoursPopoverOpen }"
				></i>
			</div>
			<SkPopover v-model="isHoursPopoverOpen">
				<div
					v-for="(schedule, i) in schedules"
					:key="i"
					class="menu-schedule"
				>
					<div class="sk-muted-text">
						{{ schedule.daysText }}
					</div>
					<div>
						{{ schedule.timesText }}
					</div>
				</div>
			</SkPopover>
		</div>
		<div>
			<div :title="statusLabel" :class="statusBadgeClass">
				<span :class="statusClasses"></span>
				{{ statusLabel }}
			</div>
		</div>
		<div class="sk-table-column-fit">
			<div class="sk-table-actions">
				<button
					class="button button-icon"
					title="Edit menus"
					@click="editMenus"
				>
					<i class="sk-icon-pencil-solid"></i>
				</button>
				<router-link
					:key="business.id"
					:to="`/business/${business.slug}`"
					tag="button"
					class="button button-icon"
					title="View"
				>
					<i class="sk-icon-eye-solid"></i>
				</router-link>
			</div>
		</div>
	</div>
</template>

<style scoped>
.sk-table-row .sk-icon-chevron-down-regular {
	font-size: 10px;
	margin-left: 10px;
}

.sk-table-pill > .pill.pill-grey {
	max-width: 400px;
}

.business-menu-preference {
	font-size: 12px;
}

.menu-today {
	display: flex;
	align-items: center;
}
</style>

<script>
import { BusinessType } from "@arikgaisler/utils/enums/businessType"
import momentHelper from "@/utils/moment-helper"
import moment from "moment"
import "moment-timezone"
import SkPopover from "@/components/SkPopover.vue"

export default {
	name: "HubBusinessLineItem",
	components: {
		SkPopover
	},
	props: {
		business: Object
	},
	data: function () {
		return {
			cachedSchedules: {},
			businessSchedule: [],
			isHoursPopoverOpen: false,
			isMenusPopoverOpen: false
		}
	},
	computed: {
		statusBadgeClass() {
			return this.business.is_active
				? "pill-alt pill-alt-green"
				: "pill-alt pill-alt-grey"
		},
		statusLabel() {
			return this.business.is_active ? "Active" : "Inactive"
		},
		statusClasses() {
			return this.business.is_active
				? "status-dot green"
				: "status-dot grey"
		},
		schedules() {
			return this.businessSchedule.map((schedule) => {
				if (schedule.times && !schedule.timesText) {
					let timesText = schedule.times
						.map((time) => {
							let pieces = time.split("-")
							let openTime = moment
								.utc(pieces[0], "HH:mm:ss")
								.format("h:mm A")
							let closeTime = moment
								.utc(pieces[1], "HH:mm:ss")
								.format("h:mm A")
							return `${openTime} - ${closeTime}`
						})
						.join(", ")
					return {
						dows: schedule.dows,
						daysText: schedule.daysText,
						timesText: timesText
					}
				}
				return schedule
			})
		},
		todaysSchedule() {
			const date = moment()
			let dow = date.day()
			const normalizedDow = dow == 0 ? 6 : dow - 1
			const todaysSchedule = this.schedules.find((schedule) =>
				schedule.dows.includes(Number(normalizedDow))
			)
			if (todaysSchedule) {
				return {
					daysText: momentHelper.dowNames[normalizedDow],
					timesText: todaysSchedule.timesText
				}
			}
			return null
		},
		menusCount() {
			return this.menus.length
		},
		menus() {
			const currentHub = this.$store.state.hubsModule.currentHub
			const offset =
				this.business && this.business.timezone_offset
					? this.business.timezone_offset
					: 0
			if (
				this.business.menus &&
				this.business.menus.length > 0 &&
				currentHub &&
				currentHub.restaurant_menu_ids
			) {
				return this.business.menus
					.filter((menu) =>
						currentHub.restaurant_menu_ids.includes(menu.id)
					)
					.map((menu) => {
						return {
							id: menu.id,
							name: menu.name,
							preference: menu.is_backup ? "Backup" : "Primary",
							opening_hours: menu.opening_hours,
							offset: offset
						}
					})
			}
			return []
		},
		businessIcon() {
			switch (this.business.type) {
				case BusinessType.BAR:
					return "sk-icon-beer-solid"
				case BusinessType.HOTEL:
					return "sk-icon-bed-empty-solid"
				case BusinessType.RESIDENTIAL:
					return "sk-icon-couch-solid"
				case BusinessType.COWORKING:
					return "sk-icon-briefcase-solid"
				case BusinessType.AIRPORT:
					return "sk-icon-plane-engines-solid-1"
			}
		}
	},
	methods: {
		togglePopover(name) {
			this.isHoursPopoverOpen =
				name == "isHoursPopoverOpen" ? !this.isHoursPopoverOpen : false
			this.isMenusPopoverOpen =
				name == "isMenusPopoverOpen" ? !this.isMenusPopoverOpen : false
		},
		editMenus() {
			this.$emit("editMenus", this.business.id)
		}
	},
	watch: {
		menus: {
			deep: true,
			immediate: true,
			handler() {
				//Maximum 24 hours in a day, 15m increments
				const maxHourSlots = 24 * (60 / 15)
				const days = {
					0: [],
					1: [],
					2: [],
					3: [],
					4: [],
					5: [],
					6: []
				}

				this.menus.forEach((menu) => {
					const hours = menu.opening_hours ? menu.opening_hours : []
					hours.forEach((hour) => {
						const dow = hour.day_of_week
						const startMoment = moment
							.utc(hour.open_time, "HH:mm:ss")
							.add(menu.offset, "m")
						const startTime = startMoment.format("HH:mm:ss")
						const closeTime = moment
							.utc(hour.close_time, "HH:mm:ss")
							.add(menu.offset, "m")
							.format("HH:mm:ss")
						const cacheKey = `${startTime}-${closeTime}`
						if (this.cachedSchedules[cacheKey]) {
							let menuSchedule = this.cachedSchedules[cacheKey]
							menuSchedule.forEach((time) => {
								if (!days[dow].includes(time)) {
									days[dow].push(time)
								}
							})
						} else {
							let menuSchedule = []
							let i = 0
							let reachedCloseTime = false
							while (!reachedCloseTime) {
								let time = startMoment.format("HH:mm:ss")
								menuSchedule.push(time)
								if (time == "00:00:00") {
									menuSchedule.push("24:00:00")
								}
								if (!days[dow].includes(time)) {
									days[dow].push(time)
									if (time == "00:00:00") {
										days[dow].push("24:00:00")
									}
								}
								startMoment.add(15, "m")
								reachedCloseTime =
									time == closeTime || i >= maxHourSlots
								i++
							}
							this.cachedSchedules[cacheKey] = menuSchedule
						}
					})
				})

				let schedules = []
				let scheduleGroups = {}
				const openAllDay = []
				const closedAllDay = []

				for (var i = 0; i < 7; i++) {
					const schedule = days[i]
					if (schedule.length == 0) {
						closedAllDay.push(i)
						continue
					}
					if (schedule.length > maxHourSlots) {
						openAllDay.push(i)
						continue
					}

					let dailyKey = ""
					let dailySchedule = []
					let currentScheduleIndex = 0

					schedule.sort((timeA, timeB) => {
						let numericalTimeA = parseFloat(timeA.replace(":", ""))
						let numericalTimeB = parseFloat(timeB.replace(":", ""))
						return numericalTimeA - numericalTimeB
					})
					schedule.forEach((time, index) => {
						let newSchedule = false
						if (!dailySchedule[currentScheduleIndex]) {
							dailySchedule.push({
								open_time: time,
								close_time: null
							})
							return
						}
						let nextSlot = schedule[index + 1]
						if (!nextSlot) {
							newSchedule = true
						}

						if (!newSchedule) {
							const nextTime = moment
								.utc(time, "HH:mm:ss")
								.add(15, "m")
								.format("HH:mm:ss")

							if (
								nextTime != nextSlot &&
								nextSlot != "24:00:00" &&
								nextTime != "00:00:00"
							) {
								newSchedule = true
							}
						}

						if (newSchedule) {
							dailySchedule[currentScheduleIndex].close_time =
								time
							let key = `${dailySchedule[currentScheduleIndex].open_time}-${time}`
							dailyKey += dailyKey.length > 0 ? `_${key}` : key
							currentScheduleIndex++
						}
					})
					if (!scheduleGroups[dailyKey]) {
						scheduleGroups[dailyKey] = []
					}
					scheduleGroups[dailyKey].push(i)
				}

				if (openAllDay.length > 0) {
					const daysText = openAllDay
						.map((dow) => momentHelper.dowNames[dow])
						.join(", ")
					schedules.push({
						dows: openAllDay,
						daysText: daysText,
						timesText: "Open All Day"
					})
				}

				if (closedAllDay.length > 0) {
					const daysText = closedAllDay
						.map((dow) => momentHelper.dowNames[dow])
						.join(", ")
					schedules.push({
						dows: closedAllDay,
						daysText: daysText,
						timesText: "Closed"
					})
				}

				for (let group in scheduleGroups) {
					let times = group.split("_")
					let dows = scheduleGroups[group]
					schedules.push({
						dows: dows,
						daysText: dows
							.map((dow) => momentHelper.dowNames[dow])
							.join(", "),
						timesText: null,
						times: times
					})
				}

				this.businessSchedule = schedules
			}
		}
	}
}
</script>