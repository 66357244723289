<template>
	<SkBaseModal
		:isOpen="isOpen"
		:showCloseButton="false"
		:hasLoader="true"
		@close="close"
	>
		<loading :active.sync="loading" :is-full-page="false"></loading>
		<div class="sk-modal-alt-title">
			<h1>Location</h1>
		</div>
		<div v-for="(location, i) in locationSearches" :key="i">
			<div class="sk-row">
				<div
					class="sk-widget-alt"
					:class="{
						'entity-selected':
							location.type == entityTypes.BUSINESS,
						disabled: !canAddLocations
					}"
				>
					<div class="sk-radio-group">
						<sk-radio
							:option="entityTypes.BUSINESS"
							v-model="location.type"
						/>
						<span>Business</span>
					</div>
				</div>
				<div
					class="sk-widget-alt"
					:class="{
						'entity-selected':
							location.type == entityTypes.RESTAURANT,
						disabled: !canAddLocations
					}"
				>
					<div class="sk-radio-group">
						<sk-radio
							:option="entityTypes.RESTAURANT"
							v-model="location.type"
						/>
						<span>Restaurant</span>
					</div>
				</div>
			</div>
			<div class="sk-row">
				<AddressTypeaheadInput
					v-model="location.address"
					ref="addressInput"
					:placesFields="['address_components', 'geometry.location']"
				/>
			</div>
			<!-- <div class="sk-row" v-if="canAddLocations">
				<button
					class="button button-text sk-widget-section-text-button"
					@click="addLocation"
				>
					<i class="sk-icon-plus-regular"></i>
					Add Another Address
				</button>
			</div>
			<div class="sk-row" v-else>
				<button
					class="button button-text sk-widget-section-text-button"
					@click="removeLocation(i)"
				>
					<i class="sk-icon-plus-regular"></i>
					Remove Address
				</button>
			</div> -->
		</div>
		<div class="sk-modal-actions">
			<button @click="clearLocation" class="button button-text">
				Clear All
			</button>
			<button class="button button-primary" @click="searchLocation">
				Search
			</button>
		</div>
	</SkBaseModal>
</template>

<style scoped>
.sk-widget-alt >>> .sk-radio {
	width: 20px;
	height: 20px;
	margin-right: 15px;
}

.sk-radio-group > span {
	font-size: 14px;
	font-weight: 600;
	color: var(--sk-dark-navy);
}

.sk-row {
	width: 100%;
	margin-left: 0;
}

.entity-selected {
	border-color: var(--sk-dark-navy);
}

.disabled {
	pointer-events: none;
}

.sk-modal-actions > .button {
	min-width: 141px;
}
</style>

<script>
import { EntityType } from "@/enums/entityType"
import { EventBusEvents } from "@/enums/eventBusEvents"
import { eventBus } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import ActionTypes from "@/store/modules/sales/action-types"
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import AddressTypeaheadInput from "@/components/AddressTypeaheadInput.vue"
import SkRadio from "@/components/SkRadio.vue"
import Loading from "vue-loading-overlay"

export default {
	name: "LocationSearchModal",
	mixins: [skModalMixin],
	components: {
		SkBaseModal,
		AddressTypeaheadInput,
		SkRadio,
		Loading
	},
	data: function () {
		return {
			entityTypes: EntityType,
			locationSearches: [
				{
					type: EntityType.BUSINESS,
					address: null
				}
			],
			loading: false
		}
	},
	computed: {
		canAddLocations() {
			return this.locationSearches.length < 2
		},
		availableEntityTypes() {
			const entityTypes = [EntityType.RESTAURANT, EntityType.BUSINESS]
			this.locationSearches.forEach((location) => {
				const index = entityTypes.indexOf(location.type)
				if (index >= 0) {
					entityTypes.splice(index, 1)
				}
			})
			return entityTypes
		}
	},
	methods: {
		handleOptions() {
			const locations = this.$store.state.salesModule.searchedLocations
				? this.$store.state.salesModule.searchedLocations
				: []
			this.loading = false
			if (this.$refs.addressInput) {
				this.$refs.addressInput.forEach(input => {
					input.resetQuery()
				})
			}
			if (locations.length > 0) {
				this.locationSearches = locations.map((location) => {
					return {
						type: location.type,
						address: location.address
					}
				})
			} else {
				this.locationSearches = [
					{
						type: EntityType.BUSINESS,
						address: null
					}
				]
			}
		},
		addLocation() {
			if (
				!this.availableEntityTypes ||
				this.availableEntityTypes.length == 0
			) {
				return
			}
			this.locationSearches.push({
				type: this.availableEntityTypes[0],
				address: null
			})
		},
		removeLocation(index) {
			this.locationSearches.splice(index, 1)
		},
		searchLocation() {
			const locations = this.locationSearches.filter(
				(location) => location.address != null
			)

			if (locations.length == 0) {
				this.clearLocation()
				return
			}
			this.loading = true
			this.$store
				.dispatch(`salesModule/${ActionTypes.GET_NEAREST_LOCATIONS}`, {
					locations: locations
				})
				.then((success) => {
					if (success) {
						this.close()
					} else {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Location Search",
							message: "Something went wrong, please try again",
							type: NotificationType.WARNING
						})
					}
				})
				.finally(() => {
					this.loading = false
				})
		},
		clearLocation() {
			this.$store.dispatch(`salesModule/${ActionTypes.CLEAR_LOCATIONS}`)
			this.close()
		}
	}
}
</script>