import ActionTypes from "@/store/modules/coupons/action-types"
import { ConfirmationModalType } from "@/enums/confirmationModalType"

let couponStatusMixin = {
    methods: {
        deleteCoupon: async function (coupon) {
            try {
                var title = `Delete ${coupon.code} ?`
                var body = "Are you sure you want to delete this coupon?"
                var cancel = "Keep Coupon"
                var confirmation = "Delete Coupon"
                var confirmationIcon = "sk-icon-times-regular"
                var confirmationType = ConfirmationModalType.RED
                const confirmed = await this.$refs.confirmationModal.open({
                    title: title,
                    body: body,
                    cancel: cancel,
                    confirmation: confirmation,
                    confirmationIcon: confirmationIcon,
                    type: confirmationType
                })
                if (confirmed) {
                    this.$store.dispatch(`couponsModule/${ActionTypes.DELETE_COUPON}`, coupon.id)
                }
                return confirmed
            } catch (e) {
                throw e
            }
        }
    }
}

export default couponStatusMixin
