<template>
  <span class="pill" 
    :class="pillClass"
    v-sk-tooltip="posBattery">
    <i class="sk-icon-heart-rate-regular"></i> {{pillText}}
  </span>
</template>

<script>
import { RestaurantPOSDevice } from "@/enums/restaurantPOSDevice"
export default {
  name: "RestaurantPOSLineItem",
  props: {
    device: Object
  },
	computed: {
    pillClass() {
      return this.device.is_healthy && this.device.is_active ? "pill-green" : "pill-red"
    },
    pillText() {
			switch (this.device.pos_system_id) {
        case RestaurantPOSDevice.TABLET:
          return "Tablet"
        case RestaurantPOSDevice.CHOWLY:
          return "Chowly"
      }
      return ""
    },
    posBattery() {
      const level = this.device.battery_status != null ? `${this.device.battery_status}%` : "N/A"
			return `<span class="kiosk-battery"><i class="sk-icon-battery-quarter-regular"></i> Battery: ${level}</span>`
		}
  }
}
</script>