<template>
	<TypeaheadInput
		:placeholder="placeholder"
		:fetch="autocompleteAddress"
		:serializer="addressSerializer"
		:query="addressQuery"
		:invalid="invalid"
		@selected="addressSelected"
		@blur="resetQuery"
	/>
</template>

<style scoped>
</style>

<script>
import gapi from "@/utils/gapi"
import TypeaheadInput from "@/components/TypeaheadInput.vue"

export default {
	name: "AddressTypeaheadInput",
	components: {
		TypeaheadInput
	},
	props: {
		value: null,
		placeholder: {
			type: String,
			default: "Address, City, State Zipcode"
		},
		invalid: {
			type: Boolean,
			default: false
		},
		placesFields: Array
	},
	data: function () {
		return {
			addressQuery: ""
		}
	},
	computed: {
		model: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit("input", val)
			}
		}
	},
	methods: {
		autocompleteAddress(query) {
			return gapi.autocompleteLocation(query, ["address"])
		},
		addressSelected(location) {
			if (location && location.id) {
				gapi.getPlaceDetails(location.id, this.placesFields).then((result) => {
					const addressComponents = result ? result.address_components : null
					const data = gapi.parseAddressComponents(addressComponents)
					data.placeId = location.id
					data.details = result
					this.model = data
				})
			} else {
				this.model = null
			}
		},
		addressSerializer(result) {
			return {
				text: result.name.replace(", USA", "")
			}
		},
		resetQuery() {
			if ((this.addressQuery == "" || !this.addressQuery) && this.model && this.model.placeId != null) {
				this.model = null
			}
		}
	},
	watch: {
		model: {
			immediate: true,
			handler(newVal) {
				if (
					newVal &&
					newVal.address &&
					newVal.city &&
					newVal.state &&
					newVal.zip
				) {
					this.addressQuery = `${newVal.address}, ${newVal.city}, ${newVal.state} ${newVal.zip}`
				} else {
					this.addressQuery = ""
				}
			}
		}
	}
}
</script>