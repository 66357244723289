import Vue from 'vue'
import { store } from "@/store/store"
import { parseResponse } from "@arikgaisler/utils/api-utils"

let host = process.env.VUE_APP_API_HOST

const USER_API = `https://user${host}.2ndkitchen.com`

export default {
    async resendActivationToken(payload) {
        return parseResponse(Vue.axios.post(`${USER_API}/admin_users/refresh_activation_token`,payload));
    },
    async verifyActivationToken(payload) {
        return parseResponse(Vue.axios.post(`${USER_API}/auth/admin/verify_activation_token`, payload))
    },
    async addUser(payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.post(`${USER_API}/admin_users`,payload, { 'headers': { 'Authorization': tokenStr } }));
    },
    async updateUser(user_id, payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.put(`${USER_API}/admin_users/${user_id}`,payload, { 'headers': { 'Authorization': tokenStr } }));
    },
    async deleteUser(user_id) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.delete(`${USER_API}/admin_users/${user_id}`, { 'headers': { 'Authorization': tokenStr } }));
    },
    async sendForgotPassword(payload) {
        return parseResponse(Vue.axios.post(`${USER_API}/admin_users/request_password_reset`, payload))
    },
    async getUser(user_id) {
        return parseResponse(Vue.axios.get(`${USER_API}/admin_users/${user_id}`))
    },
    async getUserByEmail(email) {
        return parseResponse(Vue.axios.get(`${USER_API}/users/${email}/email`))
    },
    async getUsers() {
        const tokenStr = 'Bearer ' + store.state.user_token
        return Vue.axios.get(`${USER_API}/admin_users`, { 'headers': { 'Authorization': tokenStr } });
    },
    async getBusinessGuest(business_guest_id) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.get(`${USER_API}/business_guests/${business_guest_id}`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async searchBusinessGuests(payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.post(`${USER_API}/business_guests/search`,payload, { 'headers': { 'Authorization': tokenStr } }));
    },
    async addBusinessGuest(payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.post(`${USER_API}/business_guests`,payload, { 'headers': { 'Authorization': tokenStr } }));
    },
    async updateBusinessGuest(business_guest_id, payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.put(`${USER_API}/business_guests/${business_guest_id}`,payload, { 'headers': { 'Authorization': tokenStr } }));
    },
    async deleteBusinessGuest(business_guest_id) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.delete(`${USER_API}/business_guests/${business_guest_id}`, { 'headers': { 'Authorization': tokenStr } }));
    },
    async addBusinessGuestAmenity(business_guest_id,amenity_type,payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.post(`${USER_API}/business_guests/${business_guest_id}/amenities/${amenity_type}`,payload, { 'headers': { 'Authorization': tokenStr } }));
    },
    async generateMobileOTP(userId) {
        return parseResponse(Vue.axios.post(`${USER_API}/admin_users/${userId}/generate_mobile_code`, {}))
    },
    async verifyMobileOTP(userId, payload) {
        return parseResponse(Vue.axios.post(`${USER_API}/admin_users/${userId}/verify_mobile_code`, payload))
    }
}