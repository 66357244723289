let financeDetailsDisplayMixin = {
    computed: {
        financeDetails() {
			return this.$store.state.financeModule.currentFinanceDetails
		},
		account() {
			return this.financeDetails.funding_source
		},
		isLinked() {
			return (
				this.financeDetails != null &&
				this.financeDetails.funding_source != null
			)
		},
		accountType() {
			if (typeof this.account.bank_account_type !== "string") return ""
			return (
				this.account.bank_account_type.charAt(0).toUpperCase() +
				this.account.bank_account_type.slice(1)
			)
		},
		linkedStatusBadgeClasses() {
			return this.isLinked
				? "pill-alt pill-alt-green"
				: "pill-alt pill-alt-red"
		},
		linkedStatusClasses() {
			return this.isLinked ? "status-dot green" : "status-dot red"
		},
		linkedStatusLabel() {
			return this.isLinked ? "Linked" : "Not Linked"
		},
		isW9Uploaded() {
			return false
		},
		w9StatusBadgeClasses() {
			return this.isW9Uploaded
				? "pill-alt pill-alt-green"
				: "pill-alt pill-alt-red"
		},
		w9StatusClasses() {
			return this.isW9Uploaded ? "status-dot green" : "status-dot red"
		},
		w9StatusLabel() {
			return this.isW9Uploaded ? "Uploaded" : "Not Uploaded"
		}
    }
}

export default financeDetailsDisplayMixin