<template>
	<div class="sk-table-row">
		<div class="sk-table-column-fit">
			<div class="sk-pills-container">
				<div :title="statusLabel" :class="statusBadgeClasses">
					<span :class="statusClasses"></span>
					{{ statusLabel }}
				</div>
			</div>
		</div>
		<div :title="$options.filters.eventTypeName(event.type)">
			{{ event.type | eventTypeName }}
		</div>
		<div class="short-name text-truncate" :title="businessName" v-sk-unassigned="businessName"></div>
		<div class="short-name text-truncate" :title="restaurantName" v-sk-unassigned="restaurantName"></div>
		<div class="short-name text-truncate" :title="menuName" v-sk-unassigned="menuName"></div>
		<div>
			{{ preordersEnabled }}
		</div>
		<div :title="eventDate" v-sk-unassigned="eventDate"></div>
		<div :title="eventTimeRange" v-sk-unassigned="eventTimeRange"></div>
		<div class="sk-table-column-fit">
			<div class="sk-table-actions">
				<button
					class="button button-icon"
					v-if="isConfirmable"
					@click="
						actionOptionClicked({
							id: 4
						})
					"
					title="Confirm"
				>
					<i class="sk-icon-check-regular"></i>
				</button>
				<button
					class="button button-icon"
					@click="
						actionOptionClicked({
							id: 1
						})
					"
					v-else-if="isEditable"
					title="Edit"
				>
					<i class="sk-icon-pencil-solid"></i>
				</button>
				<button
					class="button button-icon"
					@click="
						actionOptionClicked({
							id: 3
						})
					"
					v-else-if="isDeletable"
					title="Delete"
				>
					<i class="sk-icon-trash-alt-solid"></i>
				</button>
				<button
					class="button button-icon"
					@click="
						actionOptionClicked({
							id: 2
						})
					"
					v-else
					title="View"
				>
					<i class="sk-icon-eye-solid"></i>
				</button>
				<SkDropdownSelect
					v-if="allowedActions"
					class="secondary-actions"
					:options="allowedActions"
					:iconClasses="'sk-icon-ellipsis-v-regular'"
					:showSelectedOption="false"
					:position="'left'"
					@selected="actionOptionClicked"
				/>
			</div>
		</div>
	</div>
</template>

<style scoped>
.button-icon:last-child {
	padding-right: 0;
}

.button-icon:last-child + .secondary-actions {
	padding-right: 10px;
}

.pill-alt {
	overflow: visible !important;
}

.short-name {
	max-width: 200px;
}
</style>

<script>
import { BusinessEventType } from "@arikgaisler/utils/enums/businessEventType"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"
import eventStatusMixin from "@/mixins/events/event-status-mixin"
import eventMixin from "@/mixins/events/event-mixin"
import eventDisplayMixin from "@/mixins/events/event-display-mixin"

export default {
	name: "EventLineItem",
	mixins: [eventStatusMixin, eventMixin, eventDisplayMixin],
	components: {
		SkDropdownSelect
	},
	props: {
		event: Object
	},
	computed: {
		menu() {
			if (!this.event.menu_id) {
				return null
			}
			return this.$store.state.eventsModule.eventMenus[this.event.menu_id]
		},
		eventDate() {
			switch (this.event.type) {
				case BusinessEventType.POPUP:
					return this.$options.filters.formatEventDate(
						this.event.start_date,
						this.event.start_time,
						this.businessTimezone,
						"ddd MMM DD, YYYY"
					)
				case BusinessEventType.VIRTUAL:
					return this.$options.filters.formatEventDate(
						this.event.end_date,
						this.event.end_time,
						this.businessTimezone,
						"ddd MMM DD, YYYY"
					)
			}
		},
		eventTimeRange() {
			switch (this.event.type) {
				case BusinessEventType.POPUP: {
					const startTime = this.$options.filters.timeWithOffset(
						this.event.start_date,
						this.event.start_time,
						this.businessTimezone
					)
					const endTime = this.$options.filters.timeWithOffset(
						this.event.end_date,
						this.event.end_time,
						this.businessTimezone
					)

					if (startTime && endTime) {
						return `${startTime} - ${endTime}`
					} else if (startTime) {
						return startTime
					} else if (endTime) {
						return endTime
					}
					break
				}
				case BusinessEventType.VIRTUAL: {
					return this.$options.filters.timeWithOffset(
						this.event.end_date,
						this.event.end_time,
						this.businessTimezone
					)
				}
			}
			return null
		},
		preordersEnabled() {
			return this.event.pre_order_end_time != null ? "On" : "Off"
		}
	},
	methods: {
		actionOptionClicked(action) {
			this.actionSelected({
				id: action.id,
				event: this.event,
				businessName: this.businessName
			})
		}
	}
}
</script>