<template>
	<SkBaseModal :isOpen="isOpen" @close="close" class="sk-modal-alt">
		<div class="sk-modal-alt-title">
			<h1>
				Link Bank Account
			</h1>
		</div>
		<div class="sk-row">
			<SkDropdownSelect
				class="sk-input"
				:options="accountTypes"
				v-model="accountType"
			/>
		</div>
		<div class="sk-row">
			<SkInput
				ref="accountNameInput"
				:name="'Account Name'"
				:required="true"
				v-model="accountName"
			/>
		</div>
		<div class="sk-row">
			<SkInput
				ref="accountNumberInput"
				:name="'Account Number'"
				:required="true"
				:type="'string'"
				v-model="accountNumber"
			/>
		</div>
		<div class="sk-row">
			<SkInput
				ref="routingNumberInput"
				:name="'Routing Number'"
				:required="true"
				:type="'string'"
				v-model="routingNumber"
			/>
		</div>
		<div id="email-error" v-if="error">
			{{ errMsg }}
		</div>
		<div class="sk-modal-actions">
			<button @click="close" class="button button-text">
				Close
			</button>
			<button
				type="button"
				class="button button-success"
				@click="linkAccount"
			>
				<i class="sk-icon-check-regular"></i>
				Link Account
			</button>
		</div>
	</SkBaseModal>
</template>

<style scoped>
* >>> .sk-radio {
	width: 15px;
	height: 15px;
	margin-right: 10px;
}
</style>

<script>
import ActionTypes from "@/store/modules/finance/action-types"
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import SkInput from "@/components/SkInput.vue"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"

export default {
	name: "LinkBankAccountModal",
	mixins: [skModalMixin],
	components: {
		SkBaseModal,
		SkInput,
		SkDropdownSelect
	},
	data: function() {
		return {
			error: false,
			errMsg: "",
			restaurant_id: 0,
			accountName: "",
			accountNumber: null,
			routingNumber: null,
			accountType: {
				id: null,
				text: "Select an Account Type"
			},
			accountTypes: [
				{
					id: null,
					text: "Select an Account Type"
				},
				{
					id: 0,
					text: "Checking"
				},
				{
					id: 1,
					text: "Savings"
				}
			],
			funding_source_id: null
		}
	},
	methods: {
		opened() {
			this.accountType.id = null
			this.accountType.text = "Select an Account Type"
			this.accountName = ""
			this.accountNumber = null
			this.routingNumber = null
		},
		handleOptions(options) {
			if (options) {
				this.restaurant_id = options.restaurant_id
			}
		},
		validateAccountData() {
			const validName = this.$refs.accountNameInput.validate()
			const validAccountNumber = this.$refs.accountNumberInput.validate()
			const validRoutingNumber = this.$refs.routingNumberInput.validate()
			if (this.accountType == null) {
				throw "Please select an account type"
			}
			if (!validName) {
				throw "Please enter a valid account name"
			}
			if (!validAccountNumber) {
				throw "Please enter a valid account number"
			}
			if (!validRoutingNumber) {
				throw "Please enter a valid routing number"
			}
			return true
		},
		dwollaCB(err, res) {
			if (err == null) {
				let hrefArr = res._links["funding-source"].href.split("/")
				const external_id = res._links["funding-source"].href.split(
					"/"
				)[hrefArr.length - 1]
				const payload = {
					external_id: external_id,
					funding_source_id: this.funding_source_id
				}
				this.$store.dispatch(
					`financeModule/${ActionTypes.VERIFY_MANUAL_RESTAURANT_FUNDING_SOURCE}`,
					payload
				)
				this.close()
			} else {
				this.bugsnag.notify(
					"Dwolla error",
					event => {
						event.addMetadata("error", {
							error: err
						})
					}
				)
				let error = "Something went wrong, please try again"
				if (err._embedded.errors && err._embedded.errors.length > 0) {
					error = err._embedded.errors[0].message 
				}
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Link Bank Account",
					message: error,
					type: NotificationType.WARNING
				})
			}
		},
		async linkAccount() {
			try {
				this.validateAccountData()
				const tokenResp = await this.$store.dispatch(
					`financeModule/${ActionTypes.ADD_MANUAL_RESTAURANT_FUNDING_SOURCE}`,
					this.restaurant_id
				)
				let token = ""
				if (tokenResp != null) {
					this.funding_source_id = tokenResp.id
					token = tokenResp.token
					const dwollaObj = {
						routingNumber: this.routingNumber.toString(),
						accountNumber: this.accountNumber.toString(),
						type: this.accountType.id == 0 ? "checking" : "savings",
						name: this.accountName
					}
					dwolla.fundingSources.create(
						token,
						dwollaObj,
						this.dwollaCB
					)
				}
			} catch (e) {
				this.bugsnag.notify(
					"Unable to link bank account",
					event => {
						event.addMetadata("error", {
							error: e,
							restaurant_id: this.restaurant_id
						})
					}
				)
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Link Bank Account",
					message:
						e && !e.message
							? e
							: "Something went wrong, please try again",
					type: NotificationType.WARNING
				})
			}
		}
	}
}
</script>