<template>
	<div class="menu-items">
		<div v-if="!isMobile" class="menu-items-sidebar dark-scrollbar">
			<h2 class="categories-title">Your Categories</h2>
			<div
				class="menu-category product-category"
				v-for="category in menuCategories"
				:key="category.id"
				@click="scrollToCategory(category.id)"
			>
				{{ category.name }} ({{ category.count }})
			</div>
			<h2
				class="categories-title all-categories"
				@click="categoriesExpanded = !categoriesExpanded"
			>
				All Categories
				<i
					class="sk-icon-chevron-down-regular"
					:style="allCategoriesIconStyle"
				></i>
			</h2>
			<transition-expand>
				<div v-if="categoriesExpanded">
					<div
						class="product-category"
						v-for="category in allCategories"
						:key="category.id"
					>
						{{ category.name }}
					</div>
				</div>
			</transition-expand>
			<button
				class="button button-icon"
				v-if="canAddCategory"
				@click="addNewCategory"
			>
				<i class="sk-icon-plus-regular"></i>
				Add New Category
			</button>
		</div>
		<div v-else>
			<div
				class="all-categories"
				@click="categoriesExpanded = !categoriesExpanded"
			>
				Categories
				<i
					class="sk-icon-chevron-down-regular"
					:style="allCategoriesIconStyle"
				></i>
			</div>
			<transition-expand>
				<div class="mobile-categories" v-if="categoriesExpanded">
					<div
						class="menu-category product-category"
						v-for="category in menuCategories"
						:key="category.id"
						@click="scrollToCategory(category.id)"
					>
						{{ category.name }} ({{ category.count }})
					</div>
					<button
						class="button button-icon"
						v-if="canAddCategory"
						@click="addNewCategory"
					>
						<i class="sk-icon-plus-regular"></i>
						Add New Category
					</button>
				</div>
			</transition-expand>
		</div>
		<div class="menu-products dark-scrollbar" v-if="showMenuProducts">
			<div
				v-for="(categoryData, categoryId) in menuProducts"
				:key="`category-${categoryId}`"
				:ref="`category-${categoryId}`"
			>
				<div class="sk-row category-title">
					{{ categoryData.category }}
				</div>
				<div class="sk-grid">
					<RestaurantMenuItem
						v-for="product in categoryData.products"
						:key="product.id"
						:product="product"
						@remove="remove"
					/>
				</div>
			</div>
		</div>
		<EmptyPlaceholderWidget
			v-else
			:title="`No Items`"
			:description="
				`Your menu doesn’t have any items yet. Add an item to start serving food to your partners today!`
			"
			:buttonText="'New Item'"
			@actionClicked="addItem"
		/>
		<NewCategoryModal ref="newCategoryModal" />
		<ConfirmationModal ref="confirmationModal" />
	</div>
</template>

<style scoped>
.button-icon {
	padding: 35px 0 0 0;
}

.button-icon,
.button-icon i {
	font-size: 12px;
	color: var(--sk-grey3);
}

.button-icon i {
	margin-right: 10px;
}
</style>

<script>
import { PermissionMethod } from "@/utils/permissions"
import tracker from "@/utils/tracker"
import { ConfirmationModalType } from "@/enums/confirmationModalType"
import TransitionExpand from "@/components/transitions/TransitionExpand.vue"
import RestaurantMenuItem from "@/components/restaurant-menu/RestaurantMenuItem.vue"
import mobileResponsivenessMixin from "@/mixins/mobile-responsiveness-mixin"
import loaderMixin from "@/mixins/loader-mixin"
import EmptyPlaceholderWidget from "@/components/EmptyPlaceholderWidget.vue"
import NewCategoryModal from "@/components/modals/NewCategoryModal.vue"
import PreviewProductModal from "@/components/modals/PreviewProductModal.vue"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue"

export default {
	name: "RestaurantMenuItems",
	mixins: [mobileResponsivenessMixin, loaderMixin],
	components: {
		TransitionExpand,
		RestaurantMenuItem,
		EmptyPlaceholderWidget,
		NewCategoryModal,
		PreviewProductModal,
		ConfirmationModal
	},
	props: {
		menu: Object
	},
	data: function() {
		return {
			categoriesExpanded: false,
			mobileThresholdWidth: 700
		}
	},
	computed: {
		canAddCategory() {
			return this.$store.state.permissions.includes(PermissionMethod.NEW_RESTAURANT_ITEM)
		},
		showMenuProducts() {
			return this.products && this.products.length > 0
		},
		menuCategories() {
			if (!this.showMenuProducts) {
				return []
			}

			return Object.keys(this.menuProducts)
				.filter(
					categoryId =>
						this.categoriesMap[categoryId] != undefined &&
						this.menuProducts[categoryId].products != undefined
				)
				.map(categoryId => {
					return {
						count: this.menuProducts[categoryId].products.length,
						name: this.categoriesMap[categoryId],
						id: categoryId
					}
				})
		},
		totalCategories() {
			return this.$store.state.restaurantsModule.categories
		},
		allCategories() {
			const categories = this.menuCategories.map(category => category.id)
			return this.totalCategories
				.filter(category => !categories.includes(`${category.id}`))
				.sort((a, b) => a.name.localeCompare(b.name))
		},
		categoriesMap() {
			return this.totalCategories.reduce((map, obj) => {
				map[obj.id] = obj.name
				return map
			}, {})
		},
		menuProducts() {
			let categoryProductsMap = {}
			this.products.forEach(product => {
				let categoryId =
					product.category_id != null ? product.category_id : -1
				if (categoryProductsMap[categoryId] == undefined) {
					categoryProductsMap[categoryId] = {
						category: this.categoriesMap[categoryId],
						products: []
					}
				}
				categoryProductsMap[categoryId].products.push(product)
			})
			return categoryProductsMap
		},
		products() {
			return this.$store.state.restaurantsModule.restaurantMenuProducts[
				this.menu.id
			]
		},
		restaurant() {
			return this.$store.state.restaurantsModule.currentRestaurant
		},
		allCategoriesIconStyle() {
			return {
				transform: this.categoriesExpanded
					? "rotate(180deg)"
					: "rotate(0)"
			}
		}
	},
	methods: {
		scrollToCategory(id) {
			const ref = this.$refs[`category-${id}`]
			if (ref && ref[0] && ref[0].scrollIntoView) {
				ref[0].scrollIntoView({
					behavior: "smooth"
				})
			}
		},
		addNewCategory() {
			tracker.trackEvent("Add New Category Clicked")
			this.$refs.newCategoryModal.open()
		},
		addItem() {
			this.$emit("addItem")
		},
		async remove(product) {
			const confirmed = await this.$refs.confirmationModal.open({
				title: `Remove ${product.name}`,
				body: `Are you sure you want to remove ${product.name} from this menu?`,
				confirmation: "Remove",
				cancel: "Keep",
				type: ConfirmationModalType.RED
			})
			if (confirmed) {
				this.$store.dispatch("restaurantsModule/removeMenuProduct", {
					productId: product.id,
					menuId: this.menu.id
				})
			}
		}
	}
}
</script>
