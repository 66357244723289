<template>
	<div class="modal-step-navbar">
		<div
			v-for="step in steps"
			class="modal-step"
			:key="step.id"
			:class="{
				active: step.isActive,
				complete: step.isComplete,
				invalid: step.isInvalid
			}"
			@click="setStep(step.id, $event)"
		>
			{{ step.text }}
			<SkRadio :disabled="true" :option="step.id" :value="currentStep" />
		</div>
	</div>
</template>

<style scoped>
.modal-step-navbar {
	background: var(--sk-white);
	display: flex;
	justify-content: space-between;
	height: 54px;
	box-shadow: 0px 0px 3px rgba(191, 191, 191, 0.5);
	margin: 0 -20px;
	padding: 0 0 0 20px;
}

.modal-step {
	display: flex;
	align-items: center;
	color: var(--sk-grey2);
	font-size: 14px;
	cursor: pointer;
}

.modal-step.active {
	color: var(--sk-navy);
	font-weight: 600;
}

.modal-step.invalid:not(.active) >>> .checkmark {
	border: 1px solid var(--sk-red);
	background: var(--sk-red);
}

.modal-step.complete:not(.active) >>> .checkmark {
	border: 1px solid var(--sk-grey2);
	background: var(--sk-grey2);
}

.modal-step >>> .checkmark-fill {
	display: none;
}

.modal-step >>> .sk-radio {
	width: 10px;
	height: 10px;
	margin-left: 10px;
}
</style>

<script>
import SkRadio from "@/components/SkRadio.vue"

export default {
	name: "SkModalStepNavbar",
	components: {
		SkRadio
	},
	props: {
		currentStep: Number,
		steps: Array
	},
	methods: {
		setStep(id, event) {
			if (event) {
				event.stopPropagation()
			}
			this.$emit("setStep", id)
		}
	}
}
</script>