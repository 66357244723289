import { InvoiceStatus } from "@/enums/invoiceStatus"
import MutationTypes from "@/store/modules/finance/mutation-types"

export default {
	[MutationTypes.LOADING](state) {
        state.loading = true
    },
	[MutationTypes.LOADED](state) {
        state.loading = false
    },
    [MutationTypes.GOT_RESTAURANTS_FINANCE_DETAILS](state, payload) {
        state.restaurantsFinanceDetails = payload.fundingSources.map((source) => {
            let pendingAmountObj = payload.pendingAmounts.find(a => a.restaurant_id == source.entity_id)
            let amount = pendingAmountObj == undefined ? 0 : pendingAmountObj.amount
            return {
                funding_source: source, 
                amount: amount 
            }
        }).sort((a, b) => a.entity_id - b.entity_id)
    },
    [MutationTypes.GOT_BUSINESSES_FINANCE_DETAILS](state, payload) {
        state.businessesFinanceDetails = payload.fundingSources.map((source) => {
            let pendingAmountObj = payload.pendingAmounts.find(a => a.restaurant_id == source.entity_id)
            let amount = pendingAmountObj == undefined ? 0 : pendingAmountObj.amount
            return {
                funding_source: source, 
                amount: amount 
            }
        }).sort((a, b) => a.entity_id - b.entity_id)
    },
    [MutationTypes.GOT_CURRENT_FINANCE_DETAILS](state, payload) {
        state.currentFinanceDetails = payload
    },
    [MutationTypes.SET_CURRENT_FUNDING_ACCOUNT](state, payload) {
        const newDetails = {
            amount: state.currentFinanceDetails.amount,
            funding_source: payload
        }
        state.currentFinanceDetails = newDetails
    },
    [MutationTypes.UPDATED_FUNDING_SOURCE_AUTO_PAY](state, payload) {
        if (state.currentFinanceDetails.funding_source && state.currentFinanceDetails.funding_source.id == payload.fundingSource.id) {
            state.currentFinanceDetails.funding_source.auto_pay = payload.autoPay
        }
    },
    [MutationTypes.GOT_RESTAURANT_PAYOUTS](state, payload) {
        state.restaurantPayouts = payload.data.payouts
    },
    [MutationTypes.GOT_CURRENT_RESTAURANT_PAYOUT_HISTORY](state, payload) {
        state.currentRestaurantPayoutHistory = payload.data.payouts
    },
    [MutationTypes.PAID_BUSINESS_INVOICE](state, paidInvoice) {
        const invoice = state.currentBusinessInvoiceHistory.find(invoice => invoice.id == paidInvoice.id)
        if (invoice) {
            invoice.status.value = {
                name: "PROCESSING",
                value: InvoiceStatus.PROCESSING
            }
        }
    },
    [MutationTypes.CLEAR_CURRENT_FINANCE_DETAILS](state) {
        state.currentFinanceDetails = {
            amount: 0,
            funding_source: null
        }
    },
    [MutationTypes.GOT_CURRENT_BUSINESS_INVOICE_HISTORY](state, payload) {
        state.currentBusinessInvoiceHistory = payload.data.billing_collections
        state.currentBusinessInvoiceHistoryTotalCount = payload.data.billing_collections_count
    },
    [MutationTypes.REMOVE_RESTAURANT_PAYOUT](state, payout_id) {
        for (var i = 0; i < state.restaurantPayouts.length; i++) {
            if (state.restaurantPayouts[i].id == payout_id) {
                state.restaurantPayouts.splice(i, 1)
                break;
            }
        }
    },
    [MutationTypes.ADJUSTED_RESTAURANT_PAYOUT](state, obj){
        let payout = state.restaurantPayouts.find(payout => payout.id == obj.id)
        if (payout){
            payout.amount = payout.amount + obj.amount
        }
    },
    [MutationTypes.BATCH_REMOVED_PAYOUTS](state, ids) {
        ids.forEach(id => {
            let index = state.restaurantPayouts.findIndex(payout => payout.id == id)
            if (index >= 0) {
                state.restaurantPayouts.splice(index, 1)
            }
        })
    },
    [MutationTypes.GOT_BUSINESS_INVOICES](state, payload) {
        state.businessInvoices = payload.data.billing_collections
    },
    [MutationTypes.ADDED_BUSINESS_INVOICE_RECORD]() {

    },
    [MutationTypes.REMOVE_BUSINESS_INVOICE](state, invoiceToRemove) {
        const invoiceIndex = state.businessInvoices.findIndex(invoice => invoice.id == invoiceToRemove.id)
        if (invoiceIndex > -1) {
            invoiceIndex.splice(invoiceIndex, 1)
        }
    },
    [MutationTypes.BATCH_APPROVED_BUSINESS_INVOICES](state, approvedInvoiceIds) {
        state.businessInvoices = state.businessInvoices
            .filter((invoice) => !approvedInvoiceIds.includes(invoice.id))
	}
}