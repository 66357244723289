<template>
	<div class="centered-tab-content fixed-button-bottom">
		<div class="sk-row sk-widget sk-widget-section">
			<div class="sk-widget-section-title">API Info</div>
			<SkInput
				ref="slugInput"
				:name="'Restaurant ID/Slug'"
				:placeholder="'Slug'"
				:validation="/^[a-zA-Z0-9]{0,10}$/"
				v-model="slug"
				@input="slugUpdate"
				@change="slugChanged"
			/>
		</div>
		<div class="sk-row sk-widget sk-widget-section">
			<div class="sk-widget-section-title">Untappd Settings</div>
			<SkInput
				:name="'User'"
				:placeholder="'Username'"
				v-model="restaurant.user_name"
			/>
			<SkInput
				:name="'API Key'"
				:placeholder="'Key'"
				v-model="restaurant.password"
			/>
			<div class="sk-switch-label-wrapper">
				<SkCheckbox v-model="syncCatalog"/>
				<span>Auto Sync</span>
			</div>
		</div>
		<div class="sk-row sk-widget sk-widget-section">
			<div class="sk-widget-section-title">Delivery</div>
			<div class="sk-radio-group">
				<SkRadio
					:option="courierIds.POSTMATES"
					v-model="restaurant.delivery_id"
				/>
				<span>Postmates</span>
			</div>
			<div class="sk-radio-group">
				<SkRadio
					:option="courierIds.DOORDASH"
					v-model="restaurant.delivery_id"
				/>
				<span>Doordash</span>
			</div>
			<div class="sk-radio-group">
				<SkRadio
					:option="courierIds.CUT_CATS"
					v-model="restaurant.delivery_id"
				/>
				<span>Cut Cats</span>
			</div>
			<div class="sk-radio-group">
				<SkRadio
					:option="courierIds.WALKING"
					v-model="restaurant.delivery_id"
				/>
				<span>Self Delivery</span>
			</div>
			<div class="sk-radio-group">
				<SkRadio
					:option="courierIds.TOOKAN"
					v-model="restaurant.delivery_id"
				/>
				<span>Bar Delivery</span>
			</div>
			<div class="sk-radio-group">
				<SkRadio
					:option="courierIds.REEF"
					v-model="restaurant.delivery_id"
				/>
				<span>Reef</span>
			</div>
		</div>
		<div class="sk-row sk-widget sk-widget-section">
			<div class="sk-widget-section-title">Commission</div>
			<div class="sk-row">
				<SkInput
					class="commission-input"
					v-model="courierCommission"
					:name="'3rd Party'"
					:placeholder="'Commission'"
					:type="'number'"
					:group="'%'"
				/>
				<SkInput
					class="commission-input"
					v-model="selfDeliveryCommission"
					:name="'Self Delivery'"
					:placeholder="'Commission'"
					:type="'number'"
					:group="'%'"
				/>
			</div>
			<div class="sk-row">
				<SkInput
					class="commission-input"
					v-model="toGoCommission"
					:name="'2Go'"
					:placeholder="'Commission'"
					:type="'number'"
					:group="'%'"
				/>
				<SkInput
					class="commission-input"
					v-model="eventCommission"
					:name="'Popups'"
					:placeholder="'Commission'"
					:type="'number'"
					:group="'%'"
				/>
			</div>
			<div class="sk-row">
				<SkInput
					class="commission-input"
					v-model="prePackagedCommission"
					:name="'Catering'"
					:placeholder="'Commission'"
					:type="'number'"
					:group="'%'"
				/>
			</div>
		</div>
		<div class="sk-row sk-widget sk-widget-section">
			<div class="sk-widget-section-title">Extras</div>
			<textarea
				class="sk-input"
				v-model="restaurant.notes"
				placeholder="Special Instructions"
				type="text"
			></textarea>
		</div>
		<button
			class="button button-primary button-absolute"
			@click="saveRestaurantInfo"
		>
			Save Details
		</button>
	</div>
</template>

<style scoped>
.sk-widget-section >>> .sk-radio {
	width: 15px;
	height: 15px;
	margin-right: 10px;
}

.commission-input {
	max-width: 260px;
}
</style>

<script>
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import { CourierId } from "@arikgaisler/utils/enums/courierId"
import restaurantCommissionMixin from "@/mixins/restaurant-commission-mixin"
import SkInput from "@/components/SkInput.vue"
import SkRadio from "@/components/SkRadio.vue"
import SkCheckbox from "@/components/SkCheckbox.vue"

export default {
	name: "RestaurantAdminInfo",
	mixins: [restaurantCommissionMixin],
	components: {
		SkInput,
		SkRadio,
		SkCheckbox
	},
	data: function() {
		return {
			slug: ""
		}
	},
	computed: {
		restaurant() {
			return this.$store.state.restaurantsModule.currentRestaurant
		},
		courierIds() {
			return CourierId
		},
		restaurantSlug() {
			return this.restaurant.slug
		},
		syncCatalog: {
			get: function() {
				return this.restaurant.sync_catalog
			},
			set: function(newVal) {
				if (newVal != undefined && newVal != null) {
					if (newVal) this.restaurant.sync_catalog = 1
					else this.restaurant.sync_catalog = 0
				}
			}
		}
	},
	methods: {
		slugChanged() {
			if (this.restaurant.logo != undefined) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Changing slug requires to re-upload images",
					type: NotificationType.WARNING
				})
			}
		},
		slugUpdate() {
			const isValid = this.$refs.slugInput.validate()
			if (isValid) {
				this.restaurant.slug = `${this.slug}`
			}
		},
		saveRestaurantInfo() {
			this.$emit("save")
		}
	},
	watch: {
		restaurantSlug: {
			immediate: true,
			handler(newVal) {
				this.slug = `${newVal}`
			}
		},
		autoSyncClicked(event) {
			if (event.target.checked) {
				this.restaurant.sync_catalog = 1
			} else this.restaurant.sync_catalog = 0
		}
	}
}
</script>