<template>
	<div class="sk-table-header">
		<div class="sk-table-row">
			<div
				class="table-column"
				v-for="column in columns"
				:key="column.id"
				:class="{ disabled: column.sortable === false }"
				@click="sort(column)"
			>
				<div>
					{{ column.name }}
					<div class="sortable-indicator" v-if="column.sortable != false">
						<i
							class="sk-icon-sort-up-solid"
							:class="{
								active:
									model && model.id == column.id && model.asc
							}"
						></i>
						<i
							class="sk-icon-sort-down-solid"
							:class="{
								active:
									model && model.id == column.id && !model.asc
							}"
						></i>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.table-column:not(.disabled) {
	cursor: pointer;
}

.table-column > div {
	display: flex;
	align-items: center;
}

.sortable-indicator {
	display: flex;
	flex-direction: column;
	margin-left: 10px;
}

i {
	font-size: 10px;
	height: 6px;
	color: var(--sk-grey2);
}

i.active {
	color: var(--sk-navy);
}
</style>

<script>
export default {
	name: "SkTableHeader",
	props: {
		value: {
			type: Object,
			default: () => {
				return {
					id: 0,
					asc: 0
				}
			},
			validator: value => {
				if (value.id == undefined || value.asc == undefined) {
					return false
				}
				return true
			}
		},
		columns: {
			type: Array,
			default: () => [],
			validator: columns => {
				return columns.every(column => {
					let isValid = true
					if (column.name == undefined) {
						isValid = false
					}
					if (column.id == undefined) {
						isValid = false
					}
					if (
						column.sortable != undefined &&
						typeof column.sortable !== "boolean"
					) {
						isValid = false
					}
					if (!isValid) {
						console.warn("Invalid Column data")
					}
					return isValid
				})
			}
		}
	},
	computed: {
		model: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit("input", val)
			}
		}
	},
	methods: {
		sort(column) {
			if (column.sortable != undefined && column.sortable == false) {
				return
			}
			const asc =
				this.model &&
				this.model.id != undefined &&
				this.model.id == column.id
					? !this.model.asc
					: 1
			this.model = {
				id: column.id,
				asc: asc
			}
		}
	}
}
</script>