<template>
	<div
		class="sk-select"
		tabindex="0"
		:class="classes"
		@blur="onBlur"
		@click="toggleDropdown"
	>
		<div class="selected placeholder">
			<span v-if="showSelectedOptions && selectedOptionsText">
				{{ selectedOptionsText }}
			</span>
			<span
				v-else-if="placeholder && placeholder.text"
				v-html="placeholder.text"
			></span>
			<i
				class="dropdown-icon"
				:class="[
					iconClasses,
					iconClasses == 'sk-icon-chevron-down-regular' && open
						? 'flipped'
						: ''
				]"
			></i>
		</div>
		<div
			class="options dark-scrollbar"
			ref="optionsList"
			:class="{ 'select-hide': !open }"
			:style="positionStyle"
		>
			<div
				class="option"
				v-for="option of validOptions"
				:key="option.id"
				:class="{
					selected: model.includes(option.id)
				}"
			>
				<div class="sk-row">
					<div
						class="sk-switch-label-wrapper"
						@click="optionSelected(option, $event)"
					>
						<SkCheckbox
							@click="checkboxClicked($event)"
							:checked="model.includes(option.id)"
						/>
						<div class="option-text" v-html="option.text"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.sk-select {
	position: relative;
	width: 100%;
	text-align: left;
	outline: none;
	height: 100%;
	display: flex;
	cursor: pointer;
}

.sk-select.disabled {
	opacity: 0.5;
	pointer-events: none;
	cursor: normal;
}

.new-font-section {
	position: absolute;
	width: 215px;
	height: 76px;
	left: 734px;
	top: 696px;
	background: var(--sk-greybg);
	border-radius: 5px;
}

.multi-select-cb {
	position: absolute;
	left: 5.35%;
	right: 5.35%;
	top: 15.62%;
	bottom: 15.62%;
}

.selected i {
	margin-left: auto;
	text-align: center;
}

.selected .dropdown-icon {
	margin-left: 15px;
}

.selected span {
	margin-right: 15px;
	width: 100%;
}

.selected {
	align-items: center;
	display: flex;
	font-size: 14px;
	width: 100%;
}

.selected.placeholder {
	color: var(--sk-grey2);
}

.options {
	padding: 20px;
	color: var(--sk-white);
	border-radius: 5px;
	overflow: hidden;
	position: absolute;
	background: var(--sk-white);
	box-shadow: 0px 1px 4px rgba(136, 136, 136, 0.25);
	z-index: 800;
	max-height: 300px;
	overflow-y: auto;
}

.option {
	color: var(--sk-grey2);
	cursor: pointer;
	user-select: none;
	font-size: 14px;
}

.option.selected {
	color: var(--sk-grey3);
}

.option + .option {
	margin-top: 30px;
}

.option-text {
	display: flex;
	align-items: center;
	white-space: nowrap;
	flex-grow: 1;
}

.option:hover {
	color: var(--sk-grey3);
}

.select-hide {
	display: none;
}

.options >>> i {
	margin-right: 20px;
}
</style>

<script>
import utils from "@/utils/utils"
import SkCheckbox from "@/components/SkCheckbox.vue"

export default {
	name: "SkDropdownMultiSelect",
	components: {
		SkCheckbox
	},
	props: {
		value: null,
		position: {
			type: String,
			default: "bottom"
		},
		options: {
			type: Array,
			required: true
		},
		classes: {
			type: String,
			default: "sk-input"
		},
		iconClasses: {
			type: String,
			default: "sk-icon-chevron-down-regular"
		},
		showSelectedOptions: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			open: false,
			defaultModel: []
		}
	},
	computed: {
		model: {
			get() {
				return this.value != undefined ? this.value : this.defaultModel
			},
			set(val) {
				if (
					this.value == undefined &&
					val != null &&
					val != undefined
				) {
					this.defaultModel = val
				}
				this.$emit("input", val)
			}
		},
		placeholder() {
			return this.options
				? this.options.find((option) => option.id == null)
				: {}
		},
		validOptions() {
			return this.options.filter((option) => option.id != null)
		},
		positionStyle() {
			switch (this.position) {
				case "top":
					return {
						left: "0",
						bottom: "calc(100% + 20px)"
					}
				case "bottom":
					return {
						top: "calc(100% + 20px)",
						left: "0"
					}
				case "right":
					return {
						left: "calc(100% + 20px)",
						top: "0"
					}
				case "left":
					return {
						right: "100%",
						top: "0"
					}
			}
		},
		selectedOptionsText() {
			if (this.model && this.model.length > 0 && this.options) {
				return this.options
					.filter((option) => this.model.includes(option.id))
					.map((option) => option.text)
					.join(", ")
			}
			return null
		}
	},
	mounted: function () {
		this.reset(this.options)
	},
	methods: {
		reset(options) {
			if (!options) {
				options = []
			}
			const ids = options.map((option) => option.id)
			if (this.model) {
				this.model = this.model.filter((option) =>
					ids.includes(option.id)
				)
			}
		},
		checkboxClicked(event) {
			event.stopPropagation()
			return false
		},
		optionSelected(selectedOption, event) {
			if (event) {
				event.stopPropagation()
			}
			const optionIds = this.model.slice()
			const selected = optionIds.includes(selectedOption.id)
			if (selected) {
				this.model = optionIds.filter((id) => id != selectedOption.id)
			} else {
				optionIds.push(selectedOption.id)
				this.model = optionIds
			}
		},
		onBlur() {
			this.open = false
		},
		toggleDropdown(event) {
			this.open = !this.open
			if (this.open) {
				this.$nextTick(() => {
					if (
						!utils.isInViewport(this.$refs.optionsList) &&
						event.target &&
						event.target.scrollIntoView
					) {
						event.target.scrollIntoView({
							behavior: "smooth",
							block: "center"
						})
					}
				})
			}
		}
	},
	watch: {
		options(newVal, oldVal) {
			if (this.model && this.model.length > 0) {
				const options = newVal.reduce((options, option) => {
					options[option.id] = option
					return options
				})
				const oldOptions = oldVal.reduce((options, option) => {
					options[option.id] = option
					return options
				})
				let selectedOptions = this.model.map(optionId => {
					return {
						id: optionId,
						text: oldOptions[optionId] ? oldOptions[optionId].text : ""
					}
				})
				let invalidOption = selectedOptions.find(option => {
					const newOption = options[option.id]
					if (!newOption) {
						return true
					} else if (newOption.id != option.id || newOption.text != option.text) {
						return true
					}
				})
				if (invalidOption) {
					this.reset(newVal)
				}
			}
		}
	}
}
</script>