<template>
	<div class="product-footer">
		<slot v-if="isMobile">
			<SkDropdownSelect
				v-if="showOverflowMenu"
				class="actions-overflow"
				:options="actions"
				:iconClasses="'sk-icon-ellipsis-v-regular'"
				:showSelectedOption="false"
				@selected="actionSelected"
			/>
			<button
				v-if="showEdit"
				class="button button-icon edit-button"
				@click="actionSelected(3)"
			>
				<i class="sk-icon-pencil-solid"></i>
				Edit
			</button>
			<button
				v-if="showUnarchive"
				class="button button-icon"
				@click="actionSelected(5)"
			>
				<i class="sk-icon-power-off-regular"></i>
				Reactivate Product
			</button>
		</slot>
		<slot v-if="!isMobile">
			<button
				v-if="showEdit"
				class="button button-icon edit-button"
				@click="actionSelected(3)"
			>
				<i class="sk-icon-pencil-solid"></i>
				Edit
			</button>
			<button
				v-if="showPreview"
				class="button button-icon"
				@click="actionSelected(0)"
			>
				<i class="sk-icon-eye-solid"></i>
				Preview
			</button>
			<button
				v-if="showDuplicate"
				class="button button-icon"
				@click="actionSelected(1)"
			>
				<i class="sk-icon-copy-solid"></i>
				Duplicate
			</button>
			<button
				v-if="showArchive"
				class="button button-icon"
				@click="actionSelected(4)"
			>
				<i class="sk-icon-archive-solid"></i>
				Archive
			</button>
			<button
				v-if="showUnarchive"
				class="button button-icon"
				@click="actionSelected(5)"
			>
				<i class="sk-icon-power-off-regular"></i>
				Reactivate
			</button>
		</slot>
		<div class="right-action" v-if="showStockStatus">
			<SkToggle v-model="inStock" :width="'33px'" />
			<label>In Stock</label>
		</div>
		<div v-else-if="showDelete" class="right-action">
			<button class="button button-icon" @click="actionSelected(2)">
				<i class="sk-icon-trash-alt-solid"></i>
				Delete Product
			</button>
		</div>
	</div>
</template>

<style scoped>
.product-footer {
	background: var(--sk-greybg2);
	padding: 5px 15px;
	margin: 20px -20px -20px -20px;
	box-shadow: 0 0 2px hsla(0, 0%, 53.3%, 0.25);
	display: flex;
	align-items: center;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

.button-icon {
	font-size: 12px;
}

.button-icon i {
	margin-right: 10px;
	font-size: 12px;
}

.button-icon + .button-icon {
	margin-left: 20px;
}

.right-action {
	display: inline-block;
	margin-left: 10px;
	margin-left: auto;
	align-items: center;
	display: flex;
}

.right-action label {
	color: var(--sk-grey3);
	margin-left: 10px;
	font-size: 12px;
	margin-bottom: 0;
}

.actions-overflow {
	display: inline-block;
	width: 30px;
	padding: 10px 0;
	margin-right: 15px;
}
</style>

<script>
import { ProductStatus } from "@/enums/productStatus"
import tracker from "@/utils/tracker"
import mobileResponsivenessMixin from "@/mixins/mobile-responsiveness-mixin"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"
import SkToggle from "@/components/SkToggle.vue"
import { PermissionMethod } from "@/utils/permissions"

export default {
	name: "RestaurantProductFooter",
	mixins: [mobileResponsivenessMixin],
	components: {
		SkDropdownSelect,
		SkToggle
	},
	props: {
		product: Object,
		hiddenActions: {
			type: Array,
			default: () => []
		}
	},
	data: function() {
		return {
			mobileThresholdWidth: 1400
		}
	},
	computed: {
		showPreview() {
			return !this.isArchived && !this.hiddenActions.includes(0)
		},
		showDuplicate() {
			return (
				!this.isArchived &&
				this.$store.state.permissions.includes(PermissionMethod.NEW_RESTAURANT_ITEM) &&
				!this.hiddenActions.includes(1)
			)
		},
		showDelete() {
			return (
				this.isArchived &&
				this.$store.state.permissions.includes(PermissionMethod.DELETE_RESTAURANT_ITEM) &&
				!this.hiddenActions.includes(2)
			)
		},
		showArchive() {
			return (
				!this.isArchived &&
				this.$store.state.permissions.includes(PermissionMethod.ARCHIVE_RESTAURANT_ITEM) &&
				!this.hiddenActions.includes(4)
			)
		},
		showUnarchive() {
			return (
				this.isArchived &&
				this.$store.state.permissions.includes(PermissionMethod.UNARCHIVE_RESTAURANT_ITEM) &&
				!this.hiddenActions.includes(5)
			)
		},
		showEdit() {
			return !this.isArchived && !this.hiddenActions.includes(3)
		},
		showStockStatus() {
			return !this.isArchived
		},
		showOverflowMenu() {
			return !this.isArchived && this.actions.length > 0
		},
		isArchived() {
			return this.product.status == ProductStatus.ARCHIVED
		},
		actions() {
			let actions = []

			if (this.showPreview) {
				actions.push({
					id: 0,
					text: `<i class="sk-icon-eye-solid"></i> Preview`
				})
			}

			if (this.showDuplicate) {
				actions.push({
					id: 1,
					text: `<i class="sk-icon-copy-solid"></i> Duplicate`
				})
			}

			if (this.showArchive) {
				actions.push({
					id: 4,
					text: `<i class="sk-icon-archive-solid"></i> Archive`
				})
			}

			return actions
		},
		inStock: {
			get() {
				return this.product.stock_status == 1
			},
			set(value) {
				tracker.trackEvent("Restaurant Product Stock Toggled", {
					active: value
				})
				this.$store.dispatch("restaurantsModule/updateProductStock", {
					productId: this.product.id,
					status: value
				})
			}
		}
	},
	methods: {
		actionSelected(action) {
			tracker.trackEvent("Restaurant Product Action Clicked", {
				action: action.text
			})
			const id = action.id != undefined ? action.id : action
			this.$emit("actionSelected", id)
		}
	}
}
</script>