
<template>
	<div class="empty-placeholder-container">
		<div class="sk-widget-alt empty-placeholder">
			<h2>{{ title }}</h2>
			<div class="empty-description">{{ description }}</div>
			<img
				class="placeholder-image"
				:src="imgSrc"
				alt="Empty Placeholder"
			/>
			<button
				class="button button-primary"
				@click="actionButtonClicked"
				v-if="buttonText"
			>
				<i :class="buttonIcon"></i>
				{{ buttonText }}
			</button>
		</div>
	</div>
</template>

<style scoped>
.sk-widget-alt {
	padding: 20px;
}

.empty-placeholder-container {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.empty-placeholder {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 435px;
}

h2 {
	font-size: 20px;
	font-weight: 600;
	color: var(--sk-navy);
	margin-bottom: 15px;
}

.empty-description {
	color: var(--sk-grey3);
	font-size: 14px;
	margin-bottom: 37px;
	text-align: center;
}

.placeholder-image {
	height: 147px;
	margin-bottom: 48px;
}
</style>

<script>
export default {
	name: "EmptyPlaceholderWidget",
	props: {
		title: {
			type: String,
			required: true
		},
		description: {
			type: String,
			default: ""
		},
		buttonText: {
			type: String
		},
		buttonIcon: {
			type: String,
			default: "sk-icon-plus-regular"
		},
		imgSrc: {
			type: String,
			default: "/images/2k-no-menu.png"
		}
	},
	methods: {
		actionButtonClicked() {
			this.$emit("actionClicked")
		}
	}
}
</script>