<template>
	<SkBaseModal :isOpen="isOpen" @close="close" class="sk-modal-alt">
		<loading :active.sync="loading" :is-full-page="false"></loading>
		<div class="sk-modal-alt-title">
			<h1>
				{{ modalTitle }}
				<span class="sk-modal-alt-subtitle">#{{ orderId }}</span>
			</h1>
		</div>
		<div class="sk-row sk-widget-alt">
			<div class="radio-container">
				<SkRadio :option="refundTypes.FULL" v-model="refundType" />
			</div>
			<div class="sk-radio-label">
				<div class="radio-label-title">
					<h2>Full Refund</h2>
					<span>
						{{ fullAmount | currency }}
					</span>
				</div>
				<div>Includes COGs, Tax, Fees & Tip</div>
			</div>
		</div>
		<div class="sk-row sk-widget-alt">
			<div class="radio-container">
				<SkRadio :option="refundTypes.PARTIAL" v-model="refundType" />
			</div>
			<div class="sk-radio-label">
				<div class="radio-label-title">
					<h2>Partial Refund</h2>
					<span>
						{{ partialAmount | currency }}
					</span>
				</div>
				<div
					v-for="product in suborderProducts"
					:key="product.uniqueId"
				>
					<div class="suborder-product">
						<SkCheckbox
							:checked="product.checked"
							@input="toggleProductRefund(product)"
						/>
						<span>{{ product.quantity }} {{ product.name }}</span>
						<span>
							{{ product.price | currency }}
						</span>
					</div>
					<div
						class="suborder-product-meta"
						v-for="meta in product.metas"
						:key="meta.uniqueId"
					>
						<SkCheckbox
							:checked="meta.checked"
							@input="toggleMetaRefund(meta)"
						/>
						<span>{{ meta.name }}</span>
						<span>
							{{ meta.price | currency }}
						</span>
					</div>
				</div>
			</div>
		</div>
		<div class="sk-row sk-widget-alt">
			<div class="radio-container">
				<SkRadio :option="refundTypes.CUSTOM" v-model="refundType" />
			</div>
			<div class="sk-radio-label">
				<h2>Custom Refund</h2>
				<div>
					<SkInput
						v-model="customAmount"
						:name="'Custom Refund'"
						:placeholder="placeholderText"
						:type="'number'"
						@focus="refundType = refundTypes.CUSTOM"
					/>
				</div>
			</div>
		</div>
		<div class="sk-row">
			<SkDropdownSelect
				:classes="'sk-input'"
				v-model="refundReason"
				:options="reasons"
			/>
		</div>
		<transition-expand>
			<div class="sk-row">
				<SkInput
					v-model="refundReasonText"
					:multiline="true"
					:name="'Refund Reason'"
				/>
			</div>
		</transition-expand>
		<div class="sk-modal-actions">
			<button @click="close" class="button button-text">Close</button>
			<button
				type="button"
				class="button button-success"
				@click="saveRefund"
			>
				<i class="sk-icon-check-regular"></i>
				{{ saveButtonText }}
			</button>
		</div>
	</SkBaseModal>
</template>

<style scoped>
.radio-container {
	margin: -15px 15px -15px -20px;
	background: var(--sk-greybg);
	display: flex;
	align-items: center;
	justify-content: center;
	border-right: 1px solid var(--sk-grey);
	width: 50px;
}

.sk-radio-label {
	width: 100%;
	color: var(--sk-grey2);
	font-size: 14px;
}

.sk-radio-label h2,
.radio-label-title {
	font-weight: 600;
	font-size: 14px;
	color: var(--sk-navy);
}

.radio-label-title h2 {
	margin-bottom: 0;
}

.radio-label-title {
	display: flex;
	justify-content: space-between;
}

.sk-widget-alt >>> .sk-radio {
	width: 20px;
	height: 20px;
	flex-shrink: 0;
	margin-right: 0;
}

.suborder-product,
.suborder-product-meta {
	display: flex;
	align-items: center;
}

.suborder-product {
	margin-top: 10px;
}

.suborder-product + .suborder-product-meta {
	margin-top: 10px;
}

.suborder-product-meta {
	margin: 10px 0 10px 50px;
}

.suborder-product span,
.suborder-product-meta span {
	color: var(--sk-grey3);
}

.suborder-product span {
	font-size: 14px;
}

.suborder-product span + span,
.suborder-product-meta span + span {
	margin-left: 10px;
}

.suborder-product-meta span {
	font-size: 12px;
	font-style: italic;
}

.suborder-product >>> .sk-checkbox,
.suborder-product-meta >>> .sk-checkbox {
	margin-right: 20px;
}
</style>

<script>
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import search_service from "@/services/search"
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import currencyFilterMixin from "@/mixins/currency-filter-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import SkRadio from "@/components/SkRadio.vue"
import SkInput from "@/components/SkInput.vue"
import SkCheckbox from "@/components/SkCheckbox.vue"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"
import TransitionExpand from "@/components/transitions/TransitionExpand.vue"
import Loading from "vue-loading-overlay"

export const RefundType = {
	FULL: 0,
	PARTIAL: 1,
	CUSTOM: 2
}

export default {
	name: "RefundModal",
	mixins: [skModalMixin, currencyFilterMixin],
	components: {
		SkBaseModal,
		SkRadio,
		SkInput,
		SkCheckbox,
		SkDropdownSelect,
		TransitionExpand,
		Loading
	},
	data() {
		return {
			loading: false,
			refundId: null,
			customAmount: null,
			refundReason: null,
			selectedProducts: [],
			selectedMetas: {},
			refundType: RefundType.FULL,
			refundTypes: RefundType,
			refundReasonText: ""
		}
	},
	computed: {
		modalTitle() {
			return this.refundId != null ? "Edit Refund:" : "Issue Refund:"
		},
		orderId() {
			return this.currentOrder ? this.currentOrder.id : ""
		},
		currentOrder() {
			return this.$store.state.ordersModule.currentOrder
		},
		placeholderText() {
			let price = this.currentOrder ? this.currentOrder.price : 0
			return "Max $" + parseFloat(price).toFixed(2)
		},
		fullAmount() {
			return this.currentOrder
				? parseFloat(this.currentOrder.price).toFixed(2)
				: "0"
		},
		partialAmount() {
			const products = this.suborderProducts.filter((product) =>
				this.selectedProducts.includes(product.uniqueId)
			)

			const productPrices = products.reduce((amount, product) => {
				let price = parseFloat(product.price)
				amount = amount + price
				return amount
			}, 0)

			const metaPrices = Object.values(this.selectedMetas).reduce(
				(amount, meta) => {
					let metaPrice = meta.price
					amount = amount + metaPrice
					return amount
				},
				0
			)

			return productPrices + metaPrices
		},
		suborderProducts() {
			if (!this.currentOrder) {
				return []
			}

			const suborders = this.currentOrder
				? this.currentOrder.sub_orders
				: []
			const businessId = this.currentOrder.business_id ? this.currentOrder.business_id : this.currentOrder.business.id
			const business = this.$store.getters.businessesMap[businessId]
			const tax = business && business.tax ? business.tax : 0
			const fees = business && business.service_fee ? business.service_fee : 0
			return suborders
				.map((suborder) => suborder.products)
				.flat()
				.map((product, i) => {
					let metas = []
					let productMetas = null
					if (product.metas) {
						productMetas = product.metas
					} else if (product.meta) [(productMetas = product.meta)]
					if (productMetas) {
						metas = productMetas.map((meta) => {
							let uniqueId = `${product.id}-${meta.meta_id}-${meta.meta_val_id}-${i}`
							let metaPrice = parseFloat(meta.price) * product.quantity
							let taxesAndFees = (metaPrice * (tax + fees)) / 100
							return {
								uniqueId: uniqueId,
								id: meta.meta_id,
								price: metaPrice + taxesAndFees,
								name: `${meta.label}: ${meta.value}`,
								checked:
									uniqueId in this.selectedMetas
										? true
										: false
							}
						})
					}
					let uniqueId = `${product.id}-${i}`
					let taxesAndFees = (parseFloat(product.subtotal) * (tax + fees)) / 100
					return {
						name: product.name,
						quantity: product.quantity,
						price: parseFloat(product.subtotal) + taxesAndFees,
						uniqueId: uniqueId,
						id: product.id,
						checked: this.selectedProducts.includes(uniqueId),
						metas: metas
					}
				})
		},
		reasons() {
			let reasons = this.$store.state.refund_reasons.slice()
			reasons.unshift({
				id: null,
				text: "Refund Reason"
			})
			return reasons
		},
		saveButtonText() {
			return this.refundId != null ? "Update Refund" : "Issue Refund"
		}
	},
	methods: {
		handleOptions(options) {
			if (options.refund) {
				this.refundId = options.refund.id
				const reason = this.reasons.find(
					(reason) => reason.id == options.refund.reason_id
				)
				if (reason) {
					this.refundReason = reason
					if (options.refund.reason) {
						this.refundReasonText = options.refund.reason
					}
				}
				this.loading = true
				const orderId = options.refund.order ? options.refund.order.id : options.refun.order_id
				search_service
					.searchOrders({
						filters: {
							ids: [orderId]
						}
					})
					.then((response) => {
						const orders = response.data.orders
						if (this.isOpen) {
							if (orders && orders.length > 0) {
								this.$store.dispatch(
									"ordersModule/setCurrentOrder",
									{
										order: orders[0]
									}
								)
							} else {
								throw "Refund order not found!"
							}
						}
					}).catch((e) => {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							type: NotificationType.ERROR,
							title: "Issue Refund",
							message: "Unable to edit refund, please try again"
						})
						this.close()
						throw e
					}).finally(() => {
						this.loading = false
					})
			}
		},
		toggleProductRefund(product) {
			if (this.refundType != RefundType.PARTIAL) {
				this.refundType = RefundType.PARTIAL
			}
			let productMetaIds = product.metas.map((meta) => {
				return { id: meta.uniqueId, price: meta.price }
			})
			if (product.checked) {
				const index = this.selectedProducts.indexOf(product.uniqueId)
				this.selectedProducts.splice(index, 1)
				if (productMetaIds) {
					productMetaIds.forEach((productMetaIdObj) => {
						if (productMetaIdObj.id in this.selectedMetas) {
							this.$delete(
								this.selectedMetas,
								productMetaIdObj.id
							)
						}
					})
				}
			} else if (!this.selectedProducts.includes(product.uniqueId)) {
				this.selectedProducts.push(product.uniqueId)
				let newProductMetaIds = productMetaIds.filter(
					(prodMeta) => !(prodMeta.id in this.selectedMetas)
				)
				if (newProductMetaIds) {
					newProductMetaIds.forEach(
						(productMeta) =>
							(this.selectedMetas[productMeta.id] = productMeta)
					)
				}
			}
		},
		toggleMetaRefund(meta) {
			if (this.refundType != RefundType.PARTIAL) {
				this.refundType = RefundType.PARTIAL
			}
			if (meta.checked) {
				this.$delete(this.selectedMetas, meta.uniqueId)
			} else if (!(meta.uniqueId in this.selectedMetas)) {
				this.$set(this.selectedMetas, meta.uniqueId, {
					id: meta.uniqueId,
					price: meta.price
				})
			}
		},
		validateRefundData(amount) {
			if (amount <= 0 || !amount) {
				throw "Please enter an amount"
			}
			if (
				this.refundReason == null ||
				this.refundReason.id == undefined
			) {
				throw "A reason is required"
			}
			if (this.currentOrder.price < amount) {
				throw "Amount must be lower than or equal to the price paid"
			}
			return true
		},
		async saveRefund() {
			try {
				let refundAmount = 0
				switch (this.refundType) {
					case RefundType.FULL:
						refundAmount = this.fullAmount
						break
					case RefundType.PARTIAL:
						refundAmount = this.partialAmount
						break
					case RefundType.CUSTOM:
						if (
							this.customAmount != null &&
							this.customAmount > 0
						) {
							refundAmount = this.customAmount
						}
						break
				}
				this.validateRefundData(refundAmount)
				
				this.loading = true
				let success = false
				if (this.refundId != null) {
					success = await this.$store.dispatch(
						"ordersModule/updateRefund",
						{
							refundId: this.refundId,
							amount: parseFloat(refundAmount).toFixed(2),
							reasonId: this.refundReason.id,
							reason: this.refundReasonText
						}
					)
				} else {
					success = await this.$store.dispatch(
						"ordersModule/addRefund",
						{
							orderId:
								this.$store.state.ordersModule.currentOrder.id,
							amount: parseFloat(refundAmount).toFixed(2),
							reasonId: this.refundReason.id,
							reason: this.refundReasonText
						}
					)
				}

				if (success) {
					let message =
						this.refundId != null
							? `Refund was applied to #${this.orderId}!`
							: `Refund was updated for ${this.orderId}`
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						type: NotificationType.SUCCESS,
						title: "Issue Refund",
						message: message
					})
				} else {
					throw "Something went wrong, please try again."
				}

				this.$store.dispatch(
					"ordersModule/orderStatusUpdate",
					this.$store.state.ordersModule.currentOrder.id
				)
				this.loading = false
				this.close()
			} catch (e) {
				this.loading = false
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					type: NotificationType.ERROR,
					title: "Issue Refund",
					message: e.message ? e.message : e
				})
			}
		},
		closed() {
			this.loading = false
			this.refundId = null
			this.amount = 0
			this.customAmount = null
			this.refundReason = null
			this.selectedProducts = []
			this.selectedMetas = {}
			this.refundType = RefundType.FULL
			this.refundReasonText = ""
		}
	},
	watch: {
		refundType: function (newVal) {
			if (newVal != RefundType.PARTIAL) {
				this.selectedProducts = []
				this.selectedMetas = {}
			}
			if (newVal != RefundType.CUSTOM) {
				this.customAmount = null
			}
		}
	}
}
</script>