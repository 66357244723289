let plaidMixin = {
    data: function() {
        return {
            plaid_handler: null
        }
    },
    computed: {
        plaidConfig() {
            return {
                clientName: "2ndKitchen",
				countryCodes: ["US"],
				env: process.env.VUE_APP_PLAID_ENV,
				key: process.env.VUE_APP_PLAID_KEY,
				product: ["auth"],
				language: "en",
				userLegalName: "2ndKitchen",
				userEmailAddress: "info@2ndkitchen.com"
            }
        }
    },
    methods: {
        initPlaid() {
			let that = this
			this.plaid_handler = Plaid.create({
				clientName: this.plaidConfig.clientName,
				countryCodes: this.plaidConfig.countryCodes,
				env: this.plaidConfig.env,
				key: this.plaidConfig.key,
				product: this.plaidConfig.product,
				language: this.plaidConfig.language,
				userLegalName: this.plaidConfig.userLegalName,
				userEmailAddress: this.plaidConfig.userEmailAddress,
				onLoad: function () {
                    that.onPlaidLoad()
                },
				onSuccess: function (public_token, metadata) {
                    that.onPlaidSuccess(public_token, metadata)
				},
				onExit: function (err, metadata) {
                    that.onPlaidExit(err, metadata)
				},
				onEvent: function (eventName, metadata) {
                    that.onPlaidEvent(eventName, metadata)
                }
			})
		},
        onPlaidLoad() {},
        // eslint-disable-next-line no-unused-vars
        onPlaidSuccess(public_token, metadata) {},
        // eslint-disable-next-line no-unused-vars
        onPlaidExit(err, metadata) {
            if (err != null) {
                // The user encountered a Plaid API error prior to exiting.
            }
        },
        // eslint-disable-next-line no-unused-vars
        onPlaidEvent(eventName, metaData) {}
    }
}

export default plaidMixin