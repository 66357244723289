<template>
	<div class="activity-feed-item">
		<i :class="activityIcon"></i>
		<div class="activity-feed-item-content">
			<div>
				<div class="activity-feed-item-title">{{ activity.title }}</div>
				<span class="activity-feed-detail">
					{{ timeSince }}
				</span>
				<span v-visible="unread" class="activity-new"></span>
			</div>
			<div class="activity-feed-item-description">
				{{ activity.subtext }}
				<router-link :to="linkPath" v-if="hasLink">
					<span class="activity-feed-link">{{ linkText }}</span>
				</router-link>
			</div>
		</div>
	</div>
</template>

<style scoped>
.activity-feed-item {
	display: flex;
	align-items: center;
	height: 35px;
	padding: 0 20px;
}

i {
	margin-right: 15px;
	color: var(--sk-dark-navy);
	font-size: 12px;
}

.activity-feed-item-title {
	font-weight: 600;
	color: var(--sk-dark-navy);
	font-size: 12px;
}

.activity-feed-detail {
	font-size: 10px;
	color: var(--sk-grey2);
	margin-left: 5px;
	flex: 1;
	text-align: right;
}

.activity-feed-item-description {
	font-size: 10px;
	color: var(--sk-grey2);
	max-width: 225px;
}

.activity-feed-item-content {
	width: 100%;
}

.activity-feed-item-content > div:first-child {
	margin-bottom: 5px;
	display: flex;
	align-items: center;
}

.activity-feed-link {
	color: var(--sk-dark-navy);
	margin-left: 5px;
}

.activity-feed-link:hover,
.activity-feed-link:active {
	text-decoration: none;
}

.activity-new {
	background: var(--sk-orange);
	width: 7px;
	height: 7px;
	border-radius: 50%;
	margin-left: 20px;
}
</style>

<script>
import activityFeedItemMixin from "@/mixins/activity-feed-item-mixin"

export default {
	name: "SideBarActivityFeedItem",
	mixins: [activityFeedItemMixin]
}
</script>