export const ActivityType = {
	ADMIN_USER_ACTIVATED: 1,
	BUSINESS_LAUNCHED: 2,
	BUSINESS_SUPPLY_SHIPPED: 3,
	CAMPAIGN_ACTION_DELIVERED: 4,
	BUSINESS_MENU_RESTAURANT_APPROVED: 5,
	BUSINESS_EVENT_SCHEDULED: 6,
	VOUCHER_REQUEST_CREATED: 7,
	VOUCHERS_RUNNING_LOW: 8,
	CATERING_ORDER_REMINDER: 9
}