export const GET_FINANCE = "GET_FINANCE"
export const GET_RESTAURANTS_FINANCE_DETAILS = "GET_RESTAURANTS_FINANCE_DETAILS"
export const GET_BUSINESSES_FINANCE_DETAILS = "GET_BUSINESSES_FINANCE_DETAILS"
export const GET_FUNDING_SOURCE = "GET_FUNDING_SOURCE"
export const ADD_FUNDING_SOURCE = "ADD_FUNDING_SOURCE"
export const TOGGLE_FUNDING_SOURCE_AUTO_PAY = "TOGGLE_FUNDING_SOURCE_AUTO_PAY"
export const ADD_MANUAL_RESTAURANT_FUNDING_SOURCE = "ADD_MANUAL_RESTAURANT_FUNDING_SOURCE"
export const VERIFY_MANUAL_RESTAURANT_FUNDING_SOURCE = "VERIFY_MANUAL_RESTAURANT_FUNDING_SOURCE"
export const REMOVE_FUNDING_SOURCE = "REMOVE_FUNDING_SOURCE"
export const GET_CURRENT_RESTAURANT_FINANCE_DETAILS = "GET_CURRENT_RESTAURANT_FINANCE_DETAILS"
export const GET_CURRENT_BUSINESS_FINANCE_DETAILS = "GET_CURRENT_BUSINESS_FINANCE_DETAILS"
export const CLEAR_CURRENT_FINANCE_DETAILS = "CLEAR_CURRENT_FINANCE_DETAILS"
export const GET_CURRENT_BUSINESS_INVOICE_HISTORY = "GET_CURRENT_BUSINESS_INVOICE_HISTORY"
export const PAY_BUSINESS_INVOICE = "PAY_BUSINESS_INVOICE"
export const DOWNLOAD_INVOICE_USAGE_BREAKDOWN = "DOWNLOAD_INVOICE_USAGE_BREAKDOWN"
export const GET_RESTAURANT_PAYOUTS = "GET_RESTAURANT_PAYOUTS"
export const REMOVE_RESTAURANT_PAYOUT = "REMOVE_RESTAURANT_PAYOUT"
export const ADJUSTED_RESTAURANTS_PAYOUT = "ADJUSTED_RESTAURANTS_PAYOUT"
export const BATCH_REMOVED_PAYOUTS = "BATCH_REMOVED_PAYOUTS"
export const APPROVE_RESTAURANT_PAYOUT = "APPROVE_RESTAURANT_PAYOUT"
export const ADJUST_RESTAURANT_PAYOUT_AMOUNT = "ADJUST_RESTAURANT_PAYOUT_AMOUNT"
export const BATCH_APPROVE_RESTAURANT_PAYOUTS = "BATCH_APPROVE_RESTAURANT_PAYOUTS"
export const CLEAR_RESTAURANT_PAYOUT = "CLEAR_RESTAURANT_PAYOUT"
export const BATCH_COMPLETE_RESTAURANT_PAYOUTS = "BATCH_COMPLETE_RESTAURANT_PAYOUTS"
export const GET_PAYOUT_ORDERS = "GET_PAYOUT_ORDERS"
export const GET_PAYOUT_EXCEPTIONS = "GET_PAYOUT_EXCEPTIONS"
export const ISSUE_PAYOUTS = "ISSUE_PAYOUTS"
export const UPDATE_RESTAURANT_PAYOUT = "UPDATE_RESTAURANT_PAYOUT"
export const GET_BUSINESS_INVOICES = "GET_BUSINESS_INVOICES"
export const ADD_BUSINESS_INVOICE_RECORD = "ADD_BUSINESS_INVOICE_RECORD"
export const APPROVE_BUSINESS_INVOICE = "APPROVE_BUSINESS_INVOICE"
export const BATCH_APPROVE_BUSINESS_INVOICES = "BATCH_APPROVE_BUSINESS_INVOICES"
export const COMPLETE_BUSINESS_INVOICE = "COMPLETE_BUSINESS_INVOICE"

export default {
	GET_FINANCE,
	GET_RESTAURANTS_FINANCE_DETAILS,
	GET_BUSINESSES_FINANCE_DETAILS,
	GET_FUNDING_SOURCE,
	ADD_FUNDING_SOURCE,
	TOGGLE_FUNDING_SOURCE_AUTO_PAY,
	ADD_MANUAL_RESTAURANT_FUNDING_SOURCE,
	VERIFY_MANUAL_RESTAURANT_FUNDING_SOURCE,
	REMOVE_FUNDING_SOURCE,
	GET_CURRENT_RESTAURANT_FINANCE_DETAILS,
	GET_CURRENT_BUSINESS_FINANCE_DETAILS,
	CLEAR_CURRENT_FINANCE_DETAILS,
	GET_CURRENT_BUSINESS_INVOICE_HISTORY,
	PAY_BUSINESS_INVOICE,
	DOWNLOAD_INVOICE_USAGE_BREAKDOWN,
	GET_RESTAURANT_PAYOUTS,
	REMOVE_RESTAURANT_PAYOUT,
	ADJUSTED_RESTAURANTS_PAYOUT,
	BATCH_REMOVED_PAYOUTS,
	APPROVE_RESTAURANT_PAYOUT,
	ADJUST_RESTAURANT_PAYOUT_AMOUNT,
	BATCH_APPROVE_RESTAURANT_PAYOUTS,
	CLEAR_RESTAURANT_PAYOUT,
	BATCH_COMPLETE_RESTAURANT_PAYOUTS,
	GET_PAYOUT_ORDERS,
	GET_PAYOUT_EXCEPTIONS,
	ISSUE_PAYOUTS,
	UPDATE_RESTAURANT_PAYOUT,
	GET_BUSINESS_INVOICES,
	ADD_BUSINESS_INVOICE_RECORD,
	APPROVE_BUSINESS_INVOICE,
	BATCH_APPROVE_BUSINESS_INVOICES,
	COMPLETE_BUSINESS_INVOICE
}