import actions from "./actions"
import mutations from "./mutations"


const getters = {}

const state = {
    loading: false,
    updating: false,
    coupons: [], 
    totalCouponsCount: 0,
    voucherRequests: [],
    totalVoucherRequestsCount: 0,
    voucherRequest: {},
    voucherRequestVouchers: [],
    vouchers: [],
    totalVouchersCount: 0
}

export default{
    namespaced: true, 
    state,
    mutations,
    actions,
    getters
};