import { IntegrationType } from "@/enums/integrationType"
import moment from "moment"
import momentHelper from "@/utils/moment-helper"

export default class Integration {
    constructor(type, data) {
        this.type = type
        this.id = data != null && data.id ? data.id : null
        this.isEnabled = this.id != null
        if (this.isEnabled) {
            if (data.restaurant_id) {
                this.restaurant_id = data.restaurant_id
            }
            if (data.external_location_id) {
                this.locationId = data.external_location_id
            } else if (data.external_restaurant_id) {
                this.locationId = data.external_restaurant_id
            }

            if (data.synced_at) {
                let syncedAtMoment = moment.utc(data.synced_at)
                let now = moment()
                this.lastSynced = momentHelper.timeDiff(syncedAtMoment, now)
            }

            if (data.sync_catalog == 1) {
                this.autoSync = data.sync_catalog
            }
            if (data.access_token && type == IntegrationType.REEF) {
                this.secret = data.access_token
            } else if (data.access_token) {
                this.apiKey = data.access_token
            }
            if (data.username != null) {
                this.username = data.username
            }
        } else {
            this.restaurant_id = null
            this.loacationId = null
            this.autoSync = null
            this.lastSynced = null
            this.secret = null
            this.username = null
        }
        
        this.isAvailable = this.isEnabled || (data != null && data.is_active == 1)
        this.requiresOauth = type == IntegrationType.SQUARE
        this.description = ""

        switch (type) {
            case IntegrationType.SQUARE: {
                this.name = "Square"
                this.description = "Connect your Square account with 2ndKitchen seamlessly sync your Square configured menu and receive 2ndKithchen orders directly to your inhouse Square POS"
                break
            }
            case IntegrationType.UNTAPPD: {
                this.name = "Untappd"
                this.description = "Keep your 2ndKitchen 2Go menu always up to date with your UnTappd menu configuration"
                break
            }
            case IntegrationType.TOAST: {
                this.name = "Toast"
                break
            }
            case IntegrationType.CLOVER: {
                this.name = "Clover"
                break
            }
            case IntegrationType.CHOWLY: {
                this.name = "Chowly"
                this.description = "Streamline your orders directly to your very own in house POS and Printer systems (Chowly account required)"
                break
            }
            case IntegrationType.OTTER: {
                this.name = "Otter"
                this.description = "Otter empowers all restaurants to thrive in connecting food and customers. The product is an end-to-end platform that helps businesses succeed in delivery through aggregation, management & optimization, expansion, and partnership. In short, Otter provides superpowers for digital restaurants."
                break
            }
            case IntegrationType.ORDERMARK: {
                this.name ="Ordermark"
                this.description = "Ordermark helps you take charge of your restaurant's online ordering, with powerful tools designed to help your business survive & thrive, now."
                break
            }
            case IntegrationType.REEF: {
                this.name = "Reef"
                this.description = "Connect your restaurant to REEF/NBRHD brands and power nearby hospitality food & beverage programs. Enable menu item syncing, orders/pre-orders, customer support and reporting with a click of a button"
                break
            }
            case IntegrationType.GRUBHUB: {
                this.name = "Grubhub"
                this.description = "Import your menu items from Grubhub"
                break
            }
        }
    }
}