import { ConfirmationModalType } from "@/enums/confirmationModalType"
import { EventBusEvents } from "@/enums/eventBusEvents"
import { NotificationType } from "@/enums/notificationType"
import ActionTypes from "@/store/modules/users/action-types"
import { eventBus } from "@/utils/eventBus"

let businessGuestActionsMixin = {
    methods: {
        openEditBusinessGuestModal(businessGuest) {
			this.$refs.newBusinessGuestModal.open({
				businessGuest: businessGuest
			})
		},
        sendBusinessGuestVoucherClicked(businessGuest) {
			this.$refs.sendBusinessGuestVoucherModal.open({
				businessGuest: businessGuest
			})
		},
        async deleteBusinessGuest(businessGuest) {
			const confirmed = await this.$refs.businessGuestConfirmationModal.open(
				{
					title: `Are you sure you want to remove ${businessGuest.name} from your guest list?`,
					confirmation: "Remove",
					confirmationIcon: "sk-icon-trash-alt-solid",
					cancel: "Cancel",
					type: ConfirmationModalType.RED
				}
			)
			if (confirmed) {
				const resp = await this.$store.dispatch(
					`usersModule/${ActionTypes.DELETE_BUSINESS_GUEST}`,
					businessGuest.id
				)
				if (resp) {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: `Successfully removed ${businessGuest.name} from guest list`
					})
				} else {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Something went wrong, please try again",
						type: NotificationType.WARNING
					})
				}
				return true
			}
			return false
		},
	
		async sendBusinessGuestDigitalMenu(businessGuest) {
			const resp = this.$store.dispatch(
				`usersModule/${ActionTypes.SEND_BUSINESS_GUEST_DIGITAL_MENU}`,
				businessGuest.id
			)
			if (resp) {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: `Successfully sent digital menu to ${businessGuest.name}!`
				})
			} else {
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Something went wrong, please try again",
					type: NotificationType.WARNING
				})
			}
		}
    }
}

export default businessGuestActionsMixin