
<template>
  <div class="sk-row sk-row-bottom">
    <div class="pagination-footer">
      <div class="pages-summary">
        Showing <b>{{pageRangeStart}} - {{pageRangeEnd}}</b> of <b>{{total}}</b> {{contentText}} 
      </div>
      <div class="pages-container">
        <button class="button-page" @click="prevPageClicked" :disabled="canGoBack">
          <i class="sk-icon-chevron-left-regular"></i>
        </button>
        <button
          v-for="(page, i) in pages"
          type="button"
          class="button-page"
          :class="{active: page.name == currentPage, spacer: page.isSpacer}"
          :disabled="page.isDisabled"
          :key="i"
          @click="pageClicked(page.name)">
          {{ page.name }}
        </button>
        <button class="button-page" @click="nextPageClicked" :disabled="canGoForward">
          <i class="sk-icon-chevron-right-regular"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>

.button-page {
  color: var(--sk-grey2);
  border: 0;
  background: none;
  padding: 0 15px;
  cursor: pointer;
  font-size: 14px;
}

.button-page.active {
  color: var(--sk-grey3);
  font-weight: 600;
  opacity: 1 !important;
}

.button-page:disabled {
  opacity: 0.5;
  cursor: default;
}

.button-page.spacer {
  letter-spacing: 12px;
  padding: 0;
  text-indent: 12px;
}

.pagination-footer {
  align-items: center;
  width: 100%;
}

.pages-container,
.pagination-footer {
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
}

.pages-container {
  background: var(--sk-white);
  box-shadow: 0px 1px 4px rgba(136, 136, 136, 0.25);
  border-radius: 5px;
  height: 38px;
  align-items: center;
}

.pages-summary {
  color: var(--sk-grey2);
  font-size: 14px;
}

.pages-summary b {
  color: var(--sk-grey3);
}

@media (max-width: 480px) {

  .pagination-footer {
    flex-direction: column;
    margin-bottom: 10px;
  }

  .pages-summary {
    margin-bottom: 20px;
  }

  .sk-row-bottom {
    justify-content: center;
  }
}

</style>

<script>

export default {
  name: "PaginationFooter",
  data: function() {
    return {
      currentPage: 1
    }
  },
  props: {
    total: {
      type: Number,
      required: true
    },
    pageSize: {
      type: Number,
      required: true
    },
    contentText: {
      type: String,
      default: "Items"
    }
  },
  computed: {
    canGoBack() {
      return this.currentPage == 1
    },
    canGoForward() {
      return this.currentPage == this.totalPages
    },
    pageRangeEnd() {
      if (this.currentPage < this.totalPages) {
        return this.currentPage * this.pageSize
      }
      return this.total
    },
    pageRangeStart() {
      return this.currentPage <= 1 ? 1 : (this.currentPage * this.pageSize) - this.pageSize + 1
    },
    totalPages() {
      return Math.max(parseFloat(Math.ceil((this.total / this.pageSize)).toFixed(0)), 1)
    },
    pages() {
      let atBeginning = this.totalPages > 5 ? this.currentPage <= 5 : true
      let atEnd = this.totalPages > 5 ? this.currentPage > this.totalPages - 5 : true
      let atMiddle =  this.totalPages > 5 ? this.totalPages / 2 == this.currentPage : false

      const pages = [{
        name: "1",
        isDisabled: 1 === this.currentPage
      }]

      if (!atBeginning) {
        pages.push({
          name: "...",
          isDisabled: true,
          isSpacer: true
        })
      }

      if (!atBeginning && !atEnd) {
        for (let i = 0; i < 3; i += 1) {
          let name = this.currentPage - 1 + i
          pages.push({
            name: `${name}`,
            isDisabled: name === this.currentPage
          })
        }
      } else if (atBeginning && atEnd && !atMiddle) {
        for (let i = 2; i < this.totalPages; i += 1) {
          pages.push({
            name: `${i}`,
            isDisabled: i === this.currentPage
          })
        }
      } else if (atBeginning) {
        for (let i = 2; i <= 5; i += 1) {
          pages.push({
            name: `${i}`,
            isDisabled: i === this.currentPage
          })
        }
      } else if (atEnd) {
        let start = this.totalPages - 4
        for (let i = start; i <= this.totalPages - 1; i += 1) {
          pages.push({
            name: `${i}`,
            isDisabled: name === this.currentPage
          })
        }
      }

      if (!atEnd || (atEnd && atMiddle)) {
        pages.push({
          name: "...",
          isDisabled: true,
          isSpacer: true
        })
      }

      if (this.totalPages > 1) {
        pages.push({
          name: `${this.totalPages}`,
          isDisabled: this.totalPages === this.currentPage
        })
      }

      return pages
    }
  },
  methods: {
    nextPageClicked() {
      this.pageChanged(this.currentPage + 1)
    },
    prevPageClicked() {
      this.pageChanged(this.currentPage - 1)
    },
    pageClicked(pageNumber) {
      this.pageChanged(pageNumber)
    },
    pageChanged(pageNumber) {
      if (this.currentPage != pageNumber) {
        this.currentPage = Number(pageNumber)
        this.$emit("pageChanged", {
          page: pageNumber,
          start: this.pageRangeStart - 1,
          end: this.pageRangeEnd
        })
      }
    }
  }
}
</script>