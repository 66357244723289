import { parseResponse } from "@arikgaisler/utils"
import Vue from "vue"
import { store } from "../store/store"

let host = process.env.VUE_APP_API_HOST
const DELIVERY_API = `https://delivery${host}.2ndkitchen.com`

export default {
    async getDelivery(delivery_id) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return parseResponse(Vue.axios.get(`${DELIVERY_API}/deliveries/${delivery_id}`, { 'headers': { 'Authorization': tokenStr } }))
    },
    async createDelivery(payload) {
        const tokenStr = 'Bearer ' + store.state.user_token
        return Vue.axios.post(`${DELIVERY_API}/deliveries`,payload, { 'headers': { 'Authorization': tokenStr } });
    }
}