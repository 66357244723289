
<template>
	<div class="sk-page" v-if="!loading">
		<transition-group :name="transitionName" tag="div" class="page-left">
			<ActivateWidget
				v-if="displayingActivateWidget"
				key="0"
				:userDetails="userDetails"
				@refreshActivation="displayRefreshActivationWidget"
				@toggleLoader="setActivating"
			/>
			<RefreshActivationWidget
				v-else
				key="1"
				@toggleLoader="setActivating"
			/>
		</transition-group>
		<LoginCarousel />
	</div>
</template>

<style scoped>
.sk-page {
	flex-direction: row;
}

.page-left {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>

<script>
import auth from "@/utils/auth"
import loaderMixin from "@/mixins/loader-mixin"
import LoginCarousel from "@/components/login/LoginCarousel.vue"
import ActivateWidget from "@/components/login/ActivateWidget.vue"
import RefreshActivationWidget from "@/components/login/RefreshActivationWidget.vue"

export default {
	name: "Activate",
	mixins: [loaderMixin],
	components: {
		LoginCarousel,
		ActivateWidget,
		RefreshActivationWidget
	},
	data() {
		return {
			loading: false,
			activating: false,
			userDetails: null,
			displayingActivateWidget: true
		}
	},
	created: function () {
		this.checkToken()
	},
	computed: {
		transitionName() {
			return this.displayingActivateWidget ? "widgetexit" : "widgetenter"
		}
	},
	methods: {
		setLoading(loading) {
			this.loading = loading
		},
		setActivating(activating) {
			this.activating = activating
		},
		displayRefreshActivationWidget() {
			this.displayingActivateWidget = false
			this.activating = false
		},
		checkToken() {
			this.loading = true
			const token = this.$route.params.token
			auth.verifyActivationToken(token)
				.then((userDetails) => {
					if (!userDetails) {
						this.displayingActivateWidget = false
					} else {
						this.userDetails = userDetails
					}
				})
				.finally(() => {
					this.loading = false
				})
		}
	},
	watch: {
		loading(newVal, oldVal) {
			if (newVal && !oldVal) {
				this.toggleLoader(true)
			} else if (!newVal && oldVal) {
				this.toggleLoader(false)
			}
		},
		activating(newVal, oldVal) {
			if (newVal && !oldVal) {
				this.toggleLoader(true)
			} else if (!newVal && oldVal) {
				this.toggleLoader(false)
				this.$router.replace(this.$route.query.redirect || "/")
			}
		}
	}
}
</script>