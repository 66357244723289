<template>
	<div class="sk-page-content">
		<PageHeader
			:title="headerTitle"
			:actions="actions"
			:secondaryActions="secondaryActions"
			:tabs="tabs"
			:breadcrumbs="breadcrumbs"
			@actionClicked="headerActionClicked"
			@secondaryActionClicked="headerSecondaryActionClicked"
			@tabClicked="headerTabClicked"
		>
			<SkToggle
				slot="title-decorator"
				:disabled="true"
				:value="restaurant.stock_status"
				@click.native="updateRestaurantStock"
			/>
		</PageHeader>
		<input
			type="file"
			ref="fileInput"
			webkitdirectory
			multiple
			style="display: none"
			@change="batchUploadFilesChanged"
		/>
		<transition name="slide" mode="out-in">
			<RestaurantBasicInfo
				key="0"
				v-if="activeTab.id == 0"
				class="centered-tab-content"
				@save="saveRestaurant"
				@toggleLoader="toggleLoader"
			/>
			<RestaurantProducts
				key="1"
				v-if="activeTab.id == 1"
				ref="restaurantProducts"
				@save="saveRestaurant"
				:selectedIds.sync="batchSelectedProductIds"
			/>
			<RestaurantMenus
				key="2"
				v-if="activeTab.id == 2"
				@openNewMenuModal="openNewMenuModal"
			/>
			<Schedule
				key="3"
				v-if="activeTab.id == 3"
				:hours="openingHours"
				:offset="offset"
				:readonly="!canCreateProduct"
				:timezone="timezone"
				@setHours="setRestaurantHours"
				@save="saveRestaurant"
			/>
			<RestaurantHoursExceptions
				key="4"
				v-if="activeTab.id == 4"
				@openNewHoursExceptionModal="openRestaurantHoursExceptionModal"
			/>
			<RestaurantPOSDevices key="5" v-if="activeTab.id == 5" />
			<RestaurantAdminInfo
				key="6"
				v-if="activeTab.id == 6"
				@save="saveRestaurant"
			/>
			<RestaurantIntegrations key="7" v-if="activeTab.id == 7" />
		</transition>
		<RestaurantMenuSetupModal ref="newMenuModal" />
		<RestaurantHoursExceptionModal ref="restaurantHoursExceptionModal" />
		<SelectExternalMenuModal ref="selectExternalMenuModal" />
		<ConfirmationModal ref="confirmationModal" />
		<BatchAddMenusProductsModal ref="batchAddMenusProductsModal" />
		<BatchEditProductsModal
			ref="batchEditProductsModal"
			@unselectItems="unselectItems"
		/>
		<GrubhubImportProductsModal ref="grubhubImportProductsModal" />
		<RestaurantOutOfStockModal ref="restaurantOutOfStockModal" />
	</div>
</template>

<script>
import { NotificationType } from "@/enums/notificationType"
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { IntegrationType } from "@/enums/integrationType"
import { ConfirmationModalType } from "@/enums/confirmationModalType"
import { PermissionMethod, PermissionTab } from "@/utils/permissions"
import tracker from "@/utils/tracker"
import PageHeader from "@/components/PageHeader.vue"
import loaderMixin from "@/mixins/loader-mixin"
import pluralizeFilterMixin from "@/mixins/pluralize-filter-mixin"
import RestaurantMenus from "@/components/restaurant/RestaurantMenus.vue"
import RestaurantBasicInfo from "@/components/restaurant/RestaurantBasicInfo.vue"
import RestaurantProducts from "@/components/restaurant/RestaurantProducts.vue"
import RestaurantAdminInfo from "@/components/restaurant/RestaurantAdminInfo.vue"
import RestaurantPOSDevices from "@/components/restaurant/RestaurantPOSDevices.vue"
import RestaurantHoursExceptions from "@/components/restaurant/RestaurantHoursExceptions.vue"
import RestaurantIntegrations from "@/components/restaurant/RestaurantIntegrations.vue"
import SkToggle from "@/components/SkToggle.vue"
import Schedule from "@/components/Schedule.vue"
import RestaurantMenuSetupModal from "@/components/modals/restaurants/RestaurantMenuSetupModal.vue"
import RestaurantHoursExceptionModal from "@/components/modals/RestaurantHoursExceptionModal.vue"
import SelectExternalMenuModal from "@/components/modals/SelectExternalMenuModal.vue"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue"
import BatchAddMenusProductsModal from "@/components/modals/BatchAddMenusProductsModal.vue"
import BatchEditProductsModal from "@/components/modals/restaurantProducts/BatchEditProductsModal.vue"
import GrubhubImportProductsModal from "@/components/modals/GrubhubImportProductsModal.vue"
import RestaurantOutOfStockModal from "@/components/modals/restaurants/RestaurantOutOfStockModal.vue"

export default {
	name: "Restaurant",
	mixins: [loaderMixin, pluralizeFilterMixin],
	components: {
		PageHeader,
		RestaurantMenus,
		RestaurantBasicInfo,
		RestaurantProducts,
		RestaurantPOSDevices,
		RestaurantAdminInfo,
		RestaurantHoursExceptions,
		RestaurantIntegrations,
		SkToggle,
		Schedule,
		RestaurantMenuSetupModal,
		RestaurantHoursExceptionModal,
		SelectExternalMenuModal,
		ConfirmationModal,
		BatchAddMenusProductsModal,
		BatchEditProductsModal,
		GrubhubImportProductsModal,
		RestaurantOutOfStockModal
	},
	data() {
		return {
			didMount: false,
			activeTabId: 0,
			batchSelectedProductIds: []
		}
	},
	mounted: async function () {
		this.toggleLoader(true)
		if (
			this.$route.params.rest_slug != undefined &&
			this.$route.params.rest_slug != ""
		) {
			await this.$store.dispatch(
				"restaurantsModule/getRestaurant",
				this.$route.params.rest_slug
			)
			if (this.$route.query.tabId != undefined) {
				const tabId = parseFloat(this.$route.query.tabId)
				const tab = this.tabs.find((tab) => tab.id == tabId)
				if (tab) {
					this.headerTabClicked(tab)
					this.$router.replace({ query: null })
				}
			}
		} else {
			this.$store.dispatch("restaurantsModule/clearRestaurant")
		}
		this.didMount = true
	},
	methods: {
		unselectItems() {
			this.batchSelectedProductIds = []
		},
		async batchUploadFilesChanged(event) {
			try {
				const files = Object.values(event.target.files)
				if (!files) {
					return
				}

				const menuIds =
					await this.$refs.batchAddMenusProductsModal.open()

				await this.$store.dispatch(
					"restaurantsModule/batchUploadProducts",
					{
						files: files,
						menuIds: menuIds
					}
				)
				this.$refs.fileInput.value = ""
				return true
			} catch (e) {
				this.$refs.fileInput.value = ""
				return false
			}
		},
		async exportProductsClicked() {
			const confirmed = await this.$refs.confirmationModal.open({
				title: "Would you also like to export product photos?",
				confirmation: "Export Photos",
				confirmationIcon: "sk-icon-check-regular",
				cancel: "Export CSV"
			})
			this.$refs.restaurantProducts.exportProducts(confirmed)
		},
		async importExternalMenuClicked() {
			try {
				this.toggleLoader(true)
				const untappdIntegration =
					this.$store.state.restaurantsModule.integrations.find(
						(integration) =>
							integration.type == IntegrationType.UNTAPPD
					)
				if (untappdIntegration && untappdIntegration.isEnabled) {
					const menus = await this.$store.dispatch(
						"restaurantsModule/getExternalMenus",
						this.restaurant.id
					)
					this.toggleLoader(false)
					const response =
						await this.$refs.selectExternalMenuModal.open(menus)
					this.toggleLoader(true)
					if (
						response != null &&
						response.externalMenus &&
						response.menu
					) {
						const menuIds = response.externalMenus.map(
							(i) => `${i.id}`
						)
						const payload = {
							externalMenuIds: menuIds,
							menuId: response.menu.id
						}
						const success = await this.$store.dispatch(
							"restaurantsModule/importExternalMenu",
							payload
						)
						if (success) {
							eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
								title: "Untappd Import",
								message:
									"Your import request is being processed!"
							})
						} else {
							eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
								title: "Untappd Import",
								message: "Something went wrong, try again.",
								type: NotificationType.ERROR
							})
						}
					}
				} else {
					this.promptIntegrationRedirect(
						"UnTapped Integration",
						"An Untappd integration was not found, please visit the integrations tab to connect Untappd."
					)
				}
				this.toggleLoader(false)
			} catch (e) {
				this.toggleLoader(false)
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Untappd Import",
					message: "Something went wrong, try again.",
					type: NotificationType.ERROR
				})
				if (e) {
					throw e
				}
			}
		},
		async importSquareCatalog() {
			try {
				this.toggleLoader(true)
				await this.$store.dispatch(
					"restaurantsModule/getRestaurantIntegrations"
				)
				const squareIntegration =
					this.$store.state.restaurantsModule.integrations.find(
						(integration) =>
							integration.type == IntegrationType.SQUARE
					)
				if (squareIntegration && squareIntegration.isEnabled) {
					const success = await this.$store.dispatch(
						"restaurantsModule/importIntegrationCatalog",
						squareIntegration.id
					)
					if (success) {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Square import",
							message: "Square import complete"
						})
					} else {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Square import",
							message: "Something went wrong, try again.",
							type: NotificationType.ERROR
						})
					}
				} else {
					this.promptIntegrationRedirect(
						"Square Integration",
						"A Square integration was not found, please visit the integrations tab to connect Square."
					)
				}
				this.toggleLoader(false)
			} catch (e) {
				this.toggleLoader(false)
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Square import",
					message: "Something went wrong, try again.",
					type: NotificationType.ERROR
				})
				if (e) {
					throw e
				}
			}
		},
		async importChowlyCatalog() {
			try {
				this.toggleLoader(true)
				const chowlyIntegration =
					this.$store.state.restaurantsModule.integrations.find(
						(integration) =>
							integration.type == IntegrationType.CHOWLY
					)
				if (chowlyIntegration && chowlyIntegration.isEnabled) {
					const success = await this.$store.dispatch(
						"restaurantsModule/importIntegrationCatalog",
						chowlyIntegration.id
					)
					if (success) {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Chowly import",
							message: "Chwoly import complete"
						})
					} else {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Chowly import",
							message: "Something went wrong, try again.",
							type: NotificationType.ERROR
						})
					}
				} else {
					this.promptIntegrationRedirect(
						"Chowly Integration",
						"A Chowly integration was not found, please visit the integrations tab to connect Square."
					)
				}
				this.toggleLoader(false)
			} catch (e) {
				this.toggleLoader(false)
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Chowly import",
					message: "Something went wrong, try again.",
					type: NotificationType.ERROR
				})
				if (e) {
					throw e
				}
			}
		},
		async importReefCatalog() {
			try {
				this.toggleLoader(true)
				const reefIntegration =
					this.$store.state.restaurantsModule.integrations.find(
						(integration) =>
							integration.type == IntegrationType.REEF
					)
				if (reefIntegration && reefIntegration.isEnabled) {
					const success = await this.$store.dispatch(
						"restaurantsModule/importIntegrationCatalog",
						reefIntegration.id
					)
					if (success) {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Reef import",
							message: "Reef import complete"
						})
					} else {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Reef import",
							message: "Something went wrong, try again.",
							type: NotificationType.ERROR
						})
					}
				} else {
					this.promptIntegrationRedirect(
						"Reef Integration",
						"A Reef integration was not found, please visit the integrations tab to connect Reef."
					)
				}
				this.toggleLoader(false)
			} catch (e) {
				this.toggleLoader(false)
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Reef import",
					message: "Something went wrong, try again.",
					type: NotificationType.ERROR
				})
				if (e) {
					throw e
				}
			}
		},
		async importGrubhubCatalog() {
			try {
				const productIds =
					await this.$refs.grubhubImportProductsModal.open()
				if (productIds.length > 0) {
					this.toggleLoader(true)
					const grubhubIntegration =
						this.$store.state.restaurantsModule.integrations.find(
							(integration) =>
								integration.type == IntegrationType.GRUBHUB
						)
					const success = await this.$store.dispatch(
						"restaurantsModule/importIntegrationProducts",
						{
							id: grubhubIntegration.id,
							productIds: productIds
						}
					)
					if (success) {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Grubhub import",
							message: "Grubhub import complete"
						})
					} else {
						eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
							title: "Grubhub import",
							message: "Something went wrong, try again.",
							type: NotificationType.ERROR
						})
					}
					this.toggleLoader(false)
				}
			} catch (e) {
				this.toggleLoader(false)
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Grubhub import",
					message: "Something went wrong, try again.",
					type: NotificationType.ERROR
				})
				if (e) {
					throw e
				}
			}
		},
		async promptIntegrationRedirect(title, body) {
			const confirmed = await this.$refs.confirmationModal.open({
				title: title,
				body: body,
				confirmation: "View Integrations",
				cancel: "Cancel"
			})
			if (confirmed) {
				const integrationTab = this.tabs.find(tab => tab.id == 7)
				if (integrationTab) {
					this.headerTabClicked(integrationTab)
				}
			}
		},
		headerActionClicked(action) {
			switch (action.id) {
				case 0: {
					this.$refs.restaurantProducts.newItem()
					tracker.trackEvent("New Menu Item Clicked")
					break
				}
				case 1: {
					tracker.trackEvent("New Menu Clicked")
					this.openNewMenuModal()
					break
				}
				case 2: {
					tracker.trackEvent("New Hours Exception Modal Clicked")
					this.openRestaurantHoursExceptionModal()
					break
				}
				case 3: {
					tracker.trackEvent("Cancel Batch Edit Products Clicked")
					this.$refs.restaurantProducts.cleanUpBatchIds()
					break
				}
				case 4: {
					this.handleBatchProductsActionClicked(action)
					break
				}
			}
		},
		headerSecondaryActionClicked(action) {
			tracker.trackEvent("Restaurant Menu Action Clicked", {
				action: action.text
			})
			switch (action.id) {
				case 0: {
					this.importExternalMenuClicked()
					break
				}
				case 1: {
					this.importSquareCatalog()
					break
				}
				case 2: {
					this.$refs.fileInput.click()
					break
				}
				case 3: {
					this.exportProductsClicked()
					break
				}
				case 4: {
					this.importChowlyCatalog()
					break
				}
				case 5: {
					this.importGrubhubCatalog()
					break
				}
				case 6: {
					this.importReefCatalog()
					break
				}
			}
		},
		async handleBatchProductsActionClicked(action) {
			switch (action.id) {
				case 4: {
					switch (action.optionId) {
						case 0: {
							tracker.trackEvent("Batch Edit Products Clicked")
							this.$refs.batchEditProductsModal.open(
								this.batchSelectedProductIds
							)
							break
						}
						case 1: {
							const productsCount =
								this.batchSelectedProductIds.length
							const titleTxt = this.$options.filters.pluralize(
								productsCount,
								"Product"
							)
							const confirmed =
								await this.$refs.confirmationModal.open({
									title: `Delete ${productsCount} ${titleTxt}?`,
									confirmation: "Delete",
									confirmationIcon: "sk-icon-check-alt-solid",
									cancel: "Cancel",
									type: ConfirmationModalType.RED
								})
							if (confirmed) {
								this.toggleLoader(true)
								const success = await this.$store.dispatch(
									"restaurantsModule/batchDeleteProducts",
									this.batchSelectedProductIds
								)
								if (success) {
									eventBus.emit(
										EventBusEvents.SHOW_NOTIFICATION,
										{
											title: "Products",
											message: `${productsCount} products successfully deleted`
										}
									)
									this.$refs.restaurantProducts.cleanUpBatchIds()
								} else {
									eventBus.emit(
										EventBusEvents.SHOW_NOTIFICATION,
										{
											title: "Products",
											message:
												"Failed to delete products",
											type: NotificationType.ERROR
										}
									)
								}
								this.toggleLoader(false)
							}
							break
						}
					}
					break
				}
			}
		},
		openNewMenuModal() {
			this.$refs.newMenuModal.open()
		},
		openRestaurantHoursExceptionModal(exception) {
			this.$refs.restaurantHoursExceptionModal.open(exception)
		},
		headerTabClicked(tabClicked) {
			this.activeTabId = tabClicked.id
			if (this.$refs.restaurantProducts) {
				this.$refs.restaurantProducts.cleanUpBatchIds()
			} else {
				this.batchSelectedProductIds = []
			}
			tracker.trackEvent("Restaurant Tab Clicked", {
				tab_name: tabClicked.text
			})
		},
		async saveRestaurant() {
			try {
				tracker.trackEvent("Restaurant Save Clicked")
				this.toggleLoader(true)
				const restaurantValidation = this.validateRestaurant()
				if (!restaurantValidation.valid) {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: restaurantValidation.reason,
						type: NotificationType.ERROR
					})
					this.toggleLoader(false)
					return false
				}
				if (this.$route.params.rest_slug != undefined) {
					await this.$store.dispatch(
						"restaurantsModule/updateRestaurant",
						this.restaurant
					)
				} else {
					const added = await this.$store.dispatch(
						"addRestaurant",
						this.restaurant
					)
					if (added) {
						this.$router.push({ path: "/restaurants" })
					}
				}
				this.toggleLoader(false)
			} catch (e) {
				console.error(e)
				this.toggleLoader(false)
			}
		},
		validateRestaurant() {
			let reason = null
			if (
				this.restaurant.address == "" ||
				this.restaurant.address == undefined
			) {
				reason = "Address is empty"
			} else if (
				this.restaurant.city == "" ||
				this.restaurant.city == undefined
			) {
				reason = "City is empty"
			} else if (
				this.restaurant.state == "" ||
				this.restaurant.state == undefined
			) {
				reason = "State is empty"
			} else if (
				this.restaurant.zip == "" ||
				this.restaurant.zip == undefined
			) {
				reason = "Zipcode is empty"
			} else if (
				this.restaurant.slug == "" ||
				this.restaurant.slug == undefined
			) {
				reason = "Restaurant slug is empty"
			}
			return {
				valid: !reason,
				reason: reason
			}
		},
		async updateRestaurantStock() {
			const stockStatus = !this.restaurant.stock_status
			if (!stockStatus) {
				const confirmedData =
					await this.$refs.restaurantOutOfStockModal.open()
				if (confirmedData) {
					this.$store.dispatch(
						"restaurantsModule/updateRestaurantStock",
						{
							status: stockStatus,
							minutes: confirmedData.minutes
						}
					)
				}
			} else {
				this.$store.dispatch(
					"restaurantsModule/updateRestaurantStock",
					{
						status: stockStatus
					}
				)
			}
		},
		setRestaurantHours(hours) {
			this.$store.dispatch("restaurantsModule/updateRestaurantHours", {
				hours: hours
			})
		}
	},
	computed: {
		canCreateProduct() {
			return this.$store.state.permissions.includes(
				PermissionMethod.NEW_RESTAURANT_ITEM
			)
		},
		canImportProducts() {
			return this.$store.state.permissions.includes(
				PermissionMethod.IMPORT_RESTAURANT_ITEM
			)
		},
		tabs() {
			let allowedTabs = [
				{
					id: 0,
					text: "Details"
				},
				{
					id: 3,
					text: "Hours of Operation"
				}
			]
			if (
				this.$store.state.permissions.includes(
					PermissionTab.RESTAURANT_ADMIN
				)
			) {
				allowedTabs.push({
					id: 6,
					text: "Admin"
				})
			}

			if (!this.newRestaurant) {
				allowedTabs = allowedTabs.concat([
					{
						id: 4,
						text: "Special Closures",
						overflowTab: true
					},
					{
						id: 1,
						text: "Products"
					},
					{
						id: 2,
						text: "Menus"
					},
					{
						id: 5,
						text: "Devices",
						overflowTab: true
					},
					{
						id: 7,
						text: "Integrations",
						overflowTab: true
					}
				])
			}
			return allowedTabs
				.map((tab) => {
					tab.active = tab.id == this.activeTabId
					return tab
				})
				.sort((a, b) => a.id - b.id)
		},
		actions() {
			switch (this.activeTab.id) {
				case 1: {
					const canBatchEditProducts =
						this.$store.state.permissions.includes(
							PermissionMethod.BATCH_UPDATE_RESTAURANT_ITEMS
						)
					if (
						canBatchEditProducts &&
						this.batchSelectedProductIds.length > 0
					) {
						const productsText = this.$options.filters.pluralize(
							this.batchSelectedProductIds.length,
							"Product"
						)
						return [
							{
								id: 3,
								text: `Cancel`,
								classes: "button button-grey"
							},
							{
								id: 4,
								text: `Update ${this.batchSelectedProductIds.length} ${productsText} <i class='sk-icon-sort-down-solid'></i>`,
								classes:
									"button button-primary button-icon-right",
								options: [
									{
										id: 0,
										text: "<i class='sk-icon-pencil-solid'></i> Edit Details"
									},
									{
										id: 1,
										text: "<i class='sk-icon-times-regular'></i> Delete"
									}
								]
							}
						]
					} else if (this.canCreateProduct) {
						return [
							{
								id: 0,
								text: `<i class="sk-icon-plus-regular"></i> New Product`,
								classes: "button button-primary"
							}
						]
					}
					break
				}
				case 2: {
					if (this.canCreateMenu) {
						return [
							{
								id: 1,
								text: `<i class="sk-icon-plus-regular"></i> New Menu`,
								classes: "button button-primary"
							}
						]
					}
					break
				}
				case 4: {
					return [
						{
							id: 2,
							text: `<i class="sk-icon-plus-regular"></i> New Closure Hours`,
							classes: "button button-primary"
						}
					]
				}
			}
			return []
		},
		secondaryActions() {
			switch (this.activeTab.id) {
				case 1: {
					let retVal = []

					if (
						this.canImportProducts &&
						this.batchSelectedProductIds.length == 0
					) {
						const untappdIntegration =
							this.$store.state.restaurantsModule.integrations.find(
								(integration) =>
									integration.type == IntegrationType.UNTAPPD
							)
						if (
							untappdIntegration &&
							untappdIntegration.isEnabled
						) {
							retVal.push({
								id: 0,
								text: `Import from UnTappd`
							})
						}
						const squareIntegration =
							this.$store.state.restaurantsModule.integrations.find(
								(integration) =>
									integration.type == IntegrationType.SQUARE
							)
						if (squareIntegration && squareIntegration.isEnabled) {
							retVal.push({
								id: 1,
								text: `Import from Square`
							})
						}
						const chowlyIntegration =
							this.$store.state.restaurantsModule.integrations.find(
								(integration) =>
									integration.type == IntegrationType.CHOWLY
							)
						if (chowlyIntegration && chowlyIntegration.isEnabled) {
							retVal.push({
								id: 4,
								text: `Import from Chowly`
							})
						}
						const grubhubIntegration =
							this.$store.state.restaurantsModule.integrations.find(
								(integration) =>
									integration.type == IntegrationType.GRUBHUB
							)
						if (
							grubhubIntegration &&
							grubhubIntegration.isEnabled
						) {
							retVal.push({
								id: 5,
								text: `Import from Grubhub`
							})
						}
						const reefIntegration =
							this.$store.state.restaurantsModule.integrations.find(
								(integration) =>
									integration.type == IntegrationType.REEF
							)
						if (
							reefIntegration &&
							reefIntegration.isEnabled
						) {
							retVal.push({
								id: 6,
								text: `Import from Reef`
							})
						}
						retVal = retVal.concat([
							{
								id: 2,
								text: `Batch Upload`
							},
							{
								id: 3,
								text: `Export Products`
							}
						])
						return retVal
					}
					break
				}
			}
			return null
		},
		activeTab() {
			return this.tabs.find((tab) => tab.active)
		},
		loading() {
			return this.$store.state.restaurantsModule.loading || !this.didMount
		},
		canCreateMenu() {
			return this.$store.state.permissions.includes(
				PermissionMethod.NEW_RESTAURANT_MENU
			)
		},
		restaurant() {
			return this.$store.state.restaurantsModule.currentRestaurant
		},
		headerTitle() {
			if (this.newRestaurant) {
				return "New Restaurant"
			} else {
				return this.$store.state.restaurantsModule.currentRestaurant
					.name
					? this.$store.state.restaurantsModule.currentRestaurant.name
					: "..."
			}
		},
		newRestaurant() {
			return this.$route.params.rest_slug != undefined ? false : true
		},
		offset() {
			return this.$store.getters["restaurantsModule/offset"]
		},
		timezone() {
			return this.$store.getters["restaurantsModule/timezone"]
		},
		openingHours() {
			return this.restaurant.opening_hours
		},
		breadcrumbs() {
			return [
				{
					name: "Restaurants",
					link: "/restaurants"
				},
				{
					name: this.headerTitle
				}
			]
		}
	},
	watch: {
		loading: function (newVal) {
			this.toggleLoader(newVal)
		}
	}
}
</script>
