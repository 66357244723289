<template>
	<SkBaseModal
		class="sk-modal-alt"
		:isOpen="isOpen"
		:hasLoader="true"
		@close="cancel"
	>
		<loading :active.sync="loading" :is-full-page="false" />
		<div class="sk-modal-alt-title">
			<h1>Grubhub Import Products</h1>
		</div>
		<div class="sk-row">
			<div class="search-input">
				<i class="sk-icon-search-regular"></i>
				<input
					class="sk-input"
					placeholder="Search by Product Name"
					v-model="search"
				/>
			</div>
		</div>
		<div class="sk-row">
			<div class="sk-switch-label-wrapper" @click="selectAll">
				<SkCheckbox :checked="allSelected" />
				<span>Select All</span>
			</div>
		</div>
		<transition-group
			tag="div"
			class="product-rows dark-scrollbar"
			name="list"
			appear
		>
			<div
				class="sk-row sk-widget-alt product-row"
				v-for="product in products"
				:key="product.id"
				@click="productSelected(product)"
			>
				<SkCheckbox :checked="productIds.includes(product.id)" />
				<div class="product-name">{{ product.name }}</div>
			</div>
		</transition-group>
		<div class="sk-modal-actions">
			<button class="button button-text" @click="cancel(null)">
				Not Now
			</button>
			<button class="button button-primary" @click="confirm(productIds)">
				<i class="sk-icon-check-regular"></i>
				Import Products
			</button>
		</div>
	</SkBaseModal>
</template>

<style scoped>
.search-input {
	position: relative;
}

.search-input i {
	position: absolute;
	left: 15px;
	top: 17.5px;
	color: var(--sk-grey2);
}

.search-input .sk-input {
	padding-left: 60px;
}

.sk-switch-label-wrapper {
	padding-left: 20px;
	font-weight: 600;
	font-size: 12px;
	color: var(--sk-dark-navy);
}

.sk-switch-label-wrapper .sk-checkbox {
	margin-right: 20px;
}

.product-rows {
	position: relative;
	margin: 0;
	height: 330px;
	overflow-y: auto;
	overflow-x: hidden;
	margin: 0 -5px 20px -5px;
	padding: 5px 5px 0 5px;
}

.product-row {
	display: flex;
	align-items: center;
	margin-left: 0;
	width: 100%;
}

.product-row:first-child {
	margin-top: 0;
}

.product-row >>> .sk-checkbox {
	margin-right: 20px;
}

.product-row .product-name {
	flex: 1;
}

.product-row div {
	font-size: 14px;
	font-weight: 600;
}
</style>

<script>
import { IntegrationType } from "@/enums/integrationType"
import utils from "@/utils/utils"
import restaurant_service from "@/services/restaurants"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import skPromiseModalMixin from "@/mixins/modals/sk-promise-modal-mixin"
import SkCheckbox from "@/components/SkCheckbox.vue"
import Loading from "vue-loading-overlay"

export default {
	name: "GrubhubImportProductsModal",
	mixins: [skPromiseModalMixin],
	components: {
		SkBaseModal,
		SkCheckbox,
		Loading
	},
	data() {
		return {
			loading: false,
			productResponse: [],
			productIds: [],
			search: ""
		}
	},
	computed: {
		allSelected() {
			return (
				!this.loading &&
				this.productResponse.length > 0 &&
				this.productIds.length == this.productResponse.length
			)
		},
		products() {
			let regex = null
			if (this.search.length > 0) {
				const term = utils.sanitize(this.search)
				regex = utils.stringToRegex(term)
			}

			return this.productResponse
				.filter((product) => {
					if (!regex) {
						return true
					}

					if (product.name && product.name.match(regex) !== null) {
						return true
					}

					if (this.productIds.includes(product.id)) {
						return true
					}
					return false
				})
				.sort((a, b) => {
					if (this.allSelected) {
						return a.name - b.name
					}
					if (this.productIds.includes(a.id)) {
						return -1
					}
					if (this.productIds.includes(b.id)) {
						return 1
					}

					if (regex) {
						const aIndex = a.name.indexOf(a.name.match(regex)[0])
						const bIndex = b.name.indexOf(b.name.match(regex)[0])
						if (aIndex < bIndex) {
							return -1
						}
						if (aIndex > bIndex) {
							return 1
						}
					} else {
						return a.name - b.name
					}

					return 0
				})
		}
	},
	methods: {
		selectAll() {
			if (!this.allSelected) {
				this.productIds = this.productResponse.map(
					(product) => product.id
				)
			} else {
				this.productIds = []
			}
		},
		async opened() {
			try {
				this.loading = true
				this.productIds = []
				this.productResponse = []
				const grubhubIntegration =
					this.$store.state.restaurantsModule.integrations.find(
						(integration) =>
							integration.type == IntegrationType.GRUBHUB
					)
				if (grubhubIntegration) {
					const catalogResponse =
						await restaurant_service.getGrubhubRestaurantCatalog(
							grubhubIntegration.locationId
						)
					if (
						catalogResponse.status == 200 &&
						catalogResponse.products
					) {
						this.productResponse = catalogResponse.products
					} else {
						throw `API Error: ${catalogResponse.status}`
					}
				}
				this.loading = false
			} catch (e) {
				this.loading = false
				throw e
			}
		},
		productSelected(product) {
			if (!this.productIds.includes(product.id)) {
				this.productIds.push(product.id)
			} else {
				this.productIds = this.productIds.filter(
					(id) => product.id != id
				)
			}
		}
	}
}
</script>