import actions from "./actions"
import mutations from "./mutations"

const state = {
    hubs: [],
    currentHub: null,
    currentHubRestaurantMenus: [],
    currentHubBusinesses: [],
    menuTimeRanges: {
        breakfast: [700, 800, 900, 1000],
        lunch: [1100, 1200, 1300, 1400, 1500],
        dinner: [1600, 1700, 1800, 1900, 2000],
        latenight: [
            2100, 2200, 2300, 2400, 0, 100, 200, 300, 400, 500, 600
        ]
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
