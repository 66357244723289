import { MenuType } from "@arikgaisler/utils/enums/menuType"

let menuTypeFormatterMixin = {
    filters: {
		menuTypeText: function (menuType) {
            switch (menuType) {
				case MenuType.DEFAULT:
					return "Default"
				case MenuType.POPUP:
					return "Popup"
				case MenuType.EMBEDDED:
					return "In Room Tablet"
				case MenuType.ECOMMERCE:
					return "2GO"
				case MenuType.DONATION:
					return "Donation"
				case MenuType.PRE_PACKAGED:
					return "Catering"
				case MenuType.ESSENTIALS:
					return "Essentials"
			}
			return "N/A"
		}
	}
}

export default menuTypeFormatterMixin