<template>
	<div class="top-exceptions-widget vld-parent">
		<loading :active.sync="loading" :is-full-page="false"></loading>
		<InsightBaseWidget
			:title="'Top Exceptions'"
		>
			<div class="top-exceptions-content" slot="content">
				<SkChart
					v-if="!loading"
					class="exceptions-chart"
					:type="'Pie'"
					:data="chartData"
					:options="chartOptions"
					:eventHandlers="eventHandlers"
					:tooltip="true"
				/>
				<div class="exception-labels">
					<div
						class="exception-label"
						v-for="exception in exceptionsData"
						:key="exception.reason_id"
					>
						<div
							class="series-color"
							:style="{ background: exception.color }"
						></div>
						<div>
							<b>{{ exception.reasonText }}</b>
							{{ exception.percentage }}%
						</div>
					</div>
				</div>
			</div>
		</InsightBaseWidget>
	</div>
</template>

<style scoped>
.top-exceptions-widget >>> .sk-widget-alt {
	height: 100%;
}

.top-exceptions-content {
	display: flex;
	justify-content: center;
	padding: 40px 0;
	position: relative;
	min-height: 255px;
}

.top-exceptions-content > * {
	display: flex;
}

.exceptions-chart {
	width: 57%;
	justify-content: center;
}

.exception-labels {
	width: 43%;
	height: 100%;
	border-left: 1px solid var(--sk-grey);
	padding-left: 50px;
	flex-direction: column;
}

.exception-label {
	display: flex;
	align-items: center;
	font-size: 12px;
	color: var(--sk-dark-navy);
}

.series-color {
	width: 10px;
	height: 10px;
	border-radius: 2px;
	margin-right: 10px;
	background: var(--sk-orange);
	flex-shrink: 0;
}

.ct-series:first-child .ct-slice-donut-solid {
	stroke: var(--sk-grey);
}
</style>

<script>
import chartist from "chartist"
import InsightBaseWidget from "@/components/insights/InsightBaseWidget.vue"
import Loading from "vue-loading-overlay"
import SkChart from "@/components/SkChart.vue"

export default {
	name: "RestaurantTopExceptionsChart",
	components: {
		InsightBaseWidget,
		Loading,
		SkChart
	},
	data: function () {
		return {
			colors: [
				"#FF7000",
				"#2E3C49",
				"#FF8525",
				"#2F4858",
				"#B0B3B6",
				"#70787C",
				"#EEEEEE",
				"#FFA800",
				"#FFE4AF",
				"##FDA764",
				"#C50000",
				"#8B0011",
				"#C8ED9B",
				"#519400"
			],
			chartOptions: {
				height: "175px",
				width: "175px",
				donut: true,
				donutWidth: 20,
				startAngle: 0,
				total: 100,
				chartPadding: 0,
				showLabel: false,
				transformTooltipText: (value) => {
					return `${value}%`
				}
			},
			eventHandlers: [
				{
					event: "draw",
					fn(event) {
						if (event.type === "slice") {
							let pathLength = event.element._node.getTotalLength()

							event.element.attr({
								"stroke-dasharray":
									pathLength + "px " + pathLength + "px",
								stroke: event.series.color
							})

							let animationDefinition = {
								"stroke-dashoffset": {
									id: "anim" + event.index,
									dur: 1000,
									from: -pathLength + "px",
									to: "0px",
									easing: chartist.Svg.Easing.easeOutQuint,
									fill: "freeze"
								}
							}
							event.element.attr({
								"stroke-dashoffset": -pathLength + "px"
							})
							event.element.animate(animationDefinition, false)
						}
					}
				}
			]
		}
	},
	computed: {
		loading() {
			return this.$store.state.insightsModule.topExceptions.loading
		},
		exceptionsData() {
			const data = this.$store.state.insightsModule.topExceptions.data
			if (data) {
				const exceptionTypeMap = this.$store.getters[
					"ordersModule/exceptionTypeMap"
				]
				const total = data.reduce((total, exception) => {
					total += exception.exceptions
					return total
				}, 0)
				let exceptions = []
				data.forEach((exception, i) => {
					exceptions.push({
						reasonId: exception.reason_id,
						count: exception.exceptions,
						percentage: ((exception.exceptions / total) * 100)
							.toFixed(2)
							.replace(/[.,]00$/, ""),
						reasonText: exceptionTypeMap[exception.reason_id],
						color: this.colors[i]
					})
				})
				return exceptions
			}
			return []
		},
		chartData() {
			const series = this.exceptionsData.map((exception) => {
				return {
					meta: exception.reasonText,
					color: exception.color,
					value: exception.percentage,
					className: "ct-insights-donut"
				}
			})
			return {
				series: series
			}
		}
	}
}
</script>