<template>
	<SkBaseModal
		class="sk-modal-alt"
		:isOpen="isOpen"
		:hasLoader="true"
		@close="close"
	>
		<loading :active.sync="loading" :is-full-page="false" />
		<button class="button button-icon button-close" @click="close">
			<i class="sk-icon-times-regular"></i>
		</button>
		<div class="sk-modal-alt-title">
			<h1>Add products to business menus?</h1>
		</div>
		<div class="sk-modal-description">
			Would you like to also add those products to these business menus?
		</div>
		<div>
			<div
				class="sk-switch-label-wrapper sk-modal-select-all"
				@click="selectAll"
			>
				<SkCheckbox :checked="allSelected" />
				<span class="sk-modal-select-all-label">Select All</span>
			</div>
			<div
				v-for="menu in businessMenusToSync"
				:key="`${menu.businessId}-${menu.menu.id}`"
				class="sk-switch-label-wrapper"
			>
				<SkCheckbox
					@input="
						toggleBusinessMenuId(
							`${menu.businessId}-${menu.menu.id}`
						)
					"
					:value="
						businessMenuIds.includes(
							`${menu.businessId}-${menu.menu.id}`
						)
					"
				/>
				<span>{{ menu.businessName }} - {{ menu.menuName }}</span>
			</div>
		</div>
		<div class="sk-modal-actions">
			<button class="button button-text" @click="close">Not Now</button>
			<button class="button button-primary" @click="addProducts">
				<i class="sk-icon-check-regular"></i>
				Add Products
			</button>
		</div>
	</SkBaseModal>
</template>

<script>
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import SkCheckbox from "@/components/SkCheckbox.vue"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import Loading from "vue-loading-overlay"

export default {
	name: "SyncBusinessMenusProductsModal",
	mixins: [skModalMixin],
	components: {
		SkCheckbox,
		SkBaseModal,
		Loading
	},
	data: function () {
		return {
			loading: false,
			restaurantMenuIds: null,
			businessMenusToSync: [],
			businessMenuIds: [],
			productIds: []
		}
	},
	computed: {
		allSelected() {
			return (
				!this.loading &&
				this.businessMenusToSync.length == this.businessMenuIds.length
			)
		}
	},
	methods: {
		selectAll() {
			if (this.allSelected) {
				this.businessMenuIds = []
			} else {
				this.businessMenuIds = this.businessMenusToSync.map(
					(businessMenu) =>
						`${businessMenu.businessId}-${businessMenu.menu.id}`
				)
			}
		},
		toggleBusinessMenuId(id) {
			const index = this.businessMenuIds.indexOf(id)
			if (index > -1) {
				this.businessMenuIds.splice(index, 1)
			} else {
				this.businessMenuIds.push(id)
			}
		},
		handleOptions(options) {
			this.productIds = options.productIds
			this.restaurantMenuIds = options.restaurantMenuIds
			this.businessMenusToSync = options.businessMenusToSync
		},
		closed() {
			this.loading = false
			this.restaurantMenuIds = null
			this.buinessIds = []
			this.productIds = []
		},
		async addProducts() {
			try {
				this.loading = true

				const promises = []
				this.restaurantMenuIds.forEach((restaurantMenuId) => {
					let products =
						this.$store.state.restaurantsModule.restaurantMenuProducts[
							restaurantMenuId
						].filter((product) =>
							this.productIds.includes(product.id)
						)
					this.$store.state.restaurantsModule.restaurantBusinesses
						.filter((business) =>
							this.businessMenuIds.includes(
								`${business.id}-${restaurantMenuId}`
							)
						)
						.forEach((business) => {
							let menu = business.menus.find(
								(menu) => menu.id == restaurantMenuId
							)
							if (menu && menu.products) {
								menu.products = menu.products.concat(products)
							} else {
								this.$set(menu, "products", products)
							}
							promises.push(
								this.$store.dispatch(
									"businessesModule/updateBusinessMenu",
									{
										menu: menu,
										business: business
									}
								)
							)
						})
				})

				const responses = await Promise.all(promises)
				this.loading = false
				const success = responses.every((response) => response == true)
				const partialSuccess = responses.some(
					(response) => response == true
				)
				if (success) {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Successfully added products to the business menus"
					})
					this.close()
				} else if (partialSuccess) {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Only some products were added to the business menus",
						type: NotificationType.WARNING
					})
					this.close()
				} else {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Failed to add products to business menus, please try again",
						type: NotificationType.ERROR
					})
				}
			} catch (e) {
				this.loading = false
				eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
					title: "Failed to add products to business menus, please try again",
					type: NotificationType.ERROR
				})
				throw e
			}
		}
	}
}
</script>