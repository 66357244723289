import Vue from "vue"
import utils from "@/utils/utils"

const vue = new Vue()
let _autocompleteService = null
let _placesService = null

export default {
	parseAddressComponents(addressComponents) {
		const data = {
			address: "",
			city: "",
			state: "",
			zip: ""
		}
		if (addressComponents) {
			for (const component of addressComponents) {
				const componentType = component.types[0]

				switch (componentType) {
					case "street_number": {
						data.address = `${component.long_name} ${data.address}`
						break
					}
					case "route": {
						data.address += component.short_name
						break
					}
					case "postal_code": {
						data.zip = `${component.long_name}${data.zip}`
						break
					}
					case "locality":
					case "sublocality":
					case "sublocality_level_1":
					case "sublocality_level_2":
					case "sublocality_level_3":
					case "sublocality_level_4":
					case "postal_town": {
						data.city = component.long_name
						break
					}
					case "administrative_area_level_1": {
						data.state = component.short_name
						break
					}
				}
			}
		}
		return data
	},
	async getPlaceDetails(placeId, fields) {
		try {
			if (!placeId) {
				return null
			}

			if (Vue.$gmapApiPromiseLazy) {
				await Vue.$gmapApiPromiseLazy()
			}
			if (!_placesService) {
				const div = document.createElement("div")
				_placesService = new google.maps.places.PlacesService(div)
			}
			const promise = utils.createPromise()
			_placesService.getDetails(
				{
					placeId: placeId,
					fields: fields
						? fields
						: ["address_components", "international_phone_number"]
				},
				(result) => {
					promise.resolve(result)
				}
			)
			return promise.promise
		} catch (e) {
			vue.$bugsnag.notify("Failed to get google place", (event) => {
				event.addMetadata("error", {
					error: e,
					placeId: placeId
				})
			})
			return null
		}
	},
	async nearbySearch(latitude, longitude, radius, type) {
		try {
			if (!latitude || !longitude || !radius) {
				return null
			}

			if (Vue.$gmapApiPromiseLazy) {
				await Vue.$gmapApiPromiseLazy()
			}
			if (!_placesService) {
				const div = document.createElement("div")
				_placesService = new google.maps.places.PlacesService(div)
			}
			const promise = utils.createPromise()
			const inputLocation = new google.maps.LatLng(latitude, longitude)
			_placesService.nearbySearch(
				{
					location: inputLocation,
					radius: radius * 1609.344,
					type: type ? [type] : undefined
				},
				(results) => {
					if (results) {
						results.forEach((result) => {
							const distance =
								google.maps.geometry.spherical.computeDistanceBetween(
									inputLocation,
									result.geometry.location
								)
							result.distance = distance * 0.000621371192
						})
					}
					promise.resolve(results)
				}
			)
			return promise.promise
		} catch (e) {
			vue.$bugsnag.notify("Failed to search nearby", (event) => {
				event.addMetadata("error", {
					error: e,
					latitude: latitude,
					longitude: longitude,
					radius: radius,
					type: type
				})
			})
			return null
		}
	},
	async autocompleteEstablishment(query, types) {
		try {
			if (!query || query.length == 0) {
				return []
			}

			if (Vue.$gmapApiPromiseLazy) {
				await Vue.$gmapApiPromiseLazy()
			}
			if (!_autocompleteService) {
				_autocompleteService =
					new google.maps.places.AutocompleteService()
			}
			const promise = utils.createPromise()
			_autocompleteService.getPlacePredictions(
				{
					input: query,
					types: types,
					componentRestrictions: { country: "us" }
				},
				(predictions) => {
					if (!predictions) {
						return []
					}
					const results = predictions
						.filter((prediction) => {
							if (
								!prediction.terms ||
								!prediction.terms[0] ||
								!prediction.terms[0].value
							) {
								return false
							}
							return true
						})
						.map((prediction) => {
							const shortName = prediction.terms[0].value
							const name = prediction.terms[1]
								? `${shortName}, ${prediction.terms[1].value}`
								: ""

							return {
								id: prediction.place_id,
								name: name,
								shortName: shortName
							}
						})
					promise.resolve(results)
				}
			)
			return promise.promise
		} catch (e) {
			vue.$bugsnag.notify(
				"Failed to autocomplete google places establishment",
				(event) => {
					event.addMetadata("error", {
						error: e,
						query: query,
						types: types
					})
				}
			)
			return []
		}
	},
	async autocompleteLocation(query, types) {
		try {
			if (!query || query.length == 0) {
				return []
			}

			if (Vue.$gmapApiPromiseLazy) {
				await Vue.$gmapApiPromiseLazy()
			}
			if (!_autocompleteService) {
				_autocompleteService =
					new google.maps.places.AutocompleteService()
			}
			const promise = utils.createPromise()
			_autocompleteService.getPlacePredictions(
				{
					input: query,
					types: types,
					componentRestrictions: { country: "us" }
				},
				(predictions) => {
					if (!predictions) {
						return []
					}
					const results = predictions
						.filter((prediction) => {
							if (
								prediction.terms &&
								prediction.terms.length > 1 &&
								prediction.terms[1].value.includes("US")
							) {
								return false
							}
							return true
						})
						.map((prediction) => {
							if (types[0].includes("region")) {
								let city = prediction.terms[0].value
								let state = prediction.terms[1]
									? prediction.terms[1].value
									: ""
								return {
									id: prediction.place_id,
									name:
										city && state.length > 0
											? `${city}, ${state}`
											: city,
									city: city,
									state: state
								}
							} else {
								return {
									id: prediction.place_id,
									name: prediction.description
								}
							}
						})
					promise.resolve(results)
				}
			)
			return promise.promise
		} catch (e) {
			vue.$bugsnag.notify(
				"Failed to autocomplete google places location",
				(event) => {
					event.addMetadata("error", {
						error: e,
						query: query,
						types: types
					})
				}
			)
			return []
		}
	}
}
