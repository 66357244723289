<template>
	<div class="sk-widget-alt" :class="{ 'is-draggable': draggingAllowed, 'no-actions': !draggingAllowed && !editAllowed }">
		<div class="business-menu-item">
			<div>
				<SkInput
					v-if="editAllowed"
					:name="'Name'"
					:placeholder="'Name'"
					v-model="name"
					class="product-name-input"
				/>
				<h2 v-else>{{ product.name }}</h2>
				<br />
				<div class="product-description text-truncate">
					{{ product.description }}
				</div>
				<div v-if="editAllowed" class="price-input">
					<div v-if="!priceOverride" class="product-price">
						{{ price | currency }}
					</div>
					<SkInput
						v-else
						v-model="price"
						:name="'Price'"
						:placeholder="'Price'"
						:type="'number'"
						:group="'$'"
					/>
					<div class="sk-switch-label-wrapper">
						<SkCheckbox v-model="priceOverride" />
						<span class="price-override-description">Override</span>
					</div>
					<div
						v-if="cogsWarning"
						class="cogs-warning"
						v-sk-tooltip.right="cogsWarningText"
					>
						<i class="sk-icon-exclamation-triangle-solid"></i>
					</div>
				</div>
				<div v-else class="product-price">{{ price | currency }}</div>
			</div>
			<div
				class="product-img"
				:style="{ backgroundImage: `url(${backgroundPath})` }"
			></div>
		</div>
		<div v-if="draggingAllowed || editAllowed" class="product-footer">
			<i class="draggable-icon sk-icon-bars-regular" v-if="draggingAllowed"></i>
			<div class="toggle" v-if="editAllowed">
				<SkToggle v-model="inStock" :width="'33px'" />
			</div>
		</div>
	</div>
</template>

<style scoped>
.sk-widget-alt {
	overflow: visible;
	min-width: 0;
}

.cogs-warning {
	margin-left: 15px;
	width: 18px;
}

.cogs-warning i {
	color: var(--sk-orange2);
}

.product-name-input {
	width: 200px;
}

.price-input >>> .sk-input-group {
	width: 100px;
}

.price-input {
	display: flex;
	align-items: center;
}

h2 {
	font-size: 16px;
	font-weight: 600;
}

h2,
.product-price {
	color: var(--sk-navy);
}

h2,
.product-description {
	margin-bottom: 12px;
}

.product-description {
	color: var(--sk-grey3);
	font-size: 12px;
	max-width: 95%;
}

.product-price {
	font-size: 14px;
	font-weight: 600;
}

.business-menu-item {
	display: flex;
}

.business-menu-item > div:first-child {
	display: flex;
	flex-direction: column;
	flex: 1;
	min-width: 0;
}

.product-img {
	width: 34%;
	background-position: center;
	background-size: cover;
	border-top-right-radius: 5px;
	margin-right: -20px;
	margin-top: -15px;
	margin-bottom: -18px;
}

.no-actions .product-img {
	border-bottom-right-radius: 5px;
	margin-bottom: -15px
}

.product-footer {
	background: var(--sk-greybg2);
	padding: 5px 15px;
	margin: 20px -20px -20px -20px;
	box-shadow: 0px 0px 4px rgba(136, 136, 136, 0.25);
	display: flex;
	align-items: center;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	z-index: 2;
    position: relative;
}

.button-icon {
	font-size: 12px;
}

.button-icon i {
	margin-right: 10px;
	font-size: 12px;
}

.button-icon + .button-icon {
	margin-left: 20px;
}

.toggle {
	display: inline-block;
	margin-left: 10px;
	margin-left: auto;
	align-items: center;
	display: flex;
}

.toggle label {
	color: var(--sk-grey3);
	margin-left: 10px;
	font-size: 12px;
	margin-bottom: 0;
}

.actions-overflow {
	display: none;
	width: 30px;
	padding: 10px 0;
	margin-right: 15px;
}

.draggable-icon {
	cursor: move;
}

@media only screen and (max-width: 1350px) {
	.actions-overflow {
		display: inline-block;
	}

	.button-icon:not(.edit-button):not(.preview-allowed) {
		display: none;
	}
}

.price-override-description {
	color: var(--sk-grey3);
	font-size: 12px;
}

.sk-switch-label-wrapper {
	margin-left: 10px;
}

.sk-switch-label-wrapper >>> .sk-checkbox {
	margin-right: 5px;
}
</style>

<script>
import currencyFilterMixin from "@/mixins/currency-filter-mixin"
import SkToggle from "@/components/SkToggle.vue"
import SkInput from "@/components/SkInput.vue"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"
import SkCheckbox from "@/components/SkCheckbox.vue"
import { PermissionMethod } from "@/utils/permissions"

export default {
	name: "BusinessMenuItem",
	mixins: [currencyFilterMixin],
	components: {
		SkToggle,
		SkDropdownSelect,
		SkInput,
		SkCheckbox
	},
	props: {
		product: Object
	},
	data: function() {
		return {
			actions: [
				{
					id: 0,
					text: `<i class="sk-icon-eye-solid"></i> Preview`
				},
				{
					id: 1,
					text: `<i class="sk-icon-copy-solid"></i> Duplicate`
				},
				{
					id: 2,
					text: `<i class="sk-icon-trash-alt-solid"></i> Delete`
				}
			]
		}
	},
	computed: {
		backgroundPath() {
			if (this.product.img) {
				const filenameArr = this.product.img.split(".")
				const sizedFileName =
					filenameArr[0] + "_" + "500x500." + filenameArr[1]
				return (
					this.$restaurantCDNURL +
					this.product.restaurant_slug +
					"/" +
					sizedFileName
				)
			} else {
				return ""
			}
		},
		business() {
			return this.$store.state.businessesModule.currentBusiness
		},
		menu() {
			return this.business.menus.find(
				e => e.id == this.$route.params.menu_id
			)
		},
		cogsWarning() {
			return this.price < this.product.cogs
		},
		editAllowed() {
			return this.$store.state.permissions.includes(PermissionMethod.EDIT_BUSINESS_PRODUCTS)
		},
		draggingAllowed() {
			return this.inStock && this.editAllowed
		},
		cogsWarningText() {
			return "Price lower than COGs ($" + this.product.cogs + ")"
		},
		name: {
			get: function() {
				var name = this.product.name
				if (this.menu.products != undefined) {
					const menuProd = this.menu.products.find(
						e => e.id == this.product.id
					)
					if (menuProd && menuProd != null && menuProd.name != null) {
						name = menuProd.name
					}
				}
				return name
			},
			set: function(newVal) {
				var menuProd = this.menu.products.find(
					e => e.id == this.product.id
				)
				if (menuProd && menuProd != null) {
					if (newVal == "") menuProd.name = null
					else menuProd.name = newVal
				}
			}
		},
		price: {
			get: function() {
				var price = this.product.price
				if (this.menu.products != undefined) {
					const menuProd = this.menu.products.find(
						e => e.id == this.product.id
					)
					if (
						menuProd &&
						menuProd != null &&
						menuProd.price != null &&
						menuProd.price > 0
					) {
						price = menuProd.price
					}
				}
				return price
			},
			set: function(newVal) {
				var menuProd = this.menu.products.find(
					e => e.id == this.product.id
				)
				if (menuProd && menuProd != null) {
					menuProd.price = newVal
				}
			}
		},
		inStock: {
			get() {
				const currentMenu = this.$store.state.businessesModule.currentBusiness.menus.filter(
					e => e.id == this.$route.params.menu_id
				)
				if (
					currentMenu.length > 0 &&
					currentMenu[0].products != undefined &&
					currentMenu[0].products.some(e => e.id === this.product.id)
				) {
					return true
				} else {
					return false
				}
			},
			set(value) {
				if (value) {
					this.$store.dispatch(
						"businessesModule/addBusinessMenuItem",
						[this.product, this.menu.id]
					)
				} else {
					this.$store.dispatch(
						"businessesModule/removeBusinessMenuItem",
						[this.product.id, this.menu.id]
					)
				}
				this.$emit("itemChanged")
			}
		},
		priceOverride: {
			get: function() {
				var retVal = false
				if (this.menu.products != undefined) {
					const menuProd = this.menu.products.find(
						e => e.id == this.product.id
					)
					if (menuProd && menuProd != null) {
						retVal = menuProd.is_price_override == 1
					}
				}
				return retVal
			},
			set: function(newVal) {
				var menuProd = this.menu.products.find(
					e => e.id == this.product.id
				)
				if (menuProd && menuProd != null) {
					if (newVal) {
						this.$set(menuProd, "is_price_override", 1)
					} else {
						this.$set(menuProd, "is_price_override", 0)
					}
				}
			}
		}
	}
}
</script>
