<template>
	<div id="app">
		<Notification />
		<SideBar
			@mouseover.native="hovering(true)"
			@mouseleave.native="hovering(false)"
			@hidden="hovering(false)"
			@forceExpand="forceExpand"
			:hovering="isExpanded"
		/>
		<CrispLauncher />
		<StaffTrainingIntroModal />
		<router-view class="router-wrapper" :class="routerViewClass" />
	</div>
</template>

<style scoped>
#app {
	display: flex;
	height: 100%;
}

.router-wrapper {
	transition: transform 0.25s linear;
}

.sidebar-expanded {
	transform: translateX(145px);
}

@media (max-width: 1024px) {
	.sidebar-expanded {
		transform: translateX(0);
	}

	.router-wrapper {
		transition: none;
	}
}
</style>

<script>
import { PusherEvent } from "@/enums/pusherEvent"
import ActionTypes from "@/store/modules/activityFeed/action-types"
import loaderMixin from "@/mixins/loader-mixin"
import pusherMixin from "@/mixins/pusher-mixin"
import Notification from "@/components/Notification.vue"
import SideBar from "@/components/sidebar/SideBar.vue"
import CrispLauncher from "@/components/CrispLauncher.vue"
import StaffTrainingIntroModal from "@/components/modals/StaffTrainingIntroModal.vue"
import { UserRole } from "./enums/userRole"

export default {
	mixins: [loaderMixin, pusherMixin],
	components: {
		Notification,
		SideBar,
		CrispLauncher,
		StaffTrainingIntroModal
	},
	data: function () {
		return {
			didMount: false,
			hoveringSidebar: false,
			forceExpanded: false,
			disconnected: null
		}
	},
	computed: {
		loading() {
			return this.$route.path == "/" && this.$route.name == null
		},
		userId() {
			return this.$store.state.user_id
		},
		username() {
			return this.$store.state.user_name
		},
		calculatePermissions() {
			return {
				role: this.$store.state.user_role,
				subRole: this.$store.state.user_sub_role,
				businesses: this.$store.state.businesses
			}
		},
		routerViewClass() {
			return this.isExpanded ? "sidebar-expanded" : ""
		},
		isExpanded() {
			return this.hoveringSidebar || this.forceExpanded
		}
	},
	beforeCreate: function () {
		this.pusher.initClient(process.env)
	},
	created: function () {
		this.$store.restored.then(() => {
			this.bugsnag.setUser(
				this.$store.state.user_id,
				undefined,
				this.$store.state.user_name
			)

			if (this.$store.state.user_id) {
				this.$store.dispatch("sendForkLiftEvent", "chef_session_start")
			}
			this.$store.dispatch("initializeChef")
		})
	},
	mounted: function () {
		this.didMount = true
		if (this.loading) {
			this.toggleLoader(true)
		}

		this.$store.restored.then(() => {
			if (this.$store.state.user_id) {
				this.subscribe(this.handlePusherEvent)
			}
		})
	},
	methods: {
		hovering(isHovering) {
			this.hoveringSidebar = isHovering
		},
		forceExpand(expanded) {
			this.forceExpanded = expanded
		},
		subscribeToPusher() {
			this.subscribeToAdminChannel()
			this.pusher.pusherClient.connection.bind(
				"state_change",
				(states) => {
					if (
						states.previous == "connected" &&
						states.current == "connecting"
					) {
						this.disconnected = true
					} else if (
						states.previous == "connecting" &&
						states.current == "connected"
					) {
						if (this.disconnected) {
							this.$store.dispatch("initializeChef")
						}
						this.disconnected = false
					}
				}
			)

			if (this.didMount) {
				this.subscribe(this.handlePusherEvent)
			}
		},
		handlePusherEvent(event) {
			switch (event.name) {
				case PusherEvent.ACTIVITY_ADDED: {
					this.$store.dispatch(
						`activityFeedModule/${ActionTypes.ADD_ACTIVITY}`,
						event.data
					)
					break
				}
				case PusherEvent.RESTAURANT_CREATED:
				case PusherEvent.RESTAURANT_UPDATED:
				case PusherEvent.RESTAURANT_MENU_UPDATED: {
					const isOwnedEntity =
						this.$store.state.user_role == UserRole.SUPER_ADMIN ||
						(this.$store.state.user_role ==
							UserRole.RESAURANT_ADMIN &&
							this.$store.state.user_entities.includes(
								event.data.id
							))
					if (
						isOwnedEntity &&
						event.data.admin_user_id != this.$store.state.user_id
					) {
						this.$store.dispatch(
							"getRestaurantsByIds",
							{
								ids: [event.data.id],
								silently: true
							},
							{ root: true }
						)
					}
					break
				}
				case PusherEvent.BUSINESS_CREATED:
				case PusherEvent.BUSINESS_UPDATED:
				case PusherEvent.BUSINESS_MENU_UPDATED: {
					const isOwnedEntity =
						this.$store.state.user_role == UserRole.SUPER_ADMIN ||
						(this.$store.state.user_role ==
							UserRole.BUSINESS_ADMIN &&
							this.$store.state.user_entities.some(
								(entity) => entity.id == event.data.id
							))
					if (
						isOwnedEntity &&
						event.data.admin_user_id != this.$store.state.user_id
					) {
						this.$store.dispatch(
							"getBusinessesByIds",
							{
								ids: [event.data.id],
								silently: true
							},
							{ root: true }
						)
					}
					break
				}
			}
		},
		unsubscribePusher() {
			this.pusher.channels.forEach((channel) => {
				this.pusher.unsubscribe(channel)
			})
			this.unsubscribe()
		},
		configureBugsnag() {
			this.bugsnag.user = {
				id: this.$store.state.user_id,
				name: this.$store.state.user_name
			}
		}
	},
	watch: {
		loading(newVal) {
			this.toggleLoader(newVal)
		},
		calculatePermissions: {
			immediate: true,
			handler() {
				this.$store.dispatch("calculatePermissions")
			}
		},
		username: {
			immediate: true,
			handler() {
				this.configureBugsnag()
			}
		},
		userId: {
			immediate: true,
			handler(newVal) {
				if (newVal) {
					this.subscribeToPusher()
				} else {
					this.unsubscribePusher()
				}
			}
		}
	}
}
</script>