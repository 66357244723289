
<template>
	<div class="sk-page-content">
		<PageHeader
			:title="eventTitle"
			:actions="headerAction"
			:secondaryActions="secondaryActions"
			:tabs="tabs"
			:breadcrumbs="breadcrumbs"
			@actionClicked="headerActionClicked"
			@secondaryActionClicked="headerActionClicked"
			@tabClicked="headerTabClicked"
		>
			<div
				slot="title-decorator"
				class="status-badge"
				:class="statusBadgeClasses"
			>
				<span :class="statusClasses"></span>
				{{ statusLabel }}
			</div>
		</PageHeader>
		<transition name="slide" mode="out-in">
			<EventDetails
				v-if="activeTab.id == 0"
				key="0"
				class="centered-tab-content"
				:event="event"
				@editEvent="editEvent"
			/>
			<EventOrders v-if="activeTab.id == 1" key="1" :event="event" />
			<EventReminders
				v-if="activeTab.id == 2"
				key="2"
				:event="event"
				@edit="openEventReminderModal"
				@new="openEventReminderModal"
			/>
		</transition>
		<ConfirmationModal ref="confirmationModal" />
		<EditEventModal ref="editEventModal" />
		<EventReminderModal ref="eventReminderModal" :event="event" />
	</div>
</template>

<style scoped>
.pill-alt {
	margin-left: 25px;
}

@media (max-width: 560px) {
	.status-badge {
		margin-left: 0;
		margin-top: 5px;
		margin-bottom: 5px;
	}
}
</style>
  
<script>
import { EventStatus } from "@arikgaisler/utils/enums/eventStatus"
import { PreOrderFulfillmentStatus } from "@arikgaisler/utils/enums/preOrderFulfillmentStatus"
import tracker from "@/utils/tracker"
import eventMixin from "@/mixins/events/event-mixin"
import eventStatusMixin from "@/mixins/events/event-status-mixin"
import eventActionsMixin from "@/mixins/events/event-actions-mixin"
import loaderMixin from "@/mixins/loader-mixin"
import PageHeader from "@/components/PageHeader.vue"
import EventDetails from "@/components/events/EventDetails.vue"
import EventOrders from "@/components/events/EventOrders.vue"
import EventReminders from "@/components/events/EventReminders.vue"
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue"
import EditEventModal from "@/components/modals/EditEventModal.vue"
import EventReminderModal from "@/components/modals/EventReminderModal.vue"

export default {
	name: "Event",
	mixins: [eventActionsMixin, eventStatusMixin, eventMixin, loaderMixin],
	components: {
		PageHeader,
		EventDetails,
		EventOrders,
		EventReminders,
		ConfirmationModal,
		EditEventModal,
		EventReminderModal
	},
	data: function () {
		return {
			tabs: [
				{
					id: 0,
					text: "Details",
					active: true
				},
				{
					id: 1,
					text: "Orders",
					active: false
				},
				{
					id: 2,
					text: "Reminders",
					active: false
				}
			]
		}
	},
	computed: {
		event() {
			return this.$store.state.eventsModule.event
		},
		eventTitle() {
			return this.event.name ? this.event.name : "Popup"
		},
		headerAction() {
			switch (this.activeTab.id) {
				case 0: {
					if (!this.event.status) {
						return []
					}

					if (this.isConfirmable) {
						return [
							{
								text: "Confirm",
								id: 3,
								classes: "button button-success"
							}
						]
					} else if (this.isCancelable) {
						return [
							{
								text: "Cancel",
								id: 0,
								classes: "button button-danger"
							}
						]
					} else if (this.isDeletable) {
						return [
							{
								text: "Delete",
								id: 4,
								classes: "button button-danger"
							}
						]
					} else if (this.isEditable) {
						return [
							{
								text: "Edit",
								id: 1,
								classes: "button button-primary"
							}
						]
					}

					break
				}
				case 1: {
					if (
						this.event.manifest_generated ||
						this.event.status == EventStatus.PREORDER
					) {
						return [
							{
								text: `<i class="sk-icon-download"></i> Download Manifest`,
								id: 0,
								classes: "button button-primary"
							}
						]
					}
					break
				}
				case 2: {
					return [
						{
							text: `<i class="sk-icon-plus-regular"></i> New Reminder`,
							id: 0,
							classes: "button button-primary"
						}
					]
				}
			}
			return []
		},
		secondaryActions() {
			switch (this.activeTab.id) {
				case 0: {
					let actions = []

					if (!this.event.status) {
						return actions
					}

					if (this.isEditable) {
						actions.push({
							text: `<i class="sk-icon-pencil-solid"></i> Edit`,
							id: 1
						})
					}

					if (this.isDeletable) {
						actions.push({
							text: `<i class="sk-icon-undo-regular"></i> Restore`,
							id: 2
						})
					}

					if (this.isCancelable) {
						actions.push({
							text: `<i class="sk-icon-times-regular"></i> Cancel`,
							id: 0
						})
					}

					if (this.isAcceptable) {
						actions.push({
							text: `<i class="sk-icon-check-regular"></i> Mark Accepted`,
							id: 6
						})
					} else if (this.isCompletable) {
						actions.push({
							text: `<i class="sk-icon-check-regular"></i> Mark Picked Up`,
							id: 7
						})
					} else if (this.isDeliverable) {
						actions.push({
							text: `<div class="event-action-option"><i class="sk-icon-check-regular"></i> Mark Delivered</div>`,
							id: 8
						})
					}

					return actions.length > 0 ? actions : null
				}
			}
			return null
		},
		activeTab() {
			return this.tabs.find((tab) => tab.active)
		},
		breadcrumbs() {
			return [
				{
					name: "Events",
					link: "/events"
				},
				{
					name: this.eventTitle
				}
			]
		}
	},
	methods: {
		cancelEvent() {
			this.updateEventStatus(
				this.event,
				this.businessName,
				EventStatus.CANCELLED
			).then((cancelled) => {
				if (cancelled) {
					this.$router.push({ path: `/event/${this.event.id}` })
				}
			})
		},
		async headerActionClicked(action) {
			switch (this.activeTab.id) {
				case 0: {
					switch (action.id) {
						case 0:
							this.cancelEvent()
							break
						case 1:
							this.$refs.editEventModal.open({
								eventId: this.event.id
							})
							break
						case 2:
							this.updateEventStatus(
								this.event,
								this.businessName,
								EventStatus.IN_REVIEW
							).then((inReview) => {
								if (inReview) {
									this.$router.push({
										path: `/event/edit/${this.event.id}`
									})
								}
							})
							break
						case 3:
							this.updateEventStatus(
								this.event,
								this.businessName,
								EventStatus.CONFIRMED
							)
							break
						case 4:
							this.deleteEvent(this.event, this.businessName)
							break
						case 6:
							this.updateEventFulfillmentStatus(
								this.event,
								this.businessName,
								PreOrderFulfillmentStatus.IN_PROGRESS
							)
							break
						case 7:
							this.updateEventFulfillmentStatus(
								this.event,
								this.businessName,
								PreOrderFulfillmentStatus.COMPLETED
							)
							break
						case 8:
							this.updateEventFulfillmentStatus(
								this.event,
								this.businessName,
								PreOrderFulfillmentStatus.DELIVERED
							)
							break
					}
					break
				}
				case 1: {
					this.toggleLoader(true)
					const url = await this.getEventManifestUrl(this.event)
					if (url && url != null && url != "") {
						window.open(url, "_blank")
					}
					this.toggleLoader(false)
					break
				}
				case 2: {
					this.openEventReminderModal({})
					break
				}
			}
		},
		headerTabClicked(tabClicked) {
			tracker.trackEvent("Event Page Tab Clicked", {
				tab_name: tabClicked.text
			})
			this.tabs.forEach((tab) => {
				tab.active = tabClicked.id == tab.id
			})
		},
		editEvent(options) {
			this.$refs.editEventModal.open(options)
		},
		openEventReminderModal(options) {
			this.$refs.eventReminderModal.open(options)
		}
	}
}
</script>