<template>
	<div class="sk-page-content">
		<PageHeader
			ref="pageHeader"
			:title="'Insights'"
			:tabs="tabs"
			:filters="filters"
			:actions="actions"
			@tabClicked="headerTabClicked"
			@actionClicked="headerActionClicked"
			@filterSet="filterSet"
			@allFiltersReset="clearFilters"
		/>
		<BusinessSalesAndOrders
			v-if="activeTabId == 0"
			:filterVals="filterVals"
		/>
		<BusinessRatings v-if="activeTabId == 3" :filterVals="filterVals" />
	</div>
</template>

<script>
import moment from "moment"
import ActionTypes from "@/store/modules/insights/action-types"
import PageHeader from "@/components/PageHeader.vue"
import BusinessSalesAndOrders from "@/components/insights/BusinessSalesAndOrders.vue"
import BusinessRatings from "@/components/insights/BusinessRatings"

export default {
	name: "BusinessInsights",
	components: {
		PageHeader,
		BusinessSalesAndOrders,
		BusinessRatings
	},
	data: function () {
		return {
			activeTabId: 0,
			filterVals: {
				businessId: null,
				restaurantId: null,
				startDate: null,
				endDate: null
			}
		}
	},
	computed: {
		pairedRestaurants() {
			const entityIds = this.$store.state.user_entities.map(
				(entity) => entity.id
			)
			const restaurants = []
			const restaurantIds = []
			entityIds.forEach((entityId) => {
				const pairedBusinesses =
					this.$store.getters.restaurantBusinessPairings[entityId]
				if (pairedBusinesses && pairedBusinesses.restaurants) {
					pairedBusinesses.restaurants.forEach((restaurant) => {
						if (!restaurantIds.includes(restaurant.id)) {
							restaurants.push(restaurant)
							restaurantIds.push(restaurant.id)
						}
					})
				}
			})
			return restaurants
		},
		actions() {
			if (this.$store.getters.isSuperAdmin) {
				return [
					{
						id: 0,
						classes: "button-reset",
						text: `<div class="insights-toggle"><div class="active">Business View</div><div>Restaurant View</div></div>`
					}
				]
			}
			return null
		},
		tabs() {
			const tabs = [
				{
					id: 0,
					text: "Sales & Orders"
				},
				// {
				// 	id: 1,
				// 	text: "Platform Usage"
				// },
				// {
				// 	id: 2,
				// 	text: "Menus"
				// },
				{
					id: 3,
					text: "Ratings"
				}
			]
			return tabs
				.map((tab) => {
					tab.active = tab.id == this.activeTabId
					return tab
				})
				.sort((a, b) => a.id - b.id)
		},
		filters() {
			let filters = [
				{
					id: 0,
					placeholder: "Date",
					type: "daterange",
					shortcuts: [
						{
							key: "thisWeek",
							label: "This Week",
							value: "isoWeek"
						},
						{
							key: "lastWeek",
							label: "Last Week",
							value: "-isoWeek"
						},
						{
							key: "lastMonth",
							label: "Last Month",
							value: "-month"
						},
						{
							key: "thisMonth",
							label: "This Month",
							value: "month"
						},
						{
							key: "thisQuarter",
							label: "This Quarter",
							value: "quarter"
						},
						{ key: "thisYear", label: "This Year", value: "year" }
					]
				}
			]

			if (this.$store.getters.ownsMultipleEntities) {
				filters.push({
					id: 1,
					placeholder: "Business Name",
					type: "search",
					width: "250px",
					fetch: (query) => {
						return this.$store.dispatch(
							"businessesModule/autocompleteBusinesses",
							query
						)
					},
					serializer: (i) => {
						return { text: i.name }
					}
				})
			}

			if (this.$store.getters.isSuperAdmin) {
				filters.push({
					id: 2,
					placeholder: "Restaurant Name",
					type: "search",
					width: "250px",
					fetch: (query) => {
						return this.$store.dispatch(
							"restaurantsModule/autocompleteRestaurants",
							query
						)
					},
					serializer: (i) => {
						return { text: i.name }
					}
				})
			} else {
				filters.push({
					id: 2,
					placeholder: "Restaurant Name",
					type: "search",
					width: "250px",
					items: this.pairedRestaurants,
					serializer: (i) => {
						return { text: i.name }
					}
				})
			}

			return filters
		}
	},
	methods: {
		headerTabClicked(tab) {
			this.activeTabId = tab.id
		},
		headerActionClicked() {
			this.$router.push({
				path: "/insights",
				query: { view: "restaurant" }
			})
		},
		filterSet(filter) {
			switch (filter.id) {
				case 0: {
					this.filterVals.startDate = filter.value.from
					this.filterVals.endDate = filter.value.to
					break
				}
				case 1: {
					this.filterVals.businessId = filter.value
						? filter.value.id
						: null
					this.filterVals.restaurantId = null
					break
				}
				case 2: {
					this.filterVals.restaurantId = filter.value
						? filter.value.id
						: null
					this.filterVals.businessId = null
				}
			}
			this.setFilters()
		},
		clearFilters() {
			this.filterVals.businessId = null
			this.filterVals.restaurantId = null
			this.setDefaultDateFilter()
			this.setFilters()
		},
		setFilters() {
			this.$store.dispatch(`insightsModule/${ActionTypes.SET_FILTERS}`, {
				startDate: this.filterVals.startDate,
				endDate: this.filterVals.endDate,
				businessId: this.filterVals.businessId,
				restaurantId: this.filterVals.restaurantId
			})
		},
		setDefaultDateFilter() {
			const start = moment
				.utc()
				.subtract(7, "days")
				.hours(0)
				.minutes(0)
				.seconds(0)
			const end = moment
				.utc()
				.subtract(1, "days")
				.hours(23)
				.minutes(59)
				.seconds(59)
			this.filterVals.startDate = start.format("YYYY-MM-DD")
			this.filterVals.endDate = end.format("YYYY-MM-DD")
			this.$refs.pageHeader.filterSet(
				this.filters[0],
				{
					start: start.format("MMM DD, YYYY"),
					end: end.format("MMM DD, YYYY"),
					formatted: {
						start: start.format("MMM DD, YYYY"),
						end: end.format("MMM DD, YYYY")
					}
				},
				true
			)
		}
	},
	mounted: function () {
		this.setDefaultDateFilter()
		this.setFilters()
	}
}
</script>