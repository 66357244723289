<template>
	<div>
		<div class="sk-column">
			<div class="sk-insight-section-title">Ratings</div>
			<div class="sk-insight-section-description">
				How do your customers feel about their experience?
			</div>
		</div>
		<div class="sk-row">
			<AverageRatingWidget class="average-rating-widget" />
		</div>
	</div>
</template>

<style scoped>
.average-rating-widget {
	max-width: 430px;
}
</style>

<script>
import ActionTypes from "@/store/modules/insights/action-types"
import AverageRatingWidget from "@/components/insights/AverageRatingWidget"

export default {
	name: "BusinessSalesAndOrders",
	components: {
		AverageRatingWidget
	},
	props: {
		filterVals: Object
	},
	watch: {
		filterVals: {
			immediate: true,
			deep: true,
			handler(filterVals) {
				if (!filterVals.startDate || !filterVals.endDate) {
					return
				}
				this.$store.dispatch(
					`insightsModule/${ActionTypes.GET_AVERAGE_RATING}`
				)
			}
		}
	}
}
</script>