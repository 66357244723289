import MutationTypes from "@/store/modules/events/mutation-types"
import { EventStatus } from "@arikgaisler/utils/enums/eventStatus"
import { PreOrderFulfillmentStatus } from "@arikgaisler/utils/enums/preOrderFulfillmentStatus"
import Vue from "vue"

export default {
	[MutationTypes.SET_EVENTS_FILTERS](state, payload) {
		if (payload.startDate !== undefined) {
			state.filters.startDate = payload.startDate
		}
		if (payload.endDate !== undefined) {
			state.filters.endDate = payload.endDate
		}
		if (payload.businessId !== undefined) {
			state.filters.businessId = payload.businessId
		}
		if (payload.status !== undefined) {
			state.filters.status = payload.status
		}
		if (state.filters.restaurantId !== undefined) {
			state.filters.restaurantId = payload.restaurantId
		}
		if (payload.type !== undefined) {
			state.filters.type = payload.type
		}
	},
	[MutationTypes.GOT_EVENTS](state, payload) {
		if (payload.data && payload.data.events) {
			state.events = payload.data.events.sort((a, b) => b.id - a.id)
		} else {
			state.events = []
		}
	},
	[MutationTypes.SCHEDULED_EVENT](state, event) {
		state.events.unshift(event)
	},
	[MutationTypes.UPDATED_EVENT](state, updatedEvent) {
		let eventIndex = state.events.findIndex(event => event.id == updatedEvent.id)
		if (eventIndex >= 0) {
			state.events.splice(eventIndex, 1, updatedEvent)
		}
		if (state.event && state.event.id == updatedEvent.id) {
			state.event = updatedEvent
		}
	},
	[MutationTypes.DELETED_EVENT](state, eventId) {
		let eventIndex = state.events.findIndex(event => event.id == eventId)
		if (eventIndex >= 0) {
			state.events.splice(eventIndex, 1)
		}
		state.event = {}
	},
	[MutationTypes.ENDED_EVENT](state, eventId) {
		const event = state.events.find(event => event.id == eventId)
        if (event) {
            event.status = EventStatus.COMPLETED
        }
	},
	[MutationTypes.STOPPED_EVENT_PRE_ORDERS](state, eventId) {
		const event = state.events.find(event => event.id == eventId)
        if (event) {
			event.status = EventStatus.PRE_ORDER_FULFILLMENT
			event.pre_order_fulfillment_status = PreOrderFulfillmentStatus.IN_PROGRESS
        }
	},
	[MutationTypes.UPDATED_EVENT_STATUS](state, payload) {
		if (payload.id && payload.status) {
			if (state.event.id == payload.id) {
				state.event.status = payload.status
			}
			let event = state.events.find(event => event.id == payload.id)
			if (event) {
				event.status = payload.status
			}
		}
	},
	[MutationTypes.UPDATED_EVENT_FULFILLMENT_STATUS](state, payload) {
		if (payload.id && payload.status) {
			if (state.event.id == payload.id) {
				state.event.pre_order_fulfillment_status = payload.status
			}
			let event = state.events.find(event => event.id == payload.id)
			if (event) {
				event.pre_order_fulfillment_status = payload.status
			}
		}
	},
	[MutationTypes.UPDATED_EVENT_NOTES](state, payload) {
		if (payload.id) {
			if (state.event.id == payload.id) {
				state.event.manifest_notes = payload.notes
			}
			let event = state.events.find(event => event.id == payload.id)
			if (event) {
				event.manifest_notes = payload.notes
			}
		}
	},
	[MutationTypes.GOT_EVENT](state, payload) {
		if (payload.data && payload.data.event) {
			state.event = payload.data.event
		} else {
			state.event = {}
		}
		state.eventOrders = []
		state.eventReminders = []
	},
	[MutationTypes.GOT_EVENT_ORDERS](state, orders) {
		state.eventOrders = orders ? orders : []
	},
	[MutationTypes.GOT_EVENT_REMINDERS](state, reminders) {
		state.eventReminders = reminders ? reminders : []
	},
	[MutationTypes.UPDATED_EVENT_REMINDER](state, updatedReminder) {
		let reminderIndex = state.eventReminders.findIndex(reminder => reminder.id == updatedReminder.id)
		if (reminderIndex >= 0) {
			state.eventReminders.splice(reminderIndex, 1, updatedReminder)
		}
	},
	[MutationTypes.ADDED_EVENT_REMINDER](state, reminder) {
		state.eventReminders.push(reminder)
	},
	[MutationTypes.DELETED_EVENT_REMINDER](state, reminderId) {
		let reminderIndex = state.eventReminders.findIndex(reminder => reminder.id == reminderId)
		if (reminderIndex >= 0) {
			state.eventReminders.splice(reminderIndex, 1)
		}
	},
	[MutationTypes.GOT_EVENT_MENUS](state, payload) {
		if (payload.menus) {
			payload.menus.forEach((menu) => {
				Vue.set(state.eventMenus, menu.id, menu)
			})
		}
	},
	[MutationTypes.GOT_EVENT_RESTAURANT_MENUS](state, payload) {
		if (payload.menus) {
			Vue.set(state.restaurantMenus, payload.restaurantId, payload.menus)
		}
	},
	[MutationTypes.GOT_EVENT_RESTAURANT_MENU_PRODUCTS](state, payload) {
		if (payload.products && payload.restaurantId && state.restaurantMenus[payload.restaurantId]) {
			let menu = state.restaurantMenus[payload.restaurantId].find((menu) => menu.id == payload.menuId)
			if (menu) {
				if (payload.selectedProducts) {
					let selectedProductIds = payload.selectedProducts.map(product => product.id)
					payload.products.forEach(product => {
						product.addToEventMenu = selectedProductIds.includes(product.id)
					})
				}
				Vue.set(menu, "products", payload.products)
			}
		}
	}
}