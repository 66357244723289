import { ActivityType } from "@/enums/activityType"
import moment from "moment"

let activityFeedItemMixin = {
    props: {
		activity: {
			type: Object,
			default: () => {}
		}
	},
	computed: {
		activityIcon() {
			switch (this.activity.type) {
				case ActivityType.ADMIN_USER_ACTIVATED:
					return "sk-icon-hand-paper-solid"
				case ActivityType.BUSINESS_LAUNCHED:
					return "sk-icon-rocket-launch-regular"
				case ActivityType.BUSINESS_SUPPLY_SHIPPED:
					return "sk-icon-parachute-box-solid"
				case ActivityType.CAMPAIGN_ACTION_DELIVERED:
					return "sk-icon-comet-solid"
				case ActivityType.BUSINESS_MENU_RESTAURANT_APPROVED:
					return "sk-icon-thumbs-up-solid"
				case ActivityType.BUSINESS_EVENT_SCHEDULED:
					return "sk-icon-calendar-alt-regular"
				case ActivityType.VOUCHER_REQUEST_CREATED:
					return "sk sk-icon-tag-solid"
				case ActivityType.VOUCHERS_RUNNING_LOW:
					return "sk sk-icon-tag-solid"
				case ActivityType.CATERING_ORDER_REMINDER:
					return "sk sk-icon-egg-fried-solid"
			}
		},
		hasLink() {
			switch (this.activity.type) {
				case ActivityType.BUSINESS_EVENT_SCHEDULED:
				case ActivityType.CAMPAIGN_ACTION_DELIVERED:
				case ActivityType.BUSINESS_MENU_RESTAURANT_APPROVED:
				case ActivityType.VOUCHERS_RUNNING_LOW:
				case ActivityType.VOUCHER_REQUEST_CREATED:
				case ActivityType.CATERING_ORDER_REMINDER:
					return true
				default:
					return false
			}
		},
		linkText() {
			switch (this.activity.type) {
				case ActivityType.BUSINESS_EVENT_SCHEDULED:
					return "View Event"
				case ActivityType.CAMPAIGN_ACTION_DELIVERED:
					return "View Orders"
				case ActivityType.BUSINESS_MENU_RESTAURANT_APPROVED:
					return "View Restaurant"
				case ActivityType.VOUCHER_REQUEST_CREATED:
					return "View Voucher Request"
				case ActivityType.VOUCHERS_RUNNING_LOW:
					return "Manage Vouchers"
				case ActivityType.CATERING_ORDER_REMINDER:
					return "Order Catering"
				default:
					return ""
			}
		},
		linkPath() {
			switch (this.activity.type) {
				case ActivityType.BUSINESS_EVENT_SCHEDULED:
					return `/events/${this.activity.attributes.event_id}`
				case ActivityType.CAMPAIGN_ACTION_DELIVERED:
					return "/orders"
				case ActivityType.BUSINESS_MENU_RESTAURANT_APPROVED:
					return `/restaurant/${this.activity.attributes.restaurant_id}`
				case ActivityType.VOUCHER_REQUEST_CREATED:
					return `/promotions/${this.activity.attributes.voucher_request_id}`
				case ActivityType.VOUCHERS_RUNNING_LOW:
					return `/promotions`
				case ActivityType.CATERING_ORDER_REMINDER:
					return `/catering`
				default:
					return "/"
			}
		},
		timeSince() {
			return moment(this.activity.created_date).local().fromNow()
		},
		unread() {
			return (
				this.activity.read_date == null ||
				this.activity.read_date == undefined
			)
		}
    }
}

export default activityFeedItemMixin