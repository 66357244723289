<template>
	<div class="sk-page-content">
		<PageHeader
			:title="pageTitle"
			:tabs="tabs"
			:filters="filters"
			:breadcrumbs="breadcrumbs"
			@filterSet="filterSet"
			@filtersReset="clearFilters"
			@tabClicked="headerTabClicked"
		/>
		<transition name="slide" mode="out-in">
			<PayoutDetailsBasicInfo
				key="0"
				v-if="activeTabId == 0"
				@showPayoutHistory="payoutHistoryClicked"
			/>
			<PayoutDetailsHistory
				key="1"
				v-if="activeTabId == 1"
				:filters="payoutFilters"
			/>
		</transition>
	</div>
</template>

<style scoped>
</style>

<script>
import PageHeader from "@/components/PageHeader.vue"
import PayoutDetailsBasicInfo from "@/components/finance/PayoutDetailsBasicInfo.vue"
import PayoutDetailsHistory from "@/components/finance/PayoutDetailsHistory.vue"
import { PayoutStatus } from "@/enums/payoutStatus"
import loaderMixin from "@/mixins/loader-mixin"

export default {
	name: "PayoutDetails",
	mixins: [loaderMixin],
	components: {
		PageHeader,
		PayoutDetailsBasicInfo,
		PayoutDetailsHistory
	},
	data: function () {
		return {
			activeTabId: 0,
			didMount: false,
			payoutFilters: {
				status: -1,
				location: null,
				payoutStartDate: null,
				payoutEndDate: null,
				method: null
			}
		}
	},
	mounted: function () {
		this.toggleLoader(true)
		this.didMount = true
	},
	computed: {
		restaurant() {
            const restaurantId = this.$route.params.entity_id
			return this.$store.state.restaurants[restaurantId]
        },
		pageTitle() {
			return this.restaurant && this.restaurant.name
				? `${this.restaurant.name} Payouts`
				: "Payouts"
		},
		loading() {
			return this.$store.state.financeModule.loading || !this.didMount
		},
		tabs() {
			let allowedTabs = [
				{
					id: 0,
					text: "Account Info"
				},
				{
					id: 1,
					text: "Payout History"
				}
			]
			return allowedTabs
				.map((tab) => {
					tab.active = tab.id == this.activeTabId
					return tab
				})
				.sort((a, b) => a.id - b.id)
		},
		filters() {
			let filters = []
			if (this.activeTabId == 1) {
				filters = [
					{
						id: 0,
						placeholder: "Status",
						type: "dropdown",
						items: [
							{
								text: `Status`,
								id: null
							},
							{
								text: `<span class="status-dot dark-grey"></span> All`,
								id: -1
							},
							{
								text: `<span class="status-dot yellow"></span> Processing`,
								id: PayoutStatus.PENDING
							},
							{
								text: `<span class="status-dot green"></span> Completed`,
								id: PayoutStatus.COMPLETED
							},
							{
								text: `<span class="status-dot red"></span> Failed`,
								id: PayoutStatus.FAILED
							}
						]
					},
					{
						id: 2,
						placeholder: "Payout Dates",
						type: "daterange"
					}
				]
			}
			return filters
		},
		breadcrumbs() {
			return [
				{
					name: "Finance",
					link: "/finance"
				},
				{
					name: "Payouts"
				}
			]
		}
	},
	methods: {
		headerTabClicked(tabClicked) {
			this.activeTabId = tabClicked.id
		},
		clearFilters() {
			this.payoutFilters.status = -1
			this.payoutFilters.payoutStartDate = null
			this.payoutFilters.payoutEndDate = null
			this.payoutFilters.method = null
		},
		filterSet(filter) {
			switch (filter.id) {
				case 0:
					this.payoutFilters.status = filter.value.id
					break
				case 1:
					this.payoutFilters.location = filter.id
					break
				case 2:
					this.payoutFilters.payoutStartDate = filter.value.from
					this.payoutFilters.payoutEndDate = filter.value.to
					break
				case 3:
					this.payoutFilters.method = filter.id
					break
			}
		},
		payoutHistoryClicked() {
			this.activeTabId = 1
		}
	},
	watch: {
		activeTabId(tabId) {
			if (tabId != 1) {
				this.clearFilters()
			}
		},
		loading: function (newVal) {
			this.toggleLoader(newVal)
		}
	}
}
</script>