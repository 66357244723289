import { MenuType } from "@arikgaisler/utils/enums/menuType"
import ActionTypes from "./action-types"
import MutationTypes from "./mutation-types"
import restaurant_service from "@/services/restaurants"
import business_service from "@/services/businesses"
import orders_service from "@/services/orders"

import Vue from "vue"
import moment from "moment"

const vue = new Vue()

export default {
	async [ActionTypes.REQUEST_PREPACKAGES]({ rootGetters }) {
		try {
			const requestPackagesResponse = await orders_service.requestPrepackages({
				business_id: rootGetters.business.id
			})
			return requestPackagesResponse.status == 200
		} catch(e) {
			vue.bugsnag.notify(
				"Unable to request prepackages",
				event => {
					event.addMetadata("error", {
						error: e
					})
				}
			)
			return false
		}
	},
	async [ActionTypes.GET_PREPACKAGED_ORDERS]({ commit }, payload) {
		try {
			const filters = {}

			if (payload.businessId) {
				filters.business_id = payload.businessId
			}
			if (payload.startDate && payload.endDate) {
				filters.from_date = payload.startDate
				filters.to_date = payload.endDate
			}
			if (payload.issuerId) {
				filters.issuer_ids = [payload.issuerId]
			}
			if (payload.statuses != undefined && payload.statuses != null) {
				filters.statuses = payload.statuses
			}

			const ordersResponse = await orders_service.searchAdminOrders(filters)
			if (ordersResponse.status == 200) {
				commit(MutationTypes.GOT_PREPACKAGED_ORDERS, ordersResponse.data.admin_orders)
			} else {
				return false
			}
		} catch (e) {
			vue.bugsnag.notify(
				"Unable to fetch upcoming prepackaged orders",
				event => {
					event.addMetadata("error", {
						error: e,
						payload: payload
					})
				}
			)
			return false
		}
	},
	async [ActionTypes.GET_WEEKLY_PREPACKAGED_ORDERS]({ commit }, payload) {
		try {
			const weekStart = moment().format("YYYY-MM-DD")
			const weekEnd = moment().add(7, "d").format("YYYY-MM-DD")
			const ordersResponse = await orders_service.searchAdminOrders({
				statuses: [0],
				from_date: weekStart,
				to_date: weekEnd,
				business_id: payload.businessId
			})
			if (ordersResponse.status == 200) {
				commit(MutationTypes.GOT_WEEKLY_PREPACKAGED_ORDERS, ordersResponse.data.admin_orders)
			} else {
				return false
			}
		} catch (e) {
			vue.bugsnag.notify(
				"Unable to fetch prepackaged orders",
				event => {
					event.addMetadata("error", {
						error: e,
						payload: payload
					})
				}
			)
			return false
		}
	},
	async [ActionTypes.GET_PREPACKAGED_PRODUCTS]({ commit }, payload) {
		try {
			const businessResponse = await business_service.getBusiness(payload.businessId)
			if (!businessResponse || businessResponse.status != 200) {
				throw "Business api failed"
			}
			const menu = businessResponse.data.menus.find((menu) => payload.menuId == menu.id)
			
			if (!menu) {
				throw "No prepackaged menu found on business"
			}

			const productsPromise = restaurant_service.getMenuProducts(menu.id)
			const restaurantPromise = restaurant_service.getRestaurant(menu.restaurant_id)
			restaurantPromise.catch(() => {})
			const responses = await Promise.all([productsPromise, restaurantPromise])

			if (responses[0].status == 200 && responses[0].data.products) {
				commit(MutationTypes.GOT_PREPACKAGED_PRODUCTS, {
					menu: menu,
					businessProducts: menu.products,
					restaurantProducts: responses[0].data.products,
					restaurantHourExceptions: responses[1] && responses[1].data ? responses[1].data.opening_hours_exceptions : []
				})
			}
			return true
		} catch (e) {
			vue.bugsnag.notify(
				"Unable to fetch prepackaged products",
				event => {
					event.addMetadata("error", {
						error: e,
						data: payload
					})
				}
			)
			return false
		}
	},
	[ActionTypes.CLEAR_PREPACKAGED_PRODUCTS]({ commit }) {
		commit(MutationTypes.CLEAR_PREPACKAGED_PRODUCTS)
	},
	async [ActionTypes.ADD_PREPACKAGED_ORDERS]({ state, commit }, payload) {
		try {
			const restaurantId = state.prepackagedMenu.restaurant_id
			const products = payload.products.map(product => {
				return {
					id: product.id,
					name: product.name,
					price: product.price,
					quantity: product.quantity,
					menu_id: state.prepackagedMenu.id
				}
			})

			const scheduledDate = moment.utc(`${payload.date} ${payload.deliverySlot}`).subtract(payload.business.timezone_offset, "m").format("YYYY-MM-DD HH:mm:ss")
			const order = {
				restaurant_id: restaurantId,
				menu_type: MenuType.PRE_PACKAGED,
				products: products,
				scheduled_date: scheduledDate
			}

			const addOrdersResponse = await orders_service.addAdminOrders({
				business_id: payload.business.id,
				admin_orders: [order]
			})

			if (addOrdersResponse.status == 200) {
				commit(MutationTypes.ADDED_PREPACKAGED_ORDERS, addOrdersResponse.data.admin_orders)
			}
			return true
		} catch(e) {
			vue.bugsnag.notify(
				"Unable to add prepackaged order",
				event => {
					event.addMetadata("error", {
						error: e,
						payload: payload
					})
				}
			)
			return false
		}
	},
	async [ActionTypes.UPDATE_PREPACKAGED_ORDER]({ state, commit }, payload) {
		try {
			const suborderId = payload.order.sub_orders[0].id
			const products = payload.products.map(product => {
				return {
					id: product.id,
					name: product.name,
					price: product.price,
					quantity: product.quantity,
					menu_id: state.prepackagedMenu.id
				}
			})

			const addOrdersResponse = await orders_service.updateAdminOrder(payload.order.order_id, {
				sub_order_id: suborderId,
				products: products
			})
			if (addOrdersResponse.status == 200) {
				commit(MutationTypes.UPDATED_PREPACKAGED_ORDER, addOrdersResponse.data.admin_order)
			}
			return true
		} catch(e) {
			vue.bugsnag.notify(
				"Unable to update prepackaged order",
				event => {
					event.addMetadata("error", {
						error: e,
						payload: payload
					})
				}
			)
			return false
		}
	},
	async [ActionTypes.CANCEL_PREPACKAGED_ORDER]({ commit }, order) {
		try {
			const response = await orders_service.cancelAdminOrder(order.order_id)
			if (response.status == 200) {
				commit(MutationTypes.CANCELLED_PREPACKAGED_ORDER, order)
				return true
			}
			return false
		} catch(e) {
			vue.bugsnag.notify(
				"Unable to cancel prepackaged order",
				event => {
					event.addMetadata("error", {
						error: e,
						order: order
					})
				}
			)
			return false
		}
	},
	async [ActionTypes.GET_PREPACKAGED_MANIFEST](store, payload) {
		try {
			const response = await orders_service.getPrepackagedManifest(payload)
			if (response.status == 200) {
				return response.data.data.manifest_url
			} else {
				return null
			}
		} catch (e) {
			vue.bugsnag.notify(
				"Unable to generate manifest",
				event => {
					event.addMetadata("error", {
						error: e,
						payload: payload
					})
				}
			)
			return null
		}
	},
}