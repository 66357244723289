<template>
	<div class="sk-page-content">
		<PageHeader
			ref="pageHeader"
			:title="'Insights'"
			:tabs="tabs"
			:filters="filters"
			:actions="actions"
			@tabClicked="headerTabClicked"
			@actionClicked="headerActionClicked"
			@filterSet="filterSet"
			@allFiltersReset="clearFilters"
		/>
		<transition name="slide" mode="out-in">
			<RestaurantSalesAndOrders
				v-if="activeTabId == 0"
				key="0"
				:filterVals="filterVals"
			/>
			<RestaurantQualityAndOperations
				v-if="activeTabId == 1"
				key="1"
				:filterVals="filterVals"
			/>
		</transition>
	</div>
</template>

<script>
import moment from "moment"
import ActionTypes from "@/store/modules/insights/action-types"
import PageHeader from "@/components/PageHeader.vue"
import RestaurantSalesAndOrders from "@/components/insights/RestaurantSalesAndOrders.vue"
import RestaurantQualityAndOperations from "@/components/insights/RestaurantQualityAndOperations.vue"

export default {
	name: "RestaurantInsights",
	components: {
		PageHeader,
		RestaurantSalesAndOrders,
		RestaurantQualityAndOperations
	},
	data: function () {
		return {
			activeTabId: 0,
			filterVals: {
				restaurantId: null,
				businessId: null,
				startDate: null,
				endDate: null
			}
		}
	},
	computed: {
		pairedBusinesses() {
			const entityIds = this.$store.state.user_entities.map(
				(entity) => entity.id
			)
			const businesses = []
			const businessIds = []
			entityIds.forEach((entityId) => {
				const pairedRestaurants =
					this.$store.getters.businessRestaurantPairings[entityId]
				if (pairedRestaurants && pairedRestaurants.businesses) {
					pairedRestaurants.businesses.forEach((business) => {
						if (!businessIds.includes(business.id)) {
							businesses.push(business)
							businessIds.push(business.id)
						}
					})
				}
			})
			return businesses
		},
		actions() {
			if (this.$store.getters.isSuperAdmin) {
				return [
					{
						id: 0,
						classes: "button-reset",
						text: `<div class="insights-toggle"><div>Business View</div><div class="active">Restaurant View</div></div>`
					}
				]
			}
			return null
		},
		tabs() {
			const tabs = [
				{
					id: 0,
					text: "Sales & Orders"
				},
				{
					id: 1,
					text: "Quality & Operations"
				}
				// {
				// 	id: 2,
				// 	text: "Menus"
				// }
			]
			return tabs
				.map((tab) => {
					tab.active = tab.id == this.activeTabId
					return tab
				})
				.sort((a, b) => a.id - b.id)
		},
		filters() {
			let filters = [
				{
					id: 0,
					placeholder: "Date",
					type: "daterange",
					shortcuts: [
						{
							key: "thisWeek",
							label: "This Week",
							value: "isoWeek"
						},
						{
							key: "lastWeek",
							label: "Last Week",
							value: "-isoWeek"
						},
						{
							key: "lastMonth",
							label: "Last Month",
							value: "-month"
						},
						{
							key: "thisMonth",
							label: "This Month",
							value: "month"
						},
						{
							key: "thisQuarter",
							label: "This Quarter",
							value: "quarter"
						},
						{ key: "thisYear", label: "This Year", value: "year" }
					]
				}
			]

			if (this.$store.getters.ownsMultipleEntities) {
				filters.push({
					id: 1,
					placeholder: "Restaurant Name",
					type: "search",
					width: "250px",
					fetch: (query) => {
						return this.$store.dispatch(
							"restaurantsModule/autocompleteRestaurants",
							query
						)
					},
					serializer: (i) => {
						return { text: i.name }
					}
				})
			}

			if (this.$store.getters.isSuperAdmin) {
				filters.push({
					id: 2,
					placeholder: "Business Name",
					type: "search",
					width: "250px",
					fetch: (query) => {
						return this.$store.dispatch(
							"businessesModule/autocompleteBusinesses",
							query
						)
					},
					serializer: (i) => {
						return { text: i.name }
					}
				})
			} else {
				filters.push({
					id: 2,
					placeholder: "Business Name",
					type: "search",
					width: "250px",
					items: this.pairedBusinesses,
					serializer: (i) => {
						return { text: i.name }
					}
				})
			}

			return filters
		}
	},
	methods: {
		headerTabClicked(tab) {
			this.activeTabId = tab.id
		},
		headerActionClicked() {
			this.$router.push({
				path: "/insights",
				query: { view: "business" }
			})
		},
		filterSet(filter) {
			switch (filter.id) {
				case 0: {
					this.filterVals.startDate = filter.value.from
					this.filterVals.endDate = filter.value.to
					break
				}
				case 1: {
					this.filterVals.restaurantId = filter.value
						? filter.value.id
						: null
					this.filterVals.businessId = null
					break
				}
				case 2: {
					this.filterVals.businessId = filter.value
						? filter.value.id
						: null
					this.filterVals.restaurantId = null
					break
				}
			}
			this.setFilters()
		},
		clearFilters() {
			this.filterVals.restaurantId = null
			this.filterVals.businessId = null
			this.filterVals.startDate = null
			this.filterVals.endDate = null
			this.setDefaultDateFilter()
			this.setFilters()
		},
		setFilters() {
			this.$store.dispatch(`insightsModule/${ActionTypes.SET_FILTERS}`, {
				startDate: this.filterVals.startDate,
				endDate: this.filterVals.endDate,
				restaurantId: this.filterVals.restaurantId,
				businessId: this.filterVals.businessId
			})
		},
		setDefaultDateFilter() {
			const start = moment
				.utc()
				.subtract(7, "days")
				.hours(0)
				.minutes(0)
				.seconds(0)
			const end = moment
				.utc()
				.subtract(1, "days")
				.hours(23)
				.minutes(59)
				.seconds(59)
			this.filterVals.startDate = start.format("YYYY-MM-DD")
			this.filterVals.endDate = end.format("YYYY-MM-DD")
			this.$refs.pageHeader.filterSet(
				this.filters[0],
				{
					start: start.format("MMM DD, YYYY"),
					end: end.format("MMM DD, YYYY"),
					formatted: {
						start: start.format("MMM DD, YYYY"),
						end: end.format("MMM DD, YYYY")
					}
				},
				true
			)
		}
	},
	mounted: function () {
		this.setDefaultDateFilter()
		this.setFilters()
	}
}
</script>