import Vue from "vue"
import api from "@/api"
import restaurant_service from "@/services/restaurants"
import permissions, { PermissionInfo } from "@/utils/permissions"
import pos_service from "@/services/pos"
import orders_service from "@/services/orders"
import payment_service from "@/services/payments"
import user_service from "@/services/users"
import auth_service from "@/services/auth"
import { UserRole } from "@/enums/userRole"
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import ActionTypes from "@/store/modules/activityFeed/action-types"

const vue = new Vue()

export default {
    async deleteProduct({ commit }, product_id) {
        const prodResp = await restaurant_service.deleteProduct(product_id)
        if (prodResp.data.code == 'ok') {
            await commit('removeRestaurantProduct', product_id)
        }
    },
    async getRestaurants({ commit }, silently) {
        try {
            if (!silently) {
                commit("loading")
            }
            await commit("gotRestaurants", await restaurant_service.getRestaurants())
            if (!silently) {
                commit("loaded")
            }
            return true
        } catch (e) {
            if (!silently) {
                commit("loaded")
            }
            vue.bugsnag.notify(
                "Failed to get restaurants",
                event => {
                    event.addMetadata("error", {
                        error: e
                    })
                }
            )
            return false
        }
    },
    async getRestaurantsByIds({ commit }, params) {
        try {
            if (!params) {
                return true
            }

            if (!params.silently) {
                commit("loading")
            }
            const restaurantsResp = await restaurant_service.getRestaurantsByIds(params.ids.join(","), "opening_hours,integrations")
            commit("gotRestaurantsByIds", restaurantsResp)
            if (!params.silently) {
                commit("loaded")
            }
            return true
        } catch (e) {
            if (!params.silently) {
                commit("loaded")
            }
            vue.bugsnag.notify(
                "Failed to get restaurants by ids",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        params: params
                    })
                }
            )
            return false
        }
    },
    async addRestaurant({ commit }, rest) {
        try {
            const payload = { "restaurant": rest }
            const response = await restaurant_service.addRestaurant(payload)
            if (response.status == 200 && response.restaurant) {
                commit("addedRestaurant", response.restaurant)
                return response.restaurant
            } else {
                throw `API Error: ${response.status}`
            }
        } catch (e) {
            let message = e.message ? e.message : "Failed to add restaurant"
            if (e.code == "invalid_request_parameters") {
                message = "Invalid parameters, please review the fields and try again."
            }
            eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
                title: message,
                type: NotificationType.ERROR
            })
            vue.bugsnag.notify(
                "Failed to add restaurant",
                event => {
                    event.addMetadata("error", {
                        error: e
                    })
                }
            )
            return false
        }
    },
    async deleteRestaurant({ commit }, restaurantId) {
        try {
            const response = await restaurant_service.deleteRestaurant(restaurantId)
            if (response.status == 200) {
                commit("deletedRestaurant", restaurantId)
                return true
            } else {
                throw `API Error: ${response.status}`
            }
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to delete restaurant",
                event => {
                    event.addMetadata("error", {
                        error: e
                    })
                }
            )
            return false
        }
    },
    async getRestaurantsPOSDevices({ commit }, ids) {
        try {
            const response = await pos_service.getRestaurantsPOSDevices(ids.join(","))
            if (response.data && response.data.data && response.data.data.devices) {
                commit("gotRestaurantsPOSDevices", response.data.data.devices)
                return true
            } else {
                throw "Devices not returned"
            }
        } catch (e) {
            vue.bugsnag.notify(
                "Unable to get POS Devices",
                event => {
                    event.addMetadata("error", {
                        error: e
                    })
                }
            )
            throw e
        }
    },
    async getBusinesses({ commit }, silently) {
        try {
            if (!silently) {
                commit("loading")
            }
            const businessResp = await api.getBusinesses()
            commit("gotBusinesses", businessResp)
            if (!silently) {
                commit("loaded")
            }
            return true
        } catch (e) {
            if (!silently) {
                commit("loaded")
            }
            vue.bugsnag.notify(
                "Failed to get businesses",
                event => {
                    event.addMetadata("error", {
                        error: e
                    })
                }
            )
            return false
        }
    },
    async getBusinessesByIds({ commit }, params) {
        try {
            if (!params) {
                return true
            }
            if (params.ids.length <= 0) {
                return true
            }
            if (!params.silently) {
                commit("loading")
            }

            const businessResp = await api.getBusinessesByIds(params.ids.join(","))
            commit("gotBusinessesByIds", businessResp)
            if (!params.silently) {
                commit("loaded")
            }
            return true
        } catch (e) {
            if (!params.silently) {
                commit("loaded")
            }
            vue.bugsnag.notify(
                "Failed to get businesses by ids",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        params: params
                    })
                }
            )
            return false
        }
    },
    async getEmployees({ commit }) {
        commit('loading')
        const employeeResp = await api.getBusinessEmployees()
        await commit('gotBusinessEmployees', employeeResp)
        commit('loaded')
    },
    async addBusinessEmployee({ commit }, arr) {
        const business_id = arr[0]
        const name = arr[1]
        const email = arr[2]
        const title = arr[3]
        const phone = arr[4]
        var payload = { 'name': name, 'email': email, 'title': title, 'phone': phone }
        const employeeResp = await api.addBusinessEmployee(business_id, payload)
        if (employeeResp.data.code == 'ok') {
            await commit('gotBusinessEmployees', await api.getBusinessEmployees())
        }
    },
    async updateBusinessEmployee({ commit }, arr) {
        const employee_id = arr[0]
        const name = arr[1]
        const email = arr[2]
        const title = arr[3]
        const phone = arr[4]
        var payload = { 'employee': { 'name': name, 'email': email, 'title': title, 'phone': phone } }
        const employeeResp = await api.updateBusinessEmployee(employee_id, payload)
        if (employeeResp.data.code == 'ok') {
            await commit('gotBusinessEmployees', await api.getBusinessEmployees())
        }
    },
    async removeBusinessEmployee({ commit }, employee_id) {
        const employeeResp = await api.removeBusinessEmployee(employee_id)
        if (employeeResp.data.code == 'ok') {
            await commit('gotBusinessEmployees', await api.getBusinessEmployees())
        }
    },
    async activateBusinessEmployee({ commit }, employee_id) {
        const payload = {
            "status": 1
        }
        const employeeResp = await api.activateBusinessEmployee(employee_id, payload)
        if (employeeResp.data.code == 'ok') {
            await commit('gotBusinessEmployees', await api.getBusinessEmployees())

        }
    },
    async deactivateBusinessEmployee({ commit }, employee_id) {
        const payload = {
            "status": 2
        }
        const employeeResp = await api.activateBusinessEmployee(employee_id, payload)
        if (employeeResp.data.code == 'ok') {
            await commit('gotBusinessEmployees', await api.getBusinessEmployees())

        }
    },
    async getBusinessEmployeesPaidTips({ commit }) {
        commit('loading')
        await commit('gotBusinessEmployeesPaidTips', await api.searchBusinessEmployeeTips())
        commit('loaded')
    },
    async getBusinessTipAmounts({ commit }, business_id) {
        try {
            await commit("gotBusinessTipAmounts", await api.getBusinessTips(business_id))
            return true
        } catch (e) {
            console.warn("Unable to get business tips", e)
            return true
        }
    },
    async payoutBusinessEmployee({ commit, dispatch, state }) {
        commit('loading')
        var tips_paid = 0
        for (var i = 0; i < state.business_employees.length; i++) {
            const employee_tip = state.business_employees[i]
            if (employee_tip.tip_amount != null && employee_tip.tip_amount != undefined && employee_tip.tip_amount > 0) {
                const payload = { "business_employee_id": employee_tip.id, "amount": employee_tip.tip_amount }
                const resp = await api.payoutBusinessEmployee(payload)
                if (resp.data.code == 'ok') {
                    tips_paid = parseFloat(tips_paid) + parseFloat(employee_tip.tip_amount)
                    commit('addBusinessEmployeeTip', [employee_tip.business_employee_id, 0])
                }
            }
        }
        await dispatch("getBusinesses")
        await dispatch("getBusinessEmployeesPaidTips")
        await dispatch("getBusinessTipAmounts")
        commit('loaded')
        eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
            title: `Your tips payout request of ${parseFloat(tips_paid).toFixed(2)} is being processed!`
        })
    },
    async setExceptionStatus({ commit }, arr) {
        try {
            const id = arr[0]
            const status = arr[1]
            const payload = { 'status': status }
            const resp = await api.setExceptionStatus(id, payload)
            if (resp.status == 200) {
                commit("removeException", id)
                return true
            }
            return false
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to set exception status",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        data: arr
                    })
                }
            )
            return false
        } 
    },
    async batchSetExceptionsStatus({ commit }, payload) {
        try {
            const response = await orders_service.batchSetExceptionStatus(payload)
            if (response.status == 200) {
                commit('batchRemovedExceptions', response.data.exceptions)
                return true
            }
            return false
        }
        catch (e) {
            vue.bugsnag.notify(
                "Failed to batch update exceptions",
                event => {
                    event.addMetadata("error", {
                        error: e
                    })
                }
            )
            return false
        }
    },
    async setRefundStatus({ commit }, payload) {
        try {
            commit('loading')
            const statusStr = payload.status == 0 ? "reject" : "approve"
            const resp = await payment_service.setRefundStatus(payload.id, statusStr)
            commit("loaded")
            if (resp.status == 200) {
                commit("removeRefund", payload.id)
                return true
            }
            return false
        } catch (e) {
            commit("loaded")
            return false
        }
    },
    async batchSetRefundStatus({ commit }, payload) {
        try {
            commit("loading")
            const apiPayload = {
                refund_ids: payload.ids,
            }
            const statusStr = payload.status == 0 ? "reject" : "approve"
            const response = await payment_service.batchSetRefundsStatus(apiPayload, statusStr)
            if (response && response.status == 200) {
                commit("removeRefunds", payload.ids)
            } else {
                throw `API Error: ${response.status}`
            }
            commit("loaded")
            return true
        } catch (e) {
            commit("loaded")
            vue.bugsnag.notify(
                "Failed to batch set refund status",
                event => {
                    event.addMetadata("error", {
                        error: e
                    })
                    event.addMetadata("payload", payload)
                }
            )
            return false
        }
    },
    async setSupplyRequestStatus({ commit }, arr) {
        //commit('loading')
        const id = arr[0]
        const status = arr[1]
        var statusStr = 'approve'
        if (status == 2)
            statusStr = 'reject'
        if (status == 3)
            statusStr = 'cancel'
        const resp = await api.setSupplyRequestStatus(id, statusStr)
        if (resp.data.code == 'ok') {
            if (status == 2)
                await commit('removeSupplyRequest', id)
            else
                await commit('updateSupplyRequest', [id, status])
        }
        //commit('loaded')
    },
    async getRefunds({ commit }, silently) {
        try {
            if (!silently) {
                commit("loading")
            }
            await commit("gotRefunds", await api.getRefunds())
            if (!silently) {
                commit("loaded")
            }
            return true
        } catch (e) {
            if (!silently) {
                commit("loaded")
            }
            vue.bugsnag.notify(
                "Failed to get refunds",
                event => {
                    event.addMetadata("error", {
                        error: e
                    })
                }
            )
            return false
        }
    },
    async getExceptions({ commit }, silently) {
        try {
            if (!silently) {
                commit("loading")
            }
            const exceptionsResp = await api.getExceptions()
            if (exceptionsResp.status == 200){
                commit("gotOrderExceptions", exceptionsResp.data)
            }
            if (!silently) {
                commit("loaded")
            }
            return exceptionsResp.status == 200
        } catch (e) {
            if (!silently) {
                commit("loaded")
            }
            vue.bugsnag.notify(
                "Failed to get exceptions",
                event => {
                    event.addMetadata("error", {
                        error: e
                    })
                }
            )
            return false
        }
    },
    async clearBusinesses({ commit }) {
        commit('clearBusinesses')
    },
    async clearRestaurants({ commit }) {
        commit('clearRestaurants')
    },
    async initializeChef({ state, commit, dispatch, getters }) {
        try {
            if (getters.isLoggedIn && !state.initializing) {
                commit("initializing", true)
                await Promise.all([
                    dispatch("getAdminUser", state.user_id),
                    dispatch("getAdminUsers"),
                    dispatch("ordersModule/getOrderExceptionReasons"),
                    dispatch("getRefundTypes"),
                    dispatch("getDeliveryReasons"),
                    dispatch("getRestaurants", true),
                    dispatch("getBusinesses", true)
                ])
                commit("initializing", false)
            }
            return true
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to initialize chef",
                event => {
                    event.addMetadata("error", {
                        error: e
                    })
                }
            )
            commit("initializing", false)
            return false
        }
    },
    async authUser({ commit, dispatch, state }, payload) {
        try {
            commit("loggedIn", true)
            const authUserResponse = await auth_service.authUser({
                user_name: payload.email,
                password: payload.pass
            })
            commit("userAuthenticated", authUserResponse)
            dispatch("sendForkLiftEvent", "chef_session_start")
            await dispatch("getAdminUser", state.user_id)
            await Promise.all([
                dispatch("getBusinesses"),
                dispatch("getRestaurants"),
                dispatch("getAdminUsers"),
                dispatch("getExceptionTypes"),
                dispatch("getRefundTypes"),
                dispatch("getDeliveryReasons")
            ])
            commit("loggedIn", false)
            eventBus.emit(EventBusEvents.LOGGED_IN)
            return {
                success: true
            }
        } catch (e) {
            commit("loggedIn", false)
            vue.bugsnag.notify(
                "Failed to authenticate user",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        email: payload.email
                    })
                }
            )
            return {
                success: false,
                code: e && e.code ? e.code : false
            }
        }
    },
    userLogout({ commit, dispatch }) {
        commit("userLoggedOut")
        dispatch(`activityFeedModule/${ActionTypes.RESET_ACTIVITY_FEED}`)
    },
    async activateUser({ commit, dispatch, state }, payload) {
        try {
            const activateResponse = await auth_service.activateUser({
                token: payload.token,
                user_name: payload.username,
                password: payload.pass
            })
            commit("userAuthenticated", activateResponse)
            await dispatch("getAdminUser", state.user_id)
            await Promise.all([
                dispatch("getBusinesses"),
                dispatch("getRestaurants"),
                dispatch("getAdminUsers")
            ])
            dispatch("sendForkLiftEvent", "chef_session_start")
            return activateResponse.status == 200
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to activate user",
                event => {
                    event.addMetadata("error", {
                        error: e
                    })
                }
            )
            return false
        }
    },
    async verifyActivationToken(store, token) {
        try {
            if (token != null && token != undefined) {
                const verifyResponse = await user_service.verifyActivationToken({ activation_token: token })
                if (verifyResponse.status == 200){
                    return verifyResponse.data.user
                } else {
                    return null
                }
            }
            return false
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to verify token",
                event => {
                    event.addMetadata("error", {
                        error: e
                    })
                }
            )
            return false
        }
    },
    async resendActivationLink(store, email) {
        try {
            if (email && email != "") {
                const resendResponse = await user_service.resendActivationToken({
                    username: email
                })
                return resendResponse.status == 200            
            }
            return false
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to resend activation link",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        email: email
                    })
                }
            )
            return false
        }
    },
    async resetPassword(store, payload) {
		try {
			if (payload.pass && payload.pass != "") {
				const resetPassResponse = await auth_service.resetPassword({
					password: payload.pass,
					token: payload.token
				})
				return resetPassResponse.status == 200
			}
			return false
		} catch(e) {
			vue.bugsnag.notify(
				"Failed to send reset password",
				event => {
					event.addMetadata("error", {
						error: e
					})
				}
			)
			return false
		}
	},
    async sendSMSActivationCode(store, userId) {
		try {
            const response = await user_service.generateMobileOTP(userId)
            if (response.status == 200) {
                return true
            }
            throw `API Error: ${response.status}`
		} catch(e) {
			vue.bugsnag.notify(
				"Failed to send SMS Activation Code",
				event => {
					event.addMetadata("error", {
						error: e
					})
				}
			)
			return false
		}
	},
    async verifySMSActivationCode(store, payload) {
		try {
            const response = await user_service.verifyMobileOTP(payload.userId, {
                code: payload.code
            })
            if (response.status == 200) {
                return response.data
            }
            throw `API Error: ${response.status}`
		} catch(e) {
			vue.bugsnag.notify(
				"Failed to send SMS Activation Code",
				event => {
					event.addMetadata("error", {
						error: e
					})
				}
			)
			return false
		}
	},
    calculatePermissions({ commit, state, getters }) {
        if (state.user_id) {
            const userRole = state.user_role
            const userSubRole = state.user_sub_role
            const extraData = {}
            if (userRole != UserRole.SUPER_ADMIN) {
                extraData.hasHotel = getters.hasHotel
                extraData.hasCoworking = getters.hasCoworking
            }
            const grantedPermissions = permissions.calculatePermissions(userRole, userSubRole, extraData)
            commit("calculatedPermissions", grantedPermissions)
        } else {
            commit("calculatedPermissions", [])
        }
    },
    async getAdminUser({ commit, state }, userId) {
        try {
            const userResponse = await user_service.getUser(userId)
            commit("gotAdminUser", userResponse)
            vue.bugsnag.setUser(state.user_id, undefined, state.user_name)
            return true
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to get admin user",
                event => {
                    event.addMetadata("error", {
                        error: e,
                        userId: userId
                    })
                }
            )
            return false
        }
    },
    async getAdminUsers({ commit, state }) {
        try {
            if (state.user_role == UserRole.SUPER_ADMIN || state.user_role == UserRole.BUSINESS_ADMIN) {
                await commit("gotAdminUsers", await user_service.getUsers())
            }
            return true
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to get admin users",
                event => {
                    event.addMetadata("error", {
                        error: e
                    })
                }
            )
            return false
        }
    },
    async addAdminUser({ commit }, payload) {
        try {
            const userResp = await user_service.addUser(payload)
            if (userResp.status == 200) {
                commit('addedAdminUser', userResp.data.user)
                return userResp.data.user
            }
            return false
        }
        catch (e) {
            vue.bugsnag.notify(
                "Failed to add admin user",
                event => {
                    event.addMetadata("error", {
                        error: e
                    })
                }
            )
            return false
        }
    },
    async updateAdminUser({ commit }, user) {
        try {
            const payload = { 'user': user }
            const userResp = await user_service.updateUser(user.id, payload)
            if (userResp.status == 200) {
                commit('updatedAdminUser', userResp.data.user)
                return true
            }
            return false
        }
        catch (e) {
            vue.bugsnag.notify(
                "Failed to update admin user",
                event => {
                    event.addMetadata("error", {
                        error: e
                    })
                }
            )
            return false
        }
    },
    async resendAdminUserActivationEmail(store, user) {
        try {
            const payload = {
                "username": user.username
            }
            const resp = await user_service.resendActivationToken(payload)
            if (resp.status == 200) {
                return true
            }
            return false
        }
        catch (e) {
            vue.bugsnag.notify(
                "Failed to resend activation link",
                event => {
                    event.addMetadata("error", {
                        error: e
                    })
                }
            )
            return false
        }
    },
    async resendAdminUserForgotPasswordEmail(store, user) {
        try {
            const payload = {
                "username": user.username
            }
            const resp = await user_service.sendForgotPassword(payload)
            return resp.status == 200
        }
        catch (e) {
            vue.bugsnag.notify(
                "Failed to resend forgot password link",
                event => {
                    event.addMetadata("error", {
                        error: e
                    })
                }
            )
            return false
        }
    },
    async removeAdminUser({ commit }, user_id) {
        try {
            const userResp = await user_service.deleteUser(user_id)
            if (userResp.status == 200) {
                commit('removedAdminUser', user_id)
                return true
            }
            return false
        }
        catch (e) {
            vue.bugsnag.notify(
                "Failed to remove admin user",
                event => {
                    event.addMetadata("error", {
                        error: e
                    })
                }
            )
            return false
        }
    },
    sendForkLiftEvent({ state }, eventName) {
        api.sendEvent({
            name: eventName,
            attributes: {
                user_id: state.user_id
            }
        })
    },
    async login({ commit }) {
        commit('loggedIn', true)
    },
    async getRefundTypes({ commit }) {
        try {
            const response = await api.getRefundTypes()
            if (response.status == 200) {
                commit("gotRefundTypes", response)
                return true
            }
            throw `API Error: ${response.status}`
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to get refund types",
                event => {
                    event.addMetadata("error", {
                        error: e
                    })
                }
            )
            return false
        }
    },
    async getDeliveryReasons({ commit }) {
        try {
            const response = await api.getDeliveryReasons()
            if (response.status == 200) {
                commit("gotDeliveryReasons", response)
                return true
            }
            throw `API Error: ${response.status}`
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to get delivery reasons",
                event => {
                    event.addMetadata("error", {
                        error: e
                    })
                }
            )
            return false
        }
    },
    async getExceptionTypes({ commit }) {
        try {
            const response = await orders_service.getExceptionTypes()
            if (response.status == 200) {
                commit("gotExceptionTypes", response)
                return true
            }
            throw `API Error: ${response.status}`
        } catch (e) {
            vue.bugsnag.notify(
                "Failed to get exception types",
                event => {
                    event.addMetadata("error", {
                        error: e
                    })
                }
            )
            return false
        }
    },
    async getSupplies({ state, dispatch }) {
        try {
            const promises = []

            if (state.permissions.includes(PermissionInfo.BUSINESS_SUPPLIES)) {
                promises.push(dispatch("businessesModule/getSupplies"))
            }

            if (state.permissions.includes(PermissionInfo.RESTAURANT_SUPPLIES)) {
                promises.push(dispatch("restaurantsModule/getSupplies"))
            }

            await Promise.all(promises)
            return true
        } catch (e) {
            return false
        }
    },
    async getSupplyRequests({ state, dispatch }) {
        try {
            const promises = []

            if (state.permissions.includes(PermissionInfo.BUSINESS_SUPPLIES)) {
                promises.push(dispatch("businessesModule/getSupplyRequests"))
            }

            if (state.permissions.includes(PermissionInfo.RESTAURANT_SUPPLIES)) {
                promises.push(dispatch("restaurantsModule/getSupplyRequests"))
            }

            await Promise.all(promises)
            return true
        } catch (e) {
            return false
        }
    }
}