<template>
	<InsightBaseWidget
		:title="title"
		:footerIcon="footerIcon"
		:footerTitle="footerTitle"
	>
		<div class="sk-insight-widget-tabs" slot="tabs">
			<SkTabs :tabs="tabs" :isVisible="true" @tabClicked="tabClicked" />
		</div>
		<template v-for="(index, name) in $slots" :slot="name">
			<slot :name="name" />
		</template>
	</InsightBaseWidget>
</template>

<style scoped>
.sk-insight-widget-tabs {
	margin: -1px -20px 0 -20px;
	padding: 15px 18px 0 18px;
}

.sk-insight-widget-tabs >>> .sk-tabs {
	box-shadow: none;
	border-bottom: 1px solid var(--sk-grey);
}
</style>

<script>
import InsightBaseWidget from "@/components/insights/InsightBaseWidget.vue"
import SkTabs from "@/components/SkTabs.vue"

export default {
	name: "TabbedInsightWidget",
	components: {
		InsightBaseWidget,
		SkTabs
	},
	props: {
		title: String,
		tabs: Array,
		footerIcon: String,
		footerTitle: String
	},
	methods: {
		tabClicked(tab) {
			this.$emit("tabClicked", tab)
		}
	}
}
</script>