<template>
	<div class="notification-wrapper">
		<transition-group name="notification">
			<div
				class="notification"
				v-for="notification in notifications"
				:class="notification.typeClass"
				:key="notification.uuid"
			>
				<div class="notification-icon">
					<i
						class="sk-icon-check-regular"
						:class="notification.iconClass"
					></i>
				</div>
				<div>
					<div class="notification-title">{{ notification.title }}</div>
					<div class="notification-message" v-if="notification.message">
						{{ notification.message }}
					</div>
				</div>
				<button class="button button-icon" @click="removeNotification(notification)">
					<i class="sk-icon-times-regular"></i>
				</button>
			</div>
		</transition-group>
	</div>
</template>

<style scoped>
.notification-enter,
.notification-leave-to {
  opacity: 0;
  transform: scale(0.9);
}

.notification-leave-active {
  position: absolute;
  z-index: -1;
}

.notification-wrapper {
	z-index: 10000;
	position: absolute;
	top: 15px;
	right: 15px;
	width: 500px;
}

.notification {
	display: flex;
	align-items: center;
	width: 100%;
	min-height: 75px;
	padding: 15px 0;
	background: var(--sk-white);
	border-radius: 5px;
	box-shadow: 0px 1px 4px rgba(136, 136, 136, 0.25);
	border-left: 5px solid var(--sk-green);
	position: relative;
	transition: all 0.5s;
}

.notification.warning {
	border-left: 5px solid var(--sk-yellow);
}

.notification.error {
	border-left: 5px solid var(--sk-red);
}

.notification.info {
	border-left: 5px solid var(--sk-navy);
}

.notification + .notification {
	margin-top: 10px;
}

.notification-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 35px;
	height: 35px;
	background: var(--sk-green);
	border-radius: 50%;
	margin-left: 25px;
	margin-right: 30px;
}

.notification.warning .notification-icon {
	background: var(--sk-yellow);
}

.notification.error .notification-icon {
	background: var(--sk-red);
}

.notification.info .notification-icon {
	background: var(--sk-navy);
}

.notification-icon i {
	color: var(--sk-white);
	font-size: 20px;
}

.notification-title {
	color: var(--sk-grey3);
	font-size: 16px;
}

.notification-message {
	color: var(--sk-grey2);
	font-size: 12px;
	margin-top: 10px;
}

.button-icon {
	position: absolute;
	top: 10px;
	right: 10px;
	color: var(--sk-grey2);
	flex-shrink: 0;
}
</style>

<script>
import { NotificationType } from "@/enums/notificationType"
import { v1 as uuidv1 } from "uuid"
import { eventBus, EventBusEvents } from "@/utils/eventBus"

export default {
	name: "Notification",
	data: function() {
		return {
			notifications: []
		}
	},
	created: function() {
		eventBus.on(EventBusEvents.SHOW_NOTIFICATION, data => {
			this.addNotification(data)
		})
	},
	methods: {
		classForNotificationType(type) {
			switch (type) {
				case NotificationType.ERROR:
					return "error"
				case NotificationType.WARNING:
					return "warning"
				case NotificationType.INFO:
					return "info"
				case NotificationType.SUCCESS:
					return "success"
			}
		},
		iconForNotificationType(type) {
			switch (type) {
				case NotificationType.ERROR:
					return "sk-icon-times-regular"
				case NotificationType.WARNING:
					return "sk-icon-exclamation-solid"
				case NotificationType.INFO:
					return "sk-icon-info-solid"
				case NotificationType.SUCCESS:
					return "sk-icon-check-regular"
			}
			return "sk-icon-check-regular"
		},
		addNotification(data) {
			const type = data.type != undefined ? data.type : NotificationType.SUCCESS
			const notification = {
				uuid: uuidv1(),
				message: data.message,
				title: data.title,
				typeClass: this.classForNotificationType(type),
				iconClass: this.iconForNotificationType(type)
			}
			this.notifications.unshift(notification)

			setTimeout(() => {
				this.removeNotification(notification)
			}, 6000)
		},
		removeNotification(notification) {
			const index = this.notifications.findIndex(
				item => notification.uuid == item.uuid
			)
			if (index > -1) {
				this.notifications.splice(index, 1)
			}
		}
	}
}
</script>