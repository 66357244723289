import { SupplyRequestStatus } from "@/enums/supplyRequestStatus"
import moment from "moment"
import Vue from "vue"

export default {
    gotBusiness(state, payload) {
        state.currentBusiness = payload.data
    },
    gotBusinessRestaurants(state, restaurants) {
        state.currentBusinessRestaurants = restaurants
    },
    gotBusinessRestaurant(state, restaurant) {
        state.currentBusinessRestaurants.push(restaurant)
	},
    gotCurrentBusinessRestaurantMenu(state, menuProducts) {
        state.currentBusinessRestaurantMenuProducts = menuProducts
    },
    addBusinessRestaurantMenuProducts(state, arr) {
        const menu = arr[0]
        const products = arr[1]
        const menu_id = menu.id
        Vue.set(state.currentBusinessRestaurantMenus, menu_id, { products: products, menu: menu })
    },
    gotCategories(state, categories) {
        categories.push({
            id: -1,
            name: "General"
        })
        state.currentBusinessMenuCategories = categories
    },
    addBusinessMenu(state, menu) {
        if (state.currentBusiness && state.currentBusiness.id) {
            state.currentBusiness.menus.push(Object.assign({}, menu))
        }
    },
    updatedBusinessMenu(state, menu) {
        if (state.currentBusiness && state.currentBusiness.menus) {
            let menuIndex = state.currentBusiness.menus.findIndex(businessMenu => businessMenu.id == menu.id)
            if (menuIndex >= 0) {
                state.currentBusiness.menus.splice(menuIndex, 1, menu)
            }
        }
    },
    addBusinessMenuItem(state, arr){
        const item = arr[0]
        const menu_id = arr[1]
        if (state.currentBusiness.menus == undefined || !(state.currentBusiness.menus.some(e => e.id == menu_id)))
            state.currentBusiness.menus.push(Object.assign({}, state.currentBusinessRestaurantMenus[menu_id].menu))

        const businessMenu = state.currentBusiness.menus.find(menu => menu.id == menu_id)
        if (businessMenu.products == undefined || businessMenu.products.length == 0) {
            Vue.set(businessMenu, "products", [item])
        } else {
            businessMenu.products.push(item)
        }
    },
    removeBusinessMenuItem(state, arr) {
        const id = arr[0]
        const menu_id = arr[1]
        for (var i = 0; i < state.currentBusiness.menus.length; i++) {
            if (menu_id == state.currentBusiness.menus[i].id) {
                for (var j = 0; j < state.currentBusiness.menus[i].products.length; j++) {
                    if (state.currentBusiness.menus[i].products[j].id == id) {
                        state.currentBusiness.menus[i].products.splice(j, 1)
                        break
                    }
                }
                break
            }
        }
    },
    removeBusinessMenu(state, menuID) {
        for (var i = 0; i < state.currentBusiness.menus.length; i++) {
            if (menuID == state.currentBusiness.menus[i].id) {
                state.currentBusiness.menus.splice(i, 1)
                break;
            }
        }
	},
	addDropoffLocation(state, payload) {
		if (payload != null) {
			payload.forEach((location) => {
				state.currentBusiness.locations[0].dropoff_locations.unshift({
					courier_dropoff_instructions:
						location.courier_dropoff_instructions,
					created_at: location.created_at,
					customer_pickup_instructions:
						location.customer_pickup_instructions,
					delivery_mode: location.delivery_mode,
					description: location.description,
					id: location.id,
					is_deleted: location.is_deleted,
					text: location.text
				})
			})
		}
	},
	updateDropoffLocation(state, payload) {
		let index = state.currentBusiness.locations[0].dropoff_locations.findIndex(location => location.id == payload.id)
		if (index >= 0){
			state.currentBusiness.locations[0].dropoff_locations.splice(index, 1, payload)
		}
	},
    deletedDropoffLocation(state, dropoff_location_id) {
        for (var i = 0; i < state.currentBusiness.locations[0].dropoff_locations.length; i++) {
            if (dropoff_location_id == state.currentBusiness.locations[0].dropoff_locations[i].id) {
                state.currentBusiness.locations[0].dropoff_locations.splice(i, 1)
                break;
            }
        }
    },
    addBusiness(state, payload) {
        if (payload != null && payload.data != null) {
            if (state.businesses != null && state.businesses != undefined && state.businesses.length > 0) {
                state.businesses[payload.data.business.slug] = payload.data.business
                state.currentBusiness = payload.data.business
            }
        }
    },
    setCurrentBusinessCover(state, img) {
        Vue.set(state.currentBusiness, "img", img)
    },
    setCurrentBusinessLogo(state, img) {
        Vue.set(state.currentBusiness, "logo", img)
    },
    updateMenuItemPosition(state, obj) {
        const menuId = obj.menuId
        const order = obj.order

        let foundMenu = state.currentBusiness.menus.find(menu => menu.id == menuId)
        foundMenu.products
            .forEach(product => {
                if (order.indexOf(product.id) >= 0) {
                    Vue.set(product, "position", order.indexOf(product.id))
                }
            })
    },
    updateBusinessCategoryPosition(state, obj) {
        const menuId = obj.menuId
        const order = obj.order

        var foundMenu = state.currentBusiness.menus.find(menu => menu.id == menuId)
        for (var i = 0; i < order.length; i++) {
            const orderNum = parseInt(order[i])
            if (orderNum != -1) {
                var menuCatItem = foundMenu.products_categories.find(e => e.category_id == orderNum)
                if (!menuCatItem) {
                    foundMenu.products_categories.push({ category_id: orderNum, name: null, position: i })
                } else {
                    menuCatItem.position = i
                }
            }
        }
    },
    updatedBusinessHours(state, payload) {
        state.currentBusiness.opening_hours = payload.hours
    },
    updatedMenuHours(state, payload) {
        const currentMenu = state.currentBusiness.menus.find(menu => menu.id == payload.menuId)
        if (currentMenu != undefined) {
            currentMenu.opening_hours = payload.hours
        }
    },
    updateBusinessLaunchDate(state) {
        state.currentBusiness.launch_date = moment.utc().toDate()
    },
    enabledRewards(state) {
        state.currentBusiness.tip_commission = 0.5
    },
    addBusinessKiosk(state) {
        if (state.currentBusiness.kiosks == undefined) {
            state.currentBusiness.kiosks = []
        }
        state.currentBusiness.kiosks.push({ "external_id": 0 })
    },
    removeBusinessKiosk(state) {
        if (state.currentBusiness.kiosks != undefined && state.currentBusiness.kiosks.length > 0) {
            state.currentBusiness.kiosks.splice(0, 1)
        }
    },
    gotDefaultMURs(state, value) {
        state.defaultMURs = value
    },
    gotCurrentBusinessMURs(state, value) {
        state.currentBusinessMURs = value
    },
    setBusinessMURValue(state, payload) {
        let currentMUR = state.currentBusinessMURs.find(e => e.type == payload.type)
        if (currentMUR && currentMUR != null) {
            currentMUR.markup_value = payload.markup_value
        }
        else {
            const defaultMUR = state.defaultMURs.find(e => e.id == payload.type)
            const currentBusinessMUR = state.currentBusiness.markup_rules ? state.currentBusiness.markup_rules.find(e => e.type == payload.type) : undefined
            if (defaultMUR && defaultMUR != null) {
                state.currentBusinessMURs.push({
                    type: payload.type,
                    markup_type: defaultMUR.markup_type,
                    markup_value: payload.markup_value,
                    id: currentBusinessMUR ? currentBusinessMUR.id : 0
                })
            }
        }
    },
    setBusinessMURType(state, payload) {
        let currentMUR = state.currentBusinessMURs.find(e => e.type == payload.type)
        if (currentMUR && currentMUR != null) {
            currentMUR.markup_type = payload.markup_type
        }
        else {
            const defaultMUR = state.defaultMURs.find(e => e.id == payload.type)
            const currentBusinessMUR = state.currentBusiness.markup_rules ? state.currentBusiness.markup_rules.find(e => e.type == payload.type) : undefined
            if (defaultMUR && defaultMUR != null) {
                state.currentBusinessMURs.push({
                    type: payload.type,
                    markup_type: payload.markup_type,
                    markup_value: defaultMUR.value,
                    id: currentBusinessMUR ? currentBusinessMUR.id : 0
                })
            }
        }
    },
    removeBusinessMUR(state, type) {
        state.currentBusinessMURs = state.currentBusinessMURs.filter(e => e.type != type)
    },

    gotBusinessEmployees(state, employees) {
        state.business_employees = []
        for (var i = 0; i < employees.length; i++) {
            var obj = employees[i]
            if (obj.phone.startsWith('+')) {
                obj.phone = obj.phone.substring(2)
            }
            state.business_employees.push(obj)
        }
    },
    gotBusinessEmployee(state, employee) {
        state.business_employees.unshift(employee)
    },
    updatedBusinessEmployee(state, payload) {
        let employeeIndex = state.business_employees.findIndex(employee => employee.id == payload.id)
        if (employeeIndex >= 0) {
            state.business_employees.splice(employeeIndex, 1, payload)
        }
    },
    businessEmployeeStatusUpdated(state, payload) {
        let employee = state.business_employees.find(employee => employee.id == payload.id)
        if (employee) {
            employee.is_active = payload.status
        }
    },
    removedBusinessEmployee(state, employee_id) {
        state.business_employees = state.business_employees.filter(e => e.id != employee_id)
    },
    clearBusiness(state) {
        state.currentBusiness = {
            name: "",
            slug: "",
            menus: [],
            external_location_id: null,
            external_establishment_id: null,
            external_establishment_name: "",
            address: "",
            city: "",
            state: "",
            zip: "",
            locations: []
        }
        state.currentBusinessRestaurants = []
        state.currentBusinessRestaurantMenus = {}
        state.currentBusinessMURs = []
    },
    loading(state) {
        state.loading = true
    },
    loaded(state) {
        state.loading = false
    },
    updating(state) {
        state.updating = true
    },
    updated(state) {
        state.updating = false
    },
    updateDeliverySlots(state, payload) {
        const menu = state.currentBusiness.menus.find(menu => menu.id == payload.menuId)
        if (menu) {
            menu.delivery_slots = payload.slots
        }
    },
    updateDeliveryModes(state, updatedDeliveryModes) {
        const deliveryModes = updatedDeliveryModes.map((deliveryMode) => {
            return {
                business_id: state.currentBusiness.id,
                delivery_mode: deliveryMode.deliveryMode,
                is_default: deliveryMode.isDefault ? 1 : 0
            }
        })
        if (!state.currentBusiness.delivery_modes) {
            Vue.set(state.currentBusiness, "delivery_modes", deliveryModes)
        } else {
            state.currentBusiness.delivery_modes = deliveryModes
        }
    },
    updateMenuDeliveryModes(state, payload) {
        const deliveryModes = payload.updatedDeliveryModes.map((deliveryMode) => {
            return {
                business_id: state.currentBusiness.id,
                menu_id: payload.menuId,
                delivery_mode: deliveryMode.deliveryMode,
                is_default: deliveryMode.isDefault ? 1 : 0
            }
        })
        const menu = state.currentBusiness.menus.find(menu => menu.id == payload.menuId)
        if (menu && !menu.delivery_modes) {
            Vue.set(menu, "delivery_modes", deliveryModes)
        } else {
            menu.delivery_modes = deliveryModes
        }
    },
    gotSupplies(state, supplies) {
        state.supplies = supplies
    },
    gotSupplyRequests(state, supplyRequests) {
        state.supplyRequests = supplyRequests
    },
    removeSupplyRequest(state, id) {
        const index = state.supplyRequests.findIndex(request => request.id == id)
        if (index > -1) {
            state.supplyRequests.splice(index, 1)
        }
    },
    updateSupplyRequest(state, payload) {
        let statusStr = "APPROVED"
        if (payload.status == SupplyRequestStatus.REJECTED) {
            statusStr = "REJECTED"
        } else if (payload.status == SupplyRequestStatus.CANCELLED) {
            statusStr = "CANCELLED"
        }
        const supplyRequest = state.supplyRequests.find(request => request.id == payload.id)
        if (supplyRequest) {
            state.supplyRequests.status.name = statusStr
            state.supplyRequests.status.value = status
        }
    },
}