import utils from "@/utils/utils"

const clientId = "sq0idp-9YGfQ_i7rO991T6F6YBhdw"

export default {
    async authorize(scopes) {
        const promise = utils.createPromise()
        const oauthUrl = `https://connect.squareup.com/oauth2/authorize?client_id=${clientId}&scope=${scopes.join("+")}`
        let popup = window.open(oauthUrl, "square", "height=600,width=500")
        let timer = null
        let messageHandler = (event) => {
            if (window.origin == event.origin || window.origin.includes("localhost")) {
                const data = event.data ? JSON.parse(event.data) : {}
                if (data.event == "oauthCallback") {
                    if (timer) {
                        clearInterval(timer)
                        timer = null
                    }
                    popup.close()
                    window.removeEventListener("message", messageHandler, false)
                    const params = new URLSearchParams(data.response)
                    const responseType = params.get("response_type")
                    const code = params.get("code")
                    if (responseType && responseType == "code" && code) {
                        promise.resolve(code)
                    } else {
                        promise.reject()
                    }
                }
            }
        }
        window.addEventListener("message", messageHandler, false)

        timer = setInterval(function () {
            if (popup.closed) {
                clearInterval(timer)
                promise.reject()
                window.removeEventListener("message", messageHandler, false)
            }
        }, 1000)
        return promise.promise
    }
}