<template>
	<div
		class="sk-widget-alt sk-widget-hover"
		:class="{ 'has-actions': actions.length > 0 }"
	>
		<div class="dashboard-cta-icon">
			<i :class="icon"></i>
		</div>
		<div class="dashboard-cta-content">
			<div class="dashboard-cta-title">{{ title }}</div>
			<div class="dashboard-cta-subtitle">
				<div class="dashboard-cta-message">{{ message }}</div>
				<div class="dashboard-cta-actions">
					<div
						@click="actionClicked(action)"
						v-for="(action, i) in actions"
						:key="i"
					>
						{{ action.text }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.sk-widget-alt {
	cursor: pointer;
}

.dashboard-cta-icon {
	flex-shrink: 0;
	width: 50px;
	height: 50px;
	background: #ffedde;
	color: var(--sk-orange);
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 20px;
	border-radius: 50%;
}

.dashboard-cta-icon i {
	font-size: 18px;
}

.dashboard-cta-content {
	margin-left: 20px;
}

.dashboard-cta-title {
	font-size: 14px;
	color: var(--sk-dark-navy);
	font-weight: 600;
	margin-bottom: 10px;
}

.dashboard-cta-subtitle {
	position: relative;
	overflow: hidden;
}

.dashboard-cta-message {
	color: var(--sk-grey3);
	font-size: 10px;
	transform: translateY(0);
}

.dashboard-cta-actions,
.dashboard-cta-message {
	transition: all 0.25s ease-in;
}

.dashboard-cta-actions {
	position: absolute;
	top: 15px;
	left: 0;
	line-height: 11px;
    opacity: 0;
}

.dashboard-cta-actions > div {
	display: inline-block;
	font-size: 10px;
	color: var(--sk-grey2);
	cursor: pointer;
}

.dashboard-cta-actions > div:first-child {
	color: var(--sk-orange);
}

.dashboard-cta-actions > div + div {
	margin-left: 10px;
}

.has-actions:hover .dashboard-cta-actions {
	top: 0;
    opacity: 1;
}

.has-actions:hover .dashboard-cta-message {
	transform: translateY(-30px);
    opacity: 0;
}
</style>

<script>
export default {
	name: "DashboardCta",
	props: {
		title: String,
		message: String,
		icon: String,
		actions: {
			type: Array,
			default: () => []
		}
	},
	methods: {
		actionClicked(action) {
			this.$emit("actionSelected", action)
		}
	}
}
</script>