<template>
	<SkBaseModal
		class="order-prepackages-modal"
		:isOpen="isOpen"
		:showCloseButton="false"
		:hasLoader="true"
		@close="close"
	>
		<loading :active.sync="loading" :is-full-page="false" />
		<div class="sk-modal-alt-title">
			<h1>{{ modalTitle }}</h1>
		</div>
		<SkModalStepNavbar
			:steps="steps"
			:currentStep="currentStep"
			@setStep="setStep"
		/>
		<transition-group name="fade-height">
			<div
				v-show="currentStep == orderPrepackagesStep.DETAILS"
				key="0"
				class="sk-modal-section"
			>
				<div class="sk-row" v-if="showBusinessSelector">
					<TypeaheadInput
						ref="businessSearchInput"
						:invalid="businessSearchInvalid"
						:placeholder="'Business'"
						:fetch="autocompleteBusinesses"
						:serializer="searchSerializer"
						@selected="businessSelected"
					/>
				</div>
				<div class="sk-row" v-if="showMenuSelector">
					<SkDropdownSelect
						:classes="'sk-input'"
						:options="menuOptions"
						v-model="selectedMenu"
						@selected="menuSelected"
					/>
				</div>
				<div class="sk-row">
					<VueCtkDateTimePicker
						v-model="dateSelected"
						:label="'Delivery Date'"
						:no-clear-button="true"
						:no-label="true"
						:no-button-now="true"
						:only-date="true"
						:button-color="'#90DA36'"
						:format="'YYYY-MM-DD'"
						:formatted="'ll'"
						:color="'#2E3C49'"
						:output-format="'YYYY-MM-DD'"
						:min-date="minStartDate"
						:max-date="maxEndDate"
						:no-value-to-custom-elem="true"
						:disabled-dates="disabledDates"
					>
						<SkInput
							:value="dateSelected"
							:disabled="true"
							:name="'Delivery Date'"
						/>
					</VueCtkDateTimePicker>
				</div>
				<div class="sk-row" v-if="datesInvalid">
					<div class="step-hint-error">
						<i class="sk-icon-exclamation-triangle-solid"></i>
						Must choose a delivery date to complete your order.
					</div>
				</div>
				<div class="sk-row" v-if="useDynamicDeliverySlot">
					<VueCtkDateTimePicker
						v-model="dynamicDeliverySlot"
						:label="'Delivery Time Slot'"
						:no-clear-button="true"
						:no-label="true"
						:no-button-now="true"
						:only-time="true"
						:minute-interval="60"
						:button-color="'#90DA36'"
						:format="'h:mm A'"
						:formatted="'LT'"
						:color="'#2E3C49'"
						:output-format="'HH:mm:ss'"
						:no-value-to-custom-elem="true"
						:disabled-hours="disabledHours"
					>
						<SkInput
							ref="deliverySlotInput"
							:value="formattedDynamicDeliverySlot"
							:disabled="true"
							:name="'Delivery Time Slot'"
							:required="true"
						/>
					</VueCtkDateTimePicker>
				</div>
				<div
					class="sk-row"
					v-else-if="
						deliverySlotOptions && deliverySlotOptions.length > 2
					"
				>
					<SkDropdownSelect
						ref="deliverySlotInput"
						v-model="deliverySlotInput"
						:classes="'sk-input'"
						:options="deliverySlotOptions"
						:required="true"
					/>
				</div>
				<div class="sk-row" v-if="cutoffTime">
					<div class="step-hint">
						* Catering orders can be updated up until
						{{ cutoffTime }} the night before delivery
					</div>
				</div>
				<div class="sk-row" v-if="deliverySlotInvalid">
					<div class="step-hint-error">
						<i class="sk-icon-exclamation-triangle-solid"></i>
						Must choose a delivery slot to complete your order.
					</div>
				</div>
			</div>
			<div
				v-show="currentStep == orderPrepackagesStep.PRODUCTS"
				key="1"
				class="sk-modal-section"
			>
				<div
					class="sk-row"
					v-for="product in products"
					:key="product.id"
				>
					<PrepackagedProductQuantityInput
						ref="prepackagedProductInput"
						v-model="quantities[product.id]"
						:product="product"
					/>
				</div>
				<div class="sk-row product-quantity-total">
					<div>Total Meals</div>
					<div>{{ productTotal }}</div>
				</div>
			</div>
			<div
				v-show="currentStep == orderPrepackagesStep.REVIEW"
				key="2"
				class="sk-modal-section"
			>
				<SetupReviewStep
					:stepName="'Details'"
					:stepId="orderPrepackagesStep.DETAILS"
					:maxProgress="detailsStepMaxProgress"
					:progress="detailsStepProgress"
					:complete="detailsStepComplete"
					:invalid="detailsStepInvalid"
					:reviewingStepId="reviewingStepId"
					:editable="!order"
					@reviewStep="reviewStep"
					@goToStep="setStep"
				>
					<div class="setup-review-step-detail">
						Business
						<b v-sk-unassigned="businessName" />
					</div>
					<div
						v-if="showMenuSelector"
						class="setup-review-step-detail"
					>
						Menu
						<b v-sk-unassigned="menuName" />
					</div>
					<div class="setup-review-step-detail">
						Delivery On
						<b v-sk-unassigned="deliveryOn" />
					</div>
					<div class="setup-review-step-detail">
						Delivery Time
						<b v-sk-unassigned="deliveryTime" />
					</div>
					<div class="setup-review-step-detail">
						Delivery By
						<b v-sk-unassigned="deliveryBy" />
					</div>
				</SetupReviewStep>
				<SetupReviewStep
					:stepName="'Quantity & Type'"
					:stepId="orderPrepackagesStep.PRODUCTS"
					:maxProgress="1"
					:progress="productsStepProgress"
					:complete="productsStepComplete"
					:invalid="productsStepInvalid"
					:reviewingStepId="reviewingStepId"
					@reviewStep="reviewStep"
					@goToStep="setStep"
				>
					<div
						v-for="product in reviewProducts"
						:key="product.id"
						class="
							setup-review-step-detail
							prepackaged-product-review
						"
					>
						<div>
							<b>{{ product.name }}</b>
							<b>{{ product.totalPrice | currency }}</b>
						</div>
						<div>
							Quantity:
							<b>{{ product.quantity }}</b>
						</div>
					</div>
					<div class="prepackaged-review-divider"></div>
					<div
						class="
							setup-review-step-detail
							prepackaged-product-review
						"
					>
						<div>
							<b>Total</b>
							<b>{{ productTotalPrice | currency }}</b>
						</div>
						<div>
							Quantity:
							<b>{{ productTotal }}</b>
						</div>
					</div>
				</SetupReviewStep>
				<div class="sk-row">
					<div class="step-hint">
						* Payment will not automatically be taken. All charges
						will take place on the businesses billing cycle.
					</div>
				</div>
			</div>
		</transition-group>
		<div class="sk-modal-actions">
			<button @click="close" class="button button-text">Cancel</button>
			<button
				v-if="currentStep != lastStep"
				class="button button-primary"
				@click="nextStep"
			>
				Continue
				<i class="sk-icon-caret-right-solid"></i>
			</button>
			<button
				v-else
				class="button button-primary"
				@click="savePrepackagedOrder"
			>
				{{ saveButtonText }}
			</button>
		</div>
	</SkBaseModal>
</template>

<style scoped>
.sk-widget-section-title {
	margin-bottom: 0;
	font-size: 14px;
	margin-top: 30px;
}

.order-prepackages-modal >>> .sk-modal-content {
	background: var(--sk-greybg2);
}

.step-hint,
.step-hint-error {
	font-size: 12px;
	font-style: italic;
	color: var(--sk-grey2);
}

.step-hint-error {
	color: var(--sk-red);
}

.step-hint-error i {
	margin-right: 10px;
}

.product-quantity-total {
	display: flex;
	justify-content: space-between;
}

.product-quantity-total > div {
	font-size: 14px;
	font-weight: 600;
	color: var(--sk-dark-navy);
}

.product-quantity-total > div:last-child {
	text-align: right;
}

.prepackaged-product-review div:first-child {
	display: flex;
	justify-content: space-between;
}

.prepackaged-product-review div:first-child b {
	margin-left: 0;
}

.prepackaged-product-review div:last-child b {
	margin-left: 5px;
}

.prepackaged-review-divider {
	padding-bottom: 15px;
	margin-top: -5px;
	border-top: 1px solid var(--sk-grey);
}

.sk-modal-alt-title {
	margin-bottom: 0;
	position: relative;
}

.sk-modal-actions .button i {
	margin-left: 30px;
	margin-right: 0px;
}
</style>

<script>
import { CourierId } from "@arikgaisler/utils/enums/courierId"
import { eventBus, EventBusEvents } from "@/utils/eventBus"
import { NotificationType } from "@/enums/notificationType"
import { MenuType } from "@arikgaisler/utils/enums/menuType"
import moment from "moment"
import utils from "@/utils/utils"
import ActionTypes from "@/store/modules/prepackages/action-types"
import currencyFilterMixin from "@/mixins/currency-filter-mixin"
import skModalMixin from "@/mixins/modals/sk-modal-mixin"
import SkBaseModal from "@/components/modals/SkBaseModal.vue"
import SkModalStepNavbar from "@/components/modals/SkModalStepNavbar.vue"
import Loading from "vue-loading-overlay"
import SetupReviewStep from "@/components/modals/SetupReviewStep.vue"
import PrepackagedProductQuantityInput from "@/components/modals/prepackages/PrepackagedProductQuantityInput.vue"
import TypeaheadInput from "@/components/TypeaheadInput.vue"
import SkDropdownSelect from "@/components/SkDropdownSelect.vue"
import VueCtkDateTimePicker from "vue-ctk-date-time-picker"
import SkInput from "@/components/SkInput.vue"
import momentHelper from "@/utils/moment-helper"

const OrderPrepackagesStep = {
	DETAILS: 0,
	PRODUCTS: 1,
	REVIEW: 2
}

export default {
	name: "OrderPrepackagesModal",
	mixins: [skModalMixin, currencyFilterMixin],
	components: {
		SkBaseModal,
		SkModalStepNavbar,
		Loading,
		SetupReviewStep,
		PrepackagedProductQuantityInput,
		TypeaheadInput,
		SkDropdownSelect,
		VueCtkDateTimePicker,
		SkInput
	},
	data: function () {
		return {
			orderPrepackagesStep: OrderPrepackagesStep,
			loading: false,
			currentStep: 0,
			lastStep: 2,
			stepsSeen: [],
			reviewingStepId: null,
			business: null,
			menuId: null,
			prefilledMenuId: null,
			dateSelected: null,
			availableDates: {},
			minStartDate: null,
			maxEndDate: null,
			dynamicDeliverySlot: null,
			quantities: {},
			order: null
		}
	},
	computed: {
		modalTitle() {
			if (this.order) {
				const suborder = this.order.sub_orders[0]
				const orderMoment = moment
					.utc(suborder.delivery_slot)
					.add(this.businessOffset, "m")
				const shortDate = orderMoment.format("M/D")
				const day = orderMoment.format("dddd")
				return `${day} ${shortDate} Catering`
			}
			return "Order Catering"
		},
		ownsMultipleEntities() {
			return this.$store.getters.ownsMultipleEntities
		},
		showBusinessSelector() {
			return this.businessesWithPrepackagedMenus.length > 1
		},
		showMenuSelector() {
			return this.menus.length > 1
		},
		restaurantHourExceptions() {
			return this.$store.state.prepackagesModule.prepackagedRestaurantHourExceptions.map(
				(exception) => {
					const startTime = exception.open_time
						? exception.open_time
						: "00:00"
					const closeTime = exception.close_time
						? exception.close_time
						: "23:59"

					if (exception.open_time && exception.close_time) {
						const startDate = moment
							.utc(
								`${exception.start_date} ${startTime}`,
								"YYYY-MM-DD HH:mm:ss"
							)
							.add(this.businessOffset, "m")
						const endDate = moment
							.utc(
								`${exception.end_date} ${closeTime}`,
								"YYYY-MM-DD HH:mm:ss"
							)
							.add(this.businessOffset, "m")
						const schedules = []
						let dayDiff = startDate.diff(endDate, "d")
						dayDiff = dayDiff == 0 ? (dayDiff = 1) : dayDiff
						const numericalStartTime = momentHelper.numericalTime(
							exception.open_time
						)
						const numericalEndTime = momentHelper.numericalTime(
							exception.close_time
						)
						const exceptionOverlaps =
							numericalEndTime < numericalStartTime
						for (var i = 0; i < dayDiff; i++) {
							const workingStartDate = moment
								.utc(`${exception.start_date} ${startTime}`)
								.add(this.businessOffset, "m")
								.add(i, "d")
							const workingEndDate = moment
								.utc(`${exception.start_date} ${closeTime}`)
								.add(this.businessOffset, "m")
								.add(i, "d")
							if (exceptionOverlaps) {
								workingEndDate.add(1, "d")
							}
							schedules.push({
								startDate: workingStartDate.format(
									"YYYY-MM-DD HH:mm:ss"
								),
								endDate: workingEndDate.format(
									"YYYY-MM-DD HH:mm:ss"
								)
							})
						}
						return {
							closedAllDay: false,
							localStartTime: startDate.format("HH:mm"),
							localEndTime: endDate.format("HH:mm"),
							localStartDate: startDate.format("YYYY-MM-DD"),
							localEndDate: endDate.format("YYYY-MM-DD"),
							schedules: schedules
						}
					} else {
						return {
							closedAllDay: true,
							localStartTime: null,
							localEndTime: null,
							localStartDate: exception.start_date,
							localEndDate: exception.end_date
						}
					}
				}
			)
		},
		menus() {
			if (this.business) {
				const business =
					this.$store.getters.businessesMap[this.business.id]
				return (
					business &&
					business.menus.filter(
						(menu) =>
							menu.type == MenuType.PRE_PACKAGED &&
							menu.pre_order_allowed == 0
					)
				)
			}
			return []
		},
		menuOptions() {
			return this.menus.map((menu) => {
				return {
					id: menu.id,
					text: menu.name
				}
			})
		},
		selectedMenu: {
			get: function () {
				return this.menuOptions.find((menu) => menu.id == this.menuId)
			},
			set: function () {}
		},
		businessesWithPrepackagedMenus() {
			return this.$store.getters[
				"prepackagesModule/businessesWithPrepackagedMenus"
			]
		},
		hiddenDates() {
			const today = moment.utc().format("YYYY-MM-DD")
			return [today]
		},
		steps() {
			const steps = [
				{
					id: OrderPrepackagesStep.PRODUCTS,
					isActive: this.currentStep == OrderPrepackagesStep.PRODUCTS,
					isComplete: this.productsStepComplete,
					isInvalid: this.productsStepInvalid,
					text: "Quantity & Type"
				},
				{
					id: OrderPrepackagesStep.REVIEW,
					isActive: this.currentStep == OrderPrepackagesStep.REVIEW,
					isComplete: false,
					isInvalid: false,
					text: "Pricing & Review"
				}
			]

			if (!this.order) {
				steps.unshift({
					id: OrderPrepackagesStep.DETAILS,
					isActive: this.currentStep == OrderPrepackagesStep.DETAILS,
					isComplete: this.detailsStepComplete,
					isInvalid: this.detailsStepInvalid,
					text: "Details"
				})
			}

			return steps
		},
		detailsStepComplete() {
			const hasDeliverySlot = this.useDynamicDeliverySlot
				? this.dynamicDeliverySlot != null
				: true
			return (
				this.business != null &&
				this.dateSelected != null &&
				hasDeliverySlot
			)
		},
		detailsStepInvalid() {
			if (this.stepsSeen.includes(OrderPrepackagesStep.DETAILS)) {
				const hasDeliverySlot = this.useDynamicDeliverySlot
					? this.dynamicDeliverySlot != null
					: true
				return (
					this.business == null ||
					!this.dateSelected ||
					!hasDeliverySlot
				)
			}
			return false
		},
		datesInvalid() {
			return this.detailsStepInvalid && !this.dateSelected
		},
		deliverySlotInvalid() {
			return (
				this.detailsStepInvalid &&
				this.useDynamicDeliverySlot &&
				!this.dynamicDeliverySlot
			)
		},
		businessSearchInvalid() {
			return this.detailsStepInvalid && this.business == null
		},
		detailsStepMaxProgress() {
			return this.showBusinessSelector ? 2 : 1
		},
		detailsStepProgress() {
			let progress = 0
			if (this.business != null) {
				progress += 1
			}

			if (this.dateSelected) {
				progress += 1
			}
			return progress
		},
		productsStepComplete() {
			return (
				!utils.isObjEmpty(this.quantities) &&
				this.reviewProducts.length > 0
			)
		},
		productsStepInvalid() {
			return (
				this.stepsSeen.includes(OrderPrepackagesStep.PRODUCTS) &&
				(utils.isObjEmpty(this.quantities) ||
					this.reviewProducts.length == 0)
			)
		},
		productsStepProgress() {
			return utils.isObjEmpty(this.quantities) ||
				this.reviewProducts.length == 0
				? 0
				: 1
		},
		canOrderPrepackages() {
			return this.steps.every((step) => !step.isInvalid)
		},
		products() {
			return this.$store.state.prepackagesModule.prepackagedProducts
		},
		productTotal() {
			return Object.values(this.quantities).reduce((total, quantity) => {
				return total + quantity
			}, 0)
		},
		reviewProducts() {
			const products = []
			const productsMap = this.products.reduce((products, product) => {
				products[product.id] = product
				return products
			}, {})
			for (let productId in this.quantities) {
				if (
					!productsMap[productId] ||
					this.quantities[productId] == null ||
					this.quantities[productId] == 0
				) {
					continue
				}
				products.push({
					id: productsMap[productId].id,
					name: productsMap[productId].name,
					price: productsMap[productId].price,
					quantity: this.quantities[productId],
					totalPrice:
						productsMap[productId].price *
						this.quantities[productId]
				})
			}
			return products
		},
		productTotalPrice() {
			return this.reviewProducts.reduce((total, product) => {
				return total + product.totalPrice
			}, 0)
		},
		businessName() {
			return this.business ? this.business.name : null
		},
		menuName() {
			const menu = this.$store.state.prepackagesModule.prepackagedMenu
			if (menu) {
				return menu.name
			} else if (this.menuId) {
				return "N/A"
			}
			return null
		},
		businessOffset() {
			return this.business ? this.business.timezone_offset : 0
		},
		deliveryOn() {
			if (this.dateSelected) {
				let momentDate = moment(this.dateSelected, "YYYY-MM-DD")
				let dowName = momentDate.format("dddd")
				let shortDate = momentDate.format("M/D")
				return `${dowName} ${shortDate}`
			}
			return null
		},
		deliverySlot() {
			const menu = this.$store.state.prepackagesModule.prepackagedMenu
			const selectedDateSlots = this.availableDates[this.dateSelected]
			if (selectedDateSlots && selectedDateSlots.length > 0) {
				const staticSlots = selectedDateSlots.filter(
					(slot) => !slot.dynamic && slot.time
				)
				if (staticSlots.length > 0) {
					let time = staticSlots[0].time
					let localTime = staticSlots[0].localTime
					if (this.dynamicDeliverySlot) {
						localTime = this.dynamicDeliverySlot
						time = moment
							.utc(
								`${this.dateSelected} ${this.dynamicDeliverySlot}`,
								"YYYY-MM-DD HH:mm:ss"
							)
							.subtract(this.businessOffset, "m")
							.format("HH:mm:ss")
					}
					const deliverySlot = menu.delivery_slots.find(
						(slot) => slot.time == time
					)
					if (deliverySlot) {
						return {
							time: deliverySlot.time,
							localTime: localTime,
							cutoff_delta: deliverySlot.cutoff_delta
						}
					}
				}
			}
			return null
		},
		deliverySlotOptions() {
			const selectedDateSlots = this.availableDates[this.dateSelected]
			if (selectedDateSlots && selectedDateSlots.length > 0) {
				const slots = selectedDateSlots
					.slice()
					.sort((a, b) => {
						const aOrder = a.time.replace(":", "")
						const bOrder = b.time.replace(":", "")
						return aOrder - bOrder
					})
					.map((option, i) => {
						const time = moment
							.utc(option.localTime, "HH:mm")
							.format("h:mm A")
						return {
							id: i,
							text: time
						}
					})
				slots.unshift({
					id: null,
					text: "Select a delivery slot"
				})
				return slots
			}
			return null
		},
		deliverySlotInput: {
			get: function () {
				if (this.dynamicDeliverySlot) {
					const time = moment
						.utc(this.dynamicDeliverySlot, "HH:mm:ss")
						.format("h:mm A")
					return this.deliverySlotOptions.find(
						(slot) => slot.text == time
					)
				}
				return this.deliverySlotOptions[0]
			},
			set: function (value) {
				if (value && value.id != null) {
					const time = moment
						.utc(value.text, "h:mm A")
						.format("HH:mm:ss")
					this.dynamicDeliverySlot = time
				} else {
					this.dynamicDeliverySlot = null
				}
			}
		},
		cutoffTime() {
			let cutoffOffset =
				this.deliverySlot && this.deliverySlot.cutoff_delta
					? this.deliverySlot.cutoff_delta
					: 0

			if (cutoffOffset && this.deliverySlot && this.deliverySlot.time) {
				return moment
					.utc(this.deliverySlot.time, "HH:mm:ss")
					.add(this.businessOffset, "m")
					.add(cutoffOffset, "m")
					.format("h:mm A")
			} else if (this.dynamicDeliverySlot) {
				let cutoff = cutoffOffset ? cutoffOffset : null
				if (!cutoff) {
					const menu =
						this.$store.state.prepackagesModule.prepackagedMenu
					const slotWithCutoff =
						menu &&
						menu.delivery_slots &&
						menu.delivery_slots.length > 0
							? menu.delivery_slots.find(
									(slot) => slot.cutoff_delta
							  )
							: null
					cutoff = slotWithCutoff ? slotWithCutoff.cutoff_delta : null
				}
				if (cutoff) {
					return moment(this.dynamicDeliverySlot, "HH:mm:ss")
						.add(cutoff, "m")
						.format("h:mm A")
				}
			}
			return null
		},
		dailyHours() {
			const dailyHours = []
			for (var i = 0; i < 24; i++) {
				let paddedHour = i < 10 ? `0${i}` : i
				dailyHours.push(`${paddedHour}:00`)
			}
			return dailyHours
		},
		disabledDates() {
			let disabledDates = []
			for (let date in this.availableDates) {
				if (
					!this.availableDates[date] ||
					this.availableDates[date].length == 0
				) {
					disabledDates.push(date)
				}
			}
			return disabledDates
		},
		disabledHours() {
			if (!this.dateSelected) {
				return []
			}

			if (!this.availableDates[this.dateSelected]) {
				return this.dailyHours
			}

			const dateSelectedLocalTimes = this.availableDates[
				this.dateSelected
			].map((slot) => slot.localTime)

			return this.dailyHours
				.filter((hour) => !dateSelectedLocalTimes.includes(hour))
				.map((slot) => slot.split(":")[0])
		},
		formattedDynamicDeliverySlot() {
			return this.dynamicDeliverySlot
				? moment
						.utc(this.dynamicDeliverySlot, "HH:mm:ss")
						.format("h:mm A")
				: ""
		},
		useDynamicDeliverySlot() {
			const menu = this.$store.state.prepackagesModule.prepackagedMenu
			return menu && (!this.deliverySlot || !this.deliverySlot.time)
		},
		deliveryTime() {
			if (this.deliverySlot && this.deliverySlot.time) {
				return moment
					.utc(this.deliverySlot.time, "HH:mm:ss")
					.add(this.businessOffset, "m")
					.format("h:mm A")
			} else if (this.dynamicDeliverySlot) {
				return moment
					.utc(this.dynamicDeliverySlot, "HH:mm:ss")
					.format("h:mm A")
			}
			return null
		},
		deliveryBy() {
			const menu = this.$store.state.prepackagesModule.prepackagedMenu
			if (menu) {
				const restaurant =
					this.$store.state.restaurants[menu.restaurant_id]
				switch (menu.courier_id) {
					case CourierId.TOOKAN:
					case CourierId.WALKING:
						return restaurant ? restaurant.name : "N/A"
					case CourierId.CUT_CATS:
						return "Cut Cats"
					case CourierId.POSTMATES:
						return "Postmates"
					case CourierId.DOORDASH:
						return "Door Dash"
				}
			}
			return null
		},
		saveButtonText() {
			return this.order ? "Update Catering Order" : "Order Catering"
		}
	},
	methods: {
		setLoader(loading) {
			this.loading = loading
		},
		handleOptions(options) {
			if (options && options.order) {
				this.order = options.order
				const suborder = options.order.sub_orders[0]
				suborder.products.forEach((product) => {
					this.$set(
						this.quantities,
						product.product_id,
						product.quantity
					)
				})
				this.business =
					this.$store.getters.businessesMap[options.order.business_id]
				this.dateSelected = moment
					.utc(suborder.delivery_slot)
					.add(this.businessOffset, "m")
					.format("YYYY-MM-DD")
				this.currentStep = OrderPrepackagesStep.PRODUCTS
			} else if (
				!this.ownsMultipleEntities ||
				this.businessesWithPrepackagedMenus.length == 1
			) {
				this.prefilledMenuId = options.menuId
				this.business = this.businessesWithPrepackagedMenus[0]
			} else {
				this.minStartDate = moment
					.utc()
					.add(1, "d")
					.format("YYYY-MM-DD")
				this.maxEndDate = moment
					.utc(this.minStartDate, "YYYY-MM-DD")
					.add(2, "M")
					.format("YYYY-MM-DD")
			}

			if (options && options.date) {
				this.dateSelected = options.date
			}
		},
		resetValidation() {
			if (
				this.$refs.prepackagedProductInput &&
				this.$refs.prepackagedProductInput[0]
			) {
				this.$refs.prepackagedProductInput[0].resetValidation()
			}
			if (this.showBusinessSelector) {
				this.$refs.businessSearchInput.clearSearch()
			}
			this.resetDeliverySlotValidation()
		},
		resetDeliverySlotValidation() {
			if (this.$refs.deliverySlotInput) {
				this.$refs.deliverySlotInput.validate()
				if (this.$refs.deliverySlotInput.resetValidation) {
					this.$refs.deliverySlotInput.resetValidation()
				}
			}
		},
		closed() {
			this.loading = false
			this.currentStep = 0
			this.stepsSeen = []
			this.business = null
			this.resetValidation()
			this.reviewingStepId = null
			this.quantities = {}
			this.dateSelected = null
			this.dynamicDeliverySlot = null
			this.order = null
			this.menuId = null
			this.prefilledMenuId = null
			this.minStartDate = null
			this.maxEndDate = null
			this.availableDates = {}
			this.$store.dispatch(
				`prepackagesModule/${ActionTypes.CLEAR_PREPACKAGED_PRODUCTS}`
			)
		},
		nextStep() {
			let nextStepId = this.currentStep + 1
			if (this.currentStep < this.lastStep) {
				this.setStep(nextStepId)
			}
		},
		setStep(id) {
			if (!this.stepsSeen.includes(this.currentStep)) {
				this.stepsSeen.push(this.currentStep)
			}
			if (this.currentStep == OrderPrepackagesStep.PRODUCTS) {
				this.validateProducts()
			} else if (this.currentStep == OrderPrepackagesStep.DETAILS) {
				if (this.$refs.deliverySlotInput) {
					this.$refs.deliverySlotInput.validate()
				}
			}
			if (id == OrderPrepackagesStep.REVIEW) {
				this.stepsSeen = [
					OrderPrepackagesStep.DETAILS,
					OrderPrepackagesStep.PRODUCTS
				]
			}
			this.currentStep = id
		},
		reviewStep(id) {
			this.reviewingStepId = id
		},
		async savePrepackagedOrder() {
			try {
				if (!this.canOrderPrepackages) {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Order Catering",
						message: "Required fields are missing or invalid",
						type: NotificationType.ERROR
					})
					const firstInvalidStep = this.steps.find(
						(step) => step.isInvalid
					)
					this.reviewingStepId = firstInvalidStep.id
					return false
				}
				this.loading = true
				let success = false
				if (this.order) {
					success = await this.$store.dispatch(
						`prepackagesModule/${ActionTypes.UPDATE_PREPACKAGED_ORDER}`,
						{
							order: this.order,
							products: this.reviewProducts
						}
					)
				} else {
					let deliverySlot = ""
					if (this.useDynamicDeliverySlot) {
						deliverySlot = this.dynamicDeliverySlot
					} else {
						deliverySlot = this.deliverySlot.localTime
					}
					success = await this.$store.dispatch(
						`prepackagesModule/${ActionTypes.ADD_PREPACKAGED_ORDERS}`,
						{
							business: this.business,
							products: this.reviewProducts,
							date: this.dateSelected,
							deliverySlot: deliverySlot
						}
					)
				}
				if (success) {
					const successMessage = this.order
						? "Catering orders updated"
						: "Catering orders added"
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Order Catering",
						message: successMessage,
						type: NotificationType.SUCCESS
					})
					this.close()
				} else {
					eventBus.emit(EventBusEvents.SHOW_NOTIFICATION, {
						title: "Order Catering",
						message:
							"Something went wrong, please contact support.",
						type: NotificationType.ERROR
					})
				}
				this.loading = false
			} catch (e) {
				this.loading = false
				throw e
			}
		},
		businessUpdated() {
			this.minStartDate = moment
				.utc()
				.add(this.businessOffset, "m")
				.startOf("d")
				.add(1, "d")
				.format("YYYY-MM-DD")
			this.maxEndDate = moment
				.utc(this.minStartDate, "YYYY-MM-DD")
				.add(2, "M")
				.format("YYYY-MM-DD")
			if (this.order) {
				const suborder = this.order.sub_orders[0]
				this.menuSelected({
					id: suborder.products[0].menu_id
				})
			} else if (this.menus.length > 0) {
				if (this.prefilledMenuId) {
					this.menuSelected({
						id: this.prefilledMenuId
					})
					this.prefilledMenuId = null
				} else {
					this.menuSelected(this.menus[0])
				}
			} else {
				this.menuId = null
				this.quantities = {}
				this.dynamicDeliverySlot = null
				this.$store.dispatch(
					`prepackagesModule/${ActionTypes.CLEAR_PREPACKAGED_PRODUCTS}`
				)
			}
		},
		getPrepackagedProducts() {
			return this.$store.dispatch(
				`prepackagesModule/${ActionTypes.GET_PREPACKAGED_PRODUCTS}`,
				{
					businessId: this.business.id,
					menuId: this.menuId
				}
			)
		},
		calculateAvailableDates() {
			const startMoment = moment.utc(this.minStartDate, "YYYY-MM-DD")
			const endMoment = moment.utc(this.maxEndDate, "YYYY-MM-DD")
			const workingDate = moment
				.utc(this.minStartDate, "YYYY-MM-DD")
				.startOf("d")

			const daysElapsed = endMoment.diff(startMoment, "d")
			const dates = {}

			for (let i = 0; i < daysElapsed + 1; i++) {
				const date = workingDate.format("YYYY-MM-DD")
				dates[date] = this.calculateSlotsForDate(date)
				if (!dates[date] || dates[date].length == 0) {
					dates[date] = null
				}
				workingDate.add(1, "d")
			}

			this.availableDates = dates
			if (this.dateSelected && !this.availableDates[this.dateSelected]) {
				this.dateSelected = null
			}
		},
		calculateSlotsForDate(date) {
			const dateMoment = moment.utc(date, "YYYY-MM-DD")
			const menu = this.$store.state.prepackagesModule.prepackagedMenu
			const menuSchedule =
				this.$store.getters[
					"prepackagesModule/normalizedPrepackagedMenuSchedule"
				]
			let dateMenuSchedules = null
			if (menuSchedule) {
				const dow = moment.utc(date, "YYYY-MM-DD").day()
				const normalizedDow = dow == 0 ? 6 : dow - 1
				dateMenuSchedules =
					menuSchedule[normalizedDow] &&
					menuSchedule[normalizedDow].length > 0
						? menuSchedule[normalizedDow]
						: null
			}
			const hourException = this.restaurantHourExceptions.find(
				(exception) => {
					if (exception.schedules) {
						return exception.schedules.some((schedule) => {
							const startDate = moment.utc(
								schedule.startDate,
								"YYYY-MM-DD HH:mm"
							).startOf("d")
							const endDate = moment.utc(
								schedule.endDate,
								"YYYY-MM-DD HH:mm"
							).startOf("d")
							return dateMoment.isBetween(
								startDate,
								endDate,
								"days",
								"[]"
							)
						})
					} else if (exception.closedAllDay) {
						const startDate = moment.utc(
							`${exception.localStartDate} 00:00`,
							"YYYY-MM-DD HH:mm"
						)
						const endDate = moment.utc(
							`${exception.localEndDate} 23:59`,
							"YYYY-MM-DD HH:mm"
						)
						return dateMoment.isBetween(
							startDate,
							endDate,
							"days",
							"[]"
						)
					}
				},
				[]
			)

			if (hourException && hourException.closedAllDay) {
				return []
			}

			if (menu && menu.delivery_slots.length > 0) {
				let reservedSlots = []
				return menu.delivery_slots.reduce((slots, slot) => {
					if (!slot.time && !slot.cutoff_delta) {
						return slots
					}

					if (
						slot.cutoff_delta &&
						slot.time &&
						!reservedSlots.includes(slot.time)
					) {
						const slotMoment = moment.utc(
							`${date} ${slot.time}`,
							"YYYY-MM-DD HH:mm:ss"
						)
						const cutoffDate = moment
							.utc()
							.startOf("d")
							.format("YYYY-MM-DD")
						const cutoffTime = slot.time
							? slot.time
							: moment.utc().format("HH:mm")
						let cutoffMoment = moment
							.utc(`${cutoffDate} ${cutoffTime}`)
							.add(slot.cutoff_delta, "m")

						if (slotMoment.isSameOrBefore(cutoffMoment)) {
							return slots
						}

						const localTime = slotMoment
							.add(this.businessOffset, "m")
							.format("HH:mm")
						if (hourException) {
							const withinExceptionBounds =
								hourException.schedules.some((schedule) => {
									return this.isDateBetweenDates(
										date,
										localTime,
										schedule.startDate,
										schedule.endDate
									)
								})
							if (!withinExceptionBounds) {
								return slots
							}
						}
						reservedSlots.push(slot.time)
						slots.push({
							localTime: localTime,
							time: slot.time,
							cutoff_delta: slot.cutoff_delta,
							dynamic: false
						})
					} else if (slot.cutoff_delta) {
						const dateMoment = moment.utc(date, "YYYY-MM-DD")
						const cutoffDate = moment
							.utc()
							.startOf("d")
							.format("YYYY-MM-DD")
						const cutoffTime = moment.utc().format("HH:mm")
						let cutoff = moment
							.utc(`${cutoffDate} ${cutoffTime}`)
							.add(slot.cutoff_delta, "m")
						const cutoffPastDate = cutoff.isSameOrAfter(dateMoment)
						if (cutoffPastDate && cutoff.isSame(date, "day")) {
							const numericalCutoff = cutoff
								.startOf("hour")
								.add(this.businessOffset, "m")
								.format("HH:mm")
								.replace(":", "")
							this.dailyHours.reduce((slots, dailyHour) => {
								let numericalTime = dailyHour.replace(":", "")
								if (numericalTime > numericalCutoff) {
									let time = moment
										.utc(dailyHour, "HH:mm")
										.subtract(this.businessOffset, "m")
										.format("HH:mm")
									if (!reservedSlots.includes(time)) {
										if (hourException) {
											const withinExceptionBounds =
												hourException.schedules.some(
													(schedule) => {
														return this.isDateBetweenDates(
															date,
															dailyHour,
															schedule.startDate,
															schedule.endDate
														)
													}
												)
											if (!withinExceptionBounds) {
												return slots
											}
										}

										if (dateMenuSchedules) {
											const isAvailableInMenuSchedule =
												dateMenuSchedules.some(
													(schedule) =>
														this.isDateBetweenTimes(
															date,
															time,
															schedule.openTime,
															schedule.closeTime
														)
												)

											if (!isAvailableInMenuSchedule) {
												return slots
											}
										}
										slots.push({
											time: time,
											localTime: dailyHour,
											cutoff_delta: slot.cutoff_delta,
											dynamic: true
										})
										reservedSlots.push(time)
									}
								}
								return slots
							}, [])
						} else if (!cutoffPastDate) {
							this.dailyHours.forEach((dailyHour) => {
								let time = moment
									.utc(dailyHour, "HH:mm")
									.subtract(this.businessOffset, "m")
									.format("HH:mm")
								if (hourException) {
									const withinExceptionBounds =
										hourException.schedules.some(
											(schedule) => {
												return this.isDateBetweenDates(
													date,
													dailyHour,
													schedule.startDate,
													schedule.endDate
												)
											}
										)
									if (!withinExceptionBounds) {
										return slots
									}
								}
								if (dateMenuSchedules) {
									const isAvailableInMenuSchedule =
										dateMenuSchedules.some((schedule) =>
											this.isDateBetweenTimes(
												date,
												time,
												schedule.openTime,
												schedule.closeTime
											)
										)

									if (!isAvailableInMenuSchedule) {
										return
									}
								}
								if (!reservedSlots.includes(dailyHour)) {
									slots.push({
										time: time,
										localTime: dailyHour,
										cutoff_delta: slot.cutoff_delta,
										dynamic: true
									})
								}
							})
						}
					} else if (!reservedSlots.includes(slot.time)) {
						const slotMoment = moment.utc(
							`${date} ${slot.time}`,
							"YYYY-MM-DD HH:mm:ss"
						)
						const localTime = slotMoment
							.add(this.businessOffset, "m")
							.format("HH:mm")
						if (hourException) {
							const withinExceptionBounds =
								hourException.schedules.some((schedule) => {
									return this.isDateBetweenDates(
										date,
										localTime,
										schedule.startDate,
										schedule.endDate
									)
								})
							if (!withinExceptionBounds) {
								return slots
							}
						}
						reservedSlots.push(slot.time)
						slots.push({
							localTime: localTime,
							time: slot.time,
							cutoff_delta: null,
							dynamic: false
						})
					}

					return slots
				}, [])
			} else {
				return this.dailyHours.reduce((dailyHours, dailyHour) => {
					let time = moment
						.utc(dailyHour, "HH:mm")
						.subtract(this.businessOffset, "m")
						.format("HH:mm")
					if (hourException) {
						const withinExceptionBounds =
							hourException.schedules.some((schedule) => {
								return this.isDateBetweenDates(
									date,
									dailyHour,
									schedule.startDate,
									schedule.endDate
								)
							})
						if (!withinExceptionBounds) {
							return dailyHours
						}
					}

					if (dateMenuSchedules) {
						const isAvailableInMenuSchedule =
							dateMenuSchedules.some((schedule) =>
								this.isDateBetweenTimes(
									date,
									time,
									schedule.openTime,
									schedule.closeTime
								)
							)

						if (!isAvailableInMenuSchedule) {
							return dailyHours
						}
					}

					dailyHours.push({
						time: time,
						localTime: dailyHour,
						cutoff_delta: null,
						dynamic: true
					})

					return dailyHours
				}, [])
			}
		},
		isDateBetweenDates(date, dailyHour, startDate, endDate) {
			if (startDate && endDate) {
				const hourMoment = moment.utc(
					`${date} ${dailyHour}`,
					"YYYY-MM-DD HH:mm"
				)
				const startDateMoment = moment.utc(
					startDate,
					"YYYY-MM-DD HH:mm"
				)

				const endDateMoment = moment.utc(endDate, "YYYY-MM-DD HH:mm")

				return hourMoment.isBetween(
					startDateMoment,
					endDateMoment,
					"m",
					"[]"
				)
			}
			return true
		},
		isDateBetweenTimes(date, dailyHour, startTime, endTime) {
			if (startTime && endTime) {
				const hourMoment = moment.utc(
					`${date} ${dailyHour}`,
					"YYYY-MM-DD HH:mm"
				)
				const startDateMoment = moment.utc(
					`${date} ${startTime}`,
					"YYYY-MM-DD HH:mm"
				)

				const endDateMoment = moment.utc(
					`${date} ${endTime}`,
					"YYYY-MM-DD HH:mm"
				)

				return hourMoment.isBetween(
					startDateMoment,
					endDateMoment,
					"m",
					"[]"
				)
			}
			return true
		},
		async autocompleteBusinesses(query) {
			const results = await this.$store.dispatch(
				"businessesModule/autocompleteBusinesses",
				query
			)
			return results
		},
		searchSerializer(result) {
			return {
				text: result.name
			}
		},
		businessSelected(event) {
			this.business = event
		},
		validateProducts() {
			if (
				this.$refs.prepackagedProductInput &&
				this.$refs.prepackagedProductInput[0]
			) {
				if (this.productTotal > 0) {
					this.$refs.prepackagedProductInput[0].resetValidation()
				} else {
					this.$refs.prepackagedProductInput[0].validate()
				}
			}
		},
		menuSelected(menu) {
			this.menuId = menu.id
			this.loading = true
			Promise.all([this.getPrepackagedProducts()]).finally(() => {
				this.loading = false
				this.calculateAvailableDates()
				if (this.order) {
					const suborder = this.order.sub_orders[0]
					this.dynamicDeliverySlot = moment
						.utc(suborder.delivery_slot)
						.add(this.businessOffset, "m")
						.format("HH:mm:ss")
				} else if (
					this.useDynamicDeliverySlot &&
					this.deliverySlotOptions &&
					this.deliverySlotOptions.length > 0
				) {
					this.deliverySlotInput = this.deliverySlotOptions[1]
				} else {
					this.dynamicDeliverySlot = null
				}
			})
			this.quantities = {}
		}
	},
	watch: {
		business: {
			immediate: true,
			handler: function (newVal) {
				if (newVal != null) {
					this.businessUpdated()
				}
			}
		},
		productTotal() {
			this.validateProducts()
		},
		dateSelected() {
			this.dynamicDeliverySlot = null
		},
		dynamicDeliverySlot() {
			if (this.dynamicDeliverySlot && !this.order) {
				const hour = this.dynamicDeliverySlot.split(":")[0]
				if (this.disabledHours.includes(hour)) {
					this.dynamicDeliverySlot = null
				}
			}
		}
	}
}
</script>