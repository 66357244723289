let batchSelectableMixin = {
    props: {
        batchSelectId: Number,
        batchSelectedIds: Array
    },
    computed: {
        batchSelected() {
            return this.batchSelectedIds.includes(this.batchSelectId)
        }
    },
    methods: {
        batchSelectClicked(event) {
            event.stopPropagation()
            this.$emit("batchSelected", this.batchSelectId)
        }
    }
}

export default batchSelectableMixin