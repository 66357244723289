export default {
  offset: state => {
    if (state.currentRestaurant) {
      return state.currentRestaurant.timezone_offset
    }
    return 0
  },
  timezone: state => {
    if (state.currentRestaurant) {
      return state.currentRestaurant.timezone
    }
    return null
  },
  currentMenusHash: state => {
    const menus = state.currentRestaurant && state.currentRestaurant.menus ? state.currentRestaurant.menus : []
    return menus.reduce((menus, menu) => {
      menus[menu.id] = menu
      return menus
    }, {})
  }
}